import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useCredentials } from 'hooks/credentials';

import { Link, useLocation } from 'react-router-dom';

import api from 'services/api';

import { format } from 'date-fns';
import { Container, Content } from 'styles/sgo_wrappers';

import { FaShare } from 'react-icons/fa';

import { VotedProps } from '../../vote';

import { Grid, GridItem } from './styles';

const EleicaoComprovante: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();
  const [list, setList] = useState<VotedProps[]>([]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/eleicao_comprovantes.php');

      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getList();
  }, [getList]);
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Comprovantes" />
      <Content>
        <Grid>
          {list.map((item) => (
            <GridItem>
              <span>
                <p>
                  Objetivo:<strong>{item.obj}</strong>
                </p>
              </span>
              <span>
                <p>
                  Período:
                  <strong>{format(new Date(item.ini), 'dd/MM/yyyy')}</strong> a
                  <strong>{format(new Date(item.end), 'dd/MM/yyyy')}</strong>
                </p>
              </span>
              <span>
                <p>
                  Votado em:
                  <strong>
                    {format(new Date(item.voted_at), 'dd/MM/yyyy HH:mm:ss')}
                  </strong>
                </p>
              </span>
              <Link
                to={{
                  pathname: `${pathname.substr(0, pathname.length - 1)}`,
                  state: { ...item },
                }}
              >
                <FaShare />
              </Link>
            </GridItem>
          ))}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EleicaoComprovante;
