import React from 'react';

import { Container, Content } from 'styles/wrappers';
import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { TOVService, Schedule } from '../styles';

const manualteen = `${process.env.REACT_APP_ASSETS_DIR}/materiais/5e485839-74e6-46e0-9185-59b19df2dbdf.png`;
const scheduleteen = `${process.env.REACT_APP_ASSETS_DIR}/tovServices/scheduleteen.jpg`;

const Adolescentes: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida para Adolescentes</div>
        </AlteredHeader>
        <TOVService>
          <h2>TOV Adolescente</h2>
          <div>
            <span>
              <p>
                TOV Adolescentes O adolescente é uma criatura de Deus vivendo
                significativas mudanças exteriores e interiores, físicas,
                emocionais e psíquicas. Assim sendo, pode perceber,
                sensivelmente, como Deus está recriando-o diariamente em todos
                os aspectos. É um ser em busca de si mesmo, que deseja acolher
                sua própria identidade para relacionar-se adequadamente com
                Deus, consigo mesmo e com seus semelhantes. Para tanto,
                necessita viver suas próprias realidades, fazer opções e assumir
                projetos. Nesta Oficina, ensinamos os jovens participantes a
                contemplar a figura deslumbrante de nosso Senhor Jesus Cristo,
                Sua vida, Suas palavras, Seus gestos, Suas atitudes interiores e
                motivações. Conhecendo Jesus intimamente, poderão pisar Suas
                pegadas, servindo aos irmãos e anunciando as maravilhas que o
                Pai realiza em sua vida.
              </p>
            </span>
            <img src={manualteen} alt="Manual TOV Adolescente" />
          </div>
          <span>
            <h4>
              <strong>TOV Adolescentes</strong>{' '}
              <p>| Manual “Caminhando com Jesus”</p>
            </h4>
            <h4>
              <strong>Participantes:</strong>
              <p>Adolescentes na faixa etária de 12 a 18 anos.</p>
            </h4>
            <h4>
              <strong>Duração:</strong>
              <p>
                Nove sessões, uma por semana, com duração de uma hora e trinta
                minutos cada.
              </p>
            </h4>
          </span>
        </TOVService>
        <Schedule>
          <h2>Programação da Oficina TOV Adolescente</h2>
          <img src={scheduleteen} alt="Programação Tov Adolescente" />
        </Schedule>
      </Content>
      <Footer />
    </Container>
  );
};

export default Adolescentes;
