import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Content } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Checkbox from 'components/Checkbox';
import InputDialog from 'components/InputDialog';
import { useCredentials } from 'hooks/credentials';

import api from 'services/api';

import IncludeButton from 'components/IncludeButton';
import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';
import { FaPencilAlt, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { useToast } from 'hooks/toast';
import { useSpring } from 'react-spring';

import { df64 } from 'utils/encoding';
import { User, useAuth } from 'hooks/auth';

import { AltButton, ExcButton, Grid, GridItem } from './styles';

interface ListProps {
  uuid: string;
  cod: string;
  desc: string;
  status: string;
  saldo: number;
}

interface FormData {
  appName: string;
}

const AplicConfig: React.FC = () => {
  const { updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const updateFormRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<ListProps[]>([]);
  const [maximumAmount, setMaximumAmount] = useState(0);

  const [inc, setInc] = useState<IncludeProps>(() => {
    return {
      title: 'Inclusão',
      open: false,
      content: '',
    };
  });

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({
    open: false,
    content: '',
    values: {},
  });

  const getAplics = useCallback(async () => {
    try {
      const response = await api.get('/sgo/aplic_cfg.php');

      const { fetchList, amount } = response.data;
      setList(fetchList);
      setMaximumAmount(amount);

      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['GUI', 'LOC']);
    getAplics();
  }, [getAplics, handlePermission]);

  const handleCheckbox = useCallback(
    async (uuid: string) => {
      try {
        setLoading(true);
        const input = document.querySelector<HTMLInputElement>(
          `input[id="${uuid}"]`,
        );
        const index = list.findIndex((item: ListProps) => item.uuid === uuid);
        const value = input?.checked;
        const send = new FormData();
        const data = {
          uuid,
          value: value ? 'A' : 'I',
          status: true,
        };

        send.append('data', JSON.stringify(data));

        await api.post('/sgo/aplic_cfg.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setList((state) => [
          ...state.filter((item) => item.uuid !== uuid),
          {
            ...state[state.findIndex((item) => item.uuid === uuid)],
            status: value ? 'A' : 'I',
          },
        ]);

        let user: User = {} as User;
        const getuser = localStorage.getItem(`${process.env.REACT_APP_USER}`);
        if (getuser) {
          user = df64(getuser);
        }

        const content = [...user.tes.content];

        content.splice(list[index].cod === 'A1' ? 2 : 3, 0, {
          text: list[index].desc,
          route: `tesouraria/appl/${uuid}`,
          uuid,
        });

        const updated = {
          ...user,
          tes: {
            ...user.tes,
            content: value
              ? content
              : user.tes.content.filter((item) => item.uuid !== uuid),
          },
        };

        updateUser(updated);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    [errorHandling, list, updateUser],
  );

  const handleExclusion = useCallback(
    async (uuid: string) => {
      setLoading(true);
      try {
        const send = new FormData();
        send.append('data', JSON.stringify({ uuid, delete: true }));

        await api.post('/sgo/aplic_cfg.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        let user: User = {} as User;
        const getuser = localStorage.getItem(`${process.env.REACT_APP_USER}`);
        if (getuser) {
          user = df64(getuser);
        }

        const updated = {
          ...user,
          tes: {
            ...user.tes,
            content: user.tes.content.filter((item) => item.uuid !== uuid),
          },
        };

        updateUser(updated);

        setList((state) =>
          state.filter((item) => item.uuid !== uuid).map((item) => item),
        );

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    [errorHandling, updateUser],
  );

  const handleInclusion = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          appName: yup
            .string()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(20, 'Quantidade máxima de caracteres excedida (20).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        const insert = { ...data, insert: true, next: list.length + 1 };
        send.append('data', JSON.stringify(insert));

        setInc((state) => ({ ...state, open: false }));
        const response = await api.post('/sgo/aplic_cfg.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        let user: User = {} as User;
        const getuser = localStorage.getItem(`${process.env.REACT_APP_USER}`);
        if (getuser) {
          user = df64(getuser);
        }

        const content = [...user.tes.content];

        content.splice(3, 0, {
          text: `Aplicação ${data.appName}`,
          route: `tesouraria/appl/${response.data}`,
          uuid: response.data,
        });

        const updated = {
          ...user,
          tes: {
            ...user.tes,
            content,
          },
        };
        updateUser(updated);

        formRef.current?.setFieldValue('appName', '');
        setList([]);
        getAplics();
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, getAplics, list.length, updateUser],
  );

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        updateFormRef.current?.setErrors({});

        const schema = yup.object().shape({
          appName: yup
            .string()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(20, 'Quantidade máxima de caracteres excedida (20).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        const update = { ...data, update: true, uuid: updateDiag.values?.uuid };
        send.append('data', JSON.stringify(update));

        setUpdateDiag((state) => ({ ...state, open: false }));
        await api.post('/sgo/aplic_cfg.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // const user: User = getLocalStorage(
        //   `${process.env.REACT_APP_USER}`,
        // ) as User;

        let user: User = {} as User;
        const getuser = localStorage.getItem(`${process.env.REACT_APP_USER}`);
        if (getuser) {
          user = df64(getuser);
        }

        const content = [...user.tes.content];

        const index = content.findIndex(
          (item) => item.uuid === updateDiag.values?.uuid,
        );

        content[index].text = `Aplicação ${data.appName}`;
        const updated = {
          ...user,
          tes: {
            ...user.tes,
            content,
          },
        };

        updateUser(updated);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, updateDiag.values, updateUser],
  );

  const moveList = useSpring({
    transform: list.length <= 0 ? 'translateX(25px)' : 'translateX(0px)',
    opacity: list.length <= 0 ? 0 : 1,
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {!loading && list.length < maximumAmount && (
        <IncludeButton isButton>
          <button
            type="button"
            onClick={() => setInc((state) => ({ ...state, open: true }))}
          >
            <FaPlusCircle />
          </button>
        </IncludeButton>
      )}
      <ScrollTop />
      <SGOHeader />
      {/* <SGONavbar disableBack /> */}
      <SGONavbar />
      <Content>
        <Grid style={moveList}>
          {list.map((item: ListProps) => (
            <GridItem key={item.uuid} disabled={item.saldo > 0}>
              <span>
                <p>
                  Descrição:&nbsp;<strong>{item.desc}</strong>
                </p>
                <AltButton
                  type="button"
                  onClick={() =>
                    setUpdateDiag((state) => ({
                      ...state,
                      open: true,
                      values: {
                        appname: item.desc.replace('Aplicação ', ''),
                        uuid: item.uuid,
                      },
                    }))
                  }
                >
                  <FaPencilAlt />
                </AltButton>
              </span>
              <span>
                <Checkbox
                  id={`${item.uuid}`}
                  title="Status:"
                  checked={item.status === 'A'}
                  onClick={() => handleCheckbox(item.uuid)}
                  isDisabled={item.saldo !== 0}
                />
                <strong>&nbsp;{item.status === 'A' ? 'A' : 'Ina'}tiva</strong>
              </span>
              {item.saldo === 0 && (
                <span>
                  <ExcButton
                    type="button"
                    onClick={() => handleExclusion(item.uuid)}
                  >
                    <p>
                      <FaTimesCircle /> Excluir
                    </p>
                  </ExcButton>
                </span>
              )}
            </GridItem>
          ))}
        </Grid>
        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={formRef} onSubmit={handleInclusion}>
            <I.Title>
              <h2>{inc.title}</h2>
            </I.Title>
            <I.Content>
              <h4
                style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}
              >
                Nova aplicação:
              </h4>
              <InputDialog
                name="appName"
                placeholder="Aplicação"
                mask="alpha"
              />
              <span>
                <p style={{ fontSize: '13px', color: 'red' }}>
                  * Não é necessário a palavra aplicação!
                </p>
              </span>
            </I.Content>
            <I.Actions>
              <I.Cancel
                type="button"
                onClick={() =>
                  setInc({
                    ...inc,
                    open: false,
                  })
                }
              >
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>
        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <Form
            ref={updateFormRef}
            onSubmit={handleUpdate}
            initialData={{ appName: updateDiag.values?.appname || '' }}
          >
            <U.Title>
              <h2>Editar nome</h2>
            </U.Title>
            <U.Content>
              <h4
                style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}
              >
                Editar aplicação:
              </h4>
              <InputDialog
                name="appName"
                placeholder="Aplicação"
                mask="alpha"
              />
              <span>
                <p style={{ fontSize: '13px', color: 'red' }}>
                  * Não é necessário a palavra aplicação!
                </p>
              </span>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={(state) => setUpdateDiag({ ...state, open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AplicConfig;
