import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    /* scroll-behavior: smooth; */
  }
  /* *:not(input, textarea){
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select:none;
    user-select: none;
  } */

  html{
    font-size: 62.5%;
  }

  body {
    background: #c6c6c6;
    color: #262626;
  }


  body, input, button {
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  h1{
    font-size: 3.2rem;
  }
  h2{
    font-size: 2.4rem;
  }
  h3{
    font-size: 1.872rem;
  }
  h4{
    font-size: 1.6rem;
  }
  h5{
    font-size: 1.328rem;
  }
  h6{
    font-size: 1.072rem;
  }

  button{
    cursor: pointer;
  }

  @media (max-width: 1366px){
    html{
      font-size: 58%;
    }
  }

  @media (max-width: 1024px){
    html{
      font-size: 54%;
    }
  }

  @media (max-width: 800px){
    html{
      font-size: 48%;
    }
  }

  @media (max-width: 640px){
    html{
      font-size: 40%;
    }
  }

  @media (max-width: 400px){
    html{
      font-size: 35%;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }



`;
