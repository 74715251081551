import React, { useCallback, useEffect, useState, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';

import { formatNumber } from 'utils/calcTotal';

import api from 'services/api';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { setLocalStorage } from 'utils/handleLocalStorage';

import { useWindow } from 'hooks/window';
import {
  Table,
  TDMonth,
  TDPrevious2,
  TDPrevious,
  TDCurrent,
  TDCompl,
} from './styles';

interface StateProps {
  lanccod: string;
  lancdesc: string;
  cod: string;
  desc: string;
  year: number;
}

export interface PlanDataProps {
  month: number;
  monthdesc: string;
  previous2: string;
  previous: string;
  current: string;
  description: string;
}

const PlanejamentoConsult: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling } = useCredentials();
  const { width } = useWindow();

  const [loading, setLoading] = useState(true);
  const [planData, setPlanData] = useState<PlanDataProps[]>([]);
  const [state, setState] = useState<StateProps>(() => {
    return location.state || ({} as StateProps);
  });

  const [initialYear, setInitialYear] = useState(
    state.year ? state.year.toString() : 'Selecione',
  );
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);

  const getData = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/planejamento_data.php?data=${JSON.stringify({
          cod: state.cod,
          lanccod: state.lanccod,
          year: state.year,
        })}`,
      );

      setPlanData(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, state.cod, state.lanccod, state.year]);

  const getComboYears = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/combos/comboAnosPlanejamento.php?data=${JSON.stringify({
        cod: state.cod,
        lanccod: state.lanccod,
      })}`,
    );

    const toSet = [
      {
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear().toString(),
      },
    ];
    setComboYears(response.data.length === 0 ? [...toSet] : [...response.data]);
    // const { year }: LocalStorageProps = getLocalStorage(
    //   `${process.env.REACT_APP_PLANEJAMENTO}`,
    // );

    // const index = response.data.findIndex(
    //   (item: ComboProps) => item.value === year,
    // );

    // setInitialYear(response.data[index > -1 ? index : 0].label);
    // setSelectedYear(response.data[index > -1 ? index : 0].label);
    // setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
    //   year: response.data[index > -1 ? index : 0].label,
    // });
    // setComboYears(response.data);

    setLoading(false);
  }, [state.cod, state.lanccod]);

  const handleYearSelection = useCallback(() => {
    const year = formRef.current?.getFieldValue('comboYears');

    setInitialYear(year);

    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      year,
    });

    setState((prev) => ({ ...prev, year }));
  }, []);

  useEffect(() => {
    if (!location.state) {
      history.replace(`${location.pathname.replace('/consult', '')}`);
      return;
    }
    getComboYears();
    getData();
  }, [history, location.pathname, location.state, getData, getComboYears]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title="Planejamento Financeiro - Consulta"
        isResponsible
      />
      <Content>
        <AlteredHeader>
          <div>
            {state.desc} | {state.year}
          </div>
          <div>{state.lancdesc}</div>
        </AlteredHeader>
        <Form ref={formRef} onSubmit={() => null}>
          <SelectV2
            name="comboYears"
            content={comboYears}
            initial={initialYear}
            onChange={handleYearSelection}
          />
        </Form>
        <Table>
          <thead>
            <tr>
              <TDMonth>Mês</TDMonth>
              {state.year - 2 >= 2017 && (
                <TDPrevious2>{state.year - 2}</TDPrevious2>
              )}
              {state.year - 1 >= 2017 && (
                <TDPrevious>{state.year - 1}</TDPrevious>
              )}
              <TDCurrent>{state.year}</TDCurrent>
              <TDCompl>Complemento</TDCompl>
            </tr>
          </thead>
          <tbody>
            {planData.map((item, index) => (
              <tr key={item.month}>
                <TDMonth paint={index + 1}>
                  {width > 600
                    ? item.monthdesc
                    : `${item.monthdesc.substr(0, 3)}.`}
                </TDMonth>
                {state.year - 2 >= 2017 && (
                  <TDPrevious2
                    paint={index + 1}
                    bgcolor="#fff2f4"
                    style={{
                      justifyContent: item.previous2 ? 'flex-end' : 'center',
                    }}
                  >
                    {item.previous2
                      ? `R$ ${formatNumber(parseFloat(item.previous2))}`
                      : '-'}
                  </TDPrevious2>
                )}
                {state.year - 1 >= 2017 && (
                  <TDPrevious
                    paint={index + 1}
                    bgcolor="#ffe6e8"
                    style={{
                      justifyContent: item.previous ? 'flex-end' : 'center',
                    }}
                  >
                    {item.previous
                      ? `R$ ${formatNumber(parseFloat(item.previous))}`
                      : '-'}
                  </TDPrevious>
                )}

                <TDCurrent
                  paint={index + 1}
                  bgcolor="#ffd9db"
                  style={{
                    justifyContent: item.current ? 'flex-end' : 'center',
                    // color: '#fff',
                  }}
                >
                  {item.current
                    ? `R$ ${formatNumber(parseFloat(item.current))}`
                    : '-'}
                </TDCurrent>
                {/* <td></td>
                <td>R$ {formatNumber(item.previous)}</td>
                <td>R$ {formatNumber(item.current)}</td> */}
                <TDCompl
                  paint={index + 1}
                  style={{
                    justifyContent: item.description ? 'flex-start' : 'center',
                  }}
                >
                  {item.description || '-'}
                </TDCompl>
              </tr>
            ))}
          </tbody>
        </Table>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default PlanejamentoConsult;
