import React from 'react';

import { Content } from '../../../styles/wrappers';
import { Container, World, Zonal, ZonalSpan } from './styles';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import ScrollTop from '../../../components/ScrollTop';

const world = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/world.png`;
const USA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/usa.png`;
const MEX = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/mexico.png`;
const AMC = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/americaCentral.png`;
const CAR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/caribe.png`;
const AND = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/andina.png`;
const ZBR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/brasil2.png`;
const CS1 = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/coneSul.png`;
const CS2 = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/coneSul2.png`;
const EUR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/europa.png`;
const ZLA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/lusoAfricana.png`;
const ZAS = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/asia.png`;
const NFA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/africaSul.png`;
const NFZ = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/novaZelandia.png`;

const PeloMundo: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <Navbar web />
      <Content>
        <World>
          <img src={world} alt="Pelo mundo" />
          <div>
            <h1>Coordenação Internacional</h1>

            <span>
              <p>Coordenador:</p>
              {/* <h4>MARÍA EUGENIA TORO DE MEJÍA</h4> */}
              <h4>LORENA RODRIGUEZ MEDIAVILLA</h4>
            </span>

            <span>
              <p>Secretário:</p>
              {/* <h4>CARLOTA FRANCO CIFUENTES</h4> */}
              <h4>-</h4>
            </span>

            <span>
              <p>Tesoureiro:</p>
              {/* <h4>MARIA BERMEO</h4> */}
              <h4>-</h4>
            </span>

            <span>
              <p>Localização da Coordenação:</p>
              {/* <h4>CAJICÁ - CUNDINAMARCA / COLÔMBIA</h4> */}
              <h4>BIZKAIA – ESPAÑA</h4>
            </span>

            <span>
              <p>Contato:</p>
              {/* <h4>Cel. +57 310 2058140 / mautome@gmail.com</h4> */}
              <h4>+34 6913 34 007 / +34 6194 50 022 WhatsApp</h4>
            </span>
            <span>
              <p>Email:</p>
              {/* <h4>Cel. +57 310 2058140 / mautome@gmail.com</h4> */}
              <h4>
                coordinacioninternacional@tovpil.org /
                lorenarodriguezmed@gmail.com
              </h4>
            </span>
          </div>
        </World>
        {/* ESTADOS UNIDOS */}
        <Zonal>
          <h1>Coordenação Zonal Estados Unidos</h1>
          <div>
            <figure>
              <img src={USA} alt="Zonal Estados Unidos" />
            </figure>
            <span>
              <h2>Zona Estados Unidos</h2>
              <h3>Abrangência: Estados Unidos e Canadá</h3>
              <span>
                <p>Coordenador:</p>
                <h4>RUBEN e LUISA HERRERA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>ARTURO GONZALES</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>MARTHA IRENE MURO</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>MANUEL GALLARDO</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>COPPELL - TEXAS / EUA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +1 214 773 7898 - tovzoneusa@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* MÉXICO */}
        <Zonal>
          <h1>Coordenação Zonal México</h1>
          <figure>
            <img src={MEX} alt="Zonais do México" />
          </figure>
          <div>
            <span>
              <h2>Zona México Norte</h2>
              <h3>Abrangência: Norte do México</h3>
              <span>
                <p>Coordenador:</p>
                <h4>MARÍA DEL PILAR SAGAZ MORELOS</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>SARA URIBE MONTOYA</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>JOSÉ JUAN RODRIGUEZ RIOS</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>FROILAN ARMANDO CHACON BUCIO</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>QUERÉTARO – QUERÉTARO / MÉXICO</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +52 4421704244 - pisamo3tov@hotmail.com</h4>
              </span>
            </span>
            <span>
              <h2>Zona México</h2>
              <h3>Abrangência: Sul do México</h3>
              <span>
                <p>Coordenador:</p>
                <h4>MARIA DE LOURDES LICEA LOPEZ</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>YOLANDA BERENICE ROJAS SANCHEZ</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>SOFIA ESCOBAR GUTIERREZ</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>ABEL CHAVEZ REYES</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>CIDADE DO MÉXICO – MÉXICO</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +52 55 1546 0403 - tletzin18@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* América Central */}
        <Zonal>
          <h1>Coordenação Zonal América Central</h1>
          <div>
            <figure>
              <img src={AMC} alt="Zonal América Central" />
            </figure>
            <span>
              <h2>Zona América Central</h2>
              <h3>
                Abrangência: El Salvador, Costa Rica, Guatemala, Honduras,
                Nicarágua e Panamá
              </h3>
              <span>
                <p>Coordenador:</p>
                <h4>ARONETTE DIAZ PINEDA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>ANA VILMA MEJIA</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>DELMY FANTINA CORTEZ DE HUEZO</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>SAN SALVADOR / EL SALVADOR</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +503 7069 0800 - diazpineda4@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Caribe */}
        <Zonal>
          <h1>Coordenação Zonal Caribe</h1>
          <div>
            <figure>
              <img src={CAR} alt="Zonal Caribe" />
            </figure>
            <span>
              <h2>Zona Caribe</h2>
              <h3>
                Abrangência: Republica Dominicana, Cuba, Porto Rico, Trinidade Y
                Tobago
              </h3>
              <span>
                <p>Coordenador:</p>
                <h4>DIGNA CAROLINA MORROBEL</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>MARIA L. PEÑA MIRABAL</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>LUZ ELENA ORTEGA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>REPÚBLICA DOMINICANA – CARIBE</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +1(809)8036060 - carolinamm63@hotmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Andina */}
        <Zonal>
          <h1>Coordenação Zonal Andina</h1>
          <div>
            <figure>
              <img src={AND} alt="Zonal Andina" />
            </figure>
            <span>
              <h2>Zona Andina</h2>
              <h3>Abrangência: Colômbia, Equador, Peru e Venezuela</h3>
              <span>
                <p>Coordenador:</p>
                <h4>LUIS MIGUEL SOSA ÁVILA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>LUZ STELLA PACHECO SÁNCHEZ</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>GLADYS GÓMEZ DE OJEDA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>BUCARAMANGA / COLÔMBIA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +57 3105600315 - tovzonaandina@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Brasil */}
        <Zonal>
          <h1>Coordenação Zonal Brasil</h1>
          <div>
            <figure>
              <img src={ZBR} alt="Zonal Brasil" />
            </figure>
            <ZonalSpan bgcolor="#009c3b">
              <h2>Zona Brasil Oeste</h2>
              <span>
                <p>Coordenador:</p>
                <h4>SIRLEY SERRANO DA SILVEIRA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>RITA DE CASSIA DA COSTA TEJADA</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>YVONETE APARECIDA GRANADO</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>ADILSON BRUNO DA SILVEIRA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>SANTO ANDRÉ – SÃO PAULO</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +55 11 98097-0484 - zonabrasiloeste@gmail.com</h4>
              </span>
            </ZonalSpan>
          </div>
          <div>
            <ZonalSpan bgcolor="#ffdf00">
              <h2>Zona Brasil Centro</h2>

              <span>
                <p>Coordenador:</p>
                <h4>MARIA APARECIDA DE FARIA GROSSI</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>SONIA TEIXEIRA LOPES SIMÕES</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>MARIA DAS DORES BATISTA MARTINS</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>ELISANE RIBEIRO COSENZA BARBOSA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>BELO HORIZONTE – MINAS GERAIS</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +55 31 99981-0168 - zonabrasilcentro@gmail.com</h4>
              </span>
            </ZonalSpan>
            <ZonalSpan bgcolor="#0040c2">
              <h2>Zona Brasil Leste</h2>
              <span>
                <p>Coordenador:</p>
                <h4>MARIA CONSTANCIA CRISPIM MUNIZ</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>MARIA ELIZABETH CRISPIM PASCHOAL</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>LUCIA DE MARILAC DE SOUZA MORAIS</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>JOSÉ ATAÍDE DA SILVA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>CAMPINA GRANDE - PARAÍBA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +55 83 98879-2703 - zonabrasilleste3@gmail.com</h4>
              </span>
            </ZonalSpan>
          </div>
        </Zonal>
        {/* Cone Sul1 */}
        <Zonal>
          <h1>Coordenação Zonal Cone Sul 1</h1>
          <div>
            <figure>
              <img src={CS1} alt="Zonal Cone Sul 1" />
            </figure>
            <span>
              <h2>Zona Cone Sul 1</h2>
              <h3>Abrangência: Argentina e Uruguai</h3>
              <span>
                <p>Coordenador:</p>
                <h4>JULIO CESAR CABRERA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>NIDIA GARCIA</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>CARMEN FIDELINA P. DE KONOPOCKI</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>SARA EUGENIA AGUILERA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>POSADAS – MISIONES / ARGENTINA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +37 6450 8201 - juliocesarcabrera1@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Cone Sul2 */}
        <Zonal>
          <h1>Coordenação Zonal Cone Sul 2</h1>
          <div>
            <figure>
              <img src={CS2} alt="Zonal Cone Sul 2" />
            </figure>
            <span>
              <h2>Zona Cone Sul 2</h2>
              <h3>Abrangência: Bolívia, Chile e Paraguai</h3>
              <span>
                <p>Coordenador:</p>
                <h4>GLADYS DUARTE</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>PAOLA BOGGINO</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>CLAUDIA GONZALEZ</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>FRANCISCO AÑASCO</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>FERNANDO DE LA MORA / PARAGUAI</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +59 59 8145 5500 - gida_15@hotmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Europa */}
        <Zonal>
          <h1>Coordenação Zonal Europa</h1>
          <div>
            <figure>
              <img src={EUR} alt="Zonal Europa" />
            </figure>
            <span>
              <h2>Zona Europa</h2>
              <h3>
                Abrangência: Espanha, Itália, Reino Unido, Alemanha, Albânia,
                França, Suíça e Eslovênia
              </h3>
              <span>
                <p>Coordenador:</p>
                <h4>CRISTINA NAVARRO MARTÍN</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>MARGARITA JAMBRINA BALBASTRE</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>MERCEDES SANZ FORTEA</h4>
              </span>
              <span>
                <p>Material:</p>
                <h4>ALFONSO DE LA CASA BUENDÍA</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>CEUTA – ESPANHA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel. +34 6 5135 2200 - cristanavarro@hotmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Luso Africana */}
        <Zonal>
          <h1>Coordenação Zonal Luso Africana</h1>
          <div>
            <figure>
              <img src={ZLA} alt="Zonal Luso Africana" />
            </figure>
            <span>
              <h2>Zona Luso Africana</h2>
              <h3>Abrangência: Portugal, Angola e Moçambique</h3>
              <span>
                <p>Coordenador:</p>
                <h4>JOÃO e MARIANA CONDADO</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>CARLA SOFIA MAÇARICO</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>MANUELA DE JESUS PEREIRA CONDADO</h4>
              </span>

              <span>
                <p>Localização da Coordenação:</p>
                <h4>BRAGANÇA / PORTUGAL</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>351 9 3554 5631 - joncondado@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* Ásia */}
        <Zonal>
          <h1>Coordenação Zonal Ásia</h1>
          <div>
            <figure>
              <img src={ZAS} alt="Zonal Ásia" />
            </figure>
            <span>
              <h2>Zona Ásia</h2>
              <h3>Abrangência: Filipinas</h3>
              <span>
                <p>Coordenador:</p>
                <h4>DOMINGO JR e MILAGROS MENDOZA</h4>
              </span>
              <span>
                <p>Secretário:</p>
                <h4>DERRICK LARA</h4>
              </span>
              <span>
                <p>Tesoureiro:</p>
                <h4>JANICE ROQUE DE GUZMAN</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>FILIPINAS – ÁSIA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>+63 2 931 8111 - domengmilamendoza@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* NF Republica Afria do Sul */}
        <Zonal>
          <h1>Núcleo em Formação - República da África do Sul</h1>
          <div>
            <figure>
              <img src={NFA} alt="África do Sul" />
            </figure>
            <span>
              <h2>República da África do Sul</h2>
              <h3>Abrangência: República da África do Sul</h3>
              <span>
                <p>Coordenador:</p>
                <h4>ANI SCHNEIDER</h4>
              </span>
              <span>
                <p>Localização da Coordenação:</p>
                <h4>República da África do Sul</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel: +27 82 410 9366 - ani.schneider@gmail.com</h4>
              </span>
            </span>
          </div>
        </Zonal>
        {/* NF Nova Zelandia */}
        <Zonal>
          <h1>Núcleo em Formação - Nova Zelândia</h1>
          <div>
            <figure>
              <img src={NFZ} alt="Nova Zelândia" />
            </figure>
            <span>
              <h2>Nova Zelândia</h2>
              <h3>Abrangência: Nova Zelândia</h3>
              <span>
                <p>Coordenador:</p>
                <h4>GLORIA SALANOA</h4>
              </span>

              <span>
                <p>Localização da Coordenação:</p>
                <h4>AUCKLAND - NOVA ZELÂNDIA</h4>
              </span>
              <span>
                <p>Contato:</p>
                <h4>Cel: +021 0229 5303</h4>
              </span>
            </span>
          </div>
        </Zonal>
      </Content>
      <Footer />
    </Container>
  );
};
export default PeloMundo;
