import React, { useEffect, useState, useCallback, useRef } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';
import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { formatDate } from 'utils/formatDate';
import { ListContent, Message, SelectContainer, StyledTR } from './styles';

interface ComboMat {
  value: string;
  label: string;
  matgrped: string;
  matcntr: string;
}

interface LocalStorageProps {
  code?: string;
  desc?: string;
}

interface ListProps {
  matcod: string;
  matdesc: string;
  code: string;
  desc: string;
  qte: number;
  from: string;
  fromdesc: string;
  date: string;
}

const HistEnv: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [mats, setMats] = useState<ComboProps[]>([]);
  const [selectedMat, setSelectedMat] = useState(() => {
    const { code, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_HISTENV}`,
    );
    return { code, desc } || ({} as LocalStorageProps);
  });

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/histenv_list.php');
      setRawList(response.data);

      const { code }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_HISTENV}`,
      );

      code &&
        setList(
          response.data.filter((item: ListProps) => item.matcod === code),
        );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboMATS = useCallback(async () => {
    const response = await api.get('/combos/comboMATs.php');
    setMats(
      response.data.map((item: ComboMat) => ({
        value: item.value,
        label: item.label,
      })),
    );
  }, []);

  useEffect(() => {
    handlePermission(['GUI']);
    getComboMATS();
    getList();
  }, [getComboMATS, getList, handlePermission]);

  const handleMatSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('material');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedMat({ code: value, desc: text });

    setList(rawList.filter((item) => item.matcod === value));

    setLocalStorage(`${process.env.REACT_APP_HISTENV}`, {
      code: value,
      desc: text,
    });
  }, [rawList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Histórico Material Enviado</AlteredHeader>
        <SelectContainer>
          <p>Selecione o material:</p>
          <Form ref={formRef} onSubmit={() => null}>
            <SelectV2
              name="material"
              content={mats}
              initial={selectedMat.code || 'Selecione'}
              onChange={handleMatSelection}
            />
          </Form>
        </SelectContainer>
        {!selectedMat.code ? (
          <Message>Selecione um material para exibir resultados</Message>
        ) : (
          !loading && (
            <ListContent>
              {list.length === 0 ? (
                <Message>Sem registros para o material selecionado</Message>
              ) : (
                <table>
                  <thead>
                    <td>
                      <p>Data</p>
                    </td>
                    <td>
                      <p>{width >= 550 ? 'Material' : 'Mat'}</p>
                    </td>
                    <td>
                      <p>{width >= 550 ? 'Quantidade' : 'Qtd'}</p>
                    </td>
                    {user.perfil !== 'LOC' && (
                      <td>
                        <p>{width >= 550 ? 'Coordenação' : 'Coord'}</p>
                      </td>
                    )}
                    <td>
                      <p>Origem</p>
                    </td>
                    <td>
                      <p>{width >= 550 ? 'Documento' : 'Doc'}</p>
                    </td>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <>
                        {index !== 0 && index % 10 === 0 && (
                          <tr style={{ background: '#332e2e', color: '#fff' }}>
                            <td>
                              <p>Data</p>
                            </td>
                            <td>
                              <p>{width >= 550 ? 'Material' : 'Mat'}</p>
                            </td>
                            <td>
                              <p>{width >= 550 ? 'Quantidade' : 'Qtd'}</p>
                            </td>
                            {user.perfil !== 'LOC' && (
                              <td>
                                <p>{width >= 550 ? 'Coordenação' : 'Coord'}</p>
                              </td>
                            )}

                            <td>
                              <p>Origem</p>
                            </td>
                            <td>
                              <p>{width >= 550 ? 'Documento' : 'Doc'}</p>
                            </td>
                          </tr>
                        )}
                        <StyledTR paint={index}>
                          <td>
                            <p>{formatDate(item.date)}</p>
                          </td>
                          <td>
                            <p>
                              {width >= 950
                                ? `${item.matcod} - ${item.matdesc}`
                                : width >= 650
                                ? item.matdesc
                                : item.matcod}
                            </p>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <p>{item.qte}</p>
                          </td>
                          {user.perfil !== 'LOC' && (
                            <td>
                              <p>
                                {width >= 950
                                  ? `${item.code} - ${item.desc}`
                                  : width >= 650
                                  ? item.desc
                                  : item.code}
                                {/* {item.code} - {item.desc} */}
                              </p>
                            </td>
                          )}
                          <td style={{ textAlign: 'center' }}>
                            <p>{item.from}</p>
                          </td>
                          <td>
                            <p>{item.fromdesc}</p>
                          </td>
                        </StyledTR>
                      </>
                    ))}
                  </tbody>
                </table>
              )}
            </ListContent>
          )
        )}
        {/* <ListContainer></ListContainer> */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default HistEnv;
