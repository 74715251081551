import React, { useState, useEffect, useCallback, useRef } from 'react';

import { subYears, addDays, format } from 'date-fns';

import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { Container, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useChanges } from 'hooks/changes';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import { convertSpecialChars, deconvertSpecialChars } from 'utils/specialChars';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { GuiaProps } from '../main';
import { FormData } from '../insert';
import { gestcivil } from '../gestcivil';

import { Content, GridContainer, AnimatedSection } from '../insert/styles';

export interface StateProps {
  guia: GuiaProps;
}

interface GetCertProps {
  value: string;
  label: string;
  from: string;
}

const GuiasUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { mud } = useChanges();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [comboTG, setComboTG] = useState<ComboProps[]>([]);
  const [comboCERT, setComboCERT] = useState<ComboProps[]>([]);
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  const [guia] = useState<GuiaProps>(() => {
    if (location.state.guia) {
      return location.state.guia;
    }

    return {} as GuiaProps;
  });

  const comboEstCivil: ComboProps[] = gestcivil;

  const [firstUF, setFirstUF] = useState(() => {
    return guia.uf;
  });
  const [firstTG, setFirstTG] = useState(() => {
    return guia.tgcod;
  });
  const [firstESTCiv, setFirstESTCiv] = useState(() => {
    return guia.estciv;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gSex, setGSex] = useState(() => {
    if (location.state.guia) {
      return location.state.guia.sex;
    }
    return '';
  });

  const [hasState, setHasState] = useState(false);

  const [TG, setTG] = useState('');

  const [show, setShow] = useState<ShowProps>({
    title: 'Atribuir certificado',
    open: false,
    content: '',
  });

  const getComboTG = useCallback(async () => {
    const response = await api.get('/combos/comboTG.php');

    const checkform = formRef.current;

    response.data.filter((tg: ComboProps) => {
      if (tg.value === guia?.tgcod) {
        checkform?.setData({
          ...checkform,
          type: tg.value,
        });
        setFirstTG(tg.value);
      }

      return tg.value;
    });

    setTimeout(() => {
      setComboTG(
        response.data.filter((item: ComboProps) => {
          if (guia?.tgcod === 'FG') {
            return ['GA', 'FD', 'FG'].indexOf(item.value) > -1;
          }

          if (guia?.tgcod === 'GE') {
            return ['GE', 'GF'].indexOf(item.value) > -1;
          }

          if (guia?.tgcod === 'GD') {
            return ['GA', 'GB', 'GF', 'GE', 'GD'].indexOf(item.value) > -1;
          }

          if (guia?.tgcod === 'GB') {
            return ['GA', 'GF', 'GB'].indexOf(item.value) > -1;
          }

          if (guia?.tgcod === 'GP' || guia?.tgcod === 'GA') {
            return (
              ['GA', 'GP', 'GB', 'GF', 'GE', 'GD'].indexOf(item.value) > -1
            );
          }
          return null;
        }),
      );
    }, 100);
  }, [guia]);

  const getComboCERT = useCallback(async () => {
    const response = await api.get(
      `/combos/comboCERTIFICADOS.php?code=${guia?.loccod}`,
    );

    setComboCERT(response.data);
  }, [guia]);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');
    const checkform = formRef.current;
    response.data.filter((state: ComboProps) => {
      if (state.value === guia?.uf) {
        checkform?.setData({
          ...checkform,
          state: state.value,
        });
        setHasState(true);
        setFirstUF(state.value);
      }

      return state.value;
    });

    setComboEstados(response.data);
  }, [guia]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }

    // setGuia(location.state.guia);

    getComboTG();
    getUFs();

    formRef?.current?.setFieldValue('zon', guia.zoncod);
    formRef?.current?.setFieldValue('anb', guia.anbcod);
    formRef?.current?.setFieldValue('loc', guia.loccod);

    formRef?.current?.setFieldValue('estcivil', guia.estciv);
    formRef?.current?.setFieldValue('sex', guia.sex);

    setTG(guia.tgcod);

    comboEstCivil.filter((estciv: ComboProps) => {
      if (estciv.value === guia.estciv) {
        formRef.current?.setData({
          ...formRef.current,
          estciv: estciv.value,
        });
        setFirstESTCiv(estciv.value);
      }

      return estciv.value;
    });
  }, [
    getComboTG,
    getUFs,
    history,
    location.pathname,
    location.state,
    comboEstCivil,
    guia,
  ]);

  const handleTGSelect = useCallback(() => {
    const selected = formRef.current?.getFieldValue('type');
    setTG(selected);
    if (guia?.tgcod === 'FG' && selected === 'GA') {
      // setFirstTG('Selecione o tipo de Guia');
      // formRef.current?.setFieldValue('type', '');

      getComboCERT();
      setShow({
        ...show,
        open: true,
      });

      return;
    }

    if (selected.substr(0, 1) === 'F') {
      formRef.current?.setFieldValue('env', '');
      formRef.current?.setFieldValue('access', guia?.cert);
    }
  }, [guia, show, getComboCERT]);

  const handleCERTSelection = useCallback(() => {
    const cert = formRef.current?.getFieldValue('cert');

    if (cert !== '' && cert !== 'Selecione') {
      formRef.current?.setFieldValue('access', cert);
    } else {
      formRef.current?.setFieldValue('env', '');
      setTG(guia?.tgcod);
      setFirstTG(`${guia.tgcod}`);
      formRef.current?.setFieldValue('type', guia?.tgcod);
    }
    setShow({
      ...show,
      open: false,
    });
  }, [show, guia]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        form.setData({
          address: guia?.end.includes(address) ? guia?.end : address,
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setHasState(false);
      }
    }
  }, [comboEstados, guia, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          type: yup.string().notOneOf([''], ' '),
          access: yup.string().when('type', {
            is: (val) => val === 'FG' || val === 'FD',
            then: yup
              .string()
              .trim()
              .min(11, 'Quantidade mínima de caracteres não atingida (11).')
              .max(11, 'Quantidade máxima de caracteres excedida (11).'),
            otherwise: yup
              .string()
              .trim()
              .min(5, 'Quantidade mínima de caracteres não atingida (5).')
              .max(6, 'Quantidade máxima de caracteres excedida (6).'),
          }),
          mail: yup
            .string()
            .trim()
            .email('Formato inválido de email')
            .required('Este campo é obrigatório'),
          env: yup.date().when('type', {
            is: (val) => val === 'FG' || val === 'FD',
            then: yup
              .date()
              .nullable()
              .transform((curr, orig) => (orig === '' ? null : curr))
              .notRequired(),
            otherwise: yup
              .date()
              .typeError('Data inválida.')
              .min(
                new Date('1985-01-01'),
                'A data de envio deve ser posterior a 31/12/1984.',
              )
              .max(
                new Date(),
                `A data de envio deve ser anterior a ${format(
                  addDays(new Date(), 1),
                  'd/MM/yyyy',
                )}.`,
              ),
          }),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(80, 'Quantidade máxima de caracteres excedida (80).'),
          state: yup.string().notOneOf([''], ' '),
          district: yup
            .string()
            .trim()
            .min(2, 'Quantidade mínima de caracteres não atingida (2).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          resPhone: yup.lazy(() =>
            data.celPhone !== ''
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    13,
                    'Esse campo deve ser preenchido caso não possua celular.',
                  ),
          ),
          celPhone: yup.lazy(() =>
            data.resPhone !== ''
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    14,
                    'Esse campo deve ser preenchido caso não possua telefone fixo.',
                  ),
          ),
          sex: yup.string().oneOf(['F', 'M'], 'Sexo do guia é obrigatório'),
          dtnasc: yup
            .date()
            .typeError('Data inválida.')
            .min(
              new Date(subYears(new Date(), 100)),
              `Não é possível cadastrar guias com data de nascimento anterior a ${format(
                addDays(subYears(new Date(), 100), 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              new Date(subYears(new Date(), 18)),
              'O guia deve ter no mínimo 18 anos.',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dateCheck = data.env === '' ? '0000-00-00' : data.env;

        const send = {
          ...data,
          anb: guia?.anbcod,
          loc: guia?.loccod,
          gseq: parseInt(guia?.gseq, 10),
          name: convertSpecialChars(data.name),
          address: convertSpecialChars(data.address),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          altHist: guia?.rawenv !== dateCheck,
          oldType: guia?.tgcod,
        };

        await api.get(`/sgo/guias_update.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            ['FG', 'FD'].indexOf(data.type) > -1 ? 'Futuro g' : 'G'
          }uia ${data.name} alterado!`,
        });

        history.replace(location.pathname.replace('/update', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, addToast, guia, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Alteração de Guia: ${
          guia.nome.substr(0, guia.nome.indexOf(' ')) || ''
        }`}
        isResponsible
      />

      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: guia.nome,

            access: guia?.cert,
            env: guia?.rawenv !== '0000-00-00' ? guia?.rawenv : '',
            zon: guia?.zoncod,
            anb: guia?.anbcod,
            loc: guia?.loccod,
            cep: guia?.cep,

            address: guia.end && deconvertSpecialChars(guia?.end),
            district: guia.bairro && deconvertSpecialChars(guia?.bairro),
            city: guia.cid && deconvertSpecialChars(guia?.cid),
            resPhone: guia?.fres,
            celPhone: guia?.fcel,
            mail: guia?.mail,
            facebook: guia?.face,
            locnasc: guia?.locnasc,
            // dtnasc: guia?.dtnasc,
            dtnasc: guia?.rawnasc !== '0000-00-00' ? guia?.rawnasc : '',

            rg: guia?.rg,
            sex: guia.sex,
            ocupation: guia?.ocup,
            alt_lang1: guia?.idioma1,
            alt_lang2: guia?.idioma2,
          }}
        >
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Nome do Guia</p>
                <Input name="name" placeholder="Nome do Guia" mask="name" />
              </span>
              <span>
                <p>Tipo de Guia</p>
                <SelectV2
                  name="type"
                  content={comboTG}
                  initial={firstTG}
                  disabled={!mud || !!guia.funccod}
                  dropdownStyle={{ maxHeight: '150px', borderColor: '#a6a6a6' }}
                  onChange={handleTGSelect}
                />
              </span>
              <span>
                <p>N° Certificado</p>
                <Input
                  name="access"
                  placeholder={
                    TG !== 'FG' ? 'Número do certificado' : 'Número do CPF'
                  }
                  disabled
                  type="number"
                  min={1}
                />
              </span>
              <span>
                <p>Data de Envio</p>
                <Input
                  isDate
                  type={guia?.rawenv === '0000-00-00' ? 'text' : 'date'}
                  defaultValue={
                    guia?.rawenv === '0000-00-00' ? '' : guia?.rawenv
                  }
                  name="env"
                  placeholder="dd/mm/aaaa"
                  disabled={['FG', 'FD'].indexOf(TG) > -1}
                />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>

              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                />
              </span>

              <span>
                <p>Endereço</p>
                <Textarea name="address" placeholder="Endereço" />
              </span>

              {/* <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>

              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>

              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span> */}

              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" />
              </span>

              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.7} duration={1.1}>
              <span>
                <p>Telefone</p>
                <Input
                  name="resPhone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
              <span>
                <p>Celular</p>
                <Input
                  name="celPhone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-Mail" />
              </span>
              <span>
                <p>Facebook</p>
                <Input name="facebook" placeholder="https://facebook.com/" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={1.05} duration={1.1}>
              <span>
                <p>Cidade Natal</p>
                <Input name="locnasc" placeholder="Local de nascimento" />
              </span>
              <span>
                <p>Data de Nascimento</p>
                <Input
                  type={guia?.rawnasc === '0000-00-00' ? 'text' : 'date'}
                  defaultValue={
                    guia?.rawnasc === '0000-00-00' ? '' : guia?.rawnasc
                  }
                  name="dtnasc"
                  isDate={guia?.rawnasc === '0000-00-00'}
                  placeholder="dd/mm/aaaa"
                />
              </span>
              <RadioContainer
                title="Sexo"
                name="sex"
                selected={gSex}
                itemsStyle={{ justifyContent: 'flex-start' }}
                content={[
                  {
                    id: 'gM',
                    value: 'M',
                    label: 'Masculino',
                  },
                  {
                    id: 'gF',
                    value: 'F',
                    label: 'Feminino',
                  },
                ]}
              />
              <span style={{ marginTop: '10px' }}>
                <p>RG do Guia</p>
                <Input name="rg" placeholder="N° RG" />
              </span>
              <span style={{ marginTop: '10px' }}>
                <p>Estado Civil</p>
                <SelectV2
                  name="estcivil"
                  initial={firstESTCiv}
                  // initial={firstUF}
                  content={comboEstCivil}
                  // disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>
              <span>
                <p>Profissão</p>
                <Input name="ocupation" placeholder="Profissão" />
              </span>
              <span>
                <p>Idioma 2</p>
                <Input name="alt_lang1" placeholder="Idioma" />
              </span>
              <span>
                <p>Idioma 3</p>
                <Input name="alt_lang2" placeholder="Idioma" />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>

          <S.Container scroll="paper" maxWidth={false} open={show.open}>
            <S.Title>
              <h2>{show.title}</h2>
            </S.Title>
            <S.Content>
              <div style={{ height: '250px' }}>
                <h3
                  style={{ width: '100%', color: '#8A0002', fontSize: '14px' }}
                >
                  Selecione o certificado a ser atribuído:
                </h3>
                <SelectV2
                  name="cert"
                  content={comboCERT}
                  containerStyle={{
                    borderColor: '#a6a6a6',
                    width: '250px',
                    maxWidth: '250px',
                  }}
                  dropdownStyle={{ maxWidth: '250px' }}
                />
              </div>
            </S.Content>
            <S.Actions>
              <S.Confirm type="button" onClick={handleCERTSelection}>
                Atribuir
              </S.Confirm>
            </S.Actions>
          </S.Container>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GuiasUpdate;
