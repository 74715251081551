import React, { useState, useCallback, useRef, useEffect } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import { useSpring, animated } from 'react-spring';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import { Link, useLocation } from 'react-router-dom';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FaRegFileExcel, FaRegListAlt } from 'react-icons/fa';
import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import api from 'services/api';
import { Menu, AnimatedDiv, PrintButton } from './styles';

const CntrNumMenu: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading] = useState(false);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();

  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [zons, setZons] = useState<ComboProps[]>([]);

  const [show, setShow] = useState<ShowProps>({
    title: 'Impressão',
    open: false,
    content: '',
  });

  const [selectShow, setSelectShow] = useState<ShowProps>({
    title: 'Selecione a impressão',
    open: false,
    content: '',
  });

  const [printCoord, setPrintCoord] = useState('');

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setNacs([
      { value: user.zoncod, label: user.zondesc },
      ...response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    ]);
  }, [user.zoncod, user.zondesc]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setZons(response.data);
  }, []);

  useEffect(() => {
    handlePermission(['GUI']);
    getComboANB();
    getComboZON();
  }, [handlePermission, getComboANB, getComboZON]);

  const handleDownload = useCallback(async () => {
    try {
      setShow((state) => ({ ...state, open: true }));
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      if (['LOC', 'NAC'].indexOf(user.perfil) > -1) {
        const link = document.querySelector<HTMLAnchorElement>(`a[id=cmFIXED]`);
        if (link) {
          link.click();
        }
      }

      if (['ZON', 'INT'].indexOf(user.perfil) > -1) {
        setSelectShow((state) => ({ ...state, open: false }));
        setPrintCoord('');
        const anotherLink = document.querySelector<HTMLAnchorElement>(
          `a[id=cmMUTABLE]`,
        );
        if (anotherLink) {
          anotherLink.click();
        }
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, user.perfil]);

  const handleCertDownload = useCallback(async () => {
    try {
      setShow((state) => ({ ...state, open: true }));
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      if (['LOC', 'NAC'].indexOf(user.perfil) > -1) {
        const link = document.querySelector<HTMLAnchorElement>(
          `a[id=cmCERTFIXED]`,
        );
        if (link) {
          link.click();
        }
      }

      if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
        setSelectShow((state) => ({ ...state, open: false }));
        setPrintCoord('');
        const anotherLink = document.querySelector<HTMLAnchorElement>(
          `a[id=cmCERTMUTABLE]`,
        );
        if (anotherLink) {
          anotherLink.click();
        }
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, user.perfil]);

  const handleSelection = useCallback(() => {
    setPrintCoord(formRef.current?.getFieldValue('coord'));
  }, []);

  const finishPrint = useCallback(async () => {
    setPrintCoord('');
    setSelectShow((state) => ({ ...state, open: false }));
  }, []);

  const anchor = useSpring({
    display: 'none',
  });

  const styledPrint = useSpring({
    opacity: printCoord !== '' ? 1 : 0,
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Menu de Materiais Numerados</AlteredHeader>
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>Materiais Numerados</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.35}>
            <Link to={`${pathname.replace('/menu', '/localizar')}`}>
              <FaRegListAlt size={30} />
              <h3>Localização de Numerados</h3>
            </Link>
          </AnimatedDiv>
          {['NAC', 'ZON'].indexOf(user.perfil) > -1 && (
            <AnimatedDiv duration={1.1} delay={0.7}>
              <Link to={`${pathname.replace('/menu', '/transferencia')}`}>
                <FaRegListAlt size={30} />
                <h3>Transferir Numerado</h3>
              </Link>
            </AnimatedDiv>
          )}
          {user.perfil === 'ZON' && (
            <AnimatedDiv duration={1.1} delay={1.05}>
              <Link to={`${pathname.replace('/menu', '/fora_de_circulacao')}`}>
                <FaRegListAlt size={30} />
                <h3>Fora de Circulação</h3>
              </Link>
            </AnimatedDiv>
          )}
          <AnimatedDiv
            duration={1.1}
            delay={
              ['LOC', 'INT'].indexOf(user.perfil) > -1
                ? 0.7
                : user.perfil === 'NAC'
                ? 1.05
                : 1.4
            }
          >
            <button
              type="button"
              onClick={
                ['LOC', 'NAC'].indexOf(user.perfil) > -1
                  ? handleDownload
                  : () => {
                      setSelectShow((state) => ({
                        ...state,
                        open: true,
                        content: (
                          <Form
                            ref={formRef}
                            onSubmit={handleDownload}
                            style={{ height: '260px' }}
                          >
                            <p>Selecione a coordenação</p>

                            <SelectV2
                              name="coord"
                              content={user.perfil === 'ZON' ? nacs : zons}
                              onChange={handleSelection}
                              initial="Selecione"
                            />

                            <PrintButton style={styledPrint} type="submit">
                              Gerar XLS
                            </PrintButton>
                          </Form>
                        ),
                      }));
                    }
              }
            >
              <FaRegFileExcel size={30} />
              <h3>Planilha de Manuais</h3>
            </button>
          </AnimatedDiv>
          <AnimatedDiv
            duration={1.1}
            delay={
              ['LOC', 'INT'].indexOf(user.perfil) > -1
                ? 1.05
                : user.perfil === 'NAC'
                ? 1.4
                : 1.75
            }
          >
            <button
              type="button"
              onClick={
                ['LOC', 'NAC'].indexOf(user.perfil) > -1
                  ? handleCertDownload
                  : () => {
                      setSelectShow((state) => ({
                        ...state,
                        open: true,
                        content: (
                          <Form
                            ref={formRef}
                            onSubmit={handleCertDownload}
                            style={{ height: '260px' }}
                          >
                            <p>Selecione a coordenação</p>

                            <SelectV2
                              name="coord"
                              content={user.perfil === 'ZON' ? nacs : zons}
                              onChange={handleSelection}
                              initial="Selecione"
                            />

                            <PrintButton style={styledPrint} type="submit">
                              Gerar XLS
                            </PrintButton>
                          </Form>
                        ),
                      }));
                    }
              }
            >
              <FaRegFileExcel size={30} />
              <h3>Planilha de Certificados</h3>
            </button>
          </AnimatedDiv>

          <animated.a
            style={anchor}
            id="cmFIXED"
            href={`${
              process.env.REACT_APP_API
            }/sgo/xls_materiais_numerados.php?data=${JSON.stringify({
              code: user.perfil === 'LOC' ? user.loccod : user.anbc,
              type: user.perfil,
              token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
            })}`}
            rel="noreferrer noopener"
            target="_top"
          >
            CM LOC
          </animated.a>
          <animated.a
            style={anchor}
            id="cmCERTFIXED"
            href={`${
              process.env.REACT_APP_API
            }/sgo/xls_certificados.php?data=${JSON.stringify({
              code:
                user.perfil === 'LOC'
                  ? user.loccod
                  : user.perfil === 'NAC'
                  ? user.anbc
                  : user.zoncod,
              type: user.perfil,
              token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
            })}`}
            rel="noreferrer noopener"
            target="_top"
          >
            CM LOC
          </animated.a>
          <animated.a
            style={anchor}
            id="cmMUTABLE"
            href={`${
              process.env.REACT_APP_API
            }/sgo/xls_materiais_numerados.php?data=${JSON.stringify({
              code: printCoord,
              type:
                user.perfil === 'INT'
                  ? 'ZON'
                  : printCoord === user.zoncod
                  ? user.perfil
                  : 'NAC',
              token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
            })}`}
            rel="noreferrer noopener"
            target="_top"
          >
            CM LOC
          </animated.a>
          <animated.a
            style={anchor}
            id="cmCERTMUTABLE"
            href={`${
              process.env.REACT_APP_API
            }/sgo/xls_certificados.php?data=${JSON.stringify({
              code: printCoord,
              type:
                user.perfil === 'INT'
                  ? 'ZON'
                  : printCoord === user.zoncod
                  ? user.perfil
                  : 'NAC',
              token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
            })}`}
            rel="noreferrer noopener"
            target="_top"
          >
            CM LOC
          </animated.a>
        </Menu>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <S.Container scroll="paper" maxWidth={false} open={selectShow.open}>
        <S.Title>
          <h2>{selectShow.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{selectShow.content}</S.Content>
        <S.Actions>
          <S.Confirm type="button" onClick={finishPrint}>
            Fechar
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default CntrNumMenu;
