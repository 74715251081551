const arte = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/ArteDeSerFeliz.png`;
const forcas = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/AsForcasDaDecadencia.png`;
const audiosilencio = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/AudioLivroSilencioDeMaria.png`;
const casamento = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/CasamentoFeliz.png`;
const encontro = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/EncontroManualDeOracao.png`;
const irmao = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/IrmaoDeAssis.png`;
const itinerario = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/ItinerarioRumoDeus.png`;
const rosto = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/MostraMeTeuRosto.png`;
const pobre = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/PobreDeNazare.png`;
const rosa = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/RosaEoFogo.png`;
const salmos = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/SalmosParaVida.png`;
const sentido = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/SentidoDaVida.png`;
const silencio = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/SilencioDeMaria.png`;
const sofrimento = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/SofrimentoPaz.png`;
const suba = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/SubaComigo.png`;
const trans = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/Transfiguracao.png`;
const aventura = `${process.env.REACT_APP_ASSETS_DIR}/freiBooks/UmaAventuraDoEspirito.png`;

export interface BookContent {
  title: string;
  content: string;
  image: string;
  published: string;
}

export const books: BookContent[] = [
  {
    title: 'Arte de ser Feliz',
    content:
      'Escrito em linguagem simples, direta e de fácil leitura, com orientações práticas para recuperar a paz interior, o sentido da vida e a alegria de viver, este livro é uma síntese da obra “Sofrimento e Paz”, também escrita por frei Ignacio Larrañaga. Nesta publicação, o autor não pretende o sonho impossível de eliminar por completo as penas da vida, mas mitigá-las, arrancar alguns espinhos, sarar algumas feridas, enxugar muitas lágrimas, para que o leitor possa sentar-se, por fim, à sombra do arvoredo e respirar, dormir, sonhar.',

    image: arte,
    published: 'Escrito em 2002 (ed. Paulinas)',
  },
  {
    title: 'A Rosa e o Fogo',
    content:
      'Neste livro, frei Ignacio Larrañaga faz um relato autobiográfico, no qual testemunha sua experiência de Deus. Partindo de sua entrada no seminário, relata a decepção com os estudos de Teologia, a leitura apaixonada dos clássicos humanistas, suas experiências noturnas de oração, sua paixão pela música, o trabalho como organista e, acima de tudo, a experiência relâmpago com o amor de Deus (uma gratuidade infusa extraordinária). O autor narra também sua vida como missionário no Chile e o período do Concílio Vaticano II (1962-1965) com suas lutas por renovação. A “Rosa e o Fogo” fará o leitor ter dificuldade em se afastar antes de virada a última página, pois é um relato de sinceridade e, sobretudo, de amor.',
    image: rosa,
    published: 'Escrito em 1997 (ed. Paulinas)',
  },
  {
    title: 'As Forças da Decadência',
    content:
      'Seguindo a proposta do livro “Sofrimento e Paz”, o autor aprofunda-se naquelas forças que arrastam o homem para baixo, como a inutilidade, a impotência, o fracasso, a solidão, a enfermidade, a velhice e a morte. Conforme seu método, primeiramente, frei Ignacio analisa a fundo cada fenômeno e, prontamente, nos entrega exercícios práticos e orientações de vida para confrontarmos, com elegância, a decadência inevitável da vida.',
    image: forcas,
    published: 'Escrito em 2004 (ed. Paulinas)',
  },
  {
    title: 'Encontro Manual de Oração',
    content:
      'Este vade-mécum é um manual de oração, em que não falta nem sobra nada. Contém grande variedade de orações para qualquer circunstância ou emergência da vida. Explica as diversas modalidades de oração para se relacionar com Deus, bem como oferece sugestões de textos bíblicos e orientações pedagógicas para a atividade oracional. Em suma, trata-se de um manual breve e completo para os que empreendem a ascensão para Deus.',
    image: encontro,
    published: 'Escrito em 1984 (ed. Loyola)',
  },
  {
    title: 'Itinerario rumo a Deus',
    content:
      'Através dos quatro capítulos deste livro: (1) princípios e constantes da oração, (2) purificação e ascensão penitencial, (3) rumo ao encontro, (4) oração e vida, Ignacio Larrañaga descreve-nos as diversas fases do itinerário rumo a Deus. Nesse caminho de vida espiritual, as primeiras etapas são iniciativas da alma, mas, pouco a pouco, o próprio Deus vai tomando a frente, conduzindo-a até as últimas alturas da espiritualidade.',
    image: itinerario,
    published: 'Escrito em 1995 (ed. Paulus)',
  },
  {
    title: 'Mostra-me Teu Rosto',
    content:
      'Esta obra oferece uma eficaz colaboração aos que querem iniciar ou recuperar a experiência de Deus, bem como aos que desejam avançar no mistério insondável de Deus. Mostra todo o itinerário até Deus, desde os primeiros passos até as alturas da contemplação. Aborda e analisa as etapas e as dificuldades que surgem nesta caminhada: dispersão e concentração, o silêncio de Deus e a certeza da fé, as provas purificadoras, a devoção e a aridez, as distrações, a secura e a consolação, o silêncio interior e os meios para consegui-lo. Enfim, toda a caminhada desconcertante da graça até a transformação cristificante. “Mostra-me o Teu Rosto” está escrito em estilo direto e vivo, com clareza e profundidade, inspiração bíblica e existencial. É um livro nascido da vida. Para qualquer cristão, oferece uma ajuda prática e inestimável.',
    image: rosto,
    published: 'Escrito em 1974 (ed. Paulinas)',
  },
  {
    title: 'O Casamento Feliz',
    content:
      'Precioso e prático livro sobre a vida conjugal. O autor analisa e dá as pistas para a solução de quaisquer emergências ou situações que possam se originar de uma longa convivência de um casal, como os problemas de dominação, dependência, adaptação, separação, divórcio, ciúmes, sexualidade, estima, desencanto, comunicação.',
    image: casamento,
    published: 'Escrito em 2000 (ed. Paulus)',
  },
  {
    title: 'Irmão de Assis',
    content:
      'É com muito agrado que se lê este livro encantador sobre o Pobre de Assis. É uma história revivida, uma biografia recriada, uma memória viva de um Francisco de Assis que passou pela alma do autor. Como resultado, encontramo-nos com um ideal de vida, um código de felicidade diametralmente oposto ao ideal hedonista da sociedade de consumo.',
    image: irmao,
    published: 'Escrito em 1980 (ed. Paulinas)',
  },
  {
    title: 'PobreDeNazare',
    content:
      'Frei Ignacio Larrañaga penetra nos abismos insondáveis do mistério de Jesus Cristo, para captar, ali, algo de sua riqueza inescrutável, a fim de transmiti-la aos leitores. Mas por não ser possível essa transmissão com palavras comuns ou a precisão gramatical, o autor utiliza, com frequência, a evocação, a sugestão da linguagem figurada, deixando no ar um “não sei quê” que “diz” muito mais do que se entende.',
    image: pobre,
    published: 'Escrito em 1990 (ed. Loyola)',
  },
  {
    title: 'SentidoDaVida',
    content:
      'O sentido da vida é aquele valor que dá valor a todos os demais valores, para estar em harmonia consigo mesmo e com os outros. Dia a dia, meditando sobre a família, o amor, a amizade, a compaixão e a misericórdia, o leitor irá aproximando-se progressivamente da paz interior. O autor conduz o leitor de surpresa em surpresa, com o objetivo de que, paulatinamente, ele vá adquirindo um conjunto de intuições e convicções que iluminem sua vida com uma luz a que frei Ignacio denomina "o Sentido da Vida".',
    image: sentido,
    published: 'Escrito em 1998 (ed. Paulinas)',
  },
  {
    title: 'O Silêncio de Maria',
    content:
      'Trata-se de uma contemplação original sobre Maria, tal como ela aparece nos Evangelhos: peregrina da fé e serva do Senhor, senhora de si mesma, mulher cheia de fortaleza e elegância, possuidora de uma estabilidade emocional invejável e admirável.',
    image: silencio,
    published: 'Escrito em 1976 (ed. Paulinas)',
  },
  {
    title: 'O Silêncio de Maria - áudio livro',
    content:
      'Delicada, concentrada, silenciosa, assim frei Ignacio Larrañaga sintetiza a personalidade da jovem escolhida para ser a Mãe de Deus e de toda a humanidade. O autor nos convida a ir ao encontro de Maria, caminhando sobre terra firme, isto é, a partir de uma investigação histórica de sua intimidade, retratada nos Evangelhos. Enquanto avança na interpretação das passagens evangélicas, o leitor é levado a uma profunda reflexão sobre o silêncio de Maria, sua missão e sobre seu infinito amor. Neste áudio-livro, o estilo sincero e transparente de frei Ignacio Larrañaga está envolto na beleza e na poesia da Mãe do silêncio e da humildade que, segundo ele, vive perdida e encontrada no mar sem fim do mistério do Senhor.',
    image: audiosilencio,
    published: '(ed. Paulinas)',
  },
  {
    title: 'Salmos para a Vida',
    content:
      'Expressando suas vivências e intuições de modo meditativo, frei Ignacio criou um estilo que procura fazer dos Salmos um diálogo entre Deus e o homem, uma novidade, uma redescoberta da vida. Pelas páginas deste livro, mergulhamos no interior dos Salmos, contagiamo-nos de vida para, depois, sairmos, pelo mundo, com as mãos cheias de riqueza e novidade.',
    image: salmos,
    published: 'Escrito em 1986 (ed. Vozes)',
  },
  {
    title: 'Sofrimento e Paz',
    content:
      'O sofrimento é uma silhueta negra e persistente que sobrevém ao homem de toda idade, classe, cultura e religião. O autor, após as devidas análises e diagnósticos, propõe métodos, exercícios práticos, orientações de vida para que o leitor, por si mesmo, possa eliminar ou amenizar qualquer sofrimento, sobretudo na linha das angústias, obsessões, depressões, tristezas, ansiedades e medos.',
    image: sofrimento,
    published: 'Escrito em 1984 (ed. Vozes)',
  },
  {
    title: 'Suba Comigo',
    content:
      'Frei Ignacio começa por analisar o mistério radical do ser humano: solidão e relação, necessidade de ser ele mesmo e necessidade de ser para os demais. Em seguida, trata de solucionar os obstáculos que surgem nas relações humanas: apropriações, agressões emocionais, megalomanias, egoísmos de todo gênero. Por fim, acaba estudando, exaustivamente, as exigências práticas das relações interpessoais: respeitar-se, adaptar-se, perdoar-se, aceitar-se, comunicar-se, acolher, dialogar...',
    image: suba,
    published: 'Escrito em 1978 (ed. Paulinas)',
  },
  {
    title: 'Transfiguração',
    content:
      'Um livro de bolso, pérola preciosa de caráter eminentemente prático. Trata-se de uma pedagogia experimentalmente progressiva para uma santificação cristificante, individual e coletiva.',
    image: trans,
    published: 'Escrito em 1996 (ed. Paulinas)',
  },
  {
    title: 'Uma Aventura do Espírito: Vida e obra de Ignacio Larrañaga',
    content:
      'Ninguém melhor que frei Camilo Luquín, capuchinho, tão próximo de frei Ignacio Larrañaga, para recordar sua vida e suas principais obras. Depois de uma apresentação da vida de Ignacio Larrañaga, sem omitir os períodos difíceis de incompreensão e de grandes dificuldades, frei Luquín acompanha as circunstâncias que estiveram na origem dos principais escritos de frei Ignacio, oferecendo-nos preciosas indicações para sua interpretação, no contexto em que tiveram origem. Particular atenção é dada aos Encontros de Experiência de Deus (EED), a partir de 1974, e às Oficinas de Oração e Vida (OOV), ou Talleres de Oración y Vida (TOV), a partir de 1984, inseparáveis do nome de Ignacio Larrañaga.',
    image: aventura,
    published: 'Escrito por frei Camilo Luquín (ed. Paulinas)',
  },
];
