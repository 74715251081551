import React, { useRef, useCallback, useState, useEffect } from 'react';

import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '../../../../../styles/sgo_wrappers';
import { AnimatedContent } from '../../../../../styles/animated';

import SGOHeader from '../../../../../components/SGOHeader';
import ScrollTop from '../../../../../components/ScrollTop';
import SGONavbar from '../../../../../components/SGONavbar';
import SGOFooter from '../../../../../components/SGOFooter';
import Loading from '../../../../../components/Loading';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';

import { useCredentials } from '../../../../../hooks/credentials';
import { useToast } from '../../../../../hooks/toast';

import getValidationErrors from '../../../../../utils/getValidationErrors';
import { convertSpecialChars } from '../../../../../utils/specialChars';
import api from '../../../../../services/api';

interface FormData {
  code: string;
  description: string;
}

interface LocationProps {
  [key: string]: string;
}

interface UpdateParamsProps {
  code: string;
  desc: string;
}

const GruposUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const { code, desc } = location.state;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!code || !desc) {
      history.replace(location.pathname.replace('/update', ''));
    }
  }, [history, location.pathname, code, desc]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          description: yup
            .string()
            .trim()
            .required('Descrição obrigatória')
            .min(3, 'Quantidade mínima de caracteres não atingida (3)')
            .max(50, 'Quantidade máxima de caracteres excedida (50)'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.get(
          `/sgo/grupo_update.php?code=${data.code}&desc=${convertSpecialChars(
            data.description,
          )}`,
        );

        history.goBack();

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Grupo de material alterado com sucesso!',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, history, addToast],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração Grupo de Material" />
      <AnimatedContent direction="right" needmargin={75}>
        <Form
          ref={formRef}
          initialData={{
            code,
            description: desc,
          }}
          onSubmit={handleSubmit}
        >
          <p>Código</p>
          <Input name="code" placeholder="Código" disabled />
          <p style={{ marginTop: '10px' }}>Descrição</p>
          <Input name="description" placeholder="Descrição" />
          <Button bgcolor="#00802b" type="submit">
            Alterar
          </Button>
        </Form>
      </AnimatedContent>
      <SGOFooter />
    </Container>
  );
};
export default GruposUpdate;
