import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import * as yup from 'yup';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { formatNumber } from 'utils/calcTotal';
import {
  formatDate,
  handleTimeZone,
  minimumAllowedDate,
  endOfDay,
} from 'utils/formatDate';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FaPencilAlt, FaUndo, FaMoneyBillAlt } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import InputDialog from 'components/InputDialog';
import { addDays, subDays } from 'date-fns';
import getValidationErrors from 'utils/getValidationErrors';
import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';

import {
  FinHeader,
  FinContent,
  FinItem,
  FinContentHeader,
  Data,
  Anterior,
  Parcela,
  Saldo,
  Track,
  Wrapper,
  IncButton,
  UpdateContainer,
} from './styles';

interface StateProps {
  finHeader: {
    num: number;
    cod: string;
    desc: string;
    valor: number;
    date: string;
    locdesc: string;
  };
}

interface ListProps {
  seq: number;
  dtmov: string;
  ant: number;
  parc: number;
  saldo: number;
  cecpr: string;
  cftrack: string;
}

interface FormData {
  seq: number;
  cenum: number;
  name: string;
  value: number;
  rawDate: string;
  maxDate: string;
  prevDate: string;
}

const FinCEDetail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();

  const [show, setShow] = useState<ShowProps>({
    title: 'Conteúdo do Acerto',
    open: false,
    content: '',
  });

  const [today] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
  );

  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [finHeader] = useState(() => {
    return location.state.finHeader || ({} as StateProps);
  });

  const [dateCompare, setDateCompare] = useState(true);

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({
    open: false,
    content: '',
    values: {},
  });

  const [list, setList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        num: finHeader.num,
        cod: finHeader.cod,
      };
      const response = await api.get(
        `/sgo/fin_ce_detail.php?data=${JSON.stringify(data)}`,
      );
      setList(response.data);
    } catch (err) {
      errorHandling(err);
    }
    setLoading(false);
  }, [errorHandling, finHeader.cod, finHeader.num]);

  useEffect(() => {
    if (!location.state.finHeader) {
      history.replace(location.pathname.replace('/detail', ''));
    }

    getList();
  }, [getList, history, location.pathname, location.state]);

  const handleUpdateMovDate = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        // const parsedMinDate = new Date(parseISO(data.prevDate));

        let parsedMinDate = minimumAllowedDate(data.prevDate, true);
        if (
          parsedMinDate.getUTCDate() ===
          handleTimeZone(data.prevDate).getUTCDate()
        ) {
          parsedMinDate = addDays(parsedMinDate, 1);
        }

        // const parsedMaxDate = new Date(parseISO(data.maxDate));
        const parsedMaxDate = endOfDay(data.maxDate);

        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .min(
              parsedMinDate,
              `O lançamento deve ser posterior a ${formatDate(
                subDays(parsedMinDate, 1).toISOString(),
              )}`,
            )
            .max(
              parsedMaxDate,
              `Data limite excedida (${formatDate(
                new Date(parsedMaxDate).toISOString(),
              )})`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setUpdateDiag((state) => ({ ...state, open: false }));

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/fin_ce_date_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro alterado.',
        });
        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, getList],
  );

  const handleInputChange = useCallback(() => {
    const input1 = formRef.current?.getFieldValue('date');

    const date =
      (updateDiag.values?.initDate as string) || new Date().toDateString();

    setDateCompare(
      handleTimeZone(input1).getTime() === handleTimeZone(date).getTime(),
    );
  }, [updateDiag.values]);

  const handleCancel = useCallback(() => {
    setDateCompare(true);
    setUpdateDiag((state) => ({ ...state, open: false }));
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />

      {list.length > 0 &&
        list[list.length - 1].saldo > 0 &&
        width >= 890 &&
        user.perfil === 'LOC' && (
          <IncludeButton
            options={{
              num: finHeader.num,
              cod: finHeader.cod,
              desc: finHeader.desc,
              valor: finHeader.valor,
              date: finHeader.date,
            }}
          />
        )}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Acompanhamento Financeiro CE ${finHeader.num}`}
        isResponsible
      />
      <Content>
        {user.perfil === 'NAC' && (
          <AlteredHeader>{finHeader.locdesc}</AlteredHeader>
        )}

        <FinHeader>
          <span>
            <p>
              Guia:&nbsp;<strong>{finHeader.desc}</strong>
            </p>
          </span>
          <span>
            <p>
              Valor:&nbsp;<strong>R$ {formatNumber(finHeader.valor)}</strong>
            </p>
          </span>
          <span>
            <p>
              Data:&nbsp;
              <strong>{formatDate(finHeader.date)}</strong>
            </p>
          </span>
        </FinHeader>

        {list.length > 0 &&
          list[list.length - 1].saldo > 0 &&
          user.perfil === 'LOC' && (
            <IncButton
              to={{
                pathname: `${location.pathname}/insert`,
                state: {
                  options: {
                    num: finHeader.num,
                    cod: finHeader.cod,
                    desc: finHeader.desc,
                    valor: finHeader.valor,
                    date: finHeader.date,
                  },
                },
              }}
            >
              Pagamento da CE
            </IncButton>
          )}

        <FinContent>
          <Wrapper>
            <FinContentHeader>
              <Data>
                <p>Data</p>
              </Data>
              <Anterior>
                <p>S{width >= 581 ? 'aldo' : '.'} Anterior</p>
              </Anterior>
              <Parcela>
                <p>Parcela</p>
              </Parcela>
              <Track>
                <p>Itens</p>
              </Track>
              <Saldo>
                <p>Saldo</p>
              </Saldo>
            </FinContentHeader>

            {list.map((item, index) => (
              <FinItem paint={index} key={item.seq}>
                <Data>
                  <p>{formatDate(item.dtmov)}</p>
                  {index > 0 && user.perfil === 'LOC' ? (
                    <button
                      type="button"
                      onClick={() =>
                        setUpdateDiag((state) => ({
                          ...state,
                          open: true,
                          values: {
                            value: item.parc,
                            seq: item.seq,
                            initDate: item.dtmov,
                            rawDate: item.dtmov,
                            maxDate:
                              index === list.length - 1
                                ? today
                                : list[index + 1].dtmov,
                            prevDate: list[index - 1].dtmov,
                          },
                        }))
                      }
                    >
                      <FaPencilAlt />
                    </button>
                  ) : (
                    ''
                  )}
                </Data>
                <Anterior>
                  <p>R$ {formatNumber(item.ant)}</p>
                </Anterior>
                <Parcela>
                  <p>R$ {formatNumber(item.parc)}</p>
                </Parcela>

                <Track>
                  {index > 0 ? (
                    <button
                      type="button"
                      onClick={() =>
                        setShow((state) => ({
                          ...state,
                          open: true,
                          content: (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                              }}
                            >
                              {item.cftrack.length === 0 ? (
                                <p>Registro não disponível.</p>
                              ) : (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.cftrack.replace(/£/g, '<br>'),
                                  }}
                                />
                              )}
                            </div>
                          ),
                        }))
                      }
                    >
                      {item.cecpr === 'PAGAMENTO' ? (
                        <FaMoneyBillAlt color="#1c541c" />
                      ) : (
                        <FaUndo color="#c53030" />
                      )}
                    </button>
                  ) : (
                    '-'
                  )}
                </Track>
                <Saldo>
                  <p>R$ {formatNumber(item.saldo)}</p>
                </Saldo>
              </FinItem>
            ))}
          </Wrapper>
        </FinContent>
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              Fechar
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <Form
            ref={formRef}
            onSubmit={handleUpdateMovDate}
            initialData={{
              seq: updateDiag.values?.seq,
              value: updateDiag.values?.value,
              cenum: finHeader.num,
              name: finHeader.desc,
              prevDate: updateDiag.values?.prevDate,
              maxDate: updateDiag.values?.maxDate,
              rawDate: updateDiag.values?.rawDate,
              date: updateDiag.values?.rawDate,
              initDate: updateDiag.values?.initDate,
            }}
          >
            <U.Title>
              <h2>Alteração de Data do Movimento</h2>
            </U.Title>
            <U.Content>
              <InputDialog name="seq" isHidden />
              <InputDialog name="cenum" isHidden />
              <InputDialog name="name" isHidden />
              <InputDialog name="value" isHidden />
              <InputDialog name="rawDate" isHidden type="date" />
              <InputDialog name="initDate" isHidden type="date" />
              <UpdateContainer>
                <span>
                  <p>Data mínima permitida:</p>
                  <InputDialog name="prevDate" type="date" disabled />
                </span>
                <span>
                  <p>Data máxima permitida:</p>
                  <InputDialog name="maxDate" type="date" disabled />
                </span>
                <span>
                  <p>Nova data deste movimento:</p>
                  <InputDialog
                    name="date"
                    type="date"
                    onChange={handleInputChange}
                  />
                </span>
              </UpdateContainer>
            </U.Content>
            <U.Actions>
              <U.Cancel type="button" onClick={handleCancel}>
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit" disabled={dateCompare}>
                Confirmar
              </U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FinCEDetail;
