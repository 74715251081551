import React, { useCallback } from 'react';

// import { FaGhost } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Container, Content, Button404 } from './styles';
// import { Container, Content, GhostContainer, Button404 } from './styles';

export const Page404: React.FC = () => {
  const history = useHistory();
  const handleGoBack = useCallback(() => {
    // history.replace('/dashboard');
    // history.replace(`/`);
    history.goBack();
  }, [history]);

  return (
    <Container>
      <Content>
        <h1>[ Erro 404 ]</h1>
        <h2>A página que você procura não existe...</h2>
        <h4>...e tudo bem, isso pode acontecer</h4>
        {/* <GhostContainer>
          <FaGhost />
        </GhostContainer> */}
        <Button404 onClick={handleGoBack}>Voltar para TOV Brasil</Button404>
      </Content>
    </Container>
  );
};

export default Page404;
