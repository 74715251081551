import styled from 'styled-components';
import { shade } from 'polished';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface IncludeProps {
  title?: string;
  open: boolean;
  content?: string | JSX.Element;
  values?: {
    [key: string]: string | number;
  };
}

export const Container = styled(Dialog)`
  margin: 0 auto;
  max-width: 800px;
`;

export const Title = styled(DialogTitle)`
  background: #1c541c;

  color: #efefef;
  font-family: 'Roboto Slab', serif;
  h2,
  h4 {
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 24px;
  }
  h4 {
    font-size: 16px;
  }
`;

export const Content = styled(DialogContent)`
  background: #e6e6e6;

  display: flex;
  align-items: center;
  flex-direction: column;

  * {
    font-size: 16px;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: #174517;

    &:hover {
      background: #1c541c;
    }
  }

  @media screen and (max-width: 800px) {
  }
`;

export const ContentText = styled(DialogContentText)``;

export const Actions = styled(DialogActions)`
  background: #e6e6e6;
  button {
    font-size: 16px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;

    font-family: 'Roboto Slab', serif;

    transition: background-color 0.35s ease, color 0.35s ease;
  }
`;

export const Cancel = styled.button`
  border: 2px solid #c53030;
  background: #c53030;
  color: #efefef;

  &:hover,
  &:focus {
    background-color: ${shade(0.3, '#c53030')};
    color: #fff;
  }
`;

export const Confirm = styled.button`
  border: 2px solid #00802b;
  background: #00802b;
  color: #efefef;

  &:hover,
  &:focus {
    background-color: ${shade(0.3, '#00802b')};
    color: #fff;
  }
`;
