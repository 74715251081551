import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import Checkbox from 'components/Checkbox';
import RadioContainer from 'components/RadioContainer';
import Textarea from 'components/Textarea';
import Input from 'components/Input';
import Button from 'components/Button';

import ScrollTop from 'components/ScrollTop';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import api from 'services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import { ListProps } from '../main';
import { CheckboxContainer, FormContent } from '../insert/styles';

interface StateProps {
  lanc: ListProps;
}

interface FormData {
  type: string;
  description: string;
  loc: boolean;
  nac: boolean;
  zon: boolean;
  plan: boolean;
  status: boolean;
}

const TipoLancUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation<StateProps>();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [lanc] = useState<ListProps>(() => {
    if (location.state) {
      return location.state.lanc;
    }

    return {} as ListProps;
  });
  const [status, setStatus] = useState(() => {
    return lanc.stat;
  });

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
    }
  }, [history, location.pathname, location.state]);

  const handleCheckbox = useCallback((field: string) => {
    const input = document.querySelector<HTMLInputElement>(
      `input[id="${field}"]`,
    );

    if (field === 'status') {
      setStatus((state) => !state);
    }

    const value = input?.checked;
    const loc = formRef.current?.getFieldValue('loc');
    const nac = formRef.current?.getFieldValue('nac');
    const zon = formRef.current?.getFieldValue('zon');
    const plan = formRef.current?.getFieldValue('plan');
    const stat = formRef.current?.getFieldValue('status');

    formRef.current?.setData({
      status: field === 'status' ? value : stat,
      loc: field === 'loc' ? value : loc,
      nac: field === 'nac' ? value : nac,
      zon: field === 'zon' ? value : zon,
      plan: field === 'plan' ? value : plan,
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          type: yup.string().notOneOf(['on', ''], 'Este campo é obrigatório'),
          description: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/tplanc_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro alterado.',
        });
        history.replace(location.pathname.replace('/update', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração Tipo de Lançamento" isResponsible />

      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            code: lanc.code,
            status: lanc.stat,
            type: lanc.type,
            description: lanc.desc,
            loc: lanc.loc,
            nac: lanc.nac,
            zon: lanc.zon,
            plan: lanc.plan,
          }}
        >
          <Input name="code" isHidden />
          <Input name="status" isHidden />
          <Input name="loc" isHidden />
          <Input name="nac" isHidden />
          <Input name="zon" isHidden />
          <Input name="plan" isHidden />
          <FormContent>
            <RadioContainer
              title="Tipo de Lançamento"
              // onClick={handleRadioClick}
              name="type"
              content={[
                {
                  id: 'tpE',
                  value: 'E',
                  label: 'Entrada',
                },
                {
                  id: 'tpS',
                  value: 'S',
                  label: 'Saída',
                },
              ]}
              itemsStyle={{
                flexDirection: 'row',
              }}
              selected={lanc.type}
            />
            <span>
              <p>Descrição</p>
              <Textarea
                name="description"
                placeholder="Descrição do Tipo de Lançamento"
                mask="alpha"
              />
            </span>
            <span>
              <p>Status:</p>
            </span>
            <CheckboxContainer>
              <p>{status ? 'A' : 'Ina'}tivo</p>
              <Checkbox
                id="status"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('status')}
                checked={lanc.stat}
              />
            </CheckboxContainer>
            <span>
              <p>Disponível para:</p>
            </span>
            <CheckboxContainer>
              <p>Local</p>
              <Checkbox
                id="loc"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('loc')}
                checked={lanc.loc}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Nacional</p>
              <Checkbox
                id="nac"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('nac')}
                checked={lanc.nac}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Zonal</p>
              <Checkbox
                id="zon"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('zon')}
                checked={lanc.zon}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Planejamento</p>
              <Checkbox
                id="plan"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('plan')}
                checked={lanc.plan}
              />
            </CheckboxContainer>
          </FormContent>
          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ marginBottom: '20px' }}
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default TipoLancUpdate;
