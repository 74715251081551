import styled, { keyframes, css } from 'styled-components';
import { Content } from './sgo_wrappers';

interface AnimatedProps {
  direction: 'right' | 'left';
  needmargin?: number;
}

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;
const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimatedContent = styled(Content)<AnimatedProps>`
  form {
    ${(props) =>
      props.needmargin &&
      css`
        margin-top: ${props.needmargin}px;
      `}
    animation: ${(props) =>
      props.direction === 'right' ? appearFromRight : appearFromLeft}
      1s ease forwards;
  }
`;
