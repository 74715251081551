import React, { useCallback, useEffect, useState } from 'react';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import { format } from 'date-fns';
import momenttz from 'moment-timezone';

const HourTesting: React.FC = () => {
  const timezoneObj = {
    europe: 'Europe/Rome',
    saopaulo: 'America/Sao_Paulo',
  };

  const [mad, setMAD] = useState<any>();
  const [dates, setDates] = useState(() => {
    return { currdate: '2024-06-10 23:59', lancdate: '2024-05-31 23:59' };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const minimumAllowedDate = useCallback(
    (date: string | Date, auto?: boolean): Date => {
      const cutDateDay = 10;
      const isAutomatic = auto || false;
      const timezone = 'America/Sao_Paulo';
      // Simulação de data atual;
      const today = momenttz.tz(
        new Date(dates.currdate).toUTCString(),
        timezone,
      );

      const todayDay = today.get('date');
      let todayMonth = today.get('month');
      if (todayDay < cutDateDay + 1 && !isAutomatic) {
        todayMonth -= 1;
      }

      let todayYear = today.get('year');
      if (todayMonth === -1) {
        todayMonth = 11;
        todayYear -= 1;
      }

      const minimumGenDate = momenttz
        .tz(new Date().toUTCString(), timezone)
        .year(todayYear)
        .month(todayMonth)
        .date(1)
        .hour(0)
        .minute(0)
        .seconds(0);

      console.log(`today Date:: ${todayDay}`);
      console.log(`today Month:: ${todayMonth}`);
      console.log(`today Year:: ${todayYear}`);
      console.log(`Minimum:: ${minimumGenDate.toDate()}`);

      const receivedDate = momenttz
        .tz(new Date(date).toUTCString(), timezone)
        .hour(0)
        .minute(0)
        .seconds(0);

      console.log(`minimumGenDateTZ:: ${minimumGenDate}`);
      console.log(`receivedDateTZ:: ${receivedDate}`);

      if (receivedDate.isSameOrBefore(minimumGenDate)) {
        return minimumGenDate.toDate();
      }
      return receivedDate.toDate();
    },
    [dates.currdate],
  );

  useEffect(() => {
    setMAD(minimumAllowedDate(dates.lancdate));
  }, [dates, minimumAllowedDate]);

  const onChange = useCallback((ev) => {
    const curr = ev.currentTarget;
    const { type } = curr.dataset;

    setDates((state) => {
      return { ...state, [type]: curr.value };
    });
  }, []);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Testes de Horas</AlteredHeader>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '10px',
            margin: '25px auto',
          }}
        >
          <div>
            <div>Simular Data Atual</div>
            <input
              type="datetime-local"
              name="currdate"
              defaultValue={dates.currdate}
              data-type="currdate"
              onChange={onChange}
            />
          </div>
          <div>
            <div>Data do Lançamento</div>
            <input
              type="datetime-local"
              name="lancdate"
              defaultValue={dates.lancdate}
              data-type="lancdate"
              onChange={onChange}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '25px',
            margin: '0 auto',
            width: '50%',
          }}
        >
          <div style={{ width: '50%' }}>
            <div>Simulação Data Atual</div>
            {dates.currdate && (
              <>
                <div>Inserida: {new Date(dates.currdate).toISOString()}</div>
                <div>
                  {timezoneObj.europe}:&nbsp;
                  {momenttz
                    .tz(
                      new Date(dates.currdate).toUTCString(),
                      timezoneObj.europe,
                    )
                    .format('DD/MM/YYYY HH:mm z')}
                  {/* {JSON.stringify(new Date(dates.currdate))} */}
                </div>
                <div>
                  {timezoneObj.saopaulo}:&nbsp;
                  {momenttz
                    .tz(
                      new Date(dates.currdate).toUTCString(),
                      timezoneObj.saopaulo,
                    )
                    .format('DD/MM/YYYY HH:mm z')}
                </div>
                {mad && (
                  <div style={{ margin: '10px 0 0 0 ' }}>
                    Data mínima::&nbsp;
                    {format(mad, 'dd/MM/yyyy HH:mm:ss OOOO')}
                  </div>
                )}
              </>
            )}
          </div>
          <div style={{ width: '50%' }}>
            <div>Data Lançamento</div>
            {dates.lancdate && (
              <>
                <div>Inserida: {new Date(dates.lancdate).toISOString()}</div>
                <div>
                  {timezoneObj.europe}:&nbsp;
                  {momenttz
                    .tz(
                      new Date(dates.lancdate).toUTCString(),
                      timezoneObj.europe,
                    )
                    .format('DD/MM/YYYY HH:mm z')}
                </div>
                <div>
                  {timezoneObj.saopaulo}:&nbsp;
                  {momenttz
                    .tz(
                      new Date(dates.lancdate).toUTCString(),
                      timezoneObj.saopaulo,
                    )
                    .format('DD/MM/YYYY HH:mm z')}
                </div>
              </>
            )}
          </div>
        </div>
        {/* <button
          style={{ margin: '20px 0 0 0' }}
          type="button"
          onClick={handleCheck}
        >
          Verificar bloqueio
        </button> */}

        {/* <div>
          {format(
            momenttz
              .tz(format(now, 'dd/MM/yyyy hh:mm:ss'), 'America/Sao_Paulo')
              .toDate(),
            'dd/MM/yyyy',
          )}
        </div> */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default HourTesting;
