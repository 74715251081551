import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
  altHeight: number;
}

export const Container = styled.div<ContainerProps>`
  background: #efefef;
  border-radius: 5px;
  padding: 16px;
  width: 350px;


  height: ${(props) => props.altHeight}px;

  display: flex;
  align-items: center;

  color: #c6c6c6;
  border: 2px solid #a6a6a6;

  & + div {
    margin-top: 8px;
  }


  ${(props) =>
    props.hasError &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #332e2e;
      border-color: #332e2e;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #332e2e;
    `}



  textarea {

    resize: none;
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto Slab', serif;

    height: 100%;

    &::placeholder, &:disabled {
      color: #c6c6c6;
    }
  }

  > svg {
    margin-right: 16px;
    width: 20px;
    height: 20px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;
