import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface AccordeonProps {
  active?: boolean;
  height?: string;
}

export const Container = styled.div<AccordeonProps>`
  width: 90vw;
  /* max-width: 1000px; */

  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  height: auto;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.75s ease-in-out;

  ${(props) =>
    props.active &&
    css`
      box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.5);
      section {
        background: ${shade(0.2, '#767676')};
        svg {
          transform: rotate(90deg);
        }
      }

      div {
        height: ${props.height ? `${props.height}` : '500px'};
        padding: 10px;
      }
    `}

  @media screen and (max-width: 1100px) {
    width: 100vw;
    border-radius: 0px;
  }
`;

export const HeaderContent = styled.section`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 30px;
  color: #fff;
  background: #767676;
  transition: background-color 0.75s ease-in-out;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 15px;
    transition: transform 0.5s;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    text-shadow: 1px 1px 1px #464646;
  }
`;

export const Content = styled.div<AccordeonProps>`
  height: 0px;
  max-height: ${(props) => (props.height ? `${props.height}` : '500px')};
  padding: 0px 10px;
  overflow-y: auto;
  overflow-x: hidden;

  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: #464646;

    &:hover {
      background: ${shade(0.2, '#464646')};
    }
  }
`;
