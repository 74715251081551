import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

interface BtType {
  bgcolor: string;
}

export const Grid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
  width: 100vw;
  padding: 10px 10px 25px 10px;

  @media screen and (max-width: 1500px) {
    grid: auto / 1fr 1fr 1fr;
    max-width: 1300px;
  }
  @media screen and (max-width: 1300px) {
    grid: auto / 1fr 1fr;
    max-width: 800px;
  }
  @media screen and (max-width: 700px) {
    grid: auto / 1fr;
    width: 400px;
  }

  @media screen and (max-width: 400px) {
    padding: 10px 0px 25px 0px;
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  font-size: 16px;

  h4 {
    font-size: 16px;
    margin: 5px auto;
    color: #8a0002;
  }
  div {
    display: inherit;
    flex-direction: row;

    span {
      display: inherit;
      width: 100%;
      justify-content: flex-start;
    }
  }

  &:hover {
    border-color: ${shade(0.2, '#efefef')};
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 400px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const OptButton = styled.button<BtType>`
  --border-color: ${(props) => props.bgcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  /* background: #007acc; */
  background: var(--border-color);

  transition: background-color 0.5s;

  &:hover {
    ${(props) => css`
      background-color: ${shade(0.2, props.bgcolor)};
    `}
  }
`;

export const TrajContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px 7px;
    background: #fff;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;
    > div {
      display: flex;
      > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #8a0002;
          text-shadow: unset;
        }
        p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
          user-select: none;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }
      & + div {
        margin: 10px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 500px) {
    > div {
      > div {
        flex-direction: column;

        span + span {
          margin: 5px 0 0 0;
        }
      }
    }
  }
`;

export const YearContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 200px;
  align-items: center;
  justify-content: center;

  margin: 10px auto 0 auto;
  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #332e2e;
  }

  /* @media screen and (max-width: 460px) {
    margin: 60px auto 0 auto;
  } */
  /* @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100vw;
  } */

  transition: all 0.5s;
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;
