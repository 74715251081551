import styled, { keyframes, css } from 'styled-components';

interface TOVProps {
  borderlessImage?: boolean;
}

const borderShifter = keyframes`
  from {
    border-color: #efefef;
  } to {
    border-color: #332e2e;
  }
`;

export const TOVService = styled.section<TOVProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 1000px;

  h2,
  h3 {
    width: 100%;
    margin-top: 10px;

    text-align: center;

    color: #fff;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(197, 48, 48, 0.25),
      rgba(197, 48, 48, 0.5),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(197, 48, 48, 0.5),
      rgba(197, 48, 48, 0.25),
      rgba(255, 255, 255, 0)
    );
  }
  div {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    img {
      align-self: center;
      height: 70%;
      max-height: 230px;

      object-fit: cover;
      ${(props) =>
        !props.borderlessImage &&
        css`
          box-shadow: -5px 15px 10px 2px rgba(0, 0, 0, 0.85);
        `}
    }
    > span {
      margin: 0 15px 0 0;
      background: #efefef;
      padding: 15px 20px;
      border: 2px solid;
      border-radius: 5px;

      box-shadow: -5px 15px 10px 2px rgba(0, 0, 0, 0.85);

      animation: ${borderShifter} 2.5s linear infinite alternate;

      p {
        font-size: 16px;
        text-align: justify;

        & + p {
          margin-top: 5px;
        }
      }
    }
  }

  > h4 {
    margin: 20px 0;
    width: 100%;
    text-align: right;
    font-weight: 400;
    strong {
      /* margin: 0 5px 0 0; */
    }
  }

  > span {
    padding: 5px;
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h4 {
      font-size: 14px;
      display: flex;
      strong {
        margin: 0;
      }
      p {
        margin-left: 2px;
        font-weight: 400;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    width: 100vw;
    div {
      margin: 30px 0 0 0;
      flex-direction: column;

      img {
        margin-top: 30px;
      }

      span {
        margin: 0;
      }
    }
    > h4 {
      text-align: center;
    }

    > span {
      align-items: flex-start;
      h4 {
        font-size: 12px;
        strong {
          margin: 0;
        }
        p {
          margin: 0 0 0 5px;
        }
      }
    }
  }
`;

export const Schedule = styled.section`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    margin: 10px 0;

    text-align: center;

    color: #fff;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(197, 48, 48, 0.25),
      rgba(197, 48, 48, 0.5),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(197, 48, 48, 0.5),
      rgba(197, 48, 48, 0.25),
      rgba(255, 255, 255, 0)
    );
  }
  img {
    width: 90%;

    margin: 20px 0;
    border: 2px solid #332e2e;
    border-radius: 5px;
  }
`;
