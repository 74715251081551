import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  setLocalStorage,
  getLocalStorage,
  localStorageLength,
} from 'utils/handleLocalStorage';

import { formatDate } from 'utils/formatDate';
import {
  FaArrowCircleRight,
  FaArrowCircleUp,
  FaPencilAlt,
  FaSearch,
} from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import { formatNumber } from 'utils/calcTotal';
import { GridContainer, GridItem, InvertButton } from './styles';

export interface LocalStorageProps {
  stat?: string;
  group?: string;
}

export interface ListProps {
  cod: string;
  num: number;
  dtpedido: string;
  status: string;
  statDesc: string;
  obs: string[];
  numgd: string;
  dtgd: string;
  dtmanut: string;
  anbdesc: string;
  vlrPed: number;
  addressval: string;
  address: {
    street: string;
    district: string;
    city: string;
    state: string;
    cep: string;
  };
}

const Pedido: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();

  const { user } = useAuth();

  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [status] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_PEDIDO}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PEDIDO}`,
      );

      if (stat) {
        return stat;
      }

      return 'A';
    }

    return 'A';
  });

  const [searchValue, setSearchValue] = useState('');
  const [isInverted, setIsInverted] = useState(false);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/pedido_list.php');

      setList(
        response.data.filter((item: ListProps) =>
          status === 'A'
            ? item
            : status === 'O'
            ? item.status === 'C'
            : item.status === 'R',
        ),
      );

      setRawList(response.data);

      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, status]);

  useEffect(() => {
    handlePermission(['ZON', 'NAC'], true);
    getList();
  }, [getList, handlePermission]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const filtered = rawList.filter((item: ListProps) => {
        return (
          item.num.toString().includes(val) ||
          removeAcento(item.anbdesc)
            .toLowerCase()
            .includes(removeAcento(val).toLowerCase()) ||
          removeAcento(item.cod)
            .toLowerCase()
            .includes(removeAcento(val).toLowerCase())
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const handleInvert = useCallback(() => {
    setIsInverted((state) => !state);
    setList((state) => [...state].reverse());
    setRawList((state) => [...state].reverse());
  }, []);

  const handleRadioClick = useCallback(() => {
    const val = formRef.current?.getFieldValue('filterSolStat');

    setLocalStorage(`${process.env.REACT_APP_PEDIDO}`, { stat: val });

    const filtered = rawList.filter((item: ListProps) =>
      val === 'A'
        ? item
        : val === 'O'
        ? item.status === 'C'
        : item.status === 'R',
    );

    setList(filtered);
  }, [rawList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'NAC' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form
            ref={formRef}
            onSubmit={() => {
              return null;
            }}
          >
            <RadioContainer
              title="Pedidos à Zonal:"
              onClick={handleRadioClick}
              name="filterSolStat"
              content={[
                {
                  id: 'allRequests',
                  value: 'A',
                  label: 'Todos',
                },
                {
                  id: 'oRequests',
                  value: 'O',
                  label: 'Em Aberto',
                },
                {
                  id: 'rRequests',
                  value: 'R',
                  label: 'Atendidos',
                },
              ]}
              itemsStyle={{
                flexDirection: 'row',
              }}
              selected={status}
            />
          </Form>
        }
      />

      <Header>Pedidos à Zonal</Header>
      {user.perfil === 'ZON' && (
        <FloatInput amount="100px">
          <input
            placeholder="Filtrar por N° ou coordenação | código"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}

      <AlteredContent pixels={user.perfil !== 'ZON' ? '231px' : '280px'}>
        {!loading && list.length > 0 && (
          <InvertButton
            type="button"
            onClick={handleInvert}
            isInverted={isInverted}
          >
            <p>Mais {isInverted ? 'recentes' : 'antigas'} primeiro</p>
            <FaArrowCircleUp />
          </InvertButton>
        )}
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem key={`${item.cod}-${item.num}`}>
              <p>
                N° {item.num} |&nbsp;
                {formatDate(item.dtpedido)}
              </p>
              <span>
                <p>
                  Valor do Pedido:&nbsp;
                  <strong>R$ {formatNumber(item.vlrPed)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statDesc}</strong>
                </p>
              </span>
              {user.perfil === 'ZON' && (
                <span>
                  <p>
                    Coord:&nbsp;
                    <strong>{item.anbdesc}</strong>
                  </p>
                </span>
              )}
              {item.numgd ? (
                <span>
                  <p>
                    GD:&nbsp;
                    <strong>N° {item.numgd}</strong>
                  </p>
                  <p>
                    | Data GD: <strong>{formatDate(item.dtgd)}</strong>
                  </p>

                  <O.ShowFrom
                    to={{
                      pathname: `${pathname.replace('pedido', 'gd/consult')}`,
                      state: { gdnum: item.numgd, anbcod: item.cod },
                    }}
                  >
                    <FaSearch />
                    &emsp;GD
                  </O.ShowFrom>
                </span>
              ) : null}
              <span>
                <p>
                  Atualizado em:&nbsp;
                  <strong>{formatDate(item.dtmanut)}</strong>
                </p>
              </span>

              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { pedido: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {item.status === 'C' && (
                  <>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { pedido: item },
                      }}
                    >
                      <FaPencilAlt />
                    </O.Update>

                    {user.perfil === 'ZON' && (
                      <O.GenFrom
                        to={{
                          pathname: `${pathname.replace(
                            'pedido',
                            'gd/pedido',
                          )}`,
                          state: { pedidoHeader: item },
                        }}
                      >
                        GD <FaArrowCircleRight />
                      </O.GenFrom>
                    )}
                  </>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Pedido;
