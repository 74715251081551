import styled, { css, keyframes } from 'styled-components';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { animated } from 'react-spring';

import { shade } from 'polished';

interface EditableProps {
  isEditable: boolean;
}

interface WrapperProps {
  paint: number;
}

const rotatePhone = keyframes`
  0%{
    transform: rotateZ(0deg);
  } 35% {
    transform: rotateZ(90deg);
  } 50% {
    transform: rotateZ(90deg);
  } 65% {
    transform: rotateZ(90deg);
  } 100% {
    transform: rotateZ(0deg);
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  padding: 10px 20px;

  width: 360px;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0 auto;
  position: relative;

  /* position: sticky;
  top: 60px; */

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;

export const RotateMessage = styled(animated.div)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  span {
    p {
      font-size: 13px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      text-align: center;
    }
    svg {
      height: 20px;
      width: 20px;
      animation: ${rotatePhone} 3s infinite;
    }

    & + span {
      margin-left: 10px;
    }
  }
`;

export const PreviousBalance = styled(animated.div)`
  margin: 20px auto 2px auto;
  width: 790px;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 5px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
  );

  transition: width 0.35s ease;

  @media screen and (max-width: 790px) {
    width: 607px;
  }
  @media screen and (max-width: 640px) {
    width: 465px;
  }
  @media screen and (max-width: 475px) {
    width: 100vw;
    border-radius: 0px;
  }
`;

export const Balance = styled(animated.div)`
  margin: 5px auto 2px auto;
  /* width: 690px; */
  width: 790px;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 5px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
  );

  transition: width 0.35s ease;
  @media screen and (max-width: 790px) {
    width: 607px;
  }
  @media screen and (max-width: 640px) {
    width: 465px;
  }
  @media screen and (max-width: 475px) {
    width: 100vw;
    border-radius: 0px;
  }
`;

export const ContentContainer = styled(animated.div)`
  margin: 0px 0 10px 0;
  background: #fff;
  display: flex;
  flex-direction: column;

  border: 2px solid #efefef;
  border-radius: 5px;
  position: relative;

  height: 500px;
  overflow-x: scroll;
  /* width: 80vw; */
  /* width: 360px; */

  transition: width 0.35s ease;

  @media screen and (max-width: 475px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  background: #332e2e;
  color: #fff;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 16px;

  > span {
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      /* padding: 3px; */
      font-size: 15px;
      height: auto;
    }

    & + div {
      margin-top: 5px;
    }
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};

  span {
  }
`;

export const Data = styled.span`
  width: 100px;
  padding: 3px;

  p {
    padding-left: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Lanc = styled(animated.span)`
  width: 142px;
`;

export const Compl = styled(animated.span)`
  width: 184px;

  button {
    background: transparent;
    border: 0;
    border-radius: 5px;
    padding: 0px 2px;
    font-size: 15px;
    transition: background-color 0.35s;

    &:hover {
      background: #c6c6c6;
    }
  }
`;

export const Mov = styled(animated.span)`
  padding: 3px;
  justify-content: flex-end;
`;

export const Saldo = styled.span`
  width: 114px;
  padding: 3px;
  justify-content: flex-end;

  p {
    padding-right: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const UpdateReg = styled(animated.span)`
  padding: 3px;
  display: flex;
  width: 75px;

  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #007acc;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    transition: background-color 0.35s ease;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    a {
      background-color: ${shade(0.2, '#007acc')};
    }
  }
`;

export const ExcReg = styled(animated.span)`
  display: flex;
  width: 75px;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #c53030;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    border: 0;
    transition: background-color 0.35s ease;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    button {
      background-color: ${shade(0.2, '#c53030')};
    }
  }
`;

export const IncludeNew = styled(animated.div)`
  margin-top: 5px;
  padding: 5px 10px;
  overflow: hidden;

  border: 2px solid #00802b;
  border-radius: 5px;

  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);

  background: #00802b;

  transition: background-color 0.35s ease, border-color 0.35s ease;

  color: #fff;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;

  &:hover {
    background-color: #004d1a;
    border-color: #004d1a;
  }
`;

export const EditButton = styled.button<EditableProps>`
  background: transparent;
  border: 0;
  border-radius: 5px;
  padding: 0px 2px;
  font-size: 15px;
  transition: background-color 0.35s;

  pointer-events: all;

  ${(props) =>
    !props.isEditable &&
    css`
      pointer-events: none;
    `}

  &:hover {
    background: #c6c6c6;
  }
`;

export const UpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  span {
    font-size: 15px;
    p {
      color: #767676;
    }
    strong {
      color: #332e2e;
    }
  }

  button {
    margin-top: 15px;
    font-size: 16px;
    border: 0;
    background: #e6e6e6;
    color: #8a0002;
  }
`;
