import styled from 'styled-components';

export const GroupContainer = styled.div`
  margin: 10px 0 10px 0;
  width: 90vw;
  max-width: 1300px;

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1350px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 530px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  position: relative;
  width: auto;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 3px solid #efefef;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: #332e2e;
  }
`;

export const GridFront = styled.span`
  width: 100%;
  p {
    color: #666;
    font-size: 14px;
    text-align: left;
  }
  strong {
    color: #332e2e;
  }
`;
