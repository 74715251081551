import React, { useCallback, useEffect, useState } from 'react';

// import ReactPlayer from 'react-player';

import { Container, Content } from 'styles/sgo_wrappers';

import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import ScrollTop from 'components/ScrollTop';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import { useCredentials } from 'hooks/credentials';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { df64, et64 } from 'utils/encoding';

import api from 'services/api';
import { formatDate } from 'utils/formatDate';
import {
  ContentContainer,
  CronTable,
  CronTableWrapper,
  FeedContainer,
  FeedContent,
} from './styles';

// import { MessageContainer, PlayerContainer } from './styles';

interface LocalStorageProps {
  autoplay?: string;
  fc?: string;
  cc?: string;
}

interface CronProps {
  index: number;
  content: string;
  date: string;
}

interface FeedProps {
  version: string;
  date: string;
  content: string;
}

const SGOHome: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { checkCredentials } = useCredentials();
  const [mode] = useState(process.env.REACT_APP_HOME_CONTENT);

  const [cron, setCron] = useState<CronProps[]>(() => {
    const { cc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return cc !== undefined
      ? cc?.substring(0, 1) === '['
        ? JSON.parse(cc)
        : df64(cc)
      : [];
  });

  const [feed, setFeed] = useState<FeedProps[]>(() => {
    const { fc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return fc !== undefined
      ? fc?.substring(0, 1) === '['
        ? JSON.parse(fc)
        : df64(fc)
      : [];
  });

  const getContent = useCallback(async () => {
    try {
      const response = await api.get('/common/homecontent.php');

      const { storedfeed, storedcron } = df64(response.data);

      if (storedfeed) {
        setFeed(storedfeed);
        setLocalStorage(`${mode}`, {
          // fc: JSON.stringify(storedfeed),
          fc: et64(storedfeed),
        });
      }
      if (storedcron) {
        setCron(storedcron);
        setLocalStorage(`${mode}`, {
          // cc: JSON.stringify(storedcron),
          cc: et64(storedcron),
        });
      }
    } catch (e) {
      console.error('Unable to fetch feed');
    }
  }, [mode]);

  useEffect(() => {
    if (user.perfil === 'CTB') {
      history.replace('/sgo/contabil');
    }
    checkCredentials();
    getContent();
  }, [checkCredentials, getContent, history, user.perfil]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <ContentContainer>
          {cron.length > 0 && (
            <div>
              <h4>Cronograma de Fechamento do SGO</h4>
              <CronTableWrapper>
                <CronTable>
                  <tbody>
                    {cron.map((item: CronProps, index) => (
                      <tr
                        key={`${item.date}${item.index}`}
                        style={{
                          background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                        }}
                      >
                        <td>
                          <p>{item.content}</p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <p>{formatDate(item.date)}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CronTable>
              </CronTableWrapper>
            </div>
          )}
          {feed.length > 0 && (
            <FeedContainer
              style={{ margin: cron.length > 0 ? '20px 0' : '0px 0px 20px' }}
            >
              <h4>Atualizações recentes</h4>
              {feed.map((item: FeedProps) => (
                <FeedContent key={`${item.date}${item.version}`}>
                  <div>
                    <p>
                      Implantada a versão&nbsp;
                      <span style={{ color: '#8a0002', fontWeight: 'bold' }}>
                        v{item.version}
                      </span>
                      , em&nbsp;
                      {formatDate(item.date)}, que inclui
                    </p>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </FeedContent>
              ))}
            </FeedContainer>
          )}

          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>
              Implantada a versão v{process.env.REACT_APP_VERSION}, em
              08/02/2022, que inclui:
            </p>
          </span>
          <span>
            <strong>• MENSAGEM ANDERSON.</strong>
          </span> */}

          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>
              Implantada a versão v{process.env.REACT_APP_VERSION}, em
              02/06/2022, que inclui:
            </p>
          </span>
          <span>
            <div dangerouslySetInnerHTML={{ __html: typed }} />
          </span> */}

          {/*
<span style={{ margin: '25px 0 0 0' }}>
            <p>
              Implantada a versão v{process.env.REACT_APP_VERSION}, em
              25/04/2022, que inclui:
            </p>
          </span>
          <span>
            <strong>
              Nessa nova versão, foi implantada a funcionalidade
              &ldquo;Movimentação de Doações Específicas&rdquo; a qual deverá
              ser acessada através do menu TESOURARIA. Essa nova ferramenta
              apresenta todos os valores que foram registrados como entrada de
              doações específicas bem como a saída para qual tais doações foram
              retiradas. Tais movimentações farão parte do cálculo de valores de
              excedente que as locais deverão prestar conta para as nacionais
              conforme o teto de disponibilidade atribuído pela Nacional para
              cada local de sua responsabilidade.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.25.0, em 13/04/2022, que inclui:</p>
          </span>
          <span>
            <strong>
              • No menu GERENCIAL + Planilhas Semestrais + Orçamento Excedente
              foi implantada a funcionalidade &ldquo;Cálculo do Excedente das
              Locais&rdquo; com base na seguinte formula:
              <br />
              (+) Saldo final do semestre anterior
              <br />
              (-) Teto de Disponibilidade estabelecido pela Equipe Nacional
              <br />
              (-) Saldo das Doações Específicas
              <br />
              (=) Repasse do excedente da Local Calculo será realizado após o
              fechamento do semestre e os valores deverão ser repassados à sua
              Coordenação Nacional
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.23.4, em 23/03/2022, que inclui:</p>
          </span>
          <span>
            <strong>
              • No menu GERENCIAL, a funcionalidade Relatório Financeiro de
              Eventos TOV visando facilitar o registro da movimentação
              financeira dos eventos Assembleia Nacional e e Jornada de
              Convivência Fraterna (para as Nacionais) e Retiros Anuais, Retiro
              de um dia e EED (para as Locais) . Por enquanto o resultado do
              evento não será automático para o caixa ou Movimento Bancário,
              cabendo à coordenação responsável realizar tal movimentação
              manualmente.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.23.0, em 16/03/2022, que inclui:</p>
          </span>
          <span>
            <strong>
              • No menu Gerencial, a funcionalidade Teto de Disponibilidade com
              o objetivo de apresentar valores necessários para que as Nacionais
              possam calcular o teto de disponibilidade entre as suas Locais.
              Esse valor único será utilizado para cálculo do Excedente
              (implantação futura).
            </strong>
          </span>
          <span>
            <strong>
              • No menu SECRETARIA, a funcionalidade Cronograma Especial com o
              objetivo apresentar as atividades de Assembleias, Retiros Anuais e
              de Casais, EED e Jornada de Convivência Fraterna pelo Brasil, além
              dos Dias Mensais do Guia, com pesquisa por Zonal, Coordenação
              Nacional e Coordenações Locais
            </strong>
          </span>
          <span>
            <strong>
              • No menu SECRETARIA, as funcionalidades Biblioteca (para as
              Locais) e Acervo (para as Nacionais e Zonais). Através dessa
              funcionalidade, o usuário poderá incluir, alterar ou mesmo excluir
              um item da sua Biblioteca Local ou do seu Acervo Nacional. As
              Zonais deverão publicar material orientando quais os itens podem
              fazer parte da Biblioteca Local.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.22.11, em 18/01/2022, que inclui:</p>
          </span>
          <span>
            <strong>
              • No menu Gerencial, a funcionalidade Presença no Retiro Anual com
              o objetivo de consultar os guias que participaram de um Retiro
              Anual em um ano solicitado. Resultados por Coordenação Local e
              Coordenação Nacional.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.21.3, em 02/01/2022, que inclui:</p>
          </span>
          <span>
            <strong>• Mudanças no menu de secretaria.</strong>
          </span>
          <span>
            <strong>
              • Manutenção (Inclusão e Exclusão) dos Evangelizadores.
            </strong>
          </span>
          <span>
            <strong>• Acesso a consulta dos membros das Equipes.</strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.20.10, em 29/11/2021, que inclui:</p>
          </span>
          <span>
            <strong>• Inclusão/alteração na FIG para História do Guia.</strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.20.4, em 16/11/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Inclusão/alteração na FIG para Serviços, Participações em
              Eventos e Funções Exercidas. As demais funcionalidades em
              desenvolvimento.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.20.2, em 28/10/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Inclusão/alteração na FIG para TRAJETÓRIA DO GUIA. As demais
              funcionalidades em desenvolvimento.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v20.0.0, em 22/10/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Inclusão/alteração na FIG para OUTRAS ATIVIDADES e DIÁLOGO
              FRATERNO. Para acesso siga SECRETARIA + Guias. No box do guia,
              selecione o último ícone na cor verde. As demais funcionalidades
              em desenvolvimento.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.19.1, em 14/10/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Funcionalidade de Devolução de Materiais gerada e mantida pela
              Nacional. Retirada a função de Solicitação de Devolução pelas
              Coord. Locais/Núcleos. As DMs passam a abater valores pendentes de
              Remessas de Materiais (RMs) e não geram valores nos Caixas da
              Locais e nem no Movimento Bancário das Nacionais
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.18.4, em 06/09/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Funcionalidade de Eleição/Consulta Prévia composta de
              Convocação, Escolha do Guia e Contagem das Escolhas. Essa
              funcionalidade permitirá que o SGO controle as convocações para
              escolha de um Coordenador Local ou Coordenador Nacional.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.17.2, em 23/08/2021, que inclui:</p>
          </span>
          <span>
            <strong>• Funcionalidade de EPG.</strong>
          </span>
          <span>
            <strong>
              • Alteração da permissão de cadastro dos manuais. Apenas as
              Coordenações Zonais poderão realizar essa tarefa.
            </strong>
          </span>
          <span>
            <strong>• Planilha para Cálculo do Orçamento de Excedentes.</strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.16.2, em 19/07/2021, que inclui:</p>
          </span>
          <span>
            <strong>• Geração da Planilha de Aniversariantes.</strong>
          </span>
          <span>
            <strong>
              • Faturamento do SGO: Planilha, Notas Fiscais e inclusão de
              comprovantes mensais (procedimento visando agilizar os processos
              de fechamento mensal)
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.15.3, em 14/07/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Liberado procedimento para Transferências de Guias.
            </strong>
          </span>
          <span>
            <strong>• Janela de Atualização aberta até dia 31/07</strong>
          </span>
          <span>
            <strong>
              • Implantada a verificação de Guias em Permissão na Planilha de
              inconsistências
            </strong>
          </span>

          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.13.0, em 22/06/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Geração de Planilha da Ficha Individual do Guia, agora acessada
              diretamente por Secretaria &gt; Guia. O Guia agora tem um novo
              botão para geração da FIG.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.12.1, em 17/06/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Inclusão e consulta aos Planejamentos Semestrais. Geração de
              Planilhas do Planejado e também Planejado x Realizado.
            </strong>
          </span>
          <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.10.0, em 27/05/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Geração de Planilha de Inconsistência, destinada as Coor.
              Nacionais e Locais, que é uma ferramenta que aponta as
              divergências encontradas para correção antes do fechamento
              semestral.
            </strong>
          </span>
*/}

          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.9.0, em 25/05/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Geração das Planilhas Semestrais de Estatísticas, Informes e
              Indicadores
            </strong>
          </span> */}
          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.8.2, em 14/05/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Cadastramento, alteração e consulta dos itens de Patrimônio
              (Ativos) das Nacionais e Zonais, incluindo geração de planilha
            </strong>
          </span> */}
          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.7.0, em 11/05/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Dia Universal do Guia: Cadastramento, manutenção e geração
              planilha Excel
            </strong>
          </span> */}
          {/* <span style={{ margin: '25px 0 0 0' }}>
            <p>Implantada a versão v5.6.0, em 04/05/2021, que inclui:</p>
          </span>
          <span>
            <strong>
              • Permissão de alguns serviços virtuais (Jornadas, Palestras,
              Cursinho Bíblico e outras)
            </strong>
          </span>
          <span>
            <strong>
            • Lançamento de Ofertas no módulo de Tesouraria (assista a
              videoaula)
            </strong>
          </span> */}

          <span />
        </ContentContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default SGOHome;
