import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Container,
  AlteredContent,
  Header,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import { useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { isValid } from 'date-fns';
import moment from 'moment';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaPencilAlt, FaTimes, FaUsers } from 'react-icons/fa';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { formatDate, handleTimeZone } from 'utils/formatDate';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import * as D from 'styles/dialog_delete';
import { useToast } from 'hooks/toast';
import { Grid, GridItem, StyledUpdate } from './styles';
import { monthList, MonthListProps } from '../../../Tesouraria/monthList';

interface LocalStorageProps {
  anb?: string;
  anbdesc?: string;
  loc?: string;
  locdesc?: string;
  year?: string;
}

interface CoordProps {
  cod: string;
  desc: string;
}

export interface ListProps {
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  prq: string;
  prqdesc: string;
  date: string;
  month: number;
  monthDesc: string;
  year: string;
  qtd: number;
  register: boolean;
}

const DUG: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();

  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [selectedYear, setSelectedYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DUG}`,
    );

    return year || new Date().getFullYear().toString();
  });

  const [years, setYears] = useState<ComboProps[]>([]);

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DUG}`,
    );

    return loc || user.loccod;
  });

  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DUG}`,
    );

    return anb || user.anbc;
  });
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const [coordSelected, setCoordSelected] = useState(() => {
    const { anb, loc, locdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DUG}`,
    );

    if (anb && loc) {
      return { cod: loc, desc: locdesc };
    }

    return {
      cod: user.loccod,
      desc: user.locdesc,
    } as CoordProps;
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [pending, setPending] = useState(false);

  const handleDate = useCallback((verify: string): boolean => {
    const rawDate = new Date(verify);
    const full = new Date();
    if (!isValid(rawDate)) {
      return false;
    }

    const eventDate = handleTimeZone(rawDate);

    const todayDate = handleTimeZone(full);

    const formats = 'YYYY-mm-dd';

    const eventMoment = moment(eventDate, formats);
    const todayMoment = moment(todayDate, formats);

    return todayMoment.isSameOrAfter(eventMoment);
  }, []);

  const getComboLOC = useCallback(
    async (params?: string) => {
      const response = await api.get(
        `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
      );
      setLocs(response.data);

      if (params) {
        const { anb }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_DUG}`,
        );

        setComboLocs(
          response.data.filter((item: ComboLocalProps) =>
            anb ? item.anb === anb : item.anb === user.anbc,
          ),
        );
      }
    },
    [user.anbc],
  );

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const getList = useCallback(
    async (params?: string) => {
      try {
        setLoading(true);
        const { loc }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_DUG}`,
        );

        const coord = loc;

        const response = await api.get(
          `/sgo/dug_list.php?data=${JSON.stringify({
            code: params || coord,
          })}`,
        );

        const firstYear = response.data.years[0].value;

        const { year }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_DUG}`,
        );

        if (year) {
          setSelectedYear(year);
        } else {
          setSelectedYear(firstYear);
        }

        setYears(response.data.years);

        const completeList = response.data.list
          .map((item: ListProps) => ({
            ...item,
            monthDesc:
              monthList[
                monthList.findIndex(
                  (month: MonthListProps) => month.value === item.month,
                )
              ].label,
          }))
          .map((item: ListProps) => ({
            ...item,
            register: handleDate(item.date),
          }));

        setRawList(completeList);
        setList(
          completeList.filter((item: ListProps) =>
            year ? item.year === year : item.year === firstYear,
          ),
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorHandling],
  );

  const handleZONselect = useCallback(() => {
    const zon = formRef?.current?.getFieldValue('comboZON');
    setComboNacs([]);
    formRef.current?.setFieldValue('comboANB', '');
    formRef.current?.setFieldValue('comboLocal', '');

    setComboNacs(
      nacs.filter((item) => item.value.substr(0, 2) === zon.substr(0, 2)),
    );

    setPending(true);
    setInitialNac('Selecione');
    setInitialLoc('Selecione');
    setComboLocs([]);
  }, [nacs]);

  const handleANBselect = useCallback(() => {
    const nac = formRef?.current?.getFieldValue('comboANB');
    const index = comboNacs.findIndex((item: ComboProps) => item.value === nac);

    setInitialNac(comboNacs[index].label);
    setInitialLoc('Selecione');
    formRef.current?.setFieldValue('comboLocal', '');
    setPending(true);

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === nac));
  }, [comboNacs, locs]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef?.current?.getFieldValue('comboLocal');
    const locIndex = comboLocs.findIndex(
      (item: ComboProps) => item.value === loc,
    );
    const anb = formRef?.current?.getFieldValue('comboANB');

    const anbIndex = comboNacs.findIndex((item: ComboProps) =>
      anb.length > 4
        ? item.label === anb.split('-', 1).includes(item.label)
        : item.value === anb,
    );

    setPending(false);
    setInitialLoc(comboLocs[locIndex].label);

    getList(loc);
    getComboLOC();
    const selectedLOC = {
      cod: loc,
      desc: comboLocs[locIndex].label,
    };

    setCoordSelected(selectedLOC);

    setLocalStorage(
      `${process.env.REACT_APP_DUG}`,
      ['INT', 'ZON'].indexOf(user.perfil) > -1
        ? {
            anb,
            anbdesc: comboNacs[anbIndex].label,
            loc,
            locdesc: comboLocs[locIndex].label,
          }
        : {
            anb: user.anbc,
            anbdesc: user.anbdesc,
            loc,
            locdesc: comboLocs[locIndex].label,
          },
    );
  }, [
    comboLocs,
    comboNacs,
    getComboLOC,
    getList,
    user.anbc,
    user.anbdesc,
    user.perfil,
  ]);

  const handleYearSelect = useCallback(() => {
    const yearSelect = formRef.current?.getFieldValue('comboAno');

    setList(rawList.filter((item) => item.year === yearSelect));
    setSelectedYear(yearSelect);

    setLocalStorage(`${process.env.REACT_APP_DUG}`, {
      year: yearSelect,
    });
  }, [rawList]);

  useEffect(() => {
    if (user.perfil === 'INT') {
      getComboZON();
    }

    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboANB();
      getComboLOC('init');

      const { anb }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_DUG}`,
      );

      formRef.current?.setFieldValue('comboANB', anb || user.anbc);
    }

    if (['NAC'].indexOf(user.perfil) > -1) {
      getComboLOC('init');
    }

    getList();
  }, [
    getComboZON,
    getComboANB,
    getComboLOC,
    getList,
    user.perfil,
    handlePermission,
    user.anbc,
  ]);

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);

          setDeleteDiag((state) => ({ ...state, open: false }));

          const send = new FormData();
          send.append('data', JSON.stringify(values));

          await api.post('/sgo/dug_delete.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          const rawIndex = rawList.findIndex(
            (item) =>
              item.anb === values.anb &&
              item.loc === values.loc &&
              item.prq === values.prq &&
              item.date === values.date,
          );

          const listIndex = list.findIndex(
            (item) =>
              item.anb === values.anb &&
              item.loc === values.loc &&
              item.prq === values.prq &&
              item.date === values.date,
          );

          if (rawIndex > -1 && listIndex > -1) {
            setRawList(
              rawList
                .filter((_, index) => index !== rawIndex)
                .map((item) => item),
            );

            setList(
              list
                .filter((_, index) => index !== listIndex)
                .map((item) => item),
            );
          } else {
            getList();
          }

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Dia Universal do Guia excluído com sucesso!',
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [addToast, errorHandling, getList, list, rawList],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'LOC' && <IncludeButton />}
      {/* {user.perfil !== 'GUI' && (
        <PrintButton icon={FaFileExcel} linkTo="/relatorio" routeSwitch />
      )} */}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON', 'NAC', 'LOC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={user.zoncod}
                  />
                </span>
              )}

              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por ANB:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                </span>
              )}

              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                </span>
              )}

              {pending ? null : (
                <span>
                  <p>Filtre por Ano:</p>
                  <SelectV2
                    name="comboAno"
                    content={years}
                    onChange={handleYearSelect}
                    initial={selectedYear}
                  />
                </span>
              )}
            </div>
          </Form>
        }
      />
      <Header>Dia Universal do Guia - {coordSelected.desc}</Header>
      <AlteredContent pixels="231px">
        <Grid>
          {list.map((item) => (
            <GridItem key={`${item.anb}${item.loc}${item.date}${item.prq}`}>
              <div>
                <span>
                  <p>
                    Mês:&nbsp;<strong>{item.monthDesc}</strong>
                  </p>
                  <p>
                    Ano:&nbsp;<strong>{item.year}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Local de Serviço/Atividade:&nbsp;
                    <strong>{item.prqdesc}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Data:&nbsp;<strong>{formatDate(item.date)}</strong>
                  </p>
                </span>
              </div>
              <O.GridOptions>
                <StyledUpdate
                  to={{
                    pathname: `${pathname}/guias`,
                    state: { reg: item },
                  }}
                >
                  <FaUsers />
                </StyledUpdate>
                {user.perfil === 'LOC' &&
                  item.loc === user.loccod &&
                  parseInt(selectedYear, 10) >= new Date().getUTCFullYear() && (
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { reg: item },
                      }}
                    >
                      <FaPencilAlt />
                    </O.Update>
                  )}
                {item.qtd === 0 &&
                  user.perfil === 'LOC' &&
                  item.loc === user.loccod &&
                  parseInt(selectedYear, 10) >= new Date().getUTCFullYear() && (
                    <O.Delete
                      onClick={() =>
                        setDeleteDiag({
                          open: true,
                          content: (
                            <ModalDeleteContent>
                              {item.qtd === 0 ? (
                                <>
                                  <p>
                                    Você está prestes a excluir o Dia Universal
                                    do Guia:
                                  </p>
                                  <div>
                                    <span>
                                      <p>
                                        Data:&nbsp;
                                        <strong>{formatDate(item.date)}</strong>
                                      </p>
                                    </span>
                                    <span>
                                      <p>
                                        Local de Serviço/Atividade:&nbsp;
                                        <strong>{item.prqdesc}</strong>
                                      </p>
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <p>
                                    Há participantes cadastrados neste Dia
                                    Universal do Guia.
                                  </p>

                                  <p>
                                    Deve-se excluí-los antes de prosseguir com a
                                    exclusão!
                                  </p>
                                </>
                              )}
                            </ModalDeleteContent>
                          ),
                          values: {
                            qtd: item.qtd,
                            prq: item.prq,
                            date: item.date,
                            anb: item.anb,
                            loc: item.loc,
                          },
                        })
                      }
                    >
                      <FaTimes />
                    </O.Delete>
                  )}
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            {deleteDiag.values && (
              <>
                {deleteDiag.values?.qtd === 0 ? (
                  <>
                    <D.Cancel
                      type="button"
                      onClick={() => setDeleteDiag({ open: false })}
                    >
                      Cancelar
                    </D.Cancel>
                    <D.Confirm
                      type="button"
                      onClick={() => handleExclusion(deleteDiag.values)}
                    >
                      Confirmar
                    </D.Confirm>
                  </>
                ) : (
                  <D.Confirm
                    type="button"
                    onClick={() => setDeleteDiag({ open: false })}
                  >
                    Ok
                  </D.Confirm>
                )}
              </>
            )}
          </D.Actions>
        </D.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default DUG;
