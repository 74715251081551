import styled from 'styled-components';
import { animated } from 'react-spring';

export const FormContent = styled.div`
  border: 2px solid #efefef;
  border-radius: 5px;
  background: #fff;
  padding: 5px 10px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    > p {
      font-size: 16px;
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
  }
`;

export const CoordSpan = styled(animated.div)`
  > p {
    font-size: 16px;
    color: #8a0002;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
  }

  & + span {
    background: #c53030;
    margin-top: 25px;
  }
`;
