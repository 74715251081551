import styled, { css } from 'styled-components';

interface ContainerProps {
  error?: boolean;
}

export const Select = styled.div<ContainerProps>`
  background: #efefef;
  width: 350px;
  height: 40px;

  border: 2px solid #a6a6a6;
  border-radius: 5px;
  color: #332e2e;

  ${(props) =>
    props.error &&
    css`
      border-color: #ff0000;
      background: #ffc7c7;
    `}

  z-index: 149;

  select {
    display: flex;
    background: transparent;

    cursor: pointer;
    width: 100%;
    height: 100%;
    border-width: 0px;
    padding: 5px 5px;
    overflow: visible;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 14px;
    border: 0;
    border-radius: 2px;

    option {
      height: 40px;
      margin: 10px 0;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
      color: #332e2e;
      background: #efefef;

      &:checked {
        background: #bc4143;
        color: #fff;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
