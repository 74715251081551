import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import RadioContainer from 'components/RadioContainer';
import { useCredentials } from 'hooks/credentials';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  setLocalStorage,
  getLocalStorage,
  localStorageLength,
} from 'utils/handleLocalStorage';

import api from 'services/api';
import { LocalStorageProps } from '../main';
import { GridContainer, GridItem, RadioDIV } from './styles';

interface ListProps {
  matgr: string;
  cod: string;
  desc: string;
  qtd: number;
  group: string;
}

const PedidoFornecedor: React.FC = () => {
  const { errorHandling, handlePermission } = useCredentials();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [groupFilter] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_PEDIDO}`);

    if (hasItem > 0) {
      const { group }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PEDIDO}`,
      );

      if (group) {
        return group;
      }

      return '';
    }

    return '';
  });

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/pedido_fornecedor.php');
      setRawList(response.data);
      setList(
        response.data.filter((item: ListProps) =>
          groupFilter === '' ? item : item.group === groupFilter,
        ),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, groupFilter]);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getList();
  }, [getList, handlePermission]);

  const handleRadioClick = useCallback(() => {
    const group = formRef.current?.getFieldValue('group');
    setLocalStorage(`${process.env.REACT_APP_PEDIDO}`, { group });

    setList(
      rawList.filter((item: ListProps) =>
        group === '' ? item : item.group === group,
      ),
    );
  }, [rawList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <Form ref={formRef} onSubmit={() => null}>
          <RadioDIV>
            <RadioContainer
              title="Filtre por Grupo"
              onClick={handleRadioClick}
              name="group"
              content={[
                {
                  id: 'aG',
                  value: '',
                  label: 'Todos',
                },
                {
                  id: 'aC',
                  value: 'C',
                  label: 'Malharia',
                },
                {
                  id: 'aG',
                  value: 'G',
                  label: 'Gráfica',
                },
                {
                  id: 'aM',
                  value: 'M',
                  label: 'Mídia',
                },
              ]}
              selected={groupFilter}
            />
          </RadioDIV>
        </Form>
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem>
              <p>{item.desc}</p>
              <span>
                <p>
                  Grupo do Material:&nbsp;
                  <strong>{item.matgr}</strong>
                </p>
              </span>
              <span>
                <p>
                  Código do Material:&nbsp;
                  <strong>{item.cod}</strong>
                </p>
              </span>
              <span>
                <p>
                  Total do Pedido:&nbsp;
                  <strong>{item.qtd}</strong>
                </p>
              </span>
            </GridItem>
          ))}
        </GridContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default PedidoFornecedor;
