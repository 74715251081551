import styled from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  width: 500px;

  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      > p {
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-weight: 500;
        font-size: 16px;
      }

      & + span {
        margin: 15px 0 0 0;
      }
    }

    /* & + div {
      margin: 50px 0 0 0;
    } */
  }

  transition: all 0.35s ease;
  @media screen and (max-width: 500px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const Block = styled(animated.div)`
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const Attention = styled.span`
  padding: 5px 10px;
  background: red;
  border-radius: 3px;

  color: white;
  margin: 0 0 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  strong {
    font-size: 16px;
    text-align: center;
  }

  svg {
    position: absolute;
    left: 5px;
    height: 20px;
    width: 20px;
  }

  transition: all 0.35s ease;
  @media screen and (max-width: 500px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 460px) {
    svg {
      opacity: 0;
    }
  }
`;

export const Warn = styled(animated.div)`
  /* transform: translateY(-15px); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;

  h4 {
    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    color: #464646;
    margin: 0 0 15px 0;
  }
  > p {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Roboto Slab', serif;
    color: #464646;
    strong {
      /* font-family: 'Esperando Arthur', serif; */
      font-size: 18px;
      color: black;
      font-weight: bolder;
    }

    & + p {
      margin: 5px 0 0 0;
    }
  }
`;
