import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { FaSearch, FaPencilAlt, FaFileExcel } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { AlteredContent, Container, Header } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import ScrollTop from 'components/ScrollTop';

import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import PrintButton from 'components/PrintButton';
import SelectV2 from 'components/SelectV2';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import { deconvertSpecialChars } from 'utils/specialChars';

import api from 'services/api';

import * as S from 'styles/dialog_consult';
import * as O from 'styles/option_buttons';
import { ShowProps } from 'styles/dialog_consult';
import MoreInfoButton from 'components/MoreInfo';
import { GridContainer, GridItem, GridFront, StyledForm } from './styles';

interface MaterialProps {
  cntr: string;
  grucod: string;
  grudesc: string;
  matcod: string;
  matdesc: string;
  sta: string;
  venda: string;
  updated_at: string;
  vlrgui: string;
  vlrloc: string;
  vlrnac: string;
  vlrgui_mod: string;
  vlrloc_mod: string;
  vlrnac_mod: string;
  webdesc: string;
}

interface ComboContent {
  value: string;
  label: string;
}

interface GrupoContent {
  grucod: string;
  grudesc: string;
}

const Materiais: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();
  const [materiais, setMateriais] = useState<MaterialProps[]>([]);
  const [filterMats, setFilterMats] = useState<MaterialProps[]>([]);
  const [comboGrupos, setComboGrupos] = useState<ComboContent[]>([]);

  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const [loading, setLoading] = useState(true);

  const getComboGrupo = useCallback(async () => {
    try {
      const response = await api.get('combos/comboGrupoMaterial.php');
      const { groups } = response.data;

      // const groupCombo: ComboContent[] = groups.map((item: GrupoContent) => {
      //   return {
      //     value: item.grucod,
      //     label: deconvertSpecialChars(item.grudesc),
      //   };
      // });

      // groupCombo.unshift({
      //   value: 'all',
      //   label: 'Todos Materiais',
      // });

      const groupCombo: ComboContent[] = [
        { value: '', label: 'Todos Materiais' },
        ...groups.map((item: GrupoContent) => ({
          value: item.grucod,
          label: deconvertSpecialChars(item.grudesc),
        })),
      ];

      setComboGrupos(groupCombo);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const getMateriais = useCallback(async () => {
    try {
      const response = await api.get('/sgo/material_tov_list.php');

      const setMats = response.data.map((mat: MaterialProps) => ({
        cntr: mat.cntr,
        grucod: mat.grucod,
        grudesc: deconvertSpecialChars(mat.grudesc),
        matcod: mat.matcod,
        matdesc: deconvertSpecialChars(mat.matdesc),
        sta: mat.sta,
        updated_at: mat.updated_at,
        venda: mat.venda,
        vlrgui: mat.vlrgui,
        vlrloc: mat.vlrloc,
        vlrnac: mat.vlrnac,
        vlrgui_mod: mat.vlrgui.replace('.', ','),
        vlrloc_mod: mat.vlrloc.replace('.', ','),
        vlrnac_mod: mat.vlrnac.replace('.', ','),
        // webdesc: deconvertSpecialChars(mat.webdesc),
        webdesc: mat.webdesc,
      }));
      setLoading(false);
      setMateriais(setMats);
      setFilterMats(setMats);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleShow = useCallback(({ open, content, title }: ShowProps) => {
    setShow({ open, content, title });
  }, []);

  useEffect(() => {
    getMateriais();
    getComboGrupo();
  }, [getMateriais, getComboGrupo]);

  const handleSubmit = useCallback(() => {
    return null;
  }, []);

  const handleGroupBlur = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboGrupos');
    const { value } = select.options[select.selectedIndex];
    // const checkForm = formRef.current;

    // if (checkForm) {
    // const selected = checkForm.getFieldValue('comboGrupos');

    const newMats = materiais.filter((item) =>
      value === '' ? item : item.grucod === value,
    );
    setFilterMats(newMats);
    // }
  }, [materiais]);

  return (
    <Container>
      {user.adm && <IncludeButton />}
      {(['GUI', 'INT'].indexOf(user.perfil) < 0 ||
        (user.adm && user.perfil !== 'INT')) && (
        <PrintButton icon={FaFileExcel} linkTo="/sgo/xls_materiais_tov.php" />
      )}
      <MoreInfoButton routeName="more-details" />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Material TOV</Header>

      <StyledForm ref={formRef} initialData={{}} onSubmit={handleSubmit}>
        <SelectV2
          name="comboGrupos"
          initial=""
          content={comboGrupos}
          onChange={handleGroupBlur}
          containerStyle={{
            boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
          }}
        />
      </StyledForm>

      <AlteredContent pixels="280px">
        <Loading isLoading={loading} />

        <GridContainer>
          {filterMats.map((mat: MaterialProps) => (
            <GridItem
              key={mat.matcod}
              style={{ borderColor: mat.sta === 'A' ? '#00802b' : '#8f0002' }}
            >
              <GridFront>
                <h4>
                  {mat.matcod} - {mat.matdesc}
                </h4>
                <h5>Valor</h5>
                <div>
                  <span>
                    <p>
                      NAC: <strong>R$ {mat.vlrnac_mod}</strong>
                    </p>
                  </span>

                  <span>
                    <p>
                      LOC: <strong>R$ {mat.vlrloc_mod}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      GUI: <strong>R$ {mat.vlrgui_mod}</strong>
                    </p>
                  </span>
                </div>
              </GridFront>
              <O.GridOptions>
                <O.Show
                  onClick={() =>
                    handleShow({
                      title: mat.matdesc,
                      open: true,
                      content: (
                        <div style={{ width: '100%' }}>
                          <p>
                            Grupo:&nbsp;<strong>{mat.grudesc}</strong>
                          </p>
                          <br />
                          <p>
                            <strong
                              style={
                                mat.sta === 'A'
                                  ? { color: '#00802b' }
                                  : { color: '#c53030' }
                              }
                            >
                              Material&nbsp;
                              {mat.sta === 'A' ? 'Ativo' : 'Inativo'}
                            </strong>
                          </p>
                          <p>
                            <strong
                              style={
                                mat.cntr === 'S'
                                  ? { color: '#00802b' }
                                  : { color: '#c53030' }
                              }
                            >
                              {mat.cntr === 'S' ? 'C' : 'Não é c'}ontrolado por
                              numeração
                            </strong>
                          </p>
                          <p>
                            <strong
                              style={
                                mat.venda === 'S'
                                  ? { color: '#00802b' }
                                  : { color: '#c53030' }
                              }
                            >
                              {mat.venda === 'S' ? 'C' : 'Não é c'}
                              omercializável
                            </strong>
                          </p>
                          <br />
                          {mat.matcod.substr(0, 2) !== 'DM' && mat.sta === 'A' && (
                            <p>
                              Descrição:&nbsp;
                              <strong>
                                {deconvertSpecialChars(mat.webdesc)}
                              </strong>
                            </p>
                          )}

                          <br />
                          <br />
                          <p>
                            Última atualização:&nbsp;
                            <strong>{mat.updated_at}</strong>
                          </p>
                        </div>
                      ),
                    })
                  }
                >
                  <FaSearch />
                </O.Show>
                {((user.perfil === 'ZON' && mat.grucod !== 'DM') ||
                  (user.perfil === 'NAC' && mat.grucod === 'DM') ||
                  user.adm) && (
                  <O.Update
                    to={{
                      pathname: `${pathname}/update`,
                      state: {
                        grudesc: mat.grudesc,
                        matcod: mat.matcod,
                        matdesc: mat.matdesc,
                        vlrgui: mat.vlrgui,
                        vlrloc: mat.vlrloc,
                        vlrnac: mat.vlrnac,
                        cntr: mat.cntr,
                        venda: mat.venda,
                        sta: mat.sta,
                        webdesc: mat.webdesc,
                      },
                    }}
                  >
                    <FaPencilAlt />
                  </O.Update>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Materiais;
