import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { animated } from 'react-spring';

interface NameProps {
  mode: string;
}

export const Print = styled(animated.div)`
  margin: 20px 0 0 0;
  overflow: hidden;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;

  button {
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #332e2e;
    color: #efefef;
    padding: 10px 20px;
    width: 250px;
    border: 2px solid transparent;
    border-radius: 5px;

    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    svg {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 18px;
      margin: 0 0 0 5px;
    }

    transition: 0.35s ease;
    &:hover {
      background-color: ${shade(0.2, '#332e2e')};
    }
  }
`;

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 800px;

  /* transition: width 0.5s ease, transform 0.5s ease, margin-right 0.5s ease; */
  @media screen and (max-width: 800px) {
    width: 100vw;
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;

    /* transition: width 0s ease, transform 0.5s ease, margin-right 0.5s ease; */
  }

  /* @media screen and (max-width: 710px) {
    width: 360px;
  }

  @media screen and (max-width: 480px) {
    align-self: left;
    margin-right: 100%;
    transform: translateX(50%);

    border-radius: 0 5px 5px 0;
    border-left-width: 0px;
  }

  @media screen and (max-width: 415px) {
    width: 300px;
  } */
`;

export const Table = styled.table`
  /* margin: 20px auto 50px; */

  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  /* min-width: 600px; */
  width: 100%;

  /* width: 1366px; */
  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    display: flex;
    align-items: center;
    min-height: 40px;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;
  }
`;

export const Name = styled.td<NameProps>`
  width: calc(100% - 170px);
  ${(props) =>
    props.mode === 'N' &&
    css`
      width: calc(100% - 120px);
    `}

  ${(props) =>
    props.mode === 'L' &&
    css`
      width: calc(100% - 60px);
    `}
`;

export const Nac = styled.td`
  width: 250px;
  @media screen and (max-width: 800px) {
    width: 170px;
  }
  @media screen and (max-width: 595px) {
    width: 100px;
  }
  @media screen and (max-width: 420px) {
    width: 60px;
  }
`;

export const Loc = styled.td`
  width: 250px;
  @media screen and (max-width: 800px) {
    width: 170px;
  }
  @media screen and (max-width: 595px) {
    width: 100px;
  }
  @media screen and (max-width: 420px) {
    width: 60px;
  }
`;

export const Cert = styled.td`
  width: 130px;
  text-align: center;
  @media screen and (max-width: 595px) {
    width: 100px;
  }
  @media screen and (max-width: 420px) {
    width: 60px;
  }
`;
