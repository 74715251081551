import styled from 'styled-components';
import { shade } from 'polished';

export const TotalGuias = styled.h3`
  font-size: 18.72px;
  margin: 10px auto;

  /* z-index: 1; */

  padding: 5px;
  width: 100%;
  text-align: center;

  text-shadow: 0px 0px 5px #8a0002;
  color: #efefef;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(197, 48, 48, 0.25),
    rgba(197, 48, 48, 0.5),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(197, 48, 48, 0.5),
    rgba(197, 48, 48, 0.25),
    rgba(255, 255, 255, 0)
  );
  text-align: center;
`;

export const Grid = styled.div`
  padding: 35px 0 10px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1800px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    grid: auto / 1fr;
    /* column-gap: 0px; */
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;

  position: relative;

  padding-bottom: 10px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    > figure {
      transform: translateY(-40%) scale(1.1);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const GridItemAvatar = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #332e2e;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  transform: translateY(-35%);

  transition: all 0.5s;

  img {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const GridItemText = styled.span`
  padding: 0 10px;
  margin-top: -25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong,
  p {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
`;

export const GridItemInfo = styled.span``;

export const ModalContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  /* flex-direction: column; */

  img {
    border: 5px solid #332e2e;
    margin: 8px auto 8px auto;
    height: 175px;
    width: 175px;
    object-fit: cover;
    border-radius: 50%;
  }

  section {
    display: flex;
    flex-direction: column;
    > span {
      width: 100%;
      padding: 3px 5px;
      border: 2px solid transparent;
      border-bottom-color: #d6d6d6;

      p {
        color: #767676;
        strong {
          color: #332e2e;
        }
        & + p {
          margin-top: 3px;
        }
      }
      & + span {
        margin-top: 10px;
      }
    }

    & + section {
      margin: 0 0 0 25px;
    }
  }

  @media screen and (max-width: 910px) {
    flex-direction: column;

    section + section {
      margin: 20px 0 0 0;
    }
  }
`;

export const Warn = styled.p`
  margin: 5px 0 0 0;
  width: 100%;
  display: flex;
  padding: 3px 5px;
  color: #fff;
  background-color: #8a0002;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

export const FIGLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #008097;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#008097')};
  }
`;
