import React, { useCallback, useEffect, useState, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import TextareaDialog from 'components/TextareaDialog';
import InputDialog from 'components/InputDialog';

import {
  Container,
  Content,
  AlteredHeader,
  TableButton,
} from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';

import { formatNumber } from 'utils/calcTotal';

import api from 'services/api';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as U from 'styles/dialog_update';
import { UpdateProps, ModalContent } from 'styles/dialog_update';
import { FaDollarSign, FaPencilAlt, FaSave } from 'react-icons/fa';

import {
  Table,
  TDMonth,
  TDPrevious2,
  TDPrevious,
  TDCurrent,
  TDCompl,
  TDUpdate,
} from './styles';

interface StateProps {
  lanccod: string;
  lancdesc: string;
  cod: string;
  desc: string;
  year: number;
}

export interface PlanDataProps {
  month: number;
  monthdesc: string;
  previous2: string;
  previous: string;
  current: string;
  description: string;
}

const PlanejamentoUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling } = useCredentials();
  const { width } = useWindow();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [planData, setPlanData] = useState<PlanDataProps[]>([]);
  const [planPrevData, setPlanPrevData] = useState<PlanDataProps[]>([]);
  const [state] = useState<StateProps>(() => {
    return location.state || ({} as StateProps);
  });

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({} as UpdateProps);

  const getData = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/planejamento_data.php?data=${JSON.stringify({
          mode: 'update',
          cod: state.cod,
          lanccod: state.lanccod,
          year: state.year,
        })}`,
      );
      // alert(JSON.stringify(response.data, null, 2));
      setPlanData(response.data);
      setPlanPrevData(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, state.cod, state.lanccod, state.year]);

  useEffect(() => {
    if (!location.state) {
      history.replace(`${location.pathname.replace('/consult', '')}`);
      return;
    }
    // getComboYears();
    getData();
  }, [history, location.pathname, location.state, getData]);

  const handleChanges = useCallback(() => {
    // const index = planData.findIndex(
    //   (item) => item.month === updateDiag.values?.month,
    // );

    // if (index > -1) {
    setPlanData((prev) => [
      ...prev.map((item: PlanDataProps, itemIndex) => {
        return itemIndex ===
          prev.findIndex(
            (item2: PlanDataProps) => item2.month === updateDiag.values?.month,
          )
          ? {
              ...item,
              current: parseFloat(
                formRef.current?.getFieldValue('planned'),
              ).toString(),
              description: formRef.current?.getFieldValue('compl'),
            }
          : { ...item };
      }),
    ]);

    setUpdateDiag({} as UpdateProps);
    // }
  }, [updateDiag.values]);

  const handleCopyPrev = useCallback(() => {
    if (!updateDiag.values?.month) {
      return;
    }

    const diagMonth = updateDiag.values.month.toString();

    setPlanData((plan) => {
      const index = plan.findIndex(
        (item) => item.month === parseInt(diagMonth, 10),
      );
      formRef.current?.setFieldValue('planned', plan[index].previous);
      return plan.map((item) =>
        item.month === parseInt(diagMonth, 10)
          ? { ...item, current: item.previous }
          : { ...item },
      );
    });
  }, [updateDiag.values]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();

      send.append(
        'data',
        JSON.stringify({
          values: planData.map((item) => ({
            month: item.month,
            value: item.current,
            compl: item.description,
          })),
          cod: state.cod,
          year: state.year,
          lanccod: state.lanccod,
        }),
      );

      await api.post('/sgo/planejamento_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Planejamento atualizado',
      });

      history.goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    history,
    planData,
    state.cod,
    state.lanccod,
    state.year,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks title="Planejamento Financeiro" isResponsible />
      <Content>
        <AlteredHeader>
          <div>
            {state.desc} | {state.year}
          </div>
          <div>{state.lancdesc}</div>
        </AlteredHeader>
        <Table>
          <thead>
            <tr>
              <TDMonth>Mês</TDMonth>
              {state.year - 2 >= 2017 && (
                <TDPrevious2>{state.year - 2}</TDPrevious2>
              )}
              {state.year - 1 >= 2017 && (
                <TDPrevious>{state.year - 1}</TDPrevious>
              )}
              <TDCurrent>{state.year}</TDCurrent>
              <TDCompl>Complemento</TDCompl>
              <TDUpdate>&nbsp;</TDUpdate>
            </tr>
          </thead>
          <tbody>
            {planData.map((item, index) => (
              <tr key={item.month}>
                <TDMonth paint={index + 1}>
                  {width > 600
                    ? item.monthdesc
                    : `${item.monthdesc.substr(0, 3)}.`}
                </TDMonth>
                {state.year - 2 >= 2017 && (
                  <TDPrevious2
                    paint={index + 1}
                    bgcolor="#fff2f4"
                    style={{
                      justifyContent: item.previous2 ? 'flex-end' : 'center',
                    }}
                  >
                    {item.previous2
                      ? `R$ ${formatNumber(parseFloat(item.previous2))}`
                      : '-'}
                  </TDPrevious2>
                )}
                {state.year - 1 >= 2017 && (
                  <TDPrevious
                    paint={index + 1}
                    bgcolor="#ffe6e8"
                    style={{
                      justifyContent: item.previous ? 'flex-end' : 'center',
                    }}
                  >
                    {item.previous
                      ? `R$ ${formatNumber(parseFloat(item.previous))}`
                      : '-'}
                  </TDPrevious>
                )}
                <TDCurrent
                  paint={index + 1}
                  bgcolor="#ffd9db"
                  style={{
                    justifyContent: item.current ? 'flex-end' : 'center',
                  }}
                >
                  {item.current
                    ? `R$ ${formatNumber(parseFloat(item.current))}`
                    : '-'}
                </TDCurrent>

                <TDCompl
                  paint={index + 1}
                  style={{
                    justifyContent: item.description ? 'flex-start' : 'center',
                  }}
                >
                  {item.description || '-'}
                </TDCompl>
                <TDUpdate paint={index + 1} bgcolor="#d1edff">
                  <TableButton
                    type="button"
                    background="#007acc"
                    onClick={() =>
                      setUpdateDiag({
                        title: item.monthdesc,
                        open: true,
                        values: {
                          month: item.month,
                          value: item.current,
                          prev: item.previous,
                          compl: item.description,
                        },
                      })
                    }
                  >
                    <FaPencilAlt />
                  </TableButton>
                </TDUpdate>
              </tr>
            ))}
          </tbody>
        </Table>
        {JSON.stringify(planData) !== JSON.stringify(planPrevData) && (
          <div>
            <Button type="button" bgcolor="#464646" onClick={handleSubmit}>
              <FaSave />
              <p>Salvar alterações</p>
            </Button>
          </div>
        )}
      </Content>
      <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
        <U.Title>
          <h2>
            {updateDiag?.title} | {state.year}
          </h2>
        </U.Title>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            planned: parseFloat(
              (updateDiag.values?.value
                ? updateDiag.values?.value
                : 0
              ).toString(),
            ).toFixed(2),
            compl: updateDiag.values?.compl || '',
          }}
        >
          <U.Content>
            <ModalContent>
              {updateDiag.values?.prev && (
                <span
                  style={{
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleCopyPrev}
                    bgcolor="#464646"
                    containerStyle={{ margin: '0 auto 10px', height: '30px' }}
                  >
                    Copiar do ano anterior
                  </Button>
                </span>
              )}
              <span>
                <p>Valor:</p>
                <InputDialog
                  icon={FaDollarSign}
                  name="planned"
                  type="number"
                  mask="currency"
                  placeholder="0,00"
                />
              </span>

              <span>
                <p>Complemento:</p>
                <TextareaDialog
                  name="compl"
                  placeholder="Descrição do planejamento"
                />
              </span>
            </ModalContent>
          </U.Content>
          <U.Actions>
            <U.Cancel
              type="button"
              onClick={() =>
                setUpdateDiag((prev) => ({ ...prev, open: false }))
              }
            >
              Cancelar
            </U.Cancel>
            <U.Confirm type="button" onClick={handleChanges}>
              Confirmar
            </U.Confirm>
          </U.Actions>
        </Form>
      </U.Container>
      <SGOFooter />
    </Container>
  );
};

export default PlanejamentoUpdate;
