import React, { useCallback, useState, useEffect, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import { MonthListProps, monthList } from 'pages/sgo/Tesouraria/monthList';

import {
  AlteredContent,
  ComboLocalProps,
  ComboProps,
  Container,
  Header,
  RemoveButton,
} from 'styles/sgo_wrappers';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';

import api from 'services/api';
import { FaBirthdayCake, FaFileExcel, FaTimes } from 'react-icons/fa';
// import { formatDate } from 'utils/formatDate';
import SelectV2 from 'components/SelectV2';

import PrintButton from 'components/PrintButton';
import {
  Birthday,
  Grid,
  GridItem,
  GridItemAvatar,
  GridItemText,
  LeftSVG,
  MonthContainer,
  RightSVG,
} from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  loccod?: string;
  locdesc?: string;
  anbcod?: string;
  anbdesc?: string;
  mes?: number;
  cod?: string;
  desc?: string;
  filterBy?: string;
}

interface ListProps {
  gseq: number;
  day: number;
  month: number;
  year: number;
  date: string;
  name: string;
  cert: string;
  phone: string;
  tgdesc: string;
  loccod: string;
  locdesc: string;
  anbcod: string;
  anbdesc: string;
  avatar: string;
}

const Aniversariantes: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { handlePermission, errorHandling } = useCredentials();

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    return cod && desc
      ? { cod, desc }
      : user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [month, setMonth] = useState<MonthListProps>(() => {
    const { mes }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    const index = monthList.findIndex(
      (item: MonthListProps) =>
        item.value ===
        (mes || new Date(new Date().toISOString()).getUTCMonth() + 1),
    );

    return { value: monthList[index].value, label: monthList[index].label };
  });

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);

  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [filter, setFilter] = useState(() => {
    const { filterBy }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    return (
      filterBy ||
      (user.perfil === 'ZON'
        ? 'zonal'
        : user.perfil === 'NAC'
        ? 'anb'
        : 'local')
    );
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    return anbcod || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    return loccod || 'Selecione';
  });

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/aniversariantes_list.php');
      setRawList(response.data);

      setList(() => {
        const { cod }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_ANIVERSARIANTES}`,
        );

        return filter === 'zonal'
          ? response.data.filter(
              (item: ListProps) => item.month === month.value,
            )
          : filter === 'anb'
          ? response.data.filter(
              (item: ListProps) =>
                item.month === month.value &&
                item.anbcod === (cod || user.anbc),
            )
          : response.data.filter(
              (item: ListProps) =>
                item.month === month.value &&
                item.loccod === (cod || user.loccod),
            );
      });

      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling, user.anbc, user.loccod, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    // setInitialNac(user.anbdesc);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php`,
      // `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    if (user.perfil === 'NAC' || !!anbcod) {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anbcod || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  useEffect(() => {
    handlePermission(['INT']);
    getComboANB();
    getComboLOC();
    getList();
  }, [getComboANB, getComboLOC, getList, handlePermission]);

  const handleDecreaseMonth = useCallback(() => {
    const current = month.value - 1;

    if (current >= 1) {
      const index = monthList.findIndex(
        (item: MonthListProps) => item.value === current,
      );
      setMonth({
        value: monthList[index].value,
        label: monthList[index].label,
      });

      setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
        mes: monthList[index].value,
      });

      const { cod }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_ANIVERSARIANTES}`,
      );

      setList(() =>
        rawList.filter(
          (item) =>
            item.month === current &&
            (filter === 'zonal'
              ? item.anbcod.substr(0, 2) === user.zoncod.substr(0, 2)
              : filter === 'anb'
              ? item.anbcod === (cod || user.anbc)
              : item.loccod === (cod || user.loccod)),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month.value, rawList, user.anbc, user.loccod, user.zoncod]);

  const handleAddMonth = useCallback(() => {
    const current = month.value + 1;

    if (current <= 12) {
      const index = monthList.findIndex(
        (item: MonthListProps) => item.value === current,
      );
      setMonth({
        value: monthList[index].value,
        label: monthList[index].label,
      });

      setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
        mes: monthList[index].value,
      });

      const { cod }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_ANIVERSARIANTES}`,
      );

      setList(() =>
        rawList.filter(
          (item) =>
            item.month === current &&
            (filter === 'zonal'
              ? item.anbcod.substr(0, 2) === user.zoncod.substr(0, 2)
              : filter === 'anb'
              ? item.anbcod === (cod || user.anbc)
              : item.loccod === (cod || user.loccod)),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month.value, rawList, user.anbc, user.loccod, user.zoncod]);

  const handleANBselect = useCallback(() => {
    setInitialLoc('Selecione');
    setFilter('anb');
    setComboLocs([]);
    const anb = formRef.current?.getFieldValue('comboANB');

    const index = comboNacs.findIndex((item: ComboProps) => item.value === anb);

    setComboLocs(locs.filter((item) => item.anb === anb));
    setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
      anbcod: anb,
      anbdesc: comboNacs[index].label,
      cod: anb,
      desc: comboNacs[index].label,
      filterBy: 'anb',
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_ANIVERSARIANTES}`, [
      'loccod',
      'locdesc',
    ]);

    setCoord({
      cod: anb,
      desc: comboNacs[index].label,
    });
    setInitialNac(comboNacs[index].label);

    setList(() =>
      rawList.filter(
        (item) => item.anbcod === anb && item.month === month.value,
      ),
    );
  }, [comboNacs, locs, month.value, rawList]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLocal');
    const index = comboLocs.findIndex((item: ComboProps) => item.value === loc);
    setFilter('local');
    setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
      loccod: loc,
      locdesc: comboLocs[index].label,
      cod: loc,
      desc: comboLocs[index].label,
      filterBy: 'local',
    });

    setCoord({
      cod: loc,
      desc: comboLocs[index].label,
    });
    setInitialLoc(comboLocs[index].label);

    setList(() => [
      ...rawList.filter(
        (item) => item.loccod === loc && item.month === month.value,
      ),
    ]);
  }, [comboLocs, month.value, rawList]);

  const removeFilter = useCallback(() => {
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    setFilter('zonal');
    formRef.current?.setFieldValue('comboANB', '');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_ANIVERSARIANTES}`, [
      'anbcod',
      'anbdesc',
      'loccod',
      'locdesc',
    ]);
    setComboLocs([]);

    setCoord({
      cod: user.zoncod,
      desc: user.zondesc,
    });

    setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
      cod: user.zoncod,
      desc: user.zondesc,
      filterBy: 'zonal',
    });

    setList(() => [
      ...rawList.filter(
        (item) =>
          item.anbcod.substr(0, 2) === user.zoncod.substr(0, 2) &&
          item.month === month.value,
      ),
    ]);
  }, [month.value, rawList, user.zoncod, user.zondesc]);

  const removeLoc = useCallback(() => {
    const { anbcod, anbdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ANIVERSARIANTES}`,
    );

    setCoord({
      cod: anbcod || user.anbc,
      desc: anbdesc || user.anbdesc,
    });

    setLocalStorage(`${process.env.REACT_APP_ANIVERSARIANTES}`, {
      cod: anbcod || user.anbc,
      desc: anbdesc || user.anbdesc,
      filterBy: 'anb',
    });

    setInitialLoc('Selecione');
    setFilter('anb');
    formRef.current?.setFieldValue('comboLocal', '');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_ANIVERSARIANTES}`, [
      'loccod',
      'locdesc',
    ]);

    setList(() => [
      ...rawList.filter(
        (item) =>
          item.anbcod === (anbcod || user.anbc) && item.month === month.value,
      ),
    ]);
  }, [month.value, rawList, user.anbc, user.anbdesc]);

  const filterRemove = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  const locFilterRemove = useSpring({
    opacity: initialLoc !== 'Selecione' ? 1 : 0,
    pointerEvents: initialLoc !== 'Selecione' ? 'all' : 'none',
  });

  return (
    <Container>
      {['LOC', 'NAC', 'ZON'].indexOf(user.perfil) > -1 ? (
        <PrintButton
          icon={FaFileExcel}
          hasProps
          linkTo={`/sgo/xls_aniversariantes.php?data=${JSON.stringify({
            cod: coord.cod,
            type: filter === 'zonal' ? 'ZON' : filter === 'anb' ? 'NAC' : 'LOC',
            mes: month.value,
          })}`}
        />
      ) : null}
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                  {/* <Select
                    name="comboANB"
                    content={comboNacs}
                    onBlur={handleANBselect}
                    initial={initialNac}
                  /> */}
                  <RemoveButton
                    type="button"
                    style={filterRemove}
                    onClick={removeFilter}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>

                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                  <RemoveButton
                    type="button"
                    style={locFilterRemove}
                    onClick={removeLoc}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
            </div>
          </Form>
        }
      />
      <Header>
        <div>Aniversariantes</div>
        <div>{coord.desc}</div>
      </Header>
      <MonthContainer>
        {month.value > 1 && <LeftSVG onClick={handleDecreaseMonth} />}
        <p>{month.label}</p>
        {month.value < 12 && <RightSVG onClick={handleAddMonth} />}
      </MonthContainer>

      {/* <FilterContainer>
          <LeftSVG onClick={handleLessMonth} />
          <p>{month.label}</p>&nbsp;/&nbsp;<p>{year}</p>
          <RightSVG onClick={handleMoreMonth} />
        </FilterContainer> */}
      <AlteredContent pixels="309px">
        <Grid>
          {list.map((item) => (
            <GridItem key={item.gseq}>
              <GridItemAvatar>
                <img
                  src={`${process.env.REACT_APP_AVATAR}${item.avatar}`}
                  alt={item.name}
                />
              </GridItemAvatar>
              <GridItemText>
                <strong>{item.name}</strong>
                {user.perfil === 'ZON' && initialNac === 'Selecione' && (
                  <p>{item.anbdesc}</p>
                )}
                {user.perfil !== 'LOC' && initialLoc === 'Selecione' && (
                  <p>{item.locdesc}</p>
                )}

                <Birthday>
                  <FaBirthdayCake />
                  <span>
                    <p>
                      {`0${item.day}`.slice(-2)}/{`0${item.month}`.slice(-2)}
                    </p>
                    <p>
                      {new Date().getTime() >=
                      new Date(
                        new Date().getUTCFullYear(),
                        item.month - 1,
                        item.day,
                      ).getTime()
                        ? `${new Date().getUTCFullYear() - item.year}`
                        : `${new Date().getUTCFullYear() - item.year - 1}`}
                      &nbsp;anos
                    </p>
                  </span>
                </Birthday>
              </GridItemText>
            </GridItem>
          ))}
        </Grid>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Aniversariantes;
