export const content = {
  title: 'O Profeta da Oração',
  p1:
    'Ignacio Larrañaga Orbegozo nasceu no povoado de Beristain Chanton, cidade de Azpeitia, localizada no País Basco, comunidade autônoma a Espanha, no dia 4 de maio de 1928. Seus pais, Marcelino Larrañaga e Maria Salomé Orbegozo, formaram uma família com nove filhos.',
  p2:
    'Em 1940, aos 12 anos, Ignacio Larrañaga ingressa no Seminário dos Capuchinhos de Alsasua. Em 29 de março de 1952, recebe o subdiaconato, na cidade de Pamplona. É ordenado sacerdote OFM capuchinho em San Sebastián no dia 20 de dezembro de 1952.',
  p3:
    'Radicado no Chile a partir de 1959, implantou um apostolado variado e fecundo. Empreendeu uma série de Semanas de Convivência Franciscana ao longo da estreita e longa geografia chilena.',
  p4:
    'A partir de 1974, aplicou o Encontro de Experiência de Deus (EED) por trinta e seis anos ininterruptos, em trinta e quatro países de quatro continentes, com uma média de trinta e cinco a quarenta EEDs anuais e uma assistência média de 250 pessoas por semana.',
  p5:
    'Nesse mesmo ano, com 46 anos de idade, frei Ignacio inicia seu trabalho de escritor, atividade que resultou em mais de vinte livros até 2011. Nos meios católicos, ele é celebrado como um dos autores de maior difusão de na literatura religiosa.',
  p6:
    'Frei Ignacio Larrañaga partiu para casa do Pai aos 85 anos de idade, em Guadalajara, no México, no dia 28 de outubro de 2013. Até o dia anterior, conduzira as atividades da 60ª Semana de Culminância, seu périplo pelo mundo, iniciado em 2012, na tarefa de Refundação das Oficinas de Oração e Vida.',
};
