import React, { useState, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';

import api from 'services/api';
import * as yup from 'yup';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { FaSearch, FaTimes } from 'react-icons/fa';
import getValidationErrors from 'utils/getValidationErrors';
import { useSpring } from 'react-spring';
import {
  Button,
  ClearButton,
  InfoWrapper,
  SearchWrapper,
  Wrapper,
} from './styles';

interface RegProps {
  name: string;
  gseq: number;
  gcert: string;
  tgdesc: string;
  able: boolean;
  loc: string;
  locdesc: string;
  anb: string;
  anbdesc: string;
}

const ResetUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [reg, setReg] = useState<RegProps>({} as RegProps);

  const clearState = useCallback(() => {
    setReg({} as RegProps);
    formRef.current?.setFieldValue('cert', '');
  }, []);

  const searchGuia = useCallback(
    async (data: { cert: string }) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          cert: yup
            .string()
            .trim()
            .min(2, 'Quantidade mínima de caracteres não atingida (2).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            mode: 'search',
          }),
        );

        const response = await api.post('/adm/reset_user.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (!response.data) {
          clearState();
          addToast({
            type: 'info',
            title: 'Certificado não encontrado',
          });
          setLoading(false);
          return;
        }

        setReg(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, clearState, errorHandling],
  );

  const resetGuia = useCallback(
    async (data: { gseq: string }) => {
      setReg({} as RegProps);
      formRef.current?.setFieldValue('cert', '');
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          gseq: yup.string().trim().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            mode: 'reset',
          }),
        );

        await api.post('/adm/reset_user.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        clearState();
        addToast({
          type: 'success',
          title: 'Reset realizado',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [clearState, addToast, errorHandling],
  );

  const clearStyled = useSpring({
    opacity: Object.keys(reg).length > 0 ? 1 : 0,
    height: Object.keys(reg).length > 0 ? '25px' : '0px',
    pointerEvents: Object.keys(reg).length > 0 ? 'all' : 'none',
  });
  const infoStyled = useSpring({
    opacity: Object.keys(reg).length > 0 ? 1 : 0,
    height: Object.keys(reg).length > 0 ? (!reg.able ? '95px' : '70px') : '0px',
    pointerEvents: Object.keys(reg).length > 0 ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Reset de Usuário</p>
          </div>
        </AlteredHeader>

        <Form
          ref={formRef}
          onSubmit={Object.keys(reg).length === 0 ? searchGuia : resetGuia}
          initialData={{
            gseq: reg.gseq,
          }}
        >
          <Wrapper>
            <SearchWrapper>
              <span>
                <p>Certificado:</p>
                <Input
                  name="cert"
                  placeholder="Certificado do guia"
                  type="number"
                  mask="number"
                  step="none"
                  min="1"
                  disabled={Object.keys(reg).length > 0}
                />
              </span>
            </SearchWrapper>

            <InfoWrapper style={infoStyled}>
              <Input isHidden name="gseq" />
              <span>
                <p>
                  Nome: <strong>{reg.name}</strong>
                </p>
              </span>
              {Object.keys(reg).length > 0 && !reg.able && (
                <span>
                  <p>
                    Tipo de Guia: <strong>{reg.tgdesc}</strong>
                  </p>
                </span>
              )}
              <span>
                <p>
                  ANB:&nbsp;
                  <strong>
                    {reg.anb} - {reg.anbdesc}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Local:&nbsp;
                  <strong>
                    {reg.loc} - {reg.locdesc}
                  </strong>
                </p>
              </span>
            </InfoWrapper>

            <Button
              type="submit"
              mode={Object.keys(reg).length === 0 ? 'search' : 'reset'}
            >
              {Object.keys(reg).length === 0 ? <FaSearch /> : 'Resetar'}
            </Button>
            <ClearButton type="button" style={clearStyled} onClick={clearState}>
              <FaTimes />
              <p>Limpar busca</p>
            </ClearButton>
          </Wrapper>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ResetUser;
