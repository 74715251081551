import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';

import { useWindow } from 'hooks/window';

import {
  FaArrowLeft,
  FaPlusSquare,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
} from 'react-icons/fa';
import { calcTotal, formatNumber } from 'utils/calcTotal';

import Textarea from 'components/Textarea';
import Button from 'components/Button';
import { alphaMasking } from 'components/Textarea/masks';
import {
  StyledButtonPhaseOne,
  PhaseOne,
  PhaseTwo,
  PhaseThree,
  HeaderContainer,
  ItemContainer,
  AddButton,
  InfoContainer,
  FinishButton,
  Total,
  Grid,
  GridItem,
  ReturnButton,
} from './styles';

interface ItemsProps {
  matcod: string;
  matdesc: string;
  cost: number;
  quantity: number;
  stored: number;
}

interface GuiaProps {
  seq: string;
  cert: string;
  nome: string;
  anb: string;
  loc: string;
  tg: string;
  func: string;
}

interface GuiaSelectedProps {
  gseq: string;
  gnome: string;
}

interface ComboMat {
  value: string;
  label: string;
  matgrped: string;
  matcntr: string;
}

const CEInsert: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const phaseTwo = useRef<FormHandles>(null);
  const phaseThree = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [mats, setMats] = useState<ComboProps[]>([]);
  const [guias, setGuias] = useState<ComboProps[]>([]);

  const [storageQuantity, setStorageQuantity] = useState('-');
  const [cost, setCost] = useState(0);

  const [guiaSelected, setGuiaSelected] = useState<GuiaSelectedProps>(
    {} as GuiaSelectedProps,
  );

  const [items, setItems] = useState<ItemsProps[]>([]);

  const [isPhaseOne, setIsPhaseOne] = useState(true);
  const [isPhaseTwo, setIsPhaseTwo] = useState(false);
  const [isPhaseThree, setIsPhaseThree] = useState(false);

  const total = useMemo(() => {
    const totalmemo = items.reduce(
      (accum, { quantity, cost: value }: ItemsProps) => {
        const semitotal = accum + quantity * value;
        return semitotal;
      },
      0,
    );

    return totalmemo;
  }, [items]);

  const phaseOneStyled = useSpring({
    opacity: isPhaseOne ? 1 : 0,
    transform: isPhaseOne ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseOne ? 'all' : 'none',
    display: isPhaseOne ? 'inline' : 'none',
  });
  const phaseTwoStyled = useSpring({
    opacity: isPhaseTwo ? 1 : 0,
    transform: isPhaseTwo ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseTwo ? 'all' : 'none',
    display: isPhaseTwo ? 'inline' : 'none',
  });
  const phaseThreeStyled = useSpring({
    opacity: isPhaseThree ? 1 : 0,
    transform: isPhaseThree ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseThree ? 'all' : 'none',
    display: isPhaseThree ? 'inline' : 'none',
  });

  const phaseOneProceedButton = useSpring({
    opacity: guiaSelected.gseq ? 1 : 0,
    transform: guiaSelected.gseq ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: guiaSelected.gseq ? 'all' : 'none',
    // display: coordSelected.cod ? 'inline' : 'none',
  });

  const phaseTwoProceedButton = useSpring({
    transform: items.length > 0 ? 'translateX(5px)' : 'translateX(60px)',
    opacity: items.length > 0 ? 1 : 0,
    pointerEvents: items.length > 0 ? 'all' : 'none',
  });

  const toggleFlexDirection = useSpring({
    flexDirection: width < 400 && total > 99.99 ? 'column' : 'row',
  });

  const getGuias = useCallback(async () => {
    try {
      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify({
          filter: 'byLoc',
        })}`,
      );
      setGuias(
        response.data
          .filter((item: GuiaProps) => {
            return ['GA', 'FG'].indexOf(item.tg) > -1;
          })
          .map((item: GuiaProps) => ({
            value: item.seq,
            label: item.nome,
          })),
      );
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboMATS = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboMATs.php?from=ce');
      setMats(response.data.filter((item: ComboMat) => item.matcntr !== 'S'));
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['LOC'], true);
    getGuias();
  }, [getGuias, handlePermission]);

  const handleGSelected = useCallback(() => {
    const guia = phaseOne.current?.getFieldValue('guia');

    setGuiaSelected({
      gseq: guia,
      gnome:
        guias[guias.findIndex((item: ComboProps) => item.value === guia)].label,
    });
  }, [guias]);

  const handleProceedPhaseTwo = useCallback(() => {
    setIsPhaseOne(false);
    setIsPhaseTwo(true);
    getComboMATS();
    // getAddress();
  }, [getComboMATS]);

  const handleMaterialSelect = useCallback(async () => {
    try {
      setLoading(true);
      phaseTwo.current?.setErrors({});
      const material = phaseTwo.current?.getFieldValue('material');

      const send = JSON.stringify({
        matcod: material,
        trans: 'ce',
      });
      const response = await api.get(`/sgo/getStorage.php?data=${send}`);

      const { quantity, matcost } = response.data;

      setStorageQuantity(quantity);
      setCost(matcost);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const handlePushMaterial = useCallback(() => {
    const index = mats.findIndex(
      (item: ComboProps) =>
        item.value === phaseTwo.current?.getFieldValue('material'),
    );

    phaseTwo.current?.setErrors({});

    if (parseInt(storageQuantity, 10) === 0) {
      addToast({
        type: 'info',
        title: `Este material não possui estoque`,
        description: 'Não é possível adicioná-lo',
      });
      return;
    }
    if (index > -1) {
      const newMat = {
        matcod: mats[index].value,
        matdesc: mats[index].label,
        cost,
        quantity: 1,
        stored: parseInt(storageQuantity, 10),
      };

      setItems((state) => [newMat, ...state]);
      setMats((state) =>
        state.filter((item: ComboProps) => item.value !== newMat.matcod),
      );
      setStorageQuantity('-');
      setCost(0);
    } else {
      phaseTwo.current?.setFieldError(
        'material',
        'You must select at least one material',
      );

      addToast({
        type: 'info',
        title: ``,
        description: 'É necessário escolher um material.',
      });
    }

    phaseTwo.current?.setFieldValue('material', '');
  }, [addToast, cost, mats, storageQuantity]);

  const handleAdd = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        items[index].quantity += 1;
        setItems((state) => [...state]);
      }
    },
    [items],
  );

  const handleSub = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        items[index].quantity -= 1;
        setItems((state) =>
          items[index].quantity === 0
            ? [...state].filter(
                (item: ItemsProps) => item.matcod !== items[index].matcod,
              )
            : [...state],
        );

        if (items[index].quantity === 0) {
          const reinsert = {
            value: items[index].matcod,
            label: items[index].matdesc,
          };

          const rearrange = [...mats, reinsert].sort((a, b) =>
            a.label > b.label ? 1 : -1,
          );

          setMats(rearrange);
        }
      }
    },
    [items, mats],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);
      const number = e.currentTarget.value.replace(/^0/, '');
      if (number.length > 0) {
        const asNum = parseInt(number, 10);

        if (index > -1) {
          items[index].quantity = asNum;
          setItems((state) =>
            items[index].quantity <= 0
              ? [...state].filter(
                  (item: ItemsProps) => item.matcod !== items[index].matcod,
                )
              : [...state],
          );
        }
      } else {
        items[index].quantity = 0;
        setItems((state) => [...state]);
      }

      e.currentTarget.value = e.currentTarget.value.replace(/^0/, '');
    },
    [items],
  );

  const handleInputBlur = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);
      if (e.currentTarget.value === '0') {
        setItems((state) =>
          [...state].filter(
            (item: ItemsProps) => item.matcod !== items[index].matcod,
          ),
        );

        const material = {
          value: items[index].matcod,
          label: items[index].matdesc,
        };

        const rearrange = [...mats, material].sort((a, b) =>
          a.label > b.label ? 1 : -1,
        );

        setMats(rearrange);
        return;
      }

      if (parseInt(e.currentTarget.value, 10) > items[index].stored) {
        items[index].quantity = items[index].stored;
        setItems((state) => [...state]);
      }
    },
    [items, mats],
  );

  const handleRemoveItem = useCallback(
    (mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);

      const reinsert = {
        value: items[index].matcod,
        label: items[index].matdesc,
      };

      setItems((state) =>
        state.filter((item: ItemsProps) => item.matcod !== mat),
      );

      const rearrange = [...mats, reinsert].sort((a, b) =>
        a.label > b.label ? 1 : -1,
      );

      setMats(rearrange);
    },
    [mats, items],
  );

  const handleOBSChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);

      const data = {
        gdest: guiaSelected,
        items,
        obs: phaseThree.current?.getFieldValue('obs'),
        name: user.name,
        locdesc: user.locdesc,
        total,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      const response = await api.post('/sgo/ce_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { cenum } = response.data;
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Controle de Entrega N° ${cenum} emitido para o guia ${guiaSelected.gnome}.`,
      });

      history.replace(pathname.replace('/insert', ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    guiaSelected,
    history,
    items,
    pathname,
    total,
    user.locdesc,
    user.name,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Controle de Entrega" />
      <Content>
        <PhaseOne style={phaseOneStyled}>
          <Form
            ref={phaseOne}
            onSubmit={() => {
              return null;
            }}
          >
            <span>
              <p>Selecione o guia:</p>
              <SelectV2
                name="guia"
                content={guias}
                onChange={handleGSelected}
                initial="Selecione"
              />
            </span>
          </Form>
          <StyledButtonPhaseOne
            onClick={handleProceedPhaseTwo}
            style={phaseOneProceedButton}
            bgcolor="#00802b"
          >
            Avançar
          </StyledButtonPhaseOne>
        </PhaseOne>
        <PhaseTwo style={phaseTwoStyled}>
          <Form ref={phaseTwo} onSubmit={() => null}>
            <HeaderContainer>
              <p>
                Guia: <strong>{guiaSelected.gnome}</strong>
              </p>
            </HeaderContainer>
            <ItemContainer>
              <span>
                <SelectV2
                  name="material"
                  content={mats}
                  initial="Selecione"
                  onChange={handleMaterialSelect}
                />
              </span>
              <span>
                <p>Seu estoque:</p>
                <strong>{storageQuantity}</strong>
              </span>
            </ItemContainer>
            <AddButton type="button" onClick={handlePushMaterial}>
              <FaPlusSquare /> <p>Adicionar material</p>
            </AddButton>
            <InfoContainer>
              <Total style={toggleFlexDirection}>
                <p>Total deste controle de entrega:&nbsp;</p>
                <strong>R$ {formatNumber(total)}</strong>
              </Total>

              <FinishButton
                style={phaseTwoProceedButton}
                type="button"
                onClick={() => {
                  setIsPhaseTwo(false);
                  setIsPhaseThree(true);
                }}
              >
                Avançar
              </FinishButton>
            </InfoContainer>
            <Grid>
              {items.map((item: ItemsProps) => (
                <GridItem key={item.matcod}>
                  <div>
                    <strong>
                      {item.matcod} - {item.matdesc}
                    </strong>
                  </div>
                  <div>
                    <span>
                      <p>
                        Custo:&nbsp;
                        <strong>R$ {formatNumber(item.cost)}</strong>
                      </p>
                    </span>
                    <span>
                      <p>Quantidade:</p>
                      <span>
                        <FaSortUp
                          onClick={() => handleAdd(item.matcod)}
                          style={{
                            transform: 'translateY(10px)',
                            color: '#009933',
                            opacity: item.stored > item.quantity ? 1 : 0,
                            pointerEvents:
                              item.stored > item.quantity ? 'all' : 'none',
                          }}
                        />

                        <input
                          type="number"
                          min={0}
                          value={item.quantity}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleInputChange(e, item.matcod)
                          }
                          onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                            handleInputBlur(e, item.matcod)
                          }
                        />

                        <FaSortDown
                          onClick={() => handleSub(item.matcod)}
                          style={{
                            transform: 'translateY(-10px)',
                            color: '#c53030',
                          }}
                        />
                      </span>
                      <figure>
                        <FaTimesCircle
                          onClick={() => handleRemoveItem(item.matcod)}
                        />
                      </figure>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Seu estoque atual:&nbsp;
                        <strong>{item.stored}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Total:&nbsp;
                        <strong>
                          R$ {calcTotal(item.quantity, item.cost)}
                        </strong>
                      </p>
                    </span>
                  </div>
                </GridItem>
              ))}
            </Grid>
          </Form>
        </PhaseTwo>
        <PhaseThree style={phaseThreeStyled}>
          <ReturnButton
            onClick={() => {
              setIsPhaseTwo(true);
              setIsPhaseThree(false);
            }}
          >
            <FaArrowLeft />
            <p>Retornar aos itens</p>
          </ReturnButton>
          <Form ref={phaseThree} onSubmit={() => null}>
            <div>
              <p>Observação:</p>
              <Textarea
                name="obs"
                placeholder="Insira a observação (opcional)"
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  handleOBSChange(e)
                }
              />
            </div>
          </Form>
          <Button onClick={handleFinish} bgcolor="#00802b">
            Finalizar
          </Button>
        </PhaseThree>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default CEInsert;
