import React, { SelectHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Select } from './styles';

interface ContentProps {
  value: string | number;
  label: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  content?: ContentProps[];
  initial?: string;
  containerStyle?: object;
  selectStyle?: object;
  dropdownStyle?: object;
  caretStyle?: object;
  disabled?: boolean;
}

const SelectV2: React.FC<SelectProps> = ({
  name,
  content = [],
  initial,
  containerStyle = {},
  selectStyle = {},
  disabled = false,
  ...rest
}) => {
  // const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <Select style={containerStyle} error={!!error}>
      <select
        style={selectStyle}
        name={name}
        ref={selectRef}
        {...rest}
        disabled={content.length === 0 || disabled}
      >
        {!!initial && ['Selecione', ''].includes(initial) && (
          <option value="" style={{ display: 'none' }}>
            Selecione
          </option>
        )}
        {content.map((item: ContentProps) => (
          <option
            key={item.value}
            value={item.value}
            selected={!!initial && item.value === initial}
          >
            {item.label}
          </option>
        ))}
      </select>
    </Select>
  );
};

export default SelectV2;
