import styled from 'styled-components';

import { Form } from '@unform/web';

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  text-align: center;
  color: #332e2e;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto Slab', serif;
`;

export const ListContent = styled.div`
  table {
    margin: 20px auto 50px;
    border-collapse: collapse;
    border-radius: 5px;
    border: 1px solid #332e2e;
    font-size: 16px;
  }

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    text-align: center;
    padding: 2px 3px;

    button {
      border: 0;
      background: transparent;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #c53030;
        height: 25px;
        width: 25px;
        opacity: 0.5;

        cursor: pointer;

        transition: opacity 0.35s ease;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    td {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 550px) {
    table {
      margin: 20px 0 40px 0;
      width: 100vw;
    }

    td {
      font-size: 13px;
    }
  }
`;

export const TransfContent = styled.div`
  min-height: 320px;

  span {
    max-width: 360px;
    display: flex;
    flex-direction: column;

    strong {
      color: #332e2e;
    }

    p + p {
      margin-top: 5px;
    }

    form {
      > p {
        color: #8a0002;
        font-weight: 500;
        font-family: 'Roboto Slab', serif;
      }
    }

    & + span {
      margin-top: 10px;
    }
  }
`;

export const Remove = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  > span {
    font-size: 15px;
    display: flex;
    flex-direction: column;

    p {
      color: #565656;
      strong {
        color: #332e2e;
      }
    }

    & + span {
      margin: 10px 0 0 0;
    }
  }
`;

export const AlteredForm = styled(Form)``;
