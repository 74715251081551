import React, { useCallback } from 'react';

import InputDialog from 'components/InputDialog';
import RadioContainer from 'components/RadioContainer';

import { useWindow } from 'hooks/window';

import { FormHandles } from '@unform/core';
import { RegsProps } from '../main';

import { Grid, GridItem } from './styles';

interface CompProps {
  mode: 'CI' | 'ED' | 'EP' | 'SU' | string;
  regs: RegsProps[];

  form: React.RefObject<FormHandles>;
}

export const FIGHistComp: React.FC<CompProps> = ({
  mode,
  regs = [],

  form,
}) => {
  const { width } = useWindow();

  const handleRadioClick = useCallback(
    (string: string) => {
      if (form) {
        const data = { ...form.current?.getData() };
        const dafuq = Object.keys(data);
        const filtered = dafuq.filter((item) => item.substr(0, 3) === string);
        filtered.map((item) => form.current?.setFieldValue(`${item}`, ''));
      }
    },
    [form],
  );

  return (
    <Grid mode={mode}>
      {regs.map((item) => (
        <GridItem key={`${item.codreg}${item.regseq}`}>
          {mode !== 'EP' ? (
            <div>
              <span>
                <p>
                  Tipo de&nbsp;
                  {['CI', 'SU'].indexOf(mode) > -1 ? 'Oficina' : 'Encontro'}:
                </p>
                <RadioContainer
                  hasClear
                  clearCallback={() =>
                    handleRadioClick(`${item.codreg}${item.regseq}`)
                  }
                  title=""
                  name={`${item.codreg}${item.regseq}type`}
                  content={
                    ['CI', 'SU'].indexOf(mode) > -1
                      ? [
                          {
                            id: 'tA',
                            value: 'A',
                            label: 'TOV Adultos',
                          },
                          {
                            id: 'oJ',
                            value: 'J',
                            label: 'TOV Jovens',
                          },
                        ]
                      : [
                          {
                            id: 'tF',
                            value: 'F',
                            label: 'Frei',
                          },
                          {
                            id: 'oC',
                            value: 'C',
                            label: 'Casal Evangelizador',
                          },
                        ]
                  }
                  selected={item.type}
                />
              </span>
            </div>
          ) : null}
          <div>
            <span>
              <p>
                Local d{mode !== 'ED' ? 'a' : 'o'}&nbsp;
                {['CI', 'SU'].indexOf(mode) > -1
                  ? 'Oficina'
                  : mode === 'EP'
                  ? 'Escola'
                  : 'Encontro'}
                :
              </p>
              <InputDialog
                inputStyle={{
                  width: '110px',
                }}
                containerStyle={{
                  padding: '5px 2px 4.82px 5px',
                  height: '37.5px',
                  width:
                    width > 999 ? '460px' : `3${width < 366 ? '5' : '6'}0px`,
                }}
                name={`${item.codreg}${item.regseq}place`}
                placeholder={`Local d${mode !== 'ED' ? 'a' : 'o'} ${
                  ['CI', 'SU'].indexOf(mode) > -1
                    ? 'Oficina'
                    : mode === 'EP'
                    ? 'Escola'
                    : 'Encontro'
                }`}
                defaultValue={item.place || ''}
              />
            </span>
          </div>
          {mode !== 'SU' ? (
            <div>
              <span>
                <p>Guia Aplicador:</p>
                <InputDialog
                  inputStyle={{
                    width: '110px',
                  }}
                  defaultValue={item.aplic}
                  containerStyle={{
                    padding: '5px 2px 4.82px 5px',
                    height: '37.5px',
                    width:
                      width > 999 ? '460px' : `3${width < 366 ? '5' : '6'}0px`,
                  }}
                  name={`${item.codreg}${item.regseq}aplic`}
                  placeholder="Nome do Guia"
                />
              </span>
            </div>
          ) : null}
          <div>
            <span>
              <p>Data de Início:</p>
              <InputDialog
                inputStyle={{
                  width: '110px',
                }}
                containerStyle={{
                  padding: '5px 2px 4.82px 5px',
                  height: '37.5px',
                  width:
                    width < 1000 ? (width < 365 ? '350px' : '360px') : '228px',
                }}
                name={`${item.codreg}${item.regseq}dtini`}
                defaultValue={item.ini !== '0000-00-00' ? item.ini : ''}
                type={item.ini !== '0000-00-00' ? 'date' : 'text'}
                placeholder={item.ini !== '0000-00-00' ? '' : 'dd/mm/aaaa'}
                isDate={item.ini === '0000-00-00'}
              />
            </span>
            <span>
              <p>Data {mode === 'EP' ? 'Envio' : 'Final'}:</p>
              <InputDialog
                inputStyle={{
                  padding: '5px 2px 4.82px 5px',
                  width: '110px',
                }}
                containerStyle={{
                  height: '37.5px',
                  width:
                    width < 1000 ? (width <= 365 ? '350px' : '360px') : '228px',
                }}
                name={`${item.codreg}${item.regseq}dtend`}
                defaultValue={item.end !== '0000-00-00' ? item.end : ''}
                type={item.end !== '0000-00-00' ? 'date' : 'text'}
                placeholder={item.end !== '0000-00-00' ? '' : 'dd/mm/aaaa'}
                isDate={item.end === '0000-00-00'}
              />
            </span>
          </div>
          {mode === 'SU' && (
            <div>
              <span>
                <p>Participantes: </p>
                <InputDialog
                  inputStyle={{
                    padding: '5px 2px 4.82px 5px',
                    width: '110px',
                    textAlign: 'right',
                  }}
                  containerStyle={{
                    height: '37.5px',
                    width:
                      width < 1000
                        ? width <= 365
                          ? '350px'
                          : '360px'
                        : '228px',
                  }}
                  name={`${item.codreg}${item.regseq}aplic`}
                  defaultValue={item.aplic}
                  type="number"
                  mask="allowZero"
                  step="none"
                  placeholder="0"
                />
              </span>
              <span>
                <p>Perseverantes: </p>
                <InputDialog
                  inputStyle={{
                    padding: '5px 2px 4.82px 5px',
                    width: '110px',
                    textAlign: 'right',
                  }}
                  containerStyle={{
                    height: '37.5px',
                    width:
                      width < 1000
                        ? width <= 365
                          ? '350px'
                          : '360px'
                        : '228px',
                  }}
                  name={`${item.codreg}${item.regseq}pers`}
                  defaultValue={item.pers}
                  type="number"
                  mask="allowZero"
                  step="none"
                  placeholder="0"
                />
              </span>
            </div>
          )}
        </GridItem>
      ))}
    </Grid>
  );
};

export const FIGHistCI: React.FC<CompProps> = ({ regs = [] }) => {
  const { width } = useWindow();
  return (
    <Grid mode="CI">
      {regs.map((item) => (
        <GridItem key={`${item.codreg}${item.regseq}`}>
          <div>
            <span>
              <p>Tipo de Oficina:</p>
              <RadioContainer
                title=""
                name={`${item.codreg}${item.regseq}type`}
                content={[
                  {
                    id: 'tA',
                    value: 'A',
                    label: 'TOV Adultos',
                  },
                  {
                    id: 'oJ',
                    value: 'J',
                    label: 'TOV Jovens',
                  },
                ]}
                selected={item.type}
              />
            </span>
          </div>
          <div>
            <span>
              <p>Local da Oficina:</p>
              <InputDialog
                containerStyle={{
                  height: '37.5px',
                  width:
                    width > 999 ? '460px' : `3${width < 366 ? '5' : '6'}0px`,
                }}
                name={`${item.codreg}${item.regseq}place`}
                placeholder="Local da Oficina"
                defaultValue={item.place || ''}
              />
            </span>
          </div>
          <div>
            <span>
              <p>Guia Aplicador:</p>
              <InputDialog
                defaultValue={item.aplic}
                containerStyle={{
                  height: '37.5px',
                  width:
                    width > 999 ? '460px' : `3${width < 366 ? '5' : '6'}0px`,
                }}
                name={`${item.codreg}${item.regseq}aplic`}
                placeholder="Nome do Guia"
              />
            </span>
          </div>
          <div>
            <span>
              <p>Data de Início:</p>
              <InputDialog
                containerStyle={{
                  height: '37.5px',
                  width:
                    width < 1000 ? (width < 365 ? '350px' : '360px') : '228px',
                }}
                name={`${item.codreg}${item.regseq}dtini`}
                defaultValue={item.ini !== '0000-00-00' ? item.ini : ''}
                type={item.ini !== '0000-00-00' ? 'date' : 'text'}
                placeholder={item.ini !== '0000-00-00' ? '' : 'dd/mm/aaaa'}
                isDate={item.ini === '0000-00-00'}
              />
            </span>
            <span>
              <p>Data Final:</p>
              <InputDialog
                containerStyle={{
                  height: '37.5px',
                  width:
                    width < 1000 ? (width <= 365 ? '350px' : '360px') : '228px',
                }}
                name={`${item.codreg}${item.regseq}dtend`}
                defaultValue={item.end !== '0000-00-00' ? item.end : ''}
                type={item.end !== '0000-00-00' ? 'date' : 'text'}
                placeholder={item.end !== '0000-00-00' ? '' : 'dd/mm/aaaa'}
                isDate={item.end === '0000-00-00'}
              />
            </span>
          </div>
        </GridItem>
      ))}
    </Grid>
  );
};

export const FIGHistED: React.FC<CompProps> = ({ regs = [] }) => {
  return <div>{JSON.stringify(regs)}</div>;
};
export const FIGHistEP: React.FC<CompProps> = ({ regs = [] }) => {
  return <div>{JSON.stringify(regs)}</div>;
};
export const FIGHistSU: React.FC<CompProps> = ({ regs = [] }) => {
  return <div>{JSON.stringify(regs)}</div>;
};

// export default FIGHistComps;
