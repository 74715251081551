import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { FaCamera } from 'react-icons/fa';
import { useSpring } from 'react-spring';

import { Container, Content } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import resizeImage from 'utils/resizeImage';

import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from 'utils/toCropUtils.js';

import {
  InputAvatar,
  ReactCropContainer,
  SaveButton,
  CancelButton,
} from './styles';

interface GuiaProps {
  gseq: string;
  name: string;
  avatar: string;
}

interface StateProps {
  guia: GuiaProps;
}

const Avatar: React.FC = () => {
  const { addToast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const location = useLocation<StateProps>();

  const history = useHistory();
  const { user, updateUser } = useAuth();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [guia, setGuia] = useState<GuiaProps>({} as GuiaProps);

  const [imgSrc, setImgSrc] = useState<any>('');
  const [crop, setCrop] = useState<Crop>({
    aspect: 3 / 4,
    unit: 'px',
    width: 340,
    height: 452,
    x: 0,
    y: 0,
  });

  const inputAnimation = useSpring({
    opacity: !imgSrc ? 1 : 0,
    pointerEvents: !imgSrc ? 'all' : 'none',
    top: !imgSrc ? '15px' : '-25px',
  });

  const avatarAnimation = useSpring({
    opacity: imgSrc ? 1 : 0,
    pointerEvents: imgSrc ? 'all' : 'none',
    top: imgSrc ? '15px' : '120px',
  });

  const handleAvatarInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const size = e.target.files[0].size / 1024 / 1024; // in MB

        if (size > 6) {
          addToast({
            type: 'error',
            title: 'Limite excedido',
            description:
              'Uma foto que você tentou enviar é maior do que o limite permitido de 6 MB',
          });

          return;
        }

        const width = window.innerWidth / 1.35;
        const height = window.innerHeight / 1.35;

        const newImage = await resizeImage(e.target.files[0], width, height);

        const reader = new FileReader();

        reader.addEventListener('load', () => {
          setCrop({
            aspect: 3 / 4,
            unit: 'px',
            width: 170,
            height: 226,
            x: 0,
            y: 0,
          });
          setImgSrc(reader.result);
        });

        reader.readAsDataURL(newImage);
      }
    },
    [addToast],
  );

  const handleCompleteCrop = useCallback(
    (cropped) => {
      const canvas = canvasRef.current;
      image64toCanvasRef(canvas, imgSrc, cropped);
    },
    [imgSrc],
  );

  const handleChangeCrop = useCallback((croppy) => {
    setCrop(croppy);
  }, []);

  const submitPhoto = useCallback(async () => {
    setLoading(true);
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }
    const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
    const imageData64 = canvas.toDataURL(`image/${fileExtension}`);

    const filename = `${guia?.avatar.substr(
      0,
      guia?.avatar.indexOf('.'),
    )}.${fileExtension}`;
    const newCroppedImg = base64StringtoFile(imageData64, filename);

    const data = new FormData();
    data.append('old', guia?.avatar);
    data.append('avatar', newCroppedImg);
    data.append('gseq', guia?.gseq);
    data.append('ext', fileExtension);

    try {
      const response = await api.post('/sgo/guias_avatar.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { status, newAvatar } = response.data;
      const updatedUser = {
        ...user,
        avatar: newAvatar,
      };

      if (status) {
        if (guia?.gseq === user.gseq) {
          updateUser(updatedUser);
        }

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Foto do ${user.perfil === 'CTB' ? '' : 'guia'} ${
            guia?.name
          } alterada`,
        });
        setLoading(false);
        history.goBack();
      } else {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro na alteração',
          description:
            'Não foi possível alterar a foto. Tente novamente e caso o problema persista, acione o suporte ao SGO',
        });
      }
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [imgSrc, history, addToast, errorHandling, guia, updateUser, user]);

  const cancelPhoto = useCallback(() => {
    setImgSrc('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/avatar', ''));
    } else if (location.state.guia.gseq === user.gseq) {
      setGuia({ name: user.name, gseq: user.gseq, avatar: user.avatar });
    } else {
      setGuia(location.state.guia);
    }
  }, [
    location.state,
    location.pathname,
    history,
    user.gseq,
    user.name,
    user.avatar,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />

      <ScrollTop />
      <SGOHeader disableAvatar />
      <SGONavbar noLinks title={guia?.gseq !== user.gseq ? guia?.name : ''} />
      <Content>
        <canvas
          ref={canvasRef}
          style={{ display: 'none', cursor: 'none', pointerEvents: 'none' }}
        />

        <InputAvatar style={inputAnimation}>
          <label htmlFor="avatar">
            <FaCamera />
            <h4>Carregar nova foto</h4>
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              multiple={false}
              id="avatar"
              onChange={handleAvatarInput}
            />
          </label>
        </InputAvatar>

        <ReactCropContainer style={avatarAnimation}>
          <span>
            <SaveButton type="button" onClick={submitPhoto}>
              Salvar
            </SaveButton>
            <CancelButton type="button" onClick={cancelPhoto}>
              Cancelar
            </CancelButton>
          </span>
          <ReactCrop
            src={imgSrc}
            crop={crop}
            minHeight={100}
            onComplete={handleCompleteCrop}
            onChange={handleChangeCrop}
          />
        </ReactCropContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Avatar;
