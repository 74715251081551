import React, { useState, useCallback } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import api from 'services/api';

import { FaSortDown, FaSortUp } from 'react-icons/fa';

import { useCredentials } from 'hooks/credentials';
import { VersionUpdater, GoArrow } from './styles';

interface VersionProps {
  major: number;
  minor: number;
  patch: number;
}

const VersionSGO: React.FC = () => {
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [initVersion] = useState<VersionProps>(() => {
    const stated = process.env.REACT_APP_VERSION;

    if (stated) {
      const splitted = stated.split('.');

      return {
        major: parseInt(splitted[0], 10),
        minor: parseInt(splitted[1], 10),
        patch: parseInt(splitted[2], 10),
      };
    }

    return {} as VersionProps;
  });
  const [version, setVersion] = useState<VersionProps>(() => {
    const stated = process.env.REACT_APP_VERSION;

    if (stated) {
      const splitted = stated.split('.');

      return {
        major: parseInt(splitted[0], 10),
        minor: parseInt(splitted[1], 10),
        patch: parseInt(splitted[2], 10),
      };
    }

    return {} as VersionProps;
  });

  const handleAdd = useCallback(
    (pos: string) => {
      if (pos === 'major') {
        version.major += 1;
        version.minor = 0;
        version.patch = 0;
      }

      if (pos === 'minor') {
        version.minor += 1;
        version.patch = 0;
      }

      if (pos === 'patch') {
        version.patch += 1;
      }

      setVersion((state) => ({ ...state }));
    },
    [version],
  );

  const handleSub = useCallback(
    (pos: string) => {
      if (pos === 'major') {
        version.major -= 1;

        if (version.major === initVersion.major) {
          version.minor = initVersion.minor;
          version.patch = initVersion.patch;
        }
      }

      if (pos === 'minor') {
        version.minor -= 1;

        if (version.minor === initVersion.minor) {
          version.patch = initVersion.patch;
        }
      }

      if (pos === 'patch') {
        version.patch -= 1;
      }

      setVersion((state) => ({ ...state }));
    },
    [
      initVersion.major,
      initVersion.minor,
      initVersion.patch,
      version.major,
      version.minor,
      version.patch,
    ],
  );

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append('data', JSON.stringify(version));

      await api.post('/version/version_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, version]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <VersionUpdater>
          <span>
            <FaSortUp
              style={{
                transform: 'translateY(10px)',
                color: '#009933',
              }}
              onClick={() => handleAdd('major')}
            />
            <input type="number" min={0} disabled value={version.major} />

            <FaSortDown
              style={{
                transform: 'translateY(-10px)',
                color: '#c53030',
                opacity: version.major > initVersion.major ? 1 : 0,
                pointerEvents:
                  version.major > initVersion.major ? 'all' : 'none',
              }}
              onClick={() => handleSub('major')}
            />
          </span>

          <span>
            <FaSortUp
              style={{
                transform: 'translateY(10px)',
                color: '#009933',
              }}
              onClick={() => handleAdd('minor')}
            />
            <input type="number" min={0} disabled value={version.minor} />

            <FaSortDown
              style={{
                transform: 'translateY(-10px)',
                color: '#c53030',
                opacity: version.minor > initVersion.minor ? 1 : 0,
                pointerEvents:
                  version.minor > initVersion.minor ? 'all' : 'none',
              }}
              onClick={() => handleSub('minor')}
            />
          </span>

          <span>
            <FaSortUp
              style={{
                transform: 'translateY(10px)',
                color: '#009933',
              }}
              onClick={() => handleAdd('patch')}
            />
            <input type="number" min={0} disabled value={version.patch} />

            <FaSortDown
              style={{
                transform: 'translateY(-10px)',
                color: '#c53030',
                opacity: version.patch > initVersion.patch ? 1 : 0,
                pointerEvents:
                  version.patch > initVersion.patch ? 'all' : 'none',
              }}
              onClick={() => handleSub('patch')}
            />
          </span>

          <GoArrow onClick={handleUpdate} />
        </VersionUpdater>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default VersionSGO;
