import styled from 'styled-components';

import { animated } from 'react-spring';
import { shade } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 400px;

  padding: 10px 5px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.35s;

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  > div {
    display: flex;

    span {
      p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;

        margin: 10px 0 0 0;
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }
  }
`;

export const FormWrapper = styled(animated.div)``;

export const Print = styled(animated.div)`
  margin: 20px 0 0 0;
  overflow: hidden;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;

  button {
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #332e2e;
    color: #efefef;
    padding: 10px 20px;
    width: 250px;
    border: 2px solid transparent;
    border-radius: 5px;

    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    svg {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 18px;
      margin: 0 0 0 5px;
    }

    transition: 0.35s ease;
    &:hover {
      background-color: ${shade(0.2, '#332e2e')};
    }
  }
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
  max-width: 600px;

  background: #fff;
  border: 3px solid #8a0002;
  border-radius: 5px;
  font-size: 16px;

  div + div {
    margin: 10px 0 0 0;
  }

  p {
    color: #767676;
    strong {
      color: #332e2e;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border-bottom: 2px solid #c9c9c9;

  background-image: linear-gradient(
    to bottom,
    rgba(201, 201, 201, 0),
    rgba(201, 201, 201, 1)
  );

  p {
    color: #8a0002;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const PreviewBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  span {
    width: 100%;
    display: flex;
    margin: 0 auto;

    > strong {
      width: 100%;
      margin: 0 0 0 5px;
      text-align: left;
    }
    /* min-width: 400px; */
  }
`;

export const Message = styled.div`
  padding: 5px 10px;
  display: flex;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > p {
      color: #8a0002;
    }

    > strong {
      color: #8a0002;
    }
  }
`;

export const Options = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    span {
      display: flex;
      flex-direction: column;

      font-size: 16px;

      & + span {
        margin: 0 0 0 5px;
      }
    }
  }
`;

export const Warn = styled.div`
  padding: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border-top: 2px solid #c53030;
  background: #c53030;

  strong {
    font-size: 14px;
    color: #fff;
  }
`;
