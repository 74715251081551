import styled from 'styled-components';
import { shade } from 'polished';

export const Table = styled.table`
  margin: 20px auto 50px;
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  /* width: 1366px; */

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    padding: 2px 3px;

    button {
      margin: 0 auto;
      border: 0;
      border-radius: 5px;
      /* background: transparent; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 2px 3px; */

      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
`;

export const TDDesc = styled.td`
  font-size: 16px;
  text-align: left;
`;

export const TDQtd = styled.td`
  font-size: 16px;
  text-align: center;
`;

export const TDConsult = styled.td`
  text-align: center;
  vertical-align: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;

    text-decoration: none;
    border-radius: 5px;
    padding: 5px;

    width: 25px;
    height: 25px;
    transition: background-color 0.35s ease, color 0.35s ease;

    color: #ffffff;
    background: #767676;

    &:hover {
      color: ${shade(0.1, '#ffffff')};
      background-color: ${shade(0.2, '#767676')};
    }
  }
`;

export const TDAlt = styled.td`
  text-align: center;
  vertical-align: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;

    text-decoration: none;
    border-radius: 5px;
    padding: 5px;

    width: 25px;
    height: 25px;
    transition: background-color 0.35s ease, color 0.35s ease;

    color: #ffffff;
    background: #007acc;

    &:hover {
      color: ${shade(0.1, '#ffffff')};
      background-color: ${shade(0.2, '#007acc')};
    }
  }
`;

export const ConsultContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    margin: 20px 0 0 100%;
    width: 200px;
    transform: translateX(-100%);
  }

  span {
    p {
      color: #767676;
      strong {
        margin: 0 0 0 7px;
        color: #332e2e;
      }
    }
    & + span {
      margin: 7px 0 0 0;
    }
  }
`;

export const IContentDivResp = styled.div``;

export const IContentDiv = styled.div`
  margin: 25px 0 10px 0;

  span {
    p {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Roboto Slab', serif;
      color: #8a0002;
    }
  }

  & + div {
    margin: 10px 0 25px 0;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 460px) {
    & + div {
      margin: 10px 0;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

export const FilterByStatusContainer = styled.div`
  --primary: #8a0002;
  --primaryHighlight: #c53030;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 5px;

  button {
    background: transparent;
    border: 1px solid #767676;
    border-radius: 3px 0px 0px 3px;
    padding: 3px 7px;
    background: #d6d6d6;
    color: #767676;
    font-size: 16px;
    border-right-width: 0px;

    transition: all 0.35s ease;
    &:nth-child(2) {
      border-right-width: 1px;
      border-left-width: 0px;
      border-radius: 0px 3px 3px 0px;
    }

    &:hover {
      background: #fff;
      color: var(--primary);
    }
  }

  button[data-checked='true'] {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;

    &:hover {
      background: var(--primaryHighlight);
      border-color: var(--primaryHighlight);
    }
  }

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
`;
