import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useAuth } from 'hooks/auth';
import { useSpring } from 'react-spring';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';

import { handleTimeZone } from 'utils/formatDate';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { formatNumber } from 'utils/calcTotal';

import { FaMobileAlt, FaFileExcel } from 'react-icons/fa';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { TableWrapper, RotateMessage } from './styles';

interface LocalStorageProps {
  localYear?: string;
  localSemester?: string;
}

interface ListProps {
  anb: string;
  desc: string;
  place: string;
  current: number;
  receive: number;
  pay: number;
  plan: number;
  planten: number;
  plancexc: number;
}

const Excedente: React.FC = () => {
  const { errorHandling, handlePermission } = useCredentials();
  const { width } = useWindow();
  const { user } = useAuth();
  const comboRef = useRef<FormHandles>(null);
  const [mode] = useState(process.env.REACT_APP_EXCEDENTE);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(() => {
    const { localYear }: LocalStorageProps = getLocalStorage(`${mode}`);
    const currSem = handleTimeZone(new Date()).getMonth() < 6 ? '1' : '2';
    const currYear = handleTimeZone(new Date()).getUTCFullYear();
    return localYear || (currSem === '1' ? currYear - 1 : currYear).toString();
  });

  const [semester, setSemester] = useState(() => {
    const { localSemester }: LocalStorageProps = getLocalStorage(`${mode}`);
    const currSem = handleTimeZone(new Date()).getMonth() < 6 ? '1' : '2';

    return localSemester || (currSem === '1' ? '2' : '1');
  });

  const [list, setList] = useState<ListProps[]>([]);

  const [comboYears] = useState<ComboProps[]>(() => {
    let init = 2017;
    const currSem = handleTimeZone(new Date()).getMonth() < 6 ? '1' : '2';
    const current = new Date().getUTCFullYear();
    const years: ComboProps[] = [];
    while (init <= (currSem === '1' ? current - 1 : current)) {
      years.push({ value: init.toString(), label: init.toString() });
      init += 1;
    }

    return years.sort((a, b) =>
      parseInt(a.value, 10) > parseInt(b.value, 10) ? -1 : 1,
    );
  });

  const [comboSemesters] = useState<ComboProps[]>([
    { value: '1', label: '1° Semestre' },
    { value: '2', label: '2° Semestre' },
  ]);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  function calcExc(obj: ListProps): number {
    const exc = obj.current + obj.receive - obj.pay - obj.plan - obj.plan * 0.1;
    return exc; // para retornar inclusive saldo negativo
    // return exc <= 0 ? 0 : exc;
  }

  const getList = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          year,
          semester,
        }),
      );
      const response = await api.post('/sgo/excedente_geral.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setList(
        response.data.map((item: ListProps) => ({
          ...item,
          planten: item.plan * 0.1,
          plancexc: calcExc(item),
        })),
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
      errorHandling(e);
    }
  }, [errorHandling, semester, year]);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getList();
  }, [getList, handlePermission]);

  const handleYearChange = useCallback(() => {
    const select = comboRef.current?.getFieldRef('years');
    const { value } = select.options[select.selectedIndex];
    setLocalStorage(`${mode}`, {
      localYear: value,
    });
    setYear(value);

    // const current = handleTimeZone(new Date()).getUTCFullYear().toString();

    // const toSet: ComboProps[] = [
    //   { value: '1', label: '1° Semestre' },
    //   { value: '2', label: '2° Semestre' },
    // ];

    // setComboSemesters(toSet);
    // if (current === value) {
    //   toSet.pop();
    //   setLocalStorage(`${mode}`, {
    //     localSemester: '1',
    //   });
    // }
  }, [mode]);

  const handleSemesterChange = useCallback(() => {
    const select = comboRef.current?.getFieldRef('semester');
    const { value } = select.options[select.selectedIndex];
    setLocalStorage(`${mode}`, {
      localSemester: value,
    });

    setSemester(value);
  }, [mode]);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const showMessage = useSpring({
    opacity: width >= 580 ? 0 : 1,
    height: width >= 580 ? 0 : 'auto',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Base para Cálculo do Excedente das Nacionais</p>
          </div>
        </AlteredHeader>
        <div>
          <Form
            ref={comboRef}
            onSubmit={() => null}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <div style={{ margin: '0 5px 0 0' }}>
              <SelectV2
                name="semester"
                content={comboSemesters}
                onChange={handleSemesterChange}
                initial={semester}
                containerStyle={{ width: '150px' }}
              />
            </div>
            <div style={{ margin: '0 0 0 5px' }}>
              <SelectV2
                name="years"
                content={comboYears}
                onChange={handleYearChange}
                initial={year}
                containerStyle={{ width: '150px' }}
                disabled={comboYears.length < 2}
              />
            </div>
          </Form>
        </div>

        <Button
          bgcolor="#332e2e"
          containerStyle={{ margin: '10px 0' }}
          onClick={handleDownload}
        >
          <p>Gerar planilha</p>&emsp;
          <FaFileExcel />
        </Button>

        {width >= 580 ? (
          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <td>Nacional</td>
                  <td>Saldo{width >= 815 ? ' Atual' : ''}</td>
                  <td>{width >= 815 ? 'Contas ' : ''} a Receber</td>
                  <td>{width >= 815 ? 'Contas  ' : ''}a Pagar</td>
                  <td>
                    {width >= 815 ? 'Total  ' : ''}Plan
                    {width >= 815 ? 'ejado ' : '.'}
                  </td>
                  <td>10% Plan{width >= 815 ? 'ejado ' : '.'}</td>
                  <td>{width >= 815 ? 'Valor ' : ''} Excedente</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item: ListProps, index) => (
                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <td>
                      {width >= 960
                        ? `${item.desc} - ${item.place}`
                        : width >= 500
                        ? item.desc
                        : item.anb}
                    </td>
                    <td>{formatNumber(item.current)}</td>
                    <td>{formatNumber(item.receive)}</td>
                    <td>{formatNumber(item.pay)}</td>
                    <td>{formatNumber(item.plan)}</td>
                    <td>{formatNumber(item.plan * 0.1)}</td>
                    <td>{formatNumber(item.plancexc)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        ) : (
          <RotateMessage style={showMessage}>
            <span>
              <p>Para mais informações, vire seu aparelho</p>
            </span>
            <span>
              <FaMobileAlt />
            </span>
          </RotateMessage>
        )}

        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_excedente_geral.php?data=${JSON.stringify({
            year,
            semester,
            zoncod: user.zoncod,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          XLS
        </a>
        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>Gerar XLS</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() => setShow({ ...show, open: false })}
            >
              Ok
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Excedente;
