import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import {
  Container,
  Content,
  AlteredHeader,
  CoordProps,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import { useLocation } from 'react-router-dom';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import moment from 'moment';
import { parseISO } from 'date-fns';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { formatDate } from 'utils/formatDate';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import api from 'services/api';
import { FaPencilAlt, FaTimes, FaTrafficLight } from 'react-icons/fa';
import { useSpring } from 'react-spring';
import IncludeButton from 'components/IncludeButton';
import {
  CodContainer,
  YearContainer,
  LeftSVG,
  RightSVG,
  Grid,
  GridItem,
  InfoContainer,
} from './styles';

export interface ListProps {
  epg: number;
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  pc1: number;
  pn1: string;
  wd1: string;
  wn1: string;
  hr1: string;
  dti1: string;
  dtf1: string;
  ge1: string;
  gn1: string;
  qpart1: number;
  qpers1: number;
  pc2: number;
  pn2: string;
  wd2: string;
  wn2: string;
  hr2: string;
  dti2: string;
  dtf2: string;
  ge2: string;
  gn2: string;
  qpart2: number;
  qpers2: number;
  pc3: number;
  pn3: string;
  wd3: string;
  wn3: string;
  hr3: string;
  dti3: string;
  dtf3: string;
  ge3: string;
  gn3: string;
  qpart3: number;
  qpers3: number;
  ano: number;
  sem: number;
  offer: number;
  dtoffer: string;
  sit: string;
  end: string;
}

interface LocalStorageProps {
  loc?: string;
  anb?: string;
  zon?: string;
  year?: number;
  cod?: string;
  desc?: string;
  filterBy?: string;
}

interface GetProps {
  epgs: ListProps[];
  epyears: { year: number }[];
}

const EPG: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    return cod && desc
      ? { cod, desc }
      : ['ZON', 'INT'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [currYear, setCurrYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );
    return year || new Date().getUTCFullYear();
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    return loc || 'Selecione';
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    return anb || 'Selecione';
  });

  const [initialZon, setInitialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    return zon || user.zoncod;
  });

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [anbs, setAnbs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [years, setYears] = useState<{ year: number }[]>([]);

  const startedEPG = (date: string): boolean => {
    const epgStartDate = new Date(parseISO(date));
    const today = new Date(parseISO(new Date().toISOString()));

    const start = new Date(
      epgStartDate.getFullYear(),
      epgStartDate.getMonth(),
      epgStartDate.getDate(),
      0,
      0,
      0,
    );
    const now = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0,
      0,
    );

    const formats = 'YYYY-mm-dd';

    const startAt = moment(start, formats);
    const base = moment(now, formats);

    return base.isSameOrAfter(startAt);
  };

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setAnbs(response.data);
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === (zon || user.zoncod.substr(0, 2)),
      ),
    );
    setLoading(false);
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb, loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    if (anb || user.perfil === 'NAC') {
      setInitialLoc(loc || 'Selecione');
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<GetProps>('/sgo/epg_list.php');
      const { epgs, epyears } = response.data;
      setYears(epyears);
      setRawList(epgs);

      const {
        filterBy,
        year,
        loc,
        anb,
        zon,
      }: LocalStorageProps = getLocalStorage(`${process.env.REACT_APP_EPG}`);

      setList(
        epgs.filter(
          (item: ListProps) =>
            item.ano === (year || new Date().getUTCFullYear()) &&
            (!filterBy
              ? item
              : filterBy === 'zonal'
              ? item.anb.substr(0, 2) === (zon || user.zoncod.substr(0, 2))
              : filterBy === 'anb'
              ? item.anb === anb
              : item.loc === loc),
        ),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, user.zoncod]);

  useEffect(() => {
    if (user.perfil === 'INT') {
      getComboZON();
    }

    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboANB();
    }
    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
      getComboLOC();
    }

    getList();
  }, [getComboANB, getComboLOC, getComboZON, getList, user.perfil]);

  const handleDecreaseYear = useCallback(() => {
    setLocalStorage(`${process.env.REACT_APP_EPG}`, {
      year: currYear - 1,
    });

    const { filterBy, loc, anb, zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    setList(
      rawList.filter(
        (item: ListProps) =>
          item.ano === currYear - 1 &&
          (!filterBy
            ? item
            : filterBy === 'zonal'
            ? item.anb.substr(0, 2) === zon
            : filterBy === 'anb'
            ? item.anb === anb
            : item.loc === loc),
      ),
    );

    setCurrYear((state) => state - 1);
  }, [currYear, rawList]);

  const handleIncreaseYear = useCallback(() => {
    setLocalStorage(`${process.env.REACT_APP_EPG}`, {
      year: currYear + 1,
    });

    const { filterBy, loc, anb, zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_EPG}`,
    );

    setList(
      rawList.filter(
        (item: ListProps) =>
          item.ano === currYear + 1 &&
          (!filterBy
            ? item
            : filterBy === 'zonal'
            ? item.anb.substr(0, 2) === zon
            : filterBy === 'anb'
            ? item.anb === anb
            : item.loc === loc),
      ),
    );
    setCurrYear((state) => state + 1);
  }, [currYear, rawList]);

  const handleZONselection = useCallback(() => {
    const zon =
      user.perfil === 'ZON'
        ? user.zoncod
        : formRef.current?.getFieldValue('comboZON');
    const desc =
      user.perfil === 'ZON'
        ? user.zoncod
        : comboZons[comboZons.findIndex((item) => item.value === zon)].label;
    const toSet = { cod: zon.substr(0, 2), desc };

    setLocalStorage(`${process.env.REACT_APP_EPG}`, {
      ...toSet,
      filterBy: 'zonal',
      zon: zon.substr(0, 2),
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_EPG}`, ['loc', 'anb']);

    setCoord(toSet);

    setInitialZon(zon);
    setInitialNac('Selecione');
    setInitialLoc('Selecione');

    setComboLocs([]);
    setComboNacs(
      anbs.filter((item) => item.value.substr(0, 2) === zon.substr(0, 2)),
    );

    setList(
      rawList.filter(
        (item) =>
          item.anb.substr(0, 2) === zon.substr(0, 2) && item.ano === currYear,
      ),
    );
  }, [anbs, comboZons, currYear, rawList, user.perfil, user.zoncod]);

  const handleNACselection = useCallback(() => {
    let anb = '';
    const keysToDelete = ['loc'];
    if (user.perfil === 'NAC') {
      anb = user.anbc;
      keysToDelete.push('cod', 'desc', 'filterBy');
    } else {
      anb = formRef.current?.getFieldValue('comboANB');
      const index = anbs.findIndex((item) => item.value === anb);

      const toSet = { cod: anb, desc: anbs[index].label };
      setLocalStorage(`${process.env.REACT_APP_EPG}`, {
        ...toSet,
        filterBy: 'anb',
        anb,
      });

      setCoord(toSet);

      setInitialNac(anb);

      setComboLocs(locs.filter((item) => item.anb === anb));
    }

    deleteLocalStorageItemKey(`${process.env.REACT_APP_EPG}`, keysToDelete);
    setInitialLoc('Selecione');
    setList(
      rawList.filter((item) => item.anb === anb && item.ano === currYear),
    );
  }, [anbs, currYear, locs, rawList, user.anbc, user.perfil]);

  const handleLOCselection = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLOC');
    const index = locs.findIndex((item) => item.value === loc);

    const toSet = { cod: loc, desc: locs[index].label };
    setLocalStorage(`${process.env.REACT_APP_EPG}`, {
      ...toSet,
      filterBy: 'local',
      loc,
    });

    setCoord(toSet);

    setInitialLoc(loc);
    setList(
      rawList.filter((item) => item.loc === loc && item.ano === currYear),
    );
  }, [locs, rawList, currYear]);

  const handleExclusion = useCallback(async () => {
    try {
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      setLoading(true);
      if (!deleteDiag.values) {
        return;
      }

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          epg: deleteDiag.values?.epg,
        }),
      );

      await api.post('/sgo/epg_delete.php', send, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setList((state) =>
        state.filter((item) => item.epg !== deleteDiag.values?.epg),
      );
      setRawList((state) =>
        state.filter((item) => item.epg !== deleteDiag.values?.epg),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [deleteDiag.values, errorHandling]);

  const removeButtonNac = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeButtonLoc = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <ScrollTop />
      <Loading isLoading={loading} />
      <IncludeButton />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {['INT'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Zonal:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    initial={initialZon}
                    onChange={handleZONselection}
                  />
                  <RemoveButton
                    type="button"
                    style={{ pointerEvents: 'none', opacity: 0 }}
                  >
                    <FaTimes />
                  </RemoveButton>
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleNACselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleZONselection}
                    style={removeButtonNac}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              <span>
                <p>Filtre por Local:</p>
                <SelectV2
                  name="comboLOC"
                  content={comboLocs}
                  initial={initialLoc}
                  onChange={handleLOCselection}
                />
                <RemoveButton
                  type="button"
                  onClick={handleNACselection}
                  style={removeButtonLoc}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Escolas de Preparação de Guias - EPG</p>
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>

        {!!rawList && rawList.length > 0 && (
          <YearContainer>
            {currYear > years[0].year && (
              <LeftSVG onClick={handleDecreaseYear} />
            )}
            <p>{currYear}</p>
            {currYear < years[years.length - 1].year && (
              <RightSVG onClick={handleIncreaseYear} />
            )}
          </YearContainer>
        )}
        <Grid>
          {list.map((item) => (
            <GridItem key={item.epg}>
              <InfoContainer>
                {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                  <span>
                    <p>
                      Nacional: <strong>{item.anbdesc}</strong>
                    </p>
                  </span>
                )}
                {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                  <span>
                    <p>
                      Local: <strong>{item.locdesc}</strong>
                    </p>
                  </span>
                )}
                <span>
                  <p>
                    Início: <strong>{formatDate(item.dti1)}</strong>
                  </p>
                  <p>
                    Fim: <strong>{formatDate(item.dtf3)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Guia: <strong>{item.gn1}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Situação:&nbsp;
                    <strong
                      style={{
                        color: !item.sit
                          ? '#332e2e'
                          : item.sit === 'F'
                          ? '#0070c0'
                          : '#00b050',
                      }}
                    >
                      {!item.sit
                        ? !startedEPG(item.dti1)
                          ? 'Programada'
                          : 'Em andamento'
                        : item.sit === 'F'
                        ? 'Finalizada prematuramente'
                        : 'Encerrada'}
                    </strong>
                  </p>
                </span>
                {!!item.sit && (
                  <span>
                    <p>
                      Data encerramento:&nbsp;
                      <strong>{formatDate(item.end)}</strong>
                    </p>
                  </span>
                )}
              </InfoContainer>
              <CodContainer>
                <p>
                  Cód:&nbsp;
                  <strong>E-{item.epg}</strong>
                </p>
              </CodContainer>
              <O.GridOptions>
                <O.ShowLink
                  background="#7159c1"
                  to={{ pathname: `${pathname}/etapas`, state: { ...item } }}
                  title="Etapas"
                >
                  <FaTrafficLight />
                </O.ShowLink>
                {/* <O.ShowLink
                  to={{ pathname: `${pathname}/consult`, state: { ...item } }}
                >
                  <FaSearch />
                </O.ShowLink> */}
                {(user.perfil === 'LOC' ||
                  [item.ge1, item.ge2, item.ge3].indexOf(user.gseq) > -1) && (
                  <>
                    {!item.sit && (
                      <O.Update
                        to={{
                          pathname: `${pathname}/update`,
                          state: { ...item },
                        }}
                      >
                        <FaPencilAlt />
                      </O.Update>
                    )}
                    {item.qpart1 + item.qpart2 + item.qpart3 === 0 && (
                      <O.Delete
                        onClick={() =>
                          setDeleteDiag((state) => ({
                            ...state,
                            open: !state.open,
                            values: {
                              epg: item.epg,
                            },
                            content: (
                              <D.ModalDeleteContent>
                                <p>
                                  Você está prestes a excluir&nbsp;
                                  <strong style={{ color: '#c53030' }}>
                                    permanentemente
                                  </strong>
                                  &nbsp;a&nbsp;
                                  <strong>EPG E-{item.epg}</strong>
                                </p>
                                <p>
                                  Se estiver seguro de sua decisão, clique em
                                  confirmar.
                                </p>
                              </D.ModalDeleteContent>
                            ),
                          }))
                        }
                      >
                        <FaTimes />
                      </O.Delete>
                    )}
                  </>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() =>
                setDeleteDiag((state) => ({ ...state, open: !state.open }))
              }
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleExclusion}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EPG;
