import React, { HTMLAttributes, useState, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  FaArrowLeft,
  FaShare,
  FaFacebook,
  FaInstagram,
  // FaWhatsapp,
} from 'react-icons/fa';
import { useWindow } from 'hooks/window';

import {
  Container,
  Child,
  SocialMediaContainer,
  BurgerContainer,
  Burger,
  BurgerContent,
} from './styles';
import { menu, services } from './menu-items';

interface NavbarProps extends HTMLAttributes<HTMLDivElement> {
  containerStyle?: object;
  isHome?: boolean;
}

const NavbarContainer: React.FC<NavbarProps> = ({
  containerStyle,
  isHome = false,
}) => {
  const { width } = useWindow();
  const { pathname } = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (pathname.substr(-6) === 'signin') {
      history.replace(pathname.replace('signin', ''));
      return;
    }

    history.goBack();
  }, [pathname, history]);

  const toggleOpen = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return (
    <Container style={containerStyle}>
      {!isHome && <FaArrowLeft onClick={handleClick} />}
      {width < 950 ? (
        <BurgerContainer>
          <Burger isOpen={open} onClick={toggleOpen}>
            <div />
          </Burger>

          <BurgerContent isOpen={open}>
            <div>
              <span>
                <FaShare />
                <Link to="/sgo">Área do Guia</Link>
              </span>
            </div>
            <details open>
              <summary>Menu</summary>
              <div>
                {menu.map((item) => (
                  <span>
                    <FaShare />
                    <Link key={`nav${item.route}`} to={`/${item.route}`}>
                      {item.label}
                    </Link>
                  </span>
                ))}
              </div>
            </details>
            <details open>
              <summary>Serviços</summary>
              <div>
                {services.map((item) => (
                  <span>
                    <FaShare />
                    <Link key={`nav${item.route}`} to={`/${item.route}`}>
                      {item.label}
                    </Link>
                  </span>
                ))}
              </div>
            </details>
            {/* <Link to="/sgo">
              <h3>Área do Guia</h3>
            </Link> */}
            {/* {children} */}
          </BurgerContent>
        </BurgerContainer>
      ) : (
        <>
          <Child>
            <Link to="/sgo">
              <h3>Área do Guia</h3>
            </Link>
          </Child>
          <Child quantity={menu.length}>
            <h3>Menu</h3>
            <span>
              {menu.map((item) => (
                <Link key={`nav${item.route}`} to={`/${item.route}`}>
                  {item.label}
                </Link>
              ))}
            </span>
          </Child>
          <Child quantity={services.length}>
            <h3>Serviços</h3>
            <span>
              {services.map((item) => (
                <Link key={`nav${item.route}`} to={`/${item.route}`}>
                  {item.label}
                </Link>
              ))}
            </span>
          </Child>
        </>
      )}

      <SocialMediaContainer>
        <a
          href="https://www.instagram.com/oficinasdeoracaoevida"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaInstagram size={30} />
        </a>

        {/* <a
          href="https://api.whatsapp.com/send?phone=+393475987431&text=\`\`\`Motivo do Contato:\`\`\` Auxílio site.%0a%0a"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaWhatsapp size={30} />
        </a> */}

        <a
          href="https://facebook.com/TovBrasilOficial"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaFacebook size={30} />
        </a>
      </SocialMediaContainer>
    </Container>
  );
};

NavbarContainer.defaultProps = {
  containerStyle: {},
};

export default NavbarContainer;
