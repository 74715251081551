import styled from 'styled-components';

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 800px;

  @media screen and (max-width: 800px) {
    width: 100vw;
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;

    &:nth-child(1) {
      text-align: center;
    }
    &:nth-last-child(1) {
      text-align: right;
    }
  }

  tr {
    align-items: center;
    min-height: 40px;

    &:nth-last-child(1) {
      > td {
        &:nth-child(1) {
          text-align: right;

          p {
            color: #8a0002;
          }
          strong {
            font-size: 17px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    td {
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
`;
