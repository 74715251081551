import styled from 'styled-components';
import { animated } from 'react-spring';

import { shade } from 'polished';

interface ModButton {
  mode: string;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #fff;
  font-size: 16px;

  padding: 5px 10px;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
    color: #8a0002;
    font-weight: bold;
  }

  transition: all 0.35s ease;
  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;

export const SearchWrapper = styled.div``;
export const InfoWrapper = styled(animated.div)`
  margin: 10px 0 0 0;
  strong {
    color: #332e2e;
  }
`;

export const ClearButton = styled(animated.button)`
  margin: 0 auto;
  padding: 2px 5px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  color: #8a0002;
  border-width: 0px;
  border-bottom: 2px solid #8a0002;

  svg {
    margin: 0 3px 0 0;
    width: 15px;
    height: 15px;
    transform: translateY(7.5%);
  }
`;

export const Button = styled(animated.button)<ModButton>`
  --color: ${(props) => (props.mode === 'search' ? '#5e5555' : '#00802b')};
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  min-width: 40px;
  font-size: 16px;
  color: #fff;
  background: var(--color);
  border: 3px solid var(--color);
  border-radius: 3px;

  box-shadow: 0.5px 1.25px 2.5px 0.75px rgba(0, 0, 0, 0.5);
  svg {
    /* margin: 0 0 0 5px; */
  }
  transition: all 0.35s ease;
  &:hover {
    background-color: ${(props) =>
      shade(0.2, props.mode === 'search' ? '#5e5555' : '#00802b')};
  }
`;
