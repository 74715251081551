import React, { useState, useCallback, useEffect } from 'react';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import { useHistory, useLocation } from 'react-router-dom';

import api from 'services/api';

import { monthList, MonthListProps } from 'pages/sgo/Tesouraria/monthList';

import { FaFileExcel, FaFileInvoiceDollar, FaReceipt } from 'react-icons/fa';
import { formatNumber } from 'utils/calcTotal';
import { formatDate } from 'utils/formatDate';
import {
  Grid,
  GridItem,
  YearContainer,
  LeftSVG,
  RightSVG,
  ButtonXLS,
  ButtonNF,
  ButtonReceipt,
} from './styles';

interface AvailableProps {
  month: string;
  vlrnf: number;
  dtpag: string;
  receipt: string;
}

interface ListProps {
  year: number;
  available: AvailableProps[];
}

const Faturamento: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();

  const [downloadURL, setDownloadURL] = useState({});
  const [loading, setLoading] = useState(false);
  const [raw, setRaw] = useState<ListProps[]>([]);
  const [currYear, setCurrYear] = useState(() => new Date().getUTCFullYear());
  const [currIndex, setCurrIndex] = useState(0);
  const [code] = useState(() => {
    return user.perfil === 'ZON' ? user.zoncod : user.anbc;
  });
  const [list, setList] = useState<AvailableProps[]>([]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<ListProps[]>('/sgo/faturamento_list.php');
      setRaw(response.data);
      setCurrIndex(response.data.length > 0 ? response.data.length - 1 : 0);

      const hasYear = response.data.findIndex(
        (item: ListProps) => currYear === item.year,
      );
      if (hasYear < 0) {
        setCurrYear(response.data[response.data.length - 1].year);
      }

      const search =
        response.data[response.data.length > 0 ? response.data.length - 1 : 0]
          .available;

      setList([
        ...monthList
          .filter(
            (month: MonthListProps) =>
              search.findIndex(
                (item) =>
                  month.label ===
                  (item.month === 'March' ? 'Março' : item.month),
              ) > -1,
          )
          .map((month: MonthListProps) => ({
            month: month.label,
            vlrnf:
              search[
                search.findIndex(
                  (item) =>
                    (item.month === 'March' ? 'Março' : item.month) ===
                    month.label,
                )
              ].vlrnf,
            dtpag:
              search[
                search.findIndex(
                  (item) =>
                    (item.month === 'March' ? 'Março' : item.month) ===
                    month.label,
                )
              ].dtpag,
            receipt:
              search[
                search.findIndex(
                  (item) =>
                    (item.month === 'March' ? 'Março' : item.month) ===
                    month.label,
                )
              ].receipt,
          })),
      ]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON'], true);
    getList();
  }, [getList, handlePermission]);

  const handleDecreaseYear = useCallback(() => {
    setCurrYear((state) => state - 1);
    setCurrIndex((state) => state - 1);
    const search = raw[currIndex - 1].available;

    setList([
      ...monthList
        .filter(
          (month: MonthListProps) =>
            search.findIndex(
              (item) =>
                month.label === (item.month === 'March' ? 'Março' : item.month),
            ) > -1,
        )
        .map((month: MonthListProps) => ({
          month: month.label,
          vlrnf:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].vlrnf,
          dtpag:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].dtpag,
          receipt:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].receipt,
        })),
    ]);
  }, [currIndex, raw]);

  const handleIncreaseYear = useCallback(() => {
    setCurrYear((state) => state + 1);
    setCurrIndex((state) => state + 1);

    const search = raw[currIndex + 1].available;

    setList([
      ...monthList
        .filter(
          (month: MonthListProps) =>
            search.findIndex(
              (item) =>
                month.label === (item.month === 'March' ? 'Março' : item.month),
            ) > -1,
        )
        .map((month: MonthListProps) => ({
          month: month.label,
          vlrnf:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].vlrnf,
          dtpag:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].dtpag,
          receipt:
            search[
              search.findIndex(
                (item) =>
                  (item.month === 'March' ? 'Março' : item.month) ===
                  month.label,
              )
            ].receipt,
        })),
    ]);
  }, [currIndex, raw]);

  const handleClick = useCallback(
    async (ev) => {
      const { type, url } = ev.currentTarget.dataset;

      setLoading(true);
      const response = await api.get(
        `/sgo/faturamento_download.php?data=${JSON.stringify({
          link: `${url}.${type}`,
        })}`,
      );
      const { exists, link } = response.data;

      if (exists) {
        setDownloadURL({
          href: link,
          target: type === 'xls' ? '_self' : '_blank',
        });

        const linkId = document.querySelector<HTMLAnchorElement>(
          `a[id=fileDownloader]`,
        );

        if (linkId) {
          linkId.click();
          setDownloadURL({});
          setLoading(false);
          return;
        }
      }

      addToast({
        type: 'info',
        title: `${
          type === 'xls' ? 'Relatório não encontrado' : 'NF não encontrada'
        }`,
        seconds: 5,
      });
      // }
      setLoading(false);
    },
    [addToast],
  );

  const handleReceipt = useCallback(
    (month: string) => {
      const index = list.findIndex(
        (item: AvailableProps) => item.month === month,
      );

      if (!list[index].receipt) {
        history.push(`${pathname}/comprovante`, {
          year: currYear,
          month:
            monthList[
              monthList.findIndex(
                (item: MonthListProps) => item.label === month,
              )
            ].value,
        });
      }

      const link = document.querySelector<HTMLAnchorElement>(
        `a[id=a${currYear}_${month}_receipt]`,
      );

      if (link) {
        link.click();
      }
    },
    [currYear, history, list, pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>Faturamento Mensal</div>
        </AlteredHeader>
        {!!raw && raw.length > 0 && (
          <YearContainer>
            {raw.findIndex((item) => item.year === currYear) > 0 && (
              <LeftSVG onClick={handleDecreaseYear} />
            )}
            <p>{currYear}</p>
            {raw.findIndex((item) => item.year === currYear) <
              raw.length - 1 && <RightSVG onClick={handleIncreaseYear} />}
          </YearContainer>
        )}
        <a
          id="fileDownloader"
          rel="noreferrer noopener"
          {...downloadURL}
          style={{ display: 'none' }}
        >
          none
        </a>
        <Grid>
          {list.map((item) => (
            <GridItem key={item.month}>
              <h2>{item.month}</h2>
              <div>
                <p>
                  Valor NF:&nbsp;
                  <strong>
                    {item.vlrnf
                      ? `R$ ${formatNumber(item.vlrnf)}`
                      : 'Valor não disponível'}
                  </strong>
                </p>
                <p>
                  Data do Pagamento:&nbsp;
                  <strong>
                    {item.dtpag
                      ? formatDate(item.dtpag)
                      : 'Data não disponível'}
                  </strong>
                </p>
              </div>
              <span>
                <ButtonXLS
                  type="button"
                  title="Relatório"
                  data-type="xls"
                  data-url={`${process.env.REACT_APP_FATURAMENTO}/${currYear}/${
                    item.month === 'Março' ? 'March' : item.month
                  }/${code}_${currYear}_${`0${
                    monthList[
                      monthList.findIndex(
                        (month: MonthListProps) => month.label === item.month,
                      )
                    ].value
                  }`.slice(-2)}`}
                  onClick={handleClick}
                >
                  <FaFileExcel />
                </ButtonXLS>
                <ButtonNF
                  type="button"
                  title="Nota Fiscal"
                  data-type="pdf"
                  data-url={`${process.env.REACT_APP_FATURAMENTO}/${currYear}/${
                    item.month === 'Março' ? 'March' : item.month
                  }/${code}_${currYear}_${`0${
                    monthList[
                      monthList.findIndex(
                        (month: MonthListProps) => month.label === item.month,
                      )
                    ].value
                  }`.slice(-2)}`}
                  onClick={handleClick}
                >
                  <FaFileInvoiceDollar />
                </ButtonNF>
                {!!item.receipt && (
                  <a
                    id={`a${currYear}_${item.month}_receipt`}
                    href={`${process.env.REACT_APP_COMPROVANTE}/${item.receipt}`}
                    rel="noreferrer noopener"
                    target="_blank"
                    // download="Comprovante"
                  >
                    Comprovante
                  </a>
                )}
                {item.vlrnf ? (
                  <ButtonReceipt
                    type="button"
                    title={`${
                      item.receipt ? 'Visualizar' : 'Inserir'
                    } Comprovante`}
                    onClick={() => handleReceipt(item.month)}
                  >
                    <FaReceipt />
                  </ButtonReceipt>
                ) : null}
              </span>
            </GridItem>
          ))}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Faturamento;
