import styled from 'styled-components';

export const Header = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
  }

  @media screen and (max-width: 610px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100vw;
  }
`;

export const FormContent = styled.div`
  form {
    > div {
      background: #fff;
      padding: 5px;
      border: 3px solid #efefef;
      border-radius: 5px;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

      transition: width 350ms, border 350ms, border-radius 350ms;
      h3 {
        font-size: 18.72px;
      }
      div {
        transition: width 350ms;

        table {
          p {
            font-size: 16px;
            font-weight: bold;
          }
          thead {
            td {
              &:nth-child(2) {
                text-align: right;
              }
            }
          }
          tbody {
            tr {
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 27px;
                height: 27px;
                background: transparent;
                border: 0px;

                svg {
                  color: #c53030;
                  width: 17px;
                  height: 17px;
                }
              }
              &:nth-last-child(1) {
                text-align: right;
                td {
                  &:nth-child(2) {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }

      & + div {
        margin: 10px 0 0 0;
      }

      @media screen and (max-width: 420px) {
        width: 100vw;
        border-right-width: 0px;
        border-left-width: 0px;
        border-radius: 0px;
      }
    }
  }
`;

export const OtherPartsContainer = styled.section`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 340px;
  min-width: 340px;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: width 0.5s ease, border-radius 0.5s, border-width 0.5s;
  form {
    div {
      display: flex;
      align-items: center;
      flex-direction: row !important;
      span {
        margin: 0 10px 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          color: #332e2e;
          margin: 0 7px 0 0;
          transform: translateY(-1.5px);
          font-weight: 500;
        }
        abbr {
          font-size: 11px;
          color: #c53030;
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    min-width: unset;
    max-width: unset;
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const SectionContainer = styled.section`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  max-width: 600px;
  min-width: 600px;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  h3 {
    font-size: 18.72px;
    color: #424242;
  }

  @media screen and (max-width: 605px) {
    min-width: unset;
    max-width: unset;
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const AddSection = styled.div`
  display: flex;
  margin: 10px 0;

  div {
    &:nth-child(2) {
      display: flex;

      > span {
        &:nth-child(1) {
          /* transform: translateY(-18px); */
        }
      }
    }
    & + div {
      margin: 0 0 0 5px;
    }

    span {
      color: #424242;
      font-size: 14px;
      p {
        margin: 0 0 3px 0;
      }
      & + span {
        margin: 0 0 0 5px;
      }

      &:nth-child(4) {
        display: flex;

        align-items: flex-end;
        padding: 0 0 5px 0;

        button {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #388e3c;
          border: 1px solid #388e3c;
          border-radius: 3px;
          svg {
            height: 15px;
            width: 15px;
            color: #fff;
          }
        }
      }
    }

    &:nth-child(3) {
      display: flex;

      align-items: flex-end;
      span {
        padding: 0 0 5px 0;

        button {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #388e3c;
          border: 1px solid #388e3c;
          border-radius: 3px;
          svg {
            height: 15px;
            width: 15px;
            color: #fff;
          }
        }
      }
    }
  }

  @media screen and (max-width: 605px) {
    flex-direction: column;
    align-items: center;

    div {
      & + div {
        margin: 0 0 0 20px;
      }

      &:nth-child(2) {
        > span {
          &:nth-child(1) {
            p {
              transform: translateX(-20px);
            }
            transform: translate(15px, 0px);
          }
        }
      }
    }
  }
`;

export const TableContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    font-size: 16px;
    &:nth-child(1) {
      td {
        border-bottom: 1px solid transparent;
        border-bottom: 1px solid black;
        /* text-align: center; */

        &:nth-last-child(1){
          border-bottom: 1px solid transparent;
        }
      }
    }
    &:nth-child(2n + 3) {
      background: #f2f2f2;
    }

  }

  td {
    padding: 2px 3px;
    /* border-bottom: 1px solid black; */
    text-align: right;
    overflow: hidden;

    &:nth-child(1) {
      width: 50%;
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
      width: 40px;
    }

    &:nth-child(3), &:nth-child(4){
      width: 20%;
    }

    &:nth-last-child(1) {
      border-bottom: 1px solid transparent;
      width: 10%;
      align: center;
      /* background: #fff; */
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 0;
      background transparent;
      margin: 0 auto;

      svg {
        color: #c53030;
        height: 20px;
        width: 20px;
      }
    }
  }

  @media screen and (max-width: 600px){
    tr{
      font-size: 14px;
    }
  }
  @media screen and (max-width: 480px){
    td{
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 10%;
      }

      &:nth-last-child(2) {
        display: none;
      }

    }
  }
`;

export const TotalSection = styled.div`
  margin: 15px 0 0 0;
  padding: 0 25px;
  display: flex;
  font-size: 16px;

  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const TotalContainer = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  max-width: 340px;
  min-width: 340px;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: width 0.5s ease, border-radius 0.5s, border-width 0.5s;

  p {
    font-size: 18px;
    color: #332e2e;
  }

  @media screen and (max-width: 360px) {
    min-width: unset;
    max-width: unset;
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;
