import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import Aplic from 'pages/sgo/Tesouraria/Aplic/main';

import MRoutes from './material';
import SRoutes from './secretaria';
import TRoutes from './tesouraria';
import GRoutes from './gerencial';
import ORoutes, { OtherRoutes } from './general';

import Route, { ComponentProps } from './Route';

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Switch>
      {MRoutes.map((item: ComponentProps) => (
        <Route
          key={`${item.path}`}
          path={`/sgo/material${item.path}`}
          exact
          component={item.component}
          isPrivate={item.isPrivate}
        />
      ))}
      {SRoutes.map((item: ComponentProps) => (
        <Route
          key={`${item.path}`}
          path={`/sgo/secretaria${item.path}`}
          exact
          component={item.component}
          isPrivate={item.isPrivate}
        />
      ))}
      {TRoutes.map((item: ComponentProps) => (
        <Route
          key={item.path}
          path={`/sgo/tesouraria${item.path}`}
          exact
          component={item.component}
          isPrivate={item.isPrivate}
        />
      ))}
      {user?.tes.content.map((aplic) => {
        return (
          !!aplic.uuid && (
            <Route
              key={aplic.uuid}
              path={`/sgo/tesouraria/appl/${aplic.uuid}`}
              exact
              component={Aplic}
              isPrivate
            />
          )
        );
      })}
      {GRoutes.map((item: ComponentProps) => (
        <Route
          key={`${item.path}`}
          path={`/sgo/gerencial${item.path}`}
          exact
          component={item.component}
          isPrivate={item.isPrivate}
        />
      ))}
      {ORoutes.map((item: OtherRoutes) => (
        <Route
          key={`${item.path}`}
          path={`${item.path}`}
          exact={item.exact}
          component={item.component}
          isPrivate={item.isPrivate}
          isADM={item.isADM}
        />
      ))}
      {/* {ORoutes.map((item: OtherRoutes, index: number) => item.route)} */}
    </Switch>
  );
};

export default Routes;
