import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import IncludeButton from 'components/IncludeButton';
import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import api from 'services/api';

import { FaPencilAlt, FaTimes } from 'react-icons/fa';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as O from 'styles/option_buttons';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Checkbox from 'components/Checkbox';
import { List, YearContainer, LeftSVG, RightSVG } from './styles';

import { FIGuiaProps } from '../../main';

export interface ListProps {
  seq: number;
  year: number;
  cod: string;
  desc: string;
  january: boolean;
  february: boolean;
  march: boolean;
  april: boolean;
  may: boolean;
  june: boolean;
  july: boolean;
  august: boolean;
  september: boolean;
  october: boolean;
  november: boolean;
  december: boolean;
}

interface DeletePropsInfo {
  seq: number;
  ano: number;
  descr: string;
}

const FigAssist: React.FC = () => {
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [deleteProps, setDeleteProps] = useState<DeletePropsInfo>(
    {} as DeletePropsInfo,
  );
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);

  // const [years, setYears] = useState<ComboProps[]>([]);
  const [years, setYears] = useState<{ year: number }[]>([]);

  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [maxDate, setMaxDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [currYear, setCurrYear] = useState(new Date().getFullYear());

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_assist.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      const { assists, sentDate, anos, top } = response.data;

      setYears(anos);
      if (anos.length - 1 >= 0) {
        setCurrYear(anos[anos.length - 1].year);
        setList(
          assists.filter(
            (item: ListProps) => item.year === anos[anos.length - 1].year,
          ),
        );
      }

      setMinDate(sentDate);
      setMaxDate(top);

      setRawList(assists);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getList();
  }, [getList, history, location.state]);

  const handleDecreaseYear = useCallback(() => {
    const index = years.findIndex((item) => item.year === currYear);

    const nYear = years[index > 0 ? index - 1 : 0].year;

    setCurrYear(nYear);
    setList(rawList.filter((item) => item.year === nYear));
  }, [currYear, years, rawList]);

  const handleIncreaseYear = useCallback(() => {
    const index = years.findIndex((item) => item.year === currYear);

    const nYear = years[index === years.length - 1 ? index : index + 1].year;

    setCurrYear(nYear);
    setList(rawList.filter((item) => item.year === nYear));
  }, [currYear, years, rawList]);

  const handlePreDelete = useCallback(
    (ano: number, descr: string, seq: number) => {
      setDeleteProps({ seq, ano, descr });
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [],
  );

  const handleDelete = useCallback(async () => {
    try {
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      // const index = list.findIndex((item) => item.seq === deleteProps.seq);

      const send = new FormData();

      send.append('data', JSON.stringify({ ...gState, ...deleteProps }));

      await api.post('/sgo/fig_assist.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRawList((state) => [
        ...state.filter((item) => item.seq !== deleteProps.seq),
      ]);
      setList((state) => [
        ...state.filter((item) => item.seq !== deleteProps.seq),
      ]);
      if (list.length === 1) {
        if (currYear === years[years.length - 1].year) {
          handleDecreaseYear();
        } else {
          handleIncreaseYear();
        }
        setYears((state) => [
          ...state.filter((item) => item.year !== currYear),
        ]);
      }

      if (rawList.length === 1) {
        setYears([]);
        setList([]);
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [
    currYear,
    deleteProps,
    errorHandling,
    gState,
    handleDecreaseYear,
    handleIncreaseYear,
    list.length,
    rawList.length,
    years,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
        <IncludeButton
          options={{ ...location.state, maxDate, minDate, rawList: [] }}
        />
      )}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Participações em Eventos</p>
        </AlteredHeader>
        {!!rawList && rawList.length > 0 && (
          <YearContainer>
            {years.length > 0 && currYear > years[0].year && (
              <LeftSVG onClick={handleDecreaseYear} />
            )}
            <p>{currYear}</p>
            {years.length > 0 && currYear < years[years.length - 1].year && (
              <RightSVG onClick={handleIncreaseYear} />
            )}
          </YearContainer>
        )}

        <List>
          {list.map((item) => (
            <div key={`${item.year}${item.cod}`}>
              <h4>{item.desc}</h4>
              <div>
                <span>
                  <p>Jan</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}jan`}
                    title=""
                    checked={item.january}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Fev</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}feb`}
                    title=""
                    checked={item.february}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Mar</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}mar`}
                    title=""
                    checked={item.march}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Abr</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}apr`}
                    title=""
                    checked={item.april}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Mai</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}may`}
                    title=""
                    checked={item.may}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Jun</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}jun`}
                    title=""
                    checked={item.june}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Jul</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}jul`}
                    title=""
                    checked={item.july}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Ago</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}aug`}
                    title=""
                    checked={item.august}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Set</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}sep`}
                    title=""
                    checked={item.september}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Out</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}oct`}
                    title=""
                    checked={item.october}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Nov</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}nov`}
                    title=""
                    checked={item.november}
                    isDisabled
                  />
                </span>
                <span>
                  <p>Dez</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={`${item.year}${item.cod}dec`}
                    title=""
                    checked={item.december}
                    isDisabled
                  />
                </span>
              </div>
              {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                <O.GridOptions>
                  <O.Update
                    to={{
                      pathname: `${location.pathname}/update`,
                      state: { ...item, ...gState },
                    }}
                  >
                    <FaPencilAlt />
                  </O.Update>
                  <O.Delete
                    onClick={() =>
                      handlePreDelete(item.year, item.desc, item.seq)
                    }
                  >
                    <FaTimes />
                  </O.Delete>
                </O.GridOptions>
              )}
            </div>
          ))}
        </List>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <div>
                  <p>
                    <strong>{deleteProps.descr}</strong>&nbsp;de&nbsp;
                    <strong>{deleteProps.ano}</strong>
                  </p>
                </div>
                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
        {/*
        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}



        <Grid>
          {list.map((item) => (
            <GridItem key={item.seq}>
              <h4>{item.tsdesc}</h4>

              <div>
                <span>
                  <p>
                    Início:&nbsp;<strong>{formatDate(item.start)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Fim:&nbsp;<strong>{formatDate(item.end)}</strong>
                  </p>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    Local:&nbsp;<strong>{item.place}</strong>
                  </p>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    Participantes:&nbsp;<strong>{item.part}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {/^\d+$/.test(item.pers) ? 'Perseverantes' : 'Tipo'}:&nbsp;
                    <strong>{item.pers}</strong>
                  </p>
                </span>
              </div>
              <O.GridOptions>
                <OptButton
                  bgcolor="#007acc"
                  onClick={() => handlePreAlt(item.seq)}
                >
                  <FaPencilAlt />
                </OptButton>
                <OptButton
                  bgcolor="#c53030"
                  onClick={() => handlePreDel(item.seq)}
                >
                  <FaTimes />
                </OptButton>
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Serviço:</p>
                      <SelectV2
                        name="service"
                        content={comboTG}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Local:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        placeholder="Local do serviço"
                        name="place"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Data Início:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        isDate
                        placeholder="dd/mm/aaaa"
                        name="dtstart"
                      />
                    </span>
                    <span>
                      <p>Data Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        isDate
                        placeholder="dd/mm/aaaa"
                        name="dtend"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Participantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Participantes"
                        name="part"
                      />
                    </span>
                    <span>
                      <p>Perseverantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Perseverantes"
                        name="pers"
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              dtstart: prevReg.dtstart,
              dtend: prevReg.dtend,
              place: prevReg.place,
              part: prevReg.part,
              pers: prevReg.pers,
            }}
          >
            <U.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Serviço:</p>
                      <SelectV2
                        name="service"
                        content={comboTG}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.service}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Local:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        placeholder="Local do serviço"
                        name="place"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Data Início:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                        name="dtstart"
                      />
                    </span>
                    <span>
                      <p>Data Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                        name="dtend"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Participantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Participantes"
                        name="part"
                      />
                    </span>
                    <span>
                      <p>Perseverantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Perseverantes"
                        name="pers"
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={() => setUpdateDiag({ open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <GridItem>
                  <h4>{prevConReg.service}</h4>

                  <div>
                    <span>
                      <p>
                        Início:&nbsp;
                        <strong>{formatDate(prevConReg.dtstart)}</strong>
                      </p>
                    </span>
                    <span style={{ marginLeft: '5px' }}>
                      <p>
                        Fim:&nbsp;
                        <strong>{formatDate(prevConReg.dtend)}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Local:&nbsp;<strong>{prevConReg.place}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Part.:&nbsp;<strong>{prevConReg.part}</strong>
                      </p>
                    </span>
                    <span style={{ marginLeft: '5px' }}>
                      <p>
                        {/^\d+$/.test(prevConReg.pers) ? 'Pers.' : 'Tipo'}
                        :&nbsp;
                        <strong>{prevConReg.pers}</strong>
                      </p>
                    </span>
                  </div>
                </GridItem>

                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container> */}
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FigAssist;
