import styled, { css, keyframes } from 'styled-components';
import { shade, lighten } from 'polished';
import { animated } from 'react-spring';

interface FloatButtonProps {
  amountscrolled: number;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  bgcolor: string;
  customwidth?: string;
  inverticon: boolean;
  svgcolor?: string;
}

const appearFromRight = keyframes`
  from {
    width: 0px;
    box-shadow: 0px 0px 0px 0px #262626;
    transform: translateX(15px);
    pointer-events: none;
  } to {
    width: 55px;
    box-shadow: 0px 5px 10px 2px #262626;
    transform: translateX(-10px);
    pointer-events: all;
  }
`;

const appearFromLeft = keyframes`
  from {
    width: 0px;
    box-shadow: 0px 0px 0px 0px #262626;
    transform: translateX(-15px);
    pointer-events: none;
  } to {
    width: 55px;
    box-shadow: 0px 5px 10px 2px #262626;
    transform: translateX(10px);
    pointer-events: all;
  }
`;

export const Container = styled(animated.button)<FloatButtonProps>`
  margin: -10px;
  cursor: pointer;

  z-index: 5;
  position: fixed;
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${(props) => props.bgcolor};

  background: ${(props) => props.bgcolor};
  overflow: hidden;
  box-shadow: 0px 5px 10px 2px ${(props) => shade(0.35, props.bgcolor)};

  ${(props) =>
    props.customwidth &&
    css`
      width: ${props.customwidth};
    `}

  ${(props) =>
    props.top &&
    css`
      top: calc((281px + ${props.top}px) - ${props.amountscrolled}px);
    `};
  ${(props) =>
    props.right &&
    css`
      right: ${props.right};
      border-radius: 5px 0px 0px 5px;
      animation: ${appearFromRight} 0.5s ease forwards;
    `};
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `};
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
      border-radius: 0px 5px 5px 0px;
      animation: ${appearFromLeft} 0.5s ease forwards;
    `};

  p {
    color: #fff;
    font-weight: 500;
  }
  svg {
    height: 25px;
    width: 25px;
    color: #efefef;
    opacity: 0.8;

    transition: opacity 0.35s ease, color 0.35s ease;

    ${(props) =>
      props.inverticon &&
      css`
        transform: rotateY(180deg);
      `}
    ${(props) =>
      props.svgcolor &&
      css`
        color: ${props.svgcolor};
      `}
  }

  &:hover {
    svg {
      color: #fff;

      ${(props) =>
        props.svgcolor &&
        css`
          color: ${lighten(0.2, `${props.svgcolor}`)};
        `}
      opacity: 1;
    }
  }
`;
