import React from 'react';

import { Container, Content } from 'styles/wrappers';
import { AlteredHeader } from 'styles/sgo_wrappers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
import { Common, Missao } from '../styles';

const tabua = `${process.env.REACT_APP_ASSETS_DIR}/aboutTOV/TabuaTov.png`;

const MissaoComp: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Nossa Missão</div>
        </AlteredHeader>
        <Common>
          <Missao>
            <h4>
              Antes de modelar-te no seio de tua mãe, antes de saíres do seu
              ventre,
            </h4>

            <h4>Eu te conhecia;</h4>
            <h4>Eu te consagrei; </h4>
            <h4>Eu faço de ti um profeta para as nações. (Jr 1, 5)</h4>

            <span>
              <img src={tabua} alt="Tabua TOV" />
            </span>
            <p>
              Guia é o título geral que identifica todos os que trabalham nas
              Oficinas de Oração e Vida. O quadro de Guias é a viga mestra que
              sustenta o edifício dos TOV.
            </p>

            <p>
              A missão do Guia é ensinar a orar. É implantar o Deus vivo na alma
              de seus irmãos em Cristo e, ao mesmo tempo, abrir um manancial de
              paz, de fortaleza e de alegria em seus corações.
            </p>
            <p>
              Sua missão é transformar os oficinistas em amigos e discípulos do
              Senhor. É povoar a solidão humana com a presença infinitamente
              consoladora do Pai. É promover os valores evangélicos como o
              respeito à vida, o amor fraterno, a misericórdia, a compaixão, a
              caridade aos mais necessitados. É trabalhar na contramão de
              antivalores como a cultura da morte, o materialismo, a injustiça,
              o hedonismo, a intolerância, a corrupção.
            </p>
            <p>
              A tarefa do Guia TOV é eminentemente evangelizadora e, por outro,
              é uma tarefa eminentemente humanitária, pois a verdadeira oração
              transforma-se, no interior do orante, em uma força explosiva, que
              se dimana da transformação pessoal cristificante e em um
              compromisso apostolicamente militante com a Igreja.
            </p>
            <h3>Envio</h3>
            <p>
              Antes de tudo, o Guia TOV é um missionário, quer dizer, um
              enviado. Após o ano de preparação, ele recebe, solenemente em uma
              Missa de Envio, este caráter pelo qual participa diretamente da
              missão sacerdotal, profética e régia de Jesus Cristo – anunciar as
              felizes notícias do Reino de Deus.
            </p>
            <p>
              Ao ser enviado, o Guia passa a ser um membro ativo da família TOV.
              Para bem cumprir sua missão apostólica, assume, desde então,
              compromissos imprescindíveis: a Sagrada Meia Hora Diária de
              Oração, a frequência assídua ao Dia Mensal do Guia, a aplicação de
              uma Oficina de Oração e Vida a cada semestre e a participação em
              um Retiro Anual para Guias.
            </p>
            <h3>Jesus Cristo: o GUIA</h3>
            <p>
              Como Jesus Cristo, o Guia TOV não é um conquistador, mas um
              servidor; não impõe, mas oferece a salvação; não é um doutor, mas
              uma testemunha. Meditando a Palavra (bíblica), faz-se discípulo de
              Jesus e, tratando com Ele na oração, passa a ser seu amigo e, por
              ter “visto e ouvido”, é testemunha do Senhor.
            </p>
            <p>
              Como Jesus Cristo, desce da montanha da oração e se faz presente,
              com a palavra e a presença, preferentemente, mas não
              exclusivamente, no meio dos “últimos”, para defender os fracos,
              libertar os cativos e anunciar um mundo de graça e salvação, o
              Reino do Céus aqui na Terra.
            </p>
          </Missao>
        </Common>
      </Content>
      <Footer />
    </Container>
  );
};

export default MissaoComp;
