interface LocalStorageProps {
  [key: string]: string | number | null | object;
}

export function localStorageLength(item: string): number {
  const localStorageItem = localStorage.getItem(item);

  if (localStorageItem) {
    return JSON.stringify(localStorageItem).length;
  }
  return 0;
}

export function setLocalStorage(item: string, values: LocalStorageProps): void {
  const localstorageitem = localStorage.getItem(item);

  if (localstorageitem) {
    const parsed = JSON.parse(localstorageitem);

    const updatedItem = {
      ...parsed,
      ...values,
    };

    localStorage.setItem(item, JSON.stringify(updatedItem));
  } else {
    localStorage.setItem(item, JSON.stringify(values));
  }
}

export function getLocalStorage(item: string): object {
  const localStorageItem = localStorage.getItem(item);

  if (localStorageItem) {
    return JSON.parse(localStorageItem);
  }

  return {};
}

export function deleteLocalStorageItemKey(
  item: string,
  values: string[],
): void {
  const localStorageItem = localStorage.getItem(item);

  if (localStorageItem) {
    const stored = JSON.parse(localStorageItem);
    values.map((ocurr) => {
      delete stored[ocurr];
      return null;
    });
    localStorage.setItem(item, JSON.stringify(stored));
  }
}
