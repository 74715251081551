import styled from 'styled-components';
import { shade } from 'polished';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 90vw;
  max-width: 800px;

  padding: 10px;

  @media screen and (max-width: 650px) {
    padding: 0;
    width: 100vw;
  }
`;

export const StyledP = styled.figure`
  position: absolute;
  right: 5px;
  bottom: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItem = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 3px solid #efefef;
    border-radius: 5px;
    padding: 5px 10px;

    a {
      margin: 10px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;

      color: #fff;

      border-radius: 3px;
      padding: 3px 7.5px;

      font-size: 14px;

      width: 30px;
      height: 30px;

      background: #007acc;
      transition: background-color 0.35s ease;

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: ${shade(0.2, '#007acc')};
      }
    }

    h3 {
      font-size: 16px;
      color: #8a0002;
    }

    > p {
      & + p {
        margin-top: 3px;
      }
    }

    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: box-shadow 0.5s ease, border-color 0.5s ease;

    &:hover {
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      border-color: #332e2e;
    }
    &:first-child {
      margin: 10px 0 0 0;
    }

    @media screen and (max-width: 650px) {
      border-radius: 0px;
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    color: #c53030;
    text-decoration: underline;
    & + span {
      margin-top: 6px;
    }

    &:first-child {
      margin: 15px 0 0 0;
    }
  }

  p {
    color: #767676;
    strong {
      color: #332e2e;
    }
  }
`;

export const StyledStatus = styled.article`
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: center;
  justify-content: flex-start;
  color: #767676;
  strong {
    color: #332e2e;
  }

  label {
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  input {
    display: none;
  }

  span {
    all: unset;
    display: flex;
    align-items: center;

    svg {
      height: 18px;
      width: 18px;
      transform: translateY(20%);
    }
  }
`;
