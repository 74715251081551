import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';

const rotatePhone = keyframes`
  0%{
    transform: rotateZ(0deg);
  } 35% {
    transform: rotateZ(90deg);
  } 50% {
    transform: rotateZ(90deg);
  } 65% {
    transform: rotateZ(90deg);
  } 100% {
    transform: rotateZ(0deg);
  }
`;

export const TableWrapper = styled.div`
  /* display: flex; */
  width: clamp(400px, 90vw, 1366px);

  transition: width 0.25s ease;
  border: 2px solid #332e2e;
  border-radius: 5px;
  margin: 20px auto 50px;

  table {
    width: 100%;
    /* margin: 0px auto; */
    border-collapse: collapse;
    /* border-radius: 5px; */
    border: 1px solid #332e2e;
    font-size: 16px;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    thead {
      background: #332e2e;
      color: #fff;
    }
    tbody {
      background: #fff;
    }
    tr {
      height: 35px;
    }
    td {
      text-align: right;
      padding: 2px 3px;

      &:nth-child(1) {
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 1045px) {
    table {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 700px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const RotateMessage = styled(animated.div)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  span {
    p {
      font-size: 16px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      text-align: center;
    }
    svg {
      height: 20px;
      width: 20px;
      animation: ${rotatePhone} 3s infinite;
    }

    & + span {
      margin-left: 10px;
    }
  }
`;
