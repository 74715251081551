import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';
import Checkbox from 'components/Checkbox';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';

import { useWindow } from 'hooks/window';
import { checkAddress } from 'utils/specialChars';
import {
  FaArrowLeft,
  FaPlusSquare,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
} from 'react-icons/fa';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { UpdateProps } from 'styles/dialog_update';
import * as U from 'styles/dialog_update';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import { alphaMasking } from 'components/Textarea/masks';
import {
  StyledButtonPhaseOne,
  PhaseOne,
  PhaseTwo,
  PhaseThree,
  CoordContainer,
  ItemContainer,
  AddButton,
  InfoContainer,
  FinishButton,
  Total,
  Grid,
  GridItem,
  DeclareNumbers,
  SpanMessage,
  DeclareButton,
  ReturnButton,
} from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface ItemsProps {
  matcod: string;
  matdesc: string;
  cost: number;
  quantity: number;
  stored: number;
  require: boolean;
  numbers: string[];
  selected: string[];
}

const GDInsert: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const phaseTwo = useRef<FormHandles>(null);
  const phaseThree = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [mats, setMats] = useState<ComboProps[]>([]);
  const [type, setType] = useState('N');

  const [storageQuantity, setStorageQuantity] = useState('-');
  const [cost, setCost] = useState(0);
  const [possibleNumbers, setPossibleNumbers] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState(false);
  const [items, setItems] = useState<ItemsProps[]>([]);

  const [isPhaseOne, setIsPhaseOne] = useState(true);
  const [isPhaseTwo, setIsPhaseTwo] = useState(false);
  const [isPhaseThree, setIsPhaseThree] = useState(false);

  const [coordSelected, setCoordSelected] = useState<CoordProps>(
    {} as CoordProps,
  );
  const [deliverAddress, setDeliverAddress] = useState('');
  const [deliverAble, setDeliverAble] = useState(false);

  const [pAddress, setPAddress] = useState('');
  const [sAddress, setSAddress] = useState('');

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({
    open: false,
    content: '',
    values: {},
  });

  const total = useMemo(() => {
    return items.reduce((accum, { quantity, cost: value }: ItemsProps) => {
      const semitotal = accum + quantity * value;
      return semitotal;
    }, 0);
  }, [items]);

  const faulty = useMemo(() => {
    return items.reduce(
      (accum, { quantity, selected, require }: ItemsProps) => {
        return require
          ? quantity === selected.length
            ? accum
            : accum + 1
          : accum;
      },
      0,
    );
  }, [items]);

  const getAddress = useCallback(async () => {
    try {
      const response = await api.get<{ PRINC: string; SEC: string }>(
        `/sgo/getAddress.php?data=${JSON.stringify({
          trans: 'gd',
          cod: coordSelected.cod,
        })}`,
      );

      const { PRINC, SEC } = response.data;

      if (PRINC && SEC) {
        if (checkAddress(PRINC) === checkAddress(SEC)) {
          setDeliverAddress(PRINC);
        } else {
          setDeliverAble(true);
          setDeliverAddress(PRINC);
          setPAddress(PRINC);
          setSAddress(SEC);
          phaseThree.current?.setFieldValue('deliver', 'P');
        }
      } else if (!PRINC && SEC) {
        setDeliverAddress(SEC);
      } else if (!SEC && PRINC) {
        setDeliverAddress(PRINC);
      } else {
        return;
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [coordSelected.cod, errorHandling]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(
      response.data.filter((item: ComboProps) => item.value !== user.zoncod),
    );
  }, [user.zoncod]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboMATS = useCallback(async () => {
    try {
      const response = await api.get<
        { value: string; label: string; matgrped: string }[]
      >('/combos/comboMATs.php?from=gd');

      const filtered = response.data.map((item) => ({
        value: item.value,
        label: item.label,
      }));

      setMats(
        filtered.filter((item: ComboProps) => item.value.substr(0, 2) !== 'DM'),
      );
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const phaseOneStyled = useSpring({
    opacity: isPhaseOne ? 1 : 0,
    transform: isPhaseOne ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseOne ? 'all' : 'none',
    display: isPhaseOne ? 'inline' : 'none',
  });
  const phaseTwoStyled = useSpring({
    opacity: isPhaseTwo ? 1 : 0,
    transform: isPhaseTwo ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseTwo ? 'all' : 'none',
    display: isPhaseTwo ? 'inline' : 'none',
  });
  const phaseThreeStyled = useSpring({
    opacity: isPhaseThree ? 1 : 0,
    transform: isPhaseThree ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseThree ? 'all' : 'none',
    display: isPhaseThree ? 'inline' : 'none',
  });
  const phaseOneProceedButton = useSpring({
    opacity: coordSelected.cod ? 1 : 0,
    transform: coordSelected.cod ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: coordSelected.cod ? 'all' : 'none',
    // display: coordSelected.cod ? 'inline' : 'none',
  });
  const phaseTwoProceedButton = useSpring({
    transform:
      items.length > 0 && faulty === 0 ? 'translateX(5px)' : 'translateX(60px)',
    opacity: items.length > 0 && faulty === 0 ? 1 : 0,
    pointerEvents: items.length > 0 && faulty === 0 ? 'all' : 'none',
  });

  const toggleFlexDirection = useSpring({
    flexDirection: width < 400 && total > 99.99 ? 'column' : 'row',
  });

  useEffect(() => {
    handlePermission(['ZON'], true);
    getComboMATS();
    getComboZON();
    getComboANB();
  }, [handlePermission, getComboANB, getComboMATS, getComboZON]);

  const handleRadioClick = useCallback(() => {
    setCoordSelected({} as CoordProps);
    setType(phaseOne.current?.getFieldValue('destiny'));
  }, []);

  const handleCoordSelected = useCallback(() => {
    const code = phaseOne.current?.getFieldValue('coord');
    const group = type === 'N' ? comboNacs : comboZons;
    setCoordSelected({
      cod: code,
      desc:
        group[group.findIndex((item: ComboProps) => item.value === code)].label,
    });
  }, [comboNacs, comboZons, type]);

  const handleMaterialSelect = useCallback(async () => {
    try {
      setLoading(true);
      phaseTwo.current?.setErrors({});
      const material = phaseTwo.current?.getFieldValue('material');

      const send = JSON.stringify({
        matcod: material,
        trans: 'gd',
      });
      const response = await api.get(`/sgo/getStorage.php?data=${send}`);

      const { quantity, matcost, numbers, requireNum } = response.data;

      if (requireNum) {
        setPossibleNumbers(numbers);
      }

      setIsRequired(requireNum);
      setStorageQuantity(quantity);
      setCost(matcost);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const handlePushMaterial = useCallback(() => {
    const index = mats.findIndex(
      (item: ComboProps) =>
        item.value === phaseTwo.current?.getFieldValue('material'),
    );

    phaseTwo.current?.setErrors({});

    if (parseInt(storageQuantity, 10) === 0) {
      addToast({
        type: 'info',
        title: `Este material não possui estoque`,
        description: 'Não é possível adicioná-lo',
      });
      return;
    }
    if (index > -1) {
      const newMat = {
        matcod: mats[index].value,
        matdesc: mats[index].label,
        cost,
        quantity: 1,
        stored: parseInt(storageQuantity, 10),
        require: isRequired,
        numbers: possibleNumbers,
        selected: [],
      };

      setItems((state) => [newMat, ...state]);
      setMats((state) =>
        state.filter((item: ComboProps) => item.value !== newMat.matcod),
      );
      setStorageQuantity('-');
      setCost(0);
      setIsRequired(false);
      setPossibleNumbers([]);
    } else {
      phaseTwo.current?.setFieldError(
        'material',
        'You must select at least one material',
      );

      addToast({
        type: 'info',
        title: ``,
        description: 'É necessário escolher um material.',
      });
    }

    phaseTwo.current?.setFieldValue('material', '');
  }, [addToast, cost, isRequired, mats, possibleNumbers, storageQuantity]);

  const handleAdd = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        items[index].quantity += 1;
        setItems((state) => [...state]);
      }
    },
    [items],
  );

  const handleSub = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        items[index].quantity -= 1;
        setItems((state) =>
          items[index].quantity === 0
            ? [...state].filter(
                (item: ItemsProps) => item.matcod !== items[index].matcod,
              )
            : [...state],
        );

        if (items[index].quantity === 0) {
          const reinsert = {
            value: items[index].matcod,
            label: items[index].matdesc,
          };

          const rearrange = [...mats, reinsert].sort((a, b) =>
            a.label > b.label ? 1 : -1,
          );

          setMats(rearrange);
        }
      }
    },
    [items, mats],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);
      const number = e.currentTarget.value.replace(/^0/, '');
      if (number.length > 0) {
        const asNum = parseInt(number, 10);

        if (index > -1) {
          items[index].quantity = asNum;
          setItems((state) =>
            items[index].quantity <= 0
              ? [...state].filter(
                  (item: ItemsProps) => item.matcod !== items[index].matcod,
                )
              : [...state],
          );
        }
      } else {
        items[index].quantity = 0;
        setItems((state) => [...state]);
      }

      e.currentTarget.value = e.currentTarget.value.replace(/^0/, '');
    },
    [items],
  );

  const handleInputBlur = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);
      if (e.currentTarget.value === '0') {
        setItems((state) =>
          [...state].filter(
            (item: ItemsProps) => item.matcod !== items[index].matcod,
          ),
        );

        const material = {
          value: items[index].matcod,
          label: items[index].matdesc,
        };

        const rearrange = [...mats, material].sort((a, b) =>
          a.label > b.label ? 1 : -1,
        );

        setMats(rearrange);
        return;
      }

      if (parseInt(e.currentTarget.value, 10) > items[index].stored) {
        items[index].quantity = items[index].stored;
        setItems((state) => [...state]);
      }
    },
    [items, mats],
  );

  const handleRemoveItem = useCallback(
    (mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.matcod === mat);

      const reinsert = {
        value: items[index].matcod,
        label: items[index].matdesc,
      };

      setItems((state) =>
        state.filter((item: ItemsProps) => item.matcod !== mat),
      );

      const rearrange = [...mats, reinsert].sort((a, b) =>
        a.label > b.label ? 1 : -1,
      );

      setMats(rearrange);
    },
    [mats, items],
  );

  const handleDeclareClick = useCallback(
    (matcod: string, number: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.matcod === matcod,
      );

      const exists = [...items[index].selected].includes(number);

      items[index].selected = exists
        ? [...items[index].selected].filter((item: string) => item !== number)
        : [...items[index].selected, number];

      setItems((state) => [...state]);
    },
    [items],
  );

  const handleAddressRadioClick = useCallback(() => {
    const value = phaseThree.current?.getFieldValue('deliver');

    setDeliverAddress(value === 'P' ? pAddress : sAddress);
  }, [pAddress, sAddress]);

  const handleOBSChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);

      const data = {
        dest: coordSelected,
        items,
        address: {
          value: phaseThree.current?.getFieldValue('deliver') || 'P',
          label: deliverAddress,
        },
        obs: phaseThree.current?.getFieldValue('obs').replace('#', ''),
        name: user.name,
        zondesc: user.zondesc,
        total,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      const response = await api.post('/sgo/gd_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { gdnum } = response.data;
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Guia de Distribuição N° ${gdnum} emitida para a Coordenação ${coordSelected.desc}.`,
      });

      history.replace(pathname.replace('/insert', ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    coordSelected,
    deliverAddress,
    errorHandling,
    history,
    items,
    pathname,
    total,
    user.name,
    user.zondesc,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Guia de Distribuição" />
      <Content>
        <PhaseOne style={phaseOneStyled}>
          <Form ref={phaseOne} onSubmit={() => null}>
            <span>
              <RadioContainer
                title="Remessa de Material para: "
                onClick={handleRadioClick}
                name="destiny"
                content={[
                  {
                    id: 'nD',
                    value: 'N',
                    label: 'Nacional',
                  },
                  {
                    id: 'nZ',
                    value: 'Z',
                    label: 'Zonal',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected="N"
              />
            </span>
            <span>
              <p>Selecione a coordenação:</p>
              <SelectV2
                name="coord"
                initial="Selecione"
                content={type === 'N' ? comboNacs : comboZons}
                onChange={handleCoordSelected}
              />
            </span>
          </Form>
          <StyledButtonPhaseOne
            onClick={() => {
              setIsPhaseOne(false);
              setIsPhaseTwo(true);
              getAddress();
            }}
            style={phaseOneProceedButton}
            bgcolor="#00802b"
          >
            Avançar
          </StyledButtonPhaseOne>
        </PhaseOne>
        <PhaseTwo style={phaseTwoStyled}>
          <Form ref={phaseTwo} onSubmit={() => null}>
            <CoordContainer>
              <p>
                Coordenação: <strong>{coordSelected.desc}</strong>
              </p>
            </CoordContainer>
            <ItemContainer>
              <span>
                <SelectV2
                  name="material"
                  content={mats}
                  initial="Selecione"
                  onChange={handleMaterialSelect}
                />
              </span>
              <span>
                <p>Seu estoque:</p>
                <strong>{storageQuantity}</strong>
              </span>
            </ItemContainer>
            <AddButton type="button" onClick={handlePushMaterial}>
              <FaPlusSquare /> <p>Adicionar material</p>
            </AddButton>
            <InfoContainer>
              <Total style={toggleFlexDirection}>
                <p>Total desta guia:&nbsp;</p>
                <strong>R$ {formatNumber(total)}</strong>
              </Total>

              <FinishButton
                style={phaseTwoProceedButton}
                type="button"
                onClick={() => {
                  setIsPhaseTwo(false);
                  setIsPhaseThree(true);
                }}
              >
                Avançar
              </FinishButton>
            </InfoContainer>
            <Grid>
              {items.map((item: ItemsProps) => (
                <GridItem
                  key={item.matcod}
                  required={item.require}
                  color={
                    item.selected.length < item.quantity
                      ? '#fda50f'
                      : item.selected.length === item.quantity
                      ? '#267326'
                      : '#c53030'
                  }
                >
                  <div>
                    <strong>
                      {item.matcod} - {item.matdesc}
                    </strong>
                  </div>
                  <div>
                    <span>
                      <p>
                        Custo:&nbsp;
                        <strong>R$ {formatNumber(item.cost)}</strong>
                      </p>
                    </span>
                    <span>
                      <p>Quantidade:</p>
                      <span>
                        <FaSortUp
                          onClick={() => handleAdd(item.matcod)}
                          style={{
                            transform: 'translateY(10px)',
                            color: '#009933',
                            opacity: item.stored > item.quantity ? 1 : 0,
                            pointerEvents:
                              item.stored > item.quantity ? 'all' : 'none',
                          }}
                        />

                        <input
                          type="number"
                          min={0}
                          value={item.quantity}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleInputChange(e, item.matcod)
                          }
                          onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                            handleInputBlur(e, item.matcod)
                          }
                        />

                        <FaSortDown
                          onClick={() => handleSub(item.matcod)}
                          style={{
                            transform: 'translateY(-10px)',
                            color: '#c53030',
                          }}
                        />
                      </span>
                      <figure>
                        <FaTimesCircle
                          onClick={() => handleRemoveItem(item.matcod)}
                        />
                      </figure>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Seu estoque atual:&nbsp;
                        <strong>{item.stored}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Total:&nbsp;
                        <strong>
                          R$ {calcTotal(item.quantity, item.cost)}
                        </strong>
                      </p>
                    </span>
                  </div>
                  {item.require && (
                    <>
                      <SpanMessage
                        color={
                          item.selected.length < item.quantity
                            ? '#fda50f'
                            : item.selected.length === item.quantity
                            ? '#267326'
                            : '#c53030'
                        }
                      >
                        <p>
                          {item.selected.length === 0
                            ? 'É necessário selecionar os números controlados'
                            : item.selected.length < item.quantity
                            ? 'Quantidade selecionada é inferior a quantidade apontada'
                            : item.selected.length > item.quantity
                            ? 'Quantidade selecionada excede a quantidade apontada'
                            : ''}
                        </p>
                      </SpanMessage>
                      <div>
                        <span>
                          <DeclareButton
                            type="button"
                            onClick={() =>
                              setUpdateDiag({
                                open: true,
                                content: (
                                  <DeclareNumbers>
                                    {item.numbers.map((number: string) => (
                                      <Checkbox
                                        id={`N${number}`}
                                        title={number}
                                        onClick={() =>
                                          handleDeclareClick(
                                            item.matcod,
                                            number,
                                          )
                                        }
                                        checked={[...item.selected].includes(
                                          number,
                                        )}
                                      />
                                    ))}
                                  </DeclareNumbers>
                                ),
                                values: {
                                  matcod: item.matcod,
                                  matdesc: item.matdesc,
                                  max: item.quantity,
                                },
                              })
                            }
                          >
                            Selecionar numeração controlada
                          </DeclareButton>
                        </span>
                      </div>
                    </>
                  )}
                </GridItem>
              ))}
            </Grid>
          </Form>
        </PhaseTwo>
        <PhaseThree style={phaseThreeStyled}>
          <ReturnButton
            onClick={() => {
              setIsPhaseTwo(true);
              setIsPhaseThree(false);
            }}
          >
            <FaArrowLeft />
            <p>Retornar aos itens</p>
          </ReturnButton>
          <Form ref={phaseThree} onSubmit={() => null}>
            <div>
              {deliverAble && (
                <RadioContainer
                  title="Endereço de Entrega:"
                  onClick={handleAddressRadioClick}
                  name="deliver"
                  content={[
                    {
                      id: 'dT',
                      value: 'P',
                      label: 'Escritório Nacional',
                    },
                    {
                      id: 'dL',
                      value: 'S',
                      label: 'Endereço Alternativo',
                    },
                  ]}
                  selected="P"
                />
              )}

              <p>{deliverAddress}</p>
            </div>
            <div>
              <p>Observação:</p>
              <Textarea
                name="obs"
                placeholder="Insira a observação (opcional)"
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  handleOBSChange(e)
                }
              />
            </div>
          </Form>

          <Button onClick={handleFinish} bgcolor="#00802b">
            Finalizar
          </Button>
        </PhaseThree>
        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <U.Title>
            <h2>{updateDiag.values?.matdesc || 'Material'}</h2>
          </U.Title>
          <U.Content>{updateDiag.content}</U.Content>
          <U.Actions>
            {/* <U.Cancel
              type="button"
              onClick={() => setUpdateDiag({ open: false })}
            >
              Cancelar
            </U.Cancel> */}
            <U.Confirm
              type="button"
              onClick={() => setUpdateDiag({ open: false })}
            >
              Confirmar
            </U.Confirm>
          </U.Actions>
        </U.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GDInsert;
