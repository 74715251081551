import styled, { css, keyframes } from 'styled-components';
import { Form } from '@unform/web';

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

interface ContainerProps {
  delay?: number;
  duration?: number;
}

export const AlteredForm = styled(Form)`
  /* padding: 5px 10px 10px 10px;
  border: 2px solid #efefef;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  transition: box-shadow 0.5s ease;
  > span {
    > h3 {
      font-size: 14px;
      color: #8a0002;
    }
    > p {
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
    }
    & + span {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    width: 100vw;
  } */
`;

export const InfoContainer = styled.div`
  display: flex;

  > div {
    opacity: 0;
    transform: translateX(-150px);
    padding: 5px 10px 10px 10px;
    border: 2px solid #efefef;
    border-radius: 5px;
    background: #fff;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;

    > span {
      display: flex;
      flex-direction: column;

      > p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;
      }

      & + span {
        margin-top: 10px;
      }
    }
    & + div {
      margin: 0 0 0 35px;
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;

    div + div {
      margin: 10px 0 0 0;
    }
  }
`;

export const LeftContainer = styled.div<ContainerProps>`
  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;
  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}
`;

export const RightContainer = styled.div<ContainerProps>`
  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;
  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}
`;
