import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from 'utils/getValidationErrors';
import { convertSpecialChars } from 'utils/specialChars';

import * as yup from 'yup';
import { Content, GridContainer, AnimatedSection } from './styles';

interface FormData {
  name: string;
  cep: string;
  state: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  phone: string;
}

const LocalServInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasState, setHasState] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');

    setComboEstados(response.data);
  }, []);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    getUFs();
  }, [handlePermission, getUFs]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].label);
          setHasState(true);
        } else {
          setHasState(false);
          setFirstUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setFirstUF('Selecione');
        setHasState(false);
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          state: yup.string().notOneOf([''], ' '),
          address: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup
            .string()
            .trim()
            .min(2, 'Quantidade mínima de caracteres não atingida (2).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          address: convertSpecialChars(data.address),
          number: convertSpecialChars(data.number) || 'S/N',
          compl: convertSpecialChars(data.compl),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
        };

        await api.get(`/sgo/localserv_insert.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Local de Serviço/Atividade incluído!',
        });

        history.replace(location.pathname.replace('/insert', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, history, location.pathname, addToast],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro Local de Serviço/Atividade" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Nome do Local de Serviço/Atividade</p>
                <Input
                  name="name"
                  placeholder="Nome do Local de Serviço/Atividade"
                  mask="name"
                />
              </span>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>

              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  // containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>

              <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>

              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>

              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>

              <span>
                <p>Bairro</p>
                <Input
                  name="district"
                  placeholder="Bairro"
                  disabled={!!hasDistrict}
                />
              </span>

              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" disabled={!!hasCity} />
              </span>
              <span>
                <p>Telefone para contato</p>
                <Input
                  name="phone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Cadastrar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default LocalServInsert;
