import styled, { css } from 'styled-components';

interface LoadingProps {
  isLoading: boolean;
}

export const Container = styled.section<LoadingProps>`
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.55);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;

  ${(props) =>
    props.isLoading &&
    css`
      cursor: none;
      opacity: 1;
      pointer-events: all;
    `}
`;

export const Content = styled.div`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0) 95%
  );
  width: 100vw;
  max-width: 500px;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 90vw;
    max-width: 360px;
  }

  strong {
    font-size: 40px;
    color: #8a0002;
  }
`;
