import styled, { css } from 'styled-components';
import { parseToRgb } from 'polished';

interface LabelProps {
  ischecked: boolean;
}

export const MessageContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0px;
  width: 100vw;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    margin: 0px auto 10px;
    padding: 0 10px;
  }
`;
export const Attention = styled.div`
  display: flex;
  flex-direction: row !important;
  position: relative;
  margin: 10px 0;
  background: #000;
  padding: 3px 0 !important;
  align-items: center;
  justify-content: center;
  width: 100vw;

  > svg {
    margin: 0 5px;
    color: #fce205;
    height: 25px;
    width: 25px;
  }

  > p {
    font-size: 20px;
    font-weight: bold;
    color: #fce205;

    --pr ${parseToRgb('#FFF59D').red};
    --pg ${parseToRgb('#FFF59D').green};
    --pb: ${parseToRgb('#FFF59D').blue};
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }
`;
export const Message = styled.p`
  padding: 0px 5px;
  font-size: 16px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  & + p {
    margin: 7px 0 0 0;
  }
`;

export const CustomCheckbox = styled.label<LabelProps>`
  overflow: hidden;
  position: relative;
  background: #efefef;
  display: flex;
  /* padding: 10px 5px; */
  align-items: center;
  justify-content: center;

  width: 200px;
  /* height: 50px; */
  border-radius: 5px;
  border: 2px solid #efefef;
  box-shadow: 0px 0px 0px 0px rgba(51, 46, 46, 0.25);

  input {
    z-index: 2;
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  svg {
    position: absolute;
    right: -10px;
    top: 50%;
    height: 20px;
    width: 20px;
    opacity: 0;

    transform: translateY(-50%);

    will-change: opacity, right, color;
    transition: right 0.35s ease, opacity 0.35s ease, color 0.35s ease;
  }

  p {
    padding: 10px 5px;
    font-size: 16px;
    font-weight: bold;

    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    will-change: color;
    transition: color 0.35s ease;
  }

  will-change: background-color, border-color;
  transition: background-color 0.35s ease, border-color 0.35s ease;

  ${(props) =>
    props.ischecked &&
    css`
      background: #1b5e20;
      border-color: #1b5e20;
      p {
        color: #fff;
        /* text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75); */
      }

      svg {
        color: #fff;
        right: 15px;
        opacity: 1;
      }
    `}
`;
