import styled, { css } from 'styled-components';

interface LabelProps {
  marked: boolean;
  disabled: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 400px;

  padding: 10px 5px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.35s;

  &:hover {
    border-color: #332e2e;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;

        margin: 10px 0 0 0;
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }
  }
`;

export const RelatedLocalsGrid = styled.div`
  margin: 5px 0;
  display: grid !important;
  grid: auto / 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='checkbox'] {
      width: 100%;
      display: none;
    }
  }
`;

export const GridItemLabel = styled.label<LabelProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #a6a6a6;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  width: 100%;
  min-height: 30px;
  height: 100%;
  transition: all 0.35s;
  text-align: center;
  padding: 3px 7px;
  font-size: 16px;

  &:hover {
    background: rgba(51, 255, 255, 0.25);
    border-color: #006666;
    box-shadow: 2px 5px 10px 3px rgba(0, 77, 77, 0.5);
  }

  ${(props) =>
    props.marked &&
    css`
      background: #33ffff;
      border-color: #006666;
      &:hover {
        /* background: green; */
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: grey;
      border-color: #464646;
      opacity: 0.5;
      box-shadow: 0px 0px 0px 0px rgba(0, 77, 77, 0.5);

      &:hover {
        background: grey;
        border-color: #464646;
        box-shadow: 0px 0px 0px 0px rgba(0, 77, 77, 0.5);
      }
    `}
`;

export const WarnMessageContainer = styled.section`
  --bgcolor: #c53030;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  margin: 10px 0 0 0;

  width: 100vw;
  max-width: 360px;

  text-align: justify;
  border: 2px solid var(--bgcolor);
  border-radius: 5px;
  background: #efefef;

  div:nth-child(1) {
    background-color: var(--bgcolor);
  }
`;
export const WarnMessageHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 0px;
  color: #fff;
  font-weight: 700;
`;

export const WarnMessage = styled.div`
  padding: 3px 5px;
`;
