import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { shade, lighten } from 'polished';

import { Link } from 'react-router-dom';

interface LinkProps {
  color?: string;
  background?: string;
}

interface AlteredContentProps {
  pixels?: string;
}

interface FloatProps {
  amount?: string;
}

interface ButtonProps {
  shouldAppear: boolean;
}

export interface CoordProps {
  cod: string;
  desc: string;
}

export interface ComboProps {
  value: string;
  label: string;
  matgrped?: string;
}

export interface ComboLocalProps {
  value: string;
  label: string;
  anb: string;
}

export interface ComboLocalServProps {
  cod: string;
  nome: string;
  cid: string;
}

export interface ComboGuiaProps {
  seq: string;
  cert: string;
  nome: string;
  anb: string;
  loc: string;
  tg: string;
  func: string;
}

export const Container = styled.div`
  width: 100vw;

  > h2 {
    width: 100vw;
    text-align: center;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 190px);

  overflow-x: hidden;

  h2 {
    font-size: 24px;
    margin: 15px 0;
  }

  form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      margin-top: 15px;
    }

    > div {
      > p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;

        margin: 10px 0 0 0;
      }
    }

    > p {
      width: 100%;
      text-align: left;
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;

      margin: 10px 0 0 0;
    }
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 190px);
  }
`;

export const AlteredContent = styled(Content)<AlteredContentProps>`
  min-height: calc(100vh - 240px);

  ${(props) =>
    props.pixels &&
    css`
      min-height: calc(100vh - ${props.pixels});
    `}
`;

export const FloatInput = styled.div<FloatProps>`
  position: sticky;
  margin: 10px auto 0 auto;
  top: 60px;
  ${(props) =>
    props.amount &&
    css`
      top: ${props.amount};
    `}
  z-index: 1;
  background: #efefef;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  display: flex;

  height: 40px;
  width: 90vw;
  max-width: 350px;

  input {
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }
  }
`;

export const ResetFilterButton = styled.button<ButtonProps>`
  pointer-events: none;
  opacity: 0;
  transform: translateX(-25px);
  width: 200px;
  margin: 0 auto;
  margin-bottom: 15px;
  background: #c6c6c6;

  padding: 5px 15px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;

  transition: all 0.5s;

  ${(props) =>
    props.shouldAppear &&
    css`
      pointer-events: all;
      opacity: 1;
      transform: translateX(0px);
    `}

  &:hover {
    background: #332e2e;
    color: #efefef;
  }
`;

export const Header = styled(animated.h3)`
  font-size: 16px;
  position: sticky;
  margin: 10px auto 0 auto;
  top: 60px;
  z-index: 1;

  padding: 5px;
  width: 100%;
  text-align: center;

  text-shadow: 0px 0px 5px #8a0002;
  color: #efefef;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(197, 48, 48, 0.25),
    rgba(197, 48, 48, 0.5),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(197, 48, 48, 0.5),
    rgba(197, 48, 48, 0.25),
    rgba(255, 255, 255, 0)
  );
`;

export const AlteredHeader = styled(Header)`
  position: relative;
  top: 0px;
`;

export const RemoveButton = styled(animated.button)`
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 3px auto;
  border: 0;
  background: transparent;

  p {
    color: #c53030;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    transition: color 0.35s ease;
    pointer-events: none;
  }

  svg {
    color: #8a0002;
  }

  &:hover {
    p {
      color: #8a0002;
    }
  }
`;

export const TableLink = styled(Link)<LinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  text-decoration: none;
  border-radius: 5px;
  padding: 5px;

  width: 25px;
  height: 25px;

  transition: background-color 0.35s ease, color 0.35s ease;

  color: ${(props) => (props.color ? `${props.color}` : '#ffffff')};
  background: ${(props) =>
    props.background ? `${props.background}` : 'transparent'};

  svg {
    height: 15px;
    width: 15px;
  }

  &:hover {
    color: ${(props) =>
      props.color ? shade(0.2, `${props.color}`) : shade(0.1, `#ffffff`)};
    background-color: ${(props) =>
      props.background
        ? shade(0.2, `${props.background}`)
        : shade(0.2, `#ffffff`)};
  }
`;

export const TableButton = styled.button<LinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  margin: 0 auto;

  text-decoration: none;
  border-radius: 5px;
  padding: 5px;

  width: 25px;
  height: 25px;

  transition: background-color 0.35s ease, color 0.35s ease;

  color: ${(props) => (props.color ? `${props.color}` : '#ffffff')};
  background: ${(props) =>
    props.background ? `${props.background}` : 'transparent'};

  svg {
    height: 15px;
    width: 15px;
  }

  &:hover {
    color: ${(props) =>
      props.color ? shade(0.2, `${props.color}`) : shade(0.1, `#ffffff`)};
    background-color: ${(props) =>
      props.background
        ? shade(0.2, `${props.background}`)
        : shade(0.2, `#ffffff`)};
  }
`;

export const IncButton = styled.button`
  margin: 10px 0 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 20px;
  background: #004d1a;
  border: 3px solid #004d1a;
  border-radius: 5px;
  transition: all 0.25s ease;

  color: #efefef;

  p {
    font-size: 16px;
    transition: all 0.25s ease;
  }
  svg {
    margin: 0 0 0 7px;

    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: #efefef;
    transition: all 0.25s ease;
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0);
  }

  &:hover {
    background-color: ${lighten(0.05, '#004d1a')};
    border-color: ${lighten(0.05, '#004d1a')};

    p {
      color: #fff;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.75);
    }
    svg {
      color: #fff;
      box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.75);
      filter: brightness(120%);
    }
  }
`;
