import styled from 'styled-components';

import { Form } from '@unform/web';

export const StyledForm = styled(Form)`
  position: sticky;
  margin: 10px auto 0 auto;
  top: 60px;
  z-index: 15;
  width: 350px;
`;

export const GroupContainer = styled.section`
  width: 90vw;
  max-width: 1000px;
  margin: 10px 0;
  h1 {
    text-shadow: 0px 0px 5px #8a0002;
    color: #efefef;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(197, 48, 48, 0.25),
      rgba(197, 48, 48, 0.5),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(197, 48, 48, 0.5),
      rgba(197, 48, 48, 0.25),
      rgba(255, 255, 255, 0)
    );
    text-align: center;
  }
`;

export const GroupGrid = styled.div`
  margin: 20px 0;
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr;
    column-gap: 0px;
  }
`;

export const GridItem = styled.span`
  width: 100%;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid #efefef;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;

  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 16px;
      width: 100%;
      /* text-align: left; */
    }

    p {
      text-align: left;
      font-size: 14px;
    }
  }

  &:hover {
    z-index: 1;
    transform: scale(1.2);
    border-color: #8a0002;
    box-shadow: 0px 0px 15px 5px rgba(138, 0, 2, 0.75);
  }

  @media screen and (max-width: 850px) {
    &:hover {
      transform: scale(1);
      border-color: #efefef;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;
