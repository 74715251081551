import React from 'react';

import { Container, Content } from 'styles/wrappers';
import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { TOVService } from '../styles';

const manualcc = `${process.env.REACT_APP_ASSETS_DIR}/materiais/56113ef8-4689-4d44-b506-596de6731778.png`;

const CursoCasais: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Curso para Casais</div>
        </AlteredHeader>
        <TOVService>
          <h2>Manual TOV Curso para Casais</h2>
          <div>
            <span>
              <p>
                Curso para Casais Nesta Oficina, o objetivo é acompanhar os
                casais em sua peregrinação para o “ideal” conjugal. Não se trata
                de um curso teórico, mas prático, no estilo de uma Oficina, em
                que trabalhando se aprende a trabalhar. Com um movimento de
                comunicação e diálogo, de interior a interior, de coração a
                coração, em que se estabelece uma corrente de abertura e
                acolhida, o casal se esclarece, se entende, se compreende, se
                perdoa, se aceita, entra em harmonia e suscita um gozo recíproco
                de amor e paz.
              </p>
            </span>
            <img src={manualcc} alt="Manual Curso para Casais" />
          </div>
          <span>
            <h4>
              <strong>Curso para Casais</strong>{' '}
              <p>| Manual do Curso para Casais</p>
            </h4>
            <h4>
              <strong>Participantes:</strong>
              <p>Casais (grupo de dez casais no máximo).</p>
            </h4>
            <h4>
              <strong>Duração:</strong>
              <p>
                Seis reuniões, uma por semana, com duração de duas horas cada.
              </p>
            </h4>
          </span>
        </TOVService>
      </Content>
      <Footer />
    </Container>
  );
};

export default CursoCasais;
