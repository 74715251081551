import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  margin: 20px 0;
  padding: 10px;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  p {
    color: #8a0002;
    font-weight: bold;
    font-size: 16px;
  }

  div + div {
    margin-top: 15px;
  }
`;
