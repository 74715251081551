import styled from 'styled-components';

export const Header = styled.div`
  margin: 25px 0 5px;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
      display: flex;

      p {
        text-align: center;
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }

    & + div {
      margin: 10px 0 0 0;
    }

    @media screen and (max-width: 690px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export const Table = styled.table`
  width: 360px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  margin: 20px auto;
  border-collapse: collapse;

  border: 1px solid #332e2e;

  font-size: 16px;

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    padding: 2px 5px;
  }
`;

export const TDdate = styled.td`
  text-align: center;

  width: 110px;
`;

export const TDoffer = styled.td`
  text-align: right;

  width: 75px;
`;

export const TDstats = styled.td`
  width: 160px;
`;

export const TDexc = styled.td`
  display: flex;

  button {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;

    /* border: 0; */
    background: transparent;
    border-color: transparent;

    svg {
      height: 20px;
      width: 20px;
      color: #c53030;
    }
  }
`;

export const Info = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 360px;
  font-size: 16px;

  border: 3px solid #efefef;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  p {
    color: #767676;

    strong {
      color: #332e2e;
    }
  }

  transition: border-radius 0.35s ease;

  @media screen and (max-width: 360px) {
    border-radius: 0px;
  }
`;
