import styled from 'styled-components';
import { animated } from 'react-spring';

export const Grid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr;
  max-width: 1300px;
  row-gap: 20px;
  column-gap: 20px;

  @media screen and (max-width: 760px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  padding: 10px 5px;
  display: flex;
  flex-direction: column;

  border: 2px solid #efefef;
  background: #fff;
  border-radius: 5px;

  span {
    p {
      font-size: 16px;
      color: #8a0002;
      font-weight: 500;
    }
    & + span {
      margin: 5px 0 0 0;
    }
  }
`;

export const WarnMessage = styled(animated.div)`
  /* position: sticky;
  top: 129px; */
  width: 100vw;
  /* background: black; */
  padding: 5px 10px;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: #fff;
    font-size: 16px;
  }
`;
