import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';

import {
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { removeAcento } from 'utils/specialChars';
import { FaDollarSign, FaSearch } from 'react-icons/fa';
import { GridContainer, GridItem } from './styles';

export interface ListProps {
  anbcod: string;
  anbdesc: string;
  loccod: string;
  locdesc: string;
  rmnum: number;
  data: string;
  valor: number;
  status: string;
  statDesc: string;
  solnum: number;
  soldata: string;
  financregs: number;
  rmyear: string;
  obs: string[];
  addressval: string;
  address: {
    street: string;
    district: string;
    city: string;
    state: string;
    cep: string;
  };
}

interface LocalStorageProps {
  stat?: string;
  year?: string;
}

const RM: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [statRM, setStatRM] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_RM}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_RM}`,
      );

      if (stat) {
        return stat;
      }

      return 'E';
    }

    return 'E';
  });

  const [years, setYears] = useState<ComboProps[]>([]);
  const [yearSelected, setYearSelected] = useState(() => {
    const current = JSON.stringify(
      new Date(new Date().toISOString()).getFullYear(),
    );

    const hasItem = localStorageLength(`${process.env.REACT_APP_RM}`);

    if (hasItem > 0) {
      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_RM}`,
      );

      if (year) {
        return year;
      }

      return current;
    }

    return current;
  });

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/rm_list.php');

      const { fetchlist, year } = response.data;

      const filtered = fetchlist.filter((item: ListProps) =>
        user.perfil === 'LOC' ? item.loccod === user.loccod : item,
      );

      setYears(year);
      setRawList(filtered);

      const initSTAT = formRef.current?.getFieldValue('filterRMStat');
      const initYEAR = formRef.current?.getFieldValue('filterYears');

      setList(
        filtered.filter(
          (item: ListProps) =>
            item.status === initSTAT && item.rmyear === initYEAR,
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, user.loccod, user.perfil]);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    getList();
  }, [handlePermission, getList]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const stat = formRef.current?.getFieldValue('filterRMStat');
      const year = formRef.current?.getFieldValue('filterYears');
      const filtered = rawList.filter((item: ListProps) => {
        return (
          (item.rmnum.toString().includes(val) ||
            removeAcento(item.locdesc)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase())) &&
          item.status === stat &&
          item.rmyear === year
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('filterRMStat');
    setSearchValue('');
    setStatRM(stat);
    setLocalStorage(`${process.env.REACT_APP_RM}`, { stat });

    const year = formRef.current?.getFieldValue('filterYears');

    setList(
      rawList.filter(
        (item: ListProps) =>
          (user.perfil === 'LOC' ? item.loccod === user.loccod : item) &&
          item.rmyear === year &&
          item.status === stat,
      ),
    );
  }, [rawList, user.loccod, user.perfil]);

  const handleYearSelect = useCallback(() => {
    const year = formRef.current?.getFieldValue('filterYears');
    setYearSelected(year);
    setSearchValue('');
    setLocalStorage(`${process.env.REACT_APP_RM}`, { year });

    const stat = formRef.current?.getFieldValue('filterRMStat');

    setList(
      rawList.filter(
        (item: ListProps) =>
          (user.perfil === 'LOC' ? item.loccod === user.loccod : item) &&
          item.rmyear === year &&
          item.status === stat,
      ),
    );
  }, [rawList, user.loccod, user.perfil]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'NAC' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['NAC', 'LOC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              <RadioContainer
                title="Remessas de Materiais:"
                onClick={handleRadioClick}
                name="filterRMStat"
                content={[
                  {
                    id: 'eRM',
                    value: 'E',
                    label: 'Emitidas',
                  },
                  {
                    id: 'pRM',
                    value: 'P',
                    label: 'Pagas',
                  },
                  {
                    id: 'cRM',
                    value: 'C',
                    label: 'Canceladas',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={statRM}
              />

              <span style={{ marginTop: '10px' }}>
                <p>Ano de Emissão:</p>
                <SelectV2
                  name="filterYears"
                  content={years}
                  initial={yearSelected}
                  onChange={handleYearSelect}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>Remessas de Material</Header>
      {user.perfil === 'NAC' && (
        <FloatInput amount="100px">
          <input
            placeholder="Filtrar por numeração ou coordenação"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={user.perfil !== 'NAC' ? '231px' : '280px'}>
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem
              key={item.rmnum}
              bordercolor={
                item.status === 'C'
                  ? '#c53030'
                  : item.status === 'E'
                  ? '#2156e6'
                  : '#00802b'
              }
            >
              <p>
                N° {item.rmnum} |&nbsp;
                {formatDate(item.data)}
              </p>
              <span>
                <p>
                  Valor da RM:&nbsp;
                  <strong>R$ {formatNumber(item.valor)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statDesc}</strong>
                </p>
              </span>
              {user.perfil === 'NAC' && item.loccod !== user.anbc && (
                <span>
                  <p>
                    Coord:&nbsp;
                    <strong>{item.locdesc}</strong>
                  </p>
                </span>
              )}
              {user.perfil === 'NAC' && item.loccod === user.anbc && (
                <span>
                  <p>
                    Emitida por :&nbsp;
                    <strong>{item.anbdesc}</strong>
                  </p>
                </span>
              )}

              {item.solnum ? (
                <span>
                  <p>
                    Baseada na solicitação:&nbsp;
                    <strong>
                      N° {item.solnum} | {formatDate(item.soldata)}
                    </strong>
                  </p>
                </span>
              ) : null}
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { rm: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {item.status !== 'C' && item.valor > 0 && (
                  <O.Financ
                    to={{
                      pathname: '/sgo/tesouraria/contfinrm/detail',
                      state: {
                        finHeader: {
                          num: item.rmnum,
                          anbcod: item.anbcod,
                          cod: item.loccod,
                          desc: item.locdesc,
                          date: item.data,
                          valor: item.valor,
                          status: item.status,
                        },
                      },
                    }}
                  >
                    <FaDollarSign />
                  </O.Financ>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default RM;
