import React, { useEffect } from 'react';

import {
  FaExclamationCircle,
  FaCheckCircle,
  FaInfoCircle,
  FaTimesCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';

import { ToastMessage, useToast } from 'hooks/toast';
import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FaInfoCircle size={20} />,
  success: <FaCheckCircle size={20} />,
  error: <FaExclamationCircle size={20} />,
  warning: <FaExclamationCircle size={20} />,
};
const Toast: React.FC<ToastProps> = ({ message, style, ...rest }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const seconds = message?.seconds || 5;
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id, message]);
  return (
    <Container
      type={message.type}
      hasdescription={!!message.description}
      style={style}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {!!message.description && (
          <p>
            {message.description}
            {!!message.showIcon && ` (`}
            {!!message.showIcon && <FaExclamationTriangle />}
            {!!message.showIcon && `)`}
          </p>
        )}
      </div>
      <button type="button" onClick={() => removeToast(message.id)}>
        <FaTimesCircle size={18} />
      </button>
    </Container>
  );
};

export default Toast;
