import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useCredentials } from 'hooks/credentials';
import { Link, useLocation } from 'react-router-dom';

import api from 'services/api';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { Grid } from './styles';

interface MidiaProps {
  cod: string;
  name: string;
  image: string;
  license: string;
}

const DigitalMedia: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { errorHandling } = useCredentials();
  const [availableMedia, setAvailableMedia] = useState<MidiaProps[]>([]);
  const { pathname } = useLocation();

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/midia_list.php');
      setAvailableMedia(response.data);
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [errorHandling]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Mídias Digitais</p>
          </div>
        </AlteredHeader>
        <Grid>
          {availableMedia.map((item) => (
            <div>
              <Link
                to={{
                  pathname: `${pathname}/content`,
                  search: `${item.cod}`,
                  hash: `${item.license || 'adm'}`,
                  state: {
                    matBanner: `${process.env.REACT_APP_ASSETS_DIR}/materiais/${item.image}`,
                    matName: item.name.replace('CD de ', ''),
                  },
                }}
              >
                <img
                  alt="img"
                  src={`${process.env.REACT_APP_ASSETS_DIR}/materiais/${item.image}`}
                />
                <p>{item.name}</p>
              </Link>
            </div>
          ))}
          {/* <details>
            <summary>FirstLevel</summary>
            <details style={{ marginLeft: '15px' }}>
              <summary>SecondLevel</summary>
              <details style={{ marginLeft: '30px' }}>
                <summary>ThirdLevel</summary>
                <details style={{ marginLeft: '45px' }}>
                  <summary>FourthLevel</summary>
                  <details style={{ marginLeft: '60px' }}>
                    <summary>FifthLevel</summary>
                    <p>Something2</p>
                  </details>
                </details>
              </details>
            </details>
          </details> */}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default DigitalMedia;
