import styled, { css } from 'styled-components';

import { animated } from 'react-spring';

interface AlteredButtonProps {
  shouldAppear?: boolean;
}

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;

  > div + div {
    margin: 0 0 0 30px;
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div + div {
      margin: 15px 0 0 0;
    }
  }
`;

export const LeftContainer = styled.div``;

export const ActivityInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 10px 5px;

  span {
    > p {
      width: 100%;
      text-align: left;
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
    }
  }

  /* @media screen and (max-width: 780px) {
    margin: 15px 0 0 0;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  } */
`;

export const ApplicatorsInfo = styled(animated.div)`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;

  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 10px 5px;

  > span {
    width: 360px;
    > p {
      width: 100%;
      text-align: left;
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 0 5px;
    }

    figure {
      width: 350px;
      margin: 0 0 0 5px;

      p {
        text-align: justify;
        font-size: 12px;
        color: #ff0000;
      }
    }

    & > span {
      margin-top: 10px;
    }
  }
`;

export const RightContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 10px 5px;

  span {
    > p {
      width: 100%;
      text-align: left;
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;

      strong {
        /* border: 1px solid transparent; */
        border-radius: 3px;
        padding: 0 7px;
        font-weight: 600;
        color: #8a0002;
        background: linear-gradient(
          to left,
          rgba(117, 0, 0, 0.25),
          rgba(117, 0, 0, 0.25),
          rgba(203, 0, 1, 0.25),
          rgba(117, 0, 0, 0.25),
          rgba(117, 0, 0, 0.25)
        );
      }
    }

    & + span {
      /* margin-top: 10px; */
    }
  }
`;

export const AlteredButton = styled.button<AlteredButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00802b;
  color: #efefef;
  padding: 10px 20px;
  width: 250px;
  border: 2px solid transparent;
  border-radius: 5px;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  margin-bottom: 45px;

  opacity: 0;
  pointer-events: none;

  transition: all 0.35s ease;

  ${(props) =>
    props.shouldAppear &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const ButtonRemove = styled(animated.button)`
  border: 0;
  outline: none;
  background: transparent;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  color: #767676;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  svg {
    margin-right: 5px;
    color: #c53030;
  }
`;
