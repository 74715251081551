import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Button from 'components/Button';

import { FaFileExcel, FaFlagCheckered } from 'react-icons/fa';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import * as D from 'styles/dialog_delete';
import { ModalDeleteContent, DeleteProps } from 'styles/dialog_delete';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useWindow } from 'hooks/window';
import { useSpring } from 'react-spring';

import { useLocation, useHistory } from 'react-router-dom';

import api from 'services/api';

import { formatDate } from 'utils/formatDate';

import { ListProps as ConvProps } from '../main';

import {
  TableWrapper,
  Table,
  Name,
  Nac,
  Loc,
  Cert,
  Votes,
  Print,
} from './styles';

interface ListProps {
  cert: string;
  gseq: string;
  name: string;
  loc: string;
  locdesc: string;
  anb: string;
  anbdesc: string;
  votes: number;
}

const ConvocacaoPoll: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation<ConvProps>();
  const history = useHistory();
  const { errorHandling, handlePermission } = useCredentials();
  const { addToast } = useToast();
  const { width } = useWindow();
  const [conv, setConv] = useState(() => ({ ...location.state }));

  const [list, setList] = useState<ListProps[]>([]);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const getList = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/eleicao_conv_apuracao.php?data=${JSON.stringify({
          seq: conv.seq,
        })}`,
      );
      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [conv.seq, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    handlePermission(['INT', 'ZON', 'NAC'], true);
    getList();
  }, [getList, handlePermission, history, location.state]);

  const handleDownload = useCallback(() => {
    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }
  }, []);

  const handleDiagFinish = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleFinish = useCallback(async () => {
    try {
      handleDiagFinish();
      setLoading(true);
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          uuid: conv.seq,
        }),
      );

      await api.post('/sgo/eleicao_conv_end.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setConv((state) => ({ ...state, stat: false }));
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Convocação encerrada.',
      });
      history.goBack();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, conv.seq, errorHandling, handleDiagFinish, history]);

  const contStyle = useSpring({
    opacity: list.length > 0 ? 1 : 0,
    // height: list.length > 0 ? '50px' : '0px',
    transform: list.length > 0 ? 'translateX(0px)' : 'translateX(35px)',
  });
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />

      <Content>
        <AlteredHeader>
          <div>
            <p>
              Apuração da convocação{!conv.future ? ' para' : ''}&nbsp;
              {conv.coor === 'L'
                ? conv.locdesc
                : conv.coor === 'N'
                ? conv.anbdesc
                : conv.zondesc}
            </p>
          </div>

          <div>
            <p>
              de {formatDate(conv.ini)} a {formatDate(conv.end)}
            </p>
          </div>
        </AlteredHeader>
        {conv.future && (
          <>
            <div style={{ padding: '5px 10px' }}>
              <p style={{ fontSize: '16px' }}>
                Nacional de Origem: <strong>{conv.anbrefdesc}</strong>
              </p>
            </div>
            <div style={{ padding: '5px 10px' }}>
              <p style={{ fontSize: '16px' }}>
                Locais relacionadas:&nbsp;
                <strong>
                  {conv.relatedlocals.map(
                    (rl, index) =>
                      `${rl.desc}${
                        index < conv.relatedlocals.length - 1 ? ', ' : ''
                      }`,
                  )}
                </strong>
              </p>
            </div>
          </>
        )}
        <Print style={contStyle}>
          <button type="button" onClick={handleDownload}>
            <FaFileExcel /> <p>Gerar XLS</p>
          </button>
        </Print>
        {list.length > 0 ? (
          <>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Name mode={conv.coor}>Guia</Name>

                    {conv.coor === 'Z' && (
                      <Nac>Nac{width > 595 ? 'ional' : '.'}</Nac>
                    )}

                    {['N', 'Z'].indexOf(conv.coor) > -1 && (
                      <Loc>Loc{width > 595 ? 'al' : '.'}</Loc>
                    )}
                    <Cert>Cert{width > 595 ? 'ificado' : '.'}</Cert>
                    <Votes>Votos</Votes>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <React.Fragment key={item.cert}>
                      {index !== 0 && index % 10 === 0 && (
                        <tr style={{ background: '#332e2e', color: '#fff' }}>
                          <Name mode={conv.coor}>Guia</Name>
                          {conv.coor === 'Z' && (
                            <Nac>Nac{width > 595 ? 'ional' : '.'}</Nac>
                          )}
                          {['N', 'Z'].indexOf(conv.coor) > -1 && (
                            <Loc>Loc{width > 595 ? 'al' : '.'}</Loc>
                          )}
                          <Cert>Cert{width > 595 ? 'ificado' : '.'}</Cert>
                          <Votes>Votos</Votes>
                        </tr>
                      )}
                      <tr
                        style={{
                          background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                        }}
                      >
                        <Name mode={conv.coor}>{item.name}</Name>
                        {conv.coor === 'Z' && (
                          <Nac>{width > 595 ? item.anbdesc : item.anb}</Nac>
                        )}
                        {['N', 'Z'].indexOf(conv.coor) > -1 && (
                          <Loc>{width > 595 ? item.locdesc : item.loc}</Loc>
                        )}
                        <Cert>{item.cert}</Cert>
                        <Votes>
                          {item.votes < 100
                            ? `0${item.votes}`.slice(-2)
                            : item.votes}
                        </Votes>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        ) : !loading ? (
          <div>
            <p>Sem dados de exibição</p>
          </div>
        ) : null}

        {conv.stat && (
          <Button
            bgcolor="#c53030"
            containerStyle={{ margin: '15px auto' }}
            onClick={handleDiagFinish}
          >
            <FaFlagCheckered /> <p>Encerrar</p>
          </Button>
        )}

        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_conv_apuracao.php?data=${JSON.stringify({
            seq: conv.seq,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          XLS
        </a>
        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <ModalDeleteContent>
              <p>Esta convocação será encerrada.</p>
              <p>
                Se estiver certo de sua decisão, clique em confirmar para
                continuar.
              </p>
            </ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel type="button" onClick={handleDiagFinish}>
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleFinish}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ConvocacaoPoll;
