import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';

import {
  Container,
  AlteredContent,
  ComboLocalProps,
  ComboProps,
  RemoveButton,
  FloatInput,
  Header,
  TableLink,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import SelectV2 from 'components/SelectV2';
import Loading from 'components/Loading';

import { useAuth } from 'hooks/auth';
// import { useChanges } from 'hooks/changes';
import { useCredentials } from 'hooks/credentials';

import api from 'services/api';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';
import { removeAcento } from 'utils/specialChars';
import { Table, TDDesc, TDAlt, TDShow } from './styles';

export interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  loc?: string;
  locdesc?: string;
  anb?: string;
  anbdesc?: string;
  zon?: string;
  zondesc?: string;
  cod?: string;
  desc?: string;
  typeOf?: string;
  year?: string;
}

interface ListProps {
  cod: string;
  desc: string;
  loc: boolean;
  nac: boolean;
  zon: boolean;
  stat: boolean;
  plan: boolean;
}

const PlanejamentoMenu: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  // const { plan } = useChanges();
  const { errorHandling, handlePermission } = useCredentials();
  // const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );
    return cod && desc
      ? { cod, desc }
      : ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [selectedYear, setSelectedYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return year || new Date().getUTCFullYear().toString();
  });

  const [type, setType] = useState(() => {
    const { typeOf }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return typeOf || (user.perfil === 'INT' ? 'ZON' : user.perfil);
  });

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);

  const [initialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return zon || user.zoncod;
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return anb || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return loc || 'Selecione';
  });

  const [initialYear, setInitialYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return year || new Date().getUTCFullYear().toString();
  });

  const [searchValue, setSearchValue] = useState('');

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    if (anb || user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substr(0, 2) === zon.substr(0, 2)
          : item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const getComboYears = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/combos/comboAnosPlanejamento.php?data=${JSON.stringify({
          cod: coord.cod,
        })}`,
      );

      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PLANEJAMENTO}`,
      );

      const index = response.data.findIndex(
        (item: ComboProps) => item.value === year,
      );

      setInitialYear(response.data[index > -1 ? index : 0].label);
      setSelectedYear(response.data[index > -1 ? index : 0].label);
      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        year: response.data[index > -1 ? index : 0].label,
      });
      setComboYears(response.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [coord.cod, errorHandling]);

  const getList = useCallback(async () => {
    const response = await api.get('/sgo/planejamento_list.php');

    setRawList(response.data);
    setList(
      response.data.filter((item: ListProps) => {
        return (
          !!item.stat &&
          !!item.plan &&
          (type === 'ZON'
            ? !!item.zon
            : type === 'NAC'
            ? !!item.nac
            : !!item.loc)
        );
      }),
    );
  }, [type]);

  useEffect(() => {
    if (['ZON', 'INT'].indexOf(user.perfil) > -1) {
      const { zon, zondesc }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PLANEJAMENTO}`,
      );

      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        zon: zon || user.zoncod,
        zondesc: zondesc || user.zondesc,
      });
    }
    handlePermission(['GUI']);
    getComboZON();
    getComboANB();
    getComboLOC();
    getList();
    getComboYears();
  }, [
    getComboANB,
    getComboLOC,
    getComboYears,
    getComboZON,
    getList,
    handlePermission,
    user.perfil,
    user.zoncod,
    user.zondesc,
  ]);

  const handleZONselection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value, text } = select.options[select.selectedIndex];

    setType('ZON');
    setInitialNac('Selecione');
    setInitialLoc('Selecione');

    setLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
            zon: user.zoncod,
            zondesc: user.zondesc,
            typeOf: 'ZON',
          }
        : {
            cod: value,
            desc: text,
            zon: value,
            zondesc: text,
            typeOf: 'ZON',
          },
    );

    // setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, );

    deleteLocalStorageItemKey(`${process.env.REACT_APP_PLANEJAMENTO}`, [
      'anb',
      'anbdesc',
      'loc',
      'locdesc',
    ]);

    setCoord(
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
          }
        : {
            cod: value,
            desc: text,
          },
    );

    setComboNacs(
      nacs.filter(
        (item) =>
          item.value.substr(0, 2) ===
          (user.perfil === 'ZON'
            ? user.zoncod.substr(0, 2)
            : value.substr(0, 2)),
      ),
    );
    setComboLocs([]);
  }, [nacs, user.perfil, user.zoncod, user.zondesc]);

  const handleNACselection = useCallback(() => {
    setType('NAC');

    deleteLocalStorageItemKey(`${process.env.REACT_APP_PLANEJAMENTO}`, [
      'loc',
      'locdesc',
    ]);

    setInitialLoc('Selecione');

    if (user.perfil === 'NAC') {
      setCoord({
        cod: user.anbc,
        desc: user.anbdesc,
      });

      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        cod: user.anbc,
        desc: user.anbdesc,
        anb: user.anbc,
        anbdesc: user.anbdesc,
        typeOf: 'NAC',
      });

      setComboLocs(locs.filter((item) => item.anb === user.anbc));
      return;
    }

    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setInitialNac(value);

    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      cod: value,
      desc: text,
      anb: value,
      anbdesc: text,
      typeOf: 'NAC',
    });

    setCoord({
      cod: value,
      desc: text,
    });

    setComboLocs(locs.filter((item) => item.anb === value));
    setInitialLoc('Selecione');
  }, [locs, user.anbc, user.anbdesc, user.perfil]);

  const handleLOCselection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLOC');
    const { value, text } = select.options[select.selectedIndex];

    setType('LOC');

    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      cod: value,
      desc: text,
      loc: value,
      locdesc: text,
      typeOf: 'LOC',
    });

    setCoord({
      cod: value,
      desc: text,
    });

    setInitialLoc(value);
  }, []);

  const handleYEARselection = useCallback(() => {
    const year = formRef.current?.getFieldValue('comboAno');

    setInitialYear(year);
    setSelectedYear(year);
    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      year,
    });
  }, []);

  const handleSearch = useCallback(
    (e: string) => {
      setSearchValue(e);

      setList(
        rawList.filter((item) => {
          return (
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(e).toLowerCase()) &&
            !!item.stat &&
            !!item.plan &&
            (type === 'ZON'
              ? !!item.zon
              : type === 'NAC'
              ? !!item.nac
              : !!item.loc)
          );
        }),
      );
    },
    [rawList, type],
  );

  const removeButtonNac = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeButtonLoc = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            {user.perfil === 'INT' && (
              <div>
                <span>
                  <p>Selecione a Zonal:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    initial={initialZon}
                    onChange={handleZONselection}
                  />
                  <RemoveButton
                    type="button"
                    // onClick={handleZONselection}
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    &nbsp;
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleNACselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleZONselection}
                    style={removeButtonNac}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Local:</p>
                  <SelectV2
                    name="comboLOC"
                    content={comboLocs}
                    initial={initialLoc}
                    onChange={handleLOCselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleNACselection}
                    style={removeButtonLoc}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}
            <div>
              <span>
                <p>Selecione o ano:</p>
                <SelectV2
                  name="comboAno"
                  content={comboYears}
                  initial={initialYear}
                  onChange={handleYEARselection}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>Planejamento Financeiro | {selectedYear}</div>
        <div>{coord.desc}</div>
      </Header>
      <FloatInput amount="120px">
        <input
          placeholder="Filtrar por tipo de despesa"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="302px">
        {list.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TDDesc>Descrição da Despesa</TDDesc>
                <TDShow>Consultar</TDShow>
                {((user.perfil === 'ZON' && coord.cod === user.zoncod) ||
                  (user.perfil === 'NAC' && coord.cod === user.anbc) ||
                  (user.perfil === 'LOC' && coord.cod === user.loccod)) &&
                  parseInt(selectedYear, 10) >= new Date().getFullYear() && (
                    <TDAlt>Incluir</TDAlt>
                  )}
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.cod}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <TDDesc>Descrição da despesa</TDDesc>
                      <TDShow>Consultar</TDShow>
                      {((user.perfil === 'ZON' && coord.cod === user.zoncod) ||
                        (user.perfil === 'NAC' && coord.cod === user.anbc) ||
                        (user.perfil === 'LOC' && coord.cod === user.loccod)) &&
                        parseInt(selectedYear, 10) >=
                          new Date().getFullYear() && <TDAlt>Incluir</TDAlt>}
                    </tr>
                  )}

                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <TDDesc>{item.desc}</TDDesc>
                    <TDShow>
                      <TableLink
                        background="#767676"
                        to={{
                          pathname: `${pathname}/consult`,
                          state: {
                            lanccod: item.cod,
                            lancdesc: item.desc,
                            ...coord,
                            year: selectedYear,
                          },
                        }}
                      >
                        <FaSearch />
                      </TableLink>
                    </TDShow>
                    {((user.perfil === 'ZON' && coord.cod === user.zoncod) ||
                      (user.perfil === 'NAC' && coord.cod === user.anbc) ||
                      (user.perfil === 'LOC' && coord.cod === user.loccod)) &&
                      parseInt(selectedYear, 10) >=
                        new Date().getFullYear() && (
                        <TDAlt>
                          <TableLink
                            background="#004d1a"
                            to={{
                              pathname: `${pathname}/update`,
                              state: {
                                lanccod: item.cod,
                                lancdesc: item.desc,
                                ...coord,
                                year: selectedYear,
                              },
                            }}
                          >
                            <FaPlus />
                          </TableLink>
                        </TDAlt>
                      )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default PlanejamentoMenu;
