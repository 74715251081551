import React, { useRef, useCallback, useState } from 'react';

import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { Container } from '../../../../../styles/sgo_wrappers';
import { AnimatedContent } from '../../../../../styles/animated';

import SGOHeader from '../../../../../components/SGOHeader';
import ScrollTop from '../../../../../components/ScrollTop';
import SGONavbar from '../../../../../components/SGONavbar';
import SGOFooter from '../../../../../components/SGOFooter';
import Loading from '../../../../../components/Loading';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';

import { useCredentials } from '../../../../../hooks/credentials';
import { useToast } from '../../../../../hooks/toast';

import getValidationErrors from '../../../../../utils/getValidationErrors';
import { convertSpecialChars } from '../../../../../utils/specialChars';
import api from '../../../../../services/api';

interface FormData {
  code: string;
  description: string;
}
const GruposINC: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          code: yup
            .string()
            .trim()
            .required('Código do grupo é obrigatório')
            .matches(
              /[a-z][^0-9]+/i,
              'Apenas caracteres alfanuméricos, sem acento, são permitidos',
            )
            .min(2, 'Quantidade mínima de caracteres não atingida (2)')
            .max(2, 'Quantidade máxima de caracteres excedida (2)'),
          description: yup
            .string()
            .trim()
            .required('Descrição obrigatória')
            .min(3, 'Quantidade mínima de caracteres não atingida (3)')
            .max(50, 'Quantidade máxima de caracteres excedida (50)'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const desc = convertSpecialChars(data.description);

        await api.get(
          `/sgo/grupo_insert.php?code=${data.code.toUpperCase()}&desc=${desc}`,
        );

        history.goBack();

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Grupo de material incluído com sucesso!',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, history, addToast],
  );
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Novo Grupo de Material" />
      <AnimatedContent direction="left" needmargin={75}>
        <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
          <Input name="code" placeholder="Código" />
          <Input name="description" placeholder="Descrição" />
          <Button bgcolor="#00802b" type="submit">
            Cadastrar
          </Button>
        </Form>
      </AnimatedContent>
      <SGOFooter />
    </Container>
  );
};
export default GruposINC;
