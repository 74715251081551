import styled, { css } from 'styled-components';

const Banner = `${process.env.REACT_APP_ASSETS_DIR}/header/banner_sgoreact.png`;

interface PhotoProps {
  disabled?: boolean;
}

export const Container = styled.header`
  position: relative;

  display: flex;
  align-items: center;

  width: 100vw;
  height: 100px;

  padding-left: 20px;

  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  transition: height 0.25s;
`;

export const Photo = styled.div<PhotoProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  min-height: 90px;
  min-width: 90px;

  border: 2px solid #8a0002;
  border-radius: 50%;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    img {
      height: 95%;
      width: 95%;
      object-fit: cover;
      border-radius: 50%;

      transition: transform 0.25s;
    }
  }

  &:hover {
    a {
      img {
        transform: scale(1.15);
      }
    }
  }

  @media screen and (max-width: 900px) {
    a {
      img {
        transform: scale(1.15);
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 20px 0;

  margin-left: 25px;

  h2 {
    color: #8a0002;
    margin-bottom: 7px;
  }

  div {
    overflow: hidden;
    span {
      overflow: hidden;
      display: flex;

      p {
        font-size: 1.6rem;
        color: #666;
        font-weight: 500;
      }

      strong {
        font-size: 1.6rem;
        font-weight: 700;
        margin-left: 5px;

        color: #bc4143;
      }
    }
  }
`;

export const CertPerf = styled.div`
  display: flex;

  span + span {
    margin: 0 0 0 15px;
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    span + span {
      margin: 0;
    }
  }
`;

export const Coords = styled.div`
  display: flex;
  span + span {
    margin: 0 0 0 15px;
  }

  /* @media screen and (max-width: 1250px) {
    flex-direction: column;
    span + span {
      margin: 0;
    }
  } */
`;

export const FloatingLogo = styled.div`
  position: absolute;
  right: 25px;
  top: 0;

  display: flex;
  align-items: center;

  height: 100px;
  overflow: hidden;

  transition: opacity 0.5s;

  img {
    height: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 500px) {
    opacity: 0;
    pointer-events: none;
  }
`;
