import React, { useCallback, useRef, useState, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLocation, useHistory } from 'react-router-dom';
import api from 'services/api';
import { FaTimes } from 'react-icons/fa';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import { Container, ComboProps } from 'styles/sgo_wrappers';

import { useChanges } from 'hooks/changes';
import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';
import { AlteredContent, RemovalContainer } from './styles';
import { CoordProps } from '../main';
import { StateProps } from '../update';

interface TeamProps {
  gseq: string | number;
  name: string;
  function: string;
}

const NacionaisTeam: React.FC = () => {
  const { addToast } = useToast();
  const { mud } = useChanges();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [coord] = useState<CoordProps>(() => {
    return location.state.coord || ({} as CoordProps);
  });
  const [coor, setCoor] = useState(() => {
    return coord.coor.gseq ? coord.coor.gseq.toString() : 'Selecione';
  });
  const [teso, setTeso] = useState(() => {
    return coord.teso.gseq ? coord.teso.gseq.toString() : 'Selecione';
  });
  const [secr, setSecr] = useState(() => {
    return coord.secr.gseq ? coord.secr.gseq.toString() : 'Selecione';
  });
  const [emat, setEMat] = useState(() => {
    return coord.emat.gseq ? coord.emat.gseq.toString() : 'Selecione';
  });
  const [eesc, setEEsc] = useState(() => {
    return coord.eesc.gseq ? coord.eesc.gseq.toString() : 'Selecione';
  });
  const [ejov, setEJov] = useState(() => {
    return coord.ejov.gseq ? coord.ejov.gseq.toString() : 'Selecione';
  });

  const [allGuias, setAllGuias] = useState<ComboProps[]>([]);
  const [removeCT, setRemoveCT] = useState<ComboProps[]>([]);

  const getCombo = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get(
        `/combos/comboEquipe.php?mode=NAC&coord=${coord.code}`,
      );

      const uncoor = location.state.coord.coor.gseq.toString();
      const unteso = location.state.coord.teso.gseq.toString();
      const unsecr = location.state.coord.secr.gseq.toString();
      const unemat = location.state.coord.emat.gseq.toString();
      const uneesc = location.state.coord.eesc.gseq.toString();
      const unejov = location.state.coord.ejov.gseq.toString();

      const filter = response.data
        .filter(
          (item: TeamProps) =>
            !item.function ||
            [uncoor, unteso, unsecr, unemat, unejov, uneesc].indexOf(
              `${item.gseq}`,
            ) > -1,
        )
        .map((item: TeamProps) => ({
          value: `${item.gseq}`,
          label: item.name,
        }));

      setAllGuias(filter);
      setRemoveCT(
        filter.filter(
          (item: ComboProps) => [uncoor, unteso].indexOf(item.value) < 0,
        ),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [coord.code, errorHandling, location.state.coord]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getCombo();
  }, [location.state, location.pathname, history, getCombo]);

  const handleChanges = useCallback((mode: string) => {
    const selectC = formRef.current?.getFieldRef('coor');
    const selectT = formRef.current?.getFieldRef('teso');
    const selectS = formRef.current?.getFieldRef('secr');
    const selectM = formRef.current?.getFieldRef('emat');
    const selectE = formRef.current?.getFieldRef('eesc');
    const selectJ = formRef.current?.getFieldRef('ejov');

    const cval = selectC.options[selectC.selectedIndex].value;
    const tval = selectT.options[selectT.selectedIndex].value;
    const sval = selectS.options[selectS.selectedIndex].value;
    const mval = selectM.options[selectM.selectedIndex].value;
    const escv = selectE.options[selectE.selectedIndex].value;
    const jval = selectJ.options[selectJ.selectedIndex].value;

    if (mode === 'coor') {
      setCoor(cval);
      if (cval === tval) {
        setTeso('Selecione');
      }
      if (cval === sval) {
        setSecr('Selecione');
      }
      if (cval === mval) {
        setEMat('Selecione');
      }
      if (cval === escv) {
        setEEsc('Selecione');
      }
      if (cval === jval) {
        setEJov('Selecione');
      }
    }

    if (mode === 'teso') {
      setTeso(tval);
      if (tval === cval) {
        setCoor('Selecione');
      }
      if (tval === sval) {
        setSecr('Selecione');
      }
      if (tval === mval) {
        setEMat('Selecione');
      }
      if (tval === escv) {
        setEEsc('Selecione');
      }
      if (tval === jval) {
        setEJov('Selecione');
      }
    }

    if (mode === 'secr') {
      setSecr(sval);
      if (sval === cval) {
        setCoor('Selecione');
      }
      if (sval === tval) {
        setTeso('Selecione');
      }
      if (sval === mval) {
        setEMat('Selecione');
      }
      if (sval === escv) {
        setEEsc('Selecione');
      }
      if (sval === jval) {
        setEJov('Selecione');
      }
    }

    if (mode === 'emat') {
      setEMat(mval);
      if (mval === cval) {
        setCoor('Selecione');
      }
      if (mval === tval) {
        setTeso('Selecione');
      }
      if (mval === sval) {
        setSecr('Selecione');
      }
      if (mval === escv) {
        setEEsc('Selecione');
      }
      if (mval === jval) {
        setEJov('Selecione');
      }
    }

    if (mode === 'eesc') {
      setEEsc(escv);
      if (escv === cval) {
        setCoor('Selecione');
      }
      if (escv === tval) {
        setTeso('Selecione');
      }
      if (escv === sval) {
        setSecr('Selecione');
      }
      if (escv === mval) {
        setEMat('Selecione');
      }
      if (escv === jval) {
        setEJov('Selecione');
      }
    }

    if (mode === 'ejov') {
      setEJov(jval);
      if (jval === cval) {
        setCoor('Selecione');
      }
      if (jval === tval) {
        setTeso('Selecione');
      }
      if (jval === sval) {
        setSecr('Selecione');
      }
      if (jval === mval) {
        setEMat('Selecione');
      }
      if (jval === escv) {
        setEEsc('Selecione');
      }
    }
  }, []);
  const handleCoor = useCallback(() => {
    handleChanges('coor');
  }, [handleChanges]);
  const handleTeso = useCallback(() => {
    handleChanges('teso');
  }, [handleChanges]);
  const handleSecr = useCallback(() => {
    handleChanges('secr');
  }, [handleChanges]);
  const handleEMat = useCallback(() => {
    handleChanges('emat');
  }, [handleChanges]);
  const handleEEsc = useCallback(() => {
    handleChanges('eesc');
  }, [handleChanges]);
  const handleEJov = useCallback(() => {
    handleChanges('ejov');
  }, [handleChanges]);

  const handleRemove = useCallback((mode: string) => {
    if (mode === 'coor') {
      setCoor('Selecione');
    }
    if (mode === 'teso') {
      setTeso('Selecione');
    }
    if (mode === 'secr') {
      setSecr('Selecione');
    }
    if (mode === 'emat') {
      setEMat('Selecione');
    }
    if (mode === 'eesc') {
      setEEsc('Selecione');
    }
    if (mode === 'ejov') {
      setEJov('Selecione');
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const selectC = formRef.current?.getFieldRef('coor');
      const selectT = formRef.current?.getFieldRef('teso');
      const selectS = formRef.current?.getFieldRef('secr');
      const selectM = formRef.current?.getFieldRef('emat');
      const selectE = formRef.current?.getFieldRef('eesc');
      const selectJ = formRef.current?.getFieldRef('ejov');

      const cval = selectC.options[selectC.selectedIndex].value;
      const tval = selectT.options[selectT.selectedIndex].value;
      const sval = selectS.options[selectS.selectedIndex].value;
      const mval = selectM.options[selectM.selectedIndex].value;
      const escv = selectE.options[selectE.selectedIndex].value;
      const jval = selectJ.options[selectJ.selectedIndex].value;

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          initial: {
            coor: location.state.coord.coor.gseq.toString(),
            teso: location.state.coord.teso.gseq.toString(),
            secr: location.state.coord.secr.gseq.toString(),
            emat: location.state.coord.emat.gseq.toString(),
            eesc: location.state.coord.eesc.gseq.toString(),
            ejov: location.state.coord.ejov.gseq.toString(),
          },
          altered: {
            coor: cval,
            teso: tval,
            secr: sval || '',
            emat: mval,
            eesc: escv,
            ejov: jval,
          },
          code: coord.code,
          desc: coord.desc,
        }),
      );

      await api.post(`/sgo/anbs_team.php`, send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Equipe ${coord.desc} atualizada`,
      });
      history.goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    history,
    coord.code,
    coord.desc,
    errorHandling,
    location.state.coord,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Equipe" />

      <AlteredContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <span>
              <p>Coordenador</p>
              <SelectV2
                name="coor"
                content={allGuias}
                initial={coor}
                disabled={!mud}
                onChange={handleCoor}
              />
              {mud && (
                <RemovalContainer
                  type="button"
                  onClick={() => handleRemove('coor')}
                >
                  <FaTimes />
                  {width < 420 && <p>Remover Coordenador(a)</p>}
                </RemovalContainer>
              )}
            </span>
            <span>
              <p>Tesoureiro</p>
              <SelectV2
                name="teso"
                content={allGuias}
                initial={teso}
                onChange={handleTeso}
                disabled={!mud}
              />
              {mud && (
                <RemovalContainer
                  type="button"
                  onClick={() => handleRemove('teso')}
                >
                  <FaTimes />
                  {width < 420 && <p>Remover Tesoureiro(a)</p>}
                </RemovalContainer>
              )}
            </span>
            <span>
              <p>Secretaria</p>
              <SelectV2
                name="secr"
                content={mud ? allGuias : removeCT}
                initial={secr}
                onChange={handleSecr}
              />
              <RemovalContainer
                type="button"
                onClick={() => handleRemove('secr')}
              >
                <FaTimes />
                {width < 420 && <p>Remover Secretário(a)</p>}
              </RemovalContainer>
            </span>
            <span>
              <p>Enc. material</p>
              <SelectV2
                name="emat"
                content={mud ? allGuias : removeCT}
                initial={emat}
                onChange={handleEMat}
              />
              <RemovalContainer
                type="button"
                onClick={() => handleRemove('emat')}
              >
                <FaTimes />
                {width < 420 && <p>Remover Enc. Mat</p>}
              </RemovalContainer>
            </span>
            <span>
              <p>Enc. Escola</p>
              <SelectV2
                name="eesc"
                content={mud ? allGuias : removeCT}
                initial={eesc}
                onChange={handleEEsc}
              />
              <RemovalContainer
                type="button"
                onClick={() => handleRemove('eesc')}
              >
                <FaTimes />
                {width < 420 && <p>Remover Enc. Escola</p>}
              </RemovalContainer>
            </span>
            <span>
              <p>Jovem</p>
              <SelectV2
                name="ejov"
                content={mud ? allGuias : removeCT}
                initial={ejov}
                onChange={handleEJov}
              />
              <RemovalContainer
                type="button"
                onClick={() => handleRemove('ejov')}
              >
                <FaTimes />
                {width < 420 && <p>Remover Enc. Jovem</p>}
              </RemovalContainer>
            </span>
          </div>
          <Button bgcolor="#00802b" type="submit">
            Alterar
          </Button>
        </Form>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default NacionaisTeam;
