import { addYears } from 'date-fns';
import { handleTimeZone } from 'utils/formatDate';
import moment from 'moment';

export function checkSentDate(sentDate: string, givenDate: string): boolean {
  let receivedDate = givenDate;
  if (!sentDate) {
    const today = new Date();
    receivedDate = `${today.getFullYear() - 100}-${`0${
      today.getMonth() + 1
    }`.slice(-2)}-${`0${today.getDate()}`.slice(-2)}`;
  }

  const formats = 'YYYY-mm-dd';
  // const min = new Date(parseISO(sentDate));
  // const inserted = new Date(parseISO(new Date(givenDate).toISOString()));
  const min = handleTimeZone(receivedDate);
  const inserted = handleTimeZone(givenDate);

  const minFinale = new Date(
    min.getUTCFullYear(),
    min.getUTCMonth(),
    min.getUTCDate(),
    0,
    0,
  );

  const insertedFinale = new Date(
    inserted.getUTCFullYear(),
    inserted.getUTCMonth(),
    inserted.getUTCDate(),
    0,
    0,
  );

  const finale = moment(minFinale, formats);
  const base = moment(insertedFinale, formats);

  return base.isSameOrAfter(finale);
}

export function checkTodayDate(givenDate: string): boolean {
  const formats = 'YYYY-mm-dd';
  // const inserted = new Date(parseISO(new Date(givenDate).toISOString()));
  // const today = new Date(parseISO(new Date().toISOString()));

  const inserted = handleTimeZone(givenDate);
  const today = handleTimeZone(new Date());

  const todayFinale = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    0,
    0,
  );

  const insertedFinale = new Date(
    inserted.getUTCFullYear(),
    inserted.getUTCMonth(),
    inserted.getUTCDate(),
    0,
    0,
  );

  const finale = moment(todayFinale, formats);
  const base = moment(insertedFinale, formats);

  return base.isBefore(finale);
}

export function checkTopDate(givenDate: string, topDate: string): boolean {
  const formats = 'YYYY-mm-dd';
  // const inserted = new Date(parseISO(new Date(givenDate).toISOString()));
  // const top = new Date(parseISO(new Date(topDate).toISOString()));

  const inserted = handleTimeZone(givenDate);
  const top = handleTimeZone(topDate);

  const topFinale = new Date(
    top.getUTCFullYear(),
    top.getUTCMonth(),
    top.getUTCDate(),
    0,
    0,
  );

  const insertedFinale = new Date(
    inserted.getUTCFullYear(),
    inserted.getUTCMonth(),
    inserted.getUTCDate(),
    0,
    0,
  );

  const finale = moment(topFinale, formats);
  const base = moment(insertedFinale, formats);

  return base.isBefore(finale);
}

export function checkSystemDate(givenDate: string): boolean {
  const formats = 'YYYY-mm-dd';
  // const inserted = new Date(parseISO(new Date(givenDate).toISOString()));
  // const system = new Date(parseISO(new Date('2018-07-01').toISOString()));

  const inserted = handleTimeZone(givenDate);
  const system = handleTimeZone(new Date('2018-07-01'));

  const systemFinale = new Date(
    system.getUTCFullYear(),
    system.getUTCMonth(),
    system.getUTCDate(),
    0,
    0,
  );

  const insertedFinale = new Date(
    inserted.getUTCFullYear(),
    inserted.getUTCMonth(),
    inserted.getUTCDate(),
    0,
    0,
  );

  const finale = moment(systemFinale, formats);
  const base = moment(insertedFinale, formats);

  return base.isBefore(finale);
}

export function checkFunctionDate(start: string, final: string): boolean {
  const formats = 'YYYY-mm-dd';
  // const init = new Date(parseISO(new Date(`${start}-01`).toISOString()));
  // const end = new Date(parseISO(new Date(`${final}-01`).toISOString()));

  const init = handleTimeZone(new Date(`${start}-01`));
  const end = handleTimeZone(new Date(`${final}-01`));

  const initPlus3Dated = addYears(
    new Date(
      init.getUTCFullYear(),
      init.getUTCMonth(),
      init.getUTCDate(),
      0,
      0,
    ),
    3,
  );

  const endDated = new Date(
    end.getUTCFullYear(),
    end.getUTCMonth(),
    end.getUTCDate(),
    0,
    0,
  );

  const initPlus3Finale = moment(initPlus3Dated, formats);
  const endFinale = moment(endDated, formats);

  return initPlus3Finale.isSameOrAfter(endFinale);
}

export function checkFunctionTrajDate(start: string, top: string): boolean {
  const formats = 'YYYY-mm-dd';
  // const init = new Date(parseISO(new Date(`${start}-01`).toISOString()));
  // const max = new Date(parseISO(new Date(top).toISOString()));

  const init = handleTimeZone(new Date(`${start}-01`));
  const max = handleTimeZone(top);

  const initDated = new Date(
    init.getFullYear(),
    init.getMonth(),
    init.getDate(),
    0,
    0,
  );

  const maxDated = new Date(
    max.getFullYear(),
    max.getMonth(),
    max.getDate(),
    0,
    0,
  );

  const initFinale = moment(initDated, formats);
  const maxFinale = moment(maxDated, formats);

  return initFinale.isSameOrAfter(maxFinale);
}
