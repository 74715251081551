import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useSpring } from 'react-spring';
import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { handleCPF } from 'utils/handleCPF';

import api from 'services/api';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import { addDays, format, subYears } from 'date-fns';
import { Grid, GridItem, WarnMessage } from './styles';
import { ListProps } from '../main';

interface AlunoInfos {
  cpf: string;
  epg: number;
  local: string;
  anb: string;
}

interface FormData {
  name: string;
  cpf: string;
  dtnasc: string;
  fres: string;
  fcel: string;
  mail: string;
  cep: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  state: string;
}

const AlunosInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<ListProps>();
  const history = useHistory();

  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { getCEP } = useUtils();

  const [epg] = useState(() => ({ ...location.state }));
  const [cpfs, setCPFs] = useState<AlunoInfos[]>([]);
  const [existent, setExistent] = useState<AlunoInfos>({} as AlunoInfos);
  const [estado, setEstado] = useState('Selecione');

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);
  const [hasState, setHasState] = useState(false);

  const getUFs = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboUFs.php');

      setComboEstados(response.data);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const getCPFs = useCallback(async () => {
    const response = await api.get('/sgo/epg_aluno_cpf.php');
    setCPFs(response.data);
  }, []);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getCPFs();
    getUFs();
  }, [getCPFs, getUFs, history, location.state]);

  const handleValidateCPF = useCallback(() => {
    const input = formRef.current?.getFieldValue('cpf');

    const index = cpfs.findIndex(
      (item) => item.cpf === input.replace(/[.-]/g, '').substr(0, 11),
    );

    if (index > -1) {
      formRef.current?.setFieldError('cpf', 'Já cadastrado em outra EPG');
      setExistent({ ...cpfs[index], cpf: input });
      return;
    }

    setExistent({} as AlunoInfos);
    if (!handleCPF(input) && input.length > 0) {
      formRef.current?.setFieldError('cpf', 'CPF inválido');
      formRef.current?.setFieldValue('cpf', '');
      return;
    }

    formRef.current?.setFieldError('cpf', '');
  }, [cpfs]);

  const handleUFChange = useCallback(() => {
    const select = formRef.current?.getFieldValue('state');

    setEstado(select);
  }, []);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setEstado(comboEstados[index].label);
          setHasState(true);
        } else {
          setHasState(false);
          setEstado('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setEstado('Selecione');
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          cpf: yup.string().min(14, 'Campo obrigatório'),
          dtnasc: yup
            .date()
            .typeError('Campo obrigatório')
            .min(
              new Date(subYears(new Date(), 80)),
              `Não é possível cadastrar futuros guias com data de nascimento anterior a ${format(
                addDays(subYears(new Date(), 80), 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              new Date(subYears(new Date(), 18)),
              'O futuro guia deve ter no mínimo 18 anos.',
            ),
          mail: yup.lazy((val) =>
            val
              ? yup.string().email('Formato inválido de e-mail')
              : yup.string().nullable(true),
          ),
          cep: yup.string().min(9, 'Campo obrigatório'),
          address: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          state: yup.string().notOneOf(['Selecione', '']),
        });

        await schema.validate(data, { abortEarly: false });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...data, stage: epg.stage, epg: epg.epg }),
        );

        await api.post('/sgo/epg_aluno_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `${data.name} cadastrado!`,
        });

        formRef.current?.reset();

        setEstado('Selecione');
        setHasAddress(false);
        setHasDistrict(false);
        setHasCity(false);
        setHasState(false);
        setLoading(false);

        //       cpf: string;
        // epg: number;
        // local: string;
        // anb: string;
        setCPFs((state) => [
          ...state,
          {
            cpf: data.cpf.replace(/[.-]/g, '').substr(0, 11),
            epg: epg.epg,
            local: user.locdesc,
            anb: user.anbdesc.replace('Nacional ', ''),
          },
        ]);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, epg.epg, epg.stage, errorHandling, user.anbdesc, user.locdesc],
  );

  const buttonAppear = useSpring({
    opacity: Object.keys(existent).length > 0 ? 0 : 1,
    pointerEvents: Object.keys(existent).length > 0 ? 'none' : 'all',
  });

  const headerAppear = useSpring({
    opacity: Object.keys(existent).length > 0 ? 1 : 0,
    backgroundColor: Object.keys(existent).length > 0 ? '#c53030' : '#c6c6c6',
    height: Object.keys(existent).length > 0 ? '85px' : '0px',

    overflow: 'hidden',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(epg).length > 0 ? `EPG N° ${epg.epg}` : ''}
      />

      <Content>
        <WarnMessage style={headerAppear}>
          {Object.keys(existent).length > 0 && (
            <>
              <span>
                <p>
                  O CPF n° <strong>{existent.cpf}</strong> está cadastrado na
                  EPG <strong>E-{existent.epg}</strong>
                </p>
              </span>
              <span>
                <p>
                  Coordenação responsável: <strong>{existent.local}</strong> -{' '}
                  <strong>{existent.anb}</strong>
                </p>
              </span>
            </>
          )}
        </WarnMessage>
        <AlteredHeader>
          <div>
            <p>{epg.stage}ª Etapa</p>
          </div>
        </AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Grid>
            <GridItem>
              <span>
                <p>Nome</p>
                <Input
                  name="name"
                  placeholder="Nome do Futuro Guia"
                  mask="name"
                />
              </span>
              <span>
                <p>
                  CPF
                  <br />
                  <span
                    style={{ all: 'unset', fontSize: '13px', color: '#8a0002' }}
                  >
                    * Utilize o numero do CPF correto do Futuro Guia
                    <br /> pois não é permitida a alteração desse dado
                  </span>
                </p>
                <Input
                  name="cpf"
                  placeholder="N° do CPF"
                  mask="cpf"
                  onBlur={handleValidateCPF}
                  maxLength={11}
                />
              </span>
              <span>
                <p>Data Nascimento</p>
                <Input name="dtnasc" placeholder="dd/mm/aaaa" isDate />
              </span>
              <span>
                <p>Telefone Residencial</p>
                <Input name="fres" placeholder="(xx) xxxx-xxxx" mask="phone" />
              </span>
              <span>
                <p>Telefone Celular</p>
                <Input name="fcel" placeholder="(xx) xxxxx-xxxx" mask="phone" />
              </span>
              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-mail" />
              </span>
            </GridItem>
            <GridItem>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="xxxxx-xxx"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>
              <span>
                <p>Número</p>
                <Input name="number" placeholder="Número" mask="number" />
              </span>
              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>
              <span>
                <p>Bairro</p>
                <Input
                  name="district"
                  placeholder="Bairro"
                  disabled={hasDistrict}
                />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" disabled={hasCity} />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  content={comboEstados}
                  initial={estado}
                  onChange={handleUFChange}
                  disabled={hasState}
                />
              </span>
            </GridItem>
          </Grid>
          <Button bgcolor="#00802b" style={buttonAppear} type="submit">
            Cadastrar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AlunosInsert;
