import styled from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled(animated.div)`
  display: flex;

  div + div {
    margin: 0 0 0 20px;
  }

  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
`;

export const InputPDF = styled(animated.div)`
  padding: 20px 0;
  max-width: 250px;
  font-size: 14px;
  label {
    cursor: pointer;
    padding: 10px 50px;

    background: #f40f02;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 5px;
    border: 2px solid #f40f02;
    box-shadow: 0px 0px 0px 0px rgba(51, 46, 46, 0.25);

    transition: background-color 0.35s ease, border-color 0.35s ease,
      box-shadow 0.35s ease;

    input {
      display: none;
    }

    svg {
      color: #fff;
      width: 50px;
      height: 50px;
      transition: all 0.35s ease;
    }
    h4 {
      margin-top: 5px;
      font-size: 13px;
      color: #efefef;
      text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.35);
      transition: all 0.35s ease;
    }
    &:hover {
      background-color: #fff;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.35);
      svg {
        color: #f40f02;
      }
      h4 {
        color: #f40f02;
      }
    }
  }

  /* F40F02 */
`;

export const InputAvatar = styled(animated.div)`
  /* position: absolute; */

  padding: 20px 0;
  label {
    cursor: pointer;
    padding: 10px 50px;

    background: #332e2e;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 5px;
    border: 2px solid #332e2e;
    box-shadow: 0px 0px 0px 0px rgba(51, 46, 46, 0.25);

    transition: background-color 0.35s ease, box-shadow 0.35s ease;

    input {
      display: none;
    }

    svg {
      color: #efefef;
      width: 50px;
      height: 50px;
      transition: all 0.35s ease;
    }

    h4 {
      margin-top: 5px;
      font-size: 13px;
      color: #efefef;
      text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.35);
      transition: all 0.35s ease;
    }

    &:hover {
      background-color: #fff;

      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.35);
      svg {
        color: #332e2e;
      }
      h4 {
        color: #332e2e;
      }
    }
  }
`;

export const OrDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const ReactCropContainer = styled(animated.div)`
  /* margin: 50px 0 0 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  justify-content: center;
  width: 100%;
  transform: translateY(-100px);

  /* span {
    margin: 10px 0 0 0;
    button {
      border-radius: 5px;
      outline: none;
      padding: 5px 15px;
      color: #efefef;

      & + button {
        margin-left: 10px;
      }
    }
  } */
`;

export const ButtonsContainer = styled(animated.div)`
  margin: 0 0 25px 0;
  button {
    border-radius: 5px;
    outline: none;
    padding: 5px 15px;
    color: #efefef;

    & + button {
      margin-left: 10px;
    }
  }
`;

export const SaveButton = styled.button`
  border: 2px solid #004d1a;
  background-color: #00802b;
  transition: all 0.35s;

  &:hover {
    background-color: #004d1a;
  }
`;

export const CancelButton = styled.button`
  border: 2px solid #8a0002;
  background-color: #c53030;
  transition: all 0.35s;

  &:hover {
    background-color: #8a0002;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
`;
