import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from{
    pointer-events: none;
    transform: translateX(-50vw);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    opacity: 0;
  } to {
    pointer-events: all;
    transform: translateX(0vw);
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 30px;
  margin-top: 10px;
  width: 90vw;
  max-width: 800px;

  background: #efefef;
  border: 2px solid #efefef;
  border-radius: 5px;

  animation: ${appearFromRight} 1s ease forwards;

  strong {
    font-size: 18px;
    color: #8a0002;
  }

  q {
    margin-top: 15px;
    text-align: justify;
    font-style: italic;
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
    width: 100%;
    text-align: right;
    font-style: italic;
  }
`;

export const BooksContainer = styled.section`
  margin: 20px 0;
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr;
    column-gap: 0px;
  }
`;

export const Book = styled.div`
  display: flex;

  flex-direction: column;
  /* height: 450px; */
  padding: 5px 10px;
  width: 600px;

  background: #efefef;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;

  h1 {
    text-shadow: 0px 0px 5px #8a0002;
    color: #efefef;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(197, 48, 48, 0.25),
      rgba(197, 48, 48, 0.5),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(197, 48, 48, 0.5),
      rgba(197, 48, 48, 0.25),
      rgba(255, 255, 255, 0)
    );
    text-align: center;
  }

  span {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      text-justify: justify;
    }
  }

  > p {
    width: 100%;
    text-align: right;
    font-style: italic;
    font-size: 1rem;
  }

  &:hover {
    z-index: 1;
    transform: scale(1.2);
    border-color: #8a0002;
    box-shadow: 0px 0px 15px 5px rgba(138, 0, 2, 0.75);
  }

  @media screen and (max-width: 1250px) {
    width: 90vw;
    max-width: 600px;
  }

  @media screen and (max-width: 850px) {
    &:hover {
      transform: scale(1);
      border-color: #efefef;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;
