import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: object;
  bgcolor?: string;
  animated?: boolean;
  delay?: number;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  containerStyle = {},
  bgcolor = '#bc4143',
  animated = false,
  delay = 0,
  ...rest
}) => {
  return (
    <Container
      type="button"
      style={containerStyle}
      {...rest}
      bgcolor={bgcolor}
      animated={animated || undefined}
      delay={delay}
    >
      {loading ? 'Aguarde' : children}
    </Container>
  );
};

export default Button;
