import React from 'react';

// import { FaEnvelope } from 'react-icons/fa';
import { Container } from './styles';

const Footer: React.FC = () => {
  const year = new Date().getUTCFullYear();
  return (
    <Container>
      <span>
        {/* <a
          href="mailto:contato@tovbrasil.com.br?subject=Mensagem do site - contato"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaEnvelope size={30} />
        </a> */}
        {/* <a
          href="https://facebook.com/TovBrasilOficial"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaFacebookSquare size={30} />
        </a> */}
      </span>

      <span>
        <p>
          2017 - {year} © Copyright - Todos os direitos reservados a TOV Brasil.
        </p>
      </span>
    </Container>
  );
};

export default Footer;
