import React from 'react';

import { Container, Content } from 'styles/wrappers';

import Header from 'components/Header';
import Footer from 'components/Footer';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { Common, TOV } from '../styles';

const logoFull = `${process.env.REACT_APP_ASSETS_DIR}/aboutTOV/logoTOVfull.png`;

const TOVComp: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>O que é Oficinas de Oração e Vida (TOV)?</div>
        </AlteredHeader>
        <Common>
          <TOV>
            <p>
              As Oficinas de Oração e Vida (Talleres de Oración y Vida - TOV)
              constituem o ápice e o coroamento de toda a atividade apostólica
              de frei Ignacio Larrañaga. Traduzem sua mensagem evangelizadora e
              sua espiritualidade.
            </p>
            <img src={logoFull} alt="TOV" />
            <h3>Como tudo começou...</h3>
            <strong>“O que é para o povo tem de nascer do povo.”</strong>
            <p>Os TOV tiveram início em Santiago do Chile no ano de 1984.</p>
            <p>
              Até então, entre as muitas atividades de animação e evangelização
              que frei Ignacio exercera na América Latina, destacava-se o retiro
              espiritual de cinco dias denominado Encontro de Experiência de
              Deus (EED), aplicado por primeira vez no Brasil em 1974.
            </p>
            <p>
              Uma senhora, após participar de um EED, dirigiu-se a frei Ignacio
              para testemunhar sua experiência e declarar ter descoberto uma
              riqueza excepcional e única neste retiro. Lamentava que tal
              programa de espiritualidade ficasse restrito a um pequeno número
              de pessoas, enquanto o numeroso e faminto povo de Deus se consumia
              de ânsias divinas.
            </p>
            <p>
              Ela sugeriu, então, a elaboração de um programa orgânico que
              transmitisse esta riqueza viva para todo o povo de Deus. Segundo
              frei Ignacio testemunhou no livro autobiográfico “A Rosa e o Fogo”
              (1998, p. 153),
            </p>
            <p>
              Foi nesse exato momento que um relâmpago atravessou minha mente:
              não será esta uma nova porta que o Pai está me abrindo? [...] Algo
              especial e grande se avizinhava, suscitando em mim um estado de
              ânimo singular, como de transe, como de pré-parto. Disse para mim
              mesmo: ‘O que é para o povo tem de nascer do povo’.
            </p>
            <p>
              Assim nasceu a Oficina de Oração, um serviço de animação eclesial
              para fortificar a fé do povo e implantar o Deus vivo no coração da
              sociedade, aberto a todas as pessoas que sinceramente buscam a
              Deus, independente de seu credo religioso.
            </p>
            <p>
              Em 1986, o nome deste serviço passaria a ser Oficina de Oração e
              Vida, refletindo mais precisamente seus objetivos e conteúdos e
              assinalando, de forma categórica, o equilíbrio e a harmonia entre
              Oração e Vida.
            </p>
            <p>
              Em 1997, com manifestações de alto apreço do Vaticano, realizou-se
              a cerimônia de entrega do Decreto de Aprovação dos Talleres de
              Oración y Vida (TOV) como Associação Internacional de Fiéis, de
              Direito Pontifício, com Personalidade Jurídica, com a aprovação de
              seu Estatuto por um período ad experimentum de cinco anos.
            </p>
            <p>
              A força de expansão do serviço Oficina de Oração e Vida, os frutos
              colhidos até então e o testemunho de mais de nove milhões de
              pessoas que já haviam passado por suas sessões de formação, haviam
              recebido mais de 1,5 mil depoimentos elogiosos de bispos e párocos
              de todo o mundo.
            </p>
            <p>
              Em 4 de outubro de 2002, seu Estatuto foi aprovado na íntegra e o
              original encontra-se depositado nos arquivos do Dicastério do
              Conselho Pontifício para Leigos, no Vaticano, em Roma.
            </p>
            <p>
              Em 2004, na Assembleia comemorativa dos vinte anos de fundação dos
              TOV, em Mérida (MX), frei Ignacio acrescentou o subtítulo “Uma
              Nova Evangelização”, que resume a essência de sua missão.
            </p>
            <strong>Onde o divino e o humano caminham de mãos dadas</strong>
            <p>
              A Oficina de Oração e Vida é, antes de tudo, um método para
              aprender a orar. Os participantes, como em uma oficina, onde
              trabalhando se aprende a trabalhar, aprendem a orar orando, de
              maneira ordenada, variada e progressiva, desde os primeiros passos
              até às profundidades da contemplação.
            </p>
            <p>
              A oração, levada à vida desta maneira, alcança sua dimensão
              humanizante, conduzindo o participante “do encanto de Deus ao
              encanto da vida”. Esta frase de frei Ignacio Larrañaga sintetiza,
              de forma clara e evidente, o caminho que o oficinista percorre no
              transcorrer das Sessões da Oficina de Oração e Vida.
            </p>
            <p>
              O oficinista, à luz da Palavra (bíblica) e de mensagens
              evangélicas, imerge em momentos de oração, intimidade com Deus,
              reflexão e análise da própria vida.
            </p>
            <p>
              Paulatinamente, e quase sem se dar conta, começa a se libertar de
              tristezas e angústias, a curar-se das feridas da vida, a superar
              complexos de culpa, sentimentos de insegurança, de inferioridade,
              a recuperar, enfim, a estabilidade emocional e a alegria de viver.
            </p>
            <p>
              Passo a passo, é levado a um processo de transformação pessoal
              cristificante, deixando de ser “ele mesmo”, nos seus traços
              negativos, e assumindo os traços positivos de Jesus – paciência,
              humildade, mansidão, amor, fortaleza –, perguntando-se, a cada
              nova circunstância de vida, “O que faria Jesus em meu lugar?”
            </p>
            <p>
              A breve história das Oficinas de Oração e Vida tem evidenciado sua
              notável eficácia transformadora. O testemunho de vida é o fator
              que confere garantia e credibilidade aos TOV. Proclamada de boca
              em boca, constitui a principal razão da sua rápida expansão no
              mundo.
            </p>
            <p>
              Com justiça, é razoável afirmar que os TOV colaboram na construção
              da Igreja e da sociedade, pois quem transforma um coração,
              transforma o mundo!
            </p>
            <h3>O que é uma Oficina de Oração e Vida?</h3>
            <p>
              As Oficinas de Oração e Vida (TOV) são um serviço dentro da
              Igreja. Entregam aos fiéis um método prático para aprender a orar,
              e orar de uma maneira ordenada, variada e progressiva: desde os
              primeiros passos até às profundidades da contemplação.
            </p>
            <p>
              A oração levada à vida desta maneira alcança a dimensão
              humanizante, da qual nos fala nosso Fundador.
            </p>
            <p>
              A breve história das Oficinas tem evidenciado uma realidade: elas
              possuem uma notável eficácia transformadora.
            </p>
            <p>
              Em primeiro lugar, a Oficina recebe o Oficinista e, à luz da
              Palavra, o introduz numa complexa elaboração de reflexão, oração,
              mensagem evangélica e análise da própria vida. Neste contexto o
              Oficinista imperceptivelmente é arrastado, quase sem se dar conta,
              a uma transformação de vida. Não se trata de uma conversão de um
              final de semana; trata-se de um processo lento e evolutivo ao
              longo de quatro meses. O testemunho de vida é o fator que confere
              garantia e credibilidade aos TOV. E esta eficácia transformante,
              proclamada de boca em boca pelas pessoas, constitui a principal
              razão da rápida expansão das Oficinas.
            </p>
            <p>
              Em segundo lugar, por meio dos exercícios de silenciamento, o
              sistema nervoso fica impregnado de serenidade e a paz chega à
              profundidade da alma por meio da vivência do abandono. E assim,
              por meio de mensagens e exercícios, a maioria dos Oficinistas
              chega paulatinamente a:
            </p>
            <ul>
              <li>Libertar-se das tristezas e angústias;</li>
              <li>Curar-se das feridas da vida;</li>
              <li>Superar complexos de culpa, insegurança ou inferioridade;</li>
              <li>
                Recuperar, enfim, a estabilidade emocional e a alegria de viver;
              </li>
            </ul>
            <p>
              Os TOV conseguem que o divino e o humano caminhem de mãos dadas.
            </p>
            <p>
              Em terceiro lugar, a Oficina oferece um caminho extremamente
              simples, mas eficaz, de santificação cristificante: deixar de ser
              “eu mesmo” nos meus traços negativos e ir assumindo os traços
              positivos de Jesus: paciência, fortaleza, mansidão, amor, ao
              perguntar em cada nova circunstância:
            </p>
            <strong>“O que faria Jesus em meu lugar”?</strong>
            <p>
              Impressiona escutar os familiares, quando exclamam admirados:
              “Como está mudada nossa mãe, nosso irmão”...!
            </p>
            <p>
              O bem e o mal nascem no coração do indivíduo. A Oficina atinge o
              coração e o transforma num baluarte de harmonia, serenidade e
              amor.
            </p>
            <p>
              Do coração convertido, estes dons se irradiam à família e da
              família para a sociedade.
            </p>
            <p>
              Quem transforma um coração, transforma o mundo. Podemos dizer que
              os TOV colaboram na construção da Igreja e também da sociedade.
            </p>
            <p>
              Tudo isto que foi dito, são as Oficinas de Oração e Vida (TOV).
            </p>
          </TOV>
        </Common>
      </Content>
      <Footer />
    </Container>
  );
};

export default TOVComp;
