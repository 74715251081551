import styled from 'styled-components';

export const DMGHeader = styled.div`
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 400px;

  > span {
    display: flex;
    flex-direction: column;
    > p {
      color: #767676;
      font-weight: bold;
      font-size: 16px;

      strong {
        color: #332e2e;
      }
    }

    & + span {
      margin-top: 20px;
    }
  }
`;
