import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface MarkedProps {
  marked: boolean;
}

export const CatContainer = styled(animated.div)`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 360px;

  button + button {
    margin: 0 0 0 10px;
  }
`;

export const CatButton = styled(animated.button)<MarkedProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  min-height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  border: 2px solid #efefef;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: all 0.35s;
  p {
    strong {
      color: #c53030;
      &:after {
        border: 0px solid #fff;
        position: absolute;
        opacity: 0;
        bottom: 0;
        right: 0;
        border-bottom-width: 2px;
        transform: translateX(50px);
        width: 0;
        content: '';
        transition: width 0.25s, right 0.25s, transform 0.25s, opacity 0.25s;
      }
    }
  }
  ${(props) =>
    props.marked &&
    css`
      border-color: #8a0002;
      background: #c53030;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

      p {
        color: #efefef;
        strong {
          position: relative;
          color: white;

          &:after {
            right: 0%;
            transform: translateX(0px);
            width: 100%;
            opacity: 1;
          }
        }
      }
    `}

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;

  > div {
  }
`;

export const FormCat = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  height: 360px;
  /* overflow: hidden; */
  /* width: 100vw; */

  border: 3px solid #efefef;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  > div {
    div {
      p {
        font-size: 16px;
        color: #8a0002;
        font-weight: 500;
      }

      & + div {
        margin: 10px 0 0 0;
      }
    }
    > div:nth-last-child(1) {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
`;

export const FinishButtonContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
