import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { animated } from 'react-spring';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { deconvertSpecialChars } from 'utils/specialChars';
import { useToast } from 'hooks/toast';
import { FaFileExcel } from 'react-icons/fa';

import { useSpring } from 'react-spring';

import { alphaMasking } from 'components/Textarea/masks';
import { ListProps } from '../main';

import {
  HeaderContent,
  Total,
  ContentContainer,
  ContentCabec,
  BContent,
  ContentItem,
  Monetary,
  AddressContainer,
  OBSContainer,
  AnimatedRadio,
} from './styles';

interface StateProps {
  gd: ListProps;
  gdnum?: number;
  anbcod?: string;
}

interface ItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
  numbers: string[];
  required: boolean;
}

const GDConsult: React.FC = () => {
  const printRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  const [doc, setDoc] = useState('G');
  const [content, setContent] = useState('N');
  const [gdnum] = useState<number | string>(() => {
    if (location.state.gdnum) {
      return location.state.gdnum;
    }
    if (location.state.gd) {
      return location.state.gd.gdnum;
    }
    return '-';
  });
  const [gdHeader, setGDHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }

    if (location.state.gdnum) {
      return {} as ListProps;
    }

    return location.state.gd;
  });

  const [items, setItems] = useState<ItemsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Selecione o documento',
    open: false,
    content: '',
  });

  const [printing, setPrinting] = useState(false);

  const getItems = useCallback(
    async (alt?: boolean) => {
      try {
        const fetch = {
          gdnum: alt ? location.state.gdnum : gdHeader.gdnum,
          anbcod: alt ? location.state.anbcod : gdHeader.anbcod,
          isShow: true,
        };

        const response = await api.get(
          `/sgo/gd_items.php?data=${JSON.stringify(fetch)}`,
        );

        setItems(response.data);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
        setLoading(false);
      }
    },
    [
      errorHandling,
      gdHeader.anbcod,
      gdHeader.gdnum,
      location.state.anbcod,
      location.state.gdnum,
    ],
  );

  const getConsult = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/gd_list.php?data=${JSON.stringify({
          gdnum: location.state.gdnum,
        })}`,
      );
      const index = response.data.fetchlist.findIndex(
        (item: ListProps) => item.gdnum === location.state.gdnum,
      );

      setGDHeader(response.data.fetchlist[index]);
      getItems(true);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [errorHandling, getItems, location.state.gdnum]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }

    if (location.state.gd) {
      getItems();
      return;
    }

    getConsult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const total = useMemo(() => {
    let stotal = 0;

    items.forEach((item: ItemsProps) => {
      stotal += item.qtd * item.value;
    });

    return stotal;
  }, [items]);

  const handleCancel = useCallback(
    async (values: DeleteProps['values']) => {
      setDeleteDiag({ open: false });
      if (values) {
        try {
          setLoading(true);

          let obs = '*** SEM OBSERVAÇÃO ***';
          const retrieve = document.querySelector<HTMLTextAreaElement>(
            'textarea[id="obs"]',
          );

          if (retrieve && retrieve.value !== '') {
            obs = retrieve.value;
          }

          const data = {
            ...values,
            obs: obs.replace('#', ''),
            items,
            pednum: gdHeader.pednum,
          };

          const send = new FormData();
          send.append('data', JSON.stringify(data));

          await api.post('/sgo/gd_delete.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // gd: gdHeader.gdnum,
          // anbcod: gdHeader.anbcod,
          // anbdesc: gdHeader.anbdesc,

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: `Guia de Distribuição N° ${gdHeader.gdnum} cancelada.`,
          });

          setLoading(false);

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [
      addToast,
      errorHandling,
      gdHeader.gdnum,
      gdHeader.pednum,
      history,
      items,
      location.pathname,
    ],
  );

  const handleRadioClick = useCallback(() => {
    const docSelected = printRef.current?.getFieldValue('printDoc');
    setDoc(docSelected);
  }, []);

  const handleRadioClick2 = useCallback(() => {
    const contentSelected = printRef.current?.getFieldValue('mailDoc');
    setContent(contentSelected);
  }, []);

  const handleDownload = useCallback(async () => {
    try {
      setPrinting(true);
      const docSelected = printRef.current?.getFieldValue('printDoc');

      setTimeout(() => {
        setPrinting(false);
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(
        `a[id=${docSelected === 'G' ? 'gd' : 'mail'}Link]`,
      );

      if (link) {
        link.click();
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const isPrinting = useSpring({
    opacity: printing ? 1 : 0,
    pointerEvents: printing ? 'all' : 'none',
    height: printing ? '153px' : '0px',
  });

  const isNotPrinting = useSpring({
    opacity: !printing ? 1 : 0,
    pointerEvents: !printing ? 'all' : 'none',
    height: !printing ? '200px' : '0px',
  });

  const anchor = useSpring({
    display: 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      {/* <SGONavbar noLinks title={`Consulta GD N° ${gdHeader.gdnum}`} /> */}
      <SGONavbar noLinks title={`Consulta GD N° ${gdnum}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data da GD:&nbsp;
                    <strong>{formatDate(gdHeader.data)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {user.perfil === 'ZON' ? (
                      <>
                        Coordenação:&nbsp;<strong>{gdHeader.anbdesc}</strong>
                      </>
                    ) : null}
                  </p>
                </span>
              </div>
              {gdHeader.pednum ? (
                <div>
                  <span>
                    <p>
                      Pedido N°:&nbsp;
                      <strong>{gdHeader.pednum}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Data do Pedido:&nbsp;
                      <strong>{formatDate(gdHeader.peddata)}</strong>
                    </p>
                  </span>
                </div>
              ) : null}
            </HeaderContent>
            <Total>
              <Button
                bgcolor="#332e2e"
                onClick={() => setShow((state) => ({ ...state, open: true }))}
              >
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total desta GD: <strong>R$ {formatNumber(total)}</strong>
              </p>
            </Total>
            <ContentContainer>
              <ContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </ContentCabec>
              <BContent>
                {items.map((item: ItemsProps, index: number) => (
                  <ContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </ContentItem>
                ))}
              </BContent>
            </ContentContainer>
            <AddressContainer>
              <span>
                <p>
                  Endereço de Entrega:&nbsp;
                  <strong>
                    {gdHeader.address?.street}&nbsp;-&nbsp;
                    {gdHeader.address?.district}&nbsp;-&nbsp;
                    {gdHeader.address?.city}&nbsp;-&nbsp;
                    {gdHeader.address?.state}&nbsp;-&nbsp;CEP:&nbsp;
                    {gdHeader.address?.cep}
                  </strong>
                </p>
              </span>
            </AddressContainer>
            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {gdHeader.obs &&
                    gdHeader.obs.map((item) => (
                      <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                    ))}
                </strong>
              </span>
            </OBSContainer>
            {gdHeader.financregs <= 1 &&
            user.perfil === 'ZON' &&
            user.zoncod === gdHeader.orig &&
            gdHeader.status === 'E' ? (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro da guia de
                          distribuição&nbsp;
                          <strong>N° {gdHeader.gdnum}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            placeholder="Observação"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      gd: gdHeader.gdnum,
                      anbcod: gdHeader.anbcod,
                      anbdesc: gdHeader.anbdesc,
                    },
                  })
                }
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Cancelar essa GD
              </Button>
            ) : null}
          </>
        )}
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleCancel(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <Form ref={printRef} onSubmit={() => null}>
            <animated.a
              style={anchor}
              id="gdLink"
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_gd.php?data=${JSON.stringify({
                gd: gdHeader.gdnum || location.state?.gdnum,
                dest: gdHeader.anbcod,
                token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
                // filled: contentSelected === 'S',
              })}`}
              rel="noreferrer noopener"
              target="_top"
            >
              GD
            </animated.a>

            <animated.a
              style={anchor}
              id="mailLink"
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_gd_mail.php?data=${JSON.stringify({
                gd: gdHeader.gdnum || location.state?.gdnum,
                dest: gdHeader.anbcod,
                token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
                filled: content === 'S',
              })}`}
              rel="noreferrer noopener"
              target="_top"
            >
              MAIL
            </animated.a>

            <animated.div style={isNotPrinting}>
              <RadioContainer
                title="Documento:"
                onClick={handleRadioClick}
                name="printDoc"
                content={[
                  {
                    id: 'pGD',
                    value: 'G',
                    label: 'Guia de Distribuição',
                  },
                  {
                    id: 'pMAIL',
                    value: 'M',
                    label: 'Declaração dos Correios',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={doc}
              />

              <AnimatedRadio
                onClick={handleRadioClick2}
                isVisible={doc === 'M'}
                title="Declaração:"
                name="mailDoc"
                content={[
                  {
                    id: 'mS',
                    value: 'S',
                    label: 'Em branco',
                  },
                  {
                    id: 'mN',
                    value: 'N',
                    label: 'Preenchida',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={content}
              />
            </animated.div>
            <animated.div style={isPrinting}>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </animated.div>
          </Form>
        </S.Content>
        <S.Actions>
          <S.Confirm type="button" disabled={printing} onClick={handleDownload}>
            Gerar
          </S.Confirm>
          <S.Cancel
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            Finalizar
          </S.Cancel>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default GDConsult;
