import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { FaFileExcel } from 'react-icons/fa';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useSpring } from 'react-spring';

import { useLocation, useHistory } from 'react-router-dom';

import api from 'services/api';

import { formatDate } from 'utils/formatDate';

import { ListProps as ConvProps } from '../main';

import { TableWrapper, Table, Name, Nac, Loc, Cert, Print } from './styles';

interface ListProps {
  name: string;
  cert: string;
  cod: string;
  local: string;
  anb: string;
  anbcod: string;
}

const ConvocacaoAbsent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation<ConvProps>();
  const history = useHistory();
  const { errorHandling, handlePermission } = useCredentials();
  const { width } = useWindow();
  const [conv] = useState(() => ({ ...location.state }));

  const [list, setList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/eleicao_conv_ausencia.php?data=${JSON.stringify({
          seq: conv.seq,
          mode: conv.coor,
        })}`,
      );
      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [conv.coor, conv.seq, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    handlePermission(['INT', 'ZON', 'NAC'], true);
    getList();
  }, [getList, handlePermission, history, location.state]);

  const handleDownload = useCallback(() => {
    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }
  }, []);

  const contStyle = useSpring({
    opacity: list.length > 0 ? 1 : 0,
    // height: list.length > 0 ? '50px' : '0px',
    transform: list.length > 0 ? 'translateX(0px)' : 'translateX(35px)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>
              Guias sem atuação na convocação{!conv.future ? ' para' : ''}&nbsp;
              {conv.coor === 'L'
                ? conv.locdesc
                : conv.coor === 'N'
                ? conv.anbdesc
                : conv.zondesc}
            </p>
          </div>

          <div>
            <p>
              de {formatDate(conv.ini)} a {formatDate(conv.end)}
            </p>
          </div>
        </AlteredHeader>
        {conv.future && (
          <>
            <div style={{ padding: '5px 10px' }}>
              <p style={{ fontSize: '16px' }}>
                Nacional de Origem: <strong>{conv.anbrefdesc}</strong>
              </p>
            </div>
            <div style={{ padding: '5px 10px' }}>
              <p style={{ fontSize: '16px' }}>
                Locais relacionadas:&nbsp;
                <strong>
                  {conv.relatedlocals.map(
                    (rl, index) =>
                      `${rl.desc}${
                        index < conv.relatedlocals.length - 1 ? ', ' : ''
                      }`,
                  )}
                </strong>
              </p>
            </div>
          </>
        )}
        <div />
        <Print style={contStyle}>
          <button type="button" onClick={handleDownload}>
            <FaFileExcel /> <p>Gerar XLS</p>
          </button>
        </Print>

        {list.length > 0 ? (
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Name mode={conv.coor}>Guia</Name>
                  {conv.coor === 'Z' && (
                    <Nac>Nac{width > 595 ? 'ional' : '.'}</Nac>
                  )}

                  {['N', 'Z'].indexOf(conv.coor) > -1 && (
                    <Loc>Loc{width > 595 ? 'al' : '.'}</Loc>
                  )}
                  <Cert>Cert{width > 595 ? 'ificado' : '.'}</Cert>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <React.Fragment key={item.cert}>
                    {index !== 0 && index % 10 === 0 && (
                      <tr style={{ background: '#332e2e', color: '#fff' }}>
                        <Name mode={conv.coor}>Guia</Name>
                        {conv.coor === 'Z' && (
                          <Nac>Nac{width > 595 ? 'ional' : '.'}</Nac>
                        )}
                        {['N', 'Z'].indexOf(conv.coor) > -1 && (
                          <Loc>Loc{width > 595 ? 'al' : '.'}</Loc>
                        )}
                        <Cert>Cert{width > 595 ? 'ificado' : '.'}</Cert>
                      </tr>
                    )}
                    <tr
                      style={{
                        background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                      }}
                    >
                      <Name mode={conv.coor}>{item.name}</Name>
                      {conv.coor === 'Z' && (
                        <Nac>{width > 595 ? item.anb : item.anbcod}</Nac>
                      )}
                      {['N', 'Z'].indexOf(conv.coor) > -1 && (
                        <Loc>{width > 595 ? item.local : item.cod}</Loc>
                      )}
                      <Cert>{item.cert}</Cert>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        ) : !loading ? (
          <div>
            <p>Sem dados de exibição</p>
          </div>
        ) : null}
        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_conv_ausentes.php?data=${JSON.stringify({
            seq: conv.seq,
            mode: conv.coor,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          XLS
        </a>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ConvocacaoAbsent;
