import React, { useState, useEffect, useCallback, useRef } from 'react';

import { animated } from 'react-spring';
import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';

import RadioContainer from 'components/RadioContainer';

import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { format, getDay, isValid, getMonth, subDays, addYears } from 'date-fns';
// import ptBR from 'date-fns/locale/pt-BR';
import { addDays, parseISO } from 'date-fns/esm';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { convertSpecialChars } from 'utils/specialChars';
import getValidationErrors from 'utils/getValidationErrors';

import { useSpring } from 'react-spring';
import { FaTimes } from 'react-icons/fa';
import {
  deleteLocalStorageItemKey,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { handleTimeZone } from 'utils/formatDate';
import moment from 'moment';
import {
  ServiceProps,
  ComboGEvangProps,
  ComboGuiaProps,
  ComboLocalServProps,
  DataInsertForm,
  CoordANB,
  CoordLOC,
  CoordGUIA,
} from '../interfaces';

import {
  InfoContainer,
  LeftContainer,
  ActivityInfo,
  ApplicatorsInfo,
  RightContainer,
  AlteredButton,
  ButtonRemove,
} from './styles';

const AtividadesInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [coordANBS, setCoordANBS] = useState<CoordANB[]>([]);
  const [coordLOCS, setCoordLOCS] = useState<CoordLOC[]>([]);
  const [coordGUIAS, setCoordGUIAS] = useState<CoordGUIA[]>([]);
  const [filteredCoordLOCS, setFilteredCoordLOCS] = useState<CoordLOC[]>([]);
  const [filteredCoordGUIAS, setFilteredCoordGUIAS] = useState<CoordGUIA[]>([]);

  const path = location.pathname.includes('atividades')
    ? 'S'
    : location.pathname.includes('servicos')
    ? 'O'
    : '';

  const lsConst = location.pathname.includes('atividades')
    ? process.env.REACT_APP_ATIVIDADE
    : location.pathname.includes('servicos')
    ? process.env.REACT_APP_SERVICO
    : undefined;

  const [comboANBs, setComboANBs] = useState<ComboProps[]>([]);

  const [services, setServices] = useState<ServiceProps[]>([]);
  const [comboService, setComboService] = useState<ComboProps[]>([]);
  const [selectedService, setSelectedService] = useState('');

  const [selectedOrig, setSelectedOrig] = useState('L');

  const [comboLocalServ, setComboLocalServ] = useState<ComboProps[]>([]);

  const [gevangs, setGevangs] = useState<ComboGEvangProps[]>([]);
  const [comboGevang, setComboGevang] = useState<ComboProps[]>([]);
  const [guias, setGuias] = useState<ComboGuiaProps[]>([]);
  const [comboGuia, setComboGuia] = useState<ComboProps[]>([]);
  const [comboGuia2, setComboGuia2] = useState<ComboProps[]>([]);

  const [initialG, setInitialG] = useState('Selecione');
  const [initialG2, setInitialG2] = useState('Selecione');

  const [partType, setPartType] = useState('V');

  const [initSemana, setInitSemana] = useState('Selecione');
  const semana: ComboProps[] = [
    { value: '1', label: 'Segunda' },
    { value: '2', label: 'Terça' },
    { value: '3', label: 'Quarta' },
    { value: '4', label: 'Quinta' },
    { value: '5', label: 'Sexta' },
    { value: '6', label: 'Sábado' },
    { value: '0', label: 'Domingo' },
  ];

  const styled = useSpring({
    opacity: selectedService !== '' ? 1 : 0,
    pointerEvents: selectedService !== '' ? 'all' : 'none',
  });

  const otherNac = useSpring({
    height: selectedOrig === 'E' ? '70px' : '0px',
    pointerEvents: selectedOrig === 'E' ? 'all' : 'none',
    opacity: selectedOrig === 'E' ? 1 : 0,
  });

  const buttonStyle = useSpring({
    opacity: initialG2 !== 'Selecione' ? 1 : 0,
    transform:
      initialG2 !== 'Selecione' ? 'translateX(0px)' : 'translateX(-10px)',
    pointerEvents: initialG2 !== 'Selecione' ? 'all' : 'none',
  });

  const getANBs = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboANBs(
      response.data.filter((item: ComboProps) => item.value !== user.anbc),
    );
  }, [user.anbc]);

  const getServices = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboServices.php');

      const filtered = response.data.filter(
        (item: ServiceProps) => item.type === path,
      );

      const geral = [
        'CB',
        'JE',
        'PE',
        'RD',
        // 'SA',
        // 'SJ',
        'TA',
        'TC',
        'TD',
        'TJ',
      ]; // 10
      const gui = ['SA', 'SJ'];
      const loc = ['CG', 'CO', 'ED', 'GC', 'JA', 'RC', 'RE', 'CC', 'SA', 'SJ']; // 18
      const nac = ['AN', 'JF']; // 2
      const zon = ['AI']; // 1
      const int = ['AI']; // 1

      setServices(response.data.filter((item: ServiceProps) => item));

      setComboService(
        filtered
          .filter((item: ServiceProps) => {
            if (user.tgcod === 'FG') {
              return ['SA', 'SJ'].indexOf(item.value) > -1;
            }
            if (user.perfil === 'GUI') {
              return (
                geral.indexOf(item.value) > -1 || gui.indexOf(item.value) > -1
              );
            }
            if (user.perfil === 'LOC') {
              return (
                geral.indexOf(item.value) > -1 || loc.indexOf(item.value) > -1
              );
            }
            if (user.perfil === 'NAC') {
              return (
                geral.indexOf(item.value) > -1 || nac.indexOf(item.value) > -1
              );
            }
            if (user.perfil === 'ZON') {
              return (
                geral.indexOf(item.value) > -1 || zon.indexOf(item.value) > -1
              );
            }
            if (user.perfil === 'INT') {
              return (
                geral.indexOf(item.value) > -1 || int.indexOf(item.value) > -1
              );
            }
            return item;
          })
          .map((item: ServiceProps) => {
            return { value: item.value, label: item.label };
          }),
      );
    } catch (e) {
      errorHandling(e);
    }
  }, [path, user.tgcod, user.perfil, errorHandling]);

  const getGuias = useCallback(
    async (param?: string) => {
      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify({
          filter: param || 'byLoc',
        })}`,
      );
      // const locFiltered: ComboGuiaProps[] = response.data.filter(
      //   (item: ComboGuiaProps) =>
      //     ['SA', 'SJ'].indexOf(formRef.current?.getFieldValue('tpserv')) > -1
      //       ? item.tg === 'FG'
      //       : ['GA', 'GP'].indexOf(item.tg) > -1,
      // );
      // MUDANÇA EM 14/03/2022, conforme solicitado;

      const locFiltered: ComboGuiaProps[] = response.data.filter(
        (item: ComboGuiaProps) =>
          ['SA', 'SJ'].indexOf(formRef.current?.getFieldValue('tpserv')) > -1
            ? item.tg === 'FG'
            : ['GA'].indexOf(item.tg) > -1,
      );

      if (locFiltered.length === 0) {
        setSelectedService('');
        formRef.current?.setFieldValue('tpserv', '');
        getServices();

        addToast({
          type: 'info',
          title: 'Não há Futuros Guias cadastrados na coordenação.',
          description: 'Não será possível cadastrar uma Oficina Supervisionada',
          seconds: 8,
        });

        return;
      }

      const index = locFiltered.findIndex(
        (item: ComboGuiaProps) => item.seq === user.gseq,
      );

      if (index < 0) {
        setInitialG('Selecione');
        formRef.current?.setFieldValue('comboGuia', '');
      } else {
        setInitialG(
          param === 'byNac' ? `${user.name}, ${user.loccod}` : user.gseq,
        );
        formRef.current?.setFieldValue('comboGuia', user.gseq);
      }

      setGuias(locFiltered);

      setComboGuia(
        locFiltered.map((item: ComboGuiaProps) => ({
          value: item.seq,
          label: param === 'byNac' ? `${item.nome}, ${item.loc}` : item.nome,
        })),
      );

      setComboGuia2(
        locFiltered
          .filter((item: ComboGuiaProps) => item.seq !== user.gseq)
          .map((item: ComboGuiaProps) => ({
            value: item.seq,
            label: item.nome,
          })),
      );
    },
    [getServices, addToast, user.gseq, user.name, user.loccod],
  );

  const getOtherGuias = useCallback(async (param: string) => {
    const response = await api.get(
      `/combos/comboGuias.php?data=${JSON.stringify({
        filter: 'byNac',
        anbc: param,
      })}`,
    );

    const locFiltered: ComboGuiaProps[] = response.data.filter(
      (item: ComboGuiaProps) => ['GA', 'GP'].indexOf(item.tg) > -1,
    );
    // const locFiltered: ComboGuiaProps[] = response.data.filter(
    //   (item: ComboGuiaProps) => ['GA', 'GP'].indexOf(item.tg) > -1,
    // );
    // MUDANÇA EM 14/03/2022, conforme solicitado;
    setGuias(locFiltered);

    setComboGuia(
      locFiltered.map((item: ComboGuiaProps) => ({
        value: item.seq,
        label: `${item.nome}, ${item.loc}`,
      })),
    );
  }, []);

  const getGevangs = useCallback(async () => {
    try {
      const service = formRef.current?.getFieldValue('tpserv');
      const response = await api.get('/combos/comboGEvangs.php');

      const responseFiltered = response.data.filter(
        (item: ComboGEvangProps) => {
          if (['CC', 'RC'].indexOf(service) > -1) {
            return item.tipo === 'CC';
          }
          if (service === 'ED') {
            return item.tipo === 'CE';
          }
          if (service === 'RE') {
            return item.tipo === 'AR';
          }
          return item;
        },
      );

      setGevangs(responseFiltered);

      setComboGevang(
        responseFiltered.map((item: ComboGEvangProps) => ({
          label: `${item.nomeCasal}, ${item.anb}`,
          value: item.seq,
        })),
      );
    } catch (e) {
      errorHandling(e);
    }
  }, [errorHandling]);

  const getLocalServ = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLocalServ.php${
        ['ZON', 'INT'].indexOf(user.perfil) > -1
          ? `?data=${JSON.stringify({ getZon: true })}`
          : ''
      }`,
    );

    setComboLocalServ(
      response.data.map((item: ComboLocalServProps) => ({
        value: item.cod,
        label: `${item.nome}, ${item.cid}`,
      })),
    );
  }, [user.perfil]);

  useEffect(() => {
    if (
      !(
        location.pathname.includes('atividades') ||
        location.pathname.includes('servicos')
      )
    ) {
      history.replace(
        `${location.pathname.substring(
          0,
          location.pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }
    getServices();
  }, [getServices, history, location.pathname]);

  const handleGuiaSelect = useCallback(() => {
    const guia = formRef.current?.getFieldValue('comboGuia');
    formRef.current?.setFieldValue('comboGuia2', '');
    setInitialG2('Selecione');
    setComboGuia2(
      guias
        .filter((item: ComboGuiaProps) => item.seq !== guia)
        .map((item: ComboGuiaProps) => ({
          value: item.seq,
          label: item.nome,
        })),
    );
  }, [guias]);

  const handleGuiaSelect2 = useCallback(() => {
    const g2 = formRef.current?.getFieldValue('comboGuia2');
    setInitialG2(
      comboGuia2[comboGuia2.findIndex((item: ComboProps) => item.value === g2)]
        .label,
    );
  }, [comboGuia2]);

  const handleSelectedService = useCallback(() => {
    setComboGuia([]);
    setComboGuia2([]);
    setComboGevang([]);
    setCoordANBS([]);
    setFilteredCoordLOCS([]);
    setFilteredCoordGUIAS([]);
    formRef.current?.setErrors({});
    const service = formRef.current?.getFieldValue('tpserv');

    setSelectedOrig('L');
    setInitialG('Selecione');
    setInitialG2('Selecione');
    setInitSemana('Selecione o dia da semana');
    formRef.current?.setData({
      tpserv: service,
      orig: '',
      comboANB: '',
      comboGuia: '',
      comboGuia2: '',
      comboGevang: '',
      localserv: '',
      dtini: '',
      dtfim: '',
      weekday: '',
      hour: '',
      participantes: '',
      tema: '',
    });
    setSelectedService(service);
    if (['CC', 'ED', 'RE', 'RC'].indexOf(service) < 0) {
      getGuias();
    } else {
      getGevangs();
    }

    // if (['AN', 'CB', 'JA', 'JE', 'PE', 'RC', 'RD'].indexOf(service) < 0) {
    //   setPartType('P');
    // } else {
    //   setPartType('V');
    // }
    if (['CB', 'JE', 'PE'].indexOf(service) < 0) {
      setPartType('P');
    } else {
      setPartType('V');
    }

    if (['CO', 'CG', 'GC'].indexOf(service) < 0) {
      getLocalServ();
    }
  }, [getGuias, getGevangs, getLocalServ]);

  const handleSelectedANB = useCallback(() => {
    getOtherGuias(formRef.current?.getFieldValue('comboANB'));
  }, [getOtherGuias]);

  const handlePartType = useCallback(() => {
    const type = formRef.current?.getFieldValue('partType');
    setPartType(type);
  }, []);

  const handleRadioClick = useCallback(() => {
    if (user.perfil !== 'LOC') {
      return;
    }
    const orig = formRef.current?.getFieldValue('orig');
    setComboGuia([]);

    setSelectedOrig(orig);

    if (orig === 'L') {
      getGuias();
      setComboANBs([]);
    } else if (orig === 'N') {
      getGuias('byNac');
      setComboANBs([]);
    } else {
      formRef.current?.setFieldValue('comboGuia', '');
      setInitialG('Selecione');
      getANBs();
    }
  }, [user.perfil, getGuias, getANBs]);

  const handleDateSelection = useCallback(() => {
    const date = formRef.current?.getFieldValue('dtini').padStart(10, 0);
    // const parsed = new Date(new Date(date).toISOString());
    const parsed = new Date(parseISO(date));
    const dtfimRef = formRef.current?.getFieldRef('dtfim');
    const prevVal = dtfimRef.value;

    if (!isValid(parsed)) {
      setInitSemana('Selecione o dia da semana');
      return;
    }

    const weekday = getDay(parsed);

    formRef.current?.setFieldValue('weekday', weekday);
    setInitSemana(
      semana[
        semana.findIndex(
          (item: ComboProps) => parseInt(item.value, 10) === weekday,
        )
      ].label,
    );

    let daystoadd = 0;
    if (['SA', 'TA'].indexOf(selectedService) > -1) {
      daystoadd = 110;
    }
    if (['SJ', 'TC', 'TD', 'TJ'].indexOf(selectedService) > -1) {
      daystoadd = 70;
    }
    if (selectedService === 'ED') {
      daystoadd = 6;
    }
    if (['AI', 'AN', 'RE', 'JF'].indexOf(selectedService) > -1) {
      daystoadd = 3;
    }
    if (selectedService === 'CB') {
      daystoadd = 30;
    }
    if (selectedService === 'CC') {
      daystoadd = 42;
    }

    const added = addDays(parsed, daystoadd);

    formRef.current?.setFieldValue('dtfim', format(added, 'yyyy-MM-dd'));
    // formRef.current?.setFieldValue(
    //   'dtfim',
    //   isAfter(added, lastDayOfYear(parsed))
    //     ? format(lastDayOfYear(parsed), 'yyyy-MM-dd')
    //     : format(added, 'yyyy-MM-dd'),
    // );

    if (['CG', 'CO', 'GC'].indexOf(selectedService) > -1) {
      // const year = parsed.getUTCFullYear();
      const year = parsed.getFullYear();
      const month = getMonth(parsed);

      formRef.current?.setFieldValue(
        'dtfim',
        month < 6 ? `${year}-06-30` : `${year}-12-31`,
      );
    }

    if (prevVal === '') {
      dtfimRef.focus();
    }
  }, [semana, selectedService]);

  const compareValidDate = useCallback((min: string, valid: string) => {
    const mindate = handleTimeZone(min);
    const validthrough = handleTimeZone(valid);

    const formats = 'YYYY-mm-dd';

    const mdmoment = moment(mindate, formats);
    const vtmoment = moment(validthrough, formats);

    return mdmoment.isBefore(vtmoment);
  }, []);

  const handleSubmit = useCallback(
    async (data: DataInsertForm) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        // const parsedDate = new Date(parseISO(data.dtini));
        if (data.dtini === '') {
          if (!isValid(data.dtini)) {
            formRef.current?.setFieldError('dtini', 'Data inválida');
            setLoading(false);
            return;
          }
        }

        if (new Date(data.dtini).getTime() > new Date(data.dtfim).getTime()) {
          formRef.current?.setFieldError(
            'dtfim',
            'Data final deve ser maior do que a data de início',
          );
          setLoading(false);
          return;
        }

        const parsedDate = new Date(new Date(data.dtini).toISOString());

        const minINIDate = `${new Date(
          parseISO(new Date().toISOString()),
        ).getFullYear()}-01-01`;

        if (['CC', 'ED', 'RE'].indexOf(data.tpserv) > -1) {
          if (!data.comboGevang) {
            formRef.current?.setFieldError('comboGevang', ` `);
            setLoading(false);
            return;
          }

          const index = gevangs
            .map((item) => item.seq)
            .indexOf(data.comboGevang);

          if (!compareValidDate(data.dtini, gevangs[index].valid)) {
            formRef.current?.setFieldError('comboGevang', ` `);
            let message;
            if (data.tpserv === 'CC') {
              message = 'Casal Evangelizador';
            } else if (data.tpserv === 'ED') {
              message = 'Casal Evangelizador de EED';
            } else {
              message = 'Animador no Espirito';
            }
            addToast({
              type: 'error',
              title: 'Evangelizador expirado',
              description: `${message} com data de validade expirada`,
              seconds: 20,
            });
            setLoading(false);
            return;
          }
        }

        const maxINIDate = addDays(
          addYears(
            new Date(
              `${new Date(
                parseISO(new Date().toISOString()),
              ).getFullYear()}-12-31`,
            ),
            1,
          ),
          1,
        );

        let daystoadd = 0;

        if (['SA', 'TA'].indexOf(data.tpserv) > -1) {
          daystoadd = 110;
        }
        if (['SJ', 'TC', 'TD', 'TJ'].indexOf(data.tpserv) > -1) {
          daystoadd = 70;
        }
        if (data.tpserv === 'ED') {
          daystoadd = 6;
        }
        if (['AI', 'AN', 'RE', 'JF'].indexOf(data.tpserv) > -1) {
          daystoadd = 3;
        }
        if (data.tpserv === 'CB') {
          daystoadd = 30;
        }
        if (data.tpserv === 'CC') {
          daystoadd = 42;
        }

        if (!isValid(parsedDate)) {
          formRef.current?.setFieldError('dtini', 'Este campo é obrigatório');
          setLoading(false);
          return;
        }

        if (
          parsedDate.getFullYear() <
          new Date(new Date().toISOString()).getFullYear()
        ) {
          formRef.current?.setFieldError(
            'dtini',
            `A data de início deve ser posterior a ${format(
              subDays(new Date(parseISO(minINIDate)), 1),
              'dd/MM/yyyy',
            )}`,
          );
          setLoading(false);
          return;
        }

        let ENDDate = addDays(parsedDate, daystoadd);

        if (['CG', 'CO', 'GC'].indexOf(data.tpserv) > -1) {
          const year = parsedDate.getUTCFullYear();
          const month = getMonth(parsedDate);
          ENDDate = new Date(
            parseISO(month < 6 ? `${year}-06-30` : `${year}-12-31`),
          );
        }

        const schema = yup.object().shape({
          tpserv: yup.string().notOneOf(['Selecione', '']),
          comboGevang: yup.string().when('tpserv', {
            is: (val) => ['CC', 'ED', 'RE', 'RC'].indexOf(val) > -1,
            then: yup.string().notOneOf(['Selecione', '']),
          }),
          comboGuia: yup.string().notOneOf(['Selecione', '']),
          comboGuia2: yup.string().when('tpserv', {
            is: (val) => ['TC', 'TD'].indexOf(val) > -1,
            then: yup.string().notOneOf(['Selecine', '']),
            otherwise: yup.string().nullable(true),
          }),
          // comboGuia2: yup.string().nullable(true),
          localserv: yup.string().when('partType', {
            is: (type) =>
              type === 'V' &&
              ['AN', 'CB', 'JA', 'JE', 'PE', 'RC', 'RD'].indexOf(data.tpserv) >
                -1,
            then: yup.string().nullable(true),
            otherwise: yup.string().notOneOf(['Selecione', '']),
          }),
          // localserv: yup.string().notOneOf(['Selecione', '']),
          dtini: yup
            .date()
            .typeError('Data inválida.')
            .min(
              minINIDate,
              `A data de início deve ser posterior a ${format(
                subDays(new Date(parseISO(minINIDate)), 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              maxINIDate,
              `A data de início deve ser anterior a ${format(
                addDays(maxINIDate, 1),
                'dd/MM/yyyy',
              )}`,
            ),
          dtfim: yup.date().when('tpserv', {
            is: (val) => ['CO', 'CG', 'GC'].indexOf(val) > -1,
            then: yup
              .date()
              .typeError('Data inválida.')
              .min(`${new Date(data.dtini).getFullYear()}-01-01`)
              .max(
                ENDDate,
                'O encerramento deve ser dentro do semestre de cadastro.',
              ),
            otherwise: yup
              .date()
              .typeError('Data inválida.')
              .min(subDays(ENDDate, 10), 'Data fora do período pré-determinado')
              .max(
                addDays(ENDDate, 10),
                'Data fora do período pré-determinado',
              ),
          }),

          weekday: yup.string().notOneOf(['Selecione o dia da semana', '']),
          hour: yup.string().when('tpserv', {
            is: (val) =>
              ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ', 'CC'].indexOf(val) > -1,
            then: yup.string().required('Este campo é obrigatório'),
            otherwise: yup.string().nullable(true),
          }),
          participantes: yup
            .number()
            .typeError('Quantidade inválida')
            .min(
              -1,
              'Não é possível inserir uma quantidade negativa de participantes.',
            ),
          tema: yup.string().when('tpserv', {
            is: (val) => ['JE', 'PE', 'RD', 'RC'].indexOf(val) > -1,
            then: yup.string().nullable(true),
            otherwise: yup
              .string()
              .trim()
              .min(
                5,
                'Quantidade mínima de caracteres válidos não atingidos (5).',
              ),
          }),
          comboServCoordGUIA: yup.string().when('tpserv', {
            is: (val) => ['ED', 'RE'].indexOf(val) > -1,
            then: yup.string().required('Este campo é obrigatório'),
            otherwise: yup.string().nullable(true),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newInfo = {
          ...data,
          tema: data.tema ? convertSpecialChars(data.tema) : '',
          tpactivity:
            ['CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(data.tpserv) > -1
              ? 'O'
              : 'S',
        };

        const send = new FormData();
        send.append('data', JSON.stringify(newInfo));

        await api.post('/sgo/atividades_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const serviceDesc =
          services[
            services.findIndex(
              (item: ServiceProps) => item.value === data.tpserv,
            )
          ].label;

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Atividade ${serviceDesc} cadastrada com sucesso em ${format(
            new Date(parseISO(data.dtini)),
            'dd/MM/yyyy',
          )} ${
            ['CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(data.tpserv) > -1
              ? `as ${data.hour}`
              : ''
          }!`,
          seconds: 8,
        });

        const setStorage = {
          year: `${parsedDate.getFullYear()}`,
          semester: addDays(parsedDate, 1).getUTCMonth() < 6 ? '1' : '2',
          tscod: data.tpserv,
          tsdesc: serviceDesc,
          anbcod: user.anbc,
          anbdesc: user.anbdesc,
          loccod:
            ['AN', 'AI', 'JF'].indexOf(data.tpserv) >= 0 ? '' : user.loccod,
          locdesc:
            ['AN', 'AI', 'JF'].indexOf(data.tpserv) >= 0
              ? 'Todas da Nacional'
              : user.locdesc,
          cod:
            ['AN', 'AI', 'JF'].indexOf(data.tpserv) >= 0
              ? user.anbc
              : user.loccod,
          desc:
            ['AN', 'AI', 'JF'].indexOf(data.tpserv) >= 0
              ? user.anbdesc
              : user.locdesc,
        };

        setLocalStorage(`${lsConst}`, setStorage);
        if (['GUI', 'LOC'].indexOf(user.perfil) >= 0) {
          deleteLocalStorageItemKey(`${lsConst}`, [
            'zoncod',
            'zondesc',
            'anbcod',
            'anbdesc',
          ]);
        }

        history.replace(location.pathname.replace('/insert', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      services,
      addToast,
      user.anbc,
      user.anbdesc,
      user.loccod,
      user.locdesc,
      user.perfil,
      lsConst,
      history,
      location.pathname,
      errorHandling,
      gevangs,
      compareValidDate,
    ],
  );

  // new functions

  const getCoordsForCoord = useCallback(async () => {
    try {
      setCoordANBS([]);
      setFilteredCoordLOCS([]);
      setFilteredCoordGUIAS([]);
      const response = await api.get('/combos/serviceCoordCombos.php');

      setCoordANBS(response.data.anbs);
      setCoordLOCS(response.data.locs);
      setCoordGUIAS(response.data.guias);
    } catch (err) {
      console.log(err);
    }
  }, [setCoordANBS, setCoordLOCS, setCoordGUIAS]);

  const handleGevangSelection = useCallback(
    (ev) => {
      const { type } = ev.currentTarget.dataset;
      if (!['ED', 'RE'].includes(type)) {
        return;
      }

      getCoordsForCoord();
    },
    [getCoordsForCoord],
  );

  const handleCoordSelection = useCallback(
    (ev) => {
      const { value, dataset } = ev.currentTarget;

      if (dataset.type === 'nac') {
        setFilteredCoordLOCS(coordLOCS.filter((item) => item.anb === value));
        setFilteredCoordGUIAS([]);
      }
      if (dataset.type === 'loc') {
        const select = formRef.current?.getFieldRef('comboServCoordANB');
        const anb = select.options[select.selectedIndex].value;

        const gevangSelect = formRef.current?.getFieldRef('comboGevang');
        const currGevang = gevangs.findIndex(
          (item) =>
            item.seq === gevangSelect.options[gevangSelect.selectedIndex].value,
        );

        setFilteredCoordGUIAS(() => {
          let filtered = coordGUIAS.filter(
            (item) => item.anb === anb && item.loc === value,
          );
          if (currGevang > -1) {
            const { seq1, seq2 } = gevangs[currGevang];

            filtered = filtered.filter(
              (item) => ![seq1, seq2].includes(item.gseq),
            );
          }
          return filtered;
        });
      }
    },
    [coordLOCS, coordGUIAS, gevangs],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Cadastro de ${path === 'S' ? 'Atividade' : 'Serviço'}`}
      />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ participantes: 0 }}
        >
          <InfoContainer>
            <LeftContainer>
              <ActivityInfo>
                <span>
                  <p>Atividade</p>
                  <SelectV2
                    name="tpserv"
                    initial="Selecione"
                    content={comboService}
                    onChange={handleSelectedService}
                  />
                </span>
              </ActivityInfo>
              <ApplicatorsInfo style={styled}>
                {/* <ApplicatorsInfo> */}
                {!!selectedService &&
                  ['JE', 'PE', 'RD'].indexOf(selectedService) > -1 && (
                    <>
                      <span>
                        <p>Selecione a origem do aplicador:</p>
                        <RadioContainer
                          title=""
                          onClick={handleRadioClick}
                          name="orig"
                          content={[
                            { id: 'aL', value: 'L', label: 'Local' },
                            { id: 'aN', value: 'N', label: 'Nacional' },
                            { id: 'oN', value: 'E', label: 'Outra Nacional' },
                          ]}
                          selected="L"
                          isDisabled={user.perfil !== 'LOC'}
                        />
                      </span>

                      <animated.span style={otherNac}>
                        <p>Nacional</p>
                        <SelectV2
                          name="comboANB"
                          content={comboANBs}
                          disabled={selectedOrig !== 'E'}
                          onChange={handleSelectedANB}
                        />
                      </animated.span>

                      <span>
                        <p>Guia Aplicador</p>
                        <SelectV2
                          name="comboGuia"
                          content={comboGuia}
                          initial={initialG}
                          disabled={user.perfil !== 'LOC'}
                        />
                      </span>
                    </>
                  )}
                {!!selectedService &&
                  ['CC', 'ED', 'RE', 'RC'].indexOf(selectedService) > -1 && (
                    <span>
                      <p>
                        {['CC', 'RC'].indexOf(selectedService) > -1
                          ? 'Casal Coordenador'
                          : selectedService === 'ED'
                          ? 'Casal Evangelizador'
                          : 'Animador no Espírito'}
                      </p>
                      <SelectV2
                        name="comboGevang"
                        content={comboGevang}
                        initial="Selecione"
                        data-type={selectedService}
                        onChange={handleGevangSelection}
                      />
                      {['CC', 'ED', 'RE', 'RC'].indexOf(selectedService) >
                        -1 && (
                        <figure>
                          <p>
                            * Se o{' '}
                            {['CC', 'RC', 'ED'].indexOf(selectedService) > -1
                              ? `Casal ${
                                  selectedService === 'ED'
                                    ? 'Evangelizador'
                                    : 'Coordenador'
                                }`
                              : 'Animador no Espírito'}
                            &nbsp; não estiver na lista, é necessário solicitar
                            à Coordenação&nbsp;
                            {['RE', 'CC', 'ED'].indexOf(selectedService)
                              ? 'Zonal'
                              : 'Nacional'}
                            &nbsp; o cadastramento.
                          </p>
                        </figure>
                      )}
                    </span>
                  )}
                {!!selectedService &&
                ['RE', 'ED'].indexOf(selectedService) > -1 ? (
                  <>
                    <span>
                      <p>ANB do Coordenador</p>
                      <SelectV2
                        name="comboServCoordANB"
                        content={coordANBS.map((item) => ({
                          value: item.anb,
                          label: item.anbdesc,
                        }))}
                        initial="Selecione"
                        data-type="nac"
                        onChange={handleCoordSelection}
                      />
                    </span>
                    <span>
                      <p>Local do Coordenador</p>
                      <SelectV2
                        name="comboServCoordLOC"
                        content={filteredCoordLOCS.map((item) => ({
                          value: item.loc,
                          label: item.locdesc,
                        }))}
                        initial="Selecione"
                        data-type="loc"
                        onChange={handleCoordSelection}
                      />
                    </span>
                    <span>
                      <p>Guia Coordenador</p>
                      <SelectV2
                        name="comboServCoordGUIA"
                        content={filteredCoordGUIAS.map((item) => ({
                          value: item.gseq,
                          label: item.name,
                        }))}
                        initial="Selecione"
                      />
                    </span>
                  </>
                ) : // <span>COMBOS NAC / LOC / GUIAS</span>
                null}
                {!!selectedService &&
                  [
                    'CC',
                    'RC',
                    'ED',
                    'CG',
                    'GC',
                    'RE',
                    'JA',
                    'JE',
                    'PE',
                    'RD',
                    'AI',
                    'AN',
                    'JF',
                  ].indexOf(selectedService) < 0 && (
                    <>
                      <span>
                        <p>
                          {['SA', 'SJ'].indexOf(selectedService) > -1 &&
                            'Futuro '}
                          Guia {selectedService === 'CO' && ' Animador'}
                        </p>
                        <SelectV2
                          name="comboGuia"
                          initial={initialG}
                          content={comboGuia}
                          disabled={
                            // ['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1 ||
                            user.tgcod === 'FG' ||
                            (user.perfil === 'GUI' &&
                              ['SA', 'SJ'].indexOf(selectedService) < 0)

                            // user.perfil === 'GUI' ||
                            // (user.perfil !== 'LOC' &&
                            //   ['SA', 'SJ'].indexOf(selectedService) > -1) ||
                            // user.tgcod === 'FG'
                          }
                          onChange={handleGuiaSelect}
                        />
                      </span>
                      {/* anteriormente: ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf( selectedService, ) > -1 && user.perfil !== 'GUI' */}
                      {(['TC', 'TD'].indexOf(selectedService) > -1 ||
                        (['SA', 'SJ', 'TA', 'TJ'].indexOf(selectedService) >
                          -1 &&
                          user.perfil !== 'GUI')) && (
                        <span>
                          <p>
                            {['SA', 'SJ'].indexOf(selectedService) > -1 &&
                              'Futuro '}
                            Guia
                            {['TC', 'TD'].indexOf(selectedService) > -1 &&
                              ' Acompanhante '}
                          </p>
                          <SelectV2
                            name="comboGuia2"
                            initial={initialG2}
                            content={comboGuia2}
                            onChange={handleGuiaSelect2}
                          />

                          <ButtonRemove
                            type="button"
                            style={buttonStyle}
                            onClick={handleGuiaSelect}
                          >
                            <FaTimes /> Limpar Campo
                          </ButtonRemove>
                        </span>
                      )}
                    </>
                  )}
              </ApplicatorsInfo>
            </LeftContainer>
            <RightContainer style={styled}>
              {/* ['AN', 'CB', 'JA', 'JE', 'PE', 'RC', 'RD'] */}
              {['CB', 'JE', 'PE'].indexOf(selectedService) > -1 && (
                <span>
                  <p>Participação</p>
                  <RadioContainer
                    title=""
                    onClick={handlePartType}
                    name="partType"
                    content={[
                      { id: 'pP', value: 'P', label: 'Presencial' },
                      { id: 'pV', value: 'V', label: 'Virtual' },
                    ]}
                    selected={partType}
                  />
                </span>
              )}
              {['CG', 'GC', 'CO'].indexOf(selectedService) < 0 && (
                <span>
                  <p>Local de Serviço/Atividade</p>
                  <SelectV2
                    name="localserv"
                    initial="Selecione"
                    content={comboLocalServ}
                    disabled={partType === 'V'}
                  />
                </span>
              )}
              <span>
                <p>
                  Data{' '}
                  {['CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(
                    selectedService,
                  ) > -1
                    ? 'Reunião de Abertura'
                    : 'de Início'}
                  {['JE', 'JA', 'RD', 'RC', 'PE'].indexOf(selectedService) >
                    -1 && ' e Encerramento'}
                </p>
                <Input
                  name="dtini"
                  isDate
                  placeholder="dd/mm/aaaa"
                  onBlur={handleDateSelection}
                />
              </span>
              {['JE', 'JA', 'RD', 'RC', 'PE'].indexOf(selectedService) < 0 && (
                <span>
                  <p>Data de Encerramento</p>

                  <Input
                    name="dtfim"
                    type={
                      ['CG', 'GC', 'CO'].indexOf(selectedService) > -1
                        ? 'date'
                        : 'text'
                    }
                    isDate={['CG', 'GC', 'CO'].indexOf(selectedService) < 0}
                    placeholder="dd/mm/aaaa"
                    disabled={['CG', 'GC', 'CO'].indexOf(selectedService) > -1}
                  />
                </span>
              )}
              {!!selectedService &&
                ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ', 'CC'].indexOf(
                  selectedService,
                ) > -1 && (
                  <>
                    <span>
                      <p>Dia da Semana:</p>
                      <SelectV2
                        name="weekday"
                        initial={initSemana}
                        content={semana}
                      />
                    </span>
                    <span>
                      <p>Horário</p>
                      <Input name="hour" type="time" placeholder="hh:mm" />
                    </span>
                  </>
                )}

              {!!selectedService &&
                ['CO', 'CB', 'ED', 'JE', 'RC', 'RD', 'PE'].indexOf(
                  selectedService,
                ) > -1 && (
                  <span>
                    <p>
                      Participantes{' '}
                      {selectedService !== 'RD' && <strong>NÃO Guias</strong>}
                    </p>
                    <Input
                      name="participantes"
                      type="number"
                      mask="number"
                      step="1"
                      defaultValue={0}
                    />
                  </span>
                )}

              {!!selectedService &&
                ['JE', 'PE', 'RE', 'RD', 'RC'].indexOf(selectedService) >
                  -1 && (
                  <span>
                    <p>Tema</p>
                    <Input
                      name="tema"
                      mask="alpha"
                      placeholder="Tema do serviço"
                    />
                  </span>
                )}
            </RightContainer>
          </InfoContainer>
          <AlteredButton
            type="submit"
            onClick={() => handleSubmit}
            shouldAppear={selectedService !== ''}
          >
            Cadastrar
          </AlteredButton>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AtividadesInsert;
