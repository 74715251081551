import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollingHookData {
  scrolled: number;
}

const ScrollingHook = createContext<ScrollingHookData>({} as ScrollingHookData);

const ScrollingProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const updateScrollAmount = useCallback(() => {
    setScrolled(window.scrollY);
  }, []);

  useEffect(() => {
    updateScrollAmount();
    window.addEventListener('scroll', () => setScrolled(window.scrollY));
    return () => window.removeEventListener('scroll', updateScrollAmount);
  });

  return (
    <ScrollingHook.Provider value={{ scrolled }}>
      {children}
    </ScrollingHook.Provider>
  );
};

function useScrolling(): ScrollingHookData {
  const context = useContext(ScrollingHook);

  if (!context) {
    throw new Error('useScrolling must be used within and ScrollingProvider');
  }

  return context;
}

export { ScrollingProvider, useScrolling };
