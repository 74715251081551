import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import Button from 'components/Button';

import { useLocation, useHistory } from 'react-router-dom';

import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';

import moment, { Moment } from 'moment';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import api from 'services/api';

import * as yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'utils/getValidationErrors';
import { addDays } from 'date-fns';
import { formatDate } from 'utils/formatDate';
import { ButtonsContainer, CategoryButton } from './styles';

import { FIGuiaProps } from '../../main';

import { FIGHistComp } from '../comps';

export interface FormData {
  [key: string]: string;
}

export interface RegsProps {
  codreg: string;
  regseq: string;
  type: string;
  ini: string;
  end: string;
  place: string;
  aplic: string | number;
  pers: string;
}

interface Category {
  value: string;
  label: string;
  title?: string;
}

const FIGHistoria: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [regs, setRegs] = useState<RegsProps[]>([]);

  const [selectedCat, setSelectedCat] = useState<Category>({
    value: 'default',
    label: 'História do Guia',
  });
  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  // const [maxDate, setMaxDate] = useState(
  //   `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
  //     -2,
  //   )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  // );

  const buttons: Category[] = [
    { value: 'CI', label: 'Atividades', title: 'Participações em Atividades' },
    { value: 'ED', label: 'EED', title: 'Participações em EED' },
    { value: 'EP', label: 'EPG', title: 'Escola de Preparação para Guias' },
    { value: 'SU', label: 'Supervisionada', title: 'Oficina Supervisionada' },
  ];

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_hist.php?data=${JSON.stringify({ mode: 'list', ...gState })}`,
      );
      const { sentDate, hists } = response.data;
      // const { sentDate, topDate, hists } = response.data;

      setMinDate(sentDate);
      // setMaxDate(topDate);

      setRegs(hists);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, gState]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getInfo();
  }, [getInfo, history, location.state]);

  const createDate = (date: string): Moment => {
    const theDate = new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      0,
      0,
    );

    return moment(theDate, 'YYYY-mm-dd');
  };
  const checkMinDate = useCallback((date: string): boolean => {
    const start = createDate('1985-01-01');
    const received = createDate(date);

    return received.isSameOrAfter(start);
  }, []);

  const checkMaxDate = useCallback(
    (date: string): boolean => {
      const minimum = createDate(minDate);
      const received = createDate(date);

      return received.isSameOrBefore(minimum);
    },
    [minDate],
  );

  const checkAfterDate = useCallback((field: string): boolean => {
    const start = createDate(formRef.current?.getFieldValue(`${field}ini`));
    const end = createDate(formRef.current?.getFieldValue(`${field}end`));
    return end.isSameOrAfter(start);
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const CI1 = yup.object().shape(
          {
            CI1type: yup
              .string()
              .when(['CI1place', 'CI1aplic', 'CI1dtini', 'CI1dtend'], {
                is: (CI1place, CI1aplic, CI1dtini, CI1dtend) =>
                  !CI1place && !CI1aplic && !CI1dtini && !CI1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            CI1place: yup
              .string()
              .when(['CI1type', 'CI1aplic', 'CI1dtini', 'CI1dtend'], {
                is: (CI1type, CI1apli, CI1dtini, CI1dtend) =>
                  !CI1type && !CI1apli && !CI1dtini && !CI1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            CI1aplic: yup
              .string()
              .when(['CI1type', 'CI1place', 'CI1dtini', 'CI1dtend'], {
                is: (CI1type, CI1place, CI1dtini, CI1dtend) =>
                  !CI1type && !CI1place && !CI1dtini && !CI1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            CI1dtini: yup
              .date()
              .when(['CI1type', 'CI1place', 'CI1aplic', 'CI1dtend'], {
                is: (CI1type, CI1place, CI1aplic, CI1dtend) =>
                  !CI1type && !CI1place && !CI1aplic && !CI1dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI1',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI1dtini),
                  )
                  .test(
                    'checkMaxDateCI1',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI1dtini),
                  ),
              }),
            CI1dtend: yup
              .date()
              .when(['CI1type', 'CI1place', 'CI1aplic', 'CI1dtini'], {
                is: (CI1type, CI1place, CI1aplic, CI1dtini) =>
                  !CI1type && !CI1place && !CI1aplic && !CI1dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI1',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI1dtend),
                  )
                  .test(
                    'checkMaxDateCI1',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI1dtend),
                  )
                  .test(
                    'checkIsAfterCI1',
                    `Data de início deve ser posterior a ${formatDate(
                      data.CI1dtini,
                    )}`,
                    () => checkAfterDate('CI1dt'),
                  ),
              }),
          },

          [
            ['CI1type', 'CI1place'],
            ['CI1type', 'CI1aplic'],
            ['CI1type', 'CI1dtini'],
            ['CI1type', 'CI1dtend'],
            ['CI1place', 'CI1aplic'],
            ['CI1place', 'CI1dtini'],
            ['CI1place', 'CI1dtend'],
            ['CI1aplic', 'CI1dtini'],
            ['CI1aplic', 'CI1dtend'],
            ['CI1dtini', 'CI1dtend'],
          ],
        );
        const CI2 = yup.object().shape(
          {
            CI2type: yup
              .string()
              .when(['CI2place', 'CI2aplic', 'CI2dtini', 'CI2dtend'], {
                is: (CI2place, CI2aplic, CI2dtini, CI2dtend) =>
                  !CI2place && !CI2aplic && !CI2dtini && !CI2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            CI2place: yup
              .string()
              .when(['CI2type', 'CI2aplic', 'CI2dtini', 'CI2dtend'], {
                is: (CI2type, CI2apli, CI2dtini, CI2dtend) =>
                  !CI2type && !CI2apli && !CI2dtini && !CI2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            CI2aplic: yup
              .string()
              .when(['CI2type', 'CI2place', 'CI2dtini', 'CI2dtend'], {
                is: (CI2type, CI2place, CI2dtini, CI2dtend) =>
                  !CI2type && !CI2place && !CI2dtini && !CI2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            CI2dtini: yup
              .date()
              .when(['CI2type', 'CI2place', 'CI2aplic', 'CI2dtend'], {
                is: (CI2type, CI2place, CI2aplic, CI2dtend) =>
                  !CI2type && !CI2place && !CI2aplic && !CI2dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI2',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI2dtini),
                  )
                  .test(
                    'checkMaxDateCI2',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI2dtini),
                  ),
              }),
            CI2dtend: yup
              .date()
              .when(['CI2type', 'CI2place', 'CI2aplic', 'CI2dtini'], {
                is: (CI2type, CI2place, CI2aplic, CI2dtini) =>
                  !CI2type && !CI2place && !CI2aplic && !CI2dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI2',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI2dtend),
                  )
                  .test(
                    'checkMaxDateCI2',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI2dtend),
                  )
                  .test(
                    'checkIsAfterCI2',
                    `Data de início deve ser posterior a ${formatDate(
                      data.CI2dtini,
                    )}`,
                    () => checkAfterDate('CI2dt'),
                  ),
              }),
          },

          [
            ['CI2type', 'CI2place'],
            ['CI2type', 'CI2aplic'],
            ['CI2type', 'CI2dtini'],
            ['CI2type', 'CI2dtend'],
            ['CI2place', 'CI2aplic'],
            ['CI2place', 'CI2dtini'],
            ['CI2place', 'CI2dtend'],
            ['CI2aplic', 'CI2dtini'],
            ['CI2aplic', 'CI2dtend'],
            ['CI2dtini', 'CI2dtend'],
          ],
        );
        const CI3 = yup.object().shape(
          {
            CI3type: yup
              .string()
              .when(['CI3place', 'CI3aplic', 'CI3dtini', 'CI3dtend'], {
                is: (CI3place, CI3aplic, CI3dtini, CI3dtend) =>
                  !CI3place && !CI3aplic && !CI3dtini && !CI3dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            CI3place: yup
              .string()
              .when(['CI3type', 'CI3aplic', 'CI3dtini', 'CI3dtend'], {
                is: (CI3type, CI3apli, CI3dtini, CI3dtend) =>
                  !CI3type && !CI3apli && !CI3dtini && !CI3dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            CI3aplic: yup
              .string()
              .when(['CI3type', 'CI3place', 'CI3dtini', 'CI3dtend'], {
                is: (CI3type, CI3place, CI3dtini, CI3dtend) =>
                  !CI3type && !CI3place && !CI3dtini && !CI3dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            CI3dtini: yup
              .date()
              .when(['CI3type', 'CI3place', 'CI3aplic', 'CI3dtend'], {
                is: (CI3type, CI3place, CI3aplic, CI3dtend) =>
                  !CI3type && !CI3place && !CI3aplic && !CI3dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI3',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI3dtini),
                  )
                  .test(
                    'checkMaxDateCI3',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI3dtini),
                  ),
              }),
            CI3dtend: yup
              .date()
              .when(['CI3type', 'CI3place', 'CI3aplic', 'CI3dtini'], {
                is: (CI3type, CI3place, CI3aplic, CI3dtini) =>
                  !CI3type && !CI3place && !CI3aplic && !CI3dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI3',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI3dtend),
                  )
                  .test(
                    'checkMaxDateCI3',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI3dtend),
                  )
                  .test(
                    'checkIsAfterCI3',
                    `Data de início deve ser posterior a ${formatDate(
                      data.CI3dtini,
                    )}`,
                    () => checkAfterDate('CI3dt'),
                  ),
              }),
          },

          [
            ['CI3type', 'CI3place'],
            ['CI3type', 'CI3aplic'],
            ['CI3type', 'CI3dtini'],
            ['CI3type', 'CI3dtend'],
            ['CI3place', 'CI3aplic'],
            ['CI3place', 'CI3dtini'],
            ['CI3place', 'CI3dtend'],
            ['CI3aplic', 'CI3dtini'],
            ['CI3aplic', 'CI3dtend'],
            ['CI3dtini', 'CI3dtend'],
          ],
        );
        const CI4 = yup.object().shape(
          {
            CI4type: yup
              .string()
              .when(['CI4place', 'CI4aplic', 'CI4dtini', 'CI4dtend'], {
                is: (CI4place, CI4aplic, CI4dtini, CI4dtend) =>
                  !CI4place && !CI4aplic && !CI4dtini && !CI4dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            CI4place: yup
              .string()
              .when(['CI4type', 'CI4aplic', 'CI4dtini', 'CI4dtend'], {
                is: (CI4type, CI4apli, CI4dtini, CI4dtend) =>
                  !CI4type && !CI4apli && !CI4dtini && !CI4dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            CI4aplic: yup
              .string()
              .when(['CI4type', 'CI4place', 'CI4dtini', 'CI4dtend'], {
                is: (CI4type, CI4place, CI4dtini, CI4dtend) =>
                  !CI4type && !CI4place && !CI4dtini && !CI4dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            CI4dtini: yup
              .date()
              .when(['CI4type', 'CI4place', 'CI4aplic', 'CI4dtend'], {
                is: (CI4type, CI4place, CI4aplic, CI4dtend) =>
                  !CI4type && !CI4place && !CI4aplic && !CI4dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI4',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI4dtini),
                  )
                  .test(
                    'checkMaxDateCI4',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI4dtini),
                  ),
              }),
            CI4dtend: yup
              .date()
              .when(['CI4type', 'CI4place', 'CI4aplic', 'CI4dtini'], {
                is: (CI4type, CI4place, CI4aplic, CI4dtini) =>
                  !CI4type && !CI4place && !CI4aplic && !CI4dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateCI4',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.CI4dtend),
                  )
                  .test(
                    'checkMaxDateCI4',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.CI4dtend),
                  )
                  .test(
                    'checkIsAfterCI4',
                    `Data de início deve ser posterior a ${formatDate(
                      data.CI4dtini,
                    )}`,
                    () => checkAfterDate('CI4dt'),
                  ),
              }),
          },

          [
            ['CI4type', 'CI4place'],
            ['CI4type', 'CI4aplic'],
            ['CI4type', 'CI4dtini'],
            ['CI4type', 'CI4dtend'],
            ['CI4place', 'CI4aplic'],
            ['CI4place', 'CI4dtini'],
            ['CI4place', 'CI4dtend'],
            ['CI4aplic', 'CI4dtini'],
            ['CI4aplic', 'CI4dtend'],
            ['CI4dtini', 'CI4dtend'],
          ],
        );

        const ED1 = yup.object().shape(
          {
            ED1type: yup
              .string()
              .when(['ED1place', 'ED1aplic', 'ED1dtini', 'ED1dtend'], {
                is: (ED1place, ED1aplic, ED1dtini, ED1dtend) =>
                  !ED1place && !ED1aplic && !ED1dtini && !ED1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            ED1place: yup
              .string()
              .when(['ED1type', 'ED1aplic', 'ED1dtini', 'ED1dtend'], {
                is: (ED1type, ED1apli, ED1dtini, ED1dtend) =>
                  !ED1type && !ED1apli && !ED1dtini && !ED1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            ED1aplic: yup
              .string()
              .when(['ED1type', 'ED1place', 'ED1dtini', 'ED1dtend'], {
                is: (ED1type, ED1place, ED1dtini, ED1dtend) =>
                  !ED1type && !ED1place && !ED1dtini && !ED1dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            ED1dtini: yup
              .date()
              .when(['ED1type', 'ED1place', 'ED1aplic', 'ED1dtend'], {
                is: (ED1type, ED1place, ED1aplic, ED1dtend) =>
                  !ED1type && !ED1place && !ED1aplic && !ED1dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateED1',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.ED1dtini),
                  )
                  .test(
                    'checkMaxDateED1',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.ED1dtini),
                  ),
              }),
            ED1dtend: yup
              .date()
              .when(['ED1type', 'ED1place', 'ED1aplic', 'ED1dtini'], {
                is: (ED1type, ED1place, ED1aplic, ED1dtini) =>
                  !ED1type && !ED1place && !ED1aplic && !ED1dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateED1',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.ED1dtend),
                  )
                  .test(
                    'checkMaxDateED1',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.ED1dtend),
                  )
                  .test(
                    'checkIsAfterED1',
                    `Data de início deve ser posterior a ${formatDate(
                      data.ED1dtini,
                    )}`,
                    () => checkAfterDate('ED1dt'),
                  ),
              }),
          },

          [
            ['ED1type', 'ED1place'],
            ['ED1type', 'ED1aplic'],
            ['ED1type', 'ED1dtini'],
            ['ED1type', 'ED1dtend'],
            ['ED1place', 'ED1aplic'],
            ['ED1place', 'ED1dtini'],
            ['ED1place', 'ED1dtend'],
            ['ED1aplic', 'ED1dtini'],
            ['ED1aplic', 'ED1dtend'],
            ['ED1dtini', 'ED1dtend'],
          ],
        );

        const ED2 = yup.object().shape(
          {
            ED2type: yup
              .string()
              .when(['ED2place', 'ED2aplic', 'ED2dtini', 'ED2dtend'], {
                is: (ED2place, ED2aplic, ED2dtini, ED2dtend) =>
                  !ED2place && !ED2aplic && !ED2dtini && !ED2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
              }),
            ED2place: yup
              .string()
              .when(['ED2type', 'ED2aplic', 'ED2dtini', 'ED2dtend'], {
                is: (ED2type, ED2apli, ED2dtini, ED2dtend) =>
                  !ED2type && !ED2apli && !ED2dtini && !ED2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(60, 'Quantidade máxima de caracteres excedida (60).'),
              }),
            ED2aplic: yup
              .string()
              .when(['ED2type', 'ED2place', 'ED2dtini', 'ED2dtend'], {
                is: (ED2type, ED2place, ED2dtini, ED2dtend) =>
                  !ED2type && !ED2place && !ED2dtini && !ED2dtend,
                then: yup.string().nullable(true),
                otherwise: yup
                  .string()
                  .trim()
                  .required('Campo obrigatório')
                  .min(5, 'Quantidade de caracteres não atiginda (5).')
                  .max(50, 'Quantidade máxima de caracteres excedida (50).'),
              }),
            ED2dtini: yup
              .date()
              .when(['ED2type', 'ED2place', 'ED2aplic', 'ED2dtend'], {
                is: (ED2type, ED2place, ED2aplic, ED2dtend) =>
                  !ED2type && !ED2place && !ED2aplic && !ED2dtend,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateED2',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.ED2dtini),
                  )
                  .test(
                    'checkMaxDateED2',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.ED2dtini),
                  ),
              }),
            ED2dtend: yup
              .date()
              .when(['ED2type', 'ED2place', 'ED2aplic', 'ED2dtini'], {
                is: (ED2type, ED2place, ED2aplic, ED2dtini) =>
                  !ED2type && !ED2place && !ED2aplic && !ED2dtini,
                then: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .notRequired(),
                otherwise: yup
                  .date()
                  .typeError('Data inválida')
                  .test(
                    'checkMinDateED2',
                    `Data de início deve ser posterior a 31/12/1984`,
                    () => checkMinDate(data.ED2dtend),
                  )
                  .test(
                    'checkMaxDateED2',
                    `Data de início deve ser anterior a ${formatDate(
                      addDays(new Date(minDate), 1).toISOString(),
                    )}`,
                    () => checkMaxDate(data.ED2dtend),
                  )
                  .test(
                    'checkIsAfterED2',
                    `Data de início deve ser posterior a ${formatDate(
                      data.ED2dtini,
                    )}`,
                    () => checkAfterDate('ED2dt'),
                  ),
              }),
          },

          [
            ['ED2type', 'ED2place'],
            ['ED2type', 'ED2aplic'],
            ['ED2type', 'ED2dtini'],
            ['ED2type', 'ED2dtend'],
            ['ED2place', 'ED2aplic'],
            ['ED2place', 'ED2dtini'],
            ['ED2place', 'ED2dtend'],
            ['ED2aplic', 'ED2dtini'],
            ['ED2aplic', 'ED2dtend'],
            ['ED2dtini', 'ED2dtend'],
          ],
        );

        const CIschema = yup
          .object()
          .concat(CI1)
          .concat(CI2)
          .concat(CI3)
          .concat(CI4);

        const EDschema = yup.object().concat(ED1).concat(ED2);

        const EPschema = yup.object().shape({
          EP1place: yup
            .string()
            .trim()
            .required('Campo obrigatório')
            .min(5, 'Quantidade de caracteres não atiginda (5).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
          EP1aplic: yup
            .string()
            .trim()
            .required('Campo obrigatório')
            .min(5, 'Quantidade de caracteres não atiginda (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          EP1dtini: yup
            .date()
            .typeError('Data Inválida')
            .test(
              'checkMinDateEP1',
              `Data de início deve ser posterior a 31/12/1984`,
              () => checkMinDate(data.EP1dtini),
            )
            .test(
              'checkMaxDateEP1',
              `Data de início deve ser anterior a ${formatDate(
                addDays(new Date(minDate), 1).toISOString(),
              )}`,
              () => checkMaxDate(data.EP1dtini),
            ),
          EP1dtend: yup
            .date()
            .typeError('Data Inválida')
            .test(
              'checkMinDateEP1',
              `Data de início deve ser posterior a 31/12/1984`,
              () => checkMinDate(data.EP1dtend),
            )
            .test(
              'checkMaxDateEP1',
              `Data de início deve ser anterior a ${formatDate(
                addDays(new Date(minDate), 1).toISOString(),
              )}`,
              () => checkMaxDate(data.EP1dtend),
            )
            .test(
              'checkIsAfterEP1',
              `Data de início deve ser posterior a ${formatDate(
                data.EP1dtini,
              )}`,
              () => checkAfterDate('EP1dt'),
            ),
        });

        const SUschema = yup.object().shape({
          SU1type: yup
            .string()
            .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          SU1place: yup
            .string()
            .trim()
            .required('Campo obrigatório')
            .min(5, 'Quantidade de caracteres não atiginda (5).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
          SU1dtini: yup
            .date()
            .typeError('Data inválida')
            .test(
              'checkMinDateSU1',
              `Data de início deve ser posterior a 31/12/1984`,
              () => checkMinDate(data.SU1dtini),
            )
            .test(
              'checkMaxDateSU1',
              `Data de início deve ser anterior a ${formatDate(
                addDays(new Date(minDate), 1).toISOString(),
              )}`,
              () => checkMaxDate(data.SU1dtini),
            ),
          SU1dtend: yup
            .date()
            .typeError('Data inválida')
            .test(
              'checkMinDateSU1',
              `Data de início deve ser posterior a 31/12/1984`,
              () => checkMinDate(data.SU1dtend),
            )
            .test(
              'checkMaxDateSU1',
              `Data de início deve ser anterior a ${formatDate(
                addDays(new Date(minDate), 1).toISOString(),
              )}`,
              () => checkMaxDate(data.SU1dtend),
            )
            .test(
              'checkIsAfterSU1',
              `Data de início deve ser posterior a ${formatDate(
                data.SU1dtini,
              )}`,
              () => checkAfterDate('SU1dt'),
            ),
          SU1aplic: yup
            .string()
            .notOneOf(['0', ''], 'Informação inválida')
            .test(
              'isPositive',
              'Deve ser um valor maior que zero.',
              () => parseInt(data.SU1aplic, 10) > 0,
            )
            .required('Campo obrigatório'),
          SU1pers: yup
            .string()
            .notOneOf([''], 'Informação inválida')
            .test(
              'lessthan',
              'Deve ser igual ou menor à quantidade de participantes.',
              () =>
                parseInt(data.SU1aplic || '0', 10) >=
                parseInt(data.SU1pers, 10),
            )
            .test(
              'isPositive',
              'Deve ser um valor maior que zero.',
              () => parseInt(data.SU1pers, 10) > 0,
            ),
        });

        if (selectedCat.value === 'CI') {
          await CIschema.validate(data, {
            abortEarly: false,
          });
        }
        if (selectedCat.value === 'ED') {
          await EDschema.validate(data, {
            abortEarly: false,
          });
        }

        if (selectedCat.value === 'EP') {
          await EPschema.validate(data, {
            abortEarly: false,
          });
        }

        if (selectedCat.value === 'SU') {
          await SUschema.validate(data, {
            abortEarly: false,
          });
        }

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...data, mode: selectedCat.value, ...gState }),
        );

        await api.post('/sgo/fig_hist.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: '',
          description: 'Atualização realizada com sucesso.',
        });

        getInfo();
      } catch (err) {
        setLoading(false);

        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: 'Verifique os ícones de erro.',
            showIcon: true,
          });
          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      checkAfterDate,
      checkMaxDate,
      checkMinDate,
      errorHandling,
      gState,
      getInfo,
      minDate,
      selectedCat.value,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>História do Guia</AlteredHeader>
        <ButtonsContainer>
          {buttons.map((item) => (
            <CategoryButton
              key={item.value}
              chosen={item.value === selectedCat.value}
              type="button"
              onClick={() =>
                setSelectedCat({
                  value: item.value,
                  label: item.title || item.label,
                })
              }
            >
              <p>{item.label}</p>
            </CategoryButton>
          ))}
        </ButtonsContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FIGHistComp
            mode={selectedCat.value}
            regs={regs.filter((item) => item.codreg === selectedCat.value)}
            form={formRef}
          />

          {selectedCat.value !== 'default' ? (
            <Button
              type="submit"
              bgcolor="#00802b"
              containerStyle={{ marginBottom: '25px' }}
            >
              Finalizar&nbsp;
              {selectedCat.value === 'CI'
                ? 'Oficinas'
                : selectedCat.value === 'EP'
                ? 'EPG'
                : selectedCat.value === 'ED'
                ? 'EED'
                : 'Supervisionada'}
            </Button>
          ) : null}
        </Form>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGHistoria;
