import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { animated } from 'react-spring';
import Tooltip from '../Tooltip';

interface RadioProps {
  selected: boolean;
  isDisabled: boolean;
}

interface ContainerProps {
  hasError?: boolean;
}

interface ContentProps {
  asColumn?: boolean;
}

export const Container = styled.div<ContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 350px;
  h3 {
    width: 100%;
    position: relative;
    font-size: 18.72px;
    margin: 0 0 5px 0;
    color: #8a0002;
  }

  & + div {
    margin: 8px 0 0 0;
  }

  ${(props) =>
    props.hasError &&
    css`
      h3 {
        color: #c53030;
      }
    `}
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${(props) =>
    props.asColumn &&
    css`
      flex-direction: column;
      > span {
        position: relative;
        margin: 0;
        width: 50%;
        align-items: center;
        justify-content: center;

        svg {
          position: absolute;
          left: 10%;
        }

        label {
          font-size: 16px;
        }

        & + span {
          margin: 5px 0 0 0px;
        }
      }
    `}
`;

export const Radio = styled.span<RadioProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  padding: 5px 10px;
  /* width: 50px; */

  background: #efefef;
  border: 2px solid #dfdfdf;
  border-radius: 5px;

  input {
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    width: 100%;
    opacity: 0;

    ${(props) =>
      props.isDisabled &&
      css`
        pointer-events: none;
      `}
  }

  label {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    ${(props) =>
      props.isDisabled &&
      css`
        pointer-events: none;
      `}

    svg {
      margin-right: 7px;
      height: 20px;
      width: 20px;
    }
  }

  & + span {
    margin: 0 0 0 15px;
  }

  transition: background-color 0.35s ease, border-color 0.35s ease,
    color 0.35s ease;

  &:hover {
    background-color: #c53030;
    border-color: #6a0002;
    color: #efefef;
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: #8a0002;
      border-color: #8a0002;
      color: #fff;

      &:hover {
        background-color: #6a0002;
        border-color: #6a0002;
        color: #fff;
      }
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: visible;
      cursor: not-allowed;
      opacity: 0.5;
      background-color: #d6d6d6;
      border-color: #d6d6d6;
      color: #332e2e;

      &:hover {
        opacity: 0.5;
        background-color: #d6d6d6;
        border-color: #d6d6d6;
        color: #332e2e;
      }
    `}
`;

export const Error = styled(Tooltip)`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);

  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;

export const ClearContainer = styled(animated.div)`
  position: absolute;

  right: 5px;

  button {
    border: 0px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    height: 25px;
    width: 25px;

    svg {
      color: #c53030;
      height: 100%;
      width: 100%;
    }

    &:hover {
      svg {
        color: ${shade(0.2, '#c53030')};
      }
    }
  }
`;
