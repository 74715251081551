import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ButtonProps {
  marked?: boolean;
}

export const ModeContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 360px;
  padding: 5px 10px;
  transform: translateX(0px), translateY(-25px);
  h4 {
    font-size: 16px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 360px;
  padding: 5px 10px;

  h4 {
    font-size: 16px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Button = styled.button<ButtonProps>`
  font-size: 16px;
  width: 170px;
  padding: 2px 3px;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 3px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.marked &&
    css`
      color: #332e2e;
      /* text-shadow: 0px 0px 1px #8a0002; */
      font-weight: bold;
      background-color: #ffebeb;
      border-color: #8a0002;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    `}

  & + button {
    margin: 0 0 0 5px;
  }
`;

export const CoordContainer = styled(animated.div)`
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  margin: 15px 0;
  display: flex;
  align-items: center;

  flex-direction: column;

  min-width: 400px;

  background: #fff;
  overflow: hidden;
  border: 2px solid #efefef;
  border-radius: 3px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  div {
    display: flex;

    width: 100%;
    padding: 3px;
    span {
      display: flex;
      width: 100%;
      button {
        position: relative;
        display: flex;

        align-items: center;
        /* width: 100%; */
        font-size: 16px;

        border: 0;
        background: transparent;

        p {
          font-size: 16px;
          color: #767676;
        }
        strong {
          margin: 0 0 0 5px;
          font-size: 16px;
          color: #332e2e;
          transition: transform 0.5s ease, color 0.5s ease;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translate(45px, -50%);
          color: #332e2e;
          height: 20px;
          width: 20px;
          opacity: 0;
          transition: opacity 0.5s ease, transform 0.5s ease;
        }

        &:hover {
          strong {
            color: #8a0002;
            transform: translateX(5px);
          }
          svg {
            opacity: 1;
            transform: translate(30px, -50%);
          }
        }

        &:disabled {
          pointer-events: none;
        }
      }
    }

    & + div {
      margin: 7px 0 0 0;
    }
  }

  @media screen and (max-width: 400px) {
    width: 360px;
    min-width: unset;
    div {
      span {
        button {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;

          &:hover {
            svg {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const ModalContent = styled.div`
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  /* flex-direction: column; */

  img {
    border: 5px solid #332e2e;
    margin: 8px auto 8px auto;
    height: 175px;
    width: 175px;
    object-fit: cover;
    border-radius: 50%;
  }

  section {
    display: flex;
    justify-content: center;

    flex-direction: column;
    > span {
      width: 100%;
      padding: 3px 5px;
      border: 2px solid transparent;
      border-bottom-color: #d6d6d6;

      p {
        color: #767676;
        strong,
        a {
          color: #332e2e;
        }
        a {
          text-decoration: none;
        }
        & + p {
          margin-top: 3px;
        }
      }
      & + span {
        margin-top: 10px;
      }
    }

    & + section {
      margin: 0 0 0 25px;
    }
  }

  @media screen and (max-width: 910px) {
    flex-direction: column;

    section + section {
      margin: 20px 0 0 0;
    }
  }
`;
