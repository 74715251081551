import React, { useState, useEffect, useMemo, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useHistory, useLocation } from 'react-router-dom';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { Container, Content } from 'styles/sgo_wrappers';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';

import api from 'services/api';

import { FaFileExcel } from 'react-icons/fa';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { ListProps } from '../main';

import { BodyContainer, BodyItem, Table } from './styles';

interface ConsultProps extends ListProps {
  from?: string;
}

const RelatFinEventosCon: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<ConsultProps>();

  const history = useHistory();
  const [reg, setReg] = useState<ConsultProps>(() => {
    return ({ ...location.state } || {}) as ConsultProps;
  });
  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [show, setShow] = useState({} as ShowProps);
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { svcseq } = location.state;
      const response = await api.get(
        `/sgo/relatorio_eventos_more.php?data=${JSON.stringify({
          svcseq,
        })}`,
      );
      if (typeof response.data !== 'object') {
        addToast({
          type: 'error',
          title: '',
          description: `Falha na requisição. Tente novamente mais tarde.`,
          seconds: 15,
        });
        history.goBack();
        return;
      }

      // console.table(response.data);
      setReg({ ...response.data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
      history.goBack();
    }
  }, [addToast, errorHandling, history, location.state]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);
    if (!!location.state.from && location.state.from === 'activities') {
      getData();
    }
    if (!location.state) {
      history.goBack();
    }
  }, [getData, handlePermission, history, location.state]);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=report]`);

    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const totalExpense = useMemo(() => {
    return reg.content.expense.reduce((accum, { vlrtotal }) => {
      return accum + parseFloat(vlrtotal);
    }, 0);
  }, [reg.content.expense]);

  const totalRevenue = useMemo(() => {
    return reg.content.revenue.reduce((accum, { vlrtotal }) => {
      return accum + parseFloat(vlrtotal);
    }, 0);
  }, [reg.content.revenue]);

  const totalEvent = useMemo(() => {
    return totalRevenue - totalExpense;
  }, [totalRevenue, totalExpense]);
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar title="Consulta de Relatório Financeiro" noLinks />
      <Content>
        {!loading ? (
          <BodyContainer>
            <BodyItem>
              <span>
                <p>
                  Evento:&nbsp;
                  <strong style={{ color: '#c53030' }}>
                    {reg.tsdesc} ({reg.svcseq})
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Aplicador(es):&nbsp;<strong>{reg.applicator}</strong>
                </p>
              </span>
              <span>
                <p>
                  Coordenador:&nbsp;<strong>{reg.svccoor?.name}</strong>
                </p>
              </span>
              <span>
                <p>
                  Local:&nbsp;<strong>{reg.place || '-'}</strong>
                </p>
              </span>

              <span>
                <p>
                  Coord.Nacional:&nbsp;<strong>{reg.anbd}</strong>
                </p>
              </span>
              {reg.anb !== reg.loc && (
                <span>
                  <p>
                    Coord.Local:&nbsp;<strong>{reg.locd}</strong>
                  </p>
                </span>
              )}
              <span>
                <p>
                  Data{reg.ini === reg.end ? '' : 's'}:&nbsp;
                  <strong>
                    {formatDate(reg.ini)}
                    {reg.ini === reg.end ? '' : ` - ${formatDate(reg.end)}`}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Tema:&nbsp;<strong>{reg.theme || '-'}</strong>
                </p>
              </span>
              <span>
                <p>
                  Participantes:&nbsp;
                  <strong style={{ color: '#c53030' }}>{reg.attendants}</strong>
                </p>
              </span>
              <span
                style={
                  ['RE', 'JA'].indexOf(reg.tscod) < 0
                    ? { opacity: 0, pointerEvents: 'none' }
                    : {}
                }
              >
                <p>
                  Outros Participantes:&nbsp;
                  <strong style={{ color: '#c53030' }}>{reg.otherparts}</strong>
                </p>
              </span>
              <span>
                <p>
                  Total de Participantes:&nbsp;
                  <strong style={{ color: '#c53030' }}>
                    {parseInt(reg.attendants, 10) +
                      parseInt(reg.otherparts, 10)}
                  </strong>
                </p>
              </span>
              <span>
                <table>
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      Total Evento:
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <strong
                        style={{
                          color:
                            totalEvent === 0
                              ? '#332e2e'
                              : totalEvent > 0
                              ? '#4caf50'
                              : '#c53030',
                        }}
                      >
                        R$&nbsp;
                        {formatNumber(totalEvent)}
                      </strong>
                    </td>
                  </tr>
                </table>
              </span>
              <span style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>Receitas</h3>
                <span>
                  <Table>
                    <tr>
                      <td>Descrição</td>
                      <td>Part.</td>
                      <td>Valor</td>
                      <td>Total</td>
                    </tr>
                    {reg.content.revenue.map((item) => (
                      <tr>
                        <td>{item.text}</td>
                        <td>{item.part}</td>
                        <td>{formatNumber(parseFloat(item.vlr))}</td>
                        <td>{formatNumber(parseFloat(item.vlrtotal))}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4}>
                        <p>
                          Total:&nbsp;
                          <strong style={{ color: '#4caf50' }}>
                            R$&nbsp;{formatNumber(totalRevenue)}
                          </strong>
                        </p>
                      </td>
                    </tr>
                  </Table>
                </span>
              </span>
              <span style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>Despesas</h3>
                <span>
                  <Table>
                    <tr>
                      <td>Descrição</td>
                      <td>Part.</td>
                      <td>Valor</td>
                      <td>Total</td>
                    </tr>
                    {reg.content.expense.map((item) => (
                      <tr>
                        <td>{item.text}</td>
                        <td>{item.part}</td>
                        <td>{formatNumber(parseFloat(item.vlr))}</td>
                        <td>{formatNumber(parseFloat(item.vlrtotal))}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4}>
                        <p>
                          Total:&nbsp;
                          <strong style={{ color: '#c53030' }}>
                            R$&nbsp;{formatNumber(totalExpense)}
                          </strong>
                        </p>
                      </td>
                    </tr>
                  </Table>
                </span>
              </span>
              <span>
                <button type="button" onClick={handleDownload}>
                  <FaFileExcel /> <p>Gerar relatório</p>
                </button>
              </span>
            </BodyItem>
          </BodyContainer>
        ) : null}
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() => setShow({ ...show, open: false })}
            >
              Ok
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
      <a
        style={{ display: 'none' }}
        id="report"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_relat_fin_even.php?data=${JSON.stringify({
          svcseq: reg.svcseq,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Relatório Financeiro Eventos
      </a>
    </Container>
  );
};

export default RelatFinEventosCon;
