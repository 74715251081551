import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Checkbox from 'components/Checkbox';
import SelectV2 from 'components/SelectV2';

import { useHistory, useLocation } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import Button from 'components/Button';
import { formatDate } from 'utils/formatDate';
import { Wrapper } from './styles';
import {
  ListProps,
  StateProps,
  StoredValuesProps,
  EndingFormData,
} from '../interfaces';

const AtividadesEncerramento: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { errorHandling } = useCredentials();
  const location = useLocation<StateProps>();
  const history = useHistory();

  const { addToast } = useToast();

  const [activity] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }

    return location.state.activity;
  });

  const [storedValues, setStoredValues] = useState<StoredValuesProps>(() => {
    if (!location.state) {
      return { mode: '', ended: false };
    }

    return { mode: activity.currStat, ended: activity.currStat !== null };
  });

  const options = [
    { value: '', label: 'Selecione' },
    { value: 'N', label: 'Normal' },
    { value: 'P', label: 'Prematuro' },
  ];

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
  }, [history, location.state]);

  const storeCheckboxValue = useCallback(() => {
    const checkbox = document.querySelector<HTMLInputElement>(
      `[data-checkbox="encerramento"]`,
    );

    if (checkbox) {
      setStoredValues((state) => ({
        mode: checkbox.checked ? state.mode : null,
        ended: checkbox.checked,
      }));

      if (!checkbox.checked) {
        const select = formRef.current?.getFieldRef('mode');
        select.value = '';
      }
    }
  }, []);

  const storeComboValue = useCallback(() => {
    const select = formRef.current?.getFieldRef('mode');
    const { value } = select.options[select.selectedIndex];

    setStoredValues((state) => ({ ...state, mode: value }));
  }, []);

  const handleSubmit = useCallback(
    async (data: EndingFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          mode: yup.string().when('ended', {
            is: () => !!storedValues.ended,
            then: yup.string().notOneOf(['Selecione', '']),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...storedValues, seq: activity.seq }),
        );

        await api.post('/sgo/atividades_encerramento.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: '',
          description: 'Oficina atualizada com sucesso!',
        });

        history.goBack();
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [activity.seq, addToast, errorHandling, history, storedValues],
  );

  const pers = useMemo(() => {
    return activity.qtpers ? activity.qtpers : 0;
  }, [activity]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        isResponsible
        noLinks
        // title={`Oficina ${activity?.type}-${activity.seq}`}
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Encerramento</p>
          </div>
          <div>
            <p>
              Oficina {activity?.type}-{activity.seq}
            </p>
          </div>
        </AlteredHeader>

        <Wrapper>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <section>
              <span>
                <p>
                  Coord: <strong>{activity.locdesc}</strong>
                </p>
              </span>
              <span>
                <p>
                  Local: <strong>{activity.prqname}</strong>
                </p>
              </span>
              <span>
                <p>
                  Abertura:&nbsp;
                  <strong>{formatDate(activity.dtini)}</strong>
                </p>
                <p>
                  Fim:&nbsp;
                  <strong>{formatDate(activity.dtfim)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Dia da Semana: <strong>{activity.diasem}</strong>
                </p>

                <p>
                  Horário: <strong>{activity.hora}</strong>
                </p>
              </span>
              <span>
                <p>
                  Guia(s):&nbsp;
                  <strong>{activity.gnome1}</strong>
                  {activity.gnome2 && <strong> / {activity.gnome2}</strong>}
                </p>
              </span>
              <span>
                <p>
                  Cadastrados:&nbsp;
                  <strong>{activity.qtpart}</strong>
                </p>
                <p>
                  Medição:&nbsp;
                  <strong>{activity.qt2sess || '-'}</strong>
                </p>
                <p>
                  Perseverantes:&nbsp;
                  <strong>{activity.qtpers}</strong>
                </p>
              </span>
            </section>
            <section>
              <span>
                <p>Desejo encerrar a oficina</p>
                <Checkbox
                  id={`N${activity.seq}`}
                  title=""
                  onClick={storeCheckboxValue}
                  dataInfo="encerramento"
                  checked={storedValues.ended}
                  containerStyle={{ width: '50px' }}
                />
              </span>
            </section>
            <section>
              <span>
                <p>Modo de encerramento:</p>
                <SelectV2
                  name="mode"
                  content={
                    pers > 0
                      ? options
                      : options.filter((item) => item.value !== 'N')
                  }
                  initial={storedValues.mode ? activity.currStat : 'Selecione'}
                  onChange={storeComboValue}
                  disabled={!storedValues.ended}
                />
              </span>
            </section>

            <Button bgcolor="#008a2b" type="submit">
              Encerrar
            </Button>
          </Form>
        </Wrapper>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AtividadesEncerramento;
