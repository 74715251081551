import React from 'react';

import { FaHammer, FaHardHat, FaPaintRoller } from 'react-icons/fa';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import { Container, Content } from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import { SVGContainer } from './styles';

export const PageDEV: React.FC = () => {
  return (
    <Container>
      <SGOHeader />
      <SGONavbar />
      <Content>
        <h2>Esta funcionalidade está em desenvolvimento.</h2>
        <h3>Agradecemos sua compreensão.</h3>
        <SVGContainer>
          <FaHammer /> <FaHardHat /> <FaPaintRoller />
        </SVGContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default PageDEV;
