import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';
import { useSpring } from 'react-spring';
import { Container, Content, Radio, Error, ClearContainer } from './styles';

export interface ContentProps {
  id: string;
  value: string | number;
  label: string;
  icon?: React.ComponentType<IconBaseProps>;
}

interface RadioProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  name: string;
  content: ContentProps[];
  selected?: string;
  containerStyle?: object;
  itemsStyle?: object;
  errorStyle?: object;
  isDisabled?: boolean;
  asColumn?: boolean;
  firstSelected?: boolean;
  hasClear?: boolean;
  clearCallback?: (string?: string) => void;
}

const RadioContainer: React.FC<RadioProps> = ({
  title = '',
  name = 'radio',
  content = [],
  selected = '',
  containerStyle = {},
  itemsStyle = {},
  errorStyle = {},
  isDisabled = false,
  asColumn = false,
  firstSelected = false,
  hasClear = false,
  clearCallback,
  ...rest
}) => {
  const radioRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error } = useField(name);
  const [value, setValue] = useState(() => {
    return selected || '';
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: radioRef.current,
      path: 'value',
    });

    const check = radioRef.current;
    if (selected) {
      if (check) {
        check.value = selected;
        return;
      }
    }
    if (check) {
      check.value = '';
    }
  }, [fieldName, registerField, selected]);

  const handleSelect = useCallback((val: string) => {
    const check = radioRef.current;
    if (check) {
      setValue(val);
      check.value = val;
    }
  }, []);

  const clearSelection = useCallback(() => {
    const check = radioRef.current;
    if (check) {
      check.value = '';
    }

    setValue('');
  }, []);

  const handleClear = useCallback(() => {
    clearSelection();
    if (clearCallback) {
      clearCallback();
    }
  }, [clearCallback, clearSelection]);

  const styledClear = useSpring({
    opacity: (hasClear || !!clearCallback) && value !== '' ? 1 : 0,
    transform:
      (hasClear || !!clearCallback) && value !== ''
        ? 'translateX(0px)'
        : 'translateX(-25px)',
  });

  return (
    <Container hasError={!!error} style={containerStyle} {...rest}>
      <h3>
        {title}
        {error && (
          <Error title={error} errorStyle={errorStyle}>
            <FaExclamationTriangle color="#c53030" size={20} />
          </Error>
        )}
      </h3>
      <Content style={itemsStyle} asColumn={asColumn}>
        {content.map((item, index) => (
          <Radio
            key={`${item.id}`}
            selected={value === item.value || (firstSelected && index === 0)}
            onClick={() => handleSelect(`${item.value}`)}
            isDisabled={isDisabled}
          >
            <input type="radio" name={name} ref={radioRef} />
            <label htmlFor={item.label}>
              {item.icon && <item.icon />}
              {item.label}
            </label>
          </Radio>
        ))}

        <ClearContainer style={styledClear}>
          <button type="button" onClick={handleClear}>
            <FaTimesCircle />
          </button>
        </ClearContainer>
      </Content>
    </Container>
  );
};

export default RadioContainer;
