import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useHistory } from 'react-router-dom';
import localStorageItems from 'hooks/localStorageItems';

import { Container, Content } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Loading from 'components/Loading';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import Button from 'components/Button';
import { ProfileChangeContainer, ProfileChangeItem } from './styles';

interface ValueProps {
  value: string;
  label: string;
  anb?: string;
}

interface FormData {
  perfil: string;
  zonal: string;
  nacional: string;
  local: string;
}

const ADMIN: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const { user, updateADM } = useAuth();
  const { errorHandling } = useCredentials();
  const formRef = useRef<FormHandles>(null);

  const profiles = [
    {
      value: 'GUI',
      label: 'Guia',
    },
    {
      value: 'LOC',
      label: 'Local',
    },
    {
      value: 'NAC',
      label: 'Nacional',
    },
    {
      value: 'ZON',
      label: 'Zonal',
    },
    {
      value: 'INT',
      label: 'Internacional',
    },
  ];

  const [firstProfile, setFirstProfile] = useState(user.perfalt);
  const [initZon, setInitZon] = useState<ValueProps[]>([]);
  const [firstNac, setFirstNac] = useState(user.anbc);
  const [initNac, setInitNacs] = useState<ValueProps[]>([]);
  const [filteredNac, setFilterNacs] = useState<ValueProps[]>([]);
  const [firstLoc, setFirstLoc] = useState(user.loccod);
  const [initLoc, setInitLocs] = useState<ValueProps[]>([]);
  const [filteredLoc, setFilterLocs] = useState<ValueProps[]>([]);

  // const [getNacional, setNacional] = useState<ValueProps[]>([]);
  // const [getLocal, setLocal] = useState<ValueProps[]>([]);

  const getCombos = useCallback(async () => {
    try {
      const response = await api.get('/adm/adm_combos.php');
      const { zonais, nacionais, locais } = response.data;

      if (zonais) {
        const zons = zonais.map((zon: ValueProps) => {
          return {
            value: zon.value,
            label: zon.label,
          };
        });
        setInitZon(zons);
      }

      if (nacionais) {
        const nacs = nacionais
          .filter(
            (nac: ValueProps) =>
              nac.value.substr(0, 2) === user.zoncod.substr(0, 2),
          )
          .map((nac: ValueProps) => {
            return {
              value: nac.value,
              label: nac.label,
            };
          });
        setInitNacs(nacionais);
        setFilterNacs(nacs);
      }

      if (locais) {
        const locs = locais
          .filter((loc: ValueProps) => loc.anb === user.anbc)
          .map((loc: ValueProps) => {
            return {
              value: loc.value,
              label: loc.label,
            };
          });
        setInitLocs(locais);
        setFilterLocs(locs);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, user]);

  useEffect(() => {
    getCombos();
    const checkForm = formRef.current;
    if (checkForm) {
      checkForm.setData({
        perfil: user.perfil,
        zonal: user.zoncod,
        nacional: user.anbc,
        local: user.loccod,
      });
    }
  }, [getCombos, user]);

  const handleSubmit = useCallback(
    async ({ perfil, zonal, nacional, local }: FormData) => {
      setLoading(true);
      try {
        localStorageItems.forEach((element) => {
          localStorage.removeItem(element);
        });
        const data = {
          perfil,
          zonal,
          nacional,
          local,
        };

        // const response = await api.get(
        //   `/adm/update_profile.php?data=${JSON.stringify(send)}`,
        // );

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        const response = await api.post('/adm/update_profile.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const { usuario, token } = response.data;

        const updateUser = {
          ...user,
          ...usuario,
        };

        updateADM(updateUser, token);

        history.replace('/sgo');
      } catch (err) {
        errorHandling(err);
      }
      setLoading(false);
    },
    [errorHandling, updateADM, history, user],
  );

  const handleBlur = useCallback(
    (mode: string) => {
      const checkForm = formRef.current;

      if (checkForm) {
        const perf = checkForm.getFieldValue('perfil');
        const zon = checkForm.getFieldValue('zonal');
        const nac = checkForm.getFieldValue('nacional');

        if (mode === 'perf') {
          const newPerf = profiles.filter(
            (item: ValueProps) => item.value === perf,
          );
          setFirstProfile(newPerf[0].label);
          checkForm.setData({
            ...checkForm.getData(),
            perfil: perf,
          });
          return;
        }
        if (mode === 'zon') {
          const newNacs = initNac.filter(
            (nacional: ValueProps) =>
              nacional.value.substr(0, 2) === zon.substr(0, 2),
          );

          const sameNac = newNacs.findIndex(
            (item: ValueProps) => item.value === user.anbc,
          );

          setFirstNac(newNacs[sameNac > -1 ? sameNac : 0].label);
          setFilterNacs(newNacs);

          const newLocs = initLoc.filter(
            (local: ValueProps) =>
              local.anb === newNacs[sameNac > -1 ? sameNac : 0].value,
          );

          const sameLoc = newLocs.findIndex(
            (item: ValueProps) => item.value === user.loccod,
          );
          setFirstLoc(newLocs[sameLoc > -1 ? sameLoc : 0].label);
          setFilterLocs(newLocs);

          checkForm.setData({
            ...checkForm.getData(),
            nacional: newNacs[sameNac > -1 ? sameNac : 0].value,
            local: newLocs[sameLoc > -1 ? sameLoc : 0].value,
          });

          return;
        }

        if (mode === 'nac') {
          const newLocs = initLoc.filter(
            (local: ValueProps) => local.anb === nac,
          );

          const sameLoc = newLocs.findIndex(
            (item: ValueProps) => item.value === user.loccod,
          );
          setFirstLoc(newLocs[sameLoc > -1 ? sameLoc : 0].label);
          setFilterLocs(newLocs);

          checkForm.setData({
            ...checkForm.getData(),
            local: newLocs[sameLoc > -1 ? sameLoc : 0].value,
          });
        }
      }
    },
    [initNac, initLoc, user.loccod, user.anbc, profiles],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <ProfileChangeContainer>
          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <ProfileChangeItem>
              <h3>Perfil</h3>
              <SelectV2
                name="perfil"
                initial={firstProfile}
                content={profiles}
                onChange={() => handleBlur('perf')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              />
              {/* <Select
                name="perfil"
                initial={firstProfile}
                content={profiles}
                onBlur={() => handleBlur('perf')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              /> */}
            </ProfileChangeItem>
            <ProfileChangeItem>
              <h3>Coord. Zonal</h3>
              <SelectV2
                name="zonal"
                initial={user.zoncod}
                content={initZon}
                onChange={() => handleBlur('zon')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              />
              {/* <Select
                name="zonal"
                initial={user.zondesc}
                content={initZon}
                onBlur={() => handleBlur('zon')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              /> */}
            </ProfileChangeItem>
            <ProfileChangeItem>
              <h3>Coord. Nacional</h3>
              {/* <Select
                name="nacional"
                initial={firstNac}
                content={filteredNac}
                onBlur={() => handleBlur('nac')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              /> */}
              <SelectV2
                name="nacional"
                initial={firstNac}
                content={filteredNac}
                onChange={() => handleBlur('nac')}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              />
            </ProfileChangeItem>
            <ProfileChangeItem>
              <h3>Coord. Local</h3>
              {/* <Select
                name="local"
                initial={firstLoc}
                content={filteredLoc}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              /> */}
              <SelectV2
                name="local"
                initial={firstLoc}
                content={filteredLoc}
                containerStyle={{
                  boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                }}
              />
            </ProfileChangeItem>
            <Button type="submit">Redefinir</Button>
          </Form>
        </ProfileChangeContainer>

        {/* <PHPContainer>
          <Link to={`${location.pathname}/php`}>
            <FaPhp />
          </Link>
        </PHPContainer> */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ADMIN;
