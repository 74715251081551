import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';
import Button from 'components/Button';
// import SelectV2 from 'components/SelectV2';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import api from 'services/api';
import * as yup from 'yup';
// import { animated, useSpring } from 'react-spring';
import getValidationErrors from 'utils/getValidationErrors';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

// import { Wrapper, TypeButton } from './styles';
import { Wrapper } from './styles';

interface ComboBib extends ComboProps {
  qtd: number;
}

interface FormData {
  mode: string;
  selectTitle: string;
  inputTitle: string;
  detail: string;
  compl: string;
  qte: string;
}

const BibliotecaInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();

  // const [mode, setMode] = useState('material');
  const [mode] = useState('material');
  // const [materials, setMaterials] = useState<ComboBib[]>([]);

  // const [maxAmount, setMaxAmount] = useState(0);

  // const getComboMAT = useCallback(async () => {
  //   try {
  //     const response = await api.get('/combos/comboBIB.php');

  //     setMaterials(
  //       response.data.map((item: ComboBib) => ({
  //         value: item.value,
  //         label: item.label,
  //         qtd: item.qtd,
  //       })),
  //     );
  //   } catch (err) {
  //     errorHandling(err);
  //   }
  // }, [errorHandling]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);

    // getComboMAT();
  }, [handlePermission]);

  // const setMatTOV = useCallback(() => {
  //   setMode('material');
  // }, []);

  // const setAnother = useCallback(() => {
  //   setMode('not-material');
  // }, []);

  // const handleMatSelection = useCallback(() => {
  //   const select = formRef.current?.getFieldRef('selectTitle');
  //   const { value } = select.options[select.selectedIndex];

  //   const index = materials.findIndex((item) => item.value === value);

  //   setMaxAmount(materials[index].qtd);
  // }, [materials]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          // selectTitle: yup.string().when('mode', {
          //   is: (val) => !val.includes('not'),
          //   then: yup.string().notOneOf(['Selecione', '']),
          //   otherwise: yup.string().nullable(true),
          // }),
          // inputTitle: yup.string().when('mode', {
          //   is: (val) => val.includes('not'),
          //   then: yup
          //     .string()
          //     .trim()
          //     .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          //     .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          //   otherwise: yup.string().nullable(true),
          // }),
          inputTitle: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(255, 'Quantidade máxima de caracteres excedida (255).'),
          // detail: yup.lazy((val) =>
          //   val
          //     ? yup
          //         .string()
          //         .trim()
          //         .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          //         .max(150, 'Quantidade máxima de caracteres excedida (150).')
          //     : yup.string().nullable(true),
          // ),
          compl: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .trim()
                  .min(5, 'Quantidade mínima de caracteres não atingida (5).')
                  .max(255, 'Quantidade máxima de caracteres excedida (255).')
              : yup.string().nullable(true),
          ),
          // qte: yup
          //   .number()
          //   .typeError('Este campo é obrigatório')
          //   .min(1, 'Numeração deve ser igual ou superior a 1.')
          //   .when('mode', {
          //     is: (val) => !val.includes('not'),
          //     then: yup
          //       .number()
          //       .typeError('Este campo é obrigatório')
          //       .max(
          //         maxAmount >= 10 ? 10 : maxAmount,
          //         `Numeração deve ser igual ou inferior a ${
          //           maxAmount >= 10 ? 10 : maxAmount
          //         }`,
          //       ),
          //   }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // const select = formRef.current?.getFieldRef('selectTitle');
        // const title = data.mode.includes('not')
        //   ? data.inputTitle
        //   : select.options[select.selectedIndex].text;
        const title = data.inputTitle;

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            title,
            mode: 'insert',
          }),
        );

        await api.post('/sgo/biblioteca_actions.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // if (!mode.includes('not')) {
        //   setMaterials((state) =>
        //     state.filter((item) => item.value !== data.selectTitle),
        //   );
        // }

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${title} registrado.`,
        });
        formRef.current?.reset();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling],
  );

  // const selectSpan = useSpring({
  //   transform: mode === 'material' ? 'translateX(0px)' : 'translateX(-360px)',
  //   opacity: mode === 'material' ? 1 : 0,
  //   pointerEvents: mode === 'material' ? 'all' : 'none',
  // });

  // const inputSpan = useSpring({
  //   transform:
  //     mode === 'not-material' ? 'translateX(-350px)' : 'translateX(0px)',
  //   opacity: mode === 'not-material' ? 1 : 0,
  //   pointerEvents: mode === 'not-material' ? 'all' : 'none',
  // });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>
              {/* Materiais de Estudo da&nbsp; */}
              {['ZON', 'NAC'].indexOf(user.perfil) > -1
                ? 'Acervo da Equipe'
                : 'Biblioteca da Coordenação'}
            </p>
          </div>
          <div>
            <p>
              {user.perfil === 'ZON'
                ? user.zondesc
                : user.perfil === 'NAC'
                ? user.anbdesc
                : user.locdesc}
            </p>
          </div>
        </AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="mode" value={mode} disabled isHidden />
          <Wrapper>
            {/* <div>
              <p>Tipo de Material</p>
              <span>
                <TypeButton
                  type="button"
                  onClick={setMatTOV}
                  marked={!mode.includes('not')}
                >
                  Material TOV
                </TypeButton>
                <TypeButton
                  type="button"
                  onClick={setAnother}
                  marked={mode.includes('not')}
                >
                  Outros Itens
                </TypeButton>
              </span>
            </div> */}
            <div>
              <p>Título:</p>
              <span>
                {/* <span>
                  <SelectV2
                    name="selectTitle"
                    content={materials}
                    initial="Selecione"
                    onChange={handleMatSelection}
                  />
                </span> */}
                <span>
                  <Input
                    name="inputTitle"
                    placeholder="Título do Material"
                    containerStyle={{ height: '30px' }}
                  />
                </span>
              </span>
            </div>

            {/* <div>
              <p>Detalhes:</p>
              <Input
                name="detail"
                placeholder="Detalhamento do material"
                containerStyle={{ height: '30px' }}
              />
            </div> */}
            <div>
              <p>Complemento:</p>
              <Input
                name="compl"
                placeholder="Complemento do material"
                containerStyle={{ height: '30px' }}
              />
            </div>
            {/* <div>
              <p>Quantidade:</p>
              <Input
                name="qte"
                containerStyle={{ height: '30px', width: '100px' }}
                inputStyle={{ width: '70px', textAlign: 'right' }}
                type="number"
                step="1"
                min="0"
                mask="allowZero"
                placeholder="0"
              />
            </div> */}
          </Wrapper>

          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default BibliotecaInsert;
