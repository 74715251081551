import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { animated } from 'react-spring';

interface ButtonProps {
  bg: string;
}

export const ButtonContainers = styled(animated.div)`
  display: flex;
  width: 360px;
  /* background: #7159c1; */
  align-items: center;
  justify-content: space-around;
`;

export const EPGButton = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${(props) => props.bg};
  border-radius: 5px;

  background: ${(props) => props.bg};
  color: #fff;
  padding: 5px 10px;

  width: 150px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0.25px 0.25px 1.2px #fff;

    transition: text-shadow 0.35s ease;
  }

  svg {
    position: absolute;
    left: 3px;
    height: 20px;
    width: 20px;

    opacity: 0;
    transition: opacity 0.35s ease;
  }

  transition: box-shadow 0.35s ease, border-color 0.35s ease,
    background-color 0.35s ease;
  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    ${(props) => css`
      border-color: ${shade(0.3, `${props.bg}`)};
      background-color: ${shade(0.2, `${props.bg}`)};
    `}

    svg {
      opacity: 1;
    }
    p {
      text-shadow: 0.25px 0.25px 3px #fff;
    }
  }
`;

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 600px;

  /* transition: width 0.5s ease, transform 0.5s ease, margin-right 0.5s ease; */
  @media screen and (max-width: 600px) {
    width: 100vw;
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;

    /* transition: width 0s ease, transform 0.5s ease, margin-right 0.5s ease; */
  }

  /* @media screen and (max-width: 710px) {
    width: 360px;
  }

  @media screen and (max-width: 480px) {
    align-self: left;
    margin-right: 100%;
    transform: translateX(50%);

    border-radius: 0 5px 5px 0;
    border-left-width: 0px;
  }

  @media screen and (max-width: 415px) {
    width: 300px;
  } */
`;

export const Table = styled.table`
  /* margin: 20px auto 50px; */

  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  /* min-width: 600px; */
  width: 100%;

  /* width: 1366px; */
  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    display: flex;
    align-items: center;
    min-height: 40px;
  }

  td {
    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 25px;
      width: 25px;
      padding: 2px;
      border: 0px;
      border-radius: 3px;

      > svg {
        color: #fff;
      }
    }

    padding: 2px 3px;

    > svg {
      height: 19px;
      width: 19px;
      color: #fff;
    }
  }
`;

export const Name = styled.td`
  width: calc(100% - 180px);
  width: calc(100% - 130px);
`;
export const Pers = styled.td`
  width: 100px;

  label {
    margin: 0 auto;
  }
  /* @media screen and (max-width: 415px) {
    width: 50px;
  } */
`;
export const Cons = styled.td`
  text-align: center;
  vertical-align: middle;
  width: 40px;

  button {
    background: #5e5555;

    transition: background-color 0.5s;

    svg {
      height: 15px;
      width: 15px;
    }

    &:hover {
      background: ${shade(0.2, '#5e5555')};
    }
  }
`;
export const Alt = styled.td`
  text-align: center;
  vertical-align: middle;
  width: 40px;

  button,
  a {
    background: #007acc;

    transition: background-color 0.5s;

    svg {
      height: 15px;
      width: 15px;
    }

    &:hover {
      background: ${shade(0.2, '#007acc')};
    }
  }
`;

export const ModalContent = styled.div`
  display: grid;
  width: 100%;

  grid: auto / 1fr 1fr;

  column-gap: 25px;
  row-gap: 25px;

  section {
    display: flex;
    flex-direction: column;
    > span {
      width: 100%;
      padding: 3px 5px;
      border: 2px solid transparent;
      border-bottom-color: #d6d6d6;
      p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        color: #767676;
        svg {
          height: 25px;
          width: 25px;
        }
        strong {
          margin-left: 7px;
          color: #332e2e;
        }

        & + p {
          margin-top: 3px;
        }
      }

      & + span {
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr;
  }
`;
