import styled, { css } from 'styled-components';

interface ContainerProps {
  lower: boolean;
}

export const Container = styled.div<ContainerProps>`
  z-index: 350;
  position: fixed;

  top: 0;

  ${(props) =>
    props.lower &&
    css`
      top: 60px;
    `}
  right: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  padding: 3rem;
  overflow: hidden;

  transition: top 0.35s, right 0.35s;

  @media screen and (max-width: 400px) {
    right: -15px;
  }
`;
