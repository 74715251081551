import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

interface GridItemProps {
  disabled: boolean;
}
export const Grid = styled(animated.div)`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;

export const GridItem = styled.div<GridItemProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px 10px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  width: 360px;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.35s ease;
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      }
    `}

  span {
    display: flex;
    align-items: center;
    div {
      height: 100%;
      width: 30%;
    }

    p {
      color: #767676;
      strong {
        color: #332e2e;
      }
    }
  }

  & + div {
    margin-top: 25px;
  }
`;

export const ExcButton = styled.button`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background-color: transparent;

  p {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.35s ease;
    svg {
      margin-right: 7px;
      height: 20px;
      width: 20px;
      color: #c53030;
      transition: color 0.35s ease;
    }
  }

  &:hover {
    p {
      color: #8a0002;
    }
    svg {
      color: red;
    }
  }
`;

export const AltButton = styled.button`
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background-color: transparent;

  svg {
    height: 15px;
    width: 15px;
    color: #007acc;
    transition: color 0.35s ease;
  }

  &:hover {
    svg {
      color: ${shade(0.2, '#007acc')};
    }
  }
`;
