import React, { HTMLAttributes, useState, useRef, useCallback } from 'react';

import { FaCaretRight } from 'react-icons/fa';
import { Container, HeaderContent, Content } from './styles';

interface AccordeonProps extends HTMLAttributes<HTMLDivElement> {
  name?: string;
  header?: string;
  containerStyle?: object;
  height?: string;
  isOpen?: boolean;
}

const Accordeon: React.FC<AccordeonProps> = ({
  header = 'Clique para expandir',
  containerStyle = {},
  children,
  height = '500px',
  isOpen = false,
}) => {
  const accordeonRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(isOpen);

  const handleOpening = useCallback(() => {
    const check = accordeonRef.current;

    if (check && active) {
      check.scrollTo(0, 0);
    }
    setActive((state) => !state);
  }, [active]);

  return (
    <Container style={containerStyle} height={height} active={active}>
      <HeaderContent onClick={handleOpening}>
        <FaCaretRight />
        <h1>{header}</h1>
      </HeaderContent>
      {React.Children.count(children) > 0 && (
        <Content height={height} ref={accordeonRef}>
          {children}
        </Content>
      )}
    </Container>
  );
};

export default Accordeon;
