import React, { useState, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { Container, ComboProps } from 'styles/sgo_wrappers';
import { FaDownload, FaFileExcel, FaFilePdf } from 'react-icons/fa';

import { MonthListProps, monthList } from 'pages/sgo/Tesouraria/monthList';

import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import { useSpring } from 'react-spring';
import api from 'services/api';
import { useEffect } from 'react';
import {
  AlteredContent,
  DropContainer,
  DropWrapper,
  DropzoneButton,
  FileInfoContainer,
} from './styles';

interface List {
  name: string;
  size: number;
}

const FaturamentoInsert: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [month] = useState<MonthListProps>(() => {
    const index = monthList.findIndex(
      (item: MonthListProps) =>
        item.value === new Date(new Date().toISOString()).getUTCMonth() + 1,
    );
    return { value: monthList[index].value, label: monthList[index].label };
  });
  const [filelist, setFileList] = useState<List[]>([]);
  const [zons, setZons] = useState<string[]>([]);
  const [anbs, setAnbs] = useState<string[]>([]);

  const { addToast } = useToast();
  const { errorHandling } = useCredentials();

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setZons(response.data.map((item: ComboProps) => item.value));
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setAnbs(response.data.map((item: ComboProps) => item.value));
    setLoading(false);
  }, []);

  useEffect(() => {
    getComboZON();
    getComboANB();
  }, [getComboANB, getComboZON]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleFiles = useCallback(
    (files: FileList) => {
      setFileList([]);
      let amount = 0;
      const asArray = Array.from(files);
      asArray.map((item) => {
        const filename = item.name;

        if (filename.substr(-3) !== 'pdf') {
          addToast({
            type: 'error',
            title: `Arquivo ${filename} não suportado`,
          });
          return false;
        }

        if (amount > 19) {
          addToast({
            type: 'error',
            title: `Arquivo ${filename} excedeu a quantidade de arquivos.`,
            seconds: 10,
          });
          return false;
        }
        amount += 1;
        setFileList((state) => [...state, { name: filename, size: item.size }]);
        return true;
      });
    },
    [addToast],
  );

  const fileSize = (size: number): string => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const handleFileDrop = useCallback(
    (e) => {
      e.preventDefault();
      const { files } = e.dataTransfer;
      setFileList(files);

      const input = inputRef.current;

      if (input) {
        input.files = files;
      }

      if (files.length > 0) {
        handleFiles(files);
      }
    },
    [handleFiles],
  );

  const handleFileSelected = useCallback(() => {
    const input = inputRef.current;

    if (input && input.files) {
      setFileList([]);
      handleFiles(input.files);
    }
  }, [handleFiles]);

  const handleUpload = useCallback(async () => {
    try {
      setLoading(true);
      const input = inputRef.current;
      const send = new FormData();

      if (!!input && !!input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          // send.append('files', input.files[i]);
          send.append('files[]', input.files[i], input.files[i].name);
        }

        const init = new Date().getTime();
        await api.post('/fechamento/file_upload.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const finish = new Date().getTime();

        const seconds = Math.floor((finish - init) / 1000);

        // setFileList({} as FileList);
        // const input = inputRef.current;

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `Registros incluídos em ${seconds} segundos`,
        });

        setLoading(false);
        input.value = '';
        setFileList([]);
      }
    } catch (err) {
      // setFileList({} as FileList);
      const input = inputRef.current;

      if (input) {
        input.value = '';
      }
      setLoading(false);
      errorHandling(err, err.response.data.message);
    }
  }, [addToast, errorHandling]);

  const handleClick = useCallback(() => {
    const input = inputRef.current;

    if (input) {
      input.value = '';
      input.click();
    }
  }, []);

  const handleDownloads = useCallback(async () => {
    try {
      setLoading(true);

      const sendNac = new FormData();
      sendNac.append(
        'data',
        JSON.stringify({
          anbs,
        }),
      );

      await api.post('/fechamento/xls_fatura_nac.php', sendNac, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const sendZon = new FormData();
      sendZon.append(
        'data',
        JSON.stringify({
          zons,
        }),
      );
      await api.post('/fechamento/xls_fatura_zon.php', sendZon, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [anbs, errorHandling, zons]);

  const animatedButton = useSpring({
    opacity: filelist.length > 0 ? 1 : 0,
    pointerEvents: filelist.length > 0 ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Faturamento ${month.label} | ${new Date().getUTCFullYear()}`}
        isResponsible
      />
      <AlteredContent>
        <div>
          <Button
            type="button"
            style={{ margin: '10px 0 0 0' }}
            bgcolor="#464646"
            onClick={handleDownloads}
          >
            <FaFileExcel />
            <p> Armazenar relatórios </p>
          </Button>
        </div>
        <DropWrapper>
          <DropContainer
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleFileDrop}
            onClick={handleClick}
          >
            <input
              ref={inputRef}
              type="file"
              onChange={handleFileSelected}
              multiple
            />
            <FaDownload />
            <p style={{ fontWeight: 500 }}>
              Arraste o(s) arquivo(s) aqui ou clique para importar
            </p>
            <p style={{ color: '#767676' }}>(Máx. 20 arquivos)</p>
          </DropContainer>
        </DropWrapper>

        <FileInfoContainer>
          {filelist.map((item) => (
            <span>
              <FaFilePdf />
              <p>{item.name}</p>
              <p>({fileSize(item.size)})</p>
            </span>
          ))}
          {/* {filelist.length > 0 && (
            <>
              <FaFileCsv />
              <p>{filelist[0].name}</p>
              <p>({fileSize(filelist[0].size)})</p>
            </>
          )} */}
        </FileInfoContainer>
        <DropzoneButton style={animatedButton} onClick={handleUpload}>
          Upload
        </DropzoneButton>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default FaturamentoInsert;
