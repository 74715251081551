export function formatNumber(number: number): string {
  let value = 0;
  if (number) {
    value = number;
  }
  return value
    .toFixed(2)
    .toLocaleString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function calcTotal(qtde: number, price: number): string {
  const total = (qtde * price).toFixed(2).replace('.', ',');

  return total;
}
