import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  max-width: 600px;
  margin: 25px auto;

  align-items: center;
  justify-content: center;

  padding: 0 0 25px 0;

  border: 3px solid #efefef;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    border-width: 3px 0;
  }

  form {
    display: flex;
    width: 100%;
    section {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }

      &:nth-child(1) {
        padding: 0 0 0 5px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
          font-weight: unset;
          font-size: 16px;
          color: #767676;
          strong {
            color: #332e2e;
          }

          &::first-letter {
            text-transform: capitalize;
          }
        }

        span {
          display: flex;
          p + p {
            margin: 0 0 0 10px;
          }

          & + span {
            margin: 5px 0 0 0;
          }
        }
      }

      &:nth-last-child(3) {
        span {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          /* background: #fce205; */
        }
      }

      &:nth-last-child(2) {
        margin: 10px 0;
        align-items: center;
        flex-direction: column;
        /* background: #c53030; */
      }
    }
  }
`;
