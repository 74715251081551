import React, { useState, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
// import Button from 'components/Button';

import { useCredentials } from 'hooks/credentials';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
// import { FaFileExcel } from 'react-icons/fa';

import { MonthListProps, monthList } from 'pages/sgo/Tesouraria/monthList';
import { useEffect } from 'react';

import api from 'services/api';
import { formatNumber } from 'utils/calcTotal';

import { Grid, GridItem } from './styles';

interface ListProps {
  cod: string;
  desc: string;
  cnpj: string;
  vlr: number;
}

const Faturamento: React.FC = () => {
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [month] = useState<MonthListProps>(() => {
    const index = monthList.findIndex(
      (item: MonthListProps) =>
        item.value === new Date(new Date().toISOString()).getUTCMonth() + 1,
    );
    return { value: monthList[index].value, label: monthList[index].label };
  });

  const [list, setList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/fechamento/fechamento_list.php');

      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getList();
  }, [getList]);

  // const handleDownloads = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const anbs = [
  //       ...list
  //         .filter((item) => item.cod.substr(2, 2) !== '99')
  //         .map((item) => item.cod),
  //     ];

  //     const zons = [
  //       ...list
  //         .filter((item) => item.cod.substr(2, 2) === '99')
  //         .map((item) => item.cod),
  //     ];

  //     const sendNac = new FormData();
  //     sendNac.append(
  //       'data',
  //       JSON.stringify({
  //         anbs,
  //         mes: month.label,
  //       }),
  //     );
  //     await api.post('/fechamento/xls_fatura_nac.php', sendNac, {
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //     });

  //     const sendZon = new FormData();
  //     sendZon.append(
  //       'data',
  //       JSON.stringify({
  //         zons,
  //         mes: month.label,
  //       }),
  //     );
  //     await api.post('/fechamento/xls_fatura_zon.php', sendZon, {
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //     });
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     errorHandling(err);
  //   }
  // }, [errorHandling, list, month.label]);

  return (
    <Container>
      <IncludeButton />
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Faturamento</p>
          </div>
          <div>
            <p>
              {month.label} | {new Date().getUTCFullYear()}
            </p>
          </div>
        </AlteredHeader>
        {/* <div>
          <Button
            type="button"
            style={{ margin: '10px 0 0 0' }}
            bgcolor="#464646"
            onClick={handleDownloads}
          >
            <FaFileExcel />
            <p> Armazenar relatórios </p>
          </Button>
        </div> */}
        <Grid>
          {list.map((item) => (
            <GridItem key={item.cod}>
              <h2>{item.desc}</h2>
              <p>
                CNPJ:&nbsp;<strong>{item.cnpj || '-'}</strong>
              </p>
              <p>
                Valor:&nbsp;<strong>R$ {formatNumber(item.vlr)}</strong>
              </p>
            </GridItem>
          ))}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Faturamento;
