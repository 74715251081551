import React, { ButtonHTMLAttributes } from 'react';

import { useScrolling } from 'hooks/scrolling';

import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  containerStyle?: object;
  bgcolor?: string;
  svgcolor?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  customwidth?: string;
  inverticon: boolean | string;
  icon?: React.ComponentType<IconBaseProps>;
}

const FloatButton: React.FC<ButtonProps> = ({
  containerStyle,
  bgcolor = '#5e5555',
  svgcolor = '#5e5555',
  left,
  right,
  bottom,
  top,
  customwidth,
  inverticon = false,
  icon: Icon,
  ...rest
}) => {
  const { scrolled } = useScrolling();

  return (
    <Container
      amountscrolled={scrolled}
      bgcolor={bgcolor}
      svgcolor={svgcolor}
      style={containerStyle}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      customwidth={customwidth}
      inverticon={!!inverticon}
      type="button"
      {...rest}
    >
      {Icon && (
        <>
          <Icon />
        </>
      )}
    </Container>
  );
};

export default FloatButton;
