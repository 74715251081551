import DEV from 'pages/DEV';
import FPG from 'pages/sgo/Tesouraria/FPG/main';
import FPGInsert from 'pages/sgo/Tesouraria/FPG/insert';
import FPGUpdate from 'pages/sgo/Tesouraria/FPG/update';
import MB from 'pages/sgo/Tesouraria/Movimento/main';
import MBInsert from 'pages/sgo/Tesouraria/Movimento/insert';
import MBUpdate from 'pages/sgo/Tesouraria/Movimento/update';

import AplicInsert from 'pages/sgo/Tesouraria/Aplic/insert';
import AplicUpdate from 'pages/sgo/Tesouraria/Aplic/update';
import AplicConfig from 'pages/sgo/Tesouraria/Aplic/config';
import AplicGeral from 'pages/sgo/Tesouraria/Aplic/geral';

import CPR from 'pages/sgo/Tesouraria/CPR/main';
import CPRInsert from 'pages/sgo/Tesouraria/CPR/insert';
import CPRUpdate from 'pages/sgo/Tesouraria/CPR/update';

import FinRM from 'pages/sgo/Tesouraria/FinRM/main';
import FinRMDetail from 'pages/sgo/Tesouraria/FinRM/detail';

import FinGD from 'pages/sgo/Tesouraria/FinGD/main';
import FinGDDetail from 'pages/sgo/Tesouraria/FinGD/detail';

import FinCE from 'pages/sgo/Tesouraria/FinCE/main';
import FinCEDetail from 'pages/sgo/Tesouraria/FinCE/detail';
import FinCEInsert from 'pages/sgo/Tesouraria/FinCE/insert';

import F2Mensal from 'pages/sgo/Tesouraria/InformeF2/Mensal';

import TipoLanc from 'pages/sgo/Tesouraria/TipoLanc/main';
import TipoLancInsert from 'pages/sgo/Tesouraria/TipoLanc/insert';
import TipoLancUpdate from 'pages/sgo/Tesouraria/TipoLanc/update';

import OfertaMenu from 'pages/sgo/Tesouraria/Oferta/menu';
import Ofertas from 'pages/sgo/Tesouraria/Oferta/main';
import OfertasDetail from 'pages/sgo/Tesouraria/Oferta/detail';
import OfertasRepasse from 'pages/sgo/Tesouraria/Oferta/repasse';
import OfertasRepasseInsert from 'pages/sgo/Tesouraria/Oferta/repasse/insert';

import Patrimonio from 'pages/sgo/Tesouraria/Patrimonio/main';

import PlanejamentoMenu from 'pages/sgo/Tesouraria/Planejamento/menu';
import Planejamento from 'pages/sgo/Tesouraria/Planejamento/main';
import PlanejamentoConsult from 'pages/sgo/Tesouraria/Planejamento/consult';
import PlanejamentoUpdate from 'pages/sgo/Tesouraria/Planejamento/update';

import Faturamento from 'pages/sgo/Tesouraria/Faturamento/main';
import FaturamentoInsert from 'pages/sgo/Tesouraria/Faturamento/insert';

import DoacaoEspecifica from 'pages/sgo/Tesouraria/DoacaoEspecifica/main';

import ReceitasDespesas from 'pages/sgo/Tesouraria/ReceitasDespesas/main';
import ReceitasDespesasInsert from 'pages/sgo/Tesouraria/ReceitasDespesas/insert';
import ReceitasDespesasUpdate from 'pages/sgo/Tesouraria/ReceitasDespesas/update';

const TRoutes = [
  { path: `/fpg`, isPrivate: true, component: FPG },
  { path: `/fpg/insert`, isPrivate: true, component: FPGInsert },
  { path: `/fpg/update`, isPrivate: true, component: FPGUpdate },
  { path: `/movimentos`, isPrivate: true, component: MB },
  { path: `/movimentos/insert`, isPrivate: true, component: MBInsert },
  { path: `/movimentos/update`, isPrivate: true, component: MBUpdate },
  { path: `/appl/insert`, isPrivate: true, component: AplicInsert },
  { path: `/appl/update`, isPrivate: true, component: AplicUpdate },
  { path: `/aplic`, isPrivate: true, component: AplicConfig },
  { path: `/aplic/geral`, isPrivate: true, component: AplicGeral },
  { path: `/cpr`, isPrivate: true, component: CPR },
  { path: `/cpr/insert`, isPrivate: true, component: CPRInsert },
  { path: `/cpr/update`, isPrivate: true, component: CPRUpdate },
  { path: `/contfinrm`, isPrivate: true, component: FinRM },
  { path: `/contfinrm/detail`, isPrivate: true, component: FinRMDetail },
  { path: `/contfingd`, isPrivate: true, component: FinGD },
  { path: `/contfingd/detail`, isPrivate: true, component: FinGDDetail },
  { path: `/contfince`, isPrivate: true, component: FinCE },
  { path: `/contfince/detail`, isPrivate: true, component: FinCEDetail },
  {
    path: `/contfince/detail/insert`,
    isPrivate: false,
    component: FinCEInsert,
  },
  { path: `/f2mensal`, isPrivate: true, component: F2Mensal },
  { path: `/tipolanc`, isPrivate: true, component: TipoLanc },
  { path: `/tipolanc/insert`, isPrivate: true, component: TipoLancInsert },
  { path: `/tipolanc/update`, isPrivate: true, component: TipoLancUpdate },
  { path: `/oferta/menu`, isPrivate: true, component: OfertaMenu },
  { path: `/oferta`, isPrivate: true, component: Ofertas },
  { path: `/oferta/detail`, isPrivate: true, component: OfertasDetail },
  { path: `/oferta/repasse`, isPrivate: true, component: OfertasRepasse },
  {
    path: `/oferta/repasse/insert`,
    isPrivate: true,
    component: OfertasRepasseInsert,
  },
  { path: `/patrimonio`, isPrivate: true, component: Patrimonio },
  { path: `/planejamento/menu`, isPrivate: true, component: PlanejamentoMenu },
  { path: `/planejamento`, isPrivate: true, component: Planejamento },
  {
    path: `/planejamento/consult`,
    isPrivate: true,
    component: PlanejamentoConsult,
  },
  {
    path: `/planejamento/update`,
    isPrivate: true,
    component: PlanejamentoUpdate,
  },
  { path: `/faturamento`, isPrivate: true, component: Faturamento },
  {
    path: `/faturamento/comprovante`,
    isPrivate: true,
    component: FaturamentoInsert,
  },
  {
    path: `/doacao_especifica`,
    isPrivate: true,
    component: DoacaoEspecifica,
  },
  {
    path: '/rec_desp_relat_eventos',
    isPrivate: true,
    component: ReceitasDespesas,
  },
  {
    path: '/rec_desp_relat_eventos/insert',
    isPrivate: true,
    component: ReceitasDespesasInsert,
  },
  {
    path: '/rec_desp_relat_eventos/update',
    isPrivate: true,
    component: ReceitasDespesasUpdate,
  },

  // #DEV
  { path: `/contfindm`, isPrivate: true, component: DEV },
];

export default TRoutes;
