import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface GridItem {
  active: boolean;
}

export const Title = styled.h1`
  font-size: 16px;

  margin: 10px auto;

  z-index: 1;

  padding: 5px;
  width: 100%;
  text-align: center;

  text-shadow: 0px 0px 5px #8a0002;
  color: #efefef;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(197, 48, 48, 0.25),
    rgba(197, 48, 48, 0.5),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(197, 48, 48, 0.5),
    rgba(197, 48, 48, 0.25),
    rgba(255, 255, 255, 0)
  );
  text-align: center;
`;

export const Grid = styled.div`
  padding: 25px 0;
  width: 90vw;
  max-width: 1300px;

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div<GridItem>`
  position: relative;
  width: auto;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 3px solid #8f0002;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: ${shade(0.2, '#8f0002')};
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #00802b;

      &:hover {
        border-color: ${shade(0.25, '#00802b')};
      }
    `};
`;

export const GridInfo = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  p {
    font-size: 14px;
  }
`;
