import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';

import {
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { removeAcento } from 'utils/specialChars';
import { FaSearch } from 'react-icons/fa';
import { GridContainer, GridItem } from './styles';

export interface ListProps {
  dmnum: number;
  loc: string;
  locdesc: string;
  data: string;
  value: number;
  status: string;
  obs: string[];
  dmyear: string;
}
// export interface ListProps {
//   anbcod: string;
//   anbdesc: string;
//   loccod: string;
//   locdesc: string;
//   rmnum: number;
//   data: string;
//   valor: number;
//   status: string;
//   statDesc: string;
//   solnum: number;
//   soldata: string;
//   financregs: number;
//   rmyear: string;
//   obs: string[];
//   addressval: string;
//   address: {
//     street: string;
//     district: string;
//     city: string;
//     state: string;
//     cep: string;
//   };
// }

interface LocalStorageProps {
  stat?: string;
  year?: string;
}

const DM: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [statDM, setStatDM] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_DM}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_DM}`,
      );

      if (stat) {
        return stat;
      }

      return 'E';
    }

    return 'E';
  });

  const [years, setYears] = useState<ComboProps[]>([]);
  const [yearSelected, setYearSelected] = useState(() => {
    const current = JSON.stringify(
      new Date(new Date().toISOString()).getFullYear(),
    );

    const hasItem = localStorageLength(`${process.env.REACT_APP_DM}`);

    if (hasItem > 0) {
      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_DM}`,
      );

      if (year) {
        return year;
      }

      return current;
    }

    return current;
  });

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/dm_list.php');

      const { fetchlist, year } = response.data;

      const filtered = fetchlist.filter((item: ListProps) =>
        user.perfil === 'LOC' ? item.loc === user.loccod : item,
      );

      setYears(year);
      setRawList(filtered);

      const initYEAR = formRef.current?.getFieldValue('filterYears');

      setList(
        filtered.filter(
          (item: ListProps) =>
            (statDM === 'E'
              ? ['A', 'E'].indexOf(item.status) > -1
              : item.status === statDM) && item.dmyear === initYEAR,
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, statDM, user.loccod, user.perfil]);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    getList();
  }, [handlePermission, getList]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const stat = formRef.current?.getFieldValue('filterStat');
      const year = formRef.current?.getFieldValue('filterYears');
      const filtered = rawList.filter((item: ListProps) => {
        return (
          (item.dmnum.toString().includes(val) ||
            removeAcento(item.locdesc)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase())) &&
          (stat === 'E'
            ? ['A', 'E'].indexOf(item.status) > -1
            : item.status === stat) &&
          item.dmyear === year
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('filterStat');

    setSearchValue('');
    setStatDM(stat);
    setLocalStorage(`${process.env.REACT_APP_DM}`, { stat });

    const year = formRef.current?.getFieldValue('filterYears');

    setList(
      rawList.filter(
        (item: ListProps) =>
          (user.perfil === 'LOC' ? item.loc === user.loccod : item) &&
          item.dmyear === year &&
          (stat === 'E'
            ? ['A', 'E'].indexOf(item.status) > -1
            : item.status === stat),
      ),
    );
  }, [rawList, user.loccod, user.perfil]);

  const handleYearSelect = useCallback(() => {
    const year = formRef.current?.getFieldValue('filterYears');
    setYearSelected(year);
    setSearchValue('');
    setLocalStorage(`${process.env.REACT_APP_DM}`, { year });

    const stat = formRef.current?.getFieldValue('filterStat');

    setList(
      rawList.filter(
        (item: ListProps) =>
          (user.perfil === 'LOC' ? item.loc === user.loccod : item) &&
          item.dmyear === year &&
          item.status === stat,
      ),
    );
  }, [rawList, user.loccod, user.perfil]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'NAC' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['NAC', 'LOC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              <RadioContainer
                title="Devoluções de Materiais:"
                onClick={handleRadioClick}
                name="filterStat"
                content={[
                  {
                    id: 'eDM',
                    value: 'E',
                    label: 'Emitidas',
                  },
                  {
                    id: 'pDM',
                    value: 'P',
                    label: 'Abatidas',
                  },
                  {
                    id: 'cDM',
                    value: 'C',
                    label: 'Canceladas',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={statDM}
              />
              <span style={{ marginTop: '10px' }}>
                <p>Ano de Emissão:</p>
                <SelectV2
                  name="filterYears"
                  content={years}
                  initial={yearSelected}
                  onChange={handleYearSelect}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>Devolução de Material</Header>
      {user.perfil === 'NAC' && (
        <FloatInput amount="100px">
          <input
            placeholder="Filtrar por numeração ou coordenação"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={user.perfil !== 'NAC' ? '231px' : '280px'}>
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem
              key={item.dmnum}
              bordercolor={
                item.status === 'C'
                  ? '#c53030'
                  : ['E', 'A'].indexOf(item.status) > -1
                  ? '#2156e6'
                  : '#00802b'
              }
            >
              <p>
                N° {item.dmnum} |&nbsp;
                {formatDate(item.data)}
              </p>
              <span>
                <p>
                  Valor da DM:&nbsp;
                  <strong>R$ {formatNumber(item.value)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>
                    {item.status === 'P'
                      ? 'Abatida'
                      : ['E', 'A'].indexOf(item.status) > -1
                      ? item.status === 'E'
                        ? 'Emitida'
                        : 'Em abatimento'
                      : 'Cancelada'}
                  </strong>
                </p>
              </span>
              {user.perfil === 'NAC' && item.loc !== user.anbc && (
                <span>
                  <p>
                    Coord:&nbsp;
                    <strong>{item.locdesc}</strong>
                  </p>
                </span>
              )}
              {/* {user.perfil === 'NAC' && item.loc === user.anbc && (
                <span>
                  <p>
                    Emitida por :&nbsp;
                    <strong>{item.anbdesc}</strong>
                  </p>
                </span>
              )} */}

              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { dm: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {/* {item.status !== 'C' && item.value > 0 && (
                  <O.Financ
                    to={{
                      pathname: '/sgo/tesouraria/contfinrm/detail',
                      state: {
                        finHeader: {
                          num: item.dmnum,
                          cod: item.loc,
                          desc: item.locdesc,
                          date: item.data,
                          valor: item.value,
                        },
                      },
                    }}
                  >
                    <FaDollarSign />
                  </O.Financ>
                )} */}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default DM;
