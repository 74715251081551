import styled, { css, keyframes } from 'styled-components';

interface LabelProps {
  isChecked?: boolean;
  disabled?: boolean;
}

const appear = keyframes`
0% {
  transform:  rotateY(180deg) scale(1);
  }
80% {
  transform:  rotateY(0deg) scale(1.5);
}
100% {
  transform:  rotateY(0deg) scale(1);
  }
`;

export const CheckboxLabel = styled.label<LabelProps>`
  background: #fff;
  border: 2px solid #332e2e;

  border-radius: 3px;

  height: 20px;
  width: 20px;

  display: flex;

  transition: background-color 0.35s ease;

  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
    color: #00cc44;
    opacity: 0;

    transition: all 0.5s ease;
  }

  input {
    display: none;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: #efefef;
  }

  ${(props) =>
    props.isChecked &&
    css`
      svg {
        opacity: 1;

        animation: ${appear} 1s forwards;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: #efefef;
      border: 2px solid #c6c6c6;
      cursor: not-allowed;
      pointer-events: none;

      svg {
        ${props.isChecked &&
        css`
          opacity: 0.5;
        `}
      }
    `}
`;
