import styled from 'styled-components';
import { lighten } from 'polished';

interface BtType {
  used: string;
}

export const IncButton = styled.button`
  margin: 10px 0 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 20px;
  background: #004d1a;
  border: 3px solid #004d1a;
  border-radius: 5px;
  transition: all 0.25s ease;

  /* height: 36.8px; */

  color: #efefef;

  p {
    font-size: 16px;
    transition: all 0.25s ease;
  }
  svg {
    margin: 0 0 0 7px;

    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: #efefef;
    transition: all 0.25s ease;
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0);
  }

  &:hover {
    background-color: ${lighten(0.05, '#004d1a')};
    border-color: ${lighten(0.05, '#004d1a')};

    p {
      color: #fff;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.75);
    }
    svg {
      color: #fff;
      box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.75);
      filter: brightness(120%);
    }
  }
`;

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 800px;
  transition: all 0.35s ease;

  @media screen and (max-width: 800px) {
    width: 100vw;
    border-radius: 0px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    min-height: 40px;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;
  }
`;

export const Desc = styled.td``;

export const Update = styled.td`
  width: 40px;
`;

export const Delete = styled.td`
  width: 40px;
`;

export const ButtonTD = styled.button<BtType>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */

  height: 30px;
  width: 30px;

  background: ${(props) => (props.used === 'alt' ? '#007acc' : '#c53030')};
  border-radius: 3px;
  border: 0px;

  svg {
    height: 17px;
    width: 17px;

    color: #fff;
  }
`;
