import styled from 'styled-components';
import { shade } from 'polished';

export const GridContainer = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;

  width: 95vw;
  margin: 20px auto;

  /* align-items: center; */

  transition: all 0.35s;
  @media screen and (max-width: 1366px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid: auto / 1fr;
    justify-items: center;
  }
  @media screen and (max-width: 500px) {
    justify-items: unset;
    margin: 20px 0;
    width: 100vw;
  }
  /* @media screen and (max-width: 1040px) {
    justify-items: unset;
  } */
`;

export const GridItem = styled.div`
  display: flex;
  flex-grow: 100;
  flex-direction: column;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 3px;
  min-width: 360px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  padding: 10px 5px;
  transition: all 0.35s;

  > div {
    display: flex;
    justify-content: space-between;
    p {
      color: #565656;
      font-size: 16px;
      strong {
        color: #332e2e;
        margin: 0 0 0 5px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & + div {
      margin: 10px 0 0 0;
    }
  }
  &:hover {
    border-color: ${shade(0.2, '#efefef')};
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 800px) {
    max-width: 500px;
  }
  @media screen and (max-width: 500px) {
    max-width: unset;
    width: 100vw;
    border-radius: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;
