import React from 'react';

import { Container, Content } from 'styles/wrappers';

import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { Intro, BooksContainer, Book } from './styles';

import { books } from './books';

const ObrasFrei: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <AlteredHeader>
        <div>Livros do Frei Ignácio Larrañaga</div>
      </AlteredHeader>
      <Content>
        <Intro>
          <q>
            Assim iniciou-se a minha etapa de escritor, tardiamente, aos 45 anos
            de idade, por acaso, que é o nome secular da Divina Providência.
          </q>
          <h4>Ignacio Larrañaga</h4>
        </Intro>
        <BooksContainer>
          {books.map((book) => (
            <Book>
              <h1>{book.title}</h1>
              <span>
                <img src={book.image} alt={book.title} />
                <p>{book.content}</p>
              </span>
              <p>{book.published}</p>
            </Book>
          ))}
        </BooksContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default ObrasFrei;
