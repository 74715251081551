import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import api from 'services/api';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { deconvertSpecialChars } from 'utils/specialChars';
import Button from 'components/Button';
import { useToast } from 'hooks/toast';
import { animated, useSpring } from 'react-spring';
import { FaFileExcel } from 'react-icons/fa';
import { alphaMasking } from 'components/Textarea/masks';
import { ListProps } from '../main';

import {
  HeaderContent,
  Total,
  ContentContainer,
  ContentCabec,
  BContent,
  ContentItem,
  Monetary,
  OBSContainer,
} from './styles';

interface StateProps {
  ce: ListProps;
  gseq?: number;
  cenum?: number;
  loccod?: string;
}

interface CEItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
}

const CEConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  const [ceHeader, setCEHeader] = useState<ListProps>(() => {
    if (!location.state.ce) {
      return {} as ListProps;
    }

    return location.state.ce;
  });

  const [ceItems, setCEitems] = useState<CEItemsProps[]>([]);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Impressão',
    open: false,
    content: '',
  });

  // const getItems = useCallback(async (alt?: boolean) => {
  //   try {
  //     const response = await api.get(
  //       `/sgo/ce_items.php?data=${JSON.stringify({ cenum: ceHeader.num })}`,
  //     );

  //     setCEitems(response.data);
  //     setLoading(false);
  //   } catch (err) {
  //     errorHandling(err);
  //     setLoading(false);
  //   }
  // }, [ceHeader.num, errorHandling]);

  const getItems = useCallback(
    async (alt?: boolean) => {
      try {
        const fetch = {
          cenum: alt ? location.state.cenum : ceHeader.num,
          code: alt ? location.state.loccod : ceHeader.loccod,
          isShow: true,
        };

        const response = await api.get(
          `/sgo/ce_items.php?data=${JSON.stringify(fetch)}`,
        );

        setCEitems(response.data);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
        setLoading(false);
      }
    },

    [
      ceHeader.loccod,
      ceHeader.num,
      errorHandling,
      location.state.cenum,
      location.state.loccod,
    ],
  );

  const getConsult = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/ce_list.php?data=${JSON.stringify({
          cod: location.state.loccod,
        })}`,
      );

      const index = response.data.findIndex(
        (item: ListProps) =>
          item.gseq === location.state.gseq &&
          item.num === location.state.cenum,
      );

      const filtered = response.data[index];

      setCEHeader(filtered);
      getItems(true);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [
    errorHandling,
    getItems,
    location.state.cenum,
    location.state.gseq,
    location.state.loccod,
  ]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }

    if (location.state.ce) {
      getItems();
      return;
    }

    getConsult();
  }, [getConsult, getItems, history, location.pathname, location.state]);

  const totalCE = useMemo(() => {
    let total = 0;

    ceItems.forEach((item: CEItemsProps) => {
      total += item.qtd * item.value;
    });

    return total;
  }, [ceItems]);

  const handleCancel = useCallback(
    async (values: DeleteProps['values']) => {
      setDeleteDiag({ open: false });
      if (values) {
        try {
          setLoading(true);

          let obs = '*** SEM OBSERVAÇÃO ***';
          const retrieve = document.querySelector<HTMLTextAreaElement>(
            'textarea[id="obs"]',
          );

          if (retrieve && retrieve.value !== '') {
            obs = retrieve.value;
          }

          const send = {
            ...values,
            obs: obs.replace('#', ''),
          };

          await api.get(`/sgo/ce_delete.php?data=${JSON.stringify(send)}`);

          setLoading(false);

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: `Controle de Entrega N° ${ceHeader.num} cancelado.`,
          });

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [addToast, ceHeader.num, errorHandling, history, location.pathname],
  );

  const anchor = useSpring({
    display: 'none',
  });

  const handleDownload = useCallback(async () => {
    try {
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(`a[id=ceLink]`);
      if (link) {
        link.click();
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`Consulta CE N° ${ceHeader.num || ''}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data do CE:&nbsp;
                    <strong>{formatDate(ceHeader.date)}</strong>
                  </p>
                </span>
                {/* {user.perfil === 'LOC' && ( */}
                <span>
                  <p>
                    Guia:&nbsp;<strong>{ceHeader.name}</strong>
                  </p>
                </span>
                {/* )} */}
              </div>
              <div>
                <span>
                  <p>
                    Coordenação Local:&nbsp;
                    <strong>{ceHeader.locdesc}</strong>
                  </p>
                </span>
              </div>
            </HeaderContent>
            <Total>
              <Button
                bgcolor="#332e2e"
                onClick={() => {
                  setShow((state) => ({
                    ...state,
                    open: true,
                  }));
                  handleDownload();
                }}
              >
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total deste Controle de Entrega:{' '}
                <strong>R$ {formatNumber(totalCE)}</strong>
              </p>
            </Total>
            <ContentContainer>
              <ContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </ContentCabec>
              <BContent>
                {ceItems.map((item: CEItemsProps, index: number) => (
                  <ContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </ContentItem>
                ))}
              </BContent>
            </ContentContainer>

            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {ceHeader.obs &&
                    ceHeader.obs.map((item) => (
                      <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                    ))}
                </strong>
              </span>
            </OBSContainer>
            {ceHeader.financregs <= 1 &&
            user.perfil === 'LOC' &&
            ceHeader.status === 'E' ? (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro do
                          controle de entrega&nbsp;
                          <strong>N° {ceHeader.num}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            placeholder="Observação"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      ce: ceHeader.num,
                      gseq: ceHeader.gseq,
                      gname: ceHeader.name,
                      uname: user.name,
                      locdesc: user.locdesc,
                    },
                  })
                }
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Cancelar esse CE
              </Button>
            ) : null}
          </>
        )}
        <animated.a
          style={anchor}
          id="ceLink"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_ce.php?data=${JSON.stringify({
            ce: ceHeader.num || location.state.cenum,
            loccod: ceHeader.loccod || location.state.loccod,
            gseq: ceHeader.gseq || location.state.gseq,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          CE
        </animated.a>
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleCancel(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default CEConsult;
