import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Textarea from 'components/TextareaDialog';

import InputDialog from 'components/InputDialog';
import SelectV2 from 'components/SelectV2';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import api from 'services/api';

import {
  AlteredHeader,
  Container,
  Content,
  IncButton,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import {
  FaPencilAlt,
  FaPlusCircle,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';

import {
  TableWrapper,
  Table,
  Desc,
  Dated,
  Update,
  Consult,
  ButtonTD,
  Delete,
  TG,
  NAC,
  LOC,
  TrajContent,
} from './styles';

import { FIGuiaProps } from '../main';
// import {
//   checkSentDate,
//   checkFunctionDate,
//   checkFunctionTrajDate,
// } from '../checkDates';

import { monthList } from '../../monthList';

interface ListProps {
  func: string;
  funcdesc: string;
  iniMonth: string;
  iniYear: string;
  endMonth: string;
  endYear: string;
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  desc: string;
  semi: string;
  semf: string;
}

interface FormData {
  // seq: number;
  iniMonth: string;
  endMonth: string;
  iniYear: string;
  endYear: string;
  description: string;
  funct: string;
  zon: string;
  anb: string;
  loc: string;
}

const FIGFunc: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const updateRef = useRef<FormHandles>(null);
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [list, setList] = useState<ListProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);
  const [show, setShow] = useState({} as ShowProps);
  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);

  const [prevReg, setPrevReg] = useState({} as FormData);
  const [prevConReg, setPrevConReg] = useState({} as FormData);
  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );
  const [, setMaxDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [comboFunc, setComboFunc] = useState<ComboProps[]>([]);
  const [iniZon, setIniZON] = useState('');

  const [comboZon, setComboZon] = useState<ComboProps[]>([]);
  const [anbs, setANBs] = useState<ComboProps[]>([]);
  const [comboANB, setComboANB] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLOC, setComboLOC] = useState<ComboProps[]>([]);

  const [funcao, setFuncao] = useState('');

  const getComboTG = useCallback(async () => {
    const response = await api.get(
      `/combos/comboFunctions.php?data=${JSON.stringify({
        mode: 'we',
        order: 'E',
      })}`,
    );

    setComboFunc(response.data);
  }, []);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZon([{ value: '', label: 'Selecione' }, ...response.data]);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get(
      `/combos/comboANBs.php?data=${JSON.stringify('content')}`,
    );
    setANBs([
      ...response.data.map((item: ComboProps) => ({
        ...item,
        label: item.label.replace('Nacional ', ''),
      })),
    ]);
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
  }, []);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_funcao.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      const { funcs, sentDate, topDate } = response.data;

      setMinDate(sentDate);
      setMaxDate(topDate);
      setList(funcs);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getComboZON();
    getComboANB();
    getComboLOC();
    getComboTG();
    getList();
  }, [
    getComboANB,
    getComboLOC,
    getComboTG,
    getComboZON,
    getList,
    history,
    location.state,
  ]);

  const cancelUpdate = useCallback(() => {
    setUpdateDiag({ open: false });
    setPrevConReg({} as FormData);
    setPrevReg({} as FormData);
  }, []);

  const getDesc = useCallback(
    (year: string, semester: string, month: string, func: string) => {
      const index = list.findIndex(
        (item) =>
          item.iniYear === year &&
          item.semi === semester &&
          item.iniMonth === month &&
          item.func === func,
      );

      const toSet = {
        iniYear: list[index].iniYear,
        endYear: list[index].endYear,
        iniMonth: list[index].iniMonth,
        endMonth: list[index].endMonth,
        description: list[index].desc,
        funct: list[index].func,
        zon: `${list[index].anb.substr(0, 2)}99`,
        anb:
          ['N', 'L'].indexOf(list[index].func.substr(1, 1)) > -1
            ? list[index].anb
            : '',
        loc: list[index].func.substr(1, 1) === 'L' ? list[index].loc : '',
      };

      if (['N', 'L'].indexOf(list[index].func.substr(1, 1)) > -1) {
        setComboANB(
          anbs.filter(
            (item) => item.value.substr(0, 2) === list[index].anb.substr(0, 2),
          ),
        );
      }
      setPrevReg(toSet);
      setPrevConReg({
        iniMonth: list[index].iniMonth,
        iniYear: list[index].iniYear,
        endMonth: list[index].endMonth,
        endYear: list[index].endYear,
        description: list[index].desc,
        funct: list[index].funcdesc,
        zon: list[index].func.substr(1, 1) === 'Z' ? list[index].anbdesc : '',
        anb: list[index].anbdesc,
        loc: list[index].locdesc,
      });
    },
    [anbs, list],
  );

  const handleFunction = useCallback(() => {
    const select = includeRef.current?.getFieldRef('funct');
    const { value } = select.options[select.selectedIndex];

    setFuncao(value);

    setIniZON('');
    setComboANB([]);
    setComboLOC([]);
  }, []);

  const handleFunctionUpdate = useCallback(() => {
    const select = updateRef.current?.getFieldRef('funct');
    const { value } = select.options[select.selectedIndex];

    setFuncao(value);

    setIniZON('');
    setComboANB([]);
    setComboLOC([]);
  }, []);

  const handleZONChange = useCallback(() => {
    const zon = includeRef.current?.getFieldValue('zon');
    setIniZON(zon);
    setComboANB(
      anbs.filter((item) => item.value.substr(0, 2) === zon.substr(0, 2)),
    );
    setComboLOC([]);
  }, [anbs]);

  const handleANBChange = useCallback(() => {
    const anb = includeRef.current?.getFieldValue('anb');

    setComboLOC(locs.filter((item) => item.anb === anb));
  }, [locs]);

  // const handleANBChangeUp = useCallback(() => {
  //   const anb = updateRef.current?.getFieldValue('anb');

  //   setComboLOC(locs.filter((item) => item.anb === anb));
  // }, [locs]);

  const handlePreInc = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreAlt = useCallback(
    (year: string, semester: string, month: string, func: string) => {
      getDesc(year, semester, month, func);
      // const index = list.findIndex(
      //   (item) => item.date === event && item.func === func,
      // );

      const index = list.findIndex(
        (item) =>
          item.iniYear === year &&
          item.semi === semester &&
          item.iniMonth === month &&
          item.func === func,
      );

      setComboLOC(locs.filter((item) => item.anb === list[index].anb));

      setUpdateDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc, list, locs],
  );

  const handlePreDel = useCallback(
    (year: string, semester: string, month: string, func: string) => {
      getDesc(year, semester, month, func);

      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handlePreShow = useCallback(
    (year: string, semester: string, month: string, func: string) => {
      getDesc(year, semester, month, func);

      setShow((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handleInclude = useCallback(
    async (data: FormData) => {
      try {
        includeRef.current?.setErrors({});

        setLoading(true);
        const schema = yup.object().shape({
          iniMonth: yup
            .string()
            .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          endMonth: yup
            .string()
            .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          iniYear: yup
            .number()
            .typeError('Campo obrigatório')
            .min(
              new Date(minDate).getFullYear(),
              'Ano de Início deve ser após a data de envio.',
            )
            .max(
              new Date().getFullYear(),
              'Ano de Início deve ser no máximo o atual.',
            ),
          endYear: yup
            .number()
            .typeError('Campo obrigatório')
            .min(
              new Date(data.iniYear).getFullYear(),
              'Ano do Fim deve ser após o ano de início.',
            ),
          // .max(
          //   new Date().getFullYear(),
          //   'Ano de Início deve ser no máximo o atual.',
          // ), //RETIRADO A PEDIDO DE ARTHUR
          funct: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
          zon: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          anb: yup.string().when('funct', {
            is: (funct) =>
              ['N', 'L'].indexOf(funct.substr(1, 1)) > -1 ||
              ['AR', 'CC', 'CE'].indexOf(funct) > -1,
            then: yup.string().notOneOf(['Selecione', '']).required(),
            otherwise: yup.string().nullable(),
          }),
          loc: yup.string().when('funct', {
            is: (funct) =>
              funct.substr(1, 1) === 'L' ||
              ['AR', 'CC', 'CE'].indexOf(funct) > -1,
            then: yup.string().notOneOf(['Selecione', '']).required(),
            otherwise: yup.string().nullable(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // if (
        //   !checkFunctionDate(
        //     `${data.iniYear}-${`0${data.iniMonth}`.slice(-2)}`,
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}`,
        //   )
        // ) {
        //   setLoading(false);
        //   includeRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   includeRef.current?.setFieldError(
        //     'endYear',
        //     'Ano do Fim não deve ultrapassar 3 anos.',
        //   );

        //   return;
        // }

        // if (
        //   checkFunctionTrajDate(
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}`,
        //     maxDate,
        //   )
        // ) {
        //   setLoading(false);
        //   includeRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   includeRef.current?.setFieldError(
        //     'endYear',
        //     'Deve ser um guia ativo no período.',
        //   );

        //   return;
        // }

        // if (
        //   !checkSentDate(
        //     `${data.iniYear}-${`0${data.iniMonth}`.slice(-2)}-01`,
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}-01`,
        //   )
        // ) {
        //   setLoading(false);
        //   includeRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   includeRef.current?.setFieldError(
        //     'endYear',
        //     'Data do Fim deve ser posterior a data inicial.',
        //   );

        //   return;
        // } //A PEDIDO DE ARTHUR

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            gseq: gState.gseq,
            mode: 'inc',
          }),
        );

        setInc((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_funcao.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, minDate],
  );

  // const handleUpdate = useCallback(() => {}, []);
  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        updateRef.current?.setErrors({});

        setLoading(true);
        const schema = yup.object().shape({
          iniMonth: yup
            .string()
            .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          endMonth: yup
            .string()
            .notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          iniYear: yup
            .number()
            .typeError('Campo obrigatório')
            .min(
              new Date(minDate).getFullYear(),
              'Ano de Início deve ser após a data de envio.',
            )
            .max(
              new Date().getFullYear(),
              'Ano de Início deve ser no máximo o atual.',
            ),
          endYear: yup
            .number()
            .typeError('Campo obrigatório')
            .min(
              new Date(data.iniYear).getFullYear(),
              'Ano do Fim deve ser após o ano de início.',
            ),
          // .max(
          //   new Date().getFullYear(),
          //   'Ano de Início deve ser no máximo o atual.',
          // ),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // if (
        //   !checkFunctionDate(
        //     `${data.iniYear}-${`0${data.iniMonth}`.slice(-2)}`,
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}`,
        //   )
        // ) {
        //   setLoading(false);
        //   updateRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   updateRef.current?.setFieldError(
        //     'endYear',
        //     'Ano do Fim não deve ultrapassar 3 anos.',
        //   );

        //   return;
        // }

        // if (
        //   !checkSentDate(
        //     `${data.iniYear}-${`0${data.iniMonth}`.slice(-2)}-01`,
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}-01`,
        //   )
        // ) {
        //   setLoading(false);
        //   includeRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   includeRef.current?.setFieldError(
        //     'endYear',
        //     'Data do Fim deve ser posterior a data inicial.',
        //   );

        //   return;
        // }

        // if (
        //   checkFunctionTrajDate(
        //     `${data.endYear}-${`0${data.endMonth}`.slice(-2)}`,
        //     maxDate,
        //   )
        // ) {
        //   setLoading(false);
        //   updateRef.current?.setFieldError('endMonth', 'Campo obrigatório');
        //   updateRef.current?.setFieldError(
        //     'endYear',
        //     'Deve ser um guia ativo no período.',
        //   );

        //   return;
        // }

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            prevIYear: prevReg.iniYear,
            prevIMonth: prevReg.iniMonth,
            prevISem: parseInt(prevReg.iniMonth, 10) < 7 ? 1 : 2,
            prevIFunct: prevReg.funct,
            gseq: gState.gseq,
            mode: 'update',
          }),
        );

        setUpdateDiag((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_funcao.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      errorHandling,
      gState.gseq,
      getList,
      minDate,
      prevReg.funct,
      prevReg.iniMonth,
      prevReg.iniYear,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          gseq: gState.gseq,
          iniYear: prevConReg.iniYear,
          iniMonth: prevConReg.iniMonth,
          iniSem: parseInt(prevConReg.iniMonth, 10) < 7 ? 1 : 2,
          funct:
            comboFunc[
              comboFunc.findIndex((item) => item.label === prevConReg.funct)
            ].value,
          mode: 'delete',
        }),
      );

      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/sgo/fig_funcao.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getList();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    comboFunc,
    errorHandling,
    gState.gseq,
    getList,
    prevConReg.funct,
    prevConReg.iniMonth,
    prevConReg.iniYear,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Funções Exercidas</p>
        </AlteredHeader>
        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}

        <TableWrapper>
          <Table>
            <thead>
              {width > 470 && <TG>Função</TG>}
              <Dated>Início</Dated>
              <Dated>Fim</Dated>
              {width > 380 && (
                <>
                  <NAC>Nac{width > 470 ? 'ional' : '.'}</NAC>
                  <LOC>Loc{width > 470 ? 'al' : '.'}</LOC>
                </>
              )}
              <Desc>Detalhe</Desc>
              {width > 800 ? null : <Consult>Con.</Consult>}
              {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                <>
                  <Update>Alt.</Update>
                  <Delete>Exc.</Delete>
                </>
              )}
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment
                  key={`${item.iniMonth}${item.iniYear}${item.func}`}
                >
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      {width > 470 && <TG>Função</TG>}
                      <Dated>Início</Dated>
                      <Dated>Fim</Dated>
                      {width > 380 && (
                        <>
                          <NAC>Nac{width > 470 ? 'ional' : '.'}</NAC>
                          <LOC>Loc{width > 470 ? 'al' : '.'}</LOC>
                        </>
                      )}
                      <Desc>Detalhe</Desc>
                      {width > 800 ? null : <Consult>Con.</Consult>}
                      {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                        <>
                          <Update>Alt.</Update>
                          <Delete>Exc.</Delete>
                        </>
                      )}
                    </tr>
                  )}
                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    {width > 470 && <TG>{item.funcdesc}</TG>}
                    <Dated>
                      {item.iniMonth !== '-'
                        ? `${`0${item.iniMonth}`.slice(-2)}/${item.iniYear}`
                        : item.iniMonth}
                    </Dated>
                    <Dated>
                      {item.endMonth !== '-'
                        ? `${`0${item.endMonth}`.slice(-2)}/${item.endYear}`
                        : item.endMonth}
                    </Dated>
                    {width > 380 && (
                      <>
                        <NAC>{item.anbdesc}</NAC>
                        <LOC>{item.locdesc}</LOC>
                      </>
                    )}
                    <Desc>{item.desc}</Desc>
                    {width > 800 ? null : (
                      <Consult>
                        {item.iniMonth !== '-' && (
                          <ButtonTD
                            used="show"
                            onClick={() =>
                              handlePreShow(
                                item.iniYear,
                                item.semi,
                                item.iniMonth,
                                item.func,
                              )
                            }
                          >
                            <FaSearch />
                          </ButtonTD>
                        )}
                      </Consult>
                    )}
                    {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                      <>
                        <Update>
                          {item.iniMonth !== '-' && (
                            <ButtonTD
                              used="alt"
                              onClick={() =>
                                handlePreAlt(
                                  item.iniYear,
                                  item.semi,
                                  item.iniMonth,
                                  item.func,
                                )
                              }
                            >
                              <FaPencilAlt />
                            </ButtonTD>
                          )}
                        </Update>
                        <Delete>
                          {item.iniMonth !== '-' && (
                            <ButtonTD
                              used="del"
                              onClick={() =>
                                handlePreDel(
                                  item.iniYear,
                                  item.semi,
                                  item.iniMonth,
                                  item.func,
                                )
                              }
                            >
                              <FaTimesCircle />
                            </ButtonTD>
                          )}
                        </Delete>
                      </>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Mês de Início:</p>
                      <SelectV2
                        name="iniMonth"
                        content={monthList}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                      />
                    </span>
                    <span>
                      <p>Ano de Início:</p>
                      <InputDialog
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        step="none"
                        placeholder="Ano de Início"
                        name="iniYear"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Mês do Fim:</p>
                      <SelectV2
                        name="endMonth"
                        content={monthList}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                      />
                    </span>
                    <span>
                      <p>Ano de Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '177.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        step="none"
                        placeholder="Ano do Fim"
                        name="endYear"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Função:</p>
                      <SelectV2
                        name="funct"
                        content={comboFunc}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                        onChange={handleFunction}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Detalhe:</p>
                      <Textarea
                        name="description"
                        placeholder="Insira a descrição"
                        mask="alpha"
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                        }}
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Zonal:</p>
                      <SelectV2
                        name="zon"
                        content={comboZon}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={iniZon}
                        onChange={handleZONChange}
                        disabled={funcao === ''}
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Nacional:</p>
                      <SelectV2
                        name="anb"
                        content={comboANB}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                        onChange={handleANBChange}
                        disabled={funcao.substr(1, 1) === 'Z'}
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Local:</p>
                      <SelectV2
                        name="loc"
                        content={comboLOC}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                        disabled={['N', 'Z'].indexOf(funcao.substr(1, 1)) > -1}
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <div>
                  <span>
                    <p>
                      Início:&nbsp;
                      <strong>
                        {`0${prevConReg.iniMonth}`.slice(-2)}/
                        {prevConReg.iniYear}
                      </strong>
                    </p>
                    <p style={{ margin: '0 0 0 5px' }}>
                      Fim:&nbsp;
                      <strong>
                        {`0${prevConReg.endMonth}`.slice(-2)}/
                        {prevConReg.endYear}
                      </strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Função:&nbsp;
                      <strong>{prevConReg.funct}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      {!!prevConReg.funct && prevConReg.funct.includes('onal')
                        ? 'Zonal '
                        : 'ANB'}
                      :&nbsp;<strong>{prevConReg.anb}</strong>
                    </p>
                  </span>

                  {!!prevConReg.funct &&
                  prevConReg.funct.includes('onal') ? null : (
                    <span>
                      <p>
                        Local:&nbsp;<strong>{prevConReg.loc}</strong>
                      </p>
                    </span>
                  )}

                  <span>
                    <p>
                      Detalhe:&nbsp;<strong>{prevConReg.description}</strong>
                    </p>
                  </span>
                </div>

                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>

        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>Consulta</h2>
          </S.Title>
          <S.Content>
            <ModalDeleteContent>
              <div>
                <span>
                  <p>
                    Início:&nbsp;
                    <strong>
                      {`0${prevConReg.iniMonth}`.slice(-2)}/{prevConReg.iniYear}
                    </strong>
                  </p>
                  <p style={{ margin: '0 0 0 5px' }}>
                    Fim:&nbsp;
                    <strong>
                      {`0${prevConReg.endMonth}`.slice(-2)}/{prevConReg.endYear}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    Função:&nbsp;
                    <strong>{prevConReg.funct}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {!!prevConReg.funct && prevConReg.funct.includes('onal')
                      ? 'Zonal '
                      : 'ANB'}
                    :&nbsp;<strong>{prevConReg.anb}</strong>
                  </p>
                </span>

                {!!prevConReg.funct &&
                prevConReg.funct.includes('onal') ? null : (
                  <span>
                    <p>
                      Local:&nbsp;<strong>{prevConReg.loc}</strong>
                    </p>
                  </span>
                )}

                <span>
                  <p>
                    Detalhe:&nbsp;<strong>{prevConReg.description}</strong>
                  </p>
                </span>
              </div>
            </ModalDeleteContent>
          </S.Content>
          <S.Actions>
            <S.Confirm type="button" onClick={() => setShow({ open: false })}>
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              iniYear: prevReg.iniYear,
              endYear: prevReg.endYear,
              description: prevReg.description,
            }}
          >
            <U.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Mês de Início:</p>
                      <SelectV2
                        name="iniMonth"
                        content={monthList.map((item) => ({
                          value: item.value.toString(),
                          label: item.label,
                        }))}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.iniMonth}
                      />
                    </span>
                    <span>
                      <p>Ano de Início:</p>
                      <InputDialog
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        step="none"
                        placeholder="Ano de Início"
                        name="iniYear"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Mês do Fim:</p>
                      <SelectV2
                        name="endMonth"
                        content={monthList.map((item) => ({
                          value: item.value.toString(),
                          label: item.label,
                        }))}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '177.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.endMonth}
                      />
                    </span>
                    <span>
                      <p>Ano de Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '177.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        step="none"
                        placeholder="Ano do Fim"
                        name="endYear"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Função:</p>
                      <SelectV2
                        name="funct"
                        content={comboFunc}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.funct}
                        onChange={handleFunctionUpdate}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Detalhe:</p>
                      <Textarea
                        name="description"
                        placeholder="Insira a descrição"
                        mask="alpha"
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                        }}
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Zonal:</p>
                      <SelectV2
                        name="zon"
                        content={comboZon}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.zon || 'Selecione'}
                        // onChange={handleZONChange}
                        disabled
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Nacional:</p>
                      <SelectV2
                        name="anb"
                        content={comboANB}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.anb || 'Selecione'}
                        // onChange={handleANBChange}
                        disabled
                      />
                    </span>
                  </div>
                  <div style={{ margin: '10px auto 0' }}>
                    <span>
                      <p>Local:</p>
                      <SelectV2
                        name="loc"
                        content={comboLOC}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.loc || 'Selecione'}
                        disabled
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </U.Content>
            <U.Actions>
              <U.Cancel type="button" onClick={cancelUpdate}>
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGFunc;
