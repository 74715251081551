import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { formatDate } from 'utils/formatDate';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { calcTotal, formatNumber } from 'utils/calcTotal';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { useAuth } from 'hooks/auth';

import { deconvertSpecialChars } from 'utils/specialChars';
import { FaFileExcel } from 'react-icons/fa';
import { animated, useSpring } from 'react-spring';
import { alphaMasking } from 'components/Textarea/masks';
import {
  HeaderContent,
  BContent,
  ContentContainer,
  Total,
  ContentCabec,
  ContentItem,
  Monetary,
  AddressContainer,
  OBSContainer,
} from './styles';

import { ListProps } from '../main';

export interface StateProps {
  pedido: ListProps;
}

interface ItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
}

const PedidoConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();

  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [pedidoHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.pedido;
  });
  const [items, setItems] = useState<ItemsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Planilha',
    open: false,
    content: '',
  });

  const getItems = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/pedido_items.php?data=${JSON.stringify({
          pedido: pedidoHeader.num,
          cod: pedidoHeader.cod,
        })}`,
      );
      setItems(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [errorHandling, pedidoHeader.cod, pedidoHeader.num]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }
    getItems();
  }, [getItems, history, location.pathname, location.state]);

  const total = useMemo(() => {
    let stotal = 0;

    items.forEach((item: ItemsProps) => {
      stotal += item.qtd * item.value;
    });

    return stotal;
  }, [items]);

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);

          let obs = '*** SEM OBSERVAÇÃO ***';
          const retrieve = document.querySelector<HTMLTextAreaElement>(
            'textarea[id="obs"]',
          );

          if (retrieve && retrieve.value.trim() !== '') {
            obs = retrieve.value;
          }

          const data = {
            ...values,
            obs: obs.replace('#', ''),
          };

          const send = new FormData();
          send.append('data', JSON.stringify(data));

          setDeleteDiag({ open: false });
          await api.post('/sgo/pedido_delete.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          setLoading(false);

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          errorHandling(err);
        }
      }
    },
    [errorHandling, history, location.pathname],
  );

  const handleDownload = useCallback(async () => {
    try {
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(
        `a[id=pedidoLink]`,
      );
      if (link) {
        link.click();
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const anchor = useSpring({
    display: 'none',
    height: '0px',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`Consulta Pedido N° ${pedidoHeader.num}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data do Pedido:&nbsp;
                    <strong>{formatDate(pedidoHeader.dtpedido)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Coordenação:&nbsp;
                    <strong>{pedidoHeader.anbdesc}</strong>
                  </p>
                </span>
              </div>
              {pedidoHeader.numgd ? (
                <div>
                  <span>
                    <p>
                      GD N°:&nbsp;
                      <strong>{pedidoHeader.numgd}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Data da GD:&nbsp;
                      <strong>{formatDate(pedidoHeader.dtgd)}</strong>
                    </p>
                  </span>
                </div>
              ) : null}
            </HeaderContent>

            <Total>
              <Button
                bgcolor="#332e2e"
                onClick={() => {
                  setShow((state) => ({
                    ...state,
                    open: true,
                  }));
                  handleDownload();
                }}
              >
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total deste Pedido:&nbsp;
                <strong>R$ {formatNumber(total)}</strong>
              </p>
            </Total>

            <ContentContainer>
              <ContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </ContentCabec>
              <BContent>
                {items.map((item: ItemsProps, index: number) => (
                  <ContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </ContentItem>
                ))}
              </BContent>
            </ContentContainer>

            <AddressContainer>
              <span>
                <p>
                  Endereço de Entrega:&nbsp;
                  <strong>
                    {pedidoHeader.address.street}&nbsp;-&nbsp;
                    {pedidoHeader.address.district}&nbsp;-&nbsp;
                    {pedidoHeader.address.city}&nbsp;-&nbsp;
                    {pedidoHeader.address.state}&nbsp;-&nbsp;CEP:&nbsp;
                    {pedidoHeader.address.cep}
                  </strong>
                </p>
              </span>
            </AddressContainer>
            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {pedidoHeader.obs.map((item) => (
                    <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                  ))}
                </strong>
              </span>
            </OBSContainer>

            {pedidoHeader.status === 'C' && user.perfil === 'NAC' && (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro do
                          pedido&nbsp;
                          <strong>N° {pedidoHeader.num}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            placeholder="Observação"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      pednum: pedidoHeader.num,
                    },
                  })
                }
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Excluir esse pedido
              </Button>
            )}
          </>
        )}
        <animated.a
          style={anchor}
          id="pedidoLink"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_pedido.php?data=${JSON.stringify({
            pedido: pedidoHeader.num.toString(),
            anbcod: pedidoHeader.cod,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          PEDIDO
        </animated.a>
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleExclusion(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default PedidoConsult;
