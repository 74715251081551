import React, { useCallback, useRef, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaLock } from 'react-icons/fa';
import * as yup from 'yup';
import { Container } from '../../../styles/wrappers';
import { Content } from './styles';

import Header from '../../../components/Header';
import Navbar from '../../../components/Navbar';
import ScrollTop from '../../../components/ScrollTop';
import Footer from '../../../components/Footer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import api from '../../../services/api';
import errorHandling from '../../../utils/errorHandling';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

interface ResetData {
  pass: string;
  passConfirm: string;
}

const Reset: React.FC = () => {
  const { pathname } = useLocation();
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const token = pathname.replace('/reset/', '');

  const checkToken = useCallback(async () => {
    try {
      await api.get(`/token/jwt_password.php?token=${token}`);
    } catch (err) {
      const { code } = errorHandling(err);
      let message = '';

      if (code === '404') {
        message = 'Token de reset de senha não encontrado';
      } else {
        message = 'Token de reset de senha expirado';
      }

      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: message,
      });

      // history.replace(`${process.env.REACT_APP_DEFAULT_DIR}/`);
      history.replace('/');
    }
  }, [token, history, addToast]);

  const handleSubmit = useCallback(
    async (data: ResetData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          pass: yup
            .string()
            .trim()
            .required('Senha obrigatória')
            .min(6, 'No mínimo 6 dígitos'),
          passConfirm: yup
            .string()
            .trim()
            .oneOf([yup.ref('pass'), null], 'As senhas não são identicas'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            pass: data.pass,
            // pass: data.pass.replace('#', 'HASHTAG'),
            token,
          }),
        );

        await api.post('/common/reset_password.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // await api.get(
        //   `/common/reset_password.php?pass=${data.pass.replace(
        //     '#',
        //     'HASHTAG',
        //   )}&token=${token}`,
        // );

        localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);
        localStorage.removeItem(`${process.env.REACT_APP_USER}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Você já pode realizar o login com sua nova senha',
        });

        // history.replace(`${process.env.REACT_APP_DEFAULT_DIR}/signin`);
        history.replace('/signin');
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            'Não foi possível realizar a troca de senha. Tente novamente e caso o problema persista, acione o suporte ao SGO',
        });
      }
    },
    [addToast, history, token],
  );

  useEffect(() => {
    if (user) {
      // history.replace(`${process.env.REACT_APP_DEFAULT_DIR}/dashboard`);
      // history.replace('/dashboard');
      history.replace('/sgo');
      return;
    }

    checkToken();
  }, [user, history, checkToken]);

  return (
    <Container>
      <ScrollTop />
      <Header />
      <Navbar />
      <Content>
        <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
          <h1>Reinicie sua senha:</h1>
          <Input
            icon={FaLock}
            name="pass"
            type="password"
            placeholder="Nova senha"
            isPass
          />
          <Input
            icon={FaLock}
            name="passConfirm"
            type="password"
            placeholder="Confirmar nova senha"
            isPass
          />
          <Button type="submit" containerStyle={{ marginTop: '25px' }}>
            Reiniciar
          </Button>
        </Form>
      </Content>
      <Footer />
    </Container>
  );
};

export default Reset;
