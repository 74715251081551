import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, Content } from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from 'components/Input';
import { useHistory } from 'react-router-dom';

import { Wrapper } from './styles';

interface FormData {
  type: string;
  description: string;
}

const ReceitasDespesasInsert: React.FC = () => {
  const history = useHistory();
  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handlePermission(['ZON'], true);
  }, [handlePermission]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          type: yup.string().notOneOf(['on', ''], 'Este campo é obrigatório'),
          description: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify({ ...data, mode: 'insert' }));

        await api.post('/sgo/recdespeventos.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });
        history.goBack();
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, errorHandling, history],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Nova Receita/Despesa" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <div>
              <p>Tipo:</p>
              <RadioContainer
                name="type"
                content={[
                  {
                    id: 'tR',
                    value: 'R',
                    label: 'Receita',
                  },
                  {
                    id: 'tD',
                    value: 'D',
                    label: 'Despesa',
                  },
                ]}
              />
            </div>
            <div>
              <p>Descrição:</p>
              <Input name="description" placeholder="Descrição" />
            </div>
          </Wrapper>

          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ReceitasDespesasInsert;
