import styled from 'styled-components';

export const Container = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100vw;
  height: 40px;

  background: #8a0002;
  span {
    display: flex;
    color: #efefef;
    a {
      color: #efefef;

      svg {
        height: 35px;
        width: 35px;
      }

      & + a {
        margin-left: 15px;
      }
    }

    & + span {
      margin-top: 10px;
    }
  }
`;
