import React, { useEffect, useState, HTMLAttributes, useCallback } from 'react';

import { FaPrint } from 'react-icons/fa';
import { IconBaseProps } from 'react-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from './styles';
import { useScrolling } from '../../hooks/scrolling';

import * as S from '../../styles/dialog_consult';
import { ShowProps } from '../../styles/dialog_consult';

interface PrintButtonProps extends HTMLAttributes<HTMLElement> {
  icon?: React.ComponentType<IconBaseProps>;
  asLink?: boolean;
  ignoreToken?: boolean;
  linkTo: string;
  hasProps?: boolean;
  routeSwitch?: boolean;
}

const PrintButton: React.FC<PrintButtonProps> = ({
  children,
  asLink = true,
  icon: Icon = FaPrint,
  linkTo,
  hasProps = false,
  routeSwitch = false,
  ignoreToken = false,
  ...rest
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { scrolled } = useScrolling();
  const [isSwitch] = useState(routeSwitch);
  const [link] = useState(linkTo);

  const [position, setPosition] = useState<number>(scrolled);

  useEffect(() => {
    if (scrolled > 100) {
      setPosition(100);
      return;
    }

    setPosition(scrolled);
  }, [scrolled]);

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando arquivo',
    open: false,
    content: (
      <div>
        <p>O arquivo já está sendo gerado!</p>
        <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
        <p>Assim que estiver pronto o download começará automaticamente.</p>
      </div>
    ),
  });

  const handleDownload = useCallback(() => {
    if (isSwitch) {
      history.push(`${pathname}${link}`);
    } else {
      setShow({
        ...show,
        open: true,
      });

      const timer = setTimeout(() => {
        setShow({
          ...show,
          open: false,
        });
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [history, isSwitch, link, pathname, show]);

  const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);

  return (
    <Container amountScrolled={position}>
      {asLink && (
        <>
          {isSwitch ? (
            <Icon onClick={handleDownload} />
          ) : (
            <a
              href={`${process.env.REACT_APP_API}${linkTo}${
                ignoreToken
                  ? ''
                  : `${hasProps ? '&' : '?'}token=${JSON.stringify(token)}`
              }`}
              rel="noreferrer noopener"
              target="_top"
              onClick={handleDownload}
            >
              {Icon && <Icon />}
            </a>
          )}
        </>
        // <a
        //   href={`${process.env.REACT_APP_API}${linkTo}${
        //     !routeSwitch ? (hasProps ? '&' : '?') : ''
        //   }${!routeSwitch ? `token=${JSON.stringify(token)}` : ''}`}
        //   rel="noreferrer noopener"
        //   target="_top"
        //   onClick={handleDownload}
        // >
        //   {Icon && <Icon />}
        // </a>
      )}
      {!asLink && children}

      {!routeSwitch && (
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      )}
    </Container>
  );
};

export default PrintButton;
