import React, { useState, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { FaFileExcel } from 'react-icons/fa';

import JsFileDownloader from 'js-file-downloader';

import { Menu, AnimatedDiv, ProgressDiv } from './styles';

const MateriaisCertificados: React.FC = () => {
  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Arquivo',
  } as ShowProps);
  const path = `${process.env.REACT_APP_TOV_INTERNACIONAL}`;

  const [downloaded, setDownloaded] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  function process_loading(event: ProgressEvent<EventTarget>): void {
    if (!event.lengthComputable) return;
    const downloadingPercentage = parseFloat(
      ((event.loaded / event.total) * 100).toFixed(2),
    );
    setDownloaded(downloadingPercentage);
    // what to do ...
  }

  const handleDownload = useCallback(
    (ev) => {
      setShow((state) => ({ ...state, open: true }));
      const { filename } = ev.currentTarget.dataset;
      const fUrl = `${path}/${filename}.xls`;

      setDownloaded(0);
      setShowProgressBar((state) => !state);

      const returned = new JsFileDownloader({
        url: fUrl,
        autoStart: false,
        forceDesktopMode: true,
        timeout: 360000,
        nameCallback() {
          return `${filename}.xls`;
        },
        contentType: 'application/xls',
        process: process_loading,
      });

      returned
        .start()
        .then(() => {})
        .catch((err) => console.error(err))
        .finally(() => {
          setShow((state) => ({ ...state, open: false }));
          setShowProgressBar((state) => !state);
        });
    },
    [path],
  );

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Materiais e Certificados</p>
          </div>
        </AlteredHeader>

        <Menu>
          <AnimatedDiv duration={1.1} delay={0.35}>
            <button
              type="button"
              data-link="mat_num_brasil"
              data-filename="MAT_NUM_BRASIL"
              onClick={handleDownload}
            >
              <FaFileExcel size={30} />
              <h3>XLS - Materiais e Certificados</h3>
            </button>
          </AnimatedDiv>
        </Menu>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px 0',
            }}
          >
            <div>
              <p>O arquivo já está sendo gerado!</p>
            </div>
            {showProgressBar && (
              <ProgressDiv>
                <span>
                  <strong>{downloaded}% concluído</strong>
                </span>
                <progress id="file" value={downloaded} max="100">
                  {downloaded}
                </progress>
              </ProgressDiv>
            )}
          </div>
        </S.Content>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default MateriaisCertificados;
