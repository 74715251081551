import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';

import {
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { removeAcento } from 'utils/specialChars';
import { FaDollarSign, FaSearch } from 'react-icons/fa';
import { GridContainer, GridItem } from './styles';

export interface ListProps {
  anbcod: string;
  anbdesc: string;
  gdnum: number;
  gdyear: string;
  data: string;
  valor: number;
  status: string;
  statDesc: string;
  pednum: number;
  peddata: string;
  financregs: number;
  obs: string[];
  orig: string;
  origDesc: string;
  addressval: string;
  address: {
    street: string;
    district: string;
    city: string;
    state: string;
    cep: string;
  };
}

interface LocalStorageProps {
  stat?: string;
  year?: string;
}

const GD: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [status, setStatus] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_GD}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_GD}`,
      );

      if (stat) {
        return stat;
      }

      return 'E';
    }

    return 'E';
  });
  const [years, setYears] = useState<ComboProps[]>([]);
  const [yearSelected, setYearSelected] = useState(() => {
    const current = JSON.stringify(
      new Date(new Date().toISOString()).getFullYear(),
    );

    const hasItem = localStorageLength(`${process.env.REACT_APP_GD}`);

    if (hasItem > 0) {
      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_GD}`,
      );

      if (year) {
        return year;
      }

      return current;
    }

    return current;
  });

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/gd_list.php');

      const { fetchlist, year } = response.data;

      setYears(year);
      setRawList(fetchlist);

      const initSTAT = formRef.current?.getFieldValue('filterGDStat');
      const initYEAR = formRef.current?.getFieldValue('filterYears');

      setList(
        fetchlist.filter(
          (item: ListProps) =>
            item.status === initSTAT && item.gdyear === initYEAR,
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON'], true);
    getList();
  }, [handlePermission, getList]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const stat = formRef.current?.getFieldValue('filterGDStat');
      const year = formRef.current?.getFieldValue('filterYears');
      const filtered = rawList.filter((item: ListProps) => {
        return (
          (item.gdnum.toString().includes(val) ||
            removeAcento(item.anbdesc)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase())) &&
          item.status === stat &&
          item.gdyear === year
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('filterGDStat');
    setSearchValue('');
    setStatus(stat);
    setLocalStorage(`${process.env.REACT_APP_GD}`, { stat });

    const year = formRef.current?.getFieldValue('filterYears');

    setList(
      rawList.filter(
        (item: ListProps) => item.gdyear === year && item.status === stat,
      ),
    );
  }, [rawList]);

  const handleYearSelect = useCallback(() => {
    const year = formRef.current?.getFieldValue('filterYears');
    setYearSelected(year);
    setSearchValue('');
    setLocalStorage(`${process.env.REACT_APP_GD}`, { year });

    const stat = formRef.current?.getFieldValue('filterGDStat');

    setList(
      rawList.filter(
        (item: ListProps) => item.gdyear === year && item.status === stat,
      ),
    );
  }, [rawList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'ZON' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['NAC', 'ZON'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              <RadioContainer
                title="Guias de Distribuição:"
                onClick={handleRadioClick}
                name="filterGDStat"
                content={[
                  {
                    id: 'eGD',
                    value: 'E',
                    label: 'Emitidas',
                  },
                  {
                    id: 'pGD',
                    value: 'P',
                    label: 'Pagas',
                  },
                  {
                    id: 'cGD',
                    value: 'C',
                    label: 'Canceladas',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={status}
              />

              <span style={{ marginTop: '10px' }}>
                <p>Ano de Emissão:</p>
                <SelectV2
                  name="filterYears"
                  content={years}
                  initial={yearSelected}
                  onChange={handleYearSelect}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>Guias de Distribuição</Header>
      {user.perfil === 'ZON' && (
        <FloatInput>
          <input
            placeholder="Filtrar por numeração ou coordenação"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={user.perfil !== 'ZON' ? '231px' : '280px'}>
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem
              key={item.gdnum}
              bordercolor={
                item.status === 'C'
                  ? '#c53030'
                  : item.status === 'E'
                  ? '#2156e6'
                  : '#00802b'
              }
            >
              <p>
                N° {item.gdnum} |&nbsp;
                {formatDate(item.data)}
              </p>
              <span>
                <p>
                  Valor da GD:&nbsp;
                  <strong>R$ {formatNumber(item.valor)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statDesc}</strong>
                </p>
              </span>
              {user.perfil === 'ZON' && item.orig === user.zoncod && (
                <span>
                  <p>
                    Coord:&nbsp;
                    <strong>{item.anbdesc}</strong>
                  </p>
                </span>
              )}
              {user.perfil === 'ZON' && item.orig !== user.zoncod && (
                <span>
                  <p>
                    Emitida por :&nbsp;
                    <strong>{item.origDesc}</strong>
                  </p>
                </span>
              )}

              {item.pednum ? (
                <span>
                  <p>
                    Baseada no pedido:&nbsp;
                    <strong>
                      N° {item.pednum} | {formatDate(item.peddata)}
                    </strong>
                  </p>
                </span>
              ) : null}
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { gd: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {item.status !== 'C' && item.valor > 0 && (
                  <O.Financ
                    to={{
                      pathname: '/sgo/tesouraria/contfingd/detail',
                      state: {
                        finHeader: {
                          num: item.gdnum,
                          cod: item.anbcod,
                          desc: item.anbdesc,
                          date: item.data,
                          valor: item.valor,
                        },
                      },
                    }}
                  >
                    <FaDollarSign />
                  </O.Financ>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default GD;
