import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { useSpring } from 'react-spring';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import api from 'services/api';

import { formatCPF } from 'utils/handleCPF';

import * as S from 'styles/dialog_consult';
import * as I from 'styles/dialog_include';
import { ShowProps } from 'styles/dialog_consult';
import { IncludeProps } from 'styles/dialog_include';

import { FaSearch, FaTimesCircle } from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import Button from 'components/Button';
import {
  InputContainer,
  SVGContainer,
  ListContainer,
  List,
  SelectedContainer,
  Item,
  ModalContainer,
} from './styles';

import { ListProps } from '../main';

interface FGProps {
  rawcpf: string;
  epg: number;
  name: string;
  cpf: string;
}

const AlunosTransfer: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const location = useLocation<ListProps>();
  const history = useHistory();

  const [epg] = useState(() => ({ ...location.state }));

  const [searchValue, setSearchValue] = useState('');
  const [rawFGs, setRawFGs] = useState<FGProps[]>([]);
  const [fgs, setFGs] = useState<FGProps[]>([]);
  const [selected, setSelected] = useState<FGProps[]>([]);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [inc, setInc] = useState<ShowProps>({} as IncludeProps);

  const getFGs = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/epg_aluno_cpf.php?data=${JSON.stringify({
          mode: 'transfer',
          stage: epg.stage,
        })}`,
      );

      setRawFGs(
        response.data
          .filter((item: FGProps) => item.epg !== epg.epg)
          .map((item: FGProps) => ({ ...item, cpf: formatCPF(item.rawcpf) })),
      );

      // setFGs(
      //   response.data
      //     .filter(
      //       (item: FGProps) =>
      //         item.epg !== epg.epg &&
      //         removeAcento(item.name)
      //           .toLowerCase()
      //           .includes(removeAcento('maria').toLowerCase()),
      //     )
      //     .map((item: FGProps) => ({ ...item, cpf: formatCPF(item.rawcpf) })),
      // );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [epg.epg, epg.stage, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getFGs();
  }, [history, location.state, getFGs]);

  // CASO SEJA CPF
  // const handleSearch = useCallback(
  //   (val: string) => {
  //     const replaced = val.replace(/[.-]/g, '').replace(/[a-z]/gi, '');
  //     const vL = replaced.length;
  //     setSearchValue(val.length > 3 ? formatCPF(replaced) : replaced);

  //     setFGs(
  //       vL === 0
  //         ? []
  //         : rawFGs.filter((item) => item.rawcpf.substr(0, vL) === replaced),
  //     );
  //   },
  //   [rawFGs],
  // );

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);
      setFGs(
        val.length === 0
          ? []
          : rawFGs.filter((item) =>
              removeAcento(item.name)
                .toLowerCase()
                .includes(removeAcento(val).toLowerCase()),
            ),
      );
    },
    [rawFGs],
  );

  const handleClear = useCallback(() => {
    setSearchValue('');
    setFGs([]);
  }, []);

  const handleSelection = useCallback(
    (fg: FGProps) => {
      const index = selected.findIndex((based) => based.rawcpf === fg.rawcpf);

      setSelected((state) =>
        index > -1
          ? state.filter((item) => item.rawcpf !== fg.rawcpf)
          : [...state, { ...fg }],
      );

      // setFGs((state) =>
      //   index < 0 ? state.filter((item) => item !== fg) : [...state, { ...fg }],
      // );
    },
    [selected],
  );

  const handleCheck = useCallback(() => {
    setShow((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreSubmit = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      handlePreSubmit();
      const content = selected.map((item) => item.rawcpf);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          epg: epg.epg,
          stage: epg.stage,
          fgs: content,
        }),
      );

      await api.post('/sgo/epg_aluno_transfer.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Todos os futuros guias foram transferidos.',
      });

      history.goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    epg.epg,
    epg.stage,
    errorHandling,
    handlePreSubmit,
    history,
    selected,
  ]);

  const appearClear = useSpring({
    opacity: searchValue.length > 0 ? 1 : 0,
    pointerEvents: searchValue.length > 0 ? 'all' : 'none',
  });

  const appearSelected = useSpring({
    opacity: selected.length > 0 ? 1 : 0,
    pointerEvents: selected.length > 0 ? 'all' : 'none',
    height: selected.length > 0 ? '35px' : '0px',
  });

  const appearButton = useSpring({
    opacity: selected.length > 0 ? 1 : 0,
    pointerEvents: selected.length > 0 ? 'all' : 'none',
    margin: selected.length > 0 ? '10px 0px 10px 0px' : '0px 0px 0px 0px',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(epg).length > 0 ? `EPG N° ${epg.epg}` : ''}
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Transferência de Futuro Guia</p>
          </div>
          <div>
            <p>{epg.stage}ª Etapa</p>
          </div>
        </AlteredHeader>

        <InputContainer>
          <input
            placeholder="Digite o nome do Futuro Guia"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
          <SVGContainer style={appearClear}>
            <button type="button" onClick={handleClear}>
              <FaTimesCircle />
            </button>
          </SVGContainer>
        </InputContainer>

        <SelectedContainer style={appearSelected}>
          <p>
            Selecionados para transferência:
            <strong>{selected.length}</strong>
          </p>
          <button type="button" onClick={handleCheck}>
            <FaSearch />
          </button>
        </SelectedContainer>
        <Button
          style={appearButton}
          bgcolor="#00802b"
          onClick={handlePreSubmit}
        >
          Transferir
        </Button>
        <ListContainer style={appearClear}>
          <List>
            {fgs.map((item) => (
              <Item
                key={item.cpf}
                selected={
                  selected.findIndex((based) => based.rawcpf === item.rawcpf) >
                  -1
                }
                onClick={() => handleSelection(item)}
              >
                <strong>{item.name}</strong>
                <p>
                  CPF: <strong>{item.cpf}</strong>
                </p>
              </Item>
            ))}
          </List>
        </ListContainer>
        {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
          {fgs.map((item) => (
            <span key={item.cpf}>
              <p>
                {item.name} - {item.epg} - {item.rawcpf} - {item.cpf}
              </p>
            </span>
          ))}
        </div> */}

        {/* <SelectedContainer style={appearSelected}>
          {selected.map((item) => (
            <Item
              key={item.cpf}
              selected={
                selected.findIndex((based) => based.rawcpf === item.rawcpf) > -1
              }
              onClick={() => handleSelection(item)}
            >
              <strong>{item.name}</strong>
              <p>
                CPF: <strong>{item.cpf}</strong>
              </p>
            </Item>
          ))}
        </SelectedContainer> */}
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
        <S.Title>
          <h2>Transferência</h2>
        </S.Title>
        <S.Content>
          <ModalContainer>
            {selected.length === 0 ? (
              <div>Nenhum futuro guia a ser transferido</div>
            ) : (
              selected.map((item) => (
                <div>
                  <p>{item.name}</p>
                  <button type="button" onClick={() => handleSelection(item)}>
                    <FaTimesCircle />
                  </button>
                </div>
              ))
            )}
          </ModalContainer>
        </S.Content>
        <S.Actions>
          <S.Confirm type="button" onClick={handleCheck}>
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <I.Container scroll="paper" maxWidth={false} open={!!inc.open}>
        <I.Title>
          <h2>*** ATENÇÃO ***</h2>
        </I.Title>
        <I.Content>
          {selected.length > 0 && (
            <ModalContainer>
              <p>
                Você está prestes a transferir <strong>permanentemente</strong>
                &nbsp;o
                {selected.length > 1 ? 's' : ''}
                &nbsp;futuro{selected.length > 1 ? 's' : ''}&nbsp;guia
                {selected.length > 1 ? 's' : ''}&nbsp;para esta escola (
                <strong>E-{epg.epg}</strong>).
              </p>
              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </ModalContainer>
          )}
          <ModalContainer>
            {selected.length === 0 ? (
              <div>Nenhum futuro guia a ser transferido</div>
            ) : (
              selected.map((item) => (
                <div>
                  <p>{item.name}</p>
                  <button type="button" onClick={() => handleSelection(item)}>
                    <FaTimesCircle />
                  </button>
                </div>
              ))
            )}
          </ModalContainer>
        </I.Content>
        <I.Actions>
          <I.Cancel type="button" onClick={handlePreSubmit}>
            Cancelar
          </I.Cancel>
          {selected.length > 0 && (
            <I.Confirm type="button" onClick={handleSubmit}>
              Confirmar
            </I.Confirm>
          )}
        </I.Actions>
      </I.Container>
      <SGOFooter />
    </Container>
  );
};

export default AlunosTransfer;
