import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import FloatLink from 'components/FloatLink';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';
import { useLocation, Link } from 'react-router-dom';
import { useSpring } from 'react-spring';

import api from 'services/api';

import { formatDate, handleTimeZone } from 'utils/formatDate';

import moment from 'moment';

import { FaPersonBooth, FaReceipt } from 'react-icons/fa';

import { Grid, GridItem, VoteSVG, StyledLink } from './styles';

interface RelatedLocals {
  cod: string;
  desc: string;
}

export interface ListProps {
  anb: string;
  anbdesc: string;
  anbref: string;
  anbrefdesc: string;
  coor: string;
  end: string;
  future: boolean;
  ini: string;
  loc: string;
  locdesc: string;
  obj: string;
  relatedlocals: RelatedLocals[];
  seq: string;
  zon: string;
  zondesc: string;
}

interface Returned {
  text: string;
  stat: boolean;
}

const Eleicao: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { pathname } = useLocation();
  const { width } = useWindow();

  const [list, setList] = useState<ListProps[]>([]);

  const checkEnd = (date: string): boolean => {
    const today = new Date();

    const start = new Date(handleTimeZone(date).setHours(23, 59, 59));

    const formats = 'YYYY-mm-dd HH:mm:ss';

    const startAt = moment(start, formats);
    const base = moment(today, formats);

    return base.isSameOrAfter(startAt);
  };

  const checkStat = (ini: string, end: string): Returned => {
    const start = new Date(handleTimeZone(ini).setHours(0, 0, 0));

    const now = new Date();

    // const convINIDate = new Date(parseISO(ini));
    // const today = new Date(parseISO(new Date().toISOString()));

    const formats = 'YYYY-mm-dd HH:mm:ss';

    const startAt = moment(start, formats);
    const base = moment(now, formats);

    return {
      text: checkEnd(end)
        ? 'Em apuração'
        : base.isSameOrAfter(startAt)
        ? 'Em votação'
        : 'Programada',
      stat: checkEnd(end),
    };

    // return base.isAfter(finale);
  };

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/eleicao_list.php');
      // console.table(response);
      setList(response.data);
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [errorHandling]);

  useEffect(() => {
    getList();
  }, [getList]);

  const showLink = useSpring({
    opacity: width <= 470 ? 1 : 0,
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {width > 470 && (
        <FloatLink
          routeName="comprovantes"
          icon={FaReceipt}
          title="Comprovantes"
        />
      )}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Lista das Convocações</p>
          </div>
        </AlteredHeader>
        <StyledLink
          style={showLink}
          to={{ pathname: `${pathname}/comprovantes` }}
          title="Comprovantes"
        >
          <FaReceipt />
          <p>Comprovantes</p>
        </StyledLink>
        <Grid>
          {list.map((item) => (
            <GridItem key={item.seq}>
              {user.adm && (
                <span>
                  <p>
                    Ref.: <strong>{item.seq}</strong>
                  </p>
                </span>
              )}
              {item.future ? (
                <>
                  <span>
                    <p>
                      Coord. de Origem: <strong>{item.anbrefdesc}</strong>
                    </p>
                  </span>

                  <span style={{ flexDirection: 'column' }}>
                    <p>Coord. Locais Relacionadas:</p>
                    <p style={{ margin: '0' }}>
                      <strong>
                        {item.relatedlocals.map(
                          (rl, index) =>
                            `${rl.desc}${
                              index < item.relatedlocals.length - 1 ? ', ' : ''
                            }`,
                        )}
                      </strong>
                    </p>
                  </span>
                </>
              ) : (
                <>
                  {item.coor === 'Z' ? (
                    <span>
                      <p>
                        Coord. Zonal: <strong>{item.zondesc}</strong>
                      </p>
                    </span>
                  ) : (
                    <>
                      {user.perfil === 'ZON' && (
                        <span>
                          <p>
                            Coord. Nacional: <strong>{item.anbdesc}</strong>
                          </p>
                        </span>
                      )}

                      <span>
                        <p>
                          Coord. Local: <strong>{item.locdesc}</strong>
                        </p>
                      </span>
                    </>
                  )}
                </>
              )}

              <span>
                <p>
                  Início: <strong>{formatDate(item.ini)}</strong>
                </p>
                <p>
                  Término: <strong>{formatDate(item.end)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Objetivo:&nbsp;
                  <strong>{item.obj}</strong>
                </p>
              </span>
              <span>
                <p>
                  Situação:&nbsp;
                  <strong>{checkStat(item.ini, item.end).text}</strong>
                </p>
              </span>
              {!checkStat(item.ini, item.end).stat &&
                checkStat(item.ini, item.end).text !== 'Programada' &&
                (['GA', 'GP'].indexOf(user.tgcod) > -1 || user.adm) && (
                  <VoteSVG title="Votar">
                    <Link
                      to={{ pathname: `${pathname}/votar`, state: { ...item } }}
                    >
                      <FaPersonBooth />
                    </Link>
                  </VoteSVG>
                )}
            </GridItem>
          ))}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Eleicao;
