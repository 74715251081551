import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import api from 'services/api';

import {
  Container,
  AlteredContent,
  Header,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { useCredentials } from 'hooks/credentials';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { setLocalStorage, getLocalStorage } from 'utils/handleLocalStorage';
import { formatNumber } from 'utils/calcTotal';

import { useSpring } from 'react-spring';

import { useWindow } from 'hooks/window';
import RadioContainer from 'components/RadioContainer';
import { formatDate } from 'utils/formatDate';
import Button from 'components/Button';
import { FaTimes } from 'react-icons/fa';

import { Table, TDtoRight, TRHeader, OtherTable } from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  chosenCoord?: string;
  coords?: string;
  stat?: string;
}

export interface ListProps {
  seq: string;
  cod: string;
  desc: string;
  g1: string;
  g2: string;
  offer: number;
  taxstat: string;
  uuid: string;
  dtoffer: string;
  loc: string;
  anb: string;
  dtcontab: string;
  repzon: string;
  dtrepzon: string;
  repint: string;
  dtrepint: string;
}

interface FormData {
  repassdt: string;
  repassval: string;
}

const OfertasRepasse: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const mode = process.env.REACT_APP_OFFER;
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();
  const { width } = useWindow();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  const [currentMode, setCurrentMode] = useState('NORMAL');

  const [initialCoord, setInitialCoord] = useState(() => {
    const { coords }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (coords) {
      return JSON.parse(coords);
    }
    return user.perfil === 'ZON'
      ? { anb: null, loc: null }
      : user.perfil === 'NAC'
      ? { anb: user.anbc, loc: null }
      : { anb: user.anbc, loc: user.loccod };
  });

  const [selectedCoord, setSelectedCoord] = useState(() => {
    const { chosenCoord }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (chosenCoord) {
      return JSON.parse(chosenCoord);
    }

    return user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc.replace('Brasil ', '') }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc.replace('Nacional ', '') }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [, setMinDate] = useState('');

  const [taxStat, setTaxStat] = useState(() => {
    const { stat }: LocalStorageProps = getLocalStorage(`${mode}`);

    return stat || 'N';
  });
  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [comboNacs, setComboNac] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNac(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get('/combos/comboLOCs.php');

    setLocs(response.data);

    setComboLocs(
      response.data.filter(
        (item: ComboLocalProps) => item.anb === initialCoord.anb,
      ),
    );
  }, [initialCoord.anb]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/oferta_list.php?data=${JSON.stringify({
          coord: selectedCoord.cod,
          mode: true,
        })}`,
      );

      const { fetchList, date } = response.data;
      let currMode = 'NORMAL';
      setRawList(fetchList);
      setMinDate(date);
      if (
        fetchList.reduce(
          (accum: number, { loc, anb, taxstat, repzon, repint }: ListProps) => {
            return (user.perfil === 'LOC' && loc === user.loccod
              ? taxstat
              : user.perfil === 'NAC' && anb === user.anbc
              ? repzon
              : repint) === 'B'
              ? accum + 1
              : accum;
            //
            // return accum;
          },
          0,
        ) > 0
      ) {
        currMode = 'BLOCK';
      }

      setCurrentMode(currMode);
      let filtered = [...fetchList];

      if (user.perfil === 'LOC') {
        filtered = filtered.filter(
          (item: ListProps) =>
            item.loc === user.loccod &&
            item.taxstat === (taxStat === 'S' ? 'S' : currMode.substring(0, 1)),
        );
      }

      if (user.perfil === 'NAC') {
        if (!initialCoord.loc) {
          filtered = filtered.filter(
            (item: ListProps) =>
              item.taxstat === 'S' &&
              item.anb === user.anbc &&
              item.repzon ===
                (taxStat === 'S' ? 'S' : currMode.substring(0, 1)),
          );
        } else {
          filtered = filtered.filter(
            (item: ListProps) =>
              item.loc === initialCoord.loc &&
              (taxStat === 'S' ? item.taxstat === 'S' : item.taxstat !== 'S'),
          );
        }
      }

      if (user.perfil === 'ZON') {
        if (!initialCoord.anb && !initialCoord.loc) {
          filtered = filtered.filter(
            (item: ListProps) =>
              item.taxstat === 'S' &&
              item.anb.substring(0, 2) === user.zoncod.substring(0, 2) &&
              item.repzon === 'S' &&
              item.repint ===
                (taxStat === 'S' ? 'S' : currMode.substring(0, 1)),
          );
        } else if (!!initialCoord.anb && !initialCoord.loc) {
          filtered = filtered.filter(
            (item: ListProps) =>
              item.taxstat === 'S' &&
              item.anb === initialCoord.anb &&
              (taxStat === 'S' ? item.repzon === 'S' : item.repzon !== 'S') &&
              item.repint === 'N',
          );
        } else if (!!initialCoord.anb && !!initialCoord.loc) {
          filtered = filtered.filter(
            (item: ListProps) =>
              item.loc === initialCoord.loc &&
              (taxStat === 'S' ? item.taxstat === 'S' : item.taxstat !== 'S') &&
              item.repzon === 'N' &&
              item.repint === 'N',
          );
        }
      }

      setList(filtered);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [
    selectedCoord.cod,
    user.perfil,
    user.loccod,
    user.anbc,
    user.zoncod,
    taxStat,
    initialCoord.loc,
    initialCoord.anb,
    errorHandling,
  ]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    if (!hasData) {
      if (user.perfil === 'ZON') {
        getComboANB();
      }
      if (user.perfil !== 'LOC') {
        getComboLOC();
      }
      setHasData(true);
    }
    getList();
  }, [
    handlePermission,
    getComboLOC,
    getList,
    hasData,
    user.perfil,
    getComboANB,
  ]);

  const handleNacSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboNac');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedCoord({ cod: value, desc: text });
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({ cod: value, desc: text }),
      coords: JSON.stringify({ anb: value, loc: null }),
    });

    setInitialCoord({ anb: value, loc: null });

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));
  }, [mode, locs]);

  const handleLocSelect = useCallback(() => {
    let theText = '';
    if (user.perfil === 'ZON') {
      const nacSelect = formRef.current?.getFieldRef('comboNac');
      theText = `${nacSelect.options[nacSelect.selectedIndex].text.substring(
        0,
        nacSelect.options[nacSelect.selectedIndex].text.indexOf('-') - 1,
      )} - `;
    }
    const select = formRef.current?.getFieldRef('comboLoc');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedCoord({
      cod: value,
      desc: `${user.perfil === 'ZON' ? `${theText}` : ''}${text}`,
    });

    let newValues = { anb: user.anbc, loc: 'null' };
    const { coords }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coords) {
      newValues = JSON.parse(coords);
    }
    newValues.loc = value;

    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({
        cod: value,
        desc: `${user.perfil === 'ZON' ? `${theText}` : ''}${text}`,
      }),
      coords: JSON.stringify(newValues),
    });

    setInitialCoord(newValues);
  }, [mode, user.anbc, user.perfil]);

  const handleRemoveNac = useCallback(() => {
    setInitialCoord({ anb: null, loc: null });
    setSelectedCoord({
      cod: user.zoncod,
      desc: user.zondesc.replace('Brasil ', ''),
    });
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({
        cod: user.zoncod,
        desc: user.zondesc.replace('Brasil ', ''),
      }),
      coords: JSON.stringify({ anb: null, loc: null }),
    });
    setComboLocs([]);
  }, [mode, user.zoncod, user.zondesc]);

  const handleRemoveLoc = useCallback(() => {
    setInitialCoord({ anb: user.anbc, loc: null });
    setSelectedCoord({
      cod: user.anbc,
      desc: user.anbdesc.replace('Nacional ', ''),
    });
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({
        cod: user.anbc,
        desc: user.anbdesc.replace('Nacional ', ''),
      }),
      coords: JSON.stringify({ anb: user.anbc, loc: null }),
    });
  }, [mode, user.anbc, user.anbdesc]);

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('taxstat');
    if (stat === taxStat) {
      return;
    }
    setTaxStat(stat);

    setList(rawList.filter((item) => item.taxstat === stat));
    setLocalStorage(`${process.env.REACT_APP_OFFER}`, {
      stat,
    });
  }, [rawList, taxStat]);

  const handleNavigateInc = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/insert`,
      state: {
        mode: user.perfil === 'ZON' ? 'E' : currentMode === 'BLOCK' ? 'E' : 'C',
        amount: list.reduce((accum, { offer }) => {
          const tot = accum + offer;

          return tot;
        }, 0),
      },
    });
  }, [currentMode, history, list, location.pathname, user.perfil]);

  const removeButtonNac = useSpring({
    opacity: initialCoord.anb !== null ? 1 : 0,
    pointerEvents: initialCoord.anb !== null ? 'all' : 'none',
  });
  const removeButton = useSpring({
    opacity: initialCoord.loc !== null ? 1 : 0,
    pointerEvents: initialCoord.loc !== null ? 'all' : 'none',
  });

  /* const [percent, setPercent] = useState()
  useEffect(() => {},[]); */

  const percentpre = useMemo(() => {
    let actualPercent = 1;
    // actualPercent *= 0.8;

    if (user.perfil === 'NAC' || (!!initialCoord.anb && !initialCoord.loc)) {
      actualPercent *= 0.8;
    }

    if (user.perfil === 'ZON' && !initialCoord.anb && !initialCoord.loc) {
      actualPercent *= 0.8 * 0.5;
    }

    return actualPercent;
  }, [initialCoord.anb, initialCoord.loc, user.perfil]);

  const percent = useMemo(() => {
    let actualPercent = 1;
    actualPercent *= 0.8;

    if (user.perfil === 'NAC') {
      if (!initialCoord.loc) {
        actualPercent *= 0.5;
      }
    }
    if (user.perfil === 'ZON') {
      if (!initialCoord.anb) {
        actualPercent *= 0.5;
      }
      if (!initialCoord.loc) {
        actualPercent *= 0.5;
      }
    }

    return actualPercent;
  }, [initialCoord.anb, initialCoord.loc, user.perfil]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form
            ref={formRef}
            onSubmit={() => null}
            initialData={{
              taxstat: taxStat,
            }}
          >
            {user.perfil === 'ZON' ? (
              <>
                <div>
                  <span>
                    <p>Filtre por Nacional:</p>
                    <SelectV2
                      name="comboNac"
                      content={comboNacs}
                      initial={initialCoord.anb || 'Selecione'}
                      onChange={handleNacSelect}
                    />
                  </span>
                </div>
                <RemoveButton
                  type="button"
                  onClick={handleRemoveNac}
                  style={removeButtonNac}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </>
            ) : null}
            {user.perfil !== 'LOC' ? (
              <>
                <div>
                  <span>
                    <p>Filtre por Local:</p>
                    <SelectV2
                      name="comboLoc"
                      content={comboLocs}
                      initial={initialCoord.loc || 'Selecione'}
                      onChange={handleLocSelect}
                    />
                  </span>
                </div>
                <RemoveButton
                  type="button"
                  onClick={handleRemoveLoc}
                  style={removeButton}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </>
            ) : null}

            <div>
              <span>
                <p>Repasse realizado:</p>
                <RadioContainer
                  title=""
                  onClick={handleRadioClick}
                  name="taxstat"
                  content={[
                    {
                      id: 'tS',
                      value: 'S',
                      label: 'Sim',
                    },
                    {
                      id: 'tN',
                      value: 'N',
                      label: 'Não',
                    },
                  ]}
                  selected={taxStat}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>Listagem Repasses</div>
        <div>{selectedCoord.desc}</div>
        <div>
          <p>Ofertas {taxStat === 'N' ? 'Não r' : 'R'}epassadas</p>
        </div>
      </Header>

      <AlteredContent pixels="273px">
        {initialCoord.loc ? (
          <Table>
            <tbody>
              {list.map((item, index) => {
                if (
                  index === 0 ||
                  (index !== 0 && list[index - 1].desc !== list[index].desc)
                ) {
                  return (
                    <React.Fragment key={item.uuid}>
                      <TRHeader>
                        <td
                          colSpan={
                            !initialCoord.anb && !initialCoord.loc
                              ? 7
                              : !initialCoord.loc
                              ? 6
                              : 5
                          }
                        >
                          <strong>{item.desc}</strong>
                        </td>
                      </TRHeader>
                      <tr>
                        {user.perfil === 'ZON' &&
                        !initialCoord.loc &&
                        !initialCoord.anb ? (
                          <td>{item.anb}</td>
                        ) : null}
                        {user.perfil !== 'LOC' && !initialCoord.loc ? (
                          <td>{item.loc}</td>
                        ) : null}

                        <TDtoRight>{item.seq}</TDtoRight>
                        <td>{formatDate(item.dtoffer)}</td>
                        <td>
                          {width >= 760 ? (
                            <>
                              {item.g1}
                              {item.g2 && ` / ${item.g2}`}
                            </>
                          ) : (
                            <>
                              {item.g1.substr(0, item.g1.indexOf(' '))}
                              {/* name.substr(0, name.indexOf(' ')) */}
                              {item.g2 &&
                                ` / ${item.g2.substr(0, item.g2.indexOf(' '))}`}
                            </>
                          )}
                        </td>
                        <td />
                        <TDtoRight>
                          R$&nbsp;{formatNumber(item.offer)}
                        </TDtoRight>
                      </tr>
                      {(index + 1 === list.length ||
                        list[index].desc !== list[index + 1].desc) && (
                        <>
                          <tr>
                            <td> </td>
                            <TDtoRight
                              colSpan={
                                !initialCoord.anb && !initialCoord.loc
                                  ? 5
                                  : !initialCoord.loc
                                  ? 4
                                  : 3
                              }
                            >
                              TOTAL
                            </TDtoRight>

                            <TDtoRight>
                              <strong style={{ color: '#8a0002' }}>
                                R$&nbsp;
                                {formatNumber(
                                  list.reduce((accum, { offer, cod }) => {
                                    const tot =
                                      item.cod === cod ? accum + offer : accum;
                                    return tot;
                                  }, 0),
                                )}
                              </strong>
                            </TDtoRight>
                          </tr>
                          {['ZON', 'NAC'].indexOf(user.perfil) > -1 &&
                          !initialCoord.loc ? (
                            <tr>
                              <td> </td>
                              <TDtoRight
                                colSpan={
                                  !initialCoord.anb && !initialCoord.loc
                                    ? 5
                                    : !initialCoord.loc
                                    ? 4
                                    : 3
                                }
                              >
                                TOTAL REPASSADO PELA LOCAL
                              </TDtoRight>

                              <TDtoRight>
                                <strong style={{ color: '#8a0002' }}>
                                  R$&nbsp;
                                  {formatNumber(
                                    list.reduce((accum, { offer, cod }) => {
                                      const tot =
                                        item.cod === cod
                                          ? accum + offer
                                          : accum;
                                      return tot;
                                    }, 0) * 0.8,
                                  )}
                                </strong>
                              </TDtoRight>
                            </tr>
                          ) : null}
                          {user.perfil === 'ZON' &&
                          !initialCoord.loc &&
                          !initialCoord.anb ? (
                            <tr>
                              <td> </td>
                              <TDtoRight
                                colSpan={
                                  !initialCoord.anb && !initialCoord.loc
                                    ? 5
                                    : !initialCoord.loc
                                    ? 4
                                    : 3
                                }
                              >
                                TOTAL REPASSADO PELA NACIONAL
                              </TDtoRight>

                              <TDtoRight>
                                <strong style={{ color: '#8a0002' }}>
                                  R$&nbsp;
                                  {formatNumber(
                                    list.reduce((accum, { offer, cod }) => {
                                      const tot =
                                        item.cod === cod
                                          ? accum + offer
                                          : accum;
                                      return tot;
                                    }, 0) *
                                      0.8 *
                                      0.5,
                                  )}
                                </strong>
                              </TDtoRight>
                            </tr>
                          ) : null}
                          <tr>
                            <TDtoRight
                              colSpan={
                                !initialCoord.anb && !initialCoord.loc
                                  ? 6
                                  : !initialCoord.loc
                                  ? 5
                                  : 4
                              }
                            >
                              TOTAL{' '}
                              {taxStat === 'N' ? 'a repassar' : 'repassado'}
                            </TDtoRight>

                            <TDtoRight>
                              <strong style={{ color: '#8a0002' }}>
                                R$&nbsp;
                                {formatNumber(
                                  list.reduce((accum, { offer, cod }) => {
                                    const tot =
                                      item.cod === cod ? accum + offer : accum;
                                    return tot;
                                  }, 0) * percent,
                                )}
                              </strong>
                            </TDtoRight>
                          </tr>
                        </>
                      )}
                    </React.Fragment>
                  );
                }
                if (
                  index + 1 === list.length ||
                  list[index].desc !== list[index + 1].desc
                ) {
                  return (
                    <React.Fragment key={item.uuid}>
                      <tr>
                        {user.perfil === 'ZON' &&
                        !initialCoord.loc &&
                        !initialCoord.anb ? (
                          <td>{item.anb}</td>
                        ) : null}
                        {user.perfil !== 'LOC' && !initialCoord.loc ? (
                          <td>{item.loc}</td>
                        ) : null}
                        <TDtoRight>{item.seq}</TDtoRight>
                        <td>{formatDate(item.dtoffer)}</td>
                        <td>
                          {width >= 760 ? (
                            <>
                              {item.g1}
                              {item.g2 && ` / ${item.g2}`}
                            </>
                          ) : (
                            <>
                              {item.g1.substr(0, item.g1.indexOf(' '))}
                              {/* name.substr(0, name.indexOf(' ')) */}
                              {item.g2 &&
                                ` / ${item.g2.substr(0, item.g2.indexOf(' '))}`}
                            </>
                          )}
                        </td>
                        <td />
                        <TDtoRight>
                          R$&nbsp;{formatNumber(item.offer)}
                        </TDtoRight>
                      </tr>

                      <tr>
                        <TDtoRight
                          colSpan={
                            !initialCoord.anb && !initialCoord.loc
                              ? 6
                              : !initialCoord.loc
                              ? 5
                              : 4
                          }
                        >
                          TOTAL
                        </TDtoRight>

                        <TDtoRight>
                          <strong style={{ color: '#8a0002' }}>
                            R$&nbsp;
                            {formatNumber(
                              list.reduce((accum, { offer, cod }) => {
                                const tot =
                                  item.cod === cod ? accum + offer : accum;
                                return tot;
                              }, 0),
                            )}
                          </strong>
                        </TDtoRight>
                      </tr>
                      {['ZON', 'NAC'].indexOf(user.perfil) > -1 &&
                      !initialCoord.loc ? (
                        <tr>
                          <td> </td>
                          <TDtoRight
                            colSpan={
                              !initialCoord.anb && !initialCoord.loc
                                ? 5
                                : !initialCoord.loc
                                ? 4
                                : 3
                            }
                          >
                            TOTAL REPASSADO PELA LOCAL
                          </TDtoRight>

                          <TDtoRight>
                            <strong style={{ color: '#8a0002' }}>
                              R$&nbsp;
                              {formatNumber(
                                list.reduce((accum, { offer, cod }) => {
                                  const tot =
                                    item.cod === cod ? accum + offer : accum;
                                  return tot;
                                }, 0) * 0.8,
                              )}
                            </strong>
                          </TDtoRight>
                        </tr>
                      ) : null}
                      {user.perfil === 'ZON' &&
                      !initialCoord.loc &&
                      !initialCoord.anb ? (
                        <tr>
                          <td> </td>
                          <TDtoRight
                            colSpan={
                              !initialCoord.anb && !initialCoord.loc
                                ? 5
                                : !initialCoord.loc
                                ? 4
                                : 3
                            }
                          >
                            TOTAL REPASSADO PELA NACIONAL
                          </TDtoRight>

                          <TDtoRight>
                            <strong style={{ color: '#8a0002' }}>
                              R$&nbsp;
                              {formatNumber(
                                list.reduce((accum, { offer, cod }) => {
                                  const tot =
                                    item.cod === cod ? accum + offer : accum;
                                  return tot;
                                }, 0) *
                                  0.8 *
                                  0.5,
                              )}
                            </strong>
                          </TDtoRight>
                        </tr>
                      ) : null}
                      <tr>
                        <TDtoRight
                          colSpan={
                            !initialCoord.anb && !initialCoord.loc
                              ? 6
                              : !initialCoord.loc
                              ? 5
                              : 4
                          }
                        >
                          TOTAL {taxStat === 'N' ? 'a repassar' : 'repassado'}
                        </TDtoRight>

                        <TDtoRight>
                          <strong style={{ color: '#8a0002' }}>
                            R$&nbsp;
                            {formatNumber(
                              list.reduce((accum, { offer, cod }) => {
                                const tot =
                                  item.cod === cod ? accum + offer : accum;
                                return tot;
                              }, 0) * percent,
                            )}
                          </strong>
                        </TDtoRight>
                      </tr>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={item.uuid}>
                    <tr>
                      {user.perfil === 'ZON' &&
                      !initialCoord.loc &&
                      !initialCoord.anb ? (
                        <td>{item.anb}</td>
                      ) : null}
                      {user.perfil !== 'LOC' && !initialCoord.loc ? (
                        <td>{item.loc}</td>
                      ) : null}
                      <TDtoRight>{item.seq}</TDtoRight>
                      <td>{formatDate(item.dtoffer)}</td>
                      <td>
                        {width >= 760 ? (
                          <>
                            {item.g1}
                            {item.g2 && ` / ${item.g2}`}
                          </>
                        ) : (
                          <>
                            {item.g1.substr(0, item.g1.indexOf(' '))}
                            {/* name.substr(0, name.indexOf(' ')) */}
                            {item.g2 &&
                              ` / ${item.g2.substr(0, item.g2.indexOf(' '))}`}
                          </>
                        )}
                      </td>
                      <td />
                      <TDtoRight>R$&nbsp;{formatNumber(item.offer)}</TDtoRight>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr>
                <TDtoRight
                  colSpan={
                    !initialCoord.anb && !initialCoord.loc
                      ? 7
                      : !initialCoord.loc
                      ? 6
                      : 5
                  }
                >
                  ----------------------------------------
                </TDtoRight>
              </tr>
              <tr>
                <TDtoRight
                  colSpan={
                    !initialCoord.anb && !initialCoord.loc
                      ? 6
                      : !initialCoord.loc
                      ? 5
                      : 4
                  }
                >
                  TOTAL ofertas
                </TDtoRight>
                <TDtoRight>
                  <strong style={{ color: '#8a0002' }}>
                    R$&nbsp;
                    {formatNumber(
                      list.reduce((accum, { offer }) => {
                        const tot = accum + offer;
                        return tot;
                      }, 0),
                    )}
                  </strong>
                </TDtoRight>
              </tr>
              <tr>
                <TDtoRight
                  colSpan={
                    !initialCoord.anb && !initialCoord.loc
                      ? 6
                      : !initialCoord.loc
                      ? 5
                      : 4
                  }
                >
                  TOTAL {taxStat === 'S' ? 'repassado' : 'a repassar'}
                </TDtoRight>
                <TDtoRight>
                  <strong style={{ color: '#8a0002' }}>
                    R$&nbsp;
                    {formatNumber(
                      list.reduce((accum, { offer }) => {
                        const tot = accum + offer;
                        return tot;
                      }, 0) * 0.8,
                    )}
                  </strong>
                </TDtoRight>
              </tr>
              {taxStat === 'N' &&
              list.length > 0 &&
              (user.perfil === 'LOC' ||
                (user.perfil === 'NAC' && !initialCoord.loc) ||
                (user.perfil === 'ZON' &&
                  !initialCoord.loc &&
                  !initialCoord.anb)) ? (
                <tr>
                  <td
                    colSpan={
                      !initialCoord.anb && !initialCoord.loc
                        ? 7
                        : !initialCoord.loc
                        ? 6
                        : 5
                    }
                  >
                    <Button
                      style={{
                        marginLeft: '100%',
                        transform: 'translateX(-100%)',
                      }}
                      bgcolor="#00802b"
                      onClick={handleNavigateInc}
                    >
                      {currentMode === 'BLOCK'
                        ? 'Efetivar repasse à '
                        : `Prévia do repasse à `}
                      {user.perfil === 'ZON'
                        ? 'Internacional'
                        : user.perfil === 'NAC'
                        ? 'Zonal'
                        : 'Nacional'}
                    </Button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        ) : (
          <>
            <OtherTable>
              <tbody>
                {list.map((item, index) => {
                  if (
                    index === 0 ||
                    (index !== 0 && list[index - 1].desc !== list[index].desc)
                  ) {
                    return (
                      <tr key={item.cod}>
                        <td colSpan={5}>{item.desc}</td>
                        <TDtoRight>
                          R$&nbsp;
                          {formatNumber(
                            list.reduce((accum, { offer, cod }) => {
                              const tot =
                                item.cod === cod ? accum + offer : accum;
                              return tot;
                            }, 0) * percentpre,
                          )}
                        </TDtoRight>
                      </tr>
                    );
                  }
                  return null;
                })}
                <tr />
                <tr />
                <tr />
              </tbody>
            </OtherTable>
            <OtherTable style={{ margin: '15px 0 0 0' }}>
              <tbody>
                <tr>
                  <td>Total ofertas recebidas</td>
                  <TDtoRight>
                    R$&nbsp;
                    {formatNumber(
                      list.reduce((accum, { offer }) => {
                        const tot = accum + offer;
                        return tot;
                      }, 0) * percentpre,
                    )}
                  </TDtoRight>
                </tr>
                <tr>
                  <td>Total {taxStat === 'S' ? 'repassadas' : 'a repassar'}</td>
                  <TDtoRight>
                    R$&nbsp;
                    {formatNumber(
                      list.reduce((accum, { offer }) => {
                        const tot = accum + offer;
                        return tot;
                      }, 0) * percent,
                    )}
                  </TDtoRight>
                </tr>
                <tr>
                  {taxStat === 'N' &&
                  list.length > 0 &&
                  (user.perfil === 'LOC' ||
                    (user.perfil === 'NAC' && !initialCoord.loc) ||
                    (user.perfil === 'ZON' &&
                      !initialCoord.loc &&
                      !initialCoord.anb)) ? (
                    <td colSpan={2}>
                      <Button
                        style={{
                          marginLeft: '100%',
                          transform: 'translateX(-100%)',
                        }}
                        bgcolor="#00802b"
                        onClick={handleNavigateInc}
                      >
                        {currentMode === 'BLOCK'
                          ? 'Efetivar repasse à '
                          : `Prévia do repasse à `}
                        {user.perfil === 'ZON'
                          ? 'Internacional'
                          : user.perfil === 'NAC'
                          ? 'Zonal'
                          : 'Nacional'}
                      </Button>
                    </td>
                  ) : null}
                </tr>
              </tbody>
            </OtherTable>
          </>
        )}
        {/* <TotalContainer>
          <div>
            <strong>
              Total bruto das ofertas:&nbsp;
              <span>
                R$&nbsp;
                {formatNumber(
                  list.reduce((accum, { offer }) => {
                    const tot = accum + offer;

                    return tot;
                  }, 0),
                )}
              </span>
            </strong>
          </div>
          <div>
            <strong>
              TOTAL {taxStat === 'N' ? 'a repassar' : 'repassado'}:&nbsp;
              <span>
                R$&nbsp;
                {formatNumber(
                  list.reduce((accum, { offer }) => {
                    const tot = accum + offer;

                    return tot;
                  }, 0) * percent,
                )}
              </span>
            </strong>
          </div>
        </TotalContainer> */}
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default OfertasRepasse;
