import styled, { keyframes } from 'styled-components';

const pulseHorizontal = keyframes`
    from{
      right: 25px;
    } to {
      right: 15px;
    }
  `;

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  column-gap: 20px;
  padding: 10px 20px;

  min-height: calc(100vh - 330px);

  section {
    display: flex;
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
    flex-direction: column-reverse;
    column-gap: unset;
    row-gap: 20px;
    padding: 10px 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  margin: auto 0;
  height: 100%;
  min-height: calc(100vh - 330px);

  padding: 15px 100px;
  width: 100vw;
  background: #d6d6d6;

  a {
    text-decoration: none;
  }
  div {
    height: 400px;
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;

export const BooksFrei = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  border: 5px solid #c6c6c6;
  transition: all 0.5s;
  border-radius: 10px;
  background: #dfdfdf;
  padding: 50px 0;
  width: 500px;

  span {
    background: linear-gradient(
      to right,
      rgba(214, 214, 214, 0),
      #bc4143,
      #8a0002,
      #bc4143,
      rgba(214, 214, 214, 0),
      rgba(214, 214, 214, 0),
      rgba(214, 214, 214, 0)
    );
    display: flex;
    flex-direction: column;

    h1 {
      color: #fff;
      padding: 0 50px;
      font-size: 4.8rem;
      transition: all 0.5s;

      & + h1 {
        margin-top: -20px;
      }
    }

    p {
      margin-top: 35px;
      opacity: 0;
      transform: translateX(-150px);
      pointer-events: none;
      font-size: 2.5rem;
      color: #c53030;
      transition: all 0.5s;
    }

    & + span {
      background: transparent;
    }
  }

  > p {
    z-index: 2;
    position: absolute;
    bottom: 10px;
    right: 25px;
    opacity: 1;
    pointer-events: none;

    font-size: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: #efefef;

    transition: all 0.5s;

    svg {
      margin: 0 5px;

      height: 50px;
      width: 50px;
      transform-style: preserve-3d;

      transition: transform 0.5s;
    }
  }

  &:hover {
    border-color: #bc4143;
    box-shadow: 0px 0px 15px 2px rgba(138, 0, 2, 0.85);
    div {
      bottom: 0%;
      right: -15%;
    }

    span {
      h1 {
        transform: translateX(15px);
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
        & + h1 {
          transform: translateX(-30px);
        }
      }

      p {
        opacity: 1;
        pointer-events: all;
        transform: translateX(100px);
        text-shadow: 0px 0px 5px rgba(138, 0, 2, 0.5);
      }
    }

    > p {
      animation: ${pulseHorizontal} 0.5s alternate infinite ease;

      svg {
        /* fill: #8a0002;
        stroke: #fff;
        stroke-width: 15px; */
      }
    }
  }

  @media screen and (max-width: 850px) {
    border-color: #bc4143;
    box-shadow: 0px 0px 15px 2px rgba(138, 0, 2, 0.85);

    div {
      bottom: 0%;
      right: -25%;
    }

    span {
      h1 {
        transform: translateX(50px);
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
        & + h1 {
          transform: translateX(-10px);
          margin-top: -10px;
        }
      }
      p {
        opacity: 1;
        pointer-events: all;
        transform: translateX(85px);
        text-shadow: 0px 0px 5px rgba(138, 0, 2, 0.5);
        margin-top: 35px;
      }
      & + span {
      }
    }
    > p {
      animation: ${pulseHorizontal} 0.5s alternate infinite ease;
    }

    &:hover {
      border-color: #bc4143;
      box-shadow: 0px 0px 15px 2px rgba(138, 0, 2, 0.85);

      div {
        bottom: 0%;
        right: -25%;
      }

      span {
        h1 {
          transform: translateX(50px);
          text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
          & + h1 {
            transform: translateX(-10px);
            margin-top: -10px;
          }
        }
        p {
          opacity: 1;
          pointer-events: all;
          transform: translateX(85px);
          text-shadow: 0px 0px 5px rgba(138, 0, 2, 0.5);
          margin-top: 35px;
        }
        & + span {
        }
      }
      > p {
        animation: ${pulseHorizontal} 0.5s alternate infinite ease;
      }
    }
  }

  @media screen and (max-width: 550px) {
    div {
      bottom: -25%;
    }
    &:hover {
      div {
        bottom: -25%;
      }
    }
  }
`;

export const Frei = styled.div`
  position: absolute;
  bottom: 0%;
  right: -20%;
  height: 300px;
  width: 363px;
  transition: all 0.5s;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.25s;

  img {
    /* display: none; */
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 0 0;

    transition: all 0.25s;
  }

  @media screen and (max-width: 400px) {
    right: 0%;
    img {
      height: 75%;
      width: 75%;
    }
  }
`;

export const FreiInfo = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 350px; */
  width: 100%;
  /* max-width: 600px; */
  height: auto !important;
  padding: 3px 5px;
  font-size: 18px;
  color: #332e2e;

  /* margin: 0 30px 0 0; */

  padding: 10px;
  background: #efefef;
  border: 2px solid #efefef;

  border-right: 0px solid transparent;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
`;

export const TextSection = styled.section`
  width: 70%;
  padding: 0px 20px 10px;
  margin: auto;
  border: 3px solid #464646;
  border-radius: 5px;
  background: #d6d6d6;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  div {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  h3 {
    font-size: 24px;
    text-decoration: underline;
    margin: 0 0 10px 0;
    transform: translateX(-15px);
  }

  p {
    font-size: 18px;
    text-align: justify;
  }

  @media screen and (max-width: 900px) {
    width: 100vw;
    margin: unset;
    padding: 0 0 0 0;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    div {
      padding: 0 10px;
    }
    h3 {
      transform: unset;
    }

    p {
    }
  }
`;

export const ImageSection = styled.section`
  --customWidth: 400px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(97, 97, 97, 1) 0%,
    rgba(198, 198, 198, 1) 60%
  );

  margin: auto;
  overflow: hidden;
  min-width: var(--customWidth);
  max-width: var(--customWidth);
  min-height: var(--customWidth);
  max-height: var(--customWidth);
  border: 4px solid #464646;

  border-radius: 50%;
  box-shadow: 2px 10px 10px 3px rgba(0, 0, 0, 0.5);

  img {
    position: absolute;
    bottom: 0px;
    transition: transform 0.35s;
    object-position: center;
    object-fit: contain;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 1200px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 400px) {
    --customWidth: 90vw;

    img {
      height: 100%;
    }
  }
`;
