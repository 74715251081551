import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';

interface WindowHookData {
  width: number;
  height: number;
}

const WindowHook = createContext<WindowHookData>({} as WindowHookData);

const WindowProvider: React.FC = ({ children }) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const updateWidthAndHeight = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    updateWidthAndHeight();
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return (
    <WindowHook.Provider value={{ width, height }}>
      {children}
    </WindowHook.Provider>
  );
};

function useWindow(): WindowHookData {
  const context = useContext(WindowHook);

  if (!context) {
    throw new Error('useWindow must be used within and WindowProvider');
  }

  return context;
}

export { WindowProvider, useWindow };
