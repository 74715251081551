import styled from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

export const Grid = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;

  width: 100vw;
  max-width: 1366px;

  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: 1366px) {
    padding: 0 5px;
  }

  @media screen and (max-width: 1150px) {
    grid: auto / 1fr 1fr;
    max-width: 800px;
  }

  @media screen and (max-width: 770px) {
    grid: auto / 1fr;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

export const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  span {
    display: flex;
    flex-direction: row;

    p {
      font-size: 16px;
      color: #767676;
      strong {
        color: #332e2e;
      }

      & + p {
        margin: 0 0 0 7px;
      }
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 400px) {
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;

export const VoteSVG = styled.button`
  position: absolute;
  bottom: 5px;
  right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 2px solid transparent;
  border-radius: 5px;

  padding: 5px;

  transition: all 0.35s ease;
  a {
    display: flex;
    height: 100%;
    width: 100%;
    svg {
      height: 25px;
      width: 25px;
      color: #332e2e;
      transition: all 0.35s ease;
    }
  }

  &:hover {
    background-color: #004718;
    border-color: #004718;
    a {
      svg {
        color: #fff;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;

  border: 2px solid #464646;
  border-radius: 5px;
  background: #464646;
  overflow: hidden;
  padding: 5px 10px;
  text-decoration: none;
  overflow: hidden;

  font-size: 16px;
  color: #fff;

  height: 32px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }

  transition: all 0.35s;

  &:hover {
    background-color: ${shade(0.2, '#464646')};
  }

  @media screen and (min-width: 471px) {
    opacity: 0;
    pointer-events: none;
    height: 0px;
    margin: 0px;
  }
`;
