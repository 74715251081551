import styled from 'styled-components';

export const Grid = styled.div`
  margin: 25px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  place-items: center;
  width: 100vw;

  a {
    position: relative;
    border: 2px solid transparent;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 180px;
    text-decoration: none;
    padding: 5px 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    filter: grayscale(80%);

    transition: all 0.5s ease;
    /* background: #fff; */
    img {
      height: 110px;
      object-fit: contain;
      /* border-radius: 50%;
      width: 100px; */
    }

    p {
      text-align: center;
      position: absolute;
      bottom: 5px;

      padding: 5px 10px;
      /* left: 5px; */
      font-size: 16px;
      width: 100%;
      color: #464646;
    }

    &:hover {
      filter: grayscale(0%);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (max-width: 1235px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 875px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 640px) {
    grid: auto / 1fr 1fr;
    a {
      filter: grayscale(0%);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
  @media screen and (max-width: 450px) {
    grid: auto / 1fr;
  }
`;
