import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import IncludeButton from 'components/IncludeButton';

import {
  createArray,
  removeDups,
  CreateArrayProps,
} from 'components/AutoComplete';

import { useSpring } from 'react-spring';

import api from 'services/api';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';

import { FaTimes, FaSearch, FaPencilAlt } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
import {
  Container,
  Header,
  FloatInput,
  AlteredContent,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
  CoordProps,
} from 'styles/sgo_wrappers';

import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorageItemKey,
} from 'utils/handleLocalStorage';
import { removeAcento } from 'utils/specialChars';
import { formatDate, handleTimeZone } from 'utils/formatDate';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as O from 'styles/option_buttons';

import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';
import { GridContainer, GridItem, AlteredLink, AlteredButton } from './styles';

import { ItemProps } from '../insert';

interface LocalStorageProps {
  anb?: string;
  loc?: string;
  localyear?: string;
  filterBy?: string;
  coordStringfied?: string;
}

export interface ListProps {
  svcseq: string;
  anb: string;
  loc: string;
  ini: string;
  end: string;
  tscod: string;
  tsdesc: string;
  theme: string;
  attendants: string;
  anbd: string;
  locd: string;
  place: string;
  applicator: string;
  refyear: string;
  // valor: number;
  otherparts: string;
  content: {
    expense: ItemProps[];
    revenue: ItemProps[];
  };
  svccoor: { seq: number; name: string; anbcod: string; loccod: string };
}

const RelatFinEventos: React.FC = () => {
  const [mode] = useState(process.env.REACT_APP_RELATFINEVEN);

  const { user } = useAuth();
  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();

  const { pathname } = useLocation();
  // const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { coordStringfied }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coordStringfied) {
      const { cod, desc } = JSON.parse(coordStringfied);
      return { cod, desc };
    }
    return ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);

    return anb || 'Selecione';
  });
  const [comboNac, setComboNac] = useState<ComboProps[]>([]);

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return loc || 'Selecione';
  });
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLoc, setComboLoc] = useState<ComboProps[]>([]);

  const [initialYear, setInitialYear] = useState(() => {
    const { localyear }: LocalStorageProps = getLocalStorage(`${mode}`);

    return localyear || '';
  });
  const [comboYear, setComboYear] = useState<ComboProps[]>([]);

  const [selected, setSelected] = useState({} as ListProps);

  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);

  const buildComboYear = useCallback(
    (array: CreateArrayProps[]) => {
      const { localyear }: LocalStorageProps = getLocalStorage(`${mode}`);

      const yearBuild = createArray(
        removeDups(array, 'refyear'),
        'refyear',
        'refyear',
      ).map((item) => ({ label: item.label, value: item.label }));
      // .reverse();

      setComboYear([{ value: '', label: 'Todos os Anos' }, ...yearBuild]);

      if (localyear === '') {
        return;
      }

      let currYear =
        localyear || handleTimeZone(new Date()).getUTCFullYear().toString();
      if (yearBuild.map((item) => item.value).indexOf(currYear) < 0) {
        currYear =
          yearBuild.length > 0
            ? yearBuild[0].value
            : handleTimeZone(new Date()).getUTCFullYear().toString();
        setInitialYear(currYear);
      }

      setLocalStorage(`${mode}`, {
        localyear: currYear,
      });
    },
    [mode],
  );

  const getList = useCallback(async () => {
    try {
      const response = await api.post('/sgo/relatorio_eventos_list.php');

      const { anb, loc, localyear }: LocalStorageProps = getLocalStorage(
        `${mode}`,
      );
      setRawList(response.data);

      let filtered = response.data;

      if (anb) {
        filtered = filtered.filter((item: ListProps) => item.anb === anb);
      }

      if (loc) {
        filtered = filtered.filter((item: ListProps) => item.loc === loc);
      }

      buildComboYear(filtered);
      if (localyear) {
        filtered = filtered.filter(
          (item: ListProps) => item.refyear === localyear,
        );
      }

      setList(filtered);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [buildComboYear, errorHandling, mode]);

  const handleListSetup = useCallback(
    (key: string, value: string, zonmode?: boolean) => {
      let filtered = (rawList as unknown[]) as CreateArrayProps[];

      filtered = filtered.filter((item: CreateArrayProps) =>
        zonmode
          ? item[key].toString().substr(0, 2) === value.substr(0, 2)
          : item[key] === value,
      );

      buildComboYear(filtered);

      const { localyear }: LocalStorageProps = getLocalStorage(`${mode}`);

      if (localyear !== '') {
        filtered = filtered.filter((item) => item.refyear === localyear);
      }

      setList((filtered as unknown[]) as ListProps[]);
    },
    [buildComboYear, mode, rawList],
  );

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNac(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (anb || user.perfil === 'NAC') {
      setComboLoc(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [mode, user.anbc, user.perfil]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);

    const { filterBy, localyear }: LocalStorageProps = getLocalStorage(
      `${mode}`,
    );

    if (!filterBy) {
      setLocalStorage(`${mode}`, {
        filterBy: user.perfil,
      });
    }

    if (!localyear) {
      setLocalStorage(`${mode}`, {
        localyear: '',
      });
    }

    if (user.perfil === 'ZON') {
      getComboANB();
    }

    if (user.perfil !== 'LOC') {
      getComboLOC();
    }
    getList();
  }, [getComboANB, getComboLOC, getList, handlePermission, mode, user.perfil]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const {
        anb,
        loc,
        filterBy,
        localyear,
      }: LocalStorageProps = getLocalStorage(`${mode}`);
      let filtered = rawList;

      if (filterBy === 'ZON') {
        filtered = filtered.filter(
          (item) => item.anb.substr(0, 2) === user.zoncod.substring(0, 2),
        );
      }

      if (filterBy === 'NAC') {
        filtered = filtered.filter((item) => item.anb === (anb || user.anbc));
      }
      if (filterBy === 'LOC') {
        filtered = filtered.filter((item) => item.loc === (loc || user.loccod));
      }

      if (localyear !== '') {
        filtered = filtered.filter((item) => item.refyear === localyear);
      }

      setList(
        filtered.filter((item) =>
          removeAcento(item.tsdesc)
            .toLowerCase()
            .includes(removeAcento(val).toLowerCase()),
        ),
      );
    },
    [mode, rawList, user.anbc, user.loccod, user.zoncod],
  );

  const handleNacSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('nacional');
    const { value, text } = select.options[select.selectedIndex];

    setInitialNac(value);
    setInitialLoc('Selecione');
    setComboLoc(locs.filter((item) => item.anb === value));
    setLocalStorage(`${mode}`, {
      anb: value,
      filterBy: 'NAC',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });

    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    handleListSetup('anb', value);
  }, [handleListSetup, locs, mode]);

  const handleLocSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('local');
    const { value, text } = select.options[select.selectedIndex];

    setInitialLoc(value);
    setLocalStorage(`${mode}`, {
      loc: value,
      filterBy: 'LOC',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });

    handleListSetup('loc', value);
  }, [handleListSetup, mode]);

  const handleYearSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('year');
    const { value } = select.options[select.selectedIndex];

    setInitialYear(value);
    setLocalStorage(`${mode}`, {
      localyear: value,
    });

    const { anb, loc, filterBy }: LocalStorageProps = getLocalStorage(
      `${mode}`,
    );

    let filtered = rawList;

    if (value !== '') {
      filtered = filtered.filter((item) => item.refyear === value);
    }

    if (filterBy === 'ZON') {
      filtered = filtered.filter(
        (item) => item.anb.substr(0, 2) === user.zoncod.substring(0, 2),
      );
    }

    if (filterBy === 'NAC') {
      filtered = filtered.filter((item) => item.anb === (anb || user.anbc));
    }
    if (filterBy === 'LOC') {
      filtered = filtered.filter((item) => item.loc === (loc || user.loccod));
    }

    setList(filtered);
  }, [mode, rawList, user.anbc, user.loccod, user.zoncod]);

  const handleRemoveNacFilter = useCallback(() => {
    deleteLocalStorageItemKey(`${mode}`, ['loc', 'anb']);
    const thisCoord = { cod: user.zoncod, desc: user.zondesc };
    setComboLoc([]);
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    setCoord(thisCoord);
    setLocalStorage(`${mode}`, {
      filterBy: 'ZON',
      coordStringfied: JSON.stringify(thisCoord),
    });

    handleListSetup('anb', thisCoord.cod, true);
  }, [handleListSetup, mode, user.zoncod, user.zondesc]);

  const handleRemoveLocFilter = useCallback(() => {
    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    if (user.perfil !== 'NAC') {
      const select = formRef.current?.getFieldRef('nacional');
      const { text, value } = select.options[select.selectedIndex];
      setCoord({
        cod: value,
        desc: text,
      });
      handleListSetup('anb', value);

      setLocalStorage(`${mode}`, {
        filterBy: 'NAC',
        coordStringfied: JSON.stringify({
          cod: value,
          desc: text,
        }),
      });
      return;
    }
    setCoord({
      cod: user.anbc,
      desc: user.anbdesc,
    });

    setLocalStorage(`${mode}`, {
      filterBy: 'NAC',
      coordStringfied: JSON.stringify({
        cod: user.anbc,
        desc: user.anbdesc,
      }),
    });
    handleListSetup('anb', user.anbc);
  }, [handleListSetup, mode, user.anbc, user.anbdesc, user.perfil]);

  const handlePreDelete = useCallback(
    (value: string) => {
      const index = rawList.findIndex((item) => item.svcseq === value);

      setSelected({ ...rawList[index] });
      setDeleteDiag((state) => ({ ...state, open: true }));
    },
    [rawList],
  );

  const handleCancelDel = useCallback(() => {
    setTimeout(() => {
      setSelected({} as ListProps);
    }, 500);
    setDeleteDiag((state) => ({ ...state, open: false }));
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      const send = new FormData();
      send.append('data', JSON.stringify({ ...selected }));

      await api.post('/sgo/relatorio_eventos_delete.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setList((state) =>
        state.filter((item) => item.svcseq !== selected.svcseq),
      );
      setRawList((state) =>
        state.filter((item) => item.svcseq !== selected.svcseq),
      );

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Relatório Financeiro  do ${
          selected.tsdesc
        } de ${formatDate(selected.ini)} excluído.`,
        seconds: 15,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, errorHandling, selected]);

  const removeANBButton = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeLOCButton = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil !== 'ZON' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Nacional:</p>
                  <SelectV2
                    name="nacional"
                    content={comboNac}
                    initial={initialNac}
                    onChange={handleNacSelect}
                  />
                  <RemoveButton
                    style={removeANBButton}
                    onClick={handleRemoveNacFilter}
                    type="button"
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {user.perfil !== 'LOC' && (
                <span>
                  <p>Local:</p>
                  <SelectV2
                    name="local"
                    content={comboLoc}
                    initial={initialLoc}
                    onChange={handleLocSelect}
                  />
                  <RemoveButton
                    style={removeLOCButton}
                    onClick={handleRemoveLocFilter}
                    type="button"
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}

              <span>
                <p>Ano:</p>
                <SelectV2
                  name="year"
                  content={comboYear}
                  initial={initialYear}
                  onChange={handleYearSelect}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>
            Relatório Financeiro de Eventos TOV
            {initialYear !== '' ? ` / ${initialYear}` : ''}
          </p>
        </div>
        <div>
          <p>{coord.desc}</p>
        </div>
      </Header>
      <FloatInput amount="128px">
        <input
          placeholder="Filtrar por Atividade"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="302px">
        <GridContainer>
          {list.map((item) => (
            <GridItem
              key={item.svcseq}
              borderColor={
                item.tscod === 'AI'
                  ? '#8a0002'
                  : item.tscod === 'AN'
                  ? '#c53030'
                  : item.tscod === 'RE'
                  ? '#00802b'
                  : item.tscod === 'RD'
                  ? '#fce205'
                  : item.tscod === 'ED'
                  ? '#4fc3f7'
                  : item.tscod === 'JF'
                  ? '#7159c1'
                  : '#efefef'
              }
            >
              <span>
                <strong style={{ color: '#c53030' }}>
                  {item.tsdesc} ({item.svcseq})
                </strong>
              </span>
              <span style={{ marginTop: '15px' }}>
                <p>
                  Início:&nbsp;<strong>{formatDate(item.ini)}</strong>
                </p>
              </span>

              <span>
                <strong>{item.anbd}</strong>
              </span>

              <span>
                <strong>{item.locd}</strong>
              </span>

              <O.GridOptions>
                <AlteredLink
                  bgcolor="#464646"
                  to={{ pathname: `${pathname}/more`, state: { ...item } }}
                >
                  <FaSearch />
                </AlteredLink>
                {((user.perfil === 'NAC' &&
                  item.anb === item.loc &&
                  item.anb === user.anbc) ||
                  (user.perfil === 'LOC' &&
                    item.anb !== item.loc &&
                    item.loc === user.loccod)) &&
                  item.refyear === new Date().getFullYear().toString() && (
                    <>
                      <AlteredLink
                        bgcolor="#007acc"
                        to={{
                          pathname: `${pathname}/update`,
                          state: { ...item },
                        }}
                      >
                        <FaPencilAlt />
                      </AlteredLink>
                      {/* {item.valor === 0 && ( */}
                      <AlteredButton
                        bgcolor="#c53030"
                        type="button"
                        onClick={() => handlePreDelete(item.svcseq)}
                      >
                        <FaTimes />
                      </AlteredButton>
                      {/* )} */}
                    </>
                  )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>
          <D.ModalDeleteContent>
            <p>
              Você está prestes a excluir&nbsp;
              <strong style={{ color: '#c53030' }}>permanentemente</strong>
              &nbsp;o registro:
            </p>
            <div>
              <span>
                <strong style={{ color: '#c53030' }}>{selected.tsdesc}</strong>
              </span>
              <span style={{ marginTop: '15px' }}>
                <p>
                  Início:&nbsp;<strong>{formatDate(selected.ini)}</strong>
                </p>
              </span>

              <span>
                <strong>{selected.anbd}</strong>
              </span>

              <span>
                <strong>{selected.locd}</strong>
              </span>
            </div>
            <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
          </D.ModalDeleteContent>
        </D.Content>
        <D.Actions>
          <D.Cancel type="button" onClick={handleCancelDel}>
            Cancelar
          </D.Cancel>
          <D.Confirm type="button" onClick={handleDelete}>
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <SGOFooter />
    </Container>
  );
};

export default RelatFinEventos;
