import React, {
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

import {
  phoneMasking,
  currencyMasking,
  cnpjMasking,
  cpfMasking,
  cepMasking,
  numberMasking,
  nameMasking,
  alphaMasking,
} from './masks';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerStyle?: object;
  altHeight?: number;
  mask?:
    | 'phone'
    | 'cellphone'
    | 'currency'
    | 'cnpj'
    | 'cpf'
    | 'cep'
    | 'number'
    | 'name'
    | 'alpha';
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  mask,
  icon: Icon,
  containerStyle = {},
  altHeight = 125,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!textareaRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  const maskIt = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      if (mask === 'phone') {
        phoneMasking(e);
      }

      if (mask === 'currency') {
        currencyMasking(e);
      }

      if (mask === 'cnpj') {
        cnpjMasking(e);
      }

      if (mask === 'cpf') {
        cpfMasking(e);
      }

      if (mask === 'cep') {
        cepMasking(e);
      }

      if (mask === 'number') {
        numberMasking(e);
      }

      if (mask === 'name') {
        nameMasking(e);
      }

      if (mask === 'alpha') {
        alphaMasking(e);
      }
    },
    [mask],
  );

  return (
    <Container
      altHeight={altHeight}
      hasError={!!error}
      isFocused={isFocused}
      isFilled={isFilled}
      style={containerStyle}
    >
      {Icon && <Icon />}
      <textarea
        cols={10}
        rows={2}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        ref={textareaRef}
        onKeyUp={maskIt}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FaExclamationTriangle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Textarea;
