export function handleCPF(cpf: string): boolean {
  let soma = 0;
  let resto = 0;
  let i = 1;

  const strCPF = cpf.replace(/[.-]/g, '').substr(0, 11);

  if (
    [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ].indexOf(strCPF) > -1
  ) {
    // formRef.current?.setFieldError('cpf', 'N° CPF inválido');
    // formRef.current?.setFieldValue('cpf', '');
    return false;
  }

  for (i = 1; i <= 9; i += 1) {
    soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;
  }

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(strCPF.substring(9, 10), 10)) {
    // formRef.current?.setFieldError('cpf', 'N° CPF inválido');
    // formRef.current?.setFieldValue('cpf', '');
    return false;
  }

  soma = 0;
  for (i = 1; i <= 10; i += 1) {
    soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;
  }

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(strCPF.substring(10, 11), 10)) {
    // formRef.current?.setFieldError('cpf', 'N° CPF inválido');
    // formRef.current?.setFieldValue('cpf', '');
    return false;
  }

  // formRef.current?.setFieldError('cpf', '');
  return true;
}

export function formatCPF(cpf: string): string {
  let format = cpf;
  if (!format) {
    format = '00000000000';
  }

  format = format.replace(/\D/g, '');
  format = format.replace(/^(\d{3})(\d)/, '$1.$2');
  format = format.replace(/(\d{3})(\d)/, '$1.$2');
  format = format.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return format;
}
