import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useLocation, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaSearch, FaPencilAlt, FaUsersCog } from 'react-icons/fa';
import {
  Container,
  AlteredContent,
  Header,
  ComboProps,
  CoordProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import IncludeButton from 'components/IncludeButton';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import api from 'services/api';
import * as O from 'styles/option_buttons';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { Grid, GridItem, GridInfo } from './styles';

export interface LocalProps {
  anbcod: string;
  anbdesc: string;
  coor: {
    name: string;
    gseq: number;
  };
  delivery: {
    address: string;
    city: string;
    code: string;
    district: string;
    state: string;
  };
  desc: string;
  eesc: {
    name: string;
    gseq: number;
  };
  ejov: {
    name: string;
    gseq: number;
  };
  loccod: string;
  rece: {
    name: string;
    gseq: number;
  };
  secr: {
    name: string;
    gseq: number;
  };
  status: string;
  teso: {
    name: string;
    gseq: number;
  };
  type: string;
  updated_at: string;
  user: string;
  deactivate_info: {
    ready: boolean;
    amount: number;
    balance: number;
    storage: number;
    rm: number;
    ce: number;
    bills: number;
    services: number;
    workshops: number;
    numbers: number;
    lib: number;
    team: number;
  };
}

interface LocalStorageProps {
  zoncod?: string;
  zondesc?: string;
  anbcod?: string;
  anbdesc?: string;
  locstat?: string;
}

const Locais: React.FC = () => {
  const [mode] = useState(process.env.REACT_APP_LOC);
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [locs, setLocs] = useState<LocalProps[]>([]);
  const [filterLocs, setFilterLocs] = useState<LocalProps[]>([]);

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { anbcod, anbdesc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return anbcod && anbdesc
      ? { cod: anbcod, desc: anbdesc }
      : { cod: '', desc: '' };
  });
  const [initialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(`${mode}`);
    return anbcod || '';
  });
  const [initialZon] = useState(() => {
    const { zoncod }: LocalStorageProps = getLocalStorage(`${mode}`);
    return zoncod || user.zoncod;
  });
  const [initialStat] = useState(() => {
    const { locstat }: LocalStorageProps = getLocalStorage(`${mode}`);
    return locstat || 'A';
  });

  const [comboStatus] = useState<ComboProps[]>([
    {
      value: 'A',
      label: 'Locais ativas',
    },
    {
      value: 'I',
      label: 'Locais inativas',
    },
  ]);

  const getLocais = useCallback(async () => {
    try {
      const response = await api.get('/sgo/locais_list.php');
      const { locstat, anbcod, zoncod }: LocalStorageProps = getLocalStorage(
        `${mode}`,
      );
      const status = locstat || 'A';

      let filtered = response.data.filter(
        (item: LocalProps) => item.status === status,
      );
      if (['LOC', 'NAC'].indexOf(user.perfil) > -1) {
        filtered = filtered.filter(
          (item: LocalProps) => item.anbcod === user.anbc,
        );
        setLocs(
          response.data.filter((item: LocalProps) => item.anbcod === user.anbc),
        );
      } else {
        setLocs(response.data);
        if (zoncod) {
          filtered = filtered.filter(
            (item: LocalProps) =>
              item.anbcod.substr(0, 2) === zoncod.substr(0, 2),
          );
        }
        if (anbcod) {
          filtered = filtered.filter(
            (item: LocalProps) => item.anbcod === anbcod,
          );
        }
      }

      setFilterLocs(filtered);

      // if (user.perfil !== 'ZON' && user.perfil !== 'INT') {
      //   setLocs(
      //     response.data.filter((item: LocalProps) => item.anbcod === user.anbc),
      //   );

      //   setFilterLocs(
      //     response.data.filter(
      //       (item: LocalProps) =>
      //         item.anbcod === user.anbc && item.status === status,
      //     ),
      //   );
      // } else {
      //   setLocs(response.data);

      //   if (user.perfil === 'INT') {
      //     setFilterLocs(
      //       response.data.filter(
      //         (item: LocalProps) =>
      //           item.status === status &&
      //           item.anbcod.substr(0, 2) === user.zoncod.substr(0, 2),
      //       ),
      //     );
      //   } else {
      //     setFilterLocs(
      //       response.data.filter((item: LocalProps) => item.status === status),
      //     );
      //   }
      // }
      setLoading(false);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [mode, user.perfil, user.anbc, errorHandling]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zoncod }: LocalStorageProps = getLocalStorage(`${mode}`);
    const thiszon = (zoncod || user.zoncod).substr(0, 2);
    const nacionais = response.data.filter(
      (item: ComboProps) => item.value.substr(0, 2) === thiszon,
    );
    setComboNacs([{ value: '', label: 'Todas Nacionais' }, ...nacionais]);
  }, [mode, user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  useEffect(() => {
    getLocais();
    getComboANB();
    getComboZON();
  }, [getLocais, getComboANB, getComboZON]);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const selectStatus = formRef.current?.getFieldRef('comboStatus');

    const { value, text } = select.options[select.selectedIndex];
    const status = selectStatus.options[selectStatus.selectedIndex].value;

    if (user.perfil === 'INT') {
      const selectedZON = formRef.current?.getFieldRef('comboZON');
      setLocalStorage(`${mode}`, {
        zoncod: selectedZON.options[selectedZON.selectedIndex].value,
        zondesc: selectedZON.options[selectedZON.selectedIndex].text,
      });
    }

    setLocalStorage(`${mode}`, {
      anbcod: value,
      anbdesc: text,
    });

    setCoord({ cod: value, desc: value === '' ? value : text });
    if (value === '') {
      setFilterLocs(locs.filter((item: LocalProps) => item.status === status));
      return;
    }

    setFilterLocs(
      locs.filter(
        (item: LocalProps) => item.anbcod === value && item.status === status,
      ),
    );
  }, [locs, mode, user.perfil]);

  const handleStatSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboStatus');
    const { value } = select.options[select.selectedIndex];
    setLocalStorage(`${mode}`, {
      locstat: value,
    });
    if (user.perfil === 'ZON' || user.perfil === 'INT') {
      const selectANB = formRef.current?.getFieldRef('comboANB');
      const anb = selectANB.options[selectANB.selectedIndex].value;
      if (anb !== '') {
        setFilterLocs(
          locs.filter(
            (item: LocalProps) => item.anbcod === anb && item.status === value,
          ),
        );
      } else {
        setFilterLocs(locs.filter((item: LocalProps) => item.status === value));
      }

      return;
    }

    // const selectStatus = formRef.current?.getFieldRef('comboStatus');
    // const status = selectStatus.options[selectStatus.selectedIndex].value;

    setFilterLocs(
      locs
        .map((item) => item)
        .filter((item: LocalProps) => item.status === value),
    );
  }, [locs, mode, user.perfil]);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const selectNAC = formRef.current?.getFieldRef('comboANB');
    const selectStatus = formRef.current?.getFieldRef('comboStatus');

    const { value } = select.options[select.selectedIndex];

    const anb = selectNAC.options[selectNAC.selectedIndex].value;
    const stat = selectStatus.options[selectStatus.selectedIndex].value;

    const nacionais = nacs.filter(
      (item: ComboProps) => item.value.substr(0, 2) === value.substr(0, 2),
    );

    const locais = locs.filter((item: LocalProps) =>
      anb !== ''
        ? item.anbcod === anb && item.status === stat
        : item.anbcod.substr(0, 2) === value.substr(0, 2) &&
          item.status === stat,
    );

    setComboNacs([{ value: '', label: 'Selecione' }, ...nacionais]);
    setFilterLocs(locais);
  }, [nacs, locs]);

  return (
    <Container>
      <Loading isLoading={loading} />

      {user.perfil === 'NAC' && <IncludeButton />}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={initialZon}
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                </span>
              )}

              <span>
                <p>Filtre por Status:</p>
                <SelectV2
                  name="comboStatus"
                  content={comboStatus}
                  initial={initialStat}
                  onChange={handleStatSelect}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>Equipes Locais</p>
        </div>
        <div>{coord.desc}</div>
      </Header>
      {/* <Header>{coord.desc}</Header> */}
      <AlteredContent pixels="231px">
        <Grid>
          {filterLocs.map((item: LocalProps) => (
            <GridItem key={item.loccod} active={item.status === 'A'}>
              <GridInfo>
                <h4>
                  {item.loccod} - {item.desc}
                </h4>
                {(user.perfil === 'ZON' || user.perfil === 'INT') && (
                  <p>{item.anbdesc}</p>
                )}
              </GridInfo>
              <O.GridOptions>
                {item.status === 'A' && (
                  <O.Show>
                    <Link
                      to={{
                        pathname: `${pathname}/consult`,
                        state: { coord: item },
                      }}
                      title="Consultar coordenação"
                    >
                      <FaSearch />
                    </Link>
                  </O.Show>
                )}

                {((user.perfil === 'NAC' && user.anbc === item.anbcod) ||
                  (user.perfil === 'LOC' && user.loccod === item.loccod)) && (
                  <O.Update
                    to={{
                      pathname: `${pathname}/update`,
                      state: { coord: item },
                    }}
                    title="Alterar coordenação"
                  >
                    <FaPencilAlt />
                  </O.Update>
                )}

                {((user.perfil === 'NAC' && user.anbc === item.anbcod) ||
                  (user.perfil === 'LOC' && user.loccod === item.loccod)) && (
                  <O.Team
                    to={{
                      pathname: `${pathname}/team`,
                      state: { coord: item },
                    }}
                    title="Alterar equipe"
                  >
                    <FaUsersCog />
                  </O.Team>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Locais;
