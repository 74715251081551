import React, { useEffect, useCallback, useState } from 'react';
import Header from 'components/Header';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import { useToast } from 'hooks/toast';
import { useWindow } from 'hooks/window';
import { AlteredHeader } from 'styles/sgo_wrappers';

import api from 'services/api';
import { FaEnvelope, FaPhoneSquare, FaExternalLinkAlt } from 'react-icons/fa';
import { Container, Content, Table } from './styles';

interface ContactsProps {
  id: string;
  ufname: string;
  anbdesc: string;
  anbescrit: string;
  uf: string;
  anbfone: string;
  anbfoneLink: string;
  anbmail: string;
  gnome: string;
  gfcel: string;
  gfcelLink: string;
  gmail: string;
}

const Contatos: React.FC = () => {
  const { addToast } = useToast();
  const { width } = useWindow();
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<ContactsProps[]>([]);

  const getContacts = useCallback(async () => {
    try {
      const response = await api.get('/web/contacts.php');

      let preset = [...response.data];

      preset = preset.map((item: ContactsProps) => ({
        ...item,
        anbfoneLink: item.anbfone.replace(/[()-\s]/g, ''),
        gfcelLink: item.gfcel.replace(/[()-\s]/g, ''),
      }));

      setContacts(preset);
    } catch (e) {
      addToast({
        type: 'error',
        seconds: 10,
        title: 'Falha na requisição',
        description:
          'Não foi possível concluir sua solicitação. Tente novamente e caso o problema persista acione o suporte.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Contatos com as coordenações</div>
        </AlteredHeader>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '50px' }}>UF</th>
              <th>{width >= 930 ? 'Coordenação' : 'Cidade'}</th>
              <th>Telefone</th>
              <th>Email</th>
              <th>Resp{width >= 600 ? 'onsável' : '.'}</th>
              <th>Tel{width >= 600 ? 'efone' : '.'}</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((item, index) => (
              <React.Fragment key={item.id}>
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#fff' : '#fbf4f4',
                  }}
                >
                  <td>{item.uf}</td>
                  <td>
                    {width >= 1020 ? `${item.anbdesc} - ` : ''}
                    {item.anbescrit}
                  </td>
                  <td>
                    <a href={`tel:${item.anbfoneLink})}`}>
                      <FaExternalLinkAlt />
                      {item.anbfone}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`mailto:${item.anbmail}?subject=[TOV BRASIL] - Informações`}
                    >
                      <FaExternalLinkAlt />
                      {item.anbmail}
                    </a>
                  </td>
                  <td>{item.gnome}</td>
                  <td>
                    <a href={`tel:${item.gfcelLink})}`}>
                      {width >= 600 ? (
                        <>
                          <FaExternalLinkAlt />
                          {item.gfcel}
                        </>
                      ) : (
                        <FaPhoneSquare />
                      )}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`mailto:${item.gmail}?subject=[TOV BRASIL] - Informações`}
                    >
                      {width >= 600 ? (
                        <>
                          <FaExternalLinkAlt />
                          {item.gmail}
                        </>
                      ) : (
                        <FaEnvelope />
                      )}
                    </a>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contatos;
