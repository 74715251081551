import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { FaCheckSquare } from 'react-icons/fa';

const animatedGoArrow = keyframes`
  from {
    color: ${shade(0.2, '#00802b')};
  } to {
    color: #00802b;
  }
`;

export const VersionUpdater = styled.div`
  width: 100vw;
  max-width: 360px;
  margin: 50px 0 0 0;
  /* height: calc(100vh - 190px); */

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 35px;
      width: 35px;
    }

    input {
      font-size: 18px;
      font-weight: bold;
      z-index: 2;
      width: 100%;
      padding: 1px 3px;
      text-align: center;
      border: 2px solid #767676;
      background: #efefef;
      border-radius: 5px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: none;
      margin: 0;
    }

    & + span {
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 450px) {
    margin: 0;
    position: absolute;
    bottom: 200px;
    /* height: calc(100vh - 190px); */
  }
`;

export const GoArrow = styled(FaCheckSquare)`
  cursor: pointer;
  position: absolute;
  right: 10%;

  height: 25px;
  width: 25px;

  animation: ${animatedGoArrow} 0.5s alternate infinite;
`;
