import React, { useState, useCallback, useRef, useEffect } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboLocalProps,
  ComboProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import api from 'services/api';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { FaRegFileExcel, FaRegListAlt, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import SelectV2 from 'components/SelectV2';
import { useSpring } from 'react-spring';
import Loading from 'components/Loading';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { Menu, AnimatedDiv } from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  loc?: string;
  locdesc?: string;
  anb?: string;
  anbdesc?: string;
  zon?: string;
  zondesc?: string;
  cod?: string;
  desc?: string;
  typeOf?: string;
  year?: string;
}

const PlanejamentoMenu: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const anchorList = useRef<HTMLAnchorElement>(null);
  const anchorDone = useRef<HTMLAnchorElement>(null);

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  // const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );
    return cod && desc
      ? { cod, desc }
      : ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [selectedYear, setSelectedYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return year || new Date().getUTCFullYear().toString();
  });

  const [type, setType] = useState(() => {
    const { typeOf }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return typeOf || (user.perfil === 'INT' ? 'ZON' : user.perfil);
  });

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);

  const [initialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return zon || user.zoncod;
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return anb || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return loc || 'Selecione';
  });

  const [initialYear, setInitialYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    return year || new Date().getUTCFullYear().toString();
  });

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    if (anb || user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substr(0, 2) === zon.substr(0, 2)
          : item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const getComboYears = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/combos/comboAnosPlanejamento.php?data=${JSON.stringify({
          cod: coord.cod,
        })}`,
      );

      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PLANEJAMENTO}`,
      );

      const index = response.data.findIndex(
        (item: ComboProps) => item.value === year,
      );

      setInitialYear(response.data[index > -1 ? index : 0].label);
      setSelectedYear(response.data[index > -1 ? index : 0].label);
      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        year: response.data[index > -1 ? index : 0].label,
      });
      setComboYears(response.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [coord.cod, errorHandling]);

  useEffect(() => {
    if (['ZON', 'INT'].indexOf(user.perfil) > -1) {
      const { zon, zondesc }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PLANEJAMENTO}`,
      );

      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        zon: zon || user.zoncod,
        zondesc: zondesc || user.zondesc,
      });
    }
    handlePermission(['GUI']);
    getComboZON();
    getComboANB();
    getComboLOC();

    getComboYears();
  }, [
    getComboANB,
    getComboLOC,
    getComboYears,
    getComboZON,
    handlePermission,
    user.perfil,
    user.zoncod,
    user.zondesc,
  ]);

  const handleZONselection = useCallback(() => {
    let zon = formRef.current?.getFieldValue('comboZON');
    const index = comboZons.findIndex((item) =>
      zon.length > 4 ? item.label === zon : item.value === zon,
    );

    if (zon.length > 4) {
      zon = comboZons[index].value;
    }

    setType('ZON');
    setInitialNac('Selecione');
    setInitialLoc('Selecione');

    setLocalStorage(
      `${process.env.REACT_APP_PLANEJAMENTO}`,
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
            zon: user.zoncod,
            zondesc: user.zondesc,
            typeOf: 'ZON',
          }
        : {
            cod: zon,
            desc: comboZons[index].label,
            zon,
            zondesc: comboZons[index].label,
            typeOf: 'ZON',
          },
    );

    // setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, );

    deleteLocalStorageItemKey(`${process.env.REACT_APP_PLANEJAMENTO}`, [
      'anb',
      'anbdesc',
      'loc',
      'locdesc',
    ]);

    setCoord(
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
          }
        : {
            cod: zon,
            desc: comboZons[index].label,
          },
    );

    setComboNacs(
      nacs.filter(
        (item) =>
          item.value.substr(0, 2) ===
          (user.perfil === 'ZON' ? user.zoncod.substr(0, 2) : zon.substr(0, 2)),
      ),
    );
    setComboLocs([]);
  }, [comboZons, nacs, user.perfil, user.zoncod, user.zondesc]);

  const handleNACselection = useCallback(() => {
    setType('NAC');

    deleteLocalStorageItemKey(`${process.env.REACT_APP_PLANEJAMENTO}`, [
      'loc',
      'locdesc',
    ]);

    setInitialLoc('Selecione');

    if (user.perfil === 'NAC') {
      setCoord({
        cod: user.anbc,
        desc: user.anbdesc,
      });

      setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
        cod: user.anbc,
        desc: user.anbdesc,
        anb: user.anbc,
        anbdesc: user.anbdesc,
        typeOf: 'NAC',
      });

      setComboLocs(locs.filter((item) => item.anb === user.anbc));
      return;
    }

    let nac = formRef.current?.getFieldValue('comboANB');
    const index = comboNacs.findIndex((item) =>
      nac.length > 4 ? item.label === nac : item.value === nac,
    );

    if (nac.length > 4) {
      nac = comboNacs[index].value;
    }

    setInitialNac(comboNacs[index].label);

    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      cod: nac,
      desc: comboNacs[index].label,
      anb: nac,
      anbdesc: comboNacs[index].label,
      typeOf: 'NAC',
    });

    setCoord({
      cod: nac,
      desc: comboNacs[index].label,
    });

    setComboLocs(locs.filter((item) => item.anb === nac));
    setInitialLoc('Selecione');
  }, [comboNacs, locs, user.anbc, user.anbdesc, user.perfil]);

  const handleLOCselection = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLOC');
    const index = comboLocs.findIndex((item) => item.value === loc);

    setType('LOC');

    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      cod: loc,
      desc: comboLocs[index].label,
      loc,
      locdesc: comboLocs[index].label,
      typeOf: 'LOC',
    });

    setCoord({
      cod: loc,
      desc: comboLocs[index].label,
    });

    setInitialLoc(comboLocs[index].label);
  }, [comboLocs]);

  const handleYEARselection = useCallback(() => {
    const year = formRef.current?.getFieldValue('comboAno');

    setInitialYear(year);
    setSelectedYear(year);
    setLocalStorage(`${process.env.REACT_APP_PLANEJAMENTO}`, {
      year,
    });
  }, []);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    anchorList.current?.click();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDownload2 = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    anchorDone.current?.click();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const removeButtonNac = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeButtonLoc = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            {user.perfil === 'INT' && (
              <div>
                <span>
                  <p>Selecione a Zonal:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    initial={initialZon}
                    onChange={handleZONselection}
                  />
                  <RemoveButton
                    type="button"
                    // onClick={handleZONselection}
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    &nbsp;
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleNACselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleZONselection}
                    style={removeButtonNac}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Local:</p>
                  <SelectV2
                    name="comboLOC"
                    content={comboLocs}
                    initial={initialLoc}
                    onChange={handleLOCselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleNACselection}
                    style={removeButtonLoc}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}
            <div>
              <span>
                <p>Selecione o ano:</p>
                <SelectV2
                  name="comboAno"
                  content={comboYears}
                  initial={initialYear}
                  onChange={handleYEARselection}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>Planejamento Financeiro | {selectedYear}</div>
          <div>{coord.desc}</div>
        </AlteredHeader>
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>Planejamento</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.35}>
            <button type="button" onClick={handleDownload}>
              <FaRegFileExcel size={30} />
              <h3>Planejamento Lançado</h3>
            </button>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.7}>
            <button type="button" onClick={handleDownload2}>
              <FaRegFileExcel size={30} />
              <h3>Planejado x Realizado</h3>
            </button>
          </AnimatedDiv>
        </Menu>
      </Content>

      <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
        <S.Title>
          <h2>Gerando arquivo</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>

      <a
        style={{ display: 'none' }}
        ref={anchorList}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_planejamento.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          type,
          list: 'PL',
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Planejamento
      </a>

      <a
        style={{ display: 'none' }}
        ref={anchorDone}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_plan_x_real.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          type,
          list: 'PR',
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Planejado x Realizado
      </a>
      <SGOFooter />
    </Container>
  );
};

export default PlanejamentoMenu;
