import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import AutoComplete, {
  removeDups,
  createArray,
  ArrayProps,
  CreateArrayProps,
} from 'components/AutoComplete';

import { useCredentials } from 'hooks/credentials';

import api from 'services/api';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useSpring } from 'react-spring';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { removeAcento } from 'utils/specialChars';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  ButtonContainer,
  Button,
  CoordContainer,
  ModalContent,
} from './styles';

interface ListProps {
  gseq: number;
  gcert: string;
  name: string;
  zon: string;
  zond: string;
  nac: string;
  nacd: string;
  loc: string;
  locd: string;
  func: string;
  funcd: string;
  type: string;
  fres: string;
  fcel: string;
  mail: string;
  face: string;
  avatar: string;
}

interface CoordListProps {
  [key: string]: ListProps;
}

interface BodyProps {
  type: string;
  funct: string;
  visible: boolean;
  fcback: () => void;
}

const Equipes: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [acList, setACList] = useState<
    { id: string | number; label: string }[]
  >([]);
  const [gList, setGList] = useState<{ id: string | number; label: string }[]>(
    [],
  );
  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [type, setType] = useState('');
  const [searchMode, setSM] = useState('');

  const [list, setList] = useState<ListProps[]>([]);
  const [locs, setLocs] = useState<ArrayProps[]>([]);
  const [nacs, setNacs] = useState<ArrayProps[]>([]);
  const [zons, setZons] = useState<ArrayProps[]>([]);
  const [selected, setSelected] = useState<ListProps>({} as ListProps);
  const [coordList, setCoordList] = useState<ListProps[]>([]);
  const [currYear, setCurrYear] = useState<string>(() => {
    return new Date().getFullYear().toString();
  });
  const [currSemester, setCurrSemester] = useState<string>(() => {
    const currMonth = new Date().getUTCMonth();
    return currMonth < 7 ? '1' : '2';
  });
  const [years] = useState<ComboProps[]>(() => {
    const minYear = 2018;
    let curr = parseInt(currYear, 10);
    const toReturn = [];
    while (curr >= minYear) {
      toReturn.push({ value: curr.toString(), label: curr.toString() });
      curr -= 1;
    }
    return toReturn;
  });
  const [semesters] = useState<ComboProps[]>([
    { value: '1', label: '1° Semestre' },
    { value: '2', label: '2° Semestre' },
  ]);

  const handlePreShow = useCallback(() => {
    setShow((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleSetShow = useCallback(
    (mode: string) => {
      const index = coordList.findIndex(
        (item) => item.func.substring(0, 1).toUpperCase() === mode,
      );
      if (index > -1) {
        setSelected(() => ({ ...coordList[index] }));
        handlePreShow();
      }
    },
    [coordList, handlePreShow],
  );

  const handleSetGuia = useCallback(
    (gseq: number) => {
      const index = coordList.findIndex((item) => item.gseq === gseq);

      if (index > -1) {
        setSelected(() => ({ ...coordList[index] }));
        handlePreShow();
      }
    },
    [coordList, handlePreShow],
  );

  const [body, setBody] = useState<BodyProps[]>([
    {
      type: 'C',
      funct: 'Coordenação',
      visible: true,
      fcback: () => handleSetShow('C'),
    },
    {
      type: 'T',
      funct: 'Tesouraria',
      visible: true,
      fcback: () => handleSetShow('T'),
    },
    {
      type: 'S',
      funct: 'Secretaria',
      visible: true,
      fcback: () => handleSetShow('S'),
    },
    {
      type: 'M',
      funct: 'Enc. Materiais',
      visible: true,
      fcback: () => handleSetShow('M'),
    },
    {
      type: 'E',
      funct: 'Enc. EPG',
      visible: true,
      fcback: () => handleSetShow('E'),
    },
    {
      type: 'J',
      funct: 'Jovem',
      visible: true,
      fcback: () => handleSetShow('J'),
    },
  ]);

  const resetAll = useCallback(() => {
    // setSM('');
    // setType('');
    setList([]);
    setLocs([]);
    setNacs([]);
    setZons([]);
    setSelected({} as ListProps);
    setCoordList([]);
  }, []);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      resetAll();
      const response = await api.get(
        `/sgo/equipes_list.php?data=${JSON.stringify({
          year: currYear,
          sem: currSemester,
        })}`,
      );

      const {
        guias,
        locais,
        nacionais,
        zonais,
      }: {
        guias: [];
        locais: CreateArrayProps[];
        nacionais: CreateArrayProps[];
        zonais: CreateArrayProps[];
      } = response.data;

      setList(guias);
      setGList(createArray(removeDups(guias, 'name'), 'gseq', 'name'));
      setZons(createArray(removeDups(zonais, 'desc'), 'cod', 'desc'));
      setNacs(createArray(removeDups(nacionais, 'desc'), 'cod', 'desc'));
      setLocs(createArray(removeDups(locais, 'desc'), 'cod', 'desc'));

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [currSemester, currYear, errorHandling, resetAll]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleCoordSelect = useCallback(() => {
    const input = inputRef.current;

    if (input) {
      const { value } = input;

      const index = acList.map((item) => item.label).indexOf(value);
      const currentCoord = acList[index].id;

      const filtered = list
        .filter(
          (item: ListProps) =>
            item.type === type &&
            item[type === 'Z' ? 'zon' : type === 'N' ? 'nac' : 'loc'] ===
              currentCoord,
        )
        .map((item) => item);

      // console.log(currentCoord, acList[index].id, value);
      console.log(acList);
      console.log(filtered);

      // 😊 MODE #1
      // const cIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'C');
      // const tIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'T');
      // const sIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'S');
      // const mIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'M');
      // const eIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'E');
      // const jIndex = filtered.findIndex((x) => x.func.substring(0, 1) === 'J');

      // const letsCheckOneThing: any[] = [
      //   { ...filtered[cIndex] },
      //   { ...filtered[tIndex] },
      //   { ...filtered[sIndex] },
      //   { ...filtered[mIndex] },
      //   { ...filtered[eIndex] },
      //   { ...filtered[jIndex] },
      // ]
      //   .filter((item) => Object.keys(item).length > 0)
      //   .map((item) => item);
      //    setCoordList(letsCheckOneThing);
      // 😊 END MODE #1

      // 😊 MODE #2
      // setCoordList(() => {
      //   const dafuq: ListProps[] = [];
      //   if (cIndex > -1) {
      //     dafuq.push({ ...filtered[cIndex] });
      //   }
      //   if (tIndex > -1) {
      //     dafuq.push({ ...filtered[tIndex] });
      //   }
      //   if (sIndex > -1) {
      //     dafuq.push({ ...filtered[sIndex] });
      //   }
      //   if (mIndex > -1) {
      //     dafuq.push({ ...filtered[mIndex] });
      //   }
      //   if (eIndex > -1) {
      //     dafuq.push({ ...filtered[eIndex] });
      //   }
      //   if (jIndex > -1) {
      //     dafuq.push({ ...filtered[jIndex] });
      //   }
      //   return dafuq;
      // });
      // 😊 END MODE #2

      // 😊👌 BEST MODE!
      setCoordList(
        body
          .map((cat) => ({
            ...filtered[
              filtered.findIndex((x) => x.func.substring(0, 1) === cat.type)
            ],
          }))
          .filter((filter) => Object.keys(filter).length > 0)
          .map((item) => item),
      );
      //

      const Ei = body.findIndex((item) => item.type === 'E');
      const Ji = body.findIndex((item) => item.type === 'J');
      const Mi = body.findIndex((item) => item.type === 'M');

      if (type === 'Z') {
        body[Ei].visible = false;
        body[Ji].visible = false;
        body[Mi].visible = true;
      }
      if (type === 'L') {
        body[Ei].visible = true;
        body[Ji].visible = true;
        body[Mi].visible = false;
      }
      if (type === 'N') {
        body[Ei].visible = true;
        body[Ji].visible = true;
        body[Mi].visible = true;
      }

      setBody((state) => [...state]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, list, type]);

  const handleStringSelect = useCallback(() => {
    const input = inputRef.current;
    if (input) {
      setCoordList(
        list
          .filter((item) =>
            removeAcento(item.name)
              .toLowerCase()
              .includes(removeAcento(input.value).toLowerCase()),
          )
          .map((item) => item),
      );
    }
  }, [list]);

  const handleChanges = useCallback(
    (mode: string) => {
      if (mode.substring(0, 1).toUpperCase() === type) {
        return;
      }

      const input = inputRef.current;

      setType(mode.substring(0, 1).toUpperCase());
      setCoordList([]);
      setACList(() => (mode === 'zon' ? zons : mode === 'nac' ? nacs : locs));
      if (input) {
        input.value = '';
      }
    },
    [locs, nacs, type, zons],
  );

  const handleModeCoord = useCallback(() => {
    setSM('C');
    setCoordList([]);
    const input = inputRef.current;
    if (input) {
      input.value = '';
    }
  }, []);

  const handleModeGuia = useCallback(() => {
    setSM('G');
    setType('');
    setCoordList([]);
    const input = inputRef.current;
    if (input) {
      input.value = '';
    }
  }, []);

  const handleZON = useCallback(() => {
    handleChanges('zon');
  }, [handleChanges]);

  const handleNAC = useCallback(() => {
    handleChanges('nac');
  }, [handleChanges]);

  const handleLOC = useCallback(() => {
    handleChanges('loc');
  }, [handleChanges]);

  const handleYearSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('years');
    const { value } = select.options[select.selectedIndex];
    setCurrYear(value);
  }, []);
  const handleSemesterSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('semesters');
    const { value } = select.options[select.selectedIndex];
    setCurrSemester(value);
  }, []);

  const appearContainer = useSpring({
    opacity: searchMode === 'G' ? 1 : type === '' ? 0 : 1,
    transform:
      searchMode === 'G'
        ? 'translateX(0px)'
        : type === ''
        ? 'translateX(-100px)'
        : 'translateX(0px)',
    pointerEvents: searchMode === 'G' ? 'all' : type === '' ? 'none' : 'all',
  });
  // const appearContainer = useSpring({
  //   opacity: type === '' ? 0 : 1,
  //   transform: type === '' ? 'translateX(-100px)' : 'translateX(0px)',
  //   pointerEvents: type === '' ? 'none' : 'all',
  // });

  const appearCoord = useSpring({
    opacity: coordList.length > 0 ? 1 : 0,
  });

  const notGStyle = useSpring({
    opacity: searchMode === 'C' ? 1 : 0,
    transform: searchMode === 'C' ? 'translateX(0px)' : 'translateX(-100px)',
    pointerEvents: searchMode === 'C' ? 'all' : 'none',
    height: searchMode === 'C' ? '75px' : '0px',
  });

  const clearContent = useCallback(() => {
    setCoordList([]);
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Equipes</p>
          </div>
        </AlteredHeader>

        <Form ref={formRef} onSubmit={() => null}>
          <div style={{ display: 'flex' }}>
            <SelectV2
              name="years"
              initial={currYear}
              content={years}
              onChange={handleYearSelection}
              containerStyle={{
                width: '150px',
              }}
            />
            <SelectV2
              name="semesters"
              initial={currSemester}
              content={semesters}
              onChange={handleSemesterSelection}
              containerStyle={{
                width: '150px',
              }}
            />
          </div>
        </Form>

        <ButtonContainer style={{ marginTop: '20px' }}>
          {/* <h4>Pesquisar coordenações: </h4> */}
          <div>
            <Button
              marked={searchMode === 'C'}
              type="button"
              onClick={handleModeCoord}
            >
              Coordenações
            </Button>
            <Button
              marked={searchMode === 'G'}
              type="button"
              onClick={handleModeGuia}
            >
              Guias
            </Button>
          </div>
        </ButtonContainer>

        <ButtonContainer style={notGStyle}>
          <h4>Pesquisar coordenações: </h4>
          <div>
            <Button marked={type === 'Z'} type="button" onClick={handleZON}>
              Zonais
            </Button>
            <Button marked={type === 'N'} type="button" onClick={handleNAC}>
              Nacionais
            </Button>
            <Button marked={type === 'L'} type="button" onClick={handleLOC}>
              Locais
            </Button>
          </div>
        </ButtonContainer>

        <AutoComplete
          placeholder={
            searchMode === 'C'
              ? `Digite a Coordenação ${
                  type === 'Z' ? 'Zonal' : type === 'N' ? 'Nacional' : 'Local'
                }`
              : `Digite o nome do guia`
          }
          array={searchMode === 'C' ? acList : gList}
          chars={searchMode === 'C' ? 1 : 3}
          inputRef={inputRef}
          buttonCallback={
            searchMode === 'C' ? handleCoordSelect : handleStringSelect
          }
          appearSearch={searchMode === 'G' && !!inputRef.current}
          containerStyle={appearContainer}
          onFocus={clearContent}
        />

        {coordList.length > 0 && (
          <CoordContainer style={appearCoord}>
            {searchMode === 'C'
              ? body.map(
                  (bi) =>
                    bi.visible && (
                      <div key={bi.type}>
                        <span>
                          <button
                            type="button"
                            onClick={() => handleSetShow(bi.type)}
                            disabled={
                              coordList.findIndex(
                                (cL) => cL.func.substring(0, 1) === bi.type,
                              ) < 0
                            }
                          >
                            <p>{bi.funct}:</p>
                            <strong>
                              {coordList.findIndex(
                                (cL) => cL.func.substring(0, 1) === bi.type,
                              ) > -1
                                ? coordList[
                                    coordList.findIndex(
                                      (cL) =>
                                        cL.func.substring(0, 1) === bi.type,
                                    )
                                  ].name
                                : 'Não definido'}
                            </strong>
                          </button>
                        </span>
                      </div>
                    ),
                )
              : coordList.map((item) => (
                  <div key={item.gseq}>
                    <span>
                      <button
                        type="button"
                        onClick={() => handleSetGuia(item.gseq)}
                        style={{ flexDirection: 'column' }}
                      >
                        <span>
                          <p>Nome:&nbsp;</p>
                          <strong>{item.name}</strong>
                        </span>
                        <span>
                          <p>Coord. Nacional:&nbsp;</p>
                          <strong>{item.nacd}</strong>
                        </span>
                        <span>
                          <p>Coord. Local:&nbsp;</p>
                          <strong>{item.locd}</strong>
                        </span>
                      </button>
                    </span>
                  </div>
                ))}
          </CoordContainer>
        )}
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{selected.name}</h2>
          </S.Title>
          <S.Content>
            <ModalContent>
              <section>
                <img
                  src={`${process.env.REACT_APP_AVATAR}${selected.avatar}`}
                  alt={selected.name}
                />
                <span>
                  <p>
                    Certificado: <strong>{selected.gcert}</strong>
                  </p>
                </span>

                <span>
                  <p>
                    Função: <strong>{selected.funcd}</strong>
                  </p>
                </span>

                <span>
                  <p>
                    Coord. Zonal: <strong>{selected.zond}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Coord. Nacional: <strong>{selected.nacd}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Coord. Local: <strong>{selected.locd}</strong>
                  </p>
                </span>
              </section>
              <section>
                <span>
                  {!!selected.fres && (
                    <p>
                      Telefone:&nbsp;
                      <strong>
                        <a
                          href={`tel:${selected.fres}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {selected.fres}
                        </a>
                      </strong>
                    </p>
                  )}
                  {!!selected.fcel && (
                    <p>
                      Celular:&nbsp;
                      <strong>
                        <a
                          href={`tel:${selected.fcel}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {selected.fcel}
                        </a>
                      </strong>
                    </p>
                  )}
                  {!!selected.mail && (
                    <p>
                      Email:&nbsp;
                      <strong>
                        <a
                          href={`mailto:${selected.mail}?subject=[TOV BRASIL] - Mensagem para o Guia`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {selected.mail}
                        </a>
                      </strong>
                    </p>
                  )}
                  {!!selected.face && (
                    <p>
                      Facebook: <strong>{selected.face}</strong>
                    </p>
                  )}
                </span>
              </section>
            </ModalContent>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() => setShow({ ...show, open: false })}
            >
              Ok
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Equipes;
