import styled, { css } from 'styled-components';
import { shade, parseToRgb } from 'polished';

import { Update } from 'styles/option_buttons';

interface StageBlockProps {
  bgcolor: string;
}

export const TotaisContainer = styled.figure`
  display: flex;
  margin-top: 25px;
  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  span {
    display: flex;

    p {
      color: #767676;
      font-weight: 400;
      font-size: 15px;

      strong {
        color: #332e2e;
      }
      & + p {
        margin-left: 15px;
      }
    }

    & + span {
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
    width: 90vw;
    align-items: center;

    span {
      width: 100%;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;

    span {
      flex-direction: column;
      align-items: center;

      p {
        text-align: left;
        width: 200px;
        & + p {
          margin-left: 0px;
        }
      }

      & + span {
        margin-left: 0px;
      }
    }
  }
`;

export const Grid = styled.div`
  margin: 0;
  padding: 35px 0 10px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1200px) {
    grid: auto / 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }

  @media screen and (max-width: 800px) {
    grid: auto / 1fr;
    column-gap: 0px;
    row-gap: 15px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  position: relative;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
    color: #767676;
    strong {
      color: #332e2e;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  span {
    display: flex;
    p + p {
      margin: 0 0 0 10px;
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  transition: border-color 0.35s ease, box-shadow 0.35s ease;
  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0;
  }
`;

export const CodContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 3px;
`;

export const InfoContainer = styled.div``;

export const TSDesc = styled.span`
  font-size: 16px;
  color: #8a0002;
`;

export const StyledUpdate = styled(Update)`
  background: #595d88;
  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: ${shade(0.2, '#595d88')};
  }
`;

export const FinancButton = styled(Update)`
  background: #00802b;
  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: ${shade(0.2, '#00802b')};
  }
`;

export const StageBlock = styled.div<StageBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  margin: 10px 0;
  font-size: 16px;
  color: #262626;
  font-weight: 500;

  --red: ${parseToRgb('#d6d6d6').red};
  --green: ${parseToRgb('#d6d6d6').green};
  --blue: ${parseToRgb('#d6d6d6').blue};

  ${(props) =>
    props.bgcolor &&
    css`
      --red: ${parseToRgb(`${props.bgcolor}`).red};
      --green: ${parseToRgb(`${props.bgcolor}`).green};
      --blue: ${parseToRgb(`${props.bgcolor}`).blue};
    `}

  background-image: linear-gradient(
    to right,
    rgba(var(--red), var(--green), var(--blue), 0.25),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 1),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 0.25)
  );
`;
