import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
// import RadioContainer from 'components/RadioContainer';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';

import moment from 'moment';

import api from 'services/api';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useSpring } from 'react-spring';

import getValidationErrors from 'utils/getValidationErrors';
import { formatDate, handleTimeZone } from 'utils/formatDate';
import { parseISO, subDays, isValid } from 'date-fns';
import * as yup from 'yup';
import { ListProps } from '../main';
import { GuiasProps, ComboLocalServProps } from '../insert';
import {
  Wrapper,
  HeaderContainer,
  LocalServ,
  GuiasContainer,
  GuiasContainerHeader,
  GuiasContainerBody,
  ListItem,
  Name,
  Cert,
  Check,
} from '../insert/styles';

interface StateProps {
  reg: ListProps;
}

export interface FormData {
  date: string;
  localserv: string;
}

const DMGUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { currDate } = useChanges();
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState<GuiasProps[]>([]);
  const [guias, setGuias] = useState<number[]>([]);
  const [initGuias, setInitGuias] = useState<number[]>([]);

  const [comboLocalServ, setComboLocalServ] = useState<ComboProps[]>([]);

  const [registro] = useState(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.reg;
  });

  const [register, setRegister] = useState(registro.register);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isVirtual, setIsVirtual] = useState(() => {
    if (!location.state) {
      return false;
    }

    return ['4', '5', '6'].indexOf(location.state.reg.prq) > -1;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initLocalServ, setInitLocalServ] = useState(() => {
    if (!location.state) {
      return 'Selecione';
    }

    return ['4', '5', '6'].indexOf(location.state.reg.prq) > -1
      ? 'Selecione'
      : location.state.reg.prq;
    // return ['4', '5', '6'].indexOf(location.state.reg.prq) > -1
    //   ? 'Selecione'
    //   : location.state.reg.prqdesc;
  });

  const getGuias = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        anb: registro.anb,
        loc: registro.loc,
        date: registro.date,
        prq: registro.prq,
      };

      const response = await api.get(
        `/sgo/dug_guias.php?data=${JSON.stringify(data)}`,
      );

      setList(response.data);

      const filtered = response.data
        .filter((item: GuiasProps) => item.status)
        .map((item: GuiasProps) => item.status && item.seq);

      setGuias(filtered);
      setInitGuias(filtered);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, registro.anb, registro.date, registro.loc, registro.prq]);

  const getLocalServ = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLocalServ.php${
        ['ZON', 'INT'].indexOf(user.perfil) > -1
          ? `?data=${JSON.stringify({ getZon: true })}`
          : ''
      }`,
    );

    setComboLocalServ(
      response.data.map((item: ComboLocalServProps) => ({
        value: item.cod,
        label: `${item.nome}, ${item.cid}`,
      })),
    );
  }, [user.perfil]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }
    getGuias();
    getLocalServ();

    formRef.current?.setData({
      date: registro.date,
      localserv: registro.prq,
    });
  }, [
    getGuias,
    getLocalServ,
    history,
    location.pathname,
    location.state,
    registro.date,
    registro.prq,
  ]);

  const handleDate = useCallback(() => {
    const rawDate = new Date(formRef.current?.getFieldValue('date'));
    const { full } = currDate;
    if (!isValid(rawDate)) {
      return;
    }

    const eventDate = handleTimeZone(rawDate);

    const todayDate = full;

    const formats = 'YYYY-mm-dd';

    const eventMoment = moment(eventDate, formats);
    const todayMoment = moment(todayDate, formats);

    setRegister(todayMoment.isSameOrAfter(eventMoment));
  }, [currDate]);

  // const handleRadioClick = useCallback(() => {
  //   const val = formRef.current?.getFieldValue('virtual');

  //   setIsVirtual(val !== 'P');

  //   if (val !== 'P') {
  //     let code = '';
  //     const zoncod = user.zoncod.substring(0, 2);

  //     if (zoncod === 'BC') {
  //       code = '6';
  //     }
  //     if (zoncod === 'BL') {
  //       code = '4';
  //     }
  //     if (zoncod === 'BO') {
  //       code = '5';
  //     }
  //     formRef.current?.setFieldValue('localserv', code);
  //   } else if (['4', '5', '6'].indexOf(registro.prq) < 0) {
  //     formRef.current?.setFieldValue('localserv', registro.prq);
  //     setInitLocalServ(registro.prq);
  //   } else {
  //     formRef.current?.setFieldValue('localserv', '');
  //     setInitLocalServ('Selecione');
  //   }
  // }, [registro.prq, user.zoncod]);

  const handleCheckboxClick = useCallback(
    (seq: number) => {
      const index = guias.findIndex((item) => item === seq);

      if (index > -1) {
        setGuias(guias.filter((item) => item !== seq));
        return;
      }

      const newGuias = [...guias];
      newGuias.push(seq);
      setGuias(newGuias);
    },
    [guias],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const parsedMinDate = new Date(
          parseISO(`${new Date().getUTCFullYear()}-01-01`),
        );

        const parsedMaxDate = new Date(
          parseISO(`${new Date().getUTCFullYear() + 1}-12-31`),
        );

        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .min(
              parsedMinDate,
              `O lançamento deve ser posterior a ${formatDate(
                subDays(parsedMinDate, 1).toISOString(),
              )}`,
            )
            .max(
              parsedMaxDate,
              `Data limite excedida (${formatDate(
                new Date(parsedMaxDate).toISOString(),
              )})`,
            ),
          localserv: yup.string().when('virtual', {
            is: (val) => val !== 'V',
            then: yup
              .string()
              .notOneOf(
                ['Selecione', ''],
                'Local de Serviço/Atividade deve ser informado',
              ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // if (guias.length === 0) {
        //   addToast({
        //     type: 'info',
        //     title: 'Atenção',
        //     description:
        //       'A informação não foi registrada pois não há guias participantes.',
        //   });
        //   setLoading(false);
        //   return;
        // }

        const send = new FormData();
        const whichisit = formRef.current?.getFieldValue('virtual') === 'V';

        const newData = {
          oldData: registro.date,
          data: data.date,
          oldLocalServ: registro.prq,
          localserv: whichisit
            ? user.zoncod === 'BC99'
              ? '6'
              : user.zoncod === 'BL99'
              ? '4'
              : '5'
            : data.localserv,
          guias: register ? [...guias] : [],
          initGuias,
        };

        send.append('data', JSON.stringify(newData));

        await api.post('/sgo/dug_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Dia Universal do Guia alterado.',
        });

        history.replace(`${location.pathname.replace('/insert', '')}`);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      addToast,
      errorHandling,
      guias,
      history,
      initGuias,
      location.pathname,
      register,
      registro.date,
      registro.prq,
      user.zoncod,
    ],
  );

  const styledLserv = useSpring({
    opacity: isVirtual ? 0 : 1,
    pointerEvents: isVirtual ? 'none' : 'all',
  });

  const shouldRegister = useSpring({
    opacity: register ? 1 : 0,
    pointerEvents: register ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title="Alteração Dia Universal do Guia"
        isResponsible
      />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <HeaderContainer>
              <span>
                <p>Data</p>
                <Input
                  name="date"
                  type="date"
                  isDate
                  placeholder="dd/mm/aaaa"
                  onChange={handleDate}
                />
              </span>

              {/* <span>
                <p>Participação</p>
                <RadioContainer
                  title=""
                  onClick={handleRadioClick}
                  name="virtual"
                  content={[
                    {
                      id: 'vP',
                      value: 'P',
                      label: 'Presencial',
                    },
                    {
                      id: 'vT',
                      value: 'V',
                      label: 'Virtual',
                    },
                  ]}
                  itemsStyle={{
                    flexDirection: 'row',
                  }}
                  selected={isVirtual ? 'V' : 'P'}
                />
              </span> */}
              <LocalServ style={styledLserv}>
                <p>Local de Serviço/Atividade</p>
                <SelectV2
                  name="localserv"
                  initial={initLocalServ}
                  content={comboLocalServ}
                />
              </LocalServ>
            </HeaderContainer>
            <GuiasContainer style={shouldRegister}>
              <GuiasContainerHeader>
                <ListItem>
                  <Name>
                    <p>Guia</p>
                  </Name>
                  <Cert>
                    <p>Cert.</p>
                  </Cert>
                  <Check>
                    <p>Presença</p>
                  </Check>
                </ListItem>
              </GuiasContainerHeader>
              <GuiasContainerBody>
                {list.map((item, index) => (
                  <ListItem key={item.seq} paint={index}>
                    <Name>
                      <p>{item.name}</p>
                    </Name>
                    <Cert>
                      <p>{item.cert}</p>
                    </Cert>
                    <Check>
                      <Checkbox
                        id={`${item.seq}stat`}
                        title=""
                        containerStyle={{ margin: '0px' }}
                        onClick={() => handleCheckboxClick(item.seq)}
                        checked={item.status}
                      />
                    </Check>
                  </ListItem>
                ))}
              </GuiasContainerBody>
            </GuiasContainer>
          </Wrapper>

          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ margin: '25px 0' }}
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default DMGUpdate;
