import styled from 'styled-components';

interface ZonGridProps {
  lenght: number;
}

export const Title = styled.h1`
  font-size: 16px;

  margin: 10px auto;

  z-index: 1;

  padding: 5px;
  width: 100%;
  text-align: center;

  text-shadow: 0px 0px 5px #8a0002;
  color: #efefef;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(197, 48, 48, 0.25),
    rgba(197, 48, 48, 0.5),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(197, 48, 48, 0.5),
    rgba(197, 48, 48, 0.25),
    rgba(255, 255, 255, 0)
  );
  text-align: center;
`;

export const GridZon = styled.div<ZonGridProps>`
  margin: 25px 0;
  width: 90vw;

  max-width: ${(props) => (props.lenght > 1 ? '1300px' : '600px')};

  display: grid;

  grid: auto / 1fr ${(props) => props.lenght > 1 && '1fr'};

  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 775px) {
    grid: auto / 1fr;
  }
`;

export const GridNac = styled.div`
  margin: 25px 0;
  width: 90vw;
  max-width: 1300px;

  display: grid;

  grid: auto / 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 775px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  position: relative;
  width: auto;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 3px solid #efefef;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  &:hover {
    border-color: #332e2e;
  }
`;

export const GridFront = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  h5 {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }

  div {
    padding: 0 5px;
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      color: #c6c6c6;
      margin-right: 5px;

      transition: color 0.35s;
    }

    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      text-decoration: none;
      color: #332e2e;

      transition: color 0.35s;
      &:hover {
        svg {
          color: #0071dc;
        }
        color: #0071dc;
      }
    }

    & + div {
      margin-top: 5px;
    }
  }
`;
