import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';

import {
  Container,
  AlteredContent,
  FloatInput,
  Header,
} from 'styles/sgo_wrappers';

import * as O from 'styles/option_buttons';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import IncludeButton from 'components/IncludeButton';

import { useCredentials } from 'hooks/credentials';

import api from 'services/api';
import { removeAcento } from 'utils/specialChars';

import Checkbox from 'components/Checkbox';
import { FaPencilAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { LancContainer, LancTitle, Grid, GridItem, FloatCode } from './styles';

export interface ListProps {
  code: number;
  desc: string;
  type: string;
  stat: boolean;
  loc: boolean;
  nac: boolean;
  zon: boolean;
  plan: boolean;
}

const TipoLanc: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const { handlePermission, errorHandling } = useCredentials();
  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/tplanc_list.php');

      setList(response.data);
      setRawList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['GUI']);
    getList();
  }, [handlePermission, getList]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      setList(
        rawList.filter(
          (item) =>
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()) ||
            removeAcento(item.code.toString())
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()),
        ),
      );
    },
    [rawList],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.adm && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Tipos de Lançamento</Header>
      <FloatInput amount="100px">
        <input
          placeholder="Filtrar por descrição ou código"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="281px">
        {!loading && (
          <>
            <LancContainer>
              <LancTitle>
                <h3>Entradas</h3>
              </LancTitle>
              <Grid>
                {list.map(
                  (item) =>
                    item.type === 'E' && (
                      <GridItem key={item.code}>
                        <FloatCode>
                          <p>
                            Cod.:&nbsp;{item.code.toString().padStart(3, '0')}
                          </p>
                        </FloatCode>
                        <h4>{item.desc}</h4>

                        <span>
                          <p>
                            Status:&nbsp;
                            <strong>{item.stat ? 'A' : 'Ina'}tivo</strong>
                          </p>
                        </span>
                        <span>
                          {/* <p>Local&nbsp;</p> */}
                          <Checkbox
                            id={`L${item.code}`}
                            title="Local"
                            checked={item.loc}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                          {/* <p>Nacional&nbsp;</p> */}
                          <Checkbox
                            id={`N${item.code}`}
                            title="Nacional"
                            checked={item.nac}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                          {/* <p>Zonal&nbsp;</p> */}
                          <Checkbox
                            id={`Z${item.code}`}
                            title="Zonal"
                            checked={item.zon}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                        </span>
                        <O.GridOptions>
                          {user.adm && (
                            <O.Update
                              to={{
                                pathname: `${pathname}/update`,
                                state: { lanc: item },
                              }}
                            >
                              <FaPencilAlt />
                            </O.Update>
                          )}
                        </O.GridOptions>
                      </GridItem>
                    ),
                )}
              </Grid>
            </LancContainer>

            <LancContainer>
              <LancTitle>
                <h3>Saídas</h3>
              </LancTitle>
              <Grid>
                {list.map(
                  (item) =>
                    item.type === 'S' && (
                      <GridItem key={item.code}>
                        <FloatCode>
                          <p>Cod.: {item.code.toString().padStart(3, '0')}</p>
                        </FloatCode>
                        <h4>{item.desc}</h4>
                        <span>
                          <p>
                            Status:&nbsp;
                            <strong>{item.stat ? 'A' : 'Ina'}tivo</strong>
                          </p>
                        </span>
                        <span>
                          {/* <p>Local&nbsp;</p> */}
                          <Checkbox
                            id={`L${item.code}`}
                            title="Local"
                            checked={item.loc}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                          {/* <p>Nacional&nbsp;</p> */}
                          <Checkbox
                            id={`N${item.code}`}
                            title="Nacional"
                            checked={item.nac}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                          {/* <p>Zonal&nbsp;</p> */}
                          <Checkbox
                            id={`Z${item.code}`}
                            title="Zonal"
                            checked={item.zon}
                            isDisabled
                            titleStyle={{ color: '#767676' }}
                          />
                        </span>
                        <O.GridOptions>
                          {user.adm && (
                            <O.Update
                              to={{
                                pathname: `${pathname}/update`,
                                state: { lanc: item },
                              }}
                            >
                              <FaPencilAlt />
                            </O.Update>
                          )}
                        </O.GridOptions>
                      </GridItem>
                    ),
                )}
              </Grid>
            </LancContainer>
          </>
        )}
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default TipoLanc;
