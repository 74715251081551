import styled from 'styled-components';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

export const Grid = styled.div`
  margin: 10px 0 0 0;
  padding: 35px 10px 10px 10px;
  width: 100vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1300px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 950px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 630px) {
    width: 70vw;
    grid: auto / 1fr;

    row-gap: 15px;
  }

  /* @media screen and (max-width: 500px) {
    width: 100vw;
  }

  @media screen and (max-width: 400px) {
    padding: 35px 0px 10px 0px;
  } */
`;

export const YearContainer = styled.div`
  display: flex;
  /* margin: 125px 0 0 0; */
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 360px;
  align-items: center;
  justify-content: center;

  margin: 10px auto 0 auto;
  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  @media screen and (max-width: 460px) {
    margin: 60px auto 0 auto;
  }
  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100vw;
  }

  transition: all 0.5s;
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;

export const GridItem = styled.div`
  position: relative;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
    color: #767676;
    strong {
      color: #332e2e;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  span {
    display: flex;
    p + p {
      margin: 0 0 0 10px;
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  transition: border-color 0.35s ease, box-shadow 0.35s ease;
  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 630px) {
    min-width: 360px;
  }

  @media screen and (max-width: 360px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const InfoContainer = styled.div`
  margin: 25px 0 0 0;
`;

export const CodContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 3px;
`;
