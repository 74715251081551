import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { calcTotal, formatNumber } from 'utils/calcTotal';
import {
  FaArrowLeft,
  FaPlusSquare,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
} from 'react-icons/fa';
import { useSpring } from 'react-spring';
import { useWindow } from 'hooks/window';
import RadioContainer from 'components/RadioContainer';
import Textarea from 'components/Textarea';
import { checkAddress } from 'utils/specialChars';
import Button from 'components/Button';
import { StateProps } from '../consult';
import { ListProps } from '../main';

import { PhaseOne, HeaderComponent, BodyComponent, PhaseTwo } from './styles';

import {
  Grid,
  GridItem,
  ItemContainer,
  AddButton,
  FinishButton,
  InfoContainer,
  TotalSolic,
  ReturnButton,
  AddressWarn,
} from '../insert/styles';

interface SolicItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
  stored: number;
}

const SolicUpdate: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const phaseTwo = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);

  const [storageQuantity, setStorageQuantity] = useState('-');
  const [cost, setCost] = useState(0);

  const [deliverAddress, setDeliverAddress] = useState('');
  const [guiAddress, setGuiAddress] = useState('');
  const [locAddress, setLocAddress] = useState('');

  const [deliverAble, setDeliverAble] = useState(false);
  const [addressWarn, setAddressWarn] = useState('');

  const [solicHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.solic;
  });

  const [solicItems, setSolicItems] = useState<SolicItemsProps[]>([]);

  const [mats, setMats] = useState<ComboProps[]>([]);

  const getAddress = useCallback(async () => {
    const response = await api.get<{ LOC: string; GUIA: string }>(
      `/sgo/getAddress.php?data=${JSON.stringify({
        trans: 'solic',
        cod: solicHeader.loc,
      })}`,
    );

    const { LOC, GUIA } = response.data;

    if (LOC && GUIA) {
      if (checkAddress(LOC) === checkAddress(GUIA)) {
        setDeliverAddress(solicHeader.addressval === 'P' ? GUIA : LOC);
      } else {
        setDeliverAble(true);
        setDeliverAddress(solicHeader.addressval === 'P' ? GUIA : LOC);
        setGuiAddress(GUIA);
        setLocAddress(LOC);
        phaseTwo.current?.setFieldValue('deliver', 'P');
      }
    } else if (!LOC && GUIA) {
      setDeliverAddress(solicHeader.addressval === 'P' ? GUIA : LOC);
    } else if (!GUIA && LOC) {
      setDeliverAddress(solicHeader.addressval === 'P' ? GUIA : LOC);
    } else {
      setAddressWarn(
        'Esta coordenação não atende os requisitos de endereço de entrega.',
      );
    }
  }, [solicHeader.addressval, solicHeader.loc]);

  const initialSetup = useCallback(async () => {
    try {
      setLoading(true);
      const getMat = await api.get('/combos/comboMATs.php');

      const getItems = await api.get<SolicItemsProps[]>(
        `/sgo/solic_items.php?data=${JSON.stringify({
          solic: solicHeader.num,
          loccod: solicHeader.loc,
        })}`,
      );

      setSolicItems(getItems.data);

      setMats(() =>
        getMat.data.filter(
          (item: ComboProps) =>
            !getItems.data
              .map((mat: SolicItemsProps) => mat.cod)
              .includes(item.value),
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, solicHeader.loc, solicHeader.num]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }
    getAddress();
    initialSetup();
  }, [
    getAddress,
    history,
    initialSetup,
    location.pathname,
    location.state,
    solicHeader.loc,
    solicHeader.num,
  ]);

  const handleRemoveItem = useCallback(
    (mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.cod === mat,
      );

      const reinsert = {
        value: solicItems[index].cod,
        label: solicItems[index].desc,
      };

      setSolicItems((state) =>
        state.filter((item: SolicItemsProps) => item.cod !== mat),
      );

      const rearrange = [...mats, reinsert].sort((a, b) =>
        a.label > b.label ? 1 : -1,
      );

      setMats(rearrange);
    },
    [mats, solicItems],
  );

  const handleAdd = useCallback(
    (material: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.cod === material,
      );

      if (index > -1) {
        solicItems[index].qtd += 1;
        setSolicItems((state) => [...state]);
      }
    },
    [solicItems],
  );

  const handleSub = useCallback(
    (material: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.cod === material,
      );

      if (index > -1) {
        solicItems[index].qtd -= 1;
        setSolicItems((state) =>
          solicItems[index].qtd === 0
            ? [...state].filter(
                (item: SolicItemsProps) => item.cod !== solicItems[index].cod,
              )
            : [...state],
        );

        if (solicItems[index].qtd === 0) {
          const reinsert = {
            value: solicItems[index].cod,
            label: solicItems[index].desc,
          };

          const rearrange = [...mats, reinsert].sort((a, b) =>
            a.label > b.label ? 1 : -1,
          );

          setMats(rearrange);
        }
      }
    },
    [solicItems, mats],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.cod === mat,
      );
      const number = e.currentTarget.value.replace(/^0/, '');
      if (number.length > 0) {
        const asNum = parseInt(number, 10);

        if (index > -1) {
          solicItems[index].qtd = asNum;
          setSolicItems((state) =>
            solicItems[index].qtd <= 0
              ? [...state].filter(
                  (item: SolicItemsProps) => item.cod !== solicItems[index].cod,
                )
              : [...state],
          );
        }
      } else {
        solicItems[index].qtd = 0;
        setSolicItems((state) => [...state]);
      }

      e.currentTarget.value = e.currentTarget.value.replace(/^0/, '');
    },
    [solicItems],
  );

  const handleInputBlur = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.cod === mat,
      );
      if (e.currentTarget.value === '0') {
        setSolicItems((state) =>
          [...state].filter(
            (item: SolicItemsProps) => item.cod !== solicItems[index].cod,
          ),
        );

        const material = {
          value: solicItems[index].cod,
          label: solicItems[index].desc,
        };

        const rearrange = [...mats, material].sort((a, b) =>
          a.label > b.label ? 1 : -1,
        );

        setMats(rearrange);
      }
    },
    [solicItems, mats],
  );

  const handlePushMaterial = useCallback(() => {
    const index = mats.findIndex(
      (item: ComboProps) =>
        item.value === phaseOne.current?.getFieldValue('material'),
    );

    phaseOne.current?.setErrors({});

    if (index > -1) {
      const newMat = {
        cod: mats[index].value,
        desc: mats[index].label,
        value: cost,
        qtd: 1,
        stored: parseInt(storageQuantity, 10),
      };

      // setSolicItems((state) =>
      //   [...state, newMat].sort((a, b) => (a.matdesc > b.matdesc ? 1 : -1)),
      // );

      setSolicItems((state) => [newMat, ...state]);

      setMats((state) =>
        state.filter((item: ComboProps) => item.value !== newMat.cod),
      );

      setStorageQuantity('-');
      setCost(0);
    } else {
      phaseOne.current?.setFieldError(
        'material',
        'You must select at least one material',
      );

      addToast({
        type: 'info',
        title: ``,
        description: 'É necessário escolher um material.',
      });
    }

    phaseOne.current?.setFieldValue('material', '');
  }, [addToast, cost, mats, storageQuantity]);

  const handleMaterialSelect = useCallback(async () => {
    try {
      setLoading(true);
      phaseOne.current?.setErrors({});
      const material = phaseOne.current?.getFieldValue('material');

      const send = JSON.stringify({
        matcod: material,
        trans: 'solic',
        loccod: solicHeader.loc,
      });
      const response = await api.get(`/sgo/getStorage.php?data=${send}`);

      const { quantity, matcost } = response.data;

      setStorageQuantity(quantity);
      setCost(matcost);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, solicHeader.loc]);

  const handleRadioClick = useCallback(() => {
    const value = phaseTwo.current?.getFieldValue('deliver');

    setDeliverAddress(value === 'P' ? guiAddress : locAddress);
  }, [guiAddress, locAddress]);

  const totalSolic = useMemo(() => {
    const total = solicItems.reduce(
      (accum, { qtd, value }: SolicItemsProps) => {
        const semitotal = accum + qtd * value;
        return semitotal;
      },
      0,
    );

    return total;
  }, [solicItems]);

  const toggleFlexDirection = useSpring({
    flexDirection: width < 400 && totalSolic > 99.99 ? 'column' : 'row',
  });

  const styledProceedPhaseTwo = useSpring({
    transform: solicItems.length > 0 ? 'translateX(5px)' : 'translateX(60px)',
    opacity: solicItems.length > 0 ? 1 : 0,
    pointerEvents: solicItems.length > 0 ? 'all' : 'none',
  });

  const phaseOneStyle = useSpring({
    opacity: !finish ? 1 : 0,
    transform: !finish ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: !finish ? 'all' : 'none',
    display: !finish ? 'inline' : 'none',
  });

  const phaseTwoStyle = useSpring({
    opacity: finish ? 1 : 0,
    transform: finish ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: finish ? 'all' : 'none',
    display: finish ? 'inline' : 'none',
  });

  const warnStyle = useSpring({
    opacity: addressWarn.length > 0 ? 1 : 0,
    height: addressWarn.length > 0 ? (width <= 500 ? '58px' : '30px') : '0px',
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        local: { cod: solicHeader.loc, desc: solicHeader.locdesc },
        items: solicItems,
        address: {
          value: phaseTwo.current?.getFieldValue('deliver'),
          label: deliverAddress,
        },
        obs: phaseTwo.current?.getFieldValue('obs').replace('#', ''),
        solnum: solicHeader.num,
        dtsolic: solicHeader.dtsolic,
        name: user.name,
        anbdesc: user.anbdesc,
        locdesc: user.locdesc,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      await api.post('/sgo/solic_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Solicitação N° ${data.solnum} alterada${
          user.perfil === 'NAC' ? ` para a Local ${solicHeader.locdesc}` : ''
        }.`,
      });

      history.replace(location.pathname.replace('/update', ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    deliverAddress,
    errorHandling,
    history,
    location.pathname,
    solicHeader.dtsolic,
    solicHeader.loc,
    solicHeader.locdesc,
    solicHeader.num,
    solicItems,
    user.anbdesc,
    user.locdesc,
    user.name,
    user.perfil,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Alteração Solicitação N° ${solicHeader.num}`}
        isResponsible
      />
      <Content>
        <AddressWarn style={warnStyle}>
          <h4>{addressWarn}</h4>
        </AddressWarn>
        <PhaseOne style={phaseOneStyle}>
          {user.perfil === 'NAC' && (
            <HeaderComponent>
              <span>
                <p>
                  Coordenação: <strong>{solicHeader.locdesc}</strong>
                </p>
              </span>
            </HeaderComponent>
          )}
          <BodyComponent>
            <Form
              ref={phaseOne}
              onSubmit={() => {
                return null;
              }}
            >
              <ItemContainer>
                <span>
                  <SelectV2
                    name="material"
                    content={mats}
                    initial="Selecione"
                    onChange={handleMaterialSelect}
                  />
                </span>
                <span>
                  <p>Estoque da Local:</p>
                  <strong>{storageQuantity}</strong>
                </span>
              </ItemContainer>
              <AddButton type="button" onClick={handlePushMaterial}>
                <FaPlusSquare /> <p>Adicionar material</p>
              </AddButton>
              <InfoContainer>
                <TotalSolic style={toggleFlexDirection}>
                  <p>Total desta solicitação:&nbsp;</p>
                  <strong>R$ {formatNumber(totalSolic)}</strong>
                </TotalSolic>

                <FinishButton
                  style={styledProceedPhaseTwo}
                  type="button"
                  onClick={() => setFinish(true)}
                >
                  Avançar
                </FinishButton>
              </InfoContainer>

              <Grid>
                {solicItems.map((item: SolicItemsProps) => (
                  <GridItem key={item.cod}>
                    <div>
                      <strong>
                        {item.cod} - {item.desc}
                      </strong>
                    </div>

                    <div>
                      <span>
                        <p>
                          Custo:&nbsp;
                          <strong>R$ {formatNumber(item.value)}</strong>
                        </p>
                      </span>
                      <span>
                        <p>Quantidade:</p>
                        <span>
                          <FaSortUp
                            onClick={() => handleAdd(item.cod)}
                            style={{
                              transform: 'translateY(10px)',
                              color: '#009933',
                            }}
                          />

                          <input
                            type="number"
                            min={0}
                            value={item.qtd}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              handleInputChange(e, item.cod)
                            }
                            onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                              handleInputBlur(e, item.cod)
                            }
                          />

                          <FaSortDown
                            onClick={() => handleSub(item.cod)}
                            style={{
                              transform: 'translateY(-10px)',
                              color: '#c53030',
                            }}
                          />
                        </span>
                        <figure>
                          <FaTimesCircle
                            onClick={() => handleRemoveItem(item.cod)}
                          />
                        </figure>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Estoque Local atual:&nbsp;
                          <strong>{item.stored}</strong>
                        </p>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Total:&nbsp;
                          <strong>R$ {calcTotal(item.qtd, item.value)}</strong>
                        </p>
                      </span>
                    </div>
                  </GridItem>
                ))}
              </Grid>
            </Form>
          </BodyComponent>
        </PhaseOne>
        <PhaseTwo style={phaseTwoStyle}>
          <ReturnButton onClick={() => setFinish(false)}>
            <FaArrowLeft />
            <p>Retornar aos itens</p>
          </ReturnButton>
          <Form
            ref={phaseTwo}
            onSubmit={() => {
              return null;
            }}
          >
            <div>
              {deliverAble && (
                <RadioContainer
                  title="Endereço de Entrega:"
                  onClick={handleRadioClick}
                  name="deliver"
                  content={[
                    {
                      id: 'dT',
                      value: 'P',
                      label: 'Tesoureiro',
                    },
                    {
                      id: 'dL',
                      value: 'S',
                      label: 'Local',
                    },
                  ]}
                  selected={solicHeader.addressval}
                />
              )}

              <p>{deliverAddress}</p>
            </div>
            <div>
              <p>Observação:</p>
              <Textarea
                name="obs"
                placeholder="Insira a observação (opcional)"
              />
            </div>
          </Form>
          {addressWarn.length <= 0 && (
            <Button onClick={handleFinish} bgcolor="#00802b">
              Finalizar
            </Button>
          )}
        </PhaseTwo>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default SolicUpdate;
