import React, { useEffect, useState, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { Header, ComboProps, Container, Content } from 'styles/sgo_wrappers';

import api from 'services/api';
import { monthList, MonthListProps } from 'pages/sgo/Tesouraria/monthList';
import {
  Wrapper,
  Table,
  NFSVG,
  ReceiptSVG,
  SelectionContainer,
  LeftSVG,
  RightSVG,
  SelectionItem,
} from './styles';

interface ListProps {
  cod: string;
  desc: string;
  year: number;
  month: number;
  vlrnf: string;
  receipt: string;
  dtpag: string;
}

export const Conciliacao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [years, setYears] = useState<ComboProps[]>([]);
  const [currYear, setCurrYear] = useState(() => new Date().getUTCFullYear());
  const [currMonth, setCurrMonth] = useState(
    () => new Date().getUTCMonth() + 1,
  );

  const getCoords = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/fechamento/fechamento_conciliacao.php?data=${JSON.stringify({
        year: currYear,
        month: currMonth,
      })}`,
    );
    const { coords, anos } = response.data;
    setRawList(coords);
    setList(
      coords.filter(
        (item: ListProps) => item.year === currYear && item.month === currMonth,
      ),
    );
    setYears(anos);

    setLoading(false);
  }, [currMonth, currYear]);

  useEffect(() => {
    getCoords();
  }, [getCoords]);

  const handleClick = useCallback(
    (cod: string, mode: string) => {
      const link = document.querySelector<HTMLAnchorElement>(
        `a[id=a${cod}_${currYear}_${currMonth}_${mode}]`,
      );

      if (link) {
        link.click();
      }
    },
    [currMonth, currYear],
  );

  const handleIncreaseYear = useCallback(() => {
    setCurrYear((state) => state + 1);
    // getCoords(currYear + 1);
    // setList(
    //   rawList.filter(
    //     (item: ListProps) =>
    //       item.year === currYear + 1 && item.month === currMonth,
    //   ),
    // );
  }, []);

  const handleDecreaseYear = useCallback(() => {
    setCurrYear((state) => state - 1);
    // getCoords(currYear - 1);
    // setList(
    //   rawList.filter(
    //     (item: ListProps) =>
    //       item.year === currYear - 1 && item.month === currMonth,
    //   ),
    // );
  }, []);

  const handleIncreaseMonth = useCallback(() => {
    setCurrMonth((state) => state + 1);
    // setList(
    //   rawList.filter(
    //     (item: ListProps) =>
    //       item.year === currYear && item.month === currMonth + 1,
    //   ),
    // );
  }, []);

  const handleDecreaseMonth = useCallback(() => {
    setCurrMonth((state) => state - 1);
    // setList(
    //   rawList.filter(
    //     (item: ListProps) =>
    //       item.year === currYear && item.month === currMonth - 1,
    //   ),
    // );
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar />
      <Header>
        <div>Conciliação de Pagamentos</div>
      </Header>
      <SelectionContainer>
        <SelectionItem>
          {years.length > 0 && currYear > parseInt(years[0].value, 10) && (
            <LeftSVG onClick={handleDecreaseYear} />
          )}
          <p>{currYear}</p>
          {years.length > 0 &&
            currYear < parseInt(years[years.length - 1].value, 10) && (
              <RightSVG onClick={handleIncreaseYear} />
            )}
        </SelectionItem>
        <SelectionItem>
          {currMonth > 1 && <LeftSVG onClick={handleDecreaseMonth} />}
          <p>
            {
              monthList[
                monthList.findIndex(
                  (month: MonthListProps) => month.value === currMonth,
                )
              ].label
            }
          </p>
          {currMonth < 12 && <RightSVG onClick={handleIncreaseMonth} />}
        </SelectionItem>
      </SelectionContainer>
      <Content>
        <Wrapper>
          <Table>
            <thead>
              <tr>
                <td style={{ textAlign: 'left' }}>
                  <h4>Coordenação</h4>
                </td>
                <td>
                  <h4>NF</h4>
                </td>
                <td>
                  <h4>PGTO</h4>
                </td>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={`${item.cod}_${item.year}_${item.month}`}>
                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <td style={{ textAlign: 'left' }}>
                      <p>{item.desc}</p>
                    </td>
                    <td>
                      {!!item.vlrnf && (
                        <a
                          id={`a${item.cod}_${currYear}_${currMonth}_pdf`}
                          href={`${
                            process.env.REACT_APP_FATURAMENTO
                          }/${currYear}/${
                            monthList[
                              monthList.findIndex(
                                (month: MonthListProps) =>
                                  month.value === item.month,
                              )
                            ].label
                          }/${item.cod}_${currYear}_${`0${item.month}`.slice(
                            -2,
                          )}.pdf`}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          NF
                        </a>
                      )}
                      <NFSVG
                        svgcolor={item.vlrnf ? '#00802b' : '#8a0002'}
                        onClick={() => handleClick(item.cod, 'pdf')}
                        style={{
                          cursor: item.vlrnf ? 'pointer' : 'not-allowed',
                        }}
                      />
                    </td>
                    <td>
                      {!!item.receipt && (
                        <a
                          id={`a${item.cod}_${currYear}_${currMonth}_receipt`}
                          href={`${process.env.REACT_APP_COMPROVANTE}/${item.receipt}`}
                          rel="noreferrer noopener"
                          target="_blank"
                          // download="Comprovante"
                        >
                          Comprovante
                        </a>
                      )}
                      <ReceiptSVG
                        svgcolor={item.receipt ? '#00802b' : '#8a0002'}
                        onClick={() => handleClick(item.cod, 'receipt')}
                        style={{
                          cursor: item.receipt ? 'pointer' : 'not-allowed',
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Wrapper>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Conciliacao;
