import React, { useCallback, useRef, useState, HTMLAttributes } from 'react';

import { useToast } from 'hooks/toast';

import Button from 'components/Button';
import Loading from 'components/Loading';

import { toPng } from 'html-to-image';

import { Container, Wrapper } from './styles';

interface ImageGeneratorProps extends HTMLAttributes<HTMLDivElement> {
  containerStyle?: object;
  wrapperStyle?: object;
  buttonColor?: string;
  buttonText?: string;
  imageName?: string;
}

const ImageGenerator: React.FC<ImageGeneratorProps> = ({
  children,
  containerStyle = {},
  wrapperStyle = {},
  buttonColor = '#bc4143',
  buttonText = 'Gerar imagem',
  imageName = 'imagem',
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleGeneration = useCallback(() => {
    if (wrapperRef.current === null) {
      return;
    }
    setLoading(true);
    toPng(wrapperRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${imageName}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Não foi possível gerar a imagem.',
        });
      });
    setLoading(false);
  }, [addToast, imageName]);

  return (
    <Container style={containerStyle}>
      <Loading isLoading={loading} />
      <Wrapper style={wrapperStyle} ref={wrapperRef}>
        {children}
      </Wrapper>
      <Button
        onClick={handleGeneration}
        bgcolor={buttonColor}
        containerStyle={{ margin: '15px auto' }}
      >
        {buttonText}
      </Button>
    </Container>
  );
};

export default ImageGenerator;
