import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';

import ScrollTop from 'components/ScrollTop';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import RadioContainer from 'components/RadioContainer';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import { useCredentials } from 'hooks/credentials';
import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import Textarea from 'components/Textarea';
import { useHistory, useLocation } from 'react-router-dom';
import { FormContent, CheckboxContainer } from './styles';

export interface FormData {
  type: string;
  description: string;
  loc: boolean;
  nac: boolean;
  zon: boolean;
  plan: boolean;
}

const TipoLancInsert: React.FC = () => {
  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handlePermission(['ZON'], true);
  }, [handlePermission]);

  const handleCheckbox = useCallback((field: string) => {
    const input = document.querySelector<HTMLInputElement>(
      `input[id="${field}"]`,
    );

    const value = input?.checked;
    const loc = formRef.current?.getFieldValue('loc');
    const nac = formRef.current?.getFieldValue('nac');
    const zon = formRef.current?.getFieldValue('zon');
    const plan = formRef.current?.getFieldValue('plan');

    formRef.current?.setData({
      loc: field === 'loc' ? value : loc,
      nac: field === 'nac' ? value : nac,
      zon: field === 'zon' ? value : zon,
      plan: field === 'plan' ? value : plan,
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          type: yup.string().notOneOf(['on', ''], 'Este campo é obrigatório'),
          description: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/tplanc_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });
        history.replace(location.pathname.replace('/insert', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Novo Tipo de Lançamento" isResponsible />

      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            loc: false,
            nac: false,
            zon: false,
            plan: false,
          }}
        >
          <Input name="loc" isHidden />
          <Input name="nac" isHidden />
          <Input name="zon" isHidden />
          <Input name="plan" isHidden />
          <FormContent>
            <RadioContainer
              title="Tipo de Lançamento"
              // onClick={handleRadioClick}
              name="type"
              content={[
                {
                  id: 'tpE',
                  value: 'E',
                  label: 'Entrada',
                },
                {
                  id: 'tpS',
                  value: 'S',
                  label: 'Saída',
                },
              ]}
              itemsStyle={{
                flexDirection: 'row',
              }}
            />
            <span>
              <p>Descrição</p>
              <Textarea
                name="description"
                placeholder="Descrição do Tipo de Lançamento"
                mask="alpha"
              />
            </span>
            <span>
              <p>Disponível para:</p>
            </span>
            <CheckboxContainer>
              <p>Local</p>
              <Checkbox
                id="loc"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('loc')}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Nacional</p>
              <Checkbox
                id="nac"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('nac')}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Zonal</p>
              <Checkbox
                id="zon"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('zon')}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <p>Planejamento</p>
              <Checkbox
                id="plan"
                title=""
                containerStyle={{ margin: '0px' }}
                onClick={() => handleCheckbox('plan')}
              />
            </CheckboxContainer>
          </FormContent>
          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ marginBottom: '20px' }}
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default TipoLancInsert;
