import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import InputDialog from 'components/InputDialog';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import api from 'services/api';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';
import { FaPencilAlt, FaFileExcel } from 'react-icons/fa';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';
import * as yup from 'yup';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import getValidationErrors from 'utils/getValidationErrors';
import { formatNumber } from 'utils/calcTotal';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';

import { TableWrapper, Table, TetoContainer } from './styles';

interface LocalStorageProps {
  coordStringfied?: string;
}

interface ListProps {
  anb: string;
  anbd: string;
  loc: string;
  locd: string;
  sld: number;
  receive: number;
  pay: number;
}

interface FormData {
  roof: string;
}
const TetoDisponibilidade: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const filterRef = useRef<FormHandles>(null);
  const { width } = useWindow();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [list, setList] = useState<ListProps[]>([]);
  const [currRoof, setCurrRoof] = useState(0);
  const [coord, setCoord] = useState(() => {
    const { coordStringfied }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_TETO}`,
    );

    if (coordStringfied) {
      const { cod, desc } = JSON.parse(coordStringfied);
      return { cod, desc };
    }

    return { cod: user.anbc, desc: user.anbdesc };
  });

  const [initialNac, setInitialNac] = useState(() => {
    return coord.cod;
  });
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          mode: 'list',
          anbcod: coord.cod,
        }),
      );
      const response = await api.post('/sgo/teto_disponibilidade.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { records, roof } = response.data;

      setList(records);

      setCurrRoof(roof);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [coord.cod, errorHandling]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    // setInitialNac(user.anbdesc);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON'], true);
    getComboANB();
    getList();
  }, [getComboANB, getList, handlePermission]);

  const handlePreUpdate = useCallback(() => {
    setUpdateDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        setUpdateDiag((state) => ({ ...state, open: false }));

        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          roof: yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify({ ...data, mode: 'update' }));

        await api.post('/sgo/teto_disponibilidade.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setCurrRoof(parseFloat(data.roof));
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Teto de disponibilidade alterado.',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        errorHandling(err);
      }
    },
    [addToast, errorHandling],
  );

  const handleANBSelection = useCallback(() => {
    const select = filterRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({ cod: value, desc: text });
    setInitialNac(setInitialNac);
    setLocalStorage(`${process.env.REACT_APP_TETO}`, {
      coordStringfied: JSON.stringify({ cod: value, desc: text }),
    });
  }, []);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={user.perfil === 'ZON'}
        filterContent={
          <Form ref={filterRef} onSubmit={() => null}>
            <div>
              <span>
                <p>Filtre por Nacional:</p>
                <SelectV2
                  name="comboANB"
                  content={comboNacs}
                  onChange={handleANBSelection}
                  initial={initialNac}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Base para Cálculo do Teto de Disponibilidade das Locais</p>
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>

        <Button
          bgcolor="#332e2e"
          containerStyle={{ margin: '10px 0' }}
          onClick={handleDownload}
        >
          <p>Gerar planilha</p>&emsp;
          <FaFileExcel />
        </Button>

        <TableWrapper>
          <Table>
            <thead>
              <td>Local</td>
              {width >= 615 && <td>Descrição</td>}

              <td>Saldo{width >= 435 ? ' do Caixa' : ''}</td>
              <td>{width >= 435 ? 'Contas ' : ''}a Receber</td>
              <td>{width >= 435 ? 'Contas ' : ''}a Pagar</td>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.loc}>
                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <td>{item.loc}</td>
                    {width >= 615 && <td>{item.locd}</td>}

                    <td>{formatNumber(item.sld)}</td>
                    <td>{formatNumber(item.receive)}</td>
                    <td>{formatNumber(item.pay)}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <TetoContainer>
          <strong>Teto de Disponibilidade para as Locais:</strong>
          <p>{formatNumber(currRoof)}</p>
          {user.perfil === 'NAC' && (
            <button type="button" onClick={handlePreUpdate}>
              <FaPencilAlt />
            </button>
          )}
        </TetoContainer>
        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <Form
            ref={formRef}
            onSubmit={handleUpdate}
            initialData={{
              roof: currRoof.toFixed(2),
            }}
          >
            <U.Title>
              <h2>Ajuste no Teto de Disponibilidade</h2>
            </U.Title>
            <U.Content>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <strong style={{ fontSize: '16px', margin: '0 0 10px 0' }}>
                  Informe o teto de disponibilidade para todas as Locais da sua
                  Nacional:
                </strong>

                <InputDialog
                  name="roof"
                  containerStyle={{ height: '30px', width: '100px' }}
                  inputStyle={{ width: '70px', textAlign: 'right' }}
                  type="number"
                  step=".01"
                  min="0"
                  mask="currency"
                  placeholder="0"
                />
              </div>
            </U.Content>
            <U.Actions>
              <U.Cancel type="button" onClick={handlePreUpdate}>
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>
        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_teto_disponibilidade.php?data=${JSON.stringify({
            cod: coord.cod,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          XLS
        </a>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
        <S.Title>
          <h2>Gerar XLS</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default TetoDisponibilidade;
