import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';

import { useSpring } from 'react-spring';

import {
  AlteredHeader,
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import RadioContainer from 'components/RadioContainer';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import {
  FaBackspace,
  FaExclamationTriangle,
  FaPlus,
  FaPlusCircle,
  FaTimes,
} from 'react-icons/fa';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { useToast } from 'hooks/toast';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AddButton,
  DistinctContent,
  FloatMassiveError,
  FloatDistinctError,
  FormContent,
  InputContainer,
  MassiveContent,
  ReturnedNumbers,
  ReturnedContent,
} from './styles';

interface ValuesProps {
  id: string;
  value: string;
}

interface ComboMat {
  value: string;
  label: string;
  matcntr: string;
}

interface FormData {
  destiny: string;
  coord: string;
  material: string;
  mode: string;
  start: string;
  end: string;
}

interface RespProps {
  uuid: string;
  message: string;
  num: string;
}

const CntrNumInsert: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { handlePermission, errorHandling } = useCredentials();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [comboMats, setComboMats] = useState<ComboProps[]>([]);
  const [field, setField] = useState<ValuesProps[]>([{ id: 'i0', value: '' }]);
  const [mode, setMode] = useState('F');
  const [destiny, setDestiny] = useState('U');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [massiveError, setMassiveError] = useState(false);
  const [distinctError, setDistinctError] = useState(false);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [resp, setResp] = useState<RespProps[]>([]);

  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const getComboMATS = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/combos/comboMATs.php');
      setComboMats(
        response.data.filter((item: ComboMat) => item.matcntr === 'S'),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setComboLocs(
      response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
    );
  }, [user.anbc]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  useEffect(() => {
    handlePermission(['ZON', 'NAC'], true);
    getComboMATS();
    if (user.perfil === 'ZON') {
      getComboANB();
      return;
    }
    getComboLOC();
  }, [handlePermission, getComboMATS, user.perfil, getComboLOC, getComboANB]);

  const handleDestiny = useCallback(() => {
    const radio = formRef.current?.getFieldValue('destiny');
    setDestiny(radio);
  }, []);

  const handleMode = useCallback(() => {
    const radio = formRef.current?.getFieldValue('mode');
    setMode(radio);
  }, []);

  const handleAdd = useCallback(() => {
    setField((state) => {
      const last =
        parseInt(state[state.length - 1].id.replace('i', ''), 10) + 1;
      return [...state, { id: `i${last}`, value: '' }];
    });
  }, []);

  const handleSub = useCallback((received: string) => {
    setField((state) => state.filter((item) => item.id !== received));
  }, []);

  const handleChange = useCallback(
    (val: string, id: string) => {
      let numberString = val;
      numberString = numberString.replace(/^0/g, '').replace(/[^0-9]/gi, '');
      const index = field.findIndex((item) => item.id === id);

      field[index].value = numberString;

      setField((state) => [...state]);
    },
    [field],
  );

  const handleDAChange = useCallback((val: string, id: string) => {
    let numberString = val;

    numberString = numberString.replace(/^0/g, '').replace(/[^0-9]/gi, '');

    if (id === 'start') {
      setStart(numberString);
    } else {
      setEnd(numberString);
    }
  }, []);

  const handleClear = useCallback(
    (id: string) => {
      const index = field.findIndex((item) => item.id === id);

      field[index].value = '';

      setField((state) => [...state]);
    },
    [field],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});
        setMassiveError(false);
        setDistinctError(false);
        setResp([]);

        const schema = yup.object().shape({
          coord: yup.string().when('destiny', {
            is: (dest) => dest === 'O',
            then: yup.string().required(),
            otherwise: yup.string().nullable(true),
          }),
          material: yup.string().notOneOf(['', 'Selecione']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (mode === 'M') {
          if (!start || !end) {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description: 'Os números não foram preenchidos',
            });
            setMassiveError(true);
            setLoading(false);
            return;
          }

          if (parseInt(start, 10) > parseInt(end, 10)) {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description:
                'O número inicial deve ser menor que o número final.',
            });
            setMassiveError(true);
            setLoading(false);
            return;
          }
        } else {
          const amount = field.reduce((accum, { value }: ValuesProps) => {
            return value ? accum + 1 : accum;
          }, 0);

          if (amount === 0) {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description: 'Ao menos um número deve ser preenchido',
            });
            setDistinctError(true);
            setLoading(false);
            return;
          }

          const duplicates = field.reduce((accum, { value }: ValuesProps) => {
            const count = field.filter((obj) => obj.value === value).length;
            return count > 1 ? accum + 1 : accum;
          }, 0);

          if (duplicates > 0) {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description:
                'Há números duplicados, verifique antes de prosseguir.',
            });
            setDistinctError(true);
            setLoading(false);
            return;
          }
        }

        const send = new FormData();

        const values = field
          .filter((item) => {
            return item.value !== '';
          })
          .map((item) => item.value);

        send.append(
          'data',
          JSON.stringify({
            ...data,
            values,
            start,
            end,
          }),
        );

        const response = await api.post('/sgo/cntrnum_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const { returned } = response.data;
        if (returned) {
          setResp(returned);
          setShow((state) => ({
            ...state,
            title:
              comboMats[
                comboMats.findIndex(
                  (item) =>
                    item.value === formRef.current?.getFieldValue('material'),
                )
              ].label,
            content: (
              <ReturnedContent>
                {returned.map((item: RespProps) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: item.message }}
                    key={item.uuid}
                  />
                ))}
              </ReturnedContent>
            ),
          }));

          const fieldFiltered = field.filter((item) =>
            returned
              .map((ret: RespProps) => ret.num.toString())
              .includes(item.value),
          );

          if (fieldFiltered.length === 0) {
            fieldFiltered.push({ id: 'i0', value: '' });
          }
          setLoading(false);
          return;
        }

        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Todas as numerações foram incluídas',
        });

        history.replace(location.pathname.replace('/insert', ''));
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      comboMats,
      end,
      errorHandling,
      field,
      history,
      location.pathname,
      mode,
      start,
    ],
  );

  const styledMassive = useSpring({
    opacity: mode === 'F' ? 0 : 1,
    pointerEvents: mode === 'F' ? 'none' : 'all',
    height: mode === 'F' ? 0 : 'auto',
    transform: mode === 'F' ? 'translateX(500px)' : 'translateX(0px)',
  });

  const styledDistinct = useSpring({
    opacity: mode === 'M' ? 0 : 1,
    pointerEvents: mode === 'M' ? 'none' : 'all',
    height: mode === 'M' ? 0 : 'auto',
    transform: mode === 'M' ? 'translateX(-500px)' : 'translateX(0px)',
  });

  const returnedNumbers = useSpring({
    opacity: resp.length > 0 ? 1 : 0,
    height: resp.length > 0 ? '75px ' : '0px',
    marginBottom: resp.length > 0 ? '0px' : mode === 'M' ? '-25px' : '-35px',
    transform: resp.length > 0 ? 'translateY(0px)' : 'translateY(-35px)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks />
      <Content>
        <AlteredHeader>Materiais Numerados - INCLUSÃO</AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContent>
            <div>
              <p>Coordenação de Destino</p>
              <RadioContainer
                title=""
                onClick={handleDestiny}
                name="destiny"
                content={[
                  {
                    id: 'uC',
                    value: 'U',
                    label: `Minha ${
                      user.perfil === 'ZON' ? 'Zonal' : 'Nacional'
                    }`,
                  },
                  {
                    id: 'O',
                    value: 'O',
                    label: user.perfil === 'ZON' ? 'Nacional' : 'Local',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected="U"
              />
            </div>
            <div>
              <p>Coordenação</p>
              <SelectV2
                name="coord"
                content={user.perfil === 'ZON' ? comboNacs : comboLocs}
                disabled={destiny === 'U'}
                initial="Selecione"
              />
            </div>
            <div>
              <p>Material</p>
              <SelectV2 name="material" content={comboMats} />
            </div>
            <div>
              <p>Modo de envio:</p>
              <RadioContainer
                title=""
                onClick={handleMode}
                name="mode"
                content={[
                  {
                    id: 'few',
                    value: 'F',
                    label: 'Números distintos',
                  },
                  {
                    id: 'mass',
                    value: 'M',
                    label: 'Massivo',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={mode}
              />
            </div>
            <DistinctContent style={styledDistinct}>
              <p>Números:</p>
              {field.map((item: ValuesProps, index) => (
                <InputContainer>
                  <span>
                    <input
                      type="number"
                      placeholder="000000"
                      name={item.id}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleChange(e.target.value, item.id);
                      }}
                      value={item.value}
                    />
                    {item.value !== '' && (
                      <FaBackspace onClick={() => handleClear(item.id)} />
                    )}
                  </span>
                  {index > 0 && (
                    <button type="button" onClick={() => handleSub(item.id)}>
                      <FaTimes />
                    </button>
                  )}
                </InputContainer>
              ))}
              <AddButton type="button" onClick={handleAdd}>
                <FaPlusCircle />
                <p>Numeração</p>
              </AddButton>

              <FloatDistinctError error={distinctError}>
                <FaExclamationTriangle />
              </FloatDistinctError>
            </DistinctContent>

            <MassiveContent style={styledMassive}>
              <p>Números:</p>
              <span>
                <p>De: </p>
                <input
                  type="number"
                  placeholder="000000"
                  name="start"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleDAChange(e.target.value, 'start');
                  }}
                  value={start}
                />
                {start !== '' && <FaBackspace onClick={() => setStart('')} />}
              </span>
              <span>
                <p>Até:</p>
                <input
                  type="number"
                  placeholder="000000"
                  name="end"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleDAChange(e.target.value, 'end');
                  }}
                  value={end}
                />
                {end !== '' && <FaBackspace onClick={() => setEnd('')} />}
              </span>
              <FloatMassiveError error={massiveError}>
                <FaExclamationTriangle />
              </FloatMassiveError>
            </MassiveContent>

            <ReturnedNumbers style={returnedNumbers}>
              <span>
                <p>
                  Os números remanescentes não foram inclusos por duplicidade.
                </p>
              </span>

              <button
                type="button"
                onClick={() => setShow((state) => ({ ...state, open: true }))}
              >
                <FaPlus />
                <p> Detalhes</p>
              </button>
            </ReturnedNumbers>
          </FormContent>
          {/* {resp.map((item) => (
            <React.Fragment key={item.uuid}>
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
            </React.Fragment>
          ))} */}
          <Button
            type="submit"
            containerStyle={{ margin: '25px 0' }}
            bgcolor="#00802b"
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default CntrNumInsert;
