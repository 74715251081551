import React, { useCallback, useEffect, useState } from 'react';

import { FaPencilAlt, FaSearch, FaTimes } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
import { AlteredContent, Container, Header } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import ScrollTop from 'components/ScrollTop';

import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { deconvertSpecialChars } from 'utils/specialChars';

import api from 'services/api';

import * as S from 'styles/dialog_consult';
import * as D from 'styles/dialog_delete';
import * as O from 'styles/option_buttons';
import { ShowProps } from 'styles/dialog_consult';
import { DeleteProps } from 'styles/dialog_delete';
import { GroupContainer, GridItem, GridFront } from './styles';

interface GruposProps {
  grucod: string;
  grudesc: string;
  qtde: number;
  updated_at: string;
}

const GrupoMateriais: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const { errorHandling } = useCredentials();

  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });
  const [grupos, setGrupos] = useState<GruposProps[]>([]);

  const getGrupos = useCallback(async () => {
    try {
      const response = await api.get('/sgo/grupo_list.php');

      const toSet = response.data.map((item: GruposProps) => ({
        grucod: item.grucod,
        grudesc: deconvertSpecialChars(item.grudesc),
        qtde: item.qtde,
        updated_at: item.updated_at,
      }));

      setGrupos(toSet);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getGrupos();
  }, [getGrupos]);

  const handleShow = useCallback(({ open, content, title }: ShowProps) => {
    setShow({ open, content, title });
  }, []);

  const handleDeleteDiag = useCallback(
    ({ open, content, values }: DeleteProps) => {
      setDeleteDiag({ open, content, values });
    },
    [],
  );

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);
          await api.get(`/sgo/grupo_delete.php?code=${values.code}`);

          const removedFromGroup = grupos.filter(
            (group) => group.grucod !== values.code,
          );

          setGrupos(removedFromGroup);
          setLoading(false);
          setDeleteDiag({ open: false });

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Grupo de Material excluído com sucesso!',
          });
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [errorHandling, grupos, addToast],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.adm && <IncludeButton />}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Grupos de Material</Header>
      <AlteredContent pixels="231px">
        <GroupContainer>
          {grupos.map((group: GruposProps) => (
            <GridItem key={group.grucod}>
              <GridFront>
                <p>
                  Código:&nbsp;<strong>{group.grucod}</strong>
                </p>
                <p>
                  Descrição:&nbsp;
                  <strong>{group.grudesc}</strong>
                </p>
              </GridFront>

              <O.GridOptions>
                <O.Show
                  onClick={() =>
                    handleShow({
                      title: group.grudesc,
                      open: true,
                      content: (
                        <div>
                          <p>
                            Código:&nbsp;<strong>{group.grucod}</strong>
                          </p>
                          <p>
                            Descrição:&nbsp;<strong>{group.grudesc}</strong>
                          </p>
                          <p>
                            Última atualização:&nbsp;
                            <strong>{group.updated_at}</strong>
                          </p>
                        </div>
                      ),
                    })}
                >
                  <FaSearch />
                </O.Show>

                {(user.perfil === 'ZON' || user.adm) && (
                  <O.Update
                    to={{
                      pathname: `${pathname}/update`,
                      state: { code: group.grucod, desc: group.grudesc },
                    }}
                  >
                    <FaPencilAlt />
                  </O.Update>
                )}
                {user.adm && group.qtde === 0 && (
                  <O.Delete
                    onClick={() =>
                      handleDeleteDiag({
                        open: true,
                        content: (
                          <div style={{ fontSize: '16px' }}>
                            <p>
                              Ao clicar em confirmar, o grupo&nbsp;
                              <strong
                                style={{ color: '#c53030', fontSize: '18px' }}
                              >
                                {group.grudesc}
                              </strong>
                              &nbsp; será excluído&nbsp;
                              <span style={{ fontStyle: 'italic' }}>
                                <strong>permanentemente</strong>.
                              </span>
                            </p>

                            <h2 style={{ color: '#c53030', marginTop: '10px' }}>
                              Se estiver seguro dessa ação, clique em confirmar
                            </h2>
                          </div>
                        ),
                        values: {
                          code: group.grucod,
                        },
                      })
                    }
                  >
                    <FaTimes />
                  </O.Delete>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GroupContainer>

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })}
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm
              type="button"
              onClick={() => handleExclusion(deleteDiag.values)}
            >
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </AlteredContent>

      <SGOFooter />
    </Container>
  );
};

export default GrupoMateriais;
