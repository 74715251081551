import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';
// import Checkbox from 'components/Checkbox';
import SelectV2 from 'components/SelectV2';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useCredentials } from 'hooks/credentials';
import api from 'services/api';

import { useSpring } from 'react-spring';
import { useToast } from 'hooks/toast';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { et64, df64 } from 'utils/encoding';
import {
  ListContainer,
  ListItem,
  ListItemInfo,
  FloatCheckbox,
  Button,
} from './styles';

interface ListProps {
  name: string;
  modified: string;
}

interface LocalStorageProps {
  folder?: string;
}

const PHP: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<ListProps[]>([]);
  const [folders, setFolders] = useState<ComboProps[]>([]);
  const [initialFolder, setInitialFolder] = useState(() => {
    const { folder }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PHP_PATH}`,
    );

    return folder !== undefined ? df64(folder) : 'sgo';

    // return folder || 'sgo';
  });
  const [toUpload, setToUpload] = useState<string[]>([]);

  const getFolders = useCallback(async () => {
    const response = await api.get('/adm/php_folders.php');

    setFolders(
      response.data.map((item: ListProps) => ({
        value: item.name,
        label: item.name,
      })),
    );
    setLoading(false);
  }, []);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/adm/php_list.php?folder=${initialFolder}`,
      );
      setList([]);
      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, initialFolder]);

  useEffect(() => {
    getFolders();
    getList();
  }, [getFolders, getList]);

  const handleFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('path');

    setInitialFolder(folder);
    setLocalStorage(`${process.env.REACT_APP_PHP_PATH}`, {
      folder: et64(folder),
    });
  }, []);

  const handlePHP = useCallback((php: string) => {
    setToUpload((state) =>
      state.indexOf(php) > -1
        ? [...state.filter((item) => item !== php)]
        : [...state, php],
    );
  }, []);

  const handleUploadPHPs = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({ upload: toUpload, folder: initialFolder }),
      );

      const response = await api.post('/adm/php_upload.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);
      const { message } = response.data;
      if (message) {
        addToast({
          type: 'info',
          title: 'Atenção',
          description: message,
        });

        return;
      }

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Todos PHPs subidos para o ambiente de produção.',
      });

      setToUpload([]);
      // setList([]);
      getList();

      // history.replace(location.pathname.replace('/php', ''));
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, errorHandling, getList, initialFolder, toUpload]);

  const styledButton = useSpring({
    opacity: toUpload.length > 0 ? 1 : 0,
    transform: toUpload.length > 0 ? 'translateX(0px)' : 'translateX(30px)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <Form ref={formRef} onSubmit={() => null}>
          <SelectV2
            name="path"
            content={folders}
            initial={initialFolder}
            onChange={handleFolder}
          />
        </Form>

        <ListContainer>
          {list.map((item, index) => (
            <ListItem
              paint={index}
              key={item.name}
              onClick={() => handlePHP(item.name)}
              checked={toUpload.includes(item.name)}
            >
              <ListItemInfo>
                <p>
                  Nome do PHP:&nbsp;<strong>{item.name}</strong>
                </p>
                <p>
                  Modificado em:&nbsp;<strong>{item.modified}</strong>
                </p>
              </ListItemInfo>
              <FloatCheckbox>
                {/* <Checkbox
                  id={`N${item.name}`}
                  title=""
                  onClick={() => handlePHP(item.name)}
                  checked={toUpload.includes(item.name)}
                /> */}
              </FloatCheckbox>
            </ListItem>
          ))}
        </ListContainer>
        <Button style={styledButton} type="button" onClick={handleUploadPHPs}>
          Realizar Upload
        </Button>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default PHP;
