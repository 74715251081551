import React, { useState, useEffect, useCallback } from 'react';

import { AlteredContent, Container, Header } from 'styles/sgo_wrappers';
import { Link, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';

import { FaRegFileExcel, FaRegListAlt } from 'react-icons/fa';

import { useAuth } from 'hooks/auth';

import { useCredentials } from 'hooks/credentials';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { parseISO } from 'date-fns';
import { Menu, AnimatedDiv } from './styles';

const CEMenu: React.FC = () => {
  const { pathname } = useLocation();

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando arquivo',
    open: false,
    content: (
      <div>
        <p>O arquivo já está sendo gerado!</p>
        <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
        <p>Assim que estiver pronto o download começará automaticamente.</p>
      </div>
    ),
  });

  const { user } = useAuth();
  const { checkCredentials } = useCredentials();

  useEffect(() => {
    checkCredentials();
  }, [checkCredentials]);

  const handleDownload = useCallback(() => {
    setShow({
      ...show,
      open: true,
    });

    const timer = setTimeout(() => {
      setShow({
        ...show,
        open: false,
      });
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Menu de Controle de Entrega</Header>
      <AlteredContent pixels="231px">
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>
                {user.perfil === 'LOC'
                  ? 'Controles de Entregas da Coordenação'
                  : 'Entregas de Materiais'}{' '}
              </h3>
            </Link>
          </AnimatedDiv>

          {user.perfil === 'LOC' && (
            <>
              <AnimatedDiv duration={1.1} delay={0.35}>
                <Link to={`${pathname.replace('menu', 'all')}`}>
                  <FaRegListAlt size={30} />
                  <h3>Entregas de Materiais por Guia</h3>
                </Link>
              </AnimatedDiv>
              <AnimatedDiv duration={1.1} delay={0.7}>
                <a
                  href={`${
                    process.env.REACT_APP_API
                  }/sgo/xls_ce_list.php?data=${JSON.stringify({
                    token: localStorage.getItem(
                      `${process.env.REACT_APP_TOKEN}`,
                    ),
                    stat: 'E',
                  })}`}
                  rel="noreferrer noopener"
                  target="_top"
                  onClick={handleDownload}
                >
                  <FaRegFileExcel size={30} />
                  <h3>
                    Controles de Entregas&nbsp;
                    {new Date(parseISO(new Date().toISOString())).getFullYear()}
                    &nbsp;em
                    <strong> Aberto</strong>
                  </h3>
                </a>
              </AnimatedDiv>
              <AnimatedDiv duration={1.1} delay={1.05}>
                <a
                  href={`${
                    process.env.REACT_APP_API
                  }/sgo/xls_ce_list.php?data=${JSON.stringify({
                    token: localStorage.getItem(
                      `${process.env.REACT_APP_TOKEN}`,
                    ),
                    stat: 'P',
                  })}`}
                  rel="noreferrer noopener"
                  target="_top"
                  onClick={handleDownload}
                >
                  <FaRegFileExcel size={30} />
                  <h3>
                    Controles de Entregas&nbsp;
                    {new Date(parseISO(new Date().toISOString())).getFullYear()}
                    &nbsp;
                    <strong>Quitadas</strong>
                  </h3>
                </a>
              </AnimatedDiv>
            </>
          )}
          <AnimatedDiv
            duration={1.1}
            delay={user.perfil === 'LOC' ? 1.4 : 0.35}
          >
            <a
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_ce_list.php?data=${JSON.stringify({
                token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
                gseq: user.gseq,
                stat: '',
                mode: 'G',
                name: user.name,
              })}`}
              rel="noreferrer noopener"
              target="_top"
              onClick={handleDownload}
            >
              <FaRegFileExcel size={30} />
              <h3>
                Controles de Entregas de&nbsp;
                {new Date(parseISO(new Date().toISOString())).getFullYear()}
                &nbsp;
                <strong>
                  {['NAC', 'LOC'].indexOf(user.perfil) > -1
                    ? ' em meu nome'
                    : ''}
                </strong>
              </h3>
            </a>
          </AnimatedDiv>
        </Menu>
      </AlteredContent>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default CEMenu;
