import styled, { css } from 'styled-components';

interface ContainerProps {
  isResponsible: boolean;
}

interface BurgerProps {
  isOpen: boolean;
}

interface ContentProps {
  disableLinks?: boolean;
}

interface MenuProps {
  quantity?: number;
  adm?: boolean;
}

interface BurgerItemProps {
  quantity?: number;
  isActive?: boolean;
}

export const Container = styled.div<ContainerProps>`
  z-index: 151;
  position: sticky;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 50px;

  background: #8a0002;

  h2 {
    color: #fff;
    font-size: 18px;
    text-shadow: 0px 0px 7px rgba(239, 239, 239, 0.75);

    ${(props) =>
      props.isResponsible &&
      css`
        @media screen and (max-width: 560px) {
          font-size: 14px;
        }
        @media screen and (max-width: 430px) {
          font-size: 12px;
        }
        @media screen and (max-width: 380px) {
          font-size: 10px;
        }
      `}
  }
`;

export const NavContent = styled.div<ContentProps>`
  display: flex;
  width: 90vw;
  justify-content: center;

  ${(props) =>
    props.disableLinks &&
    css`
      display: none;
      pointer-events: none;
    `}
`;

export const Menu = styled.section<MenuProps>`
  z-index: 152;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 50px;
  max-height: 50px;

  padding: 0 5px;
  position: relative;
  transition: background-color 0.35s;

  & + section {
    margin-left: 25px;
  }

  h3 {
    cursor: pointer;
    color: #efefef;

    transition: color 0.35s;
  }

  span {
    position: absolute;
    top: 50px;
    left: -2px;
    height: 0px;
    width: 350px;

    overflow-x: hidden;
    overflow-y: auto;

    visibility: hidden;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    opacity: 0;
    /* opacity: 1; */
    pointer-events: none;
    background: #efefef;

    /* overflow: hidden; */
    transition: all 0.35s;
    border: 2px solid transparent;
    border-top: 0px;
    border-radius: 0 0 5px 5px;

    ${(props) =>
      props.quantity === 0 &&
      css`
        border-width: 0px;
      `}

    ${(props) =>
      props.adm &&
      css`
        @media screen and (max-width: 1205px) {
          width: 200px;
        }
      `}

    /* button {
      border: 0;
      background: transparent;
      line-height: 25px;
      padding: 0 5px;
      width: auto;
      color: #8a0002;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    } */

    button {
      border: 0;
      background: transparent;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    a,
    button {
      line-height: 25px;
      padding: 0 5px;
      text-decoration: none;
      width: auto;

      color: #8a0002;

      transition: color 0.25s ease, background-color 0.25s, border-color 0.5s,
        visibility 0s;
      & + a,
      & + button {
        margin-top: 3px;
      }

      &:hover {
        opacity: 1;
        background: #8a0002;
        color: #fff;
      }
    }
  }

  &:hover {
    background: #efefef;

    h3 {
      color: #8a0002;
    }
    span {
      visibility: visible;
      border-color: #8a0002;
      height: calc(${(props) => props.quantity} * 28px);
      max-height: 600px;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const BackContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
  height: 50px;

  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
    color: #fff;
  }
`;

export const LogoutContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  height: 50px;

  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    opacity: 0.85;
    color: #efefef;
    height: 30px;
    width: 30px;
    transition: all 0.35s ease;
  }

  &:hover {
    svg {
      opacity: 1;
      color: #fff;
    }
  }
`;

export const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  /* background: #25d366; */
  border-radius: 50%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    cursor: pointer;
    opacity: 0.85;
    color: #efefef;
    height: 30px;
    width: 30px;
    transition: all 0.35s ease;
  }

  &:hover {
    svg {
      opacity: 1;
      color: #fff;
    }
  }
`;

export const PazinatoContainer = styled(SupportContainer)`
  border: 1px solid #000066;
  margin: 0 auto;
  position: relative;
  background-image: linear-gradient(135deg, #00004d, #337ab7, #609dd2);
  transform: translateY(0);
  right: 0;
  padding: 3px;

  img {
    height: 25px;
    object-fit: contain;
  }
`;

export const BurgerContainer = styled.section<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #7159c1; */
  /* height: 50px; */
  width: 50px;
  /* border: 1px solid rgba(239, 239, 239, 0.5); */
  padding: 2px 6px;
  border-radius: 5px;

  ${(props) =>
    props.disableLinks &&
    css`
      display: none;
      pointer-events: none;
    `}
`;

export const Burger = styled.div<BurgerProps>`
  cursor: pointer;

  background: transparent;
  border-radius: 10px;

  width: 30px;
  transition: all 0.5s;

  &::after,
  &::before,
  div {
    background: #efefef;

    border-radius: 3px;
    content: '';
    display: block;
    height: 4px;
    margin: 6px 0;
    transition: all 0.5s ease-in-out;
  }

  ${(props) =>
    props.isOpen &&
    css`
      &::before {
        transform: translateY(10px) rotate(45deg);
      }
      &::after {
        transform: translateY(-10px) rotate(-45deg);
      }

      div {
        transform: translateX(-10px);
        opacity: 0;
      }
    `}
`;

export const BurgerContent = styled.div<BurgerProps>`
  z-index: 151;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;

  background: #fff;

  width: 100vw;
  height: 0vh;

  pointer-events: none;

  opacity: 1;

  transition: height 0.5s ease, pointer-events 0.5s ease, padding 0.5s ease;
  ${(props) =>
    props.isOpen &&
    css`
      height: 100vh;
      pointer-events: all;
      padding: 25px 0;
    `};
`;

export const BurgerItem = styled.div<BurgerItemProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  h3 {
    text-align: center;

    width: 100%;
    font-size: 20px;

    color: #8a0002;
    margin-bottom: 15px;

    svg {
      height: 15px;
      width: 15px;
      transition: transform 0.35s ease;
    }
  }
  span {
    font-size: 17px;
    height: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;

    overflow: hidden;
    pointer-events: none;

    transition: height 0.5s, pointer-events 0.6s;

    button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border: 0;
      background: transparent;
      font-size: 17px;
    }

    a,
    button {
      padding: 0 0 0 20px;
      text-decoration: none;
      color: #c53030;
      width: 100%;
      text-align: left;
      & + a,
      & + button {
        margin-top: 5px;
      }
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      h3 {
        svg {
          transform: rotateZ(-90deg);
        }
      }
      span {
        height: calc(${props.quantity} * 27.5px);
        pointer-events: all;
      }
    `}

  & + div {
    margin-top: 10px;
  }
`;

export const Filter = styled.div`
  cursor: pointer;
  position: absolute;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  right: 150px;
  height: 100%;

  > svg {
    height: 20px;
    width: 20px;
    color: #efefef;

    transition: color 0.5s;
  }

  &:hover {
    > svg {
      color: #fff;
    }
  }

  @media screen and (max-width: 600px) {
    right: 25%;
  }
`;

export const FilterContent = styled.div<BurgerProps>`
  z-index: 151;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;

  background: #fff;

  width: 100vw;
  height: 0vh;
  flex-grow: 100;
  /* height: 0vh; */

  pointer-events: none;

  opacity: 1;

  > button {
    margin-top: 15px;
    background: #c6c6c6;

    padding: 5px 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid #332e2e;
    border-radius: 5px;

    transition: background-color 0.5s ease;

    svg {
      color: #332e2e;
      height: 20px;
      width: 20px;

      transition: color 0.5s ease;
    }

    &:hover {
      background: #332e2e;
      svg {
        color: #efefef;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      /* align-items: center; */

      > span {
        > p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
        }
        & + span {
          margin-top: 15px;
        }
      }
    }
  }

  transition: height 0.5s ease, pointer-events 0.5s ease, padding 0.5s ease;
  ${(props) =>
    props.isOpen &&
    css`
      height: 100vh;
      flex-grow: 100;
      pointer-events: all;
      padding: 25px 0;
    `};
`;
