import React, { useEffect, useState, HTMLAttributes } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FaImages } from 'react-icons/fa';
import { Container } from './styles';
import { useScrolling } from '../../hooks/scrolling';

interface MoreInfoButtonProps extends HTMLAttributes<HTMLElement> {
  path?: string;
  options?: object;
  isButton?: boolean;
  routeName?: string;
}

const MoreInfoButton: React.FC<MoreInfoButtonProps> = ({
  children,
  path,
  isButton = false,
  options = {},
  routeName = '',
}) => {
  const { scrolled } = useScrolling();
  const location = useLocation();
  const [position, setPosition] = useState<number>(scrolled);

  useEffect(() => {
    if (scrolled > 100) {
      setPosition(100);
      return;
    }

    setPosition(scrolled);
  }, [scrolled]);

  return (
    <Container amountScrolled={position}>
      {!isButton ? (
        <Link
          title="Mais"
          to={{
            pathname: path || `${location.pathname}/${routeName}`,
            state: { options },
          }}
        >
          <FaImages />
          {children}
        </Link>
      ) : (
        children
      )}
    </Container>
  );
};

export default MoreInfoButton;
