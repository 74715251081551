import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

interface AnimatedSpanProps {
  delay?: number;
  duration?: number;
}

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);

  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(150px);

  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ButtonLike = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 14px;
    margin: 5px 0 0 0;
    cursor: not-allowed;
    padding: 5px 10px;
    /* width: 50px; */

    background-color: #8a0002;
    border: 2px solid #8a0002;
    border-radius: 5px;

    color: #fff;

    &:hover {
      background-color: #6a0002;
      border-color: #6a0002;
      color: #fff;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;

  div + div {
    margin-top: 15px;
  }

  & + div {
    margin: 25px 0 0 0;
  }
`;

export const AnimatedDiv = styled.div<AnimatedSpanProps>`
  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;
  transition: all 0.5s;

  a,
  button {
    background: #fff;
    border-radius: 5px;
    border: 2px solid #efefef;
    padding: 5px 15px 5px 10px;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.5);

    width: 100%;
    display: flex;
    align-items: center;

    text-decoration: none;

    transition: all 0.5s;
    svg {
      margin-right: 15px;
      color: #363636;
      transition: all 0.5s;
    }

    h3 {
      font-size: 18.72px;
      color: #767676;
      transition: all 0.5s;

      strong {
        color: ${shade(0.25, '#767676')};
        transition: all 0.5s;
      }
    }
  }

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  &:hover {
    a,
    button {
      transform: translateX(10px);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      svg {
        color: #767676;
      }
      h3 {
        color: #332e2e;
        strong {
          color: ${shade(0.25, '#8a0002')};
        }
      }
    }
  }
`;

export const AnimatedH3 = styled.h3<AnimatedSpanProps>`
  opacity: 0;
  transform: translateX(150px);

  animation: ${appearFromRight} ${(props) => props.duration || 1}s ease forwards;
  transition: all 0.5s;

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}
`;
