import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Textarea from 'components/TextareaDialog';
// import Textarea from 'components/Textarea';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import api from 'services/api';

import {
  AlteredHeader,
  Container,
  Content,
  IncButton,
} from 'styles/sgo_wrappers';
import { FaPencilAlt, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import * as U from 'styles/dialog_update';
import { UpdateProps, ModalContent } from 'styles/dialog_update';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';
import { TableWrapper, Table, Desc, Update, ButtonTD, Delete } from './styles';

import { FIGuiaProps } from '../main';

interface ListProps {
  seq: number;
  desc: string;
}

const FIGOutras: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const updateRef = useRef<FormHandles>(null);
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [list, setList] = useState<ListProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);
  const [inc, setInc] = useState<IncludeProps>(() => {
    return {
      title: 'Inclusão',
      open: false,
      content: '',
    };
  });

  const [message, setMessage] = useState('');
  const [seq, setSeq] = useState(0);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_outras_atividades.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getList();
  }, [getList, history, location.state]);

  const resetIt = useCallback(() => {
    setMessage('');
    setSeq(0);
  }, []);

  const getDesc = useCallback(
    (ocurr: number) => {
      const index = list.findIndex((item) => item.seq === ocurr);
      setMessage(list[index].desc);
      setSeq(list[index].seq);
    },
    [list],
  );

  const handlePreInc = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreAlt = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);

      setUpdateDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handlePreDel = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);

      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handleInclude = useCallback(
    async (data: { description: string }) => {
      try {
        setLoading(true);
        includeRef.current?.setErrors({});
        const schema = yup.object().shape({
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            description: data.description,
            gseq: gState.gseq,
            mode: 'inc',
          }),
        );

        setInc((state) => ({ ...state, open: !state.open }));

        const response = await api.post(
          '/sgo/fig_outras_atividades.php',
          send,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );

        const { nxt } = response.data;
        // const first = list.length === 1 && list[0].seq === 0 ? true : false;

        setList((state) =>
          state.length > 0 && state[0].seq === 0
            ? [{ seq: nxt, desc: data.description }]
            : [...state, { seq: nxt, desc: data.description }],
        );
        resetIt();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, resetIt],
  );

  const handleUpdate = useCallback(
    async (data: { description: string }) => {
      try {
        setLoading(true);
        updateRef.current?.setErrors({});
        const schema = yup.object().shape({
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            description: data.description,
            seq,
            gseq: gState.gseq,
            mode: 'update',
          }),
        );

        setUpdateDiag((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_outras_atividades.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const index = list.findIndex((item) => item.seq === seq);
        list[index].desc = data.description;

        setList((state) => [...state]);
        resetIt();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, list, resetIt, seq],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          seq,
          gseq: gState.gseq,
          mode: 'delete',
        }),
      );

      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/sgo/fig_outras_atividades.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setList((state) =>
        state.filter((item) => item.seq !== seq).length > 0
          ? [...state.filter((item) => item.seq !== seq)]
          : [{ seq: 0, desc: 'Sem registros' }],
      );

      resetIt();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, gState.gseq, resetIt, seq]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Outras Atividades</p>
        </AlteredHeader>
        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}
        <TableWrapper>
          <Table>
            <thead>
              <Desc>Descrição</Desc>
              {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                <>
                  <Update>Alt.</Update>
                  <Delete>Exc.</Delete>
                </>
              )}
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.seq}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <Desc>Descrição</Desc>
                      {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                        <>
                          <Update>Alt.</Update>
                          <Delete>Exc.</Delete>
                        </>
                      )}
                    </tr>
                  )}

                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    <Desc>{item.desc}</Desc>
                    {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                      <>
                        <Update>
                          {item.seq !== 0 && (
                            <ButtonTD
                              used="alt"
                              onClick={() => handlePreAlt(item.seq)}
                            >
                              <FaPencilAlt />
                            </ButtonTD>
                          )}
                        </Update>
                        <Delete>
                          {item.seq !== 0 && (
                            <ButtonTD
                              used="del"
                              onClick={() => handlePreDel(item.seq)}
                            >
                              <FaTimesCircle />
                            </ButtonTD>
                          )}
                        </Delete>
                      </>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <h4
                style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}
              >
                {/*  */}
              </h4>
              <Textarea
                name="description"
                placeholder="Insira a descrição"
                mask="alpha"
                containerStyle={{
                  width:
                    width >= 472 ? '360px' : width >= 392 ? '280px' : '245px',
                }}
              />
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              description: message,
            }}
          >
            <U.Content>
              <ModalContent>
                <span>
                  <p>Descrição</p>
                  <Textarea
                    name="description"
                    mask="alpha"
                    placeholder="Insira a descrição"
                    containerStyle={{
                      width:
                        width >= 472
                          ? '360px'
                          : width >= 392
                          ? '280px'
                          : '245px',
                    }}
                  />
                </span>
              </ModalContent>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={() => setUpdateDiag({ open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>

        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <div>
                  <strong>{message}</strong>
                </div>
                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGOutras;
