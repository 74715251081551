import styled from 'styled-components';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

interface BtType {
  bgcolor: string;
}

export const TrajContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px 7px;
    background: #fff;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;
    > div {
      display: flex;
      > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #8a0002;
          text-shadow: unset;
        }
        p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
          user-select: none;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }
      & + div {
        margin: 10px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 500px) {
    > div {
      > div {
        flex-direction: column;

        span + span {
          margin: 5px 0 0 0;
        }
      }
    }
  }
`;

export const YearContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 200px;
  align-items: center;
  justify-content: center;

  margin: 10px auto 0 auto;
  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #332e2e;
  }

  /* @media screen and (max-width: 460px) {
    margin: 60px auto 0 auto;
  } */
  /* @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100vw;
  } */

  transition: all 0.5s;
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;

// box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
// box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
export const List = styled.div`
  margin: 25px 0;
  display: flex;
  flex-direction: column;

  /* width: 100vw; */

  > div {
    border: 3px solid #efefef;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

    h4 {
      font-size: 16px;
      width: 100%;
      color: #8a0002;
      text-align: center;
    }

    div {
      margin: 0 auto;
      display: grid;
      grid: auto / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        > p {
          font-size: 16px;
        }
      }
    }
    & + div {
      margin: 25px 0 0 0;
    }

    @media screen and (max-width: 800px) {
      div {
        grid: auto / 1fr 1fr 1fr 1fr 1fr 1fr;
        row-gap: 10px;
      }
    }

    @media screen and (max-width: 400px) {
      div {
        grid: auto / 1fr 1fr 1fr 1fr;
        row-gap: 10px;

        /* span {
          width: unset;
        } */
      }
    }
  }

  @media screen and (max-width: 430px) {
    width: 100vw;

    > div {
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
    }
  }
`;
