import React, { HTMLAttributes } from 'react';

import { Container, Content } from './styles';

import loading from './loading.gif';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isLoading = false }) => {
  return (
    <Container isLoading={isLoading}>
      <Content>
        <img src={loading} alt="Loading" />
        <strong>Aguarde</strong>
      </Content>
    </Container>
  );
};

export default Loading;
