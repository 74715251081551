import styled, { css } from 'styled-components';

interface PlayerProps {
  hasVid?: boolean;
}

interface GridProps {
  selected: boolean;
}

export const PlayerContainer = styled.div<PlayerProps>`
  margin-top: 10px;

  border-radius: 10px;
  height: 0px;

  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s, height 0.35s, pointer-events 0.35s, box-shadow 0.5s,
    transform 0.5s ease;
  ${(props) =>
    props.hasVid &&
    css`
      pointer-events: all;
      opacity: 1;
      height: 360px;
    `}

  @media screen and (max-width: 650px) {
    transform: scale(0.6);
  }
`;

export const VideoAulaContainer = styled.section`
  width: 90vw;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
`;

export const VideoAulaSection = styled.div`
  background: #fff;
  border-radius: 5px;

  margin: 10px 0;
  h1 {
    text-shadow: 0px 0px 5px #8a0002;
    color: #efefef;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(197, 48, 48, 0.25),
      rgba(197, 48, 48, 0.5),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 1),
      rgba(197, 48, 48, 0.5),
      rgba(197, 48, 48, 0.25),
      rgba(255, 255, 255, 0)
    );
    text-align: center;
  }
`;

export const VideoGrid = styled.span`
  margin: 20px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;

  justify-items: center;

  transition: all 0.5s;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;
  }

  @media screen and (max-width: 600px) {
    grid: auto / 1fr 1fr;
  }
`;

export const GridItem = styled.figure<GridProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  height: 84px;
  width: 150px;


  border-radius: 10px;

  background-repeat: no-repeat;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);


  transition: transform 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;

  img {
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%:
    width: 100%;

    transform: translate(-50%, -50%);

    transition: opacity 0.5s ease;

  }

  p {
    z-index: 1;
    text-align: center;
    font-weight: 600;
    font-size: 12px;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease, pointer-events 0.5s ease;
  }

  &:hover {
    z-index: 1;
    transform: scale(1.2);

    img{
      opacity: 0.175;
    }

    p {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media screen and (max-width: 850px) {
    &:hover {
      transform: scale(1);
    }
  }

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 0px 15px 5px rgba(138, 0, 2, 0.75);

      @media screen and (max-width: 850px) {
        &:hover {
          transform: scale(1);
        }
      }
    `}
`;
