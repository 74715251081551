import { shade } from 'polished';
import styled, { keyframes, css } from 'styled-components';

interface AnimatedSpanProps {
  delay?: number;
  duration?: number;
}

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);

  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  div + div {
    margin-top: 15px;
  }
`;

export const AnimatedDiv = styled.div<AnimatedSpanProps>`
  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;
  transition: all 0.5s;

  button {
    background: #fff;
    border-radius: 5px;
    border: 2px solid #efefef;
    padding: 5px 15px 5px 10px;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.5);

    width: 100%;
    display: flex;
    align-items: center;

    text-decoration: none;

    transition: all 0.5s;
    svg {
      margin-right: 15px;
      color: #363636;
      transition: all 0.5s;
    }

    h3 {
      font-size: 18.72px;
      color: #767676;
      transition: all 0.5s;

      strong {
        color: ${shade(0.25, '#767676')};
        transition: all 0.5s;
      }
    }
  }

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  &:hover {
    button {
      transform: translateX(10px);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      svg {
        color: #767676;
      }
      h3 {
        color: #332e2e;
        strong {
          color: ${shade(0.25, '#8a0002')};
        }
      }
    }
  }
`;

export const ProgressDiv = styled.div`
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  width: 22.5rem;
  min-height: 100px;
  progress[value] {
    margin: 0 auto;
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 20px;

    position: relative;

    > p {
      position: absolute;
    }

    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
      background-image: -webkit-linear-gradient(
          45deg,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, #8a0002, #f44);

      border-radius: 3px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }
`;
