import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { Link } from 'react-router-dom';

interface ButtonProps {
  background?: string;
}

export const Update = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #007acc;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#007acc')};
  }
`;

export const GenFrom = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  font-family: Helvetica;

  width: 75px;
  height: 30px;

  svg {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    transition: transform 0.35s ease;
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #589523;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#589523')};
    svg {
      transform: translateX(5px);
    }
  }
`;

export const ShowFrom = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin-left: 5px;

  color: #332e2e;

  border-radius: 3px;

  background: #efefef;
  border: 2px solid ${shade(0.2, '#efefef')};

  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  font-family: Helvetica;

  width: 75px;
  height: 25px;

  svg {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    transition: transform 0.35s ease;
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: transparent;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#efefef')};
  }
`;

export const Team = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #007acc;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#007acc')};
  }
`;

export const Show = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 14px;

  width: 30px;
  height: 30px;

  svg {
    width: 20px;
    height: 20px;
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 3px;

    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  & + button,
  & + a {
  }

  background: #5e5555;

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#5e5555')};

    ${(props) =>
      props.background &&
      css`
        background: ${shade(0.2, `${props.background}`)};
      `}
  }
`;

export const ShowLink = styled(Link)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #5e5555;

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#5e5555')};

    ${(props) =>
      props.background &&
      css`
        background: ${shade(0.2, `${props.background}`)};
      `}
  }
`;

export const Delete = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  width: 30px;
  height: 30px;

  svg {
    width: 20px;
    height: 20px;
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #c53030;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#c53030')};
  }
`;

export const Financ = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  color: #fff;

  border-radius: 3px;
  padding: 3px 7.5px;

  font-size: 18px;

  width: 30px;
  height: 30px;

  a {
    text-decoration: none;
    color: #fff;

    align-items: center;
    justify-content: center; */
    width: 100%;
    height: 100%;

    svg {
      width: 50px;
      height: 50px;
    }
  }

  & + button {
    margin: 10px 0 0 0;
  }
  background: #339966;

  transition: background-color 0.5s;

  &:hover {
    background: ${shade(0.2, '#339966')};
  }
`;

export const GridOptions = styled.figure`
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;

  justify-content: center;

  a,
  button {
    & + a,
    & + button {
      margin: 0 0 0 15px;
    }
  }
`;
