import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { animated } from 'react-spring';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { deconvertSpecialChars } from 'utils/specialChars';
import { useToast } from 'hooks/toast';
import { FaFileExcel } from 'react-icons/fa';

import { useSpring } from 'react-spring';

import { alphaMasking } from 'components/Textarea/masks';
import { ListProps } from '../main';

import {
  HeaderContent,
  TotalRM,
  RMContentContainer,
  RMContentCabec,
  RMContent,
  RMContentItem,
  Monetary,
  AddressContainer,
  OBSContainer,
  AnimatedRadio,
} from './styles';

interface StateProps {
  rm: ListProps;
  rmnum?: number;
  loccod?: string;
  anbcod?: string;
}

interface RMItemsProps {
  anbcod: string;
  cod: string;
  desc: string;
  qtd: number;
  value: number;
  numbers: string[];
  required: boolean;
}

const RMConsult: React.FC = () => {
  const printRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  const [doc, setDoc] = useState('R');
  const [content, setContent] = useState('N');

  const [rmHeader, setRMHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }

    if (location.state.rmnum) {
      return {} as ListProps;
    }

    return location.state.rm;
  });

  const [rmItems, setRMitems] = useState<RMItemsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Selecione o documento',
    open: false,
    content: '',
  });

  const [printing, setPrinting] = useState(false);

  const getItems = useCallback(
    async (alt?: boolean) => {
      try {
        const fetch = {
          rmnum: alt ? location.state.rmnum : rmHeader.rmnum,
          loccod: alt ? location.state.loccod : rmHeader.loccod,
          anbcod: alt ? location.state.anbcod : rmHeader.anbcod,
          isShow: true,
        };

        const response = await api.get(
          `/sgo/rm_items.php?data=${JSON.stringify(fetch)}`,
        );

        setRMitems(response.data);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
        setLoading(false);
      }
    },
    [
      errorHandling,
      location.state.loccod,
      location.state.anbcod,
      location.state.rmnum,
      rmHeader.loccod,
      rmHeader.rmnum,
      rmHeader.anbcod,
    ],
  );

  const getConsult = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/rm_list.php?data=${JSON.stringify({
          rmnum: location.state.rmnum,
          loccod: location.state.loccod,
          anbcod: location.state.anbcod,
        })}`,
      );

      setRMHeader(response.data.fetchlist[0]);
      getItems(true);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [
    errorHandling,
    getItems,
    location.state.anbcod,
    location.state.loccod,
    location.state.rmnum,
  ]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }
    if (location.state.rm) {
      getItems();
      return;
    }

    getConsult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalRM = useMemo(() => {
    let total = 0;

    rmItems.forEach((item: RMItemsProps) => {
      total += item.qtd * item.value;
    });

    return total.toFixed(2).replace('.', ',');
  }, [rmItems]);

  const handleCancel = useCallback(
    async (values: DeleteProps['values']) => {
      setDeleteDiag({ open: false });
      if (values) {
        try {
          setLoading(true);

          let obs = '*** SEM OBSERVAÇÃO ***';
          const retrieve = document.querySelector<HTMLTextAreaElement>(
            'textarea[id="obs"]',
          );

          if (retrieve && retrieve.value !== '') {
            obs = retrieve.value;
          }

          const send = {
            ...values,
            obs: obs.replace('#', ''),
            rmItems,
            solnum: rmHeader.solnum,
          };

          await api.get(`/sgo/rm_delete.php?data=${JSON.stringify(send)}`);

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: `Remessa de Material N° ${rmHeader.rmnum} cancelada.`,
          });

          setLoading(false);

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [
      addToast,
      errorHandling,
      history,
      location.pathname,
      rmHeader.rmnum,
      rmHeader.solnum,
      rmItems,
    ],
  );

  const handleRadioClick = useCallback(() => {
    const docSelected = printRef.current?.getFieldValue('printDoc');
    setDoc(docSelected);
  }, []);

  const handleRadioClick2 = useCallback(() => {
    const contentSelected = printRef.current?.getFieldValue('mailDoc');
    setContent(contentSelected);
  }, []);

  const handleDownload = useCallback(async () => {
    try {
      setPrinting(true);
      const docSelected = printRef.current?.getFieldValue('printDoc');

      setTimeout(() => {
        setPrinting(false);
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(
        `a[id=${docSelected === 'R' ? 'rm' : 'mail'}Link]`,
      );

      if (link) {
        link.click();
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const isPrinting = useSpring({
    opacity: printing ? 1 : 0,
    pointerEvents: printing ? 'all' : 'none',
    // height: printing ? '153px' : '0px',
  });
  const isNotPrinting = useSpring({
    opacity: !printing ? 1 : 0,
    pointerEvents: !printing ? 'all' : 'none',
    // height: !printing ? '200px' : '0px',
  });

  const anchor = useSpring({
    display: 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`Consulta Remessa N° ${rmHeader.rmnum}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data da RM:&nbsp;
                    <strong>{formatDate(rmHeader.data)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {rmHeader.loccod === user.anbc ? (
                      <>
                        Emitida por:&nbsp;<strong>{rmHeader.anbdesc}</strong>
                      </>
                    ) : (
                      <>
                        Coordenação:&nbsp;<strong>{rmHeader.locdesc}</strong>
                      </>
                    )}
                  </p>
                </span>
              </div>
              {rmHeader.solnum ? (
                <div>
                  <span>
                    <p>
                      Solicitação N°:&nbsp;
                      <strong>{rmHeader.solnum}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Data da Solicitação:&nbsp;
                      <strong>{formatDate(rmHeader.soldata)}</strong>
                    </p>
                  </span>
                </div>
              ) : null}
            </HeaderContent>

            <TotalRM>
              <Button
                bgcolor="#332e2e"
                onClick={() => setShow((state) => ({ ...state, open: true }))}
              >
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total desta Remessa: <strong>R$ {totalRM}</strong>
              </p>
            </TotalRM>
            <RMContentContainer>
              <RMContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </RMContentCabec>
              <RMContent>
                {rmItems.map((item: RMItemsProps, index: number) => (
                  <RMContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </RMContentItem>
                ))}
              </RMContent>
            </RMContentContainer>
            <AddressContainer>
              <span>
                <p>
                  Endereço de Entrega:&nbsp;
                  <strong>
                    {rmHeader.address?.street}&nbsp;-&nbsp;
                    {rmHeader.address?.district}&nbsp;-&nbsp;
                    {rmHeader.address?.city}&nbsp;-&nbsp;
                    {rmHeader.address?.state}&nbsp;-&nbsp;CEP:&nbsp;
                    {rmHeader.address?.cep}
                  </strong>
                </p>
              </span>
            </AddressContainer>
            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {rmHeader.obs &&
                    rmHeader.obs.map((item) => (
                      <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                    ))}
                </strong>
              </span>
            </OBSContainer>
            {rmHeader.financregs <= 1 &&
            user.perfil === 'NAC' &&
            rmHeader.loccod !== user.anbc &&
            rmHeader.status === 'E' ? (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro da remessa
                          de material&nbsp;
                          <strong>N° {rmHeader.rmnum}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            placeholder="Observação"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      rm: rmHeader.rmnum,
                      loccod: rmHeader.loccod,
                      locdesc: rmHeader.locdesc,
                      anbdesc: user.anbdesc,
                      gname: user.name,
                    },
                  })
                }
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Cancelar essa RM
              </Button>
            ) : null}
          </>
        )}
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleCancel(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <Form ref={printRef} onSubmit={() => null}>
            <animated.a
              style={anchor}
              id="rmLink"
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_rm.php?data=${JSON.stringify({
                rm: rmHeader.rmnum || location.state?.rmnum,
                dest: rmHeader.loccod,
                token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
                // filled: contentSelected === 'S',
              })}`}
              rel="noreferrer noopener"
              target="_top"
            >
              RM
            </animated.a>

            <animated.a
              style={anchor}
              id="mailLink"
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_rm_mail.php?data=${JSON.stringify({
                rm: rmHeader.rmnum || location.state?.rmnum,
                dest: rmHeader.loccod,
                token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
                filled: content === 'S',
              })}`}
              rel="noreferrer noopener"
              target="_top"
            >
              MAIL
            </animated.a>

            <animated.div style={isNotPrinting}>
              <RadioContainer
                containerStyle={{ maxWidth: '250px' }}
                title="Documento:"
                onClick={handleRadioClick}
                name="printDoc"
                content={[
                  {
                    id: 'pRM',
                    value: 'R',
                    label: 'Remessa de Material',
                  },
                  {
                    id: 'pMAIL',
                    value: 'M',
                    label: 'Declaração dos Correios',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={doc}
              />

              <AnimatedRadio
                containerStyle={{ maxWidth: '250px' }}
                onClick={handleRadioClick2}
                isVisible={doc === 'M'}
                title="Declaração:"
                name="mailDoc"
                content={[
                  {
                    id: 'mS',
                    value: 'S',
                    label: 'Em branco',
                  },
                  {
                    id: 'mN',
                    value: 'N',
                    label: 'Preenchida',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={content}
              />
            </animated.div>
            <animated.div style={isPrinting}>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </animated.div>
          </Form>
        </S.Content>
        <S.Actions>
          <S.Confirm type="button" disabled={printing} onClick={handleDownload}>
            Gerar
          </S.Confirm>
          <S.Cancel
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            Finalizar
          </S.Cancel>
        </S.Actions>
      </S.Container>

      <SGOFooter />
    </Container>
  );
};

export default RMConsult;
