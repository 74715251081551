import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const PreviousPathsContainer = styled.section`
  display: flex;
  width: 100vw;
  /* background: #c53030; */
  align-items: center;
  justify-content: center;
  padding: 15px 5px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      height: 15px;
      width: 15px;
      color: #464646;
    }
    &:nth-child(1) {
      > svg:nth-child(1) {
        height: 35px;
        width: 35px;
        margin: 0px 30px 0 0;
        color: #464646;
      }
      > button {
        border: 0px solid black;
        background: transparent;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }

  button {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #464646;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.25);
    padding: 5px 15px;
    margin: 0px 3px;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
    > svg {
      height: 25px;
      width: 25px;
      color: #464646;
    }
  }

  @media screen and (max-width: 1365px) {
    display: none;
  }
`;

export const PreviousPathsMobileContainer = styled.section`
  display: flex;
  width: 100vw;
  /* background: #c53030; */
  padding: 10px 20px;

  details {
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    /* background: #7159c1; */
    margin: 0 auto;
    width: 360px;

    font-size: 16px;

    display: flex;

    summary {
      display: flex;
      align-items: center;
      list-style-type: none;
      color: #464646;

      > svg:nth-child(3) {
        margin-left: 1rem;
        will-change: transform;
        transition: 0.25s ease;
        transform: rotate(90deg);
      }

      p {
        margin: 0px 7px;
        font-family: 'Roboto Slab', serif;
        font-weight: 500;
      }

      svg {
        height: 35px;
        width: 35px;
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;

      align-items: center;

      &:nth-child(2) {
        margin: 10px 0 0 0;
        > button {
          border: 0px solid black;
          background: transparent;
          border-radius: 5px;
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

          svg {
            height: 35px;
            width: 35px;
            color: #464646;
          }
        }
      }
    }

    button {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #464646;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.25);
      padding: 5px 15px;
      margin: 5px 0px;
      box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
      > svg {
        height: 25px;
        width: 25px;
      }
    }

    &[open] {
      /* border-color: #332e2e; */
      summary {
        > svg:nth-child(3) {
          transform: rotate(180deg);
        }
        &::before {
          /* content: attr(data-open); */
        }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    display: none;
  }
`;

export const GridContainer = styled.section`
  margin: 15px 0 0 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100vw;
  padding: 0px 20px;
  place-items: center;

  @media screen and (max-width: 1366px) {
    grid: auto / 1fr 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    padding: 0px 10px;
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 5px;
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 430px) {
    padding: 0px 0px;
    grid: auto / 1fr;
  }
`;

export const GridDocumentsContainer = styled.section`
  margin: 15px 0 0 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100vw;
  padding: 0px 20px;
  place-items: center;

  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    padding: 0px 10px;
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 550px) {
    padding: 0px 0px;
    grid: auto / 1fr;
  }
`;

export const DropWrapper = styled.div`
  /* background: #fff; */
  padding: 10px;

  width: 340px;
  height: 150px;
  /* margin: 25px 0; */
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100%;
  border: 4px dashed #1c541c;
  border-radius: 5px;

  input {
    display: none;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  p {
    margin: 5px 0 0 0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    color: #332e2e;
  }
`;

export const InsertFileInfoContainer = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;

  div {
    display: flex;

    align-items: center;

    &:nth-child(1) {
      /* width: 100%; */
      font-size: 14px;
    }

    & + div {
      margin: 10px 0 0 0px;
    }
  }

  svg {
    height: 35px;
    width: 35px;
    color: #565656;
  }

  p {
    font-size: 16px;
    color: #332e2e;
    margin-left: 10px;
  }
`;

export const FileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  height: 60px;

  border: 2px solid transparent;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  color: #464646;

  transition: all 0.35s;

  > svg {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;

    transition: all 0.5s;
  }

  p {
    position: absolute;
    left: 65px;
    top: 50%;
    transform: translateY(-50%);

    width: calc(100% - 65px);
    font-size: 14px;
  }
  a {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;

    color: #464646;
  }

  &:hover {
    border: 2px solid #363636;
    background: #fff;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

    > svg {
      color: #f40f02;
    }
  }

  @media screen and (max-width: 550px) {
    border-radius: 0px;

    border-color: #363636;
    border-width: 2px 0;
    background: #fff;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

    > svg {
      color: #f40f02;
    }

    &:hover {
      border-width: 2px 0;
    }
  }
`;

export const FolderInfoContainer = styled(FileInfoContainer)`
  height: 75px;

  > svg {
    height: 30px;
    width: 30px;
  }

  p {
    left: 45px;
    width: calc(100% - 45px);
  }

  > button:nth-child(2) {
    border: 0 solid transparent;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;

    color: #464646;
  }

  &:hover {
    > svg {
      color: #004d40;
    }
  }

  @media screen and (max-width: 550px) {
    border-radius: 5px;

    border-color: transparent;
    border-width: 2px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

    > svg {
      color: #004d40;
    }
  }

  @media screen and (max-width: 430px) {
    border-radius: 0px;

    border-color: #363636;
    border-width: 2px 0;
    background: #fff;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

    > svg {
      color: #004d40;
    }

    &:hover {
      border-width: 2px 0;
    }
  }
`;

export const ExcludeButton = styled.button`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 25px;
  height: 25px;
  background: transparent;

  border: 0;

  > svg {
    color: #c53030;
    height: 15px;
    width: 15px;
  }
`;

export const ContentWrapper = styled.details`
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  box-sizing: border-box;
  margin-top: 5px;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  summary {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    list-style-type: none;

    svg {
      will-change: transform;
      transition: 0.25s ease;
      margin-right: 0.438rem;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      /* content: attr(data-close); */
    }
  }

  &[open] {
    border-color: #332e2e;
    summary {
      svg {
        transform: rotate(90deg);
      }
      &::before {
        /* content: attr(data-open); */
      }
    }
  }
`;
