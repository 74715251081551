import styled from 'styled-components';

interface BtType {
  used: string;
}

export const TableWrapper = styled.div`
  border: 0px;
  border-top: 3px solid #332e2e;
  border-bottom: 3px solid #332e2e;

  margin: 20px 0px 20px 0px;

  /* transition: all 0.35s ease; */

  width: 100vw;
  border-radius: 0px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    /* min-height: 40px; */
  }

  td {
    font-size: 16px;
    padding: 2px 3px;
  }
`;

export const TG = styled.td``;

export const Desc = styled.td`
  text-align: justify;
  text-justify: inter-word;
  padding: 0px 3px;
`;

export const NAC = styled.td``;

export const LOC = styled.td``;

export const Dated = styled.td`
  width: 100px;
`;

export const Consult = styled.td`
  width: 40px;
`;

export const Update = styled.td`
  width: 40px;
`;

export const Delete = styled.td`
  width: 40px;
`;

export const ButtonTD = styled.button<BtType>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */

  height: 30px;
  width: 30px;

  background: ${(props) =>
    props.used === 'alt'
      ? '#007acc'
      : props.used === 'show'
      ? '#565656'
      : '#c53030'};
  border-radius: 3px;
  border: 0px;

  svg {
    height: 17px;
    width: 17px;

    color: #fff;
  }
`;

export const TrajContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px 7px;
    background: #fff;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;
    > div {
      display: flex;
      > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #8a0002;
          text-shadow: unset;
        }
        p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
          user-select: none;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }
      & + div {
        margin: 10px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 500px) {
    > div {
      > div {
        flex-direction: column;

        span + span {
          margin: 5px 0 0 0;
        }
      }
    }
  }
`;
