import React, { HTMLAttributes, useCallback, useState, useRef } from 'react';
import { FaCheckSquare } from 'react-icons/fa';

import { Container, Title, Label } from './styles';

interface CheckBoxProps extends HTMLAttributes<HTMLInputElement> {
  title: string;
  checked?: boolean;
  id: string;
  isDisabled?: boolean;
  containerStyle?: object;
  labelStyle?: object;
  titleStyle?: object;
  dataInfo?: string;
}

const Checkbox: React.FC<CheckBoxProps> = ({
  id,
  title,
  checked = false,
  isDisabled = false,
  containerStyle = {},
  labelStyle = {},
  titleStyle = {},
  dataInfo = 'checkbox',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checkState, setCheckState] = useState(checked);

  const handleChanges = useCallback(() => {
    setCheckState((state) => !state);
  }, []);

  return (
    <Container style={containerStyle}>
      <Title style={titleStyle}>{title}</Title>
      <Label
        htmlFor={id}
        isChecked={checkState}
        isDisabled={isDisabled}
        style={labelStyle}
      >
        <FaCheckSquare />
        <input
          id={id}
          type="checkbox"
          checked={checkState}
          onChange={handleChanges}
          ref={inputRef}
          {...rest}
          data-checkbox={dataInfo}
        />
      </Label>
    </Container>
  );
};

export default Checkbox;
