import styled, { keyframes } from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: #8a0002;

  display: flex;
  align-items: center;

  @media screen and (orientation: landscape) and (max-width: 850px) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  color: #e6e6e6;
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: center;

  h1 {
    font-size: 50px;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    margin: 25px 0 50px 0;
  }

  h2 {
    text-align: center;
    text-justify: justify;
    font-size: 35px;
  }

  h4 {
    font-size: 16px;
  }

  @media screen and (orientation: landscape) and (max-width: 850px) {
    h1 {
      font-size: 18.72px;
      margin: 25px 0 0 0;
    }

    h2 {
      font-size: 18.72px;
    }

    h4 {
      font-size: 14px;
    }
  }
`;

const floatingGhostAnimation = keyframes`
from {
  transform: translateY(1rem)
}
to{
  transform: translateY(-1rem)
}
`;
const ghostShadowAnimaton = keyframes`
from {
  filter: blur(1px);
  width: 80%;
}
to{
  filter: blur(5px);
  width: 100%;
}
`;

export const GhostContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    color: #e6e6e6;
    height: 150px;
    width: 150px;
    animation: ${floatingGhostAnimation} 1s linear alternate infinite;
  }

  &:after {
    margin-top: 20px;
    content: '';
    background: rgba(230, 230, 230, 0.75);
    width: 80%;
    height: 20px;
    border-radius: 50%;
    animation: ${ghostShadowAnimaton} 1s linear alternate infinite;
  }

  @media screen and (orientation: landscape) and (max-width: 850px) {
    margin-top: 25px;
    svg {
      height: 75px;
      width: 75px;
    }
  }
`;

export const Button404 = styled(Button)`
  margin-top: 25px;

  background: #efefef;
  color: #332e2e;

  &:hover {
    background-color: #332e2e;
    color: #efefef;
  }
`;
