import styled from 'styled-components';
import { animated } from 'react-spring';

interface CropProps {
  show: boolean;
}

export const InputAvatar = styled(animated.div)`
  /* position: absolute; */

  padding: 20px 0;
  label {
    cursor: pointer;
    padding: 10px 50px;

    background: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 5px;
    border: 2px solid #efefef;
    box-shadow: 0px 0px 0px 0px rgba(51, 46, 46, 0.25);

    transition: border-color 0.5s ease, box-shadow 0.5s ease;

    input {
      display: none;
    }

    svg {
      color: #a6a6a6;
      width: 50px;
      height: 50px;
      transition: all 0.5s ease;
    }

    h4 {
      margin-top: 5px;
      font-size: 15px;
      color: #767676;
      text-shadow: 2px 2px 2px rgba(51, 46, 46, 0.25);
      transition: all 0.5s ease;
    }

    &:hover {
      border-color: #332e2e;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      svg {
        color: #332e2e;
      }
      h4 {
        color: #332e2e;
      }
    }
  }
`;

export const ReactCropContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  justify-content: center;
  width: 100%;

  span {
    margin-bottom: 10px;

    button {
      border-radius: 5px;
      outline: none;
      padding: 5px 15px;
      color: #efefef;

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

export const SaveButton = styled.button`
  border: 2px solid #004d1a;
  background-color: #00802b;
  transition: all 0.5s;

  &:hover {
    background-color: #004d1a;
  }
`;

export const CancelButton = styled.button`
  border: 2px solid #8a0002;
  background-color: #c53030;
  transition: all 0.5s;

  &:hover {
    background-color: #8a0002;
  }
`;
