import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from 'services/api';
import { handleTimeZone } from 'utils/formatDate';
import { df64 } from 'utils/encoding';

interface ChangeContextData {
  getInfo(): void;
  mud: boolean;
  plan: number;
  closed: boolean;
  start: string;
  end: string;
  currDate: {
    day: number;
    month: number;
    year: number;
    full: Date;
  };
  // getMud(): void;
  // getPlan(): void;
}

export interface ChangeState {
  mud: boolean;
  plan: number;
  currDate: {
    day: number;
    month: number;
    year: number;
    full: Date;
  };
  closed: boolean;
  start: string;
  end: string;
}

const ChangeContext = createContext<ChangeContextData>({} as ChangeContextData);

const ChangeProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ChangeState>({} as ChangeState);

  const getInfo = useCallback(async () => {
    // const response = await api.get<ChangeState>('/common/changes.php');
    const response = await api.get('/common/changes.php');

    const { mud, plan, currDate }: ChangeState = df64(response.data);
    const { full } = currDate;

    setData((state) => ({
      ...state,
      mud,
      plan,
      currDate: {
        ...currDate,
        full: handleTimeZone(full),
      },
    }));
  }, []);

  const getClosed = useCallback(async () => {
    const response = await api.get('/common/closed.php');

    const { closed, start, end } = df64(response.data);

    setData((state) => ({
      ...state,
      closed,
      start,
      end,
    }));
  }, []);

  useEffect(() => {
    getInfo();
    getClosed();
  }, [getClosed, getInfo]);

  return (
    <ChangeContext.Provider
      value={{
        getInfo,
        mud: data.mud,
        plan: data.plan,
        closed: data.closed,
        start: data.start,
        end: data.end,
        currDate: data.currDate,
      }}
    >
      {children}
    </ChangeContext.Provider>
  );
};

function useChanges(): ChangeContextData {
  const context = useContext(ChangeContext);

  if (!context) {
    throw new Error('useChanges must be used within an ChangeProvider');
  }

  return context;
}

export { useChanges, ChangeProvider };
