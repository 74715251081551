import styled from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';
import { Content } from 'styles/sgo_wrappers';

export const AlteredContent = styled(Content)`
  background: #fff;
`;

export const DropWrapper = styled.div`
  /* background: #fff; */
  padding: 10px;

  width: 100vw;
  height: 100%;
  margin: 25px 0;
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 200px;
  border: 4px dashed #bc4143;
  border-radius: 5px;

  input {
    display: none;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  p {
    margin: 5px 0 0 0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    color: #332e2e;
  }
`;

export const FileInfoContainer = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
  width: 100vw;
  padding: 10px 10px 25px 10px;
  /* flex-direction: column; */
  /* background: #7159c1; */

  align-items: center;
  span {
    display: flex;
    align-items: center;
  }
  svg {
    height: 25px;
    width: 25px;
    color: #565656;
  }

  p {
    font-size: 16px;
    color: #332e2e;
    margin-left: 10px;
  }

  @media screen and (max-width: 1500px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1170px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 870px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 570px) {
    grid: auto / 1fr;
    span {
      justify-content: center;
    }
  }
`;

export const DropzoneButton = styled(animated.button)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00802b;
  color: #efefef;
  padding: 10px 20px;
  width: 200px;
  border: 2px solid transparent;
  border-radius: 5px;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;

  transition: background-color 0.35s ease, color 0.35s ease;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${shade(0.2, '#00802b')};
    color: #fff;
  }
`;
