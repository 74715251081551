import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import SelectV2 from 'components/SelectV2';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import api from 'services/api';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useSpring } from 'react-spring';
import { FaTimes } from 'react-icons/fa';
import { Grid, GridItem } from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface MonthsProps {
  id: string;
  desc: string;
  status: boolean;
}

interface ListProps {
  cod: string;
  months: MonthsProps[];
}

const Planejamento: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [initialCoord, setInitialCoord] = useState(() => {
    return user.perfil === 'ZON' ? user.zoncod : user.anbc;
  });
  // const [initialCoord, setInitialCoord] = useState(() => {
  //   return user.perfil === 'ZON' ? user.zondesc : user.anbdesc;
  // });
  const [coord, setCoord] = useState(() => {
    return user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : { cod: user.anbc, desc: user.anbdesc };
  });
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<MonthsProps[]>([]);

  const [comboCoord, setComboCoord] = useState<ComboProps[]>([]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setComboCoord(
      response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
    );
  }, [user.anbc]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    const temp = response.data.filter(
      (item: ComboProps) =>
        item.value.substr(0, 2) === user.zoncod.substr(0, 2),
    );

    temp.unshift({ label: user.zondesc, value: user.zoncod });

    setComboCoord(temp);
  }, [user.zoncod, user.zondesc]);

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/planejamento_management.php');
      setRawList(response.data);
      const index = response.data.findIndex(
        (item: ListProps) => item.cod === coord.cod,
      );

      setList(response.data[index].months);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON'], true);
    getInfo();
    if (user.perfil === 'ZON') {
      getComboANB();
      return;
    }
    getComboLOC();
  }, [getComboANB, getComboLOC, getInfo, handlePermission, user.perfil]);

  const handleCoord = useCallback(() => {
    const selected = formRef.current?.getFieldValue('coord');
    const comboIndex = comboCoord.findIndex((item) => item.value === selected);
    const rawListIndex = rawList.findIndex((item) => item.cod === selected);

    setCoord({
      cod: comboCoord[comboIndex].value,
      desc: comboCoord[comboIndex].label,
    });
    setList([...rawList[rawListIndex].months]);
    setInitialCoord(comboCoord[comboIndex].value);
  }, [comboCoord, rawList]);

  const handleCheckBoxClick = useCallback(
    async (id: string) => {
      try {
        if (user.perfil === 'NAC' && coord.cod === user.anbc) {
          return;
        }
        setList((state) => [
          ...state.map((item) =>
            item.id === id ? { ...item, status: !item.status } : { ...item },
          ),
        ]);
        // setLoading(true);
        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            cod: coord.cod,
            month: id,
          }),
        );

        await api.post('/sgo/planejamento_management.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const index = rawList.findIndex((item) => item.cod === coord.cod);
        const rawListIndex = rawList[index].months.findIndex(
          (item) => item.id === id,
        );

        rawList[index].months[rawListIndex] = {
          ...rawList[index].months[rawListIndex],
          status: !rawList[index].months[rawListIndex].status,
        };

        setRawList((state) => [...state]);
      } catch (err) {
        setList((state) => [
          ...state.map((item) =>
            item.id === id ? { ...item, status: !item.status } : { ...item },
          ),
        ]);
        errorHandling(err);
      }
    },
    [coord.cod, errorHandling, rawList, user.anbc, user.perfil],
  );

  const handleFilterReset = useCallback(() => {
    setCoord({
      cod: user.perfil === 'ZON' ? user.zoncod : user.anbc,
      desc: user.perfil === 'ZON' ? user.zondesc : user.anbdesc,
    });

    const index = rawList.findIndex(
      (item: ListProps) =>
        item.cod === (user.perfil === 'ZON' ? user.zoncod : user.anbc),
    );

    setList(rawList[index].months);
    setInitialCoord(user.perfil === 'ZON' ? user.zoncod : user.anbc);
  }, [
    rawList,
    user.anbc,
    user.anbdesc,
    user.perfil,
    user.zoncod,
    user.zondesc,
  ]);

  const removeCoord = useSpring({
    opacity:
      coord.cod !== (user.perfil === 'ZON' ? user.zoncod : user.anbc) ? 1 : 0,
    pointerEvents:
      coord.cod !== (user.perfil === 'ZON' ? user.zoncod : user.anbc)
        ? 'all'
        : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>Exceção do Planejamento Financeiro</div>
          <div>{new Date().getFullYear()}</div>
          {/* <div>{coord.desc}</div> */}
        </AlteredHeader>
        <Form ref={formRef} onSubmit={() => null}>
          <SelectV2
            name="coord"
            content={comboCoord}
            initial={initialCoord}
            onChange={() => {
              setList([]);
              handleCoord();
            }}
          />
          <RemoveButton
            type="button"
            style={removeCoord}
            onClick={handleFilterReset}
          >
            <FaTimes />
            &nbsp;
            <p>Excluir filtro</p>
          </RemoveButton>
        </Form>
        <Grid>
          {list.map((item) => (
            <GridItem
              key={item.id}
              status={item.status}
              onClick={() => handleCheckBoxClick(item.id)}
              disabled={user.perfil === 'NAC' && coord.cod === user.anbc}
            >
              <p>{item.desc}</p>
            </GridItem>
          ))}
        </Grid>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Planejamento;
