export interface ChildProps {
  label: string;
  route: string;
}

export const menu: ChildProps[] = [
  { label: 'Oficinas pelo Brasil', route: 'oficinas' },
  { label: 'Encontro de Experiência de Deus (EED)', route: 'eed' },
  { label: 'O que é Oficina de Oração e Vida (TOV)?', route: 'sobre/tov' },
  { label: 'Nossa Missão', route: 'sobre/missao' },
  { label: 'Decreto', route: 'sobre/decreto' },
  { label: 'Oficinas de Oração e Vida pelo Mundo', route: 'sobre/pelo-mundo' },
  { label: 'Livros do Frei Ignacio Larrañaga', route: 'sobre/obras-frei' },
  { label: 'Contatos', route: 'contatos' },
];

export const services: ChildProps[] = [
  { label: 'Oficinas de Oração e Vida para Adultos', route: 'tov/adultos' },
  { label: 'Oficinas de Oração e Vida para Jovens', route: 'tov/jovens' },
  {
    label: 'Oficinas de Oração e Vida para Adolescentes',
    route: 'tov/adolescentes',
  },
  { label: 'Oficinas de Oração e Vida para Crianças', route: 'tov/criancas' },
  { label: 'Curso para Casais', route: 'tov/casais' },
];
