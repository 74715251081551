import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';

import { useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  FaPencilAlt,
  FaSearch,
  FaCamera,
  FaIdCard,
  FaTimes,
} from 'react-icons/fa';
import {
  Container,
  FloatInput,
  AlteredContent,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';
import * as S from 'styles/dialog_consult';
import * as O from 'styles/option_buttons';
import { ShowProps } from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { removeAcento, deconvertSpecialChars } from 'utils/specialChars';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { useSpring } from 'react-spring';
import {
  Grid,
  GridItem,
  GridItemText,
  GridItemInfo,
  GridItemAvatar,
  TotalGuias,
  ModalContent,
  Warn,
} from './styles';

export interface GuiaProps {
  gseq: string;
  nome: string;
  anbcod: string;
  anbdesc: string;
  loccod: string;
  locdesc: string;
  funccod: string;
  funcdesc: string;
  tgcod: string;
  tgdesc: string;
  cert: string;
  dtenvio: string;
  end: string;
  bairro: string;
  cid: string;
  uf: string;
  ufdesc: string;
  cep: string;
  fres: string;
  fcel: string;
  dtnasc: string;
  locnasc: string;
  estciv: string;
  ocup: string;
  idioma1: string;
  idioma2: string;
  mail: string;
  face: string;
  user: string;
  dtmanut: string;
  idioma: string;
  rg: string;
  perfil: string;
  epgseq: string;
  avatar: string;
  zoncod: string;
  zondesc: string;
  sex: string;
  rawenv: string;
  rawnasc: string;
  transf: number;
}

interface LocalStorageProps {
  anb?: string;
  loc?: string;
  gtype?: string;
}
const mode = process.env.REACT_APP_GUIAS;
const Guias: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();

  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const [showFIG, setShowFIG] = useState<ShowProps>({} as ShowProps);

  const [guias, setGuias] = useState<GuiaProps[]>([]);
  const [filterGuias, setFilterGuias] = useState<GuiaProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const [comboTG, setComboTG] = useState<ComboProps[]>([]);

  const [initNac, setInitNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    return anb || user.anbc;
  });
  const [initLoc, setInitLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return loc || (user.perfil !== 'LOC' ? 'Selecione' : user.loccod);
  });
  const [initType] = useState(() => {
    const { gtype }: LocalStorageProps = getLocalStorage(`${mode}`);
    return gtype || 'GA';
  });

  const getGuias = useCallback(
    async (zon = '') => {
      const { anb, loc, gtype }: LocalStorageProps = getLocalStorage(`${mode}`);
      try {
        let appendData = '';
        if (user.perfil === 'INT') {
          appendData = `?data=${JSON.stringify({ zon })}`;
        }
        const response = await api.get(`/sgo/guias_list.php${appendData}`);

        setGuias(response.data);

        let gui = [...response.data];
        gui = gui.filter(
          (item: GuiaProps) =>
            (gtype === 'GA'
              ? item.tgcod === 'GA' || item.tgcod === 'GP'
              : item.tgcod === gtype) &&
            (!['LOC', 'GUI'].includes(user.perfil)
              ? item.anbcod === anb &&
                (loc ? item.loccod === loc : item.anbcod === anb)
              : item.loccod === loc),
        );

        // if (loc) {
        //   gui = gui.filter((item: GuiaProps) => item.loccod === loc);
        // }

        setLoading(false);
        setFilterGuias(gui);
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    [user.perfil, errorHandling],
  );

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const getComboTG = useCallback(async () => {
    const response = await api.get('/combos/comboTG.php');

    setComboTG(
      user.perfil === 'LOC'
        ? [{ value: '', label: 'Selecione' }, ...response.data]
        : response.data,
    );
  }, [user.perfil]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get('/combos/comboLOCs.php');
    setLocs(response.data);

    if (user.perfil === 'NAC' || user.perfil === 'ZON') {
      const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === anb),
      );
    }
  }, [user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    setComboNacs([
      // { value: '', label: 'Todas as Nacionais' },
      ...response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    ]);
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value } = select.options[select.selectedIndex];

    formRef.current?.setFieldValue('comboANB', '');
    formRef.current?.setFieldValue('comboLocal', '');

    const defineNacs = nacs.filter(
      (item: ComboProps) => item.value.substr(0, 2) === value.substr(0, 2),
    );

    setComboNacs([...defineNacs]);

    setComboLocs([]);

    setInitNac('Selecione');
    setInitLoc('Selecione');

    setLoading(true);
    getGuias(value);
  }, [getGuias, nacs]);

  const handleANBselect = useCallback(() => {
    // const tg = formRef?.current?.getFieldValue('comboTG');
    // const nac = formRef.current?.getFieldValue('comboANB');
    // const zon = formRef.current?.getFieldValue('comboZON');
    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    const selectTG = formRef.current?.getFieldRef('comboTG');
    const tg = selectTG.options[selectTG.selectedIndex].value;

    const selectNAC = formRef.current?.getFieldRef('comboANB');
    const nac = selectNAC.options[selectNAC.selectedIndex].value;

    formRef.current?.setFieldValue('comboLocal', '');
    const defineLocs = locs.filter((item: ComboLocalProps) => item.anb === nac);

    setInitLoc('Selecione');
    setComboLocs(defineLocs);

    setFilterGuias(
      guias.filter(
        (item: GuiaProps) =>
          item.anbcod === nac &&
          (tg === 'GA'
            ? item.tgcod === 'GA' || item.tgcod === 'GP'
            : item.tgcod === tg),
      ),
    );

    setLocalStorage(`${mode}`, {
      anb: nac,
    });
  }, [locs, guias]);

  const handleLOCselect = useCallback(() => {
    const { anb, gtype }: LocalStorageProps = getLocalStorage(`${mode}`);

    const loc = formRef?.current?.getFieldValue('comboLocal');
    let filtered = [...guias];
    if (anb) {
      filtered = filtered.filter((item) => item.anbcod === anb);
    }
    if (loc) {
      filtered = filtered.filter((item) => item.loccod === loc);
    }
    filtered = filtered.filter((item) =>
      gtype === 'GA'
        ? item.tgcod === 'GA' || item.tgcod === 'GP'
        : item.tgcod === gtype,
    );
    setFilterGuias(filtered);

    setLocalStorage(`${mode}`, {
      loc,
    });
    setInitLoc(loc);
  }, [guias]);

  const handleTGselect = useCallback(() => {
    const { anb, loc }: LocalStorageProps = getLocalStorage(`${mode}`);
    const tg = formRef?.current?.getFieldValue('comboTG');

    setLocalStorage(`${mode}`, {
      gtype: tg,
    });

    let filtered = [...guias];
    if (anb) {
      filtered = filtered.filter((item) => item.anbcod === anb);
    }
    if (loc) {
      filtered = filtered.filter((item) => item.loccod === loc);
    }
    filtered = filtered.filter((item) =>
      tg === 'GA'
        ? item.tgcod === 'GA' || item.tgcod === 'GP'
        : item.tgcod === tg,
    );
    setFilterGuias(filtered);
  }, [guias]);

  const handleSearch = useCallback(
    (value: string) => {
      const tg = formRef?.current?.getFieldValue('comboTG');
      const zon = formRef?.current?.getFieldValue('comboZON');
      const nac = formRef?.current?.getFieldValue('comboANB');
      const loc = formRef?.current?.getFieldValue('comboLocal');

      setSearchValue(value);

      if (loc) {
        setFilterGuias(
          guias.filter(
            (item: GuiaProps) =>
              item.loccod === loc &&
              (tg === 'GA'
                ? item.tgcod === 'GA' || item.tgcod === 'GP'
                : item.tgcod === tg) &&
              item.nome.toLowerCase().includes(value.toLowerCase()),
          ),
        );
        return;
      }

      if (nac) {
        setFilterGuias(
          guias.filter(
            (item: GuiaProps) =>
              item.anbcod === nac &&
              (tg === 'GA'
                ? item.tgcod === 'GA' || item.tgcod === 'GP'
                : item.tgcod === tg) &&
              item.nome.toLowerCase().includes(value.toLowerCase()),
          ),
        );
        return;
      }

      if (zon) {
        setFilterGuias(
          guias.filter(
            (item: GuiaProps) =>
              item.zoncod === zon &&
              (tg === 'GA'
                ? item.tgcod === 'GA' || item.tgcod === 'GP'
                : item.tgcod === tg) &&
              item.nome.toLowerCase().includes(value.toLowerCase()),
          ),
        );
        return;
      }

      setFilterGuias(
        guias.filter(
          (item: GuiaProps) =>
            (tg === 'GA'
              ? item.tgcod === 'GA' || item.tgcod === 'GP'
              : item.tgcod === tg) &&
            removeAcento(item.nome)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()),
        ),
      );
    },
    [guias],
  );

  const totalGuias = useMemo(() => {
    let total = 0;

    filterGuias.forEach(() => {
      total += 1;
    });

    return total;
  }, [filterGuias]);

  useEffect(() => {
    const { anb, loc, gtype }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (!anb && ['INT', 'ZON', 'NAC'].includes(user.perfil)) {
      setLocalStorage(`${mode}`, {
        anb: user.anbc,
      });
    }
    if (!loc && ['LOC', 'GUI'].includes(user.perfil)) {
      setLocalStorage(`${mode}`, {
        loc: user.loccod,
      });
    }
    if (!gtype) {
      setLocalStorage(`${mode}`, {
        gtype: 'GA',
      });
    }
    getComboTG();
    if (user.perfil === 'INT') {
      getComboZON();
    }
    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboANB();
    }
    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
      getComboLOC();
    }
    // getComboANB();
    // getComboLOC();
    // formRef?.current?.setFieldValue('comboZON', user.zoncod);
    // formRef?.current?.setFieldValue('comboANB', '');
    // formRef?.current?.setFieldValue('comboLocal', '');
    // formRef?.current?.setFieldValue('comboTG', 'GA');
    // setInitLoc('Selecione');
    if (user.perfil !== 'INT') {
      getGuias();
    } else {
      setLoading(false);
    }
  }, [getComboZON, getComboANB, getComboLOC, getComboTG, getGuias, user]);

  const handleDownloadFIG = useCallback((ev) => {
    const { id } = ev.currentTarget.dataset;

    const link = document.querySelector<HTMLAnchorElement>(
      `a[data-link="${id}"]`,
    );

    if (link) {
      link.click();

      setShowFIG((state) => ({
        ...state,
        open: true,
      }));

      const timer = setTimeout(() => {
        setShowFIG((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  const removeFilter = useCallback(
    (ev) => {
      setInitLoc('Selecione');

      deleteLocalStorageItemKey(`${mode}`, ['loc']);

      const { anb, gtype }: LocalStorageProps = getLocalStorage(`${mode}`);
      let filtered = [...guias];
      filtered = filtered.filter((item) => item.anbcod === anb);
      filtered = filtered.filter((item) =>
        gtype === 'GA'
          ? item.tgcod === 'GA' || item.tgcod === 'GP'
          : item.tgcod === gtype,
      );

      setFilterGuias(filtered);
    },
    [guias],
  );

  const locFilterRemove = useSpring({
    opacity: initLoc !== 'Selecione' ? 1 : 0,
    pointerEvents: initLoc !== 'Selecione' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.adm && <IncludeButton />}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial="Selecione"
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initNac}
                  />
                </span>
              )}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <>
                  <span>
                    <p>Filtre por Local:</p>
                    <SelectV2
                      name="comboLocal"
                      content={comboLocs}
                      onChange={handleLOCselect}
                      initial={initLoc}
                    />
                  </span>
                  <RemoveButton
                    type="button"
                    style={locFilterRemove}
                    data-type="loc"
                    onClick={removeFilter}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </>
              )}

              <span>
                <p>Filtre por Tipo de Guia:</p>
                <SelectV2
                  name="comboTG"
                  content={comboTG}
                  onChange={handleTGselect}
                  initial={initType}
                />
              </span>
            </div>
          </Form>
        }
      />

      {filterGuias.length > 0 && (
        <FloatInput amount="60px">
          <input
            placeholder="Filtrar por nome"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={filterGuias.length > 0 ? '240px' : '190px'}>
        {totalGuias > 0 && (
          <TotalGuias>Total de Guias: {totalGuias}</TotalGuias>
        )}

        <Grid>
          {filterGuias.map((item: GuiaProps) => (
            <GridItem key={item.gseq}>
              <GridItemAvatar>
                <img
                  src={`${process.env.REACT_APP_AVATAR}${item.avatar}`}
                  alt={item.nome}
                  onError={({ currentTarget }) => {
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.onerror = null; // prevents looping
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.src = `${process.env.REACT_APP_AVATAR}/default.png`;
                  }}
                />
              </GridItemAvatar>
              <GridItemText>
                <strong>{item.nome}</strong>
                <p style={{ color: '#565656' }}>
                  Certificado:&nbsp;<strong>{item.cert}</strong>
                </p>
                <p style={{ color: '#767676' }}>
                  {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                    <>
                      {item.anbdesc} - {item.locdesc}
                    </>
                  )}
                  {user.perfil === 'NAC' && <>{item.locdesc}</>}
                </p>
              </GridItemText>
              <GridItemInfo>
                <O.GridOptions>
                  <O.Show
                    onClick={() =>
                      setShow({
                        title: item.nome,
                        open: true,
                        content: (
                          <ModalContent>
                            <section>
                              <img
                                src={`${process.env.REACT_APP_AVATAR}${item.avatar}`}
                                alt={item.nome}
                              />
                              <span>
                                <p>
                                  Certificado: <strong>{item.cert}</strong>
                                </p>
                              </span>

                              {item.funccod && (
                                <span>
                                  <p>
                                    Função: <strong>{item.funcdesc}</strong>
                                  </p>
                                </span>
                              )}
                              <span>
                                <p>
                                  Coord. Zonal: <strong>{item.zondesc}</strong>
                                </p>
                              </span>
                              <span>
                                <p>
                                  Coord. Nacional:{' '}
                                  <strong>{item.anbdesc}</strong>
                                </p>
                              </span>
                              <span>
                                <p>
                                  Coord. Local: <strong>{item.locdesc}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Tipo de Guia: <strong>{item.tgdesc}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Data de Envio: <strong>{item.dtenvio}</strong>
                                </p>
                              </span>
                            </section>
                            <section>
                              <span>
                                <p>
                                  Endereço:{' '}
                                  <strong>
                                    {deconvertSpecialChars(item.end)}
                                  </strong>
                                </p>
                                <p>
                                  Bairro:{' '}
                                  <strong>
                                    {deconvertSpecialChars(item.bairro)}
                                  </strong>
                                </p>
                                <p>
                                  Cidade:{' '}
                                  <strong>
                                    {deconvertSpecialChars(item.cid)}
                                  </strong>
                                </p>
                                <p>
                                  UF: <strong>{item.uf}</strong>
                                </p>
                                <p>
                                  CEP: <strong>{item.cep}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Telefone: <strong>{item.fres}</strong>
                                </p>
                                <p>
                                  Celular: <strong>{item.fcel}</strong>
                                </p>
                                <p>
                                  Email: <strong>{item.mail}</strong>
                                </p>
                                <p>
                                  Facebook: <strong>{item.face}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Data de Nascimento:{' '}
                                  <strong>{item.dtnasc}</strong>
                                </p>
                                <p>
                                  Sexo:{' '}
                                  <strong>
                                    {item.sex === 'M'
                                      ? 'Masculino'
                                      : item.sex === 'F'
                                      ? 'Feminino'
                                      : '-'}
                                  </strong>
                                </p>
                                <p>
                                  Cidade Natal: <strong>{item.locnasc}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  RG: <strong>{item.rg}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Estado Civil: <strong>{item.estciv}</strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Idioma
                                  {item.idioma1.length > 0 &&
                                    item.idioma2.length > 0 &&
                                    's'}
                                  :&nbsp;
                                  <strong>
                                    {item.idioma1 && item.idioma1}
                                    {item.idioma1 && item.idioma2 && ' | '}
                                    {item.idioma2 && item.idioma2}
                                  </strong>
                                </p>
                              </span>

                              <span>
                                <p>
                                  Ocupação: <strong>{item.ocup}</strong>
                                </p>
                              </span>
                            </section>
                          </ModalContent>
                        ),
                      })
                    }
                  >
                    <FaSearch />
                  </O.Show>
                  {((user.perfil === 'NAC' && item.anbcod === user.anbc) ||
                    (user.perfil === 'LOC' && item.loccod === user.loccod)) &&
                    item.transf === 0 && (
                      <O.Update
                        to={{
                          pathname: `${pathname}/update`,
                          state: { guia: item },
                        }}
                      >
                        <FaPencilAlt />
                      </O.Update>
                    )}

                  {((user.perfil === 'NAC' && item.anbcod === user.anbc) ||
                    (user.perfil === 'LOC' && item.loccod === user.loccod) ||
                    (user.perfil === 'GUI' && item.gseq === user.gseq)) && (
                    <O.Update
                      to={{
                        pathname: `${pathname}/avatar`,
                        state: {
                          guia: {
                            gseq: item.gseq,
                            name: item.nome,
                            avatar: item.avatar,
                          },
                        },
                      }}
                      style={{ background: '#7159c1' }}
                    >
                      <FaCamera />
                    </O.Update>
                  )}
                  {((user.perfil === 'ZON' && item.zoncod === user.zoncod) ||
                    (user.perfil === 'NAC' && item.anbcod === user.anbc) ||
                    (user.perfil === 'LOC' && item.loccod === user.loccod) ||
                    (user.perfil === 'GUI' && item.gseq === user.gseq)) && (
                    <O.Update
                      to={{
                        pathname: `${pathname.replace('guias', 'fig')}`,
                        state: {
                          gseq: item.gseq,
                          name: item.nome,
                        },
                      }}
                      // style={{ background: '#5e5555' }}
                      style={{ background: '#008097' }}
                    >
                      <FaIdCard />
                    </O.Update>
                  )}

                  {user.perfil === 'INT' && (
                    <O.Show
                      onClick={handleDownloadFIG}
                      data-type="fig"
                      data-id={item.gseq}
                      style={{ background: '#008097' }}
                    >
                      <FaIdCard />
                      <a
                        data-link={item.gseq}
                        href={`${
                          process.env.REACT_APP_API
                        }/sgo/xls_fig.php?data=${JSON.stringify({
                          token: localStorage.getItem(
                            `${process.env.REACT_APP_TOKEN}`,
                          ),
                          gseq: item.gseq,
                        })}`}
                        rel="noreferrer noopener"
                        target="_top"
                        style={{ display: 'none' }}
                      >
                        <FaIdCard />
                      </a>
                    </O.Show>
                  )}
                </O.GridOptions>
                {item.transf > 0 && (
                  <Warn>
                    Este guia possui uma transferência de coordenação pendente.
                  </Warn>
                )}
              </GridItemInfo>
            </GridItem>
          ))}
        </Grid>
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
        <S.Container scroll="paper" maxWidth={false} open={showFIG.open}>
          <S.Title>
            <h2>&nbsp;</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Guias;
