import React, { useEffect, useState, useCallback } from 'react';

import ReactPlayer from 'react-player/lazy';

import { Container, Content } from 'styles/wrappers';

// import { animated, useSpring } from 'react-spring';

import SGOHeader from 'components/SGOHeader';
import Navbar from 'components/Navbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { useCredentials } from 'hooks/credentials';
// import {
//   materiais,
//   tesouraria,
//   secretaria,
//   configuracao,
//   gerencial,
//   misc,
// } from './videos';

import api from 'services/api';
import {
  PlayerContainer,
  VideoAulaContainer,
  VideoAulaSection,
  VideoGrid,
  GridItem,
} from './styles';

interface ClassProps {
  source: string;
  thumbnail: string;
  title: string;
  module: string;
}

const VideoAulas: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { errorHandling } = useCredentials();
  const [video, setVideo] = useState('');
  const [content, setContent] = useState('initial');

  const [materiais, setMateriais] = useState<ClassProps[]>([]);
  const [tesouraria, setTesouraria] = useState<ClassProps[]>([]);
  const [secretaria, setSecretaria] = useState<ClassProps[]>([]);
  const [gerencial, setGerencial] = useState<ClassProps[]>([]);
  const [diretriz, setDiretriz] = useState<ClassProps[]>([]);

  const getVideos = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<ClassProps[]>('/web/videoaulas.php');
      setMateriais(response.data.filter((item) => item.module === 'MAT'));
      setTesouraria(response.data.filter((item) => item.module === 'TES'));
      setSecretaria(response.data.filter((item) => item.module === 'SEC'));
      setGerencial(response.data.filter((item) => item.module === 'GER'));
      setDiretriz(response.data.filter((item) => item.module === 'DIR'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const handleVideo = useCallback((tag: string) => {
    setVideo(tag);
    window.scrollTo(0, 0);
  }, []);

  // const handleContent = useCallback(
  //   (value: string) => {
  //     setContent(value);
  //     handleVideo('');
  //   },
  //   [handleVideo],
  // );

  const handleInitial = useCallback(() => {
    setContent('initial');
    handleVideo('');
  }, [handleVideo]);

  const handleMaterial = useCallback(() => {
    setContent('mat');
    handleVideo('');
  }, [handleVideo]);

  const handleTesouraria = useCallback(() => {
    setContent('tes');
    handleVideo('');
  }, [handleVideo]);

  const handleSecretaria = useCallback(() => {
    setContent('sec');
    handleVideo('');
  }, [handleVideo]);

  const handleGerencial = useCallback(() => {
    setContent('ger');
    handleVideo('');
  }, [handleVideo]);

  const handleDiretrizes = useCallback(() => {
    setContent('dir');
    handleVideo('');
  }, [handleVideo]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <Navbar web containerType="buttons" avoidSocialMedia={false}>
        {content !== 'initial' && (
          <button type="button" onClick={handleInitial}>
            Todas as aulas
          </button>
        )}
        {materiais.length > 0 && content !== 'mat' && (
          <button type="button" onClick={handleMaterial}>
            Materiais
          </button>
        )}
        {tesouraria.length > 0 && content !== 'tes' && (
          <button type="button" onClick={handleTesouraria}>
            Tesouraria
          </button>
        )}
        {secretaria.length > 0 && content !== 'sec' && (
          <button type="button" onClick={handleSecretaria}>
            Secretaria
          </button>
        )}
        {gerencial.length > 0 && content !== 'ger' && (
          <button type="button" onClick={handleGerencial}>
            Gerencial
          </button>
        )}
        {diretriz.length > 0 && content !== 'dir' && (
          <button type="button" onClick={handleDiretrizes}>
            Diretrizes
          </button>
        )}
      </Navbar>

      <Content>
        <PlayerContainer hasVid={!!video}>
          <ReactPlayer
            loop
            url={`${process.env.REACT_APP_TUTORIAL}${video}.mp4`}
            controls
            onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
            playing
            // style={{ maxWidth: '90vw' }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
          />
        </PlayerContainer>
        <VideoAulaContainer>
          {(content === 'mat' || content === 'initial') &&
            materiais.length > 0 && (
              <VideoAulaSection>
                <h1>Materiais</h1>
                <VideoGrid>
                  {materiais.map((item) => (
                    <GridItem
                      key={item.source}
                      title={item.title}
                      onClick={() => handleVideo(item.source)}
                      selected={video === item.source}
                    >
                      <img
                        src={`${process.env.REACT_APP_TUTORIAL}thumbs/${item.thumbnail}.jpg`}
                        alt={item.title}
                      />
                      <p>{item.title}</p>
                    </GridItem>
                  ))}
                </VideoGrid>
              </VideoAulaSection>
            )}
          {(content === 'tes' || content === 'initial') &&
            tesouraria.length > 0 && (
              <VideoAulaSection>
                <h1>Tesouraria</h1>
                <VideoGrid>
                  {tesouraria.map((item) => (
                    <GridItem
                      key={item.source}
                      title={item.title}
                      onClick={() => handleVideo(item.source)}
                      selected={video === item.source}
                    >
                      <img
                        src={`${process.env.REACT_APP_TUTORIAL}thumbs/${item.thumbnail}.jpg`}
                        alt={item.title}
                      />
                      <p>{item.title}</p>
                    </GridItem>
                  ))}
                </VideoGrid>
              </VideoAulaSection>
            )}
          {(content === 'sec' || content === 'initial') &&
            secretaria.length > 0 && (
              <VideoAulaSection>
                <h1>Secretaria</h1>
                <VideoGrid>
                  {secretaria.map((item) => (
                    <GridItem
                      key={item.source}
                      title={item.title}
                      onClick={() => handleVideo(item.source)}
                      selected={video === item.source}
                    >
                      <img
                        src={`${process.env.REACT_APP_TUTORIAL}thumbs/${item.thumbnail}.jpg`}
                        alt={item.title}
                      />
                      <p>{item.title}</p>
                    </GridItem>
                  ))}
                </VideoGrid>
              </VideoAulaSection>
            )}
          {(content === 'ger' || content === 'initial') &&
            gerencial.length > 0 && (
              <VideoAulaSection>
                <h1>Gerencial</h1>
                <VideoGrid>
                  {gerencial.map((item) => (
                    <GridItem
                      key={item.source}
                      title={item.title}
                      onClick={() => handleVideo(item.source)}
                      selected={video === item.source}
                    >
                      <img
                        src={`${process.env.REACT_APP_TUTORIAL}thumbs/${item.thumbnail}.jpg`}
                        alt={item.title}
                      />
                      <p>{item.title}</p>
                    </GridItem>
                  ))}
                </VideoGrid>
              </VideoAulaSection>
            )}
          {(content === 'dir' || content === 'initial') && diretriz.length > 0 && (
            <VideoAulaSection>
              <h1>Diretrizes</h1>
              <VideoGrid>
                {diretriz.map((item) => (
                  <GridItem
                    key={item.source}
                    title={item.title}
                    onClick={() => handleVideo(item.source)}
                    selected={video === item.source}
                  >
                    <img
                      src={`${process.env.REACT_APP_TUTORIAL}thumbs/${item.thumbnail}.jpg`}
                      alt={item.title}
                    />
                    <p>{item.title}</p>
                  </GridItem>
                ))}
              </VideoGrid>
            </VideoAulaSection>
          )}
        </VideoAulaContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default VideoAulas;
