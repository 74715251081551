import React, { useState, useCallback, useEffect } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { useLocation, useHistory } from 'react-router-dom';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { formatCPF } from 'utils/handleCPF';

import api from 'services/api';

import { Wrapper, FGInfo, Warn } from './styles';

import { ListProps, FGProps } from '../main';

const AlunosRegister: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<ListProps>();
  const history = useHistory();

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const [epg] = useState(() => ({ ...location.state }));
  const [fgs, setFGs] = useState<FGProps[]>([]);
  const [existent, setExistent] = useState<string[]>([]);
  const [toAdd, setToAdd] = useState<string[]>([]);

  const getFGs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/epg_aluno_list.php?data=${JSON.stringify({ epg: epg.epg })}`,
      );

      // setFGs(
      //   response.data.filter((item: FGProps) =>
      //     epg.stage === 2
      //       ? item.et1 === 'C' && !item.et2
      //       : item.et2 === 'C' && !item.et3,
      //   ),
      // );
      setFGs(
        response.data.filter((item: FGProps) =>
          epg.stage === 2 ? item.et1 === 'C' : item.et2 === 'C',
        ),
      );

      setExistent([
        ...response.data
          .filter((item: FGProps) =>
            epg.stage === 2 ? !!item.et2 : !!item.et3,
          )
          .map((item: FGProps) => item.cpf),
      ]);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [epg.epg, epg.stage, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getFGs();
  }, [getFGs, history, location.state]);

  const handleExistent = useCallback((cpf: string) => {
    setToAdd((state) =>
      state.indexOf(cpf) > -1
        ? state.filter((item: string) => item !== cpf)
        : [...state, cpf],
    );
  }, []);

  const handleRegister = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({ epg: epg.epg, stage: epg.stage, fgs: toAdd }),
      );

      await api.post('/sgo/epg_aluno_register.php', send, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: `Futuros Guias registrados na ${epg.stage}ª Etapa.`,
      });
      history.goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, epg.epg, epg.stage, errorHandling, history, toAdd]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(epg).length > 0 ? `EPG N° ${epg.epg}` : ''}
      />
      <Content>
        <AlteredHeader>
          {/* <div>
            <p>{epg.stage}ª Etapa</p>
          </div> */}
          <div>
            <strong>
              Selecione o futuro guia a ser registrado na {epg.stage}ª etapa e
              clique em finalizar.
            </strong>
          </div>
        </AlteredHeader>

        <Wrapper>
          {fgs.map((item) => (
            <FGInfo
              key={item.cpf}
              type="button"
              selected={toAdd.indexOf(item.cpf) > -1}
              onClick={() => handleExistent(item.cpf)}
              disabled={existent.indexOf(item.cpf) > -1}
            >
              <Warn>
                <p>
                  {!!(epg.stage === 2 ? item.et2 : item.et3) && (
                    <>
                      Já registrado como&nbsp;
                      <strong>
                        {(epg.stage === 2 ? item.et2 : item.et3) === 'P'
                          ? 'participante'
                          : 'perseverante'}
                      </strong>
                      &nbsp;nesta etapa
                    </>
                  )}
                </p>
              </Warn>
              <strong>{item.name}</strong>
              <p>
                CPF: <strong>{formatCPF(item.cpf)}</strong>
              </p>
            </FGInfo>
          ))}
        </Wrapper>
        {toAdd.length > 0 && (
          <Button bgcolor="#00802b" animated onClick={handleRegister}>
            Finalizar
          </Button>
        )}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AlunosRegister;
