import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { FloatInput } from 'styles/sgo_wrappers';
import { animated } from 'react-spring';

interface MainContainerProps {
  shrinkit: boolean;
}

interface TableProps {
  tabletype: string;
}

export const AlteredFloatInput = styled(FloatInput)`
  position: relative;
  top: 0;
`;

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-left-width: 0px;
  border-right-width: 0px;

  margin: 10px 0px 20px 0px;
  width: 100vw;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
`;

export const Table = styled.table<TableProps>`
  border-collapse: collapse;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    height: 40px;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;

    button, a {
      text-decoration: none;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: #464646;
      border: transparent;
      border-radius: 5px;
      padding: 5px;
      color: #fff;
      transition: background-color 0.35s ease;

      svg {
        height: 17.5px;
        width: 17.5px;
      }

      &:hover {
        background-color: ${shade(0.2, '#464646')};
      }
    }

    @media screen and(max-width: 800px) {
      font-size: 14px;
    }
  }

  ${(props) => css`
    td:nth-child(${['INT', 'ZON'].indexOf(props.tabletype) > -1 ? 1 : 0}) {
      max-width: 150px;
    }
  `}

  td:nth-last-child(1) {
    width: 50px;
    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }

  ${(props) =>
    props.tabletype === 'ZON' &&
    css`
      td:nth-last-child(1) {
        button {
          background: #c53030;
          &:hover {
            background-color: ${shade(0.2, '#c53030')};
          }
        }
      }
      td:nth-last-child(2) {
        button {
          background: #464646;
          &:hover {
            background-color: ${shade(0.2, '#464646')};
          }
        }
        width: 50px;
      }

      td:nth-last-child(3) {
        text-align: left;
        @media screen and (max-width: 800px) {
          text-align: center;
        }
        @media screen and (max-width: 450px) {
          overflow: hidden;
          display: none;
        }
      }
    `}

  ${(props) =>
    props.tabletype !== 'NAC' &&
    css`
      td:nth-last-child(2) {
        text-align: left;
        @media screen and (max-width: 800px) {
          text-align: center;
        }
        @media screen and (max-width: 450px) {
          overflow: hidden;
          display: none;
        }
      }
    `}

  ${(props) =>
    ['GUI', 'LOC'].indexOf(props.tabletype) > -1 &&
    css`
      td:nth-last-child(2) {
        @media screen and (max-width: 800px) {
          text-align: left;
        }
        @media screen and (max-width: 620px) {
          text-align: center;
        }
      }
    `}
`;

//
// box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
export const SubtitleContainer = styled(animated.div)`
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0px;
  background: #fff;
  border: 2px solid #efefef;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  height: 0px;
  font-size: 14px;

  width: 330px;
  overflow: hidden;

  span {
    display: flex;
    p {
      margin: 0 0 0 5px;
    }
    & + span {
      margin: 5px 0 0 0;
    }
  }
  opacity: 0;
  z-index: -1;
  transform: translate(-25px, 250px);
  transition: transform 0.25s ease, width 0.5s ease,
    border-right-width 0.5s ease, border-left-width 0.5s ease,
    border-radius 0.5s ease, opacity 0.5s, height 0.35s ease, padding 0.35s;

  @media screen and (max-width: 800px) {
    opacity: 1;
    overflow: unset;
    height: 84px;
    padding: 5px;
    transform: translate(0px, 0px);
  }
  @media screen and (max-width: 360px) {
    width: 100vw;
    border-radius: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;
