import styled, { keyframes } from 'styled-components';
import { Form } from '@unform/web';

const appearFromLeft = keyframes`
  from{
    transform: translateX(-35px);
    /* box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.85); */
    opacity: 0;
  } to {
    transform: translateX(0px);
    /* box-shadow: 0px 15px 10px 2px rgba(0, 0, 0, 0.85); */
    opacity: 1;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 330px);
  width: 90vw;
  max-width: 1000px;

  form {
    flex: 1;
    width: 80vw;
    max-width: 1000px;
    max-height: 500px;
    padding: 0px 30px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: #efefef;
    border: 2px solid #efefef;
    border-radius: 5px; */

    h1 {
      /* font-size: 32px; */
      width: 100%;
      margin: 0 0 25px 0;

      text-align: center;

      color: #8a0002;
      /* text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75); */
      /* background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(197, 48, 48, 0.25),
        rgba(197, 48, 48, 0.5),
        rgba(138, 0, 2, 1),
        rgba(138, 0, 2, 1),
        rgba(138, 0, 2, 1),
        rgba(138, 0, 2, 1),
        rgba(138, 0, 2, 1),
        rgba(197, 48, 48, 0.5),
        rgba(197, 48, 48, 0.25),
        rgba(255, 255, 255, 0)
      ); */
    }

    animation: ${appearFromLeft} 1s forwards;
    > img {
      margin: 20% 0;
      height: 50%;
      max-height: 100px;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;

export const AlteredForm = styled(Form)`
  h2 {
    font-size: 18.72px;
    text-align: center;
    color: #8a0002;
    margin: 0 0 25px 0;
  }
`;
