import styled from 'styled-components';

export const RadioDIV = styled.div`
  padding: 5px 10px;
  border: 2px solid #efefef;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
`;

export const GridContainer = styled.div`
  margin: 20px 0 10px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  width: 90vw;

  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
    column-gap: 0;
  }

  @media screen and (max-width: 500px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  position: relative;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  > p {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    color: #8a0002;
    margin: 0 0 10px 0;
  }

  span {
    font-size: 16px;
    display: flex;

    p {
      color: #767676;
      strong {
        color: #332e2e;
      }

      & + p {
        margin: 0 0 0 5px;
      }
    }

    & + span {
      margin: 3px 0 0 0;
    }
  }

  transition: border-color 0.35s ease, box-shadow 0.35s ease;
  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 500px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0;
  }
`;
