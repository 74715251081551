import styled from 'styled-components';

export const ListContainer = styled.div`
  margin-top: 15px;
  div + div {
    margin-top: 15px;
  }
`;

export const ListItemContainer = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;

  @media screen and (max-width: 1750px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
    column-gap: 0px;
  }
`;

export const ListItem = styled.span`
  padding: 2px 3px;
  border: 2px solid #efefef;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);

  transition: box-shadow 0.35s ease;

  &:hover {
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.5);
  }

  span {
    display: flex;
    flex-direction: column;

    p {
      font-size: 15px;
      color: #767676;
      strong {
        color: #332e2e;
      }
      & + p {
        margin: 0;
      }
    }

    & + span {
      flex-direction: row;

      p + p {
        margin: 0 0 0 7px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    span {
      & + span {
        flex-direction: column;

        p + p {
          margin: 0;
        }
      }
    }
  }
`;
