import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  ResetFilterButton,
  CoordProps,
  Header,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import { useHistory } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import PrintButton from 'components/PrintButton';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FaPencilAlt, FaFileExcel } from 'react-icons/fa';
import {
  convertSpecialChars,
  deconvertSpecialChars,
  removeAcento,
} from 'utils/specialChars';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';
import {
  Grid,
  GridItem,
  UpdateContainer,
  QtdeContainer,
  JustContainer,
  InputUpdateContainer,
  TextareaUpdateContainer,
} from './styles';

interface EstoqueProps {
  cod: string;
  desc: string;
  matcod: string;
  matdesc: string;
  // qtdent: string | number;
  // qtdsai: string | number;
  qtdsld: string | number;
  qtdseg: string | number;
  manut: string;
  controlled: boolean;
}

const Estoque: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, checkCredentials } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [coord, setCoord] = useState<CoordProps>({} as CoordProps);

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({
    open: false,
    content: '',
    values: {},
  });

  const [estoque, setEstoque] = useState<EstoqueProps[]>([]);
  const [estoqueRaw, setEstoqueRaw] = useState<EstoqueProps[]>([]);

  const getEstoque = useCallback(async () => {
    const send = {
      init: true,
    };

    if (user.perfil === 'INT' || user.perfil === 'ZON') {
      setCoord({ cod: user.zoncod, desc: user.zondesc });
    }
    if (user.perfil === 'NAC') {
      setCoord({ cod: user.anbc, desc: user.anbdesc });
    }
    if (user.perfil === 'LOC') {
      setCoord({ cod: user.loccod, desc: user.locdesc });
    }

    const response = await api.get(
      `/sgo/estoque_list.php?data=${JSON.stringify(send)}`,
    );

    setEstoque(response.data);
    setEstoqueRaw(response.data);
    setLoading(false);
  }, [user]);

  const getAdditionalEstoque = useCallback(async (value: string) => {
    const send = {
      coord: value,
    };
    const response = await api.get<EstoqueProps[]>(
      `/sgo/estoque_list.php?data=${JSON.stringify(send)}`,
    );
    setEstoque(response.data);
    setEstoqueRaw(response.data);
    const { cod, desc } = response.data[0];

    setCoord({ cod, desc });
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    if (['NAC'].indexOf(user.perfil) > -1) {
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
      );
    }
  }, [user.perfil, user.anbc]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleZONselect = useCallback(() => {
    const zon = formRef.current?.getFieldValue('comboZON');

    formRef.current?.setFieldValue('comboANB', '');
    formRef.current?.setFieldValue('comboLocal', '');

    setComboNacs(
      nacs.filter(
        (item: ComboProps) => item.value.substr(0, 2) === zon.substr(0, 2),
      ),
    );

    setComboLocs([]);

    getAdditionalEstoque(zon);

    setSearchValue('');
  }, [nacs, getAdditionalEstoque]);

  const handleANBselect = useCallback(() => {
    const nac = formRef?.current?.getFieldValue('comboANB');

    getAdditionalEstoque(nac);
    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === nac));
    setSearchValue('');
  }, [getAdditionalEstoque, locs]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef?.current?.getFieldValue('comboLocal');
    getAdditionalEstoque(loc);
    setSearchValue('');
  }, [getAdditionalEstoque]);

  useEffect(() => {
    if (user.perfil === 'GUI') {
      addToast({
        type: 'error',
        title: 'Ação não permitida',
        description: 'Área restrita.',
      });

      history.replace('/sgo');
      return;
    }

    checkCredentials();
    getComboZON();
    getComboANB();
    getComboLOC();
    getEstoque();
  }, [
    getComboZON,
    getComboANB,
    getComboLOC,
    getEstoque,
    checkCredentials,
    addToast,
    history,
    user.perfil,
  ]);

  const handleEstChange = useCallback(
    async (matcod) => {
      const form = document.querySelector<HTMLFormElement>(
        `form[id="form${matcod}"]`,
      );
      const amount = form?.querySelector<HTMLInputElement>(
        'input[name="amount"]',
      );
      const seg = form?.querySelector<HTMLInputElement>('input[name="seg"]');

      const just = form?.querySelector<HTMLTextAreaElement>(
        'textarea[name="just"]',
      );

      if (amount) {
        if (parseInt(amount.value, 10) < 0) {
          addToast({
            type: 'error',
            title: 'Não é possível inserir uma quantidade inferior a zero (0).',
          });
          return;
        }
      }

      if (just) {
        if (just.value.replace(/\s/g, '').length < 10) {
          addToast({
            type: 'error',
            title: 'Quantidade mínima de caracteres válidos não atingida (10).',
          });
          return;
        }
        if (just.value.replace(/\s/g, '').length > 200) {
          addToast({
            type: 'error',
            title: 'Quantidade máxima de caracteres válidos excedida (200).',
          });
          return;
        }
      }

      if (just && amount && seg) {
        try {
          setLoading(true);

          const send = {
            matcod,
            just: convertSpecialChars(just.value),
            type: user.perfil,
            qtde: parseInt(amount.value, 10),
            seg: seg.value === 'S',
          };

          await api.get(`/sgo/estoque_alt.php?data=${JSON.stringify(send)}`);

          const index = estoque.findIndex(
            (item: EstoqueProps) => item.matcod === matcod,
          );

          estoque[index] = {
            ...estoque[index],
            qtdsld:
              seg.value === 'N'
                ? parseInt(amount.value, 10)
                : estoque[index].qtdsld,
            qtdseg:
              seg.value === 'S'
                ? parseInt(amount.value, 10)
                : estoque[index].qtdseg,
          };

          setEstoque((state: EstoqueProps[]) => [...state]);
          setEstoqueRaw((state: EstoqueProps[]) => [...state]);
          setLoading(false);
          setUpdateDiag({ open: false });
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [addToast, user.perfil, estoque, errorHandling],
  );

  const handleEstSegChange = useCallback(
    async (matcod) => {
      const form = document.querySelector<HTMLFormElement>(
        `form[id="form${matcod}"]`,
      );
      const amount = form?.querySelector<HTMLInputElement>(
        'input[name="amount"]',
      );
      const seg = form?.querySelector<HTMLInputElement>('input[name="seg"]');

      if (amount) {
        if (parseInt(amount.value, 10) < 0) {
          addToast({
            type: 'error',
            title: 'Não é possível inserir uma quantidade inferior a zero (0).',
          });
          return;
        }
      }

      if (amount && seg) {
        try {
          setLoading(true);

          const send = {
            matcod,
            type: user.perfil,
            qtde: parseInt(amount.value, 10),
            seg: seg.value === 'S',
          };

          await api.get(`/sgo/estoque_alt.php?data=${JSON.stringify(send)}`);

          const index = estoque.findIndex(
            (item: EstoqueProps) => item.matcod === matcod,
          );

          estoque[index] = {
            ...estoque[index],
            qtdsld:
              seg.value === 'N'
                ? parseInt(amount.value, 10)
                : estoque[index].qtdsld,
            qtdseg:
              seg.value === 'S'
                ? parseInt(amount.value, 10)
                : estoque[index].qtdseg,
          };

          setEstoque((state: EstoqueProps[]) => [...state]);
          setEstoqueRaw((state: EstoqueProps[]) => [...state]);
          setLoading(false);
          setUpdateDiag({ open: false });
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [addToast, user.perfil, estoque, errorHandling],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      setEstoque(
        estoqueRaw.filter(
          (item: EstoqueProps) =>
            removeAcento(item.matdesc)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()) ||
            removeAcento(item.matcod)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()),
        ),
      );
    },
    [estoqueRaw],
  );

  const handleResetFilter = useCallback(() => {
    getEstoque();
    if (user.perfil === 'ZON') {
      setComboNacs(
        nacs.filter(
          (item: ComboProps) =>
            item.value.substr(0, 2) === user.zoncod.substr(0, 2),
        ),
      );
    }

    setComboLocs(
      user.perfil === 'ZON'
        ? []
        : locs.filter((item: ComboLocalProps) => item.anb === user.anbc),
    );
  }, [getEstoque, user.perfil, user.zoncod, user.anbc, nacs, locs]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {(user.perfil === 'LOC' && coord.cod === user.loccod) ||
      (user.perfil === 'NAC' && coord.cod === user.anbc) ||
      (user.perfil === 'ZON' &&
        (coord.cod === user.zoncod ||
          comboNacs.findIndex((item) => item.value === coord.cod) > -1)) ? (
        <PrintButton
          icon={FaFileExcel}
          linkTo={`/sgo/xls_estoque.php${
            user.perfil === 'ZON'
              ? `?data=${JSON.stringify({ coord: coord.cod })}`
              : ''
          }`}
          hasProps={user.perfil === 'ZON'}
        />
      ) : null}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              <ResetFilterButton
                onClick={handleResetFilter}
                type="button"
                shouldAppear={
                  (user.perfil === 'ZON' && coord.cod !== user.zoncod) ||
                  (user.perfil === 'NAC' && coord.cod !== user.anbc)
                }
              >
                Remover seleção
              </ResetFilterButton>

              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={user.zoncod}
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial="Selecione"
                  />
                </span>
              )}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial="Selecione"
                  />
                </span>
              )}
            </div>
          </Form>
        }
      />
      <Header>Estoque {coord.desc}</Header>
      <FloatInput amount="100px">
        <input
          placeholder="Filtrar por descrição ou código"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>

      <AlteredContent pixels="280px">
        <Grid>
          {estoque.map((item: EstoqueProps) => (
            <GridItem key={item.matcod}>
              <h4>
                {item.matcod} - {deconvertSpecialChars(item.matdesc)}
              </h4>

              <span>
                <p>
                  Saldo: <strong>{item.qtdsld}</strong>
                  {!item.controlled ? (
                    <>
                      {(user.perfil === 'LOC' &&
                        coord.cod === user.loccod &&
                        ['MD', 'MV'].indexOf(item.matcod.substring(0, 2)) <
                          0) ||
                      (user.perfil === 'NAC' &&
                        coord.cod === user.anbc &&
                        ['MD', 'MV'].indexOf(item.matcod.substring(0, 2)) <
                          0) ||
                      (user.perfil === 'ZON' && coord.cod === user.zoncod) ? (
                        <FaPencilAlt
                          onClick={() =>
                            setUpdateDiag({
                              open: true,
                              content: '',
                              values: {
                                matcod: item.matcod,
                                matdesc: item.matdesc,
                                sld: item.qtdsld,
                                seg: 'N',
                              },
                            })
                          }
                        />
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                </p>
                {item.qtdseg !== 'L' &&
                  ['MD', 'MV'].indexOf(item.matcod.substring(0, 2)) < 0 && (
                    <>
                      <p>
                        Estoque de Segurança: <strong>{item.qtdseg}</strong>
                        {(user.perfil === 'LOC' && coord.cod === user.loccod) ||
                        (user.perfil === 'NAC' && coord.cod === user.anbc) ||
                        (user.perfil === 'ZON' && coord.cod === user.zoncod) ? (
                          <FaPencilAlt
                            onClick={() =>
                              setUpdateDiag({
                                open: true,
                                content: '',
                                values: {
                                  matcod: item.matcod,
                                  matdesc: item.matdesc,
                                  sld: item.qtdseg,
                                  seg: 'S',
                                },
                              })
                            }
                          />
                        ) : null}
                      </p>
                    </>
                  )}
              </span>

              <span>
                <p>
                  Última atualização: <strong>{item.manut}</strong>
                </p>
              </span>
            </GridItem>
          ))}
        </Grid>
        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <U.Title>
            <h2>
              Ajuste de Estoque
              {updateDiag.values?.seg === 'S' && ' de Segurança'}:{' '}
              {updateDiag.values?.matcod}
            </h2>
          </U.Title>
          <U.Content>
            <UpdateContainer>
              <h4>{updateDiag.values?.matdesc}</h4>
              <form
                id={`form${updateDiag.values?.matcod}`}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="hidden"
                  value={updateDiag.values?.seg || 'N'}
                  name="seg"
                />
                <QtdeContainer>
                  <p>
                    Informe a quantidade{' '}
                    {updateDiag.values?.seg === 'S'
                      ? 'para a segurança'
                      : 'presente em seu estoque'}
                    :
                  </p>
                  <InputUpdateContainer>
                    <input
                      name="amount"
                      type="number"
                      min={0}
                      defaultValue={updateDiag.values?.sld || 0}
                      maxLength={10}
                    />
                  </InputUpdateContainer>
                </QtdeContainer>
                {updateDiag.values?.seg !== 'S' && (
                  <JustContainer>
                    <p>
                      Informe o <strong>MOTIVO</strong> desta alteração:
                    </p>
                    <TextareaUpdateContainer>
                      <textarea
                        name="just"
                        cols={10}
                        rows={2}
                        maxLength={200}
                      />
                    </TextareaUpdateContainer>
                  </JustContainer>
                )}
              </form>
            </UpdateContainer>
          </U.Content>
          <U.Actions>
            <U.Cancel
              type="button"
              onClick={() => setUpdateDiag({ open: false })}
            >
              Cancelar
            </U.Cancel>
            <U.Confirm
              type="button"
              onClick={() =>
                updateDiag.values?.seg === 'S'
                  ? handleEstSegChange(updateDiag.values?.matcod)
                  : handleEstChange(updateDiag.values?.matcod)
              }
            >
              Confirmar
            </U.Confirm>
          </U.Actions>
        </U.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Estoque;
