import styled, { css } from 'styled-components';

import { FaArrowCircleRight } from 'react-icons/fa';

interface GridItemProps {
  paint: string;
}

export const Grid = styled.div`
  margin: 35px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
  padding: 0 15px;

  @media screen and (max-width: 1500px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  position: relative;

  padding-bottom: 10px;

  border: 3px solid #332e2e;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    > figure {
      transform: translateY(-40%) scale(1.1);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }

  ${(props) =>
    props.paint === 'R'
      ? css`
          /* background: #f3fff0; */
          border-color: #00750e;
        `
      : props.paint === 'N'
      ? css`
          /* background: #fff0f0; */
          border-color: #940000;
        `
      : css`
          /* background: #fffdf0; */
          border-color: #947e00;
        `}/* @media screen and (max-width: 600px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  } */
`;

export const GridItemAvatar = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #332e2e;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  transform: translateY(-35%);

  transition: all 0.5s;

  img {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const GridItemText = styled.span`
  padding: 0 10px;
  margin-top: -25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  strong,
  > p {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  > span {
    margin: 10px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #565656;
    }

    p {
      color: #332e2e;
      font-size: 16px;
      font-weight: 500;
    }

    svg {
      height: 20px;
      width: 20px;
      margin: 0 5px;
    }
  }
`;

export const Origin = styled(FaArrowCircleRight)`
  color: #be5555;
  transform: rotate(-45deg);
`;
export const Destiny = styled(FaArrowCircleRight)`
  color: #558bbe;
  transform: rotate(45deg);
`;

export const GridItemAwait = styled.span`
  position: absolute;
  left: -7.5px;
  top: -20px;

  width: 30px;
  height: 30px;
  background: #fce205;
  border: 2px solid #fce205;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  svg {
    color: #000;
    height: 25px;
    width: 25px;
  }
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    & + p {
      margin: 10px 0;
    }
  }
`;
