import React from 'react';

import { useAuth } from 'hooks/auth';

import Header from 'components/Header';
import Footer from 'components/Footer';
import NavbarContainer from 'components/NavbarContainer';

import { content } from './content';

import { Container, Content, ImageSection, TextSection } from './styles';

const fil = `${process.env.REACT_APP_ASSETS_DIR}/home/FreiIgnacio.png`;

interface LocalStorageProps {
  slidercontent?: string;
}

const Home: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Header web acesso={!user} />

      <NavbarContainer isHome />
      <Content>
        <TextSection>
          <div>
            <h3>{content.title}</h3>
            <p>{content.p1}</p>
            <p>{content.p2}</p>
            <p>{content.p3}</p>
            <p>{content.p4}</p>
            <p>{content.p5}</p>
            <p>{content.p6}</p>
          </div>
        </TextSection>
        <ImageSection>
          <img src={fil} alt="Frei Ignacio Larrañaga" />
        </ImageSection>
      </Content>
      <Footer />
    </Container>
  );
};

export default Home;
