import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import IncludeButton from 'components/IncludeButton';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import TextareaDialog from 'components/TextareaDialog';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import {
  AlteredHeader,
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';

import { useLocation } from 'react-router-dom';
import api from 'services/api';
import {
  FaCheck,
  FaExclamationTriangle,
  FaIdCard,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';

import * as O from 'styles/option_buttons';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { formatDate } from 'utils/formatDate';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import {
  Grid,
  GridItem,
  GridItemAvatar,
  GridItemText,
  GridItemAwait,
  Origin,
  Destiny,
  MessageContent,
} from './styles';

export interface ListProps {
  gtseq: string;
  hash: string;
  gseq: string;
  name: string;
  cert: string;
  avatar: string;
  anbo: string;
  anbodesc: string;
  loco: string;
  locodesc: string;
  anbd: string;
  anbddesc: string;
  locd: string;
  locddesc: string;
  dtsolic: string;
  aceite: string;
  obs: string;
  creator: string;
  dttransf: string;
}

export interface DeclineFormData {
  obs: string;
}

interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  loccod?: string;
  locdesc?: string;
  anbcod?: string;
  anbdesc?: string;
  cod?: string;
  desc?: string;
  filterBy?: string;
}

const TransfGuias: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { mud } = useChanges();
  const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [transf, setTransf] = useState<ListProps>({} as ListProps);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_GTRANSF}`,
    );

    return cod && desc
      ? { cod, desc }
      : user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_GTRANSF}`,
    );

    return anbcod || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_GTRANSF}`,
    );

    return loccod || 'Selecione';
  });

  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/transfguia_list.php');

      setRawList(response.data);

      setList(() => {
        const { cod, filterBy }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_GTRANSF}`,
        );

        return !filterBy || filterBy === 'zonal'
          ? response.data
          : filterBy === 'anb'
          ? response.data.filter(
              (item: ListProps) => item.anbd === cod || item.anbo === cod,
            )
          : response.data.filter(
              (item: ListProps) => item.locd === cod || item.loco === cod,
            );
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php`,
      // `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_GTRANSF}`,
    );

    if (user.perfil === 'NAC' || !!anbcod) {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anbcod || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    // setInitialNac(user.anbdesc);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);
    getComboANB();
    getComboLOC();
    getList();
  }, [getComboANB, getComboLOC, getList, handlePermission]);

  const handleANBselect = useCallback(() => {
    setInitialLoc('Selecione');
    setComboLocs([]);
    const anb = formRef.current?.getFieldValue('comboANB');

    const index = comboNacs.findIndex((item: ComboProps) => item.value === anb);

    setComboLocs(locs.filter((item) => item.anb === anb));
    setLocalStorage(`${process.env.REACT_APP_GTRANSF}`, {
      anbcod: anb,
      anbdesc: comboNacs[index].label,
      cod: anb,
      desc: comboNacs[index].label,
      filterBy: 'anb',
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_GTRANSF}`, [
      'loccod',
      'locdesc',
    ]);

    setCoord({
      cod: anb,
      desc: comboNacs[index].label,
    });
    setInitialNac(comboNacs[index].label);

    setList(() => [
      ...rawList.filter((item) => item.anbo === anb || item.anbd === anb),
    ]);
  }, [comboNacs, locs, rawList]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLocal');
    const index = comboLocs.findIndex((item: ComboProps) => item.value === loc);

    setLocalStorage(`${process.env.REACT_APP_GTRANSF}`, {
      loccod: loc,
      locdesc: comboLocs[index].label,
      cod: loc,
      desc: comboLocs[index].label,
      filterBy: 'local',
    });

    setCoord({
      cod: loc,
      desc: comboLocs[index].label,
    });
    setInitialLoc(comboLocs[index].label);

    setList(() => [
      ...rawList.filter((item) => item.loco === loc || item.locd === loc),
    ]);
  }, [comboLocs, rawList]);

  const removeFilter = useCallback(() => {
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    formRef.current?.setFieldValue('comboANB', '');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_GTRANSF}`, [
      'anbcod',
      'anbdesc',
      'loccod',
      'locdesc',
    ]);
    setComboLocs([]);

    setCoord({
      cod: user.zoncod,
      desc: user.zondesc,
    });

    setLocalStorage(`${process.env.REACT_APP_GTRANSF}`, {
      cod: user.zoncod,
      desc: user.zondesc,
      filterBy: 'zonal',
    });

    setList(() => [
      ...rawList.filter(
        (item) =>
          item.anbo.substr(0, 2) === user.zoncod.substr(0, 2) ||
          item.anbd.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    ]);
  }, [rawList, user.zoncod, user.zondesc]);

  const removeLoc = useCallback(() => {
    const { anbcod, anbdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_GTRANSF}`,
    );

    setCoord({
      cod: anbcod || user.anbc,
      desc: anbdesc || user.anbdesc,
    });

    setLocalStorage(`${process.env.REACT_APP_GTRANSF}`, {
      cod: anbcod || user.anbc,
      desc: anbdesc || user.anbdesc,
      filterBy: 'anb',
    });

    setInitialLoc('Selecione');
    formRef.current?.setFieldValue('comboLocal', '');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_GTRANSF}`, [
      'loccod',
      'locdesc',
    ]);

    setList(() => [
      ...rawList.filter(
        (item) =>
          item.anbo === (anbcod || user.anbc) ||
          item.anbd === (anbcod || user.anbc),
      ),
    ]);
  }, [rawList, user.anbc, user.anbdesc]);

  const handlePreAccept = useCallback((item: ListProps) => {
    setInc((state) => ({ ...state, open: !state.open }));
    setTransf({ ...item });
  }, []);

  const handleAccept = useCallback(async () => {
    try {
      setInc((state) => ({ ...state, open: !state.open }));
      setLoading(true);
      const send = new FormData();

      send.append(
        'data',
        JSON.stringify({
          loco: transf.loco,
          locodesc: transf.locodesc,
          locd: transf.locd,
          locddesc: transf.locddesc,
          anbo: transf.anbo,
          anbodesc: transf.anbodesc,
          anbd: transf.anbd,
          anbddesc: transf.anbddesc,
          name: transf.name,
          gseq: transf.gseq,
          hash: transf.hash,
          transaction: 'accept',
        }),
      );

      await api.post('/sgo/transfguia_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'info',
        title: 'Sucesso',
        description:
          'A transferência foi aceita. Um email foi enviado para as coordenações envolvidas.',
      });
      setLoading(false);
      getList();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    getList,
    transf.anbd,
    transf.anbddesc,
    transf.anbo,
    transf.anbodesc,
    transf.gseq,
    transf.hash,
    transf.locd,
    transf.locddesc,
    transf.loco,
    transf.locodesc,
    transf.name,
  ]);

  const handlePreDecline = useCallback((item: ListProps) => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
    setTransf({ ...item });
  }, []);

  const handleDecline = useCallback(
    async (data: DeclineFormData) => {
      try {
        setDeleteDiag((state) => ({ ...state, open: !state.open }));
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          obs: yup
            .string()
            .min(15, 'Quantidade mínima de caracteres não atingida (15).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            loco: transf.loco,
            locodesc: transf.locodesc,
            locd: transf.locd,
            locddesc: transf.locddesc,
            anbo: transf.anbo,
            anbodesc: transf.anbodesc,
            anbd: transf.anbd,
            anbddesc: transf.anbddesc,
            name: transf.name,
            gseq: transf.gseq,
            hash: transf.hash,
            transaction: 'decline',
          }),
        );

        await api.post('/sgo/transfguia_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'info',
          title: 'Sucesso',
          description:
            'A transferência foi recusada. Um email foi enviado para as coordenações envolvidas.',
        });

        setLoading(false);
        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      errorHandling,
      getList,
      transf.anbd,
      transf.anbddesc,
      transf.anbo,
      transf.anbodesc,
      transf.gseq,
      transf.hash,
      transf.locd,
      transf.locddesc,
      transf.loco,
      transf.locodesc,
      transf.name,
    ],
  );

  const filterRemove = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  const locFilterRemove = useSpring({
    opacity: initialLoc !== 'Selecione' ? 1 : 0,
    pointerEvents: initialLoc !== 'Selecione' ? 'all' : 'none',
  });

  const handleDownload = useCallback((ev) => {
    const { id } = ev.currentTarget.dataset;
    const link = document.querySelector<HTMLAnchorElement>(`a[id="xls${id}"]`);

    if (link) {
      setShow((state) => ({ ...state, open: !state.open }));
      link.click();

      const timer = setTimeout(() => {
        setShow((state) => ({ ...state, open: false }));
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      {['NAC', 'LOC'].indexOf(user.perfil) > -1 && !!mud && <IncludeButton />}
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                  {/* <Select
                    name="comboANB"
                    content={comboNacs}
                    onBlur={handleANBselect}
                    initial={initialNac}
                  /> */}
                  <RemoveButton
                    type="button"
                    style={filterRemove}
                    onClick={removeFilter}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              <span>
                <p>Filtre por Local:</p>
                {/* <Select
                  name="comboLocal"
                  content={comboLocs}
                  onBlur={handleLOCselect}
                  initial={initialLoc}
                /> */}
                <SelectV2
                  name="comboLocal"
                  content={comboLocs}
                  onChange={handleLOCselect}
                  initial={initialLoc}
                />
                <RemoveButton
                  type="button"
                  style={locFilterRemove}
                  onClick={removeLoc}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>Transferência de Guias</div>
          <div>{coord.desc}</div>
        </AlteredHeader>
        <Grid>
          {list.map((item) => (
            <GridItem key={item.hash} paint={item.aceite}>
              {item.aceite === 'P' && (
                <GridItemAwait>
                  <FaExclamationTriangle />
                </GridItemAwait>
              )}
              <GridItemAvatar>
                <img
                  src={`${process.env.REACT_APP_AVATAR}${item.avatar}`}
                  alt={item.name}
                />
              </GridItemAvatar>
              <GridItemText>
                <strong>{item.name}</strong>
                <p style={{ color: '#565656' }}>
                  Certificado:&nbsp;<strong>{item.cert}</strong>
                </p>
                <span>
                  <h4>
                    <Origin />
                    Origem
                  </h4>
                  <p>
                    {item.anbo === item.anbd &&
                    user.anbc === item.anbo &&
                    ['NAC', 'LOC'].indexOf(user.perfil) > -1
                      ? ''
                      : `${item.anbodesc} | `}
                    &nbsp;{item.locodesc}
                  </p>
                </span>
                <span>
                  <h4>
                    Destino
                    <Destiny />
                  </h4>
                  <p>
                    {item.anbo === item.anbd &&
                    user.anbc === item.anbo &&
                    ['NAC', 'LOC'].indexOf(user.perfil) > -1
                      ? ''
                      : `${item.anbddesc} | `}
                    &nbsp;{item.locddesc}
                  </p>
                </span>

                <span>
                  <h4>
                    Data&nbsp;
                    {item.aceite === 'R'
                      ? 'Transferência'
                      : item.aceite === 'N'
                      ? 'Recusa'
                      : 'Solicitação'}
                  </h4>
                  <p>
                    {formatDate(
                      item.aceite === 'P' ? item.dtsolic : item.dttransf,
                    )}
                  </p>
                </span>
              </GridItemText>
              <div>
                <O.GridOptions>
                  <O.ShowLink
                    to={{
                      pathname: `${pathname}/${item.hash}`,
                      state: { ...item },
                    }}
                  >
                    <FaSearch />
                  </O.ShowLink>
                  <O.Show
                    background="#008097"
                    onClick={handleDownload}
                    data-id={item.gseq}
                  >
                    <FaIdCard />
                  </O.Show>
                  {((user.perfil === 'NAC' && item.anbd === user.anbc) ||
                    (user.perfil === 'LOC' && item.locd === user.loccod)) &&
                    item.aceite === 'P' &&
                    !!mud && (
                      <>
                        <O.Show
                          background="#004d1a"
                          onClick={() => handlePreAccept(item)}
                        >
                          <FaCheck />
                        </O.Show>
                        <O.Show
                          background="#c53030"
                          onClick={() => handlePreDecline(item)}
                        >
                          <FaTimes />
                        </O.Show>
                      </>
                    )}
                </O.GridOptions>
                <a
                  style={{ display: 'none' }}
                  id={`xls${item.gseq}`}
                  href={`${
                    process.env.REACT_APP_API
                  }/sgo/xls_fig.php?data=${JSON.stringify({
                    token: localStorage.getItem(
                      `${process.env.REACT_APP_TOKEN}`,
                    ),
                    gseq: item.gseq,
                  })}`}
                  rel="noreferrer noopener"
                  target="_top"
                >
                  FIG
                </a>
              </div>
            </GridItem>
          ))}
        </Grid>
        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <I.Title>
            <h2>{inc.title || '*** ATENÇÃO ***'}</h2>
          </I.Title>
          <I.Content>
            <MessageContent>
              <p>
                Esse processo irá transferir de forma permanente o guia&nbsp;
                <strong>{transf.name}</strong>&nbsp;para&nbsp;
                {user.perfil === 'LOC' ? (
                  'sua coordenação'
                ) : (
                  <strong>{transf.locddesc}</strong>
                )}
                .
              </p>
              <p>
                Se estiver seguro de sua decisão, clique em confirmar para
                continuar.
              </p>
            </MessageContent>
          </I.Content>
          <I.Actions>
            <I.Cancel type="button" onClick={() => handlePreAccept(transf)}>
              Cancelar
            </I.Cancel>
            <I.Confirm type="button" onClick={handleAccept}>
              Confirmar
            </I.Confirm>
          </I.Actions>
        </I.Container>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <Form
            ref={formRef}
            onSubmit={handleDecline}
            style={{ margin: '0', padding: '0' }}
          >
            <D.Title>
              <h2>*** ATENÇÃO ***</h2>
            </D.Title>
            <D.Content>
              <MessageContent>
                <p>A transferência será recusada.</p>
                <p>
                  Justifique abaixo a sua decisão e clique em confirmar para
                  continuar.
                </p>
                <span>
                  <TextareaDialog
                    name="obs"
                    placeholder="Justificativa (obrigatório)"
                  />
                </span>
              </MessageContent>
            </D.Content>
            <D.Actions>
              <D.Cancel type="button" onClick={() => handlePreDecline(transf)}>
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default TransfGuias;
