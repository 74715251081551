import styled, { css, keyframes } from 'styled-components';

import Tooltip from '../Tooltip';

interface LabelProps {
  isChecked: boolean;
  isDisabled: boolean;
}

const appear = keyframes`
0% {
  transform:  rotateY(180deg) scale(1);
  }
80% {
  transform:  rotateY(0deg) scale(1.5);
}
100% {
  transform:  rotateY(0deg) scale(1);
  }
`;

export const Container = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
`;

export const Title = styled.h4`
  font-size: 16px;
`;

export const Label = styled.label<LabelProps>`
  background: #fff;
  border: 2px solid #332e2e;
  margin-left: 10px;

  border-radius: 3px;

  height: 20px;
  width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.35s ease;

  cursor: pointer;

  svg {
    color: #00cc44;
    opacity: 0;
    transition: all 0.5s ease;
  }

  input {
    display: none;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: #efefef;
  }

  ${(props) =>
    props.isChecked &&
    css`
      svg {
        opacity: 1;
        animation: ${appear} 1s forwards;
      }
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      background: #efefef;
      border: 2px solid #c6c6c6;
      cursor: not-allowed;
      pointer-events: none;

      svg {
        ${props.isChecked &&
        css`
          opacity: 0.5;
        `}
      }
    `}
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    color: #fff;
  }
`;
