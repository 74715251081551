import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { et64, df64 } from 'utils/encoding';
import api from '../services/api';

import { useToast } from './toast';
import { useChanges } from './changes';
import localStorageItems from './localStorageItems';

export interface User {
  avatar: string;
  gseq: string;
  name: string;
  gcert: string;
  perfil: string;
  perfildesc: string;
  perfalt: string;
  zoncod: string;
  zondesc: string;
  anbc: string;
  anbdesc: string;
  loccod: string;
  locdesc: string;
  tgcod: string;
  tgdesc: string;
  // password: boolean;
  status: boolean;
  mudasenha: boolean;
  mat: {
    enabled: boolean;
    content: {
      text: string;
      route: string;
    }[];
  };
  tes: {
    enabled: boolean;
    content: {
      text: string;
      route: string;
      uuid?: string;
    }[];
  };
  sec: {
    enabled: boolean;
    content: {
      text: string;
      route: string;
    }[];
  };
  ger: {
    enabled: boolean;
    content: {
      text: string;
      route: string;
    }[];
  };
  adm: boolean;
  // as?: {
  //   gseq: number;
  //   name: string;
  // };
}

interface SignInCredentials {
  certificado: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): void;
  signOut(): void;
  updateUser(user: User): void;
  updateADM(user: User, token: string): void;
}

interface AuthState {
  token: string;
  user: User;
}

interface StateProps {
  from: {
    pathname: string;
  };
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { getInfo } = useChanges();
  const { addToast } = useToast();
  const { state } = useLocation<StateProps>();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);
    const user = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      // return { token, user: JSON.parse(user) };
      return { token, user: df64(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ certificado, password }) => {
      const cert = certificado.match(/[A-Z]/i)
        ? certificado
        : certificado.padStart(6, '0');
      const pass = password;
      // const pass = password.replace('#', 'HASHTAG');
      const mode = certificado.match(/[A-Z]/i) ? 'alt' : 'guia';

      // const send = new FormData();
      // send.append('cert', cert);
      // send.append('pass', pass);
      // send.append('mode', mode);

      // const send = btoa(
      //   JSON.stringify({
      //     cert,
      //     pass,
      //     mode,
      //   }),
      // );

      const send = et64({
        cert,
        pass,
        mode,
      });

      // const response = await api.post(`/common/login.php`, send, {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // });
      const response = await api.post(`/common/login.php`, send);
      // df64(response.data);
      // return;

      const { user, token, status, message } = df64(response.data);

      if (status) {
        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description: message,
        });

        return;
      }

      if (user && token) {
        localStorage.setItem(`${process.env.REACT_APP_TOKEN}`, token);
        // localStorage.setItem(
        //   `${process.env.REACT_APP_USER}`,
        //   JSON.stringify(user),
        // );
        localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });

        getInfo();
        if (user.mudasenha) {
          history.replace('/primeiro_acesso');
          return;
        }

        if (state) {
          history.replace(state.from.pathname);
          return;
        }

        // history.replace('/dashboard');
        history.replace('/sgo');
      }
    },
    [addToast, state, getInfo, history],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);
    localStorage.removeItem(`${process.env.REACT_APP_USER}`);
    localStorageItems.forEach((element) => {
      localStorage.removeItem(element);
    });
    // localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);
    // localStorage.removeItem(`${process.env.REACT_APP_USER}`);
    // localStorage.removeItem(`${process.env.REACT_APP_SERVICE}`);
    // localStorage.removeItem(`${process.env.REACT_APP_SOLIC}`);
    // localStorage.removeItem(`${process.env.REACT_APP_RM}`);
    // localStorage.removeItem(`${process.env.REACT_APP_CE}`);

    api.defaults.headers.authorization = '';

    setData({} as AuthState);

    // history.replace(`${process.env.REACT_APP_DEFAULT_DIR}`);
    history.replace('/signin');
  }, [history]);

  const updateADM = useCallback(
    (user: User, token: string) => {
      // localStorage.setItem(
      //   `${process.env.REACT_APP_USER}`,
      //   JSON.stringify(user),
      // );
      localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));

      localStorage.setItem(`${process.env.REACT_APP_TOKEN}`, token);

      api.defaults.headers.authorization = `Bearer ${token}`;
      setData({
        token,
        user,
      });
      getInfo();
    },
    [setData, getInfo],
  );

  const updateUser = useCallback(
    (user: User) => {
      // localStorage.setItem(
      //   `${process.env.REACT_APP_USER}`,
      //   JSON.stringify(user),
      // );
      localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        updateADM,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
