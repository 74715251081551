import React, { useState, useCallback, useEffect } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';
import { useLocation, useHistory } from 'react-router-dom';

import { useCredentials } from 'hooks/credentials';
import { useChanges } from 'hooks/changes';

import { monthList } from 'pages/sgo/Tesouraria/monthList';
import api from 'services/api';
import { FIGuiaProps } from '../../main';
import { ListProps } from '../main';

import { List } from '../insert/styles';

type MergedProps = FIGuiaProps & ListProps;

interface AssistsProps {
  value: number;
  desc: string;
  assist: boolean;
}

const FIGAssistUpdate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<MergedProps>();
  const history = useHistory();
  const [prev] = useState(() => ({ ...location.state }));
  const { errorHandling } = useCredentials();
  const { currDate } = useChanges();

  const [disableMode, setDisableMode] = useState('all');

  const [assistErr, setAssistErr] = useState(false);
  const [assists, setAssists] = useState<AssistsProps[]>(() => [
    ...monthList.map((item) => {
      const mes = item.value;
      let assist = false;

      switch (mes) {
        case 1:
          assist = prev.january;
          break;
        case 2:
          assist = prev.february;
          break;
        case 3:
          assist = prev.march;
          break;
        case 4:
          assist = prev.april;
          break;
        case 5:
          assist = prev.may;
          break;
        case 6:
          assist = prev.june;
          break;
        case 7:
          assist = prev.july;
          break;
        case 8:
          assist = prev.august;
          break;
        case 9:
          assist = prev.september;
          break;
        case 10:
          assist = prev.october;
          break;
        case 11:
          assist = prev.november;
          break;
        case 12:
          assist = prev.december;
          break;
        default:
          assist = false;
      }
      return {
        value: item.value,
        desc: item.label,
        assist,
      };
    }),
  ]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    if (typeof currDate !== 'undefined') {
      const selectedYear = prev.year;
      const currSysYear = currDate.year;
      const currSysSem = currDate.month < 7 ? 1 : 2;

      if (
        (selectedYear === currSysYear && currSysSem === 1) ||
        selectedYear > currSysYear
      ) {
        setDisableMode('all');
      } else if (selectedYear === currSysYear && currSysSem === 2) {
        setDisableMode('half');
      } else if (selectedYear < currSysYear) {
        setDisableMode('none');
      }
    }
  }, [history, location.state, currDate, prev.year]);

  const handleCheckbox = useCallback(
    (id: string) => {
      const index = assists.findIndex(
        (item) => item.desc.substr(0, 3).toLowerCase() === id,
      );

      assists[index].assist = !assists[index].assist;

      setAssists((state) => [...state]);
    },
    [assists],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      setAssistErr(false);
      if (
        assists.reduce((accum, { assist }: AssistsProps) => {
          return assist ? accum + 1 : accum;
        }, 0) === 0
      ) {
        setAssistErr(true);
        setLoading(false);
        return;
      }

      const send = new FormData();

      send.append('data', JSON.stringify({ ...prev, assists, mode: 'update' }));

      await api.post('/sgo/fig_assist.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      history.goBack();
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [assists, history, prev, errorHandling]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(prev).length > 0 ? prev.name : ''}
      />
      <Content>
        <AlteredHeader>Alteração de Assistência</AlteredHeader>
        <List err={assistErr}>
          <div>
            <h4>
              {prev.desc} - {prev.year}
            </h4>
            <div>
              {assists.map((item, index) => (
                <span>
                  <p>{item.desc.substr(0, 3)}</p>
                  <Checkbox
                    containerStyle={{ display: 'flex' }}
                    titleStyle={{ display: 'none' }}
                    labelStyle={{ margin: '0px auto' }}
                    id={item.desc.substr(0, 3).toLowerCase()}
                    title=""
                    checked={assists[index].assist}
                    onClick={() =>
                      handleCheckbox(item.desc.substr(0, 3).toLowerCase())
                    }
                    isDisabled={
                      disableMode === 'all'
                        ? true
                        : disableMode === 'half'
                        ? item.value > 6
                        : false
                    }
                  />
                </span>
              ))}
            </div>
          </div>
        </List>
        <Button bgcolor="#00802b" onClick={handleSubmit}>
          Finalizar
        </Button>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FIGAssistUpdate;
