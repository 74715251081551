import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 10px;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    > p {
      font-size: 16px;
      font-weight: bold;
      color: #8a0002;
    }
  }
`;

export const InfoContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  padding: 5px 10px;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    font-size: 16px;
    > p {
      font-weight: bold;
      color: #767676;
    }

    strong {
      color: #332e2e;
    }

    & + span {
      margin-top: 10px;
    }
  }
`;
