import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

interface ItemProps {
  paint: number;
  checked: boolean;
}

export const ListContainer = styled.div`
  background: #fff;
  border: 2px solid #efefef;
  padding: 5px;

  border-radius: 5px;

  margin: 25px 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 350px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  height: calc(100vh - 350px);

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ListItem = styled.span<ItemProps>`
  padding: 10px 5px;
  position: relative;
  cursor: pointer;

  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};

  border: 2px solid
    ${(props) =>
      props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};

  border-radius: 5px;
  & + span {
    margin: 5px 0 0 0;
  }

  transition: background-color 0.35s ease, border-color 0.35s ease;

  &:hover {
    background: #a4ccb1;

    border-color: #a4ccb1;
  }

  ${(props) =>
    props.checked &&
    css`
      background: #a4ccb1;
      border-color: #a4ccb1;

      &:hover {
        background: ${shade(0.2, '#a4ccb1')};
        border-color: ${shade(0.2, '#a4ccb1')};
      }
    `}
`;

export const ListItemInfo = styled.span`
  font-size: 16px;
  color: #767676;
  strong {
    color: #332e2e;
  }
`;

export const FloatCheckbox = styled.span`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Button = styled(animated.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00802b;
  color: #efefef;
  padding: 10px 20px;
  width: 250px;
  border: 2px solid transparent;
  border-radius: 5px;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;

  transition: background-color 0.35s ease, color 0.35s ease;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${shade(0.2, '#00802b')};
    color: #fff;
  }
`;
