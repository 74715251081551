import styled from 'styled-components';
import { lighten } from 'polished';

export const Table = styled.table`
  margin: 20px auto 50px;
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;

  /* font-size: 16px; */
  /* width: 1366px; */

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    padding: 2px 3px;
  }
`;

export const TDcod = styled.td`
  text-align: left;
  font-size: 16px;
  width: 60px;

  transition: all 0.35s;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
export const TDtype = styled.td`
  text-align: left;
  font-size: 16px;
  width: 170px;

  transition: all 0.35s;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
export const TDLoc = styled.td`
  text-align: center;
  font-size: 16px;
  width: 100px;

  transition: all 0.35s;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    width: 0px;
    height: 0px;
    display: flex;
    overflow: hidden;
  }
`;
export const TDlserv = styled.td`
  text-align: left;
  font-size: 16px;
  width: 400px;

  transition: all 0.35s;
  @media screen and (max-width: 920px) {
    font-size: 14px;
  }

  @media screen and (max-width: 720px) {
    width: 0px;
    height: 0px;
    display: flex;
    overflow: hidden;
  }
`;
export const TDappl = styled.td`
  text-align: left;
  font-size: 16px;
  width: 135px;

  transition: all 0.35s;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (max-width: 390px) {
    width: 90px;
  }
`;
export const TDoffer = styled.td`
  text-align: right;
  font-size: 16px;
  min-width: 90px;
  width: 90px;

  transition: all 0.35s;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
export const TDshow = styled.td`
  text-align: center;
  font-size: 16px;
  width: 75px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 0;
    background: transparent;

    svg {
      cursor: pointer;
      color: ${lighten(0.3, '#332e2e')};
      transition: color 0.35s;
    }

    &:hover {
      svg {
        color: #332e2e;
      }
    }
  }

  transition: all 0.35s;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
