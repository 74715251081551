import styled, { css } from 'styled-components';

interface ScrollContainerProps {
  active?: boolean;
}

export const Container = styled.div<ScrollContainerProps>`
  cursor: pointer;
  z-index: 52;
  position: fixed;
  bottom: 25px;
  right: 0px;
  height: 40px;
  width: 0px;
  display: flex;
  align-items: center;
  padding-left: 5px;

  border: 2px solid #c53030;
  border-radius: 5px 0 0 5px;
  background: #c53030;
  overflow: hidden;

  box-shadow: 0px 0px 0px 0px #8a0002;

  transform: translateX(15px);

  transition: all 0.5s ease;

  svg {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #efefef;
    transition: all 0.25s ease;
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0);
  }

  ${(props) =>
    props.active &&
    css`
      width: 55px;
      transform: translateX(10px);
      box-shadow: 0px 5px 10px 2px #8a0002;
      svg {
      }
    `}

  &:hover {
    svg {
      color: #fff;
      box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.75);
      filter: brightness(120%);
    }
  }
`;
