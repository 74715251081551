import styled, { keyframes } from 'styled-components';
import Tooltip from 'components/Tooltip';

import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import { shade } from 'polished';
import { animated } from 'react-spring';

interface FinItemProps {
  paint: number;
}

const rotatePhone = keyframes`
  0%{
    transform: rotateZ(0deg);
  } 35% {
    transform: rotateZ(90deg);
  } 50% {
    transform: rotateZ(90deg);
  } 65% {
    transform: rotateZ(90deg);
  } 100% {
    transform: rotateZ(0deg);
  }
`;
export const InfoTooltip = styled(Tooltip)`
  svg {
    height: 20px;
    width: 20px;
  }
  span {
    background: #767676;
    color: #fff;
    transform: translateX(12%);

    &::before {
      left: -3%;

      transform: translateY(-150%);

      border-color: transparent #767676;
      border-width: 6px 6px 6px 0px;
    }
  }
`;

export const DataContainer = styled.div`
  margin-top: 15px;
  input {
    border: 0;
    background: transparent;
  }
`;
export const FinContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 1500px;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    width: 100vw;
  }
`;

export const Wrapper = styled.div`
  border: 2px solid #332e2e;
  border-radius: 5px;

  width: 90vw;
  max-width: 1500px;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 3px;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    border-radius: 0px;
  }
`;

export const FinContentHeader = styled.div`
  background: #332e2e;
  color: #fff;
  font-size: 15px;
`;

export const FinItem = styled.div<FinItemProps>`
  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};

  span {
    align-items: center;
    font-size: 15px;
  }
`;

export const RotateMessage = styled(animated.div)`
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;

  opacity: 0;
  margin: 0;
  height: 0;
  overflow: hidden;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  span {
    p {
      font-size: 13px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      text-align: center;
    }
    svg {
      height: 20px;
      width: 20px;
      animation: ${rotatePhone} 3s infinite;
    }

    & + span {
      margin-left: 10px;
    }
  }

  transition: opacity 0.35s ease, margin 0.35s ease, height 0.35s ease;

  @media screen and (max-width: 640px) {
    opacity: 1;
    margin: 20px 0 0 0;
    height: 100%;
  }
`;

export const FinContentTotal = styled.div`
  background: #332e2e;
  color: #fff;
`;

export const Desc = styled.span`
  width: 800px;

  justify-content: flex-start;

  transition: width 0.35s ease;

  svg {
    color: #767676;
    margin: 0px 0px 0px 5px;
  }
  @media screen and (max-width: 1200px) {
    width: 500px;
  }
  @media screen and (max-width: 930px) {
    width: 300px;
  }

  @media screen and (max-width: 550px) {
    width: 200px;
  }
  @media screen and (max-width: 400px) {
    width: 100px;
  }
`;

export const Qtde = styled.span`
  width: 100px;
  justify-content: center;
  transition: width 0.35s ease;
  @media screen and (max-width: 930px) {
    width: 50px;
  }

  @media screen and (max-width: 610px) {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
`;
export const Valor = styled.span`
  width: 150px;
  padding-right: 3px;
  justify-content: flex-end;
  transition: width 0.35s ease;
  @media screen and (max-width: 930px) {
    width: 70px;
  }
`;

export const Changeable = styled.span`
  width: 150px;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin: 0 3px;
    background: transparent;
  }
  svg {
    cursor: pointer;

    height: 23px;
    width: 23px;
  }
  input {
    font-size: 15px;
    border: 2px solid #332e2e;
    background: transparent;
    border-radius: 3px;
    text-align: center;
    width: 40px;
    font-weight: bold;
  }

  transition: width 0.35s ease;

  @media screen and (max-width: 400px) {
    width: 130px;
  }
`;

export const Pago = styled(Changeable)`
  input {
    border-color: ${shade(0.2, '#b3e0ff')}; /* 007acc 85% */
    background: #b3e0ff;
    color: blue;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Devolvido = styled(Changeable)`
  input {
    border-color: ${shade(0.2, '#f0c1c1')}; /* c53030 85% */
    background: #f0c1c1;
    color: #8a0002;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Less = styled(FaMinusSquare)`
  color: #c53030;
`;

export const More = styled(FaPlusSquare)`
  color: #007acc;
`;

export const Pendente = styled.span`
  width: 150px;
  justify-content: center;
  transition: width 0.35s ease;
  @media screen and (max-width: 930px) {
    width: 50px;
  }
`;
