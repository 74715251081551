import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const CoordContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 90vw;
  max-width: 1300px;

  padding: 10px 0;
`;

export const InfoWrapper = styled.section`
  margin-top: 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > div {
    font-size: 14px;
    width: 90vw;
    max-width: 500px;
  }

  animation: ${appearFromRight} 1s ease forwards;
`;

export const CoordHeader = styled.div`
  animation: ${appearFromLeft} 1s ease forwards;

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(182, 182, 182, 0.25),
    rgba(182, 182, 182, 0.5),
    rgba(166, 166, 166, 1),
    rgba(166, 166, 166, 1),
    rgba(166, 166, 166, 1),
    rgba(166, 166, 166, 1),
    rgba(166, 166, 166, 1),
    rgba(182, 182, 182, 0.5),
    rgba(182, 182, 182, 0.25),
    rgba(255, 255, 255, 0)
  );

  h1 {
    text-align: center;
    color: #332e2e;
    color: #fff;
    text-shadow: 2px 2px 10px #332e2e;
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    text-shadow: 2px 2px 10px #332e2e;
  }
`;

export const CoordContacts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #fff;
  padding: 10px 20px;

  h3 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
  }

  div {
    display: flex;

    span {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        color: #767676;
        transition: color 0.35s;
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #332e2e;

        transition: color 0.35s;
        &:hover {
          svg {
            color: #0071dc;
          }
          color: #0071dc;
        }
      }
      & + span {
        margin: 0 0 0 15px;
      }
    }

    & + div {
      margin: 10px;
    }
  }

  @media screen and (max-width: 460px) {
    div {
      flex-direction: column;
      align-items: center;
      span + span {
        margin: 2px 0 0 0;
      }

      & + div {
        margin: 2px 0 0 0;
      }
    }
  }
`;

export const CoordCommon = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;

  background: #fff;
  padding: 10px 20px;

  h3 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
  }

  span {
    display: flex;
    align-items: center;

    h4 {
      font-size: 14px;
      color: #969696;
    }

    p {
      font-size: 14px;
      margin: 0 0 0 3px;
    }

    & + span {
      margin: 7.5px 0 0 0;
    }

    @media screen and (max-width: 540px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
