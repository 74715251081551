import { lighten } from 'polished';
import styled from 'styled-components';

export const GridContainer = styled.div`
  margin: 10px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  width: 90vw;

  @media screen and (max-width: 1240px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 715px) {
    grid: auto / 1fr;
  }

  @media screen and (max-width: 450px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 2px solid #efefef;
  background: #fff;
  border-radius: 5px;

  > p {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    color: #8a0002;
    margin: 0 0 10px 0;
  }

  span {
    p {
      font-size: 15px;
      color: #767676;
      strong {
        font-size: 15px;
        color: #332e2e;
        display: flex;
      }

      a {
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        color: #332e2e;
        transition: color 0.35s ease;

        &:hover {
          color: #0071dc;
        }
      }
    }
    display: flex;
    /* flex-direction: column; */

    & + span {
      margin: 5px 0 0 0;
    }
  }

  @media screen and (max-width: 450px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const GridSVCItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 2px solid #efefef;
  background: #fff;
  border-radius: 5px;

  > p {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    color: #8a0002;
    margin: 0 0 10px 0;
  }

  span {
    display: flex;
    p {
      display: flex;
      font-size: 15px;
      color: #767676;
      strong {
        margin: 0 0 0 5px;
        font-size: 15px;
        color: #332e2e;
        display: flex;
      }

      a {
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        color: #332e2e;
        transition: color 0.35s ease;

        &:hover {
          color: #0071dc;
        }
      }
    }

    /* flex-direction: column; */

    & + span {
      margin: 5px 0 0 0;
    }
  }
`;

export const TelephoneContainer = styled.span`
  display: flex;
  /* flex-direction: column; */

  p + p {
    margin: 0 0 0 5px;
  }

  @media screen and (max-width: 815px) {
    flex-direction: column;

    p + p {
      margin: 5px 0 0 0;
    }
  }
`;

export const InfoContainer = styled.div`
  /* width: 90vw; */
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;
  border: 2px solid #efefef;
  background: #ffffff;
  border-radius: 5px;

  span {
    font-size: 15px;
    p {
      color: #767676;
      strong {
        color: #332e2e;
      }
    }
    & + span {
      margin: 0 0 0 10px;
    }
  }

  @media screen and (max-width: 860px) {
    width: 90vw;
    flex-direction: column;
    align-items: flex-start;

    span {
      & + span {
        margin: 3px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 450px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const ModalShowContent = styled.div`
  width: 100%;
  span {
    display: flex;
    align-items: center;

    p {
      font-size: 15px;
      display: flex;
      align-items: center;

      color: #767676;
      strong {
        font-size: 15px;
        margin-left: 5px;
        color: #332e2e;
      }

      a {
        display: flex;
        align-items: center;
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        color: #332e2e;
        transition: color 0.35s ease;
        margin-left: 5px;

        &:hover {
          color: #0071dc;
        }
      }

      & + p {
        margin: 0 0 0 10px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  @media screen and (max-width: 500px) {
    span {
      flex-direction: column;
      align-items: flex-start;

      p + p {
        margin: 5px 0 0 0;
      }
    }
  }
`;

export const PrintContainer = styled.div`
  margin: 10px auto;
  a {
    display: none;
  }
  button {
    svg {
      height: 20px;
      width: 20px;
      margin: 0 5px 0 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;

    background: #332e2e;
    color: #fff;

    font-size: 16px;
    font-family: 'Roboto Slab', serif;

    border: 2px solid #332e2e;
    border-radius: 5px;
    padding: 5px 10px;

    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: box-shadow 0.35s ease, background-color 0.35s ease;

    &:hover {
      background-color: ${lighten(0.1, '#332e2e')};
      box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
    }
  }
`;
