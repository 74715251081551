import styled from 'styled-components';

export const FormContent = styled.div`
  margin-top: 25px;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  p {
    color: #8a0002;
    font-size: 18.72px;
    font-weight: 500;
    font-family: 'Roboto Slab', serif;
  }

  > span {
    display: flex;
    flex-direction: column;
    position: relative;

    & + span {
      margin-top: 10px;
    }
  }
`;

export const CheckboxContainer = styled.span`
  p {
    color: #332e2e;
  }

  div {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
`;
