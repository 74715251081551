import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';

import api from 'services/api';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { deconvertSpecialChars } from 'utils/specialChars';
import { useToast } from 'hooks/toast';
import { FaFileExcel } from 'react-icons/fa';

import { ListProps } from '../main';

import {
  HeaderContent,
  TotalRM,
  RMContentContainer,
  RMContentCabec,
  RMContent,
  RMContentItem,
  Monetary,
  OBSContainer,
} from './styles';

interface StateProps {
  dm: ListProps;
  dmnum?: number;
  loccod?: string;
}

interface DMItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
  numbers: string[];
  required: boolean;
}

const DMConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  const [dmHeader, setDMHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }

    if (location.state.dmnum) {
      return {} as ListProps;
    }

    return location.state.dm;
  });

  const [dmItems, setDMitems] = useState<DMItemsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Selecione o documento',
    open: false,
    content: '',
  });

  const [OBS, setOBS] = useState<string>('');

  const getItems = useCallback(
    async (alt?: boolean) => {
      try {
        const fetch = {
          dmnum: alt ? location.state.dmnum : dmHeader.dmnum,
          loccod: alt ? location.state.loccod : dmHeader.loc,
          isShow: true,
        };

        const response = await api.get(
          `/sgo/dm_items.php?data=${JSON.stringify(fetch)}`,
        );

        setDMitems(response.data);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
        setLoading(false);
      }
    },
    [
      location.state.dmnum,
      location.state.loccod,
      dmHeader.dmnum,
      dmHeader.loc,
      errorHandling,
    ],
  );

  const getConsult = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/dm_list.php?data=${JSON.stringify({
          dmnum: location.state.dmnum,
          loccod: location.state.loccod,
        })}`,
      );

      setDMHeader(response.data.fetchlist[0]);
      getItems(true);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [errorHandling, getItems, location.state.loccod, location.state.dmnum]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }
    if (location.state.dm) {
      getItems();
      return;
    }

    getConsult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalDM = useMemo(() => {
    let total = 0;

    dmItems.forEach((item: DMItemsProps) => {
      total += item.qtd * item.value;
    });

    return total.toFixed(2).replace('.', ',');
  }, [dmItems]);

  const handleCancel = useCallback(
    async (values: DeleteProps['values']) => {
      setDeleteDiag({ open: false });
      if (values) {
        try {
          setLoading(true);

          if (OBS.trim().length < 5) {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description:
                'O campo Observação é obrigatório e deve conter ao menos 5 caracteres.',
            });
          }

          const send = new FormData();

          send.append(
            'data',
            JSON.stringify({
              ...values,
              obs: OBS,
              dmItems,
            }),
          );

          await api.post('/sgo/dm_delete.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: `Devolução de Material N° ${dmHeader.dmnum} cancelada.`,
          });
          setLoading(false);

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [
      OBS,
      addToast,
      dmHeader.dmnum,
      dmItems,
      errorHandling,
      history,
      location.pathname,
    ],
  );

  const handleDownload = useCallback(() => {
    const link = document.querySelector<HTMLAnchorElement>(`a[id=XLS]`);

    if (link) {
      setShow((state) => ({ ...state, open: true }));
      link.click();
    }
  }, []);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      let alpha = e.currentTarget.value;
      alpha = alpha
        .replace(/^\s/g, '')
        .replace(/\s\s+/g, ' ')
        .replace(/[[\]&$\\><`~'";:]/g, '');

      setOBS(alpha);
    },
    [],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`Consulta Devolução N° ${dmHeader.dmnum}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data da DM:&nbsp;
                    <strong>{formatDate(dmHeader.data)}</strong>
                  </p>
                </span>
                <span>
                  Coordenação:&nbsp;<strong>{dmHeader.locdesc}</strong>
                </span>
              </div>
            </HeaderContent>

            <TotalRM>
              <Button bgcolor="#332e2e" onClick={handleDownload}>
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total desta Devolução: <strong>R$ {totalDM}</strong>
              </p>
            </TotalRM>
            <RMContentContainer>
              <RMContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </RMContentCabec>
              <RMContent>
                {dmItems.map((item: DMItemsProps, index: number) => (
                  <RMContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </RMContentItem>
                ))}
              </RMContent>
            </RMContentContainer>

            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {dmHeader.obs &&
                    dmHeader.obs.map((item) => (
                      <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                    ))}
                </strong>
              </span>
            </OBSContainer>
            {user.perfil === 'NAC' && dmHeader.status === 'E' ? (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro da
                          devolução de material&nbsp;
                          <strong>N° {dmHeader.dmnum}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            placeholder="Observação (obrigatório)"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      dm: dmHeader.dmnum,
                      loccod: dmHeader.loc,
                      locdesc: dmHeader.locdesc,
                      anbdesc: user.anbdesc,
                      gname: user.name,
                    },
                  })
                }
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Cancelar essa DM
              </Button>
            ) : null}
          </>
        )}
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleCancel(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() =>
              setShow((state) => ({ ...state, open: !state.open }))
            }
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <a
        style={{ display: 'none' }}
        id="XLS"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_dm.php?data=${JSON.stringify({
          dm: dmHeader.dmnum || location.state?.dmnum,
          dest: dmHeader.loc,
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        DM
      </a>
      <SGOFooter />
    </Container>
  );
};

export default DMConsult;
