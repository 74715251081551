import styled from 'styled-components';
import { lighten, shade } from 'polished';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface DeleteProps {
  open: boolean;
  content?: string | JSX.Element;
  values?: {
    [key: string]: string | number;
  };
}

export const Container = styled(Dialog)``;

export const Content = styled(DialogContent)`
  background: #e6e6e6;

  display: flex;
  align-items: center;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: #8a0002;

    &:hover {
      background: #c53030;
    }
  }
`;

export const Title = styled(DialogTitle)`
  background: #c53030;

  color: #efefef;
  font-family: 'Roboto Slab', serif;
  h2,
  h4 {
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 24px;
  }
  h4 {
  }
`;

export const ContentText = styled(DialogContentText)``;

export const Actions = styled(DialogActions)`
  background: #e6e6e6;
  button {
    font-size: 16px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;

    font-family: 'Roboto Slab', serif;

    transition: background-color 0.35s ease, color 0.35s ease;
  }
`;

export const Cancel = styled.button`
  border: 2px solid #c53030;
  background: #c53030;
  color: #efefef;

  &:hover {
    background-color: ${shade(0.2, '#c53030')};
    color: #fff;
  }
`;

export const Confirm = styled.button`
  border: 2px solid #00802b;
  background: #00802b;
  color: #efefef;

  &:hover {
    background-color: ${shade(0.2, '#00802b')};
    color: #fff;
  }
`;

export const ModalDeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  h3 {
    font-size: 16px;
  }
  textarea {
    display: flex;
    background: #ffffff;
    border: 2px solid #c6c6c6;
    border-radius: 5px;
    resize: none;

    font-size: 15px;
    font-family: 'Roboto Slab', serif;
    padding: 10px 5px;

    width: 70vw;
  }

  > p {
    font-size: 17px;
    strong {
      font-size: 17px;
    }

    & + p {
      margin: 15px 0 0 0;
    }
  }

  > div {
    background-color: ${lighten(0.2, '#e6e6e6')};
    padding: 5px 10px;
    border: 2px solid ${shade(0.2, '#e6e6e6')};
    border-radius: 5px;
    margin: 5px;
    font-size: 15px;
    span {
      display: flex;
      p {
        font-size: 15px;
        color: #767676;
        strong {
          color: #332e2e;
        }
      }
    }
  }
`;
