import React, { useCallback, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaClipboardList, FaDollarSign } from 'react-icons/fa';
import * as yup from 'yup';
import { Container } from 'styles/sgo_wrappers';
import { AnimatedContent } from 'styles/animated';

import SGOHeader from 'components/SGOHeader';
import ScrollTop from 'components/ScrollTop';

import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';

import getValidationErrors from 'utils/getValidationErrors';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import api from 'services/api';
import { convertSpecialChars, deconvertSpecialChars } from 'utils/specialChars';

interface LocationProps {
  pathname: string;
  grudesc: string;
  matcod: string;
  matdesc: string;
  vlrgui: string;
  vlrloc: string;
  vlrnac: string;
  cntr: string;
  venda: string;
  sta: string;
  webdesc?: string;
}

interface FormData {
  grudesc: string;
  matcod: string;
  matdesc: string;
  vlrgui: string;
  vlrloc: string;
  vlrnac: string;
  cntr: string;
  venda: string;
  sta: string;
  webdesc?: string;
  controllable?: string;
  sellable?: string;
}

const MaterialUpdate: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { errorHandling } = useCredentials();
  const { user } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<LocationProps>();
  const {
    grudesc,
    matcod,
    matdesc,
    vlrgui,
    vlrloc,
    vlrnac,
    cntr,
    venda,
    sta,
    webdesc,
  } = location.state;

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schemaShape = {
          matcod: yup.string(),
          grudesc: yup.string(),
          matdesc: yup
            .string()
            .trim()
            .required('Descrição é obrigatória')
            .min(9, 'Quantidade mínima de caracteres não atingida (9)')
            .max(60, 'Quantidade máxima de caracteres excedida (60)'),
          vlrgui: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          vlrloc: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          vlrnac: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          sellable: yup.string().oneOf(['S', 'N'], 'Este campo é obrigatório'),
          controllable: yup
            .string()
            .oneOf(['S', 'N'], 'Este campo é obrigatório'),
          status: yup.string().oneOf(['A', 'I'], 'Este campo é obrigatório'),
          // webdesc: yup
          //   .string()
          //   .required('Descrição web é obrigatória')
          //   .min(30, 'Quantidade mínima de caracteres não atingida (30)')
          //   .max(650, 'Quantidade máxima de caracteres excedida (650)'),
        };

        const schema = yup.object().shape(schemaShape);

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          // grudesc: convertSpecialChars(data.grudesc),
          matdesc: convertSpecialChars(data.matdesc),
          webdesc: data.webdesc
            ? convertSpecialChars(data.webdesc)
            : data.webdesc,
        };

        if (!user.adm) {
          send.controllable = cntr;
          send.sellable = venda;
        }

        // console.table(send);
        // return;
        const response = await api.get(
          `/sgo/material_tov_update.php?matcod=${matcod}&data=${JSON.stringify(
            send,
          )}`,
        );

        const { message } = response.data;

        addToast({
          type: message ? 'info' : 'success',
          title: 'Sucesso!',
          description: message || 'Material alterado com sucesso!',
        });

        history.replace(`${location.pathname.replace('/update', '')}`);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      user.adm,
      matcod,
      addToast,
      history,
      location.pathname,
      venda,
      cntr,
      errorHandling,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração de Material" />
      <AnimatedContent direction="right" needmargin={30}>
        <Form
          ref={formRef}
          initialData={{
            matcod,
            grudesc,
            matdesc,
            vlrgui,
            vlrloc,
            vlrnac,
            controllable: cntr,
            sellable: venda,
            status: sta,
            webdesc: webdesc ? deconvertSpecialChars(webdesc) : webdesc,
          }}
          onSubmit={handleSubmit}
        >
          <p>Grupo de Material</p>
          <Input name="grudesc" disabled />

          <p>Descrição</p>
          <Textarea
            altHeight={85}
            icon={FaClipboardList}
            name="matdesc"
            placeholder="Descrição"
            disabled={!user.adm}
          />

          <p>Valor Nacional</p>
          <Input
            icon={FaDollarSign}
            name="vlrnac"
            placeholder="Valor Nacional"
            type="number"
            step=".01"
            min={0}
            // onChange={currencyMasking}
            // disabled={user.adm}
            mask="currency"
          />

          <p>Valor Local</p>
          <Input
            icon={FaDollarSign}
            name="vlrloc"
            placeholder="Valor Local"
            type="number"
            step=".01"
            min={0}
            // disabled={user.adm}
            mask="currency"
          />

          <p>Valor Guia</p>
          <Input
            icon={FaDollarSign}
            name="vlrgui"
            placeholder="Valor Guia"
            type="number"
            step=".01"
            min={0}
            // disabled={user.adm}
            mask="currency"
          />
          {user.adm && (
            <>
              <RadioContainer
                containerStyle={
                  !user.adm
                    ? {
                        visibility: 'hidden',
                        pointerEvents: 'none',
                        position: 'absolute',
                      }
                    : {}
                }
                title="Numeração Controlada?"
                name="controllable"
                selected={cntr}
                content={[
                  { id: 'cS', value: 'S', label: 'Sim' },
                  { id: 'cN', value: 'N', label: 'Não' },
                ]}
              />
            </>
          )}

          {user.adm && (
            <>
              <RadioContainer
                containerStyle={
                  !user.adm
                    ? {
                        visibility: 'hidden',
                        pointerEvents: 'none',
                        position: 'absolute',
                      }
                    : {}
                }
                title="Comercializável?"
                name="sellable"
                selected={venda}
                content={[
                  { id: 'sS', value: 'S', label: 'Sim' },
                  { id: 'sN', value: 'N', label: 'Não' },
                ]}
              />
            </>
          )}

          {(user.adm ||
            (user.perfil === 'NAC' && matcod.substr(0, 2) === 'DM')) && (
            <>
              <RadioContainer
                // containerStyle={
                //   !user.adm
                //     ? {
                //         visibility: 'hidden',
                //         pointerEvents: 'none',
                //         position: 'absolute',
                //       }
                //     : {}
                // }
                title="Status"
                name="status"
                selected={sta}
                content={[
                  { id: 'sA', value: 'A', label: 'Ativo' },
                  { id: 'sI', value: 'I', label: 'Inativo' },
                ]}
              />
            </>
          )}

          {matcod.substr(0, 2) !== 'DM' && (
            <>
              <p style={!user.adm ? { display: 'none' } : {}}>Descrição WEB</p>
              <Textarea
                icon={FaClipboardList}
                name="webdesc"
                containerStyle={
                  !user.adm
                    ? {
                        visibility: 'hidden',
                        pointerEvents: 'none',
                        position: 'absolute',
                      }
                    : {}
                }
              />
            </>
          )}

          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ marginBottom: '10px' }}
          >
            Alterar
          </Button>
        </Form>
      </AnimatedContent>
      <SGOFooter />
    </Container>
  );
};

export default MaterialUpdate;
