import React, { useEffect, useState } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import { useLocation, useHistory } from 'react-router-dom';

import {
  FaEnvelope,
  FaFacebookF,
  FaGlobe,
  FaMapMarkerAlt,
  FaPhone,
} from 'react-icons/fa';

import { CoordProps } from '../main';

import { Container, Content } from '../../../../../styles/sgo_wrappers';
import {
  CoordContainer,
  CoordHeader,
  CoordContacts,
  CoordCommon,
  InfoWrapper,
} from './styles';

import ScrollTop from '../../../../../components/ScrollTop';

interface StateProps {
  coord: CoordProps;
}

const NacionaisConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [coord, setCoord] = useState<CoordProps>({} as CoordProps);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
    } else {
      setCoord(location.state.coord);
    }
  }, [location.state, location.pathname, history]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks />
      <Content>
        <CoordContainer>
          <CoordHeader>
            <h1>{coord?.desc}</h1>
            <span>
              <FaMapMarkerAlt />
              <p>
                {coord?.escrit} - {coord.address?.uf}
              </p>
            </span>
          </CoordHeader>
          <InfoWrapper>
            <CoordContacts>
              <h3>Contatos</h3>
              <div>
                <span>
                  <FaPhone />
                  <p>{coord?.phone}</p>
                </span>
                <span>
                  <a
                    href={`mailto:${coord?.mail}?subject=Contato via SGO`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FaEnvelope /> <p>{coord?.mail}</p>
                  </a>
                </span>
              </div>
              {(coord.facebook || coord.web) && (
                <div>
                  {coord.facebook ? (
                    <span>
                      <FaFacebookF />
                      <p>{coord.facebook}</p>
                    </span>
                  ) : null}
                  {coord.web ? (
                    <span>
                      <FaGlobe />
                      <p>{coord.web}</p>
                    </span>
                  ) : null}
                </div>
              )}
            </CoordContacts>
            <CoordCommon>
              <h3>Guias com Função</h3>
              <span>
                <h4>Coordenação:</h4>
                <p>{coord.coor?.name}</p>
              </span>
              <span>
                <h4>Secretaria:</h4>
                <p>{coord.secr?.name}</p>
              </span>
              <span>
                <h4>Tesouraria:</h4>
                <p>{coord.teso?.name}</p>
              </span>
              <span>
                <h4>Enc. Materiais:</h4>
                <p>{coord.emat?.name}</p>
              </span>
              {coord.code && ['99', 'IN'].indexOf(coord.code.substr(-2)) < 0 && (
                <>
                  <span>
                    <h4>Enc. EPG:</h4>
                    <p>{coord.eesc?.name}</p>
                  </span>
                  <span>
                    <h4>Jovem:</h4>
                    <p>{coord.ejov?.name}</p>
                  </span>
                </>
              )}
            </CoordCommon>
            <CoordCommon>
              <h3>Informação Bancária</h3>
              <span>
                <h4>Banco:</h4>
                <p>{coord?.banco}</p>
              </span>
              <span>
                <h4>Agência | Conta:</h4>
                <p>
                  {coord?.agencia} | {coord?.conta}
                </p>
              </span>
              <span>
                <h4>CNPJ:</h4>
                <p>{coord?.cnpj}</p>
              </span>
            </CoordCommon>
            <CoordCommon>
              <h3>Endereço</h3>
              <span>
                <h4>Endereço:</h4>
                <p>{coord.address?.street}</p>
              </span>
              <span>
                <h4>Bairro:</h4>
                <p>{coord.address?.district}</p>
              </span>
              <span>
                <h4>Cidade | UF :</h4>
                <p>
                  {coord.address?.city} | {coord.address?.uf}
                </p>
              </span>
              <span>
                <h4>CEP:</h4>
                <p>{coord.address?.code}</p>
              </span>
            </CoordCommon>
            <CoordCommon>
              <h3>Endereço secundário</h3>
              <span>
                <h4>Endereço:</h4>
                <p>{coord.delivery?.street}</p>
              </span>
              <span>
                <h4>Bairro:</h4>
                <p>{coord.delivery?.district}</p>
              </span>
              <span>
                <h4>Cidade | UF :</h4>
                <p>
                  {coord.delivery?.city} | {coord.delivery?.uf}
                </p>
              </span>
              <span>
                <h4>CEP:</h4>
                <p>{coord.delivery?.code}</p>
              </span>
            </CoordCommon>
          </InfoWrapper>
        </CoordContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default NacionaisConsult;
