import styled, { keyframes } from 'styled-components';

interface ScrollContainerProps {
  amountScrolled: number;
}

interface DContentProps {
  quantity: number;
}

const appearFromRight = keyframes`
  from {
    width: 0px;
    box-shadow: 0px 0px 0px 0px #262626;
    transform: translateX(15px);
    pointer-events: none;
  } to {
    width: 55px;
    box-shadow: 0px 5px 10px 2px #262626;
    transform: translateX(10px);
    pointer-events: all;
  }
`;

export const Container = styled.div<ScrollContainerProps>`
  cursor: pointer;

  z-index: 5;
  position: fixed;
  top: calc(281px - ${(props) => props.amountScrolled}px);
  right: 0px;
  height: 40px;

  display: flex;
  align-items: center;
  padding-left: 5px;

  border: 2px solid #464646;
  border-radius: 5px 0 0 5px;
  background: #464646;
  overflow: hidden;

  pointer-events: none;
  width: 0px;

  box-shadow: 0px 0px 0px 0px #262626;
  transform: translateX(15px);

  transition: transform 0.5s ease, box-shadow 0.5s ease, width 0.5s ease;

  animation: ${appearFromRight} 0.5s ease forwards;
  a {
    background: transparent;
    border: 0;
    height: 100%;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #efefef;
  }

  p {
    margin-left: 10px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    /* width: 100%; */
    height: 100%;
    background: transparent;
  }

  svg {
    /* border-radius: 50%; */
    height: 30px;
    width: 30px;
    color: #dfdfdf;
    transition: all 0.25s ease;
    /* box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0); */
  }

  &:hover {
    svg {
      color: #fff;
      /* box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.75); */
      filter: brightness(120%);
    }
  }
`;
