import React, { useEffect, useState, HTMLAttributes } from 'react';

import { useScrolling } from 'hooks/scrolling';
import { FaSearch } from 'react-icons/fa';
import { IconBaseProps } from 'react-icons';
import { useLocation, Link } from 'react-router-dom';
import { Container } from './styles';

interface FloatLinkProps extends HTMLAttributes<HTMLElement> {
  icon?: React.ComponentType<IconBaseProps>;
  routeName?: string;
  title?: string;
}

const FloatLink: React.FC<FloatLinkProps> = ({
  children,

  icon: Icon = FaSearch,
  routeName = 'consult',
  title = 'Consulta',
  ...rest
}) => {
  const { pathname } = useLocation();
  const { scrolled } = useScrolling();

  const [position, setPosition] = useState<number>(scrolled);

  useEffect(() => {
    if (scrolled > 100) {
      setPosition(100);
      return;
    }

    setPosition(scrolled);
  }, [scrolled]);

  return (
    <Container amountScrolled={position} {...rest}>
      {children}
      <Link to={{ pathname: `${pathname}/${routeName}` }} title={title}>
        <Icon />
      </Link>
    </Container>
  );
};

export default FloatLink;
