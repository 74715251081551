import styled from 'styled-components';
import { Form } from '@unform/web';

export const StyledForm = styled(Form)`
  position: sticky;
  margin-top: 10px;
  top: 100px;
  z-index: 1;

  display: flex;

  width: 100vw;
  justify-content: center;
`;

export const GridContainer = styled.div`
  margin: 25px 0;
  width: 90vw;
  max-width: 1300px;

  display: grid;

  grid: auto / 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 775px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  position: relative;
  width: auto;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 3px solid #efefef;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  &:hover {
    border-color: #332e2e;
  }
`;

export const GridFront = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  h5 {
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      p {
        > strong {
          color: #8a0002;
        }
      }
      & + span {
        margin: 0 0 0 10px;
      }
    }
  }
`;
