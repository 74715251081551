import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

interface ItemProps {
  selected: boolean;
}

export const InputContainer = styled.div`
  position: relative;
  margin: 10px auto 0 auto;

  z-index: 1;
  background: #efefef;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  font-size: 16px;

  height: 40px;
  width: 90vw;
  max-width: 350px;

  input {
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }
  }
`;

export const SVGContainer = styled(animated.span)`
  button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    background-color: transparent;

    svg {
      width: 20px;
      height: 20px;
      color: red;
    }
  }
`;

export const ListContainer = styled(animated.div)`
  margin: 5px 0 25px 0;
  width: 100vw;
  max-width: 1366px;
  padding: 0px 10px;

  @media screen and (max-width: 650px) {
    padding: 0px;
  }
`;

export const List = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @media screen and (max-width: 1366px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 950px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
  }
`;

export const Item = styled.button<ItemProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */

  padding: 10px 0px;

  font-size: 16px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.selected &&
    css`
      background: #d3e4d4;
      border: 3px solid #00802b;
    `}

  transition: all 0.35s;

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 650px) {
    width: 320px;
    margin: 0 auto;
  }

  @media screen and (max-width: 360px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    margin: 0;
  }
`;

export const SelectedContainer = styled(animated.div)`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 15px auto 0 auto;

  font-size: 16px;

  background: #464646;

  p {
    color: #fff;
    strong {
      margin-left: 7px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 25px;
    background: #fff;
    border: 0;

    color: #332e2e;

    border-radius: 3px;
    padding: 3px 7.5px;

    transition: background-color 0.35s;
    font-size: 16px;
    &:hover {
      background-color: ${shade(0.2, '#fff')};
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  & + div {
    margin: 15px 0 0 0;
  }

  div {
    display: flex;
    /* align-items: center; */
    border: 2px solid transparent;
    border-bottom-color: #d6d6d6;
    font-size: 16px;

    p {
      color: #332e2e;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      background: transparent;
      svg {
        width: 20px;
        height: 20px;
        color: #c53030;
        margin: 0 0 0 7px;
      }
    }
    & + div {
      margin: 5px 0 0 0;
    }
  }
`;
