import { Form } from '@unform/web';
import styled from 'styled-components';

export const AlteredForm = styled(Form)`
  padding: 5px 10px 10px 10px;
  border: 2px solid #efefef;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  transition: box-shadow 0.5s ease;
  > span {
    > h3 {
      font-size: 14px;
      color: #8a0002;
    }
    > p {
      color: #8a0002;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-weight: 500;
    }
    & + span {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    width: 100vw;
  }
`;
