import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useSpring } from 'react-spring';
import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
  CoordProps,
} from 'styles/sgo_wrappers';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { FaSearch, FaTimes } from 'react-icons/fa';

import {
  createArray,
  removeDups,
  CreateArrayProps,
} from 'components/AutoComplete';

import { formatDate, handleTimeZone } from 'utils/formatDate';

import * as O from 'styles/option_buttons';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { FormContent, GridContainer, GridItem, ModalContent } from './styles';

import { monthList, MonthListProps } from '../../monthList';

interface ListProps {
  anb: string;
  anbd: string;
  loc: string;
  locd: string;
  ini: string;
  end: string;
  datemonth: string;
  dateyear: string;
  serv: string;
  localserv: string;
  place: string;
  contact: string;
  uuid: string;
  tscod: string;
}

interface LocalStorageProps {
  zon?: string;
  anb?: string;
  loc?: string;
  coordStringfied?: string;
  localYear?: string;
  localMonth?: string;
  filterBy?: string;
}

const CronogramaContent: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const bodyForm = useRef<FormHandles>(null);
  const { user } = useAuth();
  const location = useLocation();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [content] = useState(() => {
    return location.pathname.replace('/sgo/secretaria/cronograma/', '');
  });
  const [mode] = useState(() => {
    return content === 'mensal'
      ? process.env.REACT_APP_CRONOGRAMA_MENSAL
      : process.env.REACT_APP_CRONOGRAMA_ANUAL;
  });

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [initialZon, setInitialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(`${mode}`);

    return zon || user.zoncod;
  });
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);

    return anb || 'Selecione';
  });
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return loc || 'Selecione';
  });
  const [initialYear, setInitialYear] = useState(() => {
    const { localYear }: LocalStorageProps = getLocalStorage(`${mode}`);

    return localYear || handleTimeZone(new Date()).getUTCFullYear().toString();
  });
  const [initialMonth, setInitialMonth] = useState(() => {
    const { localMonth }: LocalStorageProps = getLocalStorage(`${mode}`);

    // return (
    //   localMonth || (handleTimeZone(new Date()).getUTCMonth() + 1).toString()
    // );
    return localMonth || '';
  });
  const [coord, setCoord] = useState<CoordProps>(() => {
    const { coordStringfied }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coordStringfied) {
      const { cod, desc } = JSON.parse(coordStringfied);
      return { cod, desc };
    }
    return ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [selected, setSelected] = useState<ListProps>({} as ListProps);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);
  const [comboMonths, setComboMonths] = useState<ComboProps[]>([]);

  const buildComboYear = useCallback(
    (array: CreateArrayProps[]) => {
      const { localYear }: LocalStorageProps = getLocalStorage(`${mode}`);

      const yearBuild = createArray(
        removeDups(array, 'dateyear'),
        'dateyear',
        'dateyear',
      )
        .map((item) => ({ label: item.label, value: item.label }))
        .reverse();

      setComboYears(yearBuild);
      let currYear =
        localYear || handleTimeZone(new Date()).getUTCFullYear().toString();

      if (yearBuild.map((item) => item.value).indexOf(currYear) < 0) {
        currYear =
          yearBuild.length > 0
            ? yearBuild[0].value
            : handleTimeZone(new Date()).getUTCFullYear().toString();
        setInitialYear(currYear);
      }

      setLocalStorage(`${mode}`, {
        localYear: currYear,
      });

      //   if (force) {
      //     setInitialYear(yearBuild[0].value);
      //     setLocalStorage(`${mode}`, {
      //       localYear: yearBuild[0].value,
      //     });
      //   }
    },
    [mode],
  );

  const buildComboMonth = useCallback(
    (array: CreateArrayProps[]): CreateArrayProps[] => {
      const { localYear }: LocalStorageProps = getLocalStorage(`${mode}`);
      const currYear =
        localYear || handleTimeZone(new Date()).getUTCFullYear().toString();

      const monthBuild = createArray(
        removeDups(
          array.filter((item) => item.dateyear === currYear),
          'datemonth',
        ),
        'datemonth',
        'datemonth',
      ).map((item) => ({
        label:
          monthList[
            monthList.findIndex(
              (mi: MonthListProps) =>
                mi.value === parseInt(item.id.toString(), 10),
            )
          ].label,
        value: item.label,
      }));

      setComboMonths([{ value: '', label: 'Todos do Ano' }, ...monthBuild]);

      // const currMonth =
      //   localMonth || (handleTimeZone(new Date()).getUTCMonth() + 1).toString();

      // if (currMonth !== '') {
      // if (monthBuild.map((item) => item.value).indexOf(currMonth) < 0) {
      //   setInitialMonth(monthBuild[0].value);
      //   setLocalStorage(`${mode}`, {
      //     localMonth: monthBuild[0].value,
      //   });
      //   return monthBuild;
      // }

      // setInitialMonth(localMonth === undefined ? '' : currMonth);
      // setLocalStorage(`${mode}`, {
      //   localMonth: localMonth === undefined ? '' : currMonth,
      // });
      // }
      return monthBuild;
    },
    [mode],
  );

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(`${mode}`);

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substring(0, 2) === zon.substring(0, 2)
          : item.value.substring(0, 2) === user.zoncod.substring(0, 2),
      ),
    );
  }, [mode, user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      // `/combos/comboLOCs.php`,
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);

    setComboLocs(
      response.data.filter(
        (item: ComboLocalProps) => item.anb === (anb || user.anbc),
      ),
    );
  }, [mode, user.anbc]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          mode: content === 'mensal' ? 'dmg' : 'other',
          zon: initialZon,
        }),
      );

      const response = await api.post('/sgo/cronograma_list.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      buildComboYear(response.data);
      buildComboMonth(response.data);

      setRawList(response.data);
      const {
        anb,
        loc,
        localYear,
        localMonth,
      }: LocalStorageProps = getLocalStorage(`${mode}`);
      let filtered = response.data;
      if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
        if (anb) {
          filtered = filtered.filter((item: ListProps) => item.anb === anb);
        }
      }
      if (['LOC', 'GUI'].indexOf(user.perfil) < 0) {
        if (loc) {
          filtered = filtered.filter((item: ListProps) => item.loc === loc);
        }
      }

      if (localYear) {
        filtered = filtered.filter(
          (item: ListProps) => item.dateyear === localYear,
        );
      }

      if (localMonth) {
        filtered = filtered.filter(
          (item: ListProps) => item.datemonth === localMonth,
        );
      }

      setList(filtered);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    buildComboMonth,
    buildComboYear,
    content,
    errorHandling,
    initialZon,
    mode,
    user.perfil,
  ]);

  useEffect(() => {
    const { filterBy }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (!filterBy) {
      setLocalStorage(`${mode}`, {
        filterBy: 'ZON',
        localMonth: '',
        // filterBy: 'ZON',
        //   user.perfil === 'INT'
        //     ? 'ZON'
        //     : user.perfil === 'GUI'
        //     ? 'LOC'
        //     : user.perfil,
      });
    }

    // if (user.perfil === 'INT') {
    //   getComboZON();
    // }
    // if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
    //   getComboANB();
    // }

    // if (['GUI', 'LOC'].indexOf(user.perfil) < 0) {
    //   getComboLOC();
    // }
    getComboZON();
    getComboANB();
    getComboLOC();
    getList();
  }, [getComboANB, getComboLOC, getComboZON, getList, mode, user.perfil]);

  const handleListSetup = useCallback(
    (key: string, value: string, zonmode?: boolean) => {
      let filtered = (rawList as unknown[]) as CreateArrayProps[];

      filtered = filtered.filter((item: CreateArrayProps) =>
        zonmode
          ? item[key].toString().substr(0, 2) === value.substr(0, 2)
          : item[key] === value,
      );

      buildComboYear(filtered);
      buildComboMonth(filtered);

      const { localYear, localMonth }: LocalStorageProps = getLocalStorage(
        `${mode}`,
      );

      filtered = filtered.filter((item) => item.dateyear === localYear);

      if (localMonth) {
        filtered = filtered.filter((item) => item.datemonth === localMonth);
      }

      setList((filtered as unknown[]) as ListProps[]);
    },
    [buildComboMonth, buildComboYear, mode, rawList],
  );

  const handleZonSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('zonal');
    const { text, value } = select.options[select.selectedIndex];

    setInitialZon(value);
    setInitialNac('Selecione');
    setInitialLoc('Selecione');
    setComboLocs([]);
    setComboNacs(
      nacs.filter(
        (item: ComboProps) => item.value.substr(0, 2) === value.substr(0, 2),
      ),
    );
    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${mode}`, {
      zon: value,
      filterBy: 'ZON',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });
    deleteLocalStorageItemKey(`${mode}`, ['anb', 'loc']);
  }, [nacs, mode]);

  const handleNacSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('nacional');
    const { text, value } = select.options[select.selectedIndex];
    setInitialLoc('Selecione');
    setInitialNac(value);
    setCoord({
      cod: value,
      desc: text,
    });

    setComboLocs(locs.filter((item) => item.anb === value));
    formRef.current?.setFieldValue('comboLoc', '');

    setLocalStorage(`${mode}`, {
      anb: value,
      filterBy: 'NAC',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });
    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    handleListSetup('anb', value);
  }, [handleListSetup, locs, mode]);

  const handleLocSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('local');
    const { text, value } = select.options[select.selectedIndex];
    setInitialLoc(value);
    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${mode}`, {
      loc: value,
      filterBy: 'LOC',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });

    handleListSetup('loc', value);
  }, [handleListSetup, mode]);

  const handleYearSelect = useCallback(() => {
    const select = bodyForm.current?.getFieldRef('year');
    const { value } = select.options[select.selectedIndex];
    setInitialYear(value);
    setLocalStorage(`${mode}`, {
      localYear: value,
    });
    const {
      zon,
      anb,
      loc,
      filterBy,
      localMonth,
    }: LocalStorageProps = getLocalStorage(`${mode}`);

    let filtered = rawList.filter((item) => item.dateyear === value);

    if (filterBy === 'ZON') {
      filtered = filtered.filter(
        (item) =>
          item.anb.substr(0, 2) === (zon || user.zoncod).substring(0, 2),
      );
    }

    if (filterBy === 'NAC') {
      filtered = filtered.filter((item) => item.anb === (anb || user.anbc));
    }
    if (filterBy === 'LOC') {
      filtered = filtered.filter((item) => item.loc === (loc || user.loccod));
    }

    const bM = buildComboMonth((filtered as unknown[]) as CreateArrayProps[]);
    if (localMonth !== '') {
      if (
        ((bM as unknown[]) as ComboProps[])
          .map((item) => item.value)
          .indexOf(
            (
              localMonth || handleTimeZone(new Date()).getUTCMonth() + 1
            ).toString(),
          ) < 0
      ) {
        setLocalStorage(`${mode}`, {
          localMonth: bM[0].value.toString(),
        });
        setInitialMonth(bM[0].value.toString());
        const selectMonth = bodyForm.current?.getFieldRef('month');
        selectMonth.value = bM[0].value.toString();
        filtered = filtered.filter(
          (item) => item.datemonth === bM[0].value.toString(),
        );
      } else {
        filtered = filtered.filter((item) => item.datemonth === localMonth);
      }
    }

    setList(filtered);
  }, [buildComboMonth, mode, rawList, user.anbc, user.loccod, user.zoncod]);

  const handleMonthSelect = useCallback(() => {
    const select = bodyForm.current?.getFieldRef('month');
    const { value } = select.options[select.selectedIndex];

    setInitialMonth(value);
    setLocalStorage(`${mode}`, {
      localMonth: value,
    });

    const {
      anb,
      loc,
      filterBy,
      localYear,
    }: LocalStorageProps = getLocalStorage(`${mode}`);

    let filtered = rawList;

    if (value !== '') {
      filtered = rawList.filter((item) => item.datemonth === value);
    }

    if (filterBy === 'ZON') {
      filtered = filtered.filter(
        (item) =>
          item.anb.substr(0, 2) === (anb || user.zoncod).substring(0, 2),
      );
    }
    if (filterBy === 'NAC') {
      filtered = filtered.filter((item) => item.anb === (anb || user.anbc));
    }
    if (filterBy === 'LOC') {
      filtered = filtered.filter((item) => item.loc === (loc || user.loccod));
    }

    if (localYear) {
      filtered = filtered.filter(
        (item: ListProps) => item.dateyear === localYear,
      );
    }

    setList(filtered);
  }, [mode, rawList, user.anbc, user.loccod, user.zoncod]);

  const handleRemoveNacFilter = useCallback(() => {
    deleteLocalStorageItemKey(`${mode}`, ['loc', 'anb']);
    const select = formRef.current?.getFieldRef('zonal');
    const { text, value } = select.options[select.selectedIndex];

    setComboLocs([]);
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    setCoord({ cod: value, desc: text });
    setLocalStorage(`${mode}`, {
      filterBy: 'ZON',
      coordStringfied: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });

    handleListSetup('anb', value, true);
  }, [handleListSetup, mode]);

  const handleRemoveLocFilter = useCallback(() => {
    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    if (user.perfil !== 'NAC') {
      const select = formRef.current?.getFieldRef('nacional');
      const { text, value } = select.options[select.selectedIndex];
      setCoord({
        cod: value,
        desc: text,
      });
      handleListSetup('anb', value);

      setLocalStorage(`${mode}`, {
        filterBy: 'NAC',
        coordStringfied: JSON.stringify({
          cod: value,
          desc: text,
        }),
      });
      return;
    }
    setCoord({
      cod: user.anbc,
      desc: user.anbdesc,
    });

    setLocalStorage(`${mode}`, {
      filterBy: 'NAC',
      coordStringfied: JSON.stringify({
        cod: user.anbc,
        desc: user.anbdesc,
      }),
    });
    handleListSetup('anb', user.anbc);
  }, [handleListSetup, mode, user.anbc, user.anbdesc, user.perfil]);

  const handlePreShow = useCallback(
    (uuid: string) => {
      const index = list.findIndex((item) => item.uuid === uuid);
      setSelected({ ...list[index] });
      setShow((state) => ({ ...state, open: true }));
    },
    [list],
  );

  const cancelShow = useCallback(() => {
    setShow((state) => ({ ...state, open: false }));
    setTimeout(() => {
      setSelected({} as ListProps);
    }, 500);
  }, []);

  const removeANBButton = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeLocButton = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        isResponsible
        // noLinks
        // needFilter={['GUI', 'LOC'].indexOf(user.perfil) < 0}
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              <span>
                <p>Zonal:</p>
                <SelectV2
                  name="zonal"
                  content={comboZons}
                  initial={initialZon}
                  onChange={handleZonSelect}
                />
                <RemoveButton
                  type="button"
                  style={{ opacity: 0, pointerEvents: 'none' }}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>

              <span>
                <p>Nacional:</p>
                <SelectV2
                  name="nacional"
                  content={comboNacs}
                  initial={initialNac}
                  onChange={handleNacSelect}
                />
                <RemoveButton
                  style={removeANBButton}
                  onClick={handleRemoveNacFilter}
                  type="button"
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>

              <span>
                <p>Local:</p>
                <SelectV2
                  name="local"
                  content={comboLocs}
                  initial={initialLoc}
                  onChange={handleLocSelect}
                />
                <RemoveButton
                  style={removeLocButton}
                  onClick={handleRemoveLocFilter}
                  type="button"
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>

              {/* <span>
                <p>Ano:</p>
                <SelectV2
                  name="year"
                  content={comboYears}
                  initial={initialYear}
                  onChange={handleYearSelect}
                />
                <RemoveButton
                  style={{ opacity: 0, pointerEvents: 'none' }}
                  type="button"
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
              <span>
                <p>Mês:</p>
                <SelectV2
                  name="year"
                  content={comboMonths}
                  initial={initialMonth}
                  onChange={handleMonthSelect}
                />
                <RemoveButton
                  style={{ opacity: 0, pointerEvents: 'none' }}
                  type="button"
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span> */}
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>
              Cronograma&nbsp;
              {content === 'mensal'
                ? 'de Dia Mensal do Guia'
                : 'Anual (Retiros / EED / Assembléias )'}
            </p>
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>
        <Form ref={bodyForm} onSubmit={() => null}>
          <FormContent>
            <span>
              <p>Ano:</p>
              <SelectV2
                name="year"
                content={comboYears}
                initial={initialYear}
                onChange={handleYearSelect}
                containerStyle={{ width: '150px' }}
              />
            </span>
            <span>
              <p>Mês:</p>
              <SelectV2
                name="month"
                content={comboMonths}
                initial={initialMonth}
                onChange={handleMonthSelect}
                containerStyle={{ width: '150px' }}
              />
            </span>
          </FormContent>
        </Form>

        <GridContainer amount={list.length}>
          {list.map((item) => (
            <GridItem
              key={item.uuid}
              borderColor={
                item.tscod === 'AI'
                  ? '#8a0002'
                  : item.tscod === 'AN'
                  ? '#c53030'
                  : item.tscod === 'RE'
                  ? '#00802b'
                  : item.tscod === 'RC'
                  ? '#fce205'
                  : item.tscod === 'ED'
                  ? '#4fc3f7'
                  : item.tscod === 'JF'
                  ? '#7159c1'
                  : '#efefef'
              }
            >
              {content === 'mensal' ? (
                <>
                  <span>
                    <p>
                      Data:&nbsp;
                      <strong style={{ color: '#c53030' }}>
                        {formatDate(item.ini)}
                      </strong>
                    </p>
                  </span>

                  <span>
                    <p>
                      Coordenação: <strong>{item.anbd}</strong>
                    </p>
                  </span>

                  <span>
                    <p>
                      Coordenação Local: <strong>{item.locd}</strong>
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <strong style={{ color: '#c53030' }}>{item.serv}</strong>
                  </span>
                  <span style={{ marginTop: '15px' }}>
                    <p>
                      Início:&nbsp;<strong>{formatDate(item.ini)}</strong>
                    </p>
                    <p>
                      Final:&nbsp;<strong>{formatDate(item.end)}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      <strong>{item.place}</strong>
                    </p>
                  </span>
                </>
              )}
              <O.GridOptions>
                <O.Show onClick={() => handlePreShow(item.uuid)}>
                  <FaSearch />
                </O.Show>
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>
              {content === 'mensal'
                ? `Dia ${formatDate(selected.ini)} - ${selected.locd}`
                : `${selected.serv}`}
            </h2>
          </S.Title>
          <S.Content>
            <ModalContent>
              {content === 'mensal' ? (
                <>
                  <span>
                    <p>
                      Local:&nbsp;<strong>{selected.localserv || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Cidade:&nbsp;<strong>{selected.place || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Coord Nacional:&nbsp;
                      <strong>{selected.anbd || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Contato:&nbsp;<strong>{selected.contact || '-'}</strong>
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <p>
                      Início:&nbsp;<strong>{formatDate(selected.ini)}</strong>
                    </p>
                    <p>
                      Final:&nbsp;<strong>{formatDate(selected.end)}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Cidade:&nbsp;<strong>{selected.place || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Coord. Nacional:&nbsp;
                      <strong>{selected.anbd || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Coord. Local:&nbsp;
                      <strong>{selected.locd || '-'}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Contato:&nbsp;<strong>{selected.contact || '-'}</strong>
                    </p>
                  </span>
                </>
              )}
            </ModalContent>
          </S.Content>
          <S.Actions>
            <S.Confirm type="button" onClick={cancelShow}>
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default CronogramaContent;
