import styled from 'styled-components';

export const Table = styled.table`
  margin: 20px auto;
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid transparent;

  /* min-width: 800px; */
  /* font-size: 16px; */
  /* width: 1366px; */

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    /* background: #fff; */
  }

  td {
    font-size: 16px;
    padding: 2px 3px;
  }

  @media screen and (max-width: 800px) {
    /* min-width: 100%; */
  }
`;

export const TDtoRight = styled.td`
  text-align: right;
`;

export const TRHeader = styled.tr`
  td {
    border-radius: 5px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    /* background: linear-gradient(
      to right,
      rgba(51, 46, 46, 1),
      rgba(51, 46, 46, 1),
      rgba(51, 46, 46, 0.5),
      rgba(51, 46, 46, 0.25),
      rgba(51, 46, 46, 0),
      rgba(51, 46, 46, 0),
      rgba(51, 46, 46, 0)
    ); */

    strong {
      color: #332e2e;
      /* color: #fff; */
      border: 1px solid transparent;
      border-radius: 5px;
    }
  }
`;

export const OtherTable = styled.table`
  margin: 20px auto;
  /* border-collapse: collapse; */
  width: 100vw;
  max-width: 650px;

  border: 1px solid transparent;
  td {
    font-size: 16px;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: 500;

    &:nth-last-child(1) {
      width: 120px;
      color: #8a0002;
    }
  }
  tr {
    border-radius: 5px;
    background: #fff;
    vertical-align: middle;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      background: transparent;
    }

    &:nth-last-child(2),
    &:nth-last-child(3) {
      text-align: right;

      td {
        margin: 15px 0 0 0;
      }
      td:nth-last-child(1) {
        background: #fff;
      }
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;

  span {
    color: #8a0002;
  }
`;
