import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  useMemo,
} from 'react';

import {
  Container,
  AlteredContent,
  Header,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useWindow } from 'hooks/window';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';

import { useSpring } from 'react-spring';
import { FaFileExcel, FaMobileAlt, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import { formatNumber } from 'utils/calcTotal';
import PrintButton from 'components/PrintButton';
import {
  ContentContainer,
  AlteredFloatInput,
  TotalContainer,
  ListContainer,
  MovContainer,
  MovContent,
  Data,
  Description,
  Document,
  Value,
  UpdateReg,
  ExcReg,
  CPRHeader,
  Wrapper,
  RotateMessage,
  H3Stilo,
} from './styles';

interface LocalStorageProps {
  cod?: string;
  desc?: string;
}

interface ListProps {
  anbc: string;
  contracod: string;
  contrapart: string | null;
  desc: string;
  docto: string;
  locc: string;
  month: number;
  mov: string;
  orig: string;
  seq: number;
  type: string;
  value: number;
  year: number;

  //   anbc: "BO02"
  // contrapart: "MAU"
  // desc: "Novo saldo da RM196 da Local *MAU*"
  // docto: "RM196"
  // locc: "BO02"
  // month: "07"
  // mov: "R"
  // orig: "BO02"
  // seq: 13753
  // type: "A"
  // value: 76.2
  // year: 2019
}

const CPR: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [coord, setCoord] = useState(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CPR}`,
    );

    return cod && desc
      ? { cod, desc }
      : user.perfil === 'ZON' || user.perfil === 'INT'
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [searchValue, setSearchValue] = useState('');

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const getList = useCallback(
    async (params?: string) => {
      setLoading(true);
      try {
        const response = await api.get(
          `/sgo/cpr_list.php?data=${JSON.stringify({
            cod: params || coord.cod,
          })}`,
        );

        setList(response.data);
        setRawList(response.data);
      } catch (err) {
        errorHandling(err);
      }
      setLoading(false);
    },
    [coord.cod, errorHandling],
  );

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
      );
    }
  }, [user.perfil, user.anbc]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${process.env.REACT_APP_CPR}`, {
      cod: value,
      desc: text,
    });
  }, []);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${process.env.REACT_APP_CPR}`, {
      cod: value,
      desc: text,
    });

    getList(value);
  }, [getList]);

  const handleLOCselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLocal');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${process.env.REACT_APP_CPR}`, {
      cod: value,
      desc: text,
    });
    getList(value);
  }, [getList]);

  useEffect(() => {
    handlePermission(['GUI']);
    getList();
    getComboZON();
    getComboANB();
    getComboLOC();
  }, [getComboZON, getComboANB, getComboLOC, handlePermission, getList]);

  const removeFilter = useCallback(() => {
    setCoord({
      cod: user.perfil === 'ZON' ? user.zoncod : user.anbc,
      desc: user.perfil === 'ZON' ? user.zondesc : user.anbdesc,
    });

    localStorage.removeItem(`${process.env.REACT_APP_CPR}`);
  }, [user.anbc, user.anbdesc, user.perfil, user.zoncod, user.zondesc]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      setList(
        rawList.filter(
          (item) =>
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase()) ||
            removeAcento(item.docto)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase()),
        ),
      );
    },
    [rawList],
  );

  const filterRemove = useSpring({
    opacity:
      user.perfil === 'ZON' && coord.cod !== user.zoncod
        ? 1
        : user.perfil === 'NAC' && coord.cod !== user.anbc
        ? 1
        : 0,
    pointerEvents:
      user.perfil === 'ZON' && coord.cod !== user.zoncod
        ? 'all'
        : user.perfil === 'NAC' && coord.cod !== user.anbc
        ? 'all'
        : 'none',
  });

  const handleDelete = useCallback(async () => {
    try {
      setDeleteDiag((state) => ({ ...state, open: false }));
      setLoading(true);
      if (deleteDiag.values?.seq) {
        const data = {
          seq: deleteDiag.values.seq,
        };

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/cpr_delete.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro excluído.',
        });

        getList();
      } else {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha na Requisição',
          description:
            'Verifique o registro e caso o problema persista acione o suporte ao SGO.',
        });
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [addToast, errorHandling, getList, deleteDiag.values]);

  const totPagar = useMemo(() => {
    return list.reduce((accum, { value, mov }: ListProps) => {
      return mov === 'P' ? accum + value : accum;
    }, 0);
  }, [list]);

  const totReceber = useMemo(() => {
    return list.reduce((accum, { value, mov }: ListProps) => {
      return mov === 'R' ? accum + value : accum;
    }, 0);
  }, [list]);

  const showMessage = useSpring({
    opacity: width >= 640 ? 0 : 1,
    height: width >= 640 ? 0 : 'auto',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {['ZON', 'NAC'].indexOf(user.perfil) >= 0 && <IncludeButton />}
      <PrintButton icon={FaFileExcel} linkTo="/sgo/xls_cpr.php" />

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={user.zoncod}
                  />
                </span>
              )}
              {['ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={
                      coord.cod === user.zoncod ? 'Selecione' : coord.cod
                    }
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeFilter}
                    style={filterRemove}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={coord.cod === user.anbc ? 'Selecione' : coord.cod}
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeFilter}
                    style={filterRemove}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
            </div>
          </Form>
        }
      />
      <Header>Contas a Pagar e Receber {coord.desc}</Header>
      <AlteredFloatInput>
        <input
          placeholder="Filtrar por Documento ou Descrição"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </AlteredFloatInput>
      <AlteredContent>
        <ContentContainer>
          <RotateMessage style={showMessage}>
            <span>
              <p>Para mais informações, vire seu aparelho</p>
            </span>
            <span>
              <FaMobileAlt />
            </span>
          </RotateMessage>
          <TotalContainer>
            <span>
              <p>
                Total a Receber:&nbsp;
                <strong style={{ color: '#2156e6' }}>
                  R$ {formatNumber(totReceber)}
                </strong>
              </p>
            </span>
            <span>
              <p>
                Total a Pagar:&nbsp;
                <strong style={{ color: '#c53030' }}>
                  R$ {formatNumber(totPagar)}
                </strong>
              </p>
            </span>
          </TotalContainer>
          <H3Stilo>A Receber</H3Stilo>
          <ListContainer>
            <MovContainer>
              <CPRHeader>
                <Data extend={user.perfil === 'LOC'}>
                  <p>Mês | Ano</p>
                </Data>
                <Description>
                  <p>Descrição</p>
                </Description>
                <Document extend={user.perfil === 'LOC'}>
                  <p>Documento</p>
                </Document>
                <Value extend={user.perfil === 'LOC'}>
                  <p>Valor</p>
                </Value>
                {['ZON', 'NAC'].indexOf(user.perfil) >= 0 && (
                  <>
                    <UpdateReg>
                      <p>{width >= 470 ? 'Editar' : <FaPencilAlt />}</p>
                    </UpdateReg>
                    <ExcReg>
                      <p>{width >= 470 ? 'Excluir' : <FaTimes />}</p>
                    </ExcReg>
                  </>
                )}
              </CPRHeader>
              <MovContent>
                {list.map(
                  (item, index) =>
                    item.mov === 'R' && (
                      <Wrapper key={item.seq} paint={index}>
                        <Data extend={user.perfil === 'LOC'}>
                          <p>
                            {item.month} | {item.year}
                          </p>
                        </Data>
                        <Description>
                          <p>{item.desc}</p>
                        </Description>
                        <Document extend={user.perfil === 'LOC'}>
                          <p>{item.docto}</p>
                        </Document>
                        <Value extend={user.perfil === 'LOC'}>
                          <p>R$&nbsp;{formatNumber(item.value)}</p>
                        </Value>

                        {['ZON', 'NAC'].indexOf(user.perfil) >= 0 && (
                          <>
                            <UpdateReg>
                              {item.type === 'M' && coord.cod === item.orig ? (
                                <Link
                                  to={{
                                    pathname: `${location.pathname}/update`,
                                    state: {
                                      reg: item.seq,
                                      mov: item.mov,
                                      document: item.docto,
                                      description: item.desc,
                                      value: item.value,
                                      year: item.year,
                                      month: item.month,
                                      contrapart: item.contrapart,
                                      contracod: item.contracod,
                                    },
                                  }}
                                >
                                  <FaPencilAlt />
                                </Link>
                              ) : (
                                <p> - </p>
                              )}
                            </UpdateReg>
                            <ExcReg>
                              {item.type === 'M' && coord.cod === item.orig ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setDeleteDiag({
                                      open: true,
                                      values: {
                                        seq: item.seq,
                                        month: item.month,
                                        year: item.year,
                                        description: item.desc,
                                        document: item.docto,
                                        value: formatNumber(item.value),
                                      },
                                    })
                                  }
                                >
                                  <FaTimes />
                                </button>
                              ) : (
                                <p> - </p>
                              )}
                            </ExcReg>
                          </>
                        )}
                      </Wrapper>
                    ),
                )}
              </MovContent>
            </MovContainer>
          </ListContainer>

          <H3Stilo>A Pagar</H3Stilo>
          <ListContainer>
            <MovContainer>
              <CPRHeader>
                <Data extend={user.perfil === 'LOC'}>
                  <p>Mês | Ano</p>
                </Data>
                <Description>
                  <p>Descrição</p>
                </Description>
                <Document extend={user.perfil === 'LOC'}>
                  <p>Documento</p>
                </Document>
                <Value extend={user.perfil === 'LOC'}>
                  <p>Valor</p>
                </Value>
                {['ZON', 'NAC'].indexOf(user.perfil) >= 0 && (
                  <>
                    <UpdateReg>
                      <p>{width >= 470 ? 'Editar' : <FaPencilAlt />}</p>
                    </UpdateReg>
                    <ExcReg>
                      <p>{width >= 470 ? 'Excluir' : <FaTimes />}</p>
                    </ExcReg>
                  </>
                )}
              </CPRHeader>
              <MovContent>
                {list.map(
                  (item, index) =>
                    item.mov === 'P' && (
                      <Wrapper key={item.seq} paint={index}>
                        <Data extend={user.perfil === 'LOC'}>
                          <p>
                            {item.month} | {item.year}
                          </p>
                        </Data>
                        <Description>
                          <p>{item.desc}</p>
                        </Description>
                        <Document extend={user.perfil === 'LOC'}>
                          <p>{item.docto}</p>
                        </Document>
                        <Value extend={user.perfil === 'LOC'}>
                          <p>R$&nbsp;{formatNumber(item.value)}</p>
                        </Value>

                        {['ZON', 'NAC'].indexOf(user.perfil) >= 0 && (
                          <>
                            <UpdateReg>
                              {item.type === 'M' && coord.cod === item.orig ? (
                                <Link
                                  to={{
                                    pathname: `${location.pathname}/update`,
                                    state: {
                                      reg: item.seq,
                                      mov: item.mov,
                                      document: item.docto,
                                      description: item.desc,
                                      value: item.value,
                                      year: item.year,
                                      month: item.month,
                                      contrapart: item.contrapart,
                                      contracod: item.contracod,
                                    },
                                  }}
                                >
                                  <FaPencilAlt />
                                </Link>
                              ) : (
                                <p> - </p>
                              )}
                            </UpdateReg>
                            <ExcReg>
                              {item.type === 'M' && coord.cod === item.orig ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setDeleteDiag({
                                      open: true,
                                      values: {
                                        seq: item.seq,
                                        month: item.month,
                                        year: item.year,
                                        description: item.desc,
                                        document: item.docto,
                                        value: formatNumber(item.value),
                                      },
                                    })
                                  }
                                >
                                  <FaTimes />
                                </button>
                              ) : (
                                <p> - </p>
                              )}
                            </ExcReg>
                          </>
                        )}
                      </Wrapper>
                    ),
                )}
              </MovContent>
            </MovContainer>
          </ListContainer>
        </ContentContainer>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <ModalDeleteContent>
              <p>Você está prestes a excluir o registro:</p>
              <div>
                <span>
                  <p>
                    Data:&nbsp;
                    <strong>
                      {deleteDiag.values?.month} | {deleteDiag.values?.year}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    Valor:&nbsp;
                    <strong>R$&nbsp;{deleteDiag.values?.value}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Documento:&nbsp;
                    <strong>{deleteDiag.values?.document}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Descrição:&nbsp;
                    <strong>{deleteDiag.values?.description}</strong>
                  </p>
                </span>
              </div>

              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleDelete}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default CPR;
