import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';
import InputDialog from 'components/InputDialog';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';

import api from 'services/api';

import * as yup from 'yup';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import moment from 'moment';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';
import { useHistory } from 'react-router-dom';

import { addYears, format, isValid } from 'date-fns';

import getValidationErrors from 'utils/getValidationErrors';
import { handleTimeZone } from 'utils/formatDate';
import {
  CatContainer,
  CatButton,
  FormContent,
  FormCat,
  FinishButtonContainer,
} from './styles';

interface GuiaProps {
  seq: string;
  cert: string;
  nome: string;
  anb: string;
  loc: string;
  tg: string;
  func: string;
}

interface ThisFProps extends ComboProps {
  loc: string;
}

interface FormData {
  comboLoc: string;
  evang1s: string;
  evang2s: string;
  evang1i: string;
  evang2i: string;
  dtinc: string;
  dtend: string;
}

interface FinalFormData extends FormData {
  loc: string;
  type: string;
  amount: string;
  gseq1: string;
  gseq2: string;
  name1: string;
  name2: string;
}

const GEvangInsert: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const formRef = useRef<FormHandles>(null);

  const [type, setType] = useState('');
  const [gAmount, setGAmount] = useState<string>('');

  const [anbs, setAnbs] = useState<ComboProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [rawGuias, setRawGuias] = useState<ThisFProps[]>([]);

  const [guias, setGuias] = useState<ComboProps[]>([]);
  const [comboEvang1, setComboEvang1] = useState<ComboProps[]>([]);
  const [comboEvang2, setComboEvang2] = useState<ComboProps[]>([]);
  const [loading, setLoading] = useState(false);

  const [toSubmit, setToSubmit] = useState<FinalFormData>({} as FinalFormData);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const getGuias = useCallback(async () => {
    try {
      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify({
          filter: 'byZon',
        })}`,
      );
      setRawGuias(
        response.data
          .filter((item: GuiaProps) => {
            return ['GA', 'GP'].indexOf(item.tg) > -1;
          })
          .map((item: GuiaProps) => ({
            value: item.seq,
            label: item.nome.substring(0, item.nome.indexOf('-') - 1),
            loc: item.loc,
          })),
      );
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setAnbs(
      response.data
        .filter(
          (item: ComboProps) =>
            item.value.substring(0, 2) === user.zoncod.substring(0, 2),
        )
        .map((item: ComboProps) => ({ value: item.value, label: item.label })),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    // setComboLocs(
    //   response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
    // );
  }, []);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getGuias();
    getComboANB();
    getComboLOC();
  }, [getComboANB, getComboLOC, getGuias, handlePermission]);

  const handleType = useCallback(
    (typeClicked: string) => {
      if (typeClicked === type) {
        return;
      }
      formRef.current?.reset();
      formRef.current?.setErrors({});

      setGAmount('');
      setType(typeClicked);
    },
    [type],
  );

  const handleANBSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value } = select.options[select.selectedIndex];

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));
    setGuias([]);
    setComboEvang1([]);
    setComboEvang2([]);
  }, [locs]);

  const handleLocSelection = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLoc');

    if (!loc) {
      return;
    }

    setGuias(rawGuias.filter((item) => item.loc === loc));
    setComboEvang1(rawGuias.filter((item) => item.loc === loc));
    setComboEvang2(rawGuias.filter((item) => item.loc === loc));
  }, [rawGuias]);

  const handleGAmount = useCallback((amountClicked: string) => {
    formRef.current?.setErrors({});
    setGAmount(amountClicked);
  }, []);

  const handleCC = useCallback(() => {
    handleType('CC');
  }, [handleType]);

  const handleCE = useCallback(() => {
    handleType('CE');
  }, [handleType]);

  const handleAR = useCallback(() => {
    handleType('AR');
  }, [handleType]);

  const handle0G = useCallback(() => {
    handleGAmount('0');
  }, [handleGAmount]);

  const handle1G = useCallback(() => {
    handleGAmount('1');
  }, [handleGAmount]);

  const handle2G = useCallback(() => {
    handleGAmount('2');
  }, [handleGAmount]);

  const handleEvang1Selection = useCallback(() => {
    const ev1 = formRef.current?.getFieldValue('evang1s');

    setComboEvang2(guias.filter((item) => item.value !== ev1));
  }, [guias]);

  const handleDate = useCallback(() => {
    const givenDate = new Date(formRef.current?.getFieldValue('dtinc'));

    if (!isValid(givenDate)) {
      return;
    }

    formRef.current?.setFieldValue(
      'dtend',
      format(addYears(givenDate, 10), 'yyyy-MM-dd'),
    );
  }, []);

  const dateValidation = (date: string): boolean => {
    const start = handleTimeZone(date);
    const today = handleTimeZone(new Date());

    const formats = 'YYYY-mm-dd';

    const startAt = moment(start, formats);
    const base = moment(today, formats);

    return base.isSameOrAfter(startAt);
  };

  const dateEndValidation = (ini: string, end: string): boolean => {
    if (!ini || !end) {
      return false;
    }
    const start = handleTimeZone(ini);
    const finish = handleTimeZone(end);

    const formats = 'YYYY-mm-dd';

    const startAt = moment(start, formats);
    const finishAt = moment(finish, formats);

    return finishAt.isSameOrAfter(startAt);
  };

  const handlePreSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          comboANB: yup.string().notOneOf(['Selecione', '']),
          comboLoc: yup.string().notOneOf(['Selecione', '']),
          evang1i: yup.string().when('type', {
            is: (tp) => tp === 'CC',
            then: yup.string().when('gAmount', {
              is: (amount) => amount === '0',
              then: yup
                .string()
                .trim()
                .min(5, 'Quantidade mínima de caracteres não atingida (5).')
                .max(60, 'Quantidade máxima de caracteres excedida (60)'),
              otherwise: yup.string().nullable(true),
            }),
            otherwise: yup
              .string()
              .trim()
              .min(5, 'Quantidade mínima de caracteres não atingida (5).')
              .max(60, 'Quantidade máxima de caracteres excedida (60)'),
          }),
          evang2i: yup.string().when('type', {
            is: (tp) => tp === 'CC',
            then: yup.string().when('gAmount', {
              is: (amount) => amount !== '2',
              then: yup
                .string()
                .trim()
                .min(5, 'Quantidade mínima de caracteres não atingida (5).')
                .max(60, 'Quantidade máxima de caracteres excedida (60)'),
              otherwise: yup.string().nullable(true),
            }),
            otherwise: yup
              .string()
              .trim()
              .min(5, 'Quantidade mínima de caracteres não atingida (5).')
              .max(60, 'Quantidade máxima de caracteres excedida (60)'),
          }),
          evang1s: yup.string().when('type', {
            is: (tp) => tp === 'CC',
            then: yup.string().when('gAmount', {
              is: (amount) => amount !== '0',
              then: yup.string().notOneOf(['Selecione', '']),
              otherwise: yup.string().notRequired(),
            }),
            otherwise: yup.string().notOneOf(['Selecione', '']),
          }),
          evang2s: yup.string().when('type', {
            is: (tp) => tp === 'CC',
            then: yup.string().when('gAmount', {
              is: (amount) => amount === '2',
              then: yup.string().notOneOf(['Selecione', '']),
              otherwise: yup.string().notRequired(),
            }),
            otherwise: yup.string().notOneOf(['Selecione', '']),
          }),
          dtinc: yup
            .date()
            .typeError('Data inválida')
            .test(
              'dtincTest',
              'Data deve ser anterior à data atual.',
              (date) => {
                return dateValidation(date);
              },
            ),
          dtend: yup
            .date()
            .typeError('Data inválida')
            .test(
              'check-with-dtinc',
              'Data deve ser posterior à data de inclusão',
              (date) => {
                return dateEndValidation(data.dtinc, date);
              },
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        const s1ref: HTMLSelectElement = formRef.current?.getFieldRef(
          'evang1s',
        );
        const s2ref: HTMLSelectElement = formRef.current?.getFieldRef(
          'evang2s',
        );

        const sentIt = {
          ...data,
          loc: data.comboLoc,
          type,
          amount: type === 'CC' ? gAmount : type === 'CE' ? '2' : '1',
          gseq1:
            ['CE', 'AR'].indexOf(type) > -1 ||
            (gAmount.length > 0 && gAmount !== '0')
              ? data.evang1s
              : '0',
          gseq2: type === 'CE' || gAmount === '2' ? data.evang2s : '0',
          name1: s1ref ? s1ref.options[s1ref.selectedIndex].text : data.evang1i,
          name2: s2ref ? s2ref.options[s2ref.selectedIndex].text : data.evang2i,
        };
        send.append('data', JSON.stringify(sentIt));

        if (dateValidation(data.dtend)) {
          setToSubmit(sentIt);
          setDeleteDiag((state) => ({ ...state, open: !state.open }));
          setLoading(false);
          return;
        }

        await api.post('/sgo/gevang_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Evangelizador registrado.',
        });

        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: 'Verifique os ícones de erro.',
            showIcon: true,
          });
          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, gAmount, history, type],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      const send = new FormData();

      send.append('data', JSON.stringify(toSubmit));

      await api.post('/sgo/gevang_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Evangelizador registrado.',
      });

      history.goBack();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, errorHandling, history, toSubmit]);

  const catCC = useSpring({
    pointerEvents: type === 'CC' ? 'all' : 'none',
    opacity: type === 'CC' ? 1 : 0,
    height: type === 'CC' ? '40px' : '0px',
    transform: type === 'CC' ? 'translateY(0%)' : 'translateY(35%)',
  });

  const typeSelected = useSpring({
    pointerEvents:
      ['CE', 'AR'].indexOf(type) > -1 || gAmount.length > 0 ? 'all' : 'none',
    zIndex: ['CE', 'AR'].indexOf(type) > -1 || gAmount.length > 0 ? 2 : -1,
    width: '360px',

    opacity: ['CE', 'AR'].indexOf(type) > -1 || gAmount.length > 0 ? 1 : 0,
    transform:
      ['CE', 'AR'].indexOf(type) > -1 || gAmount.length === 0
        ? 'translate(-50%, 0%)'
        : 'translate(-50%, 27%)',
  });

  const finishButton = useSpring({
    opacity: ['CE', 'AR'].indexOf(type) > -1 || gAmount.length > 0 ? 1 : 0,
    pointerEvents:
      ['CE', 'AR'].indexOf(type) > -1 || gAmount.length > 0 ? 'all' : 'none',
    transform:
      ['CE', 'AR'].indexOf(type) > -1
        ? 'translateY(360px)'
        : 'translateY(410px)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Guias Evangelizadores</p>
          </div>
          <div>
            <p>Inclusão</p>
          </div>
        </AlteredHeader>

        <Form ref={formRef} onSubmit={handlePreSubmit}>
          <CatContainer>
            <CatButton marked={type === 'CC'} type="button" onClick={handleCC}>
              <p>Casal CC</p>
            </CatButton>
            <CatButton marked={type === 'CE'} type="button" onClick={handleCE}>
              <p>Casal EED</p>
            </CatButton>
            <CatButton marked={type === 'AR'} type="button" onClick={handleAR}>
              <p>Animador</p>
            </CatButton>
          </CatContainer>

          <FormContent>
            <CatContainer style={catCC}>
              <CatButton
                marked={gAmount === '2'}
                type="button"
                onClick={handle2G}
              >
                <p>Ambos Guias</p>
              </CatButton>
              <CatButton
                marked={gAmount === '1'}
                type="button"
                onClick={handle1G}
              >
                <p>Apenas um Guia</p>
              </CatButton>
              <CatButton
                marked={gAmount === '0'}
                type="button"
                onClick={handle0G}
              >
                <p>
                  Ambos <strong>NÃO</strong> guias
                </p>
              </CatButton>
            </CatContainer>
            <FormCat style={typeSelected}>
              <div>
                <div>
                  <p>Coord. Nacional:</p>
                  <div>
                    <SelectV2
                      name="comboANB"
                      content={anbs}
                      initial="Selecione"
                      onChange={handleANBSelection}
                    />
                  </div>
                </div>
                <div>
                  <p>Coord. Local:</p>
                  <div>
                    <SelectV2
                      name="comboLoc"
                      content={comboLocs}
                      initial="Selecione"
                      onChange={handleLocSelection}
                    />
                  </div>
                </div>
                <div>
                  <p>Evangelizador 1:</p>
                  <div>
                    {type === 'CC' && gAmount === '0' ? (
                      <InputDialog
                        name="evang1i"
                        containerStyle={{
                          padding: '5px 2px 4.82px 5px',
                          width: '350px',
                        }}
                        placeholder="Primeiro evangelizador"
                      />
                    ) : (
                      <SelectV2
                        name="evang1s"
                        content={comboEvang1}
                        initial="Selecione"
                        onChange={handleEvang1Selection}
                      />
                    )}
                  </div>
                </div>
                {type !== 'AR' && (
                  <div>
                    <p>Evangelizador 2:</p>
                    <div>
                      {type === 'CC' && gAmount !== '2' ? (
                        <InputDialog
                          name="evang2i"
                          containerStyle={{
                            padding: '5px 2px 4.82px 5px',
                            width: '350px',
                          }}
                          placeholder="Segundo evangelizador"
                        />
                      ) : (
                        <SelectV2
                          name="evang2s"
                          content={comboEvang2}
                          initial="Selecione"
                        />
                      )}
                    </div>
                  </div>
                )}
                <div>
                  <div>
                    <span>
                      <p>Data Inclusão:</p>
                      <InputDialog
                        name="dtinc"
                        onBlur={handleDate}
                        containerStyle={{
                          padding: '5px 2px 4.82px 5px',
                          width: '172.5px',
                          height: '35px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                      />
                    </span>
                    <span>
                      <p>Data Validade:</p>
                      <InputDialog
                        // disabled
                        name="dtend"
                        containerStyle={{
                          padding: '5px 2px 4.82px 5px',
                          width: '172.5px',
                          height: '35px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </FormCat>
          </FormContent>

          <FinishButtonContainer style={finishButton}>
            <Button bgcolor="#00802b" type="submit">
              Finalizar
            </Button>
          </FinishButtonContainer>
        </Form>

        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <ModalDeleteContent>
              Ao clicar em confirmar você incluirá um Guia Evangelizador com a
              data expirada, resultando em:
              <div>
                <span>
                  <p>
                    <strong>
                      • Não exibição na listagem de Guias Evangelizadores;
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    <strong>
                      • Não exibição na criação de serviços e/ou atividades;
                    </strong>
                  </p>
                </span>
              </div>
              Se estiver seguro de sua decisão, clique em confirmar para
              continuar.
            </ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() =>
                setDeleteDiag((state) => ({ ...state, open: !state.open }))
              }
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleSubmit}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GEvangInsert;
