import styled from 'styled-components';
import { Header } from 'styles/sgo_wrappers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > span {
    > p {
      font-size: 16px;
      /* margin-right: 5px; */
      font-family: 'Roboto Slab', serif;
      font-weight: 500;
      color: #8a0002;
    }

    & + span {
      margin-top: 10px;
    }
  }

  padding: 5px 20px;

  @media screen and (max-width: 400px) {
    width: 100vw;
    padding: 5px;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const CoordIdentifier = styled(Header)`
  width: 100vw;
  position: relative;

  top: 0px;
  margin: 0 0 15px 0;
`;
