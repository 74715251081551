import React, { useState, useCallback, useEffect, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';

import { formatCPF } from 'utils/handleCPF';
import api from 'services/api';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import { addDays, format, subYears } from 'date-fns';
import { FGProps } from '../main';
import { Grid, GridItem } from '../insert/styles';

interface FormData {
  name: string;
  cpf: string;
  dtnasc: string;
  fres: string;
  fcel: string;
  mail: string;
  cep: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  state: string;
}

const AlunosUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { getCEP } = useUtils();

  const location = useLocation<FGProps>();
  const { goBack } = useHistory();

  const [fg] = useState<FGProps>(() => {
    return location.state ? { ...location.state } : ({} as FGProps);
  });

  const [estado, setEstado] = useState(() => fg.uf);

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const [hasState, setHasState] = useState(() => {
    return !!fg.uf;
  });

  const getUFs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/combos/comboUFs.php');

      setComboEstados(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    if (!location.state) {
      goBack();
    }

    getUFs();
  }, [getUFs, goBack, location.state]);

  const handleUFChange = useCallback(() => {
    const select = formRef.current?.getFieldValue('state');

    setEstado(select);
  }, []);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setEstado(comboEstados[index].label);
          setHasState(true);
        } else {
          setHasState(false);
          setEstado('Selecione');
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setEstado('Selecione');

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          dtnasc: yup
            .date()
            .typeError('Campo obrigatório')
            .min(
              new Date(subYears(new Date(), 80)),
              `A data de nascimento deve ser anterior a ${format(
                addDays(subYears(new Date(), 80), 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              new Date(subYears(new Date(), 18)),
              'O futuro guia deve ter no mínimo 18 anos.',
            ),
          mail: yup.lazy((val) =>
            val
              ? yup.string().email('Formato inválido de e-mail')
              : yup.string().nullable(true),
          ),
          cep: yup.string().min(9, 'Campo obrigatório'),
          address: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          state: yup.string().notOneOf(['Selecione', '']),
        });

        await schema.validate(data, { abortEarly: false });

        const send = new FormData();
        send.append('data', JSON.stringify({ ...data, epg: fg.epg }));

        await api.post('/sgo/epg_aluno_update.php', send, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Futuro Guia alterado.',
        });

        goBack();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, fg.epg, goBack],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks title="Futuro Guia - Alteração" isResponsible />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: fg.name,
            cpfprops: formatCPF(fg.cpf),
            cpf: fg.cpf,
            dtnasc: fg.dtnasc,
            fres: fg.fres,
            fcel: fg.fcel,
            mail: fg.mail,
            cep: fg.cep,
            district: fg.district,
            city: fg.city,
            address: fg.address,
          }}
        >
          <Input name="cpf" isHidden />
          <Grid>
            <GridItem>
              <span>
                <p>Nome</p>
                <Input name="name" mask="alpha" />
              </span>
              <span>
                <p>CPF</p>
                <Input name="cpfprops" disabled />
              </span>
              <span>
                <p>Data Nascimento</p>
                <Input name="dtnasc" type="date" />
              </span>
              <span>
                <p>Telefone Residencial</p>
                <Input name="fres" placeholder="(xx) xxxx-xxxx" mask="phone" />
              </span>
              <span>
                <p>Telefone Celular</p>
                <Input name="fcel" placeholder="(xx) xxxxx-xxxx" mask="phone" />
              </span>
              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-mail" />
              </span>
            </GridItem>
            <GridItem>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="xxxxx-xxx"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Endereço</p>
                <Input name="address" placeholder="Endereço" />
              </span>

              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  content={comboEstados}
                  initial={estado}
                  onChange={handleUFChange}
                  disabled={hasState}
                />
              </span>
            </GridItem>
          </Grid>
          <Button bgcolor="#00802b" type="submit">
            Alterar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AlunosUpdate;
