import styled from 'styled-components';

interface RMItemProps {
  paint: number;
}

export const HeaderContent = styled.div`
  width: 100vw;
  max-width: 1000px;
  padding: 5px 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    justify-content: center;
    span {
      display: flex;

      p {
        font-size: 15px;
        color: #767676;

        strong {
          margin-left: 3px;
          color: #332e2e;
        }

        & + p {
          margin: 0 0 0 5px;
        }
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }

    & + div {
      margin: 0 0 0 10px;
    }
  }

  @media screen and (max-width: 1000px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }

  @media screen and (max-width: 850px) {
    justify-content: space-around;
    div {
      flex-direction: column;
      span {
        p {
          font-size: 13px;
        }
        & + span {
          margin: 5px 0 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;

    div + div {
      margin: 5px 0 0 0;
    }
  }
`;

export const Total = styled.div`
  margin: 15px 0 5px 0;
  width: 100vw;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    margin-bottom: 15px;
  }

  p {
    padding: 0 5px;
    font-size: 16px;
    color: #332e2e;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;

    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 1)
    );

    strong {
      color: #2156e6;
    }

    @media screen and (max-width: 1000px) {
      border-radius: 0;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100vw;
  max-width: 1000px;
  max-height: 500px;
  overflow: scroll;
  margin: 0 0 10px 0;
  position: relative;

  border-radius: 5px;

  background: #efefef;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1000px) {
    border-radius: 0;
  }
`;

export const ContentCabec = styled.span`
  border-radius: 5px 5px 0 0;
  width: 100%;
  max-width: 1000px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;

  justify-content: space-between;
  background: #332e2e;

  span {
    padding: 0 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    strong {
      color: #efefef;
      font-size: 15px;
      width: 100%;
      text-align: right;
    }

    & + span {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    span {
      strong {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    span {
      padding: 0px;
      p {
        padding: 0 0 0 2px;
        font-size: 12px;
      }
      strong {
        text-align: center;
        font-size: 12px;
      }

      & + span {
        justify-content: center;
      }
    }
  }
`;

export const Monetary = styled.strong`
  width: 100%;
  text-align: right;

  @media screen and (max-width: 500px) {
    text-align: left;
  }
`;

export const BContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  overflow-y: auto;
`;

export const ContentItem = styled.span<RMItemProps>`
  width: 100%;

  display: flex;
  position: relative;

  justify-content: space-between;

  span {
    padding: 0 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    strong {
      width: 100%;
      color: #332e2e;
      text-align: right;
    }
    p {
      width: 100%;
      font-size: 15px;
      color: #767676;

      & + p {
        margin: 0 0 0 5px;
      }
    }

    & + span {
      justify-content: space-between;
    }
  }

  & + span {
    border-top: 2px solid #c6c6c6;
    margin: 5px 0 0 0;
  }

  @media screen and (max-width: 1000px) {
    span {
      p {
        font-size: 13px;
      }
      strong {
        font-size: 13px;
      }

      & + span {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 500px) {
    span {
      padding: 0px;
      p {
        padding: 0 0 0 2px;
        font-size: 12px;
      }
      strong {
        text-align: center;
        font-size: 12px;
      }

      & + span {
        justify-content: center;
      }
    }
  }
`;

export const AddressContainer = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  width: 100vw;
  max-width: 1000px;
  padding: 5px 10px;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  color: #6a0002;

  font-size: 15px;

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const OBSContainer = styled.div`
  margin: 10px 0 0 0;
  display: flex;

  width: 100vw;
  max-width: 1000px;
  padding: 5px 10px;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  h4 {
    font-size: 15px;
    color: #8a0002;
  }

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;
