import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Container, FloatInput, AlteredContent } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import PrintButton from 'components/PrintButton';

import api from 'services/api';
import * as S from 'styles/dialog_consult';

import { useCredentials } from 'hooks/credentials';

import { FaFileExcel, FaSearch } from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import {
  Grid,
  GridItem,
  GridSubGrid,
  ShowContainer,
  DetailsContainer,
} from './styles';

interface ListProps {
  cod: string;
  desc: string;
  sol: number;
  seg: number;
  sld: number;
  ped: number;
}
interface ListDetailsProps {
  cod: string;
  desc: string;
  loc: string;
  ldesc: string;
  qtde: number;
  solnum: number;
}

const EstoquexSolic: React.FC = () => {
  const {
    errorHandling,
    checkCredentials,
    handlePermission,
  } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<ListProps[]>([]);
  const [listRaw, setListRaw] = useState<ListProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState<S.ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const getList = useCallback(async () => {
    const send = {
      init: true,
    };
    const response = await api.get(
      `/sgo/estoque_solic.php?data=${JSON.stringify(send)}`,
    );

    setList(response.data);
    setListRaw(response.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    handlePermission(['NAC'], true);
    checkCredentials();
    getList();
  }, [getList, checkCredentials, handlePermission]);

  const handleSearch = useCallback(
    (e: string) => {
      setSearchValue(e);

      setList(
        listRaw.filter(
          (item: ListProps) =>
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(e).toLowerCase()) ||
            removeAcento(item.cod)
              .toLowerCase()
              .includes(removeAcento(e).toLowerCase()),
        ),
      );
    },
    [listRaw],
  );

  const handleShow = useCallback(
    async (matcod: string) => {
      try {
        setLoading(true);
        const send = {
          matcod,
        };
        const response = await api.get<ListDetailsProps[]>(
          `/sgo/estoque_solic.php?data=${JSON.stringify(send)}`,
        );
        setLoading(false);

        const total = response.data
          .map((item: ListDetailsProps) => item.qtde)
          .reduce((accum, curr) => {
            return accum + curr;
          });

        setShow({
          title: `${response.data[0].desc}`,
          open: true,
          content: (
            <DetailsContainer>
              {response.data.map((item: ListDetailsProps) => (
                <div>
                  <h4>{item.ldesc}</h4>
                  <span>
                    <p>
                      Solicitação N° <strong>{item.solnum}</strong>
                    </p>
                    <p>
                      Quantidade Solicitada: <strong>{item.qtde}</strong>
                    </p>
                  </span>
                </div>
              ))}

              <h4>
                Total Solicitado: <strong>{total}</strong>
              </h4>
            </DetailsContainer>
          ),
        });
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    [errorHandling],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <PrintButton icon={FaFileExcel} linkTo="/sgo/xls_previa_pedido.php" />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <FloatInput>
        <input
          placeholder="Filtrar por descrição ou código"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent>
        <Grid>
          {list.map((item: ListProps) => (
            <GridItem>
              <h4>
                {item.cod} - {item.desc}
              </h4>
              <GridSubGrid>
                <span>
                  <p>
                    Solicitado: <strong>{item.sol}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Estoque Segurança: <strong>{item.seg}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Estoque Atual: <strong>{item.sld}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Sugestão de Pedido: <strong>{item.ped}</strong>
                  </p>
                </span>
                {item.sol > 0 && (
                  <ShowContainer>
                    <button type="button" onClick={() => handleShow(item.cod)}>
                      <FaSearch />
                    </button>
                  </ShowContainer>
                )}
              </GridSubGrid>
            </GridItem>
          ))}
        </Grid>
        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })}
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default EstoquexSolic;
