import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Container, FloatInput, AlteredContent } from 'styles/sgo_wrappers';
import { useHistory } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { ShowProps } from 'styles/dialog_consult';
import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FaSearch } from 'react-icons/fa';
import * as O from 'styles/option_buttons';
import * as S from 'styles/dialog_consult';
import { deconvertSpecialChars, removeAcento } from 'utils/specialChars';
import { Grid, GridItem, ModalContent } from './styles';

interface AjusteProps {
  cod: string;
  desc: string;
  manut: string;
  mov: string;
  movdesc: string;
  oldqtd: number;
  nowqtd: number;
  just: string;
  who: string;
}

const EstoqueAjustes: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { checkCredentials } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [ajustes, setAjustes] = useState<AjusteProps[]>([]);
  const [ajustesRaw, setAjustesRaw] = useState<AjusteProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const getAjustes = useCallback(async () => {
    const response = await api.get('/sgo/estoque_ajustes.php');
    setAjustesRaw(response.data);
    setAjustes(response.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (['INT', 'GUI'].indexOf(user.perfil) > -1) {
      addToast({
        type: 'error',
        title: 'Ação não permitida',
        description: 'Área restrita.',
      });

      history.replace('/sgo');
      return;
    }
    checkCredentials();
    getAjustes();
  }, [user.perfil, checkCredentials, getAjustes, addToast, history]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      setAjustes(
        ajustesRaw.filter(
          (item: AjusteProps) =>
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()) ||
            removeAcento(item.cod)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase()),
        ),
      );
    },
    [ajustesRaw],
  );

  return (
    <Container>
      <Loading isLoading={loading} />

      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <FloatInput>
        <input
          placeholder="Filtrar por descrição ou código"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent>
        <Grid>
          {ajustes.map((item: AjusteProps) => (
            <GridItem isEnter={item.mov === 'E'}>
              <h4>
                {item.cod} - {item.desc}
              </h4>
              <span>
                <p>
                  Quantidade anterior: <strong>{item.oldqtd}</strong>
                </p>
              </span>
              <span>
                <p>
                  Quantidade atual:{' '}
                  <strong
                    style={{
                      color: item.nowqtd > item.oldqtd ? '#2156E6' : '#8a0002',
                    }}
                  >
                    {item.nowqtd}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Alterado em: <strong>{item.manut}</strong>
                </p>
              </span>
              <O.GridOptions>
                <O.Show
                  onClick={() =>
                    setShow({
                      title: `${item.cod} - ${item.desc}`,
                      open: true,
                      content: (
                        <ModalContent>
                          <h4>
                            {item.cod} - {item.desc}
                          </h4>
                          <span>
                            <p>
                              Quantidade anterior:{' '}
                              <strong>{item.oldqtd}</strong>
                            </p>
                            <p>
                              Quantidade atual:{' '}
                              <strong
                                style={{
                                  color:
                                    item.nowqtd > item.oldqtd
                                      ? '#2156E6'
                                      : '#8a0002',
                                }}
                              >
                                {item.nowqtd}
                              </strong>
                            </p>
                          </span>
                          <span>
                            <p>
                              Alterado por: <strong>{item.who}</strong>
                            </p>
                          </span>
                          <span>
                            <p>
                              em: <strong>{item.manut}</strong>
                            </p>
                          </span>
                          <span>
                            <p>
                              Justificativa:{' '}
                              <strong>
                                {deconvertSpecialChars(item.just)}
                              </strong>
                            </p>
                          </span>
                        </ModalContent>
                      ),
                    })
                  }
                >
                  <FaSearch />
                </O.Show>
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })}
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default EstoqueAjustes;
