import React from 'react';

// import { Link, useLocation } from 'react-router-dom';
import { Container, Content } from 'styles/wrappers';
import { AlteredHeader } from 'styles/sgo_wrappers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
// import { Common, Mundo } from '../styles';
import PeloMundoCoords from './more';

const PeloMundo: React.FC = () => {
  // const { pathname } = useLocation();
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida pelo Mundo</div>
        </AlteredHeader>
        {/* <Common>
          <Mundo>
            <p>
              “Então Jesus se aproximou e falou: ‘Toda a autoridade foi dada a
              mim no céu e sobre a terra. Portanto, vão e façam com que todos os
              povos se tornem meus discípulos, batizando-os em nome do Pai, e do
              Filho, e do Espírito Santo, e ensinando-os a observar tudo o que
              ordenei a vocês’” (Mt 28,18-20).
            </p>

            <p>
              “É na história dos movimentos onde se vê a ação do Espírito Santo”
              - Ignacio Larrañaga
            </p>
            <p>
              O serviço dos TOV emergiu dos Encontros de Experiência de Deus
              (EED) em 1984, exatamente dez anos depois de frei Ignacio
              Larrañaga celebrar ininterruptamente os EEDs.
            </p>
            <p>
              Era o ano de 1984. Decidi em minha intimidade fazer algo. Que
              fazer? Derramar na ampla sementeira do povo de Deus a mesma
              semente que vinha depositando nos corações dos assistentes dos
              Encontros, semana a semana. Isso sim, com distinta metodologia e
              estruturação.
            </p>
            <p>
              A partir dessa data, os TOV tiveram uma notável expansão – entre
              1985 e 1987, os TOV já estavam presentes em dezessete países.
              Passaram-se os anos e os resultados continuaram prodigiosos.
            </p>
            <p>
              Agora, estamos em 2017. Transcorridos trinta e três anos, os TOV
              estão presentes de norte a sul, de leste a oeste, em cinco
              continentes, representados por mais de 18.000 Guias atuantes, sem
              outra luz e guia senão a que no seu coração arde: a Fé.
            </p>
            <p>
              Olhando retrospectivamente, nosso coração estremece de emoção e
              gratidão ao comprovar como o Pai, em sua zelosa Providência, foi
              programando nossos passos e nossos dias, ao contemplar como
              milhares de pessoas recuperaram, com nosso serviço, o encanto pelo
              Senhor e o encanto pela vida e, com sua vocação apostólica
              redescoberta, trabalham hoje na primeira fila da Igreja. Podemos
              reconhecer, e com todo o gozo no espírito, que dentro da Igreja e
              da sociedade, “somos um presente de Deus”, para a humanidade. Uma
              resposta para o mundo de hoje.
            </p>
          </Mundo>
        </Common> */}

        <PeloMundoCoords />
      </Content>
      <Footer />
    </Container>
  );
};

export default PeloMundo;
