import styled from 'styled-components';

import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

export const YearContainer = styled.div`
  position: sticky;
  margin: 10px auto 0 auto;
  top: 60px;
  z-index: 1;
  background: #efefef;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  width: 100vw;
  max-width: 175px;

  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 24px;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 27px;
    width: 27px;
  }
`;

export const LeftSVG = styled(FaArrowCircleLeft)`
  left: 15px;
`;
export const RightSVG = styled(FaArrowCircleRight)`
  right: 15px;
`;

export const Grid = styled.div`
  margin-top: 15px;
  width: 100vw;
  max-width: 1200px;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 35px;
  row-gap: 25px;
  position: relative;

  @media screen and (max-width: 1300px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #efefef;

  position: relative;

  border: 2px solid #a6a6a6;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  font-size: 16px;

  transition: all 0.5s;
  padding: 5px 10px;

  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 3px 0;

    > strong {
      text-decoration: underline;
    }
    p {
      color: #767676;
      strong {
        color: #332e2e;
      }
    }
  }

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;
  /* flex-direction: column; */
  span + span {
    margin-top: 7px;
  }
`;
