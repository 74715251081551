import React, { useCallback } from 'react';

import { FaChevronCircleUp } from 'react-icons/fa';
import { Container } from './styles';
import { useScrolling } from '../../hooks/scrolling';

const ScrollTop: React.FC = () => {
  const { scrolled } = useScrolling();

  const handleScrollTop = useCallback(() => {
    // window.scrollTo(0, 0);
    const element = document.querySelector("header[data-tag='header']");
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Container onClick={handleScrollTop} active={scrolled >= 250}>
      <FaChevronCircleUp title="Voltar ao topo" />
    </Container>
  );
};

export default ScrollTop;
