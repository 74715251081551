import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { useLocation, useHistory, Link } from 'react-router-dom';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import {
  FaAward,
  FaBook,
  FaChurch,
  FaComments,
  FaPrint,
  FaShoePrints,
  FaUserCheck,
  FaUsers,
} from 'react-icons/fa';
import { Grid, GridItem } from './styles';

export interface FIGuiaProps {
  gseq: string;
  name: string;
}

export const FIGMenu: React.FC = () => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation<FIGuiaProps>();
  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const { goBack } = useHistory();
  const [gState, setGState] = useState({} as FIGuiaProps);

  useEffect(() => {
    if (!location.state) {
      goBack();
    }
    const { gseq, name } = location.state;
    setGState({ gseq, name });
  }, [goBack, location.state]);

  const handleDownload = useCallback(() => {
    if (anchorRef.current) {
      setShow((state) => ({ ...state, open: !state.open }));

      anchorRef.current.click();

      const timer = setTimeout(() => {
        setShow((state) => ({ ...state, open: false }));
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <Container>
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>Ficha Individual do Guia</div>
          <div>{gState.name}</div>
        </AlteredHeader>
        <Grid>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/outras_atividades`,
                state: { ...gState },
              }}
            >
              <p>Outras Atividades</p>
              <span>
                <FaChurch />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/historia`,
                state: { ...gState },
              }}
            >
              <p>História do Guia</p>
              <span>
                <FaBook />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/servicos`,
                state: { ...gState },
              }}
            >
              <p>Serviços</p>
              <span>
                <FaUsers />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/assistencias`,
                state: { ...gState },
              }}
            >
              <p>Participações em Eventos</p>
              <span>
                <FaUserCheck />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/dialogo_fraterno`,
                state: { ...gState },
              }}
            >
              <p>Diálogo Fraterno</p>
              <span>
                <FaComments />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/funcoes`,
                state: { ...gState },
              }}
            >
              <p>Funções Exercidas</p>
              <span>
                <FaAward />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <Link
              to={{
                pathname: `${location.pathname}/trajetoria`,
                state: { ...gState },
              }}
            >
              <p>Trajetória por Coordenações</p>
              <span>
                <FaShoePrints style={{ transform: 'rotate(-90deg)' }} />
              </span>
            </Link>
          </GridItem>
          <GridItem>
            <button type="button" onClick={handleDownload}>
              <p>Impressão da FIG</p>
              <span>
                <FaPrint />
              </span>
            </button>
          </GridItem>
        </Grid>

        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <a
        style={{ display: 'none' }}
        ref={anchorRef}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_fig.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          gseq: gState.gseq,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        FIG
      </a>
      <SGOFooter />
    </Container>
  );
};

export default FIGMenu;
