import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import SelectV2 from 'components/SelectV2';

import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import { parseISO, isBefore } from 'date-fns';

import {
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { formatNumber } from 'utils/calcTotal';

import { formatDate } from 'utils/formatDate';
import { useSpring } from 'react-spring';
import { useWindow } from 'hooks/window';
import { FaMobileAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import RadioContainer from 'components/RadioContainer';
import { ContentProps } from 'components/RadioContainer';
import {
  FilterContainer,
  LeftSVG,
  RightSVG,
  RotateMessage,
  ContentContainer,
  ContentHeader,
  Contents,
  Data,
  Lanc,
  Compl,
  Mov,
  Saldo,
  Wrapper,
  PreviousBalance,
  Balance,
} from './styles';

import { LocalStorageProps, CoordProps } from '../main';
import { monthList, MonthListProps } from '../../monthList';

interface ListProps {
  date: string;
  lanc: string;
  landesc: string;
  compl: string;
  value: number;
  type: string;
  seq: number;
  apcod: string;
  month: number;
  year: number;
}

interface AppsProps {
  apcod: string;
  apdesc: string;
}

const Aplic: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const radioForm = useRef<FormHandles>(null);

  const location = useLocation();
  const { width } = useWindow();
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [app] = useState(
    location.pathname.replace('/sgo/tesouraria/appl/', ''),
  );

  const [selectedApp, setSelectedApp] = useState('');
  const [apps, setApps] = useState<ContentProps[]>([]);

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [initialNac, setInitialNac] = useState(() => {
    const { anbg }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
    );

    return anbg || user.anbc;
  });

  const [coordSelected, setCoordSelected] = useState(() => {
    const { anbg, anbgdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
    );

    if (anbg) {
      return { cod: anbg, desc: anbgdesc };
    }
    return { cod: user.anbc, desc: user.anbdesc } as CoordProps;
  });

  const [year, setYear] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_APLIC}`);

    if (hasItem > 0) {
      const { ano }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_APLIC}`,
      );

      if (ano) {
        return ano;
      }
    }
    return new Date(parseISO(new Date().toISOString())).getUTCFullYear();
  });

  const [month, setMonth] = useState<MonthListProps>(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_APLIC}`);

    if (hasItem > 0) {
      const { mes }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_APLIC}`,
      );

      if (mes) {
        return {
          value: mes,
          label:
            monthList[
              monthList.findIndex((item: MonthListProps) => item.value === mes)
            ].label,
        };
      }
    }
    const monthNow =
      new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;
    return {
      value: monthNow,
      label:
        monthList[
          monthList.findIndex((item: MonthListProps) => item.value === monthNow)
        ].label,
    };
  });

  const [lastDay, setLastDay] = useState(new Date().toISOString());
  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const handleLastDay = useCallback(() => {
    const { ano, mes }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
    );

    const yearForList =
      ano || new Date(parseISO(new Date().toISOString())).getUTCFullYear();
    const monthForList =
      mes || new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;

    const last = new Date(yearForList, monthForList, 0);
    const today = new Date();

    const monthForLast = today.getUTCMonth() + 1;

    setLastDay(
      isBefore(last, today) || monthForLast !== monthForList
        ? last.toISOString()
        : today.toISOString(),
    );
  }, []);

  const getList = useCallback(
    async (params?: string) => {
      try {
        setLoading(true);
        handleLastDay();

        const response = await api.get<{
          fetchlist: ListProps[];
          aplics: AppsProps[];
        }>(
          `/sgo/aplic_geral.php?data=${JSON.stringify({
            code: params,
          })}`,
        );
        const { fetchlist, aplics } = response.data;

        setRawList(fetchlist);
        setSelectedApp(aplics[0].apcod);
        setApps(
          aplics.map((item: AppsProps) => ({
            id: item.apcod,
            value: item.apcod,
            label: item.apdesc,
          })),
        );

        const { ano, mes }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_APLIC}`,
        );

        const yearForList =
          ano || new Date(parseISO(new Date().toISOString())).getUTCFullYear();
        const monthForList =
          mes || new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;

        setList(
          fetchlist.filter(
            (item: ListProps) =>
              item.month === monthForList &&
              item.year === yearForList &&
              item.apcod === aplics[0].apcod,
          ),
        );

        setLoading(false);
      } catch (err) {
        errorHandling(err);
      }
    },
    [errorHandling, handleLastDay],
  );

  useEffect(() => {
    setLocalStorage(`${process.env.REACT_APP_APLIC}`, { app });
    handlePermission(['ZON'], true);

    getComboANB();

    const { anbg }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
    );

    getList(anbg || coordSelected.cod);
  }, [
    app,
    coordSelected.cod,
    getComboANB,
    getList,
    handlePermission,
    user.perfil,
  ]);

  const handleANBselect = useCallback(() => {
    const nac = formRef?.current?.getFieldValue('comboANB');
    const index = comboNacs.findIndex((item: ComboProps) => item.value === nac);

    setInitialNac(comboNacs[index].label);

    getList(nac);
    const selectedANB = {
      cod: nac,
      desc: comboNacs[index].label,
    };

    setCoordSelected(selectedANB);

    setLocalStorage(`${process.env.REACT_APP_APLIC}`, {
      anbg: nac,
      anbgdesc: comboNacs[index].label,
    });
  }, [comboNacs, getList]);

  const handleLessMonth = useCallback(() => {
    const newMonth = month.value - 1;
    const index = monthList.findIndex(
      (item: MonthListProps) => item.value === newMonth,
    );

    setMonth({
      value: newMonth === 0 ? 12 : newMonth,
      label: index < 0 ? 'Dezembro' : monthList[index].label,
    });

    setYear(newMonth === 0 ? year - 1 : year);

    setLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
      newMonth === 0
        ? {
            mes: 12,
            ano: year - 1,
          }
        : { mes: newMonth, ano: year },
    );

    handleLastDay();
    setList(
      rawList.filter((item: ListProps) =>
        newMonth === 0
          ? item.year === year - 1 &&
            item.month === 12 &&
            item.apcod === selectedApp
          : item.year === year &&
            item.month === newMonth &&
            item.apcod === selectedApp,
      ),
    );
  }, [handleLastDay, month.value, rawList, selectedApp, year]);

  const handleMoreMonth = useCallback(() => {
    const newMonth = month.value + 1;
    const index = monthList.findIndex(
      (item: MonthListProps) => item.value === newMonth,
    );

    setMonth({
      value: newMonth === 13 ? 1 : newMonth,
      label: index < 0 ? 'Janeiro' : monthList[index].label,
    });

    setYear(newMonth === 13 ? year + 1 : year);

    setLocalStorage(
      `${process.env.REACT_APP_APLIC}`,
      newMonth === 13
        ? {
            mes: 1,
            ano: year + 1,
          }
        : { mes: newMonth, ano: year },
    );
    handleLastDay();
    setList(
      rawList.filter((item: ListProps) =>
        newMonth === 13
          ? item.year === year + 1 &&
            item.month === 1 &&
            item.apcod === selectedApp
          : item.year === year &&
            item.month === newMonth &&
            item.apcod === selectedApp,
      ),
    );
  }, [handleLastDay, month.value, rawList, selectedApp, year]);

  const saldoAnterior = useMemo(() => {
    const dateNow = new Date(year, month.value - 1, 1);
    return rawList.reduce((accum, { date, value, type, apcod }: ListProps) => {
      return isBefore(new Date(parseISO(date)), dateNow)
        ? apcod === selectedApp
          ? type === 'E'
            ? accum + value
            : accum - value
          : accum
        : accum;
    }, 0);
  }, [month.value, rawList, selectedApp, year]);

  const saldoAtual = useMemo(() => {
    // return 0;
    const dateNow = new Date(year, month.value, 1);
    return rawList.reduce((accum, { date, value, type, apcod }: ListProps) => {
      return isBefore(new Date(parseISO(date)), dateNow)
        ? apcod === selectedApp
          ? type === 'E'
            ? accum + value
            : accum - value
          : accum
        : accum;
    }, 0);
  }, [month.value, rawList, selectedApp, year]);

  const monthlyEntrances = useMemo(() => {
    return list.reduce((accum, { value, type }: ListProps) => {
      return type === 'E' ? accum + value : accum;
    }, 0);
  }, [list]);

  const monthlyOutcomes = useMemo(() => {
    return list.reduce((accum, { value, type }: ListProps) => {
      return type === 'S' ? accum + value : accum;
    }, 0);
  }, [list]);

  const shrinkCompl = useSpring({
    width: width >= 640 ? '184px' : '0px',
    opacity: width >= 640 ? 1 : 0,
    height: width >= 640 ? 'auto' : 0,
    padding: width >= 640 ? '3px' : '0px',
  });

  const shrinkLanc = useSpring({
    width: width >= 470 ? '142px' : '0px',
    opacity: width >= 470 ? 1 : 0,
    height: width >= 470 ? 'auto' : 0,
    padding: width >= 470 ? '3px' : '0px',
  });

  const shrinkMov = useSpring({
    width: '100px',
    // width: width >= 475 ? '100px' : '80px',
  });

  const tableHeight = useSpring({
    maxHeight: '480px',
    height: '100%',
  });

  const showMessage = useSpring({
    opacity: width >= 640 ? 0 : 1,
    height: width >= 640 ? 0 : 'auto',
  });

  const prevBalance = useSpring({
    opacity: !loading ? 1 : 0,
    transform: !loading ? 'translateX(0px)' : 'translateX(20px)',
  });

  const handleRadioClick = useCallback(() => {
    const ap = radioForm.current?.getFieldValue('app');
    setSelectedApp(ap);
    setList(() =>
      rawList.filter(
        (item: ListProps) =>
          item.year === year && item.month === month.value && item.apcod === ap,
      ),
    );
  }, [month, rawList, year]);

  return (
    <Container>
      <Loading isLoading={loading} />

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {['ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac || 'Selecione'}
                  />
                </span>
              )}
            </div>
          </Form>
        }
      />

      <Content>
        {!!coordSelected.desc && (
          <AlteredHeader>{coordSelected.desc}</AlteredHeader>
        )}
        <FilterContainer>
          <LeftSVG onClick={handleLessMonth} />
          <p>{month.label}</p>&nbsp;/&nbsp;<p>{year}</p>
          <RightSVG onClick={handleMoreMonth} />
        </FilterContainer>

        <RotateMessage style={showMessage}>
          <span>
            <p>Para mais informações dos lançamentos, vire seu aparelho</p>
          </span>
          <span>
            <FaMobileAlt />
          </span>
        </RotateMessage>

        {apps.length > 1 && (
          <Form ref={radioForm} onSubmit={() => null}>
            <div>
              <RadioContainer
                title=" "
                onClick={handleRadioClick}
                name="app"
                content={apps}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={apps[0].id}
              />
            </div>
          </Form>
        )}

        <PreviousBalance style={prevBalance}>
          <p>Saldo anterior:&nbsp;</p>
          <p
            style={
              {
                /* color: saldoAtual < 0 ? 'red' : 'green' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(saldoAnterior)}</strong>
          </p>
        </PreviousBalance>
        <ContentContainer style={tableHeight}>
          <ContentHeader>
            <Data>
              <p>Data</p>
            </Data>
            <Lanc style={shrinkLanc}>
              <p>Lançamento</p>
            </Lanc>
            <Compl style={shrinkCompl}>
              <p>Complemento</p>
            </Compl>
            <Mov style={shrinkMov}>
              <p>Mov{width >= 475 ? 'imento' : '.'}</p>
            </Mov>
            <Saldo>
              <p>Saldo</p>
            </Saldo>
          </ContentHeader>
          <Contents>
            {list.length > 0 ? (
              list.map((item: ListProps, index) => (
                <Wrapper key={item.seq} paint={index}>
                  <Data>
                    <p>{formatDate(item.date)}</p>
                  </Data>
                  <Lanc style={shrinkLanc}>
                    <p>{item.landesc}</p>
                  </Lanc>
                  <Compl style={shrinkCompl}>
                    <p>{item.compl}</p>
                  </Compl>
                  <Mov style={shrinkMov}>
                    <p
                      style={{
                        color: item.type === 'E' ? '#2156e6' : '#c53030',
                      }}
                    >
                      {`${item.type === 'E' ? '+' : '-'}${formatNumber(
                        item.value,
                      )}`}
                    </p>
                  </Mov>
                  <Saldo>
                    <p
                      style={
                        {
                          // color:
                          //   list.reduce(
                          //     (accum, { value, type }: ListProps, indexNow) => {
                          //       if (index === 0 && indexNow === 0) {
                          //         return type === 'E'
                          //           ? accum + value
                          //           : accum - value;
                          //       }
                          //       if (indexNow <= index) {
                          //         return type === 'E'
                          //           ? accum + value
                          //           : accum - value;
                          //       }
                          //       return accum;
                          //     },
                          //     saldoAnterior,
                          //   ) < 0
                          //     ? 'red'
                          //     : 'green',
                        }
                      }
                    >
                      R$&nbsp;
                      {formatNumber(
                        list.reduce(
                          (accum, { value, type }: ListProps, indexNow) => {
                            if (index === 0 && indexNow === 0) {
                              return type === 'E'
                                ? accum + value
                                : accum - value;
                            }

                            if (indexNow <= index) {
                              return type === 'E'
                                ? accum + value
                                : accum - value;
                            }
                            return accum;
                          },
                          saldoAnterior,
                        ),
                      )}
                    </p>
                  </Saldo>
                </Wrapper>
              ))
            ) : (
              <Wrapper paint={0}>
                <Data>
                  <p>{formatDate(lastDay)}</p>
                </Data>
                <Lanc style={shrinkLanc}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Lanc>
                <Compl style={shrinkCompl}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Compl>
                <Mov style={shrinkMov}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Mov>
                <Saldo>
                  <p
                    style={
                      {
                        /* color: saldoAtual < 0 ? 'red' : 'green' */
                      }
                    }
                  >
                    R$ {formatNumber(saldoAtual)}
                  </p>
                </Saldo>
              </Wrapper>
            )}
          </Contents>
        </ContentContainer>
        <Balance style={prevBalance}>
          <p>Total de Entradas:&nbsp;</p>
          <p
            style={
              {
                /* color: 'green' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(monthlyEntrances)}</strong>
          </p>
        </Balance>
        <Balance style={prevBalance}>
          <p>Total de Saídas:&nbsp;</p>
          <p
            style={
              {
                /* color: 'red' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(monthlyOutcomes)}</strong>
          </p>
        </Balance>

        {/* <p>Saldo Anterior: R$ {formatNumber(saldoAnterior)}</p>
        <p>Saldo Atual: R$ {formatNumber(saldoAtual)}</p>
        <p>Entradas: R$ {formatNumber(monthlyEntrances)}</p>
        <p>Saídas: R$ {formatNumber(monthlyOutcomes)}</p>
        <p>
          Batimentos Entradas: R${' '}
          {formatNumber(saldoAnterior + monthlyEntrances)}
        </p>
        <p>
          Batimentos Saídas: R$ {formatNumber(saldoAtual + monthlyOutcomes)}
        </p> */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Aplic;
