import React, {
  useState,
  useCallback,
  ChangeEvent,
  HTMLAttributes,
  useEffect,
} from 'react';

import { useSpring, animated } from 'react-spring';
import { FaSearch } from 'react-icons/fa';

import { removeAcento } from 'utils/specialChars';

import { Container, InputWrapper, ItemsWrapper } from './styles';

export interface ArrayProps {
  id: string | number;
  label: string;
}

export interface CreateArrayProps {
  [key: string]: string | number | boolean;
}

interface AutoCompleteProps extends HTMLAttributes<HTMLDivElement> {
  placeholder: string;
  array?: ArrayProps[];
  chars?: number;
  inputRef: React.RefObject<HTMLInputElement>;
  inputVal?: string;
  buttonCallback?: () => void;
  appearSearch?: boolean;
  containerStyle?: object;
  inputStyle?: object;
  itemsStyle?: object;
}

export function removeDups(
  array: CreateArrayProps[],
  dupkey: string,
): CreateArrayProps[] {
  return array.filter(
    (item, index) =>
      array.map((ff) => ff[dupkey]).indexOf(item[dupkey]) === index,
  );
}

export function createArray(
  array: CreateArrayProps[],
  idKey: string,
  labelKey: string,
): ArrayProps[] {
  return array.map((item) => ({
    id: item[idKey].toString(),
    label: item[labelKey].toString(),
  }));
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  placeholder = '',
  array = [],
  chars = 1,
  inputRef,
  buttonCallback,
  appearSearch = false,
  containerStyle = {},
  inputStyle = {},
  itemsStyle = {},
  inputVal = '',
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState('');
  const [focused, setFocused] = useState(false);
  const [received, setReceived] = useState<ArrayProps[]>(array);
  const [results, setResults] = useState<ArrayProps[]>([]);

  const handleSearch = useCallback(
    (value: string) => {
      setSelected('');
      setSearchValue(value);

      if (value.trim().length >= chars) {
        const filtered = received
          .filter(
            (item, index) =>
              received.map((ff) => ff.label).indexOf(item.label) === index,
          )
          .filter((item) =>
            removeAcento(item.label.toString().toLowerCase()).includes(
              removeAcento(value.toLowerCase()),
            ),
          )
          .map((item) => ({ id: item.id, label: item.label }));
        setResults(filtered);
        return;
      }

      setResults([]);
    },
    [chars, received],
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleSelection = useCallback(
    (ev) => {
      const { id } = ev.currentTarget.dataset;
      // selection: string | number | boolean
      setTimeout(() => {
        const index = results.findIndex((item) => item.id === id);

        if (index > -1) {
          setSearchValue(results[index].label.toString());
          handleSearch(results[index].label.toString());
          setSelected(results[index].label.toString());
        }
      }, 100);
    },
    [results, handleSearch],
  );

  useEffect(() => {
    setReceived(array);
    setSearchValue(inputVal);
    handleSearch(inputVal);
  }, [array, handleSearch, inputVal]);

  const styledAppear = useSpring({
    opacity: focused && results.length > 0 ? 1 : 0,
    maxHeight: focused && results.length > 0 ? '150px' : '0px',
    pointerEvents: results.length > 0 ? 'all' : 'none',
  });

  const styledButton = useSpring({
    opacity:
      selected !== '' || (!!appearSearch && searchValue.length >= chars)
        ? 1
        : 0,
    pointerEvents:
      selected !== '' || (!!appearSearch && searchValue.length >= chars)
        ? 'all'
        : 'none',
  });

  return (
    <Container style={containerStyle} {...rest}>
      <InputWrapper style={inputStyle}>
        <input
          ref={inputRef}
          placeholder={placeholder}
          value={searchValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
          type="text"
        />
        {!!buttonCallback && (
          <animated.button
            style={styledButton}
            type="button"
            onClick={buttonCallback}
          >
            <FaSearch />
          </animated.button>
        )}
      </InputWrapper>
      <ItemsWrapper style={{ ...styledAppear, ...itemsStyle }}>
        {results.map((item) => (
          <span key={item.id}>
            <button type="button" data-id={item.id} onClick={handleSelection}>
              <p>{item.label}</p>
            </button>
          </span>
        ))}
      </ItemsWrapper>
    </Container>
  );
};

export default AutoComplete;
