import styled, { css } from 'styled-components';

interface FGInfoProps {
  selected: boolean;
}

export const Wrapper = styled.div`
  width: 100vw;
  padding: 0px 5px;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  margin: 15px auto;
  max-width: 1366px;
  /* justify-items: center; */

  gap: 20px;

  @media screen and (max-width: 700px) {
    gap: 15px;
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 500px) {
    gap: 10px;
    grid: auto / 1fr;
  }
`;

export const FGInfo = styled.button<FGInfoProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0px 0px 10px 0;

  font-size: 16px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  strong {
    /* font-size: 16px; */
    color: #332e2e;
  }
  p {
    /* font-size: 16px; */
    color: #767676;
  }

  transition: all 0.35s ease;

  ${(props) =>
    props.selected &&
    css`
      /* opacity: 0.8; */
      /* pointer-events: none; */
      background: #d3e4d4;
      border: 3px solid #00802b;
    `}

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
    > p {
      opacity: 0.8;
    }

    > strong {
      opacity: 0.8;
    }
    span {
      /* p {
        color: #767676;
        strong {
          color: #332e2e;
        }
      } */
    }
    /* p {
      color: #332e2e;
    } */
  }
`;

export const Warn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 10px 0;

  /* font-size: 16px; */
`;
