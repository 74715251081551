import styled, { css, keyframes } from 'styled-components';
import { parseToRgb } from 'polished';

const rotate = keyframes`
from { transform: rotate(0deg); } to { transform: rotate(360deg);}
`;

const pulse = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }

`;

interface ZonalProps {
  bgcolor?: string;
}

export const Container = styled.div`
  width: 100vw;
  background: #fff;
`;

export const World = styled.section`
  width: 100%;
  max-width: 1000px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  img {
    width: 80%;
    max-width: 1000px;
    object-fit: cover;
    object-position: 50% 0;
  }
  div {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    h1 {
      color: #c53030;
      font-family: 'Roboto Slab', serif;
    }

    span {
      margin-top: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      p {
        width: 50%;
        text-align: right;
        font-family: 'Roboto', serif;
      }
      h4 {
        width: 50%;
        margin-left: 15px;
        font-family: 'Roboto', serif;
      }

      & + span {
        margin-top: 0;
      }
    }
  }
`;

export const Zonal = styled.section`
  width: 100%;
  max-width: 1000px;

  > figure {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 80%;
      max-width: 413px;
    }
  }
  h1 {
    width: 100%;
    text-align: center;
    background: linear-gradient(
      to right,
      rgba(138, 0, 2, 0),
      rgba(138, 0, 2, 0.35),
      rgba(138, 0, 2, 0.5),
      rgba(138, 0, 2, 0.75),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 0.75),
      rgba(138, 0, 2, 0.5),
      rgba(138, 0, 2, 0.35),
      rgba(138, 0, 2, 0)
    );
    color: #fff;
    font-family: 'Roboto Slab', serif;
  }

  div {
    margin: 10px 0;
    display: grid;
    grid: 350px / 1fr 1fr;

    > figure {
      display: flex;

      align-items: center;
      justify-content: center;

      img {
        height: 80%;
        max-height: 250px;
      }
    }

    --red: ${parseToRgb('#8a0002').red};
    --green: ${parseToRgb('#8a0002').green};
    --blue: ${parseToRgb('#8a0002').blue};

    > span {
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 75%,
        rgba(var(--red), var(--green), var(--blue), 0.75) 100%
      );

      margin: auto 0;
      height: 300px;

      padding: 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border-radius: 5px;

      border: 2px solid transparent;
      border-top-color: #8a0002;
      border-bottom-color: #8a0002;

      h2 {
        text-align: center;
        color: #c53030;
        text-decoration: underline;
        width: 100%;
      }
      h3 {
        color: #8a0002;
        text-align: center;
        font-style: italic;
        width: 100%;
      }
      /* h4 {
        font-size: 16px;
      } */
      span {
        border: 0px;
        margin-top: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        p {
          color: #6a0002;
          width: 50%;
          text-align: right;
          font-family: 'Roboto', serif;
        }
        h4 {
          width: 50%;
          margin-left: 15px;
          font-family: 'Roboto', serif;
        }

        & + span {
          margin: 3px 0 0 0;
        }
      }

      & + span {
        margin: 25px 0 0 5px;
      }
    }
  }

  & + section {
    margin-top: 20px;
  }

  @media screen and (max-width: 1000px) {
    div {
      grid: 220px / 1fr;
      justify-items: center;

      > span {
        background: transparent;
        border-radius: 10px;
        /* border-top-color: #8a0002;
        border-bottom-color: #8a0002; */
        width: 80%;
        height: auto;
        margin: 0 auto;

        span {
          text-justify: justify;
        }

        & + span {
          margin: 10px 0 0 0;
        }
      }
    }
  }
`;

export const ZonalSpan = styled.span<ZonalProps>`
  position: relative;

  ${(props) =>
    props.bgcolor
      ? css`
          --red: ${parseToRgb(`${props.bgcolor}`).red};
          --green: ${parseToRgb(`${props.bgcolor}`).green};
          --blue: ${parseToRgb(`${props.bgcolor}`).blue};
        `
      : css`
          --red: ${parseToRgb('#8a0002').red};
          --green: ${parseToRgb('#8a0002').green};
          --blue: ${parseToRgb('#8a0002').blue};
        `}

  background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 75%,
        rgba(var(--red), var(--green), var(--blue), 0.75) 100%
      ) !important;

  @media screen and (max-width: 1000px) {
    div {
      > span {
        ${(props) =>
          props.bgcolor
            ? css`
                border-top-color: ${props.bgcolor} !important;
                border-bottom-color: ${props.bgcolor} !important;
              `
            : css`
                border-top-color: #8a0002;
                border-bottom-color: #8a0002;
              `}
      }
    }
  }
`;

export const Loader = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  top: 0;
  left: 0;

  > div {
    all: unset;
    display: flex;
    background: transparent;

    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    border: 5px solid #8a0002;
    border-radius: 50%;
    border-top-color: transparent;
    animation: ${rotate} 0.5s linear infinite;
  }
`;

export const ZonalIntContainer = styled.section`
  margin: 0 0 25px 0;
  width: 100vw;
  padding: 0px 20px;

  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;

  div,
  span,
  p {
    all: unset !important;
  }

  @media screen and (max-width: 1180px) {
    grid: auto / 1fr 1fr;
    column-gap: 40px;
    row-gap: 50px;
  }
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    row-gap: 20px;
    padding: 0px;
    column-gap: 0px;
    margin: 0 auto 25px;
    /* grid: auto / 1fr;
     */
  }
`;

export const FieldSet = styled.fieldset`
  position: relative;
  overflow: hidden;
  --primary: #8a0002;
  border-radius: 5px;
  border: 3px solid black;
  background: #d6d6d6;
  background: rgb(198, 198, 198);
  background: linear-gradient(
    180deg,
    rgba(198, 198, 198, 1) 20%,
    rgba(230, 230, 230, 1) 100%
  );
  padding: 10px 20px;

  svg {
    color: #767676 !important;
  }
  legend {
    font-weight: 500;
    padding: 0 10px;
    font-size: 24px;
    color: var(--primary);
  }
  section {
    font-size: 14px;
    font-style: italic;
    transform: translateY(-10px);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
  }
  div {
    display: flex !important;
    flex-direction: column !important;
    & + div {
      margin: 20px 0 0 0 !important;
    }
    > p {
      font-weight: 500 !important;
      display: flex !important;
      align-items: center !important;
      font-size: 20px !important;
      margin: 0 0 10px 0 !important;

      > svg {
        animation: ${pulse} 1s linear infinite !important;
      }
    }
    span {
      padding: 0px 13px !important;
      display: flex !important;
      flex-direction: column !important;

      p {
        text-wrap: wrap !important;
        font-size: 18px !important;
        & + p {
          margin: 5px 0 0 0 !important;
        }
      }

      svg {
        margin: 0 10px 0 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    border-radius: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    padding: 5px 5px;
  }
`;

export const Separator = styled.div`
  --red: ${parseToRgb('#c53030').red};
  --green: ${parseToRgb('#c53030').green};
  --blue: ${parseToRgb('#c53030').blue};

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  background: rgba(var(--red), var(--green), var(--blue), 0.8);
  width: 100vw;
  height: 70px;
  margin: 10px 0;

  @media screen and (max-width: 815px) {
    font-size: 32px;
  }
  @media screen and (max-width: 520px) {
    font-size: 26px;
  }
  @media screen and (max-width: 422px) {
    text-align: center;
    font-size: 24px;
  }
`;
