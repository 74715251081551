import React, { useState, useEffect, useCallback, useRef } from 'react';

import { v4 } from 'uuid';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import IncludeButton from 'components/IncludeButton';
import InputDialog from 'components/InputDialog';
import TextareaDialog from 'components/TextareaDialog';

import {
  Container,
  Content,
  AlteredHeader,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { ComboProps, CoordProps } from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { useSpring } from 'react-spring';
import {
  FaDollarSign,
  FaFileExcel,
  FaPencilAlt,
  FaPlusCircle,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';

import * as S from 'styles/dialog_consult';
import * as U from 'styles/dialog_update';
import * as I from 'styles/dialog_include';
import * as D from 'styles/dialog_delete';
import { ShowProps } from 'styles/dialog_consult';
import { IncludeProps } from 'styles/dialog_include';
import { DeleteProps } from 'styles/dialog_delete';
import { UpdateProps } from 'styles/dialog_update';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';
import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import { parseISO } from 'date-fns';
import PrintButton from 'components/PrintButton';
import Input from 'components/Input';
import {
  Table,
  TDDesc,
  TDQtd,
  TDConsult,
  TDAlt,
  ConsultContent,
  IContentDiv,
  FilterByStatusContainer,
} from './styles';

interface LocalStorageProps {
  zoncod?: string;
  zondesc?: string;
  anbcod?: string;
  anbdesc?: string;
  cod?: string;
  desc?: string;
  status?: string;
}

interface ListProps {
  uuid: string;
  cod: string;
  seq: string;
  desc: string;
  qtd: number;
  vlrunit: number;
  vlrtot: number;
  dtaquis: string;
  status: string;
  withdrawDate: string;
  withdrawValue: string;
  withdrawObs: string;
}

interface FormData {
  desc: string;
  qtd: number;
  acqdate: string;
  vlrunit: number;
  vlrtot: number;
}

interface FormWithdrawData {
  vlrret: number;
  obs: string;
}

export const Patrimonio: React.FC = () => {
  const [mode] = useState(process.env.REACT_APP_PATRIMONIO);
  const formRef = useRef<FormHandles>(null);
  const updateForm = useRef<FormHandles>(null);
  const insertForm = useRef<FormHandles>(null);
  const deleteForm = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const { handlePermission, errorHandling } = useCredentials();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { width } = useWindow();

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(`${mode}`);

    return anbcod || (user.perfil === 'ZON' ? 'Selecione' : user.anbc);
  });
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [initialZon] = useState(() => {
    const { zoncod }: LocalStorageProps = getLocalStorage(`${mode}`);
    return zoncod || user.zoncod;
  });
  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(`${mode}`);

    return cod && desc
      ? { cod, desc }
      : user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : { cod: user.anbc, desc: user.anbdesc };
  });

  const [inc, setInc] = useState({} as IncludeProps);

  const [show, setShow] = useState({} as ShowProps);
  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);
  const [statusFilter, setStatusFilter] = useState(() => {
    const { status }: LocalStorageProps = getLocalStorage(`${mode}`);

    return status || 'A';
  });

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/ativos_list.php');
      const { status }: LocalStorageProps = getLocalStorage(`${mode}`);
      const statusOption = status || 'A';
      setRawList(response.data);
      setList(
        response.data.filter(
          (item: ListProps) =>
            item.cod === coord.cod && item.status === statusOption,
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zoncod }: LocalStorageProps = getLocalStorage(`${mode}`);

    const filterZon = zoncod || user.zoncod;

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === filterZon.substr(0, 2),
      ),
    );
  }, [mode, user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  useEffect(() => {
    handlePermission(['LOC', ' GUI']);
    getComboANB();
    getComboZON();
    getList();
  }, [getComboANB, getComboZON, getList, handlePermission]);

  const handleZONSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({ cod: value, desc: text });
    setComboNacs(
      nacs.filter((item) => item.value.substr(0, 2) === value.substr(0, 2)),
    );
    setInitialNac('Selecione');

    setLocalStorage(`${mode}`, {
      cod: value,
      desc: text,
      anbdesc: 'Selecione',
      anbcod: 'Selecione',
      zoncod: value,
      zondesc: text,
    });

    deleteLocalStorageItemKey(`${mode}`, ['anbcod']);

    setList(
      rawList.filter(
        (item) => item.cod === value && item.status === statusFilter,
      ),
    );
  }, [mode, nacs, rawList, statusFilter]);

  const handleANBSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setCoord({ cod: value, desc: text });
    setInitialNac(value);

    setLocalStorage(`${mode}`, {
      cod: value,
      desc: text,
      anbcod: value,
      anbdesc: text,
    });

    setList(
      rawList.filter(
        (item) => item.cod === value && item.status === statusFilter,
      ),
    );
  }, [mode, rawList, statusFilter]);

  const handleClearFilter = useCallback(() => {
    setInitialNac('Selecione');

    setCoord(() => {
      if (user.perfil === 'ZON') {
        setLocalStorage(`${mode}`, {
          cod: user.zoncod,
          desc: user.zondesc,
        });

        deleteLocalStorageItemKey(`${mode}`, ['anbcod', 'anbdesc']);

        setList(
          rawList.filter(
            (item) => item.cod === user.zoncod && item.status === statusFilter,
          ),
        );
        return { cod: user.zoncod, desc: user.zondesc };
      }

      const select = formRef.current?.getFieldRef('comboZON');
      const { value, text } = select.options[select.selectedIndex];

      setLocalStorage(`${mode}`, {
        cod: value,
        desc: text,
        anbdesc: 'Selecione',
        zoncod: value,
        zondesc: text,
      });

      deleteLocalStorageItemKey(`${mode}`, ['anbcod']);
      setList(
        rawList.filter(
          (item) => item.cod === value && item.status === statusFilter,
        ),
      );
      return { cod: value, desc: text };
    });
  }, [mode, rawList, statusFilter, user.perfil, user.zoncod, user.zondesc]);

  const removeFilterStyle = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  const handleTotalChange = useCallback(() => {
    const qtd = parseInt(insertForm.current?.getFieldValue('qtd'), 10);
    const vlrunit = parseFloat(
      insertForm.current
        ?.getFieldValue('vlrunit')
        .replace(/(.*){1}/, '0$1')
        .replace(/[^\d]/g, '')
        .replace(/(\d\d?)$/, '.$1'),
    );

    insertForm.current?.setFieldValue('vlrtot', (qtd * vlrunit).toFixed(2));
  }, []);

  const handleTotalChangeUpdate = useCallback(() => {
    const qtd = parseInt(updateForm.current?.getFieldValue('qtd'), 10);
    const vlrunit = parseFloat(
      updateForm.current
        ?.getFieldValue('vlrunit')
        .replace(/(.*){1}/, '0$1')
        .replace(/[^\d]/g, '')
        .replace(/(\d\d?)$/, '.$1'),
    );

    updateForm.current?.setFieldValue('vlrtot', (qtd * vlrunit).toFixed(2));
  }, []);

  const handleInsert = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        insertForm.current?.setErrors({});

        const maxDate = new Date(parseISO(new Date().toISOString()));

        const schema = yup.object().shape({
          desc: yup
            .string()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          qtd: yup
            .number()
            .typeError('Informação obrigatória')
            .min(1, 'Quantidade obrigatória'),
          acqdate: yup
            .date()
            .typeError('Data inválida.')
            .max(
              maxDate,
              'A data de aquisição deve ser igual ou anterior a hoje.',
            ),
          vlrunit: yup
            .number()
            .typeError('Informação obrigatória')
            .min(0, 'Valor deve ser maior ou igual a 0'),
          // vlrtot: yup.number().typeError('Informação obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setInc({} as IncludeProps);

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/ativos_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setRawList((state) => [
          ...state,
          {
            uuid: v4(),
            cod: coord.cod,
            seq: (state.length + 1).toString(),
            desc: data.desc,
            qtd: data.qtd,
            vlrunit: parseFloat(data.vlrunit.toString()),
            vlrtot: parseFloat(data.vlrtot.toString()),
            dtaquis: data.acqdate,
            status: 'A',
          } as ListProps,
        ]);

        setList((state) => [
          ...state,
          {
            uuid: v4(),
            cod: coord.cod,
            seq: (state.length + 1).toString(),
            desc: data.desc,
            qtd: data.qtd,
            vlrunit: parseFloat(data.vlrunit.toString()),
            vlrtot: parseFloat(data.vlrtot.toString()),
            dtaquis: data.acqdate,
            status: 'A',
          } as ListProps,
        ]);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `${data.desc} adicionado ao patrimonio.`,
          seconds: 8,
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          insertForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, coord.cod, errorHandling],
  );

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        updateForm.current?.setErrors({});

        const maxDate = new Date(parseISO(new Date().toISOString()));

        const schema = yup.object().shape({
          desc: yup
            .string()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          qtd: yup
            .number()
            .typeError('Informação obrigatória')
            .min(1, 'Quantidade obrigatória'),
          acqdate: yup
            .date()
            .typeError('Data inválida.')
            .max(
              maxDate,
              'A data de aquisição deve ser igual ou anterior a hoje.',
            ),
          vlrunit: yup
            .number()
            .typeError('Informação obrigatória')
            .min(0, 'Valor deve ser maior ou igual a 0'),
          // vlrtot: yup.number().typeError('Informação obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setUpdateDiag({} as UpdateProps);

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            seq: updateDiag.values?.seq,
          }),
        );

        await api.post('/sgo/ativos_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `${data.desc} alterado.`,
          seconds: 8,
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, getList, updateDiag.values],
  );

  const handleUpdateWithDraw = useCallback(
    async (data: FormWithdrawData) => {
      try {
        setLoading(true);
        updateForm.current?.setErrors({});

        const schema = yup.object().shape({
          obs: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setUpdateDiag({} as UpdateProps);

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            seq: updateDiag.values?.seq,
          }),
        );

        await api.post('/sgo/ativos_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `Patrimônio alterado.`,
          seconds: 8,
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, getList, updateDiag.values],
  );

  const handleExclusion = useCallback(
    async (data: FormWithdrawData) => {
      try {
        if (!deleteDiag.values?.seq) {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Erro interno. Caso o problema persista, acione o suporte ao SGO.',
          });
          return;
        }
        setLoading(true);
        deleteForm.current?.setErrors({});

        const schema = yup.object().shape({
          obs: yup
            .string()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDeleteDiag((state) => ({ ...state, open: false }));

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ seq: deleteDiag.values?.seq, ...data }),
        );

        await api.post('/sgo/ativos_delete.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Patrimônio retirado.',
        });

        getList();

        // const tempRaw = [...rawList];
        // const tempList = [...list];

        // const rawListIndex = rawList.findIndex(
        //   (item) =>
        //     item.seq === deleteDiag.values?.seq &&
        //     item.cod === (user.perfil === 'ZON' ? user.zoncod : user.anbc),
        // );
        // const listIndex = list.findIndex(
        //   (item) => item.seq === deleteDiag.values?.seq,
        // );

        // tempRaw[rawListIndex] = {...tempRaw[rawListIndex], status: 'R'};
        // tempList[listIndex] = {...tempList[listIndex], status: 'R'};

        // setRawList((state) =>
        //   state.filter((_, index) => index !== rawListIndex),
        // );
        // setList((state) => state.filter((_, index) => index !== listIndex));

        // setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          deleteForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, deleteDiag.values, errorHandling, getList],
  );

  const handleFilterStatusChange = useCallback(
    (ev) => {
      const { status } = ev.currentTarget.dataset;
      setLocalStorage(`${mode}`, {
        status,
      });
      setStatusFilter(status);
      setList(
        rawList.filter(
          (item: ListProps) => item.cod === coord.cod && item.status === status,
        ),
      );
    },
    [coord.cod, mode, rawList],
  );

  return (
    <Container>
      {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
        <>
          <IncludeButton isButton>
            <button type="button" onClick={() => setInc({ open: true })}>
              <FaPlusCircle />
            </button>
          </IncludeButton>
          <PrintButton
            icon={FaFileExcel}
            linkTo={`/sgo/xls_ativos.php?data=${JSON.stringify({
              cod: coord.cod,
            })}`}
            hasProps
          />
        </>
      )}
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONSelect}
                    initial={initialZon}
                  />
                </span>
              )}
              <span>
                <p>Filtre por Nacional:</p>
                <SelectV2
                  name="comboANB"
                  content={comboNacs}
                  onChange={handleANBSelect}
                  initial={initialNac}
                />

                <RemoveButton
                  type="button"
                  onClick={handleClearFilter}
                  style={removeFilterStyle}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>Ativos - {coord.desc}</AlteredHeader>
        <FilterByStatusContainer>
          <button
            type="button"
            data-checked={statusFilter === 'A'}
            data-status="A"
            onClick={handleFilterStatusChange}
          >
            Ativos
          </button>
          <button
            type="button"
            data-checked={statusFilter === 'R'}
            data-status="R"
            onClick={handleFilterStatusChange}
          >
            Retirados
          </button>
        </FilterByStatusContainer>
        {list.length === 0 && !loading && <p>Não há patrimônio registrado</p>}
        {list.length > 0 && (
          <Table>
            <thead>
              <tr>
                <TDDesc>Descrição</TDDesc>
                <TDQtd>Quantidade</TDQtd>
                <TDConsult>
                  {width < 1000 ? <FaSearch /> : 'Consultar'}
                </TDConsult>
                {((user.perfil === 'ZON' && user.zoncod === coord.cod) ||
                  (user.perfil === 'NAC' && user.anbc === coord.cod)) && (
                  <TDAlt>{width < 1000 ? <FaPencilAlt /> : 'Alterar'}</TDAlt>
                )}
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.uuid}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <TDDesc>Descrição</TDDesc>
                      <TDQtd>Quantidade</TDQtd>
                      <TDConsult>
                        {width < 1000 ? <FaSearch /> : 'Consultar'}
                      </TDConsult>
                      {((user.perfil === 'ZON' && user.zoncod === coord.cod) ||
                        (user.perfil === 'NAC' && user.anbc === coord.cod)) && (
                        <TDAlt>
                          {width < 1000 ? <FaPencilAlt /> : 'Alterar'}
                        </TDAlt>
                      )}
                    </tr>
                  )}
                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    <TDDesc>{item.desc}</TDDesc>
                    <TDQtd>{item.qtd}</TDQtd>
                    <TDConsult>
                      <button
                        type="button"
                        onClick={() => {
                          setDeleteDiag((state) => ({
                            ...state,
                            values: {
                              seq: item.seq,
                              desc: item.desc,
                              dtaquis: formatDate(item.dtaquis),
                              qtd: item.qtd,
                              vlrunit: formatNumber(item.vlrunit),
                              vlrtot: formatNumber(item.vlrtot),
                            },
                          }));
                          setShow(() => ({
                            title: item.desc,
                            open: true,
                            content: (
                              <ConsultContent>
                                <span>
                                  <p>
                                    Data de Aquisição:&nbsp;
                                    <strong>{formatDate(item.dtaquis)}</strong>
                                  </p>
                                </span>
                                <span>
                                  <p>
                                    Quantidade:&nbsp;
                                    <strong>{item.qtd}</strong>
                                  </p>
                                </span>
                                <span>
                                  <p>
                                    Valor Unit.:&nbsp;
                                    <strong>
                                      R$ {formatNumber(item.vlrunit)}
                                    </strong>
                                  </p>
                                </span>
                                <span>
                                  <p>
                                    Valor Total:&nbsp;
                                    <strong>
                                      R$ {formatNumber(item.vlrtot)}
                                    </strong>
                                  </p>
                                </span>
                                {item.status === 'R' ? (
                                  <>
                                    <span>
                                      <p>
                                        Data de Retirada:&nbsp;
                                        <strong>
                                          {formatDate(item.withdrawDate)}
                                        </strong>
                                      </p>
                                    </span>
                                    <span>
                                      <p>
                                        Valor de Retirada:&nbsp;
                                        <strong>
                                          R$
                                          {formatNumber(
                                            parseFloat(item.withdrawValue),
                                          )}
                                        </strong>
                                      </p>
                                    </span>
                                    <span>
                                      <p>
                                        Justificativa de Retirada:&nbsp;
                                        <strong>{item.withdrawObs}</strong>
                                      </p>
                                    </span>
                                  </>
                                ) : null}
                              </ConsultContent>
                            ),
                          }));
                        }}
                      >
                        <FaSearch />
                      </button>
                    </TDConsult>
                    {((user.perfil === 'ZON' && user.zoncod === coord.cod) ||
                      (user.perfil === 'NAC' && user.anbc === coord.cod)) && (
                      <TDAlt>
                        <button
                          type="button"
                          onClick={() =>
                            setUpdateDiag((state) => ({
                              ...state,
                              open: true,
                              title: item.desc,
                              values: {
                                seq: item.seq,
                                status: item.status,
                                desc: item.desc,
                                qtd: item.qtd,
                                dtaquis: item.dtaquis,
                                vlrunit: parseFloat(
                                  item.vlrunit.toString(),
                                ).toFixed(2),
                                vlrtot: parseFloat(
                                  item.vlrtot.toString(),
                                ).toFixed(2),
                                vlrret:
                                  item.status === 'R'
                                    ? parseFloat(item.withdrawValue).toFixed(2)
                                    : 0.0,
                                obs: item.withdrawObs,
                              },
                            }))
                          }
                        >
                          <FaPencilAlt />
                        </button>
                      </TDAlt>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          {((user.perfil === 'ZON' && user.zoncod === coord.cod) ||
            (user.perfil === 'NAC' && user.anbc === coord.cod)) &&
            statusFilter === 'A' && (
              <S.Cancel
                type="button"
                onClick={() => {
                  setShow((state) => ({ ...state, open: false }));
                  setDeleteDiag((state) => ({ ...state, open: true }));
                }}
              >
                Retirar
              </S.Cancel>
            )}

          <S.Confirm
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <I.Container scroll="paper" maxWidth={false} open={inc.open}>
        <Form
          ref={insertForm}
          onSubmit={handleInsert}
          initialData={{
            qtd: 0,
            vlrunit: parseFloat('0').toFixed(2),
            vlrtot: parseFloat('0').toFixed(2),
          }}
        >
          <I.Title>
            <h4>Novo patrimônio</h4>
            {/* <h2>Novo patrimônio</h2> */}
          </I.Title>
          <I.Content>
            <figure>
              <IContentDiv>
                <span>
                  <p>Descrição do Patrimônio</p>

                  <InputDialog
                    name="desc"
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '360px',
                            padding: '6px 15px 6px 6px',
                          }
                    }
                    placeholder="Descrição"
                  />
                </span>
              </IContentDiv>
              <IContentDiv
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  <p>Quantidade</p>
                  <InputDialog
                    name="qtd"
                    type="number"
                    min="0"
                    mask="number"
                    placeholder="0"
                    iconStyle={{ margin: '0' }}
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '130px',
                            // overflow: 'hidden',
                            padding: '5px 25px 5px 0px',
                          }
                    }
                    inputStyle={
                      width < 460
                        ? {}
                        : {
                            width: '90px',
                            textAlign: 'center',
                          }
                    }
                    onChange={handleTotalChange}
                  />
                </span>
                <span style={{}}>
                  <p>Data de Aquisição</p>
                  <InputDialog
                    name="acqdate"
                    type="date"
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '200px',
                            padding: '5px 2px 4.82px 5px',
                          }
                    }
                    inputStyle={{
                      width: '110px',
                    }}
                  />
                </span>
              </IContentDiv>
              <IContentDiv
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  <p>Valor Unitário</p>
                  <InputDialog
                    name="vlrunit"
                    type="number"
                    step=".01"
                    min="0"
                    mask="currency"
                    placeholder="0,00"
                    icon={FaDollarSign}
                    iconStyle={{ margin: '0' }}
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '130px',
                            // overflow: 'hidden',
                            padding: '5px 15px 5px 0px',
                          }
                    }
                    inputStyle={
                      width < 460
                        ? {}
                        : {
                            width: '90px',
                            textAlign: 'right',
                          }
                    }
                    onChange={handleTotalChange}
                  />
                </span>
                <span>
                  <p>Valor Total</p>
                  <InputDialog
                    name="vlrtot"
                    type="number"
                    step=".01"
                    mask="currency"
                    placeholder="0,00"
                    icon={FaDollarSign}
                    iconStyle={{ margin: '0' }}
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '130px',
                            // overflow: 'hidden',
                            padding: '5px 15px 5px 0px',
                          }
                    }
                    inputStyle={
                      width < 460
                        ? {}
                        : {
                            width: '90px',
                            textAlign: 'right',
                          }
                    }
                    disabled
                  />
                </span>
              </IContentDiv>
            </figure>
          </I.Content>
          <I.Actions>
            <I.Cancel
              type="button"
              onClick={() =>
                setInc({
                  ...inc,
                  open: false,
                })
              }
            >
              Cancelar
            </I.Cancel>
            <I.Confirm type="submit">Finalizar</I.Confirm>
          </I.Actions>
        </Form>
      </I.Container>
      <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
        <U.Title>
          <h4>{updateDiag.title}</h4>
        </U.Title>
        <Form
          ref={updateForm}
          onSubmit={
            updateDiag.values?.status && updateDiag.values?.status === 'A'
              ? handleUpdate
              : handleUpdateWithDraw
          }
          initialData={{
            desc: updateDiag.values?.desc || '',
            qtd: updateDiag.values?.qtd || 0,
            acqdate: updateDiag.values?.dtaquis || 0,
            vlrunit: parseFloat(
              updateDiag.values?.vlrunit.toString() || '0',
            ).toFixed(2),
            vlrtot: parseFloat(
              updateDiag.values?.vlrtot.toString() || '0',
            ).toFixed(2),
            vlrret: parseFloat(
              updateDiag.values?.vlrret.toString() || '0',
            ).toFixed(2),
            obs: updateDiag.values?.obs,
          }}
        >
          <Input isHidden name="status" value={updateDiag.values?.status} />
          <U.Content>
            {updateDiag.values?.status && updateDiag.values?.status === 'A' ? (
              <figure>
                <IContentDiv>
                  <span>
                    <p>Descrição do Patrimônio</p>

                    <InputDialog
                      name="desc"
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '360px',
                              padding: '6px 15px 6px 6px',
                            }
                      }
                      placeholder="Descrição"
                    />
                  </span>
                </IContentDiv>
                <IContentDiv
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    <p>Quantidade</p>
                    <InputDialog
                      name="qtd"
                      type="number"
                      min="0"
                      mask="number"
                      placeholder="0"
                      iconStyle={{ margin: '0' }}
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '130px',
                              // overflow: 'hidden',
                              padding: '5px 25px 5px 0px',
                            }
                      }
                      inputStyle={
                        width < 460
                          ? {}
                          : {
                              width: '90px',
                              textAlign: 'center',
                            }
                      }
                      onChange={handleTotalChangeUpdate}
                    />
                  </span>
                  <span style={{}}>
                    <p>Data de Aquisição</p>
                    <InputDialog
                      name="acqdate"
                      type="date"
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '200px',
                              padding: '5px 2px 4.82px 5px',
                            }
                      }
                      inputStyle={{
                        width: '110px',
                      }}
                    />
                  </span>
                </IContentDiv>
                <IContentDiv
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    <p>Valor Unitário</p>
                    <InputDialog
                      name="vlrunit"
                      type="number"
                      step=".01"
                      min="0"
                      mask="currency"
                      placeholder="0,00"
                      icon={FaDollarSign}
                      iconStyle={{ margin: '0' }}
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '130px',
                              // overflow: 'hidden',
                              padding: '5px 15px 5px 0px',
                            }
                      }
                      inputStyle={
                        width < 460
                          ? {}
                          : {
                              width: '90px',
                              textAlign: 'right',
                            }
                      }
                      onChange={handleTotalChangeUpdate}
                    />
                  </span>
                  <span>
                    <p>Valor Total</p>
                    <InputDialog
                      name="vlrtot"
                      type="number"
                      step=".01"
                      mask="currency"
                      placeholder="0,00"
                      icon={FaDollarSign}
                      iconStyle={{ margin: '0' }}
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '130px',
                              // overflow: 'hidden',
                              padding: '5px 15px 5px 0px',
                            }
                      }
                      inputStyle={
                        width < 460
                          ? {}
                          : {
                              width: '90px',
                              textAlign: 'right',
                            }
                      }
                      disabled
                    />
                  </span>
                </IContentDiv>
              </figure>
            ) : updateDiag.values?.status &&
              updateDiag.values?.status === 'R' ? (
              <figure>
                <IContentDiv>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '7px',
                      margin: '7px 0',
                    }}
                  >
                    <p>Valor de Retirada:</p>
                    <InputDialog
                      name="vlrret"
                      type="number"
                      step=".01"
                      min="0"
                      mask="currency"
                      placeholder="0,00"
                      icon={FaDollarSign}
                      iconStyle={{ margin: '0' }}
                      containerStyle={
                        width < 460
                          ? { padding: '6px 15px 6px 6px' }
                          : {
                              width: '130px',
                              // overflow: 'hidden',
                              padding: '5px 15px 5px 0px',
                            }
                      }
                      inputStyle={
                        width < 460
                          ? {}
                          : {
                              width: '90px',
                              textAlign: 'right',
                            }
                      }
                    />
                  </span>
                  <span
                    style={{
                      margin: '7px 0 0 0',
                    }}
                  >
                    <p>Justificativa:</p>
                  </span>
                  <span>
                    <TextareaDialog
                      name="obs"
                      placeholder="Justificativa (obrigatório)"
                      containerStyle={{ width: '300px', padding: '3px 5px' }}
                    />
                  </span>
                </IContentDiv>
              </figure>
            ) : null}
          </U.Content>
          <U.Actions>
            <U.Cancel
              type="button"
              onClick={() => setUpdateDiag({ open: false })}
            >
              Cancelar
            </U.Cancel>
            <U.Confirm type="submit" onClick={() => null}>
              Confirmar
            </U.Confirm>
          </U.Actions>
        </Form>
      </U.Container>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <Form ref={deleteForm} onSubmit={handleExclusion}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <D.ModalDeleteContent>
              <p>
                Você está prestes a retirar&nbsp; esse item do patrimônio:&nbsp;
              </p>
              <div>
                <span>
                  <p>
                    Descrição:&nbsp;
                    <strong>{deleteDiag.values?.desc}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Data de Aquisição:&nbsp;
                    <strong>{deleteDiag.values?.dtaquis}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Quantidade:&nbsp;
                    <strong>{deleteDiag.values?.qtd}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Valor Unit.:&nbsp;
                    <strong>R$&nbsp;{deleteDiag.values?.vlrunit}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Valor Total:&nbsp;
                    <strong>R$&nbsp;{deleteDiag.values?.vlrtot}</strong>
                  </p>
                </span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '7px',
                    margin: '7px 0',
                  }}
                >
                  <p>Valor de Retirada:</p>
                  <InputDialog
                    name="vlrret"
                    type="number"
                    step=".01"
                    min="0"
                    mask="currency"
                    placeholder="0,00"
                    defaultValue="0.00"
                    icon={FaDollarSign}
                    iconStyle={{ margin: '0' }}
                    containerStyle={
                      width < 460
                        ? { padding: '6px 15px 6px 6px' }
                        : {
                            width: '130px',
                            // overflow: 'hidden',
                            padding: '5px 15px 5px 0px',
                          }
                    }
                    inputStyle={
                      width < 460
                        ? {}
                        : {
                            width: '90px',
                            textAlign: 'right',
                          }
                    }
                  />
                </span>
                <span
                  style={{
                    margin: '7px 0 0 0',
                  }}
                >
                  <p>Justificativa:</p>
                </span>
                <span>
                  <TextareaDialog
                    name="obs"
                    placeholder="Justificativa (obrigatório)"
                    containerStyle={{ width: '300px', padding: '3px 5px' }}
                  />
                </span>
              </div>

              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </D.ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="submit">Confirmar</D.Confirm>
          </D.Actions>
        </Form>
      </D.Container>
      <SGOFooter />
    </Container>
  );
};

export default Patrimonio;
