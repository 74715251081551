import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
  CoordProps,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import RadioContainer from 'components/RadioContainer';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';

import {
  // deleteLocalStorageItemKey,
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { removeAcento } from 'utils/specialChars';
import { FaDollarSign, FaSearch } from 'react-icons/fa';
import { GridContainer, GridItem } from './styles';

export interface ListProps {
  loccod: string;
  locdesc: string;
  num: number;
  date: string;
  value: number;
  gseq: number;
  gcert: string;
  name: string;
  status: string;
  statDesc: string;
  obs: string[];
  financregs: number;
  sld: number;
}

interface LocalStorageProps {
  zoncod?: string;
  zondesc?: string;
  anbcod?: string;
  anbdesc?: string;
  loccod?: string;
  locdesc?: string;
  cord?: CoordProps;
  stat?: string;
}
const ControleEntrega: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [statCE, setStatCE] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_CE}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_CE}`,
      );

      if (stat) {
        return stat;
      }

      return 'E';
    }

    return 'E';
  });

  // const [initialZon] = useState(() => {
  //   const { zondesc }: LocalStorageProps = getLocalStorage(
  //     `${process.env.REACT_APP_CE}`,
  //   );

  //   return zondesc || user.zondesc;
  // });
  // const [initialNac, setInitialNac] = useState(() => {
  //   const { anbdesc }: LocalStorageProps = getLocalStorage(
  //     `${process.env.REACT_APP_CE}`,
  //   );

  //   return anbdesc || user.anbdesc;
  // });
  const [initialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CE}`,
    );

    return loccod || 'Selecione';
  });
  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cord }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CE}`,
    );

    return (
      cord || {
        cod: user.loccod,
        desc: user.locdesc,
      }
    );
  });
  const [, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  // const [nacs, setNacs] = useState<ComboProps[]>([]);
  // const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  // const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const stat = formRef.current?.getFieldValue('filterCEStat');

      const filtered = rawList.filter((item: ListProps) => {
        return (
          (item.num.toString().includes(val) ||
            removeAcento(item.name)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase())) &&
          item.status === stat
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const getList = useCallback(
    async (params?: string) => {
      try {
        setLoading(true);
        const response = await api.get(
          `/sgo/ce_list.php?data=${JSON.stringify({
            cod: params || coord.cod,
          })}`,
        );

        const filtered = response.data.filter((item: ListProps) => {
          return ['NAC', 'LOC'].indexOf(user.perfil) < 0
            ? item.gseq === parseInt(user.gseq, 10)
            : item;
        });

        setRawList(filtered);
        setList(
          filtered.filter((item: ListProps) =>
            ['NAC', 'LOC'].indexOf(user.perfil) > -1
              ? item.status === statCE
              : item,
          ),
        );
        // setList(response.data);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
      }
    },
    [coord.cod, user.perfil, user.gseq, statCE, errorHandling],
  );

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CE}`,
    );

    setComboLocs(
      response.data.filter(
        (item: ComboLocalProps) => item.anb === (anbcod || user.anbc),
      ),
    );
  }, [user.anbc]);

  // const getComboANB = useCallback(async () => {
  //   const response = await api.get('/combos/comboANBs.php');
  //   // setInitialNac(user.anbdesc);
  //   setNacs(response.data);

  //   const { zoncod }: LocalStorageProps = getLocalStorage(
  //     `${process.env.REACT_APP_CE}`,
  //   );

  //   setComboNacs(
  //     response.data.filter((item: ComboProps) =>
  //       zoncod
  //         ? item.value.substr(0, 2) === zoncod.substr(0, 2)
  //         : item.value.substr(0, 2) === user.zoncod.substr(0, 2),
  //     ),
  //   );
  // }, [user.zoncod]);

  // const getComboZON = useCallback(async () => {
  //   const response = await api.get('/combos/comboZONs.php');

  //   setComboZons(response.data);
  // }, []);

  useEffect(() => {
    // getComboZON();
    // getComboANB();
    getComboLOC();
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getComboLOC]);
  // }, [getComboANB, getComboLOC, getComboZON]);

  // const handleZONselect = useCallback(() => {
  //   setComboNacs([]);
  //   setComboLocs([]);

  //   const zon = formRef.current?.getFieldValue('comboZON');
  //   setInitialLoc('Selecione');
  //   setInitialNac('Selecione');
  //   setComboNacs(
  //     nacs.filter((item) => item.value.substr(0, 2) === zon.substr(0, 2)),
  //   );
  //   formRef.current?.setData({
  //     comboANB: '',
  //     comboLocal: '',
  //   });
  // }, [nacs]);

  // const handleANBselect = useCallback(() => {
  //   setInitialLoc('Selecione');
  //   setComboLocs([]);
  //   const anb = formRef.current?.getFieldValue('comboANB');

  //   setComboLocs(locs.filter((item) => item.anb === anb));
  // }, [locs]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLocal');
    const locIndex = comboLocs.findIndex(
      (item: ComboProps) => item.value === loc,
    );

    // if (['INT'].indexOf(user.perfil) > -1) {
    //   const zon = formRef.current?.getFieldValue('comboZON');
    //   const zonIndex = comboZons.findIndex(
    //     (item: ComboProps) => item.value === zon,
    //   );

    //   setLocalStorage(`${process.env.REACT_APP_CE}`, {
    //     zoncod: zon,
    //     zondesc: comboZons[zonIndex].label || user.zondesc,
    //   });
    // }

    // if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
    //   const anb = formRef.current?.getFieldValue('comboANB');
    //   const anbIndex = comboNacs.findIndex(
    //     (item: ComboProps) => item.value === anb,
    //   );

    //   setLocalStorage(`${process.env.REACT_APP_CE}`, {
    //     anbcod: anb,
    //     anbdesc: comboNacs[anbIndex].label || user.anbdesc,
    //   });
    // }

    setLocalStorage(`${process.env.REACT_APP_CE}`, {
      loccod: loc,
      locdesc: comboLocs[locIndex].label,
      cord: { cod: loc, desc: comboLocs[locIndex].label },
    });

    setCoord({
      cod: loc,
      desc: comboLocs[locIndex].label,
    });

    getList(loc);
  }, [comboLocs, getList]);

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('filterCEStat');
    setSearchValue('');
    setStatCE(stat);
    setLocalStorage(`${process.env.REACT_APP_CE}`, { stat });

    setList(rawList.filter((item: ListProps) => item.status === stat));
  }, [rawList]);

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'LOC' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['NAC', 'LOC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              <RadioContainer
                title="Controles de Entrega:"
                onClick={handleRadioClick}
                name="filterCEStat"
                content={[
                  {
                    id: 'eCE',
                    value: 'E',
                    label: 'Emitidos',
                  },
                  {
                    id: 'pCE',
                    value: 'P',
                    label: 'Pagos',
                  },
                  {
                    id: 'cCE',
                    value: 'C',
                    label: 'Cancelados',
                  },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={statCE}
              />

              {/* {user.perfil === 'INT' && (
                <span>
                  <p>Selecione a ZONAL:</p>
                  <Select
                    name="comboZON"
                    content={comboZons}
                    onBlur={handleZONselect}
                    initial={initialZon}
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Selecione a Nacional:</p>
                  <Select
                    name="comboANB"
                    content={comboNacs}
                    onBlur={handleANBselect}
                    initial={initialNac}
                  />
                </span>
              )} */}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                </span>
              )}
            </div>
          </Form>
        }
      />

      <Header>
        <div>
          <p>Controles de Entrega</p>
          <p>
            {['NAC', 'LOC'].indexOf(user.perfil) > -1 ? coord.desc : user.name}
          </p>
        </div>
      </Header>
      {user.perfil === 'LOC' && (
        <FloatInput amount="120px">
          <input
            placeholder="Filtrar por N° CE ou Guia"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent
        pixels={
          user.perfil !== 'LOC' ? (coord.desc ? '252px' : '231px') : '231px'
        }
      >
        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem
              key={item.num}
              bordercolor={
                item.status === 'C'
                  ? '#c53030'
                  : item.status === 'E'
                  ? '#2156e6'
                  : '#00802b'
              }
            >
              <p>
                N° {item.num} |&nbsp;
                {formatDate(item.date)}
              </p>
              <span>
                <p>
                  Valor do CE:&nbsp;
                  <strong>R$ {formatNumber(item.value)}</strong>
                </p>
              </span>
              {item.status === 'E' && (
                <span>
                  <p>
                    Saldo pendente:&nbsp;
                    <strong>R$ {formatNumber(item.sld)}</strong>
                  </p>
                </span>
              )}
              {['NAC', 'LOC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>
                    Emitido para:&nbsp;
                    <strong>{item.name}</strong>
                  </p>
                </span>
              )}

              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statDesc}</strong>
                </p>
              </span>
              {/*
              {user.perfil === 'NAC' && item.loccod === user.anbc && (
                <span>
                  <p>
                    Emitida por :&nbsp;
                    <strong>{item.anbdesc}</strong>
                  </p>
                </span>
              )}

              {item.solnum ? (
                <span>
                  <p>
                    Baseada na solicitação:&nbsp;
                    <strong>
                      N° {item.solnum} | {formatDate(item.soldata)}
                    </strong>
                  </p>
                </span>
              ) : null} */}
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { ce: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {item.status !== 'C' && item.value > 0 && (
                  <O.Financ
                    to={{
                      pathname: '/sgo/tesouraria/contfince/detail',
                      state: {
                        finHeader: {
                          num: item.num,
                          cod: item.gseq,
                          desc: item.name,
                          date: item.date,
                          valor: item.value,
                          locdesc: item.locdesc,
                        },
                      },
                    }}
                  >
                    <FaDollarSign />
                  </O.Financ>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default ControleEntrega;
