import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { calcTotal, formatNumber } from 'utils/calcTotal';
import {
  FaArrowLeft,
  FaPlusSquare,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
} from 'react-icons/fa';
import { useSpring } from 'react-spring';
import { useWindow } from 'hooks/window';
import RadioContainer from 'components/RadioContainer';
import Textarea from 'components/Textarea';
import { checkAddress } from 'utils/specialChars';
import Button from 'components/Button';
import { StateProps } from '../consult';
import { ListProps } from '../main';

import { PhaseOne, HeaderComponent, BodyComponent, PhaseTwo } from './styles';

import {
  Grid,
  GridItem,
  ItemContainer,
  AddButton,
  FinishButton,
  InfoContainer,
  TotalSolic,
  ReturnButton,
} from '../insert/styles';

interface ItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
  stored: number;
  type: string;
}

const PedidoUpdate: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const phaseTwo = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [isPhaseOne, setIsPhaseOne] = useState(true);
  const [isPhaseTwo, setIsPhaseTwo] = useState(false);

  const [storageQuantity, setStorageQuantity] = useState('-');
  const [cost, setCost] = useState(0);

  const [deliverAddress, setDeliverAddress] = useState('');
  const [pAddress, setPAddress] = useState('');
  const [sAddress, setSAddress] = useState('');
  const [deliverAble, setDeliverAble] = useState(false);

  const [pedidoHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.pedido;
  });

  const [items, setItems] = useState<ItemsProps[]>([]);

  const [mats, setMats] = useState<ComboProps[]>([]);
  const [rawMats, setRawMats] = useState<ComboProps[]>([]);

  const getAddress = useCallback(async () => {
    const response = await api.get<{ PRINC: string; SEC: string }>(
      `/sgo/getAddress.php?data=${JSON.stringify({
        trans: 'pedido',
        anbcod: pedidoHeader.cod,
      })}`,
    );

    const { PRINC, SEC } = response.data;

    if (PRINC && SEC) {
      if (checkAddress(PRINC) === checkAddress(SEC)) {
        setDeliverAddress(pedidoHeader.addressval === 'P' ? PRINC : SEC);
      } else {
        setDeliverAble(true);
        setDeliverAddress(pedidoHeader.addressval === 'P' ? PRINC : SEC);
        setPAddress(PRINC);
        setSAddress(SEC);
        phaseTwo.current?.setFieldValue('deliver', 'P');
      }
    } else if (!PRINC && SEC) {
      setDeliverAddress(pedidoHeader.addressval === 'P' ? PRINC : SEC);
    } else if (!SEC && PRINC) {
      setDeliverAddress(pedidoHeader.addressval === 'P' ? PRINC : SEC);
    } else {
      return null;
    }
  }, [pedidoHeader.addressval, pedidoHeader.cod]);

  const initialSetup = useCallback(async () => {
    try {
      setLoading(true);
      const getMat = await api.get('/combos/comboMATs.php?from=pedido');

      const getItems = await api.get<ItemsProps[]>(
        `/sgo/pedido_items.php?data=${JSON.stringify({
          pedido: pedidoHeader.num,
          cod: pedidoHeader.cod,
        })}`,
      );

      setItems(getItems.data);
      setRawMats(getMat.data);
      setMats(() =>
        getMat.data.filter(
          (item: ComboProps) =>
            item.matgrped === getItems.data[0].type &&
            !getItems.data
              .map((mat: ItemsProps) => mat.cod)
              .includes(item.value),
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, pedidoHeader.cod, pedidoHeader.num]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }
    getAddress();
    initialSetup();
  }, [getAddress, history, initialSetup, location.pathname, location.state]);

  const handleMaterialSelect = useCallback(async () => {
    try {
      setLoading(true);
      phaseOne.current?.setErrors({});
      const material = phaseOne.current?.getFieldValue('material');

      const send = JSON.stringify({
        matcod: material,
        trans: 'pedido',
      });
      const response = await api.get(`/sgo/getStorage.php?data=${send}`);
      const { quantity, matcost } = response.data;

      setStorageQuantity(quantity);
      setCost(matcost);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const handlePushMaterial = useCallback(() => {
    const index = mats.findIndex(
      (item: ComboProps) =>
        item.value === phaseOne.current?.getFieldValue('material'),
    );

    phaseOne.current?.setErrors({});

    if (index > -1) {
      const newMat = {
        cod: mats[index].value,
        desc: mats[index].label,
        value: cost,
        qtd: 1,
        stored: parseInt(storageQuantity, 10),
        type: mats[index].matgrped,
      } as ItemsProps;

      setItems((state) => [newMat, ...state]);
    }
  }, [cost, mats, storageQuantity]);

  const handleAdd = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.cod === material,
      );

      if (index > -1) {
        items[index].qtd += 1;
        setItems((state) => [...state]);
      }
    },
    [items],
  );
  const handleSub = useCallback(
    (material: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.cod === material,
      );

      if (index > -1) {
        items[index].qtd -= 1;
        setItems((state) =>
          items[index].qtd === 0
            ? [...state].filter(
                (item: ItemsProps) => item.cod !== items[index].cod,
              )
            : [...state],
        );

        if (items[index].qtd === 0) {
          const rearrange = [
            ...mats,
            rawMats[
              rawMats.findIndex(
                (item: ComboProps) => item.value === items[index].cod,
              )
            ],
          ].sort((a, b) => (a.label > b.label ? 1 : -1));

          setMats(rearrange);
        }
      }
    },
    [items, mats, rawMats],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.cod === mat);
      const number = e.currentTarget.value.replace(/^0/, '');
      if (number.length > 0) {
        const asNum = parseInt(number, 10);

        if (index > -1) {
          items[index].qtd = asNum;
          setItems((state) =>
            items[index].qtd <= 0
              ? [...state].filter(
                  (item: ItemsProps) => item.cod !== items[index].cod,
                )
              : [...state],
          );
        }
      } else {
        items[index].qtd = 0;
        setItems((state) => [...state]);
      }

      e.currentTarget.value = e.currentTarget.value.replace(/^0/, '');
    },
    [items],
  );

  const handleInputBlur = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.cod === mat);
      if (e.currentTarget.value === '0') {
        setItems((state) =>
          [...state].filter(
            (item: ItemsProps) => item.cod !== items[index].cod,
          ),
        );

        const rearrange = [
          ...mats,
          rawMats[
            rawMats.findIndex(
              (item: ComboProps) => item.value === items[index].cod,
            )
          ],
        ].sort((a, b) => (a.label > b.label ? 1 : -1));

        setMats(rearrange);
      }
    },
    [items, mats, rawMats],
  );

  const handleRemoveItem = useCallback(
    (mat: string) => {
      const index = items.findIndex((item: ItemsProps) => item.cod === mat);

      setItems((state) => state.filter((item: ItemsProps) => item.cod !== mat));

      const rearrange = [
        ...mats,
        rawMats[
          rawMats.findIndex(
            (item: ComboProps) => item.value === items[index].cod,
          )
        ],
      ].sort((a, b) => (a.label > b.label ? 1 : -1));

      setMats(rearrange);
    },
    [items, mats, rawMats],
  );

  const handleRadioClick = useCallback(() => {
    const value = phaseTwo.current?.getFieldValue('deliver');

    setDeliverAddress(value === 'P' ? pAddress : sAddress);
  }, [pAddress, sAddress]);

  const total = useMemo(() => {
    return items.reduce((accum, { qtd, value }: ItemsProps) => {
      const semitotal = accum + qtd * value;
      return semitotal;
    }, 0);
  }, [items]);

  const phaseOneStyle = useSpring({
    opacity: isPhaseOne ? 1 : 0,
    transform: isPhaseOne ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseOne ? 'all' : 'none',
    display: isPhaseOne ? 'inline' : 'none',
  });
  const phaseTwoStyle = useSpring({
    opacity: isPhaseTwo ? 1 : 0,
    transform: isPhaseTwo ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: isPhaseTwo ? 'all' : 'none',
    display: isPhaseTwo ? 'inline' : 'none',
  });
  const toggleFlexDirection = useSpring({
    flexDirection: width < 400 && total > 99.99 ? 'column' : 'row',
  });
  const styledProceedPhaseTwo = useSpring({
    transform: items.length > 0 ? 'translateX(5px)' : 'translateX(60px)',
    opacity: items.length > 0 ? 1 : 0,
    pointerEvents: items.length > 0 ? 'all' : 'none',
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      const addressval =
        phaseTwo.current?.getFieldValue('deliver') || pedidoHeader.addressval;
      const data = {
        items,
        address: {
          value: addressval,
          label: deliverAddress,
        },
        obs: phaseTwo.current?.getFieldValue('obs').replace('#', ''),
        pednum: pedidoHeader.num,
        name: user.name,
        anbdesc: pedidoHeader.anbdesc,
        anbcod: pedidoHeader.cod,
        total,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      await api.post('/sgo/pedido_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Pedido N° ${data.pednum} alterado.`,
      });

      history.replace(location.pathname.replace('/update', ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    deliverAddress,
    errorHandling,
    history,
    items,
    location.pathname,
    pedidoHeader.addressval,
    pedidoHeader.anbdesc,
    pedidoHeader.cod,
    pedidoHeader.num,
    total,
    user.name,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Alteração Pedido N° ${pedidoHeader.num}`}
        isResponsible
      />
      <Content>
        <PhaseOne style={phaseOneStyle}>
          {user.perfil === 'ZON' && (
            <HeaderComponent>
              <span>
                <p>
                  Coordenação: <strong>{pedidoHeader.anbdesc}</strong>
                </p>
              </span>
            </HeaderComponent>
          )}
          <BodyComponent>
            <Form
              ref={phaseOne}
              onSubmit={() => {
                return null;
              }}
            >
              <ItemContainer>
                <span>
                  <SelectV2
                    name="material"
                    content={mats}
                    initial="Selecione"
                    onChange={handleMaterialSelect}
                  />
                </span>
                <span>
                  <p>Seu estoque:</p>
                  <strong>{storageQuantity}</strong>
                </span>
              </ItemContainer>
              <AddButton type="button" onClick={handlePushMaterial}>
                <FaPlusSquare /> <p>Adicionar material</p>
              </AddButton>
              <InfoContainer>
                <TotalSolic style={toggleFlexDirection}>
                  <p>Total deste pedido:&nbsp;</p>
                  <strong>R$ {formatNumber(total)}</strong>
                </TotalSolic>

                <FinishButton
                  style={styledProceedPhaseTwo}
                  type="button"
                  onClick={() => {
                    setIsPhaseOne(false);
                    setIsPhaseTwo(true);
                  }}
                >
                  Avançar
                </FinishButton>
              </InfoContainer>

              <Grid>
                {items.map((item: ItemsProps) => (
                  <GridItem key={item.cod}>
                    <div>
                      <strong>
                        {item.cod} - {item.desc}
                      </strong>
                    </div>

                    <div>
                      <span>
                        <p>
                          Custo:&nbsp;
                          <strong>R$ {formatNumber(item.value)}</strong>
                        </p>
                      </span>
                      <span>
                        <p>Quantidade:</p>
                        <span>
                          <FaSortUp
                            onClick={() => handleAdd(item.cod)}
                            style={{
                              transform: 'translateY(10px)',
                              color: '#009933',
                            }}
                          />

                          <input
                            type="number"
                            min={0}
                            value={item.qtd}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              handleInputChange(e, item.cod)
                            }
                            onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                              handleInputBlur(e, item.cod)
                            }
                          />

                          <FaSortDown
                            onClick={() => handleSub(item.cod)}
                            style={{
                              transform: 'translateY(-10px)',
                              color: '#c53030',
                            }}
                          />
                        </span>
                        <figure>
                          <FaTimesCircle
                            onClick={() => handleRemoveItem(item.cod)}
                          />
                        </figure>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Estoque Local atual:&nbsp;
                          <strong>{item.stored}</strong>
                        </p>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Total:&nbsp;
                          <strong>R$ {calcTotal(item.qtd, item.value)}</strong>
                        </p>
                      </span>
                    </div>
                  </GridItem>
                ))}
              </Grid>
            </Form>
          </BodyComponent>
        </PhaseOne>
        <PhaseTwo style={phaseTwoStyle}>
          <ReturnButton
            onClick={() => {
              setIsPhaseOne(true);
              setIsPhaseTwo(false);
            }}
          >
            <FaArrowLeft />
            <p>Retornar aos itens</p>
          </ReturnButton>
          <Form
            ref={phaseTwo}
            onSubmit={() => {
              return null;
            }}
          >
            <div>
              {deliverAble && (
                <RadioContainer
                  title="Endereço de Entrega:"
                  onClick={handleRadioClick}
                  name="deliver"
                  content={[
                    {
                      id: 'dT',
                      value: 'P',
                      label: 'Escritório Nacional',
                    },
                    {
                      id: 'dL',
                      value: 'S',
                      label: 'Endereço Alternativo',
                    },
                  ]}
                  selected={pedidoHeader.addressval}
                />
              )}

              <p>{deliverAddress}</p>
            </div>
            <div>
              <p>Observação:</p>
              <Textarea
                name="obs"
                placeholder="Insira a observação (opcional)"
              />
            </div>
          </Form>
          <Button onClick={handleFinish} bgcolor="#00802b">
            Finalizar
          </Button>
        </PhaseTwo>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default PedidoUpdate;
