import styled, { keyframes, css } from 'styled-components';

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(150px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

interface GridItemProps {
  delay?: number;
  duration?: number;
}

interface SecundaryProps {
  active: boolean;
}

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 190px);

  /* overflow-x: hidden; */

  h2 {
    font-size: 24px;
    margin: 15px 0 0 0;
  }

  form {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      margin: 35px;
    }

    figure,
    div,
    span {
      > p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;
      }
    }

    figure {
      margin: 10px 0;
      padding: 10px;
      border: 2px solid #efefef;
      border-radius: 5px;
      background: #fff;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

      opacity: 0;
      transform: translateX(150px);

      animation: ${appearFromRight} 1s ease forwards;
    }
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 190px);
  }
`;

export const GridContainer = styled.div`
  /* margin: 10px; */
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 35px;
  row-gap: 25px;
  position: relative;
  z-index: 51;

  @media screen and (max-width: 800px) {
    grid: auto / 1fr;
    column-gap: 0;
  }
`;

export const AnimatedSection = styled.section<GridItemProps>`
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5px 10px 10px 10px;
  border: 2px solid #efefef;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  > span + span {
    margin: 5px 0 0 0;
  }

  &:first-child {
    justify-content: center;
    > span + span {
      margin: 50px 0 0 0;
    }
  }

  @media screen and (max-width: 800px) {
    &:first-child {
      justify-content: center;
      > span + span {
        margin: 5px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
  }
`;
