import styled from 'styled-components';

import { shade } from 'polished';

import { Link } from 'react-router-dom';

interface FinItemProps {
  paint: number;
}

export const FinHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;

  width: 90vw;
  max-width: 800px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;

  margin-top: 15px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    p {
      font-size: 15px;
      color: #767676;
      strong {
        font-size: 15px;
        color: #332e2e;
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;

    display: grid;
    grid: auto / 1fr;
    column-gap: 15px;
    row-gap: 10px;
  }
`;

export const IncButton = styled(Link)`
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  padding: 3px 5px;
  text-decoration: none;

  border: 3px solid #00802b;
  background: #00802b;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  margin: 15px 0px;

  transition: border-color 0.35s ease, background-color 0.35s ease,
    margin 0.5s ease, opacity 0.5s ease;

  &:hover {
    background-color: ${shade(0.2, '#00802b')};
    border-color: ${shade(0.2, '#00802b')};
  }

  @media screen and (min-width: 889px) {
    opacity: 0;
    margin: 0px;
    pointer-events: all;
  }
`;

export const FinContent = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 800px;
  border-radius: 5px;

  align-items: center;
  justify-content: space-between;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    div {
      span {
        display: flex;
        align-items: center;
        /* width: 200px; */
        p {
          font-size: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 470px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const Wrapper = styled.div`
  border: 2px solid #332e2e;
  border-radius: 5px;
  /* border-top-width: 0px; */
  width: 90vw;
  max-width: 800px;
  overflow: hidden;

  @media screen and (max-width: 470px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const FinContentHeader = styled.div`
  display: flex;
  background: #332e2e;

  color: #fff;
`;

export const FinItem = styled.div<FinItemProps>`
  display: flex;

  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};
`;

export const Data = styled.span`
  padding-left: 3px;
  width: 200px;

  button {
    border: 0;
    background: transparent;

    svg {
      margin-left: 5px;
      cursor: pointer;
      color: #007acc;
      height: 20px;
      width: 20px;
      transition: color 0.35s ease;
    }

    &:hover {
      svg {
        color: ${shade(0.2, '#007acc')};
      }
    }
  }

  transition: width 0.75s ease;
`;

export const Anterior = styled.span`
  justify-content: flex-end;
  width: 200px;

  transition: width 0.75s ease, height 0.75s ease, opacity 0.35s ease;

  @media screen and (max-width: 465px) {
    width: 0px;
    height: 0px;
    overflow: hidden;
    opacity: 0;
  }
`;

export const Parcela = styled.span`
  justify-content: flex-end;
  width: 200px;
`;

export const Saldo = styled.span`
  padding-right: 5px;
  justify-content: flex-end;
  width: 150px;
`;

export const Track = styled.span`
  width: 150px;
  align-items: center;
  justify-content: center;

  button {
    border: 0;
    background: transparent;

    svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
      transition: color 0.35s ease;
    }
  }

  transition: width 0.75s ease;

  @media screen and (max-width: 465px) {
    width: 210px;
  }

  @media screen and (max-width: 400px) {
    width: 130px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;

  span {
    display: flex;
    flex-direction: column;
    & + span {
      margin-top: 5px;
    }
  }
`;

export const UpdateContainer = styled.div`
  p {
    color: #8a0002;
    font-size: 16px;
    font-weight: bold;
  }
`;
