import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  useMemo,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useChanges } from 'hooks/changes';
import { useCredentials } from 'hooks/credentials';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  AlteredHeader,
  ComboLocalProps,
  ComboProps,
  ComboGuiaProps,
} from 'styles/sgo_wrappers';

import api from 'services/api';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';
import { Wrapper, RelatedLocalsGrid, GridItemLabel } from './styles';

interface FormData {
  coord: string;
  coordenador: string;
}

const Multiplicacao: React.FC = () => {
  const history = useHistory();
  const { mud } = useChanges();
  const { user } = useAuth();
  const { handlePermission, errorHandling } = useCredentials();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locals, setLocals] = useState<ComboLocalProps[]>([]);
  const [guias, setGuias] = useState<ComboGuiaProps[]>([]);
  const [comboGuias, setComboGuias] = useState<ComboProps[]>([]);
  const [relatedLocals, setRelatedLocals] = useState<ComboProps[]>([]);
  const [chosenCoord, setChosenCoord] = useState<string>('');
  const [chosenLocals, setChosenLocals] = useState<string[]>([]);
  const [chosenCoordinator, setChosenCoordinator] = useState<string>('');

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    const filtered = response.data.filter(
      (item: ComboProps) =>
        item.value.substr(0, 2) === user.zoncod.substr(0, 2),
    );

    setComboNacs(filtered);
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setLocals(
      response.data.filter(
        (item: ComboLocalProps) =>
          item.anb.substring(0, 2) === user.zoncod.substring(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboGuias = useCallback(async () => {
    const response = await api.get(
      `/combos/comboGuias.php?data=${JSON.stringify({ filter: 'byZon' })}`,
    );
    const filtered = response.data.filter(
      (item: ComboGuiaProps) =>
        item.func === '' &&
        item.tg === 'GA' &&
        item.anb.substring(0, 2) === user.zoncod.substring(0, 2),
    );
    setGuias(filtered);
    setLoading(false);
  }, [user.zoncod]);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getComboANB();
    getComboLOC();
    getComboGuias();
  }, [getComboANB, getComboLOC, getComboGuias, handlePermission]);

  const handleCoordChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('coord');
    const { value } = select.options[select.selectedIndex];
    setRelatedLocals(
      locals.filter((item: ComboLocalProps) => item.anb === value),
    );
    setChosenCoord(value);
  }, [locals]);

  const handleCoordinatorChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('coordenador');
    const { value } = select.options[select.selectedIndex];
    setChosenCoordinator(value);
  }, []);

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event?.currentTarget;

      if (input) {
        let updateCL = [...chosenLocals];
        if (input.checked) {
          updateCL = [...chosenLocals, input.id];
        } else {
          updateCL = updateCL.filter((item) => item !== input.id);
        }

        setChosenLocals(updateCL);
        const possibleGuias = guias
          .filter((item) => updateCL.indexOf(item.loc) > -1)
          .map((item) => ({ value: item.seq, label: item.nome }));
        setComboGuias(possibleGuias);

        if (
          possibleGuias.length === 0 ||
          possibleGuias.map((item) => item.value).indexOf(chosenCoordinator) ===
            -1
        ) {
          setChosenCoordinator('');
        }
      }
    },
    [chosenCoordinator, chosenLocals, guias],
  );

  const enableButton = useMemo(() => {
    return !!chosenCoord && chosenLocals.length > 0 && !!chosenCoordinator;
  }, [chosenCoord, chosenLocals, chosenCoordinator]);

  const styledButton = useSpring({
    // opacity: enableButton ? 1 : 0.5,
    // pointerEvents: enableButton ? 'all' : 'none',
    // transform: enableButton ? 'translateX(0px)' : 'translateX(50px)',
    margin: '10px 0 25px 0',
    backgroundColor: enableButton ? '#00802B' : 'rgba(0,0,0,0.05)',
    color: enableButton ? '#fff' : '#767676',
    borderColor: enableButton ? '#00802B' : '#868686',
    cursor: enableButton ? 'pointer' : 'not-allowed',
  });

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        if (!enableButton) {
          return;
        }

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            orig: data.coord,
            coordenador: data.coordenador,
            locals: chosenLocals,
          }),
        );

        await api.post('/sgo/anbs_multiplicacao.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        history.push('/sgo/secretaria/nacionais');
      } catch (err) {
        errorHandling(err);
      }
    },
    [chosenLocals, enableButton, errorHandling, history],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Multiplicação de uma Nacional</p>
          </div>
        </AlteredHeader>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <div>
              <span>
                <p>Selecione a coordenação</p>
                <SelectV2
                  initial="Selecione"
                  name="coord"
                  content={comboNacs}
                  onChange={handleCoordChange}
                />
              </span>
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ margin: '15px 0 0 0' }}>Coordenações Locais:</p>
              {relatedLocals.length === 0 ? (
                <legend>* Selecione uma nacional para continuar</legend>
              ) : (
                <>
                  <RelatedLocalsGrid>
                    {relatedLocals.map((item) => (
                      <div key={`__${item.value}`}>
                        <GridItemLabel
                          marked={chosenLocals.indexOf(item.value) >= 0}
                          disabled={false}
                          htmlFor={item.value}
                        >
                          <input
                            id={item.value}
                            type="checkbox"
                            // defaultChecked={item.exists}
                            onChange={(event) => handleCheckboxChange(event)}
                          />
                          <strong>{item.label}</strong>
                        </GridItemLabel>
                      </div>
                    ))}
                  </RelatedLocalsGrid>
                </>
              )}
            </div>
            <div>
              <span>
                <p>Selecione o coordenador</p>
                <SelectV2
                  initial="Selecione"
                  name="coordenador"
                  content={comboGuias}
                  onChange={handleCoordinatorChange}
                />
                <legend>
                  * O coordenador não deve possuir função atualmente e deve
                  estar presente dentre as locais acima selecionadas.
                </legend>
              </span>
            </div>
          </Wrapper>
          {mud && (
            <Button bgcolor="#00802B" style={styledButton} type="submit">
              Multiplicar
            </Button>
          )}
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Multiplicacao;
