import React, { useState, useEffect, useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import SelectV2 from 'components/SelectV2';
import { Container, Content } from 'styles/wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import ScrollTop from 'components/ScrollTop';

import { deconvertSpecialChars } from 'utils/specialChars';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { GroupContainer, GroupGrid, GridItem, StyledForm } from './styles';

interface MaterialContent {
  grucod: string;
  matcod: string;
  matdesc: string;
  matdet: string;
  matphoto: string;
}

interface GrupoContent {
  grucod: string;
  grudesc: string;
}
interface ComboContent {
  value: string;
  label: string;
}

const MateriaisMoreDetails: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { errorHandling } = useCredentials();

  const [materiais, setMateriais] = useState<MaterialContent[]>([]);
  const [filterMateriais, setFilterMateriais] = useState<MaterialContent[]>([]);

  const [grupos, setGrupos] = useState<GrupoContent[]>([]);
  const [filterGrupos, setFilterGrupos] = useState<GrupoContent[]>([]);

  const [comboGrupos, setComboGrupos] = useState<ComboContent[]>([]);

  const handleGroupBlur = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboGrupos');
    const { value } = select.options[select.selectedIndex];

    let all = [...grupos];

    if (value !== '') {
      all = all.filter((item) => item.grucod === value);
    }

    setFilterGrupos(all);
  }, [grupos]);

  const getMaterials = useCallback(async () => {
    try {
      const response = await api.get('/web/materiais.php');
      const { groups, mats } = response.data;

      if (mats && groups) {
        setMateriais(mats);
        setFilterMateriais(mats);

        const setIt: GrupoContent[] = groups.map((item: GrupoContent) => {
          return {
            grucod: item.grucod,
            grudesc: deconvertSpecialChars(item.grudesc),
          };
        });

        setGrupos(setIt);
        setFilterGrupos(setIt);

        const groupCombo: ComboContent[] = [
          { value: '', label: 'Todos os Grupos' },
          ...groups.map((item: GrupoContent) => {
            return {
              value: item.grucod,
              label: deconvertSpecialChars(item.grudesc),
            };
          }),
        ];

        setComboGrupos(groupCombo);
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  const handleSubmit = useCallback(() => {
    setFilterMateriais(materiais);
  }, [materiais]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks />

      <StyledForm ref={formRef} initialData={{}} onSubmit={handleSubmit}>
        <SelectV2
          name="comboGrupos"
          initial="Selecione"
          content={comboGrupos}
          onChange={handleGroupBlur}
          containerStyle={{
            boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
          }}
        />
      </StyledForm>
      <Content>
        {filterGrupos.map((grupo) => (
          <GroupContainer key={grupo.grucod}>
            <h1>
              {grupo.grudesc} ({grupo.grucod})
            </h1>
            <GroupGrid>
              {filterMateriais
                .filter((mat) => mat.grucod === grupo.grucod)
                .map((mat) => (
                  <GridItem key={mat.matcod}>
                    <img
                      src={`${process.env.REACT_APP_ASSETS_DIR}/materiais/${mat.matphoto}`}
                      alt={mat.matdesc}
                    />
                    <span>
                      <h3>
                        {mat.matcod} - {mat.matdesc}
                      </h3>
                      <p>{mat.matdet}</p>
                    </span>
                  </GridItem>
                ))}
            </GroupGrid>
          </GroupContainer>
        ))}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default MateriaisMoreDetails;
