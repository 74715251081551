import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import Button from 'components/Button';
import { shade, linearGradient, lighten } from 'polished';

interface GridItemProps {
  required?: boolean;
  color?: string;
}

interface LabelProps {
  isChecked: boolean;
}
interface SpanMessageProps {
  color?: string;
}

export const AddressWarn = styled(animated.div)`
  display: flex;
  align-items: center;
  width: 100vw;
  background: #c53030;

  h4 {
    width: 100vw;
    font-size: 15px;
    color: #efefef;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
    text-align: center;
  }
`;

export const StyledButtonPhaseOne = styled(animated(Button))`
  margin: 25px auto;
`;

export const PhaseOne = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  form {
    padding: 10px 20px;
    margin: 10px auto;

    border: 3px solid #efefef;
    background: #ffffff;
    border-radius: 5px;

    span {
      > p {
        margin: 10px 0 0 0;
        color: #8a0002;
        font-weight: bold;

        font-size: 16px;
      }
    }

    @media screen and (max-width: 430px) {
      width: 100vw;
      padding: 5px 20px;
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
    }
  }
`;

export const PhaseTwo = styled(animated.div)``;

export const HeaderContainer = styled.figure`
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
  padding: 5px 7px 5px 0;
  font-size: 16px;
  color: #332e2e;

  border-radius: 0px 5px 5px 0px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
  );

  > p {
    font-weight: 500;
    color: #332e2e;

    strong {
      color: #8a0002;
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;

  border: 2px solid #efefef;
  background: #fff;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;

  span {
    display: flex;
    align-items: center;
    > p {
      font-size: 15px;
      margin-right: 5px;
      font-weight: 500;
      color: #8a0002;
    }

    > strong {
      font-size: 15px;
      width: 25px;
      text-align: center;
    }

    & + span {
      margin: 0 0 0 10px;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    padding: 5px 0px;
    border-radius: 0px;
    flex-direction: column;

    span {
      justify-content: center;

      & + span {
        margin: 5px 0 0 0;
      }
    }
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #efefef;
  background: #fff;
  border-radius: 5px;

  padding: 2px 15px 2px 2px;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  color: #00802b;

  transition: all 0.35s ease;

  p {
  }
  svg {
    height: 30px;
    width: 30px;
    margin-right: 5px;
  }

  &:hover {
    border-color: ${shade(0.1, '#efefef')};
    background: ${shade(0.1, '#fff')};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FinishButton = styled(animated.button)`
  padding: 5px 7px;
  border: 2px solid ${shade(0.2, '#00802b')};
  background: #00802b;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;

  transition: border-color 0.35s ease, background-color 0.35s ease;

  &:hover {
    border-color: ${shade(0.2, `${shade(0.2, '#00802b')}`)};
    background: ${shade(0.2, '#00802b')};
  }
`;

export const Total = styled(animated.div)`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 5px 7px 5px 0;
  font-size: 15px;
  color: #332e2e;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
  );

  strong {
    font-size: 16px;
    color: #2156e6;
  }
`;

export const Grid = styled.div`
  width: 100vw;
  padding: 0 10px;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;

  margin: 10px 0;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
    column-gap: 10px;
  }

  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
    column-gap: 0px;
    padding: 0;
    row-gap: 10px;
  }
`;

export const GridItem = styled(animated.div)<GridItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  ${(props) =>
    props.required &&
    css`
      border-color: ${props.color};

      &:hover {
        border-color: ${shade(0.2, `${props.color}`)};
      }
    `}

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    > strong {
      font-size: 15px;
      text-align: center;
      color: #8a0002;
    }

    span {
      display: flex;
      align-items: center;

      figure {
        position: absolute;
        right: 5px;

        > svg {
          cursor: pointer;

          width: 20px;
          height: 20px;

          position: absolute;
          right: 5px;
          bottom: 50%;
          transform: translateY(50%);
          color: #c53030;
          transition: color 0.35s ease;
          &:hover {
            color: #8a0002;
          }
        }
      }

      span {
        width: 60px;
        display: flex;
        flex-direction: column;
        position: relative;

        input {
          font-size: 15px;
          z-index: 2;
          width: 100%;
          padding: 1px 3px;
          text-align: center;
          border: 2px solid #767676;
          background: #efefef;
          border-radius: 5px;
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          display: none;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          appearance: none;
          margin: 0;
        }

        svg {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 35px;
          width: 35px;
        }
      }

      p {
        font-size: 15px;
        color: #767676;
        > strong {
          font-size: 15px;
          color: #332e2e;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const SpanMessage = styled(animated.span)<SpanMessageProps>`
  width: 100%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;

  p {
    width: 100%;
    text-align: center;
  }

  ${(props) =>
    props.color &&
    css`
      background-image: ${linearGradient({
        colorStops: [
          'rgba(0, 0, 255, 0) 0%',
          `${props.color} 5%`,
          `${props.color} 50%`,
          `${props.color} 95%`,
          'rgba(0, 0, 255, 0) 100%',
        ],
        toDirection: 'to right',
        fallback: '#FFF',
      })};
    `}

  ${(props) =>
    props.color === '#c53030' &&
    css`
      color: #fff;
    `}
`;

export const DeclareNumbers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label<LabelProps>`
  cursor: pointer;

  display: flex;
  align-items: center;

  ${(props) =>
    props.isChecked &&
    css`
      background: #332e2e;
    `}

  span {
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;

    p {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin-right: 10px;
    }

    svg {
      position: absolute;
      right: 0;
      color: #00cc44;
      opacity: 0;
      /* transform: scale(0);  */
      /* transform: rotateY(180deg); */
      transition: all 0.5s ease;
    }
  }

  /* input {
    display: none;
    width: 100%;
    height: 100%;
  } */

  & + label {
    margin: 5px 0 0 0;
  }
`;

export const DeclareButton = styled.button`
  margin: 5px 0;

  border: 2px solid #332e2e;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 16px;

  background: #efefef;

  color: #332e2e;

  transition: border-color 0.35s ease, background-color 0.35s ease,
    color 0.35s ease;
  &:hover {
    /* border-color: ${lighten(0.1, '#332e2e')}; */
    background: #332e2e;
    color: #efefef;
  }
`;

export const PhaseThree = styled(animated.div)`
  form {
    margin: 10px 0 0 0;
    border: 3px solid #efefef;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    div {
      padding: 5px 10px;
    }
  }

  button {
    margin: 10px auto;
  }
`;

export const ReturnButton = styled.button`
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: 5px;

  background: transparent;
  color: #565656;

  font-size: 16px;

  p {
    font-weight: 500;
  }

  padding: 0px 15px;

  svg {
    margin-right: 5px;
    transition: transform 0.35s ease;
  }

  &:hover {
    svg {
      transform: translateX(-10px);
    }
  }
`;
