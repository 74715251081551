import styled from 'styled-components';

export const LancContainer = styled.div`
  margin-top: 25px;
  width: 100vw;
`;

export const LancTitle = styled.div`
  margin: 0 auto;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(197, 48, 48, 0.25),
    rgba(197, 48, 48, 0.5),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(138, 0, 2, 1),
    rgba(197, 48, 48, 0.5),
    rgba(197, 48, 48, 0.25),
    rgba(255, 255, 255, 0)
  );

  color: #fff;
  h3 {
    font-size: 18.72px;
  }
`;

export const Grid = styled.div`
  width: 90vw;
  padding: 0 10px;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;

  margin: 10px auto;

  h4 {
    font-size: 16px;
    color: #8a0002;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 1366px) {
    width: 95vw;
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1050px) {
    width: 98vw;
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 660px) {
    grid: auto / 1fr;
  }

  @media screen and (max-width: 360px) {
    width: 100vw;
    padding: 0;
  }
`;

export const GridItem = styled.div`
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  span {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
      color: #767676;
      display: flex;
      align-items: center;
      strong {
        color: #332e2e;
      }
    }
  }

  @media screen and (max-width: 360px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const FloatCode = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;

  p {
    font-size: 13px;
  }
`;
