import styled, { css } from 'styled-components';

interface DisableProps {
  disabled?: boolean;
}

export const Grid = styled.div`
  position: relative;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  /* min-width: 1400px; */
  row-gap: 20px;
  column-gap: 30px;
  margin: 25px 0;
  padding: 15px;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 650px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 490px) {
    grid: auto / 1fr 1fr;
  }
`;

export const GridItem = styled.div<DisableProps>`
  width: 130px;
  height: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid #464646;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.35s ease;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        border-color: #a52a2a;
        box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
      `}

    a, button {
      p {
        opacity: 1;
      }
      svg {
        opacity: 0.2;
      }
    }
  }

  a,
  button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #332e2e;

    p {
      z-index: 1;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: 100%;

      opacity: 0;
      font-size: 18px;
      font-weight: 500;
      font-family: 'Roboto Slab', serif;

      transition: all 0.35s ease;
    }

    svg {
      height: 75px;
      width: 75px;
      opacity: 1;

      transition: all 0.35s ease;
    }
  }

  @media screen and (max-width: 490px) {
    a,
    button {
      p {
        opacity: 1;
      }
      svg {
        opacity: 0.2;
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;
