import styled from 'styled-components';

const world = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/world.png`;

export const Common = styled.section`
  width: 90vw;
  max-width: 1000px;
  margin: 10px 0;

  > span {
    display: flex;
    flex: 1;
    overflow-x: hidden;

    max-height: calc(100vh - 350px);

    @media screen and (max-width: 900px) {
      max-height: calc(100vh - 250px);
    }

    padding: 10px;
    background: #efefef;
    border: 2px solid #efefef;

    border-right: 0px solid transparent;
    border-radius: 5px;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #e6e6e6;
    }

    ::-webkit-scrollbar-thumb {
      background: #8a0002;

      &:hover {
        background: #c53030;
      }
    }
  }
`;

export const TOV = styled.span`
  display: flex;
  flex-direction: column;

  img {
    align-self: center;
    width: 80%;
    max-width: 350px;
    object-fit: cover;
  }
  h3 {
    margin: 15px 0;
    font-size: 18.72px;
  }
  p {
    font-size: 16px;
    & + p {
      margin-top: 10px;
    }
  }

  strong {
    margin: 15px 0;
    font-size: 16px;
  }

  ul {
    margin: 15px 0;
    list-style-type: square;

    li {
      margin-left: 35px;
    }
  }
`;

export const Frei = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 15px 0;
    font-size: 18.72px;
  }

  p {
    font-size: 16px;
    & + p {
      margin-top: 10px;
    }
  }

  strong {
    margin: 15px 0;
    font-size: 16px;
  }
`;

export const Mundo = styled.span`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 15px 0;
    font-size: 18.72px;
  }
  p {
    font-size: 16px;
    & + p {
      margin-top: 10px;
    }
  }

  strong {
    margin: 15px 0;
    font-size: 16px;
  }

  span {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 150px;

    background-image: url(${world});
    background-repeat: no-repeat;
    background-position-y: 50%;
    border-radius: 5px;

    a {
      border-radius: 5px;

      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      width: 100%;
      background: rgba(51, 46, 46, 0.5);
      text-align: center;
      text-decoration: none;

      font-family: 'Roboto Slab', serif;
      font-weight: 800;
      color: #e6e6ee;

      transition: all 0.5s ease;

      p {
        font-size: 16px;
        text-shadow: 0px 0px 3px #262626;
        transition: all 0.5s ease;
      }

      &:hover {
        color: #fff;
        background: rgba(51, 46, 46, 0.75);
        p {
          font-size: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    span {
      height: 100px;
    }
  }
`;

export const Missao = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;

    img {
      height: 80%;
      max-height: 200px;
      /* width: 80%;
      max-width: 150px; */
      object-fit: cover;
    }
  }

  h3 {
    margin: 15px 0;
    font-size: 18.72px;
  }

  h4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-size: 14px;
  }
  p {
    z-index: 1;
    font-size: 16px;
    & + p {
      margin-top: 10px;
    }
  }

  strong {
    margin: 15px 0;
    font-size: 16px;
  }
`;

export const Decreto = styled.span`
  display: flex;
  flex-direction: column;
  span {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img {
      height: 80%;
      max-height: 250px;
      object-fit: cover;
    }

    span {
      display: flex;
      flex-direction: column;
      h3 {
        font-style: italic;
        margin: 15px 0;

        & + h3 {
          margin: 0;
        }
      }
    }
  }

  p {
    font-size: 16px;
    & + p {
      margin-top: 10px;
    }
  }

  strong {
    margin: 15px 0;
    font-size: 16px;
  }

  > img {
    border: 2px solid #332e2e;
    border-radius: 5px;
    margin: 10px 0;
    align-self: center;
    height: 250px;
    object-fit: contain;
    object-position: 0 0;
    box-shadow: -5px 2px 5px 2px rgba(0, 0, 0, 0.5);
  }
`;
