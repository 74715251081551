import GrupoMateriais from 'pages/sgo/Materiais/GruposMateriais/main';
import GrupoMateriaisInsert from 'pages/sgo/Materiais/GruposMateriais/insert';
import GrupoMateriaisUpdate from 'pages/sgo/Materiais/GruposMateriais/update';

import Materiais from 'pages/sgo/Materiais/Materiais/main';
import MateriaisInsert from 'pages/sgo/Materiais/Materiais/insert';
import MateriaisUpdate from 'pages/sgo/Materiais/Materiais/update';
import MateriaisMore from 'pages/sgo/Materiais/Materiais/more';

import Entradas from 'pages/sgo/Materiais/Entradas/main';
import EntradasInsert from 'pages/sgo/Materiais/Entradas/insert';

import EstoqueMenu from 'pages/sgo/Materiais/Estoque/menu';
import Estoque from 'pages/sgo/Materiais/Estoque/main';
import EstoqueAjustes from 'pages/sgo/Materiais/Estoque/ajustes';
import EstoqueSolic from 'pages/sgo/Materiais/Estoque/estoquexsolic';

import Solic from 'pages/sgo/Materiais/Solic/main';
import SolicConsult from 'pages/sgo/Materiais/Solic/consult';
import SolicInsert from 'pages/sgo/Materiais/Solic/insert';
import SolicUpdate from 'pages/sgo/Materiais/Solic/update';

import RM from 'pages/sgo/Materiais/RM/main';
import RMConsult from 'pages/sgo/Materiais/RM/consult';
import RMInsert from 'pages/sgo/Materiais/RM/insert';
import RMSolic from 'pages/sgo/Materiais/RM/solic';

import CE from 'pages/sgo/Materiais/CE/main';
import CEMenu from 'pages/sgo/Materiais/CE/menu';
import CEConsult from 'pages/sgo/Materiais/CE/consult';
import CEInsert from 'pages/sgo/Materiais/CE/insert';
import CEListGUIA from 'pages/sgo/Materiais/CE/guia';

import Pedido from 'pages/sgo/Materiais/Pedido/main';
import PedidoConsult from 'pages/sgo/Materiais/Pedido/consult';
import PedidoInsert from 'pages/sgo/Materiais/Pedido/insert';
import PedidoUpdate from 'pages/sgo/Materiais/Pedido/update';
import PedidoMenu from 'pages/sgo/Materiais/Pedido/menu';
import PedidoEstoque from 'pages/sgo/Materiais/Pedido/estoque';
import PedidoFornecedor from 'pages/sgo/Materiais/Pedido/fornecedor';

import GD from 'pages/sgo/Materiais/GD/main';
import GDConsult from 'pages/sgo/Materiais/GD/consult';
import GDInsert from 'pages/sgo/Materiais/GD/insert';
import GDPedido from 'pages/sgo/Materiais/GD/pedido';

import HistEnv from 'pages/sgo/Materiais/HistEnv';
import HistRec from 'pages/sgo/Materiais/HistRec';

import DM from 'pages/sgo/Materiais/DM/main';
import DMConsult from 'pages/sgo/Materiais/DM/consult';
import DMInsert from 'pages/sgo/Materiais/DM/insert';

import DigitalMedia from 'pages/sgo/Materiais/DigitalMedia/main';
import DigitalMediaContent from 'pages/sgo/Materiais/DigitalMedia/content';

// import DEV from 'pages/DEV';

const MRoutes = [
  { path: `/grupos`, isPrivate: true, component: GrupoMateriais },
  { path: `/grupos/insert`, isPrivate: true, component: GrupoMateriaisInsert },
  { path: `/grupos/update`, isPrivate: true, component: GrupoMateriaisUpdate },
  { path: `/materiais`, isPrivate: true, component: Materiais },
  { path: `/materiais/insert`, isPrivate: true, component: MateriaisInsert },
  { path: `/materiais/update`, isPrivate: true, component: MateriaisUpdate },
  {
    path: `/materiais/more-details`,
    isPrivate: true,
    component: MateriaisMore,
  },
  { path: `/entradas`, isPrivate: true, component: Entradas },
  { path: `/entradas/insert`, isPrivate: true, component: EntradasInsert },
  { path: `/estoque/menu`, isPrivate: true, component: EstoqueMenu },
  { path: `/estoque`, isPrivate: true, component: Estoque },
  { path: `/estoque/ajustes`, isPrivate: true, component: EstoqueAjustes },
  { path: `/estoque/estoquexsolic`, isPrivate: true, component: EstoqueSolic },
  { path: `/solic`, isPrivate: true, component: Solic },
  { path: `/solic/consult`, isPrivate: true, component: SolicConsult },
  { path: `/solic/insert`, isPrivate: true, component: SolicInsert },
  { path: `/solic/update`, isPrivate: true, component: SolicUpdate },
  { path: `/rm`, isPrivate: true, component: RM },
  { path: `/rm/consult`, isPrivate: true, component: RMConsult },
  { path: `/rm/insert`, isPrivate: true, component: RMInsert },
  { path: `/rm/solic`, isPrivate: true, component: RMSolic },
  { path: `/ce/menu`, isPrivate: true, component: CEMenu },
  { path: `/ce`, isPrivate: true, component: CE },
  { path: `/ce/consult`, isPrivate: true, component: CEConsult },
  { path: `/ce/insert`, isPrivate: true, component: CEInsert },
  { path: `/ce/all`, isPrivate: true, component: CEListGUIA },
  { path: `/pedido`, isPrivate: true, component: Pedido },
  { path: `/pedido/consult`, isPrivate: true, component: PedidoConsult },
  { path: `/pedido/insert`, isPrivate: true, component: PedidoInsert },
  { path: `/pedido/update`, isPrivate: true, component: PedidoUpdate },
  { path: `/pedido/menu`, isPrivate: true, component: PedidoMenu },
  { path: `/pedido/estoque`, isPrivate: true, component: PedidoEstoque },
  { path: `/pedido/fornecedor`, isPrivate: true, component: PedidoFornecedor },
  { path: `/gd`, isPrivate: true, component: GD },
  { path: `/gd/consult`, isPrivate: true, component: GDConsult },
  { path: `/gd/insert`, isPrivate: true, component: GDInsert },
  { path: `/gd/pedido`, isPrivate: true, component: GDPedido },
  { path: `/histenv`, isPrivate: true, component: HistEnv },
  { path: `/histrec`, isPrivate: true, component: HistRec },
  { path: `/dm`, isPrivate: true, component: DM },
  { path: `/dm/consult`, isPrivate: true, component: DMConsult },
  { path: `/dm/insert`, isPrivate: true, component: DMInsert },
  { path: `/digital-content`, isPrivate: true, component: DigitalMedia },
  {
    path: `/digital-content/content`,
    isPrivate: true,
    component: DigitalMediaContent,
  },
  //DEV
  // { path: `/solicdevol`, isPrivate: true, component: DEV },
];

export default MRoutes;
