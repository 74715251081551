import styled from 'styled-components';
import { animated } from 'react-spring';

export const PhaseOne = styled(animated.div)``;

export const HeaderComponent = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;

  p {
    color: #363636;
    strong {
      color: #332e2e;
    }
  }
`;

export const BodyComponent = styled.div``;

export const PhaseTwo = styled(animated.div)`
  form {
    margin: 10px 0 0 0;
    border: 3px solid #efefef;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    div {
      padding: 5px 10px;
    }
  }

  button {
    margin: 10px auto;
  }
`;
