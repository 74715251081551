import { linearGradient, shade } from 'polished';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

interface GridItemProps {
  required?: boolean;
  color?: string;
}

interface SpanMessageProps {
  color?: string;
}

export const HeaderContent = styled.div`
  width: 100vw;
  max-width: 1000px;
  padding: 5px 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    justify-content: center;
    span {
      display: flex;

      p {
        font-size: 15px;
        color: #767676;

        strong {
          margin-left: 3px;
          color: #332e2e;
        }

        & + p {
          margin: 0 0 0 5px;
        }
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }

    & + div {
      margin: 0 0 0 10px;
    }
  }

  @media screen and (max-width: 1000px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }

  @media screen and (max-width: 850px) {
    justify-content: space-around;
    div {
      flex-direction: column;
      span {
        p {
          font-size: 13px;
        }
        & + span {
          margin: 5px 0 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;

    div + div {
      margin: 5px 0 0 0;
    }
  }
`;

export const TotalSolic = styled.div`
  margin: 15px 0 5px 0;
  width: 100vw;
  max-width: 1000px;
  display: flex;
  justify-content: flex-end;

  p {
    padding: 0 5px;
    font-size: 16px;
    color: #332e2e;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;

    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 1)
    );

    strong {
      color: #2156e6;
    }

    @media screen and (max-width: 1000px) {
      border-radius: 0;
    }
  }
`;

export const ButtonFinish = styled(animated.div)`
  margin: 15px 0 5px 0;
  width: 100vw;
  max-width: 1000px;
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border: 2px solid ${shade(0.2, '#00802b')};
    background: #00802b;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;

    transition: border-color 0.35s ease, background-color 0.35s ease;

    svg {
      margin-left: 5px;
      transition: transform 0.35s ease;
    }

    &:hover {
      border-color: ${shade(0.2, `${shade(0.2, '#00802b')}`)};
      background: ${shade(0.2, '#00802b')};

      svg {
        transform: translateX(3px);
      }
    }
  }
`;

export const Grid = styled.div`
  width: 100vw;
  padding: 0 10px;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;

  margin: 10px 0;

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
    column-gap: 10px;
  }

  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    grid: auto / 1fr;
    column-gap: 0px;
    padding: 0;
    row-gap: 10px;
  }
`;

export const GridItem = styled(animated.div)<GridItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  ${(props) =>
    props.required &&
    css`
      border-color: ${props.color};

      &:hover {
        border-color: ${shade(0.2, `${props.color}`)};
      }
    `}

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    > strong {
      font-size: 15px;
      text-align: center;
      color: #8a0002;
    }

    span {
      display: flex;
      align-items: center;

      figure {
        position: absolute;
        right: 5px;

        > svg {
          cursor: pointer;

          width: 20px;
          height: 20px;

          position: absolute;
          right: 5px;
          bottom: 50%;
          transform: translateY(50%);
          color: #c53030;
          transition: color 0.35s ease;
          &:hover {
            color: #8a0002;
          }
        }
      }

      span {
        width: 60px;
        display: flex;
        flex-direction: column;
        position: relative;

        input {
          font-size: 15px;
          z-index: 2;
          width: 100%;
          padding: 1px 3px;
          text-align: center;
          border: 2px solid #767676;
          background: #efefef;
          border-radius: 5px;
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          display: none;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          appearance: none;
          margin: 0;
        }

        svg {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 35px;
          width: 35px;
        }
      }

      p {
        font-size: 15px;
        color: #767676;
        > strong {
          font-size: 15px;
          color: #332e2e;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const SpanMessage = styled(animated.span)<SpanMessageProps>`
  width: 100%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;

  p {
    width: 100%;
    text-align: center;
  }

  ${(props) =>
    props.color &&
    css`
      background-image: ${linearGradient({
        colorStops: [
          'rgba(0, 0, 255, 0) 0%',
          `${props.color} 5%`,
          `${props.color} 50%`,
          `${props.color} 95%`,
          'rgba(0, 0, 255, 0) 100%',
        ],
        toDirection: 'to right',
        fallback: '#FFF',
      })};
    `}

  ${(props) =>
    props.color === '#c53030' &&
    css`
      color: #fff;
    `}
`;

export const DeclareNumbers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeclareButton = styled.button`
  margin: 5px 0;

  border: 2px solid #332e2e;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 16px;

  background: #efefef;

  color: #332e2e;

  transition: border-color 0.35s ease, background-color 0.35s ease,
    color 0.35s ease;
  &:hover {
    background: #332e2e;
    color: #efefef;
  }
`;

export const Insufficient = styled.div`
  width: 100%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* font-weight: bold; */

  p {
    width: 100%;
    text-align: center;
    color: #fff;
  }

  background-image: ${linearGradient({
    colorStops: [
      'rgba(0, 0, 255, 0) 0%',
      `#c53030 5%`,
      `#c53030 50%`,
      `#c53030 95%`,
      'rgba(0, 0, 255, 0) 100%',
    ],
    toDirection: 'to right',
    fallback: '#FFF',
  })};
`;

export const FaultyDiv = styled(animated.div)`
  width: 100vw;
  max-width: 1000px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  p {
    font-weight: bold;
    font-size: 16px;
    span {
      color: red;
    }
  }
`;

export const OBSRM = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    display: flex;
    background: #ffffff;
    border: 2px solid #c6c6c6;
    border-radius: 5px;
    resize: none;

    font-size: 15px;
    font-family: 'Roboto Slab', serif;
    width: 360px;
    height: 100px;
    /* width: calc(75vw - 100px); */

    @media screen and (max-width: 500px) {
      width: 250px;
      height: 100px;
    }
  }

  p {
    font-size: 17px;
    strong {
      font-size: 17px;
    }

    & + p {
      margin: 15px 0 0 0;
    }
  }
`;
