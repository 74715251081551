import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface OBSProps {
  mode: string;
}

interface DecisionProps {
  bgcolor: string;
}

interface WrapperProps {
  appear: boolean;
}

export const Wrapper = styled.div`
  margin: 20px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: #fff;

  width: 500px;

  padding: 5px 10px;

  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    display: flex;
    /* align-items: center; */

    h4 {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0 3px 0 0;
    }

    p {
      font-size: 16px;
    }

    svg {
      height: 20px;
      width: 20px;
      margin: 0 5px 0 0;
    }
    & + span {
      margin: 5px 0 0 0;
    }
  }

  transition: all 0.35s ease;
  @media screen and (max-width: 500px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

// export const OBSContainer = styled.span<OBSProps>`
/* ${(props) =>
    props.mode === 'P' &&
    css`
      flex-direction: column;
    `} */
export const OBSContainer = styled.span`
  display: flex;
  align-items: flex-start;
  width: 100%;

  h4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    margin: 0 3px 0 0;
  }
`;

export const ButtonWrapper = styled.div<WrapperProps>`
  margin: 15px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 250px;

  opacity: 0;
  pointer-events: none;

  ${(props) =>
    props.appear &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  button {
    padding: 5px 10px;
    box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.35s ease, background-color 0.35s ease,
      border-color 0.35s ease;
    color: #fff;

    border: 2px solid transparent;
    border-radius: 3px;
    font-size: 16px;
    font-family: 'Roboto Slab', serif;

    &:hover {
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const DecisionButton = styled.button<DecisionProps>`
  background-color: ${(props) => props.bgcolor};
  border-color: ${(props) => props.bgcolor};

  &:hover {
    background-color: ${(props) => shade(0.2, `${props.bgcolor}`)};
    border-color: ${(props) => shade(0.2, `${props.bgcolor}`)};
  }
`;
