import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Container, Content } from '../../../../../styles/sgo_wrappers';
import {
  CoordContainer,
  CoordHeader,
  InfoWrapper,
  CoordCommon,
} from './styles';

import SGOHeader from '../../../../../components/SGOHeader';
import SGONavbar from '../../../../../components/SGONavbar';
import ScrollTop from '../../../../../components/ScrollTop';
import SGOFooter from '../../../../../components/SGOFooter';

import { LocalProps } from '../main';

interface StateProps {
  coord: LocalProps;
}

const LocaisConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [coord, setCoord] = useState<LocalProps>({} as LocalProps);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
    } else {
      setCoord(location.state.coord);
    }
  }, [location.state, location.pathname, history]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks />
      <Content>
        <CoordContainer>
          <CoordHeader>
            <h1>
              {coord?.loccod} - {coord?.desc}
              <span>
                <p>{coord?.anbdesc}</p>
              </span>
            </h1>
          </CoordHeader>
          <InfoWrapper>
            <CoordCommon>
              <h3>Guias com Função</h3>
              <span>
                <h4>Coordenação:</h4>
                <p>{coord.coor?.name}</p>
              </span>
              <span>
                <h4>Secretaria:</h4>
                <p>{coord.secr?.name}</p>
              </span>
              <span>
                <h4>Tesouraria:</h4>
                <p>{coord.teso?.name}</p>
              </span>
              <span>
                <h4>Enc. EPG:</h4>
                <p>{coord.eesc?.name}</p>
              </span>
              <span>
                <h4>Jovem:</h4>
                <p>{coord.ejov?.name}</p>
              </span>
            </CoordCommon>
            <CoordCommon>
              <h3>Endereço</h3>
              <span>
                <h4>Endereço:</h4>
                <p>{coord.delivery?.address}</p>
              </span>
              <span>
                <h4>Bairro:</h4>
                <p>{coord.delivery?.district}</p>
              </span>
              <span>
                <h4>Cidade | UF :</h4>
                <p>
                  {coord.delivery?.city} | {coord.delivery?.state}
                </p>
              </span>
              <span>
                <h4>CEP:</h4>
                <p>{coord.delivery?.code}</p>
              </span>
            </CoordCommon>
          </InfoWrapper>
        </CoordContainer>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default LocaisConsult;
