import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import { useLocation, useHistory } from 'react-router-dom';

import { useCredentials } from 'hooks/credentials';
import { useChanges } from 'hooks/changes';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';
import * as yup from 'yup';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import { monthList } from 'pages/sgo/Tesouraria/monthList';
import getValidationErrors from 'utils/getValidationErrors';
import { FIGuiaProps } from '../../main';
import { ListProps } from '../main';
import { List, FormDIV } from './styles';

interface OptionsInterface extends FIGuiaProps {
  maxDate: string;
  minDate: string;
  rawList: ListProps[];
}

interface OptionsStateProps {
  options: OptionsInterface;
}

interface AssistsProps {
  value: number;
  desc: string;
  assist: boolean;
}

interface FormData {
  ano: string;
  service: string;
}

interface UniqueProps {
  year: number;
  content: string[];
}

const FIGAssistInc: React.FC = () => {
  const { currDate } = useChanges();
  const formRef = useRef<FormHandles>(null);
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<OptionsStateProps>();
  const [unique, setUnique] = useState<UniqueProps[]>([]);
  const [assistErr, setAssistErr] = useState(false);
  const [assists, setAssists] = useState<AssistsProps[]>(() => [
    ...monthList.map((item) => ({
      value: item.value,
      desc: item.label,
      assist: false,
    })),
  ]);

  const [gState] = useState<OptionsInterface>(
    () =>
      (({ ...location.state.options } as OptionsInterface) ||
      ({} as OptionsInterface)),
  );

  const [rawComboTS, setRawComboTS] = useState<ComboProps[]>([]);
  const [comboTS, setComboTS] = useState<ComboProps[]>([]);

  const [disableMode, setDisableMode] = useState('all');

  const getUniqueServices = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/fig_assist.php?data=${JSON.stringify({
          mode: 'unique',
          ...gState,
        })}`,
      );

      setUnique(response.data);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, gState]);

  const getComboTS = useCallback(async () => {
    const response = await api.get(
      `combos/comboServices.php?data=${JSON.stringify({ mode: 'ASSIST_FIG' })}`,
    );

    setRawComboTS(
      response.data.map(
        (item: { value: string; label: string; type: string }) => ({
          value: item.value,
          label: item.label,
        }),
      ),
    );
    // setComboTS(
    //   response.data.map(
    //     (item: { value: string; label: string; type: string }) => ({
    //       value: item.value,
    //       label: item.label,
    //     }),
    //   ),
    // );
  }, []);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getUniqueServices();

    getComboTS();
  }, [history, getComboTS, getUniqueServices, location.state]);

  const handleSearch = useCallback(() => {
    const input: HTMLInputElement = formRef.current?.getFieldRef('ano');
    if (input.value.length < 4) {
      assists.forEach((item) => {
        if (item.assist) {
          const element = document.querySelector<HTMLInputElement>(
            `[data-assoc='${item.value}']`,
          );
          if (element) {
            element.click();
          }
        }
      });

      setDisableMode('all');
      return;
    }

    if (input.value.length >= 4) {
      input.value = input.value.substr(0, 4);
    }

    const selectedYear = parseInt(input.value, 10);
    const currSysYear = currDate.year;
    const currSysSem = currDate.month < 7 ? 1 : 2;

    if (
      (selectedYear === currSysYear && currSysSem === 1) ||
      selectedYear > currSysYear
    ) {
      setDisableMode('all');
    } else if (selectedYear === currSysYear && currSysSem === 2) {
      setDisableMode('half');
    } else if (selectedYear < currSysYear) {
      setDisableMode('none');
    }

    formRef.current?.setFieldValue('service', '');
    setComboTS([]);
    if (input.value.length === 4) {
      const index = unique.findIndex(
        (item) => item.year === parseInt(input.value, 10),
      );

      if (index > -1) {
        setComboTS(
          rawComboTS.filter(
            (item) => !unique[index].content.includes(item.value),
          ),
        );
        // setComboTS([...unique[index].content.map(item => item)]);
        return;
      }

      setComboTS(rawComboTS);
    }
  }, [currDate, assists, unique, rawComboTS]);

  const handleCheckbox = useCallback(
    (id: string) => {
      const index = assists.findIndex(
        (item) => item.desc.substr(0, 3).toLowerCase() === id,
      );

      assists[index].assist = !assists[index].assist;

      setAssists((state) => [...state]);
    },
    [assists],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        setAssistErr(false);
        if (
          assists.reduce((accum, { assist }: AssistsProps) => {
            return assist ? accum + 1 : accum;
          }, 0) === 0
        ) {
          setAssistErr(true);
          setLoading(false);
          return;
        }

        const schema = yup.object().shape({
          ano: yup
            .number()
            .typeError('Campo obrigatório')
            .min(
              new Date(gState.minDate).getFullYear(),
              `Ano deve ser superior a ${new Date(
                gState.minDate,
              ).getFullYear()}`,
            )
            .max(
              new Date(gState.maxDate).getFullYear(),
              `Ano deve ser inferior a ${new Date(
                gState.maxDate,
              ).getFullYear()}`,
            ),
          service: yup.string().notOneOf(['', 'Selecione']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({ ...data, ...gState, assists, mode: 'inc' }),
        );

        await api.post('/sgo/fig_assist.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        history.goBack();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [assists, errorHandling, gState, history],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`${gState.name}`} />
      <Content>
        <AlteredHeader>Nova Assistência</AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormDIV>
            <span>
              <p>Ano:</p>
              <Input
                name="ano"
                placeholder="Ano"
                mask="number"
                type="number"
                step="1"
                containerStyle={{ height: '37.5px' }}
                onChange={handleSearch}
              />
            </span>
            <span>
              <p>Tipo do Serviço:</p>
              <SelectV2
                name="service"
                content={comboTS}
                containerStyle={{
                  /* , width: '177.5px' */
                  height: '37.5px',
                }}
                initial="Selecione"
              />
            </span>
          </FormDIV>

          <List err={assistErr}>
            <div>
              <div>
                {assists.map((item, index) => (
                  <span>
                    <p>{item.desc.substr(0, 3)}</p>
                    <Checkbox
                      containerStyle={{ display: 'flex' }}
                      titleStyle={{ display: 'none' }}
                      labelStyle={{ margin: '0px auto' }}
                      id={item.desc.substr(0, 3).toLowerCase()}
                      title=""
                      checked={assists[index].assist}
                      onClick={() =>
                        handleCheckbox(item.desc.substr(0, 3).toLowerCase())
                      }
                      isDisabled={
                        disableMode === 'all'
                          ? true
                          : disableMode === 'half'
                          ? item.value > 6
                          : false
                      }
                      data-assoc={assists[index].value}
                    />
                  </span>
                ))}
              </div>
            </div>
          </List>
          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FIGAssistInc;
