import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';
import ImageGenerator from 'components/ImageGenerator';

import { useSpring } from 'react-spring';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useAuth } from 'hooks/auth';

import api from 'services/api';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
} from 'styles/sgo_wrappers';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';
import { format } from 'date-fns';

import { FaFileExcel } from 'react-icons/fa';
import { ListProps as MainProps } from '../main';

import {
  Wrapper,
  FormWrapper,
  Print,
  Preview,
  Header,
  PreviewBody,
  Message,
  Options,
  Warn,
} from './styles';

interface FormData {
  first: string;
  second: string;
}

export interface OptionsProps {
  name: string;
  loc: string;
  anb: string;
}

export interface VotedProps {
  seq: string;
  zon: string;
  zondesc: string;
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  coor: string;
  obj: string;
  ini: string;
  end: string;
  voteID: string;
  voted: boolean;
  voted_at: string;
  options: OptionsProps[];
}

const EleicaoVote: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<MainProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const [conv] = useState(() => ({ ...location.state }));
  const [appear, setAppear] = useState(false);

  const [rawCombo, setRawCombo] = useState<ComboProps[]>([]);
  const [first, setFirst] = useState<ComboProps[]>([]);
  const [fs, setFS] = useState('Selecione');
  const [second, setSecond] = useState<ComboProps[]>([]);
  const [ss, setSS] = useState('Selecione');
  const [voteInfo, setVoteInfo] = useState<VotedProps>({} as VotedProps);
  const [options, setOptions] = useState<OptionsProps[]>([]);
  // const [conv, setConv] = useState<ConvProps>({} as ConvProps);

  const getHasVoted = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/eleicao_info.php?data=${JSON.stringify({
          uuid: conv.seq,
        })}`,
      );
      setVoteInfo(response.data);

      const { voted }: VotedProps = response.data;

      setAppear(!voted);

      setOptions(response.data.options);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [conv.seq]);

  const getComboEleicao = useCallback(async () => {
    const response = await api.get(
      `/combos/comboEleicao.php?data=${JSON.stringify({
        seq: conv.seq,
        coor: conv.coor,
        future: JSON.stringify(conv.future),
      })}`,
    );

    setRawCombo(response.data);
    setFirst(response.data);
    setSecond(response.data);
    setLoading(false);
  }, [conv.coor, conv.seq, conv.future]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getComboEleicao();
    getHasVoted();
  }, [getComboEleicao, getHasVoted, history, location.state]);

  const handleComboFirst = useCallback(() => {
    const fval = formRef.current?.getFieldValue('first');
    setFS(fval);

    if (conv.coor === 'N') {
      return;
    }

    const sval = formRef.current?.getFieldValue('second');

    if (sval === fval) {
      setSS('Selecione');
    }

    setSecond(rawCombo.filter((item) => item.value !== fval));
  }, [conv.coor, rawCombo]);

  const handleComboSecond = useCallback(() => {
    const sval = formRef.current?.getFieldValue('second');
    setSS(sval);

    const fval = formRef.current?.getFieldValue('first');

    if (fval === sval) {
      setFS('Selecione');
    }

    setFirst(rawCombo.filter((item) => item.value !== sval));
  }, [rawCombo]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          first: yup.string().notOneOf(['Selecione', '']),
          second: yup.string().when('orig', {
            is: () => conv.coor !== 'N',
            then: yup.string().notOneOf(['Selecione', '']),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ ...data, uuid: conv.seq, mode: conv.coor }),
        );

        await api.post('/sgo/eleicao_vote.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setAppear(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro computado.',
        });

        getHasVoted();
        // const send = new FormData();
        // send.append('data', JSON.stringify(data));

        // history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, conv.coor, conv.seq, errorHandling, getHasVoted],
  );

  const handleDownload = useCallback(() => {
    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }
  }, []);

  const smoothAppearance = useSpring({
    opacity: voteInfo.voted ? 1 : 0,
    pointerEvents: voteInfo.voted ? 'all' : 'none',
  });

  const shouldAppear = useSpring({
    opacity: appear ? 1 : 0,
    pointerEvents: appear ? 'all' : 'none',
    display: appear ? 'block' : 'none',
    width: '100vw',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Registro do Guia" />
      <Content>
        <FormWrapper style={shouldAppear}>
          <AlteredHeader>
            <div>
              <p>
                Escolha{conv.coor !== 'N' ? 's' : ''} para {conv.obj}
              </p>
            </div>
            <div>
              <p>
                Coordenação&nbsp;{conv.future ? 'de Origem: ' : ''}
                {conv.coor === 'L'
                  ? conv.locdesc
                  : conv.coor === 'N'
                  ? conv.future
                    ? conv.anbrefdesc
                    : conv.anbdesc
                  : conv.zondesc}
              </p>
            </div>
          </AlteredHeader>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Wrapper>
              <div>
                <span>
                  <p>
                    {conv.future ? 'Futuro ' : ''}Coordenador
                    {conv.coor !== 'N' ? ' (opção 1):' : ':'}
                  </p>
                  <SelectV2
                    name="first"
                    content={first}
                    initial={fs}
                    onChange={handleComboFirst}
                  />
                </span>
              </div>
              {conv.coor !== 'N' ? (
                <div>
                  <span>
                    <p>Coordenador (opção 2):</p>
                    <SelectV2
                      name="second"
                      content={second}
                      initial={ss}
                      onChange={handleComboSecond}
                    />
                  </span>
                </div>
              ) : null}
            </Wrapper>

            <Button bgcolor="#00802b" type="submit">
              Confirmar
            </Button>
          </Form>

          <Print>
            <button type="button" onClick={handleDownload}>
              <FaFileExcel /> <p>Candidatos</p>
            </button>
          </Print>
          <a
            style={{ display: 'none' }}
            id="xls"
            href={`${
              process.env.REACT_APP_API
            }/sgo/xls_conv_candidatos.php?data=${JSON.stringify({
              seq: conv.seq,
            })}`}
            rel="noreferrer noopener"
            target="_top"
          >
            XLS
          </a>
        </FormWrapper>

        <ImageGenerator
          containerStyle={{ margin: '15px 0', ...smoothAppearance }}
          buttonColor="#332e2e"
          buttonText="Gerar Comprovante"
          imageName={voteInfo.voteID}
        >
          <Preview>
            <Header>
              <p>SGO - Comprovante de Eleição / Consulta Prévia</p>
            </Header>
            <PreviewBody>
              <span>
                <p>Guia:</p>
                <strong>{user.name}</strong>
              </span>
              <span>
                <p>Coordenação:</p>
                <strong>{user.locdesc}</strong>
              </span>
            </PreviewBody>
            <Message>
              <span>
                <p>
                  Atendeu a convocação para Eleição / Consulta Prévia do
                  período:
                </p>
                <strong>
                  {formatDate(conv.ini)} a {formatDate(conv.end)}
                </strong>
              </span>
            </Message>
            <Message>
              <span>
                <p style={{ width: '100%' }}>
                  Para coordenação{' '}
                  {conv.coor === 'Z'
                    ? 'zonal'
                    : conv.coor === 'N'
                    ? 'nacional'
                    : 'local'}
                  :&nbsp;
                  <strong>
                    {conv.coor === 'Z'
                      ? conv.zondesc
                      : conv.coor === 'N'
                      ? conv.anbdesc
                      : conv.locdesc}
                  </strong>
                </p>
              </span>
            </Message>
            <Options>
              {options.map((item, index) => (
                <div>
                  <span>
                    <p>
                      {index === 0 && conv.coor !== 'N'
                        ? 'Primeira o'
                        : conv.coor !== 'N'
                        ? 'Segunda o'
                        : 'O'}
                      pção:
                    </p>
                  </span>
                  <span>
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p>
                      Coord. Nacional: <strong>{item.loc}</strong>
                    </p>
                    <p>
                      Coord. Local: <strong>{item.anb}</strong>
                    </p>
                  </span>
                </div>
              ))}
            </Options>
            <Message>
              <span>
                <p>Votação realizada em:</p>
                <strong>
                  {!!voteInfo.voted_at &&
                    format(new Date(voteInfo.voted_at), 'dd/MM/yyyy HH:mm:ss')}
                </strong>
              </span>
            </Message>
            <Warn>
              <strong>
                Importante: Esse comprovante poderá ser impresso mas deverá ser
                mantido sob total sigilo. Não poderá ser divulgado e nem
                apresentado a ninguém salvo em caso de auditoria. Sua(s)
                escolhas estão armazenadas no SGO de maneira segura e
                inviolável.
              </strong>
            </Warn>
          </Preview>
        </ImageGenerator>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EleicaoVote;
