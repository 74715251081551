import React, { useState, useEffect, useCallback, useRef } from 'react';

import api from 'services/api';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import RadioContainer from 'components/RadioContainer';

import { useAuth } from 'hooks/auth';
import Textarea from 'components/Textarea';
import Input from 'components/Input';
import { useSpring } from 'react-spring';
import { useCredentials } from 'hooks/credentials';
import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { FaDollarSign } from 'react-icons/fa';
import { useToast } from 'hooks/toast';
import { useHistory, useLocation } from 'react-router-dom';
import { CoordSpan, FormContent } from './styles';
import { monthList } from '../../monthList';

export interface RadioProps {
  id: string;
  value: string;
  label: string;
}

interface FormData {
  year: string;
  month: string;
  contrapart: string;
  coord: string;
  mov: string;
  document: string;
  description: string;
  value: string;
}

const CPRInsert: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const year = new Date().getUTCFullYear();
  const [years] = useState<ComboProps[]>([
    { value: `${year + 3}`, label: `${year + 3}` },
    { value: `${year + 2}`, label: `${year + 2}` },
    { value: `${year + 1}`, label: `${year + 1}` },
    { value: `${year}`, label: `${year}` },
    { value: `${year - 1}`, label: `${year - 1}` },
    { value: `${year - 2}`, label: `${year - 2}` },
    { value: `${year - 3}`, label: `${year - 3}` },
  ]);
  const [months] = useState([...monthList]);

  const [contrapart, setContrapart] = useState(() => {
    return user.perfil === 'NAC' ? 'L' : 'N';
  });

  const [contraparts] = useState<RadioProps[]>(() => {
    if (user.perfil === 'ZON') {
      return [
        { id: 'cZ', value: 'Z', label: 'Zonal' },
        { id: 'cA', value: 'N', label: 'Nacional' },
        { id: 'cF', value: 'F', label: 'Fornecedor' },
      ];
    }

    if (user.perfil === 'NAC') {
      return [
        { id: 'cZ', value: 'Z', label: 'Zonal' },
        { id: 'cA', value: 'N', label: 'Nacional' },
        { id: 'cL', value: 'L', label: 'Local' },
      ];
    }
    return [];
  });

  const [coords, setCoords] = useState<ComboProps[]>([]);

  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    const filtered = response.data.filter(
      (item: ComboLocalProps) => item.anb === user.anbc,
    );
    setComboLocs(filtered);

    user.perfil === 'NAC' && setCoords(filtered);
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    const filtered = response.data.filter((item: ComboProps) =>
      user.perfil === 'NAC' ? item.value !== user.anbc : item,
    );

    setComboNacs(filtered);

    user.perfil === 'ZON' && setCoords(filtered);
  }, [user.anbc, user.perfil]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    if (user.perfil === 'ZON') {
      const filtered = [
        {
          value: user.zoncod.replace('99', 'IN'),
          label: `Contab Internac Brasil ${user.zondesc.replace(
            'TOV ZONA BRASIL ',
            '',
          )}`,
        },
        ...response.data.filter((item: ComboProps) =>
          user.perfil === 'ZON' ? item.value !== user.zoncod : item,
        ),
      ];
      setComboZons(filtered);
    } else {
      const filtered = [
        ...response.data.filter(
          (item: ComboProps) => item.value === user.zoncod,
        ),
      ];
      setComboZons(filtered);
    }

    setLoading(false);
  }, [user.perfil, user.zoncod, user.zondesc]);

  const setup = useCallback(() => {
    try {
      handlePermission(['ZON', 'NAC'], true);
      formRef.current?.setFieldValue(
        'year',
        JSON.stringify(new Date().getUTCFullYear()),
      );
      // filterMonth();
      getComboLOC();
      getComboANB();
      getComboZON();
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, handlePermission, getComboANB, getComboLOC, getComboZON]);

  useEffect(() => {
    setup();
  }, [setup]);

  const handleRadioClick = useCallback(() => {
    formRef.current?.setFieldValue('coord', '');
    const radio = formRef.current?.getFieldValue('contrapart');
    setContrapart(radio);
    setCoords([]);
    if (radio === 'Z') {
      setCoords(comboZons);
      return;
    }
    if (radio === 'N') {
      setCoords(comboNacs);
      return;
    }
    if (radio === 'L') {
      setCoords(comboLocs);
      return;
    }

    setCoords([]);
  }, [comboLocs, comboNacs, comboZons]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          year: yup.string().required(),
          month: yup.string().notOneOf(['Selecione', '']),
          contrapart: yup.string().required(),
          // coord: yup.string().notOneOf(['Selecione', '']),
          coord: yup.string().when('contrapart', {
            is: (orig) => orig !== 'F',
            then: yup.string().notOneOf(['Selecione', '']).required(),
          }),
          document: yup
            .string()
            .trim()
            .required('Este campo é obrigatório')
            .min(5, 'Quantidade mínima de caracteres não atingida (5)')
            .max(20, 'Quantidade máxima de caracteres excedida (20)'),
          description: yup
            .string()
            .trim()
            .required('Este campo é obrigatório')
            .min(5, 'Quantidade mínima de caracteres não atingida (5)')
            .max(90, 'Quantidade máxima de caracteres excedida (90)'),
          value: yup
            .string()
            .notOneOf(['0.00', ''], 'Este campo é obrigatório.')
            .required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/cpr_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });
        setLoading(false);

        localStorage.removeItem(`${process.env.REACT_APP_CPR}`);

        history.replace(pathname.replace('/insert', ''));
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, pathname],
  );

  const shrinkCoord = useSpring({
    height: contrapart !== 'F' ? '75px' : '0px',
    overflow: contrapart !== 'F' ? 'visible' : 'hidden',
    opacity: contrapart !== 'F' ? 1 : 0,
    pointerEvents: contrapart !== 'F' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Inclusão de Registro" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContent>
            <span>
              <p>Ano Referência</p>
              <SelectV2
                name="year"
                content={years}
                initial={JSON.stringify(new Date().getUTCFullYear())}
                // onBlur={filterMonth}
              />
            </span>
            <span>
              <p>Mês Referência</p>
              <SelectV2 name="month" content={months} />
            </span>

            <span>
              <p>Conta a</p>
              <RadioContainer
                title=""
                onClick={handleRadioClick}
                name="mov"
                content={[
                  { id: 'mR', value: 'R', label: 'Receber' },
                  { id: 'mP', value: 'P', label: 'Pagar' },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected="R"
              />
            </span>
            <span>
              <p>Contrapartida</p>
              <RadioContainer
                title=""
                onClick={handleRadioClick}
                name="contrapart"
                content={contraparts}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={contrapart}
              />
            </span>
            <CoordSpan style={shrinkCoord}>
              <p>Coordenação</p>
              <SelectV2 name="coord" content={coords} />
            </CoordSpan>
            <span>
              <p>Documento</p>
              <Input name="document" placeholder="Documento" mask="alpha" />
            </span>
            <span>
              <p>Descrição</p>
              <Textarea
                name="description"
                placeholder="Descrição"
                mask="alpha"
              />
            </span>
            <span>
              <p>Valor</p>
              <Input
                icon={FaDollarSign}
                name="value"
                type="number"
                placeholder="Valor"
                step=".01"
                mask="currency"
              />
            </span>
          </FormContent>
          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default CPRInsert;
