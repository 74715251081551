import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { FaTimesCircle, FaPlus } from 'react-icons/fa';

import { useLocation, useHistory } from 'react-router-dom';
import { useSpring } from 'react-spring';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import { ComboProps, Container, Content } from 'styles/sgo_wrappers';
import { formatDate } from 'utils/formatDate';

import {
  Header,
  OtherPartsContainer,
  SectionContainer,
  AddSection,
  TableContentContainer,
  TotalSection,
  TotalContainer,
} from './styles';

import { ListProps } from '../main';
import { ItemProps, ComboRecDesp } from '../insert';

// type RecordProps  = ListProps;

const RelatFinEventosUpdate: React.FC = () => {
  const opForm = useRef<FormHandles>(null);
  const incForm = useRef<FormHandles>(null);
  const outForm = useRef<FormHandles>(null);
  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();

  const location = useLocation<ListProps>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [record] = useState<ListProps>(() => {
    return ({ ...location.state } || {}) as ListProps;
  });

  const [comboReceitas, setComboReceitas] = useState<ComboProps[]>([]);
  const [comboDespesas, setComboDespesas] = useState<ComboProps[]>([]);
  const [usedReceitas, setUsedReceitas] = useState<string[]>(() => {
    const { revenue } = location.state.content;
    return revenue.map((item) => item.cod);
  });
  const [usedDespesas, setUsedDespesas] = useState<string[]>(() => {
    const { expense } = location.state.content;
    return expense.map((item) => item.cod);
  });

  const [receitas, setReceitas] = useState<ItemProps[]>(() => {
    return [...location.state.content.revenue];
  });
  const [despesas, setDespesas] = useState<ItemProps[]>(() => {
    return [...location.state.content.expense];
  });

  const getCombo = useCallback(async () => {
    const response = await api.get('/combos/comboRecDespEventos.php');
    setComboReceitas(
      response.data.filter((item: ComboRecDesp) => item.type === 'R'),
    );
    setComboDespesas(
      response.data.filter((item: ComboRecDesp) => item.type === 'D'),
    );
  }, []);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    if (Object.keys(record).length === 0 || !location.state) {
      history.goBack();
    }

    getCombo();
  }, [getCombo, handlePermission, history, location.state, record]);

  const handlePartInput = useCallback(() => {
    const curr = document.querySelector<HTMLInputElement>(
      '[data-name="outros-participantes"]',
    );

    if (curr) {
      if (curr.value === '') {
        curr.value = '0';
      }
    }
  }, []);

  function querySelectorInputHandler(param: string): string {
    const queried = document.querySelector<HTMLInputElement>(`${param}`);
    if (queried) {
      return queried.value;
    }
    return '';
  }

  const handleAdd = useCallback(
    (ev) => {
      const { type } = ev.currentTarget.dataset;

      const selectQuery = document.querySelector<HTMLSelectElement>(
        `[data-name="${type}combo"]`,
      );

      const part = querySelectorInputHandler(
        `[data-name="${type}participantes"]`,
      );
      const otherParts = querySelectorInputHandler(
        `[data-name="outros-participantes"]`,
      );
      const vlrunit = querySelectorInputHandler(`[data-name="${type}vlrunit"]`);
      const vlrtot = querySelectorInputHandler(`[data-name="${type}vlrtot"]`);

      let selectVal = '';
      let selectText = '';
      if (selectQuery) {
        selectVal = selectQuery.options[selectQuery.selectedIndex].value;
        selectText = selectQuery.options[selectQuery.selectedIndex].text;
      }

      const resetObj = {
        participantes: '0',
        vlrunit: '0.00',
        vlrtot: '0.00',
      };

      const addObject = {
        cod: selectVal,
        text: selectText,
        part,
        vlr: vlrunit,
        vlrtotal: vlrtot,
        dataset: `__${selectVal}`,
      };

      const allowed =
        parseInt(otherParts, 10) + parseInt(record.attendants, 10);
      if (type === 'income') {
        incForm.current?.setErrors({});
        if (selectVal === '') {
          incForm.current?.setFieldError(`${type}combo`, ' ');
          return;
        }
        if (part === '0' || parseInt(part, 10) > allowed) {
          incForm.current?.setFieldError('participantes', ' ');
          return;
        }
        if (parseFloat(vlrtot) === 0) {
          incForm.current?.setFieldError(`vlrtot`, ' ');
          return;
        }
        setReceitas((state) => [...state, addObject]);

        setUsedReceitas((state) => [...state, selectVal]);
        incForm.current?.reset();
        incForm.current?.setData(resetObj);
      } else {
        outForm.current?.setErrors({});
        if (selectVal === '') {
          outForm.current?.setFieldError(`${type}combo`, ' ');
          return;
        }
        if (part === '0' || parseInt(part, 10) > allowed) {
          outForm.current?.setFieldError('participantes', ' ');
          return;
        }
        if (parseFloat(vlrtot) === 0) {
          outForm.current?.setFieldError(`vlrtot`, ' ');
          return;
        }
        setDespesas((state) => [...state, addObject]);

        setUsedDespesas((state) => [...state, selectVal]);
        outForm.current?.reset();
        outForm.current?.setData(resetObj);
      }
    },
    [record.attendants],
  );

  const handleRemove = useCallback((ev) => {
    const { id, type } = ev.currentTarget.dataset;

    if (type === 'income') {
      setReceitas((state) => [...state.filter((item) => item.dataset !== id)]);
      setUsedReceitas((state) => [
        ...state.filter((item) => item !== id.replace('__', '')),
      ]);
    } else {
      setDespesas((state) => [...state.filter((item) => item.dataset !== id)]);
      setUsedDespesas((state) => [
        ...state.filter((item) => item !== id.replace('__', '')),
      ]);
    }
  }, []);

  const handleChangeInput = useCallback((ev) => {
    const curr: any = ev.currentTarget;
    const { type, name } = curr.dataset;

    if (name === `${type}participantes`) {
      if (curr.value === '') {
        curr.value = '0';
      }
    }

    setTimeout(() => {
      const part = document.querySelector<HTMLSelectElement>(
        `[data-name="${type}participantes"]`,
      );
      const vlrunit = document.querySelector<HTMLSelectElement>(
        `[data-name="${type}vlrunit"]`,
      );
      const vlrtot = document.querySelector<HTMLSelectElement>(
        `[data-name="${type}vlrtot"]`,
      );

      if (part && vlrunit && vlrtot) {
        if (curr.value === '0') {
          return;
        }
        vlrunit.value = (parseFloat(vlrtot.value) / parseInt(part?.value, 10))
          .toFixed(2)
          .toString();
        // vlrtot.value = (parseInt(part?.value, 10) * parseFloat(vlrunit?.value))
        //   .toFixed(2)
        //   .toString();
      }
    }, 200);
  }, []);

  const handleRegister = useCallback(async () => {
    try {
      setLoading(true);

      const partInput = document.querySelector<HTMLInputElement>(
        '[data-name="outros-participantes"]',
      );

      const sendContent = {
        otherParts: partInput?.value,
        records: [...receitas, ...despesas],
      };

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          ...record,
          ...sendContent,
        }),
      );

      await api.post('/sgo/relatorio_eventos_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Relatório financeiro de ${formatDate(record.ini)} - ${
          record.tsdesc
        }, alterado com sucesso!`,
        seconds: 15,
      });

      history.goBack();
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [addToast, despesas, errorHandling, history, receitas, record]);

  const totalIncome = useMemo(() => {
    return receitas.reduce((accum, { vlrtotal }) => {
      return accum + parseFloat(vlrtotal);
    }, 0);
  }, [receitas]);

  const totalOutcome = useMemo(() => {
    return despesas.reduce((accum, { vlrtotal }) => {
      return accum + parseFloat(vlrtotal);
    }, 0);
  }, [despesas]);

  const totalEvent = useMemo(() => {
    return totalIncome - totalOutcome;
  }, [totalIncome, totalOutcome]);

  const comboStyle = { height: '35px', width: '280px' };
  const inputContainerStyle = { width: '100px', padding: '5px 3px' };
  const inputStyle = { width: '100px' };

  const appearFinishButton = useSpring({
    margin: '15px auto 25px',
    opacity: receitas.length > 0 || despesas.length > 0 ? 1 : 0,
    pointerEvents: receitas.length > 0 || despesas.length > 0 ? 'all' : 'none',
    transform:
      receitas.length > 0 || despesas.length > 0
        ? 'translateX(0%)'
        : 'translateX(50%)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração de relatório" />
      <Content>
        <Header>
          <span>
            <p>
              Evento:&nbsp;
              <strong style={{ color: '#c53030' }}>
                {record.tsdesc} ({record.svcseq})
              </strong>
            </p>
          </span>
          <span>
            <p>
              Aplicador(es):&nbsp;<strong>{record.applicator}</strong>
            </p>
          </span>
          {['ED', 'RE'].includes(record.tscod) ? (
            <span>
              <p>
                Coordenador:&nbsp;<strong>{record.svccoor.name}</strong>
              </p>
            </span>
          ) : null}
          <span>
            <p>
              Local:&nbsp;<strong>{record.place || '-'}</strong>
            </p>
          </span>

          <span>
            <p>
              Coord.Nacional:&nbsp;<strong>{record.anbd}</strong>
            </p>
          </span>
          {record.anb !== record.loc && (
            <span>
              <p>
                Coord.Local:&nbsp;<strong>{record.locd}</strong>
              </p>
            </span>
          )}
          <span>
            <p>
              Data{record.ini === record.end ? '' : 's'}:&nbsp;
              <strong>{formatDate(record.ini)}</strong>
              {record.ini === record.end ? '' : ` - ${formatDate(record.end)}`}
            </p>
          </span>
          <span>
            <p>
              Tema:&nbsp;<strong>{record.theme || '-'}</strong>
            </p>
          </span>
          <span>
            <p>
              Participantes:&nbsp;
              <strong style={{ color: '#c53030' }}>{record.attendants}</strong>
            </p>
          </span>
        </Header>
        <OtherPartsContainer
          style={
            ['RE', 'JA'].indexOf(record.tscod) < 0
              ? { opacity: 0, pointerEvents: 'none' }
              : {}
          }
        >
          <Form ref={opForm} onSubmit={() => null}>
            <div>
              <span>
                <p>Outros participantes:</p>
                <abbr>Válido para Eméritos e Futuros Guias</abbr>
              </span>
              <Input
                name="outros-participantes"
                containerStyle={inputContainerStyle}
                style={inputStyle}
                type="number"
                mask="allowZero"
                defaultValue={record.otherparts}
                data-name="outros-participantes"
                onChange={handlePartInput}
                // disabled={receitas.length > 0 || despesas.length > 0}
              />
            </div>
          </Form>
        </OtherPartsContainer>
        <SectionContainer>
          <Form ref={incForm} onSubmit={handleAdd}>
            <div>
              <h3>Receitas</h3>
              <AddSection>
                <div>
                  <span>
                    <p>Especificação</p>
                    <SelectV2
                      name="incomecombo"
                      data-type="income"
                      data-name="incomecombo"
                      content={comboReceitas.filter(
                        (item) => usedReceitas.indexOf(item.value) === -1,
                      )}
                      initial="Selecione"
                      containerStyle={comboStyle}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <p>Partic.</p>
                    <Input
                      name="participantes"
                      data-type="income"
                      data-name="incomeparticipantes"
                      type="number"
                      min="0"
                      step={1}
                      mask="allowZero"
                      defaultValue={0}
                      containerStyle={{
                        ...inputContainerStyle,
                        width: '60px',
                      }}
                      inputStyle={{ width: '80px' }}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <p>Vlr. Unit</p>
                    <Input
                      disabled
                      name="vlrunit"
                      data-type="income"
                      data-name="incomevlrunit"
                      type="number"
                      min="0"
                      mask="currency"
                      step={0.1}
                      defaultValue="0.00"
                      containerStyle={inputContainerStyle}
                      inputStyle={inputStyle}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <p>Vlr. Tot</p>
                    <Input
                      name="vlrtot"
                      data-type="income"
                      data-name="incomevlrtot"
                      type="number"
                      min="0"
                      mask="currency"
                      step={0.1}
                      defaultValue="0.00"
                      containerStyle={inputContainerStyle}
                      inputStyle={inputStyle}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <button
                      type="button"
                      data-type="income"
                      onClick={handleAdd}
                    >
                      <FaPlus style={{ height: '18px', width: '18px' }} />
                    </button>
                  </span>
                </div>
              </AddSection>
              <TableContentContainer>
                {receitas.length > 0 ? (
                  <table>
                    <tr>
                      <td>Descrição</td>
                      <td>Part.</td>
                      <td>Valor</td>
                      <td>Total</td>
                      <td>&nbsp;</td>
                    </tr>
                    {receitas.map((item: ItemProps) => (
                      <tr key={item.dataset}>
                        <td>{item.text}</td>
                        <td>{item.part}</td>
                        <td>
                          R$&nbsp;
                          {parseFloat(item.vlr).toFixed(2).replace('.', ',')}
                        </td>
                        <td>
                          R$&nbsp;
                          {parseFloat(item.vlrtotal)
                            .toFixed(2)
                            .replace('.', ',')}
                        </td>
                        <td>
                          <button
                            type="button"
                            data-id={item.dataset}
                            data-type="income"
                            onClick={handleRemove}
                          >
                            <FaTimesCircle />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : null}
              </TableContentContainer>
              <TotalSection>
                <p>
                  Total Receitas:&nbsp;
                  <strong
                    style={{
                      color: totalIncome > 0 ? '#4caf50' : '#332e2e',
                    }}
                  >
                    R$&nbsp;{totalIncome.toFixed(2).replace('.', ',')}
                  </strong>
                </p>
              </TotalSection>
            </div>
          </Form>
        </SectionContainer>
        <SectionContainer>
          <Form ref={outForm} onSubmit={handleAdd}>
            <div>
              <h3>Despesas</h3>
              <AddSection>
                <div>
                  <span>
                    <p>Especificação</p>
                    <SelectV2
                      name="outcomecombo"
                      data-type="outcome"
                      data-name="outcomecombo"
                      content={comboDespesas.filter(
                        (item) => usedDespesas.indexOf(item.value) === -1,
                      )}
                      initial="Selecione"
                      containerStyle={comboStyle}
                    />
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span>
                    <p>Partic.</p>
                    <Input
                      name="participantes"
                      data-type="outcome"
                      data-name="outcomeparticipantes"
                      type="number"
                      min="0"
                      step={1}
                      mask="allowZero"
                      defaultValue={0}
                      containerStyle={{
                        ...inputContainerStyle,
                        width: '60px',
                      }}
                      inputStyle={{ width: '80px' }}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <p>Vlr. Unit</p>
                    <Input
                      disabled
                      name="vlrunit"
                      data-type="outcome"
                      data-name="outcomevlrunit"
                      type="number"
                      min="0"
                      mask="currency"
                      step={0.1}
                      defaultValue="0.00"
                      containerStyle={inputContainerStyle}
                      inputStyle={inputStyle}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <p>Vlr. Tot</p>
                    <Input
                      name="vlrtot"
                      data-type="outcome"
                      data-name="outcomevlrtot"
                      type="number"
                      min="0"
                      mask="currency"
                      step={0.1}
                      defaultValue="0.00"
                      containerStyle={inputContainerStyle}
                      inputStyle={inputStyle}
                      onChange={handleChangeInput}
                      disableErrorIcon
                    />
                  </span>
                  <span>
                    <button
                      type="button"
                      data-type="outcome"
                      onClick={handleAdd}
                    >
                      <FaPlus style={{ height: '18px', width: '18px' }} />
                    </button>
                  </span>
                </div>
                {/* <div>
                      <span>
                        <button
                          type="button"
                          data-type="outcome"
                          onClick={handleAdd}
                        >
                          <FaPlus style={{ height: '18px', width: '18px' }} />
                        </button>
                      </span>
                    </div> */}
              </AddSection>
              <TableContentContainer>
                {despesas.length > 0 ? (
                  <table>
                    <tr>
                      <td>Descrição</td>
                      <td>Part.</td>
                      <td>Valor</td>
                      <td>Total</td>
                      <td>&nbsp;</td>
                    </tr>
                    {despesas.map((item: ItemProps) => (
                      <tr key={item.dataset}>
                        <td>{item.text}</td>
                        <td>{item.part}</td>
                        <td>
                          R$&nbsp;
                          {parseFloat(item.vlr).toFixed(2).replace('.', ',')}
                        </td>
                        <td>
                          R$&nbsp;
                          {parseFloat(item.vlrtotal)
                            .toFixed(2)
                            .replace('.', ',')}
                        </td>
                        <td>
                          <button
                            type="button"
                            data-id={item.dataset}
                            data-type="outcome"
                            onClick={handleRemove}
                          >
                            <FaTimesCircle />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : null}
              </TableContentContainer>
              <TotalSection>
                <p>
                  Total Despesas:&nbsp;
                  <strong
                    style={{
                      color: totalOutcome > 0 ? '#c53030' : '#332e2e',
                    }}
                  >
                    R$&nbsp;{totalOutcome.toFixed(2).replace('.', ',')}
                  </strong>
                </p>
              </TotalSection>
            </div>
          </Form>
        </SectionContainer>
        <TotalContainer>
          <p>
            Total Evento:&nbsp;
            <strong
              style={{
                color:
                  totalEvent === 0
                    ? '#332e2e'
                    : totalEvent > 0
                    ? '#4caf50'
                    : '#c53030',
              }}
            >
              R$ {totalEvent.toFixed(2).replace('.', ',')}
            </strong>
          </p>
        </TotalContainer>
        <Button
          bgcolor="#00802b"
          style={appearFinishButton}
          type="button"
          onClick={handleRegister}
        >
          Finalizar
        </Button>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default RelatFinEventosUpdate;
