import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  useMemo,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useChanges } from 'hooks/changes';

import { useToast } from 'hooks/toast';
import { useHistory, useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorageItemKey,
} from 'utils/handleLocalStorage';

import {
  Container,
  FloatInput,
  AlteredContent,
  CoordProps,
  Header,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import api from 'services/api';

import {
  FaFlagCheckered,
  FaDollarSign,
  FaPencilAlt,
  FaTimes,
  FaUsers,
} from 'react-icons/fa';

import { parseISO } from 'date-fns';

import { formatDate } from 'utils/formatDate';
import { deconvertSpecialChars, removeAcento } from 'utils/specialChars';

import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import {
  CodContainer,
  Grid,
  GridItem,
  InfoContainer,
  StyledUpdate,
  FinancButton,
  TotaisContainer,
  TSDesc,
  StageBlock,
} from './styles';

import { ListProps, LocalStorageProps, ServiceProps } from '../interfaces';

const Atividades: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { currDate } = useChanges();

  const { addToast } = useToast();
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);
  const relatFinServices = [
    'AN',
    'CB',
    'ED',
    'JA',
    'JE',
    'JF',
    'PE',
    'RC',
    'RD',
    'RE',
  ];

  const path = pathname.includes('atividades')
    ? 'S'
    : pathname.includes('servicos')
    ? 'O'
    : '';

  const lsConst = pathname.includes('atividades')
    ? process.env.REACT_APP_ATIVIDADE
    : pathname.includes('servicos')
    ? process.env.REACT_APP_SERVICO
    : undefined;

  const [searchValue, setSearchValue] = useState('');
  const [initialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(`${lsConst}`);
    return anbcod || user.anbc;
  });
  const [initialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(`${lsConst}`);
    return loccod || '';
  });
  const [initialService] = useState(() => {
    const { tscod }: LocalStorageProps = getLocalStorage(`${lsConst}`);

    return tscod || '';
  });
  const [initialYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(`${lsConst}`);

    return year || JSON.stringify(new Date().getFullYear());
  });
  const [initialSemester] = useState(() => {
    const { semester }: LocalStorageProps = getLocalStorage(`${lsConst}`);
    return semester || (new Date().getMonth() < 6 ? '1' : '2');
  });

  const [launchBlockCheck, setLaunchBlockCheck] = useState(0);

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [comboService, setComboService] = useState<ComboProps[]>([]);
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);
  const comboSemester: ComboProps[] = [
    { value: '1', label: '1° Semestre' },
    { value: '2', label: '2° Semestre' },
  ];

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(`${lsConst}`);

    return {
      cod:
        cod ||
        (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1
          ? user.anbc
          : user.perfil === 'LOC'
          ? user.loccod
          : ''),
      desc:
        desc ||
        (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1
          ? user.anbdesc
          : user.perfil === 'LOC'
          ? user.locdesc
          : ''),
    } as CoordProps;
  });

  const filter4Me = useCallback(
    (array: ListProps[]): ListProps[] => {
      const {
        loccod,
        tscod,
        year,
        semester,
      }: LocalStorageProps = getLocalStorage(`${lsConst}`);

      let init = array;

      init = init.filter(
        (item: ListProps) =>
          item.ano === (year || JSON.stringify(new Date().getFullYear())),
      );
      init = init.filter(
        (item: ListProps) =>
          item.sem === (semester || (new Date().getMonth() < 6 ? '1' : '2')),
      );

      if (loccod) {
        init = init.filter((item: ListProps) => item.loccod === loccod);
      }
      if (tscod) {
        init = init.filter((item: ListProps) => item.tscod === tscod);
      }

      return [...init];
    },
    [lsConst],
  );

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      const { anbcod }: LocalStorageProps = getLocalStorage(`${lsConst}`);
      send.append(
        'data',
        JSON.stringify({
          path,
          cod:
            ['INT', 'ZON'].indexOf(user.perfil) > -1
              ? anbcod ||
                formRef.current?.getFieldValue('comboANB') ||
                user.anbc
              : user.anbc,
        }),
      );
      const response = await api.post('/sgo/atividades_list.php', send, {
        headers: { 'Content-type': 'multipart/form-data' },
      });

      setRawList(response.data);
      setList(filter4Me(response.data));
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, filter4Me, lsConst, path, user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      user.perfil === 'ZON'
        ? response.data.filter(
            (item: ComboProps) =>
              item.value.substring(0, 2) === user.zoncod.substring(0, 2),
          )
        : response.data,
    );
  }, [user.perfil, user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anbcod }: LocalStorageProps = getLocalStorage(`${lsConst}`);

    setComboLocs([
      { label: 'Todos da Nacional', value: '' },
      ...response.data.filter(
        (item: ComboLocalProps) => item.anb === (anbcod || user.anbc),
      ),
    ]);
  }, [lsConst, user.anbc]);

  const getServices = useCallback(async () => {
    const response = await api.get<ServiceProps[]>('/combos/comboServices.php');

    const filtered = response.data.filter(
      (item: ServiceProps) => item.type === path,
    );

    setComboService([
      {
        value: '',
        label: `Tod${path === 'S' ? 'as as Atividades' : 'os os Serviços'}`,
      },
      ...filtered
        .filter((item: ServiceProps) => {
          if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
            return ['EP', 'DU', 'DM'].indexOf(item.value) < 0;
          }
          if (user.perfil === 'LOC') {
            return ['AI', 'EP', 'DU', 'DM'].indexOf(item.value) < 0;
          }
          if (user.perfil === 'GUI') {
            return ['AI', 'AN', 'EP', 'DU', 'DM'].indexOf(item.value) < 0;
          }
          return item;
        })
        .map((item: ServiceProps) => {
          return { value: item.value, label: item.label };
        }),
    ]);
  }, [path, user.perfil]);

  const getYears = useCallback(async () => {
    const response = await api.get(
      `/combos/comboAnosAtividades.php?data=${JSON.stringify({
        atvcode: initialService,
        path,
      })}`,
    );
    setComboYears(response.data);
  }, [initialService, path]);

  const handleANBselect = useCallback(() => {
    const anb = formRef.current?.getFieldValue('comboANB');
    const selectRef: HTMLSelectElement = formRef.current?.getFieldRef(
      'comboANB',
    );

    const selectedANB = {
      anbcod: anb,
      anbdesc: selectRef.options[selectRef.selectedIndex].text,
      cod: anb,
      desc: selectRef.options[selectRef.selectedIndex].text,
    };

    setLocalStorage(`${lsConst}`, selectedANB);

    formRef.current?.setFieldValue('comboLOC', '');

    setCoord({
      cod: anb,
      desc: selectRef.options[selectRef.selectedIndex].text,
    });

    deleteLocalStorageItemKey(`${lsConst}`, ['loccod', 'locdesc']);
    setComboLocs([
      { value: '', label: 'Todos da Nacional' },
      ...locs.filter((item) => item.anb === anb),
    ]);
    getList();
  }, [lsConst, locs, getList]);

  const handleLOCselect = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLOC');
    const selectRef: HTMLSelectElement = formRef.current?.getFieldRef(
      'comboLOC',
    );

    if (loc.length > 0) {
      setLocalStorage(`${lsConst}`, {
        loccod: loc,
        locdesc: selectRef.options[selectRef.selectedIndex].text,
      });
    }

    if (loc.length === 0) {
      deleteLocalStorageItemKey(`${lsConst}`, ['loccod', 'locdesc']);
    }
    setList(filter4Me(rawList));
  }, [filter4Me, lsConst, rawList]);

  const handleServiceSelect = useCallback(() => {
    const serv = formRef.current?.getFieldValue('comboService');
    const selectRef: HTMLSelectElement = formRef.current?.getFieldRef(
      'comboService',
    );

    if (serv.length > 0) {
      setLocalStorage(`${lsConst}`, {
        tscod: serv,
        tsdesc: selectRef.options[selectRef.selectedIndex].text,
      });
    }

    if (serv.length === 0) {
      deleteLocalStorageItemKey(`${lsConst}`, ['tscod', 'tscod']);
    }
    setList(filter4Me(rawList));
  }, [filter4Me, lsConst, rawList]);

  const handleYearSelect = useCallback(() => {
    const ye = formRef.current?.getFieldValue('comboYear');
    setLaunchBlockCheck(new Date().getTime());

    if (ye.length > 0) {
      setLocalStorage(`${lsConst}`, {
        year: ye,
      });
    }
    setList(filter4Me(rawList));
  }, [filter4Me, lsConst, rawList]);

  const handleSemesterSelect = useCallback(() => {
    const sem = formRef.current?.getFieldValue('comboSemester');
    setLaunchBlockCheck(new Date().getTime());

    if (sem.length > 0) {
      setLocalStorage(`${lsConst}`, {
        semester: sem,
      });
    }

    setList(filter4Me(rawList));
  }, [filter4Me, lsConst, rawList]);

  useEffect(() => {
    if (!(pathname.includes('atividades') || pathname.includes('servicos'))) {
      history.replace(
        `${pathname.substring(
          0,
          pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }
    setRawList([]);
    setList([]);

    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboANB();
    }
    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
      getComboLOC();
    }
    getServices();
    getYears();

    getList();
  }, [
    history,
    pathname,
    getList,
    user.perfil,
    getComboANB,
    getComboLOC,
    getServices,
    getYears,
  ]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      const toBF = rawList.filter(
        (item: ListProps) =>
          (!!item.gnome1 &&
            removeAcento(item.gnome1)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase())) ||
          (!!item.gnome2 &&
            removeAcento(item.gnome2)
              .toLowerCase()
              .includes(removeAcento(value).toLowerCase())) ||
          removeAcento(item.locdesc)
            .toLowerCase()
            .includes(removeAcento(value).toLowerCase()),
      );
      setList(filter4Me(toBF));
    },
    [filter4Me, rawList],
  );

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);
          setDeleteDiag({ open: false });
          await api.get(
            `/sgo/atividades_delete.php?data=${JSON.stringify(values)}`,
          );

          setList(
            list.filter(
              (item: ListProps) =>
                `${item.type}-${item.seq}` !== `${values.type}-${values.seq}`,
            ),
          );

          setLoading(false);
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: `Atividade ${values.type}-${values.seq} removida.`,
          });
        } catch (err) {
          if (err.response.data.message) {
            errorHandling(err, err.response.data.message);
            return;
          }
          errorHandling(err);
        }
      }
    },
    [addToast, errorHandling, list],
  );

  const totAt = useMemo(() => {
    let total = 0;

    list.forEach(() => {
      total += 1;
    });

    return total;
  }, [list]);

  const totPart = useMemo(() => {
    let total = 0;

    list.forEach((item: ListProps) => {
      total += parseInt(item.qtpart, 10);
    });

    return total;
  }, [list]);

  const totSessMed = useMemo(() => {
    let total = 0;

    list.forEach((item: ListProps) => {
      let value = 0;

      if (item.qt2sess) {
        value = parseInt(item.qt2sess, 10);
      }

      total += value;
    });

    return total;
  }, [list]);

  const totPers = useMemo(() => {
    let total = 0;

    list.forEach((item: ListProps) => {
      let value = 0;

      if (item.qtpers) {
        value = parseInt(item.qtpers, 10);
      }

      total += value;
    });

    return total;
  }, [list]);

  const blockFinancAndEnding = useMemo(() => {
    if (typeof currDate != 'undefined' && path === 'O') {
      const currYear = currDate.year;
      const currSemester = currDate.month < 7 ? 1 : 2;

      const { year, semester }: LocalStorageProps = getLocalStorage(
        `${lsConst}`,
      );
      const existentYear = year || JSON.stringify(new Date().getFullYear());
      const existentSemester =
        semester || (new Date().getMonth() < 6 ? '1' : '2');

      if (parseInt(existentYear, 10) > currYear) {
        return false;
      }

      if (
        parseInt(existentYear, 10) === currYear &&
        parseInt(existentSemester, 10) < currSemester
      ) {
        return true;
      }

      if (parseInt(existentYear, 10) < currYear) {
        return true;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currDate, lsConst, launchBlockCheck]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      {/* {['GA', 'GP', 'FG'].indexOf(user.tgcod) > -1 || user.adm ? (
        <IncludeButton />
      ) : null} // MUDANÇA EM 14/03/2022, conforme solicitado; */}
      {['GA', 'FG'].indexOf(user.tgcod) > -1 || user.adm ? (
        <IncludeButton />
      ) : null}
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {['ZON', 'INT'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                </span>
              )}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLOC"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                </span>
              )}
              <span>
                <p>
                  Filtre por Tipo de {path === 'S' ? 'Atividade' : 'Serviço'}:
                </p>
                <SelectV2
                  name="comboService"
                  content={comboService}
                  onChange={handleServiceSelect}
                  initial={initialService}
                />
              </span>
              <span>
                <p>Filtre por Ano:</p>
                <SelectV2
                  name="comboYear"
                  content={comboYears}
                  onChange={handleYearSelect}
                  initial={initialYear}
                />
              </span>
              <span>
                <p>Filtre por Semestre:</p>
                <SelectV2
                  name="comboSemester"
                  content={comboSemester}
                  onChange={handleSemesterSelect}
                  initial={initialSemester}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>{path === 'S' ? 'Atividades' : 'Serviços'}</p>
        </div>
        <div>{coord.desc}</div>
      </Header>
      <FloatInput amount="117px">
        <input
          placeholder="Filtrar por guia aplicador, ou coordenação"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels={user.perfil !== 'GUI' ? '302px' : '281px'}>
        <TotaisContainer>
          <span>
            <p>
              Total de Atividades: <strong>{totAt}</strong>
            </p>
            <p>
              {path === 'O' ? 'Oficinistas' : 'Participantes'}
              &nbsp;totais:&nbsp;
              <strong>{totPart}</strong>
            </p>
          </span>
          {path === 'O' && (
            <span>
              <p>
                Sessão de Medição: <strong>{totSessMed}</strong>
              </p>
              <p>
                Perseverantes totais: <strong>{totPers}</strong>
              </p>
            </span>
          )}
        </TotaisContainer>
        <Grid>
          {list.map((item: ListProps) => (
            <GridItem key={`${item.type}-${item.seq}`}>
              <InfoContainer>
                <TSDesc>
                  <strong>{item.tsdesc}</strong>
                </TSDesc>
                {item.tscod !== 'AI' && (
                  <span>
                    <p>
                      Coord: <strong>{item.locdesc}</strong>
                    </p>
                  </span>
                )}
                {['CO', 'CG', 'GC'].indexOf(item.tscod) < 0 && (
                  <span>
                    <p>
                      Local: <strong>{item.prqname}</strong>
                    </p>
                  </span>
                )}

                <span>
                  <p>
                    Abertura:&nbsp;
                    <strong>{formatDate(item.dtini)}</strong>
                  </p>
                  <p>
                    Fim:&nbsp;
                    <strong>{formatDate(item.dtfim)}</strong>
                  </p>
                </span>
                {path === 'O' && (
                  <span>
                    <p>
                      Dia da Semana: <strong>{item.diasem}</strong>
                    </p>

                    <p>
                      Horário: <strong>{item.hora}</strong>
                    </p>
                  </span>
                )}

                {['CG', 'GC', 'JA', 'JF', 'AN', 'AI'].indexOf(item.tscod) <
                  0 && (
                  <span>
                    <p>
                      Guia(s):&nbsp;
                      <strong>{item.gnome1}</strong>
                      {item.gnome2 && <strong> / {item.gnome2}</strong>}
                    </p>
                  </span>
                )}

                <span>
                  {path === 'O' ? (
                    <>
                      <p>
                        Cadastrados:&nbsp;
                        <strong>{item.qtpart}</strong>
                      </p>
                      <p>
                        Medição:&nbsp;
                        <strong>{item?.qt2sess || '-'}</strong>
                      </p>
                      <p>
                        Perseverantes:&nbsp;
                        <strong>{item.qtpers}</strong>
                      </p>
                    </>
                  ) : (
                    <>
                      {['ED', 'JE', 'PE', 'JA', 'RE'].indexOf(item.tscod) >=
                        0 && (
                        <p>
                          Total: <strong>{item.qtpart}</strong>
                        </p>
                      )}

                      {[
                        'AI',
                        'AN',
                        'CG',
                        'GC',
                        'JA',
                        'JF',
                        'RE',
                        'ED',
                        'JE',
                        'PE',
                      ].indexOf(item.tscod) >= 0 && (
                        <p>
                          Guias: <strong>{item.qteGuia}</strong>
                        </p>
                      )}

                      {[
                        'CB',
                        'CO',
                        'RC',
                        'RD',
                        'ED',
                        'JE',
                        'PE',
                        // 'JA',
                        // 'RE',
                      ].indexOf(item.tscod) >= 0 && (
                        <p>
                          Partic: <strong>{item.qteServ}</strong>
                        </p>
                      )}
                      {['ED', 'RE'].includes(item.tscod) && (
                        <p>
                          Coord: <strong>{item.svccoor.name}</strong>
                        </p>
                      )}
                    </>
                  )}
                </span>

                {item?.tema && item?.tema !== '' && (
                  <span>
                    <p>
                      Tema:&nbsp;
                      <strong>{deconvertSpecialChars(item.tema)}</strong>
                    </p>
                  </span>
                )}
              </InfoContainer>
              <CodContainer>
                <p>
                  Cód:&nbsp;
                  <strong>
                    {item.type}-{item.seq}
                  </strong>
                </p>
              </CodContainer>
              {path === 'O' && item.currStat !== null ? (
                <StageBlock
                  bgcolor={item.currStat === 'N' ? '#87ceeb' : '#fdd835'}
                >
                  Encerrada&nbsp;
                  {item.currStat === 'N' ? 'normal' : 'prematura'}mente
                </StageBlock>
              ) : null}
              <O.GridOptions>
                {['CB', 'CO', 'RC', 'RD'].indexOf(item.tscod) < 0 && (
                  <StyledUpdate
                    to={{
                      pathname: `${pathname}/participantes`,
                      state: {
                        type: path,
                        seq: item.seq,
                        year: item.ano,
                        tscod: item.tscod,
                        loccod: item.loccod,
                        guia1: item.guia1,
                        guia2: item.guia2,
                        dtini: item.dtini,
                        dtfim: item.dtfim,

                        svcaplic: item.svcaplic,
                        anbcod: item.anbcod,
                      },
                    }}
                  >
                    <FaUsers />
                  </StyledUpdate>
                )}
                {path === 'O'
                  ? parseInt(item.ano, 10) >=
                      new Date(
                        parseISO(new Date().toISOString()),
                      ).getFullYear() &&
                    ((user.perfil === 'LOC' && item.loccod === user.loccod) ||
                      user.gseq === item.guia1 ||
                      user.gseq === item.guia2 ||
                      (['SA', 'SJ'].indexOf(item.tscod) > -1 &&
                        item.loccod === user.loccod)) && (
                      // eslint-disable-next-line react/jsx-indent
                      <>
                        <O.Update
                          to={{
                            pathname: `${pathname}/update`,
                            state: { activity: item },
                          }}
                        >
                          <FaPencilAlt />
                        </O.Update>
                        {item.qtpart === '0' &&
                          item?.qtpers === '0' &&
                          item.vlrofr === '0.00' && (
                            <O.Delete
                              onClick={() =>
                                setDeleteDiag({
                                  open: true,
                                  content: (
                                    <D.ModalDeleteContent>
                                      <p>
                                        Você está prestes a excluir{' '}
                                        <strong>permanentemente</strong> o
                                        registro da atividade{' '}
                                        <strong>
                                          {item.type}-{item.seq}.
                                        </strong>
                                      </p>
                                      <p>
                                        Se estiver seguro de sua decisão, clique
                                        em confirmar.
                                      </p>
                                    </D.ModalDeleteContent>
                                  ),
                                  values: {
                                    type: item.type,
                                    seq: item.seq,
                                  },
                                })
                              }
                            >
                              <FaTimes />
                            </O.Delete>
                          )}
                      </>
                    )
                  : parseInt(item.ano, 10) >=
                      new Date(
                        parseISO(new Date().toISOString()),
                      ).getFullYear() &&
                    ((['ZON', 'INT'].indexOf(user.perfil) > -1 &&
                      item.tscod === 'AI') ||
                      (user.perfil === 'NAC' &&
                        ['AN', 'JF'].indexOf(item.tscod) > -1 &&
                        item.anbcod === user.anbc) ||
                      (user.perfil === 'LOC' &&
                        [
                          'CG',
                          'CO',
                          'ED',
                          'GC',
                          'JA',
                          'RC',
                          'RE',
                          'CC',
                          'CB',
                          'JE',
                          'PE',
                          'RD',
                          'SA',
                          'SJ',
                          'TA',
                          'TC',
                          'TD',
                          'TJ',
                        ].indexOf(item.tscod) > -1 &&
                        item.loccod === user.loccod) ||
                      // eslint-disable-next-line react/jsx-indent
                      (['CB', 'JE', 'PE', 'RD'].indexOf(item.tscod) > -1 &&
                        user.gseq === item?.svcaplic &&
                        user.loccod === item.loccod)) && (
                      // eslint-disable-next-line react/jsx-indent
                      <>
                        <O.Update
                          to={{
                            pathname: `${pathname}/update`,
                            state: { activity: item },
                          }}
                        >
                          <FaPencilAlt />
                        </O.Update>
                        {item.qtpart === '0' && (
                          <O.Delete
                            onClick={() =>
                              setDeleteDiag({
                                open: true,
                                content: (
                                  <D.ModalDeleteContent>
                                    <p>
                                      Você está prestes a excluir{' '}
                                      <strong>permanentemente</strong> o
                                      registro da atividade{' '}
                                      <strong>
                                        {item.type}-{item.seq}.
                                      </strong>
                                    </p>
                                    <p>
                                      Se estiver seguro de sua decisão, clique
                                      em confirmar.
                                    </p>
                                  </D.ModalDeleteContent>
                                ),
                                values: {
                                  type: item.type,
                                  seq: item.seq,
                                },
                              })
                            }
                          >
                            <FaTimes />
                          </O.Delete>
                        )}
                      </>
                    )}
                {path === 'O' ? (
                  <>
                    {['ZON', 'NAC', 'LOC'].indexOf(user.perfil) > -1 &&
                      !blockFinancAndEnding && (
                        <FinancButton
                          to={{
                            pathname: `${pathname.replace(
                              'secretaria/servicos',
                              'tesouraria/oferta/detail',
                            )}`,
                            state: {
                              reg: {
                                seq: item.seq,
                                cod: item.tscod,
                                desc: item.tsdesc,
                                prq: item.prqname,
                                g1: item.gnome1,
                                g2: item.gnome2,
                                offer: item.vlrofr,
                                inidt: item.dtini,
                              },
                            },
                          }}
                        >
                          <FaDollarSign />
                        </FinancButton>
                      )}
                  </>
                ) : (
                  <>
                    {relatFinServices.indexOf(item.tscod) > 0 &&
                      ['ZON', 'NAC', 'LOC'].indexOf(user.perfil) > -1 &&
                      item.ofiguia !== null && (
                        <FinancButton
                          to={{
                            pathname: `${pathname.replace(
                              'secretaria/atividades',
                              'gerencial/financeiroeventos/more',
                            )}`,
                            state: {
                              from: 'activities',
                              svcseq: item.seq,
                              content: { expense: [], revenue: [] },
                            },
                          }}
                        >
                          {/* AQUI O item.ofiguia é puxado como SVCRELAT (ver php, ultimo campo da query) */}
                          <FaDollarSign />
                        </FinancButton>
                      )}
                  </>
                )}

                {path === 'O' && item.ended && !blockFinancAndEnding ? (
                  <O.ShowLink
                    to={{
                      pathname: `${pathname}/encerramento`,
                      state: { activity: item },
                    }}
                  >
                    <FaFlagCheckered />
                  </O.ShowLink>
                ) : null}
                <></>
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm
              type="button"
              onClick={() => handleExclusion(deleteDiag.values)}
            >
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </AlteredContent>

      <SGOFooter />
    </Container>
  );
};

export default Atividades;
