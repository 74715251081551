import styled from 'styled-components';

interface ItemProps {
  paint: number;
}

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  text-align: center;
  color: #332e2e;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto Slab', serif;
`;

export const SelectContainer = styled.div`
  margin: 10px 0 25px 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;

  > p {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    color: #8a0002;
    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    border-right-width: 0px;
    border-left-width: 0px;
    border-radius: 0px;
    padding: 10px 0px;
    width: 100vw;
  }
`;

export const ListContent = styled.div`
  table {
    margin: 0 auto 50px;
    border-collapse: collapse;
    border-radius: 5px;
    border: 1px solid #332e2e;
    font-size: 16px;
  }

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  td {
    padding: 2px 3px;
  }

  @media screen and (max-width: 550px) {
    table {
      margin: 0 0 40px 0;
      width: 100vw;
    }
  }
`;

export const StyledTR = styled.tr<ItemProps>`
  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};
`;
