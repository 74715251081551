import React from 'react';

import { Container, Content } from 'styles/wrappers';
import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { TOVService } from '../styles';

const manualkid = `${process.env.REACT_APP_ASSETS_DIR}/materiais/c9790eb1-b676-45e5-a239-5d6b87c0b207.png`;

const Criancas: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida para Crianças</div>
        </AlteredHeader>
        <TOVService>
          <h2>Manual TOV Evangelizando Criança</h2>
          <div>
            <span>
              <p>
                TOV Crianças A proposta desta Oficina é mostrar aos pequenos
                oficinistas o tesouro da vida eterna, ensinando-lhes a conversar
                com Deus, que lhes é apresentado como o Papai do céu.
                Insistentemente, este Papai querido é apontado com um pai que
                ama de mil formas e maneiras. Os oficinistas também são
                ensinados a, experimentalmente, relacionar-se com Jesus como seu
                melhor amigo. Assim, com cantos e orações preciosas que permeiam
                todas as sessões e servem para todos os momentos de suas vidas,
                eles aprendem, desde pequeninos, a louvar, bendizer, pedir e
                agradecer ao Pai de ternura.
              </p>
            </span>
            <img src={manualkid} alt="Manual TOV Crianças" />
          </div>
          <span>
            <h4>
              <strong>TOV Crianças</strong>{' '}
              <p>| Manual “Evangelizando Crianças”</p>
            </h4>
            <h4>
              <strong>Participantes:</strong>
              <p>Crianças na faixa etária de 7 a 12 anos.</p>
            </h4>
            <h4>
              <strong>Duração:</strong>
              <p>
                Dez sessões, uma por semana, com duração de uma hora e trinta
                minutos cada.
              </p>
            </h4>
          </span>
        </TOVService>
      </Content>
      <Footer />
    </Container>
  );
};

export default Criancas;
