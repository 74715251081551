import { format, isValid } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import moment from 'moment';
import momenttz from 'moment-timezone';

import { addMinutes, subMinutes } from 'date-fns/esm';

export function formatDate(date?: string): string {
  if (date) {
    if (isValid(new Date(date))) {
      return format(new Date(parseISO(date)), 'dd/MM/yyyy');
    }

    return `${date.substring(8)}/${date.substring(5, 7)}/${date.substring(
      0,
      4,
    )}`;
  }

  return format(new Date(), 'dd/MM/yyyy');
  // if(typeof date === 'string'){
  //   return
  // }

  // return '';
}

export function handleTimeZone(date: string | Date): Date {
  let changeable = typeof date === 'string' ? new Date(date) : date;

  if (changeable.getTimezoneOffset() < 0) {
    changeable = subMinutes(changeable, changeable.getTimezoneOffset() * -1);
  } else {
    changeable = addMinutes(changeable, changeable.getTimezoneOffset());
  }

  return changeable;
}

export function generateToday(): Date {
  const y = new Date().getUTCFullYear().toString();
  let m = (new Date().getUTCMonth() + 1).toString();
  m = parseInt(m, 10) < 10 ? `0${m}` : m;
  let d = new Date().getUTCDate().toString();
  d = parseInt(d, 10) < 10 ? `0${d}` : d;

  return new Date(`${y}-${m}-${d}`);
}

export function minimumAllowedDate(date: string | Date, auto?: boolean): Date {
  const cutDateDay = 10;
  const isAutomatic = auto || false;
  const timezone = 'America/Sao_Paulo';
  // Simulação de data atual;
  const today = momenttz.tz(new Date().toUTCString(), timezone);

  const todayDay = today.get('date');
  let todayMonth = today.get('month');
  if (todayDay < cutDateDay + 1 && !isAutomatic) {
    todayMonth -= 1;
  }

  let todayYear = today.get('year');
  if (todayMonth === -1) {
    todayMonth = 11;
    todayYear -= 1;
  }

  const minimumGenDate = momenttz
    .tz(new Date().toUTCString(), timezone)
    .year(todayYear)
    .month(todayMonth)
    .date(1)
    .hour(0)
    .minute(0)
    .seconds(0);

  const receivedDate = momenttz
    .tz(new Date(date).toUTCString(), timezone)
    .hour(0)
    .minute(0)
    .seconds(0);

  if (receivedDate.isSameOrBefore(minimumGenDate)) {
    return minimumGenDate.toDate();
  }
  return receivedDate.toDate();
}

export function endOfDay(date: string | Date): Date {
  return momenttz
    .tz(date, 'America/Sao_Paulo')
    .hour(23)
    .minute(59)
    .second(59)
    .toDate();
}

// export function minimumAllowedDate(date: string | Date, auto?: boolean): Date {
//   const isAutomatic = auto || false;

//   const today = generateToday();

//   const todayDay = today.getDate();

//   let todayMonth = today.getMonth();

//   if (todayDay <= 10 && !isAutomatic) {
//     todayMonth -= 1;
//   }

//   let todayYear = today.getFullYear();
//   if (todayMonth === -1) {
//     todayMonth = 11;
//     todayYear -= 1;
//   }

//   const minimumGenDate = new Date(
//     `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth + 1}-01 00:00`,
//   );

//   const receivedDate = new Date(date);
//   receivedDate.setHours(0);
//   receivedDate.setUTCMinutes(0);

//   const formats = 'YYYY-mm-dd';
//   const mMinimumGenDate = moment(minimumGenDate, formats);
//   const mReceivedDate = moment(receivedDate, formats);

//   if (mReceivedDate.isSameOrBefore(mMinimumGenDate)) {
//     return minimumGenDate;
//   }
//   return receivedDate;
// }

export function minimumPossibleDate(date: string | Date): Date {
  const today = generateToday();

  const todayDay = today.getDate();

  let todayMonth = today.getMonth();

  if (todayDay <= 10) {
    todayMonth -= 1;
  }

  const minimumGenDate = new Date(
    `${today.getFullYear()}-${todayMonth < 10 ? '0' : ''}${todayMonth + 1}-01`,
  );
  const receivedDate = new Date(date);
  receivedDate.setHours(0);
  receivedDate.setUTCMinutes(0);

  const formats = 'YYYY-mm-dd';
  const mMinimumGenDate = moment(minimumGenDate, formats);
  const mReceivedDate = moment(receivedDate, formats);

  if (mReceivedDate.isSameOrBefore(mMinimumGenDate)) {
    return minimumGenDate;
  }
  return receivedDate;
}
