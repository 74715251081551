import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { formatDate } from 'utils/formatDate';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { calcTotal, formatNumber } from 'utils/calcTotal';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { useAuth } from 'hooks/auth';

import { deconvertSpecialChars } from 'utils/specialChars';
import { FaFileExcel } from 'react-icons/fa';
import { animated, useSpring } from 'react-spring';
import { alphaMasking } from 'components/Textarea/masks';
import {
  HeaderContent,
  SolicContent,
  SolicContentContainer,
  TotalSolic,
  SolicContentCabec,
  SolicContentItem,
  Monetary,
  AddressContainer,
  OBSContainer,
} from './styles';

import { ListProps } from '../main';

export interface StateProps {
  solic: ListProps;
}

interface SolicItemsProps {
  cod: string;
  desc: string;
  qtd: number;
  value: number;
}

const SolicConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();

  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [solicHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.solic;
  });
  const [solicItems, setSolicItems] = useState<SolicItemsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Planilha',
    open: false,
    content: '',
  });

  const getItems = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/solic_items.php?data=${JSON.stringify({
          solic: solicHeader.num,
          loccod: solicHeader.loc,
        })}`,
      );
      setSolicItems(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [errorHandling, solicHeader.loc, solicHeader.num]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/consult', ''));
      return;
    }
    getItems();
  }, [getItems, history, location.pathname, location.state]);

  const totalSolic = useMemo(() => {
    let total = 0;

    solicItems.forEach((item: SolicItemsProps) => {
      total += item.qtd * item.value;
    });

    return total.toFixed(2).replace('.', ',');
  }, [solicItems]);

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);

          let obs = '*** SEM OBSERVAÇÃO ***';
          const retrieve = document.querySelector<HTMLTextAreaElement>(
            'textarea[id="obs"]',
          );

          if (retrieve && retrieve.value.trim() !== '') {
            obs = retrieve.value;
          }

          const send = {
            ...values,
            obs: obs.replace('#', ''),
          };
          setDeleteDiag({ open: false });
          await api.get(`/sgo/solic_delete.php?data=${JSON.stringify(send)}`);

          setLoading(false);

          history.replace(location.pathname.replace('/consult', ''));
        } catch (err) {
          errorHandling(err);
        }
      }
    },
    [errorHandling, history, location.pathname],
  );

  const handleDownload = useCallback(async () => {
    try {
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(`a[id=solicLink]`);
      if (link) {
        link.click();
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const anchor = useSpring({
    display: 'none',
    height: '0px',
  });

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={`Consulta Solicitação N° ${solicHeader.num}`} />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Data da Solicitação:&nbsp;
                    <strong>{formatDate(solicHeader.dtsolic)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Coordenação:&nbsp;
                    <strong>{solicHeader.locdesc}</strong>
                  </p>
                </span>
              </div>
              {solicHeader.numrm && (
                <div>
                  <span>
                    <p>
                      RM N°:&nbsp;
                      <strong>{solicHeader.numrm}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Data da RM:&nbsp;
                      <strong>{formatDate(solicHeader.dtrm)}</strong>
                    </p>
                  </span>
                </div>
              )}
            </HeaderContent>

            <TotalSolic>
              <Button
                bgcolor="#332e2e"
                onClick={() => {
                  setShow((state) => ({
                    ...state,
                    open: true,
                  }));
                  handleDownload();
                }}
              >
                Gerar Planilha&emsp;
                <FaFileExcel />
              </Button>
              <p>
                Total desta Solicitação: <strong>R$ {totalSolic}</strong>
              </p>
            </TotalSolic>

            <SolicContentContainer>
              <SolicContentCabec>
                <span>
                  <p>
                    <strong>Descrição</strong>
                  </p>
                </span>
                <span>
                  <strong>Qtde</strong>
                  <Monetary>Custo</Monetary>
                  <Monetary>Total</Monetary>
                </span>
              </SolicContentCabec>
              <SolicContent>
                {solicItems.map((item: SolicItemsProps, index: number) => (
                  <SolicContentItem paint={index} key={`${item.cod}`}>
                    <span>
                      <p>
                        <strong>
                          {item.cod} - {item.desc}
                        </strong>
                      </p>
                    </span>
                    <span>
                      <strong>{item.qtd}</strong>

                      <Monetary>R$ {formatNumber(item.value)}</Monetary>

                      <Monetary>R$ {calcTotal(item.qtd, item.value)}</Monetary>
                    </span>
                  </SolicContentItem>
                ))}
              </SolicContent>
            </SolicContentContainer>

            <AddressContainer>
              <span>
                <p>
                  Endereço de Entrega:&nbsp;
                  <strong>
                    {solicHeader.address.street}&nbsp;-&nbsp;
                    {solicHeader.address.district}&nbsp;-&nbsp;
                    {solicHeader.address.city}&nbsp;-&nbsp;
                    {solicHeader.address.state}&nbsp;-&nbsp;CEP:&nbsp;
                    {solicHeader.address.cep}
                  </strong>
                </p>
              </span>
            </AddressContainer>
            <OBSContainer>
              <span>
                <h4>Observações:&nbsp;</h4>

                <strong>
                  {solicHeader.obs.map((item) => (
                    <p key={`O${item}`}>{deconvertSpecialChars(item)}</p>
                  ))}
                </strong>
              </span>
            </OBSContainer>

            {solicHeader.status === 'C' && (
              <Button
                type="button"
                onClick={() =>
                  setDeleteDiag({
                    open: true,
                    content: (
                      <ModalDeleteContent>
                        <p>
                          Você está prestes a excluir&nbsp;
                          <strong>permanentemente</strong> o registro da
                          solicitação&nbsp;
                          <strong>N° {solicHeader.num}</strong>
                        </p>
                        <p>
                          Se estiver seguro de sua decisão, clique em confirmar.
                        </p>
                        <div>
                          <textarea
                            id="obs"
                            onChange={(
                              e: React.FormEvent<HTMLTextAreaElement>,
                            ) => handleInputChange(e)}
                            placeholder="Observação"
                          />
                        </div>
                      </ModalDeleteContent>
                    ),
                    values: {
                      solic: solicHeader.num,
                      loccod: solicHeader.loc,
                      locdesc: solicHeader.locdesc,
                      anbdesc: user.anbdesc,
                      gname: user.name,
                    },
                  })}
                containerStyle={{ margin: '25px 0 10px 0' }}
              >
                Excluir essa solicitação
              </Button>
            )}
          </>
        )}
        <animated.a
          style={anchor}
          id="solicLink"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_solic.php?data=${JSON.stringify({
            solic: solicHeader.num.toString(),
            loccod: solicHeader.loc,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          SOLIC
        </animated.a>
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>{deleteDiag.content}</D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm
            type="button"
            onClick={() => handleExclusion(deleteDiag.values)}
          >
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default SolicConsult;
