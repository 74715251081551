import React, { useEffect } from 'react';

import { Container } from 'styles/wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import ScrollTop from 'components/ScrollTop';
import Accordeon from 'components/Accordeon';

import { useCredentials } from 'hooks/credentials';
import { Content, Pray } from './styles';

const Oracoes: React.FC = () => {
  const { checkCredentials } = useCredentials();

  useEffect(() => {
    checkCredentials();
  }, [checkCredentials]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <Accordeon header="Parar" height="530px">
          <span>
            <p>Ref. à 1ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 40 - Ignacio Larrañaga</p>
          </span>
          <p>Como é bom parar!</p>
          <p>
            Senhor, eu gostaria de parar agora mesmo. Por que tanta agitação?
          </p>
          <p>Para que todo esse frenesi?</p>
          <p>Já não sei parar. Esqueço-me de rezar.</p>
          <p>Fecho agora meus olhos, quero falar contigo, Senhor.</p>
          <p>
            Quero abrir-me para o teu universo, mas os meus olhos não querem
            ficar fechados.
          </p>
          <p>
            Sinto que uma agitação frenética invade todo o meu corpo, que vai e
            vem e se agita, escravo da pressa.
          </p>
          <p>Senhor, eu gostaria de parar agora mesmo.</p>
          <p>Por que tanta pressa?</p>
          <p>Por que tanta agitação?</p>
          <p>Eu não posso salvar o mundo!</p>
          <p>
            Sou apenas uma gota d&apos;água no oceano imenso de Tua maravilhosa
            criação.
          </p>
          <p>
            Verdadeiramente importante é buscar Teu rosto abençoado.
            Verdadeiramente importante é parar de vez em quando e esforçar-me
            para proclamar que Tu és a grandeza, a formosura, a magnificência,
            que Tu és o Amor.
          </p>
          <p>O urgente a fazer é deixar que Tu fales dentro de mim.</p>
          <p>
            Viver na profundidade das coisas e no esforço contínuo para
            buscar-te no silêncio de teu mistério.
          </p>
          <p>Meu coração continua batendo, mas de um jeito diferente.</p>
          <p>Não estou fazendo nada, não estou com pressa.</p>
          <p>
            Simplesmente, estou diante de Ti, Senhor, e como é bom estar diante
            de Ti.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Transfiguração">
          <span>
            <p>Ref. à 2ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 46 Ignacio Larrañaga</p>
          </span>
          <p>Senhor, estamos juntos outra vez.</p>
          <p>Estamos juntos Tu e eu, Tu e meus irmãos.</p>
          <p>Tua vida penetrou em minha vida.</p>
          <p>Minha história é tão banal, tão vazia, tão medíocre!</p>
          <p>E nem sequer tenho história.</p>
          <p>Às vezes, até me pergunto se minha vida tem sentido.</p>
          <p>Tanto vazio, tanta complicação, tanta infidelidade!</p>
          <p>
            Mas, quando estou contigo, é como se o entusiasmo, o ânimo
            renascessem, revivessem.
          </p>
          <p>
            E hoje eu vi com meus irmãos, com Pedro, Tiago e João, teu semblante
            transfigurado, iluminado, resplandecente.
          </p>
          <p>Tu, Senhor Jesus, Tu és o Deus de toda luz.</p>
          <p>Tu és o Deus de toda claridade e beleza.</p>
          <p>É bom estar ao teu lado, é bom conviver contigo.</p>
          <p>
            Mas, melhor ainda, Senhor, melhor ainda é ter a certeza de que estás
            comigo, na vida, por tua graça, por teu amor.
          </p>
          <p>
            É bom ter a certeza de que meu rosto também há de ser um rosto
            transfigurado, iluminado, resplandecente, na medida em que Tu me
            fores transformando.
          </p>
          <p>
            Livremente, alegremente, jubilosamente, eu Te suplico que me vás
            identificando cada vez mais contigo, até ao ponto de poder dizer com
            os apóstolos: “Como é bom estarmos aqui, Senhor!”
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Os que creem" height="350px">
          <span>
            <p>Ref. à 3ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 11 Ignacio Larrañaga</p>
          </span>
          <p>Felizes os que não Te viram e creram em Ti.</p>
          <p>
            Felizes os que não contemplaram teu semblante e confessaram tua
            divindade.
          </p>
          <p>
            Felizes os que, lendo o Evangelho, reconheceram em Ti aquele a quem
            esperavam.
          </p>
          <p>
            Felizes os que, em teus enviados, divisaram tua divina presença.
          </p>
          <p>
            Felizes os que, no segredo de seus corações, escutaram tua voz e
            responderam.
          </p>
          <p>
            Felizes os que, animados pelo desejo de tocar Deus, encontraram-Te
            no mistério.
          </p>
          <p>
            Felizes os que, nos momentos de escuridão, aderiram mais fortemente
            à tua luz.
          </p>
          <p>
            Felizes os que, desconcertados pela provação, mantêm sua confiança
            em Ti.
          </p>
          <p>
            Felizes os que, tendo a impressão de que estás ausente, continuam a
            crer em tua proximidade.
          </p>
          <p>
            Felizes os que não Te viram, mas vivem a firme esperança de Te ver
            um dia.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Senhora da Páscoa">
          <span>
            <p>Ref. à 4ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 62 Ignacio Larrañaga</p>
          </span>
          <p>Senhora da Páscoa,</p>
          <p>Senhora da sexta-feira e do domingo,</p>
          <p>Senhora da noite e da manhã,</p>
          <p>Senhora do silêncio e da cruz,</p>
          <p>Senhora do amor e da entrega,</p>
          <p>Senhora da palavra acolhida e da palavra dada,</p>
          <p>Senhora da paz e da esperança,</p>
          <p>
            Senhora de todas as partidas, porque és a Senhora do “trânsito”, ou
            “páscoa”, escuta-nos!
          </p>
          <p>
            Hoje queremos dizer-te muito obrigado, muito obrigado, Senhora, pelo
            teu “Fiat”, por tua completa disponibilidade de escrava, por tua
            pobreza e teu silêncio, pelo gozo de tuas sete espadas, pela dor de
            todas as tuas partidas que foram dando paz a tantas almas.
          </p>
          <p>
            Muito obrigado por teres ficado conosco apesar do tempo e das
            distâncias.
          </p>
          <p>
            Nossa Senhora da Reconciliação, imagem e princípio da Igreja, hoje
            deixamos em teu coração pobre, silencioso e disponível, esta Igreja,
            peregrina da Páscoa.
          </p>
          <p>
            Uma Igreja essencialmente missionária, fermento e alma da sociedade
            em que vivemos, uma Igreja profética que seja o anúncio de que o
            Reino de Deus já chegou.
          </p>
          <p>
            Uma Igreja de testemunhas autênticas, inserida na história dos
            homens, como presença salvadora do Senhor, fonte de paz, de alegria
            e de esperança.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Ato de abandono" height="410px">
          <span>
            <p>Ref. à 5ª Sessão, TOV Adultos.</p>
            <p>Ato de abandono</p>
          </span>
          <p>Encontro: manual de oração nº 31 Ignacio Larrañaga</p>
          <p>Em tuas mãos, ó Deus, eu me abandono.</p>
          <p>Modela esta argila, como o oleiro faz com o barro.</p>
          <p>
            Dá-lhe forma e, depois, se assim o quiseres, desmancha-a em pedaços.
          </p>
          <p>
            Manda, ordena. “Que queres que eu faça? Que queres que eu deixe de
            fazer?” Elogiado e humilhado, perseguido, incompreendido e
            caluniado, consolado, dolorido, inútil para qualquer coisa, só me
            resta dizer, a exemplo de tua Mãe:
          </p>
          <p>“Faça-se em mim segundo a tua palavra”.</p>
          <p>
            Dá-me o amor por excelência, o amor da Cruz; não uma cruz heroica,
            que possa satisfazer meu amor próprio; mas aquelas cruzes humildes e
            vulgares, que eu carrego com repugnância.
          </p>
          <p>
            As que encontro cada dia na contradição, no esquecimento, no
            fracasso.
          </p>
          <p>
            Nos maus juízos e na indiferença, na rejeição e no desprezo dos
            outros, no mal-estar e na doença, nas limitações intelectuais e na
            aridez, no silêncio do coração.
          </p>
          <p>Só então saberás que Te amo, ainda que eu mesmo não o saiba.</p>
          <p>Mas isso basta.</p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Paz" height="230px">
          <span>
            <p>Ref. à 6ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 27 Ignacio Larrañaga</p>
          </span>
          <p>Senhor!</p>
          <p>Enche de esperança o meu coração e de doçura os meus lábios!</p>
          <p>
            Põe em meus olhos a luz que acaricia e purifica, em minhas mãos o
            gesto que perdoa.
          </p>
          <p>
            Dá-me valentia para a luta, compaixão para as injúrias, misericórdia
            para a ingratidão e a injustiça.
          </p>
          <p>
            Livra-me da inveja e da ambição mesquinha, do ódio e da vingança.
          </p>
          <p>
            E que, quando eu voltar hoje para o calor de minha cama, possa, no
            mais íntimo de meu ser, sentir que estás presente.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Louvor a Deus" height="350px">
          <span>
            <p>Ref. à 7ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 63 Ignacio Larrañaga</p>
          </span>
          <p>Tu és Santo, Senhor Deus único, que fazes maravilhas.</p>
          <p>Tu és forte, Tu és grande, Tu és Altíssimo.</p>
          <p>
            Tu és o Bem, todo Bem, Sumo Bem, Senhor Deus, vivo e verdadeiro.
          </p>
          <p>
            Tu és caridade e amor, Tu és sabedoria, Tu és humildade, Tu és
            paciência, Tu és segurança.
          </p>
          <p>Tu és quietude, Tu és consolação, Tu és alegria.</p>
          <p>Tu és formosura, Tu és mansidão.</p>
          <p>Tu és nosso protetor, guarda e defensor.</p>
          <p>Tu és nossa fortaleza e esperança.</p>
          <p>Tu és nossa doçura.</p>
          <p>Tu és nossa vida eterna, grande e admirável, Senhor.</p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="É o teu rosto que eu busco Senhor" height="580px">
          <span>
            <p>Ref. à 8ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 7 Ignacio Larrañaga</p>
          </span>
          <p>
            Deixa, por um momento, as tuas preocupações habituais, homem
            insignificante; entra, por um instante, em ti mesmo, afastando-te do
            tumulto de teus pensamentos confusos e das preocupações inquietantes
            que te oprimem. Descansa em Deus por um momento, descansa nele pelo
            menos um instante.
          </p>
          <p>
            Entra no mais profundo de tua alma; afasta tudo de ti, exceto Deus e
            o que te puder ajudar a encontrá-lo. Fecha a porta de teu quarto e
            procura-o no silêncio. Dize a Deus com todas as tuas forças, dize ao
            Senhor: “Busco teu rosto. É teu rosto que eu busco Senhor”.
          </p>
          <p>
            E agora, Senhor e Deus meu, ensina-me como e onde tenho que Te
            buscar, onde e como Te alcançarei. Se não estás em mim Senhor, se
            estás ausente, onde Te encontrarei? Se estás em toda parte, por que
            não Te apresentas aqui? É certo que habitas uma luz inacessível, mas
            onde está essa luz inacessível? Como me aproximarei dela? Quem me
            guiará e me introduzirá nessa luz para que nela eu Te contemple? Em
            que rastos, por que sinais Te reconhecerei? Nunca Te vi, Senhor e
            Deus meu, não conheço teu rosto.
          </p>
          <p>
            Deus Altíssimo, que vai fazer este desterrado longe de Ti? Que vai
            fazer este servidor, sedento de teu amor, vagando longe de Ti?
            Deseja ver-Te, e teu rosto está muito longe. Deseja reunir-se
            contigo e tua casa é inacessível. Arde no desejo de Te encontrar e
            não sabe onde moras. Não suspira a não ser por Ti e nunca viu teu
            rosto.
          </p>
          <p>
            Senhor, Tu és meu Deus. Tu és meu Senhor, mas não Te conheço. Tu me
            criaste e me redimiste. Tu me pediste tudo o que tenho, mas ainda
            não Te conheço. Fui criado para ver-Te e ainda não pude chegar ao
            fim para que fui criado. E Tu, Senhor, até quando nos esquecerás,
            até quando esconderás teu rosto? Quando olharás para nós? Quando nos
            escutarás? Quando iluminarás nossos olhos e nos mostrarás teu rosto?
            Quando responderás a nossos desejos? Senhor escuta-nos, ilumina-nos,
            revela-Te a nós. Atende a nossos desejos e seremos felizes. Sem Ti,
            tudo é fastídio e tristeza. Compadece-Te de nossos trabalhos e de
            nossos esforços para chegar a Ti, já que sem Ti nada podemos.
          </p>
          <p>
            Ensina-me a buscar-Te, mostra-me teu rosto, porque se Tu não o
            ensinas não poderei encontrar-Te. Não poderei encontrar-Te se não Te
            fizeres presente. Buscar-Te-ei desejando-Te, desejar-Te-ei
            buscando-Te. Amando-Te, Te encontrarei. Encontrando-Te, amar-Te-ei.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Presença escondida" height="560px">
          <span>
            <p>Ref. à 9ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 13 Ignacio Larrañaga</p>
          </span>
          <p>Não estás.</p>
          <p>Não se vê teu rosto.</p>
          <p>Estás.</p>
          <p>Teus raios disparam em mil direções.</p>
          <p>És a Presença Escondida.</p>
          <p>Ó Presença sempre oculta e sempre clara!</p>
          <p>
            Ó Mistério Fascinante para o qual convergem todas as aspirações!
          </p>
          <p>Ó Vinho Inebriante que satisfaz todos os desejos!</p>
          <p>Ó Infinito Insondável que aquieta todas as quimeras!</p>
          <p>És o Mais-Além e o Mais-Aquém de tudo.</p>
          <p>Estás substancialmente presente em todo o meu ser.</p>
          <p>Tu me comunicas a existência e a consistência.</p>
          <p>Tu me penetras, me envolves, me amas.</p>
          <p>Estás em torno de mim e dentro de mim.</p>
          <p>
            Com tua Presença ativa, atinges até as mais remotas e profundas
            zonas de minha intimidade.
          </p>
          <p>
            És a alma de minha alma, a vida de minha vida, mais eu do que eu
            mesmo, a realidade total e totalizante dentro da qual estou
            submerso.
          </p>
          <p>Com tua força vivificante penetras tudo o que sou e tenho.</p>
          <p>
            Toma-me todo inteiro, ó Tudo do meu tudo, e faze de mim uma viva
            transparência do teu Ser e do teu Amor.
          </p>
          <p>Ó Pai queridíssimo!</p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Centro de gravidade" height="620px">
          <span>
            <p>Ref. à 10ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 1 Ignacio Larrañaga</p>
          </span>
          <p>
            Para cantar a Ti, meu Senhor Jesus, quem me dera ter olhos de águia,
            coração de criança e uma língua polida pelo silêncio!
          </p>
          <p>
            Toca meu coração, Senhor Jesus Cristo! Toca-o e verás como vão
            despertar os sonhos enterrados nas raízes humanas desde o começo do
            mundo.
          </p>
          <p>Todas as nossas vozes juntam-se diante de tuas portas.</p>
          <p>Todas as nossas ondas morrem em tuas praias.</p>
          <p>Todos os nossos ventos dormem em teus horizontes.</p>
          <p>
            Os desejos mais recônditos, sem o sabermos, Te exigem e Te invocam.
          </p>
          <p>Os anelos mais profundos buscam-Te impacientemente.</p>
          <p>
            Tu és noite estrelada, música de diamantes, vértice do universo,
            fogo que brota da pedra.
          </p>
          <p>
            No lugar em que pousas teu pé chagado, o planeta arde em sangue e
            ouro.
          </p>
          <p>Caminhas sobre as correntes sonoras e pelos cumes nevados.</p>
          <p>Suspiras nos bosques seculares.</p>
          <p>
            Sorris na murta e na giesta. Respiras nas algas, fungos e líquens.
          </p>
          <p>
            Por toda a amplidão do universo mineral e vegetal, eu Te sinto
            nascer, crescer, viver, rir e falar.
          </p>
          <p>Tu és o pulso do mundo, meu Senhor Jesus Cristo!</p>
          <p>
            És Aquele que sempre vem vindo das galáxias longínquas, do centro
            ígneo da terra, e do fundo do tempo; vens, desde sempre, há milhões
            de anos-luz.
          </p>
          <p>
            Em tua fronte, resplandece o destino do mundo e, em teu coração,
            concentra-se o fogo dos séculos.
          </p>
          <p>
            Com o coração deslumbrado diante de tanta maravilha, inclino-me para
            te dizer: Tu serás o rei de meus territórios. Para Ti será o fogo de
            meu sangue.
          </p>
          <p>
            Tu serás meu caminho e minha luz, a causa de minha alegria, a razão
            de meu existir e o sentido de minha vida.
          </p>
          <p>
            Minha bússola e meu horizonte, meu ideal, minha plenitude e minha
            consumação. Fora de Ti, não há nada para mim.
          </p>
          <p>
            Para Ti será minha última canção. Glória e honra para sempre a Ti,
            Rei dos Séculos!
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Conversão total" height="350px">
          <span>
            <p>Ref. à 11ª Sessão, TOV Adultos.</p>
            <p>Encontro: manual de oração nº 39 Ignacio Larrañaga</p>
          </span>
          <p>Sei que me estás pedindo alguma coisa, Senhor Jesus.</p>
          <p>Tantas portas abertas de uma só vez.</p>
          <p>
            O panorama de minha vida diante de meus olhos: não como em um sonho.
          </p>
          <p>
            Sei que estás esperando alguma coisa de mim, Senhor, e aqui estou,
            ao pé da muralha: tudo está aberto, só há um caminho livre, aberto
            para o infinito, o absoluto.
          </p>
          <p>Mas eu não mudei, apesar de tudo.</p>
          <p>Terei que entrar em contato contigo, Senhor.</p>
          <p>
            Buscarei tua companhia ainda por muito tempo, para, então, morrer
            inteiramente.
          </p>
          <p>
            Como esses feridos que sofrem, Senhor, peço-Te que acabes comigo.
          </p>
          <p>Estou cansado de não ser teu, de não ser Tu.</p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="A graça de comunicar-se" height="370px">
          <span>
            <p>Ref. à 12ª Sessão, TOV Adultos. </p>
            <p>Encontro - Manual de Oração nº 47 Ignacio Larrañaga</p>
          </span>
          <p>
            Senhor Jesus, chamaste de “amigos” aos discípulos porque abriste
            para eles a tua intimidade.
          </p>
          <p>Mas como é difícil abrir-se, Senhor!</p>
          <p>Como custa rasgar o véu do próprio mistério!</p>
          <p>Como há impedimentos no caminho!</p>
          <p>
            Mas eu sei bem, Senhor, que sem comunicação não há amor e que o
            mistério essencial da fraternidade consiste nesse jogo de abrir-se e
            acolher uns aos outros.
          </p>
          <p>
            Faze-me compreender, Senhor, que fui criado não como um ser acabado
            e fechado, mas como uma tensão e movimento para os outros; que devo
            participar da riqueza dos outros e deixar que os outros participem
            de minha riqueza; e que fechar-se é morte e abrir-se é vida,
            liberdade, maturidade.
          </p>
          <p>
            Senhor Jesus Cristo, rei da fraternidade, dá-me a convicção e a
            coragem de abrir-me, ensina-me a arte de abrir-me.
          </p>
          <p>
            Arrebenta meus retraimentos e medos, bloqueios e timidez, que
            impedem a corrente da comunicação.
          </p>
          <p>
            Dá-me a generosidade para me lançar, sem medo, nesse jogo
            enriquecedor de abrir-me e acolher.
          </p>
          <p>Dá-nos a graça da comunicação, Senhor Jesus.</p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="A graça da humildade" height="590px">
          <span>
            <p>Ref. à 13ª Sessão, TOV Adultos.</p>
            <p>Encontro - Manual de Oração nº 41 Ignacio Larrañaga</p>
          </span>
          <p>
            Senhor Jesus, manso e humilde, desde o pó, me sobe e me domina esta
            sede insaciável de estima, esta necessidade imperiosa de que todos
            me queiram bem.
          </p>
          <p>Meu coração está cheio de delírios impossíveis.</p>
          <p>Preciso de redenção. Misericórdia, meu Deus!</p>
          <p>
            Não consigo perdoar, o rancor me queima, as críticas me ferem, os
            fracassos me afundam, as rivalidades me assustam.
          </p>
          <p>
            Meu coração é soberbo. Dá-me a graça da humildade, meu Senhor manso
            e humilde de coração.
          </p>
          <p>
            Não sei de onde me vêm estes desejos loucos de impor minha vontade,
            de eliminar o rival, de dar rédeas à vingança. Faço o que não quero.
            Tem piedade, Senhor, e dá-me a graça da humildade.
          </p>
          <p>
            Grossas correntes prendem meu coração: este coração lança raízes,
            submete e se apropria de tudo o que sou e faço, de tudo o que me
            rodeia.
          </p>
          <p>
            E, destas apropriações, vêm-me tanto susto e tanto medo. Infeliz de
            mim, proprietário de mim mesmo!
          </p>
          <p>Quem vai romper minhas cadeias?</p>
          <p>Tua graça, meu Senhor pobre e humilde.</p>
          <p>
            Dá-me a graça da humildade, a graça de perdoar de coração, a graça
            de aceitar a crítica e a contradição ou, ao menos, de duvidar de mim
            mesmo quando me corrigirem.
          </p>
          <p>Dá-me a graça de fazer, tranquilamente, a minha autocrítica.</p>
          <p>
            Dá-me a graça de me manter sereno nos desprezos, esquecimentos e
            indiferenças; de sentir-me verdadeiramente feliz no anonimato; de
            não fomentar autossatisfações nos sentimentos, palavras e atos.
          </p>
          <p>
            Abre, Senhor, espaços livres dentro de mim para que os possas
            ocupar, Tu e meus irmãos.
          </p>
          <p>
            Por fim, meu Senhor Jesus Cristo, dá-me a graça de ir adquirindo,
            paulatinamente, um coração desprendido e vazio como o Teu; um
            coração manso, paciente e benigno.
          </p>
          <p>
            Cristo Jesus, manso e humilde de coração, faz meu coração semelhante
            ao Teu.
          </p>
          <strong>Assim seja.</strong>
        </Accordeon>
        <Accordeon header="Estás conosco" height="350px">
          <span>
            <p>Ref. à 14ª Sessão, TOV Adultos.</p>
            <p>Encontro: Manual de oração nº 50 Ignacio Larrañaga</p>
          </span>
          <p>Estás conosco todos os dias até o fim do mundo.</p>
          <p>Estás conosco, Onipotência divina, com nossa fragilidade.</p>
          <p>
            Estás conosco, amor infinito que nos acompanha em todos os nossos
            passos.
          </p>
          <p>
            Estás conosco, proteção soberana e garantia de êxito nas tentações.
          </p>
          <p>
            Estás conosco, energia que sustenta nossa generosidade vacilante.
          </p>
          <p>Estás conosco em nossas dificuldades e provações.</p>
          <p>
            Estás conosco em nossas decepções e ansiedades para nos devolver a
            coragem.
          </p>
          <p>
            Estás conosco nas tristezas para nos comunicar o entusiasmo de tua
            alegria.
          </p>
          <p>Estás conosco na solidão como companheiro que nunca falha.</p>
          <p>
            Estás conosco em nossa missão apostólica para nos guiar e ser nosso
            arrimo.
          </p>
          <p>
            Estás conosco para nos conduzir ao Pai pelo caminho da sabedoria e
            da eternidade.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Oração da esperança">
          <span>
            <p>Ref. à 15ª Sessão, TOV Adultos.</p>
            <p>Encontro: Manual de oração nº 16 Ignacio Larrañaga</p>
          </span>
          <p>Senhor,</p>
          <p>mais uma vez estou diante do teu Mistério.</p>
          <p>
            Estou constantemente envolvido em tua Presença que, tantas vezes, se
            transforma em ausência.
          </p>
          <p>Busco tua Presença na ausência de tua Presença.</p>
          <p>
            Lançando um olhar para o mundo imenso da terra dos homens, tenho a
            impressão de que muitos já não esperam em Ti.
          </p>
          <p>
            Eu mesmo traço meus planos, estabeleço minhas metas e vou colocando
            as pedras de um edifício cujo único arquiteto parece que sou eu
            mesmo.
          </p>
          <p>
            Nós, homens de hoje, somos, muitas vezes, criaturas que nos
            constituímos em esperança de nós mesmos.
          </p>
          <p>
            Dá-me, Senhor, a convicção mais profunda de que estarei destruindo
            meu futuro sempre que a esperança em Ti não estiver presente.
          </p>
          <p>Faz que eu compreenda profundamente que,</p>
          <p>apesar do caos de coisas que me rodeia,</p>
          <p>apesar das noites que tenho que atravessar,</p>
          <p>apesar do cansaço de meus dias, meu futuro está em tuas mãos,</p>
          <p>
            e que a terra que me mostras, no horizonte de minha manhã, será mais
            bela e melhor.
          </p>
          <p>
            Deposito em teu Mistério meus passos e meus dias, porque sei que teu
            Filho e meu Irmão venceu a desesperança e garantiu um futuro novo,
            porque passou da morte para a vida.
          </p>
          <strong>Amém.</strong>
        </Accordeon>
        <Accordeon header="Oração avulsa">
          <Pray>
            <p>
              Frei Ignacio Larrañaga escreveu na sua Carta Circular nº 25 de
              março de 2010:
            </p>
            <p style={{ fontStyle: 'italic' }}>
              É importante saber que um dos autores que mais influíram em nossa
              espiritualidade foi o irmão Carlos de Foucauld [1858-1916]. Aqui,
              [vocês] têm uma entranhável oração do irmão Carlos.
            </p>

            <strong>Ama-me tal como és!</strong>
          </Pray>

          <span>
            <p>
              Pela terceira vez, Jesus lhe disse:
              <q> Simão, filho de João, você me ama? </q>. (Jo 21, 17)
            </p>
            <p />
          </span>

          <p>
            Conheço tua miséria, as lutas e tribulações de tua alma, a
            debilidade e as doenças de teu corpo.
          </p>
          <p>Conheço tua covardia, teus pecados e tuas fraquezas.</p>
          <p>Apesar de tudo, te digo: dá-me teu coração.</p>
          <strong>Ama-me tal como és.</strong>
          <p>&nbsp;</p>
          <p>
            Se para dar-me teu coração, esperas ser um anjo, nunca chegarás a
            amar-me.
          </p>
          <p>
            Ainda quando caias de novo, muitas vezes, nessas faltas que jamais
            gostarias de cometer, e sejas um covarde para praticar a virtude,
          </p>
          <p>não te consinto que me deixes de amar.</p>
          <strong>Ama-me tal como és.</strong>
          <p>&nbsp;</p>
          <p>
            Ama-me em todo momento, qualquer que seja a situação em que te
            encontres,
          </p>
          <p>de fervor ou de secura, de fidelidade ou de traição.</p>
          <strong>Ama-me tal como és.</strong>
          <p>&nbsp;</p>
          <p>Quero o amor de teu coração indigente.</p>
          <p>Se esperas ser perfeito para me amar, nunca chegarás a amar...</p>
          <strong>Ama-me tal como és.</strong>
          <p>&nbsp;</p>
          <p>Deixa-te amar. Quero teu coração.</p>
          <p>Em meus planos está moldar-te, mas, enquanto isso chega,</p>
          <p>amo-te tal como és.</p>
          <p>E quero que tu faças o mesmo.</p>
          <p>&nbsp;</p>
          <p>
            Desejo ver teu coração que se levanta desde o profundo de tua
            miséria.
          </p>
          <p>Amo em ti, inclusive, tua fraqueza.</p>
          <p>Gosto do amor dos pobres.</p>
          <p>
            Quero que, desde a indigência, se levante incessantemente este
            grito:
          </p>
          <strong>Amo-te, Senhor!</strong>
          <p>&nbsp;</p>
          <p>O que me importa é o canto de teu coração.</p>
          <p>Para que necessito eu de tua ciência ou de teus talentos?</p>
          <p>Não te peço virtudes.</p>
          <p>
            E, ainda quando eu te as dou, és tão frágil que sempre se mesclaria
            nelas um pouco de amor próprio.
          </p>
          <p>Mas não te preocupes por isso...</p>
          <p>Preocupa-te somente de encher com teu amor o momento presente.</p>
          <p>&nbsp;</p>
          <p>Hoje, me tens à porta de teu coração, como a um mendigo.</p>
          <p>A mim, que sou o Senhor dos senhores.</p>
          <p>Chamo à tua porta e espero.</p>
          <p>Apressa-te a abrir-me. Não alegues tua miséria.</p>
          <p>
            Se conhecesses plenamente a dimensão de tua indigência, morrerias de
            dor.
          </p>
          <p>
            Uma só coisa poderia ferir-me o coração: ver que duvidas e que te
            falta confiança.
          </p>
          <p>&nbsp;</p>
          <p>Quero que penses em mim todas as horas do dia e da noite.</p>
          <p>
            Não quero que realizes nem sequer a ação mais insignificante por um
            motivo que não seja o amor.
          </p>
          <p>Quando te caiba sofrer, eu te darei forças.</p>
          <p>
            Tu me deste amor, eu te farei amar mais do que tenhas podido sonhar.
          </p>
          <p>
            Mas recorda somente isto: <strong>Ama-me tal como és.</strong>
          </p>
        </Accordeon>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Oracoes;
