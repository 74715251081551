import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useLocation, useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import * as yup from 'yup';

import { Container, Content } from 'styles/sgo_wrappers';

import {
  FaCalendarAlt,
  FaDollarSign,
  FaExclamationTriangle,
  FaCheck,
} from 'react-icons/fa';

import { format, parseISO, subDays } from 'date-fns';

import getValidationErrors from 'utils/getValidationErrors';
import { minimumAllowedDate } from 'utils/formatDate';

import { MessageContainer, Attention, Message, CustomCheckbox } from './styles';

interface StateProps {
  amount: number;
  mode: string;
  minimumDate: string;
}

interface FormData {
  repassdt: string;
  repassval: string;
}

const OfertasRepasseInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation<StateProps>();

  const [loading, setLoading] = useState(true);
  const [percent] = useState(() => {
    return user.perfil === 'LOC'
      ? 0.8
      : user.perfil === 'NAC'
      ? 0.8 * 0.5
      : 0.8 * 0.5 * 0.5;
  });

  const [received] = useState<StateProps>(() => {
    return { ...location.state } || ({} as StateProps);
  });

  const [minimumDate, setMinimumDate] = useState('');

  const [enable, setEnable] = useState(false);

  const getMinimumDate = useCallback(async () => {
    const response = await api.get(
      `/sgo/oferta_data_minima.php?data=${JSON.stringify({
        mode: received.mode,
      })}`,
    );
    console.log(
      `%cData mínima: %c${response.data}`,
      'font-size: 16px; color:#FFA000',
      'font-size:36px; color: #039BE5',
    );
    setMinimumDate(response.data);
    setLoading(false);
  }, [received.mode]);

  useEffect(() => {
    getMinimumDate();
  }, [getMinimumDate]);

  const handleCheckboxChange = useCallback(() => {
    const checkBox = document.querySelector('[data-id="confirm-checkbox"]');
    if (checkBox instanceof HTMLInputElement) {
      setEnable(checkBox.checked);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const lastDate = minimumAllowedDate(minimumDate, true);

        const maxDate = new Date(parseISO(new Date().toISOString()));

        const schema = yup.object().shape({
          repassdt: yup
            .date()
            .typeError('Data inválida.')
            .min(
              lastDate,
              `A data do lançamento deve posterior à ${format(
                subDays(lastDate, 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              maxDate,
              `A data do lançamento deve ser igual ou anterior à data atual (${format(
                maxDate,
                'dd/MM/yyyy',
              )}).`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // return;

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({ date: data.repassdt, mode: received.mode }),
        );

        await api.post('/sgo/oferta_repasse_status.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `Repasse de ofertas à ${
            user.perfil === 'ZON'
              ? 'Internacional '
              : user.perfil === 'NAC'
              ? 'Zonal'
              : 'Nacional'
          } ${received.mode === 'C' ? 'confirmado' : 'efetivado'} com sucesso!`,
        });

        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, minimumDate, received.mode, user.perfil],
  );

  const showForm = useSpring({
    opacity: enable ? 1 : 0,
    pointerEvents: enable ? 'all' : 'none',
    transform: enable ? 'translateX(0px)' : 'translateX(50px)',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        title={`${received.mode === 'C' ? 'Confirm' : 'Efetiv'}ação de Repasse`}
        noLinks
      />
      <Content>
        <MessageContainer>
          <Attention>
            <FaExclamationTriangle />
            <p>Atenção</p>
            <FaExclamationTriangle />
          </Attention>
          {received.mode === 'C' ? (
            <div>
              {user.perfil === 'LOC' ? (
                <>
                  <Message>
                    Todos os lançamentos de ofertas que estão sendo previamente
                    repassados para a nacional ficarão bloqueados até a
                    confirmação do depósito referente ao valor total aqui
                    mostrado.
                  </Message>
                  <Message>Não será possível reverter a prévia.</Message>
                  <Message>
                    Assim que for realizado o depósito para a nacional, o
                    tesoureiro local deverá efetivar o repasse pendente.
                  </Message>
                  <Message>
                    Nenhuma outra oferta poderá ser repassada enquanto não for
                    confirmado o repasse pendente.
                  </Message>
                </>
              ) : null}
              {user.perfil === 'NAC' ? (
                <>
                  <Message>
                    Todos os lançamentos de ofertas que estão sendo previamente
                    repassados para a zonal ficarão bloqueados até a confirmação
                    do depósito referente ao valor total aqui mostrado.
                  </Message>
                  <Message>Não será possível reverter a prévia.</Message>
                  <Message>
                    Assim que for realizado o depósito para a zonal, o
                    tesoureiro nacional deverá efetivar o repasse pendente.
                  </Message>
                  <Message>
                    Nenhuma outra oferta poderá ser repassada enquanto não for
                    confirmado o repasse pendente.
                  </Message>
                </>
              ) : null}
            </div>
          ) : (
            <>
              {user.perfil === 'LOC' ? (
                <>
                  <Message>
                    Somente realizar essa efetivação do repasse se o valor total
                    foi depositado na data informada.
                  </Message>
                  <Message>Não será possível reverter essa efetivação!</Message>
                  <Message>
                    A partir desse processo é que os valores das ofertas
                    relacionadas serão abatidos do caixa da local e creditados
                    no movimento bancário da Nacional.
                  </Message>
                  <Message>
                    Fique atento pois conforme regra bancária, valores
                    depositados fora do expediente bancário normal serão
                    efetuados no próximo dia util.
                  </Message>
                </>
              ) : null}
              {user.perfil === 'NAC' ? (
                <>
                  <Message>
                    Somente realizar essa efetivação do repasse se o valor total
                    foi depositado na data informada.
                  </Message>
                  <Message>Não será possível reverter essa efetivação!</Message>
                  <Message>
                    A partir desse processo é que os valores das ofertas
                    relacionadas serão abatidos do movimento bancário da
                    nacional e creditados no movimento bancário da Zonal.
                  </Message>
                  <Message>
                    Fique atento pois conforme regra bancária, valores
                    depositados fora do expediente bancário normal serão
                    efetuados no próximo dia util.
                  </Message>
                </>
              ) : null}
              {user.perfil === 'ZON' ? (
                <>
                  <Message>
                    Ao pressionar FINALIZAR, os valores das ofertas relacionadas
                    serão totalizadas e registradas como Contas a Pagar da Zonal
                    e como Contas a Receber da TOV INTERNACIONAL.
                  </Message>
                  <Message>Não será possível reverter a prévia.</Message>
                </>
              ) : null}
            </>
          )}
        </MessageContainer>

        <CustomCheckbox htmlFor="checkboxConfirm" ischecked={enable}>
          <input
            type="checkbox"
            data-id="confirm-checkbox"
            name="checkboxConfirm"
            onChange={handleCheckboxChange}
          />
          <p>Estou ciente</p>
          <FaCheck />
        </CustomCheckbox>
        <animated.div style={showForm}>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              repassval: (received.amount * percent).toFixed(2),
            }}
          >
            <h4 style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}>
              Data do repasse:
            </h4>
            <Input
              name="repassdt"
              // placeholder="dd/mm/aaaa"
              icon={FaCalendarAlt}
              type="date"
            />

            <h4 style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}>
              Valor do repasse:
            </h4>
            <Input
              name="repassval"
              placeholder="0,00"
              icon={FaDollarSign}
              type="number"
              mask="currency"
              step=".01"
              disabled
            />

            <Button
              style={{ margin: '25px 0 0 0' }}
              type="submit"
              bgcolor="#00802b"
            >
              Finalizar
            </Button>
          </Form>
        </animated.div>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default OfertasRepasseInsert;
