import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import InputDialog from 'components/InputDialog';
import IncludeButton from 'components/IncludeButton';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';
import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import { removeAcento } from 'utils/specialChars';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';

import api from 'services/api';

import { Container, Content } from 'styles/sgo_wrappers';
import {
  FaChevronDown,
  FaChevronRight,
  FaDownload,
  FaFilePdf,
  FaFolderPlus,
  FaHome,
  FaPlusCircle,
  FaRegFolder,
  FaRegFolderOpen,
  FaTimesCircle,
} from 'react-icons/fa';

import Button from 'components/Button';
import {
  FoldersProps,
  PayloadProps,
  DocumentProps,
  FileProps,
} from './interfaces';

import {
  DropContainer,
  DropWrapper,
  InsertFileInfoContainer,
  GridContainer,
  PreviousPathsContainer,
  PreviousPathsMobileContainer,
  Wrapper,
  FileInfoContainer,
  GridDocumentsContainer,
  FolderInfoContainer,
  ExcludeButton,
  ContentWrapper,
} from './styles';

const Documents: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { pathname } = useLocation();
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();

  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);
  const [incDoc, setIncDoc] = useState<IncludeProps>({} as IncludeProps);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const documentPath = process.env.REACT_APP_TOV_STATIC_FILES;
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<FoldersProps[]>([]);
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [previousPaths, setPreviousPaths] = useState<FoldersProps[]>([]);
  const [filelist, setFileList] = useState<FileProps[]>([]);

  const [payload, setPayload] = useState<PayloadProps>({
    tier: 0,
  } as PayloadProps);

  const toggleDocModal = useCallback(() => {
    setIncDoc((state) => ({ ...state, open: !state.open }));
    setFileList([]);
    const input = inputRef.current;
    if (input) {
      input.value = '';
    }
  }, []);

  const getList = useCallback(
    async (open = false) => {
      try {
        setLoading(true);

        const response = await api.get(
          `/sgo/documents_list.php?data=${JSON.stringify({
            ...payload,
            from: pathname.includes('gerencial') ? 'gerencial' : 'secretaria',
          })}`,
        );
        const { __folders, __documents } = response.data;

        setFolders(__folders);
        setDocuments(__documents);
      } catch (err) {
        errorHandling(err);
      } finally {
        if (open) {
          toggleDocModal();
        }
        setLoading(false);
      }
    },
    [errorHandling, pathname, payload, toggleDocModal],
  );

  useEffect(() => {
    if (pathname.includes('gerencial')) {
      handlePermission(['INT'], true);
    }
    getList();
  }, [getList, handlePermission, pathname]);

  const accessFolder = useCallback((ev) => {
    const { path, folder, currtier } = ev.currentTarget.dataset;
    setPreviousPaths((state) => [
      ...state,
      { folder, path, currentTier: parseInt(currtier, 10) },
    ]);

    setPayload((state) => {
      const obj = { ...state };
      obj.tier = parseInt(currtier, 10) + 1;
      obj.docPath = path;
      obj[`n${currtier}`] = folder;
      return obj;
    });
  }, []);

  const handleNavigateToFolder = useCallback((ev) => {
    const { path, folder, currtier } = ev.currentTarget.dataset;

    setDocuments([]);

    setPreviousPaths((state) => {
      if (folder === 'home') {
        return [];
      }
      const index = state.map((item) => item.path).indexOf(path) + 1;
      const updated = state.slice(0, index);

      return updated;
    });

    setPayload((previous) => {
      if (folder === 'home') {
        return { tier: 0 };
      }
      const obj = { ...previous };
      obj.tier = parseInt(currtier, 10) + 1;
      obj.docPath = path;
      Object.keys(obj).forEach((element) => {
        if (['tier', 'docPath'].indexOf(element) < 0) {
          const currentKey = parseInt(element.replace('n', ''), 10);
          if (currentKey >= obj.tier) {
            delete obj[element];
          }
        }
      });

      return obj;
    });
  }, []);

  const toggleDeleteModal = useCallback(
    (ev) => {
      const { id, tier, folder, mode } = ev.currentTarget.dataset;

      setDeleteDiag((state) => {
        const newObj = {} as any;
        newObj.mode = mode;
        if (mode !== 'file') {
          Object.keys(payload).forEach((key) => {
            newObj[key] = payload[key];
          });
          newObj[`n${tier}`] = folder;
          delete newObj.docPath;
          delete newObj.tier;
        }
        const obj = { ...state };
        delete obj.values;
        if (!obj.open) {
          obj.values = { ...newObj, id, tier };
        }
        obj.open = !obj.open;

        return obj;
      });
    },
    [payload],
  );

  const toggleModal = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleCreateFolder = useCallback(
    async (data: { folderName: string }) => {
      try {
        includeRef.current?.setErrors({});
        if (
          folders.findIndex(
            (item) =>
              removeAcento(item.folder).toLowerCase() ===
              removeAcento(data.folderName).toLowerCase(),
          ) > -1
        ) {
          includeRef.current?.setFieldError(
            'folderName',
            'Já existe uma pasta com esse nome.',
          );
          return;
        }

        const schema = yup.object().shape({
          folderName: yup
            .string()
            .trim()
            .min(4, 'Quantidade mínima de caracteres não atingida (4).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        setInc((state) => ({ ...state, open: !state.open }));

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            ...payload,
          }),
        );

        await api.post('/sgo/documents_folder_new.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, folders, getList, payload],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append('data', JSON.stringify(deleteDiag.values));

      let php = 'documents_file_delete';
      if (deleteDiag.values?.mode && deleteDiag.values?.mode === 'folder') {
        php = 'documents_folder_delete';
      }
      setDeleteDiag((state) => {
        const obj = { ...state };
        delete obj.values;

        obj.open = !obj.open;
        return obj;
      });
      const response = await api.post(`/sgo/${php}.php`, send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data) {
        throw Error(response.data.message);
      }

      getList();
    } catch (err) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: `${err.message}`,
        seconds: 10,
      });
    }
  }, [addToast, deleteDiag.values, getList]);

  // HANDLE FILE INCLUSION

  const fileSize = (size: number): string => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const handleDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleFiles = useCallback(
    (f: FileList) => {
      setFileList([]);

      const asArray = Array.from(f);
      asArray.map((item) => {
        const filename = item.name;

        if (filename.substr(-3) !== 'pdf') {
          addToast({
            type: 'error',
            title: `Arquivo ${filename} não suportado`,
          });
          return false;
        }

        setFileList((state) => [
          ...state,
          { name: filename, size: item.size, ext: filename.substr(-3) },
        ]);

        return true;
      });
    },
    [addToast],
  );

  const handleFileDrop = useCallback(
    (e) => {
      e.preventDefault();
      const { files } = e.dataTransfer;
      setFileList(files);

      const input = inputRef.current;

      if (input) {
        input.files = files;
      }

      if (files.length > 0) {
        handleFiles(files);
      }
    },
    [handleFiles],
  );

  const handleSimulateClick = useCallback(() => {
    const input = inputRef.current;

    if (input) {
      input.value = '';
      input.click();
    }
  }, []);

  const handleFileSelected = useCallback(() => {
    const input = inputRef.current;

    if (input && input.files) {
      setFileList([]);
      handleFiles(input.files);
    }
  }, [handleFiles]);

  const handleFileUpload = useCallback(async () => {
    try {
      setLoading(true);
      const input = inputRef.current;
      const send = new FormData();

      const obj = { ...payload } as any;
      delete obj.docPath;
      delete obj.tier;

      if (!!input && !!input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          send.append('files[]', input.files[i], input.files[i].name);
          send.append('tiers', JSON.stringify({ ...obj }));
        }

        toggleDocModal();

        await api.post('/sgo/documents_file_upload.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList(true);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `Documento incluído com sucesso.`,
        });
      }
    } catch (err) {
      // setFileList({} as FileList);
      const input = inputRef.current;

      if (input) {
        input.value = '';
      }
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: `Não foi possível incluir o documento.`,
      });
    }
  }, [addToast, getList, payload, toggleDocModal]);

  return (
    <Container>
      {user.adm && (
        <IncludeButton isButton>
          <button type="button" onClick={toggleDocModal}>
            <FaPlusCircle />
          </button>
        </IncludeButton>
      )}

      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        {user.adm && payload.tier < 8 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '20px 0',
            }}
          >
            {/* <button type="button" onClick={toggleModal} data-type="folder">
              <FaFolderPlus />
              &nbsp;Nova pasta
            </button> */}
            <Button
              type="button"
              style={{ margin: '10px 0 0 0', height: '35px' }}
              bgcolor="#2E7D32"
              onClick={toggleModal}
            >
              <FaFolderPlus
                style={{ height: '25px', width: '25px', margin: '0 7px 0 0' }}
              />
              <p> Nova pasta </p>
            </Button>
          </div>
        )}
        {/* <AlteredHeader>
          <div>
            <p></p>
          </div>
        </AlteredHeader> */}
        {/* {JSON.stringify(previousPaths)} */}
        <Wrapper>
          <PreviousPathsContainer>
            {previousPaths.length > 0 && (
              <div>
                <FaRegFolderOpen />
                <button
                  type="button"
                  data-folder="home"
                  onClick={handleNavigateToFolder}
                >
                  <FaHome />
                </button>
                <FaChevronRight />
              </div>
            )}
            {previousPaths.map((item: FoldersProps, index) => (
              <div>
                {index !== 0 ? <FaChevronRight /> : ''}
                <button
                  type="button"
                  data-folder={item.folder}
                  data-path={item.path}
                  data-currtier={item.currentTier}
                  onClick={handleNavigateToFolder}
                >
                  {item.folder}
                </button>
              </div>
            ))}
          </PreviousPathsContainer>
          <PreviousPathsMobileContainer>
            <details>
              <summary>
                <FaRegFolderOpen />
                <p>Anteriores</p>
                <FaChevronRight />
              </summary>
              {previousPaths.length > 0 && (
                <div>
                  <button
                    type="button"
                    data-folder="home"
                    onClick={handleNavigateToFolder}
                  >
                    <FaHome />
                  </button>
                  <FaChevronDown />
                </div>
              )}
              {previousPaths.map((item: FoldersProps, index) => (
                <div>
                  {index !== 0 ? <FaChevronDown /> : ''}
                  <button
                    type="button"
                    data-folder={item.folder}
                    data-path={item.path}
                    data-currtier={item.currentTier}
                    onClick={handleNavigateToFolder}
                  >
                    {item.folder}
                  </button>
                </div>
              ))}
            </details>
          </PreviousPathsMobileContainer>
        </Wrapper>
        {!loading && (
          <>
            {folders.length > 0 && (
              <ContentWrapper open>
                <summary>
                  Pastas&emsp;
                  <FaChevronDown />
                </summary>
                <GridContainer>
                  {folders.map((item) => (
                    <FolderInfoContainer key={item.path}>
                      <FaRegFolder />
                      <button
                        type="button"
                        data-folder={item.folder}
                        data-path={item.path}
                        data-currtier={item.currentTier}
                        onClick={accessFolder}
                      >
                        &nbsp;
                      </button>
                      <p>{item.folder}</p>
                      {user.adm && (
                        <ExcludeButton
                          type="button"
                          data-id={item.path}
                          data-tier={item.currentTier}
                          data-folder={item.folder}
                          data-mode="folder"
                          onClick={toggleDeleteModal}
                        >
                          <FaTimesCircle />
                        </ExcludeButton>
                      )}
                    </FolderInfoContainer>
                  ))}
                </GridContainer>
              </ContentWrapper>
            )}

            <ContentWrapper style={{ margin: '30px 0' }} open>
              <summary>
                Documentos&emsp;
                <FaChevronDown />
              </summary>
              {documents.length > 0 ? (
                <GridDocumentsContainer>
                  {documents.map((item) => (
                    <FileInfoContainer key={item.id}>
                      <FaFilePdf />
                      <a
                        href={`${documentPath}/${item.file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        &nbsp;
                      </a>
                      <p>{item.label}</p>
                      {user.adm && (
                        <ExcludeButton
                          type="button"
                          data-id={item.id}
                          data-mode="file"
                          onClick={toggleDeleteModal}
                        >
                          <FaTimesCircle />
                        </ExcludeButton>
                      )}
                    </FileInfoContainer>
                  ))}
                </GridDocumentsContainer>
              ) : (
                <div>
                  <p>Não há documentos nesta pasta</p>
                </div>
              )}
            </ContentWrapper>
          </>
        )}
      </Content>

      <I.Container scroll="paper" maxWidth={false} open={!!inc.open}>
        <Form ref={includeRef} onSubmit={handleCreateFolder}>
          <I.Title>
            <h2>Nova pasta</h2>
          </I.Title>
          <I.Content>
            <InputDialog
              mask="alphaExtra"
              name="folderName"
              containerStyle={{
                margin: '30px 0 0 0',
                width:
                  width >= 472 ? '360px' : width >= 392 ? '280px' : '245px',

                padding: '5px 2px 4.82px 5px',
              }}
              placeholder="Nova pasta"
            />
          </I.Content>
          <I.Actions>
            <I.Cancel type="button" onClick={toggleModal}>
              Cancelar
            </I.Cancel>
            <I.Confirm type="submit">Finalizar</I.Confirm>
          </I.Actions>
        </Form>
      </I.Container>

      <I.Container scroll="paper" maxWidth={false} open={!!incDoc.open}>
        <I.Title>{/*  */}</I.Title>
        <I.Content style={{ height: '300px' }}>
          <DropWrapper
            onDragOver={handleDefault}
            onDragEnter={handleDefault}
            onDragLeave={handleDefault}
            onDrop={handleFileDrop}
            onClick={handleSimulateClick}
          >
            <DropContainer>
              <input
                ref={inputRef}
                type="file"
                onChange={handleFileSelected}
                accept="application/pdf"
              />
              <FaDownload />
              <p style={{ fontWeight: 500 }}>
                Arraste o(s) arquivo(s) aqui ou clique para importar
              </p>
            </DropContainer>
          </DropWrapper>
          <InsertFileInfoContainer>
            {filelist.map((item) => (
              <>
                <div>
                  <FaFilePdf />
                  <p>{item.name}</p>
                </div>
                <div>
                  <p>({fileSize(item.size)})</p>
                </div>
              </>
            ))}
          </InsertFileInfoContainer>
        </I.Content>
        <I.Actions>
          <I.Cancel type="button" onClick={toggleDocModal}>
            Finalizar
          </I.Cancel>
          <I.Confirm type="button" onClick={handleFileUpload}>
            Enviar
          </I.Confirm>
        </I.Actions>
      </I.Container>

      <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>
          <D.ModalDeleteContent>
            <p>
              Você está prestes a excluir&nbsp;
              <strong style={{ color: '#c53030' }}>permanentemente</strong>
              &nbsp;
              {deleteDiag.values?.mode && deleteDiag.values?.mode === 'folder'
                ? 'esta pasta.'
                : 'este documento.'}
            </p>
            <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
          </D.ModalDeleteContent>
        </D.Content>
        <D.Actions>
          <D.Cancel type="button" onClick={toggleDeleteModal}>
            Cancelar
          </D.Cancel>
          <D.Confirm type="submit" onClick={handleDelete}>
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>

      <SGOFooter />
    </Container>
  );
};

export default Documents;
