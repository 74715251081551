import styled, { keyframes, css } from 'styled-components';
import { shade, parseToRgb } from 'polished';

interface AnimatedProps {
  delay?: number;
  duration?: number;
  blocked?: boolean;
}

interface StageBlockProps {
  banner?: string;
  textcolor?: string;
}
const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);

  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: rotate(37deg) translateY(-195px) translateX(38px);
  } to {
    opacity: 1;
    transform: rotate(37deg) translateY(-165px) translateX(38px);
  }
`;

export const Grid = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  width: 1200px;

  /* align-items: center;
  justify-content: center; */
  row-gap: 25px;
  column-gap: 25px;

  @media screen and (max-width: 1300px) {
    width: 100vw;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div<AnimatedProps>`
  display: flex;
  flex-direction: column;
  width: 400px;
  /* background: black; */
  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  h2 {
    width: 100%;
    text-align: center;
    color: #332e2e;
    text-shadow: 0px 2px 5px rgba(51, 46, 46, 0.5);
  }

  div {
    min-height: 275px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 7px;
    /* align-items: center; */
    background: #fff;
    overflow: hidden;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;

    figure {
      animation-delay: ${(props) => (props.delay || 1) + 0.2}s;
    }
    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        font-size: 16px;
        color: #767676;
        strong {
          color: #332e2e;
        }

        & + p {
          margin: 0 auto;
        }
      }

      & + span {
        margin: 10px 0 0 0;
      }
    }

    button {
      opacity: 1;
      position: absolute;
      bottom: 2px;
      left: 50%;
      padding: 3px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 2px solid #5e5555;
      border-radius: 3px;
      background: #5e5555;

      transition: all 0.35s;

      svg {
        opacity: 1;
        height: 25px;
        width: 25px;
        color: #fff;
      }

      &:hover {
        background: ${shade(0.2, '#5e5555')};
      }
    }

    &:hover {
      border-color: #332e2e;
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (max-width: 1300px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 430px) {
    width: 100vw;

    div {
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
    }
  }
`;

export const StageBlock = styled.figure<StageBlockProps>`
  --red: ${parseToRgb('#87ceeb').red};
  --green: ${parseToRgb('#87ceeb').green};
  --blue: ${parseToRgb('#87ceeb').blue};

  ${(props) =>
    props.banner &&
    css`
      --red: ${parseToRgb(`${props.banner}`).red};
      --green: ${parseToRgb(`${props.banner}`).green};
      --blue: ${parseToRgb(`${props.banner}`).blue};
    `}

  margin: 0px -7px;

  background-image: linear-gradient(
    to right,
    rgba(var(--red), var(--green), var(--blue), 0.25),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 1),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 0.25)
  );

  position: absolute;
  z-index: 200;
  top: 50%;
  width: 100%;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;

  opacity: 0;
  transform: rotate(37deg) translateY(-195px) translateX(38px);
  animation: ${appearFromTop} 1s forwards;

  p {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    color: #332e2e;
    ${(props) =>
      props.textcolor &&
      css`
        color: ${props.textcolor};
      `}
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
  /* transform: rotate(-37deg) translateY(-46px) translateX(-30px); */
`;
