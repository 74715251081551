import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { useSpring } from 'react-spring';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import RadioContainer from 'components/RadioContainer';
import Textarea from 'components/Textarea';
import Loading from 'components/Loading';

import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useWindow } from 'hooks/window';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  FaPlusSquare,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
  FaArrowLeft,
} from 'react-icons/fa';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { checkAddress } from 'utils/specialChars';
import Button from 'components/Button';

import { alphaMasking } from 'components/Textarea/masks';

import {
  AddressWarn,
  PhaseOne,
  PhaseTwo,
  NacItemContainer,
  ItemContainer,
  PhaseOneItemContainer,
  AddButton,
  Grid,
  GridItem,
  InfoContainer,
  TotalSolic,
  FinishButton,
  PhaseThree,
  ReturnButton,
} from './styles';

interface LocSelectedProps {
  cod: string;
  desc: string;
}

export interface SolicItemsProps {
  matcod: string;
  matdesc: string;
  cost: number;
  quantity: number;
  stored: number;
}

interface DeliverAddressProps {
  value: string;
  label: string;
}

const SolicInsert: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const phaseTwo = useRef<FormHandles>(null);
  const phaseThree = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const { width } = useWindow();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [mats, setMats] = useState<ComboProps[]>([]);
  const [storageQuantity, setStorageQuantity] = useState('-');
  const [cost, setCost] = useState(0);
  const [solicItems, setSolicItems] = useState<SolicItemsProps[]>([]);

  const [deliverAddress, setDeliverAddress] = useState('');
  const [guiAddress, setGuiAddress] = useState('');
  const [locAddress, setLocAddress] = useState('');

  const [deliverAble, setDeliverAble] = useState(false);
  const [addressWarn, setAddressWarn] = useState('');

  const [finish, setFinish] = useState(false);

  const [locSelected, setLocSelected] = useState<LocSelectedProps>(() => {
    if (user.perfil === 'NAC') {
      return {} as LocSelectedProps;
    }

    return {
      cod: user.loccod,
      desc: user.locdesc,
    };
  });

  const getAddress = useCallback(
    async (params?: LocSelectedProps['cod']) => {
      const response = await api.get<{ LOC: string; GUIA: string }>(
        `/sgo/getAddress.php?data=${JSON.stringify({
          trans: 'solic',
          cod: params || locSelected.cod,
        })}`,
      );

      const { LOC, GUIA } = response.data;

      if (LOC && GUIA) {
        if (checkAddress(LOC) === checkAddress(GUIA)) {
          setDeliverAddress(GUIA);
        } else {
          setDeliverAble(true);
          setDeliverAddress(GUIA);
          setGuiAddress(GUIA);
          setLocAddress(LOC);
          phaseThree.current?.setFieldValue('deliver', 'P');
        }
      } else if (!LOC && GUIA) {
        setDeliverAddress(GUIA);
      } else if (!GUIA && LOC) {
        setDeliverAddress(LOC);
      } else {
        setAddressWarn(
          'Esta coordenação não atende os requisitos de endereço de entrega.',
        );
      }
    },
    [locSelected.cod],
  );

  const getComboMATS = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboMATs.php');
      setMats(response.data);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1) {
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
      );
    }
  }, [user.perfil, user.anbc]);

  const handleLOCselect = useCallback(() => {
    const index = comboLocs.findIndex(
      (item: ComboProps) =>
        item.value === phaseOne.current?.getFieldValue('comboLocal'),
    );

    setLocSelected({
      cod: comboLocs[index].value,
      desc: comboLocs[index].label,
    });
    getAddress(comboLocs[index].value);
  }, [comboLocs, getAddress]);

  const handleMaterialSelect = useCallback(async () => {
    try {
      setLoading(true);
      phaseTwo.current?.setErrors({});
      const material = phaseTwo.current?.getFieldValue('material');

      const send = JSON.stringify({
        matcod: material,
        trans: 'solic',
        loccod: locSelected.cod,
      });
      const response = await api.get(`/sgo/getStorage.php?data=${send}`);

      const { quantity, matcost } = response.data;

      setStorageQuantity(quantity);
      setCost(matcost);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, locSelected.cod]);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    if (user.perfil === 'LOC') {
      getAddress();
    }
    getComboLOC();
    getComboMATS();
  }, [getAddress, getComboLOC, getComboMATS, handlePermission, user.perfil]);

  const handlePushMaterial = useCallback(() => {
    const index = mats.findIndex(
      (item: ComboProps) =>
        item.value === phaseTwo.current?.getFieldValue('material'),
    );

    phaseTwo.current?.setErrors({});

    if (index > -1) {
      const newMat = {
        matcod: mats[index].value,
        matdesc: mats[index].label,
        cost,
        quantity: 1,
        stored: parseInt(storageQuantity, 10),
      };

      // setSolicItems((state) =>
      //   [...state, newMat].sort((a, b) => (a.matdesc > b.matdesc ? 1 : -1)),
      // );

      setSolicItems((state) => [newMat, ...state]);

      setMats((state) =>
        state.filter((item: ComboProps) => item.value !== newMat.matcod),
      );
      setStorageQuantity('-');
      setCost(0);
    } else {
      phaseTwo.current?.setFieldError(
        'material',
        'You must select at least one material',
      );

      addToast({
        type: 'info',
        title: ``,
        description: 'É necessário escolher um material.',
      });
    }

    phaseTwo.current?.setFieldValue('material', '');
  }, [addToast, cost, mats, storageQuantity]);

  const handleAdd = useCallback(
    (material: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        solicItems[index].quantity += 1;
        setSolicItems((state) => [...state]);
      }
    },
    [solicItems],
  );

  const handleSub = useCallback(
    (material: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.matcod === material,
      );

      if (index > -1) {
        solicItems[index].quantity -= 1;
        setSolicItems((state) =>
          solicItems[index].quantity === 0
            ? [...state].filter(
                (item: SolicItemsProps) =>
                  item.matcod !== solicItems[index].matcod,
              )
            : [...state],
        );

        if (solicItems[index].quantity === 0) {
          const reinsert = {
            value: solicItems[index].matcod,
            label: solicItems[index].matdesc,
          };

          const rearrange = [...mats, reinsert].sort((a, b) =>
            a.label > b.label ? 1 : -1,
          );

          setMats(rearrange);
        }
      }
    },
    [solicItems, mats],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.matcod === mat,
      );
      const number = e.currentTarget.value.replace(/^0/, '');
      if (number.length > 0) {
        const asNum = parseInt(number, 10);

        if (index > -1) {
          solicItems[index].quantity = asNum;
          setSolicItems((state) =>
            solicItems[index].quantity <= 0
              ? [...state].filter(
                  (item: SolicItemsProps) =>
                    item.matcod !== solicItems[index].matcod,
                )
              : [...state],
          );
        }
      } else {
        solicItems[index].quantity = 0;
        setSolicItems((state) => [...state]);
      }

      e.currentTarget.value = e.currentTarget.value.replace(/^0/, '');
    },
    [solicItems],
  );

  const handleInputBlur = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.matcod === mat,
      );
      if (e.currentTarget.value === '0') {
        setSolicItems((state) =>
          [...state].filter(
            (item: SolicItemsProps) => item.matcod !== solicItems[index].matcod,
          ),
        );

        const material = {
          value: solicItems[index].matcod,
          label: solicItems[index].matdesc,
        };

        const rearrange = [...mats, material].sort((a, b) =>
          a.label > b.label ? 1 : -1,
        );

        setMats(rearrange);
      }
    },
    [solicItems, mats],
  );

  const handleRemoveItem = useCallback(
    (mat: string) => {
      const index = solicItems.findIndex(
        (item: SolicItemsProps) => item.matcod === mat,
      );

      const reinsert = {
        value: solicItems[index].matcod,
        label: solicItems[index].matdesc,
      };

      setSolicItems((state) =>
        state.filter((item: SolicItemsProps) => item.matcod !== mat),
      );

      const rearrange = [...mats, reinsert].sort((a, b) =>
        a.label > b.label ? 1 : -1,
      );

      setMats(rearrange);
    },
    [mats, solicItems],
  );

  const handleRadioClick = useCallback(() => {
    const value = phaseThree.current?.getFieldValue('deliver');

    setDeliverAddress(value === 'P' ? guiAddress : locAddress);
  }, [guiAddress, locAddress]);

  const totalSolic = useMemo(() => {
    const total = solicItems.reduce(
      (accum, { quantity, cost: value }: SolicItemsProps) => {
        const semitotal = accum + quantity * value;
        return semitotal;
      },
      0,
    );

    return total;
  }, [solicItems]);

  const styledProceedPhaseTwo = useSpring({
    transform: solicItems.length > 0 ? 'translateX(5px)' : 'translateX(60px)',
    opacity: solicItems.length > 0 ? 1 : 0,
    pointerEvents: solicItems.length > 0 ? 'all' : 'none',
  });

  const toggleFlexDirection = useSpring({
    flexDirection: width < 400 && totalSolic > 99.99 ? 'column' : 'row',
  });

  const phaseOneStyle = useSpring({
    opacity: !locSelected.cod && !finish ? 1 : 0,
    transform:
      !locSelected.cod && !finish ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: !locSelected.cod && !finish ? 'all' : 'none',
    display: !locSelected.cod && !finish ? 'inline' : 'none',
  });

  const phaseTwoStyle = useSpring({
    opacity: locSelected.cod && !finish ? 1 : 0,
    transform:
      locSelected.cod && !finish ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: locSelected.cod && !finish ? 'all' : 'none',
    display: locSelected.cod && !finish ? 'inline' : 'none',
  });

  const phaseThreeStyle = useSpring({
    opacity: finish ? 1 : 0,
    transform: finish ? 'translateX(0)' : 'translateX(60px)',
    pointerEvents: finish ? 'all' : 'none',
    display: finish ? 'inline' : 'none',
  });

  const warnStyle = useSpring({
    opacity: addressWarn.length > 0 ? 1 : 0,
    height: addressWarn.length > 0 ? (width <= 500 ? '58px' : '30px') : '0px',
  });

  const handleOBSChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const newstring = e;
      alphaMasking(newstring);

      return newstring;
    },
    [],
  );

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        local: locSelected,
        items: solicItems,
        address: {
          value: phaseThree.current?.getFieldValue('deliver') || 'P',
          label: deliverAddress,
        },
        obs: phaseThree.current?.getFieldValue('obs').replace('#', ''),
        name: user.name,
        anbdesc: user.anbdesc,
        locdesc: user.locdesc,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      const response = await api.post('/sgo/solic_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { solnum } = response.data;
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Solicitação N° ${solnum} registrada${
          user.perfil === 'NAC' ? ` para a Local ${locSelected.desc}` : ''
        }.`,
      });

      history.replace(pathname.replace('/insert', ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    deliverAddress,
    errorHandling,
    history,
    locSelected,
    pathname,
    solicItems,
    user.anbdesc,
    user.locdesc,
    user.name,
    user.perfil,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Solicitação de Material" />
      <Content>
        <AddressWarn style={warnStyle}>
          <h4>{addressWarn}</h4>
        </AddressWarn>
        <PhaseOne style={phaseOneStyle}>
          <Form
            ref={phaseOne}
            onSubmit={() => {
              return null;
            }}
          >
            <PhaseOneItemContainer>
              <p>Coordenação Local:</p>
              <SelectV2
                name="comboLocal"
                content={comboLocs}
                onChange={handleLOCselect}
                initial="Selecione"
              />
            </PhaseOneItemContainer>
          </Form>
        </PhaseOne>
        <PhaseTwo style={phaseTwoStyle}>
          <Form
            ref={phaseTwo}
            onSubmit={() => {
              return null;
            }}
          >
            {user.perfil === 'NAC' && (
              <NacItemContainer>
                <p>
                  Solicitação para <strong>{locSelected.desc}</strong>
                </p>
              </NacItemContainer>
            )}
            <ItemContainer>
              <span>
                <SelectV2
                  name="material"
                  content={mats}
                  initial="Selecione"
                  onChange={handleMaterialSelect}
                />
              </span>
              <span>
                <p>Estoque da Local:</p>
                <strong>{storageQuantity}</strong>
              </span>
            </ItemContainer>
            <AddButton type="button" onClick={handlePushMaterial}>
              <FaPlusSquare /> <p>Adicionar material</p>
            </AddButton>
            <InfoContainer>
              <TotalSolic style={toggleFlexDirection}>
                <p>Total desta solicitação:&nbsp;</p>
                <strong>R$ {formatNumber(totalSolic)}</strong>
              </TotalSolic>

              <FinishButton
                style={styledProceedPhaseTwo}
                type="button"
                onClick={() => setFinish(true)}
              >
                Avançar
              </FinishButton>
            </InfoContainer>
          </Form>

          <Grid>
            {solicItems.map((item: SolicItemsProps) => (
              <GridItem key={item.matcod}>
                <div>
                  <strong>
                    {item.matcod} - {item.matdesc}
                  </strong>
                </div>

                <div>
                  <span>
                    <p>
                      Custo:&nbsp;<strong>R$ {formatNumber(item.cost)}</strong>
                    </p>
                  </span>
                  <span>
                    <p>Quantidade:</p>
                    <span>
                      <FaSortUp
                        onClick={() => handleAdd(item.matcod)}
                        style={{
                          transform: 'translateY(10px)',
                          color: '#009933',
                        }}
                      />

                      <input
                        type="number"
                        min={0}
                        value={item.quantity}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          handleInputChange(e, item.matcod)
                        }
                        onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                          handleInputBlur(e, item.matcod)
                        }
                      />

                      <FaSortDown
                        onClick={() => handleSub(item.matcod)}
                        style={{
                          transform: 'translateY(-10px)',
                          color: '#c53030',
                        }}
                      />
                    </span>
                    <figure>
                      <FaTimesCircle
                        onClick={() => handleRemoveItem(item.matcod)}
                      />
                    </figure>
                  </span>
                </div>
                <div>
                  <span>
                    <p>
                      Estoque Local atual:&nbsp;
                      <strong>{item.stored}</strong>
                    </p>
                  </span>
                </div>
                <div>
                  <span>
                    <p>
                      Total:&nbsp;
                      <strong>R$ {calcTotal(item.quantity, item.cost)}</strong>
                    </p>
                  </span>
                </div>
              </GridItem>
            ))}
          </Grid>
        </PhaseTwo>
        <PhaseThree style={phaseThreeStyle}>
          <ReturnButton onClick={() => setFinish(false)}>
            <FaArrowLeft />
            <p>Retornar aos itens</p>
          </ReturnButton>
          <Form
            ref={phaseThree}
            onSubmit={() => {
              return null;
            }}
          >
            <div>
              {deliverAble && (
                <RadioContainer
                  title="Endereço de Entrega:"
                  onClick={handleRadioClick}
                  name="deliver"
                  content={[
                    {
                      id: 'dT',
                      value: 'P',
                      label: 'Tesoureiro',
                    },
                    {
                      id: 'dL',
                      value: 'S',
                      label: 'Local',
                    },
                  ]}
                  selected="P"
                />
              )}

              <p>{deliverAddress}</p>
            </div>
            <div>
              <p>Observação:</p>
              <Textarea
                name="obs"
                placeholder="Insira a observação (opcional)"
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  handleOBSChange(e)
                }
              />
            </div>
          </Form>
          {addressWarn.length <= 0 && (
            <Button onClick={handleFinish} bgcolor="#00802b">
              Finalizar
            </Button>
          )}
        </PhaseThree>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default SolicInsert;
