import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isADM?: boolean;
  component: React.ComponentType;
}

export interface ComponentProps {
  path: string;
  isPrivate: boolean;
  component: React.FC;
  uuid?: string;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isADM = false,
  component: Component,
  ...rest
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { addToast } = useToast();
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (!!user && location.pathname === '/signin') {
          // history.replace('/dashboard');
          history.replace('/sgo');
        }

        if (!!user && user.mudasenha && pathname.includes('/sgo')) {
          return <Redirect to="/primeiro_acesso" />;
        }

        if (isPrivate === !!user && !isADM) {
          return <Component />;
        }

        if (isPrivate && !user) {
          return (
            <Redirect to={{ pathname: '/signin', state: { from: location } }} />
          );
        }

        if (isADM && !!user) {
          if (user.adm) {
            return <Component />;
          }
          addToast({
            type: 'error',
            title: 'Ação não permitida',
            description: 'Área restrita à equipe administrativa.',
          });

          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        if (isADM && !user) {
          addToast({
            type: 'error',
            title: 'Ação não permitida',
            description: 'Área restrita à equipe administrativa.',
          });

          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        return <Component />;
      }}
    />
  );
};

export default Route;
