import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaPhone, FaMobileAlt, FaTimes } from 'react-icons/fa';
import { Container, Content } from 'styles/wrappers';
import { AlteredHeader } from 'styles/sgo_wrappers';

import Header from 'components/Header';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
import Footer from 'components/Footer';
import Loading from 'components/Loading';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';

import api from 'services/api';

import errorHandling from 'utils/errorHandling';
import SelectV2 from 'components/SelectV2';

import { ComboProps } from 'styles/sgo_wrappers';
import { useSpring } from 'react-spring';
import * as I from './interfaces';

import {
  Wrapper,
  SearchContainer,
  SearchButton,
  ComponentContainer,
  ComponentContent,
  Schedule,
  ScheduleItem,
  RemoveButton,
} from './styles';

const Oficinas: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [oficinas, setOficinas] = useState<I.OficinaProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<ComboProps[]>([]);
  const [ocities, setOCities] = useState<I.City[]>([]);
  const [cities, setCities] = useState<ComboProps[]>([]);
  const [odistricts, setODistricts] = useState<I.District[]>([]);
  const [districts, setDistricts] = useState<ComboProps[]>([]);
  const [hasFilterApplied, setFilterApplied] = useState(false);
  const [error, setError] = useState('');

  const getCombos = useCallback(async () => {
    try {
      const response = await api.get('/web/filterOficinas.php');
      setStates(response.data.uf);
      setOCities(response.data.cities);
      setODistricts(response.data.districts);
    } catch (err) {
      errorHandling(err);
    }
  }, []);

  useEffect(() => {
    getCombos();
  }, [getCombos]);

  const handleChange = useCallback(
    (ev: any) => {
      const { type } = ev.currentTarget.dataset;
      const select = formRef.current?.getFieldRef(type);
      const { value } = select.options[select.selectedIndex];
      if (type === 'uf') {
        setFilterApplied(true);
        setCities(
          ocities
            .filter((item) => item.uf === value)
            .map((item) => ({ label: item.label, value: item.value })),
        );
      } else if (type === 'city') {
        setDistricts(
          odistricts
            .filter((item) => item.city === value)
            .map((item) => ({ label: item.label, value: item.value })),
        );
      } else if (type === 'district') {
        // nothing to do
      } else {
        // other logics.
      }
    },
    [ocities, odistricts],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const uf = formRef.current?.getFieldRef('uf');
      const city = formRef.current?.getFieldRef('city');
      const district = formRef.current?.getFieldRef('district');

      const vU = uf.options[uf.selectedIndex].value;
      const vC = city.options[city.selectedIndex].value;
      const vD = district.options[district.selectedIndex].value;

      if (vU === '') {
        setError('Selecione o estado');

        setTimeout(() => {
          setError('');
        }, 2500);
        return;
      }

      setLoading(true);

      const response = await api.get(
        `/web/oficinas.php?uf=${vU}&city=${vC}&district=${vD}`,
      );

      setOficinas(response.data.oficinas);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilterApplied(false);
    setCities([]);
    setDistricts([]);
    formRef.current?.setData({
      uf: '',
      city: '',
      district: '',
    });
  }, []);

  const removeButtonStyled = useSpring({
    opacity: hasFilterApplied ? 1 : 0,
    pointerEvents: hasFilterApplied ? 'all' : 'none',
    height: hasFilterApplied ? '20px' : '0px',
    overflow: hasFilterApplied ? 'unset' : 'hidden',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida pelo Brasil</div>
        </AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <strong>
              Saiba onde estão sendo aplicadas atualmente as Oficinas
            </strong>
            <div
              style={{
                width: '100vw',
                display: 'flex',
              }}
            >
              <RemoveButton
                type="button"
                onClick={handleClearFilters}
                style={removeButtonStyled}
              >
                <FaTimes />
                &nbsp;
                <p>Remover filtros</p>
              </RemoveButton>
            </div>
            <SearchContainer>
              <div>
                <p>Estado</p>
                <SelectV2
                  name="uf"
                  data-type="uf"
                  initial="Selecione"
                  content={states}
                  onChange={handleChange}
                  containerStyle={{
                    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </div>
              <div>
                <p>Cidade</p>
                <SelectV2
                  name="city"
                  data-type="city"
                  initial="Selecione"
                  content={cities}
                  onChange={handleChange}
                  containerStyle={{
                    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </div>
              <div>
                <p>Bairro</p>
                <SelectV2
                  name="district"
                  data-type="district"
                  initial="Selecione"
                  content={districts}
                  onChange={handleChange}
                  containerStyle={{
                    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </div>
            </SearchContainer>

            <div style={{ width: '100vw', display: 'flex' }}>
              <SearchButton type="submit">{error || 'Pesquisar'}</SearchButton>
            </div>
          </Wrapper>
        </Form>

        <ComponentContainer>
          {oficinas.map((ofic) => (
            <ComponentContent key={ofic.code}>
              <span>
                <strong>Coordenação Responsável:</strong>
                <strong>{ofic.description}</strong>
              </span>
              <span>
                <strong>Local:</strong>
                <p>{ofic.parish}</p>
              </span>

              <span>
                <strong>Endereço:</strong>
                <p>{ofic.address}</p>
              </span>

              <span>
                <strong>Cidade:</strong>
                <p>{ofic.city}</p>
              </span>
              <span>
                <strong>Bairro:</strong>
                <p>{ofic.district}</p>
              </span>

              <span>
                <strong>Guia:</strong>
                <p>{ofic.name}</p>
              </span>

              <span>
                <strong>Contato:</strong>
                {ofic.cellphone !== '' && (
                  <p>
                    <FaMobileAlt />
                    {ofic.cellphone}
                  </p>
                )}
                {ofic.phone !== '' && (
                  <p>
                    <FaPhone />
                    {ofic.phone}
                  </p>
                )}
              </span>

              <Schedule>
                <ScheduleItem>
                  <strong>Dia da Semana:</strong>
                  <p>{ofic.weekday}</p>
                </ScheduleItem>
                <ScheduleItem>
                  <strong>Horário:</strong>
                  <p>{ofic.hour}</p>
                </ScheduleItem>
                <ScheduleItem>
                  <strong>Tipo de Serviço:</strong>
                  <p>{ofic.type}</p>
                </ScheduleItem>
              </Schedule>

              <h2>
                Data da Reunião de Abertura:&nbsp;
                {formatDate(ofic.date)}
              </h2>
            </ComponentContent>
          ))}
        </ComponentContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default Oficinas;
