import React from 'react';

import { Container, Content } from 'styles/wrappers';
import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { TOVService, Schedule } from '../styles';

const manualjovem = `${process.env.REACT_APP_ASSETS_DIR}/materiais/6b7c70e4-d46a-40d7-8d45-008ae080cd7f.png`;
const scheduleyouth = `${process.env.REACT_APP_ASSETS_DIR}/tovServices/scheduleyouth.jpg`;

const Jovens: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida para Jovens</div>
        </AlteredHeader>
        <TOVService>
          <h1>Manual TOV Jovem</h1>
          <div>
            <span>
              <p>
                TOV Jovens Dando continuidade ao anúncio vibrante da
                personalidade inesgotável de Jesus Cristo, esta Oficina pretende
                que os jovens oficinistas fiquem fascinados por Nosso Senhor
                Jesus Cristo. E, a partir de então, que se sintam decididos a
                pisar Suas pegadas, a serem Seus discípulos e testemunhas,
                convertendo-se em uma fotografia Sua no mundo, com Seus mesmos
                sentimentos, comportando-se como Ele e servindo aos seus
                semelhantes.
              </p>
            </span>
            <img src={manualjovem} alt="Manual TOV Jovem" />
          </div>
          <span>
            <h4>
              <strong>TOV Jovens </strong>{' '}
              <p>| Manual &quot;Em Busca de um Tesouro&quot;</p>
            </h4>
            <h4>
              <strong>Participantes:</strong>
              <p>Jovens com idade a partir de 18 anos.</p>
            </h4>
            <h4>
              <strong>Duração:</strong>
              <p>
                Dez sessões, uma por semana, com duração de uma hora e quarenta
                e cinco minutos cada.
              </p>
            </h4>
          </span>
        </TOVService>
        <Schedule>
          <h2>Programação da Oficina TOV Jovem</h2>
          <img src={scheduleyouth} alt="Programação Tov Jovem" />
        </Schedule>
      </Content>
      <Footer />
    </Container>
  );
};

export default Jovens;
