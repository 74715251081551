import styled from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;

  margin: 10px 0 0 0;

  width: 100vw;
  max-width: 350px;
`;

export const InputWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  border-radius: 5px;

  height: 40px;

  padding: 10px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  background: #fff;

  input {
    padding: 0 10px;
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 30px;

    border: 0;
    border-radius: 0 5px 5px 0;
    background: #efefef;

    transition: background-color 0.35s ease;

    svg {
      height: 17.5px;
      width: 17.5px;
    }

    &:hover {
      background-color: ${shade(0.1, '#efefef')};
    }
  }
`;

export const ItemsWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  z-index: 1;

  overflow-y: scroll;

  background: #fff;
  margin: 5px 0 0 0;
  padding: 5px 0 5px 0;

  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    display: flex;

    button {
      font-size: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding: 0px 5px;
      border: 0px;
      background: transparent;
      font-weight: 500;

      transition: all 0.35s ease;
      &:hover {
        color: #fff;
        font-weight: 500;
        background: #8a0002;
      }
    }
    & + span {
      margin: 3px 0 0 0;
    }
  }
`;
