import DEV from 'pages/DEV';

import Semestrais from 'pages/sgo/Gerencial/Semestrais/main';

import Planejamento from 'pages/sgo/Gerencial/Planejamento/main';

import TransfGuias from 'pages/sgo/Gerencial/Transfer/main';
import TransfGuiasInsert from 'pages/sgo/Gerencial/Transfer/insert';
import TransfGuiasMore from 'pages/sgo/Gerencial/Transfer/more';

import Aniversariantes from 'pages/sgo/Gerencial/Aniversariantes';

import Convocacao from 'pages/sgo/Gerencial/Convocacao/main';
import ConvocacaoInsert from 'pages/sgo/Gerencial/Convocacao/insert';
import ConvocacaoUpdate from 'pages/sgo/Gerencial/Convocacao/update';

import ConvocacaoAbsent from 'pages/sgo/Gerencial/Convocacao/absent';
import ConvocacaoPoll from 'pages/sgo/Gerencial/Convocacao/poll';

import Presenca from 'pages/sgo/Gerencial/Presenca/main';

import TetoDisponibilidade from 'pages/sgo/Gerencial/TetoDisponibilidade/main';

import RFE from 'pages/sgo/Gerencial/RelatorioFinanceiroEventos/main';
import RFECon from 'pages/sgo/Gerencial/RelatorioFinanceiroEventos/more';
import RFEInsert from 'pages/sgo/Gerencial/RelatorioFinanceiroEventos/insert';
import RFEUpdate from 'pages/sgo/Gerencial/RelatorioFinanceiroEventos/update';

import Excedente from 'pages/sgo/Gerencial/Excedente/main';
import Multiplicacao from 'pages/sgo/Gerencial/Multiplicacao';

import GuiasBrasil from 'pages/sgo/Gerencial/GuiasBrasil';
import MateriaisCertificados from 'pages/sgo/Gerencial/MateriaisCertificados';

import Guias from 'pages/sgo/Secretaria/Guias/main';

import DigitalMedia from 'pages/sgo/Materiais/DigitalMedia/main';
import DigitalMediaContent from 'pages/sgo/Materiais/DigitalMedia/content';
import Documents from 'pages/sgo/Secretaria/Documentos/main';
import ManageDocuments from 'pages/sgo/Gerencial/GestaoDocumentos/main';

const GRoutes = [
  { path: '/semestrais', isPrivate: true, component: Semestrais },
  { path: '/planejamento', isPrivate: true, component: Planejamento },
  { path: '/transferencia', isPrivate: true, component: TransfGuias },
  {
    path: '/transferencia/insert',
    isPrivate: true,
    component: TransfGuiasInsert,
  },
  { path: '/transferencia/:hash', isPrivate: true, component: TransfGuiasMore },
  { path: '/aniversariantes', isPrivate: true, component: Aniversariantes },
  { path: '/convocacao', isPrivate: true, component: Convocacao },
  { path: '/convocacao/insert', isPrivate: true, component: ConvocacaoInsert },
  { path: '/convocacao/update', isPrivate: true, component: ConvocacaoUpdate },
  {
    path: '/convocacao/ausencias',
    isPrivate: true,
    component: ConvocacaoAbsent,
  },
  {
    path: '/convocacao/apuracao',
    isPrivate: true,
    component: ConvocacaoPoll,
  },
  { path: '/presencaretiroanual', isPrivate: true, component: Presenca },
  {
    path: '/excedente',
    isPrivate: true,
    component: Excedente,
  },
  {
    path: '/tetodisponibilidade',
    isPrivate: true,
    component: TetoDisponibilidade,
  },
  {
    path: '/financeiroeventos',
    isPrivate: true,
    // component: DEV,
    component: RFE,
  },
  {
    path: '/financeiroeventos/more',
    isPrivate: true,
    // component: DEV,
    component: RFECon,
  },
  {
    path: '/financeiroeventos/insert',
    isPrivate: true,
    // component: DEV,
    component: RFEInsert,
  },
  {
    path: '/financeiroeventos/update',
    isPrivate: true,
    // component: DEV,
    component: RFEUpdate,
  },
  {
    path: '/multiplicacao',
    isPrivate: true,
    component: Multiplicacao,
  },
  {
    path: '/guias-brasil',
    isPrivate: true,
    component: GuiasBrasil,
  },
  {
    path: '/materiais-certificados',
    isPrivate: true,
    component: MateriaisCertificados,
  },
  {
    path: '/guias',
    isPrivate: true,
    component: Guias,
  },
  { path: `/digital-content`, isPrivate: true, component: DigitalMedia },
  {
    path: `/digital-content/content`,
    isPrivate: true,
    component: DigitalMediaContent,
  },
  { path: '/documentos-digitais', isPrivate: true, component: Documents },
  { path: '/gestao-documentos', isPrivate: true, component: ManageDocuments },
  // { path: '/informes', isPrivate: true, component: DEV,},
  // { path: '/estatsec', isPrivate: true, component: DEV,},
  // { path: '/indicadores', isPrivate: true, component: DEV,},
  { path: '/informe_f2', isPrivate: true, component: DEV },
  { path: '/indicacao', isPrivate: true, component: DEV },
];

export default GRoutes;
