import React, { useEffect } from 'react';

// import { FaSkype, FaEnvelope, FaWhatsappSquare } from 'react-icons/fa';
import Header from 'components/Header';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { useHistory } from 'react-router-dom';

import {
  Container,
  // Content,
  // Contact,
  // TextContent,
  // WhatsApps,
  // Email,
  // Skype,
} from './styles';

const Suporte: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace('/');
  }, [history]);
  return (
    <Container>
      <Header web />
      <Navbar web />
      {/* <Content>
        <Contact>
          <WhatsApps>
            <h3>Clique abaixo ou adicione aos seus contatos:</h3>
            <span>
              <a
                href="https://api.whatsapp.com/send?phone=+393475987431"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaWhatsappSquare /> <h4>+39 347 5987431</h4>
                <h4>Arthur</h4>
              </a>
            </span>
          </WhatsApps>
          <Skype>
            <h3>... se preferir temos a opção de contato via Skype:</h3>
            <span>
              <a
                href="skype:live:arthur_4192?call"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaSkype /> <h4>Arthur</h4>
              </a>
            </span>
          </Skype>
          <Email>
            <h3>... em ultimo caso, contate-nos via email:</h3>
            <span>
              <a
                href="mailto:suporte@tovbrasil.com.br?subject=[TOV BRASIL] - Mensagem do Site"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaEnvelope /> <h4>suporte@tovbrasil.com.br</h4>
              </a>
              <p>ou</p>
              <a
                href="mailto:suporte@pazinatoti.com.br?subject=[TOV BRASIL] - Mensagem do Site"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaEnvelope /> <h4>suporte@pazinatoti.com.br</h4>
              </a>
            </span>
          </Email>
        </Contact>
        <TextContent>
          <p>
            A <strong>TOV ZONA BRASIL</strong> concretizou um antigo sonho dessa
            equipe em face ao grande volume de informações e a necessidade de
            que as mesmas cheguem ao destino de maneira rápida, segura e eficaz.
            Será um instrumento que levará conhecimento tanto para os Guias da
            família das Oficinas de Oração e Vida quanto para toda a comunidade.
          </p>
          <p>
            Nosso País tem dimensões continentais e a cada semestre
            experimentamos grandes e novas dificuldades tanto na divulgação de
            informações, quanto na sua recepção e estaremos utilizando a
            tecnologia a nosso favor para que atinjamos todas as equipes que
            atuam para as Oficinas de Oração e Vida de forma uniforme e ágil.
          </p>
          <p>
            Pedimos paciência a todos com relação a eventuais problemas e
            contamos com a compreensão, uma vez que estamos diante da maior
            reformulação já realizada em uma gestão. A eficácia desse
            instrumento dependerá do empenho e da oração de todos. Deus nos
            abençoe para que tudo seja feito conforme a sua vontade e para o bem
            da Associação TOV.
          </p>
        </TextContent>
      </Content> */}

      <Footer />
    </Container>
  );
};

export default Suporte;
