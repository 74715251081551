import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  min-height: calc(100vh - 330px);

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;

export const Table = styled.table`
  width: 100vw;
  /* table-layout: fixed; */
  /* word-wrap: break-word; */
  border-collapse: collapse;
  border: 2px solid #332e2e;
  border-width: 2px 0px;
  box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.5);
  thead {
    background: #332e2e;
    color: #fff;
  }
  tr {
    font-size: 16px;
    vertical-align: middle;
    height: 30px;
  }
  th {
    text-align: left;
  }
  th,
  td {
    padding: 3px 4px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    text-align: center;
  }

  td:nth-last-child(1),
  th:nth-last-child(1) {
    text-align: left;
  }

  td:nth-child(3),
  td:nth-child(4),
  td:nth-last-child(1),
  td:nth-last-child(2) {
    white-space: nowrap;
  }

  a {
    text-decoration: none;
    color: #767676;

    transition: all 0.35s;

    svg {
      margin: 0 5px 0 0;
      transition: all 0.35s;
    }

    &:hover {
      svg {
        color: #ff0000;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    th:nth-child(3),
    th:nth-child(4),
    td:nth-child(3),
    td:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 930px) {
    tr {
      font-size: 14px;
    }

    td:nth-last-child(1),
    td:nth-last-child(2) {
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;
