import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import InputDialog from 'components/InputDialog';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useHistory } from 'react-router-dom';

import api from 'services/api';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { formatDate, handleTimeZone } from 'utils/formatDate';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import { addDays, addYears, parseISO, subDays } from 'date-fns';

import {
  Wrapper,
  RelatedLocalsGrid,
  GridItemLabel,
  WarnMessageContainer,
  WarnMessageHeader,
  WarnMessage,
} from './styles';

interface FormData {
  coord: string;
  ini: string;
  end: string;
  coordref: string;
}

interface LocalReferenceProps extends ComboLocalProps {
  exists: boolean;
}

const ConvocacaoInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();
  const { goBack } = useHistory();

  const [coord] = useState('Selecione');
  const [future, setFuture] = useState(false);
  const [localRefs, setLocalRefs] = useState<LocalReferenceProps[]>([]);
  const [relatedLocals, setRelatedLocals] = useState<LocalReferenceProps[]>([]);
  const [chosenLocals, setChosenLocals] = useState<string[]>([]);
  // const [coord, setCoord] = useState(() =>
  //   user.perfil === 'ZON' ? user.zoncod : 'Selecione',
  // );
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [existent, setExistent] = useState<string[]>([]);

  const getExistent = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/eleicao_conv_existent.php');

      setExistent(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    let filtered = response.data.filter(
      (item: ComboProps) =>
        item.value.substr(0, 2) === user.zoncod.substr(0, 2),
    );

    filtered = [
      ...filtered,
      { value: 'future', label: 'Nacional p/ Multiplicação' },
    ];
    // setComboNacs([{ value: user.zoncod, label: user.zondesc }, ...filtered]);
    setComboNacs(filtered);
    setLoading(false);
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    let combo = 'comboLOCs';
    if (user.perfil === 'ZON') {
      combo = 'comboLOCxRefConvocacao';
    }
    const response = await api.get(
      `/combos/${combo}.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    if (user.perfil === 'ZON') {
      setLocalRefs(response.data);
      return;
    }
    setComboLocs(
      response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
    );
  }, [user.anbc, user.perfil]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON', 'INT'], true);
    getExistent();
    if (user.perfil === 'INT') {
      getComboZON();
    }
    if (user.perfil === 'ZON') {
      getComboANB();
    }
    getComboLOC();
  }, [
    getComboANB,
    getComboLOC,
    getComboZON,
    getExistent,
    handlePermission,
    user.perfil,
  ]);

  const handleCoordChange = useCallback(() => {
    if (user.perfil !== 'ZON') {
      return;
    }

    const select = formRef.current?.getFieldRef('coord');
    const { value } = select.options[select.selectedIndex];

    setFuture(value === 'future');
  }, [user.perfil]);

  const handleCoordRefChange = useCallback(() => {
    if (user.perfil !== 'ZON') {
      return;
    }

    const select = formRef.current?.getFieldRef('coordref');
    const { value } = select.options[select.selectedIndex];

    const filtered = localRefs
      .filter((item: ComboLocalProps) => item.anb === value)
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

    setRelatedLocals(filtered);
    setChosenLocals([]);
  }, [localRefs, user.perfil]);

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event?.currentTarget;

      if (input) {
        setChosenLocals((state) =>
          input.checked
            ? [...state, input.id].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
            : state.filter((item) => item !== input.id),
        );
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        // const minINIDate = new Date(parseISO(new Date().toISOString()));
        const minINIDate = handleTimeZone(new Date());
        const maxINIDate = addYears(minINIDate, 1);
        const minDays = 5;
        // const minDays = data.coord.substr(2, 2) !== '99' ? 5 : 15;
        const maxDays = data.coord.substr(2, 2) !== '99' ? 20 : 90;

        const minENDDate = addDays(
          new Date(
            parseISO(
              formRef.current?.getFieldValue('ini') || new Date().toISOString(),
            ),
          ),
          minDays,
        );
        const maxENDDate = addDays(
          new Date(
            parseISO(
              formRef.current?.getFieldValue('ini') || new Date().toISOString(),
            ),
          ),
          maxDays,
        );

        const schema = yup.object().shape({
          coord: yup.string().notOneOf(['Selecione', '']),
          ini: yup
            .date()
            .typeError('Data inválida.')
            .min(
              subDays(minINIDate, 1),
              `Data de início deve ser posterior à ${formatDate(
                subDays(minINIDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxINIDate,
              `Data de início deve ser anterior à ${formatDate(
                addDays(maxINIDate, 1).toISOString(),
              )}`,
            ),
          end: yup
            .date()
            .typeError('Data inválida')
            .min(
              minENDDate,
              `Data de término deve ser posterior à ${formatDate(
                subDays(minENDDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxENDDate,
              `Data de término deve ser anterior à ${formatDate(
                addDays(maxENDDate, 1).toISOString(),
              )}`,
            ),
          coordref: yup.string().when('coord', {
            is: (val) => val === 'future',
            then: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const select = formRef.current?.getFieldRef('coord');
        const { value } = select.options[select.selectedIndex];

        if (value === 'future' && chosenLocals.length === 0) {
          addToast({
            type: 'error',
            title: 'Atenção',
            description: 'Ao menos uma local deve ser selecionada.',
          });
          return;
        }

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            chosen: chosenLocals,
          }),
        );

        await api.post('/sgo/eleicao_conv_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Convocação registrada.',
        });

        goBack();

        // setLoading(false);
      } catch (err) {
        // setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, chosenLocals, errorHandling, goBack],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro de Convocação" />
      <Content>
        <WarnMessageContainer>
          <WarnMessageHeader>Atenção</WarnMessageHeader>
          <WarnMessage>
            <p>
              Caso não encontre a coordenação desejada, certifique-se de que
              todas as convocações para aquela coordenação estejam devidamente
              encerradas.
            </p>
          </WarnMessage>
        </WarnMessageContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <div>
              <span>
                <p>Selecione a coordenação</p>
                <SelectV2
                  name="coord"
                  initial={coord}
                  // initial="future"
                  content={(user.perfil === 'INT'
                    ? comboZons
                    : user.perfil === 'ZON'
                    ? comboNacs
                    : comboLocs
                  ).filter(
                    (item: ComboProps) => existent.indexOf(item.value) < 0,
                  )}
                  onChange={handleCoordChange}
                />
              </span>
            </div>
            <div>
              <span>
                <p>Início da Eleição/Indicação</p>
                <InputDialog
                  onClick={() => formRef.current?.setFieldError('ini', '')}
                  name="ini"
                  containerStyle={{
                    width: '172.5px',
                    padding: '5px 2px 4.82px 5px',
                  }}
                  inputStyle={{
                    width: '110px',
                  }}
                  isDate
                  placeholder="dd/mm/aaaa"
                />
              </span>
              <span>
                <p>Encerramento da Eleição/Indicação</p>
                <InputDialog
                  onClick={() => formRef.current?.setFieldError('end', '')}
                  name="end"
                  containerStyle={{
                    width: '172.5px',
                    padding: '5px 2px 4.82px 5px',
                  }}
                  inputStyle={{
                    width: '110px',
                  }}
                  isDate
                  placeholder="dd/mm/aaaa"
                />
              </span>
            </div>
            {future && user.perfil === 'ZON' ? (
              <>
                <div>
                  <span>
                    <p>Selecione a coordenação de origem</p>
                    <SelectV2
                      name="coordref"
                      initial="Selecione"
                      content={comboNacs
                        .filter((item) => item.value !== 'future')
                        .filter(
                          (item: ComboProps) =>
                            existent.indexOf(item.value) < 0,
                        )}
                      onChange={handleCoordRefChange}
                    />
                  </span>
                </div>

                {relatedLocals.length > 0 ? (
                  <>
                    <p style={{ margin: '15px 0 0 0' }}>Coordenações Locais:</p>
                    <div>
                      <RelatedLocalsGrid>
                        {relatedLocals.map((item) => (
                          <div key={`__${item.value}`}>
                            <GridItemLabel
                              marked={
                                item.exists ||
                                chosenLocals.indexOf(item.value) >= 0
                              }
                              disabled={item.exists}
                              htmlFor={item.value}
                            >
                              <input
                                id={item.value}
                                type="checkbox"
                                defaultChecked={item.exists}
                                disabled={item.exists}
                                onChange={(event) =>
                                  handleCheckboxChange(event)
                                }
                              />
                              <strong>{item.label}</strong>
                            </GridItemLabel>
                          </div>
                        ))}
                      </RelatedLocalsGrid>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </Wrapper>
          <Button
            type="submit"
            bgcolor="#00802b"
            containerStyle={{ margin: '15px 0 25px' }}
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ConvocacaoInsert;
