import { shade, lighten } from 'polished';
import styled from 'styled-components';

export const ProfileChangeContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* width: 90vw; */

  max-width: 1000px;
  margin-top: 15px;
  /* margin: auto 0; */

  form {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 20px 30px; */
    background: #efefef;

    width: 100vw;
    max-width: 450px;

    border: 2px solid transparent;
    border-radius: 10px;

    > button {
      margin: 15px 0;
    }
  }
`;

export const ProfileChangeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    font-size: 18.72px;
    color: #8a0002;
    margin: 5px 0;
  }

  & + div {
    margin-top: 20px;
  }
`;

export const PHPContainer = styled.div`
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  margin: 25px 0 0 0;
  display: flex;
  align-items: center;

  width: 250px;

  background: #8993be;
  border-radius: 5px;

  transition: background-color 0.35s ease;

  a {
    width: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    svg {
      color: #232531;
      width: 45px;
      height: 45px;

      transition: color 0.35s ease;
    }
  }

  &:hover {
    background: ${shade(0.2, '#8993be')};

    a {
      svg {
        color: ${lighten(0.2, '#8993be')};
      }
    }
  }
`;
