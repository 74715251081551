import React, { useState, useEffect } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import ScrollTop from 'components/ScrollTop';
import ImageGenerator from 'components/ImageGenerator';

import { Container, Content } from 'styles/sgo_wrappers';

import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import { formatDate } from 'utils/formatDate';
import { format } from 'date-fns';

import { OptionsProps, VotedProps } from '../../vote';

import {
  Preview,
  Header,
  PreviewBody,
  Message,
  Options,
  Warn,
} from '../../vote/styles';

const EleicaoCompItem: React.FC = () => {
  const location = useLocation<VotedProps>();
  const { user } = useAuth();

  const [vote] = useState(() => ({ ...location.state }));

  const [options, setOptions] = useState<OptionsProps[]>([]);

  useEffect(() => {
    setOptions(vote.options);
  }, [vote.options]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Comprovante" />
      <Content>
        <ImageGenerator
          containerStyle={{ margin: '15px 0' }}
          buttonColor="#332e2e"
          buttonText="Gerar Comprovante"
          imageName={vote.voteID}
        >
          <Preview>
            <Header>
              <p>SGO - Comprovante de Eleição / Consulta Prévia</p>
            </Header>
            <PreviewBody>
              <span>
                <p>Guia:</p>
                <strong>{user.name}</strong>
              </span>
              <span>
                <p>Coordenação:</p>
                <strong>{user.locdesc}</strong>
              </span>
            </PreviewBody>
            <Message>
              <span>
                <p style={{ width: '100%' }}>
                  Atendeu a convocação para Eleição / Consulta Prévia do
                  período:
                </p>
                <strong>
                  {formatDate(vote.ini)} a {formatDate(vote.end)}
                </strong>
              </span>
            </Message>
            <Message>
              <span>
                <p style={{ width: '100%' }}>
                  Para coordenação{' '}
                  {vote.coor === 'Z'
                    ? 'zonal'
                    : vote.coor === 'N'
                    ? 'nacional'
                    : 'local'}
                  :&nbsp;
                  <strong>
                    {vote.coor === 'Z'
                      ? vote.zondesc
                      : vote.coor === 'N'
                      ? vote.anbdesc
                      : vote.locdesc}
                  </strong>
                </p>
              </span>
            </Message>

            <Options>
              {options.map((item, index) => (
                <div>
                  <span>
                    <p>
                      {index === 0 && vote.coor !== 'N'
                        ? 'Primeira o'
                        : vote.coor !== 'N'
                        ? 'Segunda o'
                        : 'O'}
                      pção:
                    </p>
                  </span>
                  <span>
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p>
                      Coord. Nacional: <strong>{item.anb}</strong>
                    </p>
                    <p>
                      Coord. Local: <strong>{item.loc}</strong>
                    </p>
                  </span>
                </div>
              ))}
            </Options>
            <Message>
              <span style={{ width: '100%' }}>
                <p>Votação realizada em:</p>
                <strong>
                  {format(new Date(vote.voted_at), 'dd/MM/yyyy HH:mm:ss')}
                </strong>
              </span>
            </Message>
            <Warn>
              <strong>
                Importante: Esse comprovante poderá ser impresso mas deverá ser
                mantido sob total sigilo. Não poderá ser divulgado e nem
                apresentado a ninguém salvo em caso de auditoria. Sua(s)
                escolhas estão armazenadas no SGO de maneira segura e
                inviolável.
              </strong>
            </Warn>
          </Preview>
        </ImageGenerator>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EleicaoCompItem;
