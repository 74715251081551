import styled from 'styled-components';
import { shade } from 'polished';

export const InfoWrapper = styled.div`
  h2 {
    margin: 0;
  }

  & + div {
    margin: 15px 0 0 0;
  }
`;

export const TableWrapper = styled.div`
  border: 5px solid #332e2e;
  border-radius: 5px;
  display: block;
  width: 1360px;
  overflow-x: auto;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  table {
    background: #fff;
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    tr {
      background: #332e2e;
      color: #fff;
    }
  }
  tbody {
    tr {
      background: #efefef;
      color: #464646;

      &:nth-child(even) {
        background: #fff9c4;
      }
      &:last-child {
        border-top: 3px solid #332e2e;
        background: ${shade(0.15, '#FFF9C4')};
      }

      &:hover {
        background: #ffee58;
        &:last-child {
          background: ${shade(0.15, '#FFF9C4')};
        }
      }
      transition: all 0.35s;
    }
  }
  th {
    font-weight: bold;
  }

  td,
  th {
    text-align: center;
    background-color: inherit;

    padding: 8px;
    min-width: 150px;

    &:nth-child(1),
    &:nth-child(2) {
      position: sticky;
      left: 0;
      z-index: 1;
      font-weight: bold;
    }
    &:nth-child(1) {
      left: 0px;
    }
    &:nth-child(2) {
      left: 150px;
      min-width: 110px;
      border-right-width: 1px;
    }
  }

  td {
    &:nth-child(1) {
      text-align: left;
    }
  }

  ::-webkit-scrollbar {
    height: 8px;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${shade(0.15, '#FFF9C4')};
  }

  ::-webkit-scrollbar-thumb {
    background: #464646;

    &:hover {
      background: #767676;
    }
  }

  @media screen and (max-width: 1366px) {
    width: 100vw;
  }
`;
