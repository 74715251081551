import styled, { css } from 'styled-components';

interface GridProps {
  mode?: string;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;
  justify-content: center;
  align-content: center;

  ${(props) =>
    props.mode &&
    ['EP', 'SU'].indexOf(props.mode) > -1 &&
    css`
      grid: auto / 1fr;
      > div {
        max-width: 475px;
      }
    `}

  @media screen and (max-width: 800px) {
    column-gap: 20px;
    row-gap: 25px;
  }
  @media screen and (max-width: 780px) {
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  position: relative;
  padding: 10px 5px;

  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: all 0.35s;

  > div {
    display: flex;

    > span {
      p {
        font-size: 16px;
        color: #8a0002;
        font-weight: 500;
      }

      & + span {
        margin: 0 0 0 5px;
      }
    }

    & + div {
      margin-top: 10px;
    }
  }

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 1000px) {
    > div {
      flex-direction: column;

      > span + span {
        margin: 10px 0 0 0;
      }
    }
  }
  @media screen and (max-width: 380px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;
