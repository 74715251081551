import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;

  margin: 10px 0 0 0;
  padding: 25px 10px 10px 10px;
  width: 100vw;

  @media screen and (max-width: 1366px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr;
    max-width: 650px;
    padding: 25px 0px 10px 0px;
  }
  @media screen and (max-width: 660px) {
    grid: auto / 1fr;
    max-width: 360px;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 5px 10px;
  justify-content: flex-start;
  /* align-items: center; */
  background: #fff;

  position: relative;

  padding-bottom: 10px;

  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  a {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 5px;

    text-decoration: none;
    svg {
      color: #332e2e;
      height: 25px;
      width: 25px;
    }
  }

  span {
    p {
      font-size: 16px;
      color: #767676;
      strong {
        color: #332e2e;
        margin: 0 0 0 5px;
      }
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }

  transition: all 0.35s;

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 360px) {
    border-right-width: 0px;
    border-left-width: 0px;
    border-radius: 0px;
  }
`;
