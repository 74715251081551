import React, { useState, useEffect, useCallback } from 'react';

import { useCredentials } from 'hooks/credentials';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import IncludeButton from 'components/IncludeButton';

import { Link, useLocation } from 'react-router-dom';

import api from 'services/api';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { FaPencilAlt } from 'react-icons/fa';

import { sortIt } from 'utils/specialChars';
import { Section } from './styles';

export interface ListProps {
  cod: string;
  desc: string;
  type: string;
  status: boolean;
}

const ReceitasDespesas: React.FC = () => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { handlePermission, errorHandling } = useCredentials();

  const [incomes, setIncomes] = useState<ListProps[]>([]);
  const [outcomes, setOutcomes] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/recdespeventos.php');
      const income: ListProps[] = [];
      const outcome: ListProps[] = [];
      response.data.forEach((item: ListProps) => {
        item.type === 'D' ? outcome.push(item) : income.push(item);
      });

      setIncomes(sortIt(income, 'desc'));
      setOutcomes(sortIt(outcome, 'desc'));
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getList();
  }, [getList, handlePermission]);

  return (
    <Container>
      <IncludeButton />

      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Receitas/Despesas para Relatório Financeiro de Eventos</p>
          </div>
        </AlteredHeader>

        <Section>
          <AlteredHeader style={{ maxWidth: '360px' }}>Receitas</AlteredHeader>
          <div>
            {incomes.map((item) => (
              <div
                key={item.cod}
                style={{ borderColor: item.status ? '#00802b' : '#c53030' }}
              >
                <span>
                  <h4>{item.desc}</h4>
                </span>
                <span>
                  <p>
                    Status:&nbsp;
                    <strong
                      style={{ color: item.status ? '#00802b' : '#c53030' }}
                    >
                      {item.status ? 'Ativa' : 'Desativada'}
                    </strong>
                  </p>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: `${pathname}/update`,
                      state: { ...item },
                    }}
                  >
                    <FaPencilAlt />
                  </Link>
                </span>
              </div>
            ))}
          </div>
        </Section>
        <Section>
          <AlteredHeader style={{ maxWidth: '360px' }}>Despesas</AlteredHeader>
          <div>
            {outcomes.map((item) => (
              <div
                key={item.cod}
                style={{ borderColor: item.status ? '#00802b' : '#c53030' }}
              >
                <span>
                  <h4>{item.desc}</h4>
                </span>
                <span>
                  <p>
                    Status:&nbsp;
                    <strong
                      style={{ color: item.status ? '#00802b' : '#c53030' }}
                    >
                      {item.status ? 'Ativa' : 'Desativada'}
                    </strong>
                  </p>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: `${pathname}/update`,
                      state: { ...item },
                    }}
                  >
                    <FaPencilAlt />
                  </Link>
                </span>
              </div>
            ))}
          </div>
        </Section>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default ReceitasDespesas;
