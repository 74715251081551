import React, { useState, useRef, useCallback, useEffect } from 'react';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
// import IncludeButton from 'components/IncludeButton';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import {
  Container,
  AlteredContent,
  Header,
  CoordProps,
  ComboProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';
import { FaSearchPlus, FaTimes } from 'react-icons/fa';
import { formatDate } from 'utils/formatDate';
import { useWindow } from 'hooks/window';
import { useSpring } from 'react-spring';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { ListContent, Message } from './styles';

interface LocalStorageProps {
  zoncod?: string;
  zondesc?: string;
  anbcod?: string;
  anbdesc?: string;
  loccod?: string;
  locdesc?: string;
  cord?: CoordProps;
}

interface ComboMat {
  value: string;
  label: string;
  matcntr: string;
}

interface ListProps {
  loccod: string;
  matcod: string;
  numero: string;
  user: string;
  dtmanut: string;
  matdesc: string;
  locdesc: string;
  status: boolean;
  acervo: boolean;
  dtbaixa: string;
  resp: string;
  motivo: string;
  anb: string;
  numloc: string;
  numlocdesc: string;
  codret: string;
  crdesc: string;
}

const CntrNumOOC: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();

  const { width } = useWindow();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState<ShowProps>({
    title: 'Motivo',
    open: false,
    content: '',
  });

  const [initialMat, setInitialMat] = useState('Selecione');
  const [comboMats, setComboMats] = useState<ComboProps[]>([]);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/cntrnum_list.php?data=${JSON.stringify({
          code: user.zoncod,
          mode: 'ooc',
        })}`,
      );
      setRawList(response.data);
      let filtered = response.data;

      const mat = formRef.current?.getFieldValue('comboMaterial');
      if (mat) {
        filtered = response.data.filter(
          (item: ListProps) => item.matcod === mat,
        );
      }

      setList(filtered);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, user.zoncod]);

  const getComboMATS = useCallback(async () => {
    const response = await api.get('/combos/comboMATs.php');
    setComboMats(
      response.data.filter((item: ComboMat) => item.matcntr === 'S'),
    );
  }, []);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getComboMATS();
    getList();
  }, [handlePermission, getComboMATS, getList]);

  const handleMATselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboMaterial');
    const { value } = select.options[select.selectedIndex];

    setList(rawList.filter((item) => item.matcod === value));

    setInitialMat(value);
  }, [rawList]);

  const removeMatFilter = useCallback(() => {
    formRef.current?.setFieldValue('comboMaterial', '');
    setInitialMat('Selecione');
    setList(rawList);
  }, [rawList]);

  const matFilterRemove = useSpring({
    opacity: initialMat !== 'Selecione' ? 1 : 0,
    pointerEvents: initialMat !== 'Selecione' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              <span>
                <p>Filtre por Material:</p>
                <SelectV2
                  name="comboMaterial"
                  content={comboMats}
                  onChange={handleMATselect}
                  initial={initialMat}
                />
                <RemoveButton
                  type="button"
                  onClick={removeMatFilter}
                  style={matFilterRemove}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>Materiais Numerados</p>
          <p>Fora de Circulação</p>
        </div>
      </Header>
      <AlteredContent pixels="252px">
        {!loading && (
          <ListContent>
            {list.length === 0 ? (
              <Message>Sem registros para o material selecionado</Message>
            ) : (
              <table>
                <thead>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      <p>{width >= 470 ? 'Coordenação' : 'Coord.'}</p>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <p>Descrição</p>
                    </td>
                    <td>
                      <p>{width >= 470 ? 'Numeração' : 'Num.'}</p>
                    </td>
                    <td>
                      <p>{width >= 470 ? 'Data da Baixa' : 'Baixa'}</p>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <p>Motivo</p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <React.Fragment key={`${item.matcod}${item.numero}`}>
                      {index !== 0 && index % 10 === 0 && (
                        <tr style={{ background: '#332e2e', color: '#fff' }}>
                          <td style={{ textAlign: 'left' }}>
                            <p>{width >= 470 ? 'Coordenação' : 'Coord.'}</p>
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <p>Descrição</p>
                          </td>
                          <td>
                            <p>{width >= 470 ? 'Numeração' : 'Num.'}</p>
                          </td>
                          <td>
                            <p>{width >= 470 ? 'Data da Baixa' : 'Baixa'}</p>
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <p>Motivo</p>
                          </td>
                        </tr>
                      )}
                      <tr
                        style={{
                          background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                        }}
                      >
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p>{width >= 470 ? item.locdesc : item.loccod}</p>
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p>{item.matdesc}</p>
                        </td>
                        <td>
                          <p>{item.numero}</p>
                        </td>
                        <td>
                          <p>
                            {item.dtbaixa ? formatDate(item.dtbaixa) : 'N/D'}
                          </p>
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          <p>
                            <button
                              type="button"
                              onClick={() =>
                                setShow((state) => ({
                                  ...state,
                                  open: true,
                                  content: (
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <p>
                                        Coordenação:&nbsp;
                                        <strong>{item.locdesc}</strong>
                                      </p>
                                      <p>
                                        Material:&nbsp;
                                        <strong>{item.matdesc}</strong>
                                      </p>
                                      <p>
                                        Numeração:&nbsp;
                                        <strong>{item.numero}</strong>
                                      </p>
                                      <p>
                                        Motivo:&nbsp;
                                        <strong>{item.motivo}</strong>
                                      </p>
                                    </div>
                                  ),
                                }))
                              }
                            >
                              <strong>{item.crdesc}</strong>
                              <FaSearchPlus />
                            </button>
                          </p>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </ListContent>
        )}
      </AlteredContent>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })
            }
          >
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default CntrNumOOC;
