import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import api from 'services/api';
import { UpdateProps } from 'styles/dialog_update';
import * as U from 'styles/dialog_update';
import { IncludeProps } from 'styles/dialog_include';
import * as I from 'styles/dialog_include';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import Checkbox from 'components/Checkbox';
import { useSpring } from 'react-spring';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { ListProps } from '../../Solic/main';

import {
  HeaderContent,
  TotalSolic,
  Grid,
  GridItem,
  SpanMessage,
  DeclareButton,
  DeclareNumbers,
  ButtonFinish,
  Insufficient,
  FaultyDiv,
  OBSRM,
} from './styles';

interface StateProps {
  solicHeader: ListProps;
}

interface RMItemsProps {
  matcod: string;
  matdesc: string;
  cost: number;
  quantity: number;
  stored: number;
  require: boolean;
  numbers: string[];
  selected: string[];
}

const RMSolic: React.FC = () => {
  const phaseOne = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [solicHeader] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.solicHeader;
  });

  const [inc, setInc] = useState<IncludeProps>({
    title: 'Observação da RM',
    open: false,
    content: (
      <OBSRM>
        <textarea id="obs" placeholder="Insira a observação (opcional)" />
      </OBSRM>
    ),
  });

  const [solicItems, setSolicItems] = useState<RMItemsProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState<UpdateProps>({
    open: false,
    content: '',
    values: {},
  });

  const getItems = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/getStorage.php?data=${JSON.stringify({
          trans: 'rmsolic',
          solnum: solicHeader.num,
          loccod: solicHeader.loc,
        })}`,
      );

      setSolicItems(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, solicHeader.loc, solicHeader.num]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const totalSolic = useMemo(() => {
    let total = 0;

    solicItems.forEach((item: RMItemsProps) => {
      total += item.quantity * item.cost;
    });

    return total;
  }, [solicItems]);

  const handleDeclareClick = useCallback(
    (matcod: string, number: string) => {
      const index = solicItems.findIndex(
        (item: RMItemsProps) => item.matcod === matcod,
      );

      const exists = [...solicItems[index].selected].includes(number);

      solicItems[index].selected = exists
        ? [...solicItems[index].selected].filter(
            (item: string) => item !== number,
          )
        : [...solicItems[index].selected, number];

      setSolicItems((state) => [...state]);
    },
    [solicItems],
  );

  const faulty = useMemo(() => {
    return solicItems.reduce(
      (accum, { quantity, selected, require, stored }: RMItemsProps) => {
        return require
          ? quantity === selected.length
            ? accum
            : accum + 1
          : stored < quantity
          ? accum + 1
          : accum;
      },
      0,
    );
  }, [solicItems]);

  const appear = useSpring({
    opacity: !loading && faulty === 0 ? 1 : 0,
    pointerEvents: !loading && faulty === 0 ? 'all' : 'none',
    transform:
      !loading && faulty === 0 ? 'translateX(5px)' : 'translateX(60px)',
    height: !loading && faulty === 0 ? '35px' : '0px',
    overflow: !loading && faulty === 0 ? 'visible' : 'hidden',
  });

  const faultyAppear = useSpring({
    opacity: !loading && faulty > 0 ? 1 : 0,
    pointerEvents: !loading && faulty > 0 ? 'all' : 'none',
    height: !loading && faulty > 0 ? '30px' : '0px',
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      setInc((state) => ({ ...state, open: !state }));

      let obs = '*** SEM OBSERVAÇÃO ***';
      const retrieve = document.querySelector<HTMLTextAreaElement>(
        'textarea[id="obs"]',
      );

      if (retrieve && retrieve.value.trim() !== '') {
        obs = retrieve.value;
      }

      const data = {
        dest: {
          cod: solicHeader.loc,
          desc: solicHeader.locdesc,
        },
        items: solicItems,
        address: {
          value: solicHeader.addressval,
          label: solicHeader.address.street,
        },
        obs,
        name: user.name,
        anbdesc: user.anbdesc,
        totalRM: parseFloat(totalSolic.toFixed(2)),
        type: 'L',
        from: 'solic',
        solnum: solicHeader.num,
      };

      const send = new FormData();
      send.append('data', JSON.stringify(data));

      const response = await api.post('/sgo/rm_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { rmnum } = response.data;
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Remessa de Material N° ${rmnum} emitida para a Coordenação ${solicHeader.locdesc}.`,
      });

      history.replace(location.pathname.replace('rm/solic', 'solic'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    history,
    location.pathname,
    solicHeader.address.street,
    solicHeader.addressval,
    solicHeader.loc,
    solicHeader.locdesc,
    solicHeader.num,
    solicItems,
    totalSolic,
    user.anbdesc,
    user.name,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title="Verificação de Saldo no Estoque"
        isResponsible
      />
      <Content>
        {!loading && (
          <>
            <HeaderContent>
              <div>
                <span>
                  <p>
                    Solicitação N°:&nbsp;
                    <strong>{solicHeader.num}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Data da Solicitação:&nbsp;
                    <strong>{formatDate(solicHeader.dtsolic)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Coordenação:&nbsp;
                    <strong>{solicHeader.locdesc}</strong>
                  </p>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    Endereço de Entrega:&nbsp;
                    <strong>{solicHeader.address.street}</strong>
                  </p>
                </span>
              </div>
            </HeaderContent>

            <TotalSolic>
              <p>
                Total da Solicitação:{' '}
                <strong>R$ {formatNumber(totalSolic)}</strong>
              </p>
            </TotalSolic>

            <FaultyDiv style={faultyAppear}>
              <p>
                Há <span>{faulty}</span> ite{faulty > 1 ? 'ns' : 'm'} que requer
                {faulty > 1 ? 'em' : ''} sua atenção.
              </p>
            </FaultyDiv>

            <ButtonFinish style={appear}>
              <button
                type="button"
                onClick={() => setInc((state) => ({ ...state, open: true }))}
              >
                Emitir RM <FaAngleDoubleRight />
              </button>
            </ButtonFinish>
            <Form
              ref={phaseOne}
              onSubmit={() => {
                return null;
              }}
            >
              <Grid>
                {solicItems.map((item: RMItemsProps) => (
                  <GridItem
                    key={item.matcod}
                    required
                    color={
                      item.require
                        ? item.selected.length < item.quantity
                          ? '#fda50f'
                          : item.selected.length === item.quantity
                          ? '#267326'
                          : '#c53030'
                        : item.stored < item.quantity
                        ? '#c53030'
                        : '#267326'
                    }
                  >
                    <div>
                      <strong>
                        {item.matcod} - {item.matdesc}
                      </strong>
                    </div>
                    <div>
                      <span>
                        <p>
                          Custo:&nbsp;
                          <strong>R$ {formatNumber(item.cost)}</strong>
                        </p>
                      </span>
                      <span>
                        <p>Quantidade:&nbsp;</p>
                        <strong>{item.quantity}</strong>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Seu estoque atual:&nbsp;
                          <strong>{item.stored}</strong>
                        </p>
                      </span>
                    </div>
                    <div>
                      <span>
                        <p>
                          Total:&nbsp;
                          <strong>
                            R$ {calcTotal(item.quantity, item.cost)}
                          </strong>
                        </p>
                      </span>
                    </div>
                    {item.require && (
                      <>
                        <SpanMessage
                          color={
                            item.selected.length < item.quantity
                              ? '#fda50f'
                              : item.selected.length === item.quantity
                              ? '#267326'
                              : '#c53030'
                          }
                        >
                          <p>
                            {item.selected.length === 0
                              ? 'É necessário selecionar os números controlados'
                              : item.selected.length < item.quantity
                              ? 'Quantidade selecionada é inferior a quantidade apontada'
                              : item.selected.length > item.quantity
                              ? 'Quantidade selecionada excede a quantidade apontada'
                              : ''}
                          </p>
                        </SpanMessage>
                        <div>
                          <span>
                            <DeclareButton
                              type="button"
                              onClick={() =>
                                setUpdateDiag({
                                  open: true,
                                  content: (
                                    <DeclareNumbers>
                                      {item.numbers.map((number: string) => (
                                        <Checkbox
                                          id={`N${number}`}
                                          title={number}
                                          onClick={() =>
                                            handleDeclareClick(
                                              item.matcod,
                                              number,
                                            )
                                          }
                                          checked={[...item.selected].includes(
                                            number,
                                          )}
                                        />
                                      ))}
                                    </DeclareNumbers>
                                  ),
                                  values: {
                                    matcod: item.matcod,
                                    matdesc: item.matdesc,
                                    max: item.quantity,
                                  },
                                })}
                            >
                              Selecionar numeração controlada
                            </DeclareButton>
                          </span>
                        </div>
                      </>
                    )}

                    {item.stored < item.quantity && (
                      <Insufficient>
                        <p>
                          Não há estoque suficiente para atender a solicitação.
                        </p>
                      </Insufficient>
                    )}
                  </GridItem>
                ))}
              </Grid>
            </Form>
          </>
        )}

        <U.Container scroll="paper" maxWidth={false} open={updateDiag.open}>
          <U.Title>
            <h2>{updateDiag.values?.matdesc || 'Material'}</h2>
          </U.Title>
          <U.Content>{updateDiag.content}</U.Content>
          <U.Actions>
            <U.Confirm
              type="button"
              onClick={() => setUpdateDiag({ open: false })}
            >
              Confirmar
            </U.Confirm>
          </U.Actions>
        </U.Container>
        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <I.Title>
            <h2>{inc.title || 'Inclusão'}</h2>
          </I.Title>
          <I.Content>{inc.content}</I.Content>
          <I.Actions>
            <I.Cancel
              type="button"
              onClick={() =>
                setInc({
                  ...inc,
                  open: false,
                })}
            >
              Cancelar
            </I.Cancel>
            <I.Confirm type="button" onClick={handleFinish}>
              Finalizar
            </I.Confirm>
          </I.Actions>
        </I.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default RMSolic;
