import React from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import ScrollTop from 'components/ScrollTop';

import { Link, useLocation } from 'react-router-dom';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { FaRegListAlt } from 'react-icons/fa';
import { Menu, AnimatedDiv } from './styles';

const CronogramaMenu: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Menu de Cronograma Especial</p>
          </div>
        </AlteredHeader>
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '/anual')}`}>
              <FaRegListAlt size={30} />
              <h3>Cronograma Anual (Retiros / EED / Assembléias)</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '/mensal')}`}>
              <FaRegListAlt size={30} />
              <h3>Cronograma Dia Mensal do Guia</h3>
            </Link>
          </AnimatedDiv>
        </Menu>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default CronogramaMenu;
