import React, { useState, useCallback, useEffect, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaDollarSign, FaClipboardList } from 'react-icons/fa';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from 'styles/sgo_wrappers';
import { AnimatedContent } from 'styles/animated';

import SGOHeader from 'components/SGOHeader';
import ScrollTop from 'components/ScrollTop';

import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';

import SelectV2 from 'components/SelectV2';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { convertSpecialChars, deconvertSpecialChars } from 'utils/specialChars';

import api from 'services/api';

import getValidationErrors from 'utils/getValidationErrors';

interface GrupoContent {
  grucod: string;
  grudesc: string;
}

interface ComboContent {
  value: string;
  label: string;
}

interface FormData {
  comboGrupos: string;
  description: string;
  vlrgui: number;
  vlrloc: number;
  vlrnac: number;
  sellable: string;
  controllable: string;
  webdesc: string;
}

const MateriaisInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [comboGrupos, setComboGrupos] = useState<ComboContent[]>([]);
  const history = useHistory();

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();

  const getComboGrupo = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboGrupoMaterial.php?flag=INC');
      const { groups } = response.data;

      const groupCombo: ComboContent[] = groups.map((item: GrupoContent) => {
        return {
          value: item.grucod,
          label: deconvertSpecialChars(item.grudesc),
        };
      });

      setComboGrupos(groupCombo);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getComboGrupo();
  }, [getComboGrupo]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          comboGrupos: yup
            .string()
            .notOneOf(
              ['Selecione o grupo'],
              'Grupo de Material deve ser selecionado',
            ),
          description: yup
            .string()
            .trim()
            .required('Descrição é obrigatória')
            .min(9, 'Quantidade mínima de caracteres não atingida (9)')
            .max(60, 'Quantidade máxima de caracteres excedida (60)'),
          vlrgui: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          vlrloc: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          vlrnac: yup.number().required(),
          // .moreThan(0, 'Valor deve ser superior a 0 (zero)'),
          sellable: yup.string().oneOf(['S', 'N'], 'Este campo é obrigatório'),
          controllable: yup
            .string()
            .oneOf(['S', 'N'], 'Este campo é obrigatório'),
          webdesc: yup
            .string()
            .trim()
            .required('Descrição web é obrigatória')
            .min(30, 'Quantidade mínima de caracteres não atingida (30)')
            .max(650, 'Quantidade máxima de caracteres excedida (650)'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.get(
          `/sgo/material_tov_insert.php?group=${
            data.comboGrupos
          }&desc=${convertSpecialChars(data.description)}&vlrguia=${
            data.vlrgui
          }&vlrloc=${data.vlrloc}&vlrnac=${data.vlrnac}&sell=${
            data.sellable
          }&cntr=${data.controllable}&webdesc=${convertSpecialChars(
            data.webdesc,
          )}`,
        );

        history.replace(`${pathname.replace('/insert', '')}`);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Material TOV incluído com sucesso!',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [history, errorHandling, pathname, addToast],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Novo Material" />
      <AnimatedContent direction="left" needmargin={30}>
        <Form
          ref={formRef}
          initialData={{
            vlrgui: parseFloat('0').toFixed(2),
            vlrloc: parseFloat('0').toFixed(2),
            vlrnac: parseFloat('0').toFixed(2),
          }}
          onSubmit={handleSubmit}
        >
          <p>Grupo de Material</p>
          <SelectV2
            name="comboGrupos"
            initial="Selecione"
            content={comboGrupos}
          />

          <p>Descrição</p>
          <Textarea
            altHeight={85}
            icon={FaClipboardList}
            name="description"
            placeholder="Descrição"
          />
          <p>Valor Nacional</p>
          <Input
            icon={FaDollarSign}
            name="vlrnac"
            placeholder="Valor Nacional"
            type="number"
            step="any"
            mask="currency"
            // onChange={currencyMasking}
          />

          <p>Valor Local</p>
          <Input
            icon={FaDollarSign}
            name="vlrloc"
            placeholder="Valor Local"
            type="number"
            step="any"
            mask="currency"
          />

          <p>Valor Guia</p>
          <Input
            icon={FaDollarSign}
            name="vlrgui"
            placeholder="Valor Guia"
            type="number"
            step="any"
            mask="currency"
          />

          <RadioContainer
            title="Numeração Controlada?"
            name="controllable"
            content={[
              { id: 'cS', value: 'S', label: 'Sim' },
              { id: 'cN', value: 'N', label: 'Não' },
            ]}
          />

          <RadioContainer
            title="Comercializável?"
            name="sellable"
            content={[
              { id: 'sS', value: 'S', label: 'Sim' },
              { id: 'sN', value: 'N', label: 'Não' },
            ]}
          />

          <p>Descrição WEB</p>
          <Textarea icon={FaClipboardList} name="webdesc" />

          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ marginBottom: '10px' }}
          >
            Cadastrar
          </Button>
        </Form>
      </AnimatedContent>
      <SGOFooter />
    </Container>
  );
};

export default MateriaisInsert;
