import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSpring } from 'react-spring';

import api from 'services/api';
import * as yup from 'yup';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaDollarSign } from 'react-icons/fa';
import getValidationErrors from 'utils/getValidationErrors';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { monthList, MonthListProps } from '../../monthList';

import { RadioProps } from '../insert';
import { CoordSpan, FormContent } from '../insert/styles';

interface StateProps {
  year: number;
  month: number;
  reg: number;
  mov: string;
  document: string;
  description: string;
  value: number;
  contrapart: string | null;
  contracod: string;
}

interface FormData {
  seq: number;
  year: string;
  month: string;
  contrapart: string;
  coord: string;
  mov: string;
  document: string;
  description: string;
  value: string;
}

const CPRUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const [seq] = useState(() => {
    if (!location.state) {
      return 0;
    }
    return location.state.reg;
  });
  const [months] = useState(() => {
    return monthList.map((item: MonthListProps) => ({
      label: item.label,
      value: `0${item.value}`.slice(-2),
    }));
  });
  const [mov] = useState(() => location.state.mov);
  const [document] = useState(() => location.state.document);
  const [description] = useState(() => location.state.description);
  const [value] = useState(() => location.state.value);
  const [year] = useState(() => location.state.year);
  const [month] = useState(() => location.state.month);
  const [contrapart] = useState(() => location.state.contrapart);
  const [contracod] = useState(() => location.state.contracod);
  const [initialMonth] = useState(month.toString().replace(/^0+/, ''));
  const [initialYear] = useState(year);
  const [initialCoord, setInitialCoord] = useState('Selecione');

  const currYear = new Date().getUTCFullYear();
  const [years] = useState<ComboProps[]>([
    { value: `${currYear + 3}`, label: `${currYear + 3}` },
    { value: `${currYear + 2}`, label: `${currYear + 2}` },
    { value: `${currYear + 1}`, label: `${currYear + 1}` },
    { value: `${currYear}`, label: `${currYear}` },
    { value: `${currYear - 1}`, label: `${currYear - 1}` },
    { value: `${currYear - 2}`, label: `${currYear - 2}` },
    { value: `${currYear - 3}`, label: `${currYear - 3}` },
  ]);

  const [contrapartida, setContrapartida] = useState(() => {
    return contracod;
  });

  const [contrapartidas] = useState<RadioProps[]>(() => {
    if (user.perfil === 'ZON') {
      return [
        { id: 'cZ', value: 'Z', label: 'Zonal' },
        { id: 'cA', value: 'N', label: 'Nacional' },
        { id: 'cF', value: 'F', label: 'Fornecedor' },
      ];
    }

    if (user.perfil === 'NAC') {
      return [
        { id: 'cZ', value: 'Z', label: 'Zonal' },
        { id: 'cA', value: 'N', label: 'Nacional' },
        { id: 'cL', value: 'L', label: 'Local' },
      ];
    }
    return [];
  });

  const [coords, setCoords] = useState<ComboProps[]>([]);

  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    const filtered = response.data.filter(
      (item: ComboLocalProps) => item.anb === user.anbc,
    );
    setComboLocs(filtered);

    if (contracod === 'L') {
      setInitialCoord(
        filtered[
          filtered.findIndex(
            (item: ComboLocalProps) => item.value === contrapart,
          )
        ].value,
      );

      setCoords(filtered);
    }
  }, [contracod, contrapart, user.anbc]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    const filtered = response.data.filter((item: ComboProps) =>
      user.perfil === 'NAC' ? item.value !== user.anbc : item,
    );

    setComboNacs(filtered);

    if (contracod === 'N') {
      setInitialCoord(
        filtered[
          filtered.findIndex((item: ComboProps) => item.value === contrapart)
        ].label,
      );
      setCoords(filtered);
    }

    // user.perfil === 'ZON' && setCoords(filtered);
  }, [contracod, contrapart, user.anbc, user.perfil]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    const filtered = [
      {
        value: user.zoncod.replace('99', 'IN'),
        label: `Contab Internac Brasil ${user.zondesc.replace(
          'TOV ZONA BRASIL ',
          '',
        )}`,
      },
      ...response.data.filter((item: ComboProps) =>
        user.perfil === 'ZON' ? item.value !== user.zoncod : item,
      ),
    ];

    setComboZons(filtered);

    if (contracod === 'Z') {
      setInitialCoord(
        filtered[
          filtered.findIndex((item: ComboProps) => item.value === contrapart)
        ].label,
      );
      setCoords(filtered);
    }
  }, [contracod, contrapart, user.perfil, user.zoncod, user.zondesc]);

  // const filterMonth = useCallback(() => {
  //   setInitialMonth('Selecione');
  //   formRef.current?.setFieldValue('month', '');
  //   const selectedYear = parseInt(formRef.current?.getFieldValue('year'), 10);

  //   const currentYear = new Date().getUTCFullYear();
  //   const currMonth = new Date().getUTCMonth() + 1;

  //   if (selectedYear < currentYear) {
  //     setMonths([...monthList]);
  //   } else {
  //     setMonths(() => monthList.filter((item) => item.value <= currMonth));
  //   }
  // }, []);

  const setup = useCallback(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }

    formRef.current?.setFieldValue('month', month);

    formRef.current?.setData({
      ...formRef.current?.getData(),
      seq,
      year,
      month,
      document,
      value: value.toFixed(2),
      coord: contrapart,
      description,
    });

    // const currentYear = new Date().getUTCFullYear();
    // const currMonth = new Date().getUTCMonth() + 1;

    // setMonths(
    //   year < currentYear
    //     ? [...monthList]
    //     : monthList.filter((item) => item.value <= currMonth),
    // );

    getComboLOC();
    getComboANB();
    getComboZON();
  }, [
    contrapart,
    description,
    document,
    getComboANB,
    getComboLOC,
    getComboZON,
    history,
    location.pathname,
    location.state,
    month,
    seq,
    value,
    year,
  ]);

  useEffect(() => {
    setup();
  }, [setup]);

  const handleRadioClick = useCallback(() => {
    formRef.current?.setFieldValue('coord', '');
    setInitialCoord('Selecione');
    const radio = formRef.current?.getFieldValue('contrapart');
    setContrapartida(radio);

    setCoords([]);

    if (radio === 'Z') {
      setCoords(comboZons);
      return;
    }
    if (radio === 'N') {
      setCoords(comboNacs);
      return;
    }
    if (radio === 'L') {
      setCoords(comboLocs);
      return;
    }

    setCoords([]);
  }, [comboLocs, comboNacs, comboZons]);

  const shrinkCoord = useSpring({
    height: contrapartida !== 'F' ? '75px' : '0px',
    overflow: contrapartida !== 'F' ? 'visible' : 'hidden',
    opacity: contrapartida !== 'F' ? 1 : 0,
    pointerEvents: contrapartida !== 'F' ? 'all' : 'none',
  });

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          year: yup.string().required(),
          month: yup.string().notOneOf(['Selecione', '']),
          contrapart: yup.string().required(),
          // coord: yup.string().notOneOf(['Selecione', '']),
          coord: yup.string().when('contrapart', {
            is: (orig) => orig !== 'F',
            then: yup.string().notOneOf(['Selecione', '']).required(),
          }),
          document: yup
            .string()
            .trim()
            .required('Este campo é obrigatório')
            .min(5, 'Quantidade mínima de caracteres não atingida (5)')
            .max(20, 'Quantidade máxima de caracteres excedida (20)'),
          description: yup
            .string()
            .trim()
            .required('Este campo é obrigatório')
            .min(5, 'Quantidade mínima de caracteres não atingida (5)')
            .max(90, 'Quantidade máxima de caracteres excedida (90)'),
          value: yup
            .string()
            .notOneOf(['0.00', ''], 'Este campo é obrigatório.')
            .required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/cpr_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro alterado.',
        });
        setLoading(false);

        history.replace(location.pathname.replace('/update', ''));
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração de Registro" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContent>
            <Input isHidden name="seq" />
            <span>
              <p>Ano Referência</p>
              <SelectV2
                name="year"
                content={years}
                initial={JSON.stringify(initialYear)}
                // onBlur={filterMonth}
              />
            </span>
            <span>
              <p>Mês Referência</p>
              <SelectV2
                name="month"
                content={months}
                initial={JSON.stringify(initialMonth)}
              />
            </span>
            <span>
              <p>Conta a</p>
              <RadioContainer
                title=""
                onClick={handleRadioClick}
                name="mov"
                content={[
                  { id: 'mR', value: 'R', label: 'Receber' },
                  { id: 'mP', value: 'P', label: 'Pagar' },
                ]}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={mov}
              />
            </span>
            <span>
              <p>Contrapartida</p>
              <RadioContainer
                title=""
                onClick={handleRadioClick}
                name="contrapart"
                content={contrapartidas}
                itemsStyle={{
                  flexDirection: 'row',
                }}
                selected={contracod}
              />
            </span>
            <CoordSpan style={shrinkCoord}>
              <p>Coordenação</p>
              <SelectV2 name="coord" content={coords} initial={initialCoord} />
            </CoordSpan>
            <span>
              <p>Documento</p>
              <Input name="document" placeholder="Documento" mask="alpha" />
            </span>
            <span>
              <p>Descrição</p>
              <Textarea
                name="description"
                placeholder="Descrição"
                mask="alpha"
              />
            </span>
            <span>
              <p>Valor</p>
              <Input
                icon={FaDollarSign}
                name="value"
                type="number"
                placeholder="Valor"
                step=".01"
                mask="currency"
              />
            </span>
          </FormContent>
          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default CPRUpdate;
