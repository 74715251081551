import styled, { keyframes, css } from 'styled-components';

interface AnimatedSpanProps {
  delay?: number;
  duration?: number;
}

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);

  } to {
    opacity: 1;
    transform: translateX(0px);


  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  div + div {
    margin-top: 15px;
  }
`;

export const AnimatedDiv = styled.div<AnimatedSpanProps>`
  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;
  transition: all 0.5s;

  a {
    background: #fff;
    border-radius: 5px;
    border: 2px solid #efefef;
    padding: 5px 15px 5px 10px;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.5);

    width: 100%;
    display: flex;
    align-items: center;

    text-decoration: none;

    transition: all 0.5s;
    svg {
      margin-right: 15px;
      color: #363636;
      transition: all 0.5s;
    }

    h3 {
      font-size: 18.72px;
      color: #767676;
      transition: all 0.5s;
    }
  }

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  &:hover {
    a {
      transform: translateX(10px);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
      svg {
        color: #767676;
      }
      h3 {
        color: #332e2e;
      }
    }
  }
`;
