import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';

import { useSpring } from 'react-spring';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaTimes,
  FaFileExcel,
} from 'react-icons/fa';

import api from 'services/api';

import { CreateArrayProps, removeDups } from 'components/AutoComplete';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  CoordProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { formatDate, handleTimeZone } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';
import { Table, TableWrapper } from './styles';

interface LocalStorageProps {
  anb?: string;
  loc?: string;
  localYear?: string;
  localSemester?: string;
  coordStringfied?: string;
  localType?: string;
}

interface ListProps {
  uuid: string;
  anbcod: string;
  loccod: string;
  date: string;
  cod: string;
  desc: string;
  compl: string;
  valor: number;
  type: string;
  movyear: string;
  movmonth: string;
  movsem: string;
}

const DoacaoEspecifica: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  // const comboRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [mode] = useState(process.env.REACT_APP_MDE);
  const [loading, setLoading] = useState(false);

  const { width } = useWindow();

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    return anb || 'Selecione';
  });
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(`${mode}`);
    return loc || 'Selecione';
  });
  // const [year, setYear] = useState(() => {
  //   const { localYear }: LocalStorageProps = getLocalStorage(`${mode}`);
  //   return localYear || handleTimeZone(new Date()).getUTCFullYear().toString();
  // });
  // const [semester, setSemester] = useState(() => {
  //   const { localSemester }: LocalStorageProps = getLocalStorage(`${mode}`);
  //   const currSem = handleTimeZone(new Date()).getMonth() < 6 ? '1' : '2';

  //   return localSemester || currSem;
  // });

  // const [comboYears, setComboYears] = useState<ComboProps[]>([]);
  // const [comboSemesters] = useState<ComboProps[]>([
  //   { value: '1', label: '1° Semestre' },
  //   { value: '2', label: '2° Semestre' },
  // ]);

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { coordStringfied }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coordStringfied) {
      const { cod, desc } = JSON.parse(coordStringfied);
      return { cod, desc };
    }
    return user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  const yearCreator = useCallback(
    (array: ListProps[]) => {
      const {
        localYear,
        localType,
        anb,
        loc,
      }: LocalStorageProps = getLocalStorage(`${mode}`);

      const filterBy = localType || user.perfil;
      let arr = array;
      if (filterBy === 'ZON') {
        arr = arr.filter((item) => item.loccod === user.zoncod);
      }
      if (filterBy === 'NAC') {
        arr = arr.filter((item) => item.loccod === (anb || user.anbc));
      }
      if (filterBy === 'LOC') {
        arr = arr.filter((item) => item.loccod === (loc || user.loccod));
      }

      const newArr = removeDups(
        (arr as unknown[]) as CreateArrayProps[],
        'movyear',
      ).map((item) => ({
        value: `${item.movyear}`,
        label: `${item.movyear}`,
      }));

      const singleYear = [
        {
          value: `${handleTimeZone(new Date()).getFullYear()}`,
          label: `${handleTimeZone(new Date()).getFullYear()}`,
        },
      ];
      const toSet = newArr.length > 0 ? newArr.reverse() : singleYear;

      if (toSet.map((item) => item.value).indexOf(`${localYear}`) < 0) {
        // setYear(toSet[0].value);
        setLocalStorage(`${mode}`, { localYear: toSet[0].value });
      }
      // setComboYears(toSet);
    },
    [mode, user.anbc, user.loccod, user.perfil, user.zoncod],
  );

  const listSetter = useCallback(
    (array: ListProps[]) => {
      let filtered = array;
      const {
        anb,
        loc,
        // localSemester,
        // localYear,
        localType,
      }: LocalStorageProps = getLocalStorage(`${mode}`);

      const filterBy = localType || user.perfil;

      if (filterBy === 'ZON') {
        filtered = filtered.filter((item) => item.loccod === user.zoncod);
      }

      if (filterBy === 'NAC') {
        filtered = filtered.filter(
          (item) => item.loccod === (anb || user.anbc),
        );
      }

      if (filterBy === 'LOC') {
        filtered = filtered.filter(
          (item) => item.loccod === (loc || user.loccod),
        );
      }

      // filtered = filtered.filter(
      //   (item: ListProps) => item.movyear === localYear,
      // );

      // filtered = filtered.filter(
      //   (item: ListProps) => item.movsem === localSemester,
      // );

      setList(filtered);
    },
    [mode, user.anbc, user.loccod, user.perfil, user.zoncod],
  );

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLoc = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (anb || user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [mode, user.anbc, user.perfil]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/sgo/movimentacao_doacao_especifica.php`);

      setRawList(response.data);
      setTimeout(() => {
        yearCreator(response.data);

        listSetter(response.data);
      }, 250);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, listSetter, yearCreator]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    if (user.perfil === 'ZON') {
      getComboANB();
    }
    if (user.perfil !== 'LOC') {
      getComboLoc();
    }
    getList();
  }, [getComboANB, getComboLoc, getList, handlePermission, user.perfil]);

  const handleANBChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('anb');
    const { value, text } = select.options[select.selectedIndex];
    const obj = { cod: value, desc: text };
    setInitialNac(value);
    setInitialLoc('Selecione');
    setCoord(obj);
    // setType('NAC');
    setLocalStorage(`${mode}`, {
      anb: value,
      coordStringfied: JSON.stringify(obj),
      localType: 'NAC',
    });

    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));

    setTimeout(() => {
      yearCreator(rawList);

      listSetter(rawList);
    }, 250);
  }, [listSetter, locs, mode, rawList, yearCreator]);

  const handleLOCChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('local');
    const { value, text } = select.options[select.selectedIndex];
    const obj = { cod: value, desc: text };
    setInitialLoc(value);
    setCoord(obj);
    // setType('LOC');
    setLocalStorage(`${mode}`, {
      loc: value,
      coordStringfied: JSON.stringify(obj),
      localType: 'LOC',
    });

    setTimeout(() => {
      yearCreator(rawList);

      listSetter(rawList);
    }, 250);
  }, [listSetter, mode, rawList, yearCreator]);

  // const handleYearChange = useCallback(() => {
  //   const select = comboRef.current?.getFieldRef('years');
  //   const { value } = select.options[select.selectedIndex];
  //   setLocalStorage(`${mode}`, {
  //     localYear: value,
  //   });
  //   setYear(value);

  //   listSetter(rawList);
  // }, [listSetter, mode, rawList]);

  // const handleSemesterChange = useCallback(async () => {
  //   const select = comboRef.current?.getFieldRef('semester');
  //   const { value } = select.options[select.selectedIndex];
  //   setLocalStorage(`${mode}`, {
  //     localSemester: value,
  //   });

  //   setSemester(value);
  //   listSetter(rawList);
  // }, [listSetter, mode, rawList]);

  const handleRemoveANB = useCallback(() => {
    setComboLocs([]);
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    // setType('ZON');
    deleteLocalStorageItemKey(`${mode}`, ['loc', 'anb', 'localType']);

    const obj = { cod: user.zoncod, desc: user.zondesc };

    setLocalStorage(`${mode}`, {
      coordStringfied: JSON.stringify(obj),
    });
    setCoord(obj);
    setTimeout(() => {
      yearCreator(rawList);

      listSetter(rawList);
    }, 250);
  }, [listSetter, mode, rawList, user.zoncod, user.zondesc, yearCreator]);

  const handleRemoveLOC = useCallback(() => {
    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${mode}`, ['loc']);
    // setType('NAC');
    let obj = { cod: user.anbc, desc: user.anbdesc };
    if (user.perfil === 'ZON') {
      const select = formRef.current?.getFieldRef('anb');
      const { value, text } = select.options[select.selectedIndex];

      if (!value) {
        handleRemoveANB();
        return;
      }

      obj = { cod: value, desc: text };
      setLocalStorage(`${mode}`, {
        coordStringfied: JSON.stringify(obj),
        localType: 'NAC',
      });
    }

    setLocalStorage(`${mode}`, {
      coordStringfied: JSON.stringify(obj),
    });
    setCoord(obj);
    setTimeout(() => {
      yearCreator(rawList);

      listSetter(rawList);
    }, 250);
  }, [
    handleRemoveANB,
    listSetter,
    mode,
    rawList,
    user.anbc,
    user.anbdesc,
    user.perfil,
    yearCreator,
  ]);

  const styledRemoveANB = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const styledRemoveLOC = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  const balance = useMemo(() => {
    return list.reduce((accum, { valor, type }) => {
      return type === 'E' ? accum + valor : accum - valor;
    }, 0);
  }, [list]);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="anb"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleANBChange}
                  />
                  <RemoveButton
                    type="button"
                    style={styledRemoveANB}
                    onClick={handleRemoveANB}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}

              <span>
                <p>Selecione a Local:</p>
                <SelectV2
                  name="local"
                  content={comboLocs}
                  initial={initialLoc}
                  onChange={handleLOCChange}
                />
                <RemoveButton
                  type="button"
                  style={styledRemoveLOC}
                  onClick={handleRemoveLOC}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>

              {/* <span>
                <p>Ano de referência:</p>
                <SelectV2 name="year" content={comboYears} initial={year} />
              </span>
              <span>
                <p>Semestre de referência</p>
                <SelectV2
                  name="semester"
                  content={comboSemesters}
                  initial={semester}
                />
              </span> */}
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p> Movimentação de Doações Específicas </p>
            {/* <span role="img" aria-label="smile">
              😊
            </span> */}
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>
        {/* <div>
          <Form
            ref={comboRef}
            onSubmit={() => null}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <div style={{ margin: '0 5px 0 0' }}>
              <SelectV2
                name="semester"
                content={comboSemesters}
                onChange={handleSemesterChange}
                initial={semester}
                containerStyle={{ width: '150px' }}
              />
            </div>
            <div style={{ margin: '0 0 0 5px' }}>
              <SelectV2
                name="years"
                content={comboYears}
                onChange={handleYearChange}
                initial={year}
                containerStyle={{ width: '150px' }}
                disabled={comboYears.length < 2}
              />
            </div>
          </Form>
        </div> */}
        <Button
          bgcolor="#332e2e"
          containerStyle={{ margin: '10px 0' }}
          onClick={handleDownload}
        >
          <p>Gerar planilha</p>&emsp;
          <FaFileExcel />
        </Button>
        <TableWrapper>
          <Table>
            <thead>
              <td>Data</td>
              <td>Mov{width >= 501 ? 'imento' : '.'}</td>
              <td>Comp{width >= 501 ? 'lemento' : '.'}</td>
              <td>Valor</td>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.uuid}>
                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <td>
                      <p>{formatDate(item.date)}</p>
                    </td>
                    <td>
                      <p
                        style={{
                          color: item.type === 'E' ? '#00802b' : '#ff0000',
                        }}
                      >
                        {width >= 631 ? (
                          item.desc
                        ) : width >= 501 ? (
                          item.desc.substr(0, item.desc.indexOf(' '))
                        ) : item.type === 'E' ? (
                          <FaArrowAltCircleLeft />
                        ) : (
                          <FaArrowAltCircleRight />
                        )}
                      </p>
                    </td>
                    <td>
                      <p>{item.compl}</p>
                    </td>
                    <td>
                      <p>
                        {item.type === 'S' ? '-' : ''}
                        {formatNumber(item.valor)}
                      </p>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <td colSpan={4}>
                  <p>
                    Saldo das Doações Específicas:&nbsp;
                    <strong
                      style={{
                        color:
                          balance === 0
                            ? '#332e2e'
                            : balance < 0
                            ? '#c53030'
                            : '#007acc',
                      }}
                    >
                      R$ {formatNumber(balance)}
                    </strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </TableWrapper>

        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>Gerar XLS</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() => setShow({ ...show, open: false })}
            >
              Ok
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_movto_doa_espec.php?data=${JSON.stringify({
            cod: coord.cod,
            // year,
            // sem: semester,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          XLS
        </a>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default DoacaoEspecifica;
