import React, { useState, useEffect, useCallback } from 'react';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';
import SGOFooter from 'components/SGOFooter';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import Checkbox from 'components/Checkbox';
import { formatDate } from 'utils/formatDate';
import { ListProps } from '../main';
import { DMGHeader } from './styles';
import { GuiasProps } from '../insert';
import {
  GuiasContainer,
  GuiasContainerHeader,
  GuiasContainerBody,
  ListItem,
  Name,
  Cert,
  Check,
} from '../insert/styles';

interface StateProps {
  reg: ListProps;
}

const DMGConsult: React.FC = () => {
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling } = useCredentials();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<GuiasProps[]>([]);
  // const [guias, setGuias] = useState<number[]>([]);

  const [registro] = useState(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return location.state.reg;
  });

  const getGuias = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        anb: registro.anb,
        loc: registro.loc,
        date: registro.date,
        prq: registro.prq,
      };

      const response = await api.get(
        `/sgo/dmg_guias.php?data=${JSON.stringify(data)}`,
      );
      setList(response.data);

      // setGuias(
      //   response.data
      //     .filter((item: GuiasProps) => item.status)
      //     .map((item: GuiasProps) => item.status && item.seq),
      // );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, registro.anb, registro.date, registro.loc, registro.prq]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/guias', ''));
      return;
    }
    getGuias();
  }, [getGuias, history, location.pathname, location.state]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Dia Mensal do Guia" />
      <Content>
        <AlteredHeader>Presença dos Guias</AlteredHeader>
        <DMGHeader>
          {['ZON', 'INT'].indexOf(user.perfil) > -1 && (
            <span>
              <p>
                Nacional: <strong>{registro.anbdesc}</strong>
              </p>
            </span>
          )}
          {['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1 && (
            <span>
              <p>
                Local: <strong>{registro.locdesc}</strong>
              </p>
            </span>
          )}
          <span>
            <p>
              Local de Serviço/Atividade: <strong>{registro.prqdesc}</strong>
            </p>
          </span>

          <span>
            <p>
              Data: <strong>{formatDate(registro.date)}</strong>
            </p>
          </span>
        </DMGHeader>
        {list.length > 0 && (
          <GuiasContainer>
            <GuiasContainerHeader>
              <ListItem>
                <Name>
                  <p>Guia</p>
                </Name>
                <Cert>
                  <p>Cert.</p>
                </Cert>
                <Check>
                  <p>Presença</p>
                </Check>
              </ListItem>
            </GuiasContainerHeader>

            <GuiasContainerBody>
              {list.map((item, index) => (
                <ListItem key={item.seq} paint={index}>
                  <Name>
                    <p>{item.name}</p>
                  </Name>
                  <Cert>
                    <p>{item.cert}</p>
                  </Cert>
                  <Check>
                    <Checkbox
                      id={`${item.seq}stat`}
                      title=""
                      containerStyle={{ margin: '0px' }}
                      isDisabled
                      checked={item.status}
                    />
                  </Check>
                </ListItem>
              ))}
            </GuiasContainerBody>
          </GuiasContainer>
        )}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default DMGConsult;
