import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useChanges } from 'hooks/changes';
import { useWindow } from 'hooks/window';
import { useSpring } from 'react-spring';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { CheckboxLabel } from 'styles/checkboxLabel';

import { useLocation, useHistory, Link } from 'react-router-dom';

import moment from 'moment';

import {
  FaCalendarAlt,
  FaCity,
  FaEnvelope,
  FaIdBadge,
  FaMapMarkedAlt,
  FaMobile,
  FaPencilAlt,
  FaPhone,
  FaPlusCircle,
  FaRandom,
  FaSearch,
  FaStreetView,
  FaCheckSquare,
} from 'react-icons/fa';
import api from 'services/api';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { formatDate, handleTimeZone } from 'utils/formatDate';
import { formatCPF } from 'utils/handleCPF';

import {
  ButtonContainers,
  TableWrapper,
  Table,
  Name,
  Pers,
  Cons,
  Alt,
  EPGButton,
  ModalContent,
} from './styles';

import { ListProps as MainProps } from '../../main';

export interface ListProps extends MainProps {
  stage: number;
}

export interface FGProps {
  cpf: string;
  epg: number;
  name: string;
  address: string;
  district: string;
  city: string;
  uf: string;
  ufname: string;
  cep: string;
  fres: string;
  fcel: string;
  dtnasc: string;
  mail: string;
  et1: string;
  et2: string;
  et3: string;
}

export const Alunos: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<ListProps>();
  const history = useHistory();

  const { errorHandling } = useCredentials();
  const { currDate } = useChanges();
  const { width } = useWindow();
  const { user } = useAuth();

  const [epg] = useState<ListProps>(() => ({ ...location.state }));
  const [fgs, setFGs] = useState<FGProps[]>([]);
  const [pers, setPers] = useState<string[]>([]);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [consult, setConsult] = useState<FGProps>({} as FGProps);

  // const checkStageInit = (): boolean => {
  //   const { full } = currDate;
  //   const epgDate = handleTimeZone(
  //     epg.stage === 1 ? epg.dti1 : epg.stage === 2 ? epg.dti2 : epg.dti3,
  //   );
  //   // const epgDate = new Date(
  //   //   parseISO(
  //   //     epg.stage === 1 ? epg.dti1 : epg.stage === 2 ? epg.dti2 : epg.dti3,
  //   //   ),
  //   // );

  //   const today = full;

  //   const stageDate = new Date(
  //     epgDate.getUTCFullYear(),
  //     epgDate.getUTCMonth(),
  //     epgDate.getUTCDate(),
  //     0,
  //     0,
  //   );
  //   const compare = new Date(
  //     today.getUTCFullYear(),
  //     today.getUTCMonth(),
  //     today.getUTCDate(),
  //     0,
  //     0,
  //   );

  //   const formats = 'YYYY-mm-dd';

  //   const finale = moment(stageDate, formats);
  //   const base = moment(compare, formats);

  //   return base.isSameOrAfter(finale);
  // };

  // const checkRegisterInit = (): boolean => {
  //   const epgDate = new Date(
  //     parseISO(
  //       epg.stage === 1 ? epg.dti1 : epg.stage === 2 ? epg.dti2 : epg.dti3,
  //     ),
  //   );

  //   const today = addDays(new Date(parseISO(new Date().toISOString())), 3);

  //   // const today = new Date(parseISO(new Date().toISOString()));

  //   const stageDate = new Date(
  //     epgDate.getUTCFullYear(),
  //     epgDate.getUTCMonth(),
  //     epgDate.getUTCDate(),
  //     0,
  //     0,
  //   );
  //   const compare = new Date(
  //     today.getUTCFullYear(),
  //     today.getUTCMonth(),
  //     today.getUTCDate(),
  //     0,
  //     0,
  //   );

  //   const formats = 'YYYY-mm-dd';

  //   const finale = moment(stageDate, formats);
  //   const base = moment(compare, formats);

  //   return base.isSameOrAfter(finale);
  // };

  const checkStageFinale = (): boolean => {
    const { full } = currDate;
    const epgDate = handleTimeZone(
      epg.stage === 1 ? epg.dti1 : epg.stage === 2 ? epg.dti2 : epg.dti3,
    );
    // const epgDate = new Date(
    //   parseISO(
    //     epg.stage === 1 ? epg.dtf1 : epg.stage === 2 ? epg.dtf2 : epg.dtf3,
    //   ),
    // );
    const today = full;

    const stageFinale = new Date(
      epgDate.getUTCFullYear(),
      epgDate.getUTCMonth(),
      epgDate.getUTCDate(),
      0,
      0,
    );
    const compare = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0,
      0,
    );

    const formats = 'YYYY-mm-dd';

    const finale = moment(stageFinale, formats);
    const base = moment(compare, formats);

    return base.isAfter(finale);
  };

  const [able] = useState(() => {
    const ableTo =
      (user.perfil === 'LOC' ||
        epg.ge1 === user.gseq ||
        epg.ge2 === user.gseq ||
        epg.ge3 === user.gseq) &&
      !epg.sit;

    // return ableTo;
    return ableTo; // && checkStageInit();
    // return ableTo;
  });

  const getFGs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/epg_aluno_list.php?data=${JSON.stringify({ epg: epg.epg })}`,
      );
      setFGs(
        response.data.filter((item: FGProps) =>
          epg.stage === 1
            ? { ...item }
            : epg.stage === 2
            ? !!item.et2
            : !!item.et3,
        ),
      );

      setPers(
        response.data
          .filter((item: FGProps) =>
            epg.stage === 1
              ? item.et1 === 'C'
              : epg.stage === 2
              ? item.et2 === 'C'
              : item.et3 === 'C',
          )
          .map((item: FGProps) => item.cpf),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [epg.epg, epg.stage, errorHandling]);

  useEffect(() => {
    getFGs();
  }, [getFGs]);

  const handleCheckbox = useCallback(
    async (cpf: string) => {
      setPers((state) =>
        state.indexOf(cpf) > -1
          ? state.filter((item: string) => item !== cpf)
          : [...state, cpf],
      );

      try {
        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            cpf,
            epg: epg.epg,
            stage: epg.stage,
            mode: pers.indexOf(cpf) > -1 ? 'remove' : 'add',
          }),
        );

        await api.post('/sgo/epg_aluno_conclusion.php', send, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const index = fgs.findIndex((item: FGProps) => item.cpf === cpf);

        fgs[index] = {
          ...fgs[index],
          et1:
            epg.stage === 1
              ? pers.indexOf(cpf) < 0
                ? 'C'
                : 'P'
              : fgs[index].et1,
          et2:
            epg.stage === 2
              ? pers.indexOf(cpf) < 0
                ? 'C'
                : 'P'
              : fgs[index].et2,
          et3:
            epg.stage === 3
              ? pers.indexOf(cpf) < 0
                ? 'C'
                : 'P'
              : fgs[index].et3,
        };
        setFGs((state) => [...state]);
      } catch (err) {
        setPers((state) =>
          state.indexOf(cpf) > -1
            ? state.filter((item: string) => item !== cpf)
            : [...state, cpf],
        );
        errorHandling(err);
      }
    },
    [epg.epg, epg.stage, errorHandling, fgs, pers],
  );

  const handleNavigateInsert = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/${
        epg.stage === 1 ? 'insert' : 'register'
      }`,
      state: { ...epg },
    });
  }, [epg, history, location.pathname]);

  const handleNavigateTransfer = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/transfer`,
      state: { ...epg },
    });
  }, [epg, history, location.pathname]);

  const appearButtonContainer = useSpring({
    opacity: !epg.sit ? 1 : 0,
    marginTop: !epg.sit ? '10px' : '0px',
    pointerEvents: !epg.sit ? 'all' : 'none',
    height: !epg.sit ? '40px' : '0px',
    overflow: !epg.sit ? 'unset' : 'hidden',
  });
  // const appearButtonContainer = useSpring({
  //   opacity: checkRegisterInit() && !checkStageFinale() ? 1 : 0,
  //   marginTop: checkRegisterInit() && !checkStageFinale() ? '10px' : '0px',
  //   pointerEvents: checkRegisterInit() && !checkStageFinale() ? 'all' : 'none',
  // });

  // const appearButtonContainer = useSpring({});

  const disabledCheckBox = (): boolean => {
    const formats = 'YYYY-mm-dd';
    const todayDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds(),
    );
    const stageInit = new Date(
      epg.stage === 1 ? epg.dti1 : epg.stage === 2 ? epg.dti2 : epg.dti3,
    );

    /* 1) Somente depois da data final da etapa; ✔ */
    const hasFinished = checkStageFinale();

    /*

    2) Somente se o FG não estiver como PARTICIPANTE ou PERSEVERANTE nas etapas seguintes;
    ※ VALIDAÇÃO DIRETO NO CAMPO ※
    */

    /* 3) Pode ser alterado dentro do semestre;  ✔ */
    const minDate = new Date(
      stageInit.getFullYear(),
      stageInit.getMonth() < 6 ? 1 : 6,
      1,
      0,
      0,
      0,
    );
    const maxDate = new Date(
      stageInit.getFullYear(),
      stageInit.getMonth() < 6 ? 5 : 11,
      stageInit.getMonth() < 6 ? 30 : 31,
      23,
      59,
      59,
    );
    const minMoment = moment(minDate, formats);
    const maxMoment = moment(maxDate, formats);
    const todayMoment = moment(todayDate, formats);

    const sameSemester =
      todayMoment.isSameOrAfter(minMoment) &&
      todayMoment.isSameOrBefore(maxMoment);

    /* 4) Perfil local ou guia em uma das etapas; ✔ */
    const profileAble = user.perfil === 'LOC';
    const gseqAble = [epg.ge1, epg.ge2, epg.ge3].indexOf(user.gseq) > -1;
    ///

    return !((profileAble || gseqAble) && sameSemester && hasFinished);
  };
  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(epg).length > 0 ? `EPG N° ${epg.epg}` : ''}
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>{epg.stage}ª Etapa</p>
          </div>
        </AlteredHeader>

        <ButtonContainers style={appearButtonContainer}>
          <EPGButton bg="#00802b" type="button" onClick={handleNavigateInsert}>
            <FaPlusCircle />
            <p>{epg.stage === 1 ? 'Cadastrar' : 'Registrar'}</p>
          </EPGButton>
          <EPGButton
            bg="#464646"
            type="button"
            onClick={handleNavigateTransfer}
          >
            <FaRandom />
            <p>Transferir</p>
          </EPGButton>
        </ButtonContainers>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Name>
                  <p>Futuro Guia</p>
                </Name>
                <Pers>
                  <p>{width > 414 ? 'Perseverante' : 'Pers.'} </p>
                </Pers>
                <Cons>
                  <FaSearch />
                </Cons>
                {able && (
                  <Alt>
                    <FaPencilAlt />
                  </Alt>
                )}
              </tr>
            </thead>
            <tbody>
              {fgs.map((item, index) => (
                <React.Fragment key={item.cpf}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <Name>
                        <p>Futuro Guia</p>
                      </Name>
                      <Pers>
                        <p>{width > 414 ? 'Perseverante' : 'Pers.'} </p>
                      </Pers>
                      <Cons>
                        <FaSearch />
                      </Cons>
                      {able && (
                        <Alt>
                          <FaPencilAlt />
                        </Alt>
                      )}
                    </tr>
                  )}
                  <tr
                    style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                  >
                    <Name>
                      <p>{item.name}</p>
                    </Name>
                    <Pers>
                      <CheckboxLabel
                        htmlFor={item.cpf}
                        isChecked={pers.indexOf(item.cpf) > -1}
                        disabled={
                          epg.stage === 1
                            ? disabledCheckBox() || !!item.et2 || !!item.et3
                            : epg.stage === 2
                            ? disabledCheckBox() || !!item.et3
                            : disabledCheckBox()
                        }
                      >
                        <FaCheckSquare />
                        <input
                          id={item.cpf}
                          type="checkbox"
                          onClick={() => handleCheckbox(item.cpf)}
                          checked={pers.indexOf(item.cpf) > -1}
                          readOnly
                        />
                      </CheckboxLabel>
                    </Pers>
                    <Cons>
                      <button
                        type="button"
                        onClick={() => {
                          setConsult({ ...item });
                          setShow((state) => ({ ...state, open: true }));
                        }}
                      >
                        <FaSearch />
                      </button>
                    </Cons>
                    {able && (
                      <Alt>
                        <Link
                          to={{
                            pathname: `${location.pathname}/update`,
                            state: { ...item },
                          }}
                        >
                          <FaPencilAlt />
                        </Link>
                      </Alt>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>{consult.name}</h2>
          </S.Title>
          <S.Content>
            <ModalContent>
              <section>
                <span>
                  <p>
                    {width > 850 ? 'CPF: ' : <FaIdBadge />}
                    <strong>{formatCPF(consult.cpf)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'Data Nascimento: ' : <FaCalendarAlt />}
                    <strong>{formatDate(consult.dtnasc)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'Tel. Residencial: ' : <FaPhone />}

                    <strong>{consult.fres || '-'}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'Tel. Celular: ' : <FaMobile />}

                    <strong>{consult.fcel || '-'}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'E-mail: ' : <FaEnvelope />}
                    <strong>{consult.mail || '-'}</strong>
                  </p>
                </span>
              </section>
              <section>
                <span>
                  <p>
                    {width > 850 ? 'Endereço: ' : <FaStreetView />}
                    <strong>
                      {consult.address} - {consult.district} - {consult.cep}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'Cidade: ' : <FaCity />}
                    <strong>{consult.city}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {width > 850 ? 'Estado: ' : <FaMapMarkedAlt />}
                    <strong>{consult.ufname}</strong>
                  </p>
                </span>
              </section>
            </ModalContent>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow((state) => ({ ...state, open: !state.open }))
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Alunos;
