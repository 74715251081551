import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 330px);

  overflow-x: hidden;

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;
