import styled from 'styled-components';

export const Grid = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  /* width: 1200px; */

  /* align-items: center;
  justify-content: center; */
  row-gap: 25px;
  column-gap: 25px;

  @media screen and (max-width: 1300px) {
    width: 100vw;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 400px; */
  /* background: black; */

  h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #332e2e;
    text-shadow: 0px 2px 5px rgba(51, 46, 46, 0.5);
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px 7px;
    background: #fff;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;

    > div {
      display: flex;
      > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #8a0002;
          text-shadow: unset;
        }
        p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
          user-select: none;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }

      & + div {
        margin: 10px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 360px) {
    width: 100vw;

    > div {
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
    }
  }
`;
