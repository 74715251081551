import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import InputDialog from 'components/InputDialog';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useHistory, useLocation } from 'react-router-dom';

import api from 'services/api';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';

import { addDays, addYears, parseISO, subDays } from 'date-fns';
import { ListProps as ConvProps, checkStat } from '../main';
import { Wrapper } from './styles';

interface FormData {
  uuid: string;
  ini: string;
  end: string;
}

const ConvocacaoUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();
  const location = useLocation<ConvProps>();
  const { goBack } = useHistory();

  const [conv] = useState(() => ({ ...location.state }));
  const [coord] = useState(() =>
    conv.coor === 'L'
      ? conv.loc
      : conv.coor === 'N'
      ? conv.future
        ? conv.anbref
        : conv.anb
      : conv.zon,
  );
  const [mockSelect] = useState<ComboProps[]>(() => {
    return [
      {
        value:
          conv.coor === 'L'
            ? conv.loc
            : conv.coor === 'N'
            ? conv.future
              ? conv.anbref
              : conv.anb
            : conv.zon,
        label:
          conv.coor === 'L'
            ? conv.locdesc
            : conv.coor === 'N'
            ? conv.future
              ? 'Nacional p/ Multiplicação'
              : conv.anbdesc
            : conv.zondesc,
      },
    ];
    // conv.coor === 'L' ? conv.locdesc : conv.coor === 'N' ? conv.anbdesc : conv.zondesc
  });

  useEffect(() => {
    if (!location.state) {
      goBack();
      return;
    }

    handlePermission(['NAC', 'ZON', 'INT'], true);
  }, [goBack, handlePermission, location.state, user.perfil]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const minINIDate = new Date(parseISO(new Date(conv.ini).toISOString()));
        const maxINIDate = addYears(minINIDate, 1);

        // const minDays = conv.coor !== 'Z' ? 5 : 15;
        const minDays = 5;
        const maxDays = conv.coor !== 'Z' ? 20 : 90;

        const minENDDate = addDays(
          new Date(
            parseISO(
              formRef.current?.getFieldValue('ini') || new Date().toISOString(),
            ),
          ),
          minDays,
        );
        const maxENDDate = addDays(
          new Date(
            parseISO(
              formRef.current?.getFieldValue('ini') || new Date().toISOString(),
            ),
          ),
          maxDays,
        );

        const schema = yup.object().shape({
          ini: yup
            .date()
            .typeError('Data inválida.')
            .min(
              subDays(minINIDate, 1),
              `Data de início deve ser posterior à ${formatDate(
                subDays(minINIDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxINIDate,
              `Data de início deve ser anterior à ${formatDate(
                addDays(maxINIDate, 1).toISOString(),
              )}`,
            ),
          end: yup
            .date()
            .typeError('Data inválida')
            .min(
              minENDDate,
              `Data de término deve ser posterior à ${formatDate(
                subDays(minENDDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxENDDate,
              `Data de término deve ser anterior à ${formatDate(
                addDays(maxENDDate, 1).toISOString(),
              )}`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify({ ...data }));

        await api.post('/sgo/eleicao_conv_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Convocação alterada.',
        });

        goBack();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, conv.coor, conv.ini, errorHandling, goBack],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração de Convocação" />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            uuid: conv.seq,
            ini: conv.ini,
            end: conv.end,
          }}
        >
          <Wrapper>
            <div>
              <span>
                <p>Coordenação</p>
                <SelectV2
                  name="coord"
                  initial={coord}
                  content={mockSelect}
                  disabled
                />
              </span>
            </div>
            {conv.future && (
              <>
                <div>
                  <span>
                    <p>Coordenação de Origem</p>
                    <strong>{conv.anbrefdesc}</strong>
                  </span>
                </div>
                <div>
                  <span>
                    <p>Locais Relacionadas</p>
                    <strong>
                      {conv.relatedlocals.map(
                        (rl, index) =>
                          `${rl.desc}${
                            index < conv.relatedlocals.length - 1 ? ', ' : ''
                          }`,
                      )}
                    </strong>
                  </span>
                </div>
              </>
            )}
            <div>
              <span>
                {/* DISABLED SE JÁ POSSUI VOTOS */}
                <p>Início</p>
                <InputDialog
                  onClick={() => formRef.current?.setFieldError('ini', '')}
                  disabled={
                    checkStat(conv.ini, conv.end).text === 'Em votação' ||
                    conv.votes > 0
                  }
                  name="ini"
                  containerStyle={{
                    width: '172.5px',
                    padding: '5px 2px 4.82px 5px',
                  }}
                  inputStyle={{
                    width: '110px',
                  }}
                  type="date"
                  placeholder="dd/mm/aaaa"
                />
              </span>
              <span>
                <p>Encerramento</p>
                <InputDialog
                  onClick={() => formRef.current?.setFieldError('end', '')}
                  name="end"
                  containerStyle={{
                    width: '172.5px',
                    padding: '5px 2px 4.82px 5px',
                  }}
                  inputStyle={{
                    width: '110px',
                  }}
                  type="date"
                  placeholder="dd/mm/aaaa"
                />
              </span>
            </div>

            <InputDialog name="uuid" isHidden />
          </Wrapper>
          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ConvocacaoUpdate;
