import styled, { css } from 'styled-components';

interface LabelProps {
  marked: boolean;
  disabled: boolean;
}

export const Wrapper = styled.div`
  margin: 25px 0;
  display: flex;
  min-width: 420px;
  max-width: 420px;
  align-items: center;
  flex-direction: column;

  border: 2px solid #332e2e;
  border-radius: 5px;

  padding: 10px 5px;
  background: #fff;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    max-width: 350px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    & + div {
      margin: 10px 0;
    }
  }
  p {
    width: 100%;
    text-align: left;
    color: #8a0002;
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 500;
  }

  legend {
    width: 100%;
    font-size: 12px;
    color: red;
    font-style: italic;
    text-align: left;
  }
`;

export const RelatedLocalsGrid = styled.div`
  margin: 5px 0;
  display: grid !important;
  grid: auto / 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='checkbox'] {
      width: 100%;
      display: none;
    }
  }
`;

export const GridItemLabel = styled.label<LabelProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #a6a6a6;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  width: 100%;
  min-height: 20px;
  height: 100%;
  transition: all 0.35s;
  text-align: center;
  padding: 0px;
  font-size: 14px;

  &:hover {
    background: rgba(51, 255, 255, 0.25);
    border-color: #006666;
    box-shadow: 2px 5px 10px 3px rgba(0, 77, 77, 0.5);
  }

  ${(props) =>
    props.marked &&
    css`
      background: #33ffff;
      border-color: #006666;
      &:hover {
        /* background: green; */
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: grey;
      border-color: #464646;
      opacity: 0.5;
      box-shadow: 0px 0px 0px 0px rgba(0, 77, 77, 0.5);

      &:hover {
        background: grey;
        border-color: #464646;
        box-shadow: 0px 0px 0px 0px rgba(0, 77, 77, 0.5);
      }
    `}
`;
