import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import IncludeButton from 'components/IncludeButton';
import InputDialog from 'components/InputDialog';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useSpring } from 'react-spring';

import { removeDups, CreateArrayProps } from 'components/AutoComplete';

import api from 'services/api';

import {
  Container,
  Content,
  AlteredHeader,
  CoordProps,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FaTimes, FaShare, FaPlusCircle, FaFileExcel } from 'react-icons/fa';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import getValidationErrors from 'utils/getValidationErrors';
import { subDays, addDays } from 'date-fns';

import * as yup from 'yup';

import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import * as I from 'styles/dialog_include';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { IncludeProps } from 'styles/dialog_include';
import { DeleteProps } from 'styles/dialog_delete';

import { formatDate, handleTimeZone } from 'utils/formatDate';

import { et64 } from 'utils/encoding';

import {
  GridContainer,
  GridItem,
  IContentDiv,
  DeleteModalContent,
} from './styles';

interface LocalStorageProps {
  anb?: string;
  loc?: string;
  mat?: string;
  coordStringfied?: string;
  localType?: string;
}

interface ListProps {
  anb: string;
  loc: string;
  mat: string;
  desc: string;
  num: string;
  gseq: string;
  gcert: string;
  name: string;
  date: string;
}

interface CustomProps {
  [key: string]: string | boolean;
}

interface FormData {
  manual: string;
  numero: string;
  guia: string;
  data: string;
}

interface WarnDelete {
  gseq: string;
  manual: string;
  numero: string;
}

const ManualGuia: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const comboRef = useRef<FormHandles>(null);
  const insertForm = useRef<FormHandles>(null);
  const deleteForm = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [mode] = useState(process.env.REACT_APP_MANUAIS);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(() => {
    const { localType }: LocalStorageProps = getLocalStorage(`${mode}`);
    return localType || user.perfil;
  });

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { coordStringfied }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coordStringfied) {
      const { cod, desc } = JSON.parse(coordStringfied);
      return { cod, desc };
    }
    return user.perfil === 'ZON'
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [materiais, setMateriais] = useState<ComboProps[]>([]);

  const [incmateriais, setIncMateriais] = useState<ComboProps[]>([]);

  const [rawGuias, setRawGuias] = useState<CustomProps[]>([]);
  const [guias, setGuias] = useState<ComboProps[]>([]);

  const [rawNumbers, setRawNumbers] = useState<CustomProps[]>([]);
  const [numbers, setNumbers] = useState<ComboProps[]>([]);

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    return anb || 'Selecione';
  });
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(`${mode}`);
    return loc || 'Selecione';
  });

  const [initialMaterial] = useState(() => {
    const { mat }: LocalStorageProps = getLocalStorage(`${mode}`);
    return mat || '';
  });

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [inc, setInc] = useState({} as IncludeProps);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);
  const [selectedDeletion, setSelectedDeletion] = useState<ListProps>(
    {} as ListProps,
  );

  const [isRunning, setIsRunning] = useState(false);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLoc = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (anb || user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [mode, user.anbc, user.perfil]);

  const getMultCombos = useCallback(async (getCombosMode?: string) => {
    const response = await api.get(
      `/combos/comboManuaisGuias.php?mode=${
        getCombosMode === undefined ? '' : getCombosMode
      }`,
    );
    const { dbguias, dbmaterials, dbnumbers, dbincmaterials } = response.data;

    const toadd = dbmaterials || [];
    setMateriais([{ value: '', label: 'Todos os materiais' }, ...toadd]);
    setRawGuias(dbguias);
    setIncMateriais(
      (removeDups(
        (dbincmaterials as unknown) as CreateArrayProps[],
        'value',
      ) as unknown) as ComboProps[],
    );
    setRawNumbers(dbnumbers);
  }, []);

  const listSetter = useCallback(
    (array: ListProps[]) => {
      let filtered = array;

      const { anb, loc, mat, localType }: LocalStorageProps = getLocalStorage(
        `${mode}`,
      );

      const filterBy = localType || user.perfil;

      if (filterBy === 'NAC') {
        filtered = filtered.filter((item) => item.anb === (anb || user.anbc));
      }

      if (filterBy === 'LOC') {
        filtered = filtered.filter((item) => item.loc === (loc || user.loccod));
      }

      if (mat) {
        filtered = filtered.filter((item) => item.mat === mat);
      }

      setList(filtered);
    },
    [mode, user.anbc, user.loccod, user.perfil],
  );

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      // const send = new FormData();
      const send = btoa(
        JSON.stringify({
          mode: 'list',
        }),
      );

      const response = await api.post('/sgo/manuaisguias.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRawList(response.data);
      listSetter(response.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, listSetter]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    getMultCombos();
    if (user.perfil === 'ZON') {
      getComboANB();
    }
    if (user.perfil !== 'LOC') {
      getComboLoc();
    }
    getList();
  }, [
    getComboANB,
    getComboLoc,
    getList,
    getMultCombos,
    handlePermission,
    user.perfil,
  ]);

  const handleANBChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('anb');
    const { value, text } = select.options[select.selectedIndex];
    const obj = { cod: value, desc: text };
    setInitialNac(value);
    setInitialLoc('Selecione');
    setCoord(obj);
    setType('NAC');
    setLocalStorage(`${mode}`, {
      anb: value,
      coordStringfied: JSON.stringify(obj),
      localType: 'NAC',
    });

    deleteLocalStorageItemKey(`${mode}`, ['loc']);

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));
    setTimeout(() => {
      listSetter(rawList);
    }, 250);
  }, [listSetter, locs, mode, rawList]);

  const handleLOCChange = useCallback(() => {
    const select = formRef.current?.getFieldRef('local');
    const { value, text } = select.options[select.selectedIndex];
    const obj = { cod: value, desc: text };
    setInitialLoc(value);
    setCoord(obj);
    setType('LOC');
    setLocalStorage(`${mode}`, {
      loc: value,
      coordStringfied: JSON.stringify(obj),
      localType: 'LOC',
    });

    setTimeout(() => {
      listSetter(rawList);
    }, 250);
  }, [listSetter, mode, rawList]);

  const handleMatChange = useCallback(() => {
    const select = comboRef.current?.getFieldRef('materiais');
    const { value } = select.options[select.selectedIndex];

    if (value) {
      setLocalStorage(`${mode}`, {
        mat: value,
      });
    } else {
      deleteLocalStorageItemKey(`${mode}`, ['mat']);
    }

    setTimeout(() => {
      listSetter(rawList);
    }, 250);
  }, [mode, listSetter, rawList]);

  const handleRemoveANB = useCallback(() => {
    setComboLocs([]);
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    setType('ZON');
    deleteLocalStorageItemKey(`${mode}`, ['loc', 'anb', 'localType']);

    const obj = { cod: user.zoncod, desc: user.zondesc };

    setLocalStorage(`${mode}`, {
      coordStringfied: JSON.stringify(obj),
    });
    setCoord(obj);

    setTimeout(() => {
      listSetter(rawList);
    }, 250);
  }, [listSetter, mode, rawList, user.zoncod, user.zondesc]);

  const handleRemoveLOC = useCallback(() => {
    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${mode}`, ['loc']);
    setType('NAC');
    let obj = { cod: user.anbc, desc: user.anbdesc };
    if (user.perfil === 'ZON') {
      const select = formRef.current?.getFieldRef('anb');
      const { value, text } = select.options[select.selectedIndex];

      if (!value) {
        handleRemoveANB();
        return;
      }

      obj = { cod: value, desc: text };
      setLocalStorage(`${mode}`, {
        coordStringfied: JSON.stringify(obj),
        localType: 'NAC',
      });
    }

    setLocalStorage(`${mode}`, {
      coordStringfied: JSON.stringify(obj),
    });
    setCoord(obj);

    setTimeout(() => {
      listSetter(rawList);
    }, 250);
  }, [
    handleRemoveANB,
    listSetter,
    mode,
    rawList,
    user.anbc,
    user.anbdesc,
    user.perfil,
  ]);

  const toggleIncDialog = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const toggleDeleteDialog = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleSelectedDeletion = useCallback(
    (based64: string) => {
      setSelectedDeletion(JSON.parse(atob(based64)));
      toggleDeleteDialog();
    },
    [toggleDeleteDialog],
  );

  const handleIncMatChange = useCallback(() => {
    const select = insertForm.current?.getFieldRef('manual');
    const { value } = select.options[select.selectedIndex];

    const nselect = insertForm.current?.getFieldRef('numero');
    const gselect = insertForm.current?.getFieldRef('guia');
    nselect.options[nselect.selectedIndex].selected = false;
    gselect.options[gselect.selectedIndex].selected = false;

    setNumbers(
      Object.values(rawNumbers[value]).map((item: string | boolean) => ({
        value: item.toString(),
        label: item.toString(),
      })),
    );

    setGuias(
      rawGuias
        .filter((item) => item[value] === false)
        .map((item) => ({
          label: item.label.toString(),
          value: item.value.toString(),
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    );
  }, [rawGuias, rawNumbers]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      setIsRunning(true);
      console.log('running...');
      try {
        insertForm.current?.setErrors({});

        const minDate = handleTimeZone(new Date('2022-01-01'));
        const maxDate = handleTimeZone(new Date());

        const schema = yup.object().shape({
          manual: yup
            .string()
            .notOneOf(['Selecione', ''], 'Campo obrigatório.'),
          numero: yup
            .string()
            .notOneOf(['Selecione', ''], 'Campo obrigatório.'),
          guia: yup.string().notOneOf(['Selecione', ''], 'Campo obrigatório.'),
          data: yup
            .date()
            .typeError('Data Inválida')
            .min(
              minDate,
              `Data de início deve ser posterior à ${formatDate(
                subDays(minDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxDate,
              `Data de início deve ser anterior à ${formatDate(
                addDays(maxDate, 1).toISOString(),
              )}`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        toggleIncDialog();

        const send = btoa(
          JSON.stringify({
            mode: 'insert',
            ...data,
          }),
        );

        await api.post('/sgo/manuaisguias.php', send, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        getList();
        getMultCombos();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          insertForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      } finally {
        setIsRunning(false);
      }
    },
    [errorHandling, getList, getMultCombos, toggleIncDialog],
  );

  const handleDeleteSubmit = useCallback(
    async (data: { data: string }) => {
      try {
        deleteForm.current?.setErrors({});

        const minDate = handleTimeZone(new Date(selectedDeletion.date));
        const maxDate = handleTimeZone(new Date());

        const schema = yup.object().shape({
          data: yup
            .date()
            .typeError('Data Inválida')
            .min(
              minDate,
              `Data de início deve ser posterior à ${formatDate(
                subDays(minDate, 1).toISOString(),
              )}`,
            )
            .max(
              maxDate,
              `Data de início deve ser anterior à ${formatDate(
                addDays(maxDate, 1).toISOString(),
              )}`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        toggleDeleteDialog();

        // const send = btoa(
        //   JSON.stringify({
        //     mode: 'update',
        //     ...data,
        //     ...selectedDeletion,
        //     // gseq: selectedDeletion.gseq,
        //     // num: selectedDeletion.num,
        //     // mat: selectedDeletion.mat,
        //   }),
        // );
        const send = et64({
          mode: 'update',
          ...data,
          ...selectedDeletion,
          // gseq: selectedDeletion.gseq,
          // num: selectedDeletion.num,
          // mat: selectedDeletion.mat,
        });
        // await api.post('/sgo/manuaisguias.php', send);
        // por default é o mesmo que abaixo
        await api.post('/sgo/manuaisguias.php', send, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        getList();
        getMultCombos();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          deleteForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      errorHandling,
      getList,
      getMultCombos,
      selectedDeletion,
      toggleDeleteDialog,
    ],
  );

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const styledRemoveANB = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const styledRemoveLOC = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      {user.perfil === 'LOC' && (
        <IncludeButton isButton>
          <button type="button" onClick={toggleIncDialog}>
            <FaPlusCircle />
          </button>
        </IncludeButton>
      )}
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON', 'NAC'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="anb"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleANBChange}
                  />
                  <RemoveButton
                    type="button"
                    style={styledRemoveANB}
                    onClick={handleRemoveANB}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}

              <span>
                <p>Selecione a Local:</p>
                <SelectV2
                  name="local"
                  content={comboLocs}
                  initial={initialLoc}
                  onChange={handleLOCChange}
                />
                <RemoveButton
                  type="button"
                  style={styledRemoveLOC}
                  onClick={handleRemoveLOC}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>

              {/* <span>
                <p>Ano de referência:</p>
                <SelectV2 name="year" content={comboYears} initial={year} />
              </span>
              <span>
                <p>Semestre de referência</p>
                <SelectV2
                  name="semester"
                  content={comboSemesters}
                  initial={semester}
                />
              </span> */}
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Manual sob Responsabilidade do Guia</p>
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>

        <div>
          <Form ref={comboRef} onSubmit={() => null}>
            <SelectV2
              name="materiais"
              initial={initialMaterial}
              content={materiais}
              onChange={handleMatChange}
            />
          </Form>
        </div>

        <Button
          bgcolor="#332e2e"
          containerStyle={{ margin: '10px 0' }}
          onClick={handleDownload}
        >
          <p>Gerar planilha</p>&emsp;
          <FaFileExcel />
        </Button>
        <GridContainer>
          {list.map((item) => (
            <GridItem
              key={btoa(
                JSON.stringify({
                  mat: item.mat,
                  num: item.num,
                  gseq: item.gseq,
                }),
              )}
            >
              <span>
                <p>
                  Manual:&nbsp;<strong>{item.desc}</strong>
                </p>
              </span>
              <span>
                <p>
                  Número:&nbsp;<strong>{item.num}</strong>
                </p>
                <p>
                  Data de Entrega:&nbsp;<strong>{formatDate(item.date)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Guia:&nbsp;
                  <strong>
                    ({item.gcert}) - {item.name}
                  </strong>
                </p>
              </span>
              {user.perfil === 'LOC' && (
                <O.GridOptions>
                  <O.Delete
                    onClick={() =>
                      handleSelectedDeletion(btoa(JSON.stringify(item)))
                    }
                  >
                    <FaShare style={{ transform: 'rotateY(180deg)' }} />
                  </O.Delete>
                </O.GridOptions>
              )}
            </GridItem>
          ))}
        </GridContainer>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={insertForm} onSubmit={handleSubmit}>
            <I.Title>
              <h4>Atribuir Manual</h4>
            </I.Title>
            <I.Content>
              <IContentDiv>
                <span>
                  <p>Selecione o Manual:</p>
                  <SelectV2
                    name="manual"
                    content={incmateriais}
                    initial="Selecione"
                    onChange={handleIncMatChange}
                  />
                </span>
              </IContentDiv>
              <IContentDiv>
                <span>
                  <p>Selecione a numeração:</p>
                  <SelectV2
                    name="numero"
                    content={numbers}
                    initial="Selecione"
                  />
                </span>
              </IContentDiv>
              <IContentDiv>
                <span>
                  <p>Selecione o Guia:</p>
                  <SelectV2 name="guia" content={guias} initial="Selecione" />
                </span>
              </IContentDiv>
              <IContentDiv>
                <span>
                  <p>Data de entrega do Manual:</p>
                  <InputDialog
                    name="data"
                    // isDate
                    type="date"
                    placeholder="dd/mm/aaaa"
                    containerStyle={{
                      width: '228px',
                      padding: '5px 2px 4.82px 5px',
                      height: '37.5px',
                    }}
                  />
                </span>
              </IContentDiv>
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={toggleIncDialog}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit" disabled={isRunning}>
                Finalizar
              </I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <Form ref={deleteForm} onSubmit={handleDeleteSubmit}>
            <D.Title>
              <h2>*** ATENÇÃO ***</h2>
            </D.Title>
            <D.Content>
              <DeleteModalContent>
                <div>
                  <p>
                    Você está prestes a restituir o manual abaixo para a
                    coordenação:
                  </p>
                </div>
                <div>
                  <span>
                    <p>
                      Manual:&nbsp;<strong>{selectedDeletion.desc}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Número:&nbsp;<strong>{selectedDeletion.num}</strong>
                    </p>
                    <p>
                      Data de Entrega:&nbsp;
                      <strong>{formatDate(selectedDeletion.date)}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Guia:&nbsp;
                      <strong>
                        ({selectedDeletion.gcert}) - {selectedDeletion.name}
                      </strong>
                    </p>
                  </span>
                </div>
                <div>
                  <p>
                    Se estiver seguro da sua decisão, insira a data da
                    restituição e pressione confirmar.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <p style={{ margin: '0 5px 0 0' }}>Data da Restituição</p>
                  <InputDialog
                    name="data"
                    // isDate
                    type="date"
                    placeholder="dd/mm/aaaa"
                    containerStyle={{
                      width: '228px',
                      padding: '5px 2px 4.82px 5px',
                      height: '37.5px',
                    }}
                  />
                </div>
              </DeleteModalContent>
            </D.Content>
            <D.Actions>
              <D.Cancel type="button" onClick={toggleDeleteDialog}>
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>

        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>Gerar XLS</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
              <p>Aguarde...</p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() => setShow({ ...show, open: false })}
            >
              Ok
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <a
          style={{ display: 'none' }}
          id="xls"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_manual_sob_responsa.php?data=${et64({
            coord: JSON.stringify(coord),
            type,
          })}`}
          rel="noreferrer noopener"
          target="_top"
          // target="_blank"
        >
          XLS
        </a>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ManualGuia;
