import styled from 'styled-components';
import { shade } from 'polished';

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 800px;

  @media screen and (max-width: 800px) {
    width: 100vw;
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    align-items: center;
    min-height: 40px;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;

    &:nth-child(1) {
      text-align: center;
    }
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      text-align: right;
    }
  }
`;

export const TetoContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  strong {
    margin: 0 5px 0 0;
  }
  p {
    font-size: 18px;
    padding: 3px 7px;
    margin: 0 5px;
    border: 2px solid #464646;
    border-radius: 5px;
    background: #fff;
    font-weight: 500;
    color: #c53030;
  }
  button {
    margin: 0 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    color: #fff;

    border-radius: 3px;
    padding: 3px 7.5px;

    font-size: 18px;

    width: 30px;
    height: 30px;

    background: #007acc;

    transition: background-color 0.5s;

    &:hover {
      background: ${shade(0.2, '#007acc')};
    }
  }
`;
