import React, { useCallback, useEffect, useState, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Input from 'components/Input';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';
import { Container, ComboProps } from 'styles/sgo_wrappers';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import { useUtils } from 'hooks/utils';
import { convertSpecialChars } from 'utils/specialChars';

import api from 'services/api';
import {
  Content,
  GridContainer,
  AnimatedSection,
  SecundaryAddress,
} from '../insert/styles';

import { FormData } from '../insert';
import { CoordProps } from '../main';

export interface StateProps {
  coord: CoordProps;
}

const NacionaisUpdate: React.FC = () => {
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [coord, setCoord] = useState<CoordProps>({} as CoordProps);
  const [loading, setLoading] = useState(false);
  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasState, setHasState] = useState(false);
  const [altUF, setAltUF] = useState('Selecione');
  const [hasSecState, setHasSecState] = useState(false);
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  const [radioValue, setRadioValue] = useState('N');

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');
    const params = location.state?.coord;
    const checkform = formRef.current;
    response.data.filter((state: ComboProps) => {
      if (state.value === params.address?.uf) {
        checkform?.setData({
          ...checkform,
          state: state.value,
        });
        setHasState(true);
        setFirstUF(state.value);
      }

      if (state.value === params.delivery?.uf) {
        setHasSecState(true);
        setAltUF(state.value);
        checkform?.setData({
          ...checkform,
          alt_state: state.value,
        });
      }

      return state.value;
    });

    setComboEstados(response.data);
  }, [location.state]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
    } else {
      const params = location.state.coord;

      if (params.delivery.street !== '') {
        setRadioValue('S');
      }

      setCoord(location.state.coord);
      getUFs();
    }
  }, [location.state, location.pathname, history, getUFs]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          cnpj: yup.lazy((val) =>
            !val
              ? yup.string().nullable()
              : yup
                  .string()
                  .trim()
                  .min(18, 'O CNPJ deve conter 18 caracteres.'),
          ),
          bank: yup.string().nullable(true),
          agency: yup.string().nullable(true),
          account: yup.string().nullable(true),
          // cnpj: yup.string().min(18, 'O CNPJ deve conter 18 caracteres.'),
          // bank: yup.string().required('Informação obrigatória'),
          // agency: yup.string().required('Informação obrigatória'),
          // account: yup.string().required('Informação obrigatória'),
          cep: yup.string().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup.string().trim().required('Informação obrigatória'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup.string().trim().required('Informação obrigatória'),
          city: yup.string().trim().required('Informação obrigatória'),
          state: yup.string().notOneOf(['Selecione'], ' '),

          phone: yup
            .string()
            .trim()
            .min(14, 'O telefone deve conter no mínimo 14 caracteres.'),
          mail: yup
            .string()
            .trim()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          website: yup.string().nullable(),
          facebook: yup.string().nullable(),
          alt_cep: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup
              .string()
              .trim()
              .min(9, 'O CEP secundário deve conter 9 caracteres.'),
          }),
          alt_address: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_district: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_city: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_state: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().notOneOf(['Selecione'], ' '),
          }),
          alt_number: yup.string().nullable(true),
          // alt_number: yup.mixed().when('alt', {
          //   is: (val) => val === 'S',
          //   then: yup.number().typeError('Informação obrigatória'),
          // }),
          alt_compl: yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          anbcod: coord?.code,
          address: convertSpecialChars(data.address),
          number: !data.number ? 'S/N' : data.number,
          compl: data.compl || convertSpecialChars(data.compl),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          website: data.website || convertSpecialChars(data.website),
          facebook: data.facebook || convertSpecialChars(data.facebook),
          alt_address:
            data.alt === 'S' && data.alt_address
              ? convertSpecialChars(data.alt_address)
              : '',
          alt_number: !data.alt_number ? 'S/N' : data.alt_number,
          alt_compl:
            data.alt === 'S' && data.alt_compl
              ? convertSpecialChars(data.alt_compl)
              : '',
          alt_district:
            data.alt === 'S' && data.alt_district
              ? convertSpecialChars(data.alt_district)
              : '',
          alt_city:
            data.alt === 'S' && data.alt_city
              ? convertSpecialChars(data.alt_city)
              : '',
          alt_state: (data.alt === 'S' && data.alt_state) || null,
        };

        await api.get(`/sgo/anbs_update.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${coord.desc} alterada!`,
        });

        history.replace(location.pathname.replace('/update', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, addToast, history, location.pathname, coord],
  );

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].label);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleAltCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('alt_cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );
        if (state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setAltUF(comboEstados[index].label);
          setHasSecState(true);
        } else {
          setAltUF('Selecione');
        }

        form.setData({
          alt_address: (address && address.split(/[-,]/, 1)) || '',
          alt_city: city || '',
          alt_district: district || '',
          alt_state: state || '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleRadioClick = useCallback(() => {
    setRadioValue(formRef.current?.getFieldValue('alt'));
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração de Nacional" />
      <Content>
        <Form
          ref={formRef}
          initialData={{
            cnpj: coord?.cnpj,
            bank: coord?.banco,
            agency: coord?.agencia,
            account: coord?.conta,
            cep: coord.address?.code,
            state: coord.address?.uf,
            address: coord.address?.street.split(',', 1),
            number: coord.address?.street
              .substr(coord.address?.street.indexOf(',') + 1)
              .replace(/\s/g, '')
              .split('-', 1),
            compl:
              coord.address?.street.substring(
                coord.address?.street.indexOf('-') + 1,
              ) === coord.address?.street
                ? ''
                : coord.address?.street.substring(
                    coord.address?.street.indexOf('-') + 1,
                  ),
            district: coord.address?.district,
            city: coord.address?.city,
            phone: coord?.phone,
            mail: coord?.mail,
            website: coord?.web,
            facebook: coord?.facebook,

            alt_cep: coord.delivery?.code,
            alt_state: coord.delivery?.uf,
            alt_address: coord.delivery?.street.split(',', 1),
            alt_number: coord.delivery?.street
              .substr(coord.delivery?.street.indexOf(',') + 1)
              .split('-', 1),
            alt_compl:
              coord.delivery?.street.substring(
                coord.delivery?.street.indexOf('-') + 1,
              ) === coord.delivery?.street
                ? ''
                : coord.delivery?.street.substring(
                    coord.delivery?.street.indexOf('-') + 1,
                  ),
            alt_district: coord.delivery?.district,
            alt_city: coord.delivery?.city,
          }}
          onSubmit={handleSubmit}
        >
          <figure>
            <p>{coord?.desc}</p>
          </figure>
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>CNPJ</p>
                <Input name="cnpj" placeholder="N° CNPJ" mask="cnpj" />
              </span>

              <span>
                <p>Banco</p>
                <Input name="bank" placeholder="Código - Banco" />
              </span>
              <span>
                <p>Agência</p>
                <Input name="agency" placeholder="N° da Agência" />
              </span>
              <span>
                <p>Conta</p>
                <Input name="account" placeholder="N° da Conta e dígito" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>
              <span>
                <p>Endereço</p>
                <Input name="address" placeholder="Endereço" />
              </span>
              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>
              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>
              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.7} duration={1.1}>
              <span>
                <p>Contato</p>
                <Input
                  name="phone"
                  placeholder="Telefone ou Celular"
                  mask="phone"
                />
              </span>

              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-Mail" />
              </span>

              <span>
                <p>Página Web</p>
                <Input name="website" placeholder="Página Web" />
              </span>

              <span>
                <p>Facebook</p>
                <Input name="facebook" placeholder="https://facebook.com/" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={1.05} duration={1.1}>
              <RadioContainer
                title="Há endereço alternativo para entrega?"
                name="alt"
                selected={
                  location.state.coord.delivery.street !== '' ? 'S' : 'N'
                }
                content={[
                  {
                    id: 'aS',
                    value: 'S',
                    label: 'Sim, o endereço é diferente',
                  },
                  {
                    id: 'aN',
                    value: 'N',
                    label: 'Não, desejo usar o mesmo endereço',
                  },
                ]}
                onClick={handleRadioClick}
              />

              <SecundaryAddress active={radioValue === 'S'}>
                <span>
                  <p>CEP</p>
                  <Input
                    name="alt_cep"
                    placeholder="N° CEP"
                    mask="cep"
                    onChange={handleAltCep}
                  />
                </span>
                <span>
                  <p>Estado</p>
                  <SelectV2
                    name="alt_state"
                    initial={altUF}
                    content={comboEstados}
                    dropdownStyle={{
                      maxHeight: '130px',
                      borderColor: '#a6a6a6',
                    }}
                    containerStyle={{ borderColor: '#a6a6a6' }}
                    disabled={!!hasSecState}
                  />
                </span>
                <span>
                  <p>Endereço</p>
                  <Input name="alt_address" placeholder="Endereço" />
                </span>
                <span>
                  <p>Número</p>
                  <Input name="alt_number" placeholder="S/N" mask="number" />
                </span>
                <span>
                  <p>Complemento</p>
                  <Input name="alt_compl" placeholder="Complemento" />
                </span>
                <span>
                  <p>Bairro</p>
                  <Input name="alt_district" placeholder="Bairro" />
                </span>
                <span>
                  <p>Cidade</p>
                  <Input name="alt_city" placeholder="Cidade" />
                </span>
              </SecundaryAddress>
            </AnimatedSection>
          </GridContainer>

          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default NacionaisUpdate;
