import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

const TOV = `${process.env.REACT_APP_ASSETS_DIR}/header/banner_tov.jpg`;
const SGO = `${process.env.REACT_APP_ASSETS_DIR}/header/banner_sgo.jpg`;

interface HeaderProps {
  web?: boolean;
  disabledLink?: boolean;
}
export const Container = styled.header<HeaderProps>`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100vw;

  > a {
    height: 200px;

    width: 100vw;
    background-image: url(${(props) => (props.web ? TOV : SGO)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: height 0.25s;

    ${(props) =>
      props.disabledLink &&
      css`
        pointer-events: none;
      `}
  }

  @media screen and (max-width: 900px) {
    > a {
      height: 100px;
      width: 100vw;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
`;

export const Error = styled(Tooltip)`
  position: absolute;
  z-index: 1;
  bottom: 5px;
  right: 25px;

  padding: 5px;
  background-image: linear-gradient(
    rgba(117, 0, 0, 1),
    rgba(203, 0, 1, 1),
    rgba(117, 0, 0, 1)
  );
  border: 2px solid #6a0002;
  border-radius: 5px;
  color: #efefef;
  display: flex;
  align-items: center;
  font-weight: 600;

  svg {
    margin-left: 5px;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
