import React, { useState, useEffect, useCallback, useRef } from 'react';

import { subYears, addDays, format } from 'date-fns';

import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { Container, ComboProps, ComboLocalProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import Input from 'components/Input';
import RadioContainer from 'components/RadioContainer';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import { convertSpecialChars } from 'utils/specialChars';
import { Content, GridContainer, AnimatedSection } from './styles';

import { gestcivil } from '../gestcivil';

export interface FormData {
  name: string;
  type: string;
  access: string;
  env: string;
  zon: string;
  anb: string;
  loc: string;
  cep: string;
  state: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  resPhone: string;
  celPhone: string;
  mail: string;
  facebook: string;
  sex: 'F' | 'M';
  dtnasc: string;
  locnasc: string;
  rg: string;
  estcivil: string;
  ocupation: string;
  alt_lang1: string;
  alt_lang2: string;
}

const GuiasInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboTG, setComboTG] = useState<ComboProps[]>([]);
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  // const [comboEstCivil, setComboEstCivil] = useState<ComboProps[]>(gestcivil);

  const comboEstCivil: ComboProps[] = gestcivil;

  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);
  const [hasState, setHasState] = useState(false);

  const [initialZON, setInitialZON] = useState('Selecione');
  const [initialANB, setInitialANB] = useState('Selecione');
  const [initialLOC, setInitialLOC] = useState('Selecione');
  const [TG, setTG] = useState('');

  const getComboTG = useCallback(async () => {
    const response = await api.get('/combos/comboTG.php');

    setComboTG(response.data);
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    if (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 || user.adm) {
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
      );
    }
  }, [user.perfil, user.anbc, user.adm]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');

    setComboEstados(response.data);
  }, []);

  useEffect(() => {
    if (!user.adm) {
      handlePermission(['INT', 'ZON', 'NAC', 'LOC', 'GUI']);
    }
    getComboZON();
    getComboANB();
    getComboLOC();
    getComboTG();
    getUFs();

    formRef?.current?.setFieldValue('zon', user.zoncod);
    formRef?.current?.setFieldValue('anb', user.anbc);
    formRef?.current?.setFieldValue('loc', user.loccod);
    formRef?.current?.setFieldValue('type', '');
    formRef?.current?.setFieldValue('state', '');
    formRef?.current?.setFieldValue('estcivil', '');
    formRef?.current?.setFieldValue('sex', '');

    setInitialZON(user.zoncod);
    setInitialANB(user.anbc);
    setInitialLOC(user.loccod);
  }, [
    getComboZON,
    getComboANB,
    getComboLOC,
    getComboTG,
    getUFs,
    user.zoncod,

    user.anbc,

    user.loccod,

    handlePermission,
    user.adm,
  ]);

  const handleTGSelect = useCallback(() => {
    const tg = formRef.current?.getFieldValue('type');

    setTG(tg);
  }, []);

  const handleZONSelect = useCallback(() => {
    const zon = formRef.current?.getFieldValue('zon');

    formRef.current?.setFieldValue('anb', '');
    formRef.current?.setFieldValue('loc', '');

    const defineNacs = nacs.filter(
      (item: ComboProps) => item.value.substr(0, 2) === zon.substr(0, 2),
    );

    setComboNacs(defineNacs);

    setComboLocs([]);

    setInitialANB('Selecione');
    setInitialLOC('Selecione');
  }, [nacs]);

  const handleANBSelect = useCallback(() => {
    const anb = formRef.current?.getFieldValue('anb');

    formRef.current?.setFieldValue('loc', '');

    const defineLocs = locs.filter((item: ComboLocalProps) => item.anb === anb);
    setComboLocs(defineLocs);

    setInitialLOC('Selecione');
  }, [locs]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setFirstUF('Selecione');
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          type: yup.string().notOneOf([''], ' '),
          access: yup.string().when('type', {
            is: (val) => val === 'FG' || val === 'FD',
            then: yup
              .string()
              .min(11, 'Quantidade mínima de caracteres não atingida (11).')
              .max(11, 'Quantidade máxima de caracteres excedida (11).'),
            otherwise: yup
              .string()
              .trim()
              .min(5, 'Quantidade mínima de caracteres não atingida (5).')
              .max(6, 'Quantidade máxima de caracteres excedida (6).'),
          }),
          mail: yup
            .string()
            .trim()
            .email('Formato inválido de email')
            .required('Este campo é obrigatório'),
          env: yup.date().when('type', {
            is: (val) => val === 'FG' || val === 'FD',
            then: yup
              .date()
              .nullable()
              .transform((curr, orig) => (orig === '' ? null : curr))
              .notRequired(),
            otherwise: yup
              .date()
              .typeError('Data inválida.')
              .min(
                new Date('1985-01-01'),
                'A data de envio deve ser posterior a 31/12/1984.',
              )
              .max(
                new Date(),
                `A data de envio deve ser anterior a ${format(
                  addDays(new Date(), 1),
                  'd/MM/yyyy',
                )}.`,
              ),
          }),
          zon: yup.string().notOneOf([''], ' '),
          anb: yup.string().notOneOf([''], ' '),
          loc: yup.string().notOneOf([''], ' '),
          cep: yup.string().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          state: yup.string().notOneOf([''], ' '),
          district: yup
            .string()
            .trim()
            .trim()
            .min(2, 'Quantidade mínima de caracteres não atingida (2).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          resPhone: yup.lazy(() =>
            data.celPhone !== ''
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    13,
                    'Esse campo deve ser preenchido caso não possua celular.',
                  ),
          ),
          celPhone: yup.lazy(() =>
            data.resPhone !== ''
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    14,
                    'Esse campo deve ser preenchido caso não possua telefone fixo.',
                  ),
          ),
          sex: yup.string().oneOf(['F', 'M'], 'Sexo do guia é obrigatório'),
          dtnasc: yup
            .date()
            .typeError('Data inválida.')
            .min(
              new Date(subYears(new Date(), 100)),
              `Não é possível cadastrar guias com data de nascimento anterior a ${format(
                addDays(subYears(new Date(), 100), 1),
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              new Date(subYears(new Date(), 18)),
              'O guia deve ter no mínimo 18 anos.',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          name: convertSpecialChars(data.name),
          address: convertSpecialChars(data.address),
          number: convertSpecialChars(data.number) || 'S/N',
          compl: convertSpecialChars(data.compl),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          zon: user.adm ? data.zon : user.zoncod,
          anb:
            ['INT', 'ZON'].indexOf(user.perfil) > 0 || user.adm
              ? data.anb
              : user.anbc,
          loc:
            ['INT', 'ZON', 'NAC'].indexOf(user.perfil) > 0 || user.adm
              ? data.loc
              : user.loccod,
        };

        await api.get(`/sgo/guias_insert.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            ['FG', 'FD'].indexOf(data.type) > -1 ? 'Futuro g' : 'G'
          }uia ${data.name} incluído!`,
        });

        history.replace(location.pathname.replace('/insert', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      errorHandling,
      user.adm,
      user.zoncod,
      user.anbc,
      user.loccod,
      user.perfil,
      history,
      location.pathname,
      addToast,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro de Guia" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Nome do Guia</p>
                <Input name="name" placeholder="Nome do Guia" mask="name" />
              </span>
              <span>
                <p>Tipo de Guia</p>
                <SelectV2
                  name="type"
                  content={comboTG}
                  initial="Selecione"
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  // containerStyle={{ borderColor: '#a6a6a6' }}
                  onChange={handleTGSelect}
                />
              </span>
              <span>
                <p>N° Certificado</p>
                <Input
                  name="access"
                  placeholder={
                    TG !== 'FG' ? 'Número do certificado' : 'Número do CPF'
                  }
                  disabled={TG === ''}
                  type="number"
                  min={1}
                />
              </span>
              <span>
                <p>Data de Envio</p>
                <Input
                  isDate
                  name="env"
                  placeholder="dd/mm/aaaa"
                  disabled={TG === 'FG' || TG === 'FD'}
                />
              </span>
              {user.adm && (
                <span>
                  <p>Coordenação Zonal</p>
                  <SelectV2
                    name="zon"
                    content={comboZons}
                    initial={initialZON}
                    dropdownStyle={{
                      maxHeight: '130px',
                      borderColor: '#a6a6a6',
                    }}
                    // containerStyle={{ borderColor: '#a6a6a6' }}
                    onChange={handleZONSelect}
                  />
                </span>
              )}
              {(['INT', 'ZON'].indexOf(user.perfil) > -1 || user.adm) && (
                <span>
                  <p>Coordenação Nacional</p>
                  <SelectV2
                    name="anb"
                    content={comboNacs}
                    initial={initialANB}
                    dropdownStyle={{
                      maxHeight: '130px',
                      borderColor: '#a6a6a6',
                    }}
                    // containerStyle={{ borderColor: '#a6a6a6' }}
                    onChange={handleANBSelect}
                  />
                </span>
              )}

              {(['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 ||
                user.adm) && (
                <span>
                  <p>Coordenação Local</p>
                  <SelectV2
                    name="loc"
                    content={comboLocs}
                    initial={initialLOC}
                    dropdownStyle={{
                      maxHeight: '130px',
                      borderColor: '#a6a6a6',
                    }}
                    // containerStyle={{ borderColor: '#a6a6a6' }}
                  />
                </span>
              )}
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>

              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  // containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>

              <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>

              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>

              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>

              <span>
                <p>Bairro</p>
                <Input
                  name="district"
                  placeholder="Bairro"
                  disabled={!!hasDistrict}
                />
              </span>

              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" disabled={!!hasCity} />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.7} duration={1.1}>
              <span>
                <p>Telefone</p>
                <Input
                  name="resPhone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
              <span>
                <p>Celular</p>
                <Input
                  name="celPhone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-Mail" />
              </span>
              <span>
                <p>Facebook</p>
                <Input name="facebook" placeholder="https://facebook.com/" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={1.05} duration={1.1}>
              <span>
                <p>Cidade Natal</p>
                <Input name="locnasc" placeholder="Local de nascimento" />
              </span>
              <span>
                <p>Data de Nascimento</p>
                <Input name="dtnasc" isDate placeholder="dd/mm/aaaa" />
              </span>

              <RadioContainer
                title="Sexo"
                name="sex"
                containerStyle={{ alignItems: 'flex-start' }}
                itemsStyle={{ justifyContent: 'flex-start' }}
                content={[
                  {
                    id: 'gM',
                    value: 'M',
                    label: 'Masculino',
                  },
                  {
                    id: 'gF',
                    value: 'F',
                    label: 'Feminino',
                  },
                ]}
                // onClick={handleRadioClick}
              />

              <span style={{ marginTop: '10px' }}>
                <p>RG do Guia</p>
                <Input name="rg" placeholder="N° RG" />
              </span>
              <span style={{ marginTop: '10px' }}>
                <p>Estado Civil</p>
                <SelectV2
                  name="estcivil"
                  initial="Selecione"
                  content={comboEstCivil}
                  // disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>
              <span>
                <p>Profissão</p>
                <Input name="ocupation" placeholder="Profissão" />
              </span>
              <span>
                <p>Idioma 2</p>
                <Input name="alt_lang1" placeholder="Idioma" />
              </span>
              <span>
                <p>Idioma 3</p>
                <Input name="alt_lang2" placeholder="Idioma" />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Cadastrar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GuiasInsert;
