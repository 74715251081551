import React, { useState, useCallback, useRef, useEffect } from 'react';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalServProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import InputDialog from 'components/InputDialog';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import api from 'services/api';
import Button from 'components/Button';
import { format, addDays, getDay, parseISO, isValid, subDays } from 'date-fns';
import getValidationErrors from 'utils/getValidationErrors';

import moment from 'moment';

import * as yup from 'yup';
import { addYears } from 'date-fns/esm';
import { formatDate } from 'utils/formatDate';
import { setLocalStorage } from 'utils/handleLocalStorage';
import { Grid, GridItem } from './styles';

interface FormData {
  e1lserv: string;
  e1dti: string;
  e1dtf: string;
  e1wd: string;
  e1hr: string;
  e1gap: string;
  e2lserv: string;
  e2dti: string;
  e2dtf: string;
  e2wd: string;
  e2hr: string;
  e2gap: string;
  e3dti: string;
  e3dtf: string;
  e3wd: string;
  e3hr: string;
  e3gap: string;
}

interface ComboGuias {
  seq: string;
  cert: string;
  nome: string;
  anb: string;
  loc: string;
  tg: string;
  func: string;
}

export interface DatesProps {
  minINIe1: Date;
  maxINIe1: Date;
  minINIe2: Date;
  maxINIe2: Date;
  minINIe3: Date;
  maxINIe3: Date;
}

const EPGInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const history = useHistory();

  const [comboLServ, setComboLServ] = useState<ComboProps[]>([]);
  const [lserv, setLServ] = useState('Selecione');
  const [comboGuias, setComboGuias] = useState<ComboProps[]>([]);
  const [minDate] = useState(`${new Date().getUTCFullYear()}-01-01`);
  const [maxDate] = useState(`${new Date().getUTCFullYear() + 1}-12-31`);

  const [wd] = useState('Selecione');
  const [gap1, setGap1] = useState('Selecione');
  const [gap2, setGap2] = useState('Selecione');
  const [gap3, setGap3] = useState('Selecione');
  const [df1] = useState(56);
  const [di2] = useState(6);
  const [df2] = useState(105);
  const [df3] = useState(105);
  const [rangeDF1] = useState<{ min: number; max: number }>({
    min: df1 - 10,
    max: df1 + 10,
    // min: df1 - 30,
    // max: df1 + 30,
  });
  const [rangeDF2] = useState<{ min: number; max: number }>({
    min: df2 - 10,
    max: df2 + 10,
    // min: df2 - 30,
    // max: df2 + 30,
  });
  const [rangeDF3] = useState<{ min: number; max: number }>({
    min: df3 - 10,
    max: df3 + 10,
    // min: df3 - 35,
    // max: df3 + 35,
  });

  const hourValidation = (hour: string): boolean => {
    const hourstyle = 'hh:mm';

    const time = moment(hour, hourstyle);
    const init = moment('06:59', hourstyle);
    const finale = moment('22:01', hourstyle);

    return time.isBetween(init, finale);
  };

  const createDate = (date: string): Date => {
    const theDate = new Date(
      new Date(date).getUTCFullYear(),
      new Date(date).getUTCMonth(),
      new Date(date).getUTCDate(),
      0,
      0,
    );
    return theDate;
  };

  const checkGapE1F2 = useCallback(() => {
    const init1 = formRef.current?.getFieldValue('e1dti');
    const end2 = formRef.current?.getFieldValue('e2dtf');

    const formats = 'YYYY-mm-dd';

    const base = moment(createDate(end2), formats);
    const compare = moment(addDays(createDate(init1), 168), formats);

    return base.isSameOrBefore(compare);
  }, []);

  const checkGapF2E3 = useCallback(() => {
    const end2 = formRef.current?.getFieldValue('e2dtf');
    const init3 = formRef.current?.getFieldValue('e3dti');

    const formats = 'YYYY-mm-dd';

    const base = moment(createDate(init3), formats);
    const compare = moment(addDays(createDate(end2), 61), formats);

    return base.isSameOrBefore(compare);
  }, []);

  const semana: ComboProps[] = [
    { value: '1', label: 'Segunda' },
    { value: '2', label: 'Terça' },
    { value: '3', label: 'Quarta' },
    { value: '4', label: 'Quinta' },
    { value: '5', label: 'Sexta' },
    { value: '6', label: 'Sábado' },
    { value: '0', label: 'Domingo' },
  ];

  const getGuias = useCallback(async () => {
    const response = await api.get(
      `/combos/comboGuias.php?data=${JSON.stringify({ filter: 'byLoc' })}`,
    );

    setComboGuias(
      response.data
        .filter(
          (item: ComboGuias) =>
            item.loc === user.loccod && ['GA', 'GP'].indexOf(item.tg) > -1,
        )
        .map((item: ComboGuias) => ({ label: item.nome, value: item.seq })),
    );
  }, [user.loccod]);

  const getLocalServ = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/combos/comboLocalServ.php`);

      setComboLServ(
        response.data.map((item: ComboLocalServProps) => ({
          value: item.cod,
          label: `${item.nome}, ${item.cid}`,
        })),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    getLocalServ();
    if (user.perfil !== 'LOC') {
      formRef.current?.setData({
        e1gap: user.gseq,
        e2gap: user.gseq,
        e3gap: user.gseq,
      });
    } else {
      getGuias();
    }
  }, [getGuias, getLocalServ, user.gseq, user.perfil]);

  const handleLServ = useCallback(() => {
    const ls = formRef.current?.getFieldValue('e1lserv');
    const ls2 = formRef.current?.getFieldValue('e2lserv');
    const index = comboLServ.findIndex((item) => item.value === ls);

    if (ls2 === '') {
      setLServ(comboLServ[index].value);
    }
  }, [comboLServ]);

  const handleGuiaE1 = useCallback(() => {
    const guia = formRef.current?.getFieldValue('e1gap');
    const guia2 = formRef.current?.getFieldValue('e2gap');
    const guia3 = formRef.current?.getFieldValue('e3gap');
    const index = comboGuias.findIndex((item) => item.value === guia);

    setGap1(comboGuias[index].value);
    if (guia2 === '') {
      setGap2(comboGuias[index].value);
    }
    if (guia3 === '') {
      setGap3(comboGuias[index].value);
    }
  }, [comboGuias]);

  const handleGuiaE2 = useCallback(() => {
    const guia2 = formRef.current?.getFieldValue('e2gap');
    const guia3 = formRef.current?.getFieldValue('e3gap');
    const index = comboGuias.findIndex((item) => item.value === guia2);

    setGap2(comboGuias[index].value);
    if (guia3 !== '' && guia3 !== guia2) {
      setGap3(comboGuias[index].value);
    }
  }, [comboGuias]);

  const handleDI1 = useCallback(() => {
    const dti1 = formRef.current?.getFieldValue('e1dti');
    const parsed = new Date(parseISO(dti1));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e1dti');
      input.value = '';
      return;
    }

    const weekday = getDay(parsed).toString();
    // setWD(weekday);
    const wd1 = formRef.current?.getFieldRef('e1wd');
    wd1.value = weekday;

    formRef.current?.setFieldValue(
      'e1dtf',
      format(addDays(parsed, df1), 'yyyy-MM-dd'),
    );
    formRef.current?.getFieldRef('e1dtf').focus();
    formRef.current?.getFieldRef('e1dtf').blur();
  }, [df1]);

  const handleDF1 = useCallback(() => {
    const dtf1 = formRef.current?.getFieldValue('e1dtf');
    const parsed = new Date(parseISO(dtf1));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e1dtf');
      input.value = '';
      return;
    }

    formRef.current?.setFieldValue(
      'e2dti',
      format(addDays(parsed, di2), 'yyyy-MM-dd'),
    );

    formRef.current?.getFieldRef('e2dti').focus();
    formRef.current?.getFieldRef('e2dti').blur();
  }, [di2]);

  const handleDI2 = useCallback(() => {
    const dti2 = formRef.current?.getFieldValue('e2dti');
    const parsed = new Date(parseISO(dti2));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e2dti');
      input.value = '';
      return;
    }

    const weekday = getDay(parsed).toString();
    // setWD(weekday);
    const wd2 = formRef.current?.getFieldRef('e2wd');
    wd2.value = weekday;

    formRef.current?.setFieldValue(
      'e2dtf',
      format(addDays(parsed, df2), 'yyyy-MM-dd'),
    );

    formRef.current?.getFieldRef('e2dtf').focus();
    formRef.current?.getFieldRef('e2dtf').blur();
  }, [df2]);

  const handleDF2 = useCallback(() => {
    const dtf2 = formRef.current?.getFieldValue('e2dtf');
    const parsed = new Date(parseISO(dtf2));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e2dtf');
      input.value = '';
    }
  }, []);

  const handleDI3 = useCallback(() => {
    const dti3 = formRef.current?.getFieldValue('e3dti');
    const parsed = new Date(parseISO(dti3));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e3dti');
      input.value = '';
      return;
    }

    formRef.current?.setFieldValue(
      'e3dtf',
      format(addDays(parsed, df3), 'yyyy-MM-dd'),
    );

    formRef.current?.getFieldRef('e3dtf').focus();
    formRef.current?.getFieldRef('e3dtf').blur();
  }, [df3]);

  const handleDF3 = useCallback(() => {
    const dtf3 = formRef.current?.getFieldValue('e3dtf');
    const parsed = new Date(parseISO(dtf3));

    if (!isValid(parsed)) {
      const input = formRef.current?.getFieldRef('e3dtf');
      input.value = '';
    }
  }, []);

  const handleHR1 = useCallback(() => {
    const hr = formRef.current?.getFieldRef('e1hr');

    if (hr) {
      if (!hr.value) {
        hr.type = 'text';
        return;
      }
    }
    formRef.current?.setFieldValue('e2hr', hr.value);
    formRef.current?.getFieldRef('e2hr').focus();
    formRef.current?.getFieldRef('e2hr').blur();
  }, []);

  const handleHR2 = useCallback(() => {
    const hr = formRef.current?.getFieldRef('e2hr');

    if (hr) {
      if (!hr.value) {
        hr.type = 'text';
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schemaValidateDate = yup.object().shape({
          e1dti: yup.date().typeError('Data inválida.'),
          e2dti: yup.date().typeError('Data inválida.'),
          e3dti: yup.date().typeError('Data inválida.'),
        });

        await schemaValidateDate.validate(data, {
          abortEarly: false,
        });

        const iniE1 = createDate(formRef.current?.getFieldValue('e1dti'));
        const iniE2 = createDate(formRef.current?.getFieldValue('e2dti'));
        const iniE3 = createDate(formRef.current?.getFieldValue('e3dti'));
        const min = createDate(minDate);
        const max = createDate(maxDate);

        const validYear = yup.object().shape({
          e1dti: yup
            .date()
            .min(
              min,
              `Data de início deve ser posterior à ${formatDate(
                subDays(min, 1).toISOString(),
              )}`,
            )
            .max(
              max,
              `Data de início deve ser anterior à ${formatDate(
                addDays(max, 1).toISOString(),
              )}`,
            ),
          e2dti: yup
            .date()
            .min(
              min,
              `Data de início deve ser posterior à ${formatDate(
                subDays(min, 1).toISOString(),
              )}`,
            )
            .max(
              max,
              `Data de início deve ser anterior à ${formatDate(
                addDays(max, 1).toISOString(),
              )}`,
            ),
          e3dti: yup
            .date()
            .min(
              min,
              `Data de início deve ser posterior à ${formatDate(
                subDays(min, 1).toISOString(),
              )}`,
            )
            .max(
              iniE1.getUTCFullYear() > min.getUTCFullYear()
                ? addYears(max, 1)
                : max,
              `Data de início deve ser anterior à ${formatDate(
                addDays(
                  iniE1.getUTCFullYear() > min.getUTCFullYear()
                    ? addYears(max, 1)
                    : max,
                  1,
                ).toISOString(),
              )}`,
            ),
        });

        await validYear.validate(data, {
          abortEarly: false,
        });

        const semE1 = iniE1.getMonth() < 6 ? 1 : 2;
        const semE2 = iniE2.getMonth() < 6 ? 1 : 2;
        const semE3 = iniE3.getMonth() < 6 ? 1 : 2;

        const dates: DatesProps = {
          minINIe1:
            semE1 === 1
              ? new Date(iniE1.getFullYear(), 0, 2, 0, 0)
              : new Date(iniE1.getFullYear(), 6, 1, 0, 0),
          maxINIe1:
            semE1 === 1
              ? new Date(iniE1.getFullYear(), 0, 10, 0, 0)
              : new Date(iniE1.getFullYear(), 6, 5, 0, 0),
          minINIe2:
            semE2 === 1
              ? new Date(iniE2.getFullYear(), 2, 5, 0, 0)
              : new Date(iniE2.getFullYear(), 8, 2, 0, 0),
          maxINIe2:
            semE2 === 1
              ? new Date(iniE2.getFullYear(), 2, 15, 0, 0)
              : new Date(iniE2.getFullYear(), 8, 5, 0, 0),
          minINIe3:
            semE3 === 1
              ? addYears(new Date(iniE1.getFullYear(), 0, 2, 0, 0), 1)
              : new Date(iniE1.getFullYear(), 6, 1, 0, 0),

          maxINIe3:
            semE3 === 1
              ? addYears(new Date(iniE1.getFullYear(), 1, 15, 0, 0), 1)
              : new Date(iniE3.getFullYear(), 7, 10, 0, 0),
        };

        const s1di = iniE1;

        const s2di = iniE2;

        const s3di = iniE3;

        const schema = yup.object().shape({
          e1lserv: yup.string().notOneOf(['Selecione', '']),
          e1dti: yup
            .date()
            .typeError('Data inválida.')
            .min(
              dates.minINIe1,
              `Data de início deve ser posterior à ${formatDate(
                subDays(dates.minINIe1, 1).toISOString(),
              )}`,
            )
            .max(
              dates.maxINIe1,
              `Data de início deve ser anterior à ${formatDate(
                addDays(dates.maxINIe1, 1).toISOString(),
              )}`,
            ),
          e1dtf: yup
            .date()
            .typeError('Data inválida.')
            .min(
              addDays(s1di, rangeDF1.min),
              `Data mínima permitida: ${formatDate(
                addDays(s1di, rangeDF1.min).toISOString(),
              )}`,
            )
            .max(
              addDays(s1di, rangeDF1.max),
              `Data máxima permitida: ${formatDate(
                addDays(s1di, rangeDF1.max).toISOString(),
              )}`,
            ),
          e1wd: yup.string().notOneOf(['Selecione', '']),
          e1hr: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .test(
                    'hour1',
                    'Horário deve ser entre 07h00 e 22h00',
                    (hour: string) => {
                      return hourValidation(hour);
                    },
                  )
              : yup.string().required('Campo obrigatório'),
          ),
          e1gap: yup.string().notOneOf(['Selecione', '']),
          e2lserv: yup.string().notOneOf(['Selecione', '']),
          e2dti: yup
            .date()
            .typeError('Data inválida.')
            .min(
              dates.minINIe2,
              `Data mínima permitida: ${formatDate(
                dates.minINIe2.toISOString(),
              )}`,
            )
            .max(
              dates.maxINIe2,
              `Data inicial deve ser anterior à ${formatDate(
                addDays(dates.maxINIe2, 1).toISOString(),
              )}`,
            ),
          e2dtf: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'maxENDet2',
              'Limite entre período de etapas excedido.',
              checkGapE1F2,
            )
            .min(
              addDays(s2di, rangeDF2.min),
              // addDays(s2di, rangeDF2.min),
              `Data mínima permitida: ${formatDate(
                addDays(s2di, rangeDF2.min).toISOString(),
              )}`,
            )
            .max(
              addDays(s2di, rangeDF2.max),
              `Data máxima permitida: ${formatDate(
                addDays(s2di, rangeDF2.max).toISOString(),
              )}`,
            ),
          e2wd: yup.string().notOneOf(['Selecione', '']),
          e2hr: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .test(
                    'hour2',
                    'Horário deve ser entre 07h00 e 22h00',
                    (hour: string) => {
                      return hourValidation(hour);
                    },
                  )
              : yup.string().required('Campo obrigatório'),
          ),
          e2gap: yup.string().notOneOf(['Selecione', '']),
          e3dti: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'minIniET3',
              'Limite entre período de etapas excedido.',
              checkGapF2E3,
            )
            .min(
              dates.minINIe3,
              `Data mínima permitida: ${formatDate(
                dates.minINIe3.toISOString(),
              )}`,
            )
            .max(
              dates.maxINIe3,
              `Data inicial deve ser anterior à ${formatDate(
                addDays(dates.maxINIe3, 1).toISOString(),
              )}`,
            ),
          e3dtf: yup
            .date()
            .typeError('Data inválida.')
            .min(
              addDays(s3di, rangeDF3.min),
              // addDays(s3di, rangeDF3.min),
              `Data mínima permitida: ${formatDate(
                addDays(s3di, rangeDF3.min).toISOString(),
              )}`,
            )
            .max(
              addDays(s3di, rangeDF3.max),
              `Data máxima permitida: ${formatDate(
                addDays(s3di, rangeDF3.max).toISOString(),
              )}`,
            ),
          e3gap: yup.string().notOneOf(['Selecione', '']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append('data', JSON.stringify({ ...data }));

        await api.post('/sgo/epg_insert.php', send, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'EPG cadastrada',
        });

        setLocalStorage(`${process.env.REACT_APP_EPG}`, {
          year: new Date(
            parseISO(formRef.current?.getFieldValue('e1dti')),
          ).getUTCFullYear(),
        });
        history.goBack();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      checkGapE1F2,
      checkGapF2E3,
      errorHandling,
      history,
      maxDate,
      minDate,
      rangeDF1.max,
      rangeDF1.min,
      rangeDF2.max,
      rangeDF2.min,
      rangeDF3.max,
      rangeDF3.min,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro de EPG" />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          // initialData={{
          //   e1dti: '2022-01-14',
          //   e1dtf: '2022-03-11',
          //   e2dti: '2022-03-17',
          //   e2dtf: '2022-06-30',
          //   e3dti: '2022-08-03',
          //   e3dtf: '2022-11-16',
          // }}
        >
          <Grid>
            <GridItem>
              <h2>1ª Etapa</h2>
              <div>
                <div>
                  <span>
                    <p>Local de Serviço/Atividade</p>
                    <SelectV2
                      name="e1lserv"
                      content={comboLServ}
                      initial={lserv}
                      onChange={handleLServ}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <p>Data de Início</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e1dti', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e1dti"
                      onBlur={handleDI1}
                    />
                  </span>
                  <span>
                    <p>Data Final</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e1dtf', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e1dtf"
                      onBlur={handleDF1}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <p>Dia da Semana</p>
                    <SelectV2
                      name="e1wd"
                      content={semana}
                      containerStyle={{
                        width: '172.5px',
                        height: '37.5px',
                      }}
                      initial={wd}
                    />
                  </span>
                  <span>
                    <p>Horário</p>
                    <InputDialog
                      type="time"
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      name="e1hr"
                      onBlur={handleHR1}
                      placeholder="--:--"
                    />
                  </span>
                </div>

                <div>
                  <span>
                    <p>Guia Aplicador</p>
                    {user.perfil !== 'LOC' ? (
                      <>
                        <InputDialog isHidden name="e1gap" />
                        <h3>{user.name}</h3>
                      </>
                    ) : (
                      <SelectV2
                        name="e1gap"
                        content={comboGuias}
                        initial={gap1}
                        onChange={handleGuiaE1}
                      />
                    )}
                  </span>
                </div>
              </div>
            </GridItem>
            <GridItem>
              <h2>2ª Etapa</h2>
              <div>
                <div>
                  <span>
                    <p>Local de Serviço/Atividade</p>
                    <SelectV2
                      name="e2lserv"
                      content={comboLServ}
                      initial={lserv}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <p>Data de Início</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e2dti', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e2dti"
                      onBlur={handleDI2}
                    />
                  </span>
                  <span>
                    <p>Data Final</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e2dtf', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e2dtf"
                      onBlur={handleDF2}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <p>Dia da Semana</p>
                    <SelectV2
                      name="e2wd"
                      content={semana}
                      containerStyle={{
                        width: '172.5px',
                        height: '37.5px',
                      }}
                      initial={wd}
                    />
                  </span>
                  <span>
                    <p>Horário</p>
                    <InputDialog
                      type="time"
                      placeholder="--:--"
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      name="e2hr"
                      onBlur={handleHR2}
                    />
                  </span>
                </div>

                <div>
                  <span>
                    <p>Guia Aplicador</p>
                    {user.perfil !== 'LOC' ? (
                      <>
                        <InputDialog isHidden name="e2gap" />
                        <h3>{user.name}</h3>
                      </>
                    ) : (
                      <SelectV2
                        name="e2gap"
                        content={comboGuias}
                        initial={gap2}
                        onChange={handleGuiaE2}
                      />
                    )}
                  </span>
                </div>
              </div>
            </GridItem>
            <GridItem>
              <h2>3ª Etapa</h2>
              <div>
                <div>
                  <span>
                    <h2>Oficina Supervisionada</h2>
                  </span>
                </div>
                <div>
                  <span>
                    <p>Data de Início</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e3dti', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e3dti"
                      onBlur={handleDI3}
                    />
                  </span>
                  <span>
                    <p>Data Final</p>
                    <InputDialog
                      onClick={() =>
                        formRef.current?.setFieldError('e3dtf', '')
                      }
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      // isDate
                      type="date"
                      placeholder="dd/mm/aaaa"
                      name="e3dtf"
                      onBlur={handleDF3}
                    />
                  </span>
                </div>
                <div>
                  <span style={{ opacity: 0, pointerEvents: 'none' }}>
                    <p>Dia da Semana</p>
                    <SelectV2
                      name="e3wd"
                      content={[]}
                      containerStyle={{
                        width: '172.5px',
                        height: '37.5px',
                      }}
                    />
                  </span>
                  <span style={{ opacity: 0, pointerEvents: 'none' }}>
                    <p>Horário</p>
                    <InputDialog
                      type="time"
                      containerStyle={{
                        width: '172.5px',
                        padding: '5px 2px 4.82px 5px',
                      }}
                      inputStyle={{
                        width: '110px',
                      }}
                      name="e3hr"
                    />
                  </span>
                </div>

                <div>
                  <span>
                    <p>Guia Aplicador</p>
                    {user.perfil !== 'LOC' ? (
                      <>
                        <InputDialog isHidden name="e3gap" />
                        <h3>{user.name}</h3>
                      </>
                    ) : (
                      <SelectV2
                        name="e3gap"
                        content={comboGuias}
                        initial={gap3}
                      />
                    )}
                  </span>
                </div>
              </div>
            </GridItem>
          </Grid>
          <Button bgcolor="#00802b" type="submit">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EPGInsert;
