import styled from 'styled-components';
import { animated } from 'react-spring';

interface ItemProps {
  paint?: number;
}

export const HeaderContainer = styled.div`
  padding: 10px;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 400px;

  > span {
    display: flex;
    flex-direction: column;
    > p {
      color: #8a0002;
      font-weight: bold;
      font-size: 16px;
    }

    & + span {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 400px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const LocalServ = styled(animated.span)`
  width: 350px;
`;

export const Wrapper = styled.div`
  display: flex;

  > div {
    & + div {
      margin: 0 0 0 25px;
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;

    > div {
      & + div {
        margin: 25px 0 0 0;
      }
    }
  }
`;

export const GuiasContainer = styled(animated.div)`
  /* margin: 25px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  font-size: 16px;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 400px) {
    width: 100vw;
  }
`;

export const GuiasContainerHeader = styled.div`
  background: #332e2e;
  color: #fff;
  border-radius: 5px 5px 0 0;
  @media screen and (max-width: 400px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const GuiasContainerBody = styled.div`
  padding: 5px 0;
  @media screen and (max-width: 400px) {
    /* width: 360px; */
    padding: 0;
  }
`;

export const ListItem = styled.div<ItemProps>`
  padding: 3px 0;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : ''};

  & + div {
    margin-top: 7px;
  }

  span + span {
    margin-left: 3px;
  }
`;

export const Name = styled.span`
  margin-left: 3px;
  width: 240px;
`;

export const Cert = styled.span`
  width: 75px;
`;

export const Check = styled.span`
  width: 75px;
`;
