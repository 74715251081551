import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

const calendarURL = `${process.env.REACT_APP_ASSETS_DIR}/misc/86e595d0f0d74baba94558655a198684.png`;

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
  isHidden: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #efefef;
  border-radius: 5px;
  padding: 16px;
  width: 350px;

  display: flex;
  align-items: center;

  color: #c6c6c6;
  border: 2px solid #a6a6a6;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: #c53030;
      background: #ffc7c7;
    `};

  ${(props) =>
    props.isFocused &&
    css`
      color: #332e2e;
      border-color: #332e2e;
    `};

  ${(props) =>
    props.isFilled &&
    css`
      color: #332e2e;
    `};

  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    /* display: none; */
    background: url(${calendarURL}) no-repeat;
    /* display: none;
    -webkit-appearance: none; */
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  input {
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: #a6a6a6;
    }
  }

  > svg {
    margin-right: 16px;
    width: 15px;
    height: 15px;
  }

  ${(props) =>
    props.isHidden &&
    css`
      height: 0px;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      overflow: hidden;
    `};
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;

export const ViewPass = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  margin-left: 16px;

  svg {
    cursor: pointer;
    margin: 0px;
  }
`;
