import styled from 'styled-components';

export const Grid = styled.div`
  padding: 35px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;

  @media screen and (max-width: 1850px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1460px) {
    width: 95vw;
    grid: auto / 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }

  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }

  @media screen and (max-width: 650px) {
    width: 400px;
    grid: auto / 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
  }

  /* @media screen and (max-width: 650px) {

    padding: 35px 0 10px 0;
    grid: auto / 1fr;
    column-gap: 20px;
    row-gap: 25px;
  } */
  /* @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr 1fr;
  } */
  /* @media screen and (max-width: 1850px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  } */
  /* justify-content: center;
  align-content: center; */
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  /* align-items: center; */
  background: #efefef;

  position: relative;

  padding-bottom: 10px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;
  /* padding: 0 10px; */

  h2 {
    /* position: absolute; */
    top: 0;
    z-index: 0;
    transform: translateY(-15px);
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #fff;
    transition: all 0.5s;

    background: #666;
  }

  p {
    margin: -15px 0 0 0;
    color: #767676;
    font-weight: 500;
    font-size: 18px;
    padding: 3px 5px;

    strong {
      color: #415fb9;
    }

    & + p {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 400px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;
