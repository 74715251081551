import styled from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

interface FinItemProps {
  paint: number;
}

export const FinHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;

  width: 90vw;
  max-width: 800px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;

  margin-top: 15px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    p {
      font-size: 15px;
      color: #767676;
      strong {
        font-size: 15px;
        color: #332e2e;
      }
    }
  }

  @media screen and (max-width: 470px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;

    display: grid;
    grid: auto / 1fr 1fr;
    column-gap: 15px;
    row-gap: 10px;
  }
`;

export const IncButton = styled(animated.button)`
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  padding: 3px 5px;

  border: 3px solid #00802b;
  background: #00802b;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, background-color 0.35s ease;

  &:hover {
    background-color: ${shade(0.2, '#00802b')};
    border-color: ${shade(0.2, '#00802b')};
  }
`;

export const FinContent = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 800px;
  border-radius: 5px;

  align-items: center;
  justify-content: space-between;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  div {
    div {
      span {
        display: flex;
        width: 200px;
        p {
          font-size: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 470px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const Wrapper = styled.div`
  border: 2px solid #332e2e;
  border-radius: 5px;
  /* border-top-width: 0px; */
  width: 90vw;
  max-width: 800px;
  overflow: hidden;

  @media screen and (max-width: 470px) {
    width: 100vw;
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const FinContentHeader = styled.div`
  display: flex;
  background: #332e2e;

  color: #fff;
`;

export const FinItem = styled.div<FinItemProps>`
  display: flex;

  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};
`;

export const Data = styled.span`
  padding-left: 3px;
`;

export const Anterior = styled.span`
  justify-content: flex-end;
`;

export const Parcela = styled.span`
  justify-content: flex-end;
`;

export const Saldo = styled.span`
  padding-right: 3px;
  justify-content: flex-end;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;

  span {
    display: flex;
    flex-direction: column;
    & + span {
      margin-top: 5px;
    }
  }
`;
