import React, { useState, useEffect, useMemo } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';

import ScrollTop from 'components/ScrollTop';

import { useLocation, useHistory } from 'react-router-dom';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import {
  FaCity,
  FaEnvelope,
  FaMobileAlt,
  FaPhoneAlt,
  FaAngleDoubleLeft,
} from 'react-icons/fa';
import { formatDate } from 'utils/formatDate';
import { InfoContainer, MoreContainer, GContainer, GAvatar } from './styles';

import { ListProps } from '../main';

const GEvangMore: React.FC = () => {
  const location = useLocation<ListProps>();
  const history = useHistory();

  const [gevang] = useState(() => {
    if (!location.state) {
      return {} as ListProps;
    }
    return { ...location.state };
  });

  useEffect(() => {
    if (Object.keys(gevang).length === 0) {
      history.goBack();
    }
  }, [gevang, history, location.state]);

  const amount = useMemo(() => {
    let total = 0;
    if (!gevang || Object.keys(gevang).length === 0) {
      return 0;
    }

    if (!!gevang.g1.name && gevang.g1.name.trim().length > 0) {
      total += 1;
    }

    if (!!gevang.g2.name && gevang.g2.name.trim().length > 0) {
      total += 1;
    }

    return total;
  }, [gevang]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Consulta</p>
          </div>
        </AlteredHeader>
        {Object.keys(gevang).length > 0 && (
          <>
            <InfoContainer>
              <div>
                <strong>
                  {`${
                    gevang.type === 'AR'
                      ? 'Animador no Espírito'
                      : `Guia Casal ${
                          gevang.type === 'CC'
                            ? 'Coordenador CURSO DE CASAIS'
                            : 'Evangelizador EED'
                        }`
                  }`}
                </strong>
              </div>
              <div>
                <p>Coord. Zonal:</p>
                <strong>{gevang.zond}</strong>
              </div>
              <div>
                <p>Coord. Nacional:</p>
                <strong>{gevang.nacd}</strong>
              </div>
              <div>
                <p>Coord. Local:</p>
                <strong>{gevang.locd}</strong>
              </div>
              <div>
                <p>Início:</p>
                <strong>{formatDate(gevang.init)}</strong>
              </div>
              <div>
                <p>Validade:</p>
                <strong>{formatDate(gevang.through)}</strong>
              </div>
            </InfoContainer>
            <MoreContainer amount={amount}>
              <GContainer photo={!!gevang.g1.avatar}>
                {!!gevang.g1.avatar && (
                  <GAvatar>
                    <img
                      src={`${process.env.REACT_APP_AVATAR}${gevang.g1.avatar}`}
                      alt={gevang.g1.name}
                    />
                  </GAvatar>
                )}
                <h4>{gevang.g1.name}</h4>
                {!!gevang.g1.cidade && !!gevang.g1.uf && (
                  <span>
                    <FaCity />
                    <p>
                      {gevang.g1.cidade} | {gevang.g1.uf}
                    </p>
                  </span>
                )}
                {!!gevang.g1.phoneres && (
                  <span>
                    <FaPhoneAlt />
                    <a
                      href={`tel:${gevang.g1.phoneres.replace(/[()-]/g, '')}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {gevang.g1.phoneres}
                      <FaAngleDoubleLeft />
                    </a>
                  </span>
                )}

                {!!gevang.g1.phonecel && (
                  <span>
                    <FaMobileAlt />
                    <a
                      href={`tel:${gevang.g1.phonecel.replace(/[()-]/g, '')}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {gevang.g1.phonecel}
                      <FaAngleDoubleLeft />
                    </a>
                  </span>
                )}

                {!!gevang.g1.mail && (
                  <span>
                    <FaEnvelope />
                    <a
                      href={`mailto:${gevang.g1.mail}?subject=[TOV BRASIL] - Mensagem para o Guia`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {gevang.g1.mail}
                      <FaAngleDoubleLeft />
                    </a>
                  </span>
                )}
              </GContainer>
              {amount === 2 && (
                <GContainer photo={!!gevang.g2.avatar}>
                  {!!gevang.g2.avatar && (
                    <GAvatar>
                      <img
                        src={`${process.env.REACT_APP_AVATAR}${gevang.g2.avatar}`}
                        alt={gevang.g2.name}
                      />
                    </GAvatar>
                  )}
                  <h4>{gevang.g2.name}</h4>
                  {!!gevang.g2.cidade && !!gevang.g2.uf && (
                    <span>
                      <FaCity />
                      <p>
                        {gevang.g2.cidade} | {gevang.g2.uf}
                      </p>
                    </span>
                  )}
                  {!!gevang.g2.phoneres && (
                    <span>
                      <FaPhoneAlt />
                      <a
                        href={`tel:${gevang.g2.phoneres.replace(/[()-]/g, '')}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {gevang.g2.phoneres}
                        <FaAngleDoubleLeft />
                      </a>
                    </span>
                  )}

                  {!!gevang.g2.phonecel && (
                    <span>
                      <FaMobileAlt />
                      <a
                        href={`tel:${gevang.g2.phonecel.replace(/[()-]/g, '')}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {gevang.g2.phonecel}
                        <FaAngleDoubleLeft />
                      </a>
                    </span>
                  )}

                  {!!gevang.g2.mail && (
                    <span>
                      <FaEnvelope />
                      <a
                        href={`mailto:${gevang.g2.mail}?subject=[TOV BRASIL] - Mensagem para o Guia`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {gevang.g2.mail}
                        <FaAngleDoubleLeft />
                      </a>
                    </span>
                  )}
                </GContainer>
              )}
            </MoreContainer>
          </>
        )}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GEvangMore;
