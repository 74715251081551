import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaQuestionCircle } from 'react-icons/fa';
import * as yup from 'yup';
import { Container, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import getValidationErrors from 'utils/getValidationErrors';
import { convertSpecialChars } from 'utils/specialChars';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import * as S from 'styles/dialog_consult';

import { ShowProps } from 'styles/dialog_consult';
import { LocalProps } from '../main';
import { Content, GridContainer, AnimatedSection, Warn } from './styles';

export interface StateProps {
  coord: LocalProps;
}

interface FormData {
  code: string;
  desc: string;
  core: 'N' | '';
  status?: 'A' | 'I' | undefined;
  cep: string;
  state: string;
  address: string;
  district: string;
  city: string;
}

const LocaisUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling } = useCredentials();
  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const [coord, setCoord] = useState<LocalProps>({} as LocalProps);

  const [firstUF, setFirstUF] = useState('Selecione');
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState('A');
  const [, setCoreValue] = useState('');
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  const [hasState, setHasState] = useState(false);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');
    const params = location.state?.coord;
    const checkform = formRef.current;
    response.data.filter((state: ComboProps) => {
      if (state.value === params.delivery?.state) {
        checkform?.setData({
          ...checkform,
          state: state.value,
        });
        setHasState(true);
        setFirstUF(state.value);
      }

      return state.value;
    });

    setComboEstados(response.data);
  }, [location.state]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setHasState(false);
      }
    }
  }, [comboEstados, getCEP]);

  // const handleRadioClick = useCallback(() => {
  //   setRadioValue(formRef.current?.getFieldValue('status'));
  // }, []);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
    } else {
      setCoord(location.state.coord);

      setStatusValue(location.state.coord.status);
      formRef.current?.setFieldValue('status', location.state.coord.status);

      setCoreValue(location.state.coord.type);
      formRef.current?.setFieldValue('core', location.state.coord.type);

      getUFs();
    }
  }, [location.state, location.pathname, history, getUFs]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          desc: yup
            .string()
            .trim()
            .min(4, 'O nome da coordenação deve conter ao menos 4 caracteres'),
          // core: yup.string(),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup.string().trim().required('Informação obrigatória'),
          number: yup.string().nullable(true),
          state: yup.string().notOneOf(['Selecione o Estado'], ' '),
          district: yup.string().trim().required('Informação obrigatória'),
          city: yup.string().trim().required('Informação obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          desc: convertSpecialChars(data.desc),
          address: convertSpecialChars(data.address),
          prev_status: statusValue,
          status: data.status || location.state.coord?.status,
          // prev_core: coreValue,
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
        };

        await api.get(`/sgo/locais_update.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Local ${data.desc} alterada!`,
        });

        history.replace(location.pathname.replace('/update', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      statusValue,
      location.state.coord,
      location.pathname,
      addToast,
      history,
      errorHandling,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            code: coord?.loccod,
            desc: coord?.desc,
            cep: coord.delivery?.code,
            address: coord.delivery?.address,
            // number: coord.delivery?.address
            //   .substr(coord.delivery?.address.indexOf(',') + 1)
            //   .replace(/\s/g, '')
            //   .split(' -', 1),

            // compl: coord.delivery?.address.substring(
            //   coord.delivery?.address.indexOf('-') + 1,
            // ),
            district: coord.delivery?.district,
            city: coord.delivery?.city,
          }}
          ref={formRef}
        >
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Código da Local</p>
                <Input name="code" placeholder="Código de 3 dígitos" disabled />
              </span>
              <span>
                <p>Descrição</p>
                <Input name="desc" placeholder="Nome da coordenação" />
              </span>

              <span>
                {coord.deactivate_info?.ready ? (
                  <RadioContainer
                    title="Status?"
                    name="status"
                    selected={location.state.coord?.status}
                    content={[
                      {
                        id: 'active',
                        value: 'A',
                        label: 'Ativa',
                      },
                      {
                        id: 'inactive',
                        value: 'I',
                        label: 'Inativa',
                      },
                    ]}
                  />
                ) : (
                  <Warn status={location.state.coord?.status}>
                    <section>
                      <h3>Status:</h3>
                      <h4>
                        {location.state.coord?.status === 'A' ? 'A' : 'Ina'}tiva
                      </h4>
                      <FaQuestionCircle
                        onClick={() =>
                          setShow({
                            title: '  ',
                            open: true,
                            content: (
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <h3
                                  style={{
                                    fontSize: '18.72px',
                                    marginBottom: '5px',
                                  }}
                                >
                                  Os registros abaixo impedem a inativação desta
                                  coordenação:
                                </h3>
                                {coord.deactivate_info?.amount > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Guias encontrados:{' '}
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.amount}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.balance > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Saldo Caixa:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        R$ {coord.deactivate_info?.balance}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.storage > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Materiais em estoque:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.storage}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.rm > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Remessas de Materiais em aberto:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.rm}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.ce > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Controles de Entrega em aberto:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.ce}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.bills > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Contas a Pagar e Receber pendentes:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.bills}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.services > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Serviços cadastrados:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.services}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.workshops > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Oficinas cadastradas:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.workshops}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.numbers > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Materiais numerados:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.numbers}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.lib > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Itens na Biblioteca:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.lib}
                                      </strong>
                                    </p>
                                  </span>
                                )}
                                {coord.deactivate_info?.team > 0 && (
                                  <span
                                    style={{
                                      display: 'flex',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <p>
                                      Guias com função:
                                      <strong
                                        style={{
                                          marginLeft: '5px',
                                          color: '#c53030',
                                        }}
                                      >
                                        {coord.deactivate_info?.team}
                                      </strong>
                                    </p>
                                  </span>
                                )}

                                <span
                                  style={{
                                    marginTop: '7px',
                                  }}
                                >
                                  <p>
                                    Para que a coordenação possa ser desativada,{' '}
                                    <strong style={{ color: '#c53030' }}>
                                      TODOS
                                    </strong>{' '}
                                    os itens acima devem estar com o valor{' '}
                                    <strong style={{ color: '#c53030' }}>
                                      igual a zero
                                    </strong>
                                    .
                                  </p>
                                </span>
                              </div>
                            ),
                          })
                        }
                      />
                    </section>
                  </Warn>
                )}
              </span>

              {/* <span>
                <RadioContainer
                  title="Núcleo?"
                  name="core"
                  selected={location.state.coord?.type}
                  content={[
                    {
                      id: 'coreS',
                      value: 'N',
                      label: 'Sim',
                    },
                    {
                      id: 'coreN',
                      value: '',
                      label: 'Não',
                    },
                  ]}
                />
              </span> */}
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>

              <span>
                <p>Endereço</p>
                <Textarea name="address" placeholder="Endereço" />
              </span>
              {/* <span>
                <p>Endereço</p>
                <Input name="address" placeholder="Endereço" />
              </span>
              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>
              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span> */}
              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>
        </Form>

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default LocaisUpdate;
