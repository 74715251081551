import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Container, FloatInput, AlteredContent } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SGOAccordeon from 'components/SGOAccordeon';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { removeAcento } from 'utils/specialChars';
import { formatDate } from 'utils/formatDate';
import { calcTotal, formatNumber } from 'utils/calcTotal';
import { ListContainer, ListItemContainer, ListItem } from './styles';

interface ListProps {
  gseq: number;
  name: string;
  loccod: string;
  num: number;
  data: string;
  matcod: string;
  matdesc: string;
  qte: number;
  vlr: number;
  total: number;
  status: string;
  statDesc: string;
  amount: number;
}
interface GuiaProps {
  gseq: number;
  name: string;
  amount: number;
}

const CEListGUIA: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState<ListProps[]>([]);
  const [rawGuias, setRawGuias] = useState<GuiaProps[]>([]);
  const [guias, setGuias] = useState<GuiaProps[]>([]);

  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const getList = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/ce_list.php?data=${JSON.stringify({ mode: true })}`,
      );

      const fetchGuias = response.data
        .map((item: ListProps, index: number) => {
          return (
            (index === 0 || item.name !== response.data[index - 1].name) && {
              gseq: item.gseq,
              name: item.name,
              amount: item.amount,
            }
          );
        })
        .filter((item: GuiaProps) => !!item);

      setRawGuias(fetchGuias);
      setGuias(fetchGuias);
      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['LOC'], true);
    getList();
  }, [getList, handlePermission]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      setGuias(
        rawGuias.filter((item: GuiaProps) =>
          removeAcento(item.name)
            .toLowerCase()
            .includes(removeAcento(val).toLowerCase()),
        ),
      );
    },
    [rawGuias],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <FloatInput>
        <input
          placeholder="Filtrar por Guia"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent>
        <ListContainer>
          {guias.map((guia: GuiaProps) => (
            <SGOAccordeon
              header={`${guia.name}`}
              height="400px"
              key={guia.gseq}
            >
              <ListItemContainer>
                {list.map((item: ListProps) => {
                  return (
                    item.gseq === guia.gseq && (
                      <ListItem
                        style={{
                          borderColor:
                            item.status === 'P' ? '#00802b' : '#2156e6',
                        }}
                      >
                        <span>
                          <p>
                            CE N°: <strong>{item.num}</strong> -{' '}
                            <strong>{item.statDesc}</strong>
                          </p>
                          <p>
                            Data:&nbsp;<strong>{formatDate(item.data)}</strong>
                          </p>
                          <p>
                            Material:&nbsp;
                            <strong>{item.matdesc}</strong>
                          </p>
                        </span>

                        <span>
                          <p>
                            Quantidade: <strong>{item.qte}</strong>
                          </p>
                          <p>
                            Custo: <strong>R$ {formatNumber(item.vlr)}</strong>
                          </p>
                          <p>
                            Total:&nbsp;
                            <strong>R$ {calcTotal(item.qte, item.vlr)}</strong>
                          </p>
                        </span>
                      </ListItem>
                    )
                  );
                })}
              </ListItemContainer>
            </SGOAccordeon>
          ))}
        </ListContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default CEListGUIA;
