import styled from 'styled-components';

export const PlayerContainer = styled.div`
  margin-top: 10px;

  border-radius: 10px;

  /* overflow: hidden; */

  transition: opacity 0.35s, height 0.35s, pointer-events 0.35s, box-shadow 0.5s,
    transform 0.5s ease;

  pointer-events: all;
  opacity: 1;
  height: 360px;

  @media screen and (max-width: 650px) {
    transform: scale(0.6);
  }
`;

export const MessageContainer = styled.div`
  margin: 10px 0 0 0;
  width: 90vw;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  h4,
  p {
    text-align: center;
    font-size: 18px;
  }

  h4 {
    font-weight: bolder;
    color: #8a0002;
  }

  p {
    /* font-weight: bold; */
    color: #332e2e;
  }

  span {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;

    & + span {
      margin: 10px 0 0 0;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 25px;
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-weight: bolder;
    color: #000;
    /* text-shadow: 0px 0px 10px rgba(255, 77, 77, 0.75); */
    /* -webkit-text-stroke: 1px #8a0002; */
    /* -webkit-text-stroke: 0.5px #ff4d4d; */

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

export const CronTableWrapper = styled.div`
  margin: 0 auto;
  width: clamp(400px, 90vw, 800px);
  border: 3px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: width 0.25s ease;

  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;
export const CronTable = styled.table`
  width: 100%;
  background: #fff;
  font-size: 16px;
  /* border-collapse: collapse; */
  tr {
    height: 35px;
  }

  td {
    padding: 5px;
  }
`;
export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  width: clamp(400px, 95vw, 800px);

  div {
    font-size: 16px;
    width: 100%;
    & + div {
      margin: 10px 0 0 0;
    }
  }

  /* width: clamp(360px, 100vw, 800px); */
`;

//
export const FeedContent = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  /* background: #fce205; */
  padding: 10px;
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 0, 0, 0.25) 100%
  );
  border: 2px solid #332e2e;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  line-height: 20px;

  transition: box-shadow 0.25s ease, background-image 0.25s ease;

  color: #332e2e;
  p {
    color: black;
  }
  strong {
    & + strong {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;
