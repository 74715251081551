import styled from 'styled-components';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import * as O from 'styles/option_buttons';

export const Grid = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;

  width: 100vw;
  max-width: 1366px;

  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: 1366px) {
    padding: 0 5px;
  }

  @media screen and (max-width: 1150px) {
    grid: auto / 1fr 1fr;
    max-width: 800px;
  }

  @media screen and (max-width: 770px) {
    grid: auto / 1fr;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

export const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #ffffff;
  height: 340px;
  /* height: calc(100% + 85px); */
  min-width: 360px;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  span {
    display: flex;
    flex-direction: row;

    p {
      font-size: 16px;
      color: #767676;
      strong {
        color: #332e2e;
      }

      & + p {
        margin: 0 0 0 7px;
      }
    }

    /* progress {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 35px;

      background: purple;
    } */

    & + span {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 400px) {
    border-radius: 0;
    border-right-width: 0px;
    border-left-width: 0px;
  }
`;

export const YearContainer = styled.div`
  display: flex;
  /* margin: 125px 0 0 0; */
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 360px;
  align-items: center;
  justify-content: center;

  margin: 10px auto 0 auto;
  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #332e2e;
  }

  /* @media screen and (max-width: 460px) {
    margin: 60px auto 0 auto;
  } */
  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100vw;
  }

  transition: all 0.5s;
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;

export const GridOptions = styled(O.GridOptions)`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ProgressDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 60px);
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  progress[value] {
    margin: 0 auto;
    -webkit-appearance: none;
    appearance: none;

    width: 80%;
    height: 20px;

    position: relative;

    > p {
      position: absolute;
    }

    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
      background-image: -webkit-linear-gradient(
          45deg,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, #8a0002, #f44);

      border-radius: 3px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }

  span {
    display: flex;
    margin: 0 auto;
    p {
      font-size: 16px;
      color: #464646;
      margin: 0 auto;
      strong {
        color: #332e2e;
      }
    }
    /* h4 {
    font-size: 16px;

  } */
  }
`;

export const RelatedLocals = styled.div`
  display: flex;
  background: #c53030;

  align-items: center;
  justify-content: flex-start;
  word-wrap: none;
`;
