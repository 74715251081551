import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';

import {
  Container,
  AlteredContent,
  FloatInput,
  Header,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import RadioContainer from 'components/RadioContainer';

import { useCredentials } from 'hooks/credentials';
import {
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import { removeAcento } from 'utils/specialChars';
import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/calcTotal';
import * as O from 'styles/option_buttons';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowCircleUp, FaDollarSign, FaSearch } from 'react-icons/fa';
import { Grid, GridItem, InvertButton } from './styles';

interface LocalStorageProps {
  stat?: string;
}

interface ListProps {
  num: number;
  cod: number;
  data: string;
  valor: number;
  status: string;
  statusdesc: string;
  cert: string;
  name: string;
  loccod: string;
}

const FinCE: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isInverted, setIsInverted] = useState(false);

  const [status, setStatus] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_FINCE}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_FINCE}`,
      );

      if (stat) {
        return stat;
      }

      return 'E';
    }

    return 'E';
  });

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/fin_ce_list.php');
      setRawList(response.data);
      setList(
        response.data.filter((item: ListProps) =>
          status === 'A' ? item : item.status === status,
        ),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      setList(
        rawList.filter((item: ListProps) => {
          return (
            item.num.toString().includes(val) ||
            removeAcento(item.name)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase())
          );
        }),
      );
    },
    [rawList],
  );

  const handleRadioClick = useCallback(() => {
    const stat = formRef.current?.getFieldValue('statFilter');

    setStatus(stat);

    setList(
      rawList.filter((item: ListProps) =>
        stat === 'A' ? item : item.status === stat,
      ),
    );

    setLocalStorage(`${process.env.REACT_APP_FINCE}`, { stat });
  }, [rawList]);

  const handleInvert = useCallback(() => {
    setIsInverted((state) => !state);
    setList((state) => [...state].reverse());
    setRawList((state) => [...state].reverse());
  }, []);

  return (
    <Container>
      <ScrollTop />
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form
            ref={formRef}
            onSubmit={() => {
              return null;
            }}
          >
            <RadioContainer
              title="Status Financeiro"
              onClick={handleRadioClick}
              name="statFilter"
              content={[
                {
                  id: 'sP',
                  value: 'E',
                  label: 'Pendente',
                },
                {
                  id: 'sL',
                  value: 'P',
                  label: 'Liquidada',
                },
                {
                  id: 'sA',
                  value: 'A',
                  label: 'Todas',
                },
              ]}
              itemsStyle={{
                flexDirection: 'row',
              }}
              selected={status}
            />
          </Form>
        }
      />
      <Header>Controle Financeiro de Controles de Entrega</Header>
      {user.perfil === 'LOC' && (
        <FloatInput amount="100px">
          <input
            placeholder="Filtrar por Guia ou n° CE"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={user.perfil !== 'LOC' ? '231px' : '281px'}>
        {!loading && list.length > 0 && (
          <InvertButton
            type="button"
            onClick={handleInvert}
            isInverted={isInverted}
          >
            <p>Mais {isInverted ? 'recentes' : 'antigas'} primeiro</p>
            <FaArrowCircleUp />
          </InvertButton>
        )}
        <Grid>
          {list.map((item) => (
            <GridItem key={`${item.cod}-${item.num}`}>
              <p>
                N° {item.num} |&nbsp;
                {formatDate(item.data)}
              </p>
              <span>
                <p>
                  Valor da CE:&nbsp;
                  <strong>R$ {formatNumber(item.valor)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statusdesc}</strong>
                </p>
              </span>
              {user.perfil === 'LOC' && (
                <span>
                  <p>
                    Guia:&nbsp;
                    <strong>{item.name}</strong>
                  </p>
                </span>
              )}
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname.replace(
                        'tesouraria/contfince',
                        'material/ce',
                      )}/consult`,
                      state: {
                        loccod: item.loccod,
                        cenum: item.num,
                        gseq: item.cod,
                      },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                <O.Financ
                  to={{
                    pathname: `${pathname}/detail`,
                    state: {
                      finHeader: {
                        num: item.num,
                        cod: item.cod,
                        desc: item.name,
                        date: item.data,
                        valor: item.valor,
                      },
                    },
                  }}
                >
                  <FaDollarSign />
                </O.Financ>
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default FinCE;
