import React, { useState, useCallback, useRef, useEffect } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import RadioContainer from 'components/RadioContainer';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import { handleTimeZone } from 'utils/formatDate';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useSpring } from 'react-spring';
import {
  Container,
  Content,
  ComboProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { FaFileExcel, FaTimes } from 'react-icons/fa';

import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorageItemKey,
} from 'utils/handleLocalStorage';

import api from 'services/api';

import { Wrapper, PrintButton } from './styles';

import { monthList, MonthListProps } from '../Tesouraria/monthList';

interface LocalStorageProps {
  year?: string;
  zon?: string;
  zondesc?: string;
  anb?: string;
  anbdesc?: string;
  cycle?: string;
  month?: string;
  monthdesc?: string;
  semester?: string;
}

const Contabil: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const [cycle, setCycle] = useState(() => {
    const locallyStored: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return locallyStored.cycle || 'monthly';
  });

  const [mode, setMode] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    if (anb) {
      return 'NAC';
    }
    return 'ZON';
  });

  const [, setSelectedZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return zon || 'Selecione';
  });
  const [, setSelectedNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return anb || '';
  });
  const [initialZon, setInitialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return zon || 'Selecione';
  });
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return anb || 'Selecione';
  });
  const [initialYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return year || new Date().getFullYear().toString();
  });
  const [initialMonth] = useState(() => {
    const { month }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return month || (handleTimeZone(new Date()).getMonth() + 1).toString();

    // return (
    //   month.toString ||
    //   monthList[
    //     monthList.findIndex(
    //       (item: MonthListProps) => item.value === new Date().getUTCMonth() + 1,
    //     )
    //   ].value.toString()
    // );
  });
  const [initialSemester] = useState(() => {
    const locally: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return `${
      locally.semester || (new Date().getUTCMonth() + 1 <= 6 ? '1' : '2')
    }° Semestre`;
  });
  const [month, setMonth] = useState(() => {
    const locallyStored: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return locallyStored.month || (new Date().getUTCMonth() + 1).toString();
  });
  const [, setSemester] = useState(() => {
    const locally: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    return locally.semester || (new Date().getUTCMonth() + 1 <= 6 ? '1' : '2');
  });
  const [months, setMonths] = useState<ComboProps[]>(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    const currYear = handleTimeZone(new Date()).getFullYear();
    // return monthList.filter((item: MonthListProps) => item);
    return [
      ...monthList
        .filter((item: MonthListProps) =>
          year && parseInt(year, 10) < currYear
            ? item
            : item.value <= new Date().getUTCMonth() + 1,
        )
        .map((item: MonthListProps) => ({
          value: item.value.toString(),
          label: item.label.toString(),
        })),
    ];
  });

  const [semesters] = useState<ComboProps[]>([
    { label: '1° Semestre', value: '1' },
    { label: '2° Semestre', value: '2' },
  ]);

  const [comboYears] = useState<ComboProps[]>(() => {
    const currYear = handleTimeZone(new Date()).getFullYear();
    let stopAt = handleTimeZone(new Date()).getFullYear() - 4;

    const thisCombo = [];
    while (stopAt <= currYear) {
      thisCombo.push({ value: stopAt.toString(), label: stopAt.toString() });
      stopAt += 1;
    }

    return thisCombo.reverse();
  });

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);

  const [coord, setCoord] = useState(() => {
    const { zon, anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    if (anb) {
      return anb;
    }

    return zon;
  });

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    if (!zon) {
      setInitialZon(response.data[0].label);
      setSelectedZon(response.data[0].value);

      setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
        zon: response.data[0].value,
        zondesc: response.data[0].label,
      });
    }

    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    // setInitialNac(user.anbdesc);
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substr(0, 2) === zon.substr(0, 2)
          : item.value.substr(0, 2) === comboZons[0].value.substr(0, 2),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getComboZON();
    getComboANB();
  }, [getComboANB, getComboZON]);

  const handleYearSelection = useCallback(() => {
    const year = formRef.current?.getFieldValue('comboYear');
    const currYear = handleTimeZone(new Date()).getFullYear();

    const filtered = monthList
      .filter((item: MonthListProps) =>
        parseInt(year, 10) < currYear
          ? item
          : item.value <= handleTimeZone(new Date()).getMonth() + 1,
      )
      .map((item: MonthListProps) => ({
        value: item.value.toString(),
        label: item.label.toString(),
      }));
    setMonths(filtered);
    setMonth(filtered[filtered.length - 1].value.toString());
    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      year,
      month: filtered[filtered.length - 1].value.toString(),
      monthdesc: filtered[filtered.length - 1].label,
    });

    const cMref: HTMLSelectElement = formRef.current?.getFieldRef(
      'comboMonths',
    );

    setTimeout(() => {
      cMref.value = filtered[filtered.length - 1].value.toString();
      // cMref.selectedIndex = filtered.length - 1;
    }, 200);

    // s1ref.options[s1ref.selectedIndex].text
  }, []);

  const handleZonSelection = useCallback(() => {
    const zon = formRef.current?.getFieldValue('comboZON');

    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      zon,
      zondesc:
        comboZons[comboZons.findIndex((item) => item.value === zon)].label,
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_CONTABIL}`, [
      'anb',
      'anbdesc',
    ]);

    const nacFilter = nacs.filter(
      (item) => item.value.substr(0, 2) === zon.substr(0, 2),
    );

    setCoord(zon);
    setComboNacs(nacFilter);
    setInitialNac('Selecione');
    setSelectedNac('');
    setMode('ZON');
  }, [comboZons, nacs]);

  const handleNacSelection = useCallback(() => {
    const anb = formRef.current?.getFieldValue('comboANB');

    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      anb,
      anbdesc:
        comboNacs[comboNacs.findIndex((item) => item.value === anb)].label,
    });

    setInitialNac(
      comboNacs[comboNacs.findIndex((item) => item.value === anb)].label,
    );
    setSelectedNac(anb);
    setCoord(anb);
    setMode('NAC');
  }, [comboNacs]);

  const handleCycleSelection = useCallback(() => {
    const cycleSelected = formRef.current?.getFieldValue('cycle');
    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      cycle: cycleSelected,
    });
    setCycle(cycleSelected);
  }, []);

  const handleMonthSelection = useCallback(() => {
    const mSel: HTMLSelectElement = formRef.current?.getFieldRef('comboMonths');

    setMonth(mSel.value.toString());

    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      month: mSel.options[mSel.selectedIndex].value,
      monthdesc: mSel.options[mSel.selectedIndex].text,
    });
  }, []);

  const handleSemesterSelection = useCallback(() => {
    const selected = formRef.current?.getFieldValue('comboMonths');

    setSemester(selected.toString());

    setLocalStorage(`${process.env.REACT_APP_CONTABIL}`, {
      semester: selected,
    });
  }, []);

  const handleRemoveNacFilter = useCallback(() => {
    deleteLocalStorageItemKey(`${process.env.REACT_APP_CONTABIL}`, [
      'anb',
      'anbdesc',
    ]);
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CONTABIL}`,
    );
    setInitialNac('Selecione');
    setSelectedNac('');
    setMode('ZON');
    setCoord(zon);
  }, []);

  const handleDownload = useCallback(() => {
    anchorRef.current?.click();
  }, []);

  const removeButtonNac = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
    height: initialNac === 'Selecione' ? '0px' : '25px',
    overflow: initialNac === 'Selecione' ? 'hidden' : 'unset',
  });

  return (
    <Container>
      <SGOHeader />
      <Loading isLoading={comboZons.length === 0 && comboNacs.length === 0} />
      <SGONavbar noLinks disableBack />
      <Content>
        <Form ref={formRef} onSubmit={() => null}>
          <Wrapper>
            <div>
              <p>Selecione o Ano:</p>
              <SelectV2
                name="comboYear"
                content={comboYears}
                initial={initialYear}
                onChange={handleYearSelection}
              />
            </div>

            <div>
              <p>Selecione a Zonal:</p>
              <SelectV2
                name="comboZON"
                content={comboZons}
                initial={initialZon}
                onChange={handleZonSelection}
              />
            </div>
            <div>
              <p>Selecione a Nacional:</p>
              <SelectV2
                name="comboANB"
                content={comboNacs}
                initial={initialNac}
                onChange={handleNacSelection}
              />
              <RemoveButton
                type="button"
                onClick={handleRemoveNacFilter}
                style={removeButtonNac}
              >
                <FaTimes />
                &nbsp;
                <p>Excluir filtro</p>
              </RemoveButton>
            </div>

            <div>
              <RadioContainer
                name="cycle"
                onClick={handleCycleSelection}
                title=""
                content={[
                  {
                    id: 'cycleM',
                    value: 'monthly',
                    label: 'Mensal',
                  },
                  {
                    id: 'cycleS',
                    value: 'semester',
                    label: 'Semestral',
                  },
                ]}
                selected={cycle}
              />
            </div>
            <div>
              <p>Selecione o {cycle === 'monthly' ? 'mês' : 'semestre'}:</p>
              <SelectV2
                name="comboMonths"
                content={cycle === 'monthly' ? months : semesters}
                initial={cycle === 'monthly' ? initialMonth : initialSemester}
                onChange={
                  cycle === 'monthly'
                    ? handleMonthSelection
                    : handleSemesterSelection
                }
              />
            </div>
          </Wrapper>
          <PrintButton type="button" onClick={handleDownload}>
            <FaFileExcel />
            <p>Emitir Relatório</p>
          </PrintButton>
        </Form>
        <a
          style={{ display: 'none' }}
          ref={anchorRef}
          href={`${process.env.REACT_APP_API}/sgo/xls_informe_f2${
            cycle !== 'monthly' ? '_sem' : ''
          }.php?data=${JSON.stringify({
            mode,
            code: coord,
            year: formRef.current?.getFieldValue('comboYear'),
            month,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
            sem: 1,
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          Relatório
        </a>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Contabil;
