import styled from 'styled-components';

export const GridContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  width: 90vw;

  justify-content: center;
  padding: 10px 5px;
  column-gap: 15px;
  row-gap: 15px;

  @media screen and (max-width: 1120px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 870px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 540px) {
    grid: auto / 1fr;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
    padding: 0;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 10px;

  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-color 0.35s ease, box-shadow 0.35s ease;

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & + span {
      margin: 10px 0 0 0;
    }

    &:nth-child(2) {
      flex-direction: column;
    }
  }

  p {
    font-size: 16px;
    color: #767676;
    strong {
      color: #332e2e;
    }
  }

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 400px) {
    border-radius: 0px;
    border-width: 2px 0;
    border-color: #efefef transparent;
  }
`;

export const IContentDiv = styled.div`
  /* margin: 25px 0 10px 0; */
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    /* width: 100%; */
    p {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Roboto Slab', serif;
      color: #8a0002;
    }
  }

  & + div {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 460px) {
    & + div {
      margin: 10px 0;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

export const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    padding: 5px 10px;

    background: #fff;
    border: 2px solid #332e2e;
    border-radius: 5px;

    > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & + span {
        margin: 10px 0 0 0;
      }
    }

    p {
      font-size: 16px;
      color: #767676;
      strong {
        color: #332e2e;
      }
    }
  }
  div + div {
    margin: 10px 0 0 0;
  }
`;
