import React from 'react';

import { WindowProvider } from './window';
import { ScrollingProvider } from './scrolling';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { CredentialsProvider } from './credentials';
import { ChangeProvider } from './changes';
import { UtilsProvider } from './utils';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ScrollingProvider>
      <ToastProvider>
        <ChangeProvider>
          <AuthProvider>
            <WindowProvider>
              <CredentialsProvider>
                <UtilsProvider>{children}</UtilsProvider>
              </CredentialsProvider>
            </WindowProvider>
          </AuthProvider>
        </ChangeProvider>
      </ToastProvider>
    </ScrollingProvider>
  );
};

export default AppProvider;
