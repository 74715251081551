import styled from 'styled-components';

export const BodyContainer = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 600px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 3px solid #efefef;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-width 350ms, border-radius 350ms;

  & + div {
    margin: 15px 0 0 0;
  }

  &:nth-last-child(1) {
    margin: 15px 0 35px 0;
  }

  @media screen and (max-width: 600px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const BodyItem = styled(Body)`
  span {
    display: flex;
    position: relative;
    width: 100%;
    & + span {
      margin: 10px 0 0 0;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    bottom: 5px;
    right: 10px;

    padding: 3px 5px;
    border: 3px solid #332e2e;
    background: #332e2e;
    border-radius: 5px;

    p {
      font-size: 16px;
      color: #fff;
      transition: text-shadow 350ms;
    }
    svg {
      margin: 0 5px 0 0;
      height: 17px;
      width: 17px;
      color: #fff;
    }

    &:hover {
      p {
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
      }
    }
  }
  p {
    font-size: 16px;
    color: #767676;
    strong {
      color: #332e2e;
    }
  }
`;

export const BodyFinish = styled(Body)`
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    color: #767676;
    font-size: 20px;
    strong {
      color: #332e2e;
    }
  }
`;

export const BodyTable = styled(Body)`
  p {
    color: #c53030;
  }
  > h3 {
    font-size: 18.72px;
    width: 100%;
    text-align: center;
  }
  div {
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        border-bottom: 3px solid black;
        font-size: 16px;
        td {
          &:nth-child(2) {
            text-align: right;
            width: 100px;
          }
        }
      }
      tbody {
        font-size: 16px;
        tr {
          border-bottom: 1px solid black;
          &:hover:not(&:nth-last-child(1)) {
            background-color: #d9d9d9;
            font-weight: 500;
          }
          &:nth-last-child(1) {
            border-bottom-width: 0px;

            td {
              &:nth-child(1) {
                text-align: right;
                font-weight: bold;
              }
            }
          }
        }
        td {
          &:nth-child(2) {
            text-align: right;
            width: 100px;
          }
        }
      }
    }
  }
`;

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    font-size: 16px;
    &:nth-child(1) {
      td {
        border-bottom: 1px solid transparent;
        border-bottom: 1px solid black;
        /* text-align: center; */
      }
    }
    &:nth-child(2n + 3) {
      background: #f2f2f2;
    }

    &:nth-last-child(1) {
      td {
        text-align: right;
      }
    }
  }

  td {
    padding: 2px 3px;
    /* border-bottom: 1px solid black; */

    overflow: hidden;

    &:nth-child(1) {
      width: 30%;
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
      width: 15%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
      width: 15%;
    }
  }
`;
