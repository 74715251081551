import styled, { css } from 'styled-components';
import { List as MainList } from '../main/styles';

interface ErrProps {
  err: boolean;
}

// box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
export const FormDIV = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  width: 100vw;
  max-width: 370px;

  padding: 10px 5px;

  transition: all 0.35s ease;

  > span {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #8a0002;
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }

  @media screen and (max-width: 430px) {
    padding: 10px 0;
    align-items: center;
    max-width: 430px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-radius: 0px;
  }
`;

export const List = styled(MainList)<ErrProps>`
  > div {
    ${(props) =>
      props.err &&
      css`
        border-color: #ff0000;
        background: #ffc7c7;
      `}
  }
`;
