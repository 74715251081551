import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
// import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';
import Input from 'components/Input';
import Textarea from 'components/Textarea';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useChanges } from 'hooks/changes';
import { useToast } from 'hooks/toast';

import api from 'services/api';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaDollarSign } from 'react-icons/fa';
import Button from 'components/Button';
import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import { formatDate, minimumAllowedDate, endOfDay } from 'utils/formatDate';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { format, isValid, subDays } from 'date-fns';

import { CoordIdentifier, Wrapper } from './styles';

import { LocalStorageProps } from '../main';

export interface ComboLancProps {
  code: string;
  desc: string;
  type: string;
}

interface FormData {
  movdate: string;
  movtype: string;
  compl: string;
  lanc: string;
  value: string;
}

const AplicInsert: React.FC = () => {
  const { errorHandling, handlePermission } = useCredentials();
  const { addToast } = useToast();
  const { currDate } = useChanges();
  const { user } = useAuth();

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [rawLancs, setRawLancs] = useState<ComboLancProps[]>([]);
  const [lancs, setLancs] = useState<ComboProps[]>([]);

  const getComboTLanc = useCallback(async () => {
    try {
      const response = await api.get('combos/comboTipoLanc.php');

      setRawLancs(response.data);

      setLancs(
        response.data
          .filter((item: ComboLancProps) => item.type === 'E')
          .map((item: ComboLancProps) => ({
            value: item.code,
            label: item.desc,
          })),
      );
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['GUI', 'LOC']);
    getComboTLanc();
  }, [getComboTLanc, handlePermission]);

  const handleRadioClick = useCallback(() => {
    const val = formRef.current?.getFieldValue('movtype');
    formRef.current?.setFieldValue('lanc', 'Selecione');

    setLancs(
      rawLancs
        .filter((item: ComboLancProps) => item.type === val)
        .map((item: ComboLancProps) => ({
          value: item.code,
          label: item.desc,
        })),
    );
  }, [rawLancs]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const { full } = currDate;

        const max = endOfDay(full);

        if (!isValid(new Date(data.movdate))) {
          formRef.current?.setFieldError(
            'movdate',
            'Este campo é obrigatório.',
          );
          setLoading(false);
          return;
        }

        const min = minimumAllowedDate(data.movdate);

        if (parseFloat(data.value) <= 0) {
          formRef.current?.setFieldError(
            'value',
            'O valor deve ser superior a 0 (zero).',
          );

          formRef.current?.setFieldValue('value', '0.00');
          setLoading(false);
          return;
        }

        const schema = yup.object().shape({
          movdate: yup
            .date()
            .typeError('Data inválida.')
            .min(
              min,
              `O lançamento deve ser posterior a ${formatDate(
                subDays(min, 1).toISOString(),
              )}`,
            )
            .max(
              max,
              `A data do lançamento deve ser igual ou anterior à data atual (${format(
                max,
                'dd/MM/yyyy',
              )}).`,
            ),
          movtype: yup.string().required(),
          compl: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(60, 'Quantidade máxima de caracteres excedida (60).'),
          lanc: yup.string().notOneOf(['Selecione', '']),
          value: yup
            .string()
            .notOneOf(['0.00', ''], 'Este campo é obrigatório.')
            .required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const { app }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_APLIC}`,
        );

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            uuid: app,
          }),
        );

        await api.post('/sgo/aplic_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });
        setLoading(false);
        setLocalStorage(`${process.env.REACT_APP_APLIC}`, {
          ano: new Date(data.movdate).getUTCFullYear(),
          mes: new Date(data.movdate).getUTCMonth() + 1,
        });

        handleRadioClick();
        formRef.current?.setFieldValue('lanc', 'Selecione');
        formRef.current?.setFieldValue('compl', '');
        formRef.current?.setFieldValue('value', '');
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, currDate, errorHandling, handleRadioClick],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Novo Movimento" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CoordIdentifier>
            {user.perfil === 'ZON' ? user.zondesc : user.anbdesc}
          </CoordIdentifier>
          <Wrapper>
            <span>
              <p>Data</p>
              <Input name="movdate" isDate placeholder="dd/mm/aaaa" />
            </span>
            <span>
              <p>Tipo</p>
              <RadioContainer
                title=" "
                onClick={handleRadioClick}
                name="movtype"
                content={[
                  {
                    id: 'mE',
                    value: 'E',
                    label: 'Entrada',
                  },
                  {
                    id: 'mS',
                    value: 'S',
                    label: 'Saída',
                  },
                ]}
                containerStyle={{ alignItems: 'flex-start' }}
                itemsStyle={{ justifyContent: 'flex-start' }}
                selected="E"
              />
            </span>
            <span>
              <p>Descrição:</p>
              <SelectV2 name="lanc" content={lancs} initial="Selecione" />
            </span>
            <span>
              <p>Complemento</p>
              <Textarea
                name="compl"
                placeholder="Insira o complemento deste lançamento"
                mask="alpha"
              />
            </span>
            <span>
              <p>Valor</p>
              <Input
                name="value"
                type="number"
                step=".01"
                icon={FaDollarSign}
                placeholder="0,00"
                mask="currency"
              />
            </span>
          </Wrapper>
          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ marginBottom: '10px' }}
          >
            Lançar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AplicInsert;
