import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Textarea from 'components/TextareaDialog';

import InputDialog from 'components/InputDialog';
import SelectV2 from 'components/SelectV2';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import api from 'services/api';

import {
  AlteredHeader,
  Container,
  Content,
  IncButton,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import {
  FaPencilAlt,
  FaPlusCircle,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';

import {
  TableWrapper,
  Table,
  Desc,
  Dated,
  Update,
  Consult,
  ButtonTD,
  Delete,
  TG,
  NAC,
  LOC,
  TrajContent,
} from './styles';

import { FIGuiaProps } from '../main';
import { checkSentDate, checkTopDate } from '../checkDates';

interface ListProps {
  seq: number;
  date: string;
  desc: string;
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  tg: string;
  tgdesc: string;
}

interface FormData {
  seq: number;
  date: string;
  description: string;
  tpguia: string;
  anb: string;
  loc: string;
}

const FIGTraj: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const updateRef = useRef<FormHandles>(null);
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [list, setList] = useState<ListProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);
  const [show, setShow] = useState({} as ShowProps);
  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);

  const [prevReg, setPrevReg] = useState({} as FormData);
  const [prevConReg, setPrevConReg] = useState({} as FormData);
  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [maxDate, setMaxDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [comboTG, setComboTG] = useState<ComboProps[]>([]);
  const [comboANB, setComboANB] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLOC, setComboLOC] = useState<ComboProps[]>([]);

  const getComboTG = useCallback(async () => {
    const response = await api.get('/combos/comboTG.php');

    setComboTG(
      response.data.filter(
        (item: ComboProps) => ['FD', 'SC'].indexOf(item.value) < 0,
      ),
    );
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get(
      `/combos/comboANBs.php?data=${JSON.stringify('content')}`,
    );
    setComboANB([
      ...response.data.map((item: ComboProps) => ({
        ...item,
        label: item.label.replace('Nacional ', ''),
      })),
    ]);
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    // setComboTG(
    //   response.data.filter(
    //     (item: ComboProps) => ['FD', 'FG', 'SC'].indexOf(item.value) < 0,
    //   ),
    // );
  }, []);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_trajetoria.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      const { trajs, sentDate, topDate } = response.data;

      setMinDate(sentDate);
      setMaxDate(topDate);
      setList(trajs);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getComboANB();
    getComboLOC();
    getComboTG();
    getList();
  }, [getComboANB, getComboLOC, getComboTG, getList, history, location.state]);

  const getDesc = useCallback(
    (ocurr: number) => {
      const index = list.findIndex((item) => item.seq === ocurr);
      setPrevReg({
        seq: ocurr,
        date: list[index].date,
        description: list[index].desc,
        tpguia: list[index].tg,
        anb: list[index].anb,
        loc: list[index].loc,
      });
      setPrevConReg({
        seq: ocurr,
        date: list[index].date,
        description: list[index].desc,
        tpguia: list[index].tgdesc,
        anb: list[index].anbdesc,
        loc: list[index].locdesc,
      });
    },
    [list],
  );

  const handleANBChange = useCallback(() => {
    const anb = includeRef.current?.getFieldValue('anb');

    setComboLOC(locs.filter((item) => item.anb === anb));
  }, [locs]);

  const handleANBChangeUp = useCallback(() => {
    const anb = updateRef.current?.getFieldValue('anb');

    setComboLOC(locs.filter((item) => item.anb === anb));
  }, [locs]);

  const handlePreInc = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreAlt = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);
      const index = list.findIndex((item) => item.seq === ocurr);
      setComboLOC(locs.filter((item) => item.anb === list[index].anb));

      setUpdateDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc, list, locs],
  );

  const handlePreDel = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);

      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handlePreShow = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);

      setShow((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handleInclude = useCallback(
    async (data: FormData) => {
      try {
        includeRef.current?.setErrors({});

        setLoading(true);
        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Trajetória deve ser após a data de envio (${formatDate(
                minDate,
              )})`,
              (sent: string) => {
                return checkSentDate(minDate, sent);
              },
            )
            .test(
              'todayDate',
              `Trajetória deve ser anterior a ${formatDate(maxDate)}.`,
              (today: string) => {
                return checkTopDate(today, maxDate);
              },
            ),
          tpguia: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
          anb: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          loc: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            gseq: gState.gseq,
            mode: 'inc',
          }),
        );

        setInc((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_trajetoria.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, maxDate, minDate],
  );

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        updateRef.current?.setErrors({});

        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Trajetória deve ser após a data de envio (${formatDate(
                minDate,
              )})`,
              (sent: string) => {
                return checkSentDate(minDate, sent);
              },
            )
            .test(
              'todayDate',
              `Trajetória deve ser anterior a ${formatDate(maxDate)}.`,
              (today: string) => {
                return checkTopDate(today, maxDate);
              },
            ),
          tpguia: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
          anb: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
          loc: yup.string().notOneOf(['', 'Selecione'], 'Campo obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        setUpdateDiag((state) => ({ ...state, open: !state.open }));
        setLoading(true);
        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            seq: prevReg.seq,
            gseq: gState.gseq,
            mode: 'update',
          }),
        );

        await api.post('/sgo/fig_trajetoria.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          updateRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, maxDate, minDate, prevReg.seq],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          seq: prevConReg.seq,
          gseq: gState.gseq,
          mode: 'delete',
        }),
      );

      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/sgo/fig_trajetoria.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getList();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, gState.gseq, getList, prevConReg.seq]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Trajetória do Guia</p>
        </AlteredHeader>
        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}

        <TableWrapper>
          <Table>
            <thead>
              <Dated>Data</Dated>
              {width > 470 && <TG>Tipo de Guia</TG>}
              <Desc>Detalhe</Desc>
              {width > 380 && (
                <>
                  <NAC>Nac{width > 470 ? 'ional' : '.'}</NAC>
                  <LOC>Loc{width > 470 ? 'al' : '.'}</LOC>
                </>
              )}

              {width > 800 ? null : <Consult>Con.</Consult>}
              {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                <>
                  <Update>Alt.</Update>
                  <Delete>Exc.</Delete>
                </>
              )}
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.seq}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <Dated>Data</Dated>
                      {width > 470 && <TG>Tipo de Guia</TG>}

                      <Desc>Detalhe</Desc>
                      {width > 380 && (
                        <>
                          <NAC>Nac{width > 470 ? 'ional' : '.'}</NAC>
                          <LOC>Loc{width > 470 ? 'al' : '.'}</LOC>
                        </>
                      )}

                      {width > 800 ? null : <Consult>Con.</Consult>}
                      {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                        <>
                          <Update>Alt.</Update>
                          <Delete>Exc.</Delete>
                        </>
                      )}
                    </tr>
                  )}

                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    <Dated>
                      {item.seq !== 0 ? formatDate(item.date) : item.date}
                    </Dated>
                    {width > 470 && (
                      <TG>{width > 600 ? item.tgdesc : item.tg}</TG>
                    )}
                    <Desc>
                      {width > 800
                        ? item.desc
                        : `${item.desc.substr(0, 10)}...`}
                    </Desc>
                    {width > 380 && (
                      <>
                        <NAC>{width > 1000 ? item.anbdesc : item.anb}</NAC>
                        <LOC>{width > 1000 ? item.locdesc : item.loc}</LOC>
                      </>
                    )}
                    {width > 800 ? null : (
                      <Consult>
                        <ButtonTD
                          used="show"
                          onClick={() => handlePreShow(item.seq)}
                        >
                          <FaSearch />
                        </ButtonTD>
                      </Consult>
                    )}
                    {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                      <>
                        <Update>
                          {item.seq !== 0 && (
                            <ButtonTD
                              used="alt"
                              onClick={() => handlePreAlt(item.seq)}
                            >
                              <FaPencilAlt />
                            </ButtonTD>
                          )}
                        </Update>
                        <Delete>
                          {item.seq !== 0 && (
                            <ButtonTD
                              used="del"
                              onClick={() => handlePreDel(item.seq)}
                            >
                              <FaTimesCircle />
                            </ButtonTD>
                          )}
                        </Delete>
                      </>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Data Trajetória:</p>
                      <InputDialog
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        isDate
                        placeholder="dd/mm/aaaa"
                        name="date"
                      />
                    </span>
                    <span>
                      <p>Tipo de Guia:</p>
                      <SelectV2
                        name="tpguia"
                        content={comboTG}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Detalhe:</p>
                      <Textarea
                        name="description"
                        placeholder="Insira a descrição"
                        mask="alpha"
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                        }}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Nacional:</p>
                      <SelectV2
                        name="anb"
                        content={comboANB}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                        onChange={handleANBChange}
                      />
                    </span>
                    <span>
                      <p>Local:</p>
                      <SelectV2
                        name="loc"
                        content={comboLOC}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              seq: prevReg.seq,
              date: prevReg.date,
              description: prevReg.description,
              tpguia: prevReg.tpguia,
              anb: prevReg.anb,
              loc: prevReg.loc,
            }}
          >
            <U.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Data Trajetória:</p>
                      <InputDialog
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                        name="date"
                      />
                    </span>
                    <span>
                      <p>Tipo de Guia:</p>
                      <SelectV2
                        name="tpguia"
                        content={comboTG}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.tpguia}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Detalhe:</p>
                      <Textarea
                        name="description"
                        placeholder="Insira a descrição"
                        mask="alpha"
                        containerStyle={{
                          width:
                            width >= 500
                              ? '360px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                        }}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Nacional:</p>
                      <SelectV2
                        name="anb"
                        content={comboANB}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.anb}
                        onChange={handleANBChangeUp}
                      />
                    </span>
                    <span>
                      <p>Local:</p>
                      <SelectV2
                        name="loc"
                        content={comboLOC}
                        containerStyle={{
                          width:
                            width >= 500
                              ? '172.5px'
                              : width >= 415
                              ? '280px'
                              : width >= 376
                              ? '245px'
                              : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.loc}
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={() => setUpdateDiag({ open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>

                <div>
                  <p>
                    Data: <strong>{formatDate(prevConReg.date)}</strong>
                  </p>
                  <p>
                    Tipo Guia: <strong>{prevConReg.tpguia}</strong>
                  </p>

                  <p>
                    ANB: <strong>{prevConReg.anb}</strong>
                  </p>
                  <p>
                    Local: <strong>{prevConReg.loc}</strong>
                  </p>
                  <p>
                    Detalhe: <strong>{prevConReg.description}</strong>
                  </p>
                </div>
                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>

        <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
          <S.Title>
            <h2>Consulta</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>
                Data: <strong>{formatDate(prevConReg.date)}</strong>
              </p>
              <p>
                Tipo Guia: <strong>{prevConReg.tpguia}</strong>
              </p>

              <p>
                ANB: <strong>{prevConReg.anb}</strong>
              </p>
              <p>
                Local: <strong>{prevConReg.loc}</strong>
              </p>
              <p>
                Detalhe: <strong>{prevConReg.description}</strong>
              </p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm type="button" onClick={() => setShow({ open: false })}>
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGTraj;
