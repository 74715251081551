import React from 'react';

import { Container, Content } from 'styles/wrappers';
import Header from 'components/Header';
import ScrollTop from 'components/ScrollTop';
import NavbarContainer from 'components/NavbarContainer';
import Footer from 'components/Footer';
import { AlteredHeader } from 'styles/sgo_wrappers';
import { TOVService, Schedule } from '../styles';

const manualadulto = `${process.env.REACT_APP_ASSETS_DIR}/materiais/99974bb4-8e32-4ce9-9a4e-91cea3fae869.png`;
const scheduleadult = `${process.env.REACT_APP_ASSETS_DIR}/tovServices/scheduleadult.jpg`;

const Adultos: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Oficinas de Oração e Vida para Adultos</div>
        </AlteredHeader>
        <TOVService>
          <h2>Manual TOV Adulto</h2>
          <div>
            <span>
              <p>
                TOV Adultos Esta Oficina recebe o participante à luz da Palavra
                e o introduz em uma prática de reflexão, oração, mensagens
                evangélicas e de análise da própria vida. Neste contexto, o
                Oficinista é levado, quase sem se dar conta, a uma transformação
                cristificante, deixando de ser “eu mesmo”, em seus traços
                negativos, e passando a assumir os traços positivos de Jesus:
                paciência, fortaleza, humildade, mansidão, amor. Não se trata de
                uma conversão de um final de semana, mas, sim, de um processo
                lento e evolutivo de mudança ao longo de quatro meses. O
                testemunho de vida é o fator que confere garantia e
                credibilidade aos TOV Adultos. A eficácia transformante,
                proclamada de boca em boca pelos participantes, constitui a
                principal razão da rápida expansão destas Oficinas de Oração e
                Vida. Se o bem e o mal nascem no coração do indivíduo, TOV
                Adultos atinge o coração humano e o transforma em um baluarte de
                harmonia, serenidade e amor. Do coração convertido, estes dons
                se irradiam à família e, da família, para a sociedade. Quem
                transforma um coração, transforma o mundo.
              </p>
            </span>
            <img src={manualadulto} alt="Manual TOV Adulto" />
          </div>
          <span>
            <h4>
              <strong>TOV Adultos</strong> <p>| Manual do Guia</p>
            </h4>
            <h4>
              <strong>Participantes:</strong>
              <p>Adultos</p>
            </h4>
            <h4>
              <strong>Duração:</strong>
              <p>
                Quinze sessões, uma por semana, com duração de duas horas cada.
              </p>
            </h4>
          </span>
        </TOVService>
        <Schedule>
          <h2>Programação da Oficina TOV Adulto</h2>
          <img src={scheduleadult} alt="Programação Tov Adulto" />
        </Schedule>
      </Content>
      <Footer />
    </Container>
  );
};

export default Adultos;
