export function et64(toencode: any): string {
  return btoa(encodeURIComponent(JSON.stringify(toencode)));
}

// export function df64(encoded: string): any {
//   return JSON.parse(decodeURIComponent(atob(encoded)));
// }

export function df64(encoded: string | undefined): any {
  if (encoded === undefined) {
    return '';
  }

  if (['(', '[', '{'].indexOf(encoded.substr(0, 1)) > -1) {
    return JSON.parse(encoded);
  }
  const hardCoded = encoded;
  // console.log(`----------- hardCoded:\n${hardCoded}`);

  const base64Decoded = atob(hardCoded);
  // console.log(`----------- base64Decoded:\n${base64Decoded}`);

  const urlDecoded = decodeURIComponent(base64Decoded);
  // console.log(`----------- urlDecoded:\n${urlDecoded}`);

  const parsed = JSON.parse(urlDecoded);
  // console.log(`----------- parsed:\n`);
  // console.table(parsed);
  return parsed;
}
