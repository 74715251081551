import styled from 'styled-components';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { lighten } from 'polished';

export const Grid = styled.div`
  padding: 35px 0 10px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1200px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    row-gap: 10px;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  /* align-items: center; */
  background: #efefef;

  position: relative;

  padding-bottom: 10px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;
  /* padding: 0 10px; */

  a {
    display: none;
  }

  h2 {
    /* position: absolute; */
    top: 0;
    z-index: 0;
    transform: translateY(-15px);
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #fff;
    transition: all 0.5s;

    background: #666;
  }

  p {
    margin: -15px 0 0 0;
    color: #767676;
    font-weight: 500;
    font-size: 18px;
    padding: 3px 5px;

    strong {
      color: #415fb9;
    }

    & + p {
      margin: 10px 0 0 0;
    }
  }

  div {
    display: flex;
    flex-direction: column;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0 0 0;

    button {
      border: 1px solid transparent;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      padding: 3px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 600px) {
    margin: 0 auto;
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const ButtonXLS = styled.button`
  svg {
    color: #106f06;
    transition: 0.35s ease;
  }

  &:hover {
    svg {
      color: ${lighten(0.2, '#106f06')};
    }
  }
`;

export const ButtonNF = styled.button`
  svg {
    color: #8f000c;
    transition: 0.35s ease;
  }

  &:hover {
    svg {
      color: ${lighten(0.2, '#8f000c')};
    }
  }
`;

export const ButtonReceipt = styled.button`
  svg {
    color: #565656;
    transition: 0.35s ease;
  }

  &:hover {
    svg {
      color: ${lighten(0.2, '#565656')};
    }
  }
`;

export const YearContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  width: 360px;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0 auto;
  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;
