import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';

import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import { convertSpecialChars, deconvertSpecialChars } from 'utils/specialChars';
import { LeftContainer, RightContainer, InfoContainer } from './styles';

import { OficinistasProps } from '../main';

interface StateProps {
  ofic: OficinistasProps;
  type: string;
  tscod: string;
}

interface FormData {
  name: string;
  fres: string;
  fcel: string;
  dtnasc: string;
  mail: string;
  cep: string;
  state: string;
  address: string;
  district: string;
  city: string;
}

const ParticipantesUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();

  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const [firstUF, setFirstUF] = useState('Selecione o Estado');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasState, setHasState] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ofic, setOfic] = useState<OficinistasProps>(() => {
    if (!location.state) {
      return {} as OficinistasProps;
    }

    return { ...location.state.ofic } as OficinistasProps;
  });

  const getUFs = useCallback(async () => {
    try {
      const response = await api.get('/combos/comboUFs.php');
      setComboEstados(response.data);

      if (ofic.state) {
        const index = response.data.findIndex(
          (item: ComboProps) => item.value === ofic.state,
        );

        if (index >= 0) {
          formRef.current?.setFieldValue('state', ofic.state);

          setFirstUF(response.data[index].value);
          setHasState(true);
        }
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, ofic.state]);

  useEffect(() => {
    if (
      !(
        location.pathname.includes('atividades') ||
        location.pathname.includes('servicos')
      )
    ) {
      history.replace(
        `${location.pathname.substring(
          0,
          location.pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }
    if (!location.state) {
      history.goBack();
    }

    getUFs();
  }, [ofic, getUFs, history, location.pathname, location.state]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      formRef.current?.setFieldError('cep', '');
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const response = await api.get(
          `https://apps.widenet.com.br/busca-cep/api/cep.json?code=${cepInput.replace(
            /\D/g,
            '',
          )}`,
        );

        const { address, city, district, state, ok, message } = response.data;

        if (!ok) {
          formRef.current?.setFieldError('cep', message);
          formRef.current?.clearField('cep');
          return;
        }

        if (state && state.length > 0) {
          setFirstUF(
            `${
              comboEstados[
                comboEstados.findIndex((uf: ComboProps) => uf.value === state)
              ].value
            }`,
          );
          setHasState(true);
        } else {
          setFirstUF('Selecione o Estado');
        }

        form.setData({
          address: cepInput === ofic.cep ? ofic.address : address,
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setHasState(false);
      }
    }
  }, [comboEstados, ofic.address, ofic.cep]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          fres: yup.string().nullable(true),
          fcel: yup.string().nullable(true),
          dtnasc: yup
            .date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .notRequired(),
          mail: yup.lazy((val) =>
            val
              ? yup.string().email('Formato inválido de e-mail')
              : yup.string().nullable(true),
          ),
          cep: yup.string().min(9, 'O CEP deve conter 9 caracteres.'),
          state: yup.string().nullable(true),
          address: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .trim()
                  .min(4, 'Quantidade mínima de caracteres não atingida (4).')
                  .max(70, 'Quantidade máxima de caracteres excedida (70).')
              : yup.string().nullable(true),
          ),

          district: yup.string().trim().nullable(true),
          city: yup.string().trim().nullable(true),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          name: convertSpecialChars(data.name),
          address: convertSpecialChars(data.address),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          seq: ofic.seq,
          oseq: ofic.oseq,
        };

        await api.get(
          `/sgo/atividades_participantes_update.php?data=${JSON.stringify(
            send,
          )}`,
        );

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Oficinista ${data.name} alterado!`,
        });

        history.goBack();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, ofic.oseq, ofic.seq],
  );

  return (
    <Container>
      <Loading isLoading={loading} />

      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração Oficinista" />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: deconvertSpecialChars(ofic.name),
            fres: ofic.fres,
            fcel: ofic.fcel,
            dtnasc: ofic.dtnasc !== '0000-00-00' ? ofic.dtnasc : '',
            mail: ofic.mail,
            cep: ofic.cep,
            address: deconvertSpecialChars(ofic.address),
            district: deconvertSpecialChars(ofic.district),
            city: deconvertSpecialChars(ofic.city),
          }}
        >
          <InfoContainer>
            <LeftContainer delay={0.1}>
              <span>
                <p>Nome</p>
                <Input name="name" mask="name" placeholder="Nome" />
              </span>
              <span>
                <p>Telefone</p>
                <Input name="fres" mask="phone" placeholder="(XX) XXXX-XXXX" />
              </span>
              <span>
                <p>Celular</p>
                <Input name="fcel" mask="phone" placeholder="(XX) XXXXX-XXXX" />
              </span>
              <span>
                <p>
                  Data de{' '}
                  {location.state.tscod === 'CC' ? 'Matrimônio' : 'Nascimento'}
                </p>
                <Input
                  name="dtnasc"
                  isDate
                  type={ofic.dtnasc === '0000-00-00' ? 'text' : 'date'}
                  placeholder="dd/mm/aaaa"
                  defaultValue={ofic.dtnasc === '0000-00-00' ? '' : ofic.dtnasc}
                />
              </span>
              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-Mail" />
              </span>
            </LeftContainer>
            <RightContainer delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  content={comboEstados}
                  initial={firstUF}
                  disabled
                />
              </span>
              <span>
                <p>Endereço</p>
                <Textarea name="address" placeholder="Endereço" mask="alpha" />
              </span>

              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" mask="alpha" />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" mask="alpha" />
              </span>
            </RightContainer>
          </InfoContainer>

          <Button
            bgcolor="#00802b"
            type="submit"
            onClick={() => handleSubmit}
            containerStyle={{ marginBottom: '25px' }}
          >
            Alterar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ParticipantesUpdate;
