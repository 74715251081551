import styled, { css } from 'styled-components';
import { parseToRgb } from 'polished';

interface ZonalProps {
  bgcolor?: string;
}

export const Container = styled.div`
  width: 100vw;
  background: #fff;
`;

export const World = styled.section`
  width: 100%;
  max-width: 1000px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  img {
    width: 80%;
    max-width: 1000px;
    object-fit: cover;
    object-position: 50% 0;
  }
  div {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    h1 {
      color: #c53030;
      font-family: 'Roboto Slab', serif;
    }

    span {
      margin-top: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      p {
        width: 50%;
        text-align: right;
        font-family: 'Roboto', serif;
      }
      h4 {
        width: 50%;
        margin-left: 15px;
        font-family: 'Roboto', serif;
      }

      & + span {
        margin-top: 0;
      }
    }
  }
`;

export const Zonal = styled.section`
  width: 100%;
  max-width: 1000px;

  > figure {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 80%;
      max-width: 413px;
    }
  }
  h1 {
    width: 100%;
    text-align: center;
    background: linear-gradient(
      to right,
      rgba(138, 0, 2, 0),
      rgba(138, 0, 2, 0.35),
      rgba(138, 0, 2, 0.5),
      rgba(138, 0, 2, 0.75),
      rgba(138, 0, 2, 1),
      rgba(138, 0, 2, 0.75),
      rgba(138, 0, 2, 0.5),
      rgba(138, 0, 2, 0.35),
      rgba(138, 0, 2, 0)
    );
    color: #fff;
    font-family: 'Roboto Slab', serif;
  }

  div {
    margin: 10px 0;
    display: grid;
    grid: 350px / 1fr 1fr;

    > figure {
      display: flex;

      align-items: center;
      justify-content: center;

      img {
        height: 80%;
        max-height: 250px;
      }
    }

    --red: ${parseToRgb('#8a0002').red};
    --green: ${parseToRgb('#8a0002').green};
    --blue: ${parseToRgb('#8a0002').blue};

    > span {
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 75%,
        rgba(var(--red), var(--green), var(--blue), 0.75) 100%
      );

      margin: auto 0;
      height: 300px;

      padding: 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border-radius: 5px;

      border: 2px solid transparent;
      border-top-color: #8a0002;
      border-bottom-color: #8a0002;

      h2 {
        text-align: center;
        color: #c53030;
        text-decoration: underline;
        width: 100%;
      }
      h3 {
        color: #8a0002;
        text-align: center;
        font-style: italic;
        width: 100%;
      }
      span {
        border: 0px;
        margin-top: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        p {
          color: #6a0002;
          width: 50%;
          text-align: right;
          font-family: 'Roboto', serif;
        }
        h4 {
          width: 50%;
          margin-left: 15px;
          font-family: 'Roboto', serif;
        }

        & + span {
          margin: 3px 0 0 0;
        }
      }

      & + span {
        margin: 25px 0 0 5px;
      }
    }
  }

  & + section {
    margin-top: 20px;
  }

  @media screen and (max-width: 1000px) {
    div {
      grid: 220px / 1fr;
      justify-items: center;

      > span {
        background: transparent;
        border-radius: 10px;
        /* border-top-color: #8a0002;
        border-bottom-color: #8a0002; */
        width: 80%;
        height: auto;
        margin: 0 auto;

        span {
          text-justify: justify;
        }

        & + span {
          margin: 10px 0 0 0;
        }
      }
    }
  }
`;

export const ZonalSpan = styled.span<ZonalProps>`
  ${(props) =>
    props.bgcolor
      ? css`
          --red: ${parseToRgb(`${props.bgcolor}`).red};
          --green: ${parseToRgb(`${props.bgcolor}`).green};
          --blue: ${parseToRgb(`${props.bgcolor}`).blue};
        `
      : css`
          --red: ${parseToRgb('#8a0002').red};
          --green: ${parseToRgb('#8a0002').green};
          --blue: ${parseToRgb('#8a0002').blue};
        `}

  background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 75%,
        rgba(var(--red), var(--green), var(--blue), 0.75) 100%
      ) !important;

  @media screen and (max-width: 1000px) {
    div {
      > span {
        ${(props) =>
          props.bgcolor
            ? css`
                border-top-color: ${props.bgcolor} !important;
                border-bottom-color: ${props.bgcolor} !important;
              `
            : css`
                border-top-color: #8a0002;
                border-bottom-color: #8a0002;
              `}
      }
    }
  }
`;
