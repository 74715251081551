import styled, { css } from 'styled-components';

interface TypeButtonProps {
  marked: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid #efefef;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  /* width: 400px; */
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > div {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      color: #8a0002;
      font-weight: 500;
      margin: 0 0 3px 0;
    }
    > span {
      display: flex;
      align-items: center;
      /* justify-content: space-around; */
      max-width: 360px;
      /* z-index: 100; */
      /* overflow: hidden; */
      button {
        & + button {
          margin: 0 0 0 15px;
        }
      }
    }

    & + div {
      margin: 10px 0 0 0;
    }
  }
`;

export const TypeButton = styled.button<TypeButtonProps>`
  position: relative;
  display: flex;
  padding: 3px 5px;
  border: 2px solid #c6c6c6;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  background: transparent;

  transition: all 0.35s;

  ${(props) =>
    props.marked &&
    css`
      background-color: #fbdadb;
      border-color: #8a0002;
      color: #8a0002;
    `}

  &:hover {
    border-color: #8a0002;
    color: #8a0002;
  }
`;
