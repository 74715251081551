import React, { useCallback, useEffect, useState, useRef } from 'react';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import { useSpring, animated } from 'react-spring';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { Link, useLocation } from 'react-router-dom';
import { FaRegFileExcel, FaRegListAlt, FaTimes } from 'react-icons/fa';

import {
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  Container,
  Content,
  RemoveButton,
} from 'styles/sgo_wrappers';

import api from 'services/api';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Menu, AnimatedDiv, PrintContainer } from './styles';

const OfertaMenu: React.FC = () => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const formRef = useRef<FormHandles>(null);
  const [loading] = useState(false);
  const { handlePermission } = useCredentials();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [message, setMessage] = useState<ShowProps>({} as ShowProps);

  const [comboNac, setComboNac] = useState<ComboProps[]>([]);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboYears, setComboYears] = useState<ComboProps[]>([]);

  const [hasData, setHasData] = useState(false);

  const [defaultYear, setDefaultYear] = useState(() => {
    return new Date().getUTCFullYear().toString();
  });

  const [defaultSemester, setDefaultSemester] = useState('2');

  const [defaultANB, setDefaultANB] = useState(() => {
    return {
      value: user.zoncod,
      label: 'Resumo de todas Nacionais',
    };
  });

  const [defaultCoord, setDefaultCoord] = useState({
    value: '',
    label: 'Todas da Nacional',
  });

  const [coordSelected, setCoordSelected] = useState(() => {
    return ['ZON', 'NAC'].indexOf(user.perfil) > -1
      ? { value: user.anbc, label: user.anbdesc }
      : { value: user.loccod, label: user.locdesc };
  });

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNac([
      { value: user.zoncod, label: 'Resumo de todas Nacionais' },
      ...response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    ]);
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    setComboLocs([
      { value: '', label: 'Todas da Nacional' },
      ...response.data.filter(
        (item: ComboLocalProps) => item.anb === user.anbc,
      ),
    ]);
  }, [user.anbc]);

  const getYears = useCallback(async () => {
    // setLoading(true);
    const send = {
      type: user.perfil,
      anbcod: defaultANB.value,
      loccod: coordSelected.value,
      menu: true,
    };

    const response = await api.get(
      `/sgo/oferta_list.php?data=${JSON.stringify(send)}`,
    );

    const currentYear = new Date().getUTCFullYear().toString();
    const retrievedYears = response.data.map((item: ComboProps) => item.value);

    if (!retrievedYears.includes(currentYear)) {
      setDefaultYear(retrievedYears[retrievedYears.length - 1]);
    } else {
      setDefaultSemester('1');
    }

    setComboYears(response.data);
    // setLoading(false);
  }, [coordSelected.value, defaultANB.value, user.perfil]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    if (!hasData) {
      if (user.perfil === 'ZON') {
        getComboANB();
      }
      if (user.perfil !== 'LOC') {
        getComboLOC();
      }
      setHasData((state) => !state);
    }
    getYears();
  }, [
    handlePermission,
    getComboLOC,
    getComboANB,
    user.perfil,
    getYears,
    hasData,
  ]);

  const handleNACSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('coordNac');
    const { value, text } = select.options[select.selectedIndex];
    // getYears();
    setCoordSelected({ value, label: text });
    setDefaultANB({ value, label: text });
    setDefaultCoord({
      value: '',
      label: 'Todas da Nacional',
    });

    setComboLocs([
      { value: '', label: 'Todas da Nacional' },
      ...locs.filter((item: ComboLocalProps) => item.anb === value),
    ]);
  }, [locs]);

  const handleCoordSelection = useCallback(() => {
    // getYears();
    const select = formRef.current?.getFieldRef('coord');
    const { value, text } = select.options[select.selectedIndex];

    setDefaultCoord({ value, label: text });

    const selected = { value, label: text };

    if (value === '') {
      if (user.perfil === 'ZON') {
        const selectedNac = formRef.current?.getFieldRef('coordNac');
        const nacSelection = selectedNac.options[selectedNac.selectedIndex];

        selected.value = nacSelection.value;
        selected.label = nacSelection.text;
      } else {
        selected.value = user.anbc;
        selected.label = user.anbdesc;
      }
    }
    setCoordSelected(selected);
  }, [user.anbc, user.anbdesc, user.perfil]);

  const removeLocSelection = useCallback(() => {
    setDefaultCoord({ value: '', label: 'Todas da Nacional' });
    const selected = { value: '', label: '' };

    if (user.perfil === 'ZON') {
      const selectedNac = formRef.current?.getFieldRef('coordNac');
      const nacSelection = selectedNac.options[selectedNac.selectedIndex];

      selected.value = nacSelection.value;
      selected.label = nacSelection.text;
    } else {
      selected.value = user.anbc;
      selected.label = user.anbdesc;
    }
    setCoordSelected(selected);
  }, [user.anbc, user.anbdesc, user.perfil]);

  const handleDownload = useCallback(async () => {
    setShow((state) => ({ ...state, open: false }));
    setMessage((state) => ({ ...state, open: true }));

    const timer = setTimeout(() => {
      setShow((state) => ({ ...state, open: true }));
      setMessage((state) => ({ ...state, open: false }));
    }, 5000);

    anchorRef.current?.click();

    return () => {
      clearTimeout(timer);
    };

    // const link = document.querySelector<HTMLAnchorElement>(`a[id=offerXLS]`);
    // if (link) {
    //   link.click();
    // }
  }, []);

  const anchor = useSpring({
    display: 'none',
  });

  const openDialog = useCallback(() => {
    setShow({ open: true });
  }, []);

  const removeButton = useSpring({
    opacity: defaultCoord.value !== '' && user.perfil === 'ZON' ? 1 : 0,
    pointerEvents:
      defaultCoord.value !== '' && user.perfil === 'ZON' ? 'all' : 'none',
  });

  const handleYearSelection = useCallback(() => {
    setDefaultYear(formRef.current?.getFieldValue('year').toString());
  }, []);

  const handleSemesterSelection = useCallback(() => {
    const selected = formRef.current?.getFieldValue('semester');
    console.log(selected);
    setDefaultSemester(formRef.current?.getFieldValue('semester'));
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Menu de Ofertas</AlteredHeader>
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>Oficinas no Período</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.35}>
            <Link to={`${pathname.replace('menu', 'repasse')}`}>
              <FaRegListAlt size={30} />
              <h3>Repasses no Período</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.7}>
            <button type="button" onClick={openDialog}>
              <FaRegFileExcel size={30} />
              <h3>Planilha de Ofertas no Período</h3>
            </button>
          </AnimatedDiv>
        </Menu>
      </Content>
      <animated.a
        ref={anchorRef}
        style={anchor}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_ofertas.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          params: {
            anb:
              ['NAC', 'LOC'].indexOf(user.perfil) > -1
                ? user.anbc
                : defaultANB.value,
            loc: coordSelected.value,
            year: defaultYear,
            semester: defaultSemester,
            type: user.perfil,
          },
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Ofertas Período
      </animated.a>

      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>Geração XLS</h2>
        </S.Title>
        <Form ref={formRef} onSubmit={handleDownload}>
          <S.Content>
            <PrintContainer>
              {user.perfil === 'ZON' && (
                <span>
                  <p>Coordenação Nacional:</p>
                  <SelectV2
                    // caretStyle={{
                    //   transform: 'translateX(0px)',
                    // }}
                    // containerStyle={{
                    //   borderColor: '#a6a6a6',
                    //   width: '250px',
                    //   maxWidth: '250px',
                    // }}
                    // dropdownStyle={{ width: '250px', maxWidth: '250px' }}
                    content={comboNac}
                    name="coordNac"
                    initial={defaultANB.value}
                    onChange={handleNACSelection}
                  />
                  <RemoveButton
                    type="button"
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {user.perfil !== 'LOC' && (
                <span>
                  <p>Coordenação Local:</p>
                  <SelectV2
                    // caretStyle={{
                    //   transform: 'translateX(0px)',
                    // }}
                    // containerStyle={{
                    //   borderColor: '#a6a6a6',
                    //   width: '250px',
                    //   maxWidth: '250px',
                    // }}
                    // dropdownStyle={{ width: '250px', maxWidth: '250px' }}
                    content={comboLocs}
                    name="coord"
                    initial={defaultCoord.value}
                    onChange={handleCoordSelection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeLocSelection}
                    style={removeButton}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}

              <span>
                <p>Ano:</p>
                <SelectV2
                  // caretStyle={{
                  //   transform: 'translateX(0px)',
                  // }}
                  // containerStyle={{
                  //   borderColor: '#a6a6a6',
                  //   width: '250px',
                  //   maxWidth: '250px',
                  // }}
                  // dropdownStyle={{ width: '250px', maxWidth: '250px' }}
                  content={comboYears}
                  name="year"
                  initial={defaultYear}
                  onChange={handleYearSelection}
                />
              </span>

              <span>
                <p>Semestre:</p>
                {new Date().getUTCMonth() < 6 &&
                new Date().getUTCFullYear() === parseInt(defaultYear, 10) ? (
                  <span>
                    <strong>Primeiro</strong>
                  </span>
                ) : (
                  <RadioContainer
                    title=""
                    onClick={handleSemesterSelection}
                    name="semester"
                    content={[
                      {
                        id: 'xP',
                        value: '1',
                        label: 'Primeiro',
                      },
                      {
                        id: 'xS',
                        value: '2',
                        label: 'Segundo',
                      },
                    ]}
                    selected={defaultSemester}
                  />
                )}
              </span>
            </PrintContainer>
          </S.Content>
          <S.Actions>
            <S.Confirm type="submit">Gerar</S.Confirm>
            <S.Cancel
              type="button"
              onClick={() => setShow({ ...message, open: false })}
            >
              Fechar
            </S.Cancel>
          </S.Actions>
        </Form>
      </S.Container>

      <S.Container scroll="paper" maxWidth={false} open={message.open}>
        <S.Title>{/*  */}</S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => {
              setShow({ ...show, open: true });
              setMessage({ ...show, open: false });
            }}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>

      <SGOFooter />
    </Container>
  );
};

export default OfertaMenu;
