import styled, { keyframes, css } from 'styled-components';
import { parseToRgb } from 'polished';

interface GridItemProps {
  delay?: number;
  duration?: number;
  blocked?: boolean;
}

interface StageBlockProps {
  banner?: string;
  textcolor?: string;
}

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-150px);

  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: rotate(37deg) translateY(-195px) translateX(37px);
  } to {
    opacity: 1;
    transform: rotate(37deg) translateY(-165px) translateX(37px);
  }
`;

export const Grid = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  position: relative;
  /* width: 1200px; */

  /* align-items: center;
  justify-content: center; */
  row-gap: 25px;
  column-gap: 25px;

  @media screen and (max-width: 1300px) {
    width: 100vw;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.blocked &&
    css`
      * {
        cursor: not-allowed;
      }
    `}

  opacity: 0;
  transform: translateX(-150px);

  animation: ${appearFromLeft} ${(props) => props.duration || 1}s ease forwards;

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}
  /* width: 400px; */
  /* background: black; */

  h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #332e2e;
    text-shadow: 0px 2px 5px rgba(51, 46, 46, 0.5);
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px 7px;
    background: #fff;
    overflow: hidden;

    position: relative;

    padding-bottom: 10px;

    border: 3px solid #efefef;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: all 0.35s;

    figure {
      animation-delay: ${(props) => (props.delay || 1) + 0.2}s;
    }

    > div {
      display: flex;

      ${(props) =>
        props.blocked &&
        css`
          * {
            pointer-events: none;
            opacity: 0.8;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -khtml-user-select: none;
            user-select: none;
          }
        `}
      > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #8a0002;
          text-shadow: unset;
        }
        p {
          color: #8a0002;
          font-size: 16px;
          font-weight: bold;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
          user-select: none;
        }
        & + span {
          margin: 0 0 0 5px;
        }
      }

      & + div {
        margin: 10px 0 0 0;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 360px) {
    width: 100vw;

    > div {
      border-radius: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
    }
  }
`;

export const StageBlock = styled.figure<StageBlockProps>`
  --red: ${parseToRgb('#87ceeb').red};
  --green: ${parseToRgb('#87ceeb').green};
  --blue: ${parseToRgb('#87ceeb').blue};

  ${(props) =>
    props.banner &&
    css`
      --red: ${parseToRgb(`${props.banner}`).red};
      --green: ${parseToRgb(`${props.banner}`).green};
      --blue: ${parseToRgb(`${props.banner}`).blue};
    `}

  margin: -2px -7px;

  background-image: linear-gradient(
    to right,
    rgba(var(--red), var(--green), var(--blue), 0.25),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 1),
    rgba(var(--red), var(--green), var(--blue), 0.75),
    rgba(var(--red), var(--green), var(--blue), 0.25)
  );

  position: absolute;
  z-index: 200;
  top: 50%;
  width: 100%;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;

  opacity: 0;
  transform: rotate(37deg) translateY(-195px) translateX(37px);
  animation: ${appearFromTop} 1s forwards;

  p {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    color: #332e2e;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
`;
// export const StageBlock = styled.figure`
//   margin: -2px -7px;
//   background-image: linear-gradient(
//     to right,
//     rgba(117, 0, 0, 0.25),
//     rgba(117, 0, 0, 0.75),
//     rgba(117, 0, 0, 1),
//     rgba(117, 0, 0, 0.75),
//     rgba(117, 0, 0, 0.25)
//   );
//   background-image: linear-gradient(
//     to right,
//     rgba(135, 206, 235, 0.25),
//     rgba(135, 206, 235, 0.75),
//     rgba(135, 206, 235, 1),
//     rgba(135, 206, 235, 0.75),
//     rgba(135, 206, 235, 0.25)
//   );
//   background-image: linear-gradient(
//     to right,
//     rgba(102, 102, 102, 0.25),
//     rgba(102, 102, 102, 0.75),
//     rgba(102, 102, 102, 1),
//     rgba(102, 102, 102, 0.75),
//     rgba(102, 102, 102, 0.25)
//   );
//   position: absolute;
//   z-index: 200;
//   top: 50%;
//   width: 130%;

//   padding: 5px;
//   border-radius: 5px;
//   p {
//     font-size: 18px;
//     font-weight: 500;
//     width: 100%;
//     text-align: center;
//     color: #ffffff;
//     text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
//   }
//   transform: rotate(-37deg) translateY(-46px) translateX(-30px);
// `;
