import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useWindow } from 'hooks/window';

import ReactPlayer from 'react-player/lazy';
import JsFileDownloader from 'js-file-downloader';

import api from 'services/api';

import { FaDownload, FaPlusCircle, FaVolumeUp } from 'react-icons/fa';

import { Container, Content } from 'styles/sgo_wrappers';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import {
  Wrapper,
  WrapperContent,
  ASide,
  BSide,
  DownloadContainer,
  Details,
  SubTrack,
  PlayerContainer,
  PlayerWrapper,
  ProgressDiv,
} from './styles';
import {
  SubtrackProps,
  MediaProps,
  DocumentProps,
  LocationProps,
} from './interfaces';

const DigitalMediaContent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { hash, search } = useLocation();
  const location = useLocation<LocationProps>();
  const { goBack } = useHistory();
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { width } = useWindow();

  const [validLicense, setValidLicense] = useState<boolean>(false);
  const [fileUrl, setFileURL] = useState('null.mp3');
  const [bannerName, setBannerName] = useState('');
  // const [fileUrl, setFileURL] = useState(
  //   'https://tovbrasil.com.br/api/midias/MD07/2ef1bc71-d9be-462d-bfcf-35ee26107395.mp3',
  // );
  // const [bannerName, setBannerName] = useState(
  //   '5ª Mensagem - 01 Curar as feridas - Regra de ouro - Maneiras de perdoar',
  // );

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Arquivo',
  } as ShowProps);

  const [matBanner] = useState(`${location.state.matBanner}`);
  const [title] = useState(`${location.state.matName}`);

  const path = `${process.env.REACT_APP_MIDIAS_DIR}`;
  const mat = `${search.substring(1)}`;

  const [tracks, setTracks] = useState<MediaProps[]>([]);
  const [documents, setDocuments] = useState<DocumentProps[]>([]);

  const [downloaded, setDownloaded] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const getContent = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/midia_details.php?data=${JSON.stringify({
          mat: search.substring(1),
        })}`,
      );

      const { extracks, exdocuments } = response.data;

      setTracks(extracks);
      setDocuments(exdocuments);
    } catch (err) {
      errorHandling(err);
    } finally {
      setValidLicense(true);
      setLoading(false);
    }
  }, [errorHandling, search]);

  const checkLicense = useCallback(async (): Promise<boolean> => {
    const response = await api.get(
      `/sgo/midia_check.php?data=${JSON.stringify({
        license: hash.substring(1),
        mat: search.substring(1),
      })}`,
    );

    return response.data;
  }, [hash, search]);

  useEffect(() => {
    if (user.adm) {
      getContent();
      return;
    }

    checkLicense().then((result) => {
      if (!result) {
        console.log(`result::${result}`);
        addToast({
          type: 'error',
          title: 'Licença inválida',
          description: `Não foi possível validar a licença. Tente novamente e caso o problema persista, acione o suporte ao SGO`,
          seconds: 10,
        });

        goBack();
        return;
      }

      getContent();
    });
  }, [checkLicense, user.adm, goBack, addToast, getContent, location]);

  const handleListen = useCallback(
    (ev) => {
      const { file, track, filetype } = ev.currentTarget.dataset;
      setBannerName(track);

      setFileURL(`${path}/${mat}/${file}.${filetype}`);

      if (width < 675) {
        const element = document.querySelector("div[data-tag='player']");
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    },
    [mat, path, width],
  );

  const process_loading = useCallback(
    (event: ProgressEvent<EventTarget>): void => {
      if (!event.lengthComputable) return;
      const downloadingPercentage = parseFloat(
        ((event.loaded / event.total) * 100).toFixed(2),
      );
      setDownloaded(downloadingPercentage);
    },
    [],
  );

  const handleDownload = useCallback(
    (ev) => {
      setShow((state) => ({ ...state, open: true }));
      const { file, filename, filetype } = ev.currentTarget.dataset;
      const fUrl = `${path}/${mat}/${file}.${filetype}`;

      setDownloaded(0);
      setShowProgressBar((state) => !state);

      const returned = new JsFileDownloader({
        url: fUrl,
        autoStart: false,
        forceDesktopMode: true,
        timeout: 360000,
        nameCallback() {
          return `${filename}.${filetype}`;
        },
        contentType: filetype === 'mp3' ? 'audio/mpeg' : 'application/zip',
        process: process_loading,
      });

      returned
        .start()
        .then(() => {})
        .catch((err) => console.error(err)) // TODO: add error toast
        .finally(() => {
          setShow((state) => ({ ...state, open: false }));
          setShowProgressBar((state) => !state);
        });
    },
    [mat, path, process_loading],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={title} isResponsible />
      <Content>
        {validLicense && (
          <Wrapper>
            <DownloadContainer>
              <h3>CD{documents.length > 1 ? 's' : ''} .zip</h3>
              <div>
                {documents.map((item: DocumentProps) => (
                  <button
                    type="button"
                    data-filename={item.name}
                    data-file={item.file}
                    data-filetype="zip"
                    onClick={handleDownload}
                  >
                    <FaDownload />
                    <p>{item.name.replace('CD de ', '')}</p>
                  </button>
                ))}
              </div>
            </DownloadContainer>
            <WrapperContent>
              <ASide>
                {tracks.map((item: MediaProps) => (
                  <Details key={item.track}>
                    <summary>
                      {item.trackname} <FaPlusCircle />
                    </summary>
                    {item.subtracks.map((subitem: SubtrackProps) => (
                      <SubTrack key={subitem.file}>
                        <span>
                          <p>
                            {`0${subitem.num}`.slice(-2)}.&nbsp;{subitem.name}
                          </p>
                        </span>
                        <span>
                          <button
                            type="button"
                            // data-id="button1"
                            data-mode="listen"
                            data-track={`${
                              item.trackname
                            } - ${`0${subitem.num}`.slice(-2)}. ${
                              subitem.name
                            }`}
                            data-file={subitem.file}
                            data-filetype="mp3"
                            onClick={handleListen}
                          >
                            <FaVolumeUp
                              color={
                                bannerName ===
                                `${item.trackname} - ${`0${subitem.num}`.slice(
                                  -2,
                                )}. ${subitem.name}`
                                  ? '#03A9F4'
                                  : '#1A237E'
                              }
                            />
                          </button>
                        </span>
                        <span>
                          <button
                            type="button"
                            // data-id="button1"
                            data-mode="download"
                            data-filename={`${item.desc.replace(
                              'Midia Digital do ',
                              '',
                            )} - ${item.trackname} - ${
                              subitem.num < 10 ? '0' : ''
                            }${subitem.num}. ${subitem.name}`}
                            data-file={subitem.file}
                            data-filetype="mp3"
                            onClick={handleDownload}
                          >
                            <FaDownload color="#1A237E" />
                          </button>
                        </span>
                      </SubTrack>
                    ))}
                  </Details>
                ))}
              </ASide>
              <BSide>
                <PlayerContainer>
                  <img alt="img" src={`${matBanner}`} />
                  <div>
                    <p>{bannerName}</p>
                  </div>
                  <ReactPlayer
                    data-tag="player"
                    wrapper={PlayerWrapper}
                    height={60}
                    width={340}
                    loop={false}
                    url={fileUrl}
                    controls
                    onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                    playing
                    volume={0.1}
                    style={{ maxWidth: '90vw', backgroundColor: 'transparent' }}
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                </PlayerContainer>
              </BSide>
            </WrapperContent>
          </Wrapper>
        )}
        {/* <DigitalContentContainer>
          <DownloadsContainer>
            {documents.map((item: DocumentProps) => (
              <button
                type="button"
                data-filename={item.file}
                // onClick={handleDownloadZip}
                data-exib={item.name}
              >
                <FaFileDownload />
              </button>
            ))}
          </DownloadsContainer>
          <Content>
            <div>4 Content</div>
            <div>4 Player</div>
          </Content>
        </DigitalContentContainer> */}
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px 0',
            }}
          >
            <div>
              <p>Geração do arquivo em andamento.</p>
              <p>Aguarde chegar em 100%</p>
            </div>
            {showProgressBar && (
              <ProgressDiv>
                <span>
                  <strong>{downloaded}% concluído</strong>
                </span>
                <progress id="file" value={downloaded} max="100">
                  {downloaded}
                </progress>
              </ProgressDiv>
            )}
          </div>
        </S.Content>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default DigitalMediaContent;
