import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

import { FloatInput } from 'styles/sgo_wrappers';

const rotatePhone = keyframes`
  0%{
    transform: rotateZ(0deg);
  } 35% {
    transform: rotateZ(90deg);
  } 50% {
    transform: rotateZ(90deg);
  } 65% {
    transform: rotateZ(90deg);
  } 100% {
    transform: rotateZ(0deg);
  }
`;

interface ContentProps {
  paint: number;
}

interface PerfProps {
  extend: boolean;
}

export const AlteredFloatInput = styled(FloatInput)`
  z-index: 3;
`;

export const RotateMessage = styled(animated.div)`
  margin: 0 0px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  span {
    p {
      font-size: 13px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      text-align: center;
    }
    svg {
      height: 20px;
      width: 20px;
      animation: ${rotatePhone} 3s infinite;
    }

    & + span {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0 30px 10px 0;
    width: 330px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 20px;
`;

export const H3Stilo = styled.h3`
  font-size: 18.72px;
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;

  margin: 0px 0px 5px 0px;
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;

  margin: 0px 0px 10px 0px;

  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
  );

  border-radius: 5px;

  transition: background-color 0.35s ease;

  span {
    font-size: 16px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 1)
    );

    span + span {
      margin: 3px 0 0 0;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  margin: 0 auto 25px 0;
  border-radius: 5px;
  height: auto;
  overflow: hidden;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  h3 {
    margin: 0 auto;
    position: relative;
  }
`;

export const MovContainer = styled.div`
  /* width: 90vw; */
  margin: 0 auto;
  position: relative;
  background: #332e2e;

  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  border-radius: 5px;
`;

export const CPRHeader = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  background: #332e2e;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 16px;
`;

export const MovContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Wrapper = styled.div<ContentProps>`
  background: ${(props) =>
    props.paint ? (props.paint % 2 > 0 ? '#e6e6e6' : '#fff') : '#fff'};

  span {
  }
`;

export const Data = styled.span<PerfProps>`
  display: flex;
  width: ${(props) => (props.extend ? '150px' : '100px')};
  justify-content: flex-start;
  align-items: center;
  padding: 3px;

  transition: width 0.35s ease-in;

  @media screen and (max-width: 400px) {
    width: ${(props) => (props.extend ? '130px' : '80px')};
  }
`;

export const Description = styled.span`
  display: flex;
  width: 550px;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  opacity: 1;

  transition: width 0.35s ease-in, opacity 0.35s ease-in;

  @media screen and (max-width: 1100px) {
    width: 475px;
  }
  @media screen and (max-width: 1000px) {
    width: 550px;
  }
  @media screen and (max-width: 900px) {
    width: 250px;
  }
  @media screen and (max-width: 700px) {
    display: none;
    opacity: 0;
    width: 0px;
    height: 0px;
    padding: 0px;
  }
`;

export const Document = styled.span<PerfProps>`
  display: flex;
  width: ${(props) => (props.extend ? '250px' : '200px')};
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  opacity: 1;

  transition: width 0.35s ease-in, opacity 0.35s ease-in;

  @media screen and (max-width: 1000px) {
    opacity: 0;
    width: 0px;
    height: 0px;
    padding: 0px;
    display: none;
  }
  @media screen and (max-width: 700px) {
    opacity: 1;
    width: ${(props) => (props.extend ? '230px' : '180px')};
    height: auto;
    padding: 3px;
    display: flex;
  }

  @media screen and (max-width: 510px) {
    width: ${(props) => (props.extend ? '150px' : '100px')};
  }

  @media screen and (max-width: 470px) {
    width: 100px;
  }
`;

export const Value = styled.span<PerfProps>`
  padding: 3px;
  display: flex;
  width: ${(props) => (props.extend ? '125px' : '75px')};
  justify-content: flex-end;
  align-items: center;
`;

export const UpdateReg = styled.span`
  padding: 3px;
  display: flex;
  width: 75px;
  justify-content: center;
  align-items: center;

  transition: width 0.35s ease-in;

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #007acc;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    transition: background-color 0.35s ease;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    a {
      background-color: ${shade(0.2, '#007acc')};
    }
  }

  @media screen and (max-width: 470px) {
    width: 50px;
  }
`;
export const ExcReg = styled.span`
  display: flex;
  width: 75px;
  padding: 3px;
  justify-content: center;
  align-items: center;

  transition: width 0.35s ease-in;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #c53030;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    border: 0;
    transition: background-color 0.35s ease;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    button {
      background-color: ${shade(0.2, '#c53030')};
    }
  }

  @media screen and (max-width: 470px) {
    width: 50px;
  }
`;
