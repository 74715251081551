import React, { useRef, useCallback, useState, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLocation, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { Container, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Input from 'components/Input';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';
import RadioContainer from 'components/RadioContainer';
import ScrollTop from 'components/ScrollTop';
import Loading from 'components/Loading';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';
import { convertSpecialChars } from 'utils/specialChars';
import {
  Content,
  NacHeader,
  GridContainer,
  AnimatedSection,
  SecundaryAddress,
} from './styles';

interface StateProps {
  options: {
    SEQ: number;
    prefix: string;
    code: string;
  };
}

export interface FormData {
  // anbc: string;
  // anbdesc: string;
  address: string;
  cep: string;
  city: string;
  district: string;
  state: string;
  bank: string;
  agency: string;
  account: string;
  number: number;
  compl: string;
  cnpj: string;
  phone: string;
  mail: string;
  website: string;
  facebook: string;
  alt_cep: string;
  alt_address: string;
  alt_district: string;
  alt_city: string;
  alt_state: string;
  alt_number: number;
  alt_compl: string;
  alt: 'S' | 'N';
}

const NacionaisInc: React.FC = () => {
  const location = useLocation<StateProps>();
  const received = location.state;
  const [SEQ, setSEQ] = useState(() => {
    if (received && received.options.SEQ) {
      return received.options.SEQ;
    }
    return null;
  });
  const [prefix, setPrefix] = useState(() => {
    if (received && received.options.prefix) {
      return received.options.prefix;
    }
    return null;
  });
  const [code, setCode] = useState(() => {
    if (received && received.options.code) {
      return received.options.code;
    }
    return null;
  });

  const { errorHandling } = useCredentials();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('N');
  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);
  const [hasState, setHasState] = useState(false);

  const [hasSecAddress, setHasSecAddress] = useState(false);
  const [hasSecDistrict, setHasSecDistrict] = useState(false);
  const [hasSecCity, setHasSecCity] = useState(false);
  const [hasSecState, setHasSecState] = useState(false);

  const [altUF, setAltUF] = useState('Selecione');
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  useEffect(() => {
    if (!received || !received.options) {
      setSEQ(0);
      setPrefix('');
      setCode('');
      history.replace(location.pathname.replace('/insert', ''));
    }
  }, [history, location.pathname, location.state, received]);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');

    setComboEstados(response.data);
  }, []);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].label);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setFirstUF('Selecione');
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleAltCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('alt_cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );
        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setAltUF(comboEstados[index].label);
          setHasSecState(true);
        } else {
          setAltUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasSecAddress(true);
        }
        if (city && city.length > 0) {
          setHasSecCity(true);
        }
        if (district && district.length > 0) {
          setHasSecDistrict(true);
        }

        form.setData({
          alt_address: (address && address.split(/[-,]/, 1)) || '',
          alt_city: city || '',
          alt_district: district || '',
          alt_state: state || '',
          ...form,
        });
      } else {
        setAltUF('Selecione');
        setHasSecAddress(false);
        setHasSecCity(false);
        setHasSecDistrict(false);
        form.setData({
          alt_address: '',
          alt_city: '',
          alt_district: '',
          alt_state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleRadioClick = useCallback(() => {
    setRadioValue(formRef.current?.getFieldValue('alt'));
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          cnpj: yup.lazy((val) =>
            !val
              ? yup.string().nullable()
              : yup
                  .string()
                  .trim()
                  .min(18, 'O CNPJ deve conter 18 caracteres.'),
          ),
          bank: yup.string().nullable(true),
          agency: yup.string().nullable(true),
          account: yup.string().nullable(true),
          // cnpj: yup.string().min(18, 'O CNPJ deve conter 18 caracteres.'),
          // bank: yup.string().required('Informação obrigatória'),
          // agency: yup.string().required('Informação obrigatória'),
          // account: yup.string().required('Informação obrigatória'),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup.string().trim().required('Informação obrigatória'),
          number: yup.string().nullable(true),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup.string().trim().required('Informação obrigatória'),
          city: yup.string().trim().required('Informação obrigatória'),
          state: yup.string().notOneOf(['Selecione o Estado'], ' '),

          phone: yup
            .string()
            .trim()
            .min(14, 'O telefone deve conter no mínimo 14 caracteres.'),
          mail: yup
            .string()
            .trim()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          website: yup.string().nullable(),
          facebook: yup.string().nullable(),
          alt_cep: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup
              .string()
              .trim()
              .min(9, 'O CEP secundário deve conter 9 caracteres.'),
          }),
          alt_address: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_district: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_city: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().trim().required('Informação obrigatória'),
          }),
          alt_state: yup.string().when('alt', {
            is: (val) => val === 'S',
            then: yup.string().notOneOf(['Selecione o Estado'], ' '),
          }),
          alt_number: yup.string().nullable(true),
          // alt_number: yup.mixed().when('alt', {
          //   is: (val) => val === 'S',
          //   then: yup.number().typeError('Informação obrigatória'),
          // }),
          alt_compl: yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          anbcod: `${code}${SEQ}`,
          address: convertSpecialChars(data.address),
          number: !data.number ? 'S/N' : data.number,
          compl: data.compl || convertSpecialChars(data.compl),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          website: data.website || convertSpecialChars(data.website),
          facebook: data.facebook || convertSpecialChars(data.facebook),
          alt_address:
            data.alt === 'S' && data.alt_address
              ? convertSpecialChars(data.alt_address)
              : '',
          alt_number: !data.alt_number ? 'S/N' : data.alt_number,
          alt_compl:
            data.alt === 'S' && data.alt_compl
              ? convertSpecialChars(data.alt_compl)
              : '',
          alt_district:
            data.alt === 'S' && data.alt_district
              ? convertSpecialChars(data.alt_district)
              : '',
          alt_city:
            data.alt === 'S' && data.alt_city
              ? convertSpecialChars(data.alt_city)
              : '',
          alt_state: (data.alt === 'S' && data.alt_state) || null,
        };

        await api.get(`/sgo/anbs_insert.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Área Brasil ${prefix} ${SEQ} incluída!`,
        });

        history.replace(location.pathname.replace('/insert', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, SEQ, code, addToast, history, prefix, location.pathname],
  );

  useEffect(() => {
    getUFs();
  }, [getUFs]);

  // const received = 'Rua Abelardo Barbosa, S/N - 1° andar';
  // const splittedAddress = received.split(',');
  // const splittedCompl = received.split('-', -1);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro de Nacional" />
      <Content>
        <Form
          ref={formRef}
          initialData={{
            anbdesc: `Área Brasil ${prefix} ${SEQ}`,
          }}
          onSubmit={handleSubmit}
        >
          <NacHeader>
            <p>Nova Nacional</p>
            <Input name="anbdesc" disabled />
          </NacHeader>
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>CNPJ</p>
                <Input name="cnpj" placeholder="N° CNPJ" mask="cnpj" />
              </span>

              <span>
                <p>Banco</p>
                <Input name="bank" placeholder="Código - Banco" />
              </span>
              <span>
                <p>Agência</p>
                <Input name="agency" placeholder="N° da Agência" />
              </span>
              <span>
                <p>Conta</p>
                <Input name="account" placeholder="N° da Conta e dígito" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>
              <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>
              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>
              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>
              <span>
                <p>Bairro</p>
                <Input
                  name="district"
                  placeholder="Bairro"
                  disabled={!!hasDistrict}
                />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" disabled={!!hasCity} />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={0.7} duration={1.1}>
              <span>
                <p>Contato</p>
                <Input
                  name="phone"
                  placeholder="Telefone ou Celular"
                  mask="phone"
                />
              </span>

              <span>
                <p>E-Mail</p>
                <Input name="mail" placeholder="E-Mail" />
              </span>

              <span>
                <p>Página Web</p>
                <Input name="website" placeholder="Página Web" />
              </span>

              <span>
                <p>Facebook</p>
                <Input name="facebook" placeholder="https://facebook.com/" />
              </span>
            </AnimatedSection>

            <AnimatedSection delay={1.05} duration={1.1}>
              <RadioContainer
                title="Há endereço alternativo para entrega?"
                name="alt"
                selected={radioValue}
                content={[
                  {
                    id: 'aS',
                    value: 'S',
                    label: 'Sim, o endereço é diferente',
                  },
                  {
                    id: 'aN',
                    value: 'N',
                    label: 'Não, desejo usar o mesmo endereço',
                  },
                ]}
                onClick={handleRadioClick}
              />

              <SecundaryAddress active={radioValue === 'S'}>
                {/* <section style={radioValue === 'S' ? {} : {}}> */}
                {/* <section> */}
                <span>
                  <p>CEP</p>
                  <Input
                    name="alt_cep"
                    placeholder="N° CEP"
                    mask="cep"
                    onChange={handleAltCep}
                  />
                </span>
                <span>
                  <p>Estado</p>
                  <SelectV2
                    name="alt_state"
                    initial={altUF}
                    content={comboEstados}
                    disabled={!!hasSecState}
                    dropdownStyle={{
                      maxHeight: '130px',
                      borderColor: '#a6a6a6',
                    }}
                    containerStyle={{ borderColor: '#a6a6a6' }}
                  />
                </span>
                <span>
                  <p>Endereço</p>
                  <Input
                    name="alt_address"
                    placeholder="Endereço"
                    disabled={!!hasSecAddress}
                  />
                </span>
                <span>
                  <p>Número</p>
                  <Input name="alt_number" placeholder="S/N" mask="number" />
                </span>
                <span>
                  <p>Complemento</p>
                  <Input name="alt_compl" placeholder="Complemento" />
                </span>
                <span>
                  <p>Bairro</p>
                  <Input
                    name="alt_district"
                    placeholder="Bairro"
                    disabled={!!hasSecDistrict}
                  />
                </span>
                <span>
                  <p>Cidade</p>
                  <Input
                    name="alt_city"
                    placeholder="Cidade"
                    disabled={!!hasSecCity}
                  />
                </span>

                {/* </section> */}
              </SecundaryAddress>
            </AnimatedSection>
          </GridContainer>

          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Cadastrar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default NacionaisInc;
