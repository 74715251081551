import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface GridItemProps {
  status: boolean;
  disabled: boolean;
}

export const Grid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;

  row-gap: 20px;
  column-gap: 20px;

  margin: 25px 0;

  @media screen and (max-width: 605px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 450px) {
    grid: auto / 1fr 1fr;
  }
`;

// export const GridItem = styled.div`
//   padding: 5px 10px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   flex-direction: row;
//   background: #fff;
//   border: 3px solid #efefef;
//   border-radius: 5px;
//   width: 135px;
//   box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

//   position: relative;

//   transition: box-shadow 0.35s, border-color 0.35s;

//   span {
//     position: absolute;
//     right: 10px;
//   }

//   &:hover {
//     box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
//     border-color: ${shade(0.1, '#efefef')};
//   }
// `;

export const GridItem = styled.button<GridItemProps>`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: ${(props) => (props.status ? '#ceebc6' : '#ebc6c6')};
  border: 3px solid ${(props) => (props.status ? '#069823' : '#c53030')};
  border-radius: 5px;
  color: ${(props) => shade(0.2, props.status ? '#069823' : '#c53030')};
  width: 135px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto Slab', serif;
  }

  position: relative;

  transition: box-shadow 0.35s, border-color 0.35s;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    border-color: ${(props) =>
      shade(0.2, props.status ? '#069823' : '#c53030')};

    ${(props) =>
      props.disabled &&
      css`
        box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
        border-color: ${props.status ? '#069823' : '#c53030'};
      `}
  }
`;
