import React, { HTMLAttributes, useState } from 'react';

import { Link } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';
import {
  Container,
  Photo,
  Info,
  CertPerf,
  Coords,
  FloatingLogo,
} from './styles';

const TOV = `${process.env.REACT_APP_ASSETS_DIR}/logos/logoTOV_300x345.png`;

interface SGOHeaderProps extends HTMLAttributes<HTMLHeadElement> {
  disableAvatar?: boolean;
}

const SGOHeader: React.FC<SGOHeaderProps> = ({
  disableAvatar = false,
  ...rest
}) => {
  const { user } = useAuth();
  const { width } = useWindow();
  const [able] = useState(
    ['GUI', 'LOC', 'NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1,
  );
  const {
    adm,
    avatar,
    gseq,
    name,
    gcert,
    perfil,
    perfildesc,
    perfalt,
    zoncod,
    zondesc,
    anbc,
    anbdesc,
    loccod,
    locdesc,
  } = user;

  return (
    <Container {...rest} data-tag="header">
      <Photo disabled={disableAvatar || user.perfil === 'INT'}>
        <Link
          title="Alterar foto"
          to={{
            pathname: `/sgo/${
              perfil === 'CTB' ? 'contabil/avatar' : 'secretaria/guias/avatar'
            }`,
            state: {
              guia: {
                gseq,
                name,
                avatar,
              },
            },
          }}
        >
          <img src={`${process.env.REACT_APP_AVATAR}${avatar}`} alt={name} />
        </Link>
      </Photo>
      <Info>
        <h2>Olá, {name.substr(0, name.indexOf(' '))}!</h2>
        {able && (
          <>
            <CertPerf>
              {!adm && (
                <span>
                  <p>Certificado:</p>
                  <strong>{gcert}</strong>
                </span>
              )}
              <span>
                <p>Perfil:</p>
                <strong>{!adm ? perfildesc : perfalt}</strong>
              </span>
            </CertPerf>
            <Coords>
              <span>
                <p>Zonal:</p>
                <strong>
                  {!adm ? (width < 950 ? ` ${zoncod}` : ` ${zondesc}`) : zoncod}
                  {/* {width < 950 && ` ${zoncod}`}
                  {width >= 950 && `${zondesc}`} */}
                </strong>
              </span>
              <span>
                <p>Nacional:</p>
                <strong>
                  {!adm ? (width < 950 ? ` ${anbc}` : ` ${anbdesc}`) : anbc}
                  {/* {width < 950 && ` ${anbc}`}
                  {width >= 950 && `${anbdesc}`} */}
                </strong>
              </span>
              <span>
                <p>Local:</p>
                <strong>
                  {!adm ? (width < 950 ? ` ${loccod}` : ` ${locdesc}`) : loccod}
                  {/* {width < 950 && ` ${loccod}`}
                  {width >= 950 && `${locdesc}`} */}
                </strong>
              </span>
            </Coords>
          </>
        )}
      </Info>
      <FloatingLogo>
        <img src={TOV} alt="TOV Brasil" />
      </FloatingLogo>
    </Container>
  );
};

export default SGOHeader;
