import React, { useState, useRef, useCallback, useEffect } from 'react';

import { ReactComponent as ExchangeSVG } from 'icons/exchange.svg';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import IncludeButton from 'components/IncludeButton';
import RadioContainer from 'components/RadioContainer';
import Textarea from 'components/Textarea';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';
import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';
import * as yup from 'yup';

import {
  Container,
  AlteredContent,
  Header,
  CoordProps,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import api from 'services/api';
import { FaTimes } from 'react-icons/fa';
import { formatDate } from 'utils/formatDate';
import { useWindow } from 'hooks/window';
import { useSpring } from 'react-spring';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import {
  ListContent,
  Message,
  Remove,
  TransfContent,
  AlteredForm,
} from './styles';

interface LocalStorageProps {
  zoncod?: string;
  zondesc?: string;
  anbcod?: string;
  anbdesc?: string;
  loccod?: string;
  locdesc?: string;
  matcod?: string;
  matdesc?: string;
  cord?: CoordProps;
}

interface ComboMat {
  value: string;
  label: string;
  matcntr: string;
}

interface ListProps {
  loccod: string;
  matcod: string;
  numero: string;
  user: string;
  dtmanut: string;
  matdesc: string;
  locdesc: string;
  status: boolean;
  acervo: boolean;
  dtbaixa: string;
  resp: string;
  motivo: string;
  anb: string;
  numloc: string;
  numlocdesc: string;
  codret: string;
}

interface FormData {
  motive: string;
  comp: string;
}

const CntrNum: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const transForm = useRef<FormHandles>(null);
  const removeForm = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { width } = useWindow();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [inc, setInc] = useState<IncludeProps>({
    title: 'Transferência',
    open: false,
    content: '',
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [initialZon] = useState(() => {
    const { zoncod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    return zoncod || user.zoncod;
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    return anbcod || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    return loccod || 'Selecione';
  });

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cord }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    return (
      cord || {
        cod:
          ['INT', 'ZON'].indexOf(user.perfil) > -1
            ? user.zoncod
            : user.perfil === 'NAC'
            ? user.anbc
            : user.loccod,
        desc:
          ['INT', 'ZON'].indexOf(user.perfil) > -1
            ? user.zondesc
            : user.perfil === 'NAC'
            ? user.anbdesc
            : user.locdesc,
      }
    );
  });

  const [initialMat, setInitialMat] = useState(() => {
    const { matcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    return matcod || 'Selecione';
  });

  const [comboMats, setComboMats] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getList = useCallback(
    async (code?: string) => {
      try {
        setLoading(true);
        const response = await api.get(
          `/sgo/cntrnum_list.php?data=${JSON.stringify({
            code: code || coord.cod,
          })}`,
        );
        setRawList(response.data);
        let filtered = response.data;

        const { matcod }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_CNTRNUM}`,
        );

        if (matcod) {
          filtered = response.data.filter(
            (item: ListProps) => item.matcod === matcod,
          );
        }

        setList(filtered);
        setLoading(false);
      } catch (err) {
        errorHandling(err);
      }
    },
    [coord.cod, errorHandling],
  );

  const getComboMATS = useCallback(async () => {
    const response = await api.get('/combos/comboMATs.php');
    setComboMats(
      response.data.filter((item: ComboMat) => item.matcntr === 'S'),
    );
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    if (user.perfil === 'NAC' || !!anbcod) {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anbcod || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    // setInitialNac(user.anbdesc);
    setNacs(response.data);

    const { zoncod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zoncod
          ? item.value.substr(0, 2) === zoncod.substr(0, 2)
          : item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  useEffect(() => {
    handlePermission(['GUI']);
    getComboZON();
    getComboANB();
    getComboLOC();
    getComboMATS();
    getList();
  }, [
    handlePermission,
    getComboANB,
    getComboLOC,
    getComboMATS,
    getComboZON,
    getList,
  ]);

  const handleZONselect = useCallback(() => {
    setComboNacs([]);
    setComboLocs([]);

    const select = formRef.current?.getFieldRef('comboZON');
    const { value, text } = select.options[select.selectedIndex];

    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    setComboNacs(
      nacs.filter((item) => item.value.substr(0, 2) === value.substr(0, 2)),
    );

    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      zoncod: value,
      zondesc: text,
      cord: { cod: value, desc: text },
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_CNTRNUM}`, [
      'anbcod',
      'anbdesc',
      'loccod',
      'locdesc',
    ]);

    formRef.current?.setData({
      comboANB: '',
      comboLocal: '',
    });

    setCoord({
      cod: value,
      desc: text,
    });
  }, [nacs]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];
    setInitialLoc('Selecione');
    setComboLocs([]);

    setComboLocs(locs.filter((item) => item.anb === value));
    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      anbcod: value,
      anbdesc: text,
      cord: { cod: value, desc: text },
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_CNTRNUM}`, [
      'loccod',
      'locdesc',
    ]);

    setCoord({
      cod: value,
      desc: text,
    });
    setInitialNac(value);
  }, [locs]);

  const handleLOCselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLocal');
    const { value, text } = select.options[select.selectedIndex];

    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      loccod: value,
      locdesc: text,
      cord: { cod: value, desc: text },
    });

    setCoord({
      cod: value,
      desc: text,
    });
    setInitialLoc(value);
  }, []);

  const handleMATselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboMaterial');
    const { value, text } = select.options[select.selectedIndex];

    setList(rawList.filter((item) => item.matcod === value));

    setInitialMat(text);

    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      matcod: value,
      matdesc: text,
    });
  }, [rawList]);

  const removeFilter = useCallback(() => {
    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_CNTRNUM}`, [
      'anbcod',
      'anbdesc',
      'loc',
      'locdesc',
    ]);
    setComboLocs([]);

    if (user.perfil === 'INT') {
      const select = formRef.current?.getFieldRef('comboZON');
      const { value, text } = select.options[select.selectedIndex];

      setCoord({
        cod: value,
        desc: text,
      });

      setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
        zoncod: value,
        zondesc: text,
        cord: { cod: value, desc: text },
      });

      getList(value);
      return;
    }

    setCoord({
      cod: user.zoncod,
      desc: user.zondesc,
    });

    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      zoncod: user.zoncod,
      zondesc: user.zondesc,
      cord: { cod: user.zoncod, desc: user.zondesc },
    });

    getList(user.zoncod);
  }, [getList, user.perfil, user.zoncod, user.zondesc]);

  const removeLoc = useCallback(() => {
    const { anbcod, anbdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_CNTRNUM}`,
    );

    setCoord({
      cod: anbcod || user.anbc,
      desc: anbdesc || user.anbdesc,
    });

    setLocalStorage(`${process.env.REACT_APP_CNTRNUM}`, {
      cord: { cod: anbcod || user.anbc, desc: anbdesc || user.anbdesc },
    });

    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_CNTRNUM}`, [
      'loccod',
      'locdesc',
    ]);

    getList(anbcod);
  }, [getList, user.anbc, user.anbdesc]);

  const removeMatFilter = useCallback(() => {
    formRef.current?.setFieldValue('comboMaterial', '');
    setInitialMat('Selecione');
    setList(rawList);
  }, [rawList]);

  const handleFinishTransf = useCallback(async () => {
    if (
      !inc.values ||
      (inc.values?.orig === 'same' &&
        !transForm.current?.getFieldValue('destiny'))
    ) {
      transForm.current?.setFieldError('destiny', 'Error');
      return;
    }
    transForm.current?.setFieldError('destiny', '');
    try {
      setLoading(true);
      setInc((state) => ({ ...state, open: false }));

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          ...inc.values,
          destiny:
            inc.values?.orig === 'same'
              ? transForm.current?.getFieldValue('destiny')
              : 'other',
        }),
      );

      await api.post('/sgo/cntrnum_transf.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setList((state) =>
        state.filter(
          (item) =>
            item.matcod !== inc.values?.matcod &&
            item.numero !== inc.values?.num,
        ),
      );

      addToast({
        type: 'success',
        title: 'Material transferido!',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err, err.response.data.message);
    }
  }, [inc.values, addToast, errorHandling]);

  const handleRemove = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        removeForm.current?.setErrors({});
        const schema = yup.object().shape({
          motive: yup.string().notOneOf(['', 'on'], 'Este campo é obrigatório'),
          comp: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setDeleteDiag((state) => ({ ...state, open: false }));

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            ...deleteDiag.values,
          }),
        );

        await api.post('/sgo/cntrnum_remove.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setList((state) =>
          state.filter((item) =>
            item.matcod !== deleteDiag.values?.mat
              ? item
              : item.numero !== deleteDiag.values?.num && item,
          ),
        );

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Material retirado de circulação.',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          removeForm.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, deleteDiag.values, errorHandling],
  );

  const filterRemove = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  const locFilterRemove = useSpring({
    opacity: initialLoc !== 'Selecione' ? 1 : 0,
    pointerEvents: initialLoc !== 'Selecione' ? 'all' : 'none',
  });

  const matFilterRemove = useSpring({
    opacity: initialMat !== 'Selecione' ? 1 : 0,
    pointerEvents: initialMat !== 'Selecione' ? 'all' : 'none',
  });

  return (
    <Container>
      {['ZON'].indexOf(user.perfil) > -1 && <IncludeButton />}
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={initialZon}
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeFilter}
                    style={filterRemove}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLocal"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeLoc}
                    style={locFilterRemove}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              <span>
                <p>Filtre por Material:</p>
                <SelectV2
                  name="comboMaterial"
                  content={comboMats}
                  onChange={handleMATselect}
                  initial={initialMat}
                />
                <RemoveButton
                  type="button"
                  onClick={removeMatFilter}
                  style={matFilterRemove}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>Lista de Materiais Numerados</p>
          <p>{coord.desc}</p>
        </div>
      </Header>
      <AlteredContent pixels="252px">
        {!loading && (
          <ListContent>
            {list.length === 0 ? (
              <Message>Sem registros para o material selecionado</Message>
            ) : (
              <table>
                <thead>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      <p>Descrição</p>
                    </td>
                    <td>
                      <p>{width >= 470 ? 'Numeração' : 'Num.'}</p>
                    </td>
                    <td>
                      <p>{width >= 470 ? 'Data Inclusão' : 'Inc.'}</p>
                    </td>
                    {user.perfil === 'ZON' && (
                      <td>
                        <p>{width >= 470 ? 'Excluir' : 'Exc.'}</p>
                      </td>
                    )}
                    {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                      <td>
                        <p>{width >= 470 ? 'Transferir' : 'Transf'}</p>
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <React.Fragment key={`${item.matcod}${item.numero}`}>
                      {index !== 0 && index % 10 === 0 && (
                        <tr style={{ background: '#332e2e', color: '#fff' }}>
                          <td style={{ textAlign: 'left' }}>
                            <p>Descrição</p>
                          </td>
                          <td>
                            <p>{width >= 470 ? 'Numeração' : 'Num.'}</p>
                          </td>
                          <td>
                            <p>{width >= 470 ? 'Data Inclusão' : 'Inc.'}</p>
                          </td>
                          {user.perfil === 'ZON' && (
                            <td>
                              <p>{width >= 470 ? 'Excluir' : 'Exc.'}</p>
                            </td>
                          )}
                          {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                            <td>
                              <p>{width >= 470 ? 'Transferir' : 'Transf'}</p>
                            </td>
                          )}
                        </tr>
                      )}
                      <tr
                        style={{
                          background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                        }}
                      >
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p>{item.matdesc}</p>
                        </td>
                        <td>
                          <p>{item.numero}</p>
                        </td>
                        <td>
                          <p>{formatDate(item.dtmanut)}</p>
                        </td>
                        {user.perfil === 'ZON' && (
                          <td>
                            {(item.matcod !== 'EP07' ||
                              (item.matcod === 'EP07' &&
                                parseInt(item.numero, 10) >= 10000)) && (
                              <button
                                type="button"
                                onClick={() =>
                                  setDeleteDiag({
                                    open: true,
                                    content: (
                                      <>
                                        <span>
                                          <p>
                                            Material:&nbsp;
                                            <strong>
                                              {item.matcod} - {item.matdesc}
                                            </strong>
                                          </p>
                                          <p>
                                            Numeração:&nbsp;
                                            <strong>{item.numero}</strong>
                                          </p>
                                        </span>
                                      </>
                                    ),
                                    values: {
                                      mat: item.matcod,
                                      num: item.numero,
                                      coord: item.loccod,
                                    },
                                  })
                                }
                              >
                                <FaTimes />
                              </button>
                            )}
                          </td>
                        )}
                        {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                          <td>
                            <button
                              type="button"
                              onClick={() =>
                                setInc((state) => ({
                                  ...state,
                                  open: true,
                                  content: (
                                    <TransfContent>
                                      <span>
                                        <p>
                                          Material:&nbsp;
                                          <strong>
                                            {item.matcod} - {item.matdesc}
                                          </strong>
                                        </p>
                                        <p>
                                          Numeração:&nbsp;
                                          <strong>{item.numero}</strong>
                                        </p>
                                      </span>
                                      {((user.perfil === 'ZON' &&
                                        coord.cod === user.zoncod) ||
                                        (user.perfil === 'NAC' &&
                                          coord.cod === user.anbc)) && (
                                        <span>
                                          <Form
                                            ref={transForm}
                                            onSubmit={() => null}
                                          >
                                            <p>Coordenação de destino</p>
                                            <SelectV2
                                              name="destiny"
                                              content={
                                                user.perfil === 'ZON'
                                                  ? comboNacs
                                                  : comboLocs
                                              }
                                              initial="Selecione"
                                            />
                                          </Form>
                                        </span>
                                      )}
                                      <span>
                                        <p>
                                          A transferência irá alterar as
                                          quantidades presentes nos estoques.
                                        </p>
                                        <p>
                                          Se estiver seguro de sua decisão,
                                          clique em transferir.
                                        </p>
                                      </span>
                                      {/* {user.perfil === 'ZON' ? (
                                        coord.cod === user.zoncod ? (
                                          <span>
                                            <Form
                                              ref={transForm}
                                              onSubmit={() => null}
                                            >
                                              <p>Coordenação de destino</p>
                                              <Select
                                                name="destiny"
                                                content={comboNacs}
                                                onBlur={handleDestinySelection}
                                              />
                                            </Form>
                                          </span>
                                        ) : (
                                          'other'
                                        )
                                      ) : coord.cod === user.anbc ? (
                                        'same'
                                      ) : (
                                        'other'
                                      )} */}
                                    </TransfContent>
                                  ),
                                  values: {
                                    mat: item.matcod,
                                    num: item.numero,
                                    orig:
                                      (user.perfil === 'ZON' &&
                                        coord.cod === user.zoncod) ||
                                      (user.perfil === 'NAC' &&
                                        coord.cod === user.anbc)
                                        ? 'same'
                                        : 'other',
                                  },
                                }))
                              }
                            >
                              <ExchangeSVG />
                            </button>

                            {/* <FaExchangeAlt /> */}
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </ListContent>
        )}
      </AlteredContent>
      <I.Container scroll="paper" maxWidth={false} open={inc.open}>
        <I.Title>
          <h2>{inc.title || 'Inclusão'}</h2>
        </I.Title>
        <I.Content>{inc.content}</I.Content>
        <I.Actions>
          <I.Cancel
            type="button"
            onClick={() =>
              setInc({
                ...inc,
                open: false,
              })
            }
          >
            Cancelar
          </I.Cancel>
          <I.Confirm type="button" onClick={handleFinishTransf}>
            Transferir
          </I.Confirm>
        </I.Actions>
      </I.Container>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <AlteredForm ref={removeForm} onSubmit={handleRemove}>
          <D.Title>
            <h2>Retirar de Circulação</h2>
          </D.Title>
          <D.Content>
            <Remove>
              <span>
                {deleteDiag.content}
                <p>Motivo da Retirada:</p>
                <RadioContainer
                  title=""
                  // onClick={handleRadioClick}
                  name="motive"
                  content={[
                    {
                      id: 'rD',
                      value: 'D',
                      label: 'Danificado',
                    },
                    {
                      id: 'rR',
                      value: 'R',
                      label: 'Rasurado',
                    },
                    {
                      id: 'rP',
                      value: 'P',
                      label: 'Perdido',
                    },
                  ]}
                  itemsStyle={{
                    flexDirection: 'row',
                  }}
                />
              </span>
              <span>
                <p>Complemento do Motivo:</p>
                <Textarea
                  name="comp"
                  mask="alpha"
                  placeholder="Descreva o motivo da retirada"
                />
              </span>
            </Remove>
          </D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="submit">Confirmar</D.Confirm>
          </D.Actions>
        </AlteredForm>
      </D.Container>
      <SGOFooter />
    </Container>
  );
};

export default CntrNum;
