import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 330px);

  padding: 20px 0;

  div {
    font-size: 16px;
    > span {
      font-size: 13px;
      font-style: italic;
      width: 100%;
      text-align: right;
      color: #b6b6b6;
      margin-bottom: 15px;
    }

    > p {
      & + p {
        margin-top: 3px;
      }
      & + strong {
        margin-top: 3px;
      }
    }

    & + div {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;

export const Pray = styled.figure`
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p + p {
    margin: 15px 0;
  }

  strong {
    margin-top: 5px;
  }
`;
