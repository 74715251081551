import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 330px);

  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 230px);
  }
`;

export const Contact = styled.section`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  width: 100vw;
  max-width: 800px;

  div {
    > h3 {
      text-align: center;
    }

    @media screen and (max-width: 650px) {
      > h3 {
        font-size: 16px;
      }
    }

    & + div {
      margin-top: 15px;
    }
  }
`;

export const WhatsApps = styled.div`
  span {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 10px 5px;

      background: linear-gradient(to left, #c6c6c6, #fff, #fff, #c6c6c6);
      /* border: 2px solid #006600; */
      border: 2px solid transparent;
      border-radius: 5px;

      color: ${shade(0.2, '#006600')};

      transition: color 0.35s ease, background-color 0.35s ease,
        border-color 0.35s ease;
      svg {
        color: #128c7e;
        height: 25px;
        width: 25px;

        transition: color 0.35s ease;
      }
      h4 {
        margin-left: 10px;
        & + h4 {
          margin-left: 15px;
        }
      }

      & + a {
        margin: 0 0 0 10px;
      }

      &:hover {
        background: ${shade(0.2, '#006600')};
        /* border-color: ${shade(0.2, '#006600')}; */
        color: #efefef;

        svg {
          color: #25d366;
          justify-content: unset;
        }
      }
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
      a {
        width: 80vw;

        justify-content: center;

        h4 {
          font-size: 16px;
        }

        & + a {
          margin: 10px 0 0 0;
        }
      }
    }
  }
`;

export const Skype = styled.div`
  span {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 10px 5px;

      background: linear-gradient(to left, #c6c6c6, #fff, #fff, #c6c6c6);
      /* border: 2px solid #0071dc; */
      border: 2px solid transparent;
      border-radius: 5px;

      color: ${shade(0.2, '#0071dc')};

      transition: color 0.35s ease, background-color 0.35s ease,
        border-color 0.35s ease;
      svg {
        color: #0071dc;
        height: 25px;
        width: 25px;

        transition: color 0.35s ease;
      }
      h4 {
        margin-left: 10px;
        & + h4 {
          margin-left: 15px;
        }
      }

      & + a {
        margin: 0 0 0 10px;
      }

      &:hover {
        background: ${shade(0.2, '#0071dc')};
        /* border-color: ${shade(0.2, '#0071dc')}; */
        color: #efefef;

        svg {
          color: #efefef;
        }
      }
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
      a {
        width: 80vw;

        justify-content: center;

        h4 {
          font-size: 16px;
        }

        & + a {
          margin: 10px 0 0 0;
        }
      }
    }
  }
`;

export const Email = styled.div`
  span {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p {
      margin: 0 10px;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 10px 5px;

      background: linear-gradient(to left, #c6c6c6, #fff, #fff, #c6c6c6);
      /* border: 2px solid #000099; */
      border: 2px solid transparent;
      border-radius: 5px;

      color: #000099;

      transition: color 0.35s ease, background-color 0.35s ease,
        border-color 0.35s ease;

      svg {
        color: #3333ff;
        height: 25px;
        width: 25px;

        transition: color 0.35s ease;
      }
      h4 {
        margin-left: 10px;
        & + h4 {
          margin-left: 15px;
        }
      }

      & + a {
        margin: 0 0 0 10px;
      }

      &:hover {
        background: ${shade(0.2, '#000099')};
        /* border-color: ${shade(0.2, '#000099')}; */
        color: #efefef;

        svg {
          color: #efefef;
        }
      }
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
      p {
        margin: 10px 0;
      }

      a {
        width: 80vw;

        justify-content: center;

        h4 {
          font-size: 16px;
        }

        & + a {
          margin: 10px 0 0 0;
        }
      }
    }
  }
`;

export const TextContent = styled.div`
  height: 100%;

  margin: 25px 0;
  max-width: 1000px;
  padding: 0 25px;

  p {
    text-align: justify;
    font-size: 2.5rem;

    & + p {
      padding-top: 15px;
    }
  }
`;
