import React, { useState, useRef, useCallback, useEffect } from 'react';

import {
  Container,
  AlteredContent,
  Header,
  ComboProps,
} from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import Input from 'components/Input';
import Button from 'components/Button';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { FaSearch } from 'react-icons/fa';
import api from 'services/api';
import { useCredentials } from 'hooks/credentials';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';
import { InfoContainer, SearchContainer } from './styles';

interface FormData {
  material: string;
  num: number;
}

interface ComboMat {
  value: string;
  label: string;
  matcntr: string;
}

interface GuiaProps {
  zoncod: string;
  anbcod: string;
  loccod: string;
  gcert: string;
  zondesc: string;
  anbdesc: string;
  locdesc: string;
  gnome: string;
  anbescrit: string;
  tgcod: string;
  tgdesc: string;
  dtenv: string;
  matcod: string;
  matdesc: string;
}

interface MaterialProps {
  loccod: string;
  locdesc: string;
  matcod: string;
  numero: string;
  ultanb: string;
  matdesc: string;
  escrit: string;
  status: boolean;
  motivo: string;
  dtbaixa: string;
  acervo: string;
  codret: string;
  crdesc: string;
  ultloc: string;
  anbdesc: string;
  anbescrit: string;
}

const CntrNumSearch: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [comboMats, setComboMats] = useState<ComboProps[]>([]);
  const [material, setMaterial] = useState<MaterialProps>({} as MaterialProps);
  const [guia, setGuia] = useState<GuiaProps>({} as GuiaProps);
  const [notFound, setNotFound] = useState('');
  const { errorHandling, handlePermission } = useCredentials();

  const getComboMATS = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/combos/comboMATs.php');
      setComboMats(
        response.data.filter((item: ComboMat) => item.matcntr === 'S'),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['GUI']);
    getComboMATS();
  }, [getComboMATS, handlePermission]);

  const handleSearch = useCallback(
    async (data: FormData) => {
      try {
        setNotFound('');
        setMaterial({} as MaterialProps);
        setGuia({} as GuiaProps);

        setLoading(true);

        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          material: yup.string().notOneOf(['Selecione', '']),
          num: yup.number().when('material', {
            is: (mat) => mat === 'EP07',
            then: yup
              .number()
              .typeError('Este campo é obrigatório')
              .min(10000, 'Numeração deve ser superior a 10000')
              .required('Este campo é obrigatório'),
            otherwise: yup
              .number()
              .typeError('Este campo é obrigatório')
              .required('Este campo é obrigatório'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.get(
          `/sgo/cntrnum_search.php?data=${JSON.stringify({
            mat: data.material,
            num: data.num,
          })}`,
        );

        const { g, m, nf } = response.data;

        if (!nf) {
          if (g) {
            setGuia({
              ...g,
              matcod: 'EP07',
              matdesc:
                comboMats[comboMats.findIndex((item) => item.value === 'EP07')]
                  .label,
            });
            setMaterial({} as MaterialProps);
          } else {
            setGuia({} as GuiaProps);
            setMaterial(m);
          }
        } else {
          setNotFound('Nenhum registro encontrado');
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [comboMats, errorHandling],
  );
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Materiais Numerados" />

      <Header>
        <p>Localizar Numeração</p>
      </Header>
      <AlteredContent pixels="231px">
        <Form ref={formRef} onSubmit={handleSearch}>
          <SearchContainer>
            <div>
              <p>Selecione o material</p>
              <SelectV2
                name="material"
                content={comboMats}
                initial="Selecione"
              />
            </div>
            <div>
              <p>Insira a numeração:</p>
              <Input
                name="num"
                type="number"
                mask="number"
                placeholder="000000"
              />
            </div>
            <Button
              bgcolor="#565656"
              type="submit"
              containerStyle={{ margin: '10px auto 0' }}
            >
              <FaSearch />
            </Button>
          </SearchContainer>
          {(JSON.stringify(guia) !== '{}' ||
            JSON.stringify(material) !== '{}' ||
            notFound !== '') && (
            <InfoContainer>
              {notFound !== '' && <p>{notFound}</p>}
              {JSON.stringify(guia) !== '{}' && (
                <>
                  {/* <span>
                  <p>
                    Material:&nbsp;
                    <strong>
                      {guia.matcod} - {guia.matdesc}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    Numeração:&nbsp;
                    <strong>{guia.gcert}</strong>
                  </p>
                </span> */}
                  <span>
                    <p>
                      Nome:&nbsp;
                      <strong>{guia.gnome}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Zonal:&nbsp;
                      <strong>{guia.zondesc}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Nacional:&nbsp;
                      <strong>
                        {guia.anbdesc} - {guia.anbescrit}
                      </strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Local:&nbsp;
                      <strong>{guia.locdesc}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Tipo de Guia:&nbsp;
                      <strong>{guia.tgdesc}</strong>
                    </p>
                  </span>
                </>
              )}
              {JSON.stringify(material) !== '{}' && (
                <>
                  <span>
                    <p>
                      {material.ultanb || material.ultloc
                        ? 'Última Coordenação'
                        : 'Coordenação Atual'}
                      :&nbsp;
                      {material.ultanb || material.ultloc ? (
                        <strong>
                          {material.ultloc
                            ? `${material.ultanb} - ${material.ultloc}`
                            : material.ultanb}
                        </strong>
                      ) : (
                        <strong>
                          {material.anbdesc === material.locdesc
                            ? material.anbdesc
                            : ` ${material.anbdesc} - ${material.locdesc}`}
                        </strong>
                      )}
                    </p>
                  </span>

                  <span>
                    <p>
                      Status:&nbsp;
                      <strong>
                        {material.status
                          ? 'MATERIAL FORA DE CIRCULAÇÃO'
                          : 'MATERIAL ATIVO em CIRCULAÇÃO'}
                      </strong>
                    </p>
                  </span>
                  {material.status && (
                    <>
                      <span>
                        <p>
                          Tipo:&nbsp;<strong>{material.crdesc}</strong>
                        </p>
                      </span>
                      <span>
                        <p>
                          Motivo:&nbsp;<strong>{material.motivo}</strong>
                        </p>
                      </span>
                      <span>
                        <p>
                          Data de Baixa:&nbsp;
                          <strong>
                            {material.dtbaixa
                              ? formatDate(material.dtbaixa)
                              : 'N/D'}
                          </strong>
                        </p>
                      </span>
                    </>
                  )}
                </>
              )}
            </InfoContainer>
          )}
        </Form>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default CntrNumSearch;
