import styled from 'styled-components';
import { shade } from 'polished';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaFileInvoiceDollar,
  FaReceipt,
} from 'react-icons/fa';

interface SVGProps {
  svgcolor: string;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
`;

export const Table = styled.table`
  margin: 25px auto;
  width: 360px;
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    height: 40px;
    vertical-align: center;

    td {
      text-align: center;
      padding: 2px 3px;

      a {
        display: none;
      }
      p,
      h4 {
        font-size: 18px;
      }
      p {
        margin-left: 5px;
      }
      svg {
        cursor: pointer;
        height: 27.5px;
        width: 27.5px;
        transition: color 0.35s ease;
      }
    }
  }
`;

export const NFSVG = styled(FaFileInvoiceDollar)<SVGProps>`
  color: ${(props) => props.svgcolor};

  &:hover {
    color: ${(props) => shade(0.2, `${props.svgcolor}`)};
  }
`;

export const ReceiptSVG = styled(FaReceipt)<SVGProps>`
  color: ${(props) => props.svgcolor};

  &:hover {
    color: ${(props) => shade(0.2, `${props.svgcolor}`)};
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: sticky;
  top: 100px;
  margin: 5px 0;
  z-index: 2;
`;

export const SelectionItem = styled.div`
  display: flex;
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  z-index: 2;

  width: 167.5px;
  align-items: center;
  justify-content: center;

  position: relative;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  & + div {
    /* margin: 10px 0 0 0px; */
    margin: 0 0 0 25px;
  }
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;
