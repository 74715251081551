import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import InputDialog from 'components/InputDialog';
import ScrollTop from 'components/ScrollTop';

import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import { useLocation, useHistory } from 'react-router-dom';
import api from 'services/api';
import { formatNumber } from 'utils/calcTotal';
import {
  FaCalendarAlt,
  FaDollarSign,
  FaPlusCircle,
  FaTimes,
} from 'react-icons/fa';
import {
  formatDate,
  handleTimeZone,
  minimumAllowedDate,
} from 'utils/formatDate';
import IncludeButton from 'components/IncludeButton';
import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { addDays, format, parseISO, subDays } from 'date-fns';
import getValidationErrors from 'utils/getValidationErrors';
import { ListProps } from '../main';
import { Header, Info, Table, TDdate, TDoffer, TDstats, TDexc } from './styles';

interface LocationProps {
  reg: ListProps;
}

interface DetailsProps {
  date: string;
  offer: number;
  contab: string;
}

interface FormData {
  offerdt: string;
  offerval: string;
}

const OfertasDetail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const { handlePermission, errorHandling } = useCredentials();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { width } = useWindow();
  const location = useLocation<LocationProps>();
  const history = useHistory();
  const [ofiseq] = useState(() => {
    if (!location.state.reg) {
      return 0;
    }

    return parseInt(location.state.reg.seq, 10);
  });
  const [registro] = useState<ListProps>(() => {
    if (!location.state.reg) {
      return {} as ListProps;
    }

    return location.state.reg;
  });

  const [details, setDetails] = useState<DetailsProps[]>([]);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [inc, setInc] = useState<IncludeProps>(() => {
    return {
      title: 'Inclusão',
      open: false,
      content: '',
    };
  });

  const getDetails = useCallback(async () => {
    try {
      const response = await api.get(
        `/sgo/oferta_detail.php?data=${JSON.stringify({ seq: ofiseq })}`,
      );

      setDetails(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
      setLoading(false);
    }
  }, [errorHandling, ofiseq]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    if (!location.state.reg) {
      history.replace('/detail', '');
      return;
    }
    getDetails();
  }, [handlePermission, history, location.state, getDetails]);

  const handleExclusion = useCallback(async () => {
    try {
      setLoading(true);
      setDeleteDiag((state) => ({ ...state, open: false }));
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          tscod: registro.cod,
          seq: registro.seq,
          date: deleteDiag.values?.rawDate,
          offer: deleteDiag.values?.offer,
        }),
      );

      await api.post('/sgo/oferta_delete.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);

      setDetails((state) =>
        state.filter(
          (item) =>
            item.date !== deleteDiag.values?.rawDate &&
            item.offer !== deleteDiag.values?.offer,
        ),
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lançamento de Oferta estornado.',
      });
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, deleteDiag.values, errorHandling, registro.cod, registro.seq]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        // let minDate = new Date(parseISO(registro.inidt));
        const usedDate =
          details.length > 0
            ? details[details.length - 1].date
            : registro.inidt;
        let minDate = minimumAllowedDate(registro.inidt, true);

        const maxDate = new Date(parseISO(new Date().toISOString()));

        if (details.length > 0) {
          minDate = minimumAllowedDate(details[details.length - 1].date, true);
        }

        if (minDate.getUTCDate() === handleTimeZone(usedDate).getUTCDate()) {
          minDate = addDays(minDate, 1);
        }

        const schema = yup.object().shape({
          offerdt: yup
            .date()
            .typeError('Data inválida.')
            .min(
              minDate,
              `A data do lançamento deve ser ${
                details.length > 0 ? '' : 'igual ou '
              }posterior à ${format(subDays(minDate, 1), 'dd/MM/yyyy')}.`,
            )
            .max(
              maxDate,
              `A data do lançamento deve ser anterior à data atual (${format(
                maxDate,
                'dd/MM/yyyy',
              )}).`,
            ),
          offerval: yup
            .number()
            .typeError('Este campo é obrigatório')
            .min(0.01, 'O valor da oferta deve ser superior à R$ 0,00')
            .required('Este campo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setInc((state) => ({ ...state, open: false }));

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            seq: registro.seq,
            tscod: registro.cod,
          }),
        );

        await api.post('/sgo/oferta_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // setDetails((state) => [
        //   ...state,
        //   {
        //     date: data.offerdt,
        //     offer: parseFloat(data.offerval),
        //     contab: false,
        //   },
        // ]);

        // const newDetails: DetailsProps[] = [
        //   ...details,
        //   {
        //     date: data.offerdt,
        //     offer: parseFloat(data.offerval),
        //     contab: false,
        //   },
        // ];
        setDetails((state) => [
          ...state,
          {
            date: data.offerdt,
            offer: parseFloat(data.offerval),
            contab: 'N',
          },
        ]);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Lançamento de oferta registrado com sucesso!',
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        if (err.response.data.message === '1062') {
          errorHandling(err, 'Oferta já registrada para essa data.');
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      details,
      errorHandling,
      registro.cod,
      registro.inidt,
      registro.seq,
    ],
  );

  const totalOffer = useMemo(() => {
    return details.reduce((accum, { offer }) => {
      const semitotal = accum + offer;
      return semitotal;
    }, 0);
  }, [details]);

  const doneOffer = useMemo(() => {
    return details.reduce((accum, { offer, contab }) => {
      return contab === 'S' ? accum + offer : accum;
    }, 0);
  }, [details]);

  const pendingOffer = useMemo(() => {
    return details.reduce((accum, { offer, contab }) => {
      return ['B', 'N'].indexOf(contab) > -1 ? accum + offer : accum;
    }, 0);
  }, [details]);

  return (
    <Container>
      {user.perfil === 'LOC' && (
        <IncludeButton isButton>
          <button
            type="button"
            onClick={() => setInc((state) => ({ ...state, open: true }))}
          >
            <FaPlusCircle />
          </button>
        </IncludeButton>
      )}
      <ScrollTop />
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks />
      <Content>
        <AlteredHeader>Detalhe das Ofertas</AlteredHeader>
        <Header>
          <div>
            <span>
              <p>Código:</p>&nbsp;<strong>{registro.seq}</strong>
            </span>
            {width >= 690 && (
              <span>
                <strong>&bull;</strong>
              </span>
            )}

            <span>
              <strong>{registro.desc}</strong>
            </span>
            {width >= 690 && (
              <span>
                <strong>&bull;</strong>
              </span>
            )}
            <span>
              <p>{registro.prq}</p>
            </span>
          </div>
          <div>
            <span>
              <strong>{registro.g1}</strong>
            </span>
            {!!registro.g2 && (
              <>
                <span>
                  <strong>&bull;</strong>
                </span>
                <span>
                  <strong>{registro.g2}</strong>
                </span>
              </>
            )}
          </div>
        </Header>

        <Table>
          <thead>
            <tr>
              <TDdate>Data</TDdate>
              <TDoffer>Oferta</TDoffer>
              <TDstats>Status</TDstats>
              {user.perfil === 'LOC' && <TDexc />}
            </tr>
          </thead>
          <tbody>
            {details.map((item, index) => (
              <React.Fragment key={item.date}>
                {index !== 0 && index % 10 === 0 && (
                  <tr style={{ background: '#332e2e', color: '#fff' }}>
                    <TDdate>Data</TDdate>
                    <TDoffer>Oferta</TDoffer>
                    <TDstats>Status</TDstats>
                    {user.perfil === 'LOC' && <TDexc />}
                  </tr>
                )}
                <tr
                  style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                >
                  <TDdate>{formatDate(item.date)}</TDdate>
                  <TDoffer>{formatNumber(item.offer)}</TDoffer>
                  <TDstats>
                    {item.contab === 'S'
                      ? 'Repassado'
                      : item.contab === 'N'
                      ? 'Não repassado'
                      : 'Não efetivado'}
                  </TDstats>
                  {user.perfil === 'LOC' && (
                    <TDexc>
                      {item.contab === 'N' && (
                        <button
                          type="button"
                          onClick={() =>
                            setDeleteDiag({
                              open: true,
                              values: {
                                date: formatDate(item.date),
                                rawDate: item.date,
                                offer: formatNumber(item.offer),
                                rawOffer: item.offer,
                              },
                            })
                          }
                        >
                          <FaTimes />
                        </button>
                      )}
                    </TDexc>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        {['NAC', 'LOC'].indexOf(user.perfil) > -1 ? (
          <Info>
            <p>
              Total de Ofertas:&nbsp;
              <strong>R$&nbsp;{formatNumber(totalOffer)}</strong>
            </p>
            <p>
              Total Repassado:&nbsp;
              <strong>R$&nbsp;{formatNumber(doneOffer * 0.8)}</strong>
            </p>
            <p>
              Total não repassado:&nbsp;
              <strong>R$&nbsp;{formatNumber(pendingOffer * 0.8)}</strong>
            </p>
          </Info>
        ) : null}
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>
          <ModalDeleteContent>
            <p>
              Você está prestes a excluir&nbsp;<strong>permanentemente</strong>
              &nbsp;o registro:
            </p>
            <span>
              <p>
                Oficina:&nbsp;<strong>{registro.seq}</strong>
              </p>
              <p>
                Data oferta:&nbsp;
                <strong>{deleteDiag.values?.date}</strong>
              </p>
              <p>
                Valor oferta:&nbsp;
                <strong>R$&nbsp;{deleteDiag.values?.offer}</strong>
              </p>
            </span>
            <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
          </ModalDeleteContent>
        </D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm type="button" onClick={handleExclusion}>
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <I.Container scroll="paper" maxWidth={false} open={inc.open}>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={
            {
              // offerdt: `${new Date().getFullYear()}-${`0${
              //   new Date().getMonth() + 1
              // }`.slice(-2)}-${`0${new Date().getDate()}`.slice(-2)}`,
            }
          }
        >
          <I.Title>
            <h2>{inc.title}</h2>
          </I.Title>
          <I.Content>
            <div style={{ margin: '60px 0 0 0' }}>{/*  */}</div>
            <h4 style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}>
              Data do lançamento:
            </h4>
            <InputDialog
              name="offerdt"
              // placeholder="dd/mm/aaaa"
              icon={FaCalendarAlt}
              type="date"
            />
            <h4 style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}>
              Valor lançamento:
            </h4>
            <InputDialog
              name="offerval"
              placeholder="0,00"
              icon={FaDollarSign}
              type="number"
              mask="currency"
              step=".01"
            />
          </I.Content>
          <I.Actions>
            <I.Cancel
              type="button"
              onClick={() =>
                setInc({
                  ...inc,
                  open: false,
                })
              }
            >
              Cancelar
            </I.Cancel>
            <I.Confirm type="submit">Finalizar</I.Confirm>
          </I.Actions>
        </Form>
      </I.Container>
      <SGOFooter />
    </Container>
  );
};

export default OfertasDetail;
