import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';
import PrintButton from 'components/PrintButton';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useSpring } from 'react-spring';

import { FaFileExcel } from 'react-icons/fa';

import api from 'services/api';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { handleTimeZone } from 'utils/formatDate';
import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorageItemKey,
} from 'utils/handleLocalStorage';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FaTimes } from 'react-icons/fa';
import { Wrapper } from './styles';

import { monthList } from '../../monthList';

interface LocalStorageProps {
  zon?: string;
  anb?: string;
  loc?: string;
  year?: string;
  desc?: string;
}

interface ListProps {
  anb: string;
  loc: string;
  locd: string;
  cert: string;
  name: string;
  month: number;
}

const Presenca: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [coord, setCoord] = useState(() => {
    const { desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );
    return (
      desc ||
      (['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1
        ? user.anbdesc
        : user.locdesc)
    );
  });

  const [currYear, setCurrYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );
    return year || handleTimeZone(new Date()).getFullYear().toString();
  });

  const [initialZon, setInitialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );

    return zon || `${user.anbc.substring(0, 2)}99`;
  });
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );

    return anb || user.anbc;
  });
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );

    return loc || 'Selecione';
  });

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [comboYear, setComboYear] = useState<ComboProps[]>([]);
  const [comboZon, setComboZon] = useState<ComboProps[]>([]);
  const [anbs, setANBs] = useState<ComboProps[]>([]);
  const [comboNac, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLoc, setComboLocs] = useState<ComboProps[]>([]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZon(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setANBs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substring(0, 2) === zon.substring(0, 2)
          : item.value.substring(0, 2) === user.zoncod.substring(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      // `/combos/comboLOCs.php`,
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_PRES_RE}`,
    );

    setComboLocs(
      response.data.filter(
        (item: ComboLocalProps) => item.anb === (anb || user.anbc),
      ),
    );
  }, [user.anbc]);

  const getComboYears = useCallback(async () => {
    const response = await api.get(
      `/combos/comboAnosPresencaRetiro.php?data=${JSON.stringify(
        ['INT', 'ZON'].indexOf(user.perfil) > -1 ? { anbcod: initialNac } : {},
      )}`,
    );

    setComboYear(response.data);
  }, [initialNac, user.perfil]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const send = {
        year: currYear,
        anbcod: initialNac,
      };
      const response = await api.get(
        `/sgo/presenca_re_list.php?data=${JSON.stringify(send)}`,
      );

      let filtered = response.data;
      filtered = filtered.sort((x: ListProps, y: ListProps) =>
        x.locd > y.locd ? 1 : y.locd > x.locd ? -1 : 0,
      );

      const { loc }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_PRES_RE}`,
      );
      if (loc) {
        filtered = filtered.filter((item: ListProps) => item.loc === loc);
      }

      setRawList(filtered);
      setList(filtered);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [currYear, errorHandling, initialNac]);

  useEffect(() => {
    handlePermission(['GUI']);
    if (user.perfil === 'INT') {
      getComboZON();
    }

    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboANB();
    }

    if (user.perfil !== 'LOC') {
      getComboLOC();
    }
    getComboYears();
    getList();
  }, [
    user.perfil,
    getComboANB,
    getComboLOC,
    getComboYears,
    getComboZON,
    getList,
    handlePermission,
  ]);

  const handleZonSelection = useCallback(() => {
    const selectZON = formRef.current?.getFieldRef('comboZon');

    const { value } = selectZON.options[selectZON.selectedIndex];

    deleteLocalStorageItemKey(`${process.env.REACT_APP_PRES_RE}`, ['loc']);

    const filtered = anbs.filter(
      (item) => item.value.substring(0, 2) === value.substring(0, 2),
    );
    setComboNacs(filtered);

    setLocalStorage(`${process.env.REACT_APP_PRES_RE}`, {
      zon: value,
      anb: filtered[0].value,
      desc: filtered[0].label,
    });
    setInitialZon(value);
    setInitialNac(filtered[0].value);
    setInitialLoc('Selecione');
    setCoord(filtered[0].label);
  }, [anbs]);

  const handleANBSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboNac');
    const { text, value } = select.options[select.selectedIndex];

    setComboLocs(locs.filter((item) => item.anb === value));
    formRef.current?.setFieldValue('comboLoc', '');

    setLocalStorage(`${process.env.REACT_APP_PRES_RE}`, {
      anb: value,
      desc: text,
    });
    deleteLocalStorageItemKey(`${process.env.REACT_APP_PRES_RE}`, ['loc']);

    setInitialNac(value);
    setCoord(text);

    setList(rawList.filter((item) => item.anb === value));
  }, [locs, rawList]);

  const handleLocSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLoc');
    const { text, value } = select.options[select.selectedIndex];

    setLocalStorage(`${process.env.REACT_APP_PRES_RE}`, {
      loc: value,
      desc: text,
    });

    setInitialLoc(value);
    setCoord(text);
    setList(rawList.filter((item) => item.loc === value));
  }, [rawList]);

  const handleYear = useCallback(() => {
    const select = formRef.current?.getFieldRef('year');
    const { value } = select.options[select.selectedIndex];

    setLocalStorage(`${process.env.REACT_APP_PRES_RE}`, {
      year: value,
    });

    setCurrYear(value);
  }, []);

  const handleRemoveFilter = useCallback(() => {
    if (user.perfil !== 'NAC') {
      const select = formRef.current?.getFieldRef('comboNac');
      const { text, value } = select.options[select.selectedIndex];
      setCoord(text);
      setInitialLoc('Selecione');
      deleteLocalStorageItemKey(`${process.env.REACT_APP_PRES_RE}`, ['loc']);
      setList(rawList.filter((item) => item.anb === value));
      return;
    }
    setCoord(user.anbdesc);
    setInitialLoc('Selecione');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_PRES_RE}`, ['loc']);
    setList(rawList.filter((item) => item.anb === user.anbc));
  }, [rawList, user.anbc, user.anbdesc, user.perfil]);

  const removeButton = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      {/* https://tovbrasil.com.br/dev/api/sgo/xls_presenca_retiro.php?ANO=2018&ANBCOD=BO02&LOCCOD=SA3&PERFIL=NAC */}
      <PrintButton
        icon={FaFileExcel}
        hasProps
        linkTo={`/sgo/xls_presenca_retiro.php?data=${JSON.stringify({
          year: currYear,
          anbcod: initialNac === 'Selecione' ? '' : initialNac,
          loccod: initialLoc === 'Selecione' ? '' : initialLoc,
        })}`}
      />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Selecione a Zonal:</p>
                  <SelectV2
                    name="comboZon"
                    content={comboZon}
                    onChange={handleZonSelection}
                    initial={initialZon}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleRemoveFilter}
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="comboNac"
                    content={comboNac}
                    onChange={handleANBSelection}
                    initial={initialNac}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleRemoveFilter}
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {user.perfil !== 'LOC' && (
                <span>
                  <p>Selecione a Local:</p>
                  <SelectV2
                    name="comboLoc"
                    content={comboLoc}
                    onChange={handleLocSelection}
                    initial={initialLoc}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleRemoveFilter}
                    style={removeButton}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              <span>
                <p>Ano:</p>
                <SelectV2
                  name="year"
                  content={comboYear}
                  onChange={handleYear}
                  initial={currYear}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>Presença no Retiro Anual - {currYear}</p>
          </div>
          <div>
            <p>{coord}</p>
          </div>
        </AlteredHeader>
        <Wrapper>
          {list.map((item, index) => (
            <React.Fragment key={item.cert}>
              {/* {user.perfil !== 'LOC' &&
              (index === 0 || list[index].locd !== list[index - 1].locd) ? (
                <p>FUCK</p>
              ) : null} */}
              {user.perfil !== 'LOC' &&
              (index === 0 || list[index].locd !== list[index - 1].locd) &&
              initialLoc === 'Selecione' ? (
                <>
                  <strong>
                    {item.loc} - {item.locd}
                  </strong>
                </>
              ) : index === 0 ? (
                <div
                  style={{
                    background: '#332e2e',
                    borderColor: '#332e2e',
                  }}
                >
                  <span style={{ color: '#efefef' }}>
                    <p>Certificado</p>
                  </span>
                  <span style={{ color: '#efefef' }}>
                    <p>Nome</p>
                  </span>
                  <span style={{ color: '#efefef' }}>
                    <p>Mês</p>
                  </span>
                </div>
              ) : null}

              <div style={{ background: index % 2 === 0 ? '#fff' : '#eaeaea' }}>
                <span>
                  <p>{item.cert}</p>
                </span>
                <span>
                  <p>{item.name}</p>
                </span>
                <span>
                  <p>
                    {item.month > 0
                      ? monthList[item.month - 1].label
                          .substr(0, 3)
                          .toUpperCase()
                      : '-'}
                  </p>
                </span>
              </div>
            </React.Fragment>
          ))}
        </Wrapper>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Presenca;
