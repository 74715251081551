import styled, { css } from 'styled-components';

interface GridItemProps {
  isEnter: boolean;
}

export const Grid = styled.div`
  width: 90vw;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 35px;
  row-gap: 25px;
  justify-content: center;

  margin-top: 15px;

  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;
  }

  @media screen and (max-width: 670px) {
    grid: auto / 1fr;
    column-gap: 0px;
  }

  @media screen and (max-width: 420px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  position: relative;
  width: auto;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 3px solid #2156e6;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  h4 {
    font-size: 15px;
    width: 100%;
    text-align: start;
  }

  span {
    margin: 10px 0 0 0;
    display: flex;

    p {
      color: #767676;
      font-size: 15px;
      strong {
        color: #332e2e;
      }
      & + p {
        margin: 0 0 0 10px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  ${(props) =>
    !props.isEnter &&
    css`
      border-color: #8f0002;
    `}

  @media screen and (max-width: 420px) {
    width: 100vw;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;

  h4 {
    font-size: 15px;
    width: 100%;
    text-align: start;
  }

  span {
    margin: 10px 0 0 0;
    display: flex;

    p {
      color: #767676;
      font-size: 15px;
      strong {
        color: #332e2e;
      }
      & + p {
        margin: 0 0 0 10px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  @media screen and (max-width: 570px) {
    span {
      flex-direction: column;
      p + p {
        margin: 3px 0 0 0;
      }
    }
  }
`;
