import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import FloatButton from 'components/FloatButton';
import RadioContainer, { ContentProps } from 'components/RadioContainer';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';
import { FaFlagCheckered, FaUsers } from 'react-icons/fa';
import { parseISO } from 'date-fns';
import moment from 'moment';

import * as D from 'styles/dialog_delete';
import { ModalDeleteContent, DeleteProps } from 'styles/dialog_delete';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import api from 'services/api';
import { ListProps } from '../main';

import { Grid, GridItem, StageBlock } from './styles';

interface EPGAmount {
  pe1: number;
  ps1: number;
  pe2: number;
  ps2: number;
  pe3: number;
  ps3: number;
}

const EPGStages: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();
  const location = useLocation<ListProps>();
  const history = useHistory();

  const [epg, setEPG] = useState(() => ({ ...location.state }));
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);
  const [amount, setAmount] = useState<EPGAmount>(() => {
    return {
      pe1: epg.qpart1,
      ps1: epg.qpers1,
      pe2: epg.qpart2,
      ps2: epg.qpers2,
      pe3: epg.qpart3,
      ps3: epg.qpers3,
    };
  });

  const checkStageBlock = (date: string): boolean => {
    if (!location.state) {
      return true;
    }

    const epgDate = new Date(parseISO(date));
    const today = new Date(parseISO(new Date().toISOString()));

    const stageFinale = new Date(
      epgDate.getUTCFullYear(),
      epgDate.getUTCMonth(),
      epgDate.getUTCDate(),
      0,
      0,
    );
    const compare = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0,
      0,
    );

    const formats = 'YYYY-mm-dd';

    const finale = moment(stageFinale, formats);
    const base = moment(compare, formats);

    return base.isAfter(finale);
  };

  const [blockStage1] = useState(() => {
    // return checkStageBlock(epg.dtf1) && epg.qpart1 > 0 && epg.qpers1 > 0;
    return checkStageBlock(epg.dtf1) && epg.qpart1 > 0;
  });

  const [blockStage2] = useState(() => {
    // return checkStageBlock(epg.dtf2) && epg.qpart2 > 0 && epg.qpers2 > 0;
    return checkStageBlock(epg.dtf2) && epg.qpart2 > 0;
  });

  const [blockStage3] = useState(() => {
    // return checkStageBlock(epg.dtf3) && epg.qpart3 > 0 && epg.qpers3 > 0;
    return checkStageBlock(epg.dtf3) && epg.qpart3 > 0;
  });

  const [endOptions] = useState<ContentProps[]>(() => {
    return blockStage3
      ? [
          {
            id: 'E1',
            value: 'E',
            label: 'Padrão (escola terminada)',
          },
          {
            id: 'E2',
            value: 'F',
            label: 'Finalizada Prematuramente',
          },
        ]
      : [
          {
            id: 'E2',
            value: 'F',
            label: 'Finalizada Prematuramente',
          },
        ];
  });

  const getAmount = useCallback(async () => {
    // const response = await api.get(`/sgo/epg_stage_amount.php?data=${JSON.stringify({epg: epg.epg})}`);

    await api
      .get(`/sgo/epg_stage_amount.php?data=${JSON.stringify({ epg: epg.epg })}`)
      .then((response) => setAmount(response.data));
    // setAmount(response.data);
  }, [epg.epg]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getAmount();
  }, [getAmount, history, location.state]);

  const handleNavigateStage1 = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/futuros_guias`,
      state: { ...epg, stage: 1 },
    });
  }, [location.pathname, history, epg]);

  const handleNavigateStage2 = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/futuros_guias`,
      state: { ...epg, stage: 2 },
    });
  }, [location.pathname, history, epg]);

  const handleNavigateStage3 = useCallback(() => {
    history.push({
      pathname: `${location.pathname}/futuros_guias`,
      state: { ...epg, stage: 3 },
    });
  }, [location.pathname, history, epg]);

  const handleDiagFinish = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleFinish = useCallback(
    async (data: { end: string }) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          end: yup
            .string()
            .notOneOf(['Selecione', ''], 'Justificativa obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDeleteDiag((state) => ({ ...state, open: !state.open }));
        setLoading(true);

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            epg: epg.epg,
          }),
        );

        await api.post('/sgo/epg_end.php', send, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setEPG((state) => ({ ...state, sit: data.end }));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [epg.epg, errorHandling],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={Object.keys(epg).length > 0 ? `EPG N° ${epg.epg}` : ''}
      />
      {!epg.sit &&
        (user.perfil === 'LOC' ||
          [epg.ge1, epg.ge2, epg.ge3].indexOf(user.gseq) > -1) && (
          <FloatButton
            top="60"
            right="0"
            icon={FaFlagCheckered}
            bgcolor="#c53030"
            svgcolor="#fff"
            inverticon="true"
            onClick={handleDiagFinish}
          />
        )}
      <Content>
        {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
          <AlteredHeader>
            {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
              <div>
                <p>{epg.anbdesc}</p>
              </div>
            )}
            <div>
              <p>{epg.locdesc}</p>
            </div>
          </AlteredHeader>
        )}
        <Grid>
          <GridItem delay={0.35} blocked={blockStage1}>
            <h2>1ª Etapa</h2>

            <div>
              {/* <StageBlock
                banner={epg.sit === 'F' ? '#c53030' : ''}
                textcolor={epg.sit === 'F' ? '#fff' : ''}
              >
                <p>{epg.sit === 'F' ? 'Encerrada' : 'Finalizada'}</p>
              </StageBlock> */}
              {(blockStage1 || !!epg.sit) && (
                <StageBlock
                  banner={epg.sit === 'F' ? '#c53030' : ''}
                  textcolor={epg.sit === 'F' ? '#fff' : ''}
                >
                  <p>{epg.sit === 'F' ? 'Encerrada' : 'Finalizada'}</p>
                </StageBlock>
              )}
              <span>
                <p>
                  Local:&nbsp;<strong>{epg.pn1}</strong>
                </p>
              </span>
              <span>
                <p>
                  Início:&nbsp;<strong>{formatDate(epg.dti1)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Fim:&nbsp;<strong>{formatDate(epg.dtf1)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Dia&nbsp;|&nbsp;Hora:&nbsp;
                  <strong>
                    {epg.wn1}&nbsp;|&nbsp;{epg.hr1}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Guia:&nbsp;<strong>{epg.gn1}</strong>
                </p>
              </span>
              <span>
                <p>
                  Participantes:&nbsp;<strong>{amount.pe1}</strong>
                </p>
                <p>
                  Perseverantes:&nbsp;<strong>{amount.ps1}</strong>
                </p>
              </span>
              <button type="button" onClick={handleNavigateStage1}>
                <FaUsers />
              </button>
            </div>
          </GridItem>
          <GridItem delay={0.7} blocked={blockStage2}>
            <h2>2ª Etapa</h2>
            <div>
              {(blockStage2 || !!epg.sit) && (
                <StageBlock
                  banner={epg.sit === 'F' ? '#c53030' : ''}
                  textcolor={epg.sit === 'F' ? '#fff' : ''}
                >
                  <p>{epg.sit === 'F' ? 'Encerrada' : 'Finalizada'}</p>
                </StageBlock>
              )}
              <span>
                <p>
                  Local:&nbsp;<strong>{epg.pn2}</strong>
                </p>
              </span>
              <span>
                <p>
                  Início:&nbsp;<strong>{formatDate(epg.dti2)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Fim:&nbsp;<strong>{formatDate(epg.dtf2)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Dia&nbsp;|&nbsp;Hora:&nbsp;
                  <strong>
                    {epg.wn2}&nbsp;|&nbsp;{epg.hr2}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Guia:&nbsp;<strong>{epg.gn2}</strong>
                </p>
              </span>
              <span>
                <p>
                  Participantes:&nbsp;<strong>{amount.pe2}</strong>
                </p>
                <p>
                  Perseverantes:&nbsp;<strong>{amount.ps2}</strong>
                </p>
              </span>
              <button type="button" onClick={handleNavigateStage2}>
                <FaUsers />
              </button>
            </div>
          </GridItem>
          <GridItem delay={1.05} blocked={blockStage3}>
            <h2>3ª Etapa</h2>
            <div>
              {(blockStage3 || !!epg.sit) && (
                <StageBlock
                  banner={epg.sit === 'F' ? '#c53030' : ''}
                  textcolor={epg.sit === 'F' ? '#fff' : ''}
                >
                  <p>{epg.sit === 'F' ? 'Encerrada' : 'Finalizada'}</p>
                </StageBlock>
              )}
              <span>
                <p>
                  Início:&nbsp;<strong>{formatDate(epg.dti3)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Fim:&nbsp;<strong>{formatDate(epg.dtf3)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Guia:&nbsp;<strong>{epg.gn3}</strong>
                </p>
              </span>
              <span>
                <p>
                  Participantes:&nbsp;<strong>{amount.pe3}</strong>
                </p>
                <p>
                  Perseverantes:&nbsp;<strong>{amount.ps3}</strong>
                </p>
              </span>
              <button type="button" onClick={handleNavigateStage3}>
                <FaUsers />
              </button>
            </div>
          </GridItem>
        </Grid>
        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <Form
            ref={formRef}
            onSubmit={handleFinish}
            style={{ margin: '0', padding: '0' }}
          >
            <D.Title>
              <h2>*** ATENÇÃO ***</h2>
            </D.Title>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  A escola <strong>E-{epg.epg}</strong> será encerrada
                  permanentemente.
                </p>
                <p>
                  Justifique abaixo a sua decisão e clique em confirmar para
                  continuar.
                </p>
                <h3 style={{ margin: '20px 0 0 0' }}>Finalizada de forma:</h3>
                <RadioContainer
                  title=""
                  errorStyle={{ right: '0', transform: 'translateY(50%)' }}
                  containerStyle={{ width: '100%', margin: '10px 0 25px 0' }}
                  itemsStyle={{ justifyContent: 'space-around' }}
                  // onClick={handleSEMESTERselection}
                  name="end"
                  content={endOptions}
                />
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel type="button" onClick={handleDiagFinish}>
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EPGStages;
