import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import TextareaDialog from 'components/TextareaDialog';
import Loading from 'components/Loading';

import { useLocation, useHistory } from 'react-router-dom';

import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';

import { FaArrowLeft } from 'react-icons/fa';
import { formatDate } from 'utils/formatDate';

import api from 'services/api';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { ListProps, DeclineFormData } from '../main';
import { Origin, Destiny, MessageContent } from '../main/styles';
import { ButtonWrapper, DecisionButton, OBSContainer, Wrapper } from './styles';

const TransfGuiasMore: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<ListProps>();
  const { goBack, push } = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { mud } = useChanges();
  const {
    errorHandling,
    handlePermission,
    handleGTPermission,
  } = useCredentials();

  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const [transf, setTransf] = useState<ListProps>({} as ListProps);

  const getInfo = useCallback(async () => {
    const hash = location.pathname.substr(
      location.pathname.lastIndexOf('/') + 1,
    );

    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/transfguia_list.php?data=${JSON.stringify({ hash })}`,
      );
      const { anbo, loco, anbd, locd }: ListProps = response.data;
      handleGTPermission(anbo, loco, anbd, locd, user.perfil);

      setTransf(() => ({ ...response.data }));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, handleGTPermission, location.pathname, user.perfil]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);
    if (!location.state) {
      getInfo();
      return;
    }

    const { anbo, loco, anbd, locd }: ListProps = location.state;

    handleGTPermission(anbo, loco, anbd, locd, user.perfil);
    setTransf(() => ({ ...location.state }));
  }, [
    getInfo,
    goBack,
    handleGTPermission,
    handlePermission,
    location.state,
    user.perfil,
  ]);

  const handleDirectBack = useCallback(() => {
    push(
      location.pathname.replace(
        `/${location.pathname.substr(location.pathname.lastIndexOf('/') + 1)}`,
        '',
      ),
    );
  }, [push, location.pathname]);

  const handlePreAccept = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleAccept = useCallback(async () => {
    try {
      setInc((state) => ({ ...state, open: !state.open }));
      setLoading(true);
      const send = new FormData();

      send.append(
        'data',
        JSON.stringify({
          loco: transf.loco,
          locodesc: transf.locodesc,
          locd: transf.locd,
          locddesc: transf.locddesc,
          anbo: transf.anbo,
          anbodesc: transf.anbodesc,
          anbd: transf.anbd,
          anbddesc: transf.anbddesc,
          name: transf.name,
          gseq: transf.gseq,
          hash: transf.hash,
          transaction: 'accept',
        }),
      );

      await api.post('/sgo/transfguia_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'info',
        title: 'Sucesso',
        description:
          'A transferência foi aceita. Um email foi enviado para as coordenações envolvidas.',
      });

      goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    goBack,
    transf.anbd,
    transf.anbddesc,
    transf.anbo,
    transf.anbodesc,
    transf.gseq,
    transf.hash,
    transf.locd,
    transf.locddesc,
    transf.loco,
    transf.locodesc,
    transf.name,
  ]);

  const handlePreDecline = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleDecline = useCallback(
    async (data: DeclineFormData) => {
      try {
        setDeleteDiag((state) => ({ ...state, open: !state.open }));
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          obs: yup
            .string()
            .min(15, 'Quantidade mínima de caracteres não atingida (15).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            loco: transf.loco,
            locodesc: transf.locodesc,
            locd: transf.locd,
            locddesc: transf.locddesc,
            anbo: transf.anbo,
            anbodesc: transf.anbodesc,
            anbd: transf.anbd,
            anbddesc: transf.anbddesc,
            name: transf.name,
            gseq: transf.gseq,
            hash: transf.hash,
            transaction: 'decline',
          }),
        );

        await api.post('/sgo/transfguia_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'info',
          title: 'Sucesso',
          description:
            'A transferência foi recusada. Um email foi enviado para as coordenações envolvidas.',
        });

        goBack();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      errorHandling,
      goBack,
      transf.anbd,
      transf.anbddesc,
      transf.anbo,
      transf.anbodesc,
      transf.gseq,
      transf.hash,
      transf.locd,
      transf.locddesc,
      transf.loco,
      transf.locodesc,
      transf.name,
    ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        customBack={
          !location.state && <FaArrowLeft onClick={handleDirectBack} />
        }
      />
      <Content>
        <AlteredHeader>
          <div>Transferência de Guia</div>
          <div>{transf.name}</div>
        </AlteredHeader>
        {Object.keys(transf).length > 0 && (
          <Wrapper>
            <span>
              <h4>Certificado:</h4>
              <p>{transf.cert}</p>
            </span>
            <span>
              <h4>
                <Origin />
                Origem:
              </h4>
              <p>
                {transf.anbo === transf.anbd &&
                user.anbc === transf.anbo &&
                ['NAC', 'LOC'].indexOf(user.perfil) > -1
                  ? ''
                  : `${transf.anbodesc} | `}
                &nbsp;{transf.locodesc}
              </p>
            </span>
            <span>
              <h4>
                <Destiny />
                Destino:
              </h4>
              <p>
                {transf.anbo === transf.anbd &&
                user.anbc === transf.anbo &&
                ['NAC', 'LOC'].indexOf(user.perfil) > -1
                  ? ''
                  : `${transf.anbddesc} | `}
                &nbsp;{transf.locddesc}
              </p>
            </span>

            <span>
              <h4>Data Solicitação:</h4>
              <p>{formatDate(transf.dtsolic)}</p>
            </span>
            {transf.aceite !== 'P' && (
              <span>
                <h4>
                  Data {transf.aceite === 'N' ? 'Recusa' : 'Transferência'}:
                </h4>
                <p>{formatDate(transf.dttransf)}</p>
              </span>
            )}
            <OBSContainer>
              <h4>Observação:</h4>
              {transf.aceite !== 'P' ? (
                <p>{transf.obs}</p>
              ) : (
                <p>Tomada de decisão pendente</p>
              )}
              {/* {!loading && transf.aceite !== 'P' ? (
                <p>{transf.obs}</p>
              ) : (
                <TextareaDialog
                  name="obs"
                  placeholder="Motivo da decisão (Obrigatório em caso de recusa)"
                />
              )} */}
            </OBSContainer>
          </Wrapper>
        )}

        <ButtonWrapper
          appear={
            transf.aceite === 'P' &&
            !!mud &&
            ((user.perfil === 'LOC' && user.loccod === transf.locd) ||
              (user.perfil === 'NAC' && user.anbc === transf.anbd))
          }
        >
          <DecisionButton
            bgcolor="#bc4143"
            type="button"
            onClick={handlePreDecline}
          >
            Recusar
          </DecisionButton>
          <DecisionButton
            bgcolor="#00802b"
            type="button"
            onClick={handlePreAccept}
          >
            Aceitar
          </DecisionButton>
        </ButtonWrapper>
        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <I.Title>
            <h2>{inc.title || '*** ATENÇÃO ***'}</h2>
          </I.Title>
          <I.Content>
            <MessageContent>
              <p>
                Esse processo irá transferir de forma permanente o guia&nbsp;
                <strong>{transf.name}</strong>&nbsp;para&nbsp;
                {user.perfil === 'LOC' ? (
                  'sua coordenação'
                ) : (
                  <strong>{transf.locddesc}</strong>
                )}
                .
              </p>
              <p>
                Se estiver seguro de sua decisão, clique em confirmar para
                continuar.
              </p>
            </MessageContent>
          </I.Content>
          <I.Actions>
            <I.Cancel type="button" onClick={handlePreAccept}>
              Cancelar
            </I.Cancel>
            <I.Confirm type="button" onClick={handleAccept}>
              Confirmar
            </I.Confirm>
          </I.Actions>
        </I.Container>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <Form
            ref={formRef}
            onSubmit={handleDecline}
            style={{ margin: '0', padding: '0' }}
          >
            <D.Title>
              <h2>*** ATENÇÃO ***</h2>
            </D.Title>
            <D.Content>
              <MessageContent>
                <p>A transferência será recusada.</p>
                <p>
                  Justifique abaixo a sua decisão e clique em confirmar para
                  continuar.
                </p>
                <span>
                  <TextareaDialog
                    name="obs"
                    placeholder="Justificativa (obrigatório)"
                  />
                </span>
              </MessageContent>
            </D.Content>
            <D.Actions>
              <D.Cancel type="button" onClick={handlePreDecline}>
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default TransfGuiasMore;
