import styled from 'styled-components';
import { parseToRgb } from 'polished';

export const Wrapper = styled.div`
  margin: 10px 0 30px 0;
  --red: ${parseToRgb('#bc4143').red};
  --green: ${parseToRgb('#bc4143').green};
  --blue: ${parseToRgb('#bc4143').blue};

  display: flex;
  flex-direction: column;

  strong {
    font-size: 24px;
    border-radius: 3px 0 0 3px;
    margin: 15px 0 3px 0;
    padding: 0px 5px;
    color: #efefef;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(var(--red), var(--green), var(--blue), 1),
      rgba(var(--red), var(--green), var(--blue), 1),
      rgba(var(--red), var(--green), var(--blue), 1),
      rgba(var(--red), var(--green), var(--blue), 1),
      rgba(var(--red), var(--green), var(--blue), 1),
      rgba(var(--red), var(--green), var(--blue), 0.5),
      rgba(var(--red), var(--green), var(--blue), 0.5),
      rgba(var(--red), var(--green), var(--blue), 0.25),
      rgba(var(--red), var(--green), var(--blue), 0.25),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
  }
  div {
    display: flex;
    /* min-height: 30px; */
    min-width: 360px;
    width: 100vw;
    max-width: 500px;

    transition: border-color 0.35s, border-width 0.35s, border-radius 0.25s;

    border: 2px solid #eaeaea;
    border-radius: 3px;

    & + div {
      margin: 10px 0 0 0;
    }

    /*  */
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
      }
      & + span {
        margin: 0 0 0 7.5px;
      }
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2) {
        justify-content: flex-start;
        width: calc(100% - 140px);
      }
      &:nth-child(3) {
        width: 60px;
      }
    }

    &:hover {
      border-color: #332e2e;
    }

    @media screen and (max-width: 500px) {
      border-radius: 0px;
      border-right-width: 0px;
      border-left-width: 0px;
    }
  }
`;
