import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import InputDialog from 'components/InputDialog';
import SelectV2 from 'components/SelectV2';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import api from 'services/api';

import {
  AlteredHeader,
  Container,
  Content,
  IncButton,
  ComboProps,
} from 'styles/sgo_wrappers';

import { FaPencilAlt, FaPlusCircle, FaTimes } from 'react-icons/fa';

import * as U from 'styles/dialog_update';
import { UpdateProps } from 'styles/dialog_update';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import * as O from 'styles/option_buttons';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';

import {
  Grid,
  GridItem,
  TrajContent,
  OptButton,
  YearContainer,
  LeftSVG,
  RightSVG,
} from './styles';

import { FIGuiaProps } from '../main';
import { checkSentDate, checkTopDate } from '../checkDates';

interface ListProps {
  seq: number;
  year: string;
  sem: number;
  tscod: string;
  start: string;
  end: string;
  place: string;
  part: string;
  pers: string;
  tsdesc: string;
}

interface FormData {
  seq: number;
  service: string;
  place: string;
  dtstart: string;
  dtend: string;
  part: string;
  pers: string;
}

const FIGServ: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const updateRef = useRef<FormHandles>(null);
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);

  const [inc, setInc] = useState<IncludeProps>({} as IncludeProps);

  // const [years, setYears] = useState<ComboProps[]>([]);
  const [years, setYears] = useState<{ year: number }[]>([]);
  const [selectedService, setSelectedService] = useState('');

  const [prevReg, setPrevReg] = useState({} as FormData);
  const [prevConReg, setPrevConReg] = useState({} as FormData);
  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [maxDate, setMaxDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [comboTG, setComboTG] = useState<ComboProps[]>([]);
  const [currYear, setCurrYear] = useState(new Date().getFullYear());

  const getComboTG = useCallback(async () => {
    const response = await api.get(
      `combos/comboServices.php?data=${JSON.stringify({ mode: 'FIG' })}`,
    );

    setComboTG(
      response.data.map(
        (item: { value: string; label: string; type: string }) => ({
          value: item.value,
          label: item.label,
        }),
      ),
    );
  }, []);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_servicos.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      const { services, sentDate, anos, top } = response.data;

      setYears(anos);
      const maxYear = anos[anos.length - 1].year;
      setCurrYear(maxYear);
      setMinDate(sentDate);
      setMaxDate(top);
      setRawList(services);

      setList(
        services.filter(
          (item: ListProps) => parseInt(item.year, 10) === maxYear,
        ),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getComboTG();
    getList();
  }, [getComboTG, getList, history, location.state]);

  const handleDecreaseYear = useCallback(() => {
    setList(
      rawList.filter(
        (item: ListProps) => parseInt(item.year, 10) === currYear - 1,
      ),
    );

    setCurrYear((state) => state - 1);
  }, [currYear, rawList]);

  const handleIncreaseYear = useCallback(() => {
    setList(
      rawList.filter(
        (item: ListProps) => parseInt(item.year, 10) === currYear + 1,
      ),
    );
    setCurrYear((state) => state + 1);
  }, [currYear, rawList]);

  const handleSelection = useCallback(() => {
    const service = includeRef.current?.getFieldValue('service');
    setSelectedService(service);

    if (['EP', 'CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(service) < 0) {
      includeRef.current?.setFieldValue('pers', '');
    }
  }, []);

  const handleUpdateSelection = useCallback(() => {
    const service = updateRef.current?.getFieldValue('service');
    setSelectedService(service);

    if (['EP', 'CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(service) < 0) {
      updateRef.current?.setFieldValue('pers', '');
    }
  }, []);

  const getDesc = useCallback(
    (ocurr: number) => {
      const index = list.findIndex((item) => item.seq === ocurr);
      setPrevReg({
        seq: ocurr,
        service: list[index].tscod,
        place: list[index].place,
        dtstart: list[index].start,
        dtend: list[index].end,
        part: list[index].part,
        pers:
          ['EP', 'CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(
            list[index].tscod,
          ) < 0
            ? ''
            : list[index].pers,
      });
      setPrevConReg({
        seq: ocurr,
        service: list[index].tsdesc,
        place: list[index].place,
        dtstart: list[index].start,
        dtend: list[index].end,
        part: list[index].part,
        pers:
          ['EP', 'CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(
            list[index].tscod,
          ) < 0
            ? ''
            : list[index].pers,
      });
    },
    [list],
  );

  const handlePreInc = useCallback(() => {
    setSelectedService('');
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreAlt = useCallback(
    (ocurr: number) => {
      setSelectedService('');
      getDesc(ocurr);
      const index = list.findIndex((item) => item.seq === ocurr);

      if (index > -1) {
        setSelectedService(list[index].tscod);
      }
      // const index = list.findIndex((item) => item.seq === ocurr);
      // setComboLOC(locs.filter((item) => item.anb === list[index].anb));

      setUpdateDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc, list],
  );

  const handlePreDel = useCallback(
    (ocurr: number) => {
      getDesc(ocurr);
      setSelectedService('');
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handleInclude = useCallback(
    async (data: FormData) => {
      try {
        includeRef.current?.setErrors({});

        setLoading(true);
        const schema = yup.object().shape({
          service: yup.string().notOneOf(['Selecione', '']),
          place: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(70, 'Quantidade máxima de caracteres excedida (70)'),
          dtstart: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Serviço deve ser após a data de envio (${formatDate(minDate)})`,
              (sent: string) => {
                return checkSentDate(minDate, sent);
              },
            )
            .test(
              'givenDate',
              `Serviço deve ser anterior a ${formatDate(maxDate)}.`,
              (sent: string) => {
                return checkTopDate(sent, maxDate);
              },
            ),
          dtend: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Data Final deve ser após a data de início (${formatDate(
                data.dtstart,
              )})`,
              (sent: string) => {
                return checkSentDate(data.dtstart, sent);
              },
            )
            .test(
              'givenDate',
              `Serviço deve ser anterior a ${formatDate(maxDate)}.`,
              (sent: string) => {
                return checkTopDate(sent, maxDate);
              },
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            gseq: gState.gseq,
            mode: 'inc',
          }),
        );

        setInc((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_servicos.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, minDate, maxDate],
  );

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        updateRef.current?.setErrors({});

        setLoading(true);
        const schema = yup.object().shape({
          service: yup.string().notOneOf(['Selecione', '']),
          place: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(70, 'Quantidade máxima de caracteres excedida (70)'),
          dtstart: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Serviço deve ser após a data de envio (${formatDate(minDate)})`,
              (sent: string) => {
                return checkSentDate(minDate, sent);
              },
            )
            .test(
              'givenDate',
              `Serviço deve ser anterior a ${formatDate(maxDate)}.`,
              (sent: string) => {
                return checkTopDate(sent, maxDate);
              },
            ),
          dtend: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Data Final deve ser após a data de início (${formatDate(
                data.dtstart,
              )})`,
              (sent: string) => {
                return checkSentDate(data.dtstart, sent);
              },
            )
            .test(
              'givenDate',
              `Serviço deve ser anterior a ${formatDate(maxDate)}.`,
              (sent: string) => {
                return checkTopDate(sent, maxDate);
              },
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            seq: prevReg.seq,
            gseq: gState.gseq,
            mode: 'update',
          }),
        );

        setUpdateDiag((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_servicos.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, minDate, prevReg.seq, maxDate],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          seq: prevConReg.seq,
          gseq: gState.gseq,
          mode: 'delete',
        }),
      );

      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/sgo/fig_servicos.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getList();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, gState.gseq, getList, prevConReg.seq]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Serviços TOV</p>
        </AlteredHeader>

        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}

        {!!rawList && rawList.length > 0 && (
          <YearContainer>
            {years.length > 0 && currYear > years[0].year && (
              <LeftSVG onClick={handleDecreaseYear} />
            )}
            <p>{currYear}</p>
            {years.length > 0 && currYear < years[years.length - 1].year && (
              <RightSVG onClick={handleIncreaseYear} />
            )}
          </YearContainer>
        )}

        <Grid>
          {list.map((item) => (
            <GridItem key={item.seq}>
              <h4>{item.tsdesc}</h4>

              <div>
                <span>
                  <p>
                    Início:&nbsp;<strong>{formatDate(item.start)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Fim:&nbsp;<strong>{formatDate(item.end)}</strong>
                  </p>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    Local:&nbsp;<strong>{item.place}</strong>
                  </p>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    Participantes:&nbsp;<strong>{item.part}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    {/^\d+$/.test(item.pers) ? 'Perseverantes' : 'Tipo'}:&nbsp;
                    <strong>{item.pers}</strong>
                  </p>
                </span>
              </div>
              <O.GridOptions>
                <OptButton
                  bgcolor="#007acc"
                  onClick={() => handlePreAlt(item.seq)}
                >
                  <FaPencilAlt />
                </OptButton>
                <OptButton
                  bgcolor="#c53030"
                  onClick={() => handlePreDel(item.seq)}
                >
                  <FaTimes />
                </OptButton>
              </O.GridOptions>
            </GridItem>
          ))}
        </Grid>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Serviço:</p>
                      <SelectV2
                        name="service"
                        content={comboTG}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial="Selecione"
                        onChange={handleSelection}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Local:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        placeholder="Local do serviço"
                        name="place"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Data Início:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        isDate
                        placeholder="dd/mm/aaaa"
                        name="dtstart"
                      />
                    </span>
                    <span>
                      <p>Data Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        isDate
                        placeholder="dd/mm/aaaa"
                        name="dtend"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Participantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Participantes"
                        name="part"
                      />
                    </span>
                    <span>
                      <p>Perseverantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Perseverantes"
                        name="pers"
                        disabled={
                          [
                            'EP',
                            'CC',
                            'SA',
                            'SJ',
                            'TA',
                            'TC',
                            'TD',
                            'TJ',
                          ].indexOf(selectedService) < 0
                        }
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              dtstart: prevReg.dtstart,
              dtend: prevReg.dtend,
              place: prevReg.place,
              part: prevReg.part,
              pers: prevReg.pers,
            }}
          >
            <U.Content>
              <TrajContent>
                <div>
                  <div>
                    <span>
                      <p>Serviço:</p>
                      <SelectV2
                        name="service"
                        content={comboTG}
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          height: '37.5px',
                        }}
                        initial={prevReg.service}
                        onChange={handleUpdateSelection}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Local:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '360px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        placeholder="Local do serviço"
                        name="place"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Data Início:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                        name="dtstart"
                      />
                    </span>
                    <span>
                      <p>Data Fim:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="date"
                        placeholder="dd/mm/aaaa"
                        name="dtend"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>Participantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',

                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Participantes"
                        name="part"
                      />
                    </span>
                    <span>
                      <p>Perseverantes:</p>
                      <InputDialog
                        containerStyle={{
                          width: width >= 500 ? '172.5px' : '228px',
                          padding: '5px 2px 4.82px 5px',
                          height: '37.5px',
                        }}
                        inputStyle={{
                          width: '110px',
                        }}
                        type="number"
                        mask="allowZero"
                        step="none"
                        placeholder="Perseverantes"
                        name="pers"
                        disabled={
                          [
                            'EP',
                            'CC',
                            'SA',
                            'SJ',
                            'TA',
                            'TC',
                            'TD',
                            'TJ',
                          ].indexOf(selectedService) < 0
                        }
                      />
                    </span>
                  </div>
                </div>
              </TrajContent>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={() => setUpdateDiag({ open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <GridItem>
                  <h4>{prevConReg.service}</h4>

                  <div>
                    <span>
                      <p>
                        Início:&nbsp;
                        <strong>{formatDate(prevConReg.dtstart)}</strong>
                      </p>
                    </span>
                    <span style={{ marginLeft: '5px' }}>
                      <p>
                        Fim:&nbsp;
                        <strong>{formatDate(prevConReg.dtend)}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Local:&nbsp;<strong>{prevConReg.place}</strong>
                      </p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <p>
                        Part.:&nbsp;<strong>{prevConReg.part}</strong>
                      </p>
                    </span>
                    <span style={{ marginLeft: '5px' }}>
                      <p>
                        {/^\d+$/.test(prevConReg.pers) ? 'Pers.' : 'Tipo'}
                        :&nbsp;
                        <strong>{prevConReg.pers}</strong>
                      </p>
                    </span>
                  </div>
                </GridItem>

                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGServ;
