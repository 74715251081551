import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface BurgerProps {
  isOpen: boolean;
}

export const Container = styled.div`
  z-index: 151;
  position: sticky;
  top: -1px;
  /* padding: 0px 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 3px 5px 0px rgba(138, 0, 2, 0.75); */

  width: 100vw;
  height: 50px;

  background: #8a0002;

  > svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: #efefef;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  button {
    background: transparent;
    border: 0;
  }

  > a,
  button {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    transition: 0.25s color ease;
    text-decoration: none;
    color: #efefef;
    font-size: 18px;

    &::after,
    &::before {
      content: '';
      transition: 0.25s all ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      height: 1px;

      width: 00%;
      background: #fff;
    }
    &::before {
      top: -5px;
      right: 0;
    }

    &::after {
      bottom: -5px;
      left: 0;
    }

    &:hover {
      color: #fff;
    }

    &:hover:before,
    &:hover:after {
      width: 100%;
    }

    & + a,
    & + button {
      margin: 0 0 0 50px;
    }
  }

  > div {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    > svg {
      cursor: pointer;
      opacity: 0.85;
      color: #efefef;
      height: 30px;
      width: 30px;

      transition: all 0.35s ease;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #efefef;
      transition: color 0.25s ease;

      > img {
        opacity: 0.85;
        border-radius: 50%;
        height: 40px;
        width: 40px;

        transition: all 0.5s ease;

        @media screen and (max-width: 900px) {
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.25);
          box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.5);
        }
      }
    }

    &:hover {
      > svg {
        opacity: 1;
        color: #fff;
      }
      a {
        color: ${shade(0.2, '#efefef')};
        > img {
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.25);
          box-shadow: 0px 0px 15px 2px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
`;

export const BurgerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #7159c1; */
  /* height: 50px; */
  width: 50px;
  /* border: 1px solid rgba(239, 239, 239, 0.5); */
  padding: 2px 6px;
  border-radius: 5px;
`;

export const Burger = styled.div<BurgerProps>`
  cursor: pointer;

  background: transparent;
  border-radius: 10px;

  width: 30px;
  transition: all 0.5s;

  &::after,
  &::before,
  div {
    background: #efefef;

    border-radius: 3px;
    content: '';
    display: block;
    height: 4px;
    margin: 6px 0;
    transition: all 0.5s ease-in-out;
  }

  ${(props) =>
    props.isOpen &&
    css`
      &::before {
        transform: translateY(10px) rotate(45deg);
      }
      &::after {
        transform: translateY(-10px) rotate(-45deg);
      }

      div {
        transform: translateX(-10px);
        opacity: 0;
      }
    `}
`;

export const BurgerContent = styled.div<BurgerProps>`
  z-index: 152;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;

  background: #fff;

  width: 100vw;
  height: 0vh;

  pointer-events: none;
  /* padding: 50px 0; */
  opacity: 1;

  button {
    background: transparent;
    border: 0;
    color: #8a0002;
  }

  a,
  button {
    text-decoration: none;
    color: #8a0002;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 25px;
    background: transparent;
    padding: 0px 25px;
    text-align: center;
    border: 2px solid transparent;

    transition: color 0.25s ease, border 0.25s ease, background 0.25s ease;

    & + a,
    & + button {
      margin: 25px 0 0 0;
    }

    &:hover {
      color: #8a0002;
      border-top-color: #8a0002;
      border-bottom-color: #8a0002;
    }

    @media screen and (max-width: 900px) {
      color: #8a0002;
      border-top-color: #bc4143;
      border-bottom-color: #bc4143;
    }
  }

  transition: height 0.5s ease, pointer-events 0.5s ease, padding 0.5s ease;
  ${(props) =>
    props.isOpen &&
    css`
      height: 100vh;
      pointer-events: all;
      padding: 50px 0;
    `};
`;

export const SocialMediaContainer = styled.div`
  position: absolute;
  right: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 80px;

  background-color: #8a0002;

  a {
    color: #fff !important;
    opacity: 0.8 !important;
    transition: opacity 0.35s !important;

    &:hover {
      opacity: 1 !important;
    }

    & + a {
      margin: 0 0 0 10px;
    }
  }
`;
