import styled from 'styled-components';

interface BtType {
  used: string;
}

export const TableWrapper = styled.div`
  border: 3px solid #332e2e;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
  width: 800px;
  transition: all 0.35s ease;

  @media screen and (max-width: 800px) {
    border: 0px;
    border-top: 3px solid #332e2e;
    border-bottom: 3px solid #332e2e;
    width: 100vw;
    border-radius: 0px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  width: 100%;

  p {
    font-size: 16px;
  }
  thead {
    background: #332e2e;
    color: #fff;
  }
  tbody {
    background: #fff;
  }

  tr {
    min-height: 40px;
  }

  td {
    font-size: 16px;
    padding: 2px 3px;
  }
`;

export const Desc = styled.td`
  text-align: justify;
  text-justify: inter-word;
  padding: 0px 3px;

  @media screen and (max-width: 400px) {
    text-align: left;
    text-justify: auto;
  }
`;

export const Dated = styled.td`
  width: 100px;
`;

export const Update = styled.td`
  width: 40px;
`;

export const Delete = styled.td`
  width: 40px;
`;

export const ButtonTD = styled.button<BtType>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */

  height: 30px;
  width: 30px;

  background: ${(props) => (props.used === 'alt' ? '#007acc' : '#c53030')};
  border-radius: 3px;
  border: 0px;

  svg {
    height: 17px;
    width: 17px;

    color: #fff;
  }
`;
