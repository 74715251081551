import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 0 20px;

  transition: padding 0.35s;

  > div {
    margin: 15px 0;
    width: 100%;
    display: grid;
    grid: auto / 1fr 1fr 1fr 1fr;
    align-content: center;
    /* justify-items: center; */
    gap: 20px;

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #fff;
      border: 2px solid #efefef;
      border-radius: 5px;
      /* min-height: 70px; */
      box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
      transition: border-radius 0.25s, border-width 0.25s;
      padding: 5px;

      span {
        font-size: 14px;

        p {
          color: #767676;
          strong {
            color: #332e2e;
          }
        }
        h4 {
          font-size: 16px;
          color: #8a0002;
        }

        & + span {
          margin-top: 10px;
        }

        &:nth-last-child(1) {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: 5px;
          right: 5px;
          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;

            color: #fff;

            border-radius: 3px;
            padding: 3px 7.5px;

            width: 27.5px;
            height: 27.5px;
            background: #007acc;
          }
          svg {
            width: 25px;
            height: 25px;
            color: #fff;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    > div {
      grid: auto / 1fr 1fr 1fr;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 0 10px;
    > div {
      grid: auto / 1fr 1fr;
    }
  }
  @media screen and (max-width: 550px) {
    padding: 0 5px;
    > div {
      grid: auto / 1fr;
      place-items: center;
      > div {
        max-width: 350px;
        width: 100vw;
      }
    }
  }
  @media screen and (max-width: 360px) {
    padding: 0;
    > div {
      > div {
        max-width: 360px;
        border-radius: 0px;
        border-width: 2px 0;
      }
    }
  }
`;

// box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
