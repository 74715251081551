import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import { useLocation, Link } from 'react-router-dom';
import { useSpring } from 'react-spring';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import api from 'services/api';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import { useCredentials } from 'hooks/credentials';

import { useAuth } from 'hooks/auth';
import { setLocalStorage, getLocalStorage } from 'utils/handleLocalStorage';
import { formatNumber } from 'utils/calcTotal';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { removeAcento } from 'utils/specialChars';
import { useWindow } from 'hooks/window';

import {
  Table,
  TDcod,
  TDLoc,
  TDtype,
  TDlserv,
  TDappl,
  TDoffer,
  TDshow,
} from './styles';

interface CoordProps {
  cod: string;
  desc: string;
}

interface LocalStorageProps {
  chosenCoord?: string;
  coords?: string;
}

export interface ListProps {
  seq: string;
  cod: string;
  desc: string;
  prq: string;
  g1: string;
  g2: string;
  offer: number;
  inidt: string;
  loc: string;
}

const Ofertas: React.FC = () => {
  const { pathname } = useLocation();
  const formRef = useRef<FormHandles>(null);
  const mode = process.env.REACT_APP_OFFER;
  const { user } = useAuth();
  const { width } = useWindow();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  const [initialCoord, setInitialCoord] = useState(() => {
    const { coords }: LocalStorageProps = getLocalStorage(`${mode}`);
    if (coords) {
      return JSON.parse(coords);
    }

    return user.perfil !== 'LOC'
      ? { anb: user.anbc, loc: null }
      : { anb: user.anbc, loc: user.loccod };
  });

  const [selectedCoord, setSelectedCoord] = useState(() => {
    const { chosenCoord }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (chosenCoord) {
      return JSON.parse(chosenCoord);
    }
    return user.perfil !== 'LOC'
      ? { cod: user.anbc, desc: user.anbdesc.replace('Nacional ', '') }
      : { cod: user.loccod, desc: user.locdesc };
  });
  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const [comboNacs, setComboNac] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNac(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setLocs(response.data);

    setComboLocs(
      response.data.filter(
        (item: ComboLocalProps) => item.anb === initialCoord.anb,
      ),
    );
  }, [initialCoord.anb]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/oferta_list.php?data=${JSON.stringify({
          coord: selectedCoord.cod,
        })}`,
      );

      setRawList(response.data);
      setList(response.data);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [selectedCoord.cod, errorHandling]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);
    if (!hasData) {
      if (user.perfil === 'ZON') {
        getComboANB();
      }
      if (user.perfil !== 'LOC') {
        getComboLOC();
      }
      setHasData(true);
    }
    getList();
  }, [
    handlePermission,
    getComboLOC,
    getList,
    user.perfil,
    getComboANB,
    hasData,
  ]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      setList(
        rawList.filter(
          (item) =>
            removeAcento(item.seq).includes(removeAcento(val)) ||
            removeAcento(item.desc)
              .toLowerCase()
              .includes(removeAcento(val).toLowerCase()),
        ),
      );
    },
    [rawList],
  );

  const handleNacSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboNac');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedCoord({ cod: value, desc: text });
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({ cod: value, desc: text }),
      coords: JSON.stringify({ anb: value, loc: null }),
    });

    setInitialCoord({ anb: value, loc: null });

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));
  }, [mode, locs]);

  const handleLocSelect = useCallback(() => {
    let theText = '';
    if (user.perfil === 'ZON') {
      const nacSelect = formRef.current?.getFieldRef('comboNac');
      theText = `${nacSelect.options[nacSelect.selectedIndex].text.substring(
        0,
        nacSelect.options[nacSelect.selectedIndex].text.indexOf('-') - 1,
      )} - `;
    }
    const select = formRef.current?.getFieldRef('comboLoc');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedCoord({
      cod: value,
      desc: `${user.perfil === 'ZON' ? `${theText}` : ''}${text}`,
    });

    let newValues = { anb: user.anbc, loc: null };
    const { coords }: LocalStorageProps = getLocalStorage(`${mode}`);

    if (coords) {
      newValues = JSON.parse(coords);
    }
    newValues.loc = value;
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({
        cod: value,
        desc: `${user.perfil === 'ZON' ? `${theText}` : ''}${text}`,
      }),
      coords: JSON.stringify(newValues),
    });

    setInitialCoord(newValues);
  }, [mode, user.anbc, user.perfil]);

  const handleRemoveLoc = useCallback(() => {
    setInitialCoord({ anb: user.anbc, loc: null });
    setSelectedCoord({
      cod: user.anbc,
      desc: user.anbdesc.replace('Nacional ', ''),
    });
    setLocalStorage(`${mode}`, {
      chosenCoord: JSON.stringify({
        cod: user.anbc,
        desc: user.anbdesc.replace('Nacional ', ''),
      }),
      coords: JSON.stringify({ anb: user.anbc, loc: null }),
    });
  }, [mode, user.anbc, user.anbdesc]);

  const removeButton = useSpring({
    opacity: initialCoord.loc !== null ? 1 : 0,
    pointerEvents: initialCoord.loc !== null ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={user.perfil !== 'LOC'}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            {user.perfil === 'ZON' ? (
              <div>
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboNac"
                    content={comboNacs}
                    initial={initialCoord.anb}
                    onChange={handleNacSelect}
                  />
                </span>
              </div>
            ) : null}
            {user.perfil !== 'LOC' ? (
              <>
                <div>
                  <span>
                    <p>Filtre por Local:</p>
                    <SelectV2
                      name="comboLoc"
                      content={comboLocs}
                      initial={initialCoord.loc || 'Selecione'}
                      onChange={handleLocSelect}
                    />
                  </span>
                </div>
                <RemoveButton
                  type="button"
                  onClick={handleRemoveLoc}
                  style={removeButton}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </>
            ) : null}
          </Form>
        }
      />
      <Header>
        <div>Ofertas</div>
        <div>{selectedCoord.desc}</div>
      </Header>
      <FloatInput amount="120px">
        <input
          placeholder="Filtrar por Código ou Tipo da Oficina"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="302px">
        <Table>
          <thead>
            <tr>
              <TDcod>Código</TDcod>
              {user.perfil !== 'LOC' && !initialCoord.loc ? (
                <TDLoc>Local</TDLoc>
              ) : null}

              <TDtype>Tipo</TDtype>
              <TDlserv>Local de Serviço/Atividade</TDlserv>
              <TDappl>Guias</TDappl>
              <TDoffer>Oferta</TDoffer>
              <TDshow>{width > 400 ? 'Consulta' : <FaSearch />}</TDshow>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <React.Fragment key={item.seq}>
                {index !== 0 && index % 10 === 0 && (
                  <tr style={{ background: '#332e2e', color: '#fff' }}>
                    <TDcod>Código</TDcod>
                    {user.perfil !== 'LOC' && !initialCoord.loc ? (
                      <TDLoc>Local</TDLoc>
                    ) : null}
                    <TDtype>Tipo</TDtype>
                    <TDlserv>Local de Serviço/Atividade</TDlserv>
                    <TDappl>Guias</TDappl>
                    <TDoffer>Oferta</TDoffer>
                    <TDshow>{width > 400 ? 'Consulta' : <FaSearch />}</TDshow>
                  </tr>
                )}
                <tr
                  style={{ background: index % 2 === 0 ? '#e6e6e6' : '#fff' }}
                >
                  <TDcod>{item.seq}</TDcod>
                  {user.perfil !== 'LOC' && !initialCoord.loc ? (
                    <TDLoc>{item.loc}</TDLoc>
                  ) : null}

                  <TDtype>{item.desc}</TDtype>
                  <TDlserv>{item.prq}</TDlserv>
                  <TDappl>
                    {item.g1.substr(0, item.g1.indexOf(' '))}
                    {item.g2 && ` / ${item.g2.substr(0, item.g2.indexOf(' '))}`}
                  </TDappl>
                  <TDoffer>R$ {formatNumber(item.offer)}</TDoffer>
                  <TDshow>
                    <Link
                      to={{
                        pathname: `${pathname}/detail`,
                        state: { reg: item },
                      }}
                    >
                      <FaSearch />
                    </Link>
                  </TDshow>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Ofertas;
