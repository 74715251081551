import styled, { css, keyframes } from 'styled-components';

interface MoreContainerProps {
  amount: number;
}

interface GContainerProps {
  photo: boolean;
}

const leftright = keyframes`
  0%{
    transform: translateX(10px);
  } 50% {
    transform: translateX(0px);
  } 100% {
    transform: translateX(9px);
  }
`;

export const InfoContainer = styled.div`
  margin: 15px 0 10px 0;
  position: relative;

  border: 3px solid #efefef;
  background: #fff;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px 10px;

  transition: box-shadow 0.35s ease, border-radius 0.75s, border-width 0.5s,
    width 0.35s;

  div {
    display: flex;
    position: relative;
    font-size: 16px;
    p {
      color: #767676;
    }
    strong {
      margin: 0 0 0 3px;
      color: #332e2e;
    }

    &:nth-child(1) {
      margin: 0 0 10px 0;
      &::after {
        border: 0px solid #a6a6a6;
        position: absolute;
        opacity: 0;
        bottom: 0;
        right: 0;
        border-bottom-width: 2px;
        transform: translateX(50px);
        width: 0;
        transition: width 0.25s, right 0.25s, transform 0.25s, opacity 0.25s;
        content: '';
      }
    }

    & + div {
      margin: 5px 0 0 0;
    }
  }

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    div {
      &:nth-child(1):after {
        right: 25%;
        transform: translateX(0px);
        width: 50%;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    width: 100%;
  }
`;

export const MoreContainer = styled.div<MoreContainerProps>`
  margin: 20px 0 50px 0;
  display: grid;

  grid: auto / 1fr;

  column-gap: 20px;
  ${(props) =>
    props.amount === 2 &&
    css`
      grid: auto / 1fr 1fr;
      @media screen and (max-width: 780px) {
        grid: auto / 1fr;
        row-gap: 20px;
        div:nth-last-child(1) {
          margin: 100px 0 0 0;
        }
      }
    `}
`;

export const GContainer = styled.div<GContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 10px 10px;
  border: 3px solid #efefef;
  background: #fff;
  border-radius: 5px;
  width: 360px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  font-size: 16px;

  h4 {
    position: relative;
    text-align: center;
    font-size: 18px;
    color: #332e2e;
    margin: 0 0 10px 0;

    &:after {
      border: 0px solid #a6a6a6;
      position: absolute;
      opacity: 0;
      bottom: 0;
      right: 0;
      border-bottom-width: 2px;
      transform: translateX(50px);
      width: 0;
      content: '';

      transition: width 0.25s, right 0.25s, transform 0.25s, opacity 0.25s;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;

    p,
    a {
      margin: 0 0 0 5px;
    }

    a {
      text-decoration: none;
      color: #332e2e;

      > svg {
        color: #3333ff;
        opacity: 0;
        height: 15px;
        width: 15px;
        transform: translateX(10px);
        transition: opacity 0.35s;
      }

      &:hover {
        > svg {
          animation: ${leftright} 2s infinite;
        }
      }
    }

    > svg {
      color: #a6a6a6;
      height: 30px;
      width: 30px;
    }
  }
  ${(props) =>
    props.photo &&
    css`
      transform: translateY(100px);
      h4 {
        margin: 20px 0 10px 0;
      }
    `}

  transition: box-shadow 0.35s, border-color 0.35s;
  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    h4 {
      &:after {
        right: 25%;
        transform: translateX(0px);
        width: 50%;
        opacity: 1;
      }
    }

    span {
      a {
        color: #000099;

        > svg {
          opacity: 1;
        }
      }
    }
    div {
      img {
        height: 180px;
        width: 180px;
      }
    }
  }
`;

export const GAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 5px solid #332e2e;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -80%);
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  height: 130px;
  width: 130px;
  border-radius: 50%;

  img {
    height: 125px;
    width: 125px;
    border-radius: 50%;
    object-fit: cover;

    transition: height 0.35s, width 0.35s;
  }
`;
