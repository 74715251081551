import styled from 'styled-components';
import { shade, lighten } from 'polished';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface UpdateProps {
  title?: string;
  open: boolean;
  content?: string | JSX.Element;
  values?: {
    [key: string]: string | number | string[];
  };
}

export const Container = styled(Dialog)`
  margin: 0 auto;
`;

export const Content = styled(DialogContent)`
  min-height: 30vh;
  min-width: 10vw;
  background: #e6e6e6;

  display: flex;
  align-items: center;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: ${lighten(0.2, '#007acc')};

    &:hover {
      background: ${lighten(0.2, '#007acc')};
    }
  }
`;

export const Title = styled(DialogTitle)`
  background: #007acc;

  color: #efefef;
  font-family: 'Roboto Slab', serif;
  h2,
  h4 {
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 24px;
  }
  h4 {
  }
`;

export const ContentText = styled(DialogContentText)``;

export const Actions = styled(DialogActions)`
  background: #e6e6e6;
  button {
    font-size: 16px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;

    font-family: 'Roboto Slab', serif;

    transition: background-color 0.35s ease, color 0.35s ease;
  }
`;

export const Cancel = styled.button`
  border: 2px solid #c53030;
  background: #c53030;
  color: #efefef;

  &:hover,
  &:focus {
    background-color: ${shade(0.3, '#c53030')};
    color: #fff;
  }
`;

export const Confirm = styled.button`
  border: 2px solid #00802b;
  background: #00802b;
  color: #efefef;

  &:hover,
  &:focus {
    background-color: ${shade(0.3, '#00802b')};
    color: #fff;
  }

  &:disabled {
    border: 2px solid #a6a6a6;
    background-color: #d6d6d6;
    color: #565656;
    opacity: 0.75;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;

  span {
    padding: 0;
    margin: 0;

    > p {
      color: #8a0002;
      font-size: 16px;
      font-weight: 500;
    }

    & + span {
      margin: 10px 0 0 0;
    }
  }
`;
