import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 400px;

  padding: 10px 5px;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.35s;

  &:hover {
    border-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  > div {
    display: flex;
    width: 350px;

    > span {
      display: flex;
      flex-direction: column;

      align-items: flex-start;
      width: 100%;

      p {
        width: 100%;
        text-align: left;
        color: #8a0002;
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        font-weight: 500;

        margin: 10px 0 0 0;
      }

      strong {
        font-size: 16px;
        color: #767676;
      }

      & + span {
        margin: 0 0 0 10px;
      }
    }
  }
`;
