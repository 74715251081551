import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  AlteredContent,
  FloatInput,
  CoordProps,
  ResetFilterButton,
  Header,
  ComboProps,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';
import { removeAcento } from 'utils/specialChars';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FaCheckCircle, FaPencilAlt, FaTimesCircle } from 'react-icons/fa';
import { List, ListItem, StyledP, StyledStatus } from './styles';

export interface ListProps {
  code: string;
  anbc: string;
  anbdesc: string;
  name: string;
  address: string;
  district: string;
  city: string;
  uf: string;
  ufdesc: string;
  cep: string;
  phone: string;
  status: string;
}

const LocalServ: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { checkCredentials, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [initialNAC, setInitialNAC] = useState(user.anbc);

  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [zons, setZons] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [coord, setCoord] = useState<CoordProps>({} as CoordProps);

  const getList = useCallback(async () => {
    setCoord({ cod: user.anbc, desc: user.anbdesc });

    const response = await api.get(
      `/sgo/localserv_list.php?data=${JSON.stringify({ init: true })}`,
    );

    setList(response.data);
    setRawList(response.data);
    setLoading(false);
  }, [user.anbc, user.anbdesc]);

  const getAdditionalList = useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const response = await api.get<ListProps[]>(
          `/sgo/localserv_list.php?data=${JSON.stringify({ coord: value })}`,
        );

        setCoord({
          cod: response.data[0].anbc,
          desc: response.data[0].anbdesc,
        });
        setRawList(response.data);
        setList(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorHandling(err);
      }
    },
    [errorHandling],
  );

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setZons(response.data);
    setComboZons(response.data);
  }, []);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value } = select.options[select.selectedIndex];
    setInitialNAC('Selecione');
    setComboNacs(
      nacs.filter(
        (item: ComboProps) => item.value.substr(0, 2) === value.substr(0, 2),
      ),
    );
  }, [nacs]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value } = select.options[select.selectedIndex];

    getAdditionalList(value);
  }, [getAdditionalList]);

  useEffect(() => {
    checkCredentials();
    getList();
    getComboZON();
    getComboANB();
  }, [getComboZON, getComboANB, getList, checkCredentials]);

  const handleSearch = useCallback(
    (e: string) => {
      setSearchValue(e);

      setList(
        rawList.filter(
          (item: ListProps) =>
            removeAcento(item.city)
              .toLowerCase()
              .includes(removeAcento(e).toLowerCase()) ||
            removeAcento(item.name)
              .toLowerCase()
              .includes(removeAcento(e).toLowerCase()),
        ),
      );
    },
    [rawList],
  );

  const handleRemoveFilter = useCallback(() => {
    getList();
    setInitialNAC(user.anbc);

    setComboZons(zons.map((item) => item));
    setComboNacs(
      nacs.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );

    const selectZON = formRef.current?.getFieldRef('comboZON');
    const selectANB = formRef.current?.getFieldRef('comboANB');

    const indexNacs = nacs
      .filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      )
      .findIndex((item) => item.value === user.anbc);

    const indexZons = zons.findIndex((item) => item.value === user.zoncod);

    selectANB.options[indexNacs].selected = true;
    selectZON.options[indexZons].selected = true;
  }, [getList, nacs, user.anbc, user.zoncod, zons]);

  const handleStatusChange = useCallback(async (ev) => {
    const { code } = ev.currentTarget.dataset;

    const send = new FormData();
    send.append(
      'data',
      JSON.stringify({ code, status: ev.currentTarget.checked ? 'A' : 'I' }),
    );
    await api
      .post('/sgo/localserv_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        setList((state) => {
          const temp = [...state];
          const index = temp.findIndex((item) => item.code === code);
          temp[index] = {
            ...temp[index],
            status: temp[index].status === 'I' ? 'A' : 'I',
          };

          return [...temp];
        });
        setRawList((state) => {
          const temp = [...state];
          const index = temp.findIndex((item) => item.code === code);
          temp[index] = {
            ...temp[index],
            status: temp[index].status === 'I' ? 'A' : 'I',
          };

          return [...temp];
        });
      });
  }, []);

  return (
    <Container>
      <Loading isLoading={loading} />
      {['INT', 'ZON', 'GUI'].indexOf(user.perfil) < 0 && <IncludeButton />}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['INT', 'ZON'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              <ResetFilterButton
                onClick={handleRemoveFilter}
                type="button"
                shouldAppear={user.anbc !== coord.cod}
              >
                Remover seleção
              </ResetFilterButton>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={user.zoncod}
                  />
                </span>
              )}
              <span>
                <p>Filtre por Nacional:</p>
                <SelectV2
                  name="comboANB"
                  content={comboNacs}
                  onChange={handleANBselect}
                  initial={initialNAC}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Header>Locais de Serviço/Atividade - {coord.desc}</Header>
      <FloatInput amount="100px">
        <input
          placeholder="Filtre por cidade ou local de serviço/atividade"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="231px">
        <List>
          {list.map((item: ListProps, index) => (
            <ListItem key={item.code}>
              {(index === 0 || item.city !== list[index - 1].city) && (
                <h2>
                  {item.city}&nbsp;({item.uf})
                </h2>
              )}
              <main>
                <StyledP>
                  <p>
                    Cód: <strong>{item.code}</strong>
                  </p>
                </StyledP>
                <h3>{item.name}</h3>
                <p>
                  Endereço: <strong>{item.address || 'Não informado'}</strong>
                </p>
                <p>
                  Bairro: <strong>{item.district || 'Não informado'}</strong>
                </p>
                <p>
                  Telefone: <strong>{item.phone || 'Não informado'}</strong>
                </p>
                <StyledStatus>
                  {/* Local de Serviço ativo:&nbsp;
                  <input
                    type="checkbox"
                    name={item.code}
                    id={item.code}
                    defaultChecked={item.status === 'A'}
                    data-code={item.code}
                    onClick={handleStatusChange}
                  /> */}

                  <label htmlFor={item.code}>
                    <input
                      type="checkbox"
                      name={item.code}
                      id={item.code}
                      defaultChecked={item.status === 'A'}
                      data-code={item.code}
                      onClick={handleStatusChange}
                      disabled={['INT', 'ZON', 'GUI'].indexOf(user.perfil) > -1}
                    />
                    <span>
                      Local de Serviço&nbsp;
                      {item.status === 'A' ? (
                        <strong style={{ color: '#2E7D32' }}>
                          Ativo <FaCheckCircle />
                        </strong>
                      ) : (
                        <strong style={{ color: '#B71C1C' }}>
                          Inativo <FaTimesCircle />
                        </strong>
                      )}
                    </span>
                  </label>
                </StyledStatus>
                {['INT', 'GUI'].indexOf(user.perfil) < 0 &&
                item.anbc.substr(0, 2) === user.zoncod.substr(0, 2) ? (
                  <Link
                    to={{
                      pathname: `${pathname}/update`,
                      state: { localserv: item },
                    }}
                  >
                    <FaPencilAlt />
                  </Link>
                ) : null}
              </main>
            </ListItem>
          ))}
        </List>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default LocalServ;
