import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import SelectV2 from 'components/SelectV2';
import Loading from 'components/Loading';
import Button from 'components/Button';

import {
  ComboLocalProps,
  ComboProps,
  Container,
  Content,
} from 'styles/sgo_wrappers';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useSpring } from 'react-spring';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import { Attention, Block, Warn, Wrapper } from './styles';

interface ComboGuias {
  gseq: string;
  name: string;
  local: string;
  ce: number;
  workshop: number;
  service: number;
  material: number;
}

interface WarnProps {
  name: string;
  ce: number;
  workshop: number;
  service: number;
  material: number;
}

interface FormData {
  anbo: string;
  loco: string;
  guia: string;
  anbd: string;
  locd: string;
}

const TransfGuiaInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { goBack } = useHistory();
  const { user } = useAuth();
  const { handlePermission, errorHandling } = useCredentials();

  const [initialGuia, setInitialGuia] = useState('Selecione');
  const [guias, setGuias] = useState<ComboGuias[]>([]);
  const [comboGuias, setComboGuias] = useState<ComboGuias[]>([]);

  const [initialOLoc] = useState(user.loccod);
  const [initialDLoc, setInitialDLoc] = useState('Selecione');

  const [comboOLocs, setComboOLocs] = useState<ComboProps[]>([]);
  const [dLocs, setDLocs] = useState<ComboLocalProps[]>([]);
  const [comboDLocs, setComboDLocs] = useState<ComboProps[]>([]);

  const [destiny, setDestiny] = useState('');

  const [initialDNac] = useState(user.anbc);
  const [comboDNacs, setComboDNacs] = useState<ComboProps[]>([]);

  const [warn, setWarn] = useState<WarnProps>({} as WarnProps);

  const getComboANB = useCallback(async () => {
    const response = await api.get(
      `/combos/comboANBs.php?data=${JSON.stringify('content')}`,
    );
    setComboDNacs(response.data);
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(`/combos/comboLOCs.php`);
    setDLocs(response.data);

    const filtered = response.data.filter(
      (item: ComboLocalProps) => item.anb === user.anbc,
    );
    setComboOLocs(filtered);
    setComboDLocs(
      filtered.filter((item: ComboLocalProps) => item.value !== user.loccod),
    );
  }, [user.anbc, user.loccod]);

  const getGuias = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/combos/comboTransfGuias.php');
      setGuias(response.data);
      setComboGuias(
        response.data.filter((item: ComboGuias) => item.local === user.loccod),
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, user.loccod]);

  useEffect(() => {
    handlePermission(['LOC', 'NAC'], true);
    getComboANB();
    getComboLOC();
    getGuias();
  }, [getComboANB, getComboLOC, getGuias, handlePermission]);

  const handleLOCOselection = useCallback(() => {
    const loc = formRef.current?.getFieldValue('loco');
    // const locD = formRef.current?.getFieldValue('locd');
    setComboGuias(guias.filter((item) => item.local === loc));

    // if (locD === loc) {
    formRef.current?.setFieldValue('locd', '');
    const anb = formRef.current?.getFieldValue('anbd');
    setComboDLocs(
      dLocs.filter(
        (item: ComboLocalProps) =>
          item.anb ===
            (anb.length > 4
              ? comboDNacs[
                  comboDNacs.findIndex((anbD) => anbD.label.includes(anb))
                ].value
              : anb) && item.value !== loc,
      ),
    );
    setInitialDLoc('Selecione');
    // }
    formRef.current?.setFieldValue('guia', '');
    setInitialGuia('Selecione');
    setDestiny('');
    setWarn({} as WarnProps);
  }, [comboDNacs, dLocs, guias]);

  const handleGuiaSelection = useCallback(() => {
    const guia = formRef.current?.getFieldValue('guia');
    const {
      name,
      ce,
      workshop,
      service,
      gseq,
      material,
    }: ComboGuias = comboGuias[
      comboGuias.findIndex((item) => item.gseq === guia)
    ];
    setInitialGuia(gseq);
    if (ce > 0 || workshop > 0 || service > 0) {
      setWarn({ name, ce, workshop, service, material });
      return;
    }

    setWarn({} as WarnProps);
  }, [comboGuias]);

  const handleNACDselection = useCallback(() => {
    const anb = formRef.current?.getFieldValue('anbd');
    const loc =
      user.perfil === 'NAC'
        ? dLocs[
            dLocs.findIndex((item) =>
              formRef.current?.getFieldValue('loco').length > 4
                ? item.label === formRef.current?.getFieldValue('loco')
                : item.value === formRef.current?.getFieldValue('loco'),
            )
          ].value
        : user.loccod;

    setComboDLocs(
      dLocs.filter(
        (item: ComboLocalProps) => item.anb === anb && item.value !== loc,
      ),
    );
    setDestiny('');
  }, [dLocs, user.loccod, user.perfil]);

  const handleLOCDSelection = useCallback(() => {
    setDestiny(formRef.current?.getFieldValue('locd'));
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          loco: yup.string().notOneOf(['Selecione', '']),
          guia: yup.string().notOneOf(['Selecione', '']),
          anbd: yup.string().notOneOf(['Selecione', '']),
          locd: yup.string().notOneOf(['Selecione', '']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            ...data,
            anbo: user.anbc,
            loco: user.perfil === 'LOC' ? user.loccod : data.loco,
          }),
        );

        await api.post('/sgo/transfguia_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false);
        goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, goBack, user.anbc, user.loccod, user.perfil],
  );

  const hasWarn = useSpring({
    opacity: Object.keys(warn).length > 0 ? 1 : 0,
    height: Object.keys(warn).length > 0 ? '200px' : '0px',
    overflow: 'hidden',
  });

  const noWarn = useSpring({
    pointerEvents:
      Object.keys(warn).length === 0 &&
      ['Selecione', ''].indexOf(formRef.current?.getFieldValue('guia')) === -1
        ? 'all'
        : 'none',
    opacity:
      Object.keys(warn).length === 0 &&
      ['Selecione', ''].indexOf(formRef.current?.getFieldValue('guia')) === -1
        ? 1
        : 0,
    height:
      Object.keys(warn).length === 0 &&
      ['Selecione', ''].indexOf(formRef.current?.getFieldValue('guia')) === -1
        ? '150px'
        : '0px',

    margin:
      Object.keys(warn).length === 0 &&
      ['Selecione', ''].indexOf(formRef.current?.getFieldValue('guia')) === -1
        ? '50px 0px 0px 0px'
        : '0px 0px 0px 0px',
  });

  const buttonAppear = useSpring({
    opacity: destiny !== '' ? 1 : 0,
    pointerEvents: destiny !== '' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks title="Nova Transferência" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <Block>
              <Attention>
                <FaExclamationTriangle />
                <strong>
                  Apenas os guias sem função podem ser transferidos.
                </strong>
              </Attention>
              {user.perfil === 'NAC' && (
                <span>
                  <p>Local de Origem:</p>
                  <SelectV2
                    name="loco"
                    content={comboOLocs}
                    onChange={handleLOCOselection}
                    initial={initialOLoc}
                  />
                </span>
              )}

              <span>
                <p>Guia:</p>
                <SelectV2
                  name="guia"
                  content={comboGuias.map((item) => ({
                    value: item.gseq,
                    label: item.name,
                  }))}
                  onChange={handleGuiaSelection}
                  initial={initialGuia}
                />
              </span>
            </Block>
            <Warn style={hasWarn}>
              <h4>Há pendências que impossibilitam a transferência:</h4>
              {!!warn.ce && warn.ce > 0 && (
                <p>
                  <strong>{`0${warn.ce}`.slice(-2)}</strong>&nbsp;Controle de
                  Entrega{warn.ce > 1 ? 's' : ''} em aberto;
                </p>
              )}
              {!!warn.workshop && warn.workshop > 0 && (
                <p>
                  <strong>{`0${warn.workshop}`.slice(-2)}</strong>&nbsp;Oficina
                  {warn.workshop > 1 ? 's' : ''} em curso;
                </p>
              )}
              {!!warn.service && warn.service > 0 && (
                <p>
                  <strong>{`0${warn.service}`.slice(-2)}</strong>&nbsp;Atividade
                  {warn.service > 1 ? 's' : ''} em curso;
                </p>
              )}
              {!!warn.material && warn.material > 0 && (
                <p>
                  <strong>{`0${warn.material}`.slice(-2)}</strong>&nbsp;Materia
                  {warn.material > 1 ? 'is' : 'l'} controlado
                  {warn.material > 1 ? 's' : ''} em posse;
                </p>
              )}
            </Warn>
            <Block style={noWarn}>
              <span>
                <p>Nacional de Destino:</p>
                <SelectV2
                  name="anbd"
                  content={comboDNacs}
                  initial={initialDNac}
                  onChange={handleNACDselection}
                />
              </span>
              <span>
                <p>Local de Destino:</p>
                <SelectV2
                  name="locd"
                  content={comboDLocs}
                  initial={initialDLoc}
                  onChange={handleLOCDSelection}
                />
              </span>
            </Block>
          </Wrapper>
          <Button
            style={buttonAppear}
            type="submit"
            bgcolor="#00802b"
            containerStyle={{
              boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
            }}
          >
            Solicitar Transferência
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default TransfGuiaInsert;
