import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import { convertSpecialChars } from 'utils/specialChars';
import { Container, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import getValidationErrors from 'utils/getValidationErrors';
import { Content, GridContainer, AnimatedSection } from './styles';

interface FormData {
  address: string;
  cep: string;
  city: string;
  code: string;
  compl: string;
  core: string;
  desc: string;
  district: string;
  number: string;
  state: string;
}

const LocaisInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);
  // const [, setRadioValue] = useState(' ');

  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);
  const [hasState, setHasState] = useState(false);
  const [exceeded, setExceeded] = useState(false);

  const [usedLocs, setUsedLocs] = useState<string[]>([]);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php?trans=locIns');
    const { ufs, locs } = response.data;

    setComboEstados(ufs);
    setUsedLocs(locs);
  }, []);

  useEffect(() => {
    getUFs();
  }, [getUFs]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setFirstUF('Selecione');
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleCode = useCallback(async () => {
    const codeInput: string = formRef.current?.getFieldValue('code');

    if (codeInput.length === 3) {
      setExceeded(true);

      const locExists = usedLocs.indexOf(codeInput.toUpperCase());

      if (locExists > -1) {
        formRef.current?.setFieldValue('code', '');
        formRef.current?.setFieldError(
          'code',
          'Já existe uma local com este código',
        );
        setExceeded(false);
        return;
      }
    }
    formRef.current?.setFieldValue(
      'code',
      codeInput.toUpperCase().replace(/[^a-z0-9]/gi, ''),
    );
  }, [usedLocs]);

  // const handleRadioClick = useCallback(() => {
  //   setRadioValue(formRef.current?.getFieldValue('core'));
  // }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const coreVal = formRef.current?.getFieldValue('core');

        const schema = yup.object().shape({
          code: yup
            .string()
            .trim()
            .min(3, 'O código da local deve conter 3 dígitos.'),
          desc: yup
            .string()
            .trim()
            .min(4, 'O nome da coordenação deve conter ao menos 4 caracteres'),
          // core: yup.string(),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup.string().trim().required('Informação obrigatória'),
          number: yup.string().nullable(true),
          state: yup.string().notOneOf(['Selecione'], ' '),
          compl: yup.lazy((val) =>
            !val
              ? yup.string().nullable(true)
              : yup
                  .string()
                  .trim()
                  .min(
                    4,
                    'O complemento deve ser branco ou conter, no mínimo, 4 caracteres.',
                  ),
          ),
          district: yup.string().trim().required('Informação obrigatória'),
          city: yup.string().trim().required('Informação obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          desc: convertSpecialChars(data.desc),
          number: !data.number ? 'S/N' : data.number,
          compl: data.compl || convertSpecialChars(data.compl),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          core: coreVal,
        };

        await api.get(`/sgo/locais_insert.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Local ${data.desc} incluída!`,
        });

        history.replace(location.pathname.replace('/insert', ''));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, addToast, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Cadastro de Local" />
      <Content>
        <Form onSubmit={handleSubmit} initialData={{}} ref={formRef}>
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Código da Local</p>
                <Input
                  name="code"
                  placeholder="Código de 3 dígitos"
                  onChange={handleCode}
                  disabled={exceeded}
                  // style={{ textTransform: 'uppercase' }}
                />
              </span>
              <span>
                <p>Descrição</p>
                <Input name="desc" placeholder="Nome da coordenação" />
              </span>
              {/* <span>
                <RadioContainer
                  title="Núcleo?"
                  name="core"
                  selected={radioValue}
                  onClick={handleRadioClick}
                  content={[
                    {
                      id: 'aS',
                      value: 'N',
                      label: 'Sim',
                    },
                    {
                      id: 'aN',
                      value: ' ',
                      label: 'Não',
                    },
                  ]}
                />
              </span> */}
            </AnimatedSection>

            <AnimatedSection delay={0.35}>
              <h3 style={{ color: '#8a0002', fontSize: '18.72px' }}>
                Endereço de Entrega
              </h3>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>
              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                  containerStyle={{ borderColor: '#a6a6a6' }}
                />
              </span>
              <span>
                <p>Endereço</p>
                <Input
                  name="address"
                  placeholder="Endereço"
                  disabled={!!hasAddress}
                />
              </span>
              <span>
                <p>Número</p>
                <Input name="number" placeholder="S/N" mask="number" />
              </span>
              <span>
                <p>Complemento</p>
                <Input name="compl" placeholder="Complemento" />
              </span>
              <span>
                <p>Bairro</p>
                <Input
                  name="district"
                  placeholder="Bairro"
                  disabled={!!hasDistrict}
                />
              </span>
              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" disabled={!!hasCity} />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Cadastrar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default LocaisInsert;
