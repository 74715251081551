import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { parseISO, subDays } from 'date-fns/esm';

import { useWindow } from 'hooks/window';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';

import { formatNumber } from 'utils/calcTotal';
import { endOfDay, formatDate, minimumAllowedDate } from 'utils/formatDate';
import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import { FaMobileAlt, FaQuestionCircle } from 'react-icons/fa';
import Button from 'components/Button';

import Input from 'components/Input';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { useToast } from 'hooks/toast';
import { useSpring } from 'react-spring';
import {
  InfoTooltip,
  FinContent,
  Wrapper,
  FinContentHeader,
  FinItem,
  FinContentTotal,
  Desc,
  Qtde,
  Valor,
  Pago,
  Devolvido,
  Pendente,
  Less,
  More,
  RotateMessage,
  DataContainer,
} from './styles';
import { FinHeader } from '../detail/styles';

interface StateProps {
  options: {
    num: number;
    cod: number;
    desc: string;
    valor: number;
    date: string;
  };
}

interface FinHeaderProps {
  num: number;
  cod: number;
  desc: string;
  valor: number;
  date: string;
}

interface ItemsProps {
  cod: string;
  desc: string;
  qte: number;
  vlr: number;
  prev: number;
  pag: number;
  dev: number;
  pend: number;
  date: string;
}

interface FormData {
  date: string;
  prevDate: string;
}

const FinCEInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation<StateProps>();
  const { errorHandling, handlePermission } = useCredentials();
  const { width } = useWindow();
  const { addToast } = useToast();
  const [finHeader] = useState<FinHeaderProps>(() => {
    if (location.state) {
      return {
        num: location.state.options.num,
        cod: location.state.options.cod,
        desc: location.state.options.desc,
        valor: location.state.options.valor,
        date: location.state.options.date,
      } as FinHeaderProps;
    }

    return {} as FinHeaderProps;
  });

  const [items, setItems] = useState<ItemsProps[]>([]);
  const [initial, setInitial] = useState<ItemsProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const data = {
        ce: finHeader.num,
        gseq: finHeader.cod,
      };
      const response = await api.get(
        `sgo/fin_ce_items.php?data=${JSON.stringify(data)}`,
      );

      setItems(
        response.data.map((item: ItemsProps) => ({
          ...item,
          pag: 0,
          dev: 0,
        })),
      );

      setInitial(
        response.data.map((item: ItemsProps) => ({
          ...item,
          pag: 0,
          dev: 0,
        })),
      );

      const parsedPrev = new Date(
        parseISO(response.data[response.data.length - 1].date),
      );
      const parsedDate = new Date(parseISO(new Date().toISOString()));

      formRef.current?.setData({
        prevDate: `${parsedPrev.getFullYear()}-${(parsedPrev.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${parsedPrev
          .getDate()
          .toString()
          .padStart(2, '0')}`,
        date: `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${parsedDate
          .getDate()
          .toString()
          .padStart(2, '0')}`,
      });

      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, finHeader.cod, finHeader.num]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname.replace('/detail/insert', ''));
      return;
    }
    handlePermission(['LOC'], true);

    getList();
  }, [
    finHeader,
    getList,
    handlePermission,
    history,
    location.pathname,
    location.state,
  ]);

  const handleAdd = useCallback(
    (material: string, mode: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.cod === material,
      );

      const { qte, prev, pag, dev } = items[index];

      if (prev + pag + dev === qte) {
        return;
      }

      if (mode === 'pag') {
        items[index].pag += 1;
      } else {
        items[index].dev += 1;
      }

      items[index].pend -= 1;

      setItems((state) => [...state]);
    },
    [items],
  );

  const handleSub = useCallback(
    (material: string, mode: string) => {
      const index = items.findIndex(
        (item: ItemsProps) => item.cod === material,
      );

      const { pag, dev } = items[index];

      if (mode === 'pag') {
        if (pag === 0) {
          return;
        }
        items[index].pag -= 1;
      } else {
        if (dev === 0) {
          return;
        }
        items[index].dev -= 1;
      }

      items[index].pend += 1;

      setItems((state) => [...state]);
    },
    [items],
  );

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, mat: string, mode: string) => {
      const index = items.findIndex((item: ItemsProps) => item.cod === mat);
      const number = e.currentTarget.value.replace(/^0/g, '');
      const asNum = parseInt(number, 10);

      const { qte, prev, pag, dev } = items[index];

      if (number.length === 0) {
        if (mode === 'pag') {
          items[index].pag = 0;
        } else {
          items[index].dev = 0;
        }
      } else if (asNum >= qte - prev - pag - dev) {
        if (mode === 'pag') {
          items[index].pag = qte - prev - dev;
        } else {
          items[index].dev = qte - prev - pag;
        }
      } else if (mode === 'pag') {
        items[index].pag = asNum;
      } else {
        items[index].dev = asNum;
      }

      items[index].pend =
        items[index].qte -
        items[index].prev -
        items[index].pag -
        items[index].dev;

      setItems((state) => [...state]);
    },
    [items],
  );

  const totalPago = useMemo(() => {
    return items.reduce((accum, { vlr, pag }: ItemsProps) => {
      return accum + vlr * pag;
    }, 0);
  }, [items]);

  const totalDevolvido = useMemo(() => {
    return items.reduce((accum, { vlr, dev }: ItemsProps) => {
      return accum + vlr * dev;
    }, 0);
  }, [items]);

  const totalPendente = useMemo(() => {
    return items.reduce((accum, { vlr, pend }: ItemsProps) => {
      return accum + vlr * pend;
    }, 0);
  }, [items]);

  const totalPrev = useMemo(() => {
    return initial.reduce((accum, { vlr, pend }: ItemsProps) => {
      return accum + vlr * pend;
    }, 0);
  }, [initial]);

  const buttonStyle = useSpring({
    opacity: totalPago + totalDevolvido === 0 ? 0.25 : 1,
    pointerEvents: totalPago + totalDevolvido === 0 ? 'none' : 'all',
  });

  const handleFinish = useCallback(
    async (data: FormData) => {
      // const parsedMinDate = new Date(parseISO(data.prevDate));

      const parsedMinDate = minimumAllowedDate(data.prevDate, true);
      // if (
      //   parsedMinDate.getUTCDate() ===
      //   handleTimeZone(data.prevDate).getUTCDate()
      // ) {
      //   // parsedMinDate = addDays(parsedMinDate, 1);
      // }

      // const parsedMaxDate = new Date(parseISO(new Date().toISOString()));
      const parsedMaxDate = endOfDay(new Date());

      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .min(
              parsedMinDate,
              `O lançamento deve ser posterior a ${formatDate(
                subDays(parsedMinDate, 1).toISOString(),
              )}`,
            )
            .max(
              parsedMaxDate,
              `Data limite excedida (${formatDate(
                new Date(parsedMaxDate).toISOString(),
              )})`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        const newData = {
          ...data,
          ce: finHeader.num,
          name: finHeader.desc,
          gseq: finHeader.cod,
          items,
          prev: totalPrev,
          tp: totalPago,
          td: totalDevolvido,
          tr: totalPendente,
        };

        send.append('data', JSON.stringify(newData));

        await api.post('/sgo/fin_ce_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro inserido.',
        });

        history.goBack();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [
      addToast,
      errorHandling,
      finHeader.cod,
      finHeader.desc,
      finHeader.num,
      history,
      items,
      totalDevolvido,
      totalPago,
      totalPendente,
      totalPrev,
    ],
  );

  return (
    <Container>
      <ScrollTop />
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Acerto Financeiro CE ${finHeader.num || ''}`}
      />
      <Content>
        <FinHeader>
          <span>
            <p>
              Guia:&nbsp;<strong>{finHeader.desc}</strong>
            </p>
          </span>
          <span>
            <p>
              Valor:&nbsp;
              <strong>R$ {formatNumber(finHeader.valor)}</strong>
            </p>
          </span>
          <span>
            <p>
              Data:&nbsp;
              <strong>{formatDate(finHeader.date)}</strong>
            </p>
          </span>
        </FinHeader>

        <RotateMessage>
          <span>
            <p>Para mais informações dos lançamentos, vire seu aparelho</p>
          </span>
          <span>
            <FaMobileAlt />
          </span>
        </RotateMessage>
        <Form ref={formRef} onSubmit={handleFinish}>
          <DataContainer>
            <p>Data do Pagamento</p>
            <Input name="prevDate" type="date" isHidden />
            <Input name="date" type="date" />
          </DataContainer>
          <FinContent>
            <Wrapper>
              <FinContentHeader>
                <Desc>{width <= 930 ? 'Desc.' : 'Descrição'}</Desc>
                <Qtde>{width <= 930 ? 'Qtde.' : 'Quantidade'}</Qtde>
                <Valor>Valor</Valor>
                <Pago>Pago</Pago>
                <Devolvido>Devolvido</Devolvido>
                <Pendente>{width <= 930 ? 'Pend.' : 'Pendente'}</Pendente>
              </FinContentHeader>

              {items.map((item, index) => (
                <FinItem paint={index} key={item.cod}>
                  <Desc>
                    {width <= 400 ? (
                      <>
                        {item.cod}
                        <InfoTooltip title={item.desc}>
                          <FaQuestionCircle />
                        </InfoTooltip>
                      </>
                    ) : (
                      item.desc
                    )}
                  </Desc>
                  <Qtde>{item.qte}</Qtde>
                  <Valor>R$&nbsp;{formatNumber(item.vlr)}</Valor>
                  <Pago>
                    <button
                      type="button"
                      onClick={() => handleSub(item.cod, 'pag')}
                    >
                      <Less />
                    </button>
                    <input
                      type="number"
                      step="1"
                      value={item.pag}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleInputChange(e, item.cod, 'pag')
                      }
                    />
                    <button
                      type="button"
                      onClick={() => handleAdd(item.cod, 'pag')}
                    >
                      <More />
                    </button>
                  </Pago>
                  <Devolvido>
                    <button
                      type="button"
                      onClick={() => handleSub(item.cod, 'dev')}
                    >
                      <Less />
                    </button>
                    <input
                      type="number"
                      step="1"
                      value={item.dev}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleInputChange(e, item.cod, 'dev')
                      }
                    />
                    <button type="button">
                      <More onClick={() => handleAdd(item.cod, 'dev')} />
                    </button>
                  </Devolvido>
                  <Pendente>{item.pend}</Pendente>
                </FinItem>
              ))}
              <FinContentTotal>
                <Desc>&nbsp;</Desc>
                <Qtde>&nbsp;</Qtde>
                <Valor>&nbsp;</Valor>
                <Pago>R$&nbsp;{formatNumber(totalPago)}</Pago>
                <Devolvido>R$&nbsp;{formatNumber(totalDevolvido)}</Devolvido>
                <Pendente>R$&nbsp;{formatNumber(totalPendente)}</Pendente>
              </FinContentTotal>
            </Wrapper>
          </FinContent>
          <Button
            bgcolor="#00802b"
            type="submit"
            style={buttonStyle}
            containerStyle={{ margin: '10px 0px' }}
          >
            Finalizar
          </Button>
        </Form>
        {/* <div style={{ marginTop: '50px' }}>{JSON.stringify(items)}</div> */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FinCEInsert;
