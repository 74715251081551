import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import getValidationErrors from 'utils/getValidationErrors';
import { convertSpecialChars } from 'utils/specialChars';
import * as yup from 'yup';
import { ListProps } from '../main';
import { Content, GridContainer, AnimatedSection } from '../insert/styles';

interface FormData {
  name: string;
  cep: string;
  state: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  phone: string;
}

interface StateProps {
  localserv: ListProps;
}

const LocalServUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasState, setHasState] = useState(false);
  const [localServ, setLocalServ] = useState<ListProps>({} as ListProps);

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const getUFs = useCallback(async () => {
    const response = await api.get('/combos/comboUFs.php');
    response.data.filter((state: ComboProps) => {
      if (state.value === localServ?.uf) {
        formRef.current?.setData({
          ...formRef.current,
          state: state.value,
        });
        setHasState(true);
        setFirstUF(state.value);
      }

      return state.value;
    });

    setComboEstados(response.data);
  }, [localServ]);

  useEffect(() => {
    handlePermission(['INT', 'GUI']);

    if (!location.state) {
      history.replace(location.pathname.replace('/update', ''));
      return;
    }
    getUFs();

    setLocalServ(location.state.localserv);
  }, [handlePermission, getUFs, history, location.pathname, location.state]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        form.setData({
          address: localServ?.address.includes(address)
            ? localServ?.address
            : address,
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setHasState(false);
      }
    }
  }, [comboEstados, getCEP, localServ]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          cep: yup.string().trim().min(9, 'O CEP deve conter 9 caracteres.'),
          state: yup.string().notOneOf([''], ' '),
          address: yup
            .string()
            .trim()
            .min(10, 'Quantidade mínima de caracteres não atingida (10).')
            .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          district: yup
            .string()
            .trim()
            .min(2, 'Quantidade mínima de caracteres não atingida (2).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).')
            .max(40, 'Quantidade máxima de caracteres excedida (40).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          code: localServ?.code,
          address: convertSpecialChars(data.address),
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
        };

        await api.get(`/sgo/localserv_update.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Local de Serviço/Atividade alterado!',
        });

        history.replace(location.pathname.replace('/update', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, history, location.pathname, addToast, localServ],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Alteração Local de Serviço/Atividade" />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: localServ?.name,
            cep: localServ?.cep,
            state: localServ?.uf,
            address: localServ?.address,
            district: localServ?.district,
            city: localServ?.city,
            phone: localServ?.phone,
          }}
        >
          <GridContainer>
            <AnimatedSection duration={1.1}>
              <span>
                <p>Nome do Local de Serviço/Atividade</p>
                <Input
                  name="name"
                  placeholder="Nome do Local de Serviço/Atividade"
                  mask="name"
                />
              </span>
              <span>
                <p>CEP</p>
                <Input
                  name="cep"
                  placeholder="N° CEP"
                  mask="cep"
                  onChange={handleCep}
                />
              </span>

              <span>
                <p>Estado</p>
                <SelectV2
                  name="state"
                  initial={firstUF}
                  content={comboEstados}
                  disabled={!!hasState}
                  dropdownStyle={{ maxHeight: '130px', borderColor: '#a6a6a6' }}
                />
              </span>

              <span>
                <p>Endereço</p>
                <Textarea name="address" placeholder="Endereço" />
              </span>

              <span>
                <p>Bairro</p>
                <Input name="district" placeholder="Bairro" />
              </span>

              <span>
                <p>Cidade</p>
                <Input name="city" placeholder="Cidade" />
              </span>
              <span>
                <p>Telefone para contato</p>
                <Input
                  name="phone"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="phone"
                />
              </span>
            </AnimatedSection>
          </GridContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default LocalServUpdate;
