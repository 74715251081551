import styled from 'styled-components';

import Button from 'components/Button';
import { lighten } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  padding: 5px 20px;

  div {
    > p {
      font-size: 16px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      color: #8a0002;
    }
    & + div {
      margin: 15px 0 0 0;
    }
  }
`;

export const PrintButton = styled(Button)`
  position: relative;
  margin: 15px auto 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 260px;
  padding: 10px 0px;
  background: ${lighten(0.2, '#332e2e')};
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  p {
    font-size: 16px;
    text-align: left;
  }

  transition: all 0.35s ease;
  svg {
    height: 25px;
    width: 25px;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;
