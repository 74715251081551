import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useLocation } from 'react-router-dom';

import api from 'services/api';

import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { formatDate, handleTimeZone } from 'utils/formatDate';

import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import moment from 'moment';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import {
  FaPencilAlt,
  FaTimes,
  FaUserSlash,
  FaBookReader,
} from 'react-icons/fa';
import IncludeButton from 'components/IncludeButton';

import {
  Grid,
  GridItem,
  YearContainer,
  LeftSVG,
  RightSVG,
  ProgressDiv,
  RelatedLocals,
  GridOptions,
} from './styles';

interface RelatedLocals {
  cod: string;
  desc: string;
}
export interface ListProps {
  seq: string;
  zon: string;
  zondesc: string;
  anb: string;
  anbdesc: string;
  loc: string;
  locdesc: string;
  coor: string;
  stat: boolean;
  ini: string;
  end: string;
  year: number;
  votes: number;
  voters: number;
  future: boolean;
  anbref: string;
  anbrefdesc: string;
  relatedlocals: RelatedLocals[];
  hasorig: boolean;
}

interface LocalStorageProps {
  year?: number;
}

export interface Returned {
  text: string;
  stat: boolean;
}

export const checkEnd = (date: string): boolean => {
  const today = new Date();

  const start = new Date(handleTimeZone(date).setHours(23, 59, 59));

  const formats = 'YYYY-mm-dd HH:mm:ss';

  const startAt = moment(start, formats);

  const base = moment(today, formats);

  // console.log('######################');
  // console.log('###### [checkEnd] ######');
  // console.log(`convocacao now: ${today}`);
  // console.log(`convocacao date: ${start}`);
  // console.log('###### [ NEXT ] ######');
  // console.log('######################');

  return base.isSameOrAfter(startAt);
};

export const checkStat = (ini: string, end: string): Returned => {
  const start = new Date(handleTimeZone(ini).setHours(0, 0, 0));

  const now = new Date();

  const formats = 'YYYY-mm-dd';

  const startAt = moment(start, formats);
  const base = moment(now, formats);

  return {
    text: checkEnd(end)
      ? 'Em apuração'
      : base.isSameOrAfter(startAt)
      ? 'Em votação'
      : 'Programada',
    stat: checkEnd(end),
  };
};

const Convocacao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { errorHandling, handlePermission } = useCredentials();
  const { user } = useAuth();
  const { pathname } = useLocation();

  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [years, setYears] = useState<{ year: number }[]>([]);

  const [currYear, setCurrYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_ELEICAO}`,
    );
    return year || new Date().getUTCFullYear();
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  /* const checkEnd = (date: string): boolean => {
    const convDate = new Date(parseISO(date));
    const today = new Date(parseISO(new Date().toISOString()));

    const start = new Date(
      convDate.getFullYear(),
      convDate.getMonth(),
      convDate.getDate(),
      23,
      59,
      59,
    );
    // const now = new Date(
    //   today.getUTCFullYear(),
    //   today.getUTCMonth(),
    //   today.getUTCDate(),
    //   0,
    //   0,
    // );

    const formats = 'YYYY-mm-dd';

    const startAt = moment(start, formats);
    const base = moment(today, formats);

    return base.isSameOrAfter(startAt);
  };

  const checkStat = (ini: string, end: string): Returned => {
    const convINIDate = new Date(parseISO(ini));
    const today = new Date(parseISO(new Date().toISOString()));

    const start = new Date(
      convINIDate.getUTCFullYear(),
      convINIDate.getUTCMonth(),
      convINIDate.getUTCDate(),
      0,
      0,
    );
    const now = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0,
      0,
    );

    const formats = 'YYYY-mm-dd';

    const startAt = moment(start, formats);
    const base = moment(now, formats);

    return {
      text: checkEnd(end)
        ? 'Em apuração'
        : base.isSameOrAfter(startAt)
        ? 'Em votação'
        : 'Programada',
      stat: checkEnd(end),
    };

    // return base.isAfter(finale);
  }; */

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/eleicao_conv_list.php');
      const { convs, convyears } = response.data;
      console.table(convs);

      const sortedConvs = convs.map((item: ListProps) => ({
        ...item,
        relatedlocals: item.relatedlocals.sort((a, b) =>
          a.desc > b.desc ? 1 : b.desc > a.desc ? -1 : 0,
        ),
      }));
      setRawList(sortedConvs);
      setList(sortedConvs.filter((item: ListProps) => item.year === currYear));
      console.log(convyears);
      setYears(convyears);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling]);

  useEffect(() => {
    handlePermission(['NAC', 'ZON', 'INT'], true);
    getList();
  }, [getList, handlePermission]);

  const handleDecreaseYear = useCallback(() => {
    setLocalStorage(`${process.env.REACT_APP_ELEICAO}`, {
      year: currYear - 1,
    });

    setList(rawList.filter((item: ListProps) => item.year === currYear - 1));

    setCurrYear((state) => state - 1);
  }, [currYear, rawList]);

  const handleIncreaseYear = useCallback(() => {
    setLocalStorage(`${process.env.REACT_APP_ELEICAO}`, {
      year: currYear + 1,
    });

    setList(rawList.filter((item: ListProps) => item.year === currYear + 1));
    setCurrYear((state) => state + 1);
  }, [currYear, rawList]);

  const handleExclusion = useCallback(async () => {
    try {
      const seq = deleteDiag.values?.seq;

      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      setLoading(true);

      const send = new FormData();
      send.append('data', JSON.stringify(deleteDiag.values));

      await api.post('/sgo/eleicao_conv_delete.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // setRawList((state) => state.filter((item) => item.seq !== seq));
      // setList((state) => state.filter((item) => item.seq !== seq));

      setRawList((state) => {
        const index = state.findIndex((item: ListProps) => item.seq === seq);

        const { anb } = state[index];

        return state
          .filter((item) => item.seq !== seq)
          .map((item) => ({
            ...item,
            hasorig: item.anbref === anb ? false : item.hasorig,
          }));
      });
      setList((state) => {
        const index = state.findIndex((item: ListProps) => item.seq === seq);

        const { anb } = state[index];

        return state
          .filter((item) => item.seq !== seq)
          .map((item) => ({
            ...item,
            hasorig: item.anbref === anb ? false : item.hasorig,
          }));
      });
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [deleteDiag.values, errorHandling]);

  return (
    <Container>
      <IncludeButton />
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Convocações</p>
          </div>
        </AlteredHeader>
        {!!rawList && rawList.length > 0 && (
          <YearContainer>
            {years.length > 0 && currYear > years[0].year && (
              <LeftSVG onClick={handleDecreaseYear} />
            )}
            <p>{currYear}</p>
            {years.length > 0 && currYear < years[years.length - 1].year && (
              <RightSVG onClick={handleIncreaseYear} />
            )}
          </YearContainer>
        )}

        <Grid>
          {list.map((item) => (
            <GridItem key={item.seq}>
              {user.adm && (
                <span>
                  <p>
                    Ref.: <strong>{item.seq}</strong>
                  </p>
                </span>
              )}
              {!!item.zon && user.perfil === 'INT' && (
                <span>
                  <p>
                    Coord. Zonal: <strong>{item.zondesc}</strong>
                  </p>
                </span>
              )}
              {(!!item.anb || item.future) && user.perfil === 'ZON' && (
                <span>
                  <p>
                    Coord.&nbsp;{item.future ? 'de Origem' : 'Nacional'}
                    :&nbsp;
                    <strong>
                      {item.future ? item.anbrefdesc : item.anbdesc}
                    </strong>
                  </p>
                </span>
              )}
              {item.future && user.perfil === 'ZON' && (
                <>
                  <span>
                    <p>Locais Relacionadas:</p>
                  </span>
                  {/* <span>{item.stringedRelatedLocals}</span> */}
                  <span>
                    <p style={{ color: '#332e2e' }}>
                      {item.relatedlocals.map(
                        (rl, index) =>
                          `${rl.desc}${
                            index < item.relatedlocals.length - 1 ? ', ' : ''
                          }`,
                      )}
                    </p>
                  </span>
                </>
              )}
              {!!item.loc && (
                <span>
                  <p>
                    Coord. Local: <strong>{item.locdesc}</strong>
                  </p>
                </span>
              )}
              <span>
                <p>
                  Início: <strong>{formatDate(item.ini)}</strong>
                </p>
                <p>
                  Término: <strong>{formatDate(item.end)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Situação:&nbsp;
                  <strong>
                    {item.stat
                      ? checkStat(item.ini, item.end).text
                      : 'Encerrada'}
                  </strong>
                </p>
              </span>
              <span />
              {checkStat(item.ini, item.end).text !== 'Programada' && (
                <ProgressDiv>
                  <span>
                    <p>
                      <strong>
                        {item.votes > item.voters ? item.voters : item.votes}
                        &nbsp; voto{item.votes > 1 ? 's' : ''}
                      </strong>
                      &nbsp;/&nbsp;
                      <strong>
                        {item.voters}&nbsp;possíve{item.voters > 1 ? 'is' : 'l'}
                      </strong>
                      &nbsp;(
                      {(item.votes > 0
                        ? ((item.votes > item.voters
                            ? item.voters
                            : item.votes) /
                            item.voters) *
                          100
                        : 0
                      )
                        .toFixed(2)
                        .replace(/\.0+$/, '')}
                      %)
                    </p>
                    {/* {item.votes} / {item.voters} votos &nbsp;( */}
                    {/* {(item.votes > 0 ? (item.votes / item.voters) * 100 : 0)
                      .toFixed(2)
                      .replace(/\.0+$/, '')}
                    %) */}
                  </span>
                  <progress
                    max="100"
                    value={
                      item.votes > 0 ? (item.votes / item.voters) * 100 : 0
                    }
                  />
                </ProgressDiv>
              )}

              <GridOptions>
                {checkStat(item.ini, item.end).stat && (
                  <O.ShowLink
                    background="#00802b"
                    to={{
                      pathname: `${pathname}/apuracao`,
                      state: { ...item },
                    }}
                    title="Apuração"
                  >
                    <FaBookReader />
                  </O.ShowLink>
                )}

                {!checkEnd(item.end) && (
                  <>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { ...item },
                      }}
                    >
                      <FaPencilAlt />
                    </O.Update>
                    {item.votes === 0 && !item.hasorig && (
                      <O.Delete
                        onClick={() =>
                          setDeleteDiag((state) => ({
                            ...state,
                            open: !state.open,
                            values: {
                              seq: item.seq,
                            },
                          }))
                        }
                      >
                        <FaTimes />
                      </O.Delete>
                    )}
                  </>
                )}
                {/* {checkEnd(item.end) && (
                  <O.ShowLink
                    to={{
                      pathname: `${pathname}/ausencias`,
                      state: { ...item },
                    }}
                    title="Ausências"
                  >
                    <FaUserSlash />
                  </O.ShowLink>
                )} */}
                <O.ShowLink
                  to={{
                    pathname: `${pathname}/ausencias`,
                    state: { ...item },
                  }}
                  title="Ausências"
                >
                  <FaUserSlash />
                </O.ShowLink>
              </GridOptions>
            </GridItem>
          ))}
        </Grid>
        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <D.ModalDeleteContent>
              <p>
                Você está prestes a excluir&nbsp;
                <strong style={{ color: '#c53030' }}>permanentemente</strong>
                &nbsp;esta convocação.
              </p>
              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </D.ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() =>
                setDeleteDiag((state) => ({ ...state, open: !state.open }))
              }
            >
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleExclusion}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Convocacao;
