import styled from 'styled-components';

export const Grid = styled.div`
  padding: 20px 0 10px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1366px) {
    grid: auto / 1fr 1fr;
    column-gap: 20px;
    row-gap: 30px;
  }

  @media screen and (max-width: 800px) {
    grid: auto / 1fr;
    column-gap: 0px;
    row-gap: 15px;
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;

  position: relative;

  padding-bottom: 10px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

  transition: border-radius 0.5s, box-shadow 0.5s;
  padding: 10px;

  h4 {
    font-size: 15px;
    width: 100%;
    text-align: start;
  }
  span {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
    width: 100%;

    p {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #565656;
      strong {
        font-size: 15px;
        margin: 0 0 0 5px;
        color: #332e2e;
      }

      & + p {
        margin: 0 0 0 15px;
      }

      svg {
        cursor: pointer;
        color: #007acc;
        margin: 0 0 0 5px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }

    @media screen and (max-width: 1470px) {
      flex-direction: column;
      align-items: flex-start;

      p + p {
        margin: 0;
      }
    }
  }

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const UpdateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 16px;
    width: 100%;
    text-align: center;
    padding: 5px;
  }
`;

export const QtdeContainer = styled.span`
  display: flex;
  align-items: center;

  p {
    font-size: 15px;
    color: #767676;
    font-weight: bold;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextareaUpdateContainer = styled.div`
  display: flex;
  background: #efefef;
  border-radius: 5px;
  padding: 5px 16px;
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
  /* margin-left: 10px; */

  color: #c6c6c6;
  border: 2px solid #a6a6a6;
  textarea {
    resize: none;
    flex: 1;
    border: 0;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto Slab', serif;

    height: 100%;

    &::placeholder,
    &:disabled {
      color: #c6c6c6;
    }
  }

  @media screen and (max-width: 620px) {
    margin-left: 0px;
  }
`;
export const InputUpdateContainer = styled.div`
  display: flex;
  background: #efefef;
  border-radius: 5px;
  padding: 5px 16px;
  width: 150px;

  display: flex;
  align-items: center;
  margin-left: 10px;

  color: #c6c6c6;
  border: 2px solid #a6a6a6;

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  input {
    width: 100%;
    border: 0;
    text-align: center;
    background-color: transparent;
    color: #332e2e;
    font-size: 16px;
    font-weight: 500;

    &::placeholder,
    &:disabled {
      color: #a6a6a6;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: #a6a6a6;
    }
  }

  > svg {
    margin-right: 16px;
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: 620px) {
    margin-left: 0px;
  }
`;

export const JustContainer = styled.span`
  p {
    font-size: 15px;
    color: #767676;
    font-weight: bold;
  }
`;
