import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  RemoveButton,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';

import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import { parseISO, isBefore } from 'date-fns';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  localStorageLength,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { formatNumber } from 'utils/calcTotal';

import { formatDate } from 'utils/formatDate';
import { useSpring } from 'react-spring';
import { useWindow } from 'hooks/window';
import { FaMobileAlt, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useChanges } from 'hooks/changes';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import {
  FilterContainer,
  LeftSVG,
  RightSVG,
  RotateMessage,
  ContentContainer,
  ContentHeader,
  Contents,
  Data,
  Lanc,
  Compl,
  Mov,
  Saldo,
  Wrapper,
  IncludeNew,
  PreviousBalance,
  Balance,
  UpdateReg,
  ExcReg,
} from './styles';
import { monthList, MonthListProps } from '../../monthList';

export interface ListProps {
  date: string;
  lanc: string;
  landesc: string;
  compl: string;
  value: number;
  type: string;
  seq: number;
  auto: string;
  month: number;
  year: number;
}

interface LocalStorageProps {
  ano?: number;
  mes?: number;
  anb?: string;
  anbdesc?: string;
  loc?: string;
  locdesc?: string;
}

interface CoordProps {
  cod: string;
  desc: string;
}

const MB: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { currDate } = useChanges();
  const location = useLocation();
  const { width } = useWindow();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_MB}`,
    );

    return anb || 'Selecione';
  });

  const [coordSelected, setCoordSelected] = useState(() => {
    const { anb, anbdesc, loc, locdesc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_MB}`,
    );

    if (anb && loc) {
      return { cod: loc, desc: locdesc };
    }
    if (anb && !loc) {
      return { cod: anb, desc: anbdesc };
    }
    if (!anb && loc) {
      return { cod: loc, desc: locdesc };
    }
    return {} as CoordProps;
  });

  const [year, setYear] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_MB}`);

    if (hasItem > 0) {
      const { ano }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_MB}`,
      );

      if (ano) {
        return ano;
      }
    }
    return new Date(parseISO(new Date().toISOString())).getUTCFullYear();
  });

  const [month, setMonth] = useState<MonthListProps>(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_MB}`);

    if (hasItem > 0) {
      const { mes }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_MB}`,
      );

      if (mes) {
        return {
          value: mes,
          label:
            monthList[
              monthList.findIndex((item: MonthListProps) => item.value === mes)
            ].label,
        };
      }
    }
    const monthNow =
      new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;
    return {
      value: monthNow,
      label:
        monthList[
          monthList.findIndex((item: MonthListProps) => item.value === monthNow)
        ].label,
    };
  });

  const [lastDay, setLastDay] = useState(new Date().toISOString());
  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const handleLastDay = useCallback(() => {
    const { ano, mes }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_MB}`,
    );

    const yearForList =
      ano || new Date(parseISO(new Date().toISOString())).getUTCFullYear();
    const monthForList =
      mes || new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;

    const last = new Date(yearForList, monthForList, 0);
    const today = new Date();

    const monthForLast = today.getUTCMonth() + 1;

    setLastDay(
      isBefore(last, today) || monthForLast !== monthForList
        ? last.toISOString()
        : today.toISOString(),
    );
  }, []);

  const getList = useCallback(
    async (params?: string) => {
      try {
        setLoading(true);
        handleLastDay();

        const { anb, loc }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_MB}`,
        );

        let coord = '';
        if (anb && loc) {
          coord = loc;
        } else if (anb && !loc) {
          coord = anb;
        } else if (!anb && loc) {
          coord = loc;
        } else {
          coord = '';
        }

        const response = await api.get(
          `/sgo/mb_list.php?data=${JSON.stringify({
            code: params || coord,
          })}`,
        );

        setRawList(response.data);

        const { ano, mes }: LocalStorageProps = getLocalStorage(
          `${process.env.REACT_APP_MB}`,
        );

        const yearForList =
          ano || new Date(parseISO(new Date().toISOString())).getUTCFullYear();
        const monthForList =
          mes || new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;

        setList(
          response.data.filter(
            (item: ListProps) =>
              item.month === monthForList && item.year === yearForList,
          ),
        );

        setLoading(false);
      } catch (err) {
        errorHandling(err);
      }
    },
    [errorHandling, handleLastDay],
  );

  useEffect(() => {
    handlePermission(['GUI', 'LOC']);
    if (user.perfil === 'ZON') {
      getComboANB();
    }

    getList();
  }, [getComboANB, getList, handlePermission, user.perfil]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setInitialNac(text);

    getList(value);
    const selectedANB = {
      cod: value,
      desc: text,
    };

    setCoordSelected(selectedANB);

    setLocalStorage(`${process.env.REACT_APP_MB}`, {
      anb: value,
      anbdesc: text,
    });
  }, [getList]);

  const handleLessMonth = useCallback(() => {
    const newMonth = month.value - 1;
    const index = monthList.findIndex(
      (item: MonthListProps) => item.value === newMonth,
    );

    setMonth({
      value: newMonth === 0 ? 12 : newMonth,
      label: index < 0 ? 'Dezembro' : monthList[index].label,
    });

    setYear(newMonth === 0 ? year - 1 : year);

    setLocalStorage(
      `${process.env.REACT_APP_MB}`,
      newMonth === 0
        ? {
            mes: 12,
            ano: year - 1,
          }
        : { mes: newMonth, ano: year },
    );

    handleLastDay();
    setList(
      rawList.filter((item: ListProps) =>
        newMonth === 0
          ? item.year === year - 1 && item.month === 12
          : item.year === year && item.month === newMonth,
      ),
    );
  }, [handleLastDay, month.value, rawList, year]);

  const handleMoreMonth = useCallback(() => {
    const newMonth = month.value + 1;
    const index = monthList.findIndex(
      (item: MonthListProps) => item.value === newMonth,
    );

    setMonth({
      value: newMonth === 13 ? 1 : newMonth,
      label: index < 0 ? 'Janeiro' : monthList[index].label,
    });

    setYear(newMonth === 13 ? year + 1 : year);

    setLocalStorage(
      `${process.env.REACT_APP_MB}`,
      newMonth === 13
        ? {
            mes: 1,
            ano: year + 1,
          }
        : { mes: newMonth, ano: year },
    );
    handleLastDay();
    setList(
      rawList.filter((item: ListProps) =>
        newMonth === 13
          ? item.year === year + 1 && item.month === 1
          : item.year === year && item.month === newMonth,
      ),
    );
  }, [handleLastDay, month.value, rawList, year]);

  const removeFilter = useCallback(() => {
    setInitialNac('Selecione');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_MB}`, [
      'anb',
      'anbdesc',
      'loc',
      'locdesc',
    ]);

    setCoordSelected({} as CoordProps);

    getList();
  }, [getList]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteDiag((state) => ({ ...state, open: false }));
      setLoading(true);
      if (deleteDiag.values?.seq) {
        const data = {
          seq: deleteDiag.values.seq,
        };

        const send = new FormData();
        send.append('data', JSON.stringify(data));

        await api.post('/sgo/mb_delete.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro excluído.',
        });

        getList();
      } else {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha na Requisição',
          description:
            'Verifique o lançamento e caso o problema persista acione o suporte ao SGO.',
        });
      }
    } catch (err) {
      errorHandling(err);
    }
  }, [addToast, errorHandling, getList, deleteDiag.values]);

  const saldoAnterior = useMemo(() => {
    const dateNow = new Date(year, month.value - 1, 1);
    return rawList.reduce((accum, { date, value, type }: ListProps) => {
      return isBefore(new Date(parseISO(date)), dateNow)
        ? type === 'E'
          ? accum + value
          : accum - value
        : accum;
    }, 0);
  }, [month.value, rawList, year]);

  const saldoAtual = useMemo(() => {
    // return 0;
    const dateNow = new Date(year, month.value, 1);
    return rawList.reduce((accum, { date, value, type }: ListProps) => {
      return isBefore(new Date(parseISO(date)), dateNow)
        ? type === 'E'
          ? accum + value
          : accum - value
        : accum;
    }, 0);
  }, [month.value, rawList, year]);

  const monthlyEntrances = useMemo(() => {
    return list.reduce((accum, { value, type }: ListProps) => {
      return type === 'E' ? accum + value : accum;
    }, 0);
  }, [list]);

  const monthlyOutcomes = useMemo(() => {
    return list.reduce((accum, { value, type }: ListProps) => {
      return type === 'S' ? accum + value : accum;
    }, 0);
  }, [list]);

  const shrinkCompl = useSpring({
    width: width >= 790 ? '184px' : '0px',
    opacity: width >= 790 ? 1 : 0,
    height: width >= 790 ? 'auto' : 0,
    padding: width >= 790 ? '3px' : '0px',
  });

  const shrinkLanc = useSpring({
    width: width >= 640 ? '142px' : '0px',
    opacity: width >= 640 ? 1 : 0,
    height: width >= 640 ? 'auto' : 0,
    padding: width >= 640 ? '3px' : '0px',
  });

  const shrinkOptions = useSpring({
    width: width >= 475 ? '75px' : '50px',
    // opacity: width >= 460 ? 1 : 0,
    // padding: width >= 460 ? '3px' : '0px',
    // height: width >= 460 ? 'auto' : 0,
  });

  const shrinkMov = useSpring({
    width: '100px',
    // width: width >= 475 ? '100px' : '80px',
  });

  const tableHeight = useSpring({
    maxHeight: '480px',
    height: '100%',
  });

  const showMessage = useSpring({
    opacity: width >= 640 ? 0 : 1,
    height: width >= 640 ? 0 : 'auto',
  });

  const showButton = useSpring({
    opacity: width >= 720 ? 0 : 1,
    height: width >= 720 ? 0 : 'auto',
    pointerEvents: width >= 720 ? 'all' : 'none',
  });

  const prevBalance = useSpring({
    opacity: !loading ? 1 : 0,
    transform: !loading ? 'translateX(0px)' : 'translateX(20px)',
  });

  const filterRemove = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      {width >= 720 && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['ZON'].indexOf(user.perfil) > -1}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {['ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                  <RemoveButton
                    type="button"
                    onClick={removeFilter}
                    style={filterRemove}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
            </div>
          </Form>
        }
      />

      <Content>
        <AlteredHeader>
          Movimento Bancário&nbsp;-&nbsp;
          {coordSelected.desc
            ? coordSelected.desc
            : user.perfil === 'ZON'
            ? user.zondesc
            : user.perfil === 'NAC'
            ? user.anbdesc
            : user.locdesc}
        </AlteredHeader>
        <FilterContainer>
          <LeftSVG onClick={handleLessMonth} />
          <p>{month.label}</p>&nbsp;/&nbsp;<p>{year}</p>
          <RightSVG onClick={handleMoreMonth} />
        </FilterContainer>

        <RotateMessage style={showMessage}>
          <span>
            <p>Para mais informações dos lançamentos, vire seu aparelho</p>
          </span>
          <span>
            <FaMobileAlt />
          </span>
        </RotateMessage>

        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: `${location.pathname}/insert`,
          }}
        >
          <IncludeNew style={showButton}>
            <p>Incluir Registro</p>
          </IncludeNew>
        </Link>

        <PreviousBalance style={prevBalance}>
          <p>Saldo anterior:&nbsp;</p>
          <p
            style={
              {
                /* color: saldoAtual < 0 ? 'red' : 'green' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(saldoAnterior)}</strong>
          </p>
        </PreviousBalance>
        <ContentContainer style={tableHeight}>
          <ContentHeader>
            <Data>
              <p>Data</p>
            </Data>
            <Lanc style={shrinkLanc}>
              <p>Lançamento</p>
            </Lanc>
            <Compl style={shrinkCompl}>
              <p>Complemento</p>
            </Compl>
            <Mov style={shrinkMov}>
              <p>Mov{width >= 475 ? 'imento' : '.'}</p>
            </Mov>
            <Saldo>
              <p>Saldo</p>
            </Saldo>
            <UpdateReg style={shrinkOptions}>
              {width >= 475 ? (
                <p>Editar</p>
              ) : (
                <p>
                  <FaPencilAlt />
                </p>
              )}
            </UpdateReg>
            <ExcReg style={shrinkOptions}>
              {width >= 475 ? (
                <p>Excluir</p>
              ) : (
                <p>
                  <FaTimes />
                </p>
              )}
            </ExcReg>
          </ContentHeader>
          <Contents>
            {list.length > 0 ? (
              list.map((item: ListProps, index) => (
                <Wrapper key={item.seq} paint={index}>
                  <Data>
                    <p>{formatDate(item.date)}</p>
                  </Data>
                  <Lanc style={shrinkLanc}>
                    <p>{item.landesc}</p>
                  </Lanc>
                  <Compl style={shrinkCompl}>
                    <p>{item.compl}</p>
                  </Compl>
                  <Mov style={shrinkMov}>
                    <p
                      style={{
                        color: item.type === 'E' ? '#2156e6' : '#c53030',
                      }}
                    >
                      {`${item.type === 'E' ? '+' : '-'}${formatNumber(
                        item.value,
                      )}`}
                    </p>
                  </Mov>
                  <Saldo>
                    <p
                      style={
                        {
                          // color:
                          //   list.reduce(
                          //     (accum, { value, type }: ListProps, indexNow) => {
                          //       if (index === 0 && indexNow === 0) {
                          //         return type === 'E'
                          //           ? accum + value
                          //           : accum - value;
                          //       }
                          //       if (indexNow <= index) {
                          //         return type === 'E'
                          //           ? accum + value
                          //           : accum - value;
                          //       }
                          //       return accum;
                          //     },
                          //     saldoAnterior,
                          //   ) < 0
                          //     ? 'red'
                          //     : 'green',
                        }
                      }
                    >
                      R$&nbsp;
                      {formatNumber(
                        list.reduce(
                          (accum, { value, type }: ListProps, indexNow) => {
                            if (index === 0 && indexNow === 0) {
                              return type === 'E'
                                ? accum + value
                                : accum - value;
                            }

                            if (indexNow <= index) {
                              return type === 'E'
                                ? accum + value
                                : accum - value;
                            }
                            return accum;
                          },
                          saldoAnterior,
                        ),
                      )}
                    </p>
                  </Saldo>
                  <UpdateReg style={shrinkOptions}>
                    {!coordSelected.cod &&
                    item.auto !== 'A' &&
                    ((currDate.month === 1 &&
                      item.month === 12 &&
                      item.year === currDate.year - 1) ||
                      item.year >= new Date().getUTCFullYear()) ? (
                      <Link
                        to={{
                          pathname: `${location.pathname}/update`,
                          state: { reg: item },
                        }}
                      >
                        <FaPencilAlt />
                      </Link>
                    ) : (
                      <p>-</p>
                    )}
                  </UpdateReg>
                  <ExcReg style={shrinkOptions}>
                    {!coordSelected.cod &&
                    item.auto !== 'A' &&
                    ((currDate.month === 1 &&
                      item.month === 12 &&
                      item.year === currDate.year - 1) ||
                      item.year >= new Date().getUTCFullYear()) ? (
                      <button
                        type="button"
                        onClick={() =>
                          setDeleteDiag({
                            open: true,
                            values: {
                              rawdate: item.date,
                              date: formatDate(item.date),
                              desc: item.landesc,
                              rawvalue: item.value,
                              value: formatNumber(item.value),
                              compl: item.compl,
                              type: item.type,
                              seq: item.seq,
                            },
                          })
                        }
                      >
                        <FaTimes />
                      </button>
                    ) : (
                      <p>-</p>
                    )}
                  </ExcReg>
                </Wrapper>
              ))
            ) : (
              <Wrapper paint={0}>
                <Data>
                  <p>{formatDate(lastDay)}</p>
                </Data>
                <Lanc style={shrinkLanc}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Lanc>
                <Compl style={shrinkCompl}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Compl>
                <Mov style={shrinkMov}>
                  <p style={{ padding: '3px', width: '100%' }}>-</p>
                </Mov>
                <Saldo>
                  <p
                    style={
                      {
                        /* color: saldoAtual < 0 ? 'red' : 'green' */
                      }
                    }
                  >
                    R$ {formatNumber(saldoAtual)}
                  </p>
                </Saldo>
                <UpdateReg style={shrinkOptions}>-</UpdateReg>
                <ExcReg style={shrinkOptions}>-</ExcReg>
              </Wrapper>
            )}
          </Contents>
        </ContentContainer>
        <Balance style={prevBalance}>
          <p>Total de Entradas:&nbsp;</p>
          <p
            style={
              {
                /* color: 'green' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(monthlyEntrances)}</strong>
          </p>
        </Balance>
        <Balance style={prevBalance}>
          <p>Total de Saídas:&nbsp;</p>
          <p
            style={
              {
                /* color: 'red' */
              }
            }
          >
            R$&nbsp;
            <strong>{formatNumber(monthlyOutcomes)}</strong>
          </p>
        </Balance>

        {/* <p>Saldo Anterior: R$ {formatNumber(saldoAnterior)}</p>
        <p>Saldo Atual: R$ {formatNumber(saldoAtual)}</p>
        <p>Entradas: R$ {formatNumber(monthlyEntrances)}</p>
        <p>Saídas: R$ {formatNumber(monthlyOutcomes)}</p>
        <p>
          Batimentos Entradas: R${' '}
          {formatNumber(saldoAnterior + monthlyEntrances)}
        </p>
        <p>
          Batimentos Saídas: R$ {formatNumber(saldoAtual + monthlyOutcomes)}
        </p> */}
      </Content>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>
          <ModalDeleteContent>
            <p>Você está prestes a excluir o registro:</p>
            <div>
              <span>
                <p>
                  Data:&nbsp;
                  <strong>{deleteDiag.values?.date}</strong>
                </p>
              </span>
              <span>
                <p>
                  Lançamento:&nbsp;<strong>{deleteDiag.values?.desc}</strong>
                </p>
              </span>
              <span>
                <p>
                  Tipo do Movimento:&nbsp;
                  <strong>
                    {deleteDiag.values?.type === 'E' ? 'Entrada' : 'Saída'}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Complemento:&nbsp;
                  <strong>{deleteDiag.values?.compl}</strong>
                </p>
              </span>
              <span>
                <p>
                  Valor do movimento:&nbsp;
                  <strong>R$&nbsp;{deleteDiag.values?.value}</strong>
                </p>
              </span>
            </div>

            <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
          </ModalDeleteContent>
        </D.Content>
        <D.Actions>
          <D.Cancel
            type="button"
            onClick={() => setDeleteDiag({ open: false })}
          >
            Cancelar
          </D.Cancel>
          <D.Confirm type="button" onClick={handleDelete}>
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <SGOFooter />
    </Container>
  );
};

export default MB;
