import React, { useCallback, useRef, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { FaLock } from 'react-icons/fa';
import * as yup from 'yup';
import { Container } from 'styles/wrappers';

import Header from 'components/Header';
import Navbar from 'components/Navbar';
import ScrollTop from 'components/ScrollTop';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Button from 'components/Button';

import api from 'services/api';

import getValidationErrors from 'utils/getValidationErrors';
import { useToast } from 'hooks/toast';
import { useAuth } from 'hooks/auth';
import { Content, AlteredForm } from './styles';

interface FirstAccessData {
  pass: string;
  passConfirm: string;
}

const FirstAccess: React.FC = () => {
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: FirstAccessData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          pass: yup
            .string()
            .required('Senha obrigatória')
            .min(6, 'No mínimo 6 dígitos'),
          passConfirm: yup
            .string()
            .oneOf([yup.ref('pass'), null], 'As senhas não são identicas'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();
        send.append(
          'data',
          JSON.stringify({
            pass: data.pass,
            guia: {
              seq: user.gseq,
              cert: user.gcert,
              perfil: user.perfil,
              loc: user.loccod,
              anb: user.anbc,
            },
          }),
        );
        // send.append('pass', data.pass);
        // send.append('pass', data.pass.replace('#', 'HASHTAG'));

        const response = await api.post('/common/first_access.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // return;

        const { status, message } = response.data;

        if (status) {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: message,
          });

          return;
        }

        const alterUser = {
          ...user,
          mudasenha: false,
        };

        updateUser(alterUser);

        addToast({
          type: 'info',
          title: 'Sucesso!',
          description: 'Sua senha foi definida com sucesso.',
        });

        // history.replace(user.perfil === 'CTB' ? '/sgo/contabil' : '/dashboard');
        history.replace(user.perfil === 'CTB' ? '/sgo/contabil' : '/sgo');
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [history, addToast, user, updateUser],
  );

  useEffect(() => {
    if (!!user && !user.mudasenha) {
      history.replace('/');
    }
  }, [user, history]);
  return (
    <Container>
      <ScrollTop />
      <Header disabledLink />
      <Navbar first />
      <Content>
        <AlteredForm ref={formRef} initialData={{}} onSubmit={handleSubmit}>
          <h2>
            Para obter acesso ao SGO, é necessário definir uma nova senha.
          </h2>
          <Input
            icon={FaLock}
            name="pass"
            type="password"
            placeholder="Nova senha"
            isPass
          />
          <Input
            icon={FaLock}
            name="passConfirm"
            type="password"
            placeholder="Confirmar nova senha"
            isPass
          />
          <Button
            type="submit"
            bgcolor="#00802b"
            containerStyle={{ marginTop: '25px' }}
          >
            Definir
          </Button>
        </AlteredForm>
      </Content>
      <Footer />
    </Container>
  );
};

export default FirstAccess;
