import React from 'react';

import { Container } from './styles';

const SGOFooter: React.FC = () => {
  const year = new Date().getUTCFullYear();
  return (
    <Container>
      <span>
        <p>
          2017 - {year} © Copyright - Todos os direitos reservados a{' '}
          <a
            href="https://pazinatoti.com.br"
            rel="noopener noreferrer"
            target="_blank"
          >
            Pazinato TI
          </a>
          .
        </p>
      </span>

      {/* <Pazinato>
        <a
          href="https://pazinatoti.com.br"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={logo} alt="Pazinato TI" />
        </a>
      </Pazinato> */}
    </Container>
  );
};

export default SGOFooter;
