import React from 'react';

import { Container, Content } from 'styles/wrappers';
import { AlteredHeader } from 'styles/sgo_wrappers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
import { Common, Decreto } from '../styles';

const pontificium = `${process.env.REACT_APP_ASSETS_DIR}/aboutTOV/pontificium.png`;
const decreto = `${process.env.REACT_APP_ASSETS_DIR}/aboutTOV/DecretoTOV.png`;
const decreto2 = `${process.env.REACT_APP_ASSETS_DIR}/aboutTOV/DecretoTOV2.png`;

const DecretoComp: React.FC = () => {
  return (
    <Container>
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Decreto</div>
        </AlteredHeader>
        <Common>
          <Decreto>
            <span>
              <img src={pontificium} alt="Pontificium" />
              <span>
                <h3>Pontificium Consilium pro Laicis</h3>
                <h3>N. 1607/02/S-61/B-83</h3>
              </span>
            </span>
            <p>
              Em 15 de outubro de 1997, no Pontifício Conselho de Leigos do
              Vaticano, realizou-se a cerimônia de entrega do Decreto de
              Aprovação das Oficinas de Oração e Vida (Talleres de Oración y
              Vida = TOV); como Associação Internacional de Fiéis, de direito
              pontifício e personalidade jurídica e aprovando os Estatutos por
              um período ad experimentum de cinco anos. Essa determinação havia
              sido assinada na Santa Sé, no dia 4 do mesmo mês, ocasião da festa
              de São Francisco de Assis.
            </p>
            <p>
              No ato da entrega, estiveram presentes o presidente do Conselho
              dos Leigos, monsenhor J. Francis Stafford, juntamente com seu
              secretário, monsenhor Stanislaw Rylko, além do ministro geral da
              Ordem dos Capuchinhos, John Corriveau, dois de seus conselheiros
              gerais, Ignacio Larrañaga o fundador, e Maria Inês Rojas
              coordenadora internacional TOV.
            </p>
            <p>
              Sobre sua originalidade e eficácia, falavam os mais de 1,5 mil
              testemunhos de bispos e párocos, que tinham chegado ao Dicastério
              Pontifício. Isso, sem dúvida, contribuiu para o acelerado
              reconhecimento dos TOV por parte da Igreja como Associação
              Internacional de Direito Pontifício.
            </p>
            <p>
              Poucos dias após a celebração, monsenhor Stafford escreveu estas
              palavras a padre Ignacio: “Fiquei profundamente impressionado com
              o carisma, o ministério e a qualidade de vida das Oficinas. Por
              isso, garanto-lhes que continuarei a acompanha-los com a amizade e
              a oração”.
            </p>
            <img src={decreto} alt="Decreto" />
            <p>
              Transcorridos o período ad experimentum de cinco anos, acolhendo
              as modificações apresentadas ao texto dos Estatutos, considerando
              a extensão e profundidade das atividades formativas e apostólicas
              desenvolvidas durante esses anos pelos TOV houve a confirmação do
              reconhecimento das Oficinas de Oração e Vida, como Associação
              Privada Internacional de fiéis de direito pontifício, com
              personalidade jurídica, e a aprovação definitiva dos Estatutos das
              Oficinas de Oração e Vida cujo original se encontra depositado nos
              arquivos do Dicastério do Conselho Pontifício para Leigos.
            </p>
            <p>
              Dado na cidade do Vaticano, em 4 de outubro de 2002, festividade
              de São Francisco de Assis.
            </p>
            <img src={decreto2} alt="Decreto" />
          </Decreto>
        </Common>
      </Content>
      <Footer />
    </Container>
  );
};

export default DecretoComp;
