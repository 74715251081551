import React, { HTMLAttributes, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  FaArrowLeft,
  // FaPowerOff,
  FaFacebook,
  FaInstagram,
  // FaWhatsapp,
} from 'react-icons/fa';
import { useWindow } from 'hooks/window';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import {
  Container,
  BurgerContainer,
  Burger,
  BurgerContent,
  SocialMediaContainer,
} from './styles';

// const SGO = `${process.env.REACT_APP_ASSETS_DIR}/logos/SGO_shortcut.png`;
interface NavbarProps extends HTMLAttributes<HTMLDivElement> {
  containerType?: string;
  containerStyle?: object;
  isHome?: boolean;
  web?: boolean;
  noLink?: boolean;
  first?: boolean;
  avoidSocialMedia?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  containerStyle = {},
  containerType = 'links',
  first = false,
  isHome,
  web,
  noLink,
  avoidSocialMedia = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindow();
  const { signOut } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const { addToast } = useToast();

  const handleChanges = useCallback(() => {
    if (containerType === 'buttons') {
      window.scrollTo(0, 0);
      setIsOpen((state) => !state);
    }
  }, [containerType]);

  const handleClick = useCallback(() => {
    if (pathname.substr(-6) === 'signin') {
      history.replace(pathname.replace('signin', ''));
      return;
    }

    history.goBack();
  }, [pathname, history]);

  const handleLogout = useCallback(() => {
    signOut();

    addToast({
      type: 'info',
      title: 'É necessário definir uma nova senha para prosseguir.',
      // description: 'É necessário definir uma nova senha para prosseguir.',
    });

    history.replace('/');
  }, [history, signOut, addToast]);

  return (
    <Container style={containerStyle}>
      {!isHome && <FaArrowLeft onClick={!first ? handleClick : handleLogout} />}

      {!avoidSocialMedia && (
        <SocialMediaContainer>
          <a
            href="https://www.instagram.com/oficinasdeoracaoevida"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaInstagram size={30} />
          </a>

          {/* <a
            href="https://api.whatsapp.com/send?phone=+393475987431&text=\`\`\`Motivo do Contato:\`\`\` Auxílio site.%0a%0a"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaWhatsapp size={30} />
          </a> */}

          <a
            href="https://facebook.com/TovBrasilOficial"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebook size={30} />
          </a>
        </SocialMediaContainer>
      )}
      {width > 900 ? (
        <>{children}</>
      ) : (
        <>
          {React.Children.count(children) > 0 && (
            <BurgerContainer>
              <Burger
                isOpen={isOpen}
                onClick={() => setIsOpen((state) => !state)}
              >
                <div />
              </Burger>

              <BurgerContent isOpen={isOpen} onClick={handleChanges}>
                {children}
              </BurgerContent>
            </BurgerContainer>
          )}
        </>
      )}

      {/* {!user ? null : web ? (
        <div>
          <Link to={user.perfil === 'CTB' ? '/sgo/contabil' : '/sgo/'}>
            <img src={SGO} alt="SGO" />
          </Link>
          //<a href={process.env.REACT_APP_SGO} rel="noopener noreferrer">
          //  <img src={SGO} alt="SGO" />
          //</a>
        </div>
      ) : !noLink ? (
        <div>
          <FaPowerOff onClick={signOut} title="Encerrar sessão" />
        </div>
      ) : null} */}
    </Container>
  );
};

export default Navbar;
