import styled from 'styled-components';
import { shade } from 'polished';

import { Link } from 'react-router-dom';

interface AlteredLinkProps {
  bgcolor: string;
}

interface GridItemProps {
  borderColor: string;
}

export const GridContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  width: 100vw;

  justify-content: center;
  padding: 10px 5px;
  row-gap: 15px;

  @media screen and (max-width: 1470px) {
    grid: auto / 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1100px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 630px) {
    padding: 10px 0px;
    grid: auto / 1fr;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  --color: ${(props) => props.borderColor || '#efefef'};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 360px;

  background: #fff;
  border: 3px solid var(--color);
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-radius 0.35s, border-left-width 0.35s,
    border-left-width 0.35s, border-color 0.35s, box-shadow 0.35s, width 0.35s;

  span {
    font-size: 16px;
    display: flex;

    p {
      color: #767676;
      font-weight: 400;
    }
    strong {
      font-weight: bolder;
      color: #332e2e;
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    border-color: ${(props) => shade(0.2, props.borderColor || '#efefef')};
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 750px) {
    width: unset;
    min-width: 300px;
  }

  @media screen and (max-width: 630px) {
    width: 360px;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      & + p {
        margin: 0 0 0 10px;
      }
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }
`;

export const AlteredLink = styled(Link)<AlteredLinkProps>`
  --color: ${(props) => props.bgcolor || '#efefef'};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;
  border: 2px solid var(--color);
  background: var(--color);
  border-radius: 5px;
  transition: background-color 350ms, border-color 350ms;

  svg {
    color: #efefef;
    height: 17px;
    width: 17px;
    transition: color 350ms;
  }

  &:hover {
    background-color: ${(props) => shade(0.2, `${props.bgcolor}`)};
    border-color: ${(props) => shade(0.2, `${props.bgcolor}`)};

    svg {
      color: #fff;
    }
  }
`;

export const AlteredButton = styled.button<AlteredLinkProps>`
  --color: ${(props) => props.bgcolor || '#efefef'};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;
  border: 2px solid var(--color);
  background: var(--color);
  border-radius: 5px;
  transition: background-color 350ms, border-color 350ms;

  svg {
    color: #efefef;
    height: 17px;
    width: 17px;
    transition: color 350ms;
  }

  &:hover {
    background-color: ${(props) => shade(0.2, `${props.bgcolor}`)};
    border-color: ${(props) => shade(0.2, `${props.bgcolor}`)};

    svg {
      color: #fff;
    }
  }
`;
