import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';

import { v4 } from 'uuid';

import ReactCrop, { Crop } from 'react-image-crop';

import { AlteredHeader, Container, Content } from 'styles/sgo_wrappers';

import { useLocation, useHistory } from 'react-router-dom';

import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import resizeImage from 'utils/resizeImage';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

// import { parseISO } from 'date-fns';

import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from 'utils/toCropUtils.js';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import api from 'services/api';

import { useSpring } from 'react-spring';
import { FaCamera, FaFilePdf } from 'react-icons/fa';
import { formatDate } from 'utils/formatDate';
import {
  Wrapper,
  OrDiv,
  InputAvatar,
  InputPDF,
  ReactCropContainer,
  SaveButton,
  CancelButton,
  ButtonsContainer,
  DateContainer,
} from './styles';

interface LocationProps {
  year: number;
  month: number;
}

interface FormData {
  dtpag: string;
}

interface PDFFile {
  name: string;
  size: string;
}
//
const FaturamentoInsert: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<LocationProps>();
  const history = useHistory();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const pdfRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);

  const { handlePermission, errorHandling } = useCredentials();
  const { addToast } = useToast();

  const [reg] = useState<LocationProps>(() => {
    return { ...location.state };
  });

  const [imgSrc, setImgSrc] = useState<any>('');
  const [pdfSource, setPDFSource] = useState<PDFFile>({} as PDFFile);
  const [crop, setCrop] = useState<Crop>({
    // aspect: 1,
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (Object.keys(reg).length === 0) {
      history.push(`${location.pathname.replace('/comprovante', '')}`);
    }
    handlePermission(['NAC', 'ZON'], true);
  }, [handlePermission, history, location.pathname, location.state, reg]);

  const fileSize = (size: number): string => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const handlePDFInput = useCallback(() => {
    const input = pdfRef.current;

    if (input && input.files) {
      setPDFSource({
        name: input.files[0].name,
        size: fileSize(input.files[0].size),
      });
    }
  }, []);

  const handleAvatarInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const size = e.target.files[0].size / 1024 / 1024; // in MB

        if (size > 6) {
          addToast({
            type: 'error',
            title: 'Limite excedido',
            description:
              'Uma foto que você tentou enviar é maior do que o limite permitido de 6 MB',
          });

          return;
        }

        const width = window.innerWidth / 1.35;
        const height = window.innerHeight / 1.35;

        const newImage = await resizeImage(e.target.files[0], width, height);

        const reader = new FileReader();

        reader.addEventListener('load', () => {
          setCrop({
            // aspect: 1,
            unit: '%',
            width: 100,
            height: 100,
            x: 0,
            y: 0,
          });
          setImgSrc(reader.result);
        });

        reader.readAsDataURL(newImage);
      }
    },
    [addToast],
  );

  const handleCompleteCrop = useCallback(
    (cropped) => {
      const canvas = canvasRef.current;
      image64toCanvasRef(canvas, imgSrc, cropped);
    },
    [imgSrc],
  );

  const handleChangeCrop = useCallback((croppy) => {
    setCrop(croppy);
  }, []);

  const cancelPhoto = useCallback(() => {
    setImgSrc('');
    setPDFSource({} as PDFFile);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    if (pdfRef.current) {
      pdfRef.current.value = '';
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const maxDate = new Date(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
        ).toISOString();

        const minDate = new Date(reg.year, reg.month - 1, 1).toISOString();

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          dtpag: yup
            .date()
            .typeError('Data inválida')
            .min(
              minDate,
              `A data mínima desse pagamento deve ser ${formatDate(minDate)}`,
            )
            .max(
              maxDate,
              `A data máxima desse pagamento deve ser a data atual (${formatDate(
                maxDate,
              )})`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (Object.keys(pdfSource).length > 0) {
          const send = new FormData();
          const pdf = pdfRef.current;
          const newName = v4();
          const fileExtension = 'pdf';
          const filename = `${newName}.${fileExtension}`;
          if (!!pdf && !!pdf.files) {
            send.append('files[]', pdf.files[0], filename);
            send.append(
              'data',
              JSON.stringify({
                ...data,
                year: reg.year,
                month: reg.month,
                name: newName,
                ext: fileExtension,
              }),
            );
          }
          await api.post('/sgo/faturamento_comprovante_pdf.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          const canvas = canvasRef.current;

          if (!canvas) {
            return;
          }
          const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
          const imageData64 = canvas.toDataURL(`image/${fileExtension}`);

          const newName = v4();
          const filename = `${newName}.${fileExtension}`;
          const newCroppedImg = base64StringtoFile(imageData64, filename);

          const send = new FormData();
          send.append(
            'data',
            JSON.stringify({
              ...data,
              year: reg.year,
              month: reg.month,
              name: newName,
              ext: fileExtension,
            }),
          );

          send.append('receipt', newCroppedImg);
          await api.post('/sgo/faturamento_comprovante.php', send, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }

        history.goBack();
        // data.append('old', guia?.avatar);
        // data.append('avatar', newCroppedImg);
        // data.append('gseq', guia?.gseq);
        // data.append('ext', fileExtension);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, history, imgSrc, pdfSource, reg.month, reg.year],
  );

  //   const handlePDFSubmit = useCallback(() => {
  //     try {
  //       setLoading(true);
  //       const pdf = pdfRef.current;
  //       const send = new FormData();

  //     } catch (err){
  // const pdf = pdfRef.current;

  //       if (pdf) {
  //         pdf.value = '';
  //       }
  //       setLoading(false);
  //       errorHandling(err, err.response.data.message);
  //     }

  //   }, [errorHandling]);

  const inputAnimation = useSpring({
    opacity: !imgSrc ? 1 : 0,
    pointerEvents: !imgSrc ? 'all' : 'none',
  });

  const avatarAnimation = useSpring({
    opacity: imgSrc ? 1 : 0,
    pointerEvents: imgSrc ? 'all' : 'none',
  });

  const buttonsAnimation = useSpring({
    opacity: imgSrc || Object.keys(pdfSource).length > 0 ? 1 : 0,
    pointerEvents: imgSrc || Object.keys(pdfSource).length > 0 ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar noLinks />
      <Content>
        <canvas
          ref={canvasRef}
          style={{ display: 'none', cursor: 'none', pointerEvents: 'none' }}
        />
        <AlteredHeader>
          <div>
            <p>Comprovante de Pagamento</p>
          </div>
          <div>
            <p>
              {`0${reg.month}`.slice(-2)}/{reg.year}
            </p>
          </div>
        </AlteredHeader>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <DateContainer>
            <p>Data do Pagamento</p>
            <Input isDate name="dtpag" placeholder="dd/mm/aaaa" />
          </DateContainer>

          <Wrapper style={inputAnimation}>
            <InputPDF style={inputAnimation}>
              <label htmlFor="pdf">
                <FaFilePdf />
                <h4>
                  {Object.keys(pdfSource).length > 0
                    ? `${pdfSource.name}`
                    : 'Carregar documento'}
                </h4>
                <input
                  ref={pdfRef}
                  type="file"
                  accept="application/pdf"
                  multiple={false}
                  id="pdf"
                  onChange={handlePDFInput}
                />
              </label>
            </InputPDF>

            <OrDiv>
              <strong>OU</strong>
            </OrDiv>

            <InputAvatar style={inputAnimation}>
              <label htmlFor="avatar">
                <FaCamera />
                <h4>Carregar nova foto</h4>
                <input
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  multiple={false}
                  id="avatar"
                  onChange={handleAvatarInput}
                />
              </label>
            </InputAvatar>
          </Wrapper>

          <ReactCropContainer style={avatarAnimation}>
            <ReactCrop
              src={imgSrc}
              crop={crop}
              minHeight={100}
              onComplete={handleCompleteCrop}
              onChange={handleChangeCrop}
            />
          </ReactCropContainer>

          <ButtonsContainer style={buttonsAnimation}>
            <SaveButton type="submit">Finalizar</SaveButton>
            <CancelButton type="button" onClick={cancelPhoto}>
              Cancelar
            </CancelButton>
          </ButtonsContainer>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FaturamentoInsert;
