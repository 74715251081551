import React from 'react';
import Page404 from 'pages/404';

import Suporte from 'pages/web/Suporte';
import PeloMundo from 'pages/web/PeloMundo';
import ObrasFrei from 'pages/web/ObrasFrei';

import * as V2 from 'pages/web-v2';
import * as ServicoTOV from 'pages/web-v2/ServicoTOV';
import * as SobreTOV from 'pages/web-v2/SobreTOV';

import SignIn from 'pages/sgo/SignIn';
import Reset from 'pages/sgo/Reset';
import FirstAccess from 'pages/sgo/FirstAccess';
import VideoAulas from 'pages/web/VideoAulas';

import ADM from 'pages/sgo/ADM/Profile';
import PHP from 'pages/sgo/ADM/PHP';
import ResetUser from 'pages/sgo/ADM/ResetADM';
import HourTesting from 'pages/sgo/ADM/HourTesting';
import Faturamento from 'pages/sgo/ADM/Faturamento/main';
import FaturamentoInsert from 'pages/sgo/ADM/Faturamento/insert';
import Conciliacao from 'pages/sgo/ADM/Faturamento/conciliacao';

import Version from 'pages/sgo/ADM/Version';
import SGOHome from 'pages/sgo/SGOHome';

import Contabil from 'pages/sgo/Contabil';

import Avatar from 'pages/sgo/Secretaria/Guias/avatar';

export interface OtherRoutes {
  path: string;
  isPrivate: boolean;
  component: React.FC;
  exact: boolean;
  isADM: boolean;
}

const ORoutes = [
  {
    path: `/`,
    isPrivate: false,
    exact: true,
    component: V2.Home,
    isADM: false,
  },
  {
    path: `/tov/adultos`,
    exact: true,
    isPrivate: false,
    component: ServicoTOV.Adultos,
    isADM: false,
  },
  {
    path: `/tov/jovens`,
    exact: true,
    isPrivate: false,
    component: ServicoTOV.Jovens,
    isADM: false,
  },
  {
    path: `/tov/adolescentes`,
    exact: true,
    isPrivate: false,
    component: ServicoTOV.Adolescentes,
    isADM: false,
  },
  {
    path: `/tov/criancas`,
    exact: true,
    isPrivate: false,
    component: ServicoTOV.Criancas,
    isADM: false,
  },
  {
    path: `/tov/casais`,
    exact: true,
    isPrivate: false,
    component: ServicoTOV.CursoCasais,
    isADM: false,
  },
  {
    path: `/suporte`,
    isPrivate: false,
    exact: true,
    component: Suporte,
    isADM: false,
  },
  {
    path: `/contatos`,
    isPrivate: false,
    exact: true,
    component: V2.Contatos,
    isADM: false,
  },
  {
    path: `/sobre/tov`,
    exact: true,
    isPrivate: false,
    component: SobreTOV.TOV,
    isADM: false,
  },
  {
    path: `/sobre/missao`,
    exact: true,
    isPrivate: false,
    component: SobreTOV.Missao,
    isADM: false,
  },
  {
    path: `/sobre/decreto`,
    exact: true,
    isPrivate: false,
    component: SobreTOV.Decreto,
    isADM: false,
  },
  {
    path: `/sobre/pelo-mundo`,
    exact: true,
    isPrivate: false,
    component: SobreTOV.PeloMundo,
    isADM: false,
  },
  {
    path: `/sobre/obras-frei`,
    exact: true,
    isPrivate: false,
    component: SobreTOV.ObrasFrei,
    isADM: false,
  },
  {
    path: `/aboutTOV/world`,
    isPrivate: false,
    exact: true,
    component: PeloMundo,
    isADM: false,
  },
  {
    path: `/books`,
    isPrivate: false,
    exact: false,
    component: ObrasFrei,
    isADM: false,
  },
  {
    path: `/oficinas`,
    isPrivate: false,
    exact: true,
    component: V2.Oficinas,
    isADM: false,
  },
  {
    path: `/eed`,
    isPrivate: false,
    exact: false,
    component: V2.EEDs,
    isADM: false,
  },
  {
    path: `/signin`,
    isPrivate: false,
    exact: false,
    component: SignIn,
    isADM: false,
  },
  // {
  //   path: `/dashboard`,
  //   isPrivate: true,
  //   exact: true,
  //   component: Dashboard,
  //   isADM: false,
  // },
  // {
  //   path: `/dashboard/anbs`,
  //   isPrivate: true,
  //   exact: false,
  //   component: ANBs,
  //   isADM: false,
  // },
  // {
  //   path: `/dashboard/materiais`,
  //   isPrivate: true,
  //   exact: false,
  //   component: WebMateriais,
  //   isADM: false,
  // },
  // {
  //   path: `/dashboard/oracoes`,
  //   isPrivate: true,
  //   exact: false,
  //   component: Oracoes,
  //   isADM: false,
  // },
  {
    path: `/sgo/videoaulas`,
    isPrivate: true,
    exact: false,
    component: VideoAulas,
    isADM: false,
  },
  {
    path: `/reset/:token`,
    isPrivate: false,
    exact: true,
    component: Reset,
    isADM: false,
  },
  {
    path: `/primeiro_acesso`,
    isPrivate: true,
    exact: true,
    component: FirstAccess,
    isADM: false,
  },
  {
    path: `/sgo`,
    isPrivate: true,
    exact: true,
    component: SGOHome,
    isADM: false,
  },
  {
    path: `/sgo/admin`,
    isPrivate: true,
    exact: true,
    component: ADM,
    isADM: true,
  },
  {
    path: `/sgo/admin/php`,
    isPrivate: true,
    exact: true,
    component: PHP,
    isADM: true,
  },
  {
    path: `/sgo/admin/faturamento`,
    isPrivate: true,
    exact: true,
    component: Faturamento,
    isADM: true,
  },
  {
    path: `/sgo/admin/conciliacao`,
    isPrivate: true,
    exact: true,
    component: Conciliacao,
    isADM: true,
  },
  {
    path: `/sgo/admin/faturamento/insert`,
    isPrivate: true,
    exact: true,
    component: FaturamentoInsert,
    isADM: true,
  },
  {
    path: `/sgo/admin/reset_user`,
    isPrivate: true,
    exact: true,
    component: ResetUser,
    isADM: true,
  },
  {
    path: `/sgo/admin/hour_testing`,
    isPrivate: true,
    exact: true,
    component: HourTesting,
    isADM: true,
  },
  {
    path: `/sgo/version`,
    isPrivate: true,
    exact: true,
    component: Version,
    isADM: true,
  },
  {
    path: `/sgo/contabil`,
    isPrivate: true,
    exact: true,
    component: Contabil,
    isADM: false,
  },
  {
    path: `/sgo/contabil/avatar`,
    isPrivate: true,
    exact: true,
    component: Avatar,
    isADM: false,
  },
  {
    path: ``,
    isPrivate: false,
    exact: false,
    component: Page404,
    isADM: false,
  },
  // {
  //   path: `/dashboard/videoaulas`,
  //   isPrivate: true,
  //   exact: false,
  //   component: VideoAulas,
  //   isADM: false,
  // },
  // {
  //   path: `/sgo/suporte`,
  //   isPrivate: true,
  //   exact: true,
  //   component: SuporteSGO,
  //   isADM: false,
  // },
  // { route: <Route path="/" exact component={Home} />},
  // { route: <Route path="/tov" component={ServicoTOV} />},
  // { route: <Route path="/suporte" component={Suporte} />},
  // { route: <Route path="/aboutTOV" exact component={SobreTOV} />},
  // { route: <Route path="/aboutTOV/world" component={PeloMundo} />},
  // { route: <Route path="/books" component={ObrasFrei} />},
  // { route: <Route path="/oficinas" component={OficinasWeb} />},

  // { route: <Route path="/signin" component={SignIn} />},

  // { route: <Route path="/dashboard" exact component={Dashboard} isPrivate />},
  // { route: <Route path="/dashboard/anbs" component={ANBs} isPrivate />},
  // { route: <Route path="/dashboard/materiais" component={WebMateriais} isPrivate />},
  // { route: <Route path="/dashboard/oracoes" component={Oracoes} isPrivate />},
  // { route: <Route path="/dashboard/videoaulas" component={VideoAulas} isPrivate />},

  // { route: <Route path="/reset/:token" exact component={Reset} />},
  // { route: <Route path="/primeiro_acesso" exact component={FirstAccess} isPrivate />},

  // { route: <Route path="/sgo" exact component={SGOHome} isPrivate />},
  // { route: <Route path="/sgo/admin" exact component={ADM} isADM />},
  // { route: <Route component={Page404} />},
];

export default ORoutes;
