import React, { useState, useCallback, useEffect, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import RadioContainer from 'components/RadioContainer';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useSpring, animated } from 'react-spring';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import api from 'services/api';

import { FaRegFileExcel, FaTimes } from 'react-icons/fa';
import {
  Container,
  Content,
  ComboLocalProps,
  ComboProps,
  AlteredHeader,
  RemoveButton,
} from 'styles/sgo_wrappers';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { AnimatedDiv, AnimatedH3, Menu, SubMenu } from './styles';

interface LocalStorageProps {
  loc?: string;
  locdesc?: string;
  anb?: string;
  anbdesc?: string;
  zon?: string;
  zondesc?: string;
  cod?: string;
  desc?: string;
  typeOf?: string;
  year?: string;
  semester?: string;
}
interface CoordProps {
  cod: string;
  desc: string;
}

const Semestrais: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  // const { pathname } = useLocation();
  const { handlePermission } = useCredentials();

  const [loading] = useState(false);

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );
    return cod && desc
      ? { cod, desc }
      : ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [type, setType] = useState(() => {
    const { typeOf }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    return typeOf || (user.perfil === 'INT' ? 'ZON' : user.perfil);
  });
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboYears] = useState<ComboProps[]>(() => {
    let init = 2017;
    const current = new Date().getUTCFullYear();
    const years: ComboProps[] = [];
    while (init <= current) {
      years.push({ value: init.toString(), label: init.toString() });
      init += 1;
    }

    return years.sort((a, b) =>
      parseInt(a.value, 10) > parseInt(b.value, 10) ? -1 : 1,
    );
  });

  const [initialZon, setInitialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    let returnVal;

    if (user.perfil === 'INT') {
      if (zon) {
        returnVal = zon;
      } else {
        returnVal = 'Selecione';
      }

      return returnVal;
    }

    return zon || user.zoncod;
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    return anb || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    return loc || 'Selecione';
  });

  const [initialYear, setInitialYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    return year || new Date().getUTCFullYear().toString();
  });

  const [initialSemester, setInitialSemester] = useState(() => {
    const { semester }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    const currentMonth = new Date().getUTCMonth();
    return semester || (currentMonth < 6 ? '1' : '2');
  });

  const [displayOrcExc, setDisplayOrcExc] = useState(false);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    if (anb || user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
    );

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substr(0, 2) === zon.substr(0, 2)
          : item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  useEffect(() => {
    if (['ZON'].indexOf(user.perfil) > -1) {
      const { zon, zondesc }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_SEMESTRAIS}`,
      );

      setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
        zon: zon || user.zoncod,
        zondesc: zondesc || user.zondesc,
      });
    }
    handlePermission(['GUI']);
    getComboZON();
    getComboANB();
    getComboLOC();
  }, [
    getComboANB,
    getComboLOC,
    getComboZON,
    handlePermission,
    user.perfil,
    user.zoncod,
    user.zondesc,
  ]);

  /**
   * ANO < ATUAL => Mostra automaticamente
   * ANO = ATUAL && (SEMESTRE ATUAL = 2 & SEMESTRE SELECIONADO = 1) => Mostra automaticamente
   * ANO = ATUAL & SEMESTRE = ATUAL => INIBE
   */
  useEffect(() => {
    let status = false;
    const currYear = new Date().getUTCFullYear();
    const parsedYear = parseInt(initialYear, 10);
    const parsedSemester = parseInt(initialSemester, 10);
    const currentMonth = new Date().getUTCMonth();
    const currentSemester = currentMonth < 6 ? 1 : 2;
    if (
      parsedYear < currYear ||
      (parsedYear === currYear && currentSemester > parsedSemester)
    ) {
      status = true;
    }
    status = true; // força mostrar o botão, remover quando a lógica for implementada
    setDisplayOrcExc(status);
  }, [initialYear, initialSemester]);

  const handleZONselection = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value, text } = select.options[select.selectedIndex];

    setType('ZON');
    setInitialNac('Selecione');
    setInitialLoc('Selecione');
    setInitialZon(value);

    setLocalStorage(
      `${process.env.REACT_APP_SEMESTRAIS}`,
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
            zon: user.zoncod,
            zondesc: user.zondesc,
            typeOf: 'ZON',
          }
        : {
            cod: value,
            desc: text,
            zon: value,
            zondesc: text,
            typeOf: 'ZON',
          },
    );

    deleteLocalStorageItemKey(`${process.env.REACT_APP_SEMESTRAIS}`, [
      'anb',
      'anbdesc',
      'loc',
      'locdesc',
    ]);

    setCoord(
      user.perfil === 'ZON'
        ? {
            cod: user.zoncod,
            desc: user.zondesc,
          }
        : {
            cod: value,
            desc: text,
          },
    );

    setComboNacs(
      nacs.filter(
        (item) =>
          item.value.substr(0, 2) ===
          (user.perfil === 'ZON'
            ? user.zoncod.substr(0, 2)
            : value.substr(0, 2)),
      ),
    );
    setComboLocs([]);
  }, [nacs, user.perfil, user.zoncod, user.zondesc]);

  const handleNACselection = useCallback(() => {
    setType('NAC');

    deleteLocalStorageItemKey(`${process.env.REACT_APP_SEMESTRAIS}`, [
      'loc',
      'locdesc',
    ]);

    setInitialLoc('Selecione');

    if (user.perfil === 'NAC') {
      setCoord({
        cod: user.anbc,
        desc: user.anbdesc,
      });

      setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
        cod: user.anbc,
        desc: user.anbdesc,
        anb: user.anbc,
        anbdesc: user.anbdesc,
        typeOf: 'NAC',
      });

      setComboLocs(locs.filter((item) => item.anb === user.anbc));
      return;
    }

    let nac = formRef.current?.getFieldValue('comboANB');
    const index = comboNacs.findIndex((item) =>
      nac.length > 4 ? item.label === nac : item.value === nac,
    );

    if (nac.length > 4) {
      nac = comboNacs[index].value;
    }

    setInitialNac(comboNacs[index].label);

    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      cod: nac,
      desc: comboNacs[index].label,
      anb: nac,
      anbdesc: comboNacs[index].label,
      typeOf: 'NAC',
    });

    setCoord({
      cod: nac,
      desc: comboNacs[index].label,
    });

    setComboLocs(locs.filter((item) => item.anb === nac));
    setInitialLoc('Selecione');
  }, [comboNacs, locs, user.anbc, user.anbdesc, user.perfil]);

  const handleLOCselection = useCallback(() => {
    const loc = formRef.current?.getFieldValue('comboLOC');
    const index = comboLocs.findIndex((item) => item.value === loc);

    setType('LOC');

    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      cod: loc,
      desc: comboLocs[index].label,
      loc,
      locdesc: comboLocs[index].label,
      typeOf: 'LOC',
    });

    setCoord({
      cod: loc,
      desc: comboLocs[index].label,
    });

    setInitialLoc(comboLocs[index].label);
  }, [comboLocs]);

  const handleYEARselection = useCallback(() => {
    const year = formRef.current?.getFieldValue('comboAno');

    setInitialYear(year);
    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      year,
    });

    if (
      parseInt(year, 10) === new Date().getFullYear() &&
      new Date().getUTCMonth() < 6
    ) {
      setInitialSemester('1');
      setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
        semester: '1',
      });
    }
  }, []);

  const handleSEMESTERselection = useCallback(() => {
    const sem = formRef.current?.getFieldValue('semester');

    setInitialSemester(sem);
    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      semester: sem,
    });
  }, []);

  const handleDownload = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleF1 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f1${type}]`);

    if (link) {
      link.click();
    }
  }, [handleDownload, type]);

  const handleF2 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f2sem]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF2Int = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f2int]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF3 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f3]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF4 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f4]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF5 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f5]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF6 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f6]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleF7 = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=f7]`);

    if (link) {
      link.click();
    }
  }, [handleDownload]);

  const handleOrcEx = useCallback(() => {
    handleDownload();
    let xlsLink = 'orc';
    if (['NAC', 'ZON'].indexOf(user.perfil) > -1) {
      const select = formRef.current?.getFieldRef('comboLOC');
      const { value } = select.options[select.selectedIndex];

      if (value) {
        xlsLink = `${xlsLink}Loc`;
      }
    }
    const link = document.querySelector<HTMLAnchorElement>(`a[id=${xlsLink}]`);

    if (link) {
      link.click();
    }
  }, [handleDownload, user.perfil]);

  const handleEstatSec = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(
      `a[id=estatsec${type}]`,
    );

    if (link) {
      link.click();
    }
  }, [handleDownload, type]);

  const handleInd = useCallback(() => {
    handleDownload();
    const link = document.querySelector<HTMLAnchorElement>(`a[id=ind${type}]`);

    if (link) {
      link.click();
    }
  }, [handleDownload, type]);

  const removeButtonNac = useSpring({
    opacity: initialNac === 'Selecione' ? 0 : 1,
    pointerEvents: initialNac === 'Selecione' ? 'none' : 'all',
  });

  const removeButtonLoc = useSpring({
    opacity: initialLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  const anchor = useSpring({
    display: 'none',
  });

  const handleRemoveFilter = useCallback(() => {
    formRef.current?.setFieldValue('comboANB', '');
    formRef.current?.setFieldValue('comboLOC', '');
    setInitialNac('Selecione');
    setInitialLoc('Selecione');
    setComboLocs([]);
    deleteLocalStorageItemKey(`${process.env.REACT_APP_SEMESTRAIS}`, [
      'anb',
      'anbdesc',
      'loc',
      'locdesc',
    ]);
    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      typeOf: 'ZON',
    });
    setType('ZON');

    let coordSelected = {
      cod: user.zoncod,
      desc: user.zondesc,
    };

    if (user.perfil === 'INT') {
      const select = formRef.current?.getFieldRef('comboZON');
      const { value, text } = select.options[select.selectedIndex];

      coordSelected = {
        cod: value,
        desc: text,
      };
    }

    setCoord(coordSelected);
  }, [user.perfil, user.zoncod, user.zondesc]);

  const handleRemoveLocFilter = useCallback(() => {
    formRef.current?.setFieldValue('comboLOC', '');

    setInitialLoc('Selecione');
    setComboLocs([]);
    deleteLocalStorageItemKey(`${process.env.REACT_APP_SEMESTRAIS}`, [
      'loc',
      'locdesc',
    ]);
    setLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`, {
      typeOf: 'NAC',
    });
    setType('NAC');

    let coordSelected = {
      cod: user.anbc,
      desc: user.anbdesc,
    };

    if (['ZON', 'INT'].indexOf(user.perfil) > -1) {
      const select = formRef.current?.getFieldRef('comboANB');
      const { value, text } = select.options[select.selectedIndex];

      coordSelected = {
        cod: value,
        desc: text,
      };
    }

    setCoord(coordSelected);
  }, [user.anbc, user.anbdesc, user.perfil]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            {user.perfil === 'INT' && (
              <div>
                <span>
                  <p>Selecione a Zonal:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    initial={initialZon}
                    onChange={handleZONselection}
                  />
                  <RemoveButton
                    type="button"
                    // onClick={handleZONselection}
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    &nbsp;
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Nacional:</p>
                  <SelectV2
                    name="comboANB"
                    content={comboNacs}
                    initial={initialNac}
                    onChange={handleNACselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleRemoveFilter}
                    style={removeButtonNac}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}
            {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
              <div>
                <span>
                  <p>Selecione a Local:</p>
                  <SelectV2
                    name="comboLOC"
                    content={comboLocs}
                    initial={initialLoc}
                    onChange={handleLOCselection}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleRemoveLocFilter}
                    style={removeButtonLoc}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              </div>
            )}

            <div>
              <span>
                <p>Selecione o ano:</p>
                <SelectV2
                  name="comboAno"
                  content={comboYears}
                  initial={initialYear}
                  onChange={handleYEARselection}
                />
              </span>
            </div>
            <div>
              <span style={{ width: '350px', margin: '20px 0 0 0' }}>
                <p>Selecione o semestre:</p>
                <RadioContainer
                  title=""
                  onClick={handleSEMESTERselection}
                  name="semester"
                  content={[
                    {
                      id: 'xP',
                      value: '1',
                      label: 'Primeiro',
                    },
                    {
                      id: 'xS',
                      value: '2',
                      label: 'Segundo',
                    },
                  ]}
                  selected={initialSemester}
                />
              </span>
              {/* <span>
                <p>Selecione o semestre:</p>
                <RadioContainer
                  title=""
                  onClick={handleSEMESTERselection}
                  name="semester"
                  content={
                    parseInt(initialYear, 10) < new Date().getUTCFullYear() ||
                    (parseInt(initialYear, 10) ===
                      new Date().getUTCFullYear() &&
                      new Date().getUTCMonth() > 5)
                      ? [
                          {
                            id: 'xP',
                            value: '1',
                            label: 'Primeiro',
                          },
                          {
                            id: 'xS',
                            value: '2',
                            label: 'Segundo',
                          },
                        ]
                      : [
                          {
                            id: 'xP',
                            value: '1',
                            label: 'Primeiro',
                          },
                        ]
                  }
                  selected={initialSemester}
                />
                </span>
                */}
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>Planilhas Semestrais</div>
          <div>{user.perfil === 'INT' ? 'TOV Internacional' : coord.desc}</div>
        </AlteredHeader>

        <Menu>
          <SubMenu>
            <AnimatedH3 delay={0.1}>
              Informes Econômicos da Tesouraria
            </AnimatedH3>
            <AnimatedDiv duration={1.1} delay={0.15}>
              <button type="button" onClick={handleF1}>
                <FaRegFileExcel size={30} />
                <h3>F1 - Estatístico / Ofertas</h3>
              </button>
            </AnimatedDiv>

            <AnimatedDiv duration={1.1} delay={0.3}>
              <button type="button" onClick={handleF2}>
                <FaRegFileExcel size={30} />
                <h3>F2 - Relatório Econômico</h3>
              </button>
            </AnimatedDiv>

            {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
              <AnimatedDiv duration={1.1} delay={0.45}>
                <button type="button" onClick={handleF2Int}>
                  <FaRegFileExcel size={30} />
                  <h3>
                    F2 - Relatório para <strong>Internacional</strong>
                  </h3>
                </button>
              </AnimatedDiv>
            )}

            <AnimatedDiv
              duration={1.1}
              delay={['LOC', 'NAC'].indexOf(user.perfil) > -1 ? 0.45 : 0.6}
            >
              <button type="button" onClick={handleF3}>
                <FaRegFileExcel size={30} />
                <h3>F3 - Inventário de Materiais</h3>
              </button>
            </AnimatedDiv>
            {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 &&
              ['ZON', 'NAC'].indexOf(type) > -1 && (
                <AnimatedDiv
                  duration={1.1}
                  delay={user.perfil === 'NAC' ? 0.6 : 0.75}
                >
                  <button type="button" onClick={handleF4}>
                    <FaRegFileExcel size={30} />
                    <h3>F4 - Ativos/Móveis e Equipamentos</h3>
                  </button>
                </AnimatedDiv>
              )}

            <AnimatedDiv
              duration={1.1}
              delay={
                user.perfil === 'NAC' ? 0.75 : user.perfil === 'LOC' ? 0.6 : 0.9
              }
            >
              <button type="button" onClick={handleF5}>
                <FaRegFileExcel size={30} />
                <h3>F5 - Contas a Pagar / Receber</h3>
              </button>
            </AnimatedDiv>

            <AnimatedDiv
              duration={1.1}
              delay={
                user.perfil === 'NAC'
                  ? 0.9
                  : user.perfil === 'LOC'
                  ? 0.75
                  : 1.05
              }
            >
              <button type="button" onClick={handleF6}>
                <FaRegFileExcel size={30} />
                <h3>F6 - Biblioteca / Acervo</h3>
              </button>
            </AnimatedDiv>

            <AnimatedDiv
              duration={1.1}
              delay={
                user.perfil === 'NAC' ? 1.05 : user.perfil === 'LOC' ? 0.9 : 1.2
              }
            >
              <button type="button" onClick={handleF7}>
                <FaRegFileExcel size={30} />
                <h3>F7 - Realidade Econômica</h3>
              </button>
            </AnimatedDiv>

            {['ZON', 'NAC'].indexOf(user.perfil) > -1 && displayOrcExc && (
              <AnimatedDiv
                duration={1.1}
                delay={
                  user.perfil === 'NAC'
                    ? displayOrcExc
                      ? 1.2
                      : 0
                    : displayOrcExc
                    ? 1.35
                    : 0
                }
              >
                <button type="button" onClick={handleOrcEx}>
                  <FaRegFileExcel size={30} />
                  <h3>Orçamento Excedente</h3>
                </button>
              </AnimatedDiv>
            )}
          </SubMenu>

          <SubMenu>
            {/* <AnimatedH3 delay={0.1}> */}
            <AnimatedH3
              delay={
                user.perfil === 'NAC'
                  ? 1.35
                  : user.perfil === 'LOC'
                  ? 1.05
                  : 1.5
              }
            >
              Estatísticas / Indicadores de Performance
            </AnimatedH3>
            <AnimatedDiv
              duration={1.1}
              // delay={0.15}
              delay={
                user.perfil === 'NAC' ? 1.35 : user.perfil === 'LOC' ? 1.2 : 1.5
              }
            >
              <button type="button" onClick={handleEstatSec}>
                <FaRegFileExcel size={30} />
                <h3>Matriz Estatística</h3>
              </button>
            </AnimatedDiv>

            <AnimatedDiv
              duration={1.1}
              // delay={0.3}
              delay={
                user.perfil === 'NAC'
                  ? 1.65
                  : user.perfil === 'LOC'
                  ? 1.35
                  : 1.8
              }
            >
              <button type="button" onClick={handleInd}>
                <FaRegFileExcel size={30} />
                <h3>Indicadores da Refundação</h3>
              </button>
            </AnimatedDiv>
          </SubMenu>
        </Menu>
      </Content>
      <SGOFooter />

      <animated.a
        style={anchor}
        id={`f1${type}`}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f1_${type.toLowerCase()}.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F1 {type}
      </animated.a>

      <animated.a
        style={anchor}
        id="f2sem"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f2_sem.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          cycle: 'SEM',
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F2 SEM
      </animated.a>

      <animated.a
        style={anchor}
        id="f2int"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f2_int.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: user.perfil === 'INT' ? coord.cod : user.zoncod,
          year: initialYear,
          sem: initialSemester,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F2 INT
      </animated.a>

      <animated.a
        style={anchor}
        id="f3"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f3.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F3
      </animated.a>

      <animated.a
        style={anchor}
        id="f4"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f4.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: JSON.stringify(
            user.perfil === 'NAC'
              ? { anb: user.anbc }
              : getLocalStorage(`${process.env.REACT_APP_SEMESTRAIS}`),
          ),
          year: initialYear,
          sem: initialSemester,
          type: user.perfil === 'NAC' ? 'NAC' : type === 'LOC' ? 'NAC' : type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F4
      </animated.a>

      <animated.a
        style={anchor}
        id="f5"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f5.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F5
      </animated.a>

      <animated.a
        style={anchor}
        id="f6"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f6.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F6
      </animated.a>

      <animated.a
        style={anchor}
        id="f7"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_informe_f7.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        F7
      </animated.a>

      <animated.a
        style={anchor}
        id={`estatsec${type}`}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_matriz_estatistica.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        EstatSec
        {/* xls_estat_${type.toLowerCase()} */}
      </animated.a>

      <animated.a
        style={anchor}
        id={`ind${type}`}
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_indicadores_${type.toLowerCase()}.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Indicadores
      </animated.a>

      <animated.a
        style={anchor}
        id="orc"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_excedente.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Orçamento Excedente
      </animated.a>

      <animated.a
        style={anchor}
        id="orcLoc"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_excedente_loc.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          cod: coord.cod,
          year: initialYear,
          sem: initialSemester,
          type,
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Orçamento Excedente Local
      </animated.a>

      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
    </Container>
  );
};

export default Semestrais;
