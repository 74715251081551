import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import api from 'services/api';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';
import { InfoWrapper, TableWrapper } from './styles';

import * as I from './interfaces';

const Summaries: React.FC = () => {
  const { user } = useAuth();
  const { handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [gheader, setGHeader] = useState<string[]>([]);
  const [guias, setGuias] = useState<I.CountGuias[]>([]);
  const [oheader, setOHeader] = useState<string[]>([]);
  const [oficinas, setOficinas] = useState<I.CountOficinas[]>([]);
  const [sheader, setSHeader] = useState<string[]>([]);
  const [servicos, setServicos] = useState<I.CountServicos[]>([]);

  const getList = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/sgo/resumo_list.php');
    const { g, o, s, gh, oh, sh } = response.data;
    setGuias(g);
    setOficinas(o);
    setServicos(s);
    setGHeader(gh);
    setOHeader(oh);
    setSHeader(sh);
    setLoading(false);
  }, []);

  useEffect(() => {
    handlePermission(['ZON'], true);
    getList();
  }, [getList, handlePermission]);

  const gtotals = useMemo<I.CountGuias>(() => {
    return guias.reduce(
      (obj, { gagp, ga, gp, gd, gb, ge, gf, fg, sum }) => {
        const curr = { ...obj };
        curr.gagp += gagp;
        curr.ga += ga;
        curr.gp += gp;
        curr.gd += gd;
        curr.gb += gb;
        curr.ge += ge;
        curr.gf += gf;
        curr.fg += fg;
        curr.sum += sum;
        return curr;
      },
      {
        cod: 'TOT',
        desc: 'Total',
        gagp: 0,
        ga: 0,
        gp: 0,
        gd: 0,
        gb: 0,
        ge: 0,
        gf: 0,
        fg: 0,
        sum: 0,
      },
    );
  }, [guias]);

  const ototals = useMemo<I.CountOficinas>(() => {
    return oficinas.reduce(
      (obj, { ta, tc, td, tj, sa, sj, cc, epg, sum }) => {
        const curr = { ...obj };
        curr.ta += ta;
        curr.tc += tc;
        curr.td += td;
        curr.tj += tj;
        curr.sa += sa;
        curr.sj += sj;
        curr.cc += cc;
        curr.epg += epg;
        curr.sum += sum;
        return curr;
      },
      {
        cod: 'TOT',
        desc: 'Total',
        ta: 0,
        tc: 0,
        td: 0,
        tj: 0,
        sa: 0,
        sj: 0,
        cc: 0,
        epg: 0,
        sum: 0,
      },
    );
  }, [oficinas]);

  const stotals = useMemo<I.CountServicos>(() => {
    return servicos.reduce(
      (
        obj,
        { ai, an, cb, cg, co, ed, gc, ja, je, jf, pe, rc, rd, re, sum },
      ) => {
        const curr = { ...obj };

        curr.ai += ai;
        curr.an += an;
        curr.cb += cb;
        curr.cg += cg;
        curr.co += co;
        curr.ed += ed;
        curr.gc += gc;
        curr.ja += ja;
        curr.je += je;
        curr.jf += jf;
        curr.pe += pe;
        curr.rc += rc;
        curr.rd += rd;
        curr.re += re;
        curr.sum += sum;
        return curr;
      },
      {
        cod: 'TOT',
        desc: 'Total',
        ai: 0,
        an: 0,
        cb: 0,
        cg: 0,
        co: 0,
        ed: 0,
        gc: 0,
        ja: 0,
        je: 0,
        jf: 0,
        pe: 0,
        rc: 0,
        rd: 0,
        re: 0,
        sum: 0,
      },
    );
  }, [servicos]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>Resumo Estatístico</p>
          </div>
          <div>
            <p>{user.zondesc}</p>
          </div>
        </AlteredHeader>
        {!loading ? (
          <>
            <InfoWrapper>
              <h2>Guias</h2>
              <TableWrapper>
                <table>
                  <thead>
                    <tr>
                      {gheader.map((item) => (
                        <th key={`gHeader-${item}`}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {guias.map((item) => (
                      <tr key={`gContent-${item.cod}`}>
                        <td>{item.desc}</td>
                        {/* <td>{item.sum}</td> */}
                        <td>{item.gagp}</td>
                        <td>{item.ga}</td>
                        <td>{item.gp}</td>
                        <td>{item.gd}</td>
                        <td>{item.gb}</td>
                        <td>{item.ge}</td>
                        <td>{item.gf}</td>
                        <td>{item.fg}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>{gtotals.desc}</td>
                      {/* <td>{gtotals.sum}</td> */}
                      <td>{gtotals.gagp}</td>
                      <td>{gtotals.ga}</td>
                      <td>{gtotals.gp}</td>
                      <td>{gtotals.gd}</td>
                      <td>{gtotals.gb}</td>
                      <td>{gtotals.ge}</td>
                      <td>{gtotals.gf}</td>
                      <td>{gtotals.fg}</td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </InfoWrapper>

            <InfoWrapper>
              <h2>Serviços</h2>
              <TableWrapper>
                <table>
                  <thead>
                    <tr>
                      {oheader.map((item) => (
                        <th key={`oHeader-${item}`}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {oficinas.map((item) => (
                      <tr key={`oContent-${item.cod}`}>
                        <td>{item.desc}</td>
                        <td>{item.sum}</td>
                        <td>{item.cc}</td>
                        <td>{item.epg}</td>
                        <td>{item.sa}</td>
                        <td>{item.sj}</td>
                        <td>{item.ta}</td>
                        <td>{item.tc}</td>
                        <td>{item.td}</td>
                        <td>{item.tj}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>{ototals.desc}</td>
                      <td>{ototals.sum}</td>
                      <td>{ototals.cc}</td>
                      <td>{ototals.epg}</td>
                      <td>{ototals.sa}</td>
                      <td>{ototals.sj}</td>
                      <td>{ototals.ta}</td>
                      <td>{ototals.tc}</td>
                      <td>{ototals.td}</td>
                      <td>{ototals.tj}</td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </InfoWrapper>

            <InfoWrapper>
              <h2>Atividades</h2>
              <TableWrapper style={{ marginBottom: '30px' }}>
                <table>
                  <thead>
                    <tr>
                      {sheader.map((item) => (
                        <th key={`sHeader-${item}`}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {servicos.map((item) => (
                      <tr key={`sContent-${item.cod}`}>
                        <td>{item.desc}</td>
                        <td>{item.sum}</td>
                        <td>{item.ai}</td>
                        <td>{item.an}</td>
                        <td>{item.cb}</td>
                        <td>{item.cg}</td>
                        <td>{item.co}</td>
                        <td>{item.ed}</td>
                        <td>{item.gc}</td>
                        <td>{item.ja}</td>
                        <td>{item.je}</td>
                        <td>{item.jf}</td>
                        <td>{item.pe}</td>
                        <td>{item.rc}</td>
                        <td>{item.rd}</td>
                        <td>{item.re}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>{stotals.desc}</td>
                      <td>{stotals.sum}</td>
                      <td>{stotals.ai}</td>
                      <td>{stotals.an}</td>
                      <td>{stotals.cb}</td>
                      <td>{stotals.cg}</td>
                      <td>{stotals.co}</td>
                      <td>{stotals.ed}</td>
                      <td>{stotals.gc}</td>
                      <td>{stotals.ja}</td>
                      <td>{stotals.je}</td>
                      <td>{stotals.jf}</td>
                      <td>{stotals.pe}</td>
                      <td>{stotals.rc}</td>
                      <td>{stotals.rd}</td>
                      <td>{stotals.re}</td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </InfoWrapper>
          </>
        ) : null}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Summaries;
