import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';

const appear = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
  }
to {
  opacity:  1;
  transform: translateX(0px);
  }
`;

export const Wrapper = styled.section`
  display: flex;
  width: 100vw;
  flex-direction: column;
  background: #d6d6d6;
  margin: 10px 0;
  padding: 20px 0;

  strong {
    color: #8a0002;
    margin: 0 auto;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;

  > div {
    > p {
      font-size: 16px;
      font-weight: bold;
      color: #8a0002;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const SearchButton = styled.button`
  --bgcolor: #464646;
  --color: #efefef;
  width: 180px;
  background: var(--bgcolor);
  font-size: 16px;
  border: 2px solid var(--bgcolor);
  border-radius: 3px;
  color: var(--color);
  animation: ${appear} 2s ease forwards;

  transition: all 0.35s;

  margin: 25px auto 0;

  &:hover {
    --bgcolor: #332e2e;
    --color: #fff;
  }
`;

export const RemoveButton = styled(animated.button)`
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 3px auto;
  border: 0;
  background: transparent;
  margin: 5px auto;

  p {
    color: #c53030;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    transition: color 0.35s ease;
  }

  svg {
    color: #8a0002;
  }

  &:hover {
    p {
      color: #8a0002;
    }
  }
`;

export const ComponentContainer = styled.div`
  position: relative;
  margin: 15px 0;
  width: 90vw;
  /* max-width: 000px; */
  display: grid;
  /* grid: auto / 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(58rem, auto));
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
`;

export const ComponentContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid #332e2e;
  border-radius: 5px;
  background: #efefef;
  box-shadow: 3px 5px 10px 2px rgba(0, 0, 0, 0.5);

  h2 {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  > span {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    strong {
      & + p,
      & + strong {
        margin-left: 5px;
        /* color: #dd5522; */
      }
    }

    p {
      display: flex;
      align-items: center;
      svg {
        margin: 0 5px 0 15px;
        color: #c6c6c6;
      }
      & + strong {
        margin-left: 15px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }
  transition: border-color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;

  &:hover {
    border-color: #8a0002;
    z-index: 1;
    transform: scale(1.2);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.75);
  }

  @media screen and (max-width: 680px) {
    &:hover {
      border-color: #332e2e;

      transform: scale(1);
      box-shadow: 3px 5px 10px 2px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Schedule = styled.section`
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  transition: all 0.5s;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;

    span + span {
      margin: 5px 0 0 0;
    }
  }
`;

export const ScheduleItem = styled.span`
  display: flex;
  align-items: center;

  strong {
    & + p,
    & + strong {
      margin-left: 5px;
    }
  }

  p {
    display: flex;
    align-items: center;

    & + strong {
      margin-left: 15px;
    }
  }

  & + span {
    margin: 0 0 0 15px;
  }
`;
