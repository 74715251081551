import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { subDays, isAfter } from 'date-fns';

import { Container, AlteredContent, ComboProps } from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import SelectV2 from 'components/SelectV2';
import { ShowProps } from 'styles/dialog_consult';
import { DeleteProps } from 'styles/dialog_delete';

import api from 'services/api';
import * as O from 'styles/option_buttons';
import * as S from 'styles/dialog_consult';
import * as D from 'styles/dialog_delete';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import {
  YearContainer,
  LeftSVG,
  RightSVG,
  Grid,
  GridItem,
  ModalContent,
} from './styles';

interface EntradasProps {
  matcod: string;
  matdesc: string;
  rawDT: string;
  dtreceb: string;
  tporig: string;
  orig: string;
  qtde: string;
  just: string;
  origdesc: string;
  year: number;
  zonreg: string;
  keyList: string;
}

const Entradas: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [rawEntradas, setRawEntradas] = useState<EntradasProps[]>([]);
  const [filterEntradas, setFilterEntradas] = useState<EntradasProps[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const getEntradas = useCallback(async () => {
    try {
      const response = await api.get('/sgo/entradas_list.php');

      setFilterEntradas(
        response.data.filter(
          (item: EntradasProps) =>
            item.year === year && item.zonreg === user.zoncod,
        ),
      );

      setRawEntradas(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [user.zoncod, year, errorHandling]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value } = select.options[select.selectedIndex];
    // const zon = formRef?.current?.getFieldValue('comboZON');

    setFilterEntradas(
      rawEntradas.filter(
        (item: EntradasProps) => item.zonreg === value && item.year === year,
      ),
    );
  }, [rawEntradas, year]);

  const handleSubYear = useCallback(() => {
    setYear(year - 1);

    // const defineIt = rawEntradas.filter(
    //   (item: EntradasProps) => item.year === item.year - 1,
    // );
    // setFilterEntradas(defineIt);
  }, [year]);

  const handleAddYear = useCallback(() => {
    setYear(year + 1);
    // const defineIt = rawEntradas.filter(
    //   (item: EntradasProps) => item.year === item.year + 1,
    // );
    // setFilterEntradas(defineIt);
  }, [year]);

  useEffect(() => {
    if (['ZON', 'INT'].indexOf(user.perfil) < 0) {
      addToast({
        type: 'error',
        title: 'Ação não permitida',
        description: 'Área restrita.',
      });

      history.replace('/sgo');
      return;
    }

    getEntradas();
    getComboZON();
  }, [history, user.perfil, addToast, getEntradas, getComboZON]);

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);

          setDeleteDiag({ open: false });
          await api.get(
            `/sgo/entradas_delete.php?data=${JSON.stringify(values)}`,
          );

          setFilterEntradas(
            filterEntradas.filter(
              (item: EntradasProps) => item.keyList !== values.keyList,
            ),
          );
          setRawEntradas(
            rawEntradas.filter(
              (item: EntradasProps) => item.keyList !== values.keyList,
            ),
          );

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Registro de entrada excluído com sucesso!',
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [errorHandling, addToast, filterEntradas, rawEntradas],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      {user.perfil === 'ZON' && <IncludeButton />}
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={user.perfil === 'INT'}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por ZONAL:</p>
                  <SelectV2
                    name="comboZON"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={user.zoncod}
                  />
                </span>
              )}
            </div>
          </Form>
        }
      />
      <YearContainer>
        {year > new Date().getFullYear() - 5 && (
          <LeftSVG onClick={handleSubYear} />
        )}
        <h2>{year}</h2>
        {year < new Date().getFullYear() && (
          <RightSVG onClick={handleAddYear} />
        )}
      </YearContainer>
      <AlteredContent pixels="250px">
        {filterEntradas.length === 0 ? (
          <h3 style={{ paddingTop: '15px' }}>
            Não há registros a serem exibidos
          </h3>
        ) : (
          <Grid>
            {filterEntradas.map((item) => (
              <GridItem key={`${item.keyList}`}>
                <span>
                  <strong>
                    {item.matcod} - {item.matdesc}
                  </strong>
                </span>
                <span>
                  <p>
                    Recebido em: <strong>{item.dtreceb}</strong>
                  </p>
                  <p>
                    Quantidade recebida: <strong>{item.qtde}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Origem: <strong>{item.origdesc}</strong>
                  </p>
                </span>
                <O.GridOptions>
                  <O.Show
                    onClick={() =>
                      setShow({
                        title: `${item.matcod} - ${item.matdesc}`,
                        open: true,
                        content: (
                          <ModalContent>
                            <span>
                              <p>
                                Recebido em: <strong>{item.dtreceb}</strong>
                              </p>
                              <p>
                                Quantidade recebida:{' '}
                                <strong>{item.qtde}</strong>
                              </p>
                            </span>
                            <span>
                              <p>
                                Origem: <strong>{item.origdesc}</strong>
                              </p>
                            </span>
                            <span>
                              <p>
                                Justificativa: <strong>{item.just}</strong>
                              </p>
                            </span>
                          </ModalContent>
                        ),
                      })
                    }
                  >
                    <FaSearch />
                  </O.Show>
                  {isAfter(new Date(item.rawDT), subDays(new Date(), 365)) &&
                    user.perfil === 'ZON' && (
                      <O.Delete
                        onClick={() =>
                          setDeleteDiag({
                            open: true,
                            content: (
                              <div style={{ fontSize: '16px' }}>
                                <p>
                                  Ao clicar em confirmar, o registro:&nbsp;
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      marginTop: '10px',
                                      marginBottom: '10px',
                                      fontStyle: 'italic',
                                      padding: '10px',
                                      borderWidth: '2px',
                                      borderColor: '#332e2e',
                                      borderRadius: '5px',
                                      borderStyle: 'solid',
                                    }}
                                  >
                                    <span>
                                      <strong>
                                        {item.matcod} - {item.matdesc}
                                      </strong>
                                    </span>
                                    <span>
                                      <p>
                                        Recebido em:{' '}
                                        <strong>{item.dtreceb}</strong>
                                      </p>
                                      <p>
                                        Quantidade recebida:{' '}
                                        <strong>{item.qtde}</strong>
                                      </p>
                                    </span>
                                    <span>
                                      <p>
                                        Origem: <strong>{item.origdesc}</strong>
                                      </p>
                                    </span>
                                  </div>
                                  será excluído&nbsp;
                                  <span style={{ fontStyle: 'italic' }}>
                                    <strong>permanentemente</strong>.
                                  </span>
                                </p>

                                <h2
                                  style={{
                                    color: '#c53030',
                                    marginTop: '10px',
                                  }}
                                >
                                  Se estiver seguro dessa ação, clique em
                                  confirmar
                                </h2>
                              </div>
                            ),
                            values: {
                              keyList: item.keyList,
                              matcod: item.matcod,
                              recData: item.rawDT,
                              qtde: item.qtde,
                            },
                          })
                        }
                      >
                        <FaTimes />
                      </O.Delete>
                    )}
                </O.GridOptions>
              </GridItem>
            ))}
          </Grid>
        )}

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm
              type="button"
              onClick={() => handleExclusion(deleteDiag.values)}
            >
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Entradas;
