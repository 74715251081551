import React, { useEffect, useState, HTMLAttributes } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import { Container } from './styles';
import { useScrolling } from '../../hooks/scrolling';

interface IncludeButtonProps extends HTMLAttributes<HTMLElement> {
  path?: string;
  options?: object;
  isButton?: boolean;
}

const IncludeButton: React.FC<IncludeButtonProps> = ({
  children,
  path,
  isButton = false,
  options = {},
}) => {
  const { scrolled } = useScrolling();
  const location = useLocation();
  const [position, setPosition] = useState<number>(scrolled);

  useEffect(() => {
    if (scrolled > 100) {
      setPosition(100);
      return;
    }

    setPosition(scrolled);
  }, [scrolled]);

  return (
    <Container amountScrolled={position}>
      {!isButton ? (
        <Link
          title="Incluir"
          to={{
            pathname: path || `${location.pathname}/insert`,
            state: { options },
          }}
        >
          <FaPlusCircle />
          {children}
        </Link>
      ) : (
        children
      )}
    </Container>
  );
};

export default IncludeButton;
