import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

import { animated } from 'react-spring';

interface ButtonProps {
  bgcolor: string;
  animated: boolean | undefined;
  delay: number;
}

const appear = keyframes`
from { opacity: 0} to { opacity:  1}
`;

export const Container = styled(animated.button)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.bgcolor};
  color: #efefef;
  padding: 10px 20px;
  width: 250px;
  border: 2px solid transparent;
  border-radius: 5px;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;

  transition: background-color 0.35s ease, color 0.35s ease;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  ${(props) =>
    props.animated &&
    css`
      opacity: 0;
      animation: ${appear} 1s ease forwards;
    `}

  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay}s;
    `}

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0 0 0 5px;
  }

  &:hover {
    background-color: ${(props) => shade(0.2, props.bgcolor)};
    color: #fff;
  }
`;
