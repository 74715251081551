import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaPencilAlt,
  FaPhone,
  FaSearch,
  FaUsersCog,
} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import api from 'services/api';
import * as O from 'styles/option_buttons';
import { Title, GridZon, GridNac, GridItem, GridFront } from './styles';

export interface CoordProps {
  address: {
    street: string;
    district: string;
    city: string;
    uf: string;
    code: string;
  };
  agencia: string;
  banco: string;
  cnpj: string;
  code: string;
  conta: string;
  coor: {
    gseq: number;
    name: string;
  };
  delivery: {
    street: string;
    district: string;
    city: string;
    uf: string;
    code: string;
  };
  desc: string;
  eesc: {
    gseq: number;
    name: string;
  };
  ejov: {
    gseq: number;
    name: string;
  };
  emat: {
    gseq: number;
    name: string;
  };
  escrit: string;
  facebook: string;
  mail: string;
  phone: string;
  secr: {
    gseq: number;
    name: string;
  };
  status: string;
  teso: {
    gseq: number;
    name: string;
  };
  updated_at: string;
  web: string;
}

const Nacionais: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { errorHandling } = useCredentials();

  const [nacs, setNacs] = useState<CoordProps[]>([]);
  const [zons, setZons] = useState<CoordProps[]>([]);
  const [filterNacs, setFilterNacs] = useState<CoordProps[]>([]);
  const [filterZons, setFilterZons] = useState<CoordProps[]>([]);

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);

  const getCoords = useCallback(async () => {
    try {
      const response = await api.get('/sgo/anbs_list.php');

      const nacionais = response.data.filter(
        (item: CoordProps) =>
          item.code.substr(-2) !== '99' && item.code.substr(-2) !== 'IN',
      );

      const zonais = response.data.filter(
        (item: CoordProps) =>
          item.code.substr(-2) === '99' ||
          (user.perfil === 'ZON' && item.code.substr(-2) === 'IN'),
      );

      setNacs(nacionais);
      setFilterNacs(
        nacionais.filter(
          (item: CoordProps) =>
            item.code.substr(0, 2) === user.zoncod.substr(0, 2),
        ),
      );
      setZons(zonais);

      setFilterZons(
        zonais.filter(
          (item: CoordProps) =>
            item.code.substr(0, 2) === user.zoncod.substr(0, 2),
        ),
      );
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, user.perfil, user.zoncod]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  useEffect(() => {
    getCoords();
    getComboZON();
  }, [getCoords, getComboZON]);

  const handleFilter = useCallback(() => {
    return null;
  }, []);

  const handleZONselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboZON');
    const { value } = select.options[select.selectedIndex];

    setFilterZons(zons.filter((item: CoordProps) => item.code === value));
    setFilterNacs(
      nacs.filter(
        (item: CoordProps) => item.code.substr(0, 2) === value.substr(0, 2),
      ),
    );
  }, [zons, nacs]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={user.perfil === 'INT'}
        filterContent={
          <Form ref={formRef} onSubmit={handleFilter}>
            <div>
              <span>
                <p>Filtre por ZONAL:</p>
                <SelectV2
                  name="comboZON"
                  content={comboZons}
                  onChange={handleZONselect}
                  initial={user.zoncod}
                />
              </span>
            </div>
          </Form>
        }
      />
      <Content>
        <Title>Zonal</Title>
        <GridZon lenght={filterZons.length}>
          {filterZons.map((item) => (
            <GridItem key={item.code}>
              <GridFront>
                <h5>{item.desc}</h5>
                <div>
                  <FaMapMarkerAlt />
                  <p>
                    {item.escrit} - {item.address.uf}
                  </p>
                </div>

                {item.phone && (
                  <div>
                    <FaPhone />
                    <p>{item.phone}</p>
                  </div>
                )}

                {item.mail && (
                  <div>
                    <a
                      href={`mailto:${item.mail}?subject=Contato via SGO`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaEnvelope /> <p>{item.mail}</p>
                    </a>
                  </div>
                )}
              </GridFront>
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { coord: item },
                    }}
                    title="Consultar coordenação"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {(user.adm ||
                  (user.perfil === 'ZON' &&
                    user.zoncod.substr(0, 2) === item.code.substr(0, 2))) && (
                  <>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { coord: item },
                      }}
                      title="Alterar coordenação"
                    >
                      <FaPencilAlt />
                    </O.Update>
                    {item.code.substr(-2) === '99' && (
                      <O.Team
                        to={{
                          pathname: `${pathname}/team`,
                          state: { coord: item },
                        }}
                        title="Alterar equipe"
                      >
                        <FaUsersCog />
                      </O.Team>
                    )}
                  </>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridZon>
        <Title>Nacionais</Title>
        <GridNac>
          {filterNacs.map((item) => (
            <GridItem key={item.code}>
              <GridFront>
                <h5>{item.desc}</h5>
                <div>
                  <FaMapMarkerAlt />
                  <p>
                    {item.escrit} - {item.address.uf}
                  </p>
                </div>

                {item.phone && (
                  <div>
                    <FaPhone />
                    <p>{item.phone}</p>
                  </div>
                )}

                {item.mail && (
                  <div>
                    <a
                      href={`mailto:${item.mail}?subject=Contato via SGO`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaEnvelope /> <p>{item.mail}</p>
                    </a>
                  </div>
                )}
              </GridFront>
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { coord: item },
                    }}
                    title="Consultar coordenação"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {(user.adm ||
                  (user.perfil === 'ZON' &&
                    user.zoncod.substring(0, 2) ===
                      item.code.substring(0, 2)) ||
                  (user.perfil === 'NAC' && user.anbc === item.code)) && (
                  <>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { coord: item },
                      }}
                      title="Alterar coordenação"
                    >
                      <FaPencilAlt />
                    </O.Update>
                    <O.Team
                      to={{
                        pathname: `${pathname}/team`,
                        state: { coord: item },
                      }}
                      title="Alterar equipe"
                    >
                      <FaUsersCog />
                    </O.Team>
                  </>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridNac>
        {/* <h1>ZONAL</h1>
                                {zons.map((item) => (
                                  <div>{item.desc}</div>
                                  ))}
                                  <h1>NACIONAIS</h1>
                                  {nacs.map((item) => (
                                    <div>{item.desc}</div>
                                  ))} */}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default Nacionais;
