import React, { useCallback, useEffect, useState } from 'react';

import { Container, AlteredContent, Header } from 'styles/sgo_wrappers';
import { Link, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FaRegFileExcel, FaRegListAlt } from 'react-icons/fa';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';

import { Menu, AnimatedDiv } from './styles';

const PedidoMenu: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { checkCredentials, handlePermission } = useCredentials();
  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  useEffect(() => {
    handlePermission(['ZON'], true);
    checkCredentials();
  }, [checkCredentials, handlePermission]);

  const showDialog = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDownload = useCallback(
    (ev) => {
      showDialog();
      const { id } = ev.currentTarget.dataset;
      const link = document.querySelector<HTMLAnchorElement>(`a[id=${id}]`);

      if (link) {
        link.click();
      }
    },
    [showDialog],
  );

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Menu de Pedidos de Material</Header>
      <AlteredContent pixels="231px">
        <Menu>
          {/* <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('menu', 'estoque')}`}>
              <FaRegListAlt size={30} />
              <h3>Estoque ANB x Pedido Colocado</h3>
            </Link>
          </AnimatedDiv> */}

          <AnimatedDiv duration={1.1} delay={0.35}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>Relação dos Pedidos</h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.7}>
            <Link to={`${pathname.replace('menu', 'fornecedor')}`}>
              <FaRegListAlt size={30} />
              <h3>Agrupamento por Fornecedor</h3>
            </Link>
          </AnimatedDiv>
          {user.perfil === 'ZON' ? (
            <AnimatedDiv duration={1.1} delay={1.05}>
              <button
                type="button"
                data-id="supplierXLS"
                onClick={handleDownload}
              >
                <FaRegFileExcel size={30} />
                <h3>Lista por Fornecedor</h3>
              </button>
              {/* <Link to={`${pathname.replace('menu', 'fornecedor')}`}>
              <FaRegFileExcel size={30} />
              <h3>Lista por Fornecedor</h3>
            </Link> */}
            </AnimatedDiv>
          ) : null}
        </Menu>
      </AlteredContent>
      <SGOFooter />
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>

      {user.perfil === 'ZON' ? (
        <a
          style={{ display: 'none' }}
          id="supplierXLS"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_agrupamento_fornecedor.php?ZONAL=${user.zoncod.substr(
            0,
            2,
          )}`}
          rel="noreferrer noopener"
          target="_top"
        >
          Lista por Fornecedor
        </a>
      ) : null}
    </Container>
  );
};

export default PedidoMenu;
