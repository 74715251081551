import styled from 'styled-components';
import { shade } from 'polished';

interface GridProps {
  amount: number;
}

interface GridItemProps {
  borderColor: string;
}

export const FormContent = styled.div`
  display: flex;
  padding: 5px 5px 10px 5px;
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  /* box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5); */

  span {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      color: #8a0002;
      font-weight: 500;
    }
    & + span {
      margin: 0 0 0 10px;
    }
  }
`;

export const GridContainer = styled.div<GridProps>`
  margin: 15px 0;
  display: grid;
  grid: auto / ${(props) =>
      props.amount === 1
        ? '1fr'
        : props.amount === 2
        ? '1fr 1fr'
        : '1fr 1fr 1fr'};
  width: 100vw;

  justify-content: center;
  padding: 10px 5px;
  row-gap: 15px;

  @media screen and (max-width: 1100px) {
    grid: auto / ${(props) => (props.amount === 1 ? '1fr' : '1fr 1fr')};
  }
  @media screen and (max-width: 750px) {
    grid: auto / 1fr;
    padding: 10px 0px;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  --color: ${(props) => props.borderColor || '#efefef'};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 360px;

  background: #fff;
  border: 3px solid var(--color);
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: border-radius 0.35s, border-left-width 0.35s,
    border-left-width 0.35s, border-color 0.35s, box-shadow 0.35s, width 0.35s;

  span {
    font-size: 16px;
    display: flex;
    & + span {
      margin: 10px 0 0 0;
    }
    p {
      & + p {
        margin: 0 0 0 5px;
      }
    }
  }

  &:hover {
    border-color: ${(props) => shade(0.2, props.borderColor || '#efefef')};
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 400px) {
    width: 100vw;
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      & + p {
        margin: 0 0 0 10px;
      }
    }
    & + span {
      margin: 10px 0 0 0;
    }
  }
`;
