interface ErrorReturned {
  code: string | number;
  message: string;
}

export default function errorHandling(error: Error): ErrorReturned {
  const code = error.message.substr(-3);
  let message = '';

  if (code === '404') {
    message = 'Token não encontrado';
  } else if (code === '422') {
    message = 'Acesso inválido';
  } else if (code === '401') {
    message = 'Acesso expirado';
  } else if (code === '500') {
    message = 'Limite de tempo excedido';
  } else {
    message = 'Erro interno. Acione o suporte ao SGO';
  }

  return { code, message };
}
