export function phoneMasking(e: React.FormEvent<HTMLInputElement>): void {
  let phone = e.currentTarget.value;

  phone = phone.replace(/^\s/g, '');
  phone = phone.replace(/\s\s+/g, ' ');
  phone = phone.replace(/\D/g, '');
  phone = phone.replace(/^(\d{2})/, '($1) ');

  if (phone.substr(5, 1) === '9') {
    e.currentTarget.maxLength = 15;

    phone = phone.replace(/(\d{5})(\d)/, '$1-$2');
  } else {
    e.currentTarget.maxLength = 14;

    phone = phone.replace(/(\d{4})(\d)/, '$1-$2');
  }

  e.currentTarget.value = phone;
}

export function currencyMasking(e: React.FormEvent<HTMLInputElement>): void {
  let currency = e.currentTarget.value;
  currency = parseFloat(
    currency
      .replace(/(.*){1}/, '0$1')
      .replace(/[^\d]/g, '')
      .replace(/(\d\d?)$/, '.$1'),
  ).toFixed(2);

  e.currentTarget.value = currency;
}

export function cnpjMasking(e: React.FormEvent<HTMLInputElement>): void {
  e.currentTarget.maxLength = 18;
  let cnpj = e.currentTarget.value;

  cnpj = cnpj.replace(/\D/g, '');
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
  e.currentTarget.value = cnpj;
}

export function cpfMasking(e: React.FormEvent<HTMLInputElement>): void {
  e.currentTarget.maxLength = 14;
  let cpf = e.currentTarget.value;

  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  e.currentTarget.value = cpf;
}

export function cepMasking(e: React.FormEvent<HTMLInputElement>): void {
  e.currentTarget.maxLength = 9;
  let cep = e.currentTarget.value;

  cep = cep.replace(/\D/g, '');
  cep = cep.replace(/^(\d{5})(\d)/, '$1-$2');

  e.currentTarget.value = cep;
}

export function numberMasking(e: React.FormEvent<HTMLInputElement>): void {
  let number = e.currentTarget.value;

  number = number.replace(/^0/g, '').replace(/[^km0-9\s]/gi, '');

  e.currentTarget.value = number;
}

export function onlyNumbers(e: React.FormEvent<HTMLInputElement>): void {
  let number = e.currentTarget.value;

  number = number.replace(/^0/g, '').replace(/[^0-9\s]/gi, '');

  e.currentTarget.value = number;
}

export function allowZeroMasking(e: React.FormEvent<HTMLInputElement>): void {
  let number = e.currentTarget.value;

  number = parseInt(number, 10).toString();
  e.currentTarget.value = number;
}

export function nameMasking(e: React.FormEvent<HTMLInputElement>): void {
  let name = e.currentTarget.value;

  name = name
    .replace(/^\s/g, '')
    .replace(/[\d]/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/[[\]#&$\\><`~'";:%,]/g, '');

  e.currentTarget.value = name;
}

export function alphaMasking(e: React.FormEvent<HTMLInputElement>): void {
  let alpha = e.currentTarget.value;

  alpha = alpha
    .replace(/^\s/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/[[\]#&$\\><`~'";:]/g, '');

  e.currentTarget.value = alpha;
}

export function alphaMaskingExtra(e: React.FormEvent<HTMLInputElement>): void {
  let alpha = e.currentTarget.value;

  alpha = alpha
    .replace(/^\s/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/[[\]#&$\\><`´~'";:,.^]/g, '');

  e.currentTarget.value = alpha;
}
