import styled, { css } from 'styled-components';

interface AccordeonProps {
  active?: boolean;
  height?: string;
}

export const Container = styled.div<AccordeonProps>`
  width: 90vw;
  max-width: 1000px;

  background: #e6e6e6;
  border-radius: 10px;
  overflow: hidden;
  height: auto;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.75s ease-in-out;

  ${(props) =>
    props.active &&
    css`
      box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.5);
      section {
        background: #8a0002;
        svg {
          transform: rotate(90deg);
        }
      }

      div {
        height: ${props.height ? `${props.height}` : '500px'};
        padding: 10px 20px;
      }
    `}
`;

export const HeaderContent = styled.section`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  height: 60px;
  color: #fff;
  background: #c53030;
  transition: all 0.75s ease-in-out;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 15px;
    transition: all 0.25s;
  }
`;

export const Content = styled.div<AccordeonProps>`
  height: 0px;
  max-height: ${(props) => (props.height ? `${props.height}` : '500px')};
  padding: 0px 20px;
  overflow-y: auto;
  overflow-x: hidden;

  transition: all 0.75s ease-in-out;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: #8a0002;

    &:hover {
      background: #c53030;
    }
  }
`;
