import React, { createContext, useContext, useCallback, useState } from 'react';
// import { uuid } from 'uuidv4';
import { v4 } from 'uuid';

import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info' | 'warning';
  title: string;
  description?: string;
  seconds?: number;
  showIcon?: boolean;
}

interface ToastHookData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastHook = createContext<ToastHookData>({} as ToastHookData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const soundSuccess = new Audio(
    `${process.env.REACT_APP_NOTIFICATION_SOUNDS}/success.mp3`,
  );
  const soundError = new Audio(
    `${process.env.REACT_APP_NOTIFICATION_SOUNDS}/error.mp3`,
  );

  const addToast = useCallback(
    ({
      type,
      title,
      description,
      seconds = 5,
      showIcon = false,
    }: Omit<ToastMessage, 'id'>) => {
      const id = v4();

      const toast = {
        id,
        type,
        title,
        description,
        seconds,
        showIcon,
      };

      if (type === 'error') {
        soundError.play();
      } else {
        soundSuccess.play();
      }
      setMessages((state) => [...state, toast]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastHook.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastHook.Provider>
  );
};

function useToast(): ToastHookData {
  const context = useContext(ToastHook);

  if (!context) {
    throw Error('useToast must be used within ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
