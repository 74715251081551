import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import {
  Container,
  FloatInput,
  AlteredContent,
  Header,
} from 'styles/sgo_wrappers';
import { useLocation, Link } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import RadioContainer from 'components/RadioContainer';

import api from 'services/api';
import * as O from 'styles/option_buttons';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  setLocalStorage,
  getLocalStorage,
  localStorageLength,
} from 'utils/handleLocalStorage';

import { formatDate } from 'utils/formatDate';
import {
  FaArrowCircleRight,
  FaArrowCircleUp,
  FaUnlock,
  FaPencilAlt,
  FaSearch,
  FaLock,
} from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import { formatNumber } from 'utils/calcTotal';
import {
  GridContainer,
  GridItem,
  InvertButton,
  UnlockContainer,
} from './styles';

interface LocalStorageProps {
  cod?: string;
  desc?: string;
  stat?: string;
}

export interface ListProps {
  anb: string;
  loc: string;
  num: number;
  dtsolic: string;
  status: string;
  statDesc: string;
  obs: string[];
  dtrm: string;
  numrm: number;
  vlrSolic: number;
  locdesc: string;
  dtmanut: string;
  addressval: string;
  address: {
    street: string;
    district: string;
    city: string;
    state: string;
    cep: string;
  };
}

const Solic: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { pathname } = useLocation();

  const { user } = useAuth();

  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [statSol] = useState(() => {
    const hasItem = localStorageLength(`${process.env.REACT_APP_SOLIC}`);

    if (hasItem > 0) {
      const { stat }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_SOLIC}`,
      );

      if (stat) {
        return stat;
      }

      return 'A';
    }

    return 'A';
  });

  const [searchValue, setSearchValue] = useState('');
  const [isInverted, setIsInverted] = useState(false);

  const [list, setList] = useState<ListProps[]>([]);
  const [rawList, setRawList] = useState<ListProps[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/solic_list.php');

      const { fetchList } = response.data;

      setList(
        fetchList
          .filter((item: ListProps) =>
            user.perfil === 'LOC' ? item.loc === user.loccod : item,
          )
          .filter((item: ListProps) =>
            statSol === 'A'
              ? item
              : statSol === 'O'
              ? item.status !== 'R'
              : item.status === 'R',
          ),
      );

      setRawList(
        fetchList.filter((item: ListProps) =>
          user.perfil === 'LOC' ? item.loc === user.loccod : item,
        ),
      );

      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling, statSol, user.loccod, user.perfil]);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    getList();
  }, [getList, handlePermission]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearchValue(val);

      const filtered = rawList.filter((item: ListProps) => {
        return (
          item.num.toString().includes(val) ||
          removeAcento(item.locdesc)
            .toLowerCase()
            .includes(removeAcento(val).toLowerCase())
        );
      });

      setList(filtered);
    },
    [rawList],
  );

  const handleInvert = useCallback(() => {
    setIsInverted((state) => !state);
    setList((state) => [...state].reverse());
    setRawList((state) => [...state].reverse());
  }, []);

  const handleRadioClick = useCallback(() => {
    const val = formRef.current?.getFieldValue('filterSolStat');

    setLocalStorage(`${process.env.REACT_APP_SOLIC}`, { stat: val });

    const filtered = rawList.filter((item: ListProps) =>
      val === 'A'
        ? item
        : val === 'O'
        ? item.status !== 'R'
        : item.status === 'R',
    );

    setList(filtered);
  }, [rawList]);

  const handleUnlock = useCallback(
    async (ev: any) => {
      const { coord, solnum } = ev.currentTarget.dataset;
      if (coord && solnum) {
        try {
          setLoading(true);
          await api.get(
            `sgo/solic_unlock.php?data=${JSON.stringify({
              num: solnum,
              loccod: coord,
            })}`,
          );

          // const index = list.findIndex(
          //   (item: ListProps) => item.num === solnum,
          // );

          // list[index] = {
          //   ...list[index],
          //   status: 'C',
          //   statDesc: 'Criada',
          // };

          // setList((state) => [...state, list[index]]);
          getList();
          // setLoading(false);
        } catch (err) {
          setLoading(false);
          errorHandling(err);
        }
      }
    },
    [errorHandling, getList],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <IncludeButton />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter
        filterContent={
          <Form
            ref={formRef}
            onSubmit={() => {
              return null;
            }}
          >
            <RadioContainer
              title="Solicitações de materiais:"
              onClick={handleRadioClick}
              name="filterSolStat"
              content={[
                {
                  id: 'allSol',
                  value: 'A',
                  label: 'Todas',
                },
                {
                  id: 'oSol',
                  value: 'O',
                  label: 'Em Aberto',
                },
                {
                  id: 'rSol',
                  value: 'R',
                  label: 'Atendidas',
                },
              ]}
              itemsStyle={{
                flexDirection: 'row',
              }}
              selected={statSol}
            />
          </Form>
        }
      />
      <Header>Solicitação de Material</Header>
      {user.perfil === 'NAC' && (
        <FloatInput amount="100px">
          <input
            placeholder="Filtrar por numeração ou coordenação"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </FloatInput>
      )}
      <AlteredContent pixels={user.perfil === 'LOC' ? '231px' : '280px'}>
        {!loading && list.length > 0 && (
          <InvertButton
            type="button"
            onClick={handleInvert}
            isInverted={isInverted}
          >
            <p>Mais {isInverted ? 'recentes' : 'antigas'} primeiro</p>
            <FaArrowCircleUp />
          </InvertButton>
        )}

        <GridContainer>
          {list.map((item: ListProps) => (
            <GridItem key={`${item.loc}-${item.num}`}>
              {item.status === 'B' && (
                <UnlockContainer isDisabled={user.perfil !== 'NAC'}>
                  <button
                    data-coord={item.loc}
                    data-solnum={item.num}
                    type="button"
                    onClick={handleUnlock}
                    disabled={user.perfil !== 'NAC'}
                  >
                    {user.perfil === 'NAC' ? <FaUnlock /> : <FaLock />}
                  </button>
                </UnlockContainer>
              )}
              <p>
                N° {item.num} |&nbsp;
                {formatDate(item.dtsolic)}
              </p>
              <span>
                <p>
                  Valor da Solicitação:&nbsp;
                  <strong>R$ {formatNumber(item.vlrSolic)}</strong>
                </p>
              </span>
              <span>
                <p>
                  Status:&nbsp;
                  <strong>{item.statDesc}</strong>
                </p>
              </span>
              {user.perfil === 'NAC' && (
                <span>
                  <p>
                    Coord:&nbsp;
                    <strong>{item.locdesc}</strong>
                  </p>
                </span>
              )}
              {item.numrm ? (
                <span>
                  <p>
                    RM:&nbsp;
                    <strong>N° {item.numrm}</strong>
                  </p>
                  <p>
                    | Data RM: <strong>{formatDate(item.dtrm)}</strong>
                  </p>

                  <O.ShowFrom
                    to={{
                      pathname: `${pathname.replace('solic', 'rm/consult')}`,
                      state: { rmnum: item.numrm, loccod: item.loc },
                    }}
                  >
                    <FaSearch />
                    &emsp;RM
                  </O.ShowFrom>
                </span>
              ) : null}
              <span>
                <p>
                  Atualizado em:&nbsp;
                  <strong>{formatDate(item.dtmanut)}</strong>
                </p>
              </span>
              <O.GridOptions>
                <O.Show>
                  <Link
                    to={{
                      pathname: `${pathname}/consult`,
                      state: { solic: item },
                    }}
                    title="Consultar"
                  >
                    <FaSearch />
                  </Link>
                </O.Show>
                {item.status === 'C' && (
                  <>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { solic: item },
                      }}
                    >
                      <FaPencilAlt />
                    </O.Update>

                    {user.perfil === 'NAC' && (
                      <O.GenFrom
                        to={{
                          pathname: `${pathname.replace('solic', 'rm/solic')}`,
                          state: { solicHeader: item },
                        }}
                      >
                        RM <FaArrowCircleRight />
                      </O.GenFrom>
                    )}
                  </>
                )}
              </O.GridOptions>
            </GridItem>
          ))}
        </GridContainer>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Solic;
