import styled, { css } from 'styled-components';

interface ChildProps {
  quantity?: number;
}

interface BurgerProps {
  isOpen: boolean;
}

export const Container = styled.div`
  z-index: 151;
  position: sticky;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 50px;

  background: #8a0002;

  > svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: #efefef;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

export const Child = styled.section<ChildProps>`
  z-index: 152;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 50px;
  max-height: 50px;

  padding: 0 5px;
  position: relative;
  transition: background-color 0.35s;

  & + section {
    margin-left: 25px;
  }
  a {
    text-decoration: none;
  }
  a,
  h3 {
    font-size: 18px;
    cursor: pointer;
    color: #efefef;

    transition: color 0.35s;
  }

  span {
    position: absolute;
    top: 50px;
    left: -2px;
    height: 0px;
    width: 400px;

    overflow-x: hidden;
    overflow-y: auto;

    visibility: hidden;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    opacity: 0;
    /* opacity: 1; */
    pointer-events: none;
    background: #efefef;

    /* overflow: hidden; */
    transition: all 0.35s;
    border: 2px solid transparent;
    border-top: 0px;
    border-radius: 0 0 5px 5px;

    ${(props) =>
      props.quantity === 0 &&
      css`
        border-width: 0px;
      `}

    /* button {
      border: 0;
      background: transparent;
      line-height: 25px;
      padding: 0 5px;
      width: auto;
      color: #8a0002;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    } */

    button {
      border: 0;
      background: transparent;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    a,
    button {
      line-height: 25px;
      padding: 0 5px;
      text-decoration: none;
      width: auto;

      color: #8a0002;

      transition: color 0.25s ease, background-color 0.25s, border-color 0.5s,
        visibility 0s;
      & + a,
      & + button {
        margin-top: 3px;
      }

      &:hover {
        opacity: 1;
        background: #8a0002;
        color: #fff;
      }
    }
  }

  &:hover {
    background: #efefef;

    h3 {
      color: #8a0002;
    }
    span {
      visibility: visible;
      border-color: #8a0002;
      height: calc(${(props) => props.quantity} * 28px);
      max-height: 600px;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const SocialMediaContainer = styled.div`
  position: absolute;
  right: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 80px;

  background-color: #8a0002;

  a {
    color: #fff !important;
    opacity: 0.8 !important;
    transition: opacity 0.35s !important;

    &:hover {
      opacity: 1 !important;
    }

    & + a {
      margin: 0 0 0 10px;
    }
  }
`;

export const BurgerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #7159c1; */
  /* height: 50px; */
  width: 50px;
  /* border: 1px solid rgba(239, 239, 239, 0.5); */
  padding: 2px 6px;
  border-radius: 5px;
`;

export const Burger = styled.div<BurgerProps>`
  cursor: pointer;

  background: transparent;
  border-radius: 10px;

  width: 30px;
  transition: all 0.5s;

  &::after,
  &::before,
  div {
    background: #efefef;

    border-radius: 3px;
    content: '';
    display: block;
    height: 4px;
    margin: 6px 0;
    transition: all 0.5s ease-in-out;
  }

  ${(props) =>
    props.isOpen &&
    css`
      &::before {
        transform: translateY(10px) rotate(45deg);
      }
      &::after {
        transform: translateY(-10px) rotate(-45deg);
      }

      div {
        transform: translateX(-10px);
        opacity: 0;
      }
    `}
`;

export const BurgerContent = styled.div<BurgerProps>`
  --detailsPadding: 5px 40px;
  --divPadding: 5px 20px;
  --rowGap: 10px;
  --fontSize: 18px;
  z-index: 152;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;

  background: #fff;

  width: 100vw;
  height: 0vh;

  pointer-events: none;
  opacity: 1;

  details {
    width: 100%;
    padding: var(--detailsPadding);
    display: flex;
    color: #8a0002;
    summary {
      font-size: 20px;
      /* list-style-type: none; */
    }
  }

  div {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: var(--rowGap);
    padding: var(--divPadding);

    span {
      display: flex;
      align-items: center;
      column-gap: 7px;
    }
  }

  a {
    /* text-decoration: none; */
    font-size: var(--fontSize);
    color: #c53003;
  }

  /* button {
    background: transparent;
    border: 0;
    color: #8a0002;
  }

  a,
  button {
    text-decoration: none;
    color: #8a0002;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 25px;
    background: transparent;
    padding: 0px 25px;
    text-align: center;
    border: 2px solid transparent;

    transition: color 0.25s ease, border 0.25s ease, background 0.25s ease;

    & + a,
    & + button {
      margin: 25px 0 0 0;
    }

    &:hover {
      color: #8a0002;
      border-top-color: #8a0002;
      border-bottom-color: #8a0002;
    }

    @media screen and (max-width: 900px) {
      color: #8a0002;
      border-top-color: #bc4143;
      border-bottom-color: #bc4143;
    }
  }

  transition: height 0.5s ease, pointer-events 0.5s ease, padding 0.5s ease; */
  ${(props) =>
    props.isOpen &&
    css`
      height: 100vh;
      pointer-events: all;
      padding: 30px 0;
    `};

  @media screen and (max-width: 540px) {
    --detailsPadding: 5px 20px;
    --divPadding: 5px 10px;
  }

  @media screen and (max-width: 460px) {
    --detailsPadding: 5px 10px;
    --divPadding: 5px 5px;
  }

  @media screen and (max-width: 430px) {
    --rowGap: 14px;
    --fontSize: 16px;
  }
`;
