import React, { createContext, useCallback, useContext } from 'react';

import api from 'services/api';

interface CEPInfo {
  address: string;
  city: string;
  district: string;
  state: string;
}

interface UtilsHookData {
  getCEP(cep: string): Promise<CEPInfo>;
}

const UtilsHook = createContext<UtilsHookData>({} as UtilsHookData);

const UtilsProvider: React.FC = ({ children }) => {
  const endpoint = 'https://brasilapi.com.br/api/cep/v1';
  const getCEP = useCallback(async (cep): Promise<CEPInfo> => {
    const response = await api.get(`${endpoint}/${cep}`);

    const { state, neighborhood, city, street } = response.data;

    const cepinfo = { address: street, city, district: neighborhood, state };
    return cepinfo;
  }, []);
  return <UtilsHook.Provider value={{ getCEP }}>{children}</UtilsHook.Provider>;
};

function useUtils(): UtilsHookData {
  const context = useContext(UtilsHook);

  if (!context) {
    throw new Error('useUtils must be used within and UtilsProvider');
  }

  return context;
}

export { UtilsProvider, useUtils };
