import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaTimes } from 'react-icons/fa';
import { Container, ComboProps } from 'styles/sgo_wrappers';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';
import { StateProps } from '../update';
import { LocalProps } from '../main';

import { AlteredContent, RemovalContainer } from './styles';

interface TeamProps {
  gseq: string | number;
  name: string;
  function: string;
}

const LocaisTeam: React.FC = () => {
  const { addToast } = useToast();
  const { mud } = useChanges();
  const { errorHandling } = useCredentials();
  const { width } = useWindow();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [coord] = useState<LocalProps>(() => {
    return location.state.coord || ({} as LocalProps);
  });

  const [coor, setCoor] = useState(() => {
    return coord.coor.gseq ? coord.coor.gseq.toString() : 'Selecione';
  });
  const [teso, setTeso] = useState(() => {
    return coord.teso.gseq ? coord.teso.gseq.toString() : 'Selecione';
  });
  const [secr, setSecr] = useState(() => {
    return coord.secr.gseq ? coord.secr.gseq.toString() : 'Selecione';
  });
  const [eesc, setEEsc] = useState(() => {
    return coord.eesc.gseq ? coord.eesc.gseq.toString() : 'Selecione';
  });
  const [ejov, setEJov] = useState(() => {
    return coord.ejov.gseq ? coord.ejov.gseq.toString() : 'Selecione';
  });

  const [allGuias, setAllGuias] = useState<ComboProps[]>([]);
  const [removeCT, setRemoveCT] = useState<ComboProps[]>([]);

  const getCombo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/combos/comboEquipe.php?mode=LOC&coord=${coord.loccod}`,
      );

      const uncoor = location.state.coord.coor.gseq.toString();
      const unteso = location.state.coord.teso.gseq.toString();
      const unsecr = location.state.coord.secr.gseq.toString();
      const uneesc = location.state.coord.eesc.gseq.toString();
      const unejov = location.state.coord.ejov.gseq.toString();

      const filter = response.data
        .filter(
          (item: TeamProps) =>
            !item.function ||
            [uncoor, unteso, unsecr, unejov, uneesc].indexOf(`${item.gseq}`) >
              -1,
        )
        .map((item: TeamProps) => ({
          value: `${item.gseq}`,
          label: item.name,
        }));

      setAllGuias(filter);
      setRemoveCT(filter.filter((item: ComboProps) => item.value !== uncoor));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [coord.loccod, location.state.coord, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }

    getCombo();
  }, [location.state, location.pathname, history, getCombo, coord]);

  const handleChanges = useCallback((mode: string) => {
    const selectC = formRef.current?.getFieldRef('coor');
    const selectT = formRef.current?.getFieldRef('teso');
    const selectS = formRef.current?.getFieldRef('secr');
    const selectE = formRef.current?.getFieldRef('eesc');
    const selectJ = formRef.current?.getFieldRef('ejov');

    const cval = selectC.options[selectC.selectedIndex].value;
    const tval = selectT.options[selectT.selectedIndex].value;
    const sval = selectS.options[selectS.selectedIndex].value;
    const escv = selectE.options[selectE.selectedIndex].value;
    const jval = selectJ.options[selectJ.selectedIndex].value;

    if (mode === 'coor') {
      setCoor(cval);
      if (cval === tval) {
        setTeso('Selecione');
        // selectT.options[selectT.selectedIndex].value = '';
        return;
      }
      if (cval === sval) {
        setSecr('Selecione');
        // selectS.options[selectS.selectedIndex].value = '';
        return;
      }

      if (cval === escv) {
        setEEsc('Selecione');
        // selectE.options[selectE.selectedIndex].value = '';

        return;
      }
      if (cval === jval) {
        setEJov('Selecione');
        // selectJ.options[selectJ.selectedIndex].value = '';
        return;
      }
    }

    if (mode === 'teso') {
      setTeso(tval);
      if (tval === cval) {
        setCoor('Selecione');
        // selectC.options[selectC.selectedIndex].value = '';
        return;
      }
      if (tval === sval) {
        setSecr('Selecione');
        // selectS.options[selectS.selectedIndex].value = '';
        return;
      }

      if (tval === escv) {
        setEEsc('Selecione');
        // selectE.options[selectE.selectedIndex].value = '';

        return;
      }
      if (tval === jval) {
        setEJov('Selecione');
        // selectJ.options[selectJ.selectedIndex].value = '';
        return;
      }
    }

    if (mode === 'secr') {
      setSecr(sval);
      if (sval === cval) {
        setCoor('Selecione');
        // selectC.options[selectC.selectedIndex].value = '';
        return;
      }
      if (sval === tval) {
        setTeso('Selecione');
        // selectT.options[selectT.selectedIndex].value = '';
        return;
      }

      if (sval === escv) {
        setEEsc('Selecione');
        // selectE.options[selectE.selectedIndex].value = '';
        return;
      }
      if (sval === jval) {
        setEJov('Selecione');
        // selectJ.options[selectJ.selectedIndex].value = '';
      }
      return;
    }

    if (mode === 'eesc') {
      setEEsc(escv);
      if (escv === cval) {
        setCoor('Selecione');
        // selectC.options[selectC.selectedIndex].value = '';
        return;
      }
      if (escv === tval) {
        setTeso('Selecione');
        // selectT.options[selectT.selectedIndex].value = '';
        return;
      }
      if (escv === sval) {
        setSecr('Selecione');
        // selectS.options[selectS.selectedIndex].value = '';
        return;
      }

      if (escv === jval) {
        setEJov('Selecione');
        // selectJ.options[selectJ.selectedIndex].value = '';
      }
      return;
    }

    if (mode === 'ejov') {
      setEJov(jval);
      if (jval === cval) {
        setCoor('Selecione');
        // selectC.options[selectC.selectedIndex].value = '';
        return;
      }
      if (jval === tval) {
        setTeso('Selecione');
        // selectT.options[selectT.selectedIndex].value = '';
        return;
      }
      if (jval === sval) {
        setSecr('Selecione');
        // selectS.options[selectS.selectedIndex].value = '';
        return;
      }

      if (jval === escv) {
        setEEsc('Selecione');
        // selectE.options[selectE.selectedIndex].value = '';
      }
    }
  }, []);

  const handleCoor = useCallback(() => {
    handleChanges('coor');
  }, [handleChanges]);
  const handleTeso = useCallback(() => {
    handleChanges('teso');
  }, [handleChanges]);
  const handleSecr = useCallback(() => {
    handleChanges('secr');
  }, [handleChanges]);
  const handleEEsc = useCallback(() => {
    handleChanges('eesc');
  }, [handleChanges]);
  const handleEJov = useCallback(() => {
    handleChanges('ejov');
  }, [handleChanges]);

  const handleRemove = useCallback((mode: string) => {
    if (mode === 'coor') {
      setCoor('Selecione');
      // selectC.options[selectC.selectedIndex].value = '';
    }
    if (mode === 'teso') {
      setTeso('Selecione');
      // selectT.options[selectT.selectedIndex].value = '';
    }
    if (mode === 'secr') {
      setSecr('Selecione');
      // selectS.options[selectS.selectedIndex].value = '';
    }
    if (mode === 'eesc') {
      setEEsc('Selecione');
      // selectE.options[selectE.selectedIndex].value = '';
    }
    if (mode === 'ejov') {
      setEJov('Selecione');
      // selectJ.options[selectJ.selectedIndex].value = '';
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const selectC = formRef.current?.getFieldRef('coor');
      const selectT = formRef.current?.getFieldRef('teso');
      const selectS = formRef.current?.getFieldRef('secr');
      const selectE = formRef.current?.getFieldRef('eesc');
      const selectJ = formRef.current?.getFieldRef('ejov');

      const cval = selectC.options[selectC.selectedIndex].value;
      const tval = selectT.options[selectT.selectedIndex].value;
      const sval = selectS.options[selectS.selectedIndex].value;
      const escv = selectE.options[selectE.selectedIndex].value;
      const jval = selectJ.options[selectJ.selectedIndex].value;

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          initial: {
            coor: location.state.coord.coor.gseq.toString(),
            teso: location.state.coord.teso.gseq.toString(),
            secr: location.state.coord.secr.gseq.toString(),
            eesc: location.state.coord.eesc.gseq.toString(),
            ejov: location.state.coord.ejov.gseq.toString(),
          },
          altered: {
            coor: cval,
            teso: tval,
            secr: sval,
            eesc: escv,
            ejov: jval,
          },
          anb: coord.anbcod,
          local: coord.desc,
          loccod: coord.loccod,
        }),
      );

      await api.post(`/sgo/locais_team.php`, send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Equipe ${coord.desc} atualizada`,
      });

      history.goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    location.state.coord,
    coord.anbcod,
    coord.desc,
    coord.loccod,
    addToast,
    history,
    errorHandling,
  ]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Equipe" />

      <AlteredContent>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <span>
            <p>Coordenação</p>
            <SelectV2
              name="coor"
              initial={coor}
              content={allGuias}
              onChange={handleCoor}
              // disabled={!allowChange}
              disabled={!mud}
            />
            {/* {allowChange && ( */}
            {mud && (
              <RemovalContainer
                type="button"
                onClick={() => handleRemove('coor')}
              >
                <FaTimes />
                {width < 420 && <p>Remover Coordenador(a)</p>}
              </RemovalContainer>
            )}
          </span>
          <span>
            <p>Tesouraria</p>
            <SelectV2
              name="teso"
              initial={teso}
              content={mud ? allGuias : removeCT}
              onChange={handleTeso}
            />
            <RemovalContainer
              type="button"
              onClick={() => handleRemove('teso')}
            >
              <FaTimes />
              {width < 420 && <p>Remover Tesoureiro(a)</p>}
            </RemovalContainer>
          </span>
          <span>
            <p>Secretaria</p>
            <SelectV2
              name="secr"
              initial={secr}
              content={mud ? allGuias : removeCT}
              onChange={handleSecr}
            />
            <RemovalContainer
              type="button"
              onClick={() => handleRemove('secr')}
            >
              <FaTimes />
              {width < 420 && <p>Remover Secretário(a)</p>}
            </RemovalContainer>
          </span>
          <span>
            <p>Enc. Escola</p>
            <SelectV2
              name="eesc"
              initial={eesc}
              content={mud ? allGuias : removeCT}
              onChange={handleEEsc}
            />
            <RemovalContainer
              type="button"
              onClick={() => handleRemove('eesc')}
            >
              <FaTimes />
              {width < 420 && <p>Remover Enc. Escola</p>}
            </RemovalContainer>
          </span>
          <span>
            <p>Jovem</p>
            <SelectV2
              name="ejov"
              initial={ejov}
              content={mud ? allGuias : removeCT}
              onChange={handleEJov}
            />
            <RemovalContainer
              type="button"
              onClick={() => handleRemove('ejov')}
            >
              <FaTimes />
              {width < 420 && <p>Remover Enc. Jovem</p>}
            </RemovalContainer>
          </span>

          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>
        </Form>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default LocaisTeam;
