import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import Button from 'components/Button';
import getValidationErrors from 'utils/getValidationErrors';
import * as yup from 'yup';
import { convertSpecialChars } from 'utils/specialChars';
import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import RadioContainer from 'components/RadioContainer';
import SelectV2 from 'components/SelectV2';
import Textarea from 'components/Textarea';
import Input from 'components/Input';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import { AlteredForm } from './styles';

interface FormData {
  mat: string;
  zon: string;
  nac: string;
  loc: string;
  orig: string;
  qtde: number;
  just: string;
}

const EntradasInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [zons, setZons] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [comboMats, setComboMats] = useState<ComboProps[]>([]);
  const [sender, setSender] = useState('');

  const [initialZON, setInitialZON] = useState('Selecione');

  const [initialLOC, setInitialLOC] = useState('Selecione');

  const getComboMAT = useCallback(async () => {
    const response = await api.get('/combos/comboMATs.php');

    setComboMats(response.data);
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);
  }, []);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setZons(
      response.data.filter((item: ComboProps) => item.value !== user.zoncod),
    );
  }, [user.zoncod]);

  useEffect(() => {
    getComboZON();
    getComboANB();
    getComboLOC();
    getComboMAT();
  }, [getComboZON, getComboANB, getComboLOC, getComboMAT]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('nac');
    const { value } = select.options[select.selectedIndex];

    if (sender === 'LOC') {
      formRef?.current?.setFieldValue('loc', '');
      setComboLocs(
        locs.filter(
          (item: ComboLocalProps) =>
            item.anb === value && item.value !== item.anb,
        ),
      );
    }
  }, [locs, sender]);

  const handleRadioClick = useCallback(() => {
    const orig = formRef.current?.getFieldValue('orig');
    setSender(orig);

    let message = '';

    if (orig === 'FOR') {
      message = 'Material recebido do Fornecedor';
    }
    if (orig === 'ZON') {
      message = 'Material recebido da Zonal';
      setComboZons(zons.map((item: ComboProps) => item));
    }
    if (orig === 'NAC' || orig === 'LOC') {
      setComboZons([{ value: '', label: 'Selecione' }]);
      setInitialZON('Selecione');

      formRef.current?.setFieldValue('zon', '');
      formRef.current?.setFieldValue('nac', '');
      if (orig === 'LOC') {
        setComboLocs([]);
        setInitialLOC('Selecione');
        formRef.current?.setFieldValue('loc', '');
      }
      message = `Material recebido da ${orig === 'NAC' ? 'Nacion' : 'Loc'}al`;
      setComboNacs(
        nacs.filter(
          (item: ComboProps) =>
            item.value.substr(0, 2) === user.zoncod.substr(0, 2),
        ),
      );
    }

    formRef.current?.setFieldValue('just', message);
  }, [zons, nacs, user.zoncod]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          mat: yup.string().notOneOf(['Selecione', '']),
          orig: yup
            .string()
            .oneOf(['FOR', 'ZON', 'NAC', 'LOC'], 'Este campo é obrigatório.'),
          zon: yup.string().when('orig', {
            is: (orig) => orig === 'ZON',
            then: yup.string().notOneOf(['Selecione a Zonal', '']).required(),
          }),
          nac: yup.string().when('orig', {
            is: (orig) => orig === 'NAC' || orig === 'LOC',
            then: yup
              .string()
              .notOneOf(['Selecione a Nacional', ''])
              .required(),
          }),
          loc: yup.string().when('orig', {
            is: (orig) => orig === 'LOC',
            then: yup
              .string()
              .notOneOf(['Selecione uma Nacional', ''])
              .required(),
          }),
          qtde: yup
            .number()
            .typeError('Este campo é obrigatório.')
            .min(1, 'Quantidade mínima necessária não inserida (1)'),
          just: yup
            .string()
            .trim()
            .min(15, 'Quantidade mínima de caracteres não atingida (15).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = {
          ...data,
          just: convertSpecialChars(data.just),
          cod:
            data.orig === 'FOR'
              ? data.orig
              : data.orig === 'ZON'
              ? data.zon
              : data.orig === 'NAC'
              ? data.nac
              : data.loc,
        };

        await api.get(`/sgo/entradas_insert.php?data=${JSON.stringify(send)}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Registro de Entrada incluído.',
        });

        history.replace(location.pathname.replace('/insert', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [errorHandling, addToast, history, location.pathname],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Registro de Entrada" />
      <Content>
        <AlteredForm ref={formRef} onSubmit={handleSubmit}>
          <span>
            <p>Descrição do Material</p>
            <SelectV2
              initial="Selecione"
              name="mat"
              content={comboMats}
              // dropdownStyle={{
              //   borderColor: '#a6a6a6',
              // }}
            />
          </span>
          <span>
            <h3>Origens permitidas</h3>
            <RadioContainer
              title="Enviado pela:"
              onClick={handleRadioClick}
              name="orig"
              content={[
                {
                  id: 'oFOR',
                  value: 'FOR',
                  label: 'Fornecedor',
                },
                {
                  id: 'oZON',
                  value: 'ZON',
                  label: 'Zonal',
                },
                {
                  id: 'oANB',
                  value: 'NAC',
                  label: 'Nacional',
                },
                {
                  id: 'oLOC',
                  value: 'LOC',
                  label: 'Local',
                },
              ]}
            />
          </span>

          <span>
            <p>Zonal de Origem</p>
            <SelectV2
              name="zon"
              content={comboZons}
              initial={initialZON}
              // dropdownStyle={{
              //   maxHeight: '130px',
              //   borderColor: '#a6a6a6',
              // }}
              disabled={!sender || sender !== 'ZON'}
            />
          </span>
          <span>
            <p>Nacional de Origem</p>
            <SelectV2
              name="nac"
              content={comboNacs}
              initial="Selecione"
              // dropdownStyle={{
              //   maxHeight: '130px',
              //   borderColor: '#a6a6a6',
              // }}
              disabled={!sender || ['FOR', 'ZON'].indexOf(sender) > -1}
              onChange={handleANBselect}
            />
          </span>
          <span>
            <p>Local de Origem</p>
            <SelectV2
              name="loc"
              content={comboLocs}
              initial={initialLOC}
              // dropdownStyle={{
              //   maxHeight: '130px',
              //   borderColor: '#a6a6a6',
              // }}
              disabled={!sender || ['FOR', 'ZON'].indexOf(sender) > -1}
            />
          </span>
          <span>
            <p>Quantidade recebida</p>
            <Input name="qtde" type="number" mask="number" />
          </span>
          <span>
            <p>Justificativa</p>
            <Textarea name="just" placeholder="Justificativa do recebimento" />
          </span>

          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Registrar
          </Button>
        </AlteredForm>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default EntradasInsert;
