export default function resizeImage(
  file: File,
  maxWidth: number,
  maxHeight: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const { width } = image;
      const { height } = image;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0, newWidth, newHeight);
      }

      canvas.toBlob(resolve, file.type);
    };
    image.onerror = reject;
  });
}
