import React, { HTMLAttributes } from 'react';

import { Link } from 'react-router-dom';
// import { FaSignInAlt } from 'react-icons/fa';
// import { useWindow } from 'hooks/window';
import { Container } from './styles';
// import { Container, Error } from './styles';

interface HeaderProps extends HTMLAttributes<HTMLHeadElement> {
  web?: boolean;
  acesso?: boolean;
  disabledLink?: boolean;
}

const Header: React.FC<HeaderProps> = ({ web, /* acesso, */ ...rest }) => {
  // const { width } = useWindow();
  return (
    <Container web={web} {...rest} data-tag="header">
      <Link to="/" />
      {/* {acesso && (
        <Error
          title={
            width <= 900
              ? 'Clique no menu e em Área do Guia.'
              : 'Para acessar, clique em Área do Guia.'
          }
        >
          <p>Acesso ao SGO</p>
          <FaSignInAlt />
        </Error>
      )} */}
    </Container>
  );
};

export default Header;
