import React, { useState, useEffect, useCallback } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';

import { useCredentials } from 'hooks/credentials';

import { useToast } from 'hooks/toast';
import { useLocation } from 'react-router-dom';

import api from 'services/api';

import { Container, Content } from 'styles/sgo_wrappers';
import {
  FaChevronDown,
  FaChevronRight,
  FaHome,
  FaRegFolder,
  FaRegFolderOpen,
  FaRegSave,
  FaRegShareSquare,
} from 'react-icons/fa';

import { FoldersProps, PayloadProps, DocumentProps } from './interfaces';

import {
  GridContainer,
  PreviousPathsContainer,
  PreviousPathsMobileContainer,
  Wrapper,
  FolderInfoContainer,
  ContentWrapper,
  TableWrapper,
  Table,
  SaveContainer,
} from './styles';

const ManageDocuments: React.FC = () => {
  const { pathname } = useLocation();

  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();

  const documentPath = process.env.REACT_APP_TOV_STATIC_FILES;
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<FoldersProps[]>([]);
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [originalDocuments, setOriginalDocuments] = useState<DocumentProps[]>(
    [],
  );
  const [previousPaths, setPreviousPaths] = useState<FoldersProps[]>([]);

  const [payload, setPayload] = useState<PayloadProps>({
    tier: 0,
  } as PayloadProps);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/documents_list.php?data=${JSON.stringify({
          ...payload,
          from: pathname.includes('gerencial') ? 'gerencial' : 'secretaria',
        })}`,
      );
      const { __folders, __documents } = response.data;

      setFolders(__folders);
      setDocuments(__documents);
      setOriginalDocuments(__documents);
    } catch (err) {
      errorHandling(err);
    } finally {
      setLoading(false);
    }
  }, [errorHandling, pathname, payload]);

  useEffect(() => {
    if (pathname.includes('gerencial')) {
      handlePermission(['ZON'], true);
    }
    getList();
  }, [getList, handlePermission, pathname]);

  const accessFolder = useCallback((ev) => {
    const { path, folder, currtier } = ev.currentTarget.dataset;
    setPreviousPaths((state) => [
      ...state,
      { folder, path, currentTier: parseInt(currtier, 10) },
    ]);

    setPayload((state) => {
      const obj = { ...state };
      obj.tier = parseInt(currtier, 10) + 1;
      obj.docPath = path;
      obj[`n${currtier}`] = folder;
      return obj;
    });
  }, []);

  const handleNavigateToFolder = useCallback((ev) => {
    const { path, folder, currtier } = ev.currentTarget.dataset;

    setDocuments([]);

    setPreviousPaths((state) => {
      if (folder === 'home') {
        return [];
      }
      const index = state.map((item) => item.path).indexOf(path) + 1;
      const updated = state.slice(0, index);

      return updated;
    });

    setPayload((previous) => {
      if (folder === 'home') {
        return { tier: 0 };
      }
      const obj = { ...previous };
      obj.tier = parseInt(currtier, 10) + 1;
      obj.docPath = path;
      Object.keys(obj).forEach((element) => {
        if (['tier', 'docPath'].indexOf(element) < 0) {
          const currentKey = parseInt(element.replace('n', ''), 10);
          if (currentKey >= obj.tier) {
            delete obj[element];
          }
        }
      });

      return obj;
    });
  }, []);

  const handleInputChange = useCallback((ev) => {
    const { level, id } = ev.currentTarget.dataset;
    const { checked } = ev.currentTarget;

    setDocuments((state) => {
      let current = [];
      let currAccess = 0;
      switch (level) {
        case 'gui':
          currAccess = 4;
          break;
        case 'loc':
          currAccess = 3;
          break;
        case 'nac':
          currAccess = 2;
          break;
        default:
          currAccess = 1;
      }

      current = state.map((item: DocumentProps) => ({
        ...item,
        access:
          item.id === id
            ? checked
              ? currAccess
              : currAccess - 1
            : item.access,
      }));

      return current;
    });
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const updated: DocumentProps[] = [];

      documents.forEach((item) => {
        const index = originalDocuments
          .map((origItem) => origItem.id)
          .indexOf(item.id);
        if (originalDocuments[index].access !== item.access) {
          updated.push(item);
        }
      });

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          content: updated,
        }),
      );

      await api.post('/sgo/documents_management.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setOriginalDocuments([...documents]);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: `Registros alterados com sucesso`,
      });
    } catch (error) {
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  }, [addToast, documents, errorHandling, originalDocuments]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <Wrapper>
          <PreviousPathsContainer>
            {previousPaths.length > 0 && (
              <div>
                <FaRegFolderOpen />
                <button
                  type="button"
                  data-folder="home"
                  onClick={handleNavigateToFolder}
                >
                  <FaHome />
                </button>
                <FaChevronRight />
              </div>
            )}
            {previousPaths.map((item: FoldersProps, index) => (
              <div>
                {index !== 0 ? <FaChevronRight /> : ''}
                <button
                  type="button"
                  data-folder={item.folder}
                  data-path={item.path}
                  data-currtier={item.currentTier}
                  onClick={handleNavigateToFolder}
                >
                  {item.folder}
                </button>
              </div>
            ))}
          </PreviousPathsContainer>
          <PreviousPathsMobileContainer>
            <details>
              <summary>
                <FaRegFolderOpen />
                <p>Anteriores</p>
                <FaChevronRight />
              </summary>
              {previousPaths.length > 0 && (
                <div>
                  <button
                    type="button"
                    data-folder="home"
                    onClick={handleNavigateToFolder}
                  >
                    <FaHome />
                  </button>
                  <FaChevronDown />
                </div>
              )}
              {previousPaths.map((item: FoldersProps, index) => (
                <div>
                  {index !== 0 ? <FaChevronDown /> : ''}
                  <button
                    type="button"
                    data-folder={item.folder}
                    data-path={item.path}
                    data-currtier={item.currentTier}
                    onClick={handleNavigateToFolder}
                  >
                    {item.folder}
                  </button>
                </div>
              ))}
            </details>
          </PreviousPathsMobileContainer>
        </Wrapper>

        {folders.length > 0 && (
          <ContentWrapper open>
            <summary>
              Pastas&emsp;
              <FaChevronDown />
            </summary>
            <GridContainer>
              {folders.map((item) => (
                <FolderInfoContainer key={item.path}>
                  <FaRegFolder />
                  <button
                    type="button"
                    data-folder={item.folder}
                    data-path={item.path}
                    data-currtier={item.currentTier}
                    onClick={accessFolder}
                  >
                    &nbsp;
                  </button>
                  <p>{item.folder}</p>
                </FolderInfoContainer>
              ))}
            </GridContainer>
          </ContentWrapper>
        )}
        {documents.length > 0 && (
          <>
            <ContentWrapper style={{ margin: '30px 0' }} open>
              <summary>
                Documentos&emsp;
                <FaChevronDown />
              </summary>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <td>Documento</td>
                      <td>ZON</td>
                      <td>NAC</td>
                      <td>LOC</td>
                      <td>GUI</td>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((item: DocumentProps) => (
                      <tr key={item.id}>
                        <td>
                          <a
                            href={`${documentPath}/${item.file}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.label}&nbsp;
                            <FaRegShareSquare />
                          </a>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            data-level="zon"
                            checked
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            data-id={item.id}
                            data-level="nac"
                            checked={[2, 3, 4].indexOf(item.access) > -1}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            data-id={item.id}
                            data-level="loc"
                            checked={[3, 4].indexOf(item.access) > -1}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            data-id={item.id}
                            data-level="gui"
                            checked={item.access === 4}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
            </ContentWrapper>
            <SaveContainer>
              {JSON.stringify(originalDocuments) !==
              JSON.stringify(documents) ? (
                <button type="button" onClick={handleSave}>
                  <FaRegSave />
                  Confirmar
                </button>
              ) : (
                <button type="button" disabled>
                  <FaRegSave />
                  Confirmar
                </button>
              )}
            </SaveContainer>
          </>
        )}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ManageDocuments;
