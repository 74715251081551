import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';
import * as yup from 'yup';

import { Container, Content, AlteredHeader } from 'styles/sgo_wrappers';

import getValidationErrors from 'utils/getValidationErrors';
import { ListProps } from '../main';
import { Wrapper } from '../insert/styles';

interface FormData {
  mode: string;
  title: string;
  detail: string;
  compl: string;
  qte: string;
}

const BibliotecaUpdate: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<ListProps>();
  const history = useHistory();
  const [reg] = useState<ListProps>(() => {
    return { ...location.state } || ({} as ListProps);
  });

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);

    if (!location.state) {
      history.goBack();
    }
  }, [handlePermission, history, location.state]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          // title: yup.string().when('mode', {
          //   is: (val) => val.includes('not'),
          //   then: yup
          //     .string()
          //     .trim()
          //     .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          //     .max(50, 'Quantidade máxima de caracteres excedida (50).'),
          //   otherwise: yup.string().nullable(true),
          // }),
          title: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).')
            .max(255, 'Quantidade máxima de caracteres excedida (255).'),
          // detail: yup.lazy((val) =>
          //   val
          //     ? yup
          //         .string()
          //         .trim()
          //         .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          //         .max(50, 'Quantidade máxima de caracteres excedida (50).')
          //     : yup.string().nullable(true),
          // ),
          compl: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .trim()
                  .min(5, 'Quantidade mínima de caracteres não atingida (5).')
                  .max(255, 'Quantidade máxima de caracteres excedida (255).')
              : yup.string().nullable(true),
          ),
          // qte: yup
          //   .number()
          //   .typeError('Este campo é obrigatório')
          //   .min(0, 'Numeração deve ser igual ou superior a zero (0).')
          //   .max(10, `Numeração deve ser igual ou inferior a 10`),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            mode: 'update',
          }),
        );
        send.append('old', JSON.stringify({ ...reg }));

        const response = await api.post('/sgo/biblioteca_actions.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const { message } = response.data;

        if (message) {
          addToast({
            type: 'error',
            title: 'Falha na requisição!',
            description: `${message}`,
          });
          setLoading(false);
          return;
        }
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${data.title} atualizado.`,
        });
        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, errorHandling, history, reg],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <SGOHeader />
      <ScrollTop />
      <SGONavbar />
      <Content>
        <AlteredHeader>
          <div>
            <p>
              {/* Materiais de Estudo da&nbsp; */}
              {['ZON', 'NAC'].indexOf(user.perfil) > -1
                ? 'Acervo da Equipe'
                : 'Biblioteca da Coordenação'}
            </p>
          </div>
          <div>
            <p>
              {user.perfil === 'ZON'
                ? user.zondesc
                : user.perfil === 'NAC'
                ? user.anbdesc
                : user.locdesc}
            </p>
          </div>
        </AlteredHeader>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            mode: reg.matcod ? 'material' : 'not-material',
            title: reg.title,
            detail: reg.detail,
            compl: reg.compl,
            qte: reg.qte,
          }}
        >
          <Wrapper>
            <Input name="mode" disabled isHidden />
            <div>
              <p>Título:</p>
              <Input
                name="title"
                placeholder="Título do material"
                containerStyle={{ height: '30px' }}
                // disabled={!!reg.matcod}
              />
            </div>
            {/* <div>
              <p>Detalhe:</p>
              <Input
                name="detail"
                placeholder="Detalhe do material"
                containerStyle={{ height: '30px' }}
              />
            </div> */}
            <div>
              <p>Complemento:</p>
              <Input
                name="compl"
                placeholder="Complemento do material"
                containerStyle={{ height: '30px' }}
              />
            </div>
            {/* <div>
              <p>Quantidade:</p>
              <Input
                name="qte"
                containerStyle={{ height: '30px', width: '100px' }}
                inputStyle={{ width: '70px', textAlign: 'right' }}
                type="number"
                step="1"
                min="0"
                mask="allowZero"
                placeholder="0"
              />
            </div> */}
          </Wrapper>
          <Button type="submit" bgcolor="#00802b">
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default BibliotecaUpdate;
