import React, { createContext, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';
import { useToast } from './toast';

import api from '../services/api';

interface ErrorProps {
  code: string | number;
  message: string;
}

interface CredentialContextData {
  errorHandling(err: Error, altMessage?: string): ErrorProps;
  checkCredentials(): void;
  handlePermission(array: string[], equals?: boolean): void;
  handleGTPermission(
    anbo: string,
    loco: string,
    anbd: string,
    locd: string,
    perfil: string,
  ): void;
}

const CredentialContext = createContext<CredentialContextData>(
  {} as CredentialContextData,
);

const CredentialsProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const { signOut, user } = useAuth();
  const { addToast } = useToast();

  const errorHandling = useCallback(
    (err: Error, altMessage?: string) => {
      const code = err.message.substr(-3);
      let message = '';
      console.log(`%c ERR.MESSAGE:: ${err.message}`, 'font-size: 25px;');

      if (code === '400') {
        message = 'Combinação usuário / senha inválida';
      } else if (code === '401') {
        message = 'Acesso expirado';
      } else if (code === '403') {
        message = 'Usuário não autorizado';
      } else if (code === '404') {
        message = 'Token não encontrado';
      } else if (code === '422') {
        message = 'Acesso inválido';
      } else if (code === '500') {
        message = 'Erro interno. Acione o suporte ao SGO';
      } else if (code === '409') {
        message = 'Conflito de informações.';
      } else if (code === '429') {
        message =
          'Versão incompatível.\nEm 3 segundos a página será atualizada.';
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        message = 'Erro interno. Acione o suporte ao SGO';
      }

      if (altMessage) {
        message = altMessage;
      }
      // console.warn(message);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: message,
      });

      if (['500', '400', '409', '429'].indexOf(code) < 0) {
        signOut();
      }

      return { code, message };
    },
    [signOut, addToast],
  );

  const checkCredentials = useCallback(async () => {
    try {
      await api.get('/token/jwt_validate.php');
    } catch (err) {
      // console.log(err.response);
      errorHandling(err, err.response.data.message);
    }
  }, [errorHandling]);

  const handlePermission = useCallback(
    async (array: string[], equals = false) => {
      if (user) {
        if (equals) {
          if (array.indexOf(user.perfil) < 0) {
            addToast({
              type: 'error',
              title: 'Ação não permitida',
              description: 'Área restrita.',
            });
            history.replace('/sgo');
            return;
          }
          return;
        }
        if (array.indexOf(user.perfil) > -1) {
          addToast({
            type: 'error',
            title: 'Ação não permitida',
            description: 'Área restrita.',
          });
          history.replace('/sgo');
        }
        return;
      }
      addToast({
        type: 'error',
        title: 'Ação não permitida',
        description: 'Área restrita.',
      });
      history.replace('/signin');
    },
    [user, history, addToast],
  );

  const handleGTPermission = useCallback(
    (
      anbo: string,
      loco: string,
      anbd: string,
      locd: string,
      perfil: string,
    ) => {
      if (user) {
        if (
          !(
            (perfil === 'LOC' && [loco, locd].indexOf(user.loccod) > -1) ||
            (perfil === 'NAC' && [anbo, anbd].indexOf(user.anbc) > -1) ||
            perfil === 'ZON'
          )
        ) {
          addToast({
            type: 'error',
            title: 'Ação não permitida',
            description: 'Área restrita.',
          });
          history.replace('/sgo');
        }
      } else {
        addToast({
          type: 'error',
          title: 'Ação não permitida',
          description: 'Área restrita.',
        });
        history.replace('/signin');
      }
    },
    [addToast, history, user],
  );

  return (
    <CredentialContext.Provider
      value={{
        errorHandling,
        checkCredentials,
        handlePermission,
        handleGTPermission,
      }}
    >
      {children}
    </CredentialContext.Provider>
  );
};

function useCredentials(): CredentialContextData {
  const context = useContext(CredentialContext);

  if (!context) {
    throw new Error('useCredentials must be used within an CredentialProvider');
  }

  return context;
}

export { CredentialsProvider, useCredentials };
