import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface MassiveProps {
  error: boolean;
}
interface DistinctProps {
  error: boolean;
}

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;

  background: #fff;
  padding: 10px;
  border: 2px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  > div {
    > p {
      font-size: 16px;
      color: #8a0002;
      font-weight: 500;
      font-family: 'Roboto Slab', serif;
    }
    & + div {
      margin-top: 20px;
    }
  }

  /* min-width: 500px; */
`;

export const InputContainer = styled.div`
  position: relative;

  padding: 5px 0px;

  display: flex;
  justify-content: center;
  flex-direction: row;
  /* width: 360px; */
  /* background: #7159c1; */

  span {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border: 2px solid #cfcfcf;
    border-radius: 3px;
    padding: 2px 5px;
    /* width: 200px; */
    /* box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5); */

    input {
      border: 0;
      background-color: transparent;
      margin: 0 20px 0 0;
      width: 150px;
      font-size: 16px;
      font-family: 'Roboto Slab', serif;
      font-weight: 500;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 5px;
      cursor: pointer;

      color: #767676;
    }
  }

  > button {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 0;
    background: transparent;
    text-decoration: underline;
    color: #8a0002;
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
  /* width: 120px; */
  padding: 5px 10px;

  color: #00802b;
  border: 2px solid #00802b;
  border-radius: 5px;
  background: #fff;

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  > p {
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
  }

  transition: all 0.35s ease;

  &:hover {
    color: #fff;
    background-color: #00802b;
    border-color: #00802b;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;

export const DistinctContent = styled(animated.div)``;

export const MassiveContent = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* position: absolute; */

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      font-size: 16px;
      color: #332e2e;
      font-family: 'Roboto Slab', serif;
      font-weight: 500;
      width: 40px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    input {
      padding: 0;
      width: 90px;
      border: 2px solid #cfcfcf;
      border-radius: 3px;
      text-align: center;
      font-size: 16px;
      font-family: 'Roboto Slab', serif;
      font-weight: 500;
    }

    svg {
      right: 75px;
      position: absolute;
      cursor: pointer;
      margin: 0 0 0 5px;
      width: 20px;
      height: 20px;
      color: #767676;
    }

    & + span {
      margin: 10px 0 0 0;
    }
  }
`;

export const FloatMassiveError = styled.div<MassiveProps>`
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 50%;

  svg {
    color: #c53030;
    height: 25px;
    width: 25px;
  }

  ${(props) =>
    props.error &&
    css`
      opacity: 1;
    `}
`;

export const FloatDistinctError = styled.div<DistinctProps>`
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 50%;

  svg {
    color: #c53030;
    height: 25px;
    width: 25px;
  }

  ${(props) =>
    props.error &&
    css`
      opacity: 1;
    `}
`;

export const ReturnedNumbers = styled(animated.div)`
  display: flex;
  flex-direction: column;

  transform: translateY(-35px);
  max-width: 360px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #767676;
    background: #767676;
    border-radius: 3px;
    padding: 2px 10px;
    margin: 5px auto 0;

    color: #efefef;
    font-size: 16px;

    transition: all 0.35s ease;

    svg {
      margin: 0 5px 0 0;
    }

    &:hover {
      color: #fff;
      border-color: #332e2e;
      background-color: #332e2e;
    }
  }

  span {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      color: #332e2e;
      font-weight: 500;
    }
  }
`;

export const ReturnedContent = styled.div`
  display: flex;
  /* width: 600px; */
  max-width: 800px;
  width: 100%;
  flex-direction: column;

  div {
    padding: 5px;
    display: flex;
    flex-direction: column;

    width: 90%;

    background: #fff;
    border: 2px solid #332e2e;
    border-radius: 5px;

    p {
      font-size: 16px;
      color: #767676;
      strong {
        color: #332e2e;
      }
    }
    & + div {
      margin-top: 8px;
    }
  }
`;
