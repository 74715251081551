import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';
import { useWindow } from 'hooks/window';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';
import Button from 'components/Button';
import * as yup from 'yup';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';

import { formatNumber } from 'utils/calcTotal';
import {
  endOfDay,
  formatDate,
  handleTimeZone,
  minimumAllowedDate,
} from 'utils/formatDate';

import { IncludeProps } from 'styles/dialog_include';
import * as I from 'styles/dialog_include';

import { DeleteProps } from 'styles/dialog_delete';
import * as D from 'styles/dialog_delete';

import { FaDollarSign, FaPlusCircle } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import InputDialog from 'components/InputDialog';
import getValidationErrors from 'utils/getValidationErrors';
import { useSpring } from 'react-spring';
import { addDays, subDays } from 'date-fns';
import {
  FinHeader,
  FinContent,
  FinItem,
  FinContentHeader,
  Data,
  Anterior,
  Parcela,
  Saldo,
  Wrapper,
  InfoContainer,
  IncButton,
  TableWrapper,
  Table,
} from './styles';

interface StateProps {
  finHeader: {
    num: number;
    anbcod: string;
    cod: string;
    desc: string;
    valor: number;
    date: string;
    status: string;
  };
}

interface ListProps {
  seq: number;
  dtmov: string;
  ant: number;
  parc: number;
  saldo: number;
}

interface DMListProps {
  seq: number;
  dm: number;
  data: string;
  valor: number;
}

interface FormData {
  num: string;
  cod: string;
  date: string;
  value: string;
}

const FinRMDetail: React.FC = () => {
  const { user } = useAuth();
  const { width } = useWindow();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { errorHandling, handlePermission } = useCredentials();
  const [loading, setLoading] = useState(false);
  const [finHeader] = useState(() => {
    return location.state.finHeader || ({} as StateProps);
  });
  const { currDate } = useChanges();

  const [prevDate, setPrevDate] = useState('');
  const [today] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
  );

  const [inc, setInc] = useState<IncludeProps>(() => {
    return {
      title: 'Inclusão',
      open: false,
      content: '',
    };
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const [list, setList] = useState<ListProps[]>([]);
  const [dmList, setDMList] = useState<DMListProps[]>([]);
  const [isLOC, setIsLOC] = useState(false);
  const [balance, setBalance] = useState(finHeader.valor);

  const getList = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        num: finHeader.num,
        cod: finHeader.cod,
        anbcod: finHeader.anbcod,
      };
      const response = await api.get(
        `/sgo/fin_rm_detail.php?data=${JSON.stringify(data)}`,
      );
      setList(response.data);
      setBalance(response.data[response.data.length - 1].saldo);

      setPrevDate(response.data[response.data.length - 1].dtmov);
    } catch (err) {
      errorHandling(err);
    }
    setLoading(false);
  }, [errorHandling, finHeader.cod, finHeader.anbcod, finHeader.num]);

  const getDMList = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/sgo/fin_rm_dm.php?data=${JSON.stringify({
        loccod: finHeader.cod,
      })}`,
    );

    const { dmlist, proceed } = response.data;

    setDMList(dmlist);
    setIsLOC(proceed);
    setLoading(false);
  }, [finHeader.cod]);

  useEffect(() => {
    handlePermission(['NAC', 'LOC'], true);
    if (!location.state.finHeader) {
      history.replace(location.pathname.replace('/detail', ''));
    }

    getList();
    if (user.perfil === 'NAC') {
      getDMList();
    }
  }, [
    getList,
    getDMList,
    handlePermission,
    history,
    location.pathname,
    location.state,
    user.perfil,
  ]);

  const totalPago = useMemo(() => {
    return list.reduce((accum, { parc }: ListProps) => {
      return accum + parc;
    }, 0);
  }, [list]);

  const handleFinish = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        const { month } = currDate;
        // const parsedMinDate = new Date(parseISO(prevDate));
        let parsedMinDate;
        if ([1, 7].indexOf(month) > -1) {
          parsedMinDate = minimumAllowedDate(prevDate, true);
        } else {
          parsedMinDate = minimumAllowedDate(prevDate);
        }

        if (
          parsedMinDate.getUTCDate() === handleTimeZone(prevDate).getUTCDate()
        ) {
          parsedMinDate = addDays(parsedMinDate, 1);
        }

        const parsedMaxDate = endOfDay(new Date());

        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida')
            .min(
              parsedMinDate,
              `O lançamento deve ser posterior a ${formatDate(
                subDays(parsedMinDate, 1).toISOString(),
              )}`,
            )
            .max(
              parsedMaxDate,
              `Data limite excedida (${formatDate(
                new Date(parsedMaxDate).toISOString(),
              )})`,
            ),
          value: yup
            .number()
            .min(0.01, 'O valor informado deve ser superior a zero ( R$ 0,00 )')
            .max(
              finHeader.valor + 0.01 - totalPago,
              `O valor informado é superior ao valor restante ( R$ ${formatNumber(
                finHeader.valor - totalPago,
              )} )`,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setInc((state) => ({ ...state, open: false }));

        const newData = {
          ...data,
          ant: (finHeader.valor - totalPago).toFixed(2),
          saldo: (
            finHeader.valor -
            (totalPago + parseFloat(data.value))
          ).toFixed(2),
        };

        const send = new FormData();
        send.append('data', JSON.stringify(newData));

        await api.post('/sgo/fin_rm_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false);
        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [currDate, errorHandling, finHeader.valor, getList, prevDate, totalPago],
  );

  const handleDeleteDiag = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleReduceRM = useCallback(async () => {
    try {
      handleDeleteDiag();
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          loccod: finHeader.cod,
          rm: finHeader.num,
        }),
      );

      await api.post('/sgo/dm_reduce_rm.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Abatimento de DMs realizado.',
      });

      history.goBack();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [
    addToast,
    errorHandling,
    finHeader.cod,
    finHeader.num,
    handleDeleteDiag,
    history,
  ]);

  const buttonStyle = useSpring({
    opacity: width <= 889 ? 1 : 0,
    marginTop: width <= 889 ? '15px' : '0px',
    marginBottom: width <= 889 ? '15px' : '0px',
    pointerEvents: width <= 889 ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />

      {list.length > 0 &&
        list[list.length - 1].saldo > 0 &&
        user.perfil === 'NAC' &&
        user.anbc !== finHeader.cod &&
        width >= 890 && (
          <IncludeButton isButton>
            <button
              type="button"
              onClick={() => setInc((state) => ({ ...state, open: true }))}
            >
              <FaPlusCircle />
            </button>
          </IncludeButton>
        )}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Acompanhamento Financeiro RM ${finHeader.num}`}
        isResponsible
      />
      <Content>
        <FinHeader>
          <span>
            <p>
              Coord:&nbsp;<strong>{finHeader.desc}</strong>
            </p>
          </span>
          <span>
            <p>
              Valor:&nbsp;<strong>R$ {formatNumber(finHeader.valor)}</strong>
            </p>
          </span>
          <span>
            <p>
              Data:&nbsp;
              <strong>{formatDate(finHeader.date)}</strong>
            </p>
          </span>
        </FinHeader>

        {list.length > 0 &&
          list[list.length - 1].saldo > 0 &&
          user.anbc !== finHeader.cod &&
          user.perfil === 'NAC' &&
          width < 890 && (
            <IncButton
              type="button"
              style={buttonStyle}
              onClick={() => setInc((state) => ({ ...state, open: true }))}
            >
              Pagamento da RM
            </IncButton>
          )}
        <FinContent>
          <Wrapper>
            <FinContentHeader>
              <Data>
                <p>Data</p>
              </Data>
              <Anterior>
                <p>S{width >= 420 ? 'aldo' : '.'} Anterior</p>
              </Anterior>
              <Parcela>
                <p>Parcela</p>
              </Parcela>
              <Saldo>
                <p>Saldo</p>
              </Saldo>
            </FinContentHeader>

            {list.map((item, index) => (
              <FinItem paint={index} key={item.seq}>
                <Data>
                  <p>{formatDate(item.dtmov)}</p>
                </Data>
                <Anterior>
                  <p>R$ {formatNumber(item.ant)}</p>
                </Anterior>
                <Parcela>
                  <p>R$ {formatNumber(item.parc)}</p>
                </Parcela>
                <Saldo>
                  <p>R$ {formatNumber(item.saldo)}</p>
                </Saldo>
              </FinItem>
            ))}
          </Wrapper>
        </FinContent>

        {dmList.length > 0 &&
          user.perfil === 'NAC' &&
          isLOC &&
          balance > 0 &&
          finHeader.status === 'E' && (
            <>
              <TableWrapper>
                <Table>
                  <thead>
                    <td style={{ textAlign: 'center' }}>DM</td>
                    <td style={{ textAlign: 'center' }}>Data da DM</td>
                    <td style={{ textAlign: 'right' }}>Valor</td>
                  </thead>
                  <tbody>
                    {dmList.map((item, index) => (
                      <tr
                        key={item.seq}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#e6e6e6',
                        }}
                      >
                        <td style={{ textAlign: 'center' }}>{item.dm}</td>
                        <td style={{ textAlign: 'center' }}>
                          {formatDate(item.data)}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          R$ {formatNumber(item.valor)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>

              <Button bgcolor="#00802b" onClick={handleDeleteDiag}>
                Abater as DMs
              </Button>
            </>
          )}

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form
            ref={formRef}
            onSubmit={handleFinish}
            initialData={{
              num: finHeader.num,
              cod: finHeader.cod,
              date: today,
              value: (finHeader.valor - totalPago).toFixed(2),
            }}
          >
            <I.Title>
              <h2>{inc.title || 'Inclusão'}</h2>
            </I.Title>
            <I.Content>
              <InfoContainer>
                <InputDialog name="num" isHidden />
                <InputDialog name="cod" isHidden />
                <span>
                  <p>
                    Total da RM:&nbsp;
                    <strong>R$ {formatNumber(finHeader.valor)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Total Pago:&nbsp;
                    <strong>R$ {formatNumber(totalPago)}</strong>
                  </p>
                </span>
                <span>
                  <p>
                    Valor Pendente:&nbsp;
                    <strong>
                      R$ {formatNumber(finHeader.valor - totalPago)}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>Data do Pagamento:&nbsp;</p>
                  <InputDialog
                    name="date"
                    type="date"
                    placeholder="dd/mm/aaaa"
                  />
                </span>
                <span>
                  <p>Valor:&nbsp;</p>
                  <InputDialog
                    icon={FaDollarSign}
                    type="number"
                    name="value"
                    mask="currency"
                    step=".01"
                    placeholder="0,00"
                  />
                </span>
              </InfoContainer>
            </I.Content>
            <I.Actions>
              <I.Cancel
                type="button"
                onClick={() =>
                  setInc({
                    ...inc,
                    open: false,
                  })
                }
              >
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <p>
              O valor a pagar da RM{finHeader.num} de R${' '}
              {formatNumber(
                list.length > 0 ? list[list.length - 1].saldo : 0.0,
              )}
              &nbsp;será abatido pelas DM geradas para a sua coordenação.
            </p>
          </D.Content>
          <D.Actions>
            <D.Cancel type="button" onClick={handleDeleteDiag}>
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleReduceRM}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default FinRMDetail;
