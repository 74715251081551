import styled, { css } from 'styled-components';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Button from 'components/Button';
import { lighten } from 'polished';

interface AppearProps {
  showit: boolean;
}

export const FilterContainer = styled.div`
  background: #fff;
  border: 2px solid #efefef;
  margin: 10px auto;
  padding: 0 25px 25px 25px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  span {
    > p {
      color: #8a0002;
      font-size: 16px;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
    }
  }

  form {
    > span {
      &:last-child {
        margin-top: 25px;
      }
    }
  }

  @media screen and (max-width: 430px) {
    width: 100vw;
    border-radius: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    padding: 0 0px 25px 0px;
  }
`;

export const YearContainer = styled.figure`
  display: flex;
  padding: 10px 20px;

  width: 150px;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0 auto;
  position: relative;

  /* position: sticky;
  top: 60px; */

  background: #ffffff;
  /* border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5); */
  transition: border-color 0.35s ease;

  color: #767676;

  > p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  > svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const LeftSVG = styled(FaAngleDoubleLeft)<AppearProps>`
  left: 10px;
  opacity: 0;
  pointer-events: none;
  ${(props) =>
    props.showit &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)<AppearProps>`
  right: 10px;
  opacity: 0;
  pointer-events: none;
  ${(props) =>
    props.showit &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;

export const PrintButton = styled(Button)`
  position: relative;
  margin: 15px auto 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 350px;
  padding: 10px 0px;
  background: ${lighten(0.2, '#332e2e')};
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  p {
    font-size: 16px;
    text-align: left;
  }

  transition: all 0.35s ease;
  svg {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: #332e2e;
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  }
`;
