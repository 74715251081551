import React, { useState, useCallback, useEffect, useRef } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
} from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';
import { useAuth } from 'hooks/auth';
// import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useSpring, animated } from 'react-spring';
import { FaFileExcel, FaTimes } from 'react-icons/fa';

import { parseISO } from 'date-fns';
import { monthList, MonthListProps } from '../../monthList';
import {
  FilterContainer,
  YearContainer,
  LeftSVG,
  RightSVG,
  PrintButton,
} from './styles';

interface LocalStorageProps {
  anbcod?: string;
  anbdesc?: string;
  loccod?: string;
  locdesc?: string;
  code?: string;
  desc?: string;
  year?: string;
  month?: string;
  type?: string;
}

interface CoordSelected {
  code: string;
  desc: string;
}

const F2Mensal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  // const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [coordSelected, setSelectedCoord] = useState(() => {
    const { code, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    if (!!code && !!desc) {
      return { code, desc };
    }
    return user.perfil === 'ZON'
      ? { code: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { code: user.anbc, desc: user.anbdesc }
      : { code: user.loccod, desc: user.locdesc };
  });

  const [mode, setMode] = useState(() => {
    const { type }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    if (type) {
      return type;
    }

    return user.perfil;
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Planilha',
    open: false,
    content: '',
  });

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [months, setMonths] = useState([...monthList]);
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const { month }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    if (month) {
      return {
        value: month,
        label:
          monthList[
            monthList.findIndex(
              (item: MonthListProps) => item.value === parseInt(month, 10),
            )
          ].label,
      };
    }

    const monthNow =
      new Date(parseISO(new Date().toISOString())).getUTCMonth() + 1;
    return {
      value: monthNow,
      label:
        monthList[
          monthList.findIndex((item: MonthListProps) => item.value === monthNow)
        ].label,
    };
  });

  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    if (year) {
      return year.toString();
    }

    return new Date().getUTCFullYear().toString();
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    return anbcod || 'Selecione';
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_F2MES}`,
    );

    return loccod || 'Selecione';
  });

  const ensureMonthSelection = useCallback(() => {
    setTimeout(() => {
      const { month }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_F2MES}`,
      );

      let index = months
        .map((item) => item.value)
        .indexOf(new Date().getUTCMonth() + 1);
      if (month && typeof month != 'undefined') {
        index = months.map((item) => item.value).indexOf(parseInt(month, 10));
      }
      const select = formRef.current?.getFieldRef('month');
      select.options[index].selected = true;
    }, 150);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );

    // const { anbcod, anbdesc }: LocalStorageProps = getLocalStorage(
    //   `${process.env.REACT_APP_F2MES}`,
    // );

    // if (anbdesc) {
    //   setInitialNac(anbdesc);
    // }

    // if (anbcod) {
    //   formRef.current?.setFieldValue('comboANB', anbcod);
    // }
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    setLoading(false);

    if (user.perfil === 'NAC') {
      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
      );
    } else {
      const { anbcod }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_F2MES}`,
      );

      setComboLocs(
        response.data.filter((item: ComboLocalProps) => item.anb === anbcod),
      );
    }
  }, [user.anbc, user.perfil]);

  const getYears = useCallback(async () => {
    try {
      const data = {
        code: coordSelected.code,
        type: mode,
      };

      const response = await api.get(
        `/combos/comboAnosF2.php?data=${JSON.stringify(data)}`,
      );
      setYears(response.data);
      setSelectedYear(response.data[response.data.length - 1]);

      const lastPossible = response.data[response.data.length - 1];
      const currYear = new Date().getUTCFullYear();
      const currMonth = new Date().getUTCMonth() + 1;

      if (parseInt(lastPossible, 10) < currYear) {
        setMonths([...monthList]);
      } else {
        setMonths(() => monthList.filter((item) => item.value <= currMonth));
      }

      ensureMonthSelection();

      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_F2MES}`,
      );

      if (year) {
        const index = response.data.findIndex((item: string) => item === year);
        setSelectedYear(
          index > -1 ? year : response.data[response.data.length - 1],
        );
      }
    } catch (err) {
      errorHandling(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordSelected.code, mode, ensureMonthSelection]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);
    getComboANB();
    getComboLOC();
    getYears();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getYears]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setInitialNac(value);
    setInitialLoc('Selecione');
    setSelectedCoord({ code: value, desc: text });

    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      anbcod: value,
      anbdesc: text,
      code: value,
      desc: text,
      type: 'NAC',
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_F2MES}`, [
      'loccod',
      'locdesc',
    ]);

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));

    setMode('NAC');
  }, [locs]);

  const handleLOCselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLocal');
    const { value, text } = select.options[select.selectedIndex];

    setInitialLoc(value);
    setSelectedCoord({ code: value, desc: text });

    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      loccod: value,
      locdesc: text,
      code: value,
      desc: text,
      type: 'LOC',
    });

    setMode('LOC');
  }, []);

  const handleNACFilterReset = useCallback(() => {
    setInitialNac('Selecione');

    setComboNacs([]);

    setInitialLoc('Selecione');
    setInitialNac('Selecione');
    deleteLocalStorageItemKey(`${process.env.REACT_APP_F2MES}`, [
      'code',
      'desc',
      'anbcod',
      'anbdesc',
      'loccod',
      'locdesc',
    ]);

    setSelectedCoord({ code: user.zoncod, desc: user.zondesc });
    setMode('ZON');
    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      type: 'ZON',
    });

    setComboLocs([]);
  }, [user.zoncod, user.zondesc]);

  const handleLOCFilterReset = useCallback(() => {
    setMode('NAC');
    if (user.perfil === 'ZON') {
      handleANBselect();
      return;
    }

    deleteLocalStorageItemKey(`${process.env.REACT_APP_F2MES}`, [
      'code',
      'desc',
      'loccod',
      'locdesc',
      'mode',
    ]);
    setInitialLoc('Selecione');
    setSelectedCoord({ code: user.anbc, desc: user.anbdesc });
    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      type: 'NAC',
    });
  }, [handleANBselect, user.anbc, user.anbdesc, user.perfil]);

  const handleSubYear = useCallback(() => {
    const newYear = (parseInt(selectedYear, 10) - 1).toString();
    setSelectedYear(newYear);
    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      year: newYear,
    });

    const currYear = new Date().getUTCFullYear();
    const currMonth = new Date().getUTCMonth() + 1;

    if (parseInt(newYear, 10) < currYear) {
      setMonths([...monthList]);
    } else {
      setMonths(() => monthList.filter((item) => item.value <= currMonth));
    }
    ensureMonthSelection();
  }, [selectedYear, ensureMonthSelection]);

  const handleAddYear = useCallback(() => {
    const newYear = (parseInt(selectedYear, 10) + 1).toString();

    if (newYear === years[years.length - 1]) {
      const currMonth = new Date().getUTCMonth() + 1;
      setSelectedMonth({
        value: currMonth,
        label: months[currMonth - 1].label,
      });
    }
    setSelectedYear(newYear);
    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      year: newYear,
    });

    const currYear = new Date().getUTCFullYear();
    const currMonth = new Date().getUTCMonth() + 1;

    if (parseInt(newYear, 10) < currYear) {
      setMonths([...monthList]);
    } else {
      setMonths(() => monthList.filter((item) => item.value <= currMonth));
    }
    ensureMonthSelection();
  }, [months, selectedYear, years, ensureMonthSelection]);

  const handleDownload = useCallback(async () => {
    try {
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(`a[id=f2link]`);
      if (link) {
        link.click();
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const handleMonthSelection = useCallback(() => {
    const select = formRef.current?.getFieldRef('month');
    const { value, text } = select.options[select.selectedIndex];

    setSelectedMonth({
      value: parseInt(value, 10),
      label: text,
    });

    setLocalStorage(`${process.env.REACT_APP_F2MES}`, {
      month: value,
    });
  }, []);

  const filterRemove = useSpring({
    opacity: initialNac !== 'Selecione' ? 1 : 0,
    pointerEvents: initialNac !== 'Selecione' ? 'all' : 'none',
  });

  const locFilterRemove = useSpring({
    opacity: initialLoc !== 'Selecione' ? 1 : 0,
    pointerEvents: initialLoc !== 'Selecione' ? 'all' : 'none',
  });

  const anchor = useSpring({
    display: 'none',
    height: '0px',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Informe Econômico MENSAL - F2</AlteredHeader>
        <FilterContainer>
          <Form ref={formRef} onSubmit={() => null}>
            {['ZON'].indexOf(user.perfil) > -1 && (
              <span>
                <p>Selecione a Nacional:</p>
                <SelectV2
                  name="comboANB"
                  content={comboNacs}
                  onChange={handleANBselect}
                  initial={initialNac}
                />
                <RemoveButton
                  type="button"
                  onClick={handleNACFilterReset}
                  style={filterRemove}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            )}
            {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
              <span>
                <p>Selecione a Local:</p>
                <SelectV2
                  name="comboLocal"
                  content={comboLocs}
                  onChange={handleLOCselect}
                  initial={initialLoc}
                />
                <RemoveButton
                  type="button"
                  onClick={handleLOCFilterReset}
                  style={locFilterRemove}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            )}

            <YearContainer>
              <LeftSVG
                onClick={handleSubYear}
                showit={
                  !!(
                    years.length > 1 &&
                    parseInt(selectedYear, 10) > parseInt(years[0], 10)
                  )
                }
              />
              <p>{selectedYear}</p>
              <RightSVG
                onClick={handleAddYear}
                showit={
                  !!(
                    years.length > 1 &&
                    parseInt(selectedYear, 10) <
                      parseInt(years[years.length - 1], 10)
                  )
                }
              />
            </YearContainer>
            <span>
              <p>Escolha o mês:</p>
              <SelectV2
                name="month"
                content={months}
                onChange={handleMonthSelection}
              />
            </span>
          </Form>
        </FilterContainer>

        <PrintButton
          type="button"
          onClick={() => {
            setShow({
              title: years.length > 0 ? 'Gerando Planilha' : 'Atenção',
              open: true,
              content:
                years.length > 0 ? (
                  <div>
                    <p>O arquivo já está sendo gerado!</p>
                    <p>
                      Assim que estiver pronto o download começará
                      automaticamente.
                    </p>
                    <p>Aguarde...</p>
                  </div>
                ) : (
                  <div>
                    <p>
                      Não há registros de movimentos para a coordenação{' '}
                      {coordSelected.desc}
                    </p>
                  </div>
                ),
            });

            if (years.length > 0) {
              handleDownload();
            }
          }}
        >
          <span>
            <p>Informe F2 Mensal</p>
          </span>
          <span>
            <p>{coordSelected.desc}</p>
          </span>
          <FaFileExcel />
        </PrintButton>

        {/* <div>{JSON.stringify(years)}</div>
        <div>{parseInt(selectedYear, 10)}</div>
        <div>{parseInt(years[0], 10)}</div>
        <div>
          {JSON.stringify(parseInt(selectedYear, 10) <= parseInt(years[0], 10))}
        </div> */}
        <animated.a
          style={anchor}
          id="f2link"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_informe_f2.php?data=${JSON.stringify({
            mode,
            code: coordSelected.code,
            year: selectedYear,
            month: selectedMonth.value,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          F2Mensal
        </animated.a>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default F2Mensal;
