import React, { useState, useEffect, useCallback, useRef } from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import Textarea from 'components/TextareaDialog';

import InputDialog from 'components/InputDialog';

import { useLocation, useHistory } from 'react-router-dom';
import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useWindow } from 'hooks/window';

import api from 'services/api';

import {
  AlteredHeader,
  Container,
  Content,
  IncButton,
} from 'styles/sgo_wrappers';
import { FaPencilAlt, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import * as U from 'styles/dialog_update';
import { UpdateProps, ModalContent } from 'styles/dialog_update';

import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';

import * as I from 'styles/dialog_include';
import { IncludeProps } from 'styles/dialog_include';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';

import getValidationErrors from 'utils/getValidationErrors';
import { formatDate } from 'utils/formatDate';

import {
  TableWrapper,
  Table,
  Desc,
  Dated,
  Update,
  ButtonTD,
  Delete,
} from './styles';

import { FIGuiaProps } from '../main';
import { checkSentDate, checkTopDate } from '../checkDates';

interface ListProps {
  date: string;
  desc: string;
}

interface FormData {
  date: string;
  description: string;
}

const FIGDialogo: React.FC = () => {
  const includeRef = useRef<FormHandles>(null);
  const updateRef = useRef<FormHandles>(null);
  const deleteRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { width } = useWindow();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);
  const location = useLocation<FIGuiaProps>();
  const history = useHistory();
  const [gState] = useState(() => ({ ...location.state }));
  const [list, setList] = useState<ListProps[]>([]);

  const [updateDiag, setUpdateDiag] = useState({} as UpdateProps);
  const [deleteDiag, setDeleteDiag] = useState({} as DeleteProps);
  const [inc, setInc] = useState<IncludeProps>(() => {
    return {
      title: 'Inclusão',
      open: false,
      content: '',
    };
  });

  const [prevReg, setPrevReg] = useState({} as FormData);
  const [minDate, setMinDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const [maxDate, setMaxDate] = useState(
    `${new Date().getUTCFullYear()}-${`0${new Date().getUTCMonth() + 1}`.slice(
      -2,
    )}-${`0${new Date().getUTCDate()}`.slice(-2)}`,
  );

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/sgo/fig_dialogo.php?data=${JSON.stringify({
          ...gState,
          mode: 'list',
        })}`,
      );

      const { diags, sentDate, topDate } = response.data;

      setMinDate(sentDate);
      setMaxDate(topDate);
      setList(diags);
      setPrevReg({} as FormData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [gState, errorHandling]);

  useEffect(() => {
    if (!location.state) {
      history.goBack();
    }
    getList();
  }, [getList, history, location.state]);

  const getDesc = useCallback(
    (ocurr: string) => {
      const index = list.findIndex((item) => item.date === ocurr);
      setPrevReg({ date: list[index].date, description: list[index].desc });
    },
    [list],
  );

  const handlePreInc = useCallback(() => {
    setInc((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePreAlt = useCallback(
    (ocurr: string) => {
      getDesc(ocurr);

      setUpdateDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handlePreDel = useCallback(
    (ocurr: string) => {
      getDesc(ocurr);

      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [getDesc],
  );

  const handleInclude = useCallback(
    async (data: FormData) => {
      try {
        includeRef.current?.setErrors({});

        if (list.findIndex((item) => item.date === data.date) > -1) {
          includeRef.current?.setFieldError(
            'date',
            'Já existe um Diálogo Fraterno para essa data.',
          );
          return;
        }
        setLoading(true);
        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'givenDate',
              `Diálogo deve ser após a data de envio (${formatDate(minDate)})`,
              (sent: string) => {
                return checkSentDate(minDate, sent);
              },
            )
            .test(
              'todayDate',
              `Diálogo deve ser anterior a ${formatDate(maxDate)}.`,
              (today: string) => {
                return checkTopDate(today, maxDate);
              },
            ),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            ...data,
            gseq: gState.gseq,
            mode: 'inc',
          }),
        );

        setInc((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_dialogo.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          includeRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, list, maxDate, minDate],
  );

  const handleUpdate = useCallback(
    async (data: FormData) => {
      try {
        updateRef.current?.setErrors({});

        if (
          list.findIndex(
            (item) => item.date === data.date && prevReg.date !== data.date,
          ) > -1
        ) {
          updateRef.current?.setFieldError(
            'date',
            'Já existe um Diálogo Fraterno para essa data.',
          );
          return;
        }
        setLoading(true);
        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .test(
              'sentDate',
              `Diálogo deve ser após a data de envio (${formatDate(minDate)})`,
              (gd: string) => {
                return checkSentDate(minDate, gd);
              },
            )
            .test(
              'todayDate',
              `Diálogo deve ser anterior a ${formatDate(maxDate)}.`,
              (today: string) => {
                return checkTopDate(today, maxDate);
              },
            ),
          description: yup
            .string()
            .trim()
            .min(5, 'Quantidade mínima de caracteres não atingida (5).'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const send = new FormData();

        send.append(
          'data',
          JSON.stringify({
            prevDate: prevReg.date,
            ...data,
            gseq: gState.gseq,
            mode: 'update',
          }),
        );

        setUpdateDiag((state) => ({ ...state, open: !state.open }));

        await api.post('/sgo/fig_dialogo.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getList();
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          updateRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [errorHandling, gState.gseq, getList, list, maxDate, minDate, prevReg.date],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          date: prevReg.date,
          gseq: gState.gseq,
          mode: 'delete',
        }),
      );

      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/sgo/fig_dialogo.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getList();
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, gState.gseq, getList, prevReg.date]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title={gState.name} />
      <Content>
        <AlteredHeader>
          <p>Diálogo Fraterno</p>
        </AlteredHeader>
        {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
          <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        )}

        <TableWrapper>
          <Table>
            <thead>
              <Dated>Data</Dated>
              <Desc>Descrição</Desc>
              {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                <>
                  <Update>Alt.</Update>
                  <Delete>Exc.</Delete>
                </>
              )}
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.date}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      <Dated>Data</Dated>
                      <Desc>Descrição</Desc>
                      {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                        <>
                          <Update>Alt.</Update>
                          <Delete>Exc.</Delete>
                        </>
                      )}
                    </tr>
                  )}

                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    <Dated>{formatDate(item.date)}</Dated>
                    <Desc>{item.desc}</Desc>
                    {['LOC', 'NAC'].indexOf(user.perfil) > -1 && (
                      <>
                        <Update>
                          <ButtonTD
                            used="alt"
                            onClick={() => handlePreAlt(item.date)}
                          >
                            <FaPencilAlt />
                          </ButtonTD>
                        </Update>
                        <Delete>
                          <ButtonTD
                            used="del"
                            onClick={() => handlePreDel(item.date)}
                          >
                            <FaTimesCircle />
                          </ButtonTD>
                        </Delete>
                      </>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <I.Container scroll="paper" maxWidth={false} open={inc.open}>
          <Form ref={includeRef} onSubmit={handleInclude}>
            <I.Title>
              <h2>Novo Registro</h2>
            </I.Title>
            <I.Content>
              <h4
                style={{
                  color: '#8a0002',
                  width: '100%',
                  textAlign: 'left',
                  margin: '20px 0 0 0',
                }}
              >
                Data do Diálogo Fraterno:
              </h4>
              <InputDialog
                isDate
                name="date"
                containerStyle={{
                  width:
                    width >= 472 ? '360px' : width >= 392 ? '280px' : '245px',

                  padding: '5px 2px 4.82px 5px',
                }}
                placeholder="dd/mm/aaaa"
              />
              <h4
                style={{ color: '#8a0002', width: '100%', textAlign: 'left' }}
              >
                Conteúdo:
              </h4>
              <Textarea
                name="description"
                placeholder="Insira a descrição"
                mask="alpha"
                containerStyle={{
                  width:
                    width >= 472 ? '360px' : width >= 392 ? '280px' : '245px',
                }}
              />
            </I.Content>
            <I.Actions>
              <I.Cancel type="button" onClick={handlePreInc}>
                Cancelar
              </I.Cancel>
              <I.Confirm type="submit">Finalizar</I.Confirm>
            </I.Actions>
          </Form>
        </I.Container>

        <U.Container scroll="paper" maxWidth={false} open={!!updateDiag.open}>
          <U.Title>
            <h2>Atualização</h2>
          </U.Title>
          <Form
            ref={updateRef}
            onSubmit={handleUpdate}
            initialData={{
              date: prevReg.date,
              description: prevReg.description,
            }}
          >
            <U.Content>
              <ModalContent>
                <span>
                  <p>Data do Diálogo Fraterno:</p>
                  <InputDialog
                    type="date"
                    name="date"
                    containerStyle={{
                      width:
                        width >= 472
                          ? '360px'
                          : width >= 392
                          ? '280px'
                          : '245px',

                      padding: '5px 2px 4.82px 5px',
                    }}
                    placeholder="dd/mm/aaaa"
                  />
                </span>
                <span>
                  <p>Conteúdo:</p>
                  <Textarea
                    name="description"
                    mask="alpha"
                    placeholder="Insira a descrição"
                    containerStyle={{
                      width:
                        width >= 472
                          ? '360px'
                          : width >= 392
                          ? '280px'
                          : '245px',
                    }}
                  />
                </span>
              </ModalContent>
            </U.Content>
            <U.Actions>
              <U.Cancel
                type="button"
                onClick={() => setUpdateDiag({ open: false })}
              >
                Cancelar
              </U.Cancel>
              <U.Confirm type="submit">Confirmar</U.Confirm>
            </U.Actions>
          </Form>
        </U.Container>

        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <Form ref={deleteRef} onSubmit={handleDelete}>
            <D.Content>
              <ModalDeleteContent>
                <p>
                  Você está prestes a excluir de forma permanente o registro que
                  contém a seguinte informação:
                </p>
                <div>
                  <strong>{prevReg.description}</strong>
                </div>
                <p>
                  Se estiver seguro de sua decisão, pressione o botão confirmar.
                </p>
              </ModalDeleteContent>
            </D.Content>
            <D.Actions>
              <D.Cancel
                type="button"
                onClick={() => setDeleteDiag({ open: false })}
              >
                Cancelar
              </D.Cancel>
              <D.Confirm type="submit">Confirmar</D.Confirm>
            </D.Actions>
          </Form>
        </D.Container>
      </Content>

      <SGOFooter />
    </Container>
  );
};

export default FIGDialogo;
