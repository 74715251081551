import { shade } from 'polished';
import styled from 'styled-components';
import { Content } from 'styles/sgo_wrappers';

export const AlteredContent = styled(Content)`
  form {
    padding: 10px 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        width: 100%;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        color: #8a0002;
        margin-bottom: 3px;
      }

      > button {
        position: absolute;
        top: 50%;
        right: -35px;
        transform: translateY(-25%);
        border: 0;
        outline: none;
        align-self: center;
        /* width: 100%; */
        margin: 5px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: #c53030;

        font-size: 14px;
        font-weight: 500;

        svg {
          width: 20px;
          height: 20px;
          margin: 0 5px;
          transition: color 0.5s;
        }

        &:hover {
          color: ${shade(0.2, '#c53030')};
        }
      }

      & + span {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 420px) {
      span {
        > button {
          position: relative;
          right: 0;
          top: 0;
          transform: translateY(0);
        }
      }
    }

    @media screen and (max-height: 835px) {
      padding-bottom: 150px;
    }
  }
`;

export const RemovalContainer = styled.button`
  display: flex;
  align-items: center;
  > p {
    font-size: 16px;
    color: #8a0002;
  }
`;
