import styled from 'styled-components';
import { shade } from 'polished';
import { Update } from 'styles/option_buttons';

export const Grid = styled.div`
  width: 90vw;
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;

  margin: 10px auto;

  @media screen and (max-width: 1000px) {
    grid: auto / 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    grid: auto / 1fr;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;

    display: flex;
    flex-direction: column;
  }
`;

export const GridItem = styled.div`
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  background: #fff;
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  span {
    display: flex;

    p {
      font-size: 16px;
      color: #767676;
      /* display: flex;
      align-items: center; */
      strong {
        color: #332e2e;
      }

      & + p {
        margin-left: 10px;
      }
    }
    & + span {
      margin: 7px 0 0 0;
    }
  }

  @media screen and (max-width: 400px) {
    border-left-width: 0px;
    border-right-width: 0px;
    border-radius: 0px;
  }
`;

export const StyledUpdate = styled(Update)`
  background: #595d88;
  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: ${shade(0.2, '#595d88')};
  }
`;
