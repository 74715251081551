import styled from 'styled-components';

import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

export const Grid = styled.div`
  padding: 35px 0 10px 0;
  width: 90vw;
  /* max-width: 1300px; */

  display: grid;

  grid: auto / 1fr 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 50px;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1800px) {
    grid: auto / 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1250px) {
    grid: auto / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid: auto / 1fr;
    width: 360px;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;

  position: relative;

  padding-bottom: 10px;

  border: 2px solid #332e2e;
  border-radius: 5px;
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;

  &:hover {
    box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

    > figure {
      transform: translateY(-40%) scale(1.1);
      box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (max-width: 400px) {
    border-radius: 0px;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const GridItemAvatar = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #332e2e;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  transform: translateY(-35%);

  transition: all 0.5s;

  img {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const GridItemText = styled.span`
  padding: 0 10px;
  margin-top: -25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 17px;
  }

  > p {
    font-size: 16px;
    color: #565656;
    font-weight: 500;
  }
`;

export const Birthday = styled.span`
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 500;
  }
  svg {
    height: 35px;
    width: 35px;
    margin: 0 15px 0 0;
  }
`;

export const MonthContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  z-index: 2;

  width: 360px;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0 auto;
  position: relative;

  position: sticky;
  top: 120px;

  background: #ffffff;
  border: 3px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  transition: border-color 0.35s ease;

  color: #767676;

  p {
    color: #332e2e;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: #767676;
    transition: all 0.35s ease;
  }

  &:hover {
    border-color: #767676;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const LeftSVG = styled(FaAngleDoubleLeft)`
  left: 10px;

  &:hover {
    transform: translateX(-2px);
    color: #332e2e;
  }
`;

export const RightSVG = styled(FaAngleDoubleRight)`
  right: 10px;
  &:hover {
    transform: translateX(2px);
    color: #332e2e;
  }
`;
