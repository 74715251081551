import React, { useState, useEffect, useCallback } from 'react';

import { Container, AlteredContent, Header } from 'styles/sgo_wrappers';
import { Link, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';

import { FaRegFileExcel, FaRegListAlt } from 'react-icons/fa';

import { useAuth } from 'hooks/auth';

import { useCredentials } from 'hooks/credentials';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import { Menu, AnimatedDiv } from './styles';

const EstoqueMenu: React.FC = () => {
  const { pathname } = useLocation();
  const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando arquivo',
    open: false,
    content: (
      <div>
        <p>O arquivo já está sendo gerado!</p>
        <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
        <p>Assim que estiver pronto o download começará automaticamente.</p>
      </div>
    ),
  });

  const { user } = useAuth();

  const { handlePermission, checkCredentials } = useCredentials();

  useEffect(() => {
    handlePermission(['GUI']);
    checkCredentials();
  }, [handlePermission, checkCredentials]);

  const handleDownload = useCallback(() => {
    setShow({
      ...show,
      open: true,
    });

    const timer = setTimeout(() => {
      setShow({
        ...show,
        open: false,
      });
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <Container>
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Menu de Estoque</Header>
      <AlteredContent pixels="231px">
        <Menu>
          <AnimatedDiv duration={1.1}>
            <Link to={`${pathname.replace('/menu', '')}`}>
              <FaRegListAlt size={30} />
              <h3>
                Estoque da{user.perfil !== 'LOC' && 's'} Coordenaç
                {user.perfil !== 'LOC' ? 'ões' : 'ão'}
              </h3>
            </Link>
          </AnimatedDiv>
          <AnimatedDiv duration={1.1} delay={0.35}>
            <Link to={`${pathname.replace('menu', 'ajustes')}`}>
              <FaRegListAlt size={30} />
              <h3>Ajustes Realizados no Estoque</h3>
            </Link>
          </AnimatedDiv>

          {user.perfil === 'NAC' && (
            <AnimatedDiv duration={1.1} delay={0.7}>
              <Link to={`${pathname.replace('menu', 'estoquexsolic')}`}>
                <FaRegListAlt size={30} />
                <h3>Estoque x Solicitações em aberto</h3>
              </Link>
            </AnimatedDiv>
          )}
          <AnimatedDiv
            duration={1.1}
            delay={user.perfil !== 'NAC' ? 0.7 : 1.05}
          >
            <a
              href={`${
                process.env.REACT_APP_API
              }/sgo/xls_inventario.php?token=${JSON.stringify(token)}`}
              rel="noreferrer noopener"
              target="_top"
              onClick={handleDownload}
            >
              <FaRegFileExcel size={30} />
              <h3>Planilha para Inventário</h3>
            </a>
          </AnimatedDiv>
        </Menu>
      </AlteredContent>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() =>
              setShow({
                ...show,
                open: false,
              })}
          >
            OK
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default EstoqueMenu;
