import React, { useState, useCallback, useEffect, useRef } from 'react';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import SGOFooter from 'components/SGOFooter';
import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';
import { useAuth } from 'hooks/auth';
// import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useSpring, animated } from 'react-spring';
import { FaFileExcel } from 'react-icons/fa';

import {
  FilterContainer,
  YearContainer,
  LeftSVG,
  RightSVG,
  PrintButton,
} from './styles';

interface LocalStorageProps {
  anbcod?: string;
  anbdesc?: string;
  loccod?: string;
  locdesc?: string;
  code?: string;
  desc?: string;
  year?: string;
  month?: string;
  type?: string;
}

interface CoordSelected {
  code: string;
  desc: string;
}

const DMGRelatorio: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  // const { addToast } = useToast();
  const { handlePermission, errorHandling } = useCredentials();
  const [loading, setLoading] = useState(true);

  const [coordSelected, setSelectedCoord] = useState(() => {
    const { code, desc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    if (!!code && !!desc) {
      return { code, desc };
    }
    return { code: user.loccod, desc: user.locdesc };
  });

  const [mode, setMode] = useState(() => {
    const { type }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    if (type) {
      return type;
    }

    return user.perfil;
  });

  const [show, setShow] = useState<ShowProps>({
    title: 'Gerando Planilha',
    open: false,
    content: '',
  });

  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);

  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState(() => {
    const { year }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    if (year) {
      return year.toString();
    }

    return new Date().getUTCFullYear().toString();
  });

  const [initialNac, setInitialNac] = useState(() => {
    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    return anbcod || user.anbc;
  });

  const [initialLoc, setInitialLoc] = useState(() => {
    const { loccod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    return loccod || user.loccod;
  });

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);
    setLoading(false);

    const { anbcod }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DMGXLS}`,
    );

    setComboLocs(
      response.data.filter((item: ComboLocalProps) =>
        anbcod ? item.anb === anbcod : item.anb === user.anbc,
      ),
    );

    // const { loccod, locdesc }: LocalStorageProps = getLocalStorage(
    //   `${process.env.REACT_APP_DMGXLS}`,
    // );
  }, [user.anbc]);

  const getYears = useCallback(async () => {
    try {
      const data = {
        code: coordSelected.code,
        type: mode,
        prefix: 'DM',
      };

      const response = await api.get(
        `/combos/comboAnosDXG.php?data=${JSON.stringify(data)}`,
      );
      setYears(response.data);
      setSelectedYear(response.data[response.data.length - 1]);

      const { year }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_DMGXLS}`,
      );

      if (year) {
        const index = response.data.findIndex((item: string) => item === year);
        setSelectedYear(
          index > -1 ? year : response.data[response.data.length - 1],
        );
      }
    } catch (err) {
      errorHandling(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordSelected.code, mode]);

  useEffect(() => {
    handlePermission(['GUI', 'INT']);
    getComboANB();
    getComboLOC();
    getYears();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getYears]);

  const handleANBselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboANB');
    const { value, text } = select.options[select.selectedIndex];

    setInitialNac(value);
    setInitialLoc('Selecione');
    setSelectedCoord({ code: value, desc: text });

    setLocalStorage(`${process.env.REACT_APP_DMGXLS}`, {
      anbcod: value,
      anbdesc: text,
      code: value,
      desc: text,
      type: 'NAC',
    });

    deleteLocalStorageItemKey(`${process.env.REACT_APP_DMGXLS}`, [
      'loccod',
      'locdesc',
    ]);

    setComboLocs(locs.filter((item: ComboLocalProps) => item.anb === value));

    setMode('NAC');
  }, [locs]);

  const handleLOCselect = useCallback(() => {
    const select = formRef.current?.getFieldRef('comboLocal');
    const { value, text } = select.options[select.selectedIndex];

    setInitialLoc(value);
    setSelectedCoord({ code: value, desc: text });

    setLocalStorage(`${process.env.REACT_APP_DMGXLS}`, {
      loccod: value,
      locdesc: text,
      code: value,
      desc: text,
      type: 'LOC',
    });

    setMode('LOC');
  }, []);

  const handleSubYear = useCallback(() => {
    const newYear = (parseInt(selectedYear, 10) - 1).toString();
    setSelectedYear(newYear);
    setLocalStorage(`${process.env.REACT_APP_DMGXLS}`, {
      year: newYear,
    });
  }, [selectedYear]);

  const handleAddYear = useCallback(() => {
    const newYear = (parseInt(selectedYear, 10) + 1).toString();

    setSelectedYear(newYear);
    setLocalStorage(`${process.env.REACT_APP_DMGXLS}`, {
      year: newYear,
    });
  }, [selectedYear]);

  const handleDownload = useCallback(async () => {
    try {
      const timer = setTimeout(() => {
        setShow((state) => ({
          ...state,
          open: false,
        }));
      }, 5000);

      const link = document.querySelector<HTMLAnchorElement>(`a[id=dmglink]`);
      if (link) {
        link.click();
      }

      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      errorHandling(err);
    }
  }, [errorHandling]);

  const anchor = useSpring({
    display: 'none',
    height: '0px',
  });

  const shouldAppear = useSpring({
    opacity: initialLoc === 'Selecione' ? '0' : '1',
    pointerEvents: initialLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Content>
        <AlteredHeader>Dia Mensal do Guia - Relatório</AlteredHeader>
        <FilterContainer>
          <Form ref={formRef} onSubmit={() => null}>
            {['ZON'].indexOf(user.perfil) > -1 && (
              <span>
                <p>Selecione a Nacional:</p>
                <SelectV2
                  name="comboANB"
                  content={comboNacs}
                  onChange={handleANBselect}
                  initial={initialNac}
                />
              </span>
            )}
            {['ZON', 'NAC'].indexOf(user.perfil) > -1 && (
              <span>
                <p>Selecione a Local:</p>
                <SelectV2
                  name="comboLocal"
                  content={comboLocs}
                  onChange={handleLOCselect}
                  initial={initialLoc}
                />
              </span>
            )}

            <YearContainer>
              <LeftSVG
                onClick={handleSubYear}
                showit={
                  !!(
                    years.length > 1 &&
                    parseInt(selectedYear, 10) > parseInt(years[0], 10)
                  )
                }
              />
              <p>{selectedYear}</p>
              <RightSVG
                onClick={handleAddYear}
                showit={
                  !!(
                    years.length > 1 &&
                    parseInt(selectedYear, 10) <
                      parseInt(years[years.length - 1], 10)
                  )
                }
              />
            </YearContainer>
          </Form>
        </FilterContainer>
        <animated.div style={shouldAppear}>
          <PrintButton
            type="button"
            onClick={() => {
              setShow({
                title: years.length > 0 ? 'Gerando Planilha' : 'Atenção',
                open: true,
                content:
                  years.length > 0 ? (
                    <div>
                      <p>O arquivo já está sendo gerado!</p>
                      <p>
                        Assim que estiver pronto o download começará
                        automaticamente.
                      </p>
                      <p>Aguarde...</p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        Não há registros de movimentos para a coordenação{' '}
                        {coordSelected.desc}
                      </p>
                    </div>
                  ),
              });

              if (years.length > 0) {
                handleDownload();
              }
            }}
          >
            <span>
              <p>Dia Mensal do Guia</p>
            </span>
            <span>
              <p>{coordSelected.desc}</p>
            </span>
            <FaFileExcel />
          </PrintButton>
        </animated.div>

        {/* <div>{JSON.stringify(years)}</div>
        <div>{parseInt(selectedYear, 10)}</div>
        <div>{parseInt(years[0], 10)}</div>
        <div>
          {JSON.stringify(parseInt(selectedYear, 10) <= parseInt(years[0], 10))}
        </div> */}
        <animated.a
          style={anchor}
          id="dmglink"
          href={`${
            process.env.REACT_APP_API
          }/sgo/xls_dmg.php?data=${JSON.stringify({
            anb:
              ['INT', 'ZON'].indexOf(user.perfil) > -1
                ? formRef.current?.getFieldValue('comboANB')
                : user.anbc,
            loc:
              ['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1
                ? formRef.current?.getFieldValue('comboLocal')
                : user.loccod,
            year: selectedYear,
            token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
          })}`}
          rel="noreferrer noopener"
          target="_top"
        >
          F2Mensal
        </animated.a>
      </Content>
      <S.Container scroll="paper" maxWidth={false} open={show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>{show.content}</S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <SGOFooter />
    </Container>
  );
};

export default DMGRelatorio;
