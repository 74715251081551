import styled from 'styled-components';

import { shade } from 'polished';

export const Grid = styled.div`
  width: 90vw;
  max-width: 1000px;
  display: grid;
  grid: auto / 1fr;
  padding: 15px 0 10px;

  @media screen and (max-width: 500px) {
    width: 100vw;
  }
`;

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  padding: 15px 10px;
  border-radius: 5px;
  border: 3px solid #efefef;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  transition: border-color 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    font-size: 15px;
    width: 100%;
    text-align: start;
  }

  > span {
    margin: 10px 0 0 0;
    display: flex;

    p {
      color: #767676;
      font-size: 15px;
      strong {
        color: #332e2e;
      }
      & + p {
        margin: 0 0 0 10px;
      }
    }

    & + span {
      margin: 5px 0 0 0;
    }
  }

  & + div {
    margin-top: 20px;
  }

  @media screen and (max-width: 500px) {
    width: 100vw;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

export const GridSubGrid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;

  margin: 0 auto;
  width: 80%;

  > span {
    display: flex;

    p {
      color: #767676;
      font-size: 15px;
      strong {
        color: #332e2e;
      }
      & + p {
        margin: 0 0 0 10px;
      }
    }
  }

  @media screen and (max-width: 980px) {
    grid: auto / 1fr 1fr;
  }

  @media screen and (max-width: 500px) {
    grid: auto / 1fr;
  }
`;

export const ShowContainer = styled.div`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-25%);

  button {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 2px solid #332e2e;

    font-size: 14px;

    width: 30px;
    height: 30px;

    background: #5e5555;

    transition: background-color 0.5s;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: ${shade(0.2, '#5e5555')};
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  > h4 {
    margin-top: 5px;
    background: #fff;
    border: 3px solid #a6a6a6;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    font-size: 16px;
    text-align: end;
    color: #767676;
    strong {
      color: #332e2e;
    }
  }

  div {
    background: #fff;
    border: 2px solid #a6a6a6;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;

    h4 {
      font-size: 16px;
      margin: 0 20px 0 0;
    }
    span {
      display: flex;
      p {
        color: #767676;
        strong {
          color: #332e2e;
        }
        & + p {
          margin: 0 0 0 10px;
        }
      }
    }
    & + div {
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 1100px) {
    div {
      flex-direction: column;
      span {
        p + p {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    div {
      span {
        flex-direction: column;
        p + p {
          margin: 3px 0 0 0;
        }
      }
    }
  }
`;
