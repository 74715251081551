import React, { useEffect, useState, useCallback } from 'react';
import api from 'services/api';

import {
  FaPhone,
  FaEnvelope,
  FaUser,
  FaBuilding,
  FaAngleRight,
} from 'react-icons/fa';
import {
  Zonal,
  ZonalSpan,
  Loader,
  ZonalIntContainer,
  FieldSet,
  Separator,
} from './styles';

// const world = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/world.png`;
// const path = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/XXX.png`;
const ZBR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/brasil2.png`;
// const USA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/usa.png`;
// const MEX = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/mexico.png`;
// const AMC = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/americaCentral.png`;
// const CAR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/caribe.png`;
// const AND = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/andina.png`;
// const CS1 = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/coneSul.png`;
// const CS2 = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/coneSul2.png`;
// const EUR = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/europa.png`;
// const ZLA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/lusoAfricana.png`;
// const ZAS = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/asia.png`;
// const NFA = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/africaSul.png`;
// const NFZ = `${process.env.REACT_APP_ASSETS_DIR}/peloMundo/novaZelandia.png`;

interface IntZon {
  cod: string;
  desc: string;
  area: string;
  cname: string;
  cmail: string;
  cphone1: string;
  cphone2: string;
  clocal: string;
  sname: string;
  smail: string;
  sphone1: string;
  sphone2: string;
  slocal: string;
  banner: string;
}

const PeloMundoCoords: React.FC = () => {
  const [zbr, setZBR] = useState<any>({} as any);
  const [ints, setInts] = useState<IntZon[]>([]);

  const getInfo = useCallback(async () => {
    const response = await api.get('/web/coordinfo.php');
    const { br, int } = response.data;
    setZBR(br);
    setInts(int);
    // console.table(
    //   int.map((item: IntZon) => ({
    //     ...item,
    //     banner: path.replace('XXX', item.banner),
    //   })),
    // );

    // setInts(int.map((item) => ({...item, banner: path.replace('XXX', item.banner)})));

    // setTimeout(() => {
    // }, 2000);
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <>
      <Separator>Coordenações Zonais Brasil</Separator>
      <Zonal>
        {/* <h1>Coordenação Zonal Brasil</h1> */}
        <div>
          <figure>
            <img src={ZBR} alt="Zonal Brasil" />
          </figure>
          <ZonalSpan bgcolor="#009c3b">
            <h2>Zona Brasil Oeste</h2>
            {zbr?.bo ? (
              <>
                <span>
                  <p>Coordenador:</p>
                  <h4>{zbr.bo?.components.coord || '-'}</h4>
                </span>
                <span>
                  <p>Secretário:</p>
                  <h4>{zbr.bo?.components.sec || '-'}</h4>
                </span>
                <span>
                  <p>Tesoureiro:</p>
                  <h4>{zbr.bo?.components.tes || '-'}</h4>
                </span>
                <span>
                  <p>Material:</p>
                  <h4>{zbr.bo?.components.mat || '-'}</h4>
                </span>
                <span>
                  <p>Localização da Coordenação:</p>
                  <h4>
                    {zbr.bo?.location || '-'} – {zbr.bo?.uf}
                  </h4>
                </span>
                <span>
                  <p>Contato:</p>
                  <h4>
                    Cel. +55 {zbr.bo?.phone || '-'} - {zbr.bo?.mail || '-'}
                  </h4>
                </span>
              </>
            ) : (
              <Loader>
                <div />
              </Loader>
            )}
          </ZonalSpan>
        </div>
        <div>
          <ZonalSpan bgcolor="#ffdf00">
            <h2>Zona Brasil Centro</h2>
            {zbr?.bc ? (
              <>
                <span>
                  <p>Coordenador:</p>
                  <h4>{zbr.bc?.components.coord || '-'}</h4>
                </span>
                <span>
                  <p>Secretário:</p>
                  <h4>{zbr.bc?.components.sec || '-'}</h4>
                </span>
                <span>
                  <p>Tesoureiro:</p>
                  <h4>{zbr.bc?.components.tes || '-'}</h4>
                </span>
                <span>
                  <p>Material:</p>
                  <h4>{zbr.bc?.components.mat || '-'}</h4>
                </span>
                <span>
                  <p>Localização da Coordenação:</p>
                  <h4>
                    {zbr.bc?.location || '-'} – {zbr.bc?.uf}
                  </h4>
                </span>
                <span>
                  <p>Contato:</p>
                  <h4>
                    Cel. +55 {zbr.bc?.phone || '-'} - {zbr.bc?.mail || '-'}
                  </h4>
                </span>
              </>
            ) : (
              <Loader>
                <div />
              </Loader>
            )}
          </ZonalSpan>
          <ZonalSpan bgcolor="#0040c2">
            <h2>Zona Brasil Leste</h2>
            {zbr?.bl ? (
              <>
                <span>
                  <p>Coordenador:</p>
                  <h4>{zbr.bl?.components.coord || '-'}</h4>
                </span>
                <span>
                  <p>Secretário:</p>
                  <h4>{zbr.bl?.components.sec || '-'}</h4>
                </span>
                <span>
                  <p>Tesoureiro:</p>
                  <h4>{zbr.bl?.components.tes || '-'}</h4>
                </span>
                <span>
                  <p>Material:</p>
                  <h4>{zbr.bl?.components.mat || '-'}</h4>
                </span>
                <span>
                  <p>Localização da Coordenação:</p>
                  <h4>
                    {zbr.bl?.location || '-'} – {zbr.bl?.uf}
                  </h4>
                </span>
                <span>
                  <p>Contato:</p>
                  <h4>
                    Cel. +55 {zbr.bl?.phone || '-'} - {zbr.bl?.mail || '-'}
                  </h4>
                </span>
              </>
            ) : (
              <Loader>
                <div />
              </Loader>
            )}
          </ZonalSpan>
        </div>
      </Zonal>
      <Separator>Coordenações Zonais pelo Mundo</Separator>
      {/* START INT ZONS */}
      <ZonalIntContainer>
        {ints.map((item) => (
          <FieldSet>
            <img
              src={`${process.env.REACT_APP_ASSETS_DIR}/peloMundo/${item.banner}.png`}
              alt={item.desc}
            />
            <legend>{item.desc}</legend>
            <section>{item.area}</section>
            <div>
              <p>
                <FaAngleRight />
                Coordenador
              </p>
              <span>
                <p>
                  <FaUser />
                  {item.cname}
                </p>
                {item.cname ? (
                  <p>
                    <FaEnvelope />
                    {item.cmail}
                  </p>
                ) : null}

                {item.cphone1 ? (
                  <p>
                    <FaPhone />
                    {item.cphone1}
                  </p>
                ) : null}

                {item.cphone2 ? (
                  <p>
                    <FaPhone />
                    {item.cphone2}
                  </p>
                ) : null}
                <p>
                  <FaBuilding />
                  {item.clocal}
                </p>
              </span>
            </div>
            <div>
              <p>
                <FaAngleRight />
                Secretario
              </p>
              <span>
                <p>
                  <FaUser />
                  {item.sname}
                </p>
                {item.smail ? (
                  <p>
                    <FaEnvelope />
                    {item.smail}
                  </p>
                ) : null}

                {item.sphone1 ? (
                  <p>
                    <FaPhone />
                    {item.sphone1}
                  </p>
                ) : null}

                {item.sphone1 ? (
                  <p>
                    <FaPhone />
                    {item.sphone1}
                  </p>
                ) : null}
                <p>
                  <FaBuilding />
                  {item.slocal}
                </p>
              </span>
            </div>
          </FieldSet>
        ))}
      </ZonalIntContainer>
    </>
  );
};

export default PeloMundoCoords;
