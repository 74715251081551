import styled from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: #c6c6c6;

  display: flex;
  align-items: center;

  @media screen and (orientation: landscape) and (max-width: 850px) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  color: #332e2e;
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: center;

  h1 {
    font-size: 50px;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    margin: 25px 0 50px 0;
  }

  h2 {
    text-align: center;
    text-justify: justify;
    font-size: 35px;
  }

  h4 {
    font-size: 16px;
  }

  @media screen and (orientation: landscape) and (max-width: 850px) {
    h1 {
      font-size: 18.72px;
      margin: 25px 0 0 0;
    }

    h2 {
      font-size: 18.72px;
    }

    h4 {
      font-size: 14px;
    }
  }
`;

export const SVGContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #332e2e;
    height: 70px;
    width: 70px;
  }

  @media screen and (orientation: landscape) and (max-width: 850px) {
    margin-top: 25px;
  }
`;

export const Button404 = styled(Button)`
  margin-top: 25px;

  background: #332e2e;
  color: #efefef;

  &:hover {
    color: #332e2e;
    background-color: #efefef;
  }
`;
