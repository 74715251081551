import styled, { css } from 'styled-components';

import { shade } from 'polished';

interface TDProps {
  paint?: number;
  bgcolor?: string;
}

export const Table = styled.table`
  table-layout: fixed;
  margin: 20px auto 50px;
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid #332e2e;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  /* min-width: 500px; */

  thead {
    background: #332e2e;

    font-weight: bold;
    color: #fff;
  }

  tr {
    display: flex;
  }

  @media screen and (max-width: 600px) {
    /* width: 100%; */
  }
`;

const TableTD = styled.td<TDProps>`
  font-size: 16px;
  padding: 2px 3px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  ${(props) =>
    !!props.paint &&
    !!props.bgcolor &&
    css`
      background: ${props.paint % 2 === 0
        ? shade(0.1, `${props.bgcolor}`)
        : props.bgcolor};
    `}

  ${(props) =>
    props.paint &&
    !props.bgcolor &&
    css`
      background: ${props.paint % 2 === 0 ? '#efefef' : '#fff'};
    `}
`;

export const TDMonth = styled(TableTD)<TDProps>`
  width: 100px;
  justify-content: flex-start;

  transition: width 0.25s ease-in;

  @media screen and (max-width: 600px) {
    width: 50px;
  }
`;

export const TDPrevious2 = styled(TableTD)<TDProps>`
  /* font-size: 13px; */
  width: 120px;
  justify-content: flex-end;
  transition: width 0.25s ease-in, color 0.25s;

  @media screen and (max-width: 800px) {
    width: 0px;
    padding: 0px;
  }
`;

export const TDPrevious = styled(TableTD)<TDProps>`
  /* font-size: 13px; */
  width: 120px;
  justify-content: flex-end;

  transition: font-size 0.25s, width 0.25s ease-in;

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }

  @media screen and (max-width: 480px) {
    width: 0px;
    padding: 0px;
  }
`;

export const TDCurrent = styled(TableTD)<TDProps>`
  /* font-size: 13px; */
  width: 120px;
  justify-content: flex-end;

  transition: font-size 0.25;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`;

export const TDCompl = styled(TableTD)`
  justify-content: flex-start;
  width: 270px;
  /* overflow: hidden; */
  white-space: unset;
  /* white-space: normal; */
  transition: width 0.25s ease-in;

  @media screen and (max-width: 700px) {
    width: calc(100% - 270px);
  }

  @media screen and (max-width: 600px) {
    width: calc(100% - 250px);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const TDUpdate = styled(TableTD)`
  justify-content: center;
  width: 50px;
`;
