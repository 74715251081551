import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import SelectV2 from 'components/SelectV2';
import ScrollTop from 'components/ScrollTop';
import IncludeButton from 'components/IncludeButton';
import PrintButton from 'components/PrintButton';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useToast } from 'hooks/toast';

import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from 'services/api';

import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import {
  Container,
  Header,
  AlteredContent,
  CoordProps,
  ComboProps,
  ComboLocalProps,
  RemoveButton,
  FloatInput,
} from 'styles/sgo_wrappers';

import {
  deleteLocalStorageItemKey,
  getLocalStorage,
  setLocalStorage,
} from 'utils/handleLocalStorage';
import { removeAcento } from 'utils/specialChars';

import { FaTimes, FaFileExcel, FaPencilAlt } from 'react-icons/fa';
import { useSpring } from 'react-spring';

import { GridContainer, GridItem } from './styles';

interface LocalStorageProps {
  zon?: string;
  anb?: string;
  loc?: string;
  coord?: string;
  search?: string;
}

export interface ListProps {
  anb: string;
  loc: string;
  seq: number;
  qte: number;
  compl: string;
  title: string;
  detail: string;
  matcod: string;
}

const Biblioteca: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [initZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );
    return zon || user.zoncod;
  });
  const [initNac, setInitNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );
    return anb || 'Selecione';
  });
  const [initLoc, setInitLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );
    return loc || 'Selecione';
  });
  const [coord, setCoord] = useState<CoordProps>(() => {
    const { coord: Coord }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );
    return Coord
      ? JSON.parse(Coord)
      : ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [comboZON, setComboZon] = useState<ComboProps[]>([]);
  const [comboNAC, setComboNac] = useState<ComboProps[]>([]);
  const [comboLOC, setComboLoc] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);
  const [selected, setSelected] = useState<ListProps>({} as ListProps);

  const getComboZon = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZon(response.data);
  }, []);

  const getComboNac = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setComboNac(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === (zon || user.zoncod).substr(0, 2),
      ),
    );
  }, [user.zoncod]);

  const getComboLoc = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );

    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );
    if (anb || user.perfil === 'NAC') {
      setComboLoc(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [user.anbc, user.perfil]);

  const getList = useCallback(async () => {
    try {
      const response = await api.get('/sgo/biblioteca_list.php');
      setRawList(response.data);

      const { coord: Coord }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_BIBLIOTECA}`,
      );

      setList(
        response.data.filter((item: ListProps) =>
          Coord ? JSON.parse(Coord).cod === item.loc : item,
        ),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  useEffect(() => {
    if (user.perfil === 'INT') {
      getComboZon();
    }
    if (['INT', 'ZON'].indexOf(user.perfil) > -1) {
      getComboNac();
    }

    if (['GUI', 'LOC'].indexOf(user.perfil) < 0) {
      getComboLoc();
    }

    getList();

    const { coord: Coord }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    if (!Coord) {
      setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
        coord: JSON.stringify(
          ['INT', 'ZON'].indexOf(user.perfil) > -1
            ? { cod: user.zoncod, desc: user.zondesc }
            : user.perfil === 'NAC'
            ? { cod: user.anbc, desc: user.anbdesc }
            : { cod: user.loccod, desc: user.locdesc },
        ),
      });
    }
  }, [
    getComboLoc,
    getComboNac,
    getComboZon,
    getList,
    user.anbc,
    user.anbdesc,
    user.loccod,
    user.locdesc,
    user.perfil,
    user.zoncod,
    user.zondesc,
  ]);

  const handleZONSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('zonal');
    const { text, value } = select.options[select.selectedIndex];
    setInitNac('Selecione');
    setInitLoc('Selecione');
    setComboLoc([]);
    setComboNac(
      nacs.filter(
        (item: ComboProps) => item.value.substr(0, 2) === value.substr(0, 2),
      ),
    );

    setCoord({
      cod: value,
      desc: text,
    });

    setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
      zon: value,
      coord: JSON.stringify({
        cod: value,
        desc: text,
      }),
    });
    deleteLocalStorageItemKey(`${process.env.REACT_APP_BIBLIOTECA}`, [
      'anb',
      'loc',
    ]);

    const { search }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setList(
      rawList
        .filter(
          // (item: ListProps) => item.anb.substr(0, 2) === value.substr(0, 2),
          (item: ListProps) => item.loc === value,
        )
        .filter((item) =>
          removeAcento(item.title)
            .toLowerCase()
            .includes(removeAcento(search || '').toLowerCase()),
        ),
    );
  }, [nacs, rawList]);

  const handleNacSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('nacional');
    const { text, value } = select.options[select.selectedIndex];
    setInitLoc('Selecione');
    setInitNac(text);
    setComboLoc(locs.filter((item: ComboLocalProps) => item.anb === value));
    setCoord({ cod: value, desc: text });
    setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
      anb: value,
      coord: JSON.stringify({ cod: value, desc: text }),
    });
    deleteLocalStorageItemKey(`${process.env.REACT_APP_BIBLIOTECA}`, ['loc']);

    const { search }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setList(
      rawList
        .filter((item: ListProps) => item.loc === value)
        .filter((item) =>
          removeAcento(item.title)
            .toLowerCase()
            .includes(removeAcento(search || '').toLowerCase()),
        ),
    );
  }, [locs, rawList]);

  const handleLocSelect = useCallback(() => {
    const select = formRef.current?.getFieldRef('local');
    const { text, value } = select.options[select.selectedIndex];
    setInitLoc(text);
    setCoord({ cod: value, desc: text });
    setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
      loc: value,
      coord: JSON.stringify({ cod: value, desc: text }),
    });

    const { search }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setList(
      rawList
        .filter((item: ListProps) => item.loc === value)
        .filter((item) =>
          removeAcento(item.title)
            .toLowerCase()
            .includes(removeAcento(search || '').toLowerCase()),
        ),
    );
  }, [rawList]);

  const handleRemoveLocFilter = useCallback(() => {
    deleteLocalStorageItemKey(`${process.env.REACT_APP_BIBLIOTECA}`, ['loc']);
    const select = formRef.current?.getFieldRef('nacional');
    const { text, value } =
      user.perfil === 'NAC'
        ? { text: user.anbdesc, value: user.anbc }
        : select.options[select.selectedIndex];

    setInitLoc('Selecione');
    setCoord({ cod: value, desc: text });
    setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
      coord: JSON.stringify({ cod: value, desc: text }),
    });

    const { search }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setList(
      rawList
        .filter((item: ListProps) => item.loc === value)
        .filter((item) =>
          removeAcento(item.title)
            .toLowerCase()
            .includes(removeAcento(search || '').toLowerCase()),
        ),
    );
    setTimeout(() => {
      const selectLOC = formRef.current?.getFieldRef('local');
      selectLOC.options[0].selected = true;
    }, 100);
  }, [rawList, user.anbc, user.anbdesc, user.perfil]);

  const handleRemoveNacFilter = useCallback(() => {
    deleteLocalStorageItemKey(`${process.env.REACT_APP_BIBLIOTECA}`, [
      'loc',
      'anb',
    ]);
    const select = formRef.current?.getFieldRef('zonal');

    const { text, value } =
      user.perfil === 'ZON'
        ? { text: user.zondesc, value: user.zoncod }
        : select.options[select.selectedIndex];

    setComboLoc([]);
    setInitLoc('Selecione');
    setInitNac('Selecione');
    setCoord({ cod: value, desc: text });
    setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
      coord: JSON.stringify({ cod: value, desc: text }),
    });

    const { search }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_BIBLIOTECA}`,
    );

    setList(
      rawList
        .filter((item: ListProps) => item.loc === value)
        .filter((item) =>
          removeAcento(item.title)
            .toLowerCase()
            .includes(removeAcento(search || '').toLowerCase()),
        ),
    );
    setTimeout(() => {
      const selectANB = formRef.current?.getFieldRef('nacional');
      selectANB.options[0].selected = true;
    }, 100);
  }, [rawList, user.perfil, user.zoncod, user.zondesc]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      if (value.length > 0) {
        setLocalStorage(`${process.env.REACT_APP_BIBLIOTECA}`, {
          search: value,
        });
      } else {
        deleteLocalStorageItemKey(`${process.env.REACT_APP_BIBLIOTECA}`, [
          'search',
        ]);
      }

      const { coord: Coord }: LocalStorageProps = getLocalStorage(
        `${process.env.REACT_APP_BIBLIOTECA}`,
      );

      if (Coord) {
        setList(
          rawList.filter(
            (item: ListProps) =>
              JSON.parse(Coord).cod === item.loc &&
              removeAcento(item.title)
                .toLowerCase()
                .includes(removeAcento(value).toLowerCase()),
          ),
        );
      }
    },
    [rawList],
  );

  const handlePreDel = useCallback(
    (anb, loc, seq) => {
      const index = rawList.findIndex(
        (item) => item.anb === anb && item.loc === loc && item.seq === seq,
      );
      setSelected({ ...rawList[index] });
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [rawList],
  );

  const handleCancelDel = useCallback(() => {
    setSelected({} as ListProps);
    setDeleteDiag((state) => ({ ...state, open: false }));
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      const send = new FormData();
      send.append('data', JSON.stringify({ ...selected }));
      await api.post('/sgo/biblioteca_delete.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setList((state) =>
        state.filter(
          (item) =>
            `${item.anb}${item.loc}${item.seq}` !==
            `${selected.anb}${selected.loc}${selected.seq}`,
        ),
      );
      setRawList((state) =>
        state.filter(
          (item) =>
            `${item.anb}${item.loc}${item.seq}` !==
            `${selected.anb}${selected.loc}${selected.seq}`,
        ),
      );

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `${selected.title} excluído.`,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [addToast, errorHandling, selected]);

  const removeNacFilter = useSpring({
    opacity: initNac === 'Selecione' ? 0 : 1,
    pointerEvents: initNac === 'Selecione' ? 'none' : 'all',
  });
  const removeLocFilter = useSpring({
    opacity: initLoc === 'Selecione' ? 0 : 1,
    pointerEvents: initLoc === 'Selecione' ? 'none' : 'all',
  });

  return (
    <Container>
      {['INT', 'GUI'].indexOf(user.perfil) < 0 && (
        <>
          <IncludeButton />
          <PrintButton
            icon={FaFileExcel}
            ignoreToken
            linkTo={`/sgo/xls_biblioteca_acervo.php?COD=${coord.cod}`}
            // linkTo={`/sgo/xls_aniversariantes.php?data=${JSON.stringify({
            //   cod: coord.cod,
            //   type: filter === 'zonal' ? 'ZON' : filter === 'anb' ? 'NAC' : 'LOC',
            //   mes: month.value,
            // })}`}
          />
        </>
      )}

      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={['GUI', 'LOC'].indexOf(user.perfil) < 0}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Zonal:</p>
                  <SelectV2
                    name="zonal"
                    content={comboZON}
                    initial={initZon}
                    onChange={handleZONSelect}
                  />
                  <RemoveButton
                    type="button"
                    style={{ opacity: 0, pointerEvents: 'none' }}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['ZON', 'INT'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Nacional:</p>
                  <SelectV2
                    name="nacional"
                    content={comboNAC}
                    initial={initNac}
                    onChange={handleNacSelect}
                  />
                  <RemoveButton
                    type="button"
                    style={removeNacFilter}
                    onClick={handleRemoveNacFilter}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              <span>
                <p>Local:</p>
                <SelectV2
                  name="local"
                  content={comboLOC}
                  initial={initLoc}
                  onChange={handleLocSelect}
                />
                <RemoveButton
                  type="button"
                  style={removeLocFilter}
                  onClick={handleRemoveLocFilter}
                >
                  <FaTimes />
                  &nbsp;
                  <p>Excluir filtro</p>
                </RemoveButton>
              </span>
            </div>
          </Form>
        }
      />
      <Header>
        <div>
          <p>
            {/* Materiais de Estudo da&nbsp; */}
            {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 &&
            initLoc === 'Selecione'
              ? 'Acervo da Equipe'
              : 'Biblioteca da Coordenação'}
          </p>
        </div>
        <div>
          <p>{coord.desc}</p>
        </div>
      </Header>
      <FloatInput amount="121px">
        <input
          placeholder="Filtrar por título"
          value={searchValue}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
        />
      </FloatInput>
      <AlteredContent pixels="301px">
        <GridContainer>
          {list.map((item) => (
            <GridItem key={`${item.anb}${item.loc}${item.seq}`}>
              <div>
                <p>
                  Título:<strong>{item.title}</strong>
                </p>
              </div>
              {/* <div>
                <p>
                  Detalhe:<strong>{item.detail || '-'}</strong>
                </p>
              </div> */}
              <div>
                <p>
                  Complemento:<strong>{item.compl || '-'}</strong>
                </p>
              </div>
              <div>
                {/* <span>
                  <p>
                    Código do Material:<strong>{item.matcod || '-'}</strong>
                  </p>
                </span> */}
                {/* <span>
                  <p>
                    Quantidade:<strong>{item.qte}</strong>
                  </p>
                </span> */}
              </div>
              {['GUI'].indexOf(user.perfil) < 0 &&
                (user.perfil === 'LOC' ||
                  (user.perfil === 'NAC' && initLoc === 'Selecione') ||
                  (['INT', 'ZON'].indexOf(user.perfil) > -1 &&
                    initLoc === 'Selecione' &&
                    initNac === 'Selecione')) && (
                  <O.GridOptions>
                    <O.Update
                      to={{
                        pathname: `${pathname}/update`,
                        state: { ...item },
                      }}
                    >
                      <FaPencilAlt />
                    </O.Update>

                    <O.Delete
                      onClick={() => handlePreDel(item.anb, item.loc, item.seq)}
                    >
                      <FaTimes />
                    </O.Delete>
                  </O.GridOptions>
                )}
            </GridItem>
          ))}
        </GridContainer>

        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <D.ModalDeleteContent>
              <p>
                Você está prestes a excluir&nbsp;
                <strong style={{ color: '#c53030' }}>permanentemente</strong>
                &nbsp;o registro:
              </p>
              <div>
                <span>
                  <p>
                    Título:&nbsp;<strong>{selected.title}</strong>
                  </p>
                </span>
                {/* <span>
                  <p>
                    Detalhe:&nbsp;<strong>{selected.detail}</strong>
                  </p>
                </span> */}
                <span>
                  <p>
                    Complemento:&nbsp;<strong>{selected.compl}</strong>
                  </p>
                </span>
              </div>
              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </D.ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel type="button" onClick={handleCancelDel}>
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleDelete}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default Biblioteca;
