import React, { useEffect, useState, useCallback, useRef } from 'react';

import { FaPhone, FaMobileAlt, FaEnvelope, FaTimes } from 'react-icons/fa';
import { Container, Content } from 'styles/wrappers';

import Header from 'components/Header';
import NavbarContainer from 'components/NavbarContainer';
import ScrollTop from 'components/ScrollTop';
import Footer from 'components/Footer';
import Loading from 'components/Loading';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { formatDate } from 'utils/formatDate';

import api from 'services/api';
import errorHandling from 'utils/errorHandling';

import { AlteredHeader, ComboProps } from 'styles/sgo_wrappers';
import { useSpring } from 'react-spring';
import SelectV2 from 'components/SelectV2';
import * as I from '../Oficinas/interfaces';

import {
  ComponentContainer,
  ComponentContent,
  RemoveButton,
  Schedule,
  ScheduleItem,
  SearchButton,
  SearchContainer,
  Wrapper,
} from '../Oficinas/styles';

interface LocationProps {
  state?: string;
  city?: string;
}

interface EEDProps {
  svcseq: string;
  locresp: string;
  inidate: string;
  enddate: string;
  prqname: string;
  prqaddress: string;
  prqdistrict: string;
  prqcity: string;
  uf: string;
  ufnome: string;
  applname: string;
  secrname: string;
  cellphone: string;
  phone: string;
  mail: string;
}

interface SearchProps {
  param_state: string;
  param_city: string;
}

const EEDs: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [eeds, setEEDs] = useState<I.EEDProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<ComboProps[]>([]);
  const [ocities, setOCities] = useState<I.City[]>([]);
  const [cities, setCities] = useState<ComboProps[]>([]);
  const [hasFilterApplied, setFilterApplied] = useState(false);
  const [error, setError] = useState('');

  const getCombos = useCallback(async () => {
    try {
      const response = await api.get('/web/filterEED.php');
      setStates(response.data.uf);
      setOCities(response.data.cities);
    } catch (err) {
      errorHandling(err);
    }
  }, []);

  useEffect(() => {
    getCombos();
  }, [getCombos]);

  const handleChange = useCallback(
    (ev: any) => {
      const { type } = ev.currentTarget.dataset;
      const select = formRef.current?.getFieldRef(type);
      const { value } = select.options[select.selectedIndex];
      if (type === 'uf') {
        setFilterApplied(true);
        setCities(
          ocities
            .filter((item) => item.uf === value)
            .map((item) => ({ label: item.label, value: item.value })),
        );
      } else if (type === 'city') {
        // nothing to do
      } else {
        // other logics.
      }
    },
    [ocities],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const uf = formRef.current?.getFieldRef('uf');
      const city = formRef.current?.getFieldRef('city');

      const vU = uf.options[uf.selectedIndex].value;
      const vC = city.options[city.selectedIndex].value;

      if (vU === '') {
        setError('Selecione o estado');

        setTimeout(() => {
          setError('');
        }, 2500);
        return;
      }

      setLoading(true);

      const response = await api.get(`/web/eed.php?uf=${vU}&city=${vC}`);

      setEEDs(response.data.eeds);
      setLoading(false);
    } catch (err) {
      errorHandling(err);
    }
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilterApplied(false);
    setCities([]);
    formRef.current?.setData({
      uf: '',
      city: '',
    });
  }, []);

  const removeButtonStyled = useSpring({
    opacity: hasFilterApplied ? 1 : 0,
    pointerEvents: hasFilterApplied ? 'all' : 'none',
    height: hasFilterApplied ? '20px' : '0px',
    overflow: hasFilterApplied ? 'unset' : 'hidden',
  });
  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <Header web />
      <NavbarContainer />
      <Content>
        <AlteredHeader>
          <div>Encontro de Experiência de Deus</div>
        </AlteredHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <strong>
              Saiba onde estão sendo aplicadas atualmente os Encontros
            </strong>
            <div
              style={{
                width: '100vw',
                display: 'flex',
              }}
            >
              <RemoveButton
                type="button"
                onClick={handleClearFilters}
                style={removeButtonStyled}
              >
                <FaTimes />
                &nbsp;
                <p>Remover filtros</p>
              </RemoveButton>
            </div>
            <SearchContainer>
              <div>
                <p>Estado</p>
                <SelectV2
                  name="uf"
                  data-type="uf"
                  initial="Selecione"
                  content={states}
                  onChange={handleChange}
                  containerStyle={{
                    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </div>
              <div>
                <p>Cidade</p>
                <SelectV2
                  name="city"
                  data-type="city"
                  initial="Selecione"
                  content={cities}
                  onChange={handleChange}
                  containerStyle={{
                    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </div>
            </SearchContainer>

            <div style={{ width: '100vw', display: 'flex' }}>
              <SearchButton type="submit">{error || 'Pesquisar'}</SearchButton>
            </div>
          </Wrapper>
        </Form>
        <ComponentContainer>
          {eeds.map((ofic) => (
            <ComponentContent key={ofic.svcseq}>
              <span>
                <strong>Coordenação Responsável:</strong>
                <strong style={{ color: '#8a0002' }}>{ofic.locresp}</strong>
              </span>
              <Schedule>
                <ScheduleItem>
                  <strong>Data Inicial:</strong>
                  <p>{formatDate(ofic.inidate)}</p>
                </ScheduleItem>
                <ScheduleItem>
                  <strong>Data Final:</strong>
                  <p>{formatDate(ofic.enddate)}</p>
                </ScheduleItem>
              </Schedule>

              <span>
                <strong>Local:</strong>
                <p>{ofic.prqname}</p>
              </span>

              <span>
                <strong>Endereço:</strong>
                <p>{ofic.prqaddress}</p>
              </span>
              <span>
                <strong>Bairro:</strong>
                <p>{ofic.prqdistrict}</p>
              </span>

              <span>
                <strong>Cidade/UF:</strong>
                <p>
                  {ofic.prqcity}/{ofic.uf}
                </p>
              </span>

              <span>
                <strong>Guias:</strong>
                <p>{ofic.applname}</p>
              </span>

              <span>
                <strong>Contato:</strong>
                <p>{ofic.secrname}</p>
              </span>
              <span>
                {ofic.cellphone !== '' && (
                  <p>
                    <FaMobileAlt />
                    {ofic.cellphone}
                  </p>
                )}
                {ofic.phone !== '' && (
                  <p>
                    <FaPhone />
                    {ofic.phone}
                  </p>
                )}
                {ofic.phone !== '' && (
                  <p>
                    <FaEnvelope />
                    {ofic.mail}
                  </p>
                )}
              </span>
            </ComponentContent>
          ))}
        </ComponentContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default EEDs;
